import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import { BaseFile, COMPANY_DNS, InputType, INVALID_ALL_INPUTS } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { loanStructureStepUpgrade } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { openS3Document } from 'services'
import { getCurrentProductTypesPrograms, submitExceptionRequest, submitExceptionResponse } from 'services/apis'
import { Button, Modal } from 'stories/components'
import { InputValidate, isEmpty, PermissionGate } from 'utils'
import { formatDate, formatTime } from 'utils/convertor'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { convertHistoryLabel, overrideFields } from './constant'
import type { ILoanDetail, ILoanProcess } from './interfaces'

const getCommentFields = (loanNumber: number): Record<string, InputType> => ({
  comment: {
    title: 'Comment',
    inputType: 'textarea',
    rows: 3,
    error: '',
    required: true,
  },
  applicationFiles: {
    title: 'Application Files',
    inputType: 'filetable',
    error: '',
    acceptFileTypes: '*.*',
    filePath: `loan/${loanNumber}/exceptions`,
    showCategory: false,
    showStatus: false,
  },
  creditReportFiles: {
    title: 'Credit Report Files',
    inputType: 'filetable',
    error: '',
    acceptFileTypes: '*.*',
    filePath: `loan/${loanNumber}/exceptions`,
    showCategory: false,
    showStatus: false,
  },
})

interface IProps {
  isNonDscrProduct?: boolean
  loanDetail?: ILoanDetail
  exceptions: any[]
  forPdf?: boolean
  setExceptions?: (data: any[]) => void
  setLoanProcessData?: (data: ILoanProcess) => void
}

export function ExceptionResponse(props: IProps) {
  const {
    forPdf = false,
    exceptions,
    isNonDscrProduct,
    loanDetail,
    setExceptions = () => {},
    setLoanProcessData = () => {},
  } = props

  const loanNumber = setLoanNumber()

  const [action, setAction] = useState('')
  const [visibles, setVisibles] = useState(['override', 'note'])
  const [fields, setFields] = useState(overrideFields())
  const [commentFields, setCommentFields] = useState(getCommentFields(loanNumber))
  const [notifiers, setNotifiers] = useState<EmailValue>({})

  const { hasPermission } = usePermissions()
  const canResponseException = hasPermission('CAN_RESPONSE_ON_EXCEPTION_REQUEST')

  useEffect(() => {
    setDefaultNotifiers()
  }, [])
  const { email } = useSelector((state: any) => state.auth.profile)
  const isCompanyEmail = useMemo(() => (email || '').endsWith(`@${COMPANY_DNS}`), [email])

  const setDefaultNotifiers = () => {
    const notifiers: EmailValue = {}
    if (loanDetail?.parties.loanOfficer?.email) notifiers[loanDetail?.parties.loanOfficer?.email] = 'to'
    if (loanDetail?.parties.loanProcessor?.email) notifiers[loanDetail?.parties.loanProcessor?.email] = 'to'
    setNotifiers(notifiers)
  }

  const onOpen = () => {
    const newNotifiers: any = cloneDeep(notifiers)
    if (isCompanyEmail) newNotifiers.from = 'self'
    else newNotifiers.from = 'default'
    setNotifiers(newNotifiers)

    setVisibles(['override', 'note'])

    setAction('submit')
    getCurrentProductTypesPrograms()
      .then((res) => {
        const newFields: any = cloneDeep(overrideFields())
        newFields['type'].options = res.productTypes
        newFields['program'].options = res.programNames
        setFields(newFields)
      })
      .finally(() => setAction(''))
  }

  const onOpenComment = () => {
    const newFields = cloneDeep(getCommentFields(loanNumber))
    setCommentFields(newFields)
  }

  const onSubmitResponse = async () => {
    let hasError = false
    let object: any = {}
    const temp = cloneDeep(fields)

    Object.keys(temp).map((key) => {
      if (visibles.indexOf(key) !== -1) {
        const error = InputValidate({ ...temp[key] })
        object[key] = temp[key].value
        if (error.length) {
          temp[key].error = error
          hasError = true
        }
      }
    })

    if (hasError) {
      setFields(temp)
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    object.isNonDscrProduct = isNonDscrProduct

    setAction('submit')
    const res = await submitExceptionResponse({ expResponse: object, emailTo: notifiers })

    let tempExp = cloneDeep(exceptions)
    tempExp.push(res.data)
    setExceptions(tempExp)

    if (object.override) {
      const json = {
        type: object.type,
        program: object.program,
        rate: object.rate,
        price: object.price,
        sheetDate: `${formatDate('now')}(E)`,
        lockDays: object.lockDays,
        rateSpread: object?.rateSpread,
        exitFee: object?.exitFee,
      }
      setLoanProcessData(json)
      await loanStructureStepUpgrade('rateAndPrice', object.override ? 1 : 0)
    }

    setAction('')
    setDefaultNotifiers()
    return true
  }

  const onChangeField = async (key: string, e: any) => {
    let temp = cloneDeep(fields)
    temp[key].value = e
    temp[key].error = InputValidate({ ...temp[key] })
    setFields(temp)

    if (key === 'override') {
      if (e === true) {
        if (isNonDscrProduct)
          setVisibles(['override', 'type', 'program', 'rate', 'price', 'lockDays', 'rateSpread', 'exitFee', 'note'])
        else setVisibles(['override', 'type', 'program', 'rate', 'price', 'lockDays', 'note'])
      }
      if (e === false) {
        setVisibles(['override', 'note'])
      }
    }
  }

  const onChangeCommentField = async (key: string, value: any) => {
    let temp = cloneDeep(commentFields)
    temp[key].value = value
    temp[key].error = InputValidate({ ...temp[key] })
    setCommentFields(temp)
  }

  const onSubmitComment = async () => {
    let hasError = false
    let object: any = {}
    const temp = cloneDeep(commentFields)

    Object.keys(temp).map((key) => {
      const error = InputValidate({ ...temp[key] })
      object[key] = temp[key].value

      if (error.length) {
        temp[key].error = error
        hasError = true
      }
    })

    if (hasError) {
      setFields(temp)
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    object.isComment = true

    setAction('submit')
    const res = await submitExceptionRequest({ expRequest: object })

    let tempExp = cloneDeep(exceptions)
    tempExp.push(res.data)
    setExceptions(tempExp)
    setAction('')
    return true
  }

  const renderDetail = (data: any) => {
    if (['Request', 'Comment'].includes(data.type)) {
      const {
        content,
        comment,
        factor,
        errorFields,
        applicationFile,
        creditReportFile,
        applicationFiles,
        creditReportFiles,
      } = data.json as {
        applicationFiles: BaseFile[] | undefined
        creditReportFiles: BaseFile[] | undefined
        [key: string]: any
      }
      const hasFile =
        applicationFile ||
        creditReportFile ||
        (applicationFiles && !!applicationFiles.length) ||
        (creditReportFiles && !!creditReportFiles.length)

      return (
        <div className="">
          {content && (
            <div className="">
              Content: <span className="ml-2 italic">{content}</span>
            </div>
          )}
          {factor && (
            <div className="">
              Compensating factors: <span className="ml-2 italic">{factor}</span>
            </div>
          )}
          {errorFields && (
            <div className="">
              Changes: <span className="ml-2 italic">{errorFields}</span>
            </div>
          )}
          {comment && (
            <div className="">
              Comment: <span className="ml-2 italic">{comment}</span>
            </div>
          )}
          {hasFile && (
            <div className="flex gap-3 items-start">
              <span>Files:</span>
              {applicationFile && (
                <span
                  className="flex gap-1 items-center hover:underline cursor-pointer text-[15px] font-medium text-shade-blue"
                  onClick={() => openS3Document(applicationFile)}
                >
                  <span className="text-[13px]">Application</span>
                  <span>
                    <ArrowDownTrayIcon className="w-4 h-4" />
                  </span>
                </span>
              )}

              {creditReportFile && (
                <span
                  className="flex gap-1 items-center hover:underline cursor-pointer text-[15px] font-medium text-shade-blue"
                  onClick={() => openS3Document(creditReportFile)}
                >
                  <span className="text-[13px]">Credit Report</span>
                  <span>
                    <ArrowDownTrayIcon className="w-4 h-4" />
                  </span>
                </span>
              )}

              <div>
                {applicationFiles && applicationFiles.length && (
                  <div className="flex flex-wrap gap-2">
                    <span className="text-[13px] font-bold">Applications:</span>
                    {applicationFiles.map((file, index) => (
                      <span
                        key={index}
                        className="flex gap-1 items-center hover:underline cursor-pointer text-[15px] font-medium text-shade-blue"
                        onClick={() => openS3Document(file.fileKey)}
                      >
                        <span className="text-[13px]">{file.name}</span>
                        <span>
                          <ArrowDownTrayIcon className="w-4 h-4" />
                        </span>
                      </span>
                    ))}
                  </div>
                )}
                {creditReportFiles && creditReportFiles.length && (
                  <div className="flex flex-wrap gap-2">
                    <span className="text-[13px] font-bold">Credit Reports:</span>
                    {creditReportFiles.map((file, index) => (
                      <span
                        key={index}
                        className="flex gap-1 items-center hover:underline cursor-pointer text-[15px] font-medium text-shade-blue"
                        onClick={() => openS3Document(file.fileKey)}
                      >
                        <span className="text-[13px]">{file.name}</span>
                        <span>
                          <ArrowDownTrayIcon className="w-4 h-4" />
                        </span>
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    }

    if (data.type === 'Response') {
      return (
        <div className="">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <table className="text-[13px]">
              <thead>
                <tr>
                  {Object.keys(data.json).map((key, index) => {
                    if (key == 'emailTo') return null
                    return (
                      <td className="border px-2 py-1" key={index}>
                        {convertHistoryLabel(key)}
                      </td>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.keys(data.json).map((key, index) => {
                    if (key == 'emailTo') return null
                    let value = data.json[key]
                    if (typeof value == 'boolean') value = value ? 'Yes' : 'No'
                    return (
                      <td className="border px-2 py-1 italic" key={index}>
                        {value}
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          {data.json.emailTo && <p className="italic text-sm mt-2">Email {data.json.emailTo}</p>}
        </div>
      )
    }
  }

  return (
    <div className="ExceptionResponse-container">
      <div className="text-xl font-bold mb-4 flex items-center">
        <span>Exception Status</span>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Date/Time
              </th>
              <th scope="col" className="px-6 py-3">
                By
              </th>
              <th scope="col" className="px-6 py-3">
                Detail
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-900">
            {exceptions.map((item, index) => {
              return (
                <tr key={index} className={`${index % 2 === 1 && 'bg-slate-50'}`}>
                  <td scope="row" className="px-6 py-2">
                    {index + 1}
                  </td>
                  <td className="px-6 py-2">{item.type}</td>
                  <td className="px-6 py-2">{formatTime(item.time)}</td>
                  <td className="px-6 py-2">{item.email}</td>
                  <td className="px-6 py-2">{renderDetail(item)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {!forPdf && loanDetail?.rateData.locked !== true && (
        <PermissionGate permission={'CAN_RESPONSE_ON_EXCEPTION_REQUEST'} hidden={true}>
          <div className="mt-4 flex justify-center -mb-2">
            <Modal
              button={<Button color="gray">Submit Response</Button>}
              title="Submit Exception Response"
              titleOkay="Confirm"
              onOk={onSubmitResponse}
              onOpen={onOpen}
              onClose={() => setDefaultNotifiers()}
              loading={action === 'submit'}
            >
              <div>
                <div className="grid col-span-12 items-center gap-4 justify-center mb-2">
                  {visibles.map((key, index) => {
                    let cn = 'md:col-span-6 col-span-12 w-96'
                    if (key === 'override') cn = 'col-span-12'
                    return (
                      <div className={cn} key={index}>
                        <RenderInput input={fields[key]} Key={key} onChange={onChangeField} />
                      </div>
                    )
                  })}
                </div>

                <LoanPartiesEmailTo
                  loanNumber={loanNumber}
                  value={notifiers}
                  data={[]}
                  onChange={setNotifiers}
                  loadParties
                />
              </div>
            </Modal>
          </div>
        </PermissionGate>
      )}

      {!forPdf && !loanDetail?.rateData.locked && !canResponseException && (
        <div className="mt-4 flex justify-center -mb-2">
          <Modal
            button={<Button color="gray">Add Comment</Button>}
            title="Add Comment on Exception"
            titleOkay="Confirm"
            onOk={onSubmitComment}
            onOpen={onOpenComment}
            loading={action === 'submit'}
            disabled={isEmpty(commentFields.comment.value)}
          >
            <div className="mb-2 md:min-w-[600px]">
              {Object.keys(commentFields).map((key, index) => {
                return (
                  <div className="w-full mb-4" key={index}>
                    <RenderInput input={commentFields[key]} Key={key} onChange={onChangeCommentField} />
                  </div>
                )
              })}
            </div>
          </Modal>
        </div>
      )}
    </div>
  )
}
