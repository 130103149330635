import { InboxArrowDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { setBorrowerGroupData, setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { type InputType, appApiUrl } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import type { ILoanDetail } from 'pages/LoanStructure/interfaces'
import { propertyTypeNumberUnitInputLogic } from 'pages/LoanStructure/Logic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAdditionalPropertyInfo, updateAdditionalPropertyInfo } from 'services'
import Api from 'services/api'
import { FormTable, FormTableHeader } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  canEditLoanApplication,
  formatDate,
  getPrice3decimal,
  isEmpty,
  openAuditLog,
  removeComma,
  renderTableHistoryValue,
} from 'utils'

import { BorrowerSeperator } from '../CreditScore/constants'
import { propertyInfoValidate } from '../PropertyInformation/logic'
import { additionalFieldsForAdmin, adminHeader, defaultInputs, dscrCalcRelatedFiels, nonAdminHeader } from './constant'
import type { IAdditionalPropertyInformation } from './interface'
import { isAdditionalPropertyValidated, isPassedCrossCollateralLimit } from './logics'

interface IProps {
  setLoading: (value: boolean) => void
}

export const AdditionalPropertyInformation = (props: IProps) => {
  const [data, setData] = useState<IAdditionalPropertyInformation[]>([])
  const [tableInputs, setTableInputs] = useState<Record<string, InputType>>(defaultInputs())

  const loanNumber = Api.getLoanNumber()
  const { hasPermission } = usePermissions()

  const dispatch = useDispatch()
  const { borrower, auth, loan, loanDetail } = useSelector((state: any) => {
    return {
      loan: state.loan,
      borrower: state.borrower.borrower,
      auth: state.auth,
      loanDetail: state.loanDetail as ILoanDetail,
    }
  })
  const canEdit = canEditLoanApplication()
  const isAdmin = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

  useEffect(() => {
    const newInputs = cloneDeep(tableInputs)

    if (isAdmin) newInputs['occupancy'].value = 'Non Owner Occupied'
    else {
      additionalFieldsForAdmin.forEach((key) => delete newInputs[key])
    }

    setTableInputs(newInputs)
  }, [isAdmin])

  useEffect(() => {
    setData(borrower.additionalPropertyInfo)
  }, [borrower])

  const renderStatus = (item: IAdditionalPropertyInformation, index: number) => {
    const isValid = isAdditionalPropertyValidated(item)

    if (isValid) {
      const { crossCollateralBlanketLimit, allocatedLoanAmount, rateData } = loanDetail

      if (!isEmpty(crossCollateralBlanketLimit) && !!allocatedLoanAmount.length) {
        const { isPassed, errors } = isPassedCrossCollateralLimit(
          item,
          crossCollateralBlanketLimit,
          allocatedLoanAmount[index],
          rateData.type,
        )

        if (isPassed) return <div className="text-green-800 bg-green-100 rounded py-1 px-2 w-fit">Eligible</div>
        else
          return (
            <div className="flex cursor-pointer">
              <Tooltip
                message={`<div class="w-[175px] whitespace-normal leading-[14px]">${errors.join('<br/>')}</div>`}
              >
                <div className="py-1 px-2 bg-red-200 text-red-900 rounded">
                  <div className="flex items-center gap-1 rounded">
                    Ineligible
                    <QuestionMarkCircleIcon className="w-3 h-3"></QuestionMarkCircleIcon>
                  </div>
                </div>
              </Tooltip>
            </div>
          )
      } else {
        return (
          <div className="flex cursor-pointer">
            <Tooltip message="Interest Rate & Base Price is Required!">
              <div className="py-1 px-2 bg-red-200 text-red-900 rounded">
                <div className="flex items-center gap-1 rounded">
                  Ineligible
                  <QuestionMarkCircleIcon className="w-3 h-3"></QuestionMarkCircleIcon>
                </div>
              </div>
            </Tooltip>
          </div>
        )
      }
    } else return <div className="text-red-900 bg-red-200 rounded py-1 px-2 w-fit">Incomplete</div>
  }

  const convertData = (sourceData: IAdditionalPropertyInformation[]) => {
    let rlt: any = []

    if (isAdmin)
      sourceData.map((item, index) => {
        let json: any = {
          ...item,
          propertyTypeAddress: `<span><div class="border-b w-full mb-2">${
            item.propertyType || 'N/A'
          }</div><div class="border-b w-full mb-2">${item.propertyAddress}</div><div class="border-b w-full mb-2">${
            item.propertyAPN || 'N/A'
          }</div>${item.numberOfUnits || 'N/A'}</span>`,
          asIsCdaValue: `<span><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.purchasePrice,
          )}</div><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.asIsValue,
          )}</div><div class="border-b w-full mb-2">${
            item.cdaValue ? '$ ' + getPrice3decimal(item.cdaValue) : 'N/A'
          }</div>${item.acquisitionDate ? formatDate(item.acquisitionDate) : 'N/A'}</span>`,
          occupancyRentContract: `<span><div class="border-b w-full mb-2">${
            item.occupancy || 'N/A'
          }</div><div class="border-b w-full mb-2">$ ${getPrice3decimal(item.monthlyRent)}</div>${
            item.monthlyRentFromAppraisal ? '$ ' + getPrice3decimal(item.monthlyRentFromAppraisal) : 'N/A'
          }</span>`,
          annualTaxHOAHazardFlood: `<span><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.annualTaxAmount,
          )}</div><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.annualHOAFees,
          )}</div><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.insuranceAmount,
          )}</div>$ ${getPrice3decimal(item.floodInsurance)}</span>`,
          dscrValues: `<span><div class="border-b w-full mb-2">${getPrice3decimal(item.dscr)} %</div>${
            item.dscrBasedOnAppraisal ? getPrice3decimal(item.dscrBasedOnAppraisal) + ' %' : 'N/A'
          }</span>`,
          status: renderStatus(item, index),
        }
        rlt.push(json)
      })
    else
      sourceData.map((item, index) => {
        let json: any = {
          ...item,
          propertyTypeAddress: `<span><div class="border-b w-full mb-2">${
            item.propertyType || 'N/A'
          }</div><div class="border-b w-full mb-2">${item.propertyAddress}</div>${item.numberOfUnits || 'N/A'}</span>`,
          asIsValueMonthlyRent: `<span><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.purchasePrice,
          )}</div><div class="border-b w-full mb-2">$ ${getPrice3decimal(item.asIsValue)}</div>$ ${getPrice3decimal(
            item.monthlyRent,
          )}</span>`,
          annualTaxHOA: `<span><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.annualTaxAmount,
          )}</div>$ ${getPrice3decimal(item.annualHOAFees)}</span>`,
          hazardFlood: `<span><div class="border-b w-full mb-2">$ ${getPrice3decimal(
            item.insuranceAmount,
          )}</div>$ ${getPrice3decimal(item.floodInsurance)}</span>`,
          dscrValues: `<span><div class="border-b w-full mb-2">${item.occupancy || 'N/A'}</div>${getPrice3decimal(
            item.dscr,
          )} %</span>`,
          status: renderStatus(item, index),
        }
        rlt.push(json)
      })

    return rlt
  }

  const getData = useMemo(() => {
    return convertData(data)
  }, [data])

  const onRemove = async (id: any) => {
    return new Promise((resolve) => {
      props.setLoading(true)

      deleteAdditionalPropertyInfo(id)
        .then(() => {
          resolve(true)

          let newData: IAdditionalPropertyInformation[] = []

          borrower.additionalPropertyInfo.map((item: any) => {
            if (item.id !== id) newData.push(item)
          })
          dispatch(setBorrowerGroupData(BorrowerSeperator.BORROWER, { additionalPropertyInfo: newData }))
          setData(newData)
        })
        .finally(() => props.setLoading(false))
    })
  }

  const onSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      if (!currentId) {
        resolve(values)
        return
      }
      let newData = cloneDeep(borrower.additionalPropertyInfo)

      props.setLoading(true)

      updateAdditionalPropertyInfo(currentId, values)
        .then((allocatedLoanAmount) => {
          dispatch(
            setLoanDetail({
              allocatedLoanAmount,
            }),
          )
          resolve(values)
          newData.map((item: any, index: number) => {
            if (item.id === currentId) newData[index] = { ...item, ...values }
          })
          dispatch(setBorrowerGroupData(BorrowerSeperator.BORROWER, { additionalPropertyInfo: newData }))
          setData(newData)
        })
        .finally(() => {
          props.setLoading(false)
          propertyInfoValidate(true, true)
        })
    })
  }

  const showHistoryFormTable = async (key: string, headers: Array<FormTableHeader> = []) => {
    const options = {
      table: 'AdditionalPropertyInformation',
      field: `${key}`,
      keys: {
        field: key,
      },
      renderValue: headers.length ? (tableData: any) => renderTableHistoryValue(convertData(tableData), headers) : null,
    }
    openAuditLog(options)
  }

  const onChangeTableInputs = (newData: Record<string, InputType>, key: string) => {
    if (!dscrCalcRelatedFiels.includes(key)) return

    const newInputs = cloneDeep(newData)

    const monthlyRent = removeComma(newInputs['monthlyRent'].value)
    const annualTax = removeComma(newInputs['annualTaxAmount'].value)
    const annualHOA = removeComma(newInputs['annualHOAFees'].value)
    const hazardAnnual = removeComma(newInputs['insuranceAmount'].value)
    const floodAnnual = removeComma(newInputs['floodInsurance'].value)

    const monthlySum = (annualTax + annualHOA + hazardAnnual + floodAnnual) / 12
    const dscr = monthlySum === 0 ? 0 : monthlyRent / monthlySum
    newInputs['dscr'].value = dscr.toFixed(4)

    if (isAdmin) {
      const monthlyRentFromAppraisal = removeComma(newInputs['monthlyRentFromAppraisal'].value)
      const dscrBaseAppraisal = monthlySum === 0 ? 0 : monthlyRentFromAppraisal / monthlySum
      newInputs['dscrBasedOnAppraisal'].value = dscrBaseAppraisal.toFixed(4)
    }

    setTableInputs(newInputs)
  }

  const inputLogic = (rlt: any, id: number = 0, key: string = '') => {
    console.log(id)
    if (key === 'propertyType') {
      rlt.numberOfUnits = propertyTypeNumberUnitInputLogic(rlt.propertyType.value, rlt.numberOfUnits)
    }
    return rlt
  }

  if (loan.additionalPropertyAddress?.length > 0) {
    return (
      <div>
        <div className="border-b my-4 flex justify-between items-center">
          <p className="font-semibold text-[17px]">- Additional Subject Property Address</p>

          {isAdmin && (
            <Tooltip message="Download Excel">
              <a
                href={`${appApiUrl}/loan/additionalPropertyInfo/totalDownload/${loanNumber}?token=${auth.token}`}
                download
                className="p-1 hover-shadow1 cursor-pointer transition-all duration-200 rounded ml-3 mr-2"
              >
                <InboxArrowDownIcon className="w-5 h-5 text-shade-blue" />
              </a>
            </Tooltip>
          )}
        </div>

        <FormTable
          header={isAdmin ? adminHeader : nonAdminHeader}
          permission={canEdit ? 2.5 : 3}
          inputs={tableInputs}
          addText=""
          inputLogic={inputLogic}
          data={getData}
          showModal
          onSubmit={onSubmit}
          onRemove={onRemove}
          onChangeInputs={onChangeTableInputs}
          showHistory={(headers) => showHistoryFormTable('additionalPropertyInfo', headers)}
        />
      </div>
    )
  } else return null
}
