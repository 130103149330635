import { EmailTo } from 'components/EmailTo'
import { COMPANY_DNS } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { partiesMap } from 'reducers/loanDetail.reducer'
import { getBorrowerInfo, sendLoanProcessDocumentEmail } from 'services'
import { DocumentFile, InputFileTable, Modal, TextArea } from 'stories/components'
import { setLoanNumber } from 'utils/setLoanNumber'

import { LoanSubmissionDocument } from './types'
// import { formatTime } from 'utils'

export const LoanSendEmailDialog = ({
  onClose,
  documents: _documents,
}: {
  onClose: Function
  documents: LoanSubmissionDocument[]
}) => {
  const { loanDetail } = useSelector((state: any) => ({
    loanDetail: state.loanDetail,
    borrower: state.borrower.borrower,
  }))
  const { parties, emailContacts } = loanDetail
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ party: string; email: string }[]>([])

  const [selectedEmails, setSelectedEmails] = useState<Record<string, 'to' | 'cc' | 'bcc' | ''>>({})
  const [note, setNote] = useState('')
  const [documents, setDocuments] = useState<DocumentFile[]>([])
  const loanNumber = setLoanNumber()

  useEffect(() => {
    const documents = _documents.map((file) => ({
      ...file,
      fileKey: file.key,
      id: file.DocumentID,
      status: 'Not Reviewed',
    })) as DocumentFile[]
    setDocuments(documents)
  }, [])

  useEffect(() => {
    setLoading(true)
    getBorrowerInfo()
      .then(async ({ parties, borrowerEmail }) => {
        const emails: { party: string; email: string }[] = []
        if (borrowerEmail)
          emails.push({
            party: 'Borrower',
            email: borrowerEmail,
          })
        Object.keys(parties).forEach((key) => {
          if (key == 'accountManager') return
          let email = parties[key]
          if (!email) return

          emails.push({
            party: partiesMap[key],
            email,
          })
        })
        setData(emails)
        setSelectedEmails(
          borrowerEmail
            ? {
                [borrowerEmail]: 'to',
              }
            : {},
        )
      })
      .finally(() => setLoading(false))
  }, [])

  const onSubmit = async () => {
    const hasToEmail = !!Object.keys(selectedEmails).filter((email) => selectedEmails[email] == 'to').length
    if (!hasToEmail) {
      toast('Please choose one more TO email.', { type: 'error' })
      return
    }

    let hasError = false
    if (hasError) return

    setLoading(true)

    await sendLoanProcessDocumentEmail(selectedEmails, note, documents)

    await onClose(true, selectedEmails)
    toast('Email is sent successfully.', { type: 'success' })
    setLoading(false)
  }

  useEffect(() => {
    const emails: { party: string; email: string }[] = []
    Object.keys(parties).map((key) => {
      let { email } = parties[key]
      email = key === 'creator' ? email : loanDetail.parties[key].email
      if (!email) return

      emails.push({
        party: partiesMap[key],
        email,
      })
    })
    emails.push(
      ...emailContacts.map((email: string) => ({
        party: 'Email Notification Contacts',
        email,
      })),
    )
    emails.push({ party: 'Loan Setup', email: `loansetup@${COMPANY_DNS}` })
    emails.push({ party: 'Closer', email: `closing@${COMPANY_DNS}` })
    setData(emails)
  }, [parties, emailContacts])

  return (
    <Modal
      title={`Send Emails to ...`}
      titleOkay="Send Email"
      isOpen
      loading={loading}
      disabled={!Object.keys(selectedEmails).length || !Object.keys(documents).length || !note.trim()}
      lastUpdatedAt={Date.now()}
      onClose={() => onClose(false)}
      onOk={() => onSubmit()}
      childLevel={1}
    >
      <div className="w-144">
        <EmailTo Emails={selectedEmails} Data={data} onChange={(data: any) => setSelectedEmails(data)} />

        <div className="mt-4">
          <TextArea
            className=""
            title={'Notes'}
            key={'Notes'}
            rows={3}
            required
            value={note}
            onChange={(value: string) => setNote(value)}
          />
        </div>
        <InputFileTable
          title="Attachment Files"
          filePath={`/loan/${loanNumber}/submission`}
          showStatus={false}
          showCategory={false}
          value={documents}
          onChange={setDocuments}
        />
      </div>
    </Modal>
  )
}
