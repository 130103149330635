import { logout } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { INVALID_REQUEST } from 'config'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setLoanNumber } from 'utils/setLoanNumber'

import { CustomSignApplicationContent } from './CustomSignApplicationContent'

export const CustomSignApplication = () => {
  const urlParams: any = useParams()
  const { loanNumber, token } = urlParams
  const [validated, setValidated] = useState(false)
  const dispatch = useDispatch()
  const navigate = useHistory()

  useEffect(() => {
    setLoanNumber()

    const payload: any = jwtDecode(token)
    if (payload.loanNumber != loanNumber) {
      toast(INVALID_REQUEST, { type: 'error' })
      navigate.push('/')
      dispatch(logout())
      return
    }
    setValidated(true)
  }, [token, loanNumber])

  if (!validated) return <LayoutLoading show />

  return (
    <TemporaryLayout noOverview={true} requireSign>
      <div className="mt-6">
        <Overview hasBackButton={false} title="Loan Application" />

        <CustomSignApplicationContent />
      </div>
    </TemporaryLayout>
  )
}
