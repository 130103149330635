import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'
import { ConfirmOptionsModal } from 'stories/components'

const ConfirmOptionDialog = ({ show, proceed, promptation, options, selected }: ReactConfirmProps | any) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  return (
    <ConfirmOptionsModal
      isOpen={show}
      onOk={(result: any) => proceed(result as any)}
      onCancel={() => proceed(false as any)}
      lastUpdatedAt={lastUpdatedAt}
      title={promptation}
      options={options}
      selected={selected}
    />
  )
}

const confirmOptionDlg = confirmable(ConfirmOptionDialog)

const createConfirmOptions = createConfirmation(confirmOptionDlg)

export const confirmOptions = (content: string | JSX.Element, options: Record<string, any> = {}, selected?: number) =>
  createConfirmOptions({ promptation: content, options, selected })
