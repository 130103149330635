import {
  API_DATAVERIFY_CREDENTIAL,
  API_EXPIRATION_DATES,
  API_EXPIRATION_DATES_UPDATE,
  API_LOAN_DATAVERIFY_CONDITIONS,
  API_LOAN_ESCROW_HOLDINGBACK,
  API_LOAN_GET_DATAVERIFY,
  API_LOAN_GET_NY_TRANSACTIONS,
  API_LOAN_GET_NY_TRANSACTIONS_LOG,
  API_LOAN_GET_UNDERWRITING_SUMMARY,
  API_LOAN_GET_UNDERWRITING_SUMMARY_LOG,
  API_LOAN_SUBMIT_DATAVERIFY_LEXIS_ORDER,
  API_LOAN_SUBMIT_DATAVERIFY_SSA_ORDER,
  API_LOAN_SUBMIT_DATAVERIFY_TAX_ORDER,
  API_LOAN_UPDATE_DATAVERIFY_SSA_STATUS,
  API_LOAN_UPDATE_DATAVERIFY_TAX_STATUS,
  API_LOAN_UPDATE_NY_TRANSACTIONS,
  API_LOAN_UPDATE_UNDERWRITING_SUMMARY,
} from 'config'
import Api from 'services/api'

import { downloadFile, fileJsonConvert } from '../documents'

/**
 * Data Verify
 */
export const getLoanDataVerify = (type: string) => {
  return Api.get(API_LOAN_GET_DATAVERIFY, {}, { type })
}

export const updateDataverifySSAStatus = (orderID: string) => {
  return Api.get(API_LOAN_UPDATE_DATAVERIFY_SSA_STATUS, {}, { orderID })
}

export const updateDataverifyTaxStatus = (orderID: string) => {
  return Api.get(API_LOAN_UPDATE_DATAVERIFY_TAX_STATUS, {}, { orderID })
}

export const getDataVerifyCredential = () => {
  return Api.get(API_DATAVERIFY_CREDENTIAL)
}

export const submitDataVerifyCredential = (data: Record<string, string>) => {
  return Api.post(API_DATAVERIFY_CREDENTIAL, data)
}

export const getLoanDataVerifyCondition = () => {
  return Api.get(API_LOAN_DATAVERIFY_CONDITIONS)
}

export const createLoanDataVerify = () => {
  return Api.post(API_LOAN_DATAVERIFY_CONDITIONS, {}, {}, { timeout: 3000000 })
}

export const updateLoanDataVerify = (data: Record<string, string>[]) => {
  return Api.put(API_LOAN_DATAVERIFY_CONDITIONS, { data }, { timeout: 3000000 })
}

export const loanSubmitDataVerifySSAOrder = (data: Record<string, string | string[] | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_LOAN_SUBMIT_DATAVERIFY_SSA_ORDER, json.data, {}, json.files)
}

export const loanSubmitDataVerifyTaxOrder = (data: Record<string, string | string[] | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_LOAN_SUBMIT_DATAVERIFY_TAX_ORDER, json.data, {}, json.files)
}

export const loanSubmitLexisOrder = (data: Record<string, any>) => {
  return Api.post(API_LOAN_SUBMIT_DATAVERIFY_LEXIS_ORDER, data)
}

export const getEscrowHoldingBack = () => {
  return Api.get(API_LOAN_ESCROW_HOLDINGBACK)
}

export const updateEscrowHoldingBack = (data: Record<string, any>) => {
  return Api.put(API_LOAN_ESCROW_HOLDINGBACK, data)
}

export const generateEscrowHoldingBackPdf = async () => {
  const response: Blob = await Api.post(
    API_LOAN_ESCROW_HOLDINGBACK,
    {},
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(`Escrow Holdingback.pdf`, response)
}

/**
 * Expiration Dates
 */

export const getExpirationDates = () => {
  return Api.get(API_EXPIRATION_DATES)
}

export const updateExpirationDates = (type: string, data: Record<string, any>) => {
  return Api.put(API_EXPIRATION_DATES_UPDATE, data, { type })
}

export const resetExpirationDate = (type: string, keys: string[]) => {
  return Api.delete(API_EXPIRATION_DATES_UPDATE, { keys }, { type })
}

/**
 * NY Transactions
 */
export const getNYTransactions = () => {
  return Api.get(API_LOAN_GET_NY_TRANSACTIONS, {})
}

export const updateNYTransactions = (data: Record<string, any>) => {
  return Api.post(API_LOAN_UPDATE_NY_TRANSACTIONS, data)
}

export const getNYTransactionsLogs = (key: string) => {
  return Api.get(API_LOAN_GET_NY_TRANSACTIONS_LOG, {}, { key })
}

/**
 * Underwriting Summary
 */
export const getUnderwritingSummary = () => {
  return Api.get(API_LOAN_GET_UNDERWRITING_SUMMARY, {})
}

export const updateUnderwritingSummary = (data: Record<string, any>) => {
  return Api.post(API_LOAN_UPDATE_UNDERWRITING_SUMMARY, data)
}

export const getUnderwritingSummaryLogs = (key: string) => {
  return Api.get(API_LOAN_GET_UNDERWRITING_SUMMARY_LOG, {}, { key })
}
