import { EyeIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { TemporaryLayout } from 'layouts'
import { useEffect, useMemo, useState } from 'react'
import { getConstructionLedger, openS3Document } from 'services'
import type { DocumentFile } from 'stories/components'
import { setLoanNumber } from 'utils/setLoanNumber'

const TrustLedgerLables: Record<string, string> = {
  // reference: 'Reference',
  // fromWhom: 'From Whom Received/Paid',
  eligibleAmount: 'Eligible Amount',
  netEligibleAmount: 'Net Eligible Amount',
  recommendedDisbursement: 'Max Eligible Disbursement',
  previouslyDisbursed: 'Previously Disbursed',
  payment: 'Draw Issued',
  // deposit: 'Deposit',
  fee: 'Fee',
  balance: 'Construction Balance',
  date: 'Date',
}

export const BorrowerConstructionLedger = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])

  useEffect(() => {
    setLoanNumber()
    init()
  }, [])

  const init = async () => {
    setLoading(true)
    const res = await getConstructionLedger()

    setData(res.data)
    setLoading(false)
  }

  const renderTable = useMemo(() => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-sm">
        {data.map((item, index) => {
          const files: DocumentFile[] = item.files

          return (
            <div key={index} className="rounded px-2 py-1 shadow1">
              <div className="flex justify-between items-center p-1">
                <span className="text-desc">Draw No</span>
                <span className="text-sm">{index + 1}</span>
              </div>

              {Object.keys(item).map((key, idx) => {
                if (TrustLedgerLables[key])
                  return (
                    <div
                      key={`${index}-${idx}`}
                      className={`flex justify-between items-center rounded-sm px-1 py-1.5 ${
                        idx % 2 === 0 ? 'bg-slate-100' : ''
                      }`}
                    >
                      <span className="text-desc">{TrustLedgerLables[key]}</span>
                      <span className="text-xs">{item[key]}</span>
                    </div>
                  )
              })}

              {!!files && !!files.length && (
                <>
                  <div className="border-b text-sm p-1">Files ({files.length})</div>

                  <div className="grid grid-cols-7 gap-2 w-full rounded-sm bg-slate-100 p-1">
                    <p className="text-desc">No</p>
                    <p className="text-desc col-span-5">File Name</p>
                  </div>
                  {files.map((doc: DocumentFile, id: number) => (
                    <div
                      key={`${index}-${id}`}
                      className={`grid grid-cols-7 gap-2 items-center w-full rounded-sm p-1 ${
                        id % 2 !== 0 ? 'bg-slate-100' : ''
                      }`}
                    >
                      <p className="text-xs">{id + 1}</p>
                      <p className="text-xs col-span-5 truncate">{doc.name}</p>
                      <div className="flex justify-center">
                        <button
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                          onClick={() => openS3Document(doc.fileKey)}
                        >
                          <EyeIcon className="w-3.5 h-3.5" />
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )
        })}
      </div>
    )
  }, [data])

  return (
    <TemporaryLayout title="Construction Ledger" requireSign>
      <LayoutLoading show={isLoading} />

      <div>{renderTable}</div>
    </TemporaryLayout>
  )
}
