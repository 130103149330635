import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { setBorrowerGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { COMPANY_TITLE, pullCreditReportLimitDays } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import type { ILoanDetail } from 'pages/LoanStructure/interfaces'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  borrowerInfoUpdate,
  downloadS3Documents,
  getCreditRequestHistory,
  pullCreditReport,
  uploadFiles,
} from 'services'
import { Button, Select } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { canEditCreditScore, confirm, dayToMSec, formatTime, InputConvert, InputValidate, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { pullScoreValidation } from '../logic'
import { ConfirmCreditReportDialog } from './ConfirmCreditReportDialog'
import { BorrowerSeperator, CreditRequestType, defaultInputs } from './constants'
import { creditValidate, getPriceScoreOptions } from './logic'

export function CreditScore(props: any) {
  const { borrowerSeperator } = props
  const [inputs, setInputs] = useState(defaultInputs())
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('')
  const [isConfirmReportDialog, setConfirmReportDialog] = useState(false)
  const [creditScoreHistory, setCreditScoreHistory] = useState<Record<string, string>[]>([])
  const { data: permissionData, hasPermission } = usePermissions()

  const dispatch = useDispatch()
  const { borrower, loanDetail } = useSelector((state: any) => {
    return {
      borrower: state.borrower,
      loanDetail: state.loanDetail as ILoanDetail,
    }
  })
  const loanNumber = setLoanNumber()
  const canUpdatePriceScore = hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE')
  const adminProfile = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

  const showPriceScoreField = useMemo(() => {
    return canUpdatePriceScore && borrowerSeperator === BorrowerSeperator.BORROWER
  }, [canUpdatePriceScore, borrowerSeperator])

  const priceScoreOptions = useMemo(() => {
    return getPriceScoreOptions()
  }, [borrower, loanDetail])

  useEffect(() => {
    creditValidate(borrowerSeperator, true)
  }, [inputs])

  useEffect(() => {
    let newInputs = cloneDeep(inputs)
    Object.keys(inputs).map((key) => {
      const error = InputValidate({ ...inputs[key], value: borrower[borrowerSeperator][key] })
      newInputs[key].error = error
    })
    setInputs(newInputs)
  }, [borrowerSeperator])

  useEffect(() => props.setLoading(loading), [loading])

  useEffect(() => {
    loadCreditRequestHistory()
  }, [])

  const creditScoreHistoryData = useMemo(() => {
    return creditScoreHistory.map((item, index) => {
      const { firstName, middleName, lastName, ssn, presentAddress, emailBy, comment, createdAt } = item
      return [
        `${index + 1}`,
        [firstName, middleName, lastName].join(' '),
        ssn,
        presentAddress,
        comment,
        <div>
          <p>{emailBy}</p>
          <p>{formatTime(createdAt)}</p>
        </div>,
      ]
    })
  }, [creditScoreHistory])

  const requestType = useMemo<CreditRequestType>(() => borrower[borrowerSeperator].creditRequestType, [borrower])

  const creditRequestHeader = useMemo(() => {
    const rlt = ['Equifax Score', 'Experian Score', 'Trans Union Score']

    if (adminProfile) rlt.push('Pdf')
    if (showPriceScoreField) rlt.push('Price Score')

    return rlt
  }, [showPriceScoreField, adminProfile])

  const creditRequestData = useMemo(() => {
    const priceScoreKey = 'priceScore'

    const rlt = [
      borrower[borrowerSeperator].equifax,
      borrower[borrowerSeperator].experian,
      borrower[borrowerSeperator].transunion,
    ]

    if (adminProfile) {
      rlt.push(
        borrower[borrowerSeperator].creditFileKey ? (
          <button
            className="text-gray-700 cursor-pointer flex items-center hover:underline"
            onClick={() => onOpenDocument()}
          >
            <span className="flex items-center flex-wrap gap-1 text-shade-blue">
              <span className="cursor-pointer hover:underline text-[14px]">Score PDF</span>
              <ArrowDownTrayIcon className="w-5 h-5" />
            </span>
          </button>
        ) : (
          ''
        ),
      )
    }

    if (showPriceScoreField)
      rlt.push(
        <Select
          id={priceScoreKey}
          name={priceScoreKey}
          options={priceScoreOptions}
          key={priceScoreKey}
          value={borrower[BorrowerSeperator.BORROWER].priceScore}
          hasDefaultOption={true}
          readOnly={!canUpdatePriceScore}
          history={true}
          className="mb-[-16px] w-fit"
          onChange={(value) => onChange(priceScoreKey, value)}
          onBlur={() => onBlur(priceScoreKey)}
          showHistory={() => {
            if (canUpdatePriceScore) showHistory(priceScoreKey)
          }}
        />,
      )

    return rlt
  }, [borrower, showPriceScoreField, priceScoreOptions, adminProfile])

  const confirmReportDlgData = useMemo(() => {
    return [
      borrowerSeperator === 'borrower' ? 'Borrower' : 'CoBorrower',
      [
        borrower[borrowerSeperator].firstName,
        borrower[borrowerSeperator].middleName,
        borrower[borrowerSeperator].lastName,
      ].join(' '),
      borrower[borrowerSeperator].ssn,
      borrower[borrowerSeperator].presentAddress,
    ]
  }, [borrower])

  const validations = useMemo(() => {
    const validations = pullScoreValidation(borrowerSeperator)

    if (hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE') || !creditScoreHistory.length) return validations

    let lastPullScoreDate = null
    creditScoreHistory.map((item: any) => {
      if (item.comment === 'Pull Credit Report') lastPullScoreDate = item.createdAt
    })

    if (lastPullScoreDate) {
      const timeDiff = new Date().getTime() - new Date(lastPullScoreDate).getTime()
      const limit = dayToMSec(pullCreditReportLimitDays)
      if (timeDiff < limit)
        validations.push(
          `- You are eligible to request a new credit report ${pullCreditReportLimitDays} days after your last request.`,
        )
    }
    return validations
  }, [borrowerSeperator, creditScoreHistory, permissionData])

  const resetValues = async () => {
    const data = {
      experian: '',
      transunion: '',
      equifax: '',
      creditFileKey: '',
    }
    await dispatch(setBorrowerGroupData(borrowerSeperator, data))

    const reqBody = {
      ...data,
      borrowerSeperator,
    }
    await borrowerInfoUpdate(reqBody)
  }

  const loadCreditRequestHistory = async () => {
    setLoading(true)
    const history = await getCreditRequestHistory(borrowerSeperator)
    setCreditScoreHistory(history)
    setLoading(false)
  }

  const onChangeFile = (key: string, file: File) => {
    if (!file) return

    const data = {
      path: `loan/${loanNumber}/credit_score_${borrowerSeperator}`,
    }

    setLoading(true)
    uploadFiles(data, [file]).then(async (keys: Array<string>) => {
      let newInputs = cloneDeep(inputs)
      const creditFileKey = keys[0]

      await dispatch(setBorrowerGroupData(borrowerSeperator, { [key]: creditFileKey }))

      const reqBody = {
        [key]: creditFileKey,
        borrowerSeperator,
      }
      await borrowerInfoUpdate(reqBody)

      const error = InputValidate({ ...inputs[key], creditFileKey })
      newInputs[key].error = error
      setInputs(newInputs)
      setLoading(false)
    })
  }

  const onChange = async (key: string, value: any) => {
    if (key === 'creditRequestType') {
      if (value === CreditRequestType.OnHand && borrower[borrowerSeperator].creditFileKey) {
        const result = await confirm(
          'You have already pulled your credit score. After you change this, you will lose the pulled information. Are you sure you want to continue?',
        )
        if (!result) return
      }
      resetValues()
    }
    let newInputs = cloneDeep(inputs)
    setFieldValueChanged(true)
    value = InputConvert(newInputs[key], value)
    await dispatch(setBorrowerGroupData(borrowerSeperator, { [key]: value }))

    const error = InputValidate({ ...inputs[key], value })
    newInputs[key].error = error
    setInputs(newInputs)
  }

  const onBlur = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = inputs[key]
    if (input.error && input.error.length > 0) return
    setLoading(true)
    const reqBody = {
      [key]: borrower[borrowerSeperator][key],
      borrowerSeperator,
    }
    await borrowerInfoUpdate(reqBody)
    setLoading(false)
  }

  const showHistory = async (key: string) => {
    const options = {
      table: 'Borrower',
      field: `${borrowerSeperator}-${key}`,
      keys: {
        field: key,
        borrowerSeperator,
      },
    }
    openAuditLog(options)
  }

  const onPullReport = () => {
    setConfirmReportDialog(true)
  }

  const onConfirmPullReport = (method: string, callback: Function) => {
    setAction('onPullReport')
    pullCreditReport(method, borrowerSeperator)
      .then(async ({ success, pullCreditScoreLevel, data }) => {
        if (!success && pullCreditScoreLevel !== undefined) return callback(pullCreditScoreLevel)

        if (!data) return callback(false)
        await dispatch(setBorrowerGroupData(borrowerSeperator, data))

        let newInputs = cloneDeep(inputs)
        Object.keys(data).forEach((key) => {
          const error = InputValidate({ ...inputs[key], value: data[key] })
          newInputs[key].error = error
        })
        setInputs(newInputs)
        callback(true)
      })
      .catch(() => callback(false))
      .finally(async () => {
        await loadCreditRequestHistory()
        setLoading(false)
        setAction('')
      })
  }

  const renderRequestType = () => {
    let input = inputs.creditRequestType
    if (borrower[borrowerSeperator].creditRequestType !== undefined) {
      input.value = borrower[borrowerSeperator].creditRequestType
    } else {
      input.value = ''
    }
    input.history = true
    input.disabled = !canEdit
    return (
      <div className="input md:col-span-1 mb-4">
        <RenderInput
          input={input}
          Key={'creditRequestType'}
          onChange={onChange}
          showHistory={showHistory}
          onBlur={onBlur}
        />
      </div>
    )
  }

  const onOpenDocument = async () => {
    const { creditFileKey } = borrower[borrowerSeperator]
    if (!creditFileKey) return
    downloadS3Documents(creditFileKey).then((res) => {
      var windowReference: any = window.open()
      windowReference.location = res.url
    })
  }

  const renderCreditInformation = () => {
    return (
      <div className="mb-4 p-4 rounded shadow border">
        <p className="text-md font-bold border-b mb-2">Credit Information</p>

        <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
          {(() => {
            const input = inputs.creditFileKey
            if (borrower[borrowerSeperator].creditFileKey !== undefined) {
              input.value = borrower[borrowerSeperator].creditFileKey
            } else {
              input.value = ''
            }
            input.history = true
            input.disabled = !canEdit
            return (
              <div className="input md:col-span-2">
                <RenderInput
                  input={input}
                  Key={'creditFileKey'}
                  onChange={onChangeFile}
                  showHistory={showHistory}
                  onBlur={onBlur}
                />
              </div>
            )
          })()}

          {borrower[borrowerSeperator].creditFileKey &&
            ['equifax', 'experian', 'transunion'].map((key: string) => {
              const input = inputs[key]
              if (borrower[borrowerSeperator][key] !== undefined) {
                input.value = borrower[borrowerSeperator][key]
              } else {
                input.value = ''
              }
              input.history = true
              input.disabled = !canEdit
              return (
                <div className={`input md:col-span-${input.span || 1}`} key={key}>
                  <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} onBlur={onBlur} />
                </div>
              )
            })}
        </div>
      </div>
    )
  }

  const renderValidation = () => {
    if (validations.length) {
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[14px]"
          role="alert"
        >
          {validations.map((item: string, index: number) => {
            return <div key={index}>{item}</div>
          })}
        </div>
      )
    }
  }

  const canEdit = canEditCreditScore()

  return (
    <div>
      <LayoutLoading show={loading} />
      <div className="text-[15px] mb-4">
        <p className="mb-2 p-2">
          - As part of your application for a loan with {COMPANY_TITLE}, we request your authorization to pull your
          credit report. You understand that by applying for a {COMPANY_TITLE} loan and clicking on the appropriate
          disclosure, you are providing "written instructions" to {COMPANY_TITLE} under the Fair Credit Reporting Act
          authorizing
          {COMPANY_TITLE} to obtain information from your personal credit profile or other information from one or more
          consumer reporting agencies.
        </p>
        <p className="mb-2 p-2 bg-slate-100 rounded">
          - You authorize {COMPANY_TITLE} to verify information in your application, and you agree that {COMPANY_TITLE}{' '}
          may contact third parties to verify any such information. {COMPANY_TITLE} may use your consumer reports to
          authenticate your identity, to make credit decisions, and for related purposes. You authorize {COMPANY_TITLE}{' '}
          to obtain consumer reports each time you request a loan, during the processing or closing of a loan to you, or
          at various times during the term of your loan in connection with the servicing, monitoring, collection or
          enforcement of the loan.
        </p>
        <p className="mb-2 p-2">
          - You further acknowledge that {COMPANY_TITLE} may report credit data on your loan to credit reporting
          agencies.
          {COMPANY_TITLE} agrees that any information furnished will be accurate. You understand that, if you are
          applying for a loan with multiple payments, the credit pull and reporting on your loan may affect your credit
          score with Experian, Equifax, or Transunion. - Please note that if your fico score is different than the fico
          score estimate by your loan broker, the terms of your loan may change.
        </p>
      </div>

      <PlainTable
        classNames={['w-1/5', 'w-1/5', 'w-1/5', 'w-1/5']}
        header={creditRequestHeader}
        data={[creditRequestData]}
      />

      <p className="text-md font-bold border-b mb-2">Credit Request Type</p>
      {renderRequestType()}

      {requestType == CreditRequestType.Universal && (
        <div className="p-4 rounded shadow border mb-4">
          {renderValidation()}
          <Button
            className="-mb-4"
            disabled={validations.length !== 0 || canEdit !== true || loanDetail.loanStatus === 'withdrawn'}
            onClick={onPullReport}
            loading={action === 'onPullReport'}
          >
            Pull My Credit Report
          </Button>
        </div>
      )}

      {requestType == CreditRequestType.OnHand && renderCreditInformation()}

      {!!creditScoreHistory.length && (
        <div className="p-4 pb-0 rounded shadow border">
          <p className="text-md font-bold border-b mb-2">Credit Report Request History</p>
          <PlainTable
            header={['No', 'Name', 'SSN', 'Present Address', 'Comment', 'By / Created At']}
            data={creditScoreHistoryData}
            thClass="!px-2 py-3"
            tdClass={'px-2 py-4'}
          />
        </div>
      )}

      {isConfirmReportDialog && (
        <ConfirmCreditReportDialog
          refId={`${loanNumber}-borrower-${borrowerSeperator}`}
          onClose={() => setConfirmReportDialog(false)}
          onConfirm={onConfirmPullReport}
          data={confirmReportDlgData}
          paymentData={{
            userType: 'borrower',
            borrowerSeperator,
            name: confirmReportDlgData[1],
            ssn: confirmReportDlgData[2],
            address: confirmReportDlgData[3],
          }}
          showAlert={!hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE')}
        />
      )}
    </div>
  )
}
