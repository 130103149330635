import { InputType, loanOptionsConstants } from 'config'
import { fullStates } from 'config/states.constants'

export const inputGroups: any = {
  'General Info': [
    'Company',
    'FirstName',
    'MiddleName',
    'LastName',
    'Title',
    'Street',
    'City',
    'State',
    'Zip',
    'LicenseNo',
    'CompanyLicenseNo',
    'BranchID',
  ],
  'Contact Info': ['HomePhone', 'OtherPhone', 'Pager', 'WorkPhone', 'MobilePhone', 'Fax', 'EMail', 'EMail2', 'EMail3'],
  'Tax ID / NMLS': ['CompanyEIN', 'CompanyNMLSID', 'ContactNMLSID', 'Notes'],
}

export const defaultValuesIfEmpty: Record<string, any> = {
  Appraiser: {
    Title: 'Appraiser',
  },
}

export const extraInputsOnGroup: Record<string, any> = {
  Lender: {
    'General Info': {
      WebsiteUrl: 1,
      NickName: 5,
    },
  },
  Servicer: {
    'General Info': {
      WebsiteUrl: 1,
    },
  },
}

export const extraFieldGroups: Record<string, any> = {
  Lender: {
    'Entity Information': ['EntityState', 'EntityType'],
  },
  Contractor: {
    'Entity Information': ['EntityState', 'EntityType'],
  },
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    Company: {
      inputType: 'text',
      type: 'text',
      title: 'Company Name',
      value: '',
      required: true,
    },
    WebsiteUrl: {
      inputType: 'text',
      type: 'text',
      title: 'Company Website Url',
      value: '',
      required: true,
    },
    FirstName: {
      inputType: 'text',
      type: 'text',
      title: 'First Name',
      value: '',
      required: true,
    },
    MiddleName: {
      inputType: 'text',
      type: 'text',
      title: 'Middle Name',
      value: '',
    },
    LastName: {
      inputType: 'text',
      type: 'text',
      title: 'Last Name',
      value: '',
      required: true,
    },
    NickName: {
      inputType: 'text',
      type: 'text',
      title: 'Nick Name',
      value: '',
    },
    Title: {
      inputType: 'text',
      type: 'text',
      title: 'Title',
      value: '',
      required: true,
    },
    Street: {
      inputType: 'text',
      title: 'Street',
      value: '',
      required: true,
    },
    City: {
      inputType: 'text',
      type: 'text',
      title: 'City',
      value: '',
      required: true,
    },
    State: {
      inputType: 'Select',
      title: 'State',
      value: '',
      options: fullStates,
      hasDefaultOption: true,
      required: true,
    },
    Zip: {
      inputType: 'text',
      type: 'number',
      title: 'Zip',
      value: '',
      required: true,
    },
    LicenseNo: {
      inputType: 'text',
      type: 'text',
      title: 'License No',
      value: '',
    },
    CompanyLicenseNo: {
      inputType: 'text',
      type: 'text',
      title: 'Company License No',
      value: '',
    },
    BranchID: {
      inputType: 'text',
      type: 'text',
      title: 'Branch',
      value: '',
    },

    HomePhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Home Phone',
      value: '',
    },
    OtherPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Other Phone',
      value: '',
    },
    Pager: {
      inputType: 'text',
      type: 'text',
      title: 'Pager',
      value: '',
    },
    WorkPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Work Phone',
      value: '',
      required: true,
    },
    MobilePhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Mobile Phone',
      value: '',
    },
    Fax: {
      inputType: 'text',
      type: 'text',
      title: 'Fax',
      value: '',
    },
    EMail: {
      inputType: 'text',
      type: 'email',
      title: 'E-Mail 1',
      value: '',
      required: true,
    },
    EMail2: {
      inputType: 'text',
      type: 'email',
      title: 'E-Mail 2',
      value: '',
    },
    EMail3: {
      inputType: 'text',
      type: 'email',
      title: 'E-Mail 3',
      value: '',
    },
    CompanyEIN: {
      inputType: 'text',
      type: 'entityTaxID',
      title: 'Tax ID / EIN',
      value: '',
    },
    CompanyNMLSID: {
      inputType: 'text',
      type: 'text',
      title: 'Company NMLS ID',
      value: '',
    },
    ContactNMLSID: {
      inputType: 'text',
      type: 'text',
      title: 'Contact NMLS ID',
      value: '',
    },
    Notes: {
      inputType: 'textarea',
      type: 'text',
      title: 'Notes',
      value: '',
      span: 3,
    },
    EntityState: {
      inputType: 'Select',
      title: 'Entity State',
      value: '',
      options: fullStates,
      required: true,
      hasDefaultOption: true,
    },
    EntityType: {
      inputType: 'Select',
      title: 'Entity Type',
      value: '',
      options: loanOptionsConstants.entityType,
      required: true,
      hasDefaultOption: true,
    },
  }
}
