import cloneDeep from 'clone-deep'
import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addNewTask, updateTask } from 'services'
import { Modal } from 'stories/components'
import { formatDateYMD, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { Task } from '../constants'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan Number',
      value: '',
      required: true,
    },
    description: {
      inputType: 'text',
      type: 'text',
      title: 'Description',
      value: '',
      required: true,
    },
    assignedTo: {
      inputType: 'InputWithButton',
      type: 'text',
      title: 'Assigned To',
      value: '',
      required: true,
      modalTitle: 'Assigned Task to...',
      options: accountTypes,
    },
    assignedToManager: {
      inputType: 'InputWithButton',
      type: 'text',
      title: 'Assigned To Manager',
      value: '',
      required: false,
      modalTitle: 'Assigned Task to Manager...',
      options: accountTypes,
    },
    dueDate: {
      inputType: 'text',
      type: 'date',
      title: 'Due Date',
      value: '',
      required: true,
    },
    note: {
      inputType: 'textarea',
      type: 'text',
      title: 'Note',
      value: '',
      span: 2,
      required: false,
    },
    files: {
      inputType: 'filetable',
      filePath: 'task/new',
      title: 'Files',
      span: 2,
      required: false,
      showStatus: false,
      showCategory: false,
    },
  }
}

export const assignToRoles = accountTypes

export const AddTaskModal = (props: {
  assignedToList: Record<string, string>
  onClose: any
  projectId: number
  loanNumber?: number
  byteproFileName?: number
  task: Task | null
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    const { task } = props
    if (!task) {
      if (props.loanNumber) {
        let newInputs = cloneDeep(inputs)
        newInputs.loanNumber.value = props.loanNumber
        if (props.byteproFileName && props.byteproFileName > 0) newInputs.loanNumber.value = props.byteproFileName
        newInputs.loanNumber.disabled = true
        ;(newInputs.files as any).filePath = `task/${props.loanNumber}`
        setInputs(newInputs)
      }
      return
    }

    let newInputs = cloneDeep(inputs)
    newInputs.loanNumber.value = task.loanNumber
    if (task.byteproFileName > 0) newInputs.loanNumber.value = task.byteproFileName
    newInputs.description.value = task.description
    newInputs.assignedTo.value = task.assignedTo.map((item: any) => item.value)
    newInputs.assignedToManager.value = task.assignedToManager.map((item: any) => item.value)
    newInputs.dueDate.value = formatDateYMD(task.dueDate)
    newInputs.note.value = task.note
    newInputs.loanNumber.disabled = true
    ;(newInputs.files as any).filePath = `task/${task.loanNumber}`
    newInputs.files.value = task.files
    setInputs(newInputs)
  }, [props.task])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    data.projectId = props.projectId
    data.dueDate = formatDateYMD(data.dueDate)

    setLoading(true)

    if (props.task) {
      updateTask(props.task.id, data)
        .then(() => {
          props.onClose(true)
        })
        .catch(() => setLoading(false))
    } else
      addNewTask(data)
        .then(() => {
          toast('A new task is added', { type: 'info' })
          props.onClose(true)
        })
        .catch(() => setLoading(false))
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      title={!props.task ? 'Add Task' : `Task Edit - ${props.task.no}`}
      titleOkay={props.task ? 'Update' : 'Save'}
      loading={loading}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          if (input.inputType == 'InputWithButton') input.options = props.assignedToList

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
