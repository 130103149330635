import cloneDeep from 'clone-deep'
import { DataVerifyTaxAvailableYears, InputType } from 'config'
import { useMemo, useState } from 'react'
import { loanSubmitDataVerifyTaxOrder } from 'services'
import { Checkbox, Modal } from 'stories/components'
import { formatDate, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const TaxFormTypes = ['1040', '1065', '1099', '1120/1120S', 'W2']
const requireBuzInformationTypes = ['1065', '1120/1120S']

const BuzInforInputs = (): Record<string, InputType> => ({
  title: {
    inputType: 'section',
    title: 'Business Information',
  },
  name: {
    inputType: 'text',
    type: 'text',
    title: 'Business Name',
    required: true,
  },
  tax: {
    inputType: 'text',
    type: 'text',
    title: 'Tax ID',
    required: true,
  },
})

const FileInputs = (): Record<string, InputType> => ({
  form: {
    inputType: 'file',
    title: '4506C Form Upload',
    required: true,
    acceptFileTypes: 'application/pdf',
  },
  sign: {
    inputType: 'file',
    title: 'eSign Certificate Upload',
    required: true,
    acceptFileTypes: 'application/pdf',
  },
})

export const TaxRequestDialog = ({
  borrower,
  onSubmit: _onSubmit,
  onClose,
}: {
  borrower: Record<string, any>
  onClose: Function
  onSubmit: Function
}) => {
  const [action, setAction] = useState('')
  const [formType, setFormType] = useState<string | null>(null)
  const [years, setYears] = useState<Record<number, boolean>>({})
  const [buzInforInputs, setBuzInfoInputs] = useState<Record<string, InputType>>(BuzInforInputs())
  const [isESignSeparateFile, setESignSeparateFile] = useState(false)
  const [fileInputs, setFileInputs] = useState<Record<string, InputType>>(FileInputs())

  const requireBuzInfo = useMemo(() => formType && requireBuzInformationTypes.includes(formType), [formType])

  const getChecked = (type: string, year: number) => {
    if (formType != type) return false
    return !!years[year]
  }

  const onCheckChanged = (type: string, year: number, value: boolean) => {
    let newYears = cloneDeep(years)
    if (formType != type) {
      newYears = {}
      setBuzInfoInputs(BuzInforInputs())
    }

    if (!value) delete newYears[year]
    else newYears[year] = value

    if (Object.keys(newYears).length == 0) setFormType(null)
    else setFormType(type)
    setYears(newYears)
  }

  const onChangeBuz = (key: string, value: string | boolean) => {
    let newState = cloneDeep(buzInforInputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setBuzInfoInputs(newState)
  }

  const onChangeFile = (key: string, value: File) => {
    let newState = cloneDeep(fileInputs)
    newState[key].value = value
    newState[key].error = ''
    setFileInputs(newState)
  }

  const onSubmit = async () => {
    if (!formType) return
    if (!Object.keys(years).length) return

    const buzInfo: Record<string, any> = {}
    if (requireBuzInfo) {
      let hasError = false
      const newStats = cloneDeep(buzInforInputs)
      for (const key in newStats) {
        const { value, disabled = false } = newStats[key]
        let error = InputValidate(newStats[key])
        newStats[key].error = error
        if (error.length > 0) hasError = true

        if (!disabled && value !== undefined) buzInfo[key] = value
      }
      if (hasError) {
        setBuzInfoInputs(newStats)
        return
      }
    }

    let hasError = false
    const newStats = cloneDeep(fileInputs)
    if (newStats.form.value == null) {
      hasError = true
      newStats.form.error = 'Required'
    }
    if (isESignSeparateFile && newStats.sign.value == null) {
      hasError = true
      newStats.sign.error = 'Required'
    }
    if (hasError) {
      setFileInputs(newStats)
      return
    }

    setAction('Submit')
    const json: Record<string, any> = {
      ...borrower,
      formType,
      years: JSON.stringify(Object.keys(years)),
      buzInfo: JSON.stringify(buzInfo),
      formFile: fileInputs.form.value,
      signFile: isESignSeparateFile ? fileInputs.sign.value : null,
    }

    const res: any = await loanSubmitDataVerifyTaxOrder(json)
    if (res.success) {
      _onSubmit(res.data)
      onClose()
    }
    setAction('')
  }

  return (
    <Modal
      title={'4506 Order Form Request'}
      titleOkay={'Submit'}
      disabled={!formType || !Object.keys(years).length}
      loading={action === 'Submit'}
      onClose={() => onClose()}
      onOk={() => onSubmit()}
      isOpen
    >
      <div className="w-144">
        <div className="overflow-auto shadow-md sm:rounded-lg mb-4">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-2">
                  Type
                </th>
                <th scope="col" className="px-6 py-2">
                  Name
                </th>
                <th scope="col" className="px-6 py-2">
                  SSN
                </th>
                <th scope="col" className="px-6 py-2">
                  Date of Birth
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-2 capitalize">{borrower.type}</td>
                <td className="px-6 py-2">{[borrower.firstName, borrower.lastName].join(' ')}</td>
                <td className="px-6 py-2">{borrower.ssn}</td>
                <td className="px-6 py-2">{formatDate(borrower.dob)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="overflow-auto shadow-md sm:rounded-lg mb-4">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-2">
                  Form Types
                </th>
                <th scope="col" className="px-6 py-2">
                  Available Years
                </th>
              </tr>
            </thead>
            <tbody>
              {TaxFormTypes.map((formType, index) => (
                <tr key={formType} className={`${index % 2 && 'bg-slate-50'}`}>
                  <td className="px-6 py-1 capitalize">{formType}</td>
                  <td className="px-6 py-1 flex">
                    {DataVerifyTaxAvailableYears.map((year) => (
                      <Checkbox
                        id={`${formType}-${year}`}
                        title={`${year}`}
                        key={`${formType}-${year}`}
                        checked={getChecked(formType, year)}
                        onChange={(v) => onCheckChanged(formType, year, v)}
                      />
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {requireBuzInfo && (
          <div className="mb-2">
            {Object.keys(buzInforInputs).map((key: any) => {
              const input = buzInforInputs[key]
              return (
                <div className={`input mb-2`} key={key}>
                  <RenderInput input={input} Key={key} onChange={onChangeBuz} />
                </div>
              )
            })}
          </div>
        )}

        <div className="mt-4">
          <Checkbox
            id="eSignSeparateFile"
            title="eSign Certificate in a separate file?"
            checked={isESignSeparateFile}
            onChange={(v) => setESignSeparateFile(v)}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-2">
          <RenderInput input={fileInputs['form']} Key={'form'} onChange={onChangeFile} />
          {isESignSeparateFile && <RenderInput input={fileInputs['sign']} Key={'sign'} onChange={onChangeFile} />}
        </div>
      </div>
    </Modal>
  )
}
