import { PlainTable } from 'stories/components'

export const accountingDashboardData: Record<string, string> = {
  Income: 'credit',
  Expense: 'debit',
  Assets: 'debit',
  Liability: 'credit',
  Equity: 'credit',
  Distribution: 'debit',
}

export const AccountingDashboard = () => {
  return (
    <div>
      <div className="flex flex-wrap gap-3 mb-3 items-center justify-between">
        <h1 className="text-2xl font-bold flex items-center pb-5">Accounting Dashboard</h1>
      </div>
      <div className="relative overflow-auto">
        <PlainTable
          header={['No', 'Key', 'Value']}
          widthClass="w-fit"
          tdClass="px-6 py-4 whitespace-nowrap capitalize"
          data={Object.keys(accountingDashboardData).map((key, index) => [
            index + 1,
            key,
            accountingDashboardData[key],
          ])}
        />
      </div>
    </div>
  )
}
