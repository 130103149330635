import type { InputType } from 'config'

export const DeliveryMethods: Record<string, string> = {
  '1': 'In Person',
  '2': 'Mailed',
  '3': 'Emailed',
  '4': 'Faxed',
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    appraisedValue: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Appraised Value',
      prefix: '$',
      value: '',
    },
    company: {
      inputType: 'text',
      type: 'text',
      title: 'Company',
      value: '',
    },
    firstName: {
      inputType: 'text',
      type: 'text',
      title: 'First Name',
      value: '',
    },
    lastName: {
      inputType: 'text',
      type: 'text',
      title: 'Last Name',
      value: '',
    },
    licenseNo: {
      inputType: 'text',
      type: 'text',
      title: 'License No',
      value: '',
    },
    orderedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Ordered Date',
      value: '',
    },
    receivedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Received Date',
      value: '',
    },
    completedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Completed Date',
      value: '',
    },
    deliveredDate: {
      inputType: 'text',
      type: 'date',
      title: 'Delivered Date',
      value: '',
    },
    deliveryMethod: {
      inputType: 'select',
      options: DeliveryMethods,
      title: 'Delivery Method',
      hasDefaultOption: true,
      value: '',
    },
    receivedByBorrower: {
      inputType: 'text',
      type: 'date',
      title: 'Received by Borrower',
      value: '',
    },
    timingWaiverDate: {
      inputType: 'text',
      type: 'date',
      title: 'Timing Waiver Date',
      value: '',
    },
  }
}
