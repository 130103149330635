import type React from 'react'

interface TextHighlighterProps {
  searchText: string
  text: string
}

export const TextHighlighter: React.FC<TextHighlighterProps> = ({ searchText, text }) => {
  const searchTextLower = searchText.toLowerCase()
  const textLower = text.toLowerCase()

  const parts = textLower.split(new RegExp(`(${searchTextLower})`, 'g'))
  let pos = 0
  return (
    <div className="text-container">
      {parts.map((part, index) => {
        const originText = text.substring(pos, pos + part.length)
        pos += part.length
        if (part === searchTextLower) {
          return (
            <span key={index} className="bg-yellow-200">
              {originText}
            </span>
          )
        } else {
          return originText
        }
      })}
    </div>
  )
}
