import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { getPrice1or2decimal } from 'utils'

export function HUD1_1300({
  inputs,
  ignoreEror,
  isNewFeeLoading,
  renderSectionHeader,
  renderHUD1Input,
  calculateValue,
  setIsNewFeeLoading,
  setIsAddCCModalOpen,
}: {
  inputs: Record<string, any>
  ignoreEror: boolean
  isNewFeeLoading: boolean
  renderSectionHeader: Function
  renderHUD1Input: Function
  calculateValue: Function
  setIsNewFeeLoading: Function
  setIsAddCCModalOpen: Function
}) {
  return (
    <>
      {renderSectionHeader('1300. Addtional Settlement Charges')}
      {inputs['requiredService']?.map((item: any) => {
        const { key, title, valueKey } = item
        return (
          <tr>
            <td className="p-2">
              {key}. {title}
            </td>
            <td className="p-2">{getPrice1or2decimal(calculateValue(valueKey))}</td>
            <td></td>
          </tr>
        )
      })}
      {inputs['1302-1400']?.map((item: any) => {
        const { key, error } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2 flex flex-wrap justify-between gap-1" key={key}>
              {renderHUD1Input(item, key)}
            </td>
          </tr>
        )
      })}
      <tr>
        <td className="flex items-center">
          <Button
            link
            color="blue"
            onClick={() => {
              setIsNewFeeLoading(true), setIsAddCCModalOpen(true)
            }}
            disabled={isNewFeeLoading}
          >
            Add new fee
          </Button>
          {isNewFeeLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-4 h-4 text-white animate-spin" />
            </span>
          )}
        </td>
      </tr>
    </>
  )
}
