import { setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { store } from 'reducers'
import { LoanType } from 'reducers/loanDetail.reducer'

export const signAppValidate = (borrowerSeperator: string, updateStore = false) => {
  const { borrower, step, loanDetail } = store.getState()

  let success = true
  const data = borrower[borrowerSeperator]

  const agreeToSignRequired = ![LoanType.CORRESPONDENT, LoanType.TABLEFUNDER].includes(loanDetail.loanType)

  if (borrowerSeperator === 'borrower') {
    if (data.agreeToSign !== true && agreeToSignRequired) {
      success = false
    } else {
      if (data.signedDate) {
      } else {
        success = false
      }
    }
  }

  if (borrowerSeperator === 'coBorrower') {
    if (data.agreeToSign !== true && agreeToSignRequired) success = false
  }

  if (data.exempt) success = true

  if (updateStore) {
    let application = cloneDeep(step.application)
    const key = borrowerSeperator === 'borrower' ? 'sign' : 'sign2'
    application[key] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }

  return success
}
