import cloneDeep from 'clone-deep'
import { useEffect, useRef, useState } from 'react'

const InputDigit = ({
  index,
  value,
  disabled,
  error = false,
  currentIndex,
  onChange,
}: {
  index: number
  value: number | string
  disabled: boolean
  error?: boolean
  currentIndex: number
  onChange: Function
}) => {
  const ref = useRef<any>()

  useEffect(() => {
    if (index === currentIndex) ref.current.focus()
  }, [currentIndex])

  return (
    <input
      className={`border-2 ${error ? 'border-red-500' : 'border-gray-100'} w-10 h-12 text-center text-lg`}
      minLength={0}
      maxLength={1}
      type="text"
      value={value}
      ref={ref}
      disabled={disabled}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  )
}

interface InputCodeProps {
  value: string
  length?: number
  error?: boolean
  disabled?: boolean
  onChange?: Function
  onCompleted?: Function
}

export const InputCode = ({
  length = 6,
  disabled = false,
  error = false,
  onCompleted = () => {},
  ...props
}: InputCodeProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [values, setValues] = useState([...Array(length).keys()].map(() => ''))

  useEffect(() => {
    if (props.value != values.join('')) {
      const propValues = props.value.split('')
      const newValues = cloneDeep(values)
      newValues.forEach((v, index) => {
        newValues[index] = propValues[index] ? propValues[index] : ''
      })
      setValues(newValues)
      setCurrentIndex(0)
    }
  }, [props.value])

  useEffect(() => {

    props.onChange && props.onChange(values.join(''))
  }, [values])

  const onChange = (v: string, index: number) => {
    if (v == '') return
    const newValues = cloneDeep(values)
    newValues[index] = v
    setValues(newValues)
    setCurrentIndex(index + 1)

    if (index == length - 1) {
      onCompleted(newValues.join(''))
    }
  }

  return (
    <div className="input-code flex gap-4">
      {[...Array(length).keys()].map((index) => (
        <InputDigit
          value={values[index]}
          index={index}
          error={error}
          disabled={disabled}
          key={`digit-${index}`}
          currentIndex={currentIndex}
          onChange={(v: string) => onChange(v, index)}
        />
      ))}
    </div>
  )
}
