import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
    },
    insuranceCompanyName: {
      inputType: 'text',
      type: 'text',
      title: 'Insurance Company Name',
      value: '',
      error: '',
    },
    insuranceAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Insurance Company Address',
      value: '',
      error: '',
    },
    policyNumber: {
      inputType: 'text',
      type: 'text',
      title: 'Policy Number',
      value: '',
      error: '',
    },
    borrowerName: {
      inputType: 'text',
      type: 'text',
      title: 'Borrower Name',
      value: '',
      error: '',
    },
    propertyAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Property Address',
      value: '',
      error: '',
    },
    servicerName: {
      inputType: 'text',
      type: 'text',
      title: 'Servicer Name',
      value: '',
      error: '',
    },
    lenderAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Lender Address',
      value: '',
      error: '',
    },
  }
}
