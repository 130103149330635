import { LayoutLoading } from 'components/LayoutLoading'
import { calculateSingleStatistics } from 'pages/Pipeline'
import { StatusDetail } from 'pages/Pipeline/StatusDetail'
import { useEffect, useState } from 'react'
import { getLoanStatistics } from 'services'

import { BorrowerDetails } from './BorrowerDetails'

export const PipelineStatus = () => {
  const [isLoading, setLoading] = useState(false)
  const [statisticsByStatus, setStatisticsByStatus] = useState<Record<string, any> | null>(null)

  useEffect(() => {
    const json = {
      servicing: false,
      myLoans: true,
    }
    setLoading(true)

    getLoanStatistics(json)
      .then(({ byStatus, totalCount }) => {
        console.log('totalCount', totalCount)

        const dataByStatus = {
          funded: calculateSingleStatistics(byStatus, 'funded', totalCount),
          submitted: calculateSingleStatistics(byStatus, 'submitted', totalCount),
          clearToClose: calculateSingleStatistics(byStatus, 'clearToClose', totalCount),
          approved: calculateSingleStatistics(byStatus, 'approved', totalCount),
          underwriterReview: calculateSingleStatistics(byStatus, 'underwriterReview', totalCount),
          docsOut: calculateSingleStatistics(byStatus, 'docsOut', totalCount),
          readyForDocs: calculateSingleStatistics(byStatus, 'readyForDocs', totalCount),
          closed: calculateSingleStatistics(byStatus, 'closed', totalCount),
        }
        setStatisticsByStatus(dataByStatus)
      })
      .finally(() => setLoading(false))
  }, [])

  const viewStatus = (status: Array<string>) => {
    console.log(status)
  }

  return (
    <div
      className={`relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full`}
    >
      <LayoutLoading show={isLoading} />

      <p className="text-2xl font-bold">Borrower Portal</p>

      <div className="grid grid-cols-4">
        <div className="row-span-2 mt-6">
          <BorrowerDetails />
        </div>
        {statisticsByStatus && (
          <>
            <StatusDetail
              status="Submitted"
              amount={statisticsByStatus.submitted.amount}
              loans={statisticsByStatus.submitted.count}
              percent={statisticsByStatus.submitted.percent}
              color="gray"
              viewStatus={() => viewStatus(['submitted'])}
            />
            <StatusDetail
              status="Approved"
              amount={statisticsByStatus.approved.amount}
              loans={statisticsByStatus.approved.count}
              percent={statisticsByStatus.approved.percent}
              color="yellow"
              viewStatus={() => viewStatus(['approved'])}
            />
            <StatusDetail
              status="Underwriter Review"
              amount={statisticsByStatus.underwriterReview.amount}
              loans={statisticsByStatus.underwriterReview.count}
              percent={statisticsByStatus.underwriterReview.percent}
              color="red"
              viewStatus={() => viewStatus(['underwriterReview'])}
            />
            <StatusDetail
              status="Clear to Close"
              amount={statisticsByStatus.clearToClose.amount}
              loans={statisticsByStatus.clearToClose.count}
              percent={statisticsByStatus.clearToClose.percent}
              color="sky"
              viewStatus={() => viewStatus(['clearToClose'])}
            />
            <StatusDetail
              status="Ready for Docs | Docs Out | Closed"
              amount={
                statisticsByStatus.docsOut.amount +
                statisticsByStatus.readyForDocs.amount +
                statisticsByStatus.closed.amount
              }
              loans={
                statisticsByStatus.docsOut.count +
                statisticsByStatus.readyForDocs.count +
                statisticsByStatus.closed.count
              }
              percent={
                statisticsByStatus.docsOut.percent +
                statisticsByStatus.readyForDocs.percent +
                statisticsByStatus.closed.percent
              }
              color="green"
              viewStatus={() => viewStatus(['readyForDocs', 'docsOut', 'closed'])}
            />
            <StatusDetail
              status="Funded"
              amount={statisticsByStatus.funded.amount}
              loans={statisticsByStatus.funded.count}
              color="green"
              colorDeep={200}
              percent={statisticsByStatus.funded.percent}
              viewStatus={() => viewStatus(['funded'])}
            />
          </>
        )}
      </div>
    </div>
  )
}
