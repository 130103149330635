import type { InputType } from 'config'

export const plainTextCostTypeItems: string[] = [
  'z_borrowerContingency',
  'z_lenderContingency',
  'z_borrowerFinancedRehabAmount',
  'z_constructionManagementFee',
  'z_mortgagePaymentEscrows',
]

export const defaultInputs = (): Record<string, InputType> => {
  return {
    address: {
      inputType: 'map',
      title: 'Address',
      value: '',
    },
    drawType: {
      inputType: 'select',
      options: ['Advancement', 'Reimbursement', 'Advancement (Pmt Escrows Only)'],
      title: 'Draw Type',
      value: '',
      hasDefaultOption: true,
    },
    constructionBudget: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Construction Budget',
      prefix: '$',
      value: '',
      readOnly: true,
    },
    borrowerFinancedDrawType: {
      inputType: 'select',
      options: ['Not Applicable', 'Draw 00 (Borrower Expenses First)', 'Prorata (% of Rehab Cost)'],
      title: 'Borrower Financed Draw Type',
      value: '',
    },
    borrowerFinancedProRate: {
      inputType: 'text',
      type: 'text',
      title: 'Borrower Financed Pro Rate',
      value: '',
      prefix: '%',
      readOnly: true,
    },
    loanToFinancePrepaidAmounts: {
      inputType: 'select',
      options: ['True', 'False'],
      title: 'Loan To Finance Prepaid Amounts',
      value: '',
    },
    constructionManagementFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Construction Management Fee',
      prefix: '$',
      value: '',
    },
    CurrentSQFT: {
      inputType: 'text',
      type: 'number',
      title: 'Current SQFT',
      value: '',
      error: '',
    },
    AfterRehadSQFT: {
      inputType: 'text',
      type: 'number',
      title: 'After Rehab SQFT',
      value: '',
      error: '',
    },
    BedroomCount: {
      inputType: 'text',
      type: 'number',
      title: 'Bedroom Count',
      value: '',
      error: '',
    },
    BathroomCount: {
      inputType: 'text',
      type: 'number',
      title: 'Bathroom Count',
      value: '',
      error: '',
    },
    LotSize: {
      inputType: 'text',
      type: 'number',
      title: 'Lot Size',
      value: '',
      error: '',
    },
    YearBuilt: {
      inputType: 'text',
      type: 'number',
      title: 'Year Built',
      value: '',
      error: '',
    },
    narrative: {
      inputType: 'textarea',
      title: '',
      rows: 5,
      value: '',
      visible: false,
    },
  }
}
