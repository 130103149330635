import {
  API_LOAN_CONTRACTOR,
  API_LOAN_CONTRACTOR_APP_PDF,
  API_LOAN_CONTRACTOR_BY_NO,
  API_LOAN_CONTRACTOR_DOCUMENTS,
  API_LOAN_CONTRACTOR_LOG,
  API_LOAN_CONTRACTOR_MESSAGE,
  API_LOAN_CONTRACTOR_SEND_APPLICATION,
  API_LOAN_CONTRACTOR_SIGN,
  API_LOAN_CONTRACTOR_UPLOAD_DOCUMENTS,
  API_LOAN_CONTRACTOR_VALIDATE_SIGN,
} from 'config'
import Api from 'services/api'

export const getContractorDocuments = (no: number) => {
  return Api.get(API_LOAN_CONTRACTOR_DOCUMENTS, {}, { no })
}

export const getContractorApplications = () => {
  return Api.get(API_LOAN_CONTRACTOR)
}

export const getContractorApplication = (no: number) => {
  return Api.get(API_LOAN_CONTRACTOR_BY_NO, {}, { no })
}

export const createContractorApplication = (data: Record<string, any>) => {
  return Api.post(API_LOAN_CONTRACTOR, data)
}

export const updateContractorApplication = (no: number, data: Record<string, any>) => {
  return Api.put(API_LOAN_CONTRACTOR_BY_NO, data, { no })
}

export const deleteContractorApplication = (no: number) => {
  return Api.delete(API_LOAN_CONTRACTOR_BY_NO, {}, { no })
}

export const getContractorLogs = (no: number, key: string) => {
  return Api.get(API_LOAN_CONTRACTOR_LOG, {}, { no, key })
}

export const sendContractorApplication = (no: number, data: object) => {
  return Api.post(API_LOAN_CONTRACTOR_SEND_APPLICATION, data, { no })
}

export const validateContractorSignApp = (no: number, data: object) => {
  return Api.post(API_LOAN_CONTRACTOR_VALIDATE_SIGN, data, { no })
}

export const signContractorApplication = (no: number) => {
  return Api.post(API_LOAN_CONTRACTOR_SIGN, {}, { no }, { timeout: 150000 })
}

export const getContractorAppPDF = (no: number) => {
  return Api.get(API_LOAN_CONTRACTOR_APP_PDF, {}, { no })
}

export const contractorUploadedDocuments = (no: number) => {
  return Api.post(API_LOAN_CONTRACTOR_UPLOAD_DOCUMENTS, {}, { no })
}

export const sendContractorMessage = (emails: Record<string, string>, emailContent: string, no: number) => {
  return Api.post(API_LOAN_CONTRACTOR_MESSAGE, { emails, emailContent }, { no })
}
