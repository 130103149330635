import cloneDeep from 'clone-deep'
import { PlainInput2 } from 'components/PlainInput/PlainInput2'
import { productTypeOptions, transactionTypeOptions } from 'config'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Checkbox, Modal } from 'stories/components'
import { getPrice3decimal, removeComma } from 'utils'

interface IProps {
  data: {
    productType: string
    transactionType: string
    minCommitmentFee: number
  }[]
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (data: any[]) => void
}

export const MinCommitmentFeeRequiredTypes = (props: IProps) => {
  const { data, loading, isOpen, onClose, onSubmit } = props

  const [isAll, setIsAll] = useState(false)
  const [selected, setSelected] = useState<any[]>([])

  let no = 0

  useEffect(() => {
    setSelected(data)
  }, [data])

  useEffect(() => {
    if (selected.length === 8) setIsAll(true)
    else setIsAll(false)
  }, [selected])

  const onSelectAll = () => {
    const newSelected: any[] = []

    if (!isAll)
      productTypeOptions.forEach((prod) => {
        transactionTypeOptions.forEach((trans) => {
          const target = selected.find((item) => item.productType === prod && item.transactionType === trans)
          newSelected.push({
            productType: prod,
            transactionType: trans,
            minCommitmentFee: target?.minCommitmentFee || 0,
          })
        })
      })

    setSelected(newSelected)
  }

  const onSelect = (productType: string, transactionType: string) => {
    const newSelected = cloneDeep(selected)
    const index = selected.findIndex(
      (item) => item.productType === productType && item.transactionType === transactionType,
    )

    if (index !== -1) newSelected.splice(index, 1)
    else {
      const target = selected.find(
        (item) => item.productType === productType && item.transactionType === transactionType,
      )
      newSelected.push({
        productType,
        transactionType,
        minCommitmentFee: target?.minCommitmentFee || 0,
      })
    }

    setSelected(newSelected)
  }

  const onChangeMinCommitmentFee = (productType: string, transactionType: string, value: any) => {
    value = removeComma(value)

    const newSelected = cloneDeep(selected)
    newSelected.forEach((item) => {
      if (item.productType === productType && item.transactionType === transactionType) item.minCommitmentFee = value
    })

    setSelected(newSelected)
  }

  const rederTable = useMemo(() => {
    return (
      <table className="w-full">
        <thead>
          <tr>
            <th className="p-2 border">No</th>
            <th className="p-2 border">Product Type</th>
            <th className="p-2 border">Transaction Type</th>
            <th className="p-2 border">Min Commitment Fee</th>
            <th className="p-2 border">
              <div className="flex items-center justify-center gap-2">
                <span>Required</span>
                <Checkbox title="All" id="all" checked={isAll} onChange={onSelectAll} className="mb-[-6px]" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {productTypeOptions.map((prod, index) => {
            return (
              <Fragment key={`${prod}-${index}`}>
                {transactionTypeOptions.map((trans, idx) => {
                  no++
                  const checked = selected.find((item) => item.productType === prod && item.transactionType === trans)

                  return (
                    <tr key={`${prod}-${index}-${idx}`}>
                      <td className="p-2 border text-center">{no}</td>
                      <td className="p-2 border">{prod}</td>
                      <td className="p-2 border">{trans}</td>
                      <td className="p-1 border">
                        <div className="flex justify-center text-center">
                          <PlainInput2
                            value={checked?.minCommitmentFee || 0}
                            content={checked?.minCommitmentFee ? getPrice3decimal(checked.minCommitmentFee) : '0'}
                            className="w-full"
                            disabled={!checked}
                            onChange={(value: string) => onChangeMinCommitmentFee(prod, trans, value)}
                          />
                        </div>
                      </td>
                      <td className="p-1 border">
                        <div className="flex justify-center">
                          <Checkbox
                            title=""
                            id={`${index}-${idx}`}
                            checked={!!checked}
                            onChange={() => onSelect(prod, trans)}
                            className="w-5 mb-[-6px]"
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [selected, isAll])

  return (
    <Modal
      title="Edit Min Commitment Fee"
      titleOkay="Confirm"
      loading={loading === 'setting'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={() => onSubmit(selected)}
    >
      <div className="w-196">{rederTable}</div>
    </Modal>
  )
}
