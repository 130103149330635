import { InputType } from 'config'

export const defaultInputs: Record<string, InputType> = {
  enabled: {
    inputType: 'toggle',
    title: 'Enabled',
    required: true,
  },
  title: {
    inputType: 'text',
    type: 'text',
    title: 'Title',
    required: true,
  },
}
