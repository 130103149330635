import { useMemo } from 'react'
import { Input } from 'stories/components'

import type { ISecondLienLimit } from '../../interface'

export const SecondLienMaxLimits = ({ data }: { data: ISecondLienLimit | undefined }) => {
  const renderMaxLimit = useMemo(() => {
    if (!data) return <></>

    return (
      <div className="flex items-center gap-4">
        <Input
          title="Max Amount"
          type="thousandSep"
          readOnly={true}
          value={data.maxSecondLien.toString()}
          className="mb-[-16px]"
          onChange={() => {}}
        />
        <Input
          title="Max CLTV"
          type="number"
          readOnly={true}
          value={data.maxCLTV.toString()}
          className="mb-[-16px]"
          onChange={() => {}}
        />
      </div>
    )
  }, [data])

  return (
    <div className="relative">
      <div className="px-2 py-4 mt-2">{renderMaxLimit}</div>
    </div>
  )
}
