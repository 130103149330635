import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'

export const ExpirationDateType = () => {
  const [action, setAction] = useState('')
  const [changedKey, setChangedKey] = useState('')
  const [content, setContent] = useState<Record<string, string>>({})

  useEffect(() => {
    setAction('setting')
    getSetting('expirationDateType')
      .then(({ value }) => setContent(JSON.parse(value || '{}')))
      .finally(() => setAction(''))
  }, [])

  const onChange = async (key: string, value: string | number | null, index: number) => {
    let temp: any = cloneDeep(content)
    temp[key][index] = value
    setContent(temp)
    setChangedKey(key)
  }

  const onblur = async (key: string) => {
    if (key === changedKey) {
      setAction('setting')
      await updateSetting('expirationDateType', JSON.stringify(content))
      setAction('')
    }
    setChangedKey('')
  }

  return (
    <div className="relative">
      <LayoutLoading show={action !== ''} />
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Expiration Date
        {action && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>
      <div className="relative mb-6">
        <div className="overflow-auto shadow-md sm:rounded-lg">
          <table className="w-fit text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 border">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 border">
                  Days
                </th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {Object.keys(content).map((key) => {
                const item: any = content[key]
                return (
                  <tr key={key} className="mb-1">
                    <td className="px-6 py-2 border">
                      <input
                        className="block focus:ring-0 text-[14px] text-black bg-white py-0 pl-1 pr-4 bg-[center_right_0rem] w-[500px] border-gray-300"
                        value={item[0]}
                        onChange={(e: any) => onChange(key, e.target.value, 0)}
                        onBlur={() => onblur(key)}
                      />
                    </td>
                    <td className="px-6 py-1 border">
                      {!item[2] && (
                        <input
                          className="block focus:ring-0 text-[13px] text-black bg-white py-0 pl-1 pr-4 bg-[center_right_0rem] border-gray-300 w-[100px]"
                          type="number"
                          value={item[1]}
                          onChange={(e: any) => onChange(key, Number(e.target.value) || null, 1)}
                          onBlur={() => onblur(key)}
                        />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
