import { EmailTo } from 'components/EmailTo'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { partiesMap } from 'reducers/loanDetail.reducer'
import { getBorrowerInfo } from 'services'
import { Modal, TextArea } from 'stories/components'

export const BorroserEmailDialog = ({ onClose }: { onClose: Function }) => {
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState('')
  const [data, setData] = useState<{ party: string; email: string }[]>([])
  const [selectedEmails, setSelectedEmails] = useState<Record<string, 'to' | 'cc' | 'bcc' | ''>>({})

  useEffect(() => {
    setLoading(true)
    getBorrowerInfo()
      .then(async ({ parties, borrowerEmail }) => {
        const emails: { party: string; email: string }[] = []
        if (borrowerEmail)
          emails.push({
            party: 'Borrower',
            email: borrowerEmail,
          })
        Object.keys(parties).forEach((key) => {
          if (key == 'accountManager') return
          let email = parties[key]
          if (!email) return

          emails.push({
            party: partiesMap[key],
            email,
          })
        })
        setData(emails)
        setSelectedEmails(
          borrowerEmail
            ? {
                [borrowerEmail]: 'to',
              }
            : {},
        )
      })
      .finally(() => setLoading(false))
  }, [])

  const onSubmit = async () => {
    const hasToEmail = !!Object.keys(selectedEmails).filter((email) => selectedEmails[email] == 'to').length
    if (!hasToEmail) {
      toast('Please choose one more TO email.', { type: 'error' })
      return
    }

    setLoading(true)
    await onClose(true, selectedEmails, note)
    setLoading(false)
  }

  return (
    <Modal
      title={`Send Emails to ...`}
      titleOkay="Send Email"
      isOpen
      loading={loading}
      disabled={!Object.keys(selectedEmails).length}
      lastUpdatedAt={Date.now()}
      onClose={() => onClose(false)}
      onOk={() => onSubmit()}
    >
      <div className="w-144">
        <EmailTo Emails={selectedEmails} Data={data} onChange={(data: any) => setSelectedEmails(data)} />
        <div className="mt-4">
          <TextArea
            className=""
            title={'Notes'}
            key={'Notes'}
            rows={3}
            required
            value={note}
            onChange={(value: string) => setNote(value)}
          />
        </div>
      </div>
    </Modal>
  )
}
