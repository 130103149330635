import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { getPrice3decimal } from 'utils'

import type { ILoanAmountDscrOverlay, IRestriction } from '../../interface'

export const LoanAmountDscrOverlay = ({ data }: { data: IRestriction }) => {
  const [overlay, setOverlay] = useState<ILoanAmountDscrOverlay[]>([])

  useEffect(() => {
    if (data?.DscrOverlay?.loanAmountDscrOverlay) setOverlay(data.DscrOverlay.loanAmountDscrOverlay)
    else setOverlay([])
  }, [data])

  return (
    <div className="p-2">
      {!!overlay.length ? (
        <table className="text-sm w-full max-w-xl rounded-lg shadow text-center">
          <thead className="bg-gray-100">
            <tr>
              <th colSpan={2} className="px-3 py-2 border">
                Loan Amount
              </th>
              <th rowSpan={2} className="px-3 py-2 border w-1/3">
                Min DSCR
              </th>
            </tr>
            <tr>
              <th className="px-3 py-2 border w-1/3">From</th>
              <th className="px-3 py-2 border w-1/3">To</th>
            </tr>
          </thead>

          <tbody>
            {overlay.map((item, index) => {
              return (
                <tr key={index} className={`${index % 2 == 1 ? 'bg-gray-50' : ''}`}>
                  <td className="px-3 py-2 border">{getPrice3decimal(item.loanAmount.from)}</td>
                  <td className="px-3 py-2 border">{getPrice3decimal(item.loanAmount.to)}</td>
                  <td className="px-3 py-2 border">{item.minDscr}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )}
    </div>
  )
}
