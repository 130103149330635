import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType, INVALID_ALL_INPUTS } from 'config'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { submitExceptionRequest } from 'services/apis'
import { Button } from 'stories/components'
import { InputValidate, REQUIRED_FIELD_ERROR_MESSAGE } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import type { ILoan, ILoanDetail } from './interfaces'

const inputs = (loanNumber: number): Record<string, InputType> => ({
  content: {
    title: 'What is the exception request?',
    inputType: 'text',
    error: '',
    required: true,
  },
  factor: {
    title: 'What are compensating factors for this transaction?',
    inputType: 'text',
    error: '',
    required: true,
  },
  applicationFiles: {
    title: 'Application Files',
    inputType: 'filetable',
    error: '',
    acceptFileTypes: 'application/pdf',
    required: true,
    filePath: `loan/${loanNumber}/exceptions`,
    showCategory: false,
    showStatus: false,
  },
  creditReportFiles: {
    title: 'Credit Report Files',
    inputType: 'filetable',
    error: '',
    acceptFileTypes: 'application/pdf',
    required: true,
    filePath: `loan/${loanNumber}/exceptions`,
    showCategory: false,
    showStatus: false,
  },
})

interface IProps {
  loan: ILoan
  visibleFields: string[]
  loanFields: Record<string, InputType>
  loanDetail: ILoanDetail
  exceptions: any[]
  setExceptions?: (data: any[]) => void
}

export function ExceptionRequest(props: IProps) {
  const loanNumber = setLoanNumber()

  const { loan, visibleFields, loanFields, loanDetail, exceptions, setExceptions = () => {} } = props

  const [fields, setFields] = useState({ ...inputs(loanNumber) })
  const [action, setAction] = useState('')
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false)

  const onChange = async (key: string, e: any) => {
    let temp = cloneDeep(fields)
    temp[key].value = e
    temp[key].error = InputValidate({ ...temp[key] })
    setFields(temp)
  }

  const onSubmitRequest = async () => {
    let requiredErrorFields: Array<string> = []
    let otherErrorFields: Array<string> = []

    visibleFields.map((key) => {
      if (loanFields[key]?.error?.length) {
        if (loanFields[key].error === REQUIRED_FIELD_ERROR_MESSAGE) {
          requiredErrorFields.push(key)
        } else {
          otherErrorFields.push(`${loanFields[key].title}: ${(loan as any)[key]}`)
        }
      }
    })

    if (requiredErrorFields.length > 0) {
      return toast(`Please input all of the Required Fields in above "Loan Detail" section!`, { type: 'error' })
    }

    let hasError = false
    let object: any = {}
    const temp = cloneDeep(fields)

    Object.keys(temp).map((key) => {
      const error = InputValidate({ ...temp[key] })
      object[key] = temp[key].value
      if (error.length) {
        temp[key].error = error
        hasError = true
      }
    })
    if (hasError) {
      setFields(temp)
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setAction('submit')

    if (otherErrorFields.length > 0) {
      object['errorFields'] = otherErrorFields.join(', ')
    }

    const res = await submitExceptionRequest({ expRequest: object })
    let tempExp = cloneDeep(exceptions)
    tempExp.push(res.data)
    setExceptions(tempExp)
    setAction('')
    setShowSubmitSuccess(true)
  }

  return (
    <div className="ExceptionRequest-container relative">
      <LayoutLoading show={action === 'submit'} />

      <div className="text-2xl font-bold mb-4 flex items-center">
        <span>Exception Request</span>
      </div>
      {showSubmitSuccess ? (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-[14px]"
          role="alert"
        >
          <div className="font-variation-settings-600">
            Successfuly submitted your Exception Request!
            <br /> We will review this soon and get back to you within 24 hrs.
          </div>
        </div>
      ) : (
        <div className="">
          <div className="text-[15px] text-gray-800">
            - If you can't find your price then you can make an exception request.
          </div>
          <div className="text-[15px] text-gray-800">
            - Before submit the exception request, please confirm that you've completed all required loan detail's
            information.
          </div>
          <div className="text-[15px] text-gray-800 mb-4">
            - You can not edit Loan Detail after your exception request has been Approved!
          </div>
          <div className="">
            {Object.keys(fields).map((key, index) => {
              return (
                <div key={index}>
                  {index == 2 && (
                    <div className="text-[15px] text-gray-800 mb-4">
                      - Please attach Application and Credit Report with your request.
                    </div>
                  )}
                  <div className="mb-4">
                    <RenderInput input={{ ...fields[key] }} Key={key} onChange={onChange} />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="">
            <Button
              className="w-full"
              disabled={loanDetail.restructureApplicationRelease === false && false}
              onClick={() => onSubmitRequest()}
              loading={action === 'submit'}
            >
              Submit Request
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
