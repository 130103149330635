import {
  EyeIcon,
  InboxArrowDownIcon,
  MagnifyingGlassCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import {
  AccountType,
  API_DOCUMENT_DOWNLOAD_BY_ID,
  appApiUrl,
  COMPANY_ADDRESS,
  COMPANY_NAME,
  COMPANY_TITLE,
  companyName,
} from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { ResourcesBroadcastSettingKey } from 'pages/Admin'
import type { IDocument } from 'pages/Resources'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { store } from 'reducers'
// import { useHistory } from 'react-router-dom'
import {
  deleteCompanyAppraisal,
  deleteDocument,
  getAllCompanyAppraisal,
  listAllDocuments,
  openS3Document,
  sortCompanyAppraisal,
  updateDocumentOrders,
} from 'services'
import Api from 'services/api'
import { getSetting } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { confirm, renderHeader } from 'utils'
import { useTitle } from 'utils/pageTitle'

import { AddResourceDialog } from './AddResourceDialog'
import { EditCompanyAppraisalFieldsDialog } from './CompanyAppraisalDialog'
import { FindBankerDialog } from './FindBankerDialog'
import { StaticComponent } from './GuidelineOverlay'
import { ResourceOrderDialog } from './ResourceOrderDialog'
import { CompanyAppraisal } from './types'
import { UnderwritingFee } from './UnderwritingFee'

export * from './GuidelineOverlay'

export const Resources = () => {
  useTitle(`Resources - ${companyName}`)
  const [isLoading, setLoading] = useState(false)
  const [documents, setDocuments] = useState<Record<string, Record<string, any>[]>>({
    guideline: [],
    form: [],
    training: [],
    resisdential: [],
    marketing: [],
  })
  const [showFindBanker, setShowFindBanker] = useState(false)
  const [isShowAdd, setShowAdd] = useState(false)
  const [isCompanyAppraisalModal, setCompanyAppraisalModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<Record<string, any> | null>(null)
  const [sortOrderModal, setSortOrderModal] = useState('')
  const auth = useSelector((state: any) => state.auth)
  const [broadcasts, setBroadcasts] = useState<Record<string, string>>({})
  const [companyAppraisals, setCompanyAppraisals] = useState<CompanyAppraisal[]>([])

  const stateData = store.getState()

  useEffect(() => {
    refetch()
    getSetting(ResourcesBroadcastSettingKey).then(({ value }) => setBroadcasts(JSON.parse(value || '{}')))
  }, [])

  const sortedCompanyAppraisals = useMemo(
    () => companyAppraisals.sort((a, b) => a.sortNo - b.sortNo),
    [companyAppraisals],
  )

  const refetch = async () => {
    setLoading(true)
    const docs = await listAllDocuments()
    const companyAppraisals = await getAllCompanyAppraisal()
    setCompanyAppraisals(companyAppraisals)
    setDocuments(docs)
    setLoading(false)
  }

  const permission = usePermissions()
  const hasPermission = useMemo(() => {
    return permission.hasPermission('MANAGE_RESOURCES')
  }, [permission.data])

  const isAdmin = useMemo(() => {
    if (!auth.profile) return null
    return auth.profile.accountType == AccountType.ADMIN
  }, [auth])

  const onCloseModal = (isNeedRefetch: boolean) => {
    if (isNeedRefetch) refetch()
    setSelectedItem(null)
    setShowAdd(false)
    setCompanyAppraisalModal(false)
  }

  const onAdd = () => {
    setSelectedItem(null)
    setShowAdd(true)
  }

  const onEdit = (item: Record<string, any>) => {
    setSelectedItem(item)
    setShowAdd(true)
  }

  const onEditCompanyAppraisal = (item: CompanyAppraisal) => {
    setSelectedItem(item)
    setCompanyAppraisalModal(true)
  }

  const OnAddCompanyAppraisal = () => {
    setCompanyAppraisalModal(true)
  }

  const downloadDocument = async (id: number) => {
    // const res = await downloadS3Documents(fileKey)
    var windowReference: any = window.open()
    const url = Api.replaceVariables(API_DOCUMENT_DOWNLOAD_BY_ID, { id })
    windowReference.location = `${appApiUrl}${url}`
  }

  const onDelete = async (item: Record<string, any>) => {
    const content = (
      <div className="text-gray-600 mb-4 text-[16px]">
        Do you want to remove this Document?
        <br />
        <span className="text-gray-900">Document name: {item.name}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteDocument(item.id)
    setLoading(false)

    const index = documents[item.type].findIndex((doc) => doc.id == item.id)
    if (index == -1) return

    const newDocuments = cloneDeep(documents)
    newDocuments[item.type].splice(index, 1)
    setDocuments(newDocuments)
  }

  const onDeleteInfo = async (item: CompanyAppraisal) => {
    const content = (
      <div className="text-gray-600 mb-4 text-[16px]">
        Do you want to remove this Company Info?
        <br />
        <span className="text-gray-900">Company name: {item.company}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteCompanyAppraisal(item.id)
    toast('Company Appraisal Info has been deleted.', { type: 'info' })
    setLoading(false)

    const newData = cloneDeep(companyAppraisals)
    const index = companyAppraisals.findIndex((v) => v.id == item.id)
    newData.splice(index, 1)
    setCompanyAppraisals(newData)
  }

  const onOverlayGuideline = (document: IDocument) => {
    if (document.programIDs.length === 0) {
      toast(`This Document doesn't have any Programs. Select Programs.`, { type: 'error' })
    } else {
      window.open(`/guidelineOverlay/${document.id}`, '_blank')
    }
  }

  const onSort = (key: string) => {
    setSortOrderModal(key)
  }

  const updateOrders = async (orders: Record<string, number>[]) => {
    await updateDocumentOrders(orders)
    const newDocs = cloneDeep(documents)
    newDocs[sortOrderModal].forEach((doc) => {
      const orderItem = orders.find((order) => order.id == doc.id)
      if (!orderItem) return
      doc.orderId = orderItem.orderId
    })
    setDocuments(newDocs)
  }

  const renderTableHeader = ({
    title,
    key,
    colSpan = 2,
  }: {
    title: string | JSX.Element
    key: string
    colSpan?: number
  }) => {
    title = (
      <>
        <span>
          <div className="flex justify-center items-center gap-4">
            <span>{title}</span>
            {key !== 'guideline' && !!documents[key].length && (
              <Tooltip message="Download All">
                <a
                  href="#"
                  download
                  className="p-1 cursor-pointer hover-shadow1 rounded"
                  onClick={(e) => {
                    window.open(`${appApiUrl}/documents/download-all/${key}?token=${stateData.auth.token}`, '_blank')
                    e.preventDefault()
                    return false
                  }}
                >
                  <InboxArrowDownIcon className="w-5 h-5 text-shade-blue" />
                </a>
              </Tooltip>
            )}
          </div>
        </span>{' '}
      </>
    )

    return (
      <thead>
        {isAdmin ? (
          <tr>
            {renderHeader({
              title,
              index: 0,
              key,
              sortable: true,
              sortOrder: 0,
              className: 'text-center bg-gray-100 text-[16px] font-variation-settings-600 px-2 py-2.5 border',
              onSort,
              colSpan,
              btnClassName: 'capitalize',
              alignClassName: 'justify-center min-h-[28px]',
            })}
          </tr>
        ) : (
          <tr>
            <th
              colSpan={colSpan}
              className="text-center bg-gray-100 text-[16px] font-variation-settings-600 px-2 py-2.5 border"
            >
              <div className="flex justify-center items-center gap-2 min-h-[28px]">
                <span>{title}</span>
              </div>
            </th>
          </tr>
        )}
      </thead>
    )
  }

  const renderDocument = (item: Record<string, any>, index: number) => {
    return (
      <tr key={item.id} className={`${index % 2 === 1 && 'bg-slate-50'}`}>
        <td className="p-4 pl-3 border text-[14.5px]">
          <span>
            <div className="flex">
              <div className="w-[21px]">{index + 1}.</div>
              <div>{item.name}</div>
            </div>
          </span>
        </td>
        {item.type == 'guideline' && (
          <td className="p-2 border text-center">
            <span className="flex justify-center gap-2">
              <Tooltip message="Download">
                <a
                  href={`${appApiUrl}/resources/download-matrix/${item.id}?token=${stateData.auth.token}`}
                  download
                  className="p-1 hover-shadow1 cursor-pointer rounded ml-3 mr-2"
                >
                  <InboxArrowDownIcon className="w-4 h-4" />
                </a>
              </Tooltip>
              <Tooltip message="View">
                <span
                  className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                  onClick={() => onOverlayGuideline(item as IDocument)}
                >
                  <EyeIcon className="w-4 h-4"></EyeIcon>
                </span>
              </Tooltip>
            </span>
          </td>
        )}
        <td className="p-2 border w-28">
          <span className="flex justify-center gap-2">
            {hasPermission && (
              <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
            )}
            <Tooltip message="Download">
              <span className="p-1 hover-shadow1 cursor-pointer" onClick={() => downloadDocument(item.id)}>
                <InboxArrowDownIcon className="w-4 h-4"></InboxArrowDownIcon>
              </span>
            </Tooltip>
            <Tooltip message="View">
              <span
                className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                onClick={() => openS3Document(item.fileKey)}
              >
                <EyeIcon className="w-4 h-4"></EyeIcon>
              </span>
            </Tooltip>
            {hasPermission && (
              <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                <TrashIcon className="w-4 h-4"></TrashIcon>
              </span>
            )}
          </span>
        </td>
      </tr>
    )
  }

  const onSortCompanyAppraisal = async (item: CompanyAppraisal, orgIndex: number, newIndex: number) => {
    const newData = cloneDeep(sortedCompanyAppraisals)
    const sortNo = newData[orgIndex].sortNo
    newData[orgIndex].sortNo = newData[newIndex].sortNo
    newData[newIndex].sortNo = sortNo

    setCompanyAppraisals(newData)

    setLoading(true)
    await sortCompanyAppraisal([newData[orgIndex].id, newData[newIndex].id])
    setLoading(false)
  }

  const renderBroadcast = (key: string, colSpan = 3) => {
    if (!broadcasts[key]) return null
    return (
      <tr>
        <td className="p-2 pl-3 border text-[14.5px] font-bold italic" colSpan={colSpan}>
          {broadcasts[key]}
        </td>
      </tr>
    )
  }

  return (
    <div className="Documents-container">
      <div className="px-2 md:px-5 pb-2 max-w-screen-2xl m-auto mt-4">
        <div className="flex">
          <div className="flex text-[24x] md:text-[26px] font-bold text-shade-blue items-center flex-auto capitalize">
            Resources
            <span className="text-base ml-3">
              {isLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
            </span>
          </div>
          {hasPermission && (
            <div>
              <Button onClick={onAdd} className="!py-2 !px-8">
                Add
              </Button>
            </div>
          )}
        </div>
        <div className="grid md:grid-cols-2 md:gap-4 px-4">
          <div className="mt-4">
            <div className="text-slate-500">Mortgagee Clause for CPL</div>
            <div className="text-blue-900 font-bold">
              {COMPANY_TITLE} ISAOA/ATIMA {COMPANY_ADDRESS}
            </div>
          </div>
          <div className="mt-4">
            <div className="text-slate-500">Mortgagee Clause for Insurance</div>
            <div className="text-blue-900 font-bold">
              {COMPANY_TITLE} ISAOA/ATIMA {COMPANY_ADDRESS}
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="group-left overflow-visible">
              <div className="table-container mb-6 relative overflow-visible shadow sm:rounded">
                <table className="table-auto w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                  {renderTableHeader({
                    title: 'Guidelines and Matrices',
                    key: 'guideline',
                    colSpan: 3,
                  })}
                  <tbody>
                    {renderBroadcast('guideline')}
                    <tr>
                      <td className="px-2 py-2.5 border text-center bg-gray-100 text-[16px] font-variation-settings-600">
                        Products
                      </td>
                      <td className="px-2 py-2.5 border text-center bg-gray-100 text-[16px] font-variation-settings-600">
                        <span>
                          <div className="flex justify-center items-center gap-2">
                            <span>Matrix</span>
                            {!!documents.guideline.length && (
                              <Tooltip message="Download All">
                                <a
                                  href="#"
                                  download
                                  className="p-1 cursor-pointer hover-shadow1 rounded"
                                  onClick={(e) => {
                                    window.open(
                                      `${appApiUrl}/resources/download-all-matrix?token=${stateData.auth.token}`,
                                      '_blank',
                                    )
                                    e.preventDefault()
                                    return false
                                  }}
                                >
                                  <InboxArrowDownIcon className="w-5 h-5 text-shade-blue" />
                                </a>
                              </Tooltip>
                            )}
                          </div>
                        </span>
                      </td>
                      <td className="px-2 py-2.5 border text-center bg-gray-100 text-[16px] font-variation-settings-600">
                        <span>
                          <div className="flex justify-center items-center gap-2">
                            <span>Guideline</span>
                          </div>
                        </span>
                      </td>
                    </tr>
                    {documents.guideline
                      .sort((a, b) => a.orderId - b.orderId)
                      .map((document, index) => renderDocument(document, index))}
                  </tbody>
                </table>
              </div>

              <div className="table-container mb-6 relative overflow-visible shadow sm:rounded">
                <table className="table-auto w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                  {renderTableHeader({
                    title: 'Materials',
                    key: 'training',
                  })}
                  <tbody>
                    {renderBroadcast('training')}
                    {documents.training
                      .sort((a, b) => a.orderId - b.orderId)
                      .map((document, index) => renderDocument(document, index))}
                    <tr className={`${documents.training.length % 2 === 1 && 'bg-slate-50'}`}>
                      <td className="p-4 pl-3 border text-[14.5px]" colSpan={3}>
                        <span
                          className="cursor-pointer hover:underline hover:text-shade-blue"
                          onClick={() => setShowFindBanker(true)}
                        >
                          <div className="flex items-center flex-wrap">
                            <span className="mr-2">- Find Appraiser / Settlement Agent / Contractor</span>
                            <MagnifyingGlassCircleIcon className="w-5 h-5"></MagnifyingGlassCircleIcon>
                          </div>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="table-container mb-6 relative overflow-visible shadow sm:rounded">
                <table className="table-auto w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                  {renderTableHeader({
                    title: 'Marketing',
                    key: 'marketing',
                  })}
                  <tbody>
                    {renderBroadcast('marketing')}
                    {documents.marketing
                      .sort((a, b) => a.orderId - b.orderId)
                      .map((document, index) => renderDocument(document, index))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="group-right">
              <div className="table-container mb-6 relative overflow-visible shadow sm:rounded">
                <table className="table-auto w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                  {renderTableHeader({
                    title: 'Forms',
                    key: 'form',
                  })}
                  <tbody>
                    {renderBroadcast('form')}
                    {documents.form
                      .sort((a, b) => a.orderId - b.orderId)
                      .map((document, index) => renderDocument(document, index))}
                  </tbody>
                </table>
              </div>
              <div className="mb-6 relative shadow sm:rounded">
                <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400">
                  <thead className="text-[14px] text-gray-900 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-center">
                    <tr>
                      <th className="p-3 border" colSpan={5}>
                        <span className="gap-2 items-center inline-flex">
                          <span className="flex bg-transparent font-bold text-left text-[16px] capitalize font-variation-settings-600">
                            Commercial Appraisal Management
                          </span>
                        </span>
                      </th>
                      {hasPermission && (
                        <th className="px-3 py-3">
                          <button
                            className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                            onClick={() => {
                              OnAddCompanyAppraisal()
                            }}
                          >
                            + Add
                          </button>
                        </th>
                      )}
                    </tr>
                    <tr className="font-variation-settings-500">
                      <th className="border p-2 font-variation-settings-600 text-center">Company</th>
                      <th className="border p-2 font-variation-settings-600 text-center w-36">Contact Name</th>
                      <th className="border p-2 font-variation-settings-600 text-center w-36">Phone Number</th>
                      <th className="border p-2 font-variation-settings-600 text-center">Email</th>
                      {hasPermission && <th className="border p-2 font-variation-settings-600 text-center">Action</th>}
                      {hasPermission && <th className="border p-2 font-variation-settings-600 text-center">Sort</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedCompanyAppraisals.map((item, index) => {
                      const orderOptions = Array.apply(null, Array(index + 1)).map((_, index) => index.toString())
                      return (
                        <tr className={`${index % 2 ? 'bg-slate-50' : ''}`} key={index}>
                          <td className="border p-3">{item.company}</td>
                          <td className="border p-3">{item.contactName}</td>
                          <td className="border p-3">{item.phoneNumber}</td>
                          <td className="border p-3">{item.email}</td>
                          {hasPermission && (
                            <td className="border p-3">
                              <span className="flex gap-2">
                                <span
                                  className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                                  onClick={() => onEditCompanyAppraisal(item)}
                                >
                                  <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                                </span>
                                <span
                                  className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                                  onClick={() => onDeleteInfo(item)}
                                >
                                  <TrashIcon className="w-4 h-4"></TrashIcon>
                                </span>
                              </span>
                            </td>
                          )}
                          {hasPermission && (
                            <td className="w-24">
                              <select
                                onChange={(e) => onSortCompanyAppraisal(item, index, Number(e.target.value))}
                                value={index}
                                className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                              >
                                {orderOptions.map((val) => (
                                  <option key={val}>{val}</option>
                                ))}
                              </select>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              <div className="mb-6">{<UnderwritingFee broadcasts={broadcasts} canEdit={hasPermission} />}</div>

              <div className="table-container mb-6 relative overflow-x-auto">
                <StaticComponent
                  programID={999}
                  // count={1}
                  align="center"
                  additionalItem={renderBroadcast(`${COMPANY_NAME}Fees`, 2)}
                />
              </div>
            </div>
            {isCompanyAppraisalModal && (
              <EditCompanyAppraisalFieldsDialog origin={selectedItem as CompanyAppraisal} onClose={onCloseModal} />
            )}
            {isShowAdd && <AddResourceDialog origin={selectedItem} onClose={onCloseModal} />}
            {isAdmin && sortOrderModal && (
              <ResourceOrderDialog
                documents={documents[sortOrderModal]}
                onClose={() => setSortOrderModal('')}
                onSubmit={(orders: Record<string, number>[]) => updateOrders(orders)}
              />
            )}
            {showFindBanker && <FindBankerDialog onClose={() => setShowFindBanker(false)} />}
          </div>
        </div>
      </div>
    </div>
  )
}
