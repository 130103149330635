import {
  ArrowTopRightOnSquareIcon,
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { COMPANY_LANDING_URL } from 'config'
import { useEffect, useState } from 'react'
import {
  deleteAccountExecutive,
  downloadS3Documents,
  filterAccountExecutives,
  updateAccountExecutiveProps,
} from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { confirm, formatTime } from 'utils'

import { AccountExecutivesModal } from './AccountExecutivesModal'
import type { IAccountExecutive } from './types'

export const AccountExecutives = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<IAccountExecutive[]>([])
  const [currentItem, setCurrentItem] = useState<IAccountExecutive | null>()

  useEffect(() => {
    filterData()
  }, [])

  const filterData = async () => {
    setLoading(true)
    const data = await filterAccountExecutives()
    setData(data)
    setLoading(false)
  }

  const onAdd = () => {
    setCurrentItem(null)
  }

  const onClose = async (updatedItem?: IAccountExecutive) => {
    if (!updatedItem) {
      setCurrentItem(undefined)
      return
    }
    const newData = cloneDeep(data)
    const { url } = await downloadS3Documents(updatedItem.photo)
    updatedItem.photoUrl = url

    if (currentItem) {
      const index = newData.findIndex((v) => v.id == updatedItem.id)
      newData[index] = updatedItem
    } else newData.push(updatedItem)

    setData(newData)
    setCurrentItem(undefined)
  }

  const onDelete = async (item: IAccountExecutive, index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Account Executive?
        <br />
        <span className="text-gray-600">
          Name: {item.firstName} {item.lastName}
        </span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteAccountExecutive(item.id)
    setLoading(false)
    const newData = cloneDeep(data)
    newData.splice(index, 1)
    setData(newData)
  }

  const onVisible = (item: IAccountExecutive, index: number) => {
    setLoading(true)
    updateAccountExecutiveProps(item.id, { visible: !item.visible })
      .then((newItem) => {
        const newData = cloneDeep(data)
        newData[index] = newItem
        setData(newData)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="AccountExecutives-container relative">
      <LayoutLoading show={isLoading} />
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center mb-3">
          Account Executives
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h2>
        <div>
          <Button onClick={onAdd} className="">
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3">
                No
              </th>
              <th scope="col" className="px-2 py-3">
                Name / Title
              </th>
              <th scope="col" className="px-2 py-3">
                Email
              </th>
              <th scope="col" className="px-2 py-3">
                Phone
              </th>
              <th scope="col" className="px-2 py-3">
                Created At
              </th>
              <th scope="col" className="px-2 py-3"></th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((item, index: number) => {
              return (
                <tr
                  className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'} ${item.visible ? '' : 'opacity-60'}`}
                  key={`${index}`}
                >
                  <td scope="row" className="px-2 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-2">
                    <div className="flex items-center gap-2">
                      <img
                        src={item.photoUrl}
                        className="w-20 h-20 rounded-full border-2 border-gray-200 object-cover"
                      />
                      <div>
                        <p>
                          {item.firstName} {item.lastName}
                        </p>
                        <p className="italic">{item.title}</p>
                        <button
                          onClick={() =>
                            window.open(
                              `${COMPANY_LANDING_URL}/account_executives/${item.firstName}${item.lastName}`,
                              '_blank',
                            )
                          }
                          className="font-bold text-shade-blue hover:underline cursor-pointer"
                        >
                          <div className="flex items-center gap-1">
                            View <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                          </div>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td className="px-2">{item.email}</td>
                  <td className="px-2">
                    <p>{item.phone}</p>
                    <p>{item.workPhone}</p>
                  </td>
                  <td className="px-2">{formatTime(item.createdAt)}</td>
                  <td className="px-2">
                    <div className="flex flex-wrap gap-2">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                        onClick={() => setCurrentItem(item)}
                      >
                        <PencilSquareIcon className="w-4 h-4" />
                      </span>
                      <span
                        className="text-red-500 p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onDelete(item, index)}
                      >
                        <TrashIcon className="w-4 h-4" />
                      </span>
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onVisible(item, index)}
                      >
                        {item.visible ? <EyeIcon className="w-4 h-4" /> : <EyeSlashIcon className="w-4 h-4" />}
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {currentItem !== undefined && <AccountExecutivesModal data={currentItem} onClose={onClose} />}
    </div>
  )
}
