import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

import type { IDscrRangeLeverageLimit } from '../../interface'
import { convertNullToBlankValueToDecimal } from '../../logic'

export const DscrLeverageLimit = ({ data }: { data: IDscrRangeLeverageLimit[] }) => {
  return (
    <div className="h-full">
      {!!data.length ? (
        <table className="w-full text-center text-sm">
          <thead className="font-bold bg-gray-100">
            <tr>
              <th rowSpan={2} className="border p-1">
                DSCR
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap">
                Loan Purpose
              </th>
              <th colSpan={2} className="border p-1">
                Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 w-[100px]">
                Min FICO
              </th>
              <th colSpan={2} className="border p-1">
                LTV
              </th>
              <th rowSpan={2} className="border p-1 w-[100px]">
                Max CLTV
              </th>
            </tr>
            <tr>
              <th className="border p-1 w-[160px]">From</th>
              <th className="border p-1 w-[160px]">To</th>
              <th className="border p-1 w-[100px]">Min</th>
              <th className="border p-1 w-[100px]">Max</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td rowSpan={3} className="border px-3 py-1">
                      {item.dscr.from} - {item.dscr.to}
                    </td>
                    <td className="border px-3 py-1">Purchase</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minFICO.purchase}</td>
                    <td className="border px-3 py-1">{item.limit.purchase.ltv.min}</td>
                    <td className="border px-3 py-1">{item.limit.purchase.ltv.max}</td>
                    <td className="border px-3 py-1">{item.limit.purchase.cltv}</td>
                  </tr>
                  <tr>
                    <td className="border px-3 py-1">No-Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minFICO.nocashout}</td>
                    <td className="border px-3 py-1">{item.limit.nocashout.ltv.min}</td>
                    <td className="border px-3 py-1">{item.limit.nocashout.ltv.max}</td>
                    <td className="border px-3 py-1">{item.limit.nocashout.cltv}</td>
                  </tr>
                  <tr>
                    <td className="border px-3 py-1">Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minFICO.cashout}</td>
                    <td className="border px-3 py-1">{item.limit.cashout.ltv.min}</td>
                    <td className="border px-3 py-1">{item.limit.cashout.ltv.max}</td>
                    <td className="border px-3 py-1">{item.limit.cashout.cltv}</td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )}
    </div>
  )
}
