import { ArrowPathRoundedSquareIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import {
  getEliteProgressStatus,
  getEliteValuationComments,
  postEliteValuationComments,
  postEliteValuationDocuments,
  syncOrderAppraisalsInfo,
} from 'services'
import { Button, ButtonGroup, Modal, PlainTable, TextArea } from 'stories/components'
import { type CategoryDocument, CategoryDocuments } from 'stories/components/CategoryFileTable/CategoryFileTable'
import { formatDate, formatTime } from 'utils'

import { handleFailedDocsNotification } from './handleFailedDocs'
import { AppraisalDocumentCategories } from './types'

export const EliteValuation = ({ data, onClose }: { data: Record<string, any>; onClose: Function }) => {
  const [menu, setMenu] = useState('Notification Logs')
  const [logs, setLogs] = useState<Array<any>>([])
  const [progress, setProgress] = useState<any>(null)
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('')
  const [documents, setDocuments] = useState<Array<CategoryDocument>>([])
  const [uploadedDocs, setUploadedDocs] = useState<Array<string>>(data.thirdPartyAPI.uploadedDocs)

  const { OrderId, FileId } = data.thirdPartyAPI

  const getProcessStatus = async () => {
    const res = await getEliteProgressStatus(OrderId)
    if (res.success) {
      setProgress(res.data)
    }
  }

  useEffect(() => {
    getProcessStatus()
  }, [])

  const getLogs = async () => {
    setLoading(true)
    const res = await getEliteValuationComments(OrderId)
    setLoading(false)
    if (res.success) {
      let rlt: any = []
      res.data.map((item: any, index: number) => {
        rlt.push([
          index + 1,
          <div>
            {formatTime(item.NoteDate)}
            <br />
            <span className="italic">{item.UserName}</span>
          </div>,
          item.Message,
        ])
      })
      setLogs(rlt)
    }
  }

  const onSendMessage = async () => {
    setLoading(true)
    setAction('sendMessage')
    await postEliteValuationComments({
      OrderId,
      comment,
    })
    setComment('')
    setLoading(false)
    setAction('')
    getLogs()
  }

  const extraDocs = useMemo(() => {
    let docs: any = []
    documents.map((item: CategoryDocument) => {
      if (uploadedDocs?.includes(item.fileKey)) {
      } else docs.push(item)
    })
    return docs
  }, [documents, uploadedDocs])

  const sendDocuments = async () => {
    setLoading(true)
    setAction('sendDocuments')
    const res = await postEliteValuationDocuments({
      id: data.id,
      OrderId,
      documents: extraDocs,
    })

    handleFailedDocsNotification(res)

    let tempDocs = cloneDeep(uploadedDocs)
    res.uploadedDocs.map((fileKey: string) => {
      tempDocs.push(fileKey)
    })
    setUploadedDocs(tempDocs)
    setLoading(false)
    setAction('')
  }

  useEffect(() => {
    getLogs()
    setDocuments(data.info?.documents || [])
  }, [])

  const onDocumentChange = (docs: Array<CategoryDocument>) => {
    setDocuments(docs)
  }

  const renderContent = () => {
    if (menu === 'Notification Logs') {
      return (
        <div className="">
          <div className="">
            <PlainTable header={['No', 'Date / By', 'Comment']} tdClass={'px-4 py-2'} data={logs} />
          </div>
          <TextArea title="Comment" value={comment} onChange={(value) => setComment(value)} />
          <div className="text-center mt-3">
            <Button
              color="gray"
              onClick={onSendMessage}
              loading={action === 'sendMessage'}
              disabled={comment.length === 0}
            >
              Send Message to Elite Valuation
            </Button>
          </div>
        </div>
      )
    }
    if (menu === 'Documents') {
      return (
        <div className="">
          <CategoryDocuments
            documents={documents}
            onChange={onDocumentChange}
            setLoading={setLoading}
            type={'OrderAppraisal'}
            categories={AppraisalDocumentCategories}
          />
          <div className="text-center mt-4">
            <Button
              color="gray"
              onClick={sendDocuments}
              loading={action === 'sendDocuments'}
              disabled={extraDocs.length === 0}
            >
              Upload Documents to Elite Valuation
            </Button>
          </div>
        </div>
      )
    }
  }

  const diffDates = useMemo(() => {
    let rlt: any = {}
    if (progress?.sync) {
      Object.keys(progress.sync).map((key) => {
        if (formatDate(data.info[key]) !== formatDate(progress.sync[key])) rlt[key] = formatDate(progress.sync[key])
      })
    }
    return rlt
  }, [data, progress])

  const syncProgressDate = async () => {
    setLoading(true)
    await syncOrderAppraisalsInfo({
      id: data.id,
      dates: diffDates,
    })
    setLoading(false)
  }

  const renderProgressStatus = () => {
    if (progress === null) return null
    return (
      <div className="px-2">
        <div className="mb-4">
          <div className="flex flex-wrap gap-4 justify-between">
            <div>
              Status: <span className="font-bold text-shade-blue italic">{progress.status}</span>
            </div>
            {Object.keys(diffDates).length > 0 && (
              <div
                className="ml-4 text-[15px] text-shade-blue cursor-pointer hover:underline italic flex flex-wrap gap-2 items-center"
                onClick={syncProgressDate}
              >
                Sync Progress Dates
                <ArrowPathRoundedSquareIcon className="w-4 h-4"></ArrowPathRoundedSquareIcon>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-4 mb-4">
          {progress.dates.map((item: any, index: number) => {
            return (
              <div className="min-w-[270px] flex items-center" key={index}>
                <div className="min-w-[160px]">{item.label}:</div>{' '}
                <div className="italic font-bold text-[14px]">{formatDate(item.value)}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Modal isOpen title={`Eilte Valuation - ${FileId || OrderId}`} titleOkay="" onClose={() => onClose(false)}>
      <div className="text-gray-600 text-md overflow-hidden min-w-[750px] max-w-2xl relative">
        <LayoutLoading show={loading} />
        <div className="">
          <div className="border-b mb-2 flex justify-between flex-wrap gap-4">
            <div className="italic font-bold">Progress Status</div>
            <div className="">
              <a
                href={`https://elitevaluations.myvalutrac.com/valutrac/admin/appraisals/modify.aspx?id=${OrderId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="flex flex-wrap gap-2 items-center rounded p-1 hover:underline cursor-pointer text-[14.5px] text-shade-blue">
                  <span>Go to Elite Valuation Order</span>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                </span>
              </a>
            </div>
          </div>
          {renderProgressStatus()}
        </div>
        <div className="rounded p-2 border-[4px] border-shade-blue/50">
          <ButtonGroup
            title={['Notification Logs', 'Documents']}
            onChange={(key) => {
              setMenu(key)
            }}
            value={menu}
          />
        </div>
        <div className="mt-4">{renderContent()}</div>
      </div>
    </Modal>
  )
}
