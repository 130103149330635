import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { COMPANY_DNS } from 'config'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, IFromEmails } from './constants'

export const EmailSetup = () => {
  const [inputs, setInputs] = useState(defaultInputs())
  const [edit, setEdit] = useState(false)
  const [action, setAction] = useState('')
  const [fromEmails, setFromEmails] = useState<IFromEmails>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAdminConfig('emailSetup')
      .then((value) => {
        setAction('setting')
        setFromEmails(value)
        let newInputs = cloneDeep(inputs)
        Object.keys(inputs).map((key) => {
          newInputs[key].value = value[key] || ''
        })
        setInputs(newInputs)
        setIsLoading(false)
      })
      .finally(() => setAction(''))

    setEdit(false)
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
    setEdit(true)
  }

  const onSubmit = async () => {
    if (!fromEmails) return
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    const newEmails = cloneDeep(fromEmails)
    Object.keys(newEmails).forEach((key) => {
      if (data[key]) (newEmails as any)[key] = data[key]
    })

    setAction('setting')
    await setAdminConfig('emailSetup', newEmails)
    setAction('')
    setEdit(false)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <div className="mb-4 font-semibold text-xl"> Company DNS : @{COMPANY_DNS}</div>
      <div className="relative mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]

          return (
            <div className={`input md:col-span-${input.span || 1} `} key={index}>
              <RenderInput input={{ ...input }} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
      {edit && (
        <div className="flex justify-center">
          <Button className="w-[200px]" loading={action == 'setting'} onClick={onSubmit}>
            Save
          </Button>
        </div>
      )}
    </div>
  )
}
