import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getNYTransactions, updateNYTransactions } from 'services'
import { Button } from 'stories/components'
import { getPrice3decimal, InputConvert, InputValidate, openAuditLog, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { AmtTotalFields, defaultInputs, inputGroups, NYIndirectTotalFields } from './constants'

export function NYTransactions() {
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)
  const [init, setInit] = useState(true)
  const [changed, setChanged] = useState(false)

  const disableLogic = (data: any) => {
    const CEMATransaction = data.CEMATransaction.value === true
    data.CEMACurrentLender.disabled = !CEMATransaction
    data.CEMAUnpaidBalance.disabled = !CEMATransaction
    return data
  }

  useEffect(() => {
    const nInputs = cloneDeep(inputs)
    setLoading(true)
    getNYTransactions()
      .then((data) => {
        Object.keys(nInputs).forEach((key) => {
          nInputs[key].value = data[key]
          nInputs[key].error = InputValidate(nInputs[key])
        })
        setInputs(disableLogic(nInputs))
        setLoading(false)
        setInit(false)
      })
      .catch(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value

    if (AmtTotalFields.indexOf(key) !== -1) {
      let total = 0
      AmtTotalFields.map((key) => {
        total += removeComma(newInputs[key].value)
      })
      newInputs['NYReimburseBorrowerAmtTotal'].value = getPrice3decimal(total)
    }
    if (NYIndirectTotalFields.indexOf(key) !== -1) {
      let total = 0
      NYIndirectTotalFields.map((key) => {
        total += removeComma(newInputs[key].value)
      })
      newInputs['IndirectTotal'].value = getPrice3decimal(total)
    }
    newInputs[key].error = InputValidate(newInputs[key])
    setChanged(true)
    setInputs(disableLogic(newInputs))
  }

  const onSave = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    let data: Record<string, any> = {}

    Object.keys(newInputs).forEach((key) => {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    })
    setChanged(false)
    setInputs(newInputs)

    if (hasError) return

    setLoading(true)

    updateNYTransactions(data)
      .then(() => {
        toast('Successfully updated', { type: 'info' })
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'NYTransactions',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  const renderSection = (key: string, inputGroup: any) => {
    return (
      <div className="shadow rounded border">
        <div className="bg-gray-200 round-t py-1 px-4 mb-2">{key}</div>
        <div className="p-2">
          <div className="grid gap-4 md:grid-cols-6 grid-cols-1 mb-3">
            {inputGroup.map((item: string) => {
              let input = inputs[item]
              input.history = true
              input.className = 'flex items-center'
              return (
                <div className={`input md:col-span-${input.span || 2}`} key={item}>
                  <RenderInput input={input} Key={item} onChange={onChange} showHistory={showHistory} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`nyTransactinos-container`}>
      <SaveChanges show={changed} onSave={onSave} />
      <div className="max-w-screen-2xl m-auto">
        <div className={`bg-white rounded`}>
          <div className="">
            <LayoutLoading show={isLoading} />
            {!init && (
              <div className="grid md:grid-cols-2 gap-5">
                <div className="md:col-span-1">
                  {Object.keys(inputGroups).map((key, index) => {
                    if (index !== 0) return null
                    const inputGroup = inputGroups[key]
                    return (
                      <div className="" key={index}>
                        {renderSection(key, inputGroup)}
                      </div>
                    )
                  })}
                </div>
                <div className="md:col-span-1">
                  {Object.keys(inputGroups).map((key, index) => {
                    if (index === 0) return null
                    const inputGroup = inputGroups[key]
                    return (
                      <div className="mb-4" key={index}>
                        {renderSection(key, inputGroup)}
                      </div>
                    )
                  })}
                </div>
                <div className="flex justify-center md:col-span-2">
                  <Button className="w-[240px]" loading={isLoading} onClick={onSave}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
