import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import type { IInvoice } from './types'

export const defaultInputs = (): Record<string, InputType> => {
  const loanNumber = setLoanNumber()
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      required: true,
    },
    payee: {
      inputType: 'text',
      type: 'text',
      title: 'Payee',
      required: true,
    },
    description: {
      inputType: 'textarea',
      type: 'text',
      title: 'Description',
      value: '',
      span: 'full',
      required: false,
    },
    no: {
      inputType: 'text',
      title: 'Invoice Number',
      required: true,
    },
    amount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount',
      required: true,
    },
    isPaid: {
      inputType: 'checkbox',
      title: 'Is Paid',
    },
    files: {
      inputType: 'FileTable',
      title: 'File',
      required: false,
      acceptFileTypes: '.pdf',
      filePath: `loan/${loanNumber}/invoices`,
      span: 'full',
      showCategory: false,
      showStatus: false,
    },
  }
}

export const AddInvoice = ({
  index,
  item,
  onClose,
  onUpdate,
}: {
  index: number
  item: IInvoice | null
  onClose: Function
  onUpdate: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    if (item) {
      const newInputs = cloneDeep(inputs)
      Object.keys(inputs).forEach((key) => {
        newInputs[key].value = (item as any)[key]
      })
      setInputs(newInputs)
    }
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    data.amount = removeComma(data.amount)
    setLoading(true)
    onUpdate(data)
  }

  return (
    <Modal
      title={item ? `Update Invoice - No.${index + 1}` : 'Add Invoice'}
      titleOkay="Submit"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      childLevel={2}
      isOpen
    >
      <div className="text-gray-900">
        <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
