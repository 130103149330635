import { setHeader } from 'actions'
import cloneDeep from 'clone-deep'
import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateTaskStatus } from 'services'
import { DocumentFile, InputFileTable, Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { TaskStatus } from '../constants'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    comment: {
      inputType: 'textarea',
      type: 'text',
      title: 'Comment',
      value: '',
      span: 2,
      required: false,
    },
  }
}

export const assignToRoles = accountTypes

export const MarkTaskModal = ({ task, onClose }: { task: Record<string, any>; onClose: Function }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [additionalInfo, setAdditionalInfo] = useState<any>({})
  const [notifiers, setNotifiers] = useState<EmailValue>({
    [task.creator.email]: 'to',
  })
  const [files, setFiles] = useState<DocumentFile[]>([])

  const assignedToEmails = useMemo(
    () =>
      task.assignedTo
        .map((v: any) => v.value)
        .filter((v: string) => v.includes('@'))
        .map((v: string) => ({ party: 'Assigned To', email: v })),
    [task],
  )

  const dispatch = useDispatch()

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onChangeAdditionalInfo = (key: string, value: any) => {
    let temp = cloneDeep(additionalInfo)
    temp[key] = value
    setAdditionalInfo(temp)
  }

  const onChangeEmail = (data: EmailValue) => {
    setNotifiers(data)
  }

  const newStatus: TaskStatus = (
    {
      [TaskStatus.COMPLETE]: TaskStatus.ACTIVE,
      [TaskStatus.ACTIVE]: TaskStatus.COMPLETE,
    } as any
  )[task.status]

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    updateTaskStatus(task.id, { status: newStatus, comment: data.comment, additionalInfo, notifiers, files })
      .then(() => {
        onClose(true, newStatus)
      })
      .catch(() => setLoading(false))
      .finally(() => {
        dispatch(
          setHeader({
            activeChangeFlag: true,
          }),
        )
      })
  }

  useEffect(() => {
    if (task.description === 'Collect Payment') {
      setAdditionalInfo({
        collectNextPayment: true,
      })
    }
  }, [])

  const renderAdditionalFragment = useMemo(() => {
    let rlt = null
    if (task.description === 'Collect Payment') {
      if (newStatus == TaskStatus.COMPLETE) {
        rlt = (
          <div className="mt-4">
            <RenderInput
              input={{
                inputType: 'Check',
                title: 'Collect Next Payment',
                value: additionalInfo.collectNextPayment,
              }}
              Key={'collectNextPayment'}
              onChange={onChangeAdditionalInfo}
            />
          </div>
        )
      }
    }
    return rlt
  }, [task, additionalInfo])

  return (
    <Modal
      title="Are you sure to Mark as ..."
      titleOkay="Confirm"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900">
        <p>Task No: {task.no}</p>
        <p>Description: {task.description}</p>
        <p className="mt-3">
          Are you sure to Mark as{' '}
          <span className="uppercase">
            {newStatus == TaskStatus.COMPLETE ? (
              <span className="text-shade-blue font-bold">COMPLETED</span>
            ) : (
              <span className="text-red-800 font-bold">INCOMPLETED</span>
            )}
          </span>
          ?
        </p>
        <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
        {renderAdditionalFragment}

        <RenderInput input={{ inputType: 'section', title: 'Send Notification To' }} Key="title" onChange={() => {}} />
        <LoanPartiesEmailTo
          loanNumber={task.loanNumber}
          value={notifiers}
          data={[{ party: 'Task Creator', email: task.creator.email }, ...assignedToEmails]}
          onChange={onChangeEmail}
          loadParties
        />
        <InputFileTable
          title="Attachment Files"
          filePath={`task/${task.loanNumber || task.id}`}
          showStatus={false}
          showCategory={false}
          value={files}
          onChange={setFiles}
        />
      </div>
    </Modal>
  )
}
