import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Modal, Toggle } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

import { defaultSendSignatureInfos } from './constant'

export const ConfirmSignDialog = ({
  data,
  generatePdf,
  onConfirm,
  onClose,
}: {
  data: Record<string, any>
  generatePdf: Function
  onConfirm: Function
  onClose: Function
}) => {
  const [isReviewed, setReviewed] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onDownloadPdf = () => {
    generatePdf(false, true)
  }

  const onConfirmSign = async () => {
    setLoading(true)
    try {
      await onConfirm()
    } catch (e) {
      console.log(e)
      setLoading(false)
      return
    }

    toast('Signed request is sent successfully.', { type: 'success' })

    setLoading(false)
    onClose()
  }

  return (
    <Modal
      isOpen
      title="Purchase Advice Sign Request"
      titleOkay="Send Sign Request"
      disabled={!isReviewed}
      loading={isLoading}
      onClose={() => onClose()}
      onOk={onConfirmSign}
    >
      <div className="text-gray-600 mv-2 text-md overflow-hidden w-[750px]">
        <div className="grid grid-cols-3">
          {Object.keys(defaultSendSignatureInfos).map((key, index) => {
            return (
              <div className="py-1.5" key={index}>
                <RenderInput
                  input={{
                    ...defaultSendSignatureInfos[key],
                    value: data[key],
                    error: '',
                    readOnly: true,
                  }}
                  Key={key}
                  onChange={() => {}}
                />
              </div>
            )
          })}
        </div>

        <div className="text-sm bg-gray-100 px-2 pb-4 rounded">
          <Button link onClick={onDownloadPdf}>
            <div className="flex items-center underline text-gray-900 hover:text-shade-blue">
              - View Unsigned PDF <ArrowDownTrayIcon className="w-4 h-4" />
            </div>
          </Button>
          <Toggle
            id="reviewed"
            title="I have reviewed the request"
            onChange={(value) => setReviewed(value)}
            value={isReviewed}
          />
        </div>
      </div>
    </Modal>
  )
}
