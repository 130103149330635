import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  InboxArrowDownIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { AccountType, appApiUrl, INVALID_ALL_INPUTS } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { logInPageModalAPI } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { Button, Input2, Modal, Pagination, Select2 } from 'stories/components'
import { confirm, formatDate, formatDateYMD, InputConvert, InputValidate, isDateRangeIncludingToday } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, notificationTypeOpts } from './constant'

const itemCountPerPage = 10

export interface ILoginPageNotificationData {
  accountType: Record<AccountType, boolean>
  content: string
  from: string
  id: number
  individualEmails: string[]
  parentEmails: string[]
  title: string
  to: string
  type: string
  views: number
}

export const LoginPageNotifications = () => {
  const [action, setAction] = useState('')
  const [inputs, setInputs] = useState(defaultInputs())
  const [values, setValues] = useState<Record<string, any>>({})
  const [data, setData] = useState<ILoginPageNotificationData[]>([])
  const [emailUserType, setEmailUserType] = useState<Record<string, string>>({})

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    type: 'all',
    status: 'All',
    accountType: 'all',
    orderBy: 'to',
    orderDir: '-1',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  useEffect(() => {
    const timeOutId = setTimeout(() => action == '' && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = async (filters: Record<string, any>) => {
    setAction('get')
    const { data, total, emailUserType } = await logInPageModalAPI({
      type: 'get',
      data: {
        query: filters.query.trim(),
        type: filters.type,
        status: filters.status,
        accountType: filters.accountType,
        count: itemCountPerPage,
        skip: filters.pageNum * itemCountPerPage,
        orderBy: filters.orderBy,
        orderDir: filters.orderDir,
      },
    })
    setEmailUserType(emailUserType)
    setData(data)
    setTotal(total)
    setAction('')
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterData(newFilters)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const renderHeader = (title: string | JSX.Element, sortable: boolean = false, key: string) => {
    let sortOrder = filters.orderBy == key ? parseInt(filters.orderDir) : 0
    if (!sortable)
      return (
        <th scope="col" className="py-3 px-2" key={key}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
      filterData(newFilters)
    }

    return (
      <th scope="col" className="py-2 px-2" key={key}>
        <button className="flex uppercase bg-transparent font-bold items-center" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-1 text-gray-900" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-1 text-gray-900" />
            )
          ) : (
            <div className="w-3 h-3 ml-1" />
          )}
        </button>
      </th>
    )
  }

  const onAdd = () => {
    setValues({
      no: -1,
    })
    setInputs(defaultInputs())
  }

  const onEdit = (item: ILoginPageNotificationData, index: number) => {
    setValues({
      no: index,
      ...item,
      from: formatDateYMD(item.from),
      to: formatDateYMD(item.to),
    })
    setInputs(defaultInputs())
  }

  const onTrash = async (item: ILoginPageNotificationData, index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this Notification?
        <br />
        <span className="text-gray-600">No: {filters.pageNum * itemCountPerPage + index + 1}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setAction('delete')
    await logInPageModalAPI({ type: 'delete', data: { id: item.id } })
    filterData(filters)
  }

  const onSubmit = async () => {
    let newInputs: any = cloneDeep(inputs)
    let errors = 0
    Object.keys(newInputs).map((key) => {
      newInputs[key].error = InputValidate({ ...newInputs[key], value: values[key] })
      if (newInputs[key].error?.length > 0) errors += 1
    })
    setInputs(newInputs)
    if (errors) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setAction('createNotification')
    await logInPageModalAPI({ type: 'create', data: values })
    filterData(filters)
    setValues({})
  }

  const onChangeInput = (key: string, value: string) => {
    let newValues = cloneDeep(values)
    let newInputs = cloneDeep(inputs)
    value = InputConvert(inputs[key], value)

    newValues[key] = value
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    setInputs(newInputs)
    setValues(newValues)
  }

  return (
    <div className="LoginPageNotifications-container relative">
      <LayoutLoading show={action.length > 0} />
      <h1 className="text-2xl font-bold flex items-center pb-5">
        <span className="flex items-center">
          Login Page Notifications <span className="text-[16px] ml-1">({total})</span>
        </span>
        {action && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>
      <div>
        <div className="mt-4 flex flex-wrap justify-between mb-4 gap-4">
          <div className="flex flex-wrap gap-4">
            <div className="w-[300px]">
              <Input2
                type="search"
                title="Search"
                hasIcon
                icon={svgSearch}
                value={filters.query}
                onChange={(value) => onChangeFilter('query', value)}
              />
            </div>
            <div className="w-[150px]">
              <Select2
                id="type"
                title="Type"
                options={{
                  all: 'All',
                  ...notificationTypeOpts,
                }}
                value={filters.type}
                onChange={(value) => onChangeFilter('type', value)}
              />
            </div>
            <div className="w-[150px]">
              <Select2
                id="status"
                title="Status"
                options={['All', 'Active', 'Inactive']}
                value={filters.status}
                onChange={(value) => onChangeFilter('status', value)}
              />
            </div>
            <div className="w-[200px]">
              <Select2
                id="accountType"
                title="Account Type"
                options={{
                  all: 'All',
                  ...accountTypes,
                }}
                value={filters.accountType}
                onChange={(value) => onChangeFilter('accountType', value)}
              />
            </div>
          </div>
          <div>
            <Button onClick={onAdd} className="">
              Add
            </Button>
          </div>
        </div>
        <div className="main">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="pl-5 py-3 pr-3">
                    No
                  </th>
                  <th scope="col" className="py-3 px-2 max-w-[250px]">
                    Title
                  </th>
                  {renderHeader('From', true, 'from')}
                  {renderHeader('To', true, 'to')}
                  {renderHeader(
                    <span className="text-left">
                      Viewed
                      <br />
                      Users
                    </span>,
                    true,
                    'views',
                  )}
                  <th scope="col" className="py-3 px-2">
                    Status
                  </th>
                  <th scope="col" className="py-3 px-2">
                    Type
                  </th>
                  <th scope="col" className="py-3 px-2">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index: number) => {
                  return [
                    <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                      <td className="pl-5 pr-2 py-2" rowSpan={2}>
                        {filters.pageNum * itemCountPerPage + index + 1}
                      </td>
                      <td className="px-2 py-2 font-semibold">{item.title}</td>
                      <td className="px-2 py-2">{formatDate(item.from)}</td>
                      <td className="px-2 py-2">{formatDate(item.to)}</td>
                      <td className="px-2 py-2 text-shade-blue">
                        <a
                          href={`${appApiUrl}/admin/loginNotificationExcel/${item.id}?token=${auth.token}`}
                          download
                          className="p-1 hover-shadow1 cursor-pointer rounded flex gap-3 w-fit items-center"
                        >
                          {item.views}
                          <InboxArrowDownIcon className="w-4 h-4 text-shade-blue" />
                        </a>
                      </td>
                      <td className={`px-2 py-2 font-semibold`}>
                        {isDateRangeIncludingToday(item.from, item.to) ? (
                          <span className="text-sky-800">Active</span>
                        ) : (
                          <span className="text-red-800">Inactive</span>
                        )}
                      </td>
                      <td className="px-2 py-2">{notificationTypeOpts[item.type]}</td>
                      <td className="px-2 py-2">
                        <span>
                          <div className="flex flex-wrap gap-2">
                            <span
                              className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                              onClick={() => onEdit(item, index)}
                            >
                              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                            </span>
                            <span
                              className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                              onClick={() => onTrash(item, index)}
                            >
                              <TrashIcon className="w-4 h-4"></TrashIcon>
                            </span>
                          </div>
                        </span>
                      </td>
                    </tr>,
                    <tr key={`type-${index}`} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                      <td className="px-2 py-2" colSpan={7}>
                        <span>
                          <div className="mb-2 flex">
                            <span className="text-shade-blue whitespace-nowrap mr-2">- Content:</span>
                            <span>{item.content}</span>
                          </div>
                          <div className="mb-2 flex">
                            <span className="text-shade-blue whitespace-nowrap mr-2">- Account Type:</span>
                            <span>
                              {Object.keys(item.accountType).map((key: any, index2) => {
                                if (item.accountType[key as AccountType] !== true) return null
                                return (
                                  <span className="mr-1" key={index2}>
                                    {accountTypes[key]},
                                  </span>
                                )
                              })}
                            </span>
                          </div>
                          {item.parentEmails.length > 0 && (
                            <div className="mb-2 flex">
                              <span className="text-shade-blue whitespace-nowrap mr-2">- Parent Emails:</span>
                              <span>
                                {item.parentEmails.map((email: string, index2: number) => {
                                  return (
                                    <span className="mr-1" key={index2}>
                                      {email}
                                      <span className="italic">({emailUserType[email] || '-'})</span>,
                                    </span>
                                  )
                                })}
                              </span>
                            </div>
                          )}
                          {item.individualEmails.length > 0 && (
                            <div className="mb-2 flex">
                              <span className="text-shade-blue whitespace-nowrap mr-2">- Individual Emails:</span>
                              <span>{item.individualEmails.join(', ')}</span>
                            </div>
                          )}
                        </span>
                      </td>
                    </tr>,
                  ]
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="footer flex justify-end items-center mt-3 mb-3">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={filters.pageNum}
          />
        </div>
      </div>
      {values.no !== undefined && (
        <Modal
          title={
            values.id
              ? `Update Notification - ${filters.pageNum * itemCountPerPage + values.no + 1}`
              : 'Create Notification'
          }
          disabled={action === 'createNotification'}
          titleOkay={values.id ? 'Update' : 'Create'}
          isOpen
          onClose={() => setValues({})}
          onOk={onSubmit}
        >
          <div className="w-[750px]">
            <div className={`grid gap-4 md:grid-cols-2 grid-cols-1 mb-3`}>
              {Object.keys(inputs).map((key, index) => {
                let input = inputs[key]
                input.value = values[key]
                return (
                  <div className={`input md:col-span-${input.span || 1}`} key={index}>
                    <RenderInput input={input} Key={key} onChange={onChangeInput} />
                  </div>
                )
              })}
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
