import cloneDeep from 'clone-deep'
import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import { COMPANY_DNS } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { declineFields } from '../constant'
import { ILoanDetail } from '../interfaces'

interface IProps {
  loanDetail: ILoanDetail
  loading: string
  onClose: () => void
  onSubmit: (comment: string, emailTo: EmailValue) => void
}
export const DeclineLoanChangeRequest = (props: IProps) => {
  const { loanDetail, loading, onClose, onSubmit } = props
  const loanNumber = setLoanNumber()

  const [inputs, setInputs] = useState(declineFields())
  const [notifiers, setNotifiers] = useState<EmailValue>({})

  const { email } = useSelector((state: any) => state.auth.profile)
  const isCompanyEmail = useMemo(() => (email || '').endsWith(`@${COMPANY_DNS}`), [email])

  useEffect(() => {
    setDefaultNotifiers()
  }, [])

  const setDefaultNotifiers = () => {
    const notifiers: EmailValue = {}
    if (loanDetail?.parties.loanOfficer?.email) notifiers[loanDetail?.parties.loanOfficer?.email] = 'to'
    if (loanDetail?.parties.loanProcessor?.email) notifiers[loanDetail?.parties.loanProcessor?.email] = 'to'

    setNotifiers(notifiers)
  }

  const onOpen = () => {
    const newNotifiers: any = cloneDeep(notifiers)
    if (isCompanyEmail) newNotifiers.from = 'self'
    else newNotifiers.from = 'default'
    setNotifiers(newNotifiers)
  }

  const onChange = async (key: string, value: any) => {
    let temp = cloneDeep(inputs)
    temp[key].value = value
    setInputs(temp)
  }

  return (
    <Modal
      title="Decline Request"
      titleOkay="Decline"
      isOpen={true}
      loading={loading === 'updateLoanFields'}
      onClose={onClose}
      onOk={() => onSubmit(inputs.comment.value || '', notifiers)}
      onOpen={onOpen}
    >
      <div className="text-gray-700 w-[500px]">
        <div className="text-shade-blue mb-2">Are you sure want to Decline?</div>
        <div className="grid col-span-1 gap-4 mb-4">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]
            return (
              <div className="col-span-1" key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>

        <LoanPartiesEmailTo loanNumber={loanNumber} value={notifiers} data={[]} onChange={setNotifiers} loadParties />
      </div>
    </Modal>
  )
}
