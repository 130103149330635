export enum AccountingAccountType {
  Bank = 'bank',
  CreditCard = 'creditCard',
  LineOfCredit = 'lineOfCredit',
  WarehouseLine = 'warehouseLine',
}

export const AccountingAccountTypeOptions = {
  [AccountingAccountType.Bank]: 'Bank Account',
  [AccountingAccountType.CreditCard]: 'Credit Card',
  [AccountingAccountType.LineOfCredit]: 'Line of Credit',
  [AccountingAccountType.WarehouseLine]: 'Warehouse Line',
}

export interface IAccountingAccount {
  id: number
  type: AccountingAccountType
  name: string
  number: string

  invalid?: boolean
}
