import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
  CalculatorIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentCheckIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline'
import { setLoanData, setLoanDetail, setLoanGroupData, setLoanLtvMaxLimit } from 'actions'
import cloneDeep from 'clone-deep'
import { EmailValue } from 'components/EmailTo'
import { LayoutLoading } from 'components/LayoutLoading'
import { AccountType, InputType } from 'config'
import { INVALID_ALL_INPUTS } from 'config/api.status.constants'
import {
  calcCLTVFields,
  calcLTVFields,
  convertValueToLabel,
  dscrRelatedFields,
  getInitialLoanFields,
  groupedFields,
  priceLimitRelatedFields,
  priceLoanRelatedFields,
  visibleChangeFields,
} from 'config/loan.constants'
import { usePermissions } from 'hooks/usePermissions'
import { loanStructureStepUpgrade } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  cancelLoanStructureChangeRequest,
  getBorrowerByNo,
  getCurrentProductTypesPrograms,
  getLatestLoanRatesheet,
  getLoanProcessRate,
  getPendingLoanChangeRequest,
  priceLoan,
  saveLoanProcess,
  saveLoanRatesheet,
  saveToPipeLine,
  updateLoanFields,
} from 'services'
import { getLoanPriceDetailsData } from 'services/pdfs/loanStructure'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { svgLoading } from 'stories/assets'
import { Button, Modal, Toggle } from 'stories/components'
import {
  canEditLoanStructureDetail,
  confirm,
  formatDate,
  formatTime,
  InputConvert,
  InputValidate,
  isEmpty,
  removeComma,
  REQUIRED_FIELD_ERROR_MESSAGE,
  useQuery,
} from 'utils'
import { RenderInput } from 'utils/RenderInput'

import Api from '../../services/api'
import { getDefaultLoanProcessData, overrideFields, pendingChangeRequestTypes } from './constant'
import { ExceptionRequest } from './ExceptionRequest'
import { ExceptionResponse } from './ExceptionResponse'
import type {
  IInElibileProduct,
  ILoan,
  ILoanDetail,
  ILoanProcess,
  ILoanRateSheet,
  ILockDay,
  IPendingLoanChangeRequestData,
  IPrice,
  IPrice_Program,
  IPrice_Program_Price,
  IPriceLimit,
  IProduct,
  IProgram,
  ISaveToPipelineParams,
} from './interfaces'
import { LoanDetailInputs } from './LoanDetailInputs'
import { LoanResult } from './LoanResult'
import {
  addRate,
  calculateLoanLimits,
  calculateLTV,
  deleteLockDay,
  deleteProduct,
  deleteProgram,
  deleteRate,
  estimatedClosingAmountBorrower,
  getLockExpired,
  getMaximumTotalLoanAmountTip,
  isNonDSCRProduct,
  loanDataConvert,
  loanFieldsValidateAll,
  showLimitSection,
  updateLockDay,
  updatePrice,
  updateProduct,
  updateProgram,
  updateRateSpreadExitFee,
  validateLoanStructureData,
  visibleLoansLogic,
} from './Logic'
import { DeclineLoanChangeRequest } from './Modals/DeclineLoanChangeRequest'
import { PriceAdjust } from './PriceAdjust'
import { RePriceLoan } from './RePriceLoan'
import { ReviewLock } from './ReviewLock'
import { TermSheetDialog } from './TermSheetDialog'

export function LoanStructure(props: { loan_number: string; forPdf?: boolean }) {
  const { loan_number, forPdf = false } = props

  const [action, setAction] = useState('')
  const [menu, setMenu] = useState('loanDetail')
  const [visibleFields, setVisibleFields] = useState(['productType', 'transactionType'])
  const [loanFields, setLoanFields] = useState(getInitialLoanFields(forPdf))
  const [inEligibleProducts, setInEligibleProducts] = useState<IInElibileProduct>({})
  const [programs, setPrograms] = useState<IProgram[]>([])
  const [products, setProducts] = useState<IProduct[]>([])
  const [selectedProductId, setSelectedProductId] = useState<number>()
  const [selectedProgramId, setSelectedProgramId] = useState<number>()
  const [selectedProgram, setSelectedProgram] = useState<IPrice_Program_Price[]>([])
  const [priceIndex, setPriceIndex] = useState<{ index1: number; index: number }>({ index1: -1, index: -1 })
  const [lockDays, setLockDays] = useState<ILockDay[]>([])
  const [prices, setPrices] = useState<IPrice[]>([])
  const [priceLoanTime, setPriceLoanTime] = useState('')
  const [ratesheetID, setRatesheetID] = useState<number>()
  const [limit, setLimit] = useState<IPriceLimit | undefined>()
  const [showingFields, setShowingFields] = useState(groupedFields)
  const [loading, setLoading] = useState('')
  const [loanDetailSync, setLoanDetailSync] = useState(false)
  const [ratesheetSync, setRatesheetSync] = useState(false)
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [loanProcessData, setLoanProcessData] = useState<ILoanProcess>(getDefaultLoanProcessData())
  const [changedLoanProcessData, setChangedLoanProcessData] = useState<ILoanProcess>(getDefaultLoanProcessData())
  const [originLoanDetail, setOriginLoanDetail] = useState<ILoan | undefined>()
  const [exceptions, setExceptions] = useState<any[]>([])
  const [isOpen, setIsOpen] = useState('')
  const [blurCount, setBlurCount] = useState(false)
  const [limitFieldChangeCount, setlimitFieldChangeCount] = useState(false)
  const [inputChangeFlag, setInputChangeFlag] = useState(false)
  const [pricingCount, setPricingCount] = useState(-1)
  const [timerID, setTimerID] = useState<any>(null)
  const [rateSheetExpired, setRateSheetExpired] = useState(false)
  const [sandBoxMode, setSandBoxMode] = useState(false)
  const [pendingChangeRequest, setPendingChangeRequest] = useState<IPendingLoanChangeRequestData | undefined>()
  const [openRevalidateSignal, setOpenRevalidateSignal] = useState(0)
  const [loanAmountFillTry, setLoanAmountFillTry] = useState(0)
  const [loanClosingFillTry, setLoanClosingFillTry] = useState(0)
  const [modal, setModal] = useState('')
  const [ovFields, setOvFields] = useState<Record<string, InputType>>({})
  const [loanRateSheetData, setLoanRateSheetData] = useState<ILoanRateSheet>()
  const [isUpdated, setIsUpdated] = useState(false)
  const [showSub, setShowSub] = useState(false)

  const dispatch = useDispatch()
  const navigate = useHistory()
  const location = useLocation()
  const locationQuery = useQuery()
  const borrowerNo = Number(locationQuery.get('borrowerNo')) || 0
  const entity = locationQuery.get('entity') || -1

  const { hasPermission, data: permissionData } = usePermissions()

  const isAdmin = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')
  const canExceptionPricing = hasPermission('CAN_RESPONSE_ON_EXCEPTION_REQUEST')
  const canRelockRate = hasPermission('CAN_RELOCK_RATE') == true
  const anytimeCanEditLoanDetail = hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE')
  const canDoManualPricing = !!hasPermission('CAN_DO_MANUAL_PRICING')
  const canSeeSubPrograms = !!hasPermission('CAN_SEE_SUB_PROGRAMS')

  const canEditLoanDetail = canEditLoanStructureDetail()

  const { auth, loan, structureStep, applicationStep, loanDetail, ltvMaxLimit } = useSelector((state: any) => {
    return {
      auth: state.auth,
      loan: state.loan as ILoan,
      structureStep: state.step.overview.structure.detail,
      applicationStep: state.step.overview.application.detail,
      loanDetail: state.loanDetail as ILoanDetail,
      ltvMaxLimit: state.ltvMaxLimit,
    }
  })

  const canPriceAdjust = [AccountType.ADMIN, AccountType.LOCK_DESK].includes(auth.profile.accountType)
  const pricingEngineIsOff = !loanDetail.pricingEngine

  const canOnlyRevalidate = useMemo(() => {
    return canEditLoanDetail && !canRelockRate
  }, [canEditLoanDetail, canRelockRate])

  const needExceptionReviewRequest = useMemo(() => {
    return canEditLoanDetail && !canExceptionPricing
  }, [canExceptionPricing, canEditLoanDetail])

  const hidePendingExceptionReviewRequest = useMemo(() => {
    return pendingChangeRequest?.type === 2 && !canEditLoanDetail
  }, [pendingChangeRequest, canEditLoanDetail])

  const brokerCanNotPrice = useMemo(() => {
    let rlt = false
    if (loanProcessData.rateLocked) rlt = true
    return rlt
  }, [loanProcessData.rateLocked])

  const brokerCanNotEditDetail = useMemo(() => {
    let rlt = false
    if (loanProcessData.rateLocked) rlt = true
    if (loanDetail.restructureApplicationRelease === false) rlt = true
    return rlt
  }, [loanProcessData.rateLocked, loanDetail.restructureApplicationRelease])

  const priceAdjustmentObject = useMemo(() => {
    let rlt: any = {}
    loanDetail.priceAdjustments.map((item: any) => {
      rlt[item.type] = item
    })
    return rlt
  }, [loanDetail.priceAdjustments])

  const changedLoanDetailJson = useMemo(() => {
    let rlt: any = {}

    if (!originLoanDetail) return rlt

    let ltvChanged = false
    let cltvChanged = false

    Object.keys(loanFields).map((key) => {
      // if (key === 'estimatedCreditScore' && loanDetail.submitToLoanSetup === 0) {
      // } else {
      if ((loan as any)[key] != (originLoanDetail as any)[key] && (originLoanDetail as any)[key] !== undefined) {
        rlt[key] = {
          label: loanFields[key].title,
          origin: (originLoanDetail as any)[key],
          changed: (loan as any)[key],
        }

        if (loanFields[key].inputType === 'text' && (loanFields[key].type === 'text' || isEmpty(loanFields[key].type)))
          rlt[key].changed = (loan as any)[key].trim()

        if (key === 'additionalPropertyAddress') {
          try {
            if (JSON.stringify(loan[key]) === JSON.stringify((originLoanDetail as any)[key])) delete rlt[key]
            if (loan[key]?.length === 0 && (originLoanDetail as any)[key]?.length === 0) {
              delete rlt[key]
            }
          } catch {}
        }
        if (calcLTVFields.indexOf(key) !== -1) ltvChanged = true
        if (calcCLTVFields.indexOf(key) !== -1) cltvChanged = true
        if (key === 'subjectPropertyAddress') {
          const key2 = 'propertyCounty'
          if (loan[key2] !== (originLoanDetail as any)[key2]) {
            rlt[key2] = {
              label: 'Property County',
              origin: (originLoanDetail as any)[key2],
              changed: loan[key2],
            }
          }
        }

        if (dscrRelatedFields.includes(key)) {
          const originDSCR = combinedMonthlyExpense(originLoanDetail, loanProcessData, true).dscr
          const changedDSCR = combinedMonthlyExpense(loan, changedLoanProcessData, true).dscr

          if (Number(originDSCR) !== Number(changedDSCR))
            rlt['dscr'] = {
              label: 'Calculated DSCR',
              origin: originDSCR,
              changed: changedDSCR,
            }
        }
      }
      // }
    })

    if (ltvChanged) {
      const _origin = calculateLTV(originLoanDetail)
      const _changed = calculateLTV()
      if (Number(_origin) !== Number(_changed)) {
        rlt['ltv'] = {
          label: 'LTV',
          origin: _origin,
          changed: _changed,
        }
      }
    }
    if (cltvChanged) {
      const _origin = calculateLTV(originLoanDetail, true)
      const _changed = calculateLTV(null, true)
      if (Number(_origin) !== Number(_changed)) {
        rlt['cltv'] = {
          label: 'CLTV',
          origin: _origin,
          changed: _changed,
        }
      }
    }

    return rlt
  }, [originLoanDetail, loan, loanProcessData, changedLoanProcessData])

  const exceptionApproved = useMemo(() => {
    return loanProcessData?.sheetDate?.includes('(E)') && loanProcessData?.rate > 0
  }, [loanProcessData])

  const canSeeReviewLock = useMemo(() => {
    if (loanDetail.loanNumber === 'New') return false
    const alwaysCanSeeReviewLock = hasPermission('ALWAYS_CAN_SEE_REVIEW_LOCK')
    return alwaysCanSeeReviewLock || structureStep.lockRate !== -1
  }, [structureStep, permissionData])

  const lockExpired = useMemo(() => {
    return getLockExpired(loanProcessData)
  }, [loanProcessData.rateLocked, loanProcessData.expireDate])

  const showRePrice = useMemo(() => {
    let rlt = false
    rlt = !forPdf && brokerCanNotEditDetail && canEditLoanDetail

    if (lockExpired) rlt = hasPermission('CAN_RELOCK_RATE') === true || canOnlyRevalidate

    if (sandBoxMode) rlt = true
    if (exceptionApproved && anytimeCanEditLoanDetail) rlt = true
    return rlt
  }, [brokerCanNotPrice, canEditLoanDetail, sandBoxMode, lockExpired, exceptionApproved, anytimeCanEditLoanDetail])

  const maximumTotalLoanAmountTip = useMemo(() => {
    return getMaximumTotalLoanAmountTip(loan, limit)
  }, [loan, limit])

  useEffect(() => {
    const tempVisibleFields = cloneDeep(visibleLoansLogic())
    setVisibleFields(tempVisibleFields)
    setLoanFields(loanFieldsValidateAll(loanFields))

    const _originLoanDetail = loanDataConvert(loanFields)
    setOriginLoanDetail(_originLoanDetail)

    const syncPricing = async () => {
      if (loan_number !== 'New') {
        try {
          setRatesheetSync(true)
          const [loanProcessData, loanRatesheetData] = await Promise.all([
            getLoanProcessRate(),
            getLatestLoanRatesheet(),
          ])
          if (loanRatesheetData.success) {
            const ratesheetData = loanRatesheetData.data.data
            setLoanResultFeed(loanRatesheetData.data, true)

            if (ratesheetData.limit) {
            } else {
              onGetPriceLimit()
            }
          } else {
            onPriceLoan(true, tempVisibleFields)
            onGetPriceLimit()
          }
          if (loanProcessData.success) {
            const processData: ILoanProcess = loanProcessData.data
            setLoanProcessData(processData)
            setExceptions(loanProcessData.data.exceptions)
          }
          setRatesheetSync(false)
        } catch (error) {
          console.log(error)
          setRatesheetSync(false)
        }
      }
    }

    syncPricing()
  }, [])

  useEffect(() => {
    setChangedLoanProcessData(loanProcessData)
  }, [loanProcessData])

  useEffect(() => {
    if (brokerCanNotEditDetail || exceptionApproved) {
      updatePendingLoanChangeRequest()
    }
  }, [brokerCanNotEditDetail, exceptionApproved])

  useEffect(() => {
    if (!forPdf) {
      dispatch(
        setLoanDetail({
          rateData: loanProcessData,
        }),
      )
    }
  }, [loanProcessData])

  useEffect(() => {
    if (pricingCount === 60) {
      setRateSheetExpired(false)
      if (timerID !== null) clearTimeout(timerID)
    }
    if (pricingCount > 0) {
      const id = setTimeout(() => setPricingCount(pricingCount - 1), 1000)
      setTimerID(id)
    }
    if (pricingCount === 0) setRateSheetExpired(true)
  }, [pricingCount])

  useEffect(() => {
    onGetPriceLimit()
  }, [ltvMaxLimit])

  useEffect(() => {
    const flag: { [key: string]: boolean } = {}
    visibleFields.map((key) => (flag[key] = true))

    const nShowedFields: string[][] = []

    groupedFields.map((fields) => {
      const newFields = fields.filter((key) => flag[key] === true)
      if (newFields.length > 0) {
        nShowedFields.push(newFields)
      }
    })

    setShowingFields(nShowedFields)
  }, [visibleFields])

  useEffect(() => {
    if (loanAmountFillTry > 0) onBlur('proposedLoanAmount')
  }, [loanAmountFillTry])

  useEffect(() => {
    if (loanClosingFillTry > 0) onBlur('constructionReserve')
  }, [loanClosingFillTry])

  const rateAdjustment = useMemo(() => {
    let rlt = 0
    try {
      loanDetail.priceAdjustments.map((item) => {
        rlt += removeComma(item.rate)
      })
    } catch {}
    return rlt
  }, [loanDetail.priceAdjustments])

  useEffect(() => {
    if (visibleFields.length > 2) onPriceLoan()
  }, [rateAdjustment])

  useEffect(() => {
    if (visibleFields.length > 2) onPriceLoan()
  }, [showSub])

  const onConfirmOverride = async () => {
    setAction('exceptionPricing')
    let errors = 0
    let temp = cloneDeep(ovFields)
    let json: any = {}
    Object.keys(temp).map((key) => {
      if (['override'].indexOf(key) !== -1) return
      json[key] = temp[key].value
      temp[key].error = InputValidate({ ...temp[key] })
      if ((temp as any)[key].error.length > 0) errors += 1
    })
    setOvFields(temp)
    if (errors) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    json.sheetDate = `${formatDate('now')}(E)`
    await saveLoanProcess({
      ...json,
      historyAction: 'Exception on Ratesheet',
    })

    setLoanProcessData(json)

    setAction('')
    setModal('')

    return true
  }

  const onChangeOverrideField = (key: string, e: any) => {
    let temp = cloneDeep(ovFields)
    temp[key].value = e
    temp[key].error = InputValidate({ ...temp[key] })
    setOvFields(temp)
  }

  const updatePendingLoanChangeRequest = async () => {
    const res = await getPendingLoanChangeRequest()

    if (res.success) {
      setPendingChangeRequest(res.data)
    }
  }

  const applyPendingChangeRequest = () => {
    if (!originLoanDetail) return

    if (pendingChangeRequest?.loanDetailChanges && !hidePendingExceptionReviewRequest) {
      let temp = cloneDeep(originLoanDetail)
      const { loanDetailChanges } = pendingChangeRequest
      Object.keys(temp).map((key) => {
        if (loanDetailChanges[key]) {
          ;(temp as any)[key] = loanDetailChanges[key].changed
        }
      })
      changeAllLoanDetalAndVisibleWithValidate(temp)
    }
  }

  const onCancelChangeRequest = async (isDecline: boolean) => {
    if (!pendingChangeRequest) return

    let result

    if (isDecline) {
      setModal('decline')
      return
    } else {
      result = await confirm(`Are you sure want to Cancel?`)
      if (!result) return
    }

    changeSandBoxMode(false)
    setLoanDetailSync(true)
    setAction('updateLoanFields')

    const req = {
      taskID: pendingChangeRequest.taskID,
      isDecline,
      comment: isDecline ? result : '',
    }

    const res = await cancelLoanStructureChangeRequest(req)

    if (res.success) {
      setLoanDetailSync(false)
      setAction('')
      setPendingChangeRequest(undefined)
    }
  }

  const onDeclineRequest = async (comment: string, emailTo: EmailValue) => {
    if (!pendingChangeRequest) return

    changeSandBoxMode(false)
    setLoanDetailSync(true)
    setAction('updateLoanFields')

    const req = {
      taskID: pendingChangeRequest.taskID,
      isDecline: true,
      comment,
      emailTo,
    }

    const res = await cancelLoanStructureChangeRequest(req)

    if (res.success) {
      setLoanDetailSync(false)
      setAction('')
      setPendingChangeRequest(undefined)
      setModal('')
    }
  }

  const onBlur = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    setBlurCount(!blurCount)
    if (brokerCanNotEditDetail || exceptionApproved) {
      if (key === 'estimatedCreditScore' && loanDetail.submitToLoanSetup === 0) {
      } else {
        return
      }
    }
    let continueSaveChange = true
    if (loanFields[key].error?.length) continueSaveChange = false
    if (canPriceAdjust && priceAdjustmentObject[key] !== undefined && (loan as any)[key] !== '')
      continueSaveChange = true

    if (!continueSaveChange) {
      if (priceLoanRelatedFields.indexOf(key) > -1) {
        const tmpLoanProcessData = cloneDeep(loanProcessData)
        const { rate } = tmpLoanProcessData
        if (rate != 0 && rate != -1 && !exceptionApproved) {
          tmpLoanProcessData.rate = -1
          setLoanProcessData(tmpLoanProcessData)
        }
        setPrices([])
        setSelectedProgram([])
        setInEligibleProducts({})
      }
      return
    }
    setLoanDetailSync(true)
    setRatesheetSync(true)
    try {
      let promises = []
      let nothingRelatedForPricing = true
      if (loan_number !== 'New') {
        await _updateLoanFields(key)
      }
      if (priceLimitRelatedFields.indexOf(key) > -1) {
        promises.push(onGetPriceLimit())
        nothingRelatedForPricing = false
      }
      if (priceLoanRelatedFields.indexOf(key) > -1) {
        promises.push(onPriceLoan(true))
        nothingRelatedForPricing = false
      }
      await Promise.all(promises)

      if (!nothingRelatedForPricing)
        if ((originLoanDetail as any)[key] != (loan as any)[key]) {
          const tmpLoanProcessData = cloneDeep(loanProcessData)
          const { price } = tmpLoanProcessData

          if (price != 0 && !exceptionApproved) {
            tmpLoanProcessData.rate = -1
            tmpLoanProcessData.price = 0
            await saveLoanProcess({
              rate: -1,
              price: 0,
              historyAction: `Clear Price (${loanFields[key].title}: ${convertValueToLabel(
                key,
                (originLoanDetail as any)[key],
              )} -> ${convertValueToLabel(key, (loan as any)[key])})`,
            }) // this will delete the Loan-Ratesheet Also
            setLoanProcessData(tmpLoanProcessData)
            setPriceIndex({ index1: -1, index: -1 })
            await loanStructureStepUpgrade('rateAndPrice', 0)
          }
        }
      setRatesheetSync(false)
      setLoanDetailSync(false)
    } catch (error) {
      setRatesheetSync(false)
      setLoanDetailSync(false)
      console.log(error)
    }
  }

  const onClearException = async () => {
    const content = (
      <div className="text-lg text-gray-800 font-medium mb-4">Would you like to clear the Exception Price?</div>
    )

    const result = await confirm(content)

    if (!result) return

    setLoanDetailSync(true)
    setRatesheetSync(true)
    try {
      const tmpLoanProcessData = cloneDeep(loanProcessData)

      tmpLoanProcessData.rate = -1
      tmpLoanProcessData.price = 0
      tmpLoanProcessData.sheetDate = ''

      await saveLoanProcess({
        rate: -1,
        price: 0,
        sheetDate: '',
        historyAction: `Clear Exception`,
      }) // this will delete the Loan-Ratesheet Also
      setLoanProcessData(tmpLoanProcessData)
      setPriceIndex({ index1: -1, index: -1 })
      await loanStructureStepUpgrade('rateAndPrice', 0)

      setRatesheetSync(false)
      setLoanDetailSync(false)
    } catch (error) {
      setRatesheetSync(false)
      setLoanDetailSync(false)
      console.log(error)
    }
  }

  const onClearPrice = async () => {
    const content = <div className="text-lg text-gray-800 font-medium mb-4">Would you like to clear the Price?</div>

    const result = await confirm(content)

    if (!result) return

    setLoanDetailSync(true)
    setRatesheetSync(true)
    try {
      const tmpLoanProcessData = cloneDeep(loanProcessData)

      tmpLoanProcessData.rate = -1
      tmpLoanProcessData.price = 0
      tmpLoanProcessData.sheetDate = ''

      await saveLoanProcess({
        rate: -1,
        price: 0,
        sheetDate: '',
        historyAction: `Clear Price`,
      }) // this will delete the Loan-Ratesheet Also
      setLoanProcessData(tmpLoanProcessData)
      setPriceIndex({ index1: -1, index: -1 })
      await loanStructureStepUpgrade('rateAndPrice', 0)

      setRatesheetSync(false)
      setLoanDetailSync(false)
    } catch (error) {
      setRatesheetSync(false)
      setLoanDetailSync(false)
      console.log(error)
    }
  }

  const onChangeLoanInputFields = async (key: string, e: any) => {
    setFieldValueChanged(true)
    let value = InputConvert(loanFields[key], e)

    await dispatch(setLoanData({ key: key, data: value }))

    if (visibleChangeFields.indexOf(key) !== -1) {
      setVisibleFields(visibleLoansLogic())
    }

    const error = InputValidate({ ...loanFields[key], value }, loanDetail.loanNumber, loanDetail)
    let tempLoanFields: Record<string, InputType> = {}
    let needLoanFieldChange = false

    if (error !== loanFields[key].error) {
      tempLoanFields = cloneDeep(loanFields)
      tempLoanFields[key].error = error
      needLoanFieldChange = true
    }

    if (priceLimitRelatedFields.indexOf(key) !== -1) {
      tempLoanFields = loanFieldsValidateAll(cloneDeep(loanFields))
      if (borrowerNo && ['productType', 'transactionType'].includes(key)) {
        const borrower = await getBorrowerByNo(borrowerNo)
        const params = borrower.fullName.split(' ')
        const borrowerFirstName = params[0]
        const borrowerLastName = params.length > 2 ? params[2] : params[1]
        const borrowerMiddleName = params.length > 2 ? params[1] : ''
        if (borrowerFirstName) {
          await dispatch(setLoanData({ key: 'borrowerFirstName', data: borrowerFirstName }))
          tempLoanFields.borrowerFirstName.error = ''
        }
        if (borrowerLastName) {
          await dispatch(setLoanData({ key: 'borrowerLastName', data: borrowerLastName }))
          tempLoanFields.borrowerLastName.error = ''
        }
        if (borrowerMiddleName) {
          await dispatch(setLoanData({ key: 'borrowerMiddleName', data: borrowerMiddleName }))
          tempLoanFields.borrowerMiddleName.error = ''
        }
      }
      needLoanFieldChange = true

      setlimitFieldChangeCount(!limitFieldChangeCount)
      setTimeout(() => {
        onGetPriceLimit()
      }, 300)
      dispatch(setLoanLtvMaxLimit({}))
    }

    if (['productType', 'transactionType'].includes(key)) {
      const productType = key === 'productType' ? value : loan.productType
      if (isNonDSCRProduct(productType)) {
        await dispatch(setLoanData({ key: 'amortizationType', data: 'IO - Fixed' }))
      }
    }

    if (needLoanFieldChange) {
      setLoanFields(tempLoanFields)
    }

    setInputChangeFlag(!inputChangeFlag)
  }

  const checkValidation = (type: string, customVisibleFields: string[] = []) => {
    const {
      minTotalConstructionFinanced,
      maxTotalConstructionFinanced,
      minConstructionFinancedHoldback,
      maxConstructionFinancedHoldback,
    } = loanDetail.loanGlobalSettings
    let flag = true
    let _visibleFields = visibleFields
    if (customVisibleFields.length > 0) {
      _visibleFields = customVisibleFields
    }
    switch (type) {
      case 'PriceLoan':
        _visibleFields.map((key) => {
          if (priceLoanRelatedFields.indexOf(key) !== -1) {
            if (loanFields[key]?.error?.length) {
              if (priceAdjustmentObject[key] !== undefined && (loan as any)[key] !== '') {
              } else {
                flag = false
              }
            }
          }
        })
        let { closingError, rehabFinancedPercent, totalRehabFinancedPercent } = estimatedClosingAmountBorrower(
          loan,
          loanDetail.loanGlobalSettings,
        )
        if (closingError.length) flag = false
        if (removeComma(rehabFinancedPercent) > removeComma(maxConstructionFinancedHoldback)) flag = false
        if (
          removeComma(rehabFinancedPercent) > 0 &&
          removeComma(rehabFinancedPercent) < removeComma(minConstructionFinancedHoldback)
        )
          flag = false
        if (removeComma(totalRehabFinancedPercent) > removeComma(maxTotalConstructionFinanced)) flag = false
        if (
          removeComma(totalRehabFinancedPercent) > 0 &&
          removeComma(totalRehabFinancedPercent) < removeComma(minTotalConstructionFinanced)
        )
          flag = false
        break
      case 'SavePipeline':
        _visibleFields.map((key) => {
          if (!!loanFields[key]?.error?.length && loanFields[key].error === REQUIRED_FIELD_ERROR_MESSAGE) flag = false
        })
        break
    }
    return flag
  }

  const _saveToPipeline = async (data: ISaveToPipelineParams = {}) => {
    if (!auth?.isAuthenticated) return toast('Please sign in to create a new loan.', { type: 'error' })

    if (checkValidation('SavePipeline')) {
      let reqBody: { [key: string]: any } = {
        propertyCounty: loan['propertyCounty'],
      }
      visibleFields.map((field) => {
        reqBody[field] = (loan as any)[field]
      })
      reqBody.loanPriceDetails = await getLoanPriceDetailsData(showingFields, loanProcessData)
      if (Object.keys(data).length > 0) {
        reqBody.saveToPipelineWithPricingData = data
      }
      setLoading('SaveToPipeline')
      try {
        if (borrowerNo) reqBody.borrowerNo = borrowerNo
        if (Number(entity) >= 0) reqBody.borrowerEntity = Number(entity)

        const result = await saveToPipeLine(reqBody)
        Api.setLoanNumber(result.loanNumber)

        if (loan_number === 'New' && pricingEngineIsOff && !Object.keys(data).length) {
          const ratesheetJson: ILoanRateSheet = {
            ratesheetID: 0,
            data: {
              relockTimes: 0,
              prices,
              priceLoanTime,
              programs,
              products,
              priceAdjustments: [],
              selectedProgram,
              lockDays,
              limit,
              selectedProductId,
              selectedProgramId,
              priceIndex,
            },
          }

          setRatesheetSync(true)
          await saveLoanRatesheet(ratesheetJson)
          setRatesheetSync(false)
        }

        navigate.push(location.pathname.replace('new', result.loanNumber))
      } catch (error: any) {
        console.log(error)
        toast(error, { type: 'error' })
      }
      setLoading('')
    } else {
      toast(INVALID_ALL_INPUTS, { type: 'error' })
      setRatesheetSync(false)
    }
  }

  const _updateLoanFields = async (key: string) => {
    return new Promise((resolve, reject) => {
      const reqBody: { [key: string]: any } = {}
      reqBody[key] = (loan as any)[key]
      if (loanFields[key].inputType === 'text' && (loanFields[key].type === 'text' || isEmpty(loanFields[key].type)))
        reqBody[key] = (loan as any)[key].trim()

      if (key === 'subjectPropertyAddress') {
        const key2 = 'propertyCounty'
        reqBody[key2] = loan[key2]
      }

      setAction('updateLoanFields')
      updateLoanFields(reqBody)
        .then((result) => {
          console.log('Loan Updated: ', result)
          resolve(true)
          setAction('')
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  const onGetPriceLimit = async () => {
    return new Promise(
      async (
        resolve,
        // reject
      ) => {
        if (showLimitSection()) {
          let pricingResultIsEmpty = true
          prices.forEach((item) => {
            if (!!item.Programs.length) pricingResultIsEmpty = false
          })

          if (pricingResultIsEmpty && !!programs.length) {
            if (
              loanProcessData &&
              loanProcessData?.sheetDate?.includes('(E)') &&
              loanProcessData?.rate !== -1 &&
              loanProcessData?.price !== 0
            ) {
              const exceptionApprovedProgram = programs.find((item) => item.Name === loanProcessData.program)

              if (exceptionApprovedProgram)
                setLimit(calculateLoanLimits(null, false, exceptionApprovedProgram.LtvLimit))
              else setLimit(calculateLoanLimits(null, false, programs[0]?.LtvLimit))
            } else setLimit(calculateLoanLimits(null, false, programs[0]?.LtvLimit))
          } else setLimit(calculateLoanLimits())

          resolve(true)
        } else {
          setLimit(undefined)
          resolve(true)
        }
      },
    )
  }

  const initializePriceData = (
    pricesData: IPrice[],
    inEligibleObjectData: IInElibileProduct,
    programsData: IProgram[],
  ) => {
    setInEligibleProducts(inEligibleObjectData)
    setPrograms(programsData)
    setPrices(pricesData)
    setPriceIndex({ index1: -1, index: -1 })

    if (pricesData && pricesData.length > 0) {
      let prodId = Infinity
      let progId = Infinity

      if (showSub && Number(loanProcessData.rate) !== -1) {
        const targetProduct = pricesData.find((item) => item.ProductName === loanProcessData.type)
        if (targetProduct) {
          prodId = targetProduct.ProductID
          const targetProgram = pricesData
            .find((item) => item.ProductID === prodId)
            ?.Programs.find((item) => {
              const targetRate = item.Prices.find((price) => price.Rate === Number(loanProcessData.rate))
              const isExist = targetRate?.LockTermPrices.find(
                (rate) =>
                  Number(rate.LockDay.replace(/\D/g, '')) === loanProcessData.lockDays &&
                  Number(rate.Price) === Number(loanProcessData.price),
              )
              if (isExist) return true
              else return false
            })

          if (targetProgram) {
            progId = targetProgram.ProgramID

            const targetRateIndex = targetProgram.Prices.findIndex((item) => item.Rate === Number(loanProcessData.rate))
            const targetPriceIndex = targetProgram.Prices.find(
              (item) => item.Rate === Number(loanProcessData.rate),
            )?.LockTermPrices.findIndex(
              (item) =>
                Number(item.LockDay.replace(/\D/g, '')) === loanProcessData.lockDays &&
                Number(item.Price) === Number(loanProcessData.price),
            )

            setPriceIndex({ index: targetRateIndex, index1: Number(targetPriceIndex) })
          }
        }
      } else
        for (let i = 0; i < pricesData.length; i++) {
          if (pricesData[i].Programs.length > 0) {
            prodId = pricesData[i].ProductID
            progId = pricesData[i].Programs[0].ProgramID
            break
          }
        }

      if (prodId !== Infinity && progId !== Infinity) {
        setSelectedProductId(prodId)
        setSelectedProgramId(progId)

        const targetPrices = pricesData
          .find((item) => item.ProductID === prodId)
          ?.Programs.find((item) => item.ProgramID === progId)?.Prices

        if (targetPrices) setSelectedProgram(targetPrices)
        else setSelectedProgram([])

        const LtvLimit = programsData.find((item) => item.ID === progId)?.LtvLimit
        if (LtvLimit !== undefined) {
          dispatch(setLoanLtvMaxLimit(LtvLimit))
        }
      } else {
        setSelectedProductId(undefined)
        setSelectedProgramId(undefined)
        setSelectedProgram([])
        dispatch(setLoanLtvMaxLimit({}))
      }
    }
  }

  const onPriceLoan = (isSync: boolean = true, customVisibleFields: Array<string> = []) => {
    if (pricingEngineIsOff) return
    return new Promise((resolve, reject) => {
      if (checkValidation('PriceLoan', customVisibleFields)) {
        //price loan
        const reqBody: { [key: string]: any } = {}
        if (customVisibleFields.length === 0) {
          visibleFields.map((field) => {
            reqBody[field] = (loan as any)[field]
          })
        } else {
          customVisibleFields.map((field) => {
            reqBody[field] = (loan as any)[field]
          })
        }

        reqBody['showSubPrograms'] = showSub
        reqBody['changedLoanDetails'] = Object.keys(changedLoanDetailJson)

        setSelectedProgram([])
        setPrices([])
        setLoading('PriceLoan')
        priceLoan(reqBody)
          .then((result) => {
            setLoading('')
            if (result.success) {
              const res = result.data
              setRatesheetID(res.RatesheetID)
              setProducts(res.MortgageProducts)
              setLockDays(res.LockDays)
              setPriceLoanTime(formatTime('now', 'MM/DD/YYYY HH:mm:ss'))
              setPricingCount(60)
              initializePriceData(res.Prices, res.InEligibleProducts, res.Programs)
              resolve(true)
            } else {
              setLoading('')
              reject(false)
            }
          })
          .catch((error) => {
            setLoading('')
            console.log(error)
            reject(error)
          })
          .finally(() => {})
      } else {
        if (!isSync) {
          toast(INVALID_ALL_INPUTS, { type: 'error' })
        }
        setPrices([])
        setSelectedProgram([])
        resolve(false)
      }
    })
  }

  const handleSelectProgram = (programId: number, productId: number) => {
    if (loanProcessData.rateLocked) {
      toast('Rate Locked!', { type: 'error' })
      return
    }
    const arr = prices
      .find((item: IPrice) => item.ProductID === productId)
      ?.Programs.find((item1: IPrice_Program) => item1.ProgramID === programId)?.Prices

    let LtvLimit: IPriceLimit | undefined = undefined
    programs.map((item: IProgram) => {
      if (item.ID === programId && !!item?.LtvLimit) LtvLimit = item.LtvLimit
    })
    if (LtvLimit !== undefined) {
      dispatch(setLoanLtvMaxLimit(LtvLimit))
    }

    if (arr) {
      setSelectedProgram(arr)
      setSelectedProductId(productId)
      setSelectedProgramId(programId)
      setPriceIndex({ index1: -1, index: -1 })
    }
  }

  const setLoanResultFeed = (data: ILoanRateSheet, withCurrentLTV = false) => {
    if (!data) return
    const ratesheetData = data.data
    setLoanRateSheetData(data)
    setPrices(ratesheetData.prices)
    setPriceLoanTime(ratesheetData.priceLoanTime)
    setSelectedProductId(ratesheetData.selectedProductId)
    setSelectedProgramId(ratesheetData.selectedProgramId)
    setSelectedProgram(ratesheetData.selectedProgram)
    setProducts(ratesheetData.products)
    setPrograms(ratesheetData.programs)
    setLockDays(ratesheetData.lockDays)
    setPriceIndex(ratesheetData.priceIndex)
    if (withCurrentLTV) {
      const curLTVs = calculateLoanLimits()
      setLimit({
        ...ratesheetData.limit,
        aiv_ltv: curLTVs.aiv_ltv,
        arv_ltv: curLTVs.arv_ltv,
        ltc: curLTVs.ltc,
        ltp: curLTVs.ltp,
      })
    } else {
      setLimit(ratesheetData.limit)
    }
    setRatesheetID(data.ratesheetID)
  }

  const syncOriginLoanDetailWithCurrent = () => {
    let temp = cloneDeep(originLoanDetail)
    Object.keys(changedLoanDetailJson).map((key) => {
      ;(temp as any)[key] = changedLoanDetailJson[key].changed
    })
    setOriginLoanDetail(temp)
  }

  const setLoanProcessDataFromChild = (data: ILoanProcess) => {
    let temp = cloneDeep(loanProcessData)
    temp = {
      ...temp,
      ...data,
    }
    setLoanProcessData(temp)
  }

  const generateLoanTerm = () => {
    if (validateLoanStructureData(loan, loanProcessData, true).length) return

    if (!loan.borrowerFirstName && !loan.borrowerLastName) {
      toast('Please fill the Guarantor Name.', { type: 'error' })
      return
    }

    setIsOpen('TermSheetDialog')
  }

  const changeAllLoanDetalAndVisibleWithValidate = (data: ILoan) => {
    dispatch(setLoanGroupData(data))
    setTimeout(() => {
      setVisibleFields(visibleLoansLogic())
      setLoanFields(loanFieldsValidateAll(cloneDeep(loanFields)))
    }, 100)
  }

  const revertLoanDetailChanges = () => {
    if (!originLoanDetail) return
    changeAllLoanDetalAndVisibleWithValidate(originLoanDetail)
  }

  const changeSandBoxMode = (value: boolean) => {
    if (!value) revertLoanDetailChanges()
    else {
      applyPendingChangeRequest()
    }
    setTimeout(() => {
      setSandBoxMode(value)
    }, 200)
  }

  const fillLoanAmountMax = () => {
    onChangeLoanInputFields('proposedLoanAmount', maximumTotalLoanAmountTip.minValue)
    setTimeout(() => {
      setLoanAmountFillTry(loanAmountFillTry + 1)
    }, 300)
  }

  const fillClosingAmountMax = (possibleConstructionReserveMin: number) => {
    onChangeLoanInputFields('constructionReserve', possibleConstructionReserveMin)
    setTimeout(() => {
      setLoanClosingFillTry(loanClosingFillTry + 1)
    }, 300)
  }

  const onExceptionPricing = async () => {
    setModal('exceptionPricing')
    const temp: any = overrideFields()

    if (!isNonDSCRProduct(loan.productType)) {
      delete temp['rateSpread']
      delete temp['exitFee']
    }

    setAction('exceptionPricing')
    const res = await getCurrentProductTypesPrograms()
    setAction('')

    temp['type'].options = res.productTypes
    temp['program'].options = res.programNames

    setOvFields(temp)
  }

  const addProduct = (data: IProduct, isUpdate: boolean) => {
    const { newProducts, newPrices } = updateProduct(products, prices, data, isUpdate)

    updateSelectedProgram(newPrices)
    setPrices(newPrices)
    setProducts(newProducts)
    setIsUpdated(true)
  }

  const removeProduct = async (product: IProduct) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Product?
        <br />
        <span className="text-gray-600 text-base">Product Name: {product.Description}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    if (selectedProductId === product.Id) {
      setSelectedProgramId(undefined)
      setSelectedProductId(undefined)
      setSelectedProgram([])
    }
    const { newProducts, newPrices } = deleteProduct(products, prices, product)

    setPrices(newPrices)
    setProducts(newProducts)
    setIsUpdated(true)
  }

  const addProgram = (product: IProduct, program: IProgram, isUpdate: boolean) => {
    const { newPrices, newPrograms } = updateProgram(programs, prices, lockDays, product, program, isUpdate)

    setPrograms(newPrograms)
    setPrices(newPrices)
    setIsUpdated(true)
  }

  const removeProgram = async (productID: number, programID: number) => {
    const targetProduct = products.find((item) => item.Id === productID)
    const targetProgram = programs.find((item) => item.ID === programID)
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Program?
        <br />
        <span className="text-gray-600 text-base">Product: {targetProduct?.Description}</span>
        <br />
        <span className="text-gray-600 text-base">Program: {targetProgram?.Name}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const { newPrices } = deleteProgram(prices, productID, programID)

    if (productID === selectedProductId && programID === selectedProgramId) {
      setSelectedProgram([])
      setSelectedProductId(undefined)
      setSelectedProgramId(undefined)
      setPriceIndex({ index: -1, index1: -1 })
    } else updateSelectedProgram(newPrices)

    setPrices(newPrices)
    setIsUpdated(true)
  }

  const updateSelectedProgram = (nPrices: IPrice[]) => {
    const nSelectedProgram = nPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices
    setSelectedProgram(nSelectedProgram || selectedProgram)
  }

  const addLockDay = (lockDayIndex: number, value: number, isUpdate: boolean) => {
    if (selectedProductId === undefined || selectedProgramId === undefined) return

    const { newLockDays, newPrices } = updateLockDay(prices, lockDays, lockDayIndex, value, isUpdate)
    updateSelectedProgram(newPrices)
    setPrices(newPrices)
    setLockDays(newLockDays)
    setIsUpdated(true)
  }

  const removeLockDay = (index: number) => {
    const { newLockDays, newPrices } = deleteLockDay(prices, lockDays, index)
    updateSelectedProgram(newPrices)
    setPrices(newPrices)
    setLockDays(newLockDays)
    setIsUpdated(true)
  }

  const onAddRate = (index: number, value: number) => {
    if (selectedProductId === undefined || selectedProgramId === undefined) return

    const { newPrices } = addRate(prices, lockDays, selectedProductId, selectedProgramId, index, value)
    updateSelectedProgram(newPrices)
    setPrices(newPrices)
    setIsUpdated(true)
  }

  const onRemoveRate = async (index: number) => {
    if (selectedProductId === undefined || selectedProgramId === undefined) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Rate?
        <br />
        <span className="text-gray-600 text-base">Rate: {selectedProgram[index].Rate + rateAdjustment}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const { newPrices } = deleteRate(prices, selectedProductId, selectedProgramId, index)
    updateSelectedProgram(newPrices)
    setPrices(newPrices)
    setIsUpdated(true)
  }

  const onUpdatePrice = (rateIndex: number, lockDayIndex: number, price: number) => {
    if (selectedProductId === undefined || selectedProgramId === undefined) return

    const { newPrices } = updatePrice(prices, selectedProductId, selectedProgramId, rateIndex, lockDayIndex, price)

    updateSelectedProgram(newPrices)
    setPrices(newPrices)
    setIsUpdated(true)
  }

  const onUpdateRateSpreadExitFee = (index: number, key: 'RateSpread' | 'ExitFee', value: number) => {
    if (selectedProductId === undefined || selectedProgramId === undefined) return

    const { newPrices } = updateRateSpreadExitFee(prices, selectedProductId, selectedProgramId, index, key, value)

    updateSelectedProgram(newPrices)
    setPrices(newPrices)
    setIsUpdated(true)
  }

  const renderActionButtons = () => {
    if (forPdf) return <></>
    if (pricingEngineIsOff) return <></>

    return (
      <div className="my-4" key={'PriceLoan'}>
        <Button
          className="w-full"
          onClick={() => {
            onPriceLoan(false)
            onGetPriceLimit()
          }}
          loading={loading === 'PriceLoan'}
        >
          Price Loan
        </Button>
      </div>
    )
  }

  const sandBoxFragment = useMemo(() => {
    if (exceptionApproved && !anytimeCanEditLoanDetail) {
    } else if (canEditLoanDetail) return null

    return (
      <Toggle
        className="pt-1"
        id="sandbox"
        title={'Sandbox Mode'}
        key={'sandbox'}
        value={sandBoxMode}
        onChange={changeSandBoxMode}
      />
    )
  }, [canEditLoanDetail, sandBoxMode, pendingChangeRequest, exceptionApproved, anytimeCanEditLoanDetail])

  const rateSheetExpireFragment = useMemo(() => {
    if (pricingEngineIsOff) return <></>
    if (pricingCount > 0)
      return (
        <div
          className="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded relative text-[15px] my-4"
          role="alert"
        >
          <div className="text-[15px] flex">
            This ratesheet expires in:
            <div className="font-variation-settings-600 text-shade-blue mx-2 w-[14.5px] underline">{pricingCount}</div>
            seconds.
          </div>
        </div>
      )
    else if (rateSheetExpired)
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] my-4"
          role="alert"
        >
          This ratesheet has expired!
        </div>
      )

    return <></>
  }, [pricingCount, rateSheetExpired])

  const renderPendingRequests = useMemo(() => {
    const viewPendingLoanStructureChangeRequest = () => {
      if (canEditLoanDetail && !(exceptionApproved && !anytimeCanEditLoanDetail)) {
        applyPendingChangeRequest()
        setTimeout(() => {
          setBlurCount(!blurCount)
          setOpenRevalidateSignal(openRevalidateSignal + 1)
        }, 200)
      } else {
        if (sandBoxMode) {
          setTimeout(() => {
            setBlurCount(!blurCount)
          }, 200)
        }
        changeSandBoxMode(true)
      }
    }

    if ((brokerCanNotEditDetail || exceptionApproved) && pendingChangeRequest?.loanDetailChanges) {
      const isExceptionReviewRequest = pendingChangeRequest.type === 2 && needExceptionReviewRequest

      if (pendingChangeRequest.type === 2 && !canEditLoanDetail) return <></>

      return (
        <div className="flex flex-wrap items-center gap-4">
          <div
            className="px-4 py-2 ring-[2px] ring-red-300 bg-red-500 text-white hover:bg-red-300 hover:text-red-900 rounded shadow text-[14px] w-fit flex gap-2 cursor-pointer"
            onClick={viewPendingLoanStructureChangeRequest}
          >
            <svg className="animate-bounce w-5 h-5">
              <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
            </svg>
            <span>
              <span className="">
                {canEditLoanDetail && !isExceptionReviewRequest ? 'Apply ' : 'View '}
                Pending {pendingChangeRequestTypes[pendingChangeRequest.type]}
              </span>
            </span>
          </div>
          {/* {!canEditLoanDetail && (
            <div
              className="text-red-600 text-[14px] cursor-pointer hover:underline hover:text-red-800"
              onClick={onCancelChangeRequest}
            >
              Cancel Request
            </div>
          )} */}
        </div>
      )
    }
  }, [
    brokerCanNotEditDetail,
    exceptionApproved,
    anytimeCanEditLoanDetail,
    pendingChangeRequest,
    openRevalidateSignal,
    blurCount,
  ])

  return (
    <div className="LoanStructure-container px-2">
      {!forPdf && (
        <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-x-6">
          <div className="col-span-12 flex flex-wrap items-center gap-4 justify-center mb-2">
            <div
              className={`rounded-t text-gray-900 mb-4 shadow1 px-5 py-2.5 border-b-4 hover:border-sky-500 cursor-pointer flex items-center ${
                menu === 'loanDetail' && 'border-shade-blue'
              }`}
              onClick={() => {
                setMenu('loanDetail')
              }}
            >
              {structureStep.rateAndPrice === 1 && (
                <span className="mr-2 text-shade-blue">
                  <CheckIcon className="w-6 h-6"></CheckIcon>
                </span>
              )}
              <span>1. Loan Details & Rate Sheet</span>
              <span className="ml-2">
                <CalculatorIcon className="w-5 h-5"></CalculatorIcon>
              </span>
            </div>
            <span className="mb-4">
              <ChevronRightIcon className="w-6 h-6"></ChevronRightIcon>
            </span>
            <div
              className={`rounded-t shadow1 mb-4 px-5 py-2.5 cursor-pointer flex items-center border-b-4 ${
                !canSeeReviewLock ? 'text-gray-400 cursor-not-allowed' : 'text-gray-900 hover:border-sky-500'
              } ${menu === 'reviewLock' && 'border-shade-blue'}`}
              onClick={() => {
                if (canSeeReviewLock) setMenu('reviewLock')
              }}
            >
              {structureStep.lockRate === 1 && (
                <span className="mr-2 text-shade-blue">
                  <CheckIcon className="w-6 h-6"></CheckIcon>
                </span>
              )}
              <span>2. Review & Lock</span>
              <span className="ml-2">
                {!canSeeReviewLock ? (
                  <NoSymbolIcon className="w-5 h-5"></NoSymbolIcon>
                ) : (
                  <DocumentCheckIcon className="w-5 h-5"></DocumentCheckIcon>
                )}
              </span>
            </div>
          </div>
        </div>
      )}

      {menu === 'loanDetail' && (
        <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-x-6">
          {exceptions.length > 0 && (
            <div className="relative shadow1 col-span-12 bg-white rounded p-4 md:p-6 mb-4">
              <ExceptionResponse
                exceptions={exceptions}
                setLoanProcessData={setLoanProcessData}
                loanDetail={loanDetail}
                isNonDscrProduct={isNonDSCRProduct(loan.productType)}
                forPdf={forPdf}
                setExceptions={setExceptions}
              />
            </div>
          )}
          <div
            className={`${
              sandBoxMode ? 'border-[3px] border-red-300' : ''
            } relative shadow1 md:col-span-6 col-span-12 bg-white rounded p-4 md:p-6 mb-4`}
          >
            <LayoutLoading show={action === 'updateLoanFields' || loading === 'PriceLoan' || ratesheetSync} />
            <div className="text-2xl font-bold mb-4 gap-4 flex items-center">
              <span>Loan Details</span>
              {loanDetailSync && (
                <span className="flex items-center">
                  <img src={svgLoading} className="inline w-5 h-5 text-white animate-spin" />
                </span>
              )}
              {sandBoxFragment}
            </div>
            {renderPendingRequests}

            <LoanDetailInputs
              loan={loan}
              loanDetail={loanDetail}
              loanFields={loanFields}
              loan_number={loan_number}
              loanProcessData={loanProcessData}
              showingFields={showingFields}
              visibleFields={visibleFields}
              isAdmin={isAdmin}
              canEditLoanDetail={canEditLoanDetail}
              canPriceAdjust={canPriceAdjust}
              exceptionApproved={exceptionApproved}
              anytimeCanEditLoanDetail={anytimeCanEditLoanDetail}
              changedLoanDetailJson={changedLoanDetailJson}
              brokerCanNotEditDetail={brokerCanNotEditDetail}
              fillClosingAmountMax={fillClosingAmountMax}
              fillLoanAmountMax={fillLoanAmountMax}
              maximumTotalLoanAmountTip={maximumTotalLoanAmountTip}
              onBlur={onBlur}
              onChangeLoanInputFields={onChangeLoanInputFields}
              originLoanDetail={originLoanDetail}
              priceAdjustmentObject={priceAdjustmentObject}
              sandBoxMode={sandBoxMode}
            />

            {loan_number !== 'New' && isAdmin && (
              <div className="relative shadow1 col-span-12 bg-white rounded p-4">
                <PriceAdjust onChange={onChangeLoanInputFields} canPriceAdjust={canPriceAdjust} />
              </div>
            )}
          </div>

          {!sandBoxMode && (
            <div className="relative shadow1 flex-col flex md:col-span-6 col-span-12 bg-white rounded p-4 md:p-6 mb-4 h-fit">
              <LayoutLoading show={ratesheetSync} />

              <div className="text-2xl font-bold mb-4 flex items-center">
                <span>Request Rate Sheet</span>
                {ratesheetSync && (
                  <span className="ml-4 flex items-center">
                    <img src={svgLoading} className="inline w-5 h-5 text-white animate-spin" />
                  </span>
                )}
              </div>

              <div className="shadow rounded p-4 mb-6 text-[16px] text-gray-800">
                <div className="font-variation-settings-600">Instructions:</div>
                <div className="ml-2 mt-2">
                  {!pricingEngineIsOff && (
                    <>
                      <div className="mt-1">
                        1. Click on the “Price Loan” button to get your interest rates and base prices.
                      </div>
                      <div className="mt-1">
                        2. If you make changes to the loan details, click the button again to update rates.
                      </div>
                    </>
                  )}
                  <div className="mt-1 flex gap-2 items-center">
                    <span>{pricingEngineIsOff ? '' : '3. '}Click Here to </span>
                    <div
                      className="text-shade-blue cursor-pointer hover:underline font-variation-settings-700"
                      onClick={generateLoanTerm}
                    >
                      <span className="flex items-center gap-2">
                        Generate your Loan Term Sheet
                        <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {renderActionButtons()}

              <LoanResult
                auth={auth}
                loanRateSheet={loanRateSheetData as ILoanRateSheet}
                loan={loan}
                loanDetail={loanDetail}
                programs={programs}
                products={products}
                prices={prices}
                priceAdjustments={loanDetail.priceAdjustments}
                priceLoanTime={priceLoanTime}
                selectedProgramId={selectedProgramId}
                selectedProductId={selectedProductId}
                selectedProgram={selectedProgram}
                lockDays={lockDays}
                handleSelectProgram={handleSelectProgram}
                loanNumber={loan_number}
                limit={limit}
                priceIndex={priceIndex}
                setPriceIndex={setPriceIndex}
                setLimit={setLimit}
                ratesheetID={ratesheetID}
                exceptionApproved={exceptionApproved}
                loanProcessData={loanProcessData}
                inEligibleProducts={inEligibleProducts}
                setLoanProcessData={setLoanProcessDataFromChild}
                setRatesheetSync={setRatesheetSync}
                saveToPipeline={_saveToPipeline}
                brokerCanNotEditDetail={brokerCanNotEditDetail}
                canEditLoanDetail={canEditLoanDetail}
                changedLoanDetailJson={changedLoanDetailJson}
                viewExceptionPricing={loan_number !== 'New' && canPriceAdjust && !showRePrice}
                onExceptionPricing={onExceptionPricing}
                onClearException={onClearException}
                onClearPrice={onClearPrice}
                canPriceAdjust={canPriceAdjust}
                pricingEngineIsOff={pricingEngineIsOff}
                addProduct={addProduct}
                addProgram={addProgram}
                removeProduct={removeProduct}
                addLockDay={addLockDay}
                removeLockDay={removeLockDay}
                onAddRate={onAddRate}
                onRemoveRate={onRemoveRate}
                onUpdatePrice={onUpdatePrice}
                onUpdateRateSpreadExitFee={onUpdateRateSpreadExitFee}
                removeProgram={removeProgram}
                checkValidation={checkValidation}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
                canDoManualPricing={canDoManualPricing}
                canSeeSubPrograms={canSeeSubPrograms}
                showSub={showSub}
                setShowSub={setShowSub}
              />

              {rateSheetExpireFragment}

              {!forPdf && canSeeReviewLock && (
                <div className="shadow1 rounded p-4 mt-2">
                  <div className="flex justify-center">
                    <Button color="gray" className="w-full mr-0" onClick={() => setMenu('reviewLock')}>
                      {`Review & Lock >>`}
                    </Button>
                  </div>
                  <div className="flex flex-wrap gap-6 justify-center mt-2 text-[14.5px] font-variation-settings-600">
                    <Link to={`/loan_process/overview/${loanDetail.loanNumber}`}>
                      <div className="flex gap-1 items-center text-shade-blue hover:underline cursor-pointer shadow px-2 py-1 rounded">
                        <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon> Loan Overview
                      </div>
                    </Link>
                    <div
                      onClick={() => {
                        if (validateLoanStructureData(loan, loanProcessData, true).length) return
                        navigate.push(`/loan_process/application/${loanDetail.loanNumber}`)
                      }}
                    >
                      <div className="flex gap-1 items-center text-shade-blue hover:underline cursor-pointer shadow px-2 py-1 rounded">
                        Loan Application
                        <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {!forPdf && loan_number !== 'New' && !sandBoxMode && (
            <div className="relative shadow1 md:col-span-6 col-span-12 bg-white rounded p-4 md:p-6 mb-4">
              <ExceptionRequest
                loan={loan}
                visibleFields={visibleFields}
                loanFields={loanFields}
                loanDetail={loanDetail}
                exceptions={exceptions}
                setExceptions={setExceptions}
              />
            </div>
          )}
          <div className="flex justify-center mt-2 col-span-12">
            {loan_number === 'New' && (
              <Button loading={loading === 'SaveToPipeline'} onClick={() => _saveToPipeline({})}>
                Save To Pipeline
              </Button>
            )}
          </div>
        </div>
      )}

      {menu === 'reviewLock' && (
        <ReviewLock
          loanRateSheet={loanRateSheetData as ILoanRateSheet}
          loanProcessData={loanProcessData}
          setLoanProcessData={setLoanProcessData}
          loanDetail={loanDetail}
          applicationStep={applicationStep}
          showingFields={showingFields}
          pricingEngineIsOff={pricingEngineIsOff}
          syncOriginLoanDetailWithCurrent={syncOriginLoanDetailWithCurrent}
          openRevalidate={() => setOpenRevalidateSignal(openRevalidateSignal + 1)}
        />
      )}

      <TermSheetDialog
        loanNumber={loan_number}
        loanProcessData={loanProcessData}
        isOpen={isOpen === 'TermSheetDialog'}
        onClose={() => setIsOpen('')}
        loan={loan}
        loanDetail={loanDetail}
      />

      <Prompt
        when={brokerCanNotEditDetail && Object.keys(changedLoanDetailJson).length > 0}
        message={`You've made some changes on Loan Detail!\nAre you sure want to leave without Saving?`}
      />

      {showRePrice && (
        <RePriceLoan
          key={'Revalidate'}
          loanRateSheet={loanRateSheetData as ILoanRateSheet}
          sandBoxMode={sandBoxMode}
          changeSandBoxMode={changeSandBoxMode}
          changedLoanDetailJson={changedLoanDetailJson}
          visibleFields={visibleFields}
          showingFields={showingFields}
          blurCount={blurCount}
          limitFieldChangeCount={limitFieldChangeCount}
          checkValidation={checkValidation}
          // setLoanDetailSync={setLoanDetailSync}
          setAction={setAction}
          setLimit={setLimit}
          onSuccessRePrice={syncOriginLoanDetailWithCurrent}
          setLoanResultFeed={setLoanResultFeed}
          setLoanProcessData={setLoanProcessDataFromChild}
          loanProcessData={loanProcessData}
          changedLoanProcessData={changedLoanProcessData}
          setChangedLoanProcessData={setChangedLoanProcessData}
          pendingChangeRequest={pendingChangeRequest}
          openRevalidateSignal={openRevalidateSignal}
          setPendingChangeRequest={setPendingChangeRequest}
          applyPendingChangeRequest={applyPendingChangeRequest}
          inputChangeFlag={inputChangeFlag}
          onCancelChangeRequest={onCancelChangeRequest}
          canEditLoanDetail={canEditLoanDetail}
          canPriceAdjust={canPriceAdjust}
          canOnlyRevalidate={canOnlyRevalidate}
          canRelockRate={canRelockRate}
          canDoManualPricing={canDoManualPricing}
          canSeeSubPrograms={canSeeSubPrograms}
          hidePendingExceptionReviewRequest={hidePendingExceptionReviewRequest}
          needExceptionReviewRequest={needExceptionReviewRequest}
        />
      )}

      <Modal
        isOpen={modal === 'exceptionPricing'}
        title="Exception"
        loading={action === 'exceptionPricing'}
        titleOkay="Confirm"
        lastUpdatedAt={Date.now()}
        onClose={() => setModal('')}
        onOk={onConfirmOverride}
      >
        <div className="grid grid-cols-1 md:grid-cols-2  items-center gap-4 justify-center mb-2 flex-wrap">
          {Object.keys(ovFields).map((key: string, index: number) => {
            let cn = 'md:col-span-1 min-w-[240px] md:w-96'
            if (['override'].indexOf(key) !== -1) return null
            return (
              <div className={cn} key={index}>
                <RenderInput input={{ ...ovFields[key] }} Key={key} onChange={onChangeOverrideField} />
              </div>
            )
          })}
        </div>
      </Modal>

      {modal === 'decline' && (
        <DeclineLoanChangeRequest
          loanDetail={loanDetail}
          loading={action}
          onClose={() => setModal('')}
          onSubmit={onDeclineRequest}
        />
      )}
    </div>
  )
}
