import {
  API_LOAN_DELETE_DOCUMENT_HISTORY,
  API_LOAN_GENERATE_DOCUMENTS,
  API_LOAN_GENERATE_DOCUMENTS_LOG,
  API_LOAN_GENERATE_DOCUMENTS_URL,
} from 'config'
import Api from 'services/api'

export const getGenerateDocuments = (isRequireOptions = false) => {
  return Api.get(API_LOAN_GENERATE_DOCUMENTS, { isRequireOptions })
}

export const deleteGenerateDocumentsHistory = () => {
  return Api.post(API_LOAN_DELETE_DOCUMENT_HISTORY)
}

export const submitGenerateDocuments = (data: Record<string, any>) => {
  return Api.post(API_LOAN_GENERATE_DOCUMENTS, data)
}

export const updateGenerateDocuments = (data: Record<string, any>) => {
  return Api.put(API_LOAN_GENERATE_DOCUMENTS, data)
}

export const getGenerateDocumentUrl = (id: number) => {
  return Api.get(API_LOAN_GENERATE_DOCUMENTS_URL, {}, { id })
}

export const getGenerateDocumentLog = (key: string) => {
  return Api.get(API_LOAN_GENERATE_DOCUMENTS_LOG, {}, { key })
}
