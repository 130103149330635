import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { isVendorEnabled, uploadAssetInformationBankStatement } from 'services'
import { InputFile, Modal } from 'stories/components'

import { type IAssetData, SnapptVideoLink } from './constants'

export enum VerifyingMethod {
  None,
  Plaid,
  Snappt,
}

export const VerifyingMethodText: Record<VerifyingMethod, string> = {
  [VerifyingMethod.None]: 'None',
  [VerifyingMethod.Plaid]: 'Verify with Plaid by connect your Bank Account',
  [VerifyingMethod.Snappt]: 'Verify with Snappt by uploading Bank Statement',
}

export const bankStatementRequires = [
  'Files must be a directly downloaded PDF from the financial institution or payroll website.',
  'Scans, screenshots, and JPG/ PNG images of documents will not be accepted.',
  'Offer letters, tax returns, international bank statements, and password-protect documents are also not reviewable by Snappt.',
]

const verifyMethods = [VerifyingMethod.Plaid, VerifyingMethod.Snappt]

const verifyConfigKeys: Record<VerifyingMethod, string> = {
  [VerifyingMethod.None]: '',
  [VerifyingMethod.Plaid]: 'Plaid',
  [VerifyingMethod.Snappt]: 'Snappt',
}

export const VerifyingMethodModal = ({
  asset,
  onClose,
}: {
  asset: IAssetData
  onClose: (method: VerifyingMethod, data?: IAssetData) => void
}) => {
  const [isLoading, setLoading] = useState(false)
  const [method, setMethod] = useState<VerifyingMethod>(VerifyingMethod.None)
  const [file, setFile] = useState<File | null>(null)
  const [submittedData, setSubmittedData] = useState(null)
  const [error, setError] = useState('')
  const [isEnabled, setEnabled] = useState<Record<VerifyingMethod, boolean>>()

  useEffect(() => {
    loadConfig()
  }, [])

  const loadConfig = async () => {
    setLoading(true)
    const isEnabled: Record<number, boolean> = {}
    await Promise.all(
      verifyMethods.map(async (key) => {
        isEnabled[key] = await isVendorEnabled(verifyConfigKeys[key])
      }),
    )
    setEnabled(isEnabled)
    setLoading(false)
  }

  const isAllDisabled = verifyMethods.every((key) => isEnabled && !isEnabled[key])

  const onChoose = (method: VerifyingMethod) => {
    if (method == VerifyingMethod.Plaid) {
      onClose(method)
      return
    }
    setMethod(method)
  }

  const onChooseFile = (file: File | null) => {
    setFile(file)
  }

  const onSubmit = async () => {
    if (!file) return
    setLoading(true)
    setError('')
    uploadAssetInformationBankStatement(asset.id, file)
      .then((data) => {
        if (data.error) {
          setError(data.error)
          return
        }
        setSubmittedData(data)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title="Choose Verification Method"
      titleOkay={method == VerifyingMethod.Snappt && !submittedData ? 'Submit' : ''}
      titleCancel={submittedData ? 'Close' : ''}
      isOpen
      loading={isLoading}
      disabled={!file}
      onOk={onSubmit}
      onClose={() => (submittedData ? onClose(VerifyingMethod.Snappt, submittedData) : onClose(VerifyingMethod.None))}
    >
      <>
        {method == VerifyingMethod.None && (
          <div className="grid w-128 grid-cols-1 gap-8">
            {isAllDisabled && <p className="mb-1">Please contact to Administrator to enable Verification Method</p>}

            {verifyMethods
              .filter((method) => isEnabled && isEnabled[method])
              .map((method, index) => {
                return (
                  <div
                    className={`border rounded-md overflow-hidden p-4 cursor-pointer hover:border-shade-blue`}
                    onClick={() => onChoose(method)}
                    key={method}
                  >
                    <div className="flex justify-between items-center">
                      <p className="font-semibold mb-1">
                        Option #{index + 1}. {method == VerifyingMethod.Plaid ? 'Plaid' : 'Snappt'}
                      </p>
                    </div>
                    <p className="text-xs mb-1 underline">{VerifyingMethodText[method]}</p>
                  </div>
                )
              })}
          </div>
        )}

        {method == VerifyingMethod.Snappt &&
          (!submittedData ? (
            <div className="w-128">
              <p className="font-semibold mb-2">{VerifyingMethodText[VerifyingMethod.Snappt]}</p>

              <div className="bg-blue-50 p-4 rounded-lg">
                {bankStatementRequires.map((v) => (
                  <p className="flex items-start gap-2 text-sm mb-2">
                    <CheckCircleIcon className="w-4 h-4 text-shade-blue mt-1" />
                    <span className="flex-1">{v}</span>
                  </p>
                ))}
              </div>

              <InputFile title="Choose Bank Statement" value={file || undefined} onChange={onChooseFile as any} />

              {SnapptVideoLink}

              {!!error ? (
                <p className="p-2 rounded-lg border-red-400 border bg-red-50 text-sm text-red-600 mt-4">{error}</p>
              ) : null}
            </div>
          ) : (
            <div className="w-128">
              <div className="w-20 h-20 bg-blue-50 border border-blue-400 rounded-full mx-auto p-2 mb-4">
                <CheckIcon className="w-16 h-16 text-shade-blue" />
              </div>
              <p className="text-base">
                The bank statement has been successfully submitted to Snappt. It will take 1 business day to receive the
                result. We will notify you once the result has been received.
              </p>
            </div>
          ))}
      </>
    </Modal>
  )
}
