import {
  API_LOAN_BORROWER_UPLOADED_DOCUMENTS,
  API_LOAN_GET_SUBMISSION,
  API_LOAN_GET_SUBMISSION_CONDITIONS_TEMPORARY,
  API_LOAN_PROC_RUSH_PAYMENT,
  API_LOAN_SEND_LOAN_SUBMISSION_DOCUMENT_EMAIL,
  API_LOAN_SEND_SUBMISSION_PDF_EMAIL,
  API_LOAN_SUBMISSION_CLEARED_UNDERWRITER_TASK,
  API_LOAN_SUBMISSION_DOCUMENT_HISTORY,
  API_LOAN_SUBMISSION_DOCUMENTS,
  API_LOAN_SUBMISSION_LOG,
  API_LOAN_SUBMISSION_NOTIFY,
  API_LOAN_SUBMISSION_REVIEW_REQUEST,
  API_LOAN_SUBMIT_CLOSED_LOAN_REVIEW,
  API_LOAN_SUBMIT_CLOSING_CONDITION_CLEARED,
  API_LOAN_SUBMIT_CONDITIONS,
  API_LOAN_SUBMIT_CTC_REVIEW,
  API_LOAN_SUBMIT_TO_AE,
  API_LOAN_SUBMIT_TO_LOANSETUP,
  API_LOAN_SUBMIT_TO_UNDERWRITER,
  API_LOAN_UPDATE_SUBMISSION,
} from 'config'
import Api from 'services/api'
import type { LoanSubmissionPdfType } from 'services/pdfs/loan'

export const getLoanSubmission = () => {
  return Api.get(API_LOAN_GET_SUBMISSION)
}

export const getLoanSubmissionConditionsForTemporaryUser = () => {
  return Api.get(API_LOAN_GET_SUBMISSION_CONDITIONS_TEMPORARY)
}

export const getLoanSubmissionNotify = () => {
  return Api.get(API_LOAN_SUBMISSION_NOTIFY)
}

export const setLoanSubmissionNotify = (data: Record<string, any>) => {
  return Api.post(API_LOAN_SUBMISSION_NOTIFY, data)
}

export const sendLoanSubmissionNotify = () => {
  return Api.put(API_LOAN_SUBMISSION_NOTIFY)
}

export const postReviewRequest = (key: string) => {
  return Api.get(API_LOAN_SUBMISSION_REVIEW_REQUEST, {}, { key })
}

export const conditionReviewClearedUnderwriterTask = (conditionNo: number) => {
  return Api.get(API_LOAN_SUBMISSION_CLEARED_UNDERWRITER_TASK, {}, { conditionNo })
}

export const setLoanSubmissionTemplate = (templateNumber: number) => {
  return Api.put(API_LOAN_UPDATE_SUBMISSION, { templateNumber })
}

export const updateLoanSubmission = (
  conditions: Record<string, Array<any>>, // set | update | delete
  documents: Record<string, Array<any>> = {},
  conditionOrder: Record<string, Array<any>> = {},
) => {
  return Api.post(API_LOAN_UPDATE_SUBMISSION, {
    conditions,
    documents,
    conditionOrder,
  })
}

export const borrowerUploadedDocuments = () => {
  return Api.get(API_LOAN_BORROWER_UPLOADED_DOCUMENTS)
}

export const sendLoanProcessDocumentEmail = (
  emails: Record<string, string>,
  emailContent: string,
  files: Record<string, any>[],
) => {
  return Api.post(API_LOAN_SEND_LOAN_SUBMISSION_DOCUMENT_EMAIL, {
    emails,
    content: emailContent,
    files,
  })
}

export const sendLoanSubmissionDocumentEmail = (
  title: string,
  emailType: string,
  emails: Record<string, string>,
  emailContent: string,
  isSsnIncludes: boolean,
  files: Array<File>,
  pdfData: Record<string, any>,
  pdfType: LoanSubmissionPdfType,
) => {
  return Api.uploadFiles(
    API_LOAN_SEND_SUBMISSION_PDF_EMAIL,
    {
      title,
      emailType,
      emails: JSON.stringify(emails),
      content: emailContent,
      isSsnIncludes,
      pdfData: JSON.stringify(pdfData),
      pdfType,
    },
    {},
    files,
  )
}

export const getLoanSubmissionDocumentHistory = (key: string) => {
  return Api.get(API_LOAN_SUBMISSION_DOCUMENT_HISTORY, { key })
}

export const postSubmitToLoanSetup = () => {
  return Api.post(API_LOAN_SUBMIT_TO_LOANSETUP, {}, {}, { timeout: 150000 })
}

export const postSubmitClosedLoanReview = () => {
  return Api.post(API_LOAN_SUBMIT_CLOSED_LOAN_REVIEW, {}, {}, { timeout: 150000 })
}

export const postSubmitClosingConditionCleared = () => {
  return Api.post(API_LOAN_SUBMIT_CLOSING_CONDITION_CLEARED, {}, {}, { timeout: 150000 })
}

export const postSubmitConditions = () => {
  return Api.post(API_LOAN_SUBMIT_CONDITIONS, {}, {}, { timeout: 150000 })
}

export const postSubmitToUnderwriter = () => {
  return Api.post(API_LOAN_SUBMIT_TO_UNDERWRITER, {}, {}, { timeout: 150000 })
}

export const postSubmitToAE = () => {
  return Api.post(API_LOAN_SUBMIT_TO_AE, {}, {})
}

export const submitCTCReview = () => {
  return Api.post(API_LOAN_SUBMIT_CTC_REVIEW, {}, {})
}

export const sendLoanProcRushPaymentData = (data: Record<string, string | number>) => {
  return Api.post(API_LOAN_PROC_RUSH_PAYMENT, data)
}

export const getLoanSubmissionDocuments = () => {
  return Api.get(API_LOAN_SUBMISSION_DOCUMENTS)
}

export const getLoanSubmissionLogs = (key: string) => {
  return Api.get(API_LOAN_SUBMISSION_LOG, {}, { key })
}
