import { ArrowDownTrayIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { downloadFile, loanTermSheetPdf } from 'services'
import { Button } from 'stories/components'

export const SignLoanTermSheet = () => {
  const urlParams: any = useParams()
  const { loanNumber, token } = urlParams
  const [isLoading, setLoading] = useState(true)
  const [isSigned, setSigned] = useState(false)
  const [data, setData] = useState<Record<string, any>>({})

  useEffect(() => {
    const payload: Record<string, any> = jwtDecode(token)
    setData(payload)
    setLoading(false)
  }, [])

  const onSubmit = async (sign: boolean) => {
    setLoading(true)

    const pdfData = await loanTermSheetPdf({ ...data, sign })
    downloadFile(`Loan Term Sheet ${loanNumber}(${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`, pdfData)

    if (sign) {
      toast('Signed Loan Term Sheet Successfully', { type: 'success' })
      setSigned(true)
    }
    setLoading(false)
  }

  return (
    <TemporaryLayout title="Sign Loan Term Sheet">
      <LayoutLoading show={isLoading} />
      <div className="max-w-4xl">
        <div className="-mt-4">
          <Button onClick={() => onSubmit(false)} color="blue" link>
            <p className="flex items-center gap-2">
              Download unsigned loan term sheet
              <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
            </p>
          </Button>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Broker Fee Amount</div>
          <div className="font-bold ml-2">${data.feeAmount}</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Broker Fee Percent</div>
          <div className="font-bold ml-2">{data.feePercent}%</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Commitment Fee Amount</div>
          <div className="font-bold ml-2">${data.commitmentFee}</div>
        </div>
        {!isSigned ? (
          <Button onClick={() => onSubmit(true)} className="shade-blue" loading={isLoading}>
            Sign Loan Term Sheet
          </Button>
        ) : (
          <div
            className="my-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px] flex items-center"
            role="alert"
          >
            <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
            <span className="ml-1">Successfully Signed.</span>
          </div>
        )}
      </div>
    </TemporaryLayout>
  )
}
