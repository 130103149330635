import {
  ArrowDownCircleIcon,
  ArrowRightCircleIcon,
  ArrowTopRightOnSquareIcon,
  CalculatorIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { setLoanLtvMaxLimit } from 'actions'
import cloneDeep from 'clone-deep'
import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import { LayoutLoading } from 'components/LayoutLoading'
import { COMPANY_DNS, convertValueToLabel, getInitialLoanFields, InputType, INVALID_ALL_INPUTS, TimeZone } from 'config'
import moment from 'moment-timezone'
import { convertNullValue } from 'pages/Resources/GuidelineOverlay/logic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getCurrentProductTypesPrograms,
  getRelockTimes,
  getRePricingInitData,
  loanRePriceAfterLocked,
  priceLoan,
} from 'services/apis'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { Button, ButtonGroup, Modal, TextArea, Toggle } from 'stories/components'
import { confirm, formatDate, formatTime, InputValidate, isBeforeOrEqualToday, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { getDefaultLoanProcessData, overrideFields } from './constant'
import type {
  ILoan,
  ILoanDetail,
  ILoanDetailChangedJson,
  ILoanProcess,
  ILoanRateSheet,
  IPendingLoanChangeRequestData,
  IPrice,
  IPrice_Program,
  IPrice_Program_Price,
  IPriceLimit,
  IProduct,
  IProgram,
} from './interfaces'
import { LoanResult } from './LoanResult'
import {
  addRate,
  calculateLoanLimits,
  deleteLockDay,
  deleteProduct,
  deleteProgram,
  deleteRate,
  getLockExpired,
  isEmptyPrices,
  isNonDSCRProduct,
  showLimitSection,
  updateLockDay,
  updatePrice,
  updateProduct,
  updateProgram,
  updateRateSpreadExitFee,
} from './Logic'

interface IProps {
  loanRateSheet: ILoanRateSheet
  showingFields: string[][]
  visibleFields: string[]
  loanProcessData: ILoanProcess
  changedLoanProcessData: ILoanProcess
  pendingChangeRequest: IPendingLoanChangeRequestData | undefined
  changedLoanDetailJson: Record<string, ILoanDetailChangedJson>
  sandBoxMode: boolean
  canEditLoanDetail: boolean
  canPriceAdjust: boolean
  canOnlyRevalidate: boolean
  canRelockRate: boolean
  hidePendingExceptionReviewRequest: boolean
  needExceptionReviewRequest: boolean
  blurCount: boolean
  inputChangeFlag: boolean
  limitFieldChangeCount: boolean
  canDoManualPricing: boolean
  canSeeSubPrograms: boolean
  openRevalidateSignal: number
  checkValidation: (type: string, customVisibleFields?: string[]) => boolean
  setLoanResultFeed: (data: ILoanRateSheet, withCurrentLTV?: boolean) => void
  applyPendingChangeRequest: () => void
  onSuccessRePrice: () => void
  onCancelChangeRequest: (isDecline: boolean) => void
  setAction: (value: string) => void
  setLimit: (value: IPriceLimit) => void
  changeSandBoxMode: (value: boolean) => void
  setLoanProcessData: (data: ILoanProcess) => void
  setChangedLoanProcessData: (data: ILoanProcess) => void
  setPendingChangeRequest: (data: IPendingLoanChangeRequestData | undefined) => void
}

export const RePriceLoan = (props: IProps) => {
  const {
    loanRateSheet,
    changedLoanDetailJson,
    sandBoxMode,
    pendingChangeRequest,
    canEditLoanDetail,
    hidePendingExceptionReviewRequest,
    needExceptionReviewRequest,
    blurCount,
    inputChangeFlag,
    visibleFields,
    showingFields,
    canPriceAdjust,
    canOnlyRevalidate,
    canRelockRate,
    canDoManualPricing,
    canSeeSubPrograms,
  } = props

  const [showContent, setShowContent] = useState(false)
  const [ratesheetIDs, setRatesheetIDs] = useState<Record<string, number>>({})
  const [rePriceData, setRePriceData] = useState<any>({})
  const [initialized, setInitialized] = useState(false)
  const [selectedRatesheetType, setSelectedRatesheetType] = useState('')
  const [action, setAction] = useState('')
  const [modal, setModal] = useState('')
  const [ovFields, setOvFields] = useState<Record<string, InputType>>({})
  const [relockTimes, setRelockTimes] = useState(0)
  const [isRelockRate, setIsRelockRate] = useState(true)
  const [isRevalidate, setIsRevalidate] = useState(false)
  const [revalidateConfirmTitle, setRevalidateConfirmTitle] = useState('')
  const [notifiers, setNotifiers] = useState<EmailValue>({})
  const [revalidateComment, setComment] = useState('')
  const [isUpdated, setIsUpdated] = useState(false)
  const [showSub, setShowSub] = useState(false)

  const dispatch = useDispatch()

  const loanNumber = setLoanNumber()

  const { email } = useSelector((state: any) => state.auth.profile)
  const isCompanyEmail = useMemo(() => (email || '').endsWith(`@${COMPANY_DNS}`), [email])

  const { loan, loanDetail, auth } = useSelector((state: any) => {
    return {
      loan: state.loan as ILoan,
      loanDetail: state.loanDetail as ILoanDetail,
      auth: state.auth,
    }
  })

  const pricingEngineIsOff = !loanDetail.pricingEngine

  const relockTimesValue = useMemo(() => {
    return isRelockRate ? relockTimes : relockTimes === 0 ? relockTimes : relockTimes - 1
  }, [relockTimes, isRelockRate])

  const lockExpired = useMemo(() => {
    return getLockExpired(props.loanProcessData)
  }, [props.loanProcessData.rateLocked, props.loanProcessData.expireDate])

  const showRelockRate = useMemo(() => {
    return lockExpired && !canOnlyRevalidate
  }, [lockExpired, canOnlyRevalidate])

  const reLockDateObj = useMemo(() => {
    let rlt = {
      canRelock: false,
      relockExpireDate: '',
    }
    const { rateLocked, expireDate } = props.loanProcessData
    if (rateLocked && expireDate) {
      rlt.relockExpireDate = moment(new Date(expireDate)).add(30, 'days').tz(TimeZone).format('MM/DD/YYYY')
      rlt.canRelock = isBeforeOrEqualToday(rlt.relockExpireDate)
    }
    return rlt
  }, [props.loanProcessData.rateLocked, props.loanProcessData.expireDate])

  const ratesheetTypes = useMemo(() => {
    let rlt: any = {}

    if (pricingEngineIsOff) rlt.combined = `Rate Sheet`
    else {
      if (!ratesheetIDs) return rlt

      if (Object.keys(ratesheetIDs).length === 0) return rlt
      const { rateLocked } = props.loanProcessData
      if (ratesheetIDs.current) {
        rlt.current = `Current RateSheet - ${ratesheetIDs.current}`
      }
      if (ratesheetIDs.locked) rlt.locked = `Locked RateSheet - ${ratesheetIDs.locked}`

      if (rateLocked) {
        if (!lockExpired) {
          if (['admin', 'lock_desk'].includes(auth.profile.accountType)) {
          } else delete rlt.current
        } else {
          rlt.combined = `Rate Sheet`
          if (['admin', 'lock_desk'].includes(auth.profile.accountType)) {
          } else {
            delete rlt.current
            delete rlt.locked
          }
        }
      } else {
        delete rlt.locked
      }
    }

    let _selectedRatesheetType = ''

    if (canEditLoanDetail) {
      rlt.override = `Exception`
      _selectedRatesheetType = 'override'
    }
    if (rlt.locked) _selectedRatesheetType = 'locked'
    if (rlt.current) _selectedRatesheetType = 'current'
    if (rlt.combined) _selectedRatesheetType = 'combined'
    setSelectedRatesheetType(_selectedRatesheetType)

    return rlt
  }, [ratesheetIDs, props.loanProcessData.rateLocked, lockExpired])

  const currentData = useMemo(() => {
    if (rePriceData[selectedRatesheetType]?.limit) props.setLimit(rePriceData[selectedRatesheetType]?.limit)
    return rePriceData[selectedRatesheetType]
  }, [rePriceData, selectedRatesheetType])

  const showExceptionReviewRequest = useMemo(() => {
    let rlt = false

    if (needExceptionReviewRequest) {
      if (props.loanProcessData?.sheetDate?.includes('(E)') && currentData?.loanProcessData?.rate > 0) rlt = true
      if (currentData && isEmptyPrices(currentData.prices)) rlt = true
    }

    return rlt
  }, [needExceptionReviewRequest, currentData, props.loanProcessData.sheetDate])

  const isExceptionReviewRequest = useMemo(() => {
    return needExceptionReviewRequest && props.loanProcessData?.sheetDate?.includes('(E)')
  }, [needExceptionReviewRequest, props.loanProcessData.sheetDate])

  const adjustmentObject = useMemo(() => {
    let rlt = {
      rate: 0,
      price: 0,
    }
    try {
      loanDetail.priceAdjustments.map((item) => {
        rlt.rate += removeComma(item.rate)
        rlt.price += removeComma(item.price)
      })
    } catch {}
    return rlt
  }, [loanDetail.priceAdjustments])

  useEffect(() => {
    if (lockExpired) setIsRelockRate(!canOnlyRevalidate)
  }, [lockExpired, canOnlyRevalidate])

  useEffect(() => {
    setRePriceData({})
    if (showContent) {
      getRePriceData(true)
    }
  }, [blurCount, loanDetail.priceAdjustments])

  useEffect(() => {
    if (currentData?.loanProcessData || currentData?.selectedProgram?.length > 0 || currentData?.prices?.length > 0) {
      let temp = cloneDeep(rePriceData)
      if (temp[selectedRatesheetType]) {
        temp[selectedRatesheetType].loanProcessData = {}
        temp[selectedRatesheetType].selectedProgram = []
        temp[selectedRatesheetType].prices = []
        temp[selectedRatesheetType].inEligibleProducts = {}
        setRePriceData(temp)
      }
    }
  }, [inputChangeFlag])

  useEffect(() => {
    if (selectedRatesheetType !== '' && showContent) {
      getRePriceData()
    }
  }, [selectedRatesheetType, showContent])

  useEffect(() => {
    if (props.openRevalidateSignal > 0) {
      showSlide()
    }
  }, [props.openRevalidateSignal])

  useEffect(() => {
    if (showLimitSection()) {
      const limit = calculateLoanLimits()

      let temp = cloneDeep(rePriceData)
      if (temp[selectedRatesheetType]) {
        temp[selectedRatesheetType].limit = limit
        setRePriceData(temp)
      }
    }
  }, [props.limitFieldChangeCount])

  useEffect(() => {
    if (sandBoxMode) {
      showSlide()
    } else {
      hideSlide()
    }
  }, [sandBoxMode])

  useEffect(() => {
    setDefaultNotifiers()
  }, [])

  useEffect(() => {
    if (showContent) getRePriceData()
  }, [showSub])

  const setDefaultNotifiers = () => {
    const defaultNotifiers: EmailValue = {}
    if (loanDetail?.parties.loanOfficer?.email) defaultNotifiers[loanDetail?.parties.loanOfficer?.email] = 'to'
    if (loanDetail?.parties.loanProcessor?.email) defaultNotifiers[loanDetail?.parties.loanProcessor?.email] = 'to'
    if (loanDetail?.parties.accountExecutive?.email)
      defaultNotifiers[loanDetail?.parties.accountExecutive?.email] = 'to'
    setNotifiers(defaultNotifiers)
  }

  const onChangeRatesheetType = (value: string) => {
    setSelectedRatesheetType(value)
  }

  const getRePriceData = async (init: boolean = false) => {
    if (selectedRatesheetType === 'override') return

    if (!props.checkValidation('PriceLoan')) return

    let data: any
    let prices: IPrice[] = []
    let programs: IProgram[] = []
    if (pricingEngineIsOff) {
      data = {
        ratesheetID: ratesheetIDs[selectedRatesheetType],
        limit: calculateLoanLimits(),
        programs: loanRateSheet.data.programs,
        products: loanRateSheet.data.products,
        inEligibleProducts: [],
        lockDays: loanRateSheet.data.lockDays,
        prices: loanRateSheet.data.prices,
        priceIndex: { index1: -1, index: -1 },
        selectedProductId: -1,
        selectedProgramId: -1,
        selectedProgram: [],
        priceLoanTime: formatTime('now'),
        loanProcessData: getDefaultLoanProcessData(),
        highLightRate: null,
      }

      let res = await getRelockTimes()
      if (!lockExpired) res -= 1
      setRelockTimes(res)

      prices = loanRateSheet.data.prices
      programs = loanRateSheet.data.programs
    } else {
      setAction('getPricing')
      let reqBody: any = {
        rateSheetID: ratesheetIDs[selectedRatesheetType],
      }
      if (selectedRatesheetType === 'combined') {
        reqBody.rateSheetID = [ratesheetIDs.current, ratesheetIDs.locked]
      }
      if (relockTimes === 0) reqBody.getRelockTimes = true
      visibleFields.map((field) => {
        reqBody[field] = (loan as any)[field]
      })

      reqBody['showSubPrograms'] = showSub
      reqBody['changedLoanDetails'] = Object.keys(changedLoanDetailJson)

      let promises = [priceLoan(reqBody)]

      const responses = await Promise.all(promises)
      const res = responses[0].data

      if (responses[0].relockTimes) {
        let temp = responses[0].relockTimes
        if (!lockExpired) temp -= 1
        setRelockTimes(temp)
      }

      prices = res.Prices
      programs = res.Programs

      data = {
        ratesheetID: ratesheetIDs[selectedRatesheetType],
        limit: calculateLoanLimits(),
        programs: programs,
        products: res.MortgageProducts,
        inEligibleProducts: res.InEligibleProducts,
        lockDays: res.LockDays,
        prices: prices,
        priceIndex: { index1: -1, index: -1 },
        selectedProductId: -1,
        selectedProgramId: -1,
        selectedProgram: [],
        priceLoanTime: formatTime('now'),
        loanProcessData: getDefaultLoanProcessData(),
        highLightRate: null,
      }
    }

    if (prices.length > 0) {
      let prodId = Infinity
      let progId = Infinity

      if (showSub && Number(props.loanProcessData.rate) !== -1) {
        const targetProduct = prices.find((item) => item.ProductName === props.loanProcessData.type)

        if (targetProduct) {
          prodId = targetProduct.ProductID
          const targetProgram = prices
            .find((item) => item.ProductID === prodId)
            ?.Programs.find((item) => {
              const targetRate = item.Prices.find((price) => price.Rate === Number(props.loanProcessData.rate))
              const isExist = targetRate?.LockTermPrices.find(
                (rate) =>
                  Number(rate.LockDay.replace(/\D/g, '')) === props.loanProcessData.lockDays &&
                  Number(rate.Price) === Number(props.loanProcessData.price),
              )
              if (isExist) return true
              else return false
            })

          if (targetProgram) {
            progId = targetProgram.ProgramID

            const targetRateIndex = targetProgram.Prices.findIndex(
              (item) => item.Rate === Number(props.loanProcessData.rate),
            )
            const targetPriceIndex = targetProgram.Prices.find(
              (item) => item.Rate === Number(props.loanProcessData.rate),
            )?.LockTermPrices.findIndex(
              (item) =>
                Number(item.LockDay.replace(/\D/g, '')) === props.loanProcessData.lockDays &&
                Number(item.Price) === Number(props.loanProcessData.price),
            )

            data.priceIndex = { index: targetRateIndex, index1: Number(targetPriceIndex) }
          }
        }
      } else
        for (let i = 0; i < prices.length; i++) {
          if (prices[i].Programs.length > 0) {
            prodId = prices[i].ProductID
            progId = prices[i].Programs[0].ProgramID
            break
          }
        }

      try {
        prices.map((item: any) => {
          if (item.ProductName === props.loanProcessData.type) {
            item.Programs?.map((item2: any) => {
              if (item2.ProgramName === props.loanProcessData.program) {
                prodId = item.ProductID
                progId = item2.ProgramID
                data.highLightRate = {
                  prodId,
                  progId,
                  rate: props.loanProcessData.rate,
                }
              }
            })
          }
        })
      } catch {}

      if (prodId !== Infinity && progId !== Infinity) {
        data.selectedProductId = prodId
        data.selectedProgramId = progId

        const targetPrices = prices
          .find((item: any) => item.ProductID === prodId)
          ?.Programs.find((item: any) => item.ProgramID === progId)?.Prices

        data.selectedProgram = targetPrices
        const LtvLimit = programs.find((item: any) => item.ID === progId)?.LtvLimit
        if (LtvLimit !== undefined) {
          dispatch(setLoanLtvMaxLimit(LtvLimit))
          if (showLimitSection()) {
            data.limit = calculateLoanLimits(null, false, LtvLimit)
          }
        }
      } else {
        dispatch(setLoanLtvMaxLimit({}))
        if (showLimitSection()) {
          let pricingResultIsEmpty = true
          prices.forEach((item: any) => {
            if (!!item.Programs.length) pricingResultIsEmpty = false
          })
          if (pricingResultIsEmpty && !!programs.length) {
            if (
              props.loanProcessData &&
              props.loanProcessData?.sheetDate?.includes('(E)') &&
              props.loanProcessData.rate !== -1 &&
              props.loanProcessData.price !== 0
            ) {
              const exceptionApprovedProgram = programs.find((item: any) => item.Name === props.loanProcessData.program)

              if (exceptionApprovedProgram)
                data.limit = calculateLoanLimits(null, false, exceptionApprovedProgram?.LtvLimit)
              else data.limit = calculateLoanLimits(null, false, programs[0]?.LtvLimit)
            } else data.limit = calculateLoanLimits(null, false, programs[0]?.LtvLimit)
          } else data.limit = calculateLoanLimits(null, false, {})
        }
      }
    }

    let temp: any = {}
    if (!init) temp = cloneDeep(rePriceData)
    temp[selectedRatesheetType] = data

    setRePriceData(temp)
    setAction('')
  }

  const showSlide = async () => {
    setShowContent(true)
    if (!initialized) {
      props.applyPendingChangeRequest()
      setAction('init')
      const res = await getRePricingInitData()
      if (res.success) {
        setAction('')
        setInitialized(true)
        setRatesheetIDs(res.data)
      }
    }
  }

  const hideSlide = () => {
    if (action.length) return
    setShowContent(false)
  }

  const getPrice = (price: number) => {
    return Number((Number(price) - Number(adjustmentObject.price) + Number(relockTimesValue) * 0.125).toFixed(4))
  }

  const handleSelectProgram = (programId: number, productId: number) => {
    const arr = currentData.prices
      .find((item: IPrice) => item.ProductID === productId)
      ?.Programs.find((item1: IPrice_Program) => item1.ProgramID === programId)?.Prices
    let temp = cloneDeep(rePriceData)

    let rePriceDataChanged = false

    if (arr) {
      rePriceDataChanged = true
      temp[selectedRatesheetType].selectedProgram = arr
      temp[selectedRatesheetType].selectedProductId = productId
      temp[selectedRatesheetType].selectedProgramId = programId
      temp[selectedRatesheetType].priceIndex = { index1: -1, index: -1 }
    }

    let LtvLimit: IPriceLimit | undefined = undefined
    currentData.programs.map((item: IProgram) => {
      if (item.ID === programId) LtvLimit = item.LtvLimit
    })

    if (LtvLimit !== undefined) {
      dispatch(setLoanLtvMaxLimit(LtvLimit))

      if (showLimitSection()) {
        rePriceDataChanged = true
        const result = calculateLoanLimits(null, false, LtvLimit)
        temp[selectedRatesheetType].limit = result
      }
    }

    if (rePriceDataChanged) setRePriceData(temp)
  }

  const setPriceSelectOnRePricing = (data: any) => {
    let temp: any = cloneDeep(rePriceData)
    temp[selectedRatesheetType] = {
      ...temp[selectedRatesheetType],
      ...data,
    }

    if (selectedRatesheetType === 'locked') {
      temp[selectedRatesheetType].loanProcessData.sheetDate = props.loanProcessData.sheetDate
    }

    if (
      selectedRatesheetType === 'combined' &&
      temp[selectedRatesheetType]?.loanProcessData?.rate > 0 &&
      temp[selectedRatesheetType]?.ratesheetJson?.data?.selectedProgram
    ) {
      let { rate, price } = temp[selectedRatesheetType]?.loanProcessData
      price = getPrice(Number(price))
      const prices: IPrice_Program_Price[] = temp[selectedRatesheetType]?.ratesheetJson?.data?.selectedProgram
      const targetRate = prices.find((item) => item.Rate == rate)

      if (targetRate) {
        const targetPrice = targetRate.LockTermPrices.find((item) => Number(item.Price) === Number(price))
        if (targetPrice && targetPrice?.type === 'lock') {
          temp[selectedRatesheetType].loanProcessData.sheetDate = formatDate(props.loanProcessData.lockedDate)
          temp[selectedRatesheetType].ratesheetJson.ratesheetID = ratesheetIDs['locked']
        }
      }
    }

    setRePriceData(temp)
    props.setChangedLoanProcessData({
      ...props.changedLoanProcessData,
      rate: temp[selectedRatesheetType]?.loanProcessData?.rate,
      type: temp[selectedRatesheetType]?.loanProcessData?.type,
    })
  }

  const addProduct = (data: IProduct, isUpdate: boolean) => {
    const newData = cloneDeep(rePriceData)
    const { products, prices, selectedProductId, selectedProgramId } = newData[selectedRatesheetType]

    const { newProducts, newPrices } = updateProduct(products, prices, data, isUpdate)

    const nSelectedProgram = newPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

    newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    newData[selectedRatesheetType].products = newProducts
    newData[selectedRatesheetType].prices = newPrices
    setRePriceData(newData)
    setIsUpdated(true)
  }

  const removeProduct = async (product: IProduct) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Product?
        <br />
        <span className="text-gray-600 text-base">Product Name: {product.Description}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(rePriceData)
    const { products, prices, selectedProductId } = newData[selectedRatesheetType]

    if (selectedProductId === product.Id) {
      newData[selectedRatesheetType].selectedProductId = undefined
      newData[selectedRatesheetType].selectedProgramId = undefined
      newData[selectedRatesheetType].selectedProgram = []
    }

    const { newProducts, newPrices } = deleteProduct(products, prices, product)

    newData[selectedRatesheetType].products = newProducts
    newData[selectedRatesheetType].prices = newPrices
    setRePriceData(newData)
    setIsUpdated(true)
  }

  const addProgram = (product: IProduct, program: IProgram, isUpdate: boolean) => {
    const newData = cloneDeep(rePriceData)
    const { programs, prices, lockDays } = newData[selectedRatesheetType]

    const { newPrices, newPrograms } = updateProgram(programs, prices, lockDays, product, program, isUpdate)

    newData[selectedRatesheetType].programs = newPrograms
    newData[selectedRatesheetType].prices = newPrices
    setRePriceData(newData)
    setIsUpdated(true)
  }

  const removeProgram = async (productID: number, programID: number) => {
    const newData = cloneDeep(rePriceData)
    const { prices, products, programs, selectedProductId, selectedProgramId } = newData[selectedRatesheetType]

    const targetProduct = products.find((item: any) => item.Id === productID)
    const targetProgram = programs.find((item: any) => item.ID === programID)
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Program?
        <br />
        <span className="text-gray-600 text-base">Product: {targetProduct?.Description}</span>
        <br />
        <span className="text-gray-600 text-base">Program: {targetProgram?.Name}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const { newPrices } = deleteProgram(prices, productID, programID)

    if (productID === selectedProductId && programID === selectedProgramId) {
      newData[selectedRatesheetType].selectedProgram = []
      newData[selectedRatesheetType].selectedProductId = undefined
      newData[selectedRatesheetType].selectedProgramId = undefined
      newData[selectedRatesheetType].priceIndex = { index: -1, index1: -1 }
    } else {
      const nSelectedProgram = newPrices
        .find((item) => item.ProductID === selectedProductId)
        ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

      newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    }
    newData[selectedRatesheetType].prices = newPrices

    setRePriceData(newData)
    setIsUpdated(true)
  }

  const addLockDay = (lockDayIndex: number, value: number, isUpdate: boolean) => {
    const newData = cloneDeep(rePriceData)
    const { lockDays, prices, selectedProductId, selectedProgramId } = newData[selectedRatesheetType]

    const { newLockDays, newPrices } = updateLockDay(prices, lockDays, lockDayIndex, value, isUpdate)

    const nSelectedProgram = newPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

    newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    newData[selectedRatesheetType].lockDays = newLockDays
    newData[selectedRatesheetType].prices = newPrices

    setRePriceData(newData)
    setIsUpdated(true)
  }

  const removeLockDay = (index: number) => {
    const newData = cloneDeep(rePriceData)
    const { lockDays, prices, selectedProductId, selectedProgramId } = newData[selectedRatesheetType]

    const { newLockDays, newPrices } = deleteLockDay(prices, lockDays, index)
    const nSelectedProgram = newPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

    newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    newData[selectedRatesheetType].lockDays = newLockDays
    newData[selectedRatesheetType].prices = newPrices

    setRePriceData(newData)
    setIsUpdated(true)
  }

  const onAddRate = (index: number, value: number) => {
    const newData = cloneDeep(rePriceData)
    const { prices, lockDays, selectedProductId, selectedProgramId } = newData[selectedRatesheetType]

    const { newPrices } = addRate(prices, lockDays, selectedProductId, selectedProgramId, index, value)

    const nSelectedProgram = newPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

    newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    newData[selectedRatesheetType].prices = newPrices

    setRePriceData(newData)
    setIsUpdated(true)
  }

  const onRemoveRate = async (index: number) => {
    const newData = cloneDeep(rePriceData)
    const { prices, selectedProductId, selectedProgramId, selectedProgram } = newData[selectedRatesheetType]

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Rate?
        <br />
        <span className="text-gray-600 text-base">Rate: {selectedProgram[index].Rate + adjustmentObject.rate}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const { newPrices } = deleteRate(prices, selectedProductId, selectedProgramId, index)

    const nSelectedProgram = newPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

    newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    newData[selectedRatesheetType].prices = newPrices

    setRePriceData(newData)
    setIsUpdated(true)
  }

  const onUpdatePrice = (rateIndex: number, lockDayIndex: number, price: number) => {
    const newData = cloneDeep(rePriceData)
    const { prices, selectedProductId, selectedProgramId } = newData[selectedRatesheetType]

    const { newPrices } = updatePrice(prices, selectedProductId, selectedProgramId, rateIndex, lockDayIndex, price)

    const nSelectedProgram = newPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

    newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    newData[selectedRatesheetType].prices = newPrices

    setRePriceData(newData)
    setIsUpdated(true)
  }

  const onUpdateRateSpreadExitFee = (index: number, key: 'RateSpread' | 'ExitFee', value: number) => {
    const newData = cloneDeep(rePriceData)
    const { prices, selectedProductId, selectedProgramId } = newData[selectedRatesheetType]

    const { newPrices } = updateRateSpreadExitFee(prices, selectedProductId, selectedProgramId, index, key, value)

    const nSelectedProgram = newPrices
      .find((item) => item.ProductID === selectedProductId)
      ?.Programs.find((item) => item.ProgramID === selectedProgramId)?.Prices

    newData[selectedRatesheetType].selectedProgram = nSelectedProgram
    newData[selectedRatesheetType].prices = newPrices

    setRePriceData(newData)
    setIsUpdated(true)
  }

  const onConfirmOverride = () => {
    let errors = 0
    let temp = cloneDeep(ovFields)
    let json: any = {}
    Object.keys(temp).map((key) => {
      if (['override', 'note'].indexOf(key) !== -1) return
      json[key] = temp[key].value
      temp[key].error = InputValidate({ ...temp[key] })
      if ((temp as any)[key].error.length > 0) errors += 1
    })
    setOvFields(temp)
    if (errors) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    json.sheetDate = `${formatDate('now')}(E)`
    setPriceSelectOnRePricing({ loanProcessData: json })
    setModal('')
    return true
  }

  const onChangeOverrideField = (key: string, e: any) => {
    let temp = cloneDeep(ovFields)
    temp[key].value = e
    temp[key].error = InputValidate({ ...temp[key] })
    setOvFields(temp)
  }

  const openOverrideModal = async () => {
    setModal('override')
    const temp: any = overrideFields()

    if (!isNonDSCRProduct(loan.productType)) {
      delete temp['rateSpread']
      delete temp['exitFee']
    }

    try {
      setAction('loadingProductTypeProgram')
      const res = await getCurrentProductTypesPrograms()
      setAction('')

      Object.keys(temp).map((key) => {
        let value: any = null
        if (currentData?.loanProcessData) {
          value = currentData.loanProcessData[key]
        } else if (props.loanProcessData) {
          value = props.loanProcessData[key]
        }

        if (key === 'lockDays') value = String(value)

        if (value !== null) {
          temp[key].value = value
        }

        if (key === 'type') temp[key].options = res.productTypes
        if (key === 'program') temp[key].options = res.programNames
      })
      setOvFields(temp)
    } catch (error) {
      console.log(error)
      setAction('')
    }
  }

  const onConfirmRePriceLoan = (revalidate: boolean) => {
    const newNotifiers: any = cloneDeep(notifiers)
    if (isCompanyEmail) newNotifiers.from = 'self'
    else newNotifiers.from = 'default'
    setNotifiers(newNotifiers)

    let title = ''
    if (revalidate)
      title = showExceptionReviewRequest
        ? pendingChangeRequest?.pricingChanges && pendingChangeRequest?.type === 2
          ? 'update Exception Review Request'
          : 'send Exception Review Request'
        : lockExpired && isRelockRate
        ? 'Relock Rate'
        : 'Revalidate Loan'
    else {
      title = 'Make Loan Change Request'
      if (pendingChangeRequest?.pricingChanges && pendingChangeRequest?.type === 1) title = 'Update Loan Change Request'
    }

    setDefaultNotifiers()
    setIsRevalidate(revalidate)
    setRevalidateConfirmTitle(title)
    setModal('revalidateConfirm')
  }

  const onRePriceLoan = async () => {
    const loanProcessData = currentData?.loanProcessData

    setAction(isRevalidate ? 'revalidateLoan' : 'changeRequest')
    props.setAction('updateLoanFields')
    let json: any = {}
    const fields = getInitialLoanFields()
    Object.keys(changedLoanDetailJson).map((key) => {
      if (fields[key]) {
        json[key] = changedLoanDetailJson[key].changed
      }
    })
    json.changedJson = changedLoanDetailJson
    json.rePriceJson = {
      originLoanProcessData: props.loanProcessData,
      loanProcessData,
      showingFields: showingFields,
      pricingEngineIsOff: pricingEngineIsOff,
      noPriceReduction: isRelockRate,
      ratesheetData: currentData.ratesheetJson,
      overridePricing: selectedRatesheetType === 'override',
      pendingTaskID: pendingChangeRequest?.taskID,
      revalidate: isRevalidate,
      exceptionReviewRequest: showExceptionReviewRequest,
      lockExpired,
    }

    if (json.rePriceJson.overridePricing && ovFields.note.value) {
      json.rePriceJson.overrideNote = ovFields.note.value
    }

    json.emailTo = notifiers
    json.comment = revalidateComment
    json.isNonDscrProduct = isNonDSCRProduct(loan.productType)

    const res = await loanRePriceAfterLocked(json)
    if (res.success) {
      if (isRevalidate && !showExceptionReviewRequest) {
        setShowContent(false)
        setRePriceData({})
        props.onSuccessRePrice()
        props.setLoanResultFeed(currentData.ratesheetJson)
        props.setLoanProcessData(lockExpired && isRelockRate ? res.data : loanProcessData)
        props.setPendingChangeRequest(undefined)
      } else {
        props.setPendingChangeRequest(res.data)
      }
    }
    setAction('')
    setComment('')
    props.setAction('')
  }

  const onChangeIsRelockRateToggle = () => {
    setIsRelockRate(!isRelockRate)

    const data = {
      loanProcessData: {},
      ratesheetJson: {},
    }

    let temp = cloneDeep(rePriceData)

    Object.keys(temp).forEach((type) => {
      temp[type] = {
        ...temp[type],
        ...data,
      }
    })

    setRePriceData(temp)
  }

  const renderRePricingResult = (loanProcessData: any = {}) => {
    let keys = ['type', 'program', 'rate', 'price', 'sheetDate', 'lockDays']

    if (isNonDSCRProduct(loan.productType)) keys = keys.concat(['rateSpread', 'exitFee'])

    return (
      <div className="overflow-auto">
        <table className="w-full table-auto cursor-pointer shadow">
          <thead className="text-gray-700 bg-gray-50">
            <tr className="text-center">
              <th scope="col" className="border p-1"></th>
              <th scope="col" className="border p-1">
                Type
              </th>
              <th scope="col" className="border p-1">
                Program
              </th>
              <th scope="col" className="border p-1">
                Rate
              </th>
              <th scope="col" className="border p-1">
                Price
              </th>
              <th scope="col" className="border p-1">
                Rate Date
              </th>
              <th scope="col" className="border p-1">
                Lock Days
              </th>
              {isNonDSCRProduct(loan.productType) && (
                <>
                  <th scope="col" className="border p-1">
                    Rate Spread
                  </th>
                  <th scope="col" className="border p-1">
                    Exit Fee
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody className="text-gray-900">
            {pendingChangeRequest?.pricingChanges &&
              pendingChangeRequest?.pricingChanges.rate > 0 &&
              !hidePendingExceptionReviewRequest && (
                <tr className="bg-white border">
                  <td className="p-2 bg-blue-100 font-variation-settings-600">Pending</td>
                  {keys.map((key, index) => {
                    return (
                      <td className="p-2 text-center border bg-blue-100" key={index}>
                        {convertNullValue(pendingChangeRequest?.pricingChanges?.[key])}
                      </td>
                    )
                  })}
                </tr>
              )}
            <tr className="bg-white border">
              <td className="p-2 bg-gray-50 font-variation-settings-600">Current</td>
              {keys.map((key, index) => {
                return (
                  <td className="p-2 text-center border" key={index}>
                    {convertNullValue(props.loanProcessData[key])}
                  </td>
                )
              })}
            </tr>
            <tr className="bg-white border">
              <td className="p-2 bg-gray-50 font-variation-settings-600">New</td>
              {keys.map((key, index) => {
                let value: any = loanProcessData[key]
                let cn = ''
                if (loanProcessData.rate === 0 || loanProcessData.rate === undefined) value = ''
                else {
                  if (value != props.loanProcessData[key]) cn = `bg-red-300`
                }
                return (
                  <td className={`p-2 text-center border ${cn}`} key={index}>
                    {loanProcessData.rate ? convertNullValue(value) : value}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const renderActions = () => {
    const newPriced = currentData?.loanProcessData?.rate > 0
    const show = newPriced || renderLoanDetailChanges !== null || showExceptionReviewRequest

    if (show === false) return null

    return (
      <div className="relative m-2 md:m-4 p-2 md:p-4 shadow1 rounded max-w-[900px]">
        {renderLoanDetailChanges}

        <div className="">
          <div className="border-b mb-2 font-bold">Loan Pricing Changes</div>
          <div className="mb-3">{renderRePricingResult(currentData?.loanProcessData)}</div>

          {(newPriced || (sandBoxMode && renderLoanDetailChanges !== null)) && (
            <div>
              <div
                className="pl-3 pr-3 mb-4 text-[15px] bg-gray-100 border border-gray-400 text-gray-700 rounded-lg gap-2"
                role="alert"
              >
                {sandBoxMode ? (
                  <div className="my-3">
                    - If you need to changes in{' '}
                    <span className="font-bold italic border-b border-gray-400 ">Loan Details</span> or{' '}
                    <span className="font-bold italic border-b border-gray-400 ">Pricing</span> you can make{' '}
                    <span className="font-bold">Loan Change Request</span>.
                  </div>
                ) : (
                  <div className="my-3">
                    - In order for your changes in{' '}
                    <span className="font-bold italic border-b border-gray-400 ">Loan Detail</span> &{' '}
                    <span className="font-bold italic border-b border-gray-400 ">New Pricing</span> to take effect you
                    must {showExceptionReviewRequest ? 'send ' : ''}
                    <span className="font-bold">
                      {showExceptionReviewRequest
                        ? 'Exception Review Request'
                        : lockExpired && isRelockRate
                        ? 'Relock Rate'
                        : 'Revalidate the Loan'}
                    </span>
                    .
                  </div>
                )}
              </div>

              {calculatedDSCRFragment.element}

              <div className="">
                {!sandBoxMode && (
                  <div>
                    <div className="flex gap-2">
                      <Button
                        color="green"
                        className={`mb-[0px] ${showExceptionReviewRequest ? 'w-[350px]' : 'w-[300px]'}`}
                        onClick={() => onConfirmRePriceLoan(true)}
                        loading={action === 'revalidateLoan'}
                        disabled={
                          !calculatedDSCRFragment.success ||
                          (lockExpired && !reLockDateObj.canRelock) ||
                          (!canOnlyRevalidate && !isRelockRate)
                        }
                      >
                        {showExceptionReviewRequest
                          ? pendingChangeRequest?.pricingChanges && pendingChangeRequest?.type === 2
                            ? 'Update Exception Review Request'
                            : 'Exception Review Request'
                          : lockExpired && !canOnlyRevalidate
                          ? 'Relock Rate'
                          : 'Revalidate Loan'}
                      </Button>

                      {lockExpired && canEditLoanDetail && canRelockRate && (
                        <Button
                          color="green"
                          className={`mb-[0px] w-[300px]`}
                          onClick={() => onConfirmRePriceLoan(true)}
                          loading={action === 'revalidateLoan'}
                          disabled={
                            !calculatedDSCRFragment.success ||
                            (lockExpired && !reLockDateObj.canRelock) ||
                            (!canOnlyRevalidate && isRelockRate)
                          }
                        >
                          Revalidate Loan
                        </Button>
                      )}
                    </div>

                    {lockExpired && !reLockDateObj.canRelock && (
                      <div className="mt-2 ml-2 text-red-700">
                        You can relock until{' '}
                        <span className="font-semibold italic">{reLockDateObj.relockExpireDate}.</span>
                      </div>
                    )}
                  </div>
                )}

                {sandBoxMode && (
                  <Button
                    color={sandBoxMode ? 'green' : 'gray'}
                    className="mb-[0px] w-[300px]"
                    onClick={() => onConfirmRePriceLoan(false)}
                    loading={action === 'changeRequest'}
                    disabled={!calculatedDSCRFragment.success}
                  >
                    {pendingChangeRequest?.pricingChanges && pendingChangeRequest?.type === 1
                      ? 'Update Loan Change Request'
                      : 'Loan Change Request'}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderOpenButtons = () => {
    const highLight = renderLoanDetailChanges !== null || showRelockRate
    const label = showRelockRate ? 'ReLock Rate' : 'Revalidate'
    return (
      <div className="fixed right-0 top-[50%] z-20">
        <button
          className={`p-3 rounded-r-none ${
            highLight ? 'ring-[5px] ring-red-400 bg-red-600' : 'bg-shade-blue hover:ring-[2px] ring-shade-blue'
          }  text-white hover:bg-white hover:text-shade-blue rounded flex items-center gap-2 border-r-0`}
          onClick={showSlide}
        >
          {highLight && (
            <svg className="animate-bounce w-6 h-6">
              <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
            </svg>
          )}
          <CalculatorIcon className="w-5 h-5"></CalculatorIcon>
          {label}
        </button>
      </div>
    )
  }

  const renderLoanDetailChanges = useMemo(() => {
    const keys = Object.keys(changedLoanDetailJson)

    let loanDetailChanges: Record<string, ILoanDetailChangedJson> = {}
    let pendingKeys: any = []
    let pricingKeys: any = []

    if (pendingChangeRequest?.loanDetailChanges) {
      pendingKeys = Object.keys(pendingChangeRequest.loanDetailChanges)
      loanDetailChanges = pendingChangeRequest.loanDetailChanges
    }
    if (pendingChangeRequest?.pricingChanges) {
      pricingKeys = Object.keys(pendingChangeRequest.pricingChanges)
    }

    if (keys.length + pendingKeys.length + pricingKeys.length === 0) return null

    return (
      <div className="mb-4">
        <div className="border-b flex flex-wrap justify-between items-end">
          <div className="font-bold mb-2 ">Loan Detail Changes</div>
          {((needExceptionReviewRequest && pendingChangeRequest?.type === 2) ||
            (sandBoxMode && pendingChangeRequest?.type === 1) ||
            canPriceAdjust) &&
            pricingKeys.length > 0 && (
              <Button
                className="py-[4px] loanChangeCancelRequest"
                color="red"
                onClick={() => props.onCancelChangeRequest(canPriceAdjust)}
              >
                <span>{canPriceAdjust ? 'Decline' : 'Cancel'} Request</span>
              </Button>
            )}
        </div>

        {pendingKeys.length > 0 && !hidePendingExceptionReviewRequest && (
          <div className="overflow-auto mb-4 mt-2">
            <table className="table-auto cursor-pointer shadow">
              <thead className="text-gray-700 bg-gray-50">
                <tr className="">
                  <th scope="col" className="border p-1"></th>
                  {pendingKeys.map((key: string, index: number) => {
                    return (
                      <td scope="col" className="border p-1" key={index}>
                        {loanDetailChanges[key].label}
                      </td>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-3 py-1 bg-blue-100">Pending</td>
                  {Object.keys(loanDetailChanges).map((key: string, index: number) => {
                    return (
                      <td scope="col" className="border p-1 bg-blue-100 text-center" key={index}>
                        {convertValueToLabel(key, loanDetailChanges[key].changed)}
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className="overflow-auto">
          <table className="table-auto cursor-pointer shadow">
            <thead className="text-gray-700 bg-gray-50">
              <tr className="">
                <th scope="col" className="border p-1">
                  Type
                </th>
                <th scope="col" className="border p-1">
                  Current
                </th>
                <th scope="col" className="border p-1">
                  New
                </th>
              </tr>
            </thead>
            <tbody>
              {keys.map((key, index) => {
                const item = changedLoanDetailJson[key]
                const origin = convertValueToLabel(key, item.origin)
                const changed = convertValueToLabel(key, item.changed)

                return (
                  <tr key={index}>
                    <td className="border px-3 py-1">{item.label}</td>
                    <td className="border px-3 py-1">{origin}</td>
                    <td className="border px-3 py-1 bg-red-300">{changed}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }, [changedLoanDetailJson, pendingChangeRequest, hidePendingExceptionReviewRequest])

  const calculatedDSCRFragment = useMemo(() => {
    if (currentData?.loanProcessData?.rate > 0) {
      if (!isNonDSCRProduct(loan.productType)) {
        const pitis = combinedMonthlyExpense(loan, currentData.loanProcessData, true)

        // const success =
        //   removeComma(pitis['dscr']) >= DscrRangeMapping[loan.estimatedDscr].from &&
        //   removeComma(pitis['dscr']) <= DscrRangeMapping[loan.estimatedDscr].to
        const success = true

        let color = success ? 'green' : 'red'

        return {
          success,
          element: (
            <div
              className={`p-3 mb-4 text-[15px] bg-${color}-100 border border-${color}-400 text-${color}-700 rounded-lg gap-2`}
              role="alert"
            >
              <div>
                {success ? (
                  <div>After Revalidate Loan with above Changes</div>
                ) : (
                  <div>
                    Due to a change in interest rate, the DSCR has changed.
                    <br />
                    Please update the <span className="italic underline">DSCR</span> to the
                    <span className="italic underline"> Calculated DSCR</span> in the loan structure section on the
                    left.
                  </div>
                )}
              </div>
              <div className="my-1 flex gap-2">
                <div className="w-[150px]">Calculated DSCR:</div>{' '}
                <span className={`text-${color}-900`}>{pitis['dscr']}</span>
              </div>
            </div>
          ),
        }
      }
    }
    return {
      success: true,
      element: '',
    }
  }, [loan, currentData])

  return (
    <div>
      {renderOpenButtons()}

      {showContent && (
        <div
          className={`${
            sandBoxMode ? 'border-[3px] border-red-300' : 'border'
          } shadow1 fixed bg-white rounded-l z-30 right-0 top-[5vh] bottom-0 transition-[right] w-[calc(50vw-21px)] h-[90vh] overflow-auto ease-in-out`}
        >
          <div className="relative">
            <LayoutLoading show={['revalidateLoan', 'changeRequest'].includes(action)} />
            <div className="w-full fixed z-20 rounded-t flex p-3 font-bold text-sm place-items-center items-center text-shade-blue bg-white">
              <p className="text-2xl text-gray-800">
                {sandBoxMode ? 'Ratesheet Sandbox' : showRelockRate ? 'ReLock Rate' : 'Revalidate Loan'}
              </p>
              {sandBoxMode ? (
                <div className="ml-4">
                  <Toggle
                    className="pt-1"
                    id="sandbox"
                    title={'Sandbox Mode'}
                    key={'sandbox'}
                    value={props.sandBoxMode}
                    onChange={props.changeSandBoxMode}
                  />
                </div>
              ) : (
                <button className="hover:underline ml-4 p-1 rounded hover-shadow1" onClick={hideSlide}>
                  <ArrowRightCircleIcon className="w-5 h-5" />
                </button>
              )}
            </div>
            <div className="border-b pt-14"></div>

            <div className="h-fit overflow-auto relative text-sm text-gray-900">
              {lockExpired && (
                <div
                  className={`max-w-[800px] mt-4 mx-4 p-3 text-[16px] border ${
                    reLockDateObj.canRelock
                      ? 'bg-blue-100 border-blue-400 text-blue-700'
                      : 'bg-red-100 border-red-400 text-red-700'
                  } rounded-lg`}
                  role="alert"
                >
                  <div className="mb-2">
                    - You can relock until{' '}
                    <span className="font-semibold italic">{reLockDateObj.relockExpireDate}.</span>
                  </div>
                  <div>- All relocks are subject to 0.125% in price. ({relockTimesValue} x 0.125)</div>
                </div>
              )}

              <LayoutLoading show={action === 'init'} />

              <div className="p-2 md:p-4 flex flex-wrap items-center max-w-[800px] justify-between">
                <ButtonGroup title={ratesheetTypes} onChange={onChangeRatesheetType} value={selectedRatesheetType} />
                <Link to={`/loan_change_request/${loanDetail.loanNumber}`} target="_blank" rel="noopener noreferrer">
                  <div className="hover:underline text-[14px] ml-4 my-3 flex flex-wrap gap-1 cursor-pointer text-shade-blue items-center">
                    <span>Change Request Logs</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                  </div>
                </Link>
              </div>

              {renderActions()}

              <div className="m-2 md:m-4 p-2 md:mt-[0px] shadow1 pb-6 max-w-[756px] rounded">
                <LayoutLoading show={action === 'getPricing'} />
                {action !== 'getPricing' && currentData && selectedRatesheetType !== 'override' && (
                  <LoanResult
                    auth={auth}
                    loanRateSheet={loanRateSheet}
                    loan={loan}
                    loanDetail={loanDetail}
                    relockTimes={relockTimes}
                    lockExpired={lockExpired}
                    programs={currentData.programs}
                    products={currentData.products}
                    priceAdjustments={loanDetail.priceAdjustments}
                    ratesheetID={currentData.ratesheetID}
                    prices={currentData.prices}
                    selectedProgramId={currentData.selectedProgramId}
                    selectedProductId={currentData.selectedProductId}
                    selectedProgram={currentData.selectedProgram}
                    lockDays={currentData.lockDays}
                    priceLoanTime={currentData.priceLoanTime}
                    handleSelectProgram={handleSelectProgram}
                    loanNumber={loanDetail.loanNumber}
                    limit={currentData.limit}
                    setPriceSelectOnRePricing={setPriceSelectOnRePricing}
                    priceIndex={currentData.priceIndex}
                    setPriceIndex={() => {}}
                    setLimit={props.setLimit}
                    loanProcessData={currentData.loanProcessData}
                    inEligibleProducts={currentData.inEligibleProducts}
                    setLoanProcessData={() => {}}
                    setRatesheetSync={() => {}}
                    rePricing={true}
                    highLightRate={currentData.highLightRate}
                    isExceptionReviewRequest={isExceptionReviewRequest}
                    pendingChangeRequest={pendingChangeRequest}
                    canPriceAdjust={canPriceAdjust}
                    isRelockRate={isRelockRate}
                    pricingEngineIsOff={pricingEngineIsOff}
                    onChangeIsRelockRateToggle={onChangeIsRelockRateToggle}
                    addProduct={addProduct}
                    addProgram={addProgram}
                    removeProduct={removeProduct}
                    addLockDay={addLockDay}
                    removeLockDay={removeLockDay}
                    onAddRate={onAddRate}
                    onRemoveRate={onRemoveRate}
                    onUpdatePrice={onUpdatePrice}
                    onUpdateRateSpreadExitFee={onUpdateRateSpreadExitFee}
                    removeProgram={removeProgram}
                    checkValidation={props.checkValidation}
                    isUpdated={isUpdated}
                    setIsUpdated={setIsUpdated}
                    canDoManualPricing={canDoManualPricing}
                    canSeeSubPrograms={canSeeSubPrograms}
                    showSub={showSub}
                    setShowSub={setShowSub}
                  />
                )}

                {selectedRatesheetType === 'override' && (
                  <div className="override-container">
                    {currentData?.loanProcessData && (
                      <div className="mt-3">
                        <div className="text-left text-[14px]">
                          <div className="border-b mb-2 font-bold text-[15px] flex gap-2 items-center">
                            <span>Exception</span>
                            <span className="p-1 rounded hover-shadow1 cursor-pointer" onClick={openOverrideModal}>
                              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                            </span>
                          </div>
                          {renderRePricingResult(currentData.loanProcessData)}
                        </div>
                      </div>
                    )}

                    <div
                      className="p-3 mt-3 text-[16px] bg-yellow-100 border border-yellow-400 text-yellow-700 rounded-lg"
                      role="alert"
                    >
                      If you can't find the pricing from ratesheet,{' '}
                      <span
                        className="italic text-[16px] underline cursor-pointer font-bold"
                        onClick={openOverrideModal}
                      >
                        Click Here
                      </span>{' '}
                      to override values!
                    </div>

                    <Modal
                      isOpen={modal === 'override'}
                      title="Exception"
                      titleOkay="Confirm"
                      loading={['loadingProductTypeProgram'].includes(action)}
                      lastUpdatedAt={Date.now()}
                      onClose={() => setModal('')}
                      onOk={onConfirmOverride}
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2  items-center gap-4 justify-center mb-2 flex-wrap">
                        {Object.keys(ovFields).map((key, index) => {
                          let cn = 'md:col-span-1 min-w-[240px] md:w-96'
                          if (['override'].indexOf(key) !== -1) return null
                          return (
                            <div className={cn} key={index}>
                              <RenderInput input={{ ...ovFields[key] }} Key={key} onChange={onChangeOverrideField} />
                            </div>
                          )
                        })}
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </div>

          {modal === 'revalidateConfirm' && (
            <Modal
              isOpen
              title={`Are you sure want to ${revalidateConfirmTitle}?`}
              titleOkay="Yes, I'm sure"
              titleCancel="No, cancel"
              onClose={() => {
                setModal('')
                setComment('')
              }}
              onOk={() => {
                setModal('')
                onRePriceLoan()
              }}
            >
              <div className="confirmModalLoanChange">
                <div className="text-left text-[13px]">{renderLoanDetailChanges}</div>
                <div className="text-left text-[13px]">
                  <div className="border-b mb-2 font-bold">New Pricing</div>
                  {renderRePricingResult(currentData?.loanProcessData)}
                </div>

                {canEditLoanDetail && (
                  <div className="mt-4 text-[13px]">
                    <div className="border-b mb-2 font-bold">Send Notification To</div>
                    <LoanPartiesEmailTo
                      loanNumber={loanNumber}
                      value={notifiers}
                      data={[]}
                      onChange={setNotifiers}
                      loadParties
                    />

                    <TextArea
                      title="Comment"
                      rows={3}
                      onChange={(v) => setComment(v)}
                      className="mt-4"
                      value={revalidateComment}
                    />
                  </div>
                )}
              </div>
            </Modal>
          )}
        </div>
      )}
    </div>
  )
}
