import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { baseUrl } from 'config'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAmortizationSchedule } from 'services/apis'
import { PlainTable } from 'stories/components'
import { formatDate, getPrice2decimal, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

export const AmortizationScheduleContent = () => {
  const token = useSelector((state: any) => state.auth.token)
  const [action, setAction] = useState('')
  const [product, setProduct] = useState<any>({})
  const [data, setData] = useState<any>([])
  const [trClasses, setTrClasses] = useState<any>({})
  const [inputs, setInputs] = useState<any>({
    period: {
      title: 'Additional Principal Reduction',
      inputType: 'select',
      hasDefaultOption: false,
      options: {
        0: 'None',
        1: 'Every Period',
        2: 'Every 2 Periods',
        3: 'Every 3 Periods',
        4: 'Every 4 Periods',
        6: 'Every 6 Periods',
        12: 'Every 12 Periods',
      },
      disabled: true,
      value: 0,
    },
    amount: {
      title: 'Amount',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      disabled: true,
      value: '',
    },
  })

  const tableData = useMemo(() => {
    let firstPaymentDate = formatDate(data.firstPaymentDate)
    if (firstPaymentDate.length) {
      let tableData = []
      let year = 1
      let balance = data.loanAmount
      const rate = data.rate / 100
      let adjust = 'First Payment'
      let addBalance = 0
      let mortgagePayment = data.mortgagePayment
      for (let i = 1; i <= data.loanTerm; i += 1) {
        const interest = Number(((balance * rate) / 12).toFixed(2))

        const princ = Number((mortgagePayment - interest).toFixed(2))
        balance -= princ
        addBalance = 0
        if (i === data.loanTerm) {
          addBalance = 0 - balance
        }
        tableData.push([
          `${year}`,
          `${i}`,
          firstPaymentDate,
          `$${getPrice2decimal(mortgagePayment - addBalance, false, true)}`,
          `$${getPrice2decimal(interest, false, true)}`,
          `$${getPrice2decimal(princ - addBalance, false, true)}`,
          `$0.00`,
          `$${getPrice2decimal(balance + addBalance, false, true)}`,
          `${data.rate.toFixed(3)}`,
          adjust,
        ])
        if (i % 12 === 0) year += 1
        firstPaymentDate = moment(firstPaymentDate).add(1, 'M').format('MM/DD/YYYY')
        if (i === 1) adjust = ''
        if (i === data.ioMonths) {
          mortgagePayment = Number(data.interestOnlyExpired)
          adjust = 'Interest Only Expired'
        }
        if (i === data.ioMonths + 1) {
          adjust = ''
        }
      }
      let inSum = 0
      tableData.map((item) => {
        inSum += removeComma(item[4])
      })

      tableData.push([
        '',
        '',
        'Totals',
        `$${getPrice2decimal((data.loanAmount + inSum).toFixed(2), false, true)}`,
        `$${getPrice2decimal(inSum.toFixed(2), false, true)}`,
        `$${getPrice2decimal(data.loanAmount, false, true)}`,
        '',
        '',
        '',
        '',
      ])
      setTrClasses({
        [data.loanTerm]: 'font-bold',
      })
      return tableData
    }
    return []
  }, [data, inputs])

  const init = async () => {
    setAction('loading')
    const res = await getAmortizationSchedule()
    if (res.success) {
      setData(res.data)
      setProduct(res.product)
    }
    setAction('')
  }

  useEffect(() => {
    setLoanNumber()
    init()
  }, [])

  const onChange = async (key: string, e: any) => {
    let temp = cloneDeep(inputs)
    temp[key].value = e
    setInputs(temp)
  }

  const onExportExcel = () => {
    const loanNumber = setLoanNumber()
    const url = `${baseUrl}/loan/downloadAmortizationSchedule/${loanNumber}?&token=${token}&period=${
      inputs.period.options[inputs.period.value]
    }&amount=${inputs.amount.value}`

    var windowReference: any = window.open()
    windowReference.document.title = 'Downloading...'
    windowReference.location = url
  }

  return (
    <>
      <LayoutLoading show={action.length > 0} />
      <div className="max-w-[1024px]">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-5">
          {Object.keys(product).map((key, index) => {
            const input = product[key]
            return (
              <div key={index} className={`md:col-span-${input.span || 1}`}>
                <RenderInput
                  input={{
                    inputType: 'text',
                    readOnly: true,
                    value: input.value,
                    title: input.title,
                    prefix: input.prefix,
                  }}
                  Key={key}
                  onChange={onChange}
                />
              </div>
            )
          })}
        </div>
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
            {Object.keys(inputs).map((key, index) => {
              let input = inputs[key]
              if (key === 'closing') input.value = data.closingDate
              return (
                <div key={index} className="md:col-span-1">
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>
          <div className="">
            <button className="hover:underline text-[14px] text-shade-blue p-2" onClick={onExportExcel}>
              <div className="flex items-center">
                <ArrowDownTrayIcon className="w-4 h-4 mr-1" /> Export to Excel
              </div>
            </button>
          </div>
        </div>
        <PlainTable
          header={['Year', 'No', 'Date', 'P&I', 'Interest', 'Princ. Red.', 'Addi P R', 'Balance', 'Rate', 'Adjusts']}
          tdClass="px-4 py-3"
          trClasses={trClasses}
          data={tableData}
        />
      </div>
    </>
  )
}
