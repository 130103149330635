import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { signContractorApplication } from 'services/apis'
import { Button, Modal, Toggle } from 'stories/components'

export const ContractorSignDialog = (props: any) => {
  const { no } = useParams() as any
  const [isReviewed, setReviewed] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onDownloadPdf = () => {
    props.applicationPDF()
  }

  const onConfirmSign = async () => {
    setLoading(true)
    const res = await signContractorApplication(no)
    setLoading(false)
    if (res.success) {
      props.onClose()
      props.onSign(res.data)
    }
  }

  return (
    <Modal
      isOpen
      title="Confirm and Sign Application"
      titleOkay="Confirm and Sign"
      disabled={!isReviewed}
      loading={isLoading}
      onClose={props.onClose}
      onOk={onConfirmSign}
    >
      <div className={`text-gray-600 mb-4 text-md overflow-hidden`}>
        <p className="font-bold">
          Once your application is signed, you will not have the functionality to edit the application
        </p>
        <div className="text-sm bg-gray-100 px-2 pb-4 rounded gap-4">
          <div className="ml-1">
            <Button link onClick={onDownloadPdf}>
              <div className="flex items-center underline text-gray-900 hover:text-shade-blue">
                - View Unsigned Application PDF{' '}
                <span className="ml-2">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </span>
              </div>
            </Button>
            <Toggle
              id="reviewed"
              title="I have reviewed my application"
              onChange={(value) => setReviewed(value)}
              value={isReviewed}
            />
          </div>
          <div
            className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mt-4 text-[14px]"
            role="alert"
          >
            Upon successful signing of this PDF, you will be redirected to the documents upload page.
          </div>
        </div>
      </div>
    </Modal>
  )
}
