import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { getPrice3decimal, isEmpty, removeComma } from 'utils'

import type { IPriceLimit } from '../interfaces'

interface Prop {
  loanAmount?: number
  initialLoanAmount?: string
  maximumTotalLoanAmountTip: {
    tooltip: string
    minValue: number
    lists: number
    constructionReserveMinwithoutChangeLoanAmountValidation: number
  }
  limit: IPriceLimit
  pricingEngineIsOff: boolean
}

const tipsMap: Record<string, string> = {
  'AIV-LTV': `(Proposed Loan Amount - Construction Amount Financed) <br/>/<br/> Min(As Is Appraised Value, Property Purchase Price)`,
  'ARV-LTV': `Proposed Loan Amount <br/>/<br/> After Repair Value`,
  LTC: `Proposed Loan Amount <br/>/<br/> (Min(As Is Appraised Value, Property Purchase Price) + Construction Budget)`,
  LTP: `(Proposed Loan Amount - Construction Amount Financed) <br/>/<br/> Property Purchase Price`,
}

const LimitShow = (label: string, limit: any, value: any, pricingEngineIsOff: boolean) => {
  // if (limit === undefined || limit === null) return null
  if (value === Infinity) return null
  if (value === -Infinity) return null
  if (value === 0 && isEmpty(limit)) return null
  if (isNaN(Number(value))) return null
  if (value === undefined || value === null) return null

  return (
    <div className="flex-initial w-24 mx-2">
      {(!isEmpty(limit) || pricingEngineIsOff) && (
        <>
          <div className="text-center font-bold flex justify-center gap-1 items-center">
            {label}
            <span className="">
              <Tooltip message={tipsMap[label]}></Tooltip>
            </span>
          </div>
          <div className="text-center my-1 font-semibold italic">{value}%</div>

          {!pricingEngineIsOff && (
            <div
              className={`${
                Number(limit) >= Number(value) ? 'bg-lime-600' : 'bg-rose-600'
              } rounded-t text-white text-center`}
            >
              {limit}%
            </div>
          )}
        </>
      )}
    </div>
  )
}

export function MaxLoanAmount(props: Prop) {
  const { loanAmount, initialLoanAmount, maximumTotalLoanAmountTip, limit, pricingEngineIsOff } = props
  let maxLoanAmountClass = ''

  try {
    if (
      maximumTotalLoanAmountTip.lists !== 0 &&
      loanAmount &&
      removeComma(loanAmount) > removeComma(maximumTotalLoanAmountTip.minValue)
    ) {
      maxLoanAmountClass = 'border-[4px] border-red-400 bg-red-200'
    }
  } catch {}

  return (
    <div className="MaxLoanAmount-container">
      <div className="block rounded-t shadow1 p-5">
        <div className="flex justify-center border-b pb-4">
          <div className="">
            <table>
              <tbody>
                {initialLoanAmount !== undefined && (
                  <tr>
                    <td className="p-1 font-variation-settings-500">
                      <span className="flex gap-1 items-center">
                        <span>Initial Loan Amount</span>
                        <span className="">
                          <Tooltip message="Proposed Loan Amount - Construction Amount Financed"></Tooltip>
                        </span>
                      </span>
                    </td>
                    <td className="p-1">
                      <span className="font-bold italic text-[17px] ml-3">$ {initialLoanAmount}</span>
                    </td>
                  </tr>
                )}

                {loanAmount && (
                  <tr>
                    <td className="p-1 font-variation-settings-500">Proposed Loan Amount</td>
                    <td className="p-1">
                      <span className="font-bold italic text-[17px] ml-3">$ {getPrice3decimal(loanAmount)}</span>
                    </td>
                  </tr>
                )}

                {!pricingEngineIsOff && (
                  <tr className={maxLoanAmountClass}>
                    <td className="p-1 font-variation-settings-500">
                      <span className="flex gap-1 items-center">
                        <span>Maximum Eligible Loan Amount</span>
                        <span className="">
                          <Tooltip message={maximumTotalLoanAmountTip.tooltip}></Tooltip>
                        </span>
                      </span>
                    </td>
                    <td className="p-1">
                      <span className="font-bold italic text-[17px] ml-3">
                        {maximumTotalLoanAmountTip.lists === 0 ? (
                          <span>$ -</span>
                        ) : (
                          <span>$ {getPrice3decimal(maximumTotalLoanAmountTip.minValue)}</span>
                        )}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-wrap justify-around mt-4 gap-3">
          {LimitShow('LTC', limit.max_ltc, limit.ltc, pricingEngineIsOff)}
          {LimitShow('LTP', limit.max_ltp, limit.ltp, pricingEngineIsOff)}
          {LimitShow('ARV-LTV', limit.max_arv_ltv, limit.arv_ltv, pricingEngineIsOff)}
          {LimitShow('AIV-LTV', limit.max_aiv_ltv, limit.aiv_ltv, pricingEngineIsOff)}
        </div>
      </div>
    </div>
  )
}
