import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { EmailValue } from 'components/EmailTo'
import { AccountVerificationType, BrokerIdentifyCategory } from 'pages/Admin'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  contractorUploadedDocuments,
  getContractorDocuments,
  sendContractorMessage,
  updateContractorApplication,
} from 'services/apis'
import { getBrokerVerificationCategories } from 'services/apis/admin'
import { Button, PlainTable } from 'stories/components'
import { CategoryDocument, CategoryDocuments } from 'stories/components/CategoryFileTable/CategoryFileTable'
import { formatTime } from 'utils'

import { BorroserEmailDialog } from '../Budget/BorroserEmailDialog'
import { ContractorDocumentHistoryDialog } from './ContractorDocumentHistoryDialog'

export const ContractorDocuments = (props: any) => {
  const { no } = useParams() as any
  const [documents, setDocuments] = useState<Array<CategoryDocument>>([])
  const [histories, setHistories] = useState<any>([])
  const [taskCreate, setTaskCreate] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [categories, setCategories] = useState<BrokerIdentifyCategory[]>([])
  const [historyDoc, setHistoryDoc] = useState<CategoryDocument | null>(null)

  const onDocumentChange = async (docs: Array<CategoryDocument>) => {
    props.setLoading(true)
    const categoryMap: Record<string, string> = {}
    categories.forEach((c) => (categoryMap[c.value || ''] = c.id))
    const newDocs = docs.map((doc) => ({
      ...doc,
      category: categoryMap[doc.category],
    }))

    await updateContractorApplication(no, { documents: newDocs })
    setTaskCreate(true)
    setDocuments(docs)
    props.setLoading(false)
  }

  const onHistory = (doc: CategoryDocument) => {
    setHistoryDoc(doc)
  }

  useEffect(() => {
    if (taskCreate) {
      contractorUploadedDocuments(no)
    }
  }, [taskCreate])

  useEffect(() => {
    const handleVisibilityChange = () => {
      setTaskCreate(false)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const init = async () => {
    props.setLoading(true)
    const res = await getContractorDocuments(no)
    if (res.success) {
      setDocuments(res.documents)
      setHistories(res.histories)
    }
    const values = await getBrokerVerificationCategories(AccountVerificationType.ContractorApproval)
    setCategories(values)
    props.setLoading(false)
  }

  useEffect(() => {
    init()
    window.scrollTo(0, 0)
  }, [])

  const tableData = useMemo(() => {
    let rlt: any = []
    histories.map((item: any, index: number) => {
      rlt.push([index + 1, item.action, item.by, formatTime(item.time)])
    })
    return rlt.reverse()
  }, [histories])

  const filteredCategories = useMemo(() => {
    return categories.filter((v) => (props.canChangeStatus || v.visible) && !!v.value).map((v) => v.value) as any
  }, [categories])

  const showSendMessage = () => {
    setShowMessageModal(true)
  }

  const categoryMap = useMemo(() => {
    const categoryMap: Record<string, string> = {}
    categories.forEach((c) => (categoryMap[c.id] = c.value || ''))
    return categoryMap
  }, [categories])

  const documentsWithCategory = useMemo(() => {
    return documents.map((doc) => ({
      ...doc,
      category: categoryMap[doc.category] || doc.category,
    }))
  }, [documents, categoryMap])

  const onEmailDialogClose = async (result: boolean, selectedEmails: EmailValue, note: string) => {
    if (!result) {
      setShowMessageModal(false)
      return
    }
    await sendContractorMessage(selectedEmails, note, no)
    setShowMessageModal(false)
  }

  return (
    <div className="ContractorSignDialog-container">
      <div
        className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4 text-[14px]"
        role="alert"
      >
        Please upload the appropriate documentation under each document category.
      </div>
      <CategoryDocuments
        documents={documentsWithCategory}
        onChange={onDocumentChange}
        setLoading={props.setLoading}
        type={'ContractorApplication'}
        showStatus={true}
        canChangeStatus={props.canChangeStatus}
        categories={filteredCategories}
        onHistory={onHistory}
      />
      <div className="mt-6">
        <div className="mb-2 flex flex-wrap gap-4 items-center justify-between">
          <span className="font-bold text-[18px]">- Histories</span>
          <Button onClick={showSendMessage}>
            <div className="flex  items-center">
              <p>Send Message</p>
              <ArrowRightIcon className="w-4 h-4 ml-2"></ArrowRightIcon>
            </div>
          </Button>
        </div>
        <div>
          <PlainTable header={['No', 'Action', 'By', 'Date']} data={tableData} tdClass="px-4 py-2" />
        </div>
      </div>
      {showMessageModal && <BorroserEmailDialog onClose={onEmailDialogClose} />}

      {historyDoc && <ContractorDocumentHistoryDialog data={historyDoc} onClose={() => setHistoryDoc(null)} />}
    </div>
  )
}
