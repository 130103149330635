import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { PlainInput } from 'components/PlainInput'
import { SaveChanges } from 'components/SaveChanges'
import { INVALID_ALL_INPUTS } from 'config'
import type { ILoan } from 'pages/LoanStructure/interfaces'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getUnderwritingSummary, updateUnderwritingSummary } from 'services'
import { getGeoLocation } from 'services/apis/googlemap'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import {
  defaultInputs,
  inputGroups,
  recordingInfoFields,
  RecordingInformationOptions,
  sourceOfBorrowersInterestOptions,
} from './constants'

export function UnderwritingSummary({ child = false }) {
  const { loan } = useSelector((state: any) => {
    return {
      loan: state.loan as ILoan,
    }
  })

  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)
  const [init, setInit] = useState(true)
  const [changed, setChanged] = useState(false)
  const [recordingInfo, setRecordingInfo] = useState({
    book: '',
    page: '',
    instrument: '',
  })
  const [recordingInfoError, setRecordingInfoError] = useState({
    book: '',
    page: '',
    instrument: '',
  })
  const [latLong, setLatLong] = useState({ lat: 0, lng: 0 })

  useEffect(() => {
    const nInputs = cloneDeep(inputs)
    const newRecordingInfo = cloneDeep(recordingInfo)

    if (loan.transactionType === 'Purchase') {
      delete nInputs.BorrowersInterestSourceTypeInSubjectProperty
      delete nInputs.BorrowersInterestSourceTitleInSubjectProperty
      delete nInputs.RecordingInfo
      delete nInputs.BorrowersInterestSourceDateInSubjectProperty
      nInputs.purchasePrice.disabled = true
    } else {
      nInputs.purchasePrice.disabled = false
    }

    setLoading(true)

    getUnderwritingSummary()
      .then((data) => {
        Object.keys(nInputs).forEach((key) => {
          if (key === 'RecordingInfo') {
            if (Object.keys(RecordingInformationOptions).includes(data[key])) nInputs[key].value = data[key]
            else nInputs[key].value = ''
          } else nInputs[key].value = data[key]
          nInputs[key].error = InputValidate(nInputs[key])
        })
        ;['book', 'page', 'instrument'].forEach((key) => {
          if (!!data[key]) (newRecordingInfo as any)[key] = data[key]
          else (newRecordingInfo as any)[key] = ''
        })

        setInputs(nInputs)
        setRecordingInfo(newRecordingInfo)
        setLoading(false)
        setInit(false)
      })
      .catch(() => setLoading(false))
  }, [loan])

  useEffect(() => {
    initGeoLocation(loan.subjectPropertyAddress)
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])

    if (key === 'BorrowersInterestSourceTypeInSubjectProperty') {
      if (value === 'other') newInputs['BorrowersInterestSourceTitleInSubjectProperty'].value = ''
      else newInputs['BorrowersInterestSourceTitleInSubjectProperty'].value = sourceOfBorrowersInterestOptions[value]
    }

    setChanged(true)
    setInputs(newInputs)
  }

  const validateRecordingInfo = () => {
    const newRecordingInfoError = cloneDeep(recordingInfoError)

    let hasError = false

    if (recordingInfoFields[inputs.RecordingInfo.value])
      recordingInfoFields[inputs.RecordingInfo.value].forEach((key) => {
        if (!(recordingInfo as any)[key]) {
          hasError = true
          ;(newRecordingInfoError as any)[key] = 'Required field'
        } else {
          ;(newRecordingInfoError as any)[key] = ''
        }
      })
    else hasError = true

    setRecordingInfoError(newRecordingInfoError)
    return hasError
  }

  const onSave = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    let data: Record<string, any> = {}

    Object.keys(newInputs).forEach((key) => {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    })

    setChanged(false)
    setInputs(newInputs)

    if (loan.transactionType === 'Refinance') {
      if (inputs.RecordingInfo.value)
        recordingInfoFields[inputs.RecordingInfo.value].forEach((key) => {
          data[key] = (recordingInfo as any)[key]
        })

      hasError = validateRecordingInfo()
    }

    if (hasError) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    setLoading(true)

    updateUnderwritingSummary(data)
      .then(() => {
        toast('Successfully updated', { type: 'info' })
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'Underwriting',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  const onChangeRecordingInfo = (key: 'book' | 'page' | 'instrument', value: string) => {
    const newRecordingInfo = cloneDeep(recordingInfo)

    newRecordingInfo[key] = value
    setRecordingInfo(newRecordingInfo)
  }

  const initGeoLocation = async (address: string) => {
    const latLong = await getGeoLocation(address)
    setLatLong(latLong)
  }

  const renderRecordingInformation = useMemo(() => {
    if (loan.transactionType === 'Purchase') return <></>

    switch (inputs.RecordingInfo.value) {
      case 'bookAndPageOnly':
        return (
          <div className="flex items-center gap-2">
            <span>Book</span>
            <div className={`border-b w-40 ${recordingInfoError.book && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.book}
                content={recordingInfo.book}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('book', value)}
              />
            </div>
            <span>and</span>
            <span>Page</span>
            <div className={`border-b w-16 ${recordingInfoError.page && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.page}
                content={recordingInfo.page}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('page', value)}
              />
            </div>
          </div>
        )
      case 'instrumentOnly':
        return (
          <div className="flex items-center gap-2">
            <span>Instrument #</span>
            <div className={`border-b w-60 ${recordingInfoError.instrument && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.instrument}
                content={recordingInfo.instrument}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('instrument', value)}
              />
            </div>
          </div>
        )
      case 'bookAndPageWithInstrument':
        return (
          <div className="flex items-center flex-wrap gap-2">
            <span>Book</span>
            <div className={`border-b w-40 ${recordingInfoError.book && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.book}
                content={recordingInfo.book}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('book', value)}
              />
            </div>
            <span>and</span>
            <span>Page</span>
            <div className={`border-b w-16 ${recordingInfoError.page && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.page}
                content={recordingInfo.page}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('page', value)}
              />
            </div>
            <span>under</span>
            <span>Instrument #</span>
            <div className={`border-b w-60 ${recordingInfoError.instrument && 'border-rose-700'}`}>
              <PlainInput
                value={recordingInfo.instrument}
                content={recordingInfo.instrument}
                type="text"
                onChange={(value: any) => onChangeRecordingInfo('instrument', value)}
              />
            </div>
          </div>
        )
      default:
        return <></>
    }
  }, [inputs.RecordingInfo, recordingInfo, recordingInfoError, loan])

  return (
    <div className={`underwriting-summary-container ${!child && 'px-2'}`}>
      {!child && <Overview title="Underwriting Summary" />}
      <SaveChanges show={changed} onSave={onSave} />
      <div className="max-w-screen-2xl m-auto">
        <div className={`bg-white rounded ${!child && 'shadow1 mb-6 p-4'}`}>
          <div className="">
            <LayoutLoading show={isLoading} />
            {!init && (
              <div className="grid md:grid-cols-2 gap-5">
                {Object.keys(inputGroups[loan.transactionType]).map((key, index) => {
                  const inputGroup = inputGroups[loan.transactionType][key]

                  return (
                    <div key={index} className="md:col-span-1">
                      <div className="shadow rounded border">
                        <div className="bg-gray-200 round-t py-1 px-4">{key}</div>
                        <div className="p-2">
                          <div className="grid gap-4 md:grid-cols-6 grid-cols-1 mb-3">
                            {inputGroup.map((item: string) => {
                              if (
                                item === 'BorrowersInterestSourceTitleInSubjectProperty' &&
                                inputs['BorrowersInterestSourceTypeInSubjectProperty'].value !== 'other'
                              )
                                return <div></div>
                              let input = inputs[item]
                              if (item === 'constructionCompanyAddressMap') {
                                return (
                                  <div className={`input md:col-span-${input.span || 2} h-[200px]`} key={item}>
                                    <RenderInput
                                      input={{
                                        ...input,
                                        value: latLong as any,
                                      }}
                                      Key={key}
                                      onChange={onChange}
                                    />
                                  </div>
                                )
                              }
                              input.history = !input.disabled
                              input.className = 'flex items-center'

                              return (
                                <div className={`input md:col-span-${input.span || 2}`} key={item}>
                                  <RenderInput input={input} Key={item} onChange={onChange} showHistory={showHistory} />
                                </div>
                              )
                            })}
                          </div>
                          {key === 'Source of Borrowers Interest In Subject Property' && (
                            <div className="p-2">{renderRecordingInformation}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}

                <div className="flex justify-center md:col-span-2">
                  <Button className="w-[240px]" loading={isLoading} onClick={onSave}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
