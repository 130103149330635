import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addNewCompanyAppraisal, updateCompanyAppraisal } from 'services'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { CompanyAppraisal } from './types'

export const defaultCompanyAppraisalInputs = (): Record<string, InputType> => {
  return {
    company: {
      inputType: 'text',
      type: 'text',
      title: 'Company',
      value: '',
      visible: true,
      required: true,
      span: 2,
    },
    contactName: {
      inputType: 'text',
      type: 'text',
      title: 'Contact Name',
      value: '',
      visible: true,
      required: true,
      span: 2,
    },
    phoneNumber: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone Number',
      value: '',
      visible: true,
      required: true,
      span: 2,
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      value: '',
      visible: true,
      required: true,
      span: 2,
    },
  }
}

export const EditCompanyAppraisalFieldsDialog = (props: { onClose: any; origin: CompanyAppraisal | null }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultCompanyAppraisalInputs())

  useEffect(() => {
    const newInputs = cloneDeep(inputs)

    const { origin } = props
    if (!origin) return

    newInputs.company.value = origin.company || ''
    newInputs.contactName.value = origin.contactName || ''
    newInputs.phoneNumber.value = origin.phoneNumber || ''
    newInputs.email.value = origin.email || ''

    setInputs(newInputs)
  }, [props.origin])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)

    const data: Record<string, any> = {}

    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
      data[key] = newInputs[key].value
    }

    setInputs(newInputs)
    if (hasError) return

    if (props.origin) {
      updateCompanyAppraisal(data, props.origin.id)
        .then(() => {
          toast('Company Appraisal has been updated.', { type: 'info' })
          props.onClose(true)
        })
        .catch(() => setLoading(false))
    } else {
      addNewCompanyAppraisal(data)
        .then(() => {
          toast('A Company Appraisal has been created', { type: 'info' })
          props.onClose(true)
        })
        .catch(() => setLoading(false))
    }
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      title={`${props.origin ? `Update` : 'Add'} Company Appraisal Fields`}
      titleOkay={props.origin ? 'Update' : 'Add'}
      loading={loading}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]

          if (!input.visible) return null

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
