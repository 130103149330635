import {
  ArrowDownTrayIcon,
  ArrowRightIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ClockIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { type BaseFile, appApiUrl } from 'config'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getTaskByLoanNumber, getTaskByNo, getTasks, openS3Document } from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { Button, ButtonGroup, Checkbox, HorzScrollContainer, Input2 } from 'stories/components'
import { formatDate, formatTime, isBeforeOrEqualToday } from 'utils'

import type { Task } from './constants'
import { AddTaskModal } from './Modals/addTask'
import { AddTaskFromTemplate } from './Modals/addTaskFromTemplate'
import { MarkTaskModal } from './Modals/markTask'
import { StatusTaskModal } from './Modals/statusTask'

const taskTypeOptions = {
  all: 'All Tasks',
  open: 'Open Tasks',
  completed: 'Completed Tasks',
}

export const TasksForLoan = ({
  byteproFileName,
  loanNumber,
  taskNo: defaultTaskNo,
  assignedToList,
}: {
  byteproFileName: number
  loanNumber: number
  taskNo: number
  assignedToList: any
}) => {
  const navigate = useHistory()
  const [filters, setFilters] = useState<Record<string, any>>({
    taskType: 'open',
    query: '',
    assignedTo: '',
    orderBy: 'createdAt',
    orderDir: '-1',
  })
  const auth = useSelector((state: any) => state.auth)
  const [isLoading, setLoading] = useState(false)
  const [isShowAddTask, setShowAddTask] = useState(false)
  const [isShowMarkTask, setShowMarkTask] = useState(false)
  const [isShowAddFromTemplate, setShowAddFromTemplate] = useState(false)
  const [isShowStatusTask, setShowStatusTask] = useState(false)

  const [taskNo, setTaskNo] = useState<number>(0)
  const [tasks, setTasks] = useState<Record<string, any>[]>([])
  const [selectedTask, setSelectedTask] = useState<Record<string, any> | null>(null)
  const [openedTask, setOpenedTask] = useState<Record<string, any> | null>(null)
  const [taskTemplates, setTaskTemplates] = useState<Record<string, any>[]>([])
  const [rush, setRush] = useState(false)

  useEffect(() => {
    setLoading(true)
    getTaskByLoanNumber(loanNumber).then((res) => {
      setTasks(res.data)
      setRush(res.rush)
      setLoading(false)
    })

    getTasks().then(({ data }) => setTaskTemplates(data))
  }, [loanNumber])

  useEffect(() => {
    setTaskNo(defaultTaskNo)
    if (defaultTaskNo) getTaskByNo(defaultTaskNo).then((data) => setOpenedTask(data))
    else setOpenedTask(null)
  }, [defaultTaskNo])

  const downloadLink = useMemo(() => {
    return `${appApiUrl}/task/downloadByLoan/${loanNumber}/${taskNo}?token=${auth.token}`
  }, [auth.token, loanNumber, taskNo])

  const refetchData = async () => {
    setLoading(true)
    getTaskByLoanNumber(loanNumber).then((res) => {
      setTasks(res.data)
      setLoading(false)
    })
  }

  const onNavigateLoanOverview = () => {
    navigate.push(`/loan_process/overview/${loanNumber}`)
  }

  const onChangeFilter = (key: 'taskType' | 'query' | 'orderBy' | 'orderDir', value: string) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key == 'taskType') {
      setOpenedTask(null)
      setTaskNo(0)
    }
  }

  const onOpenTaskMarkModal = (task: Record<string, any>) => {
    setShowMarkTask(true)
    setSelectedTask(task)
  }

  const onAddTask = () => {
    setShowAddTask(true)
    setSelectedTask(null)
  }

  const onAddTaskFromTemplates = () => {
    setShowAddFromTemplate(true)
  }

  const onCloseModal = (isNeedRefetch: boolean, newStatus: string) => {
    if (isNeedRefetch && isShowMarkTask && selectedTask && newStatus) {
      const index = tasks.findIndex((item) => item.id === selectedTask.id)
      const newTasks = cloneDeep(tasks)
      newTasks[index].status = newStatus
      if (newStatus == 'complete') {
        newTasks[index].completor = { email: auth.profile.email }
        newTasks[index].completedDate = formatDate(new Date())
      }
      setTasks(newTasks)
    }
    setShowMarkTask(false)
    setShowAddTask(false)
    setShowStatusTask(false)
    setShowAddFromTemplate(false)
    setSelectedTask(null)
    if (isNeedRefetch) refetchData()
  }

  const onEdit = (task: Record<string, any>) => {
    setShowAddTask(true)
    setSelectedTask(task)
  }

  const assignedTo = (values: Array<{ value: string }>) => {
    return values.map(({ value }) => assignedToList[value] || value).join(', ')
  }

  const statusText = (status: string, dueDate: string) => {
    if (status == 'complete') return status
    if (!isBeforeOrEqualToday(dueDate)) return 'overdue'
    return status
  }

  const showHistory = (task: Record<string, any>) => {
    setShowStatusTask(true)
    setOpenedTask(task)
  }

  const filteredTasks = useMemo(() => {
    const { taskType, query, orderBy, orderDir } = filters
    return tasks
      .filter((task) => {
        if (taskType == 'all') return true
        if (taskType == 'completed' && task.status == 'complete') return true
        if (taskType == 'open') {
          if (taskNo && task.no == taskNo) return true
          if (!taskNo && task.status == 'active') return true
          return false
        }
      })
      .filter((task) => `${task.no} ${task.description}`.toLowerCase().includes(query.toLowerCase()))
      .sort((a, b) => (moment(a[orderBy]).diff(moment(b[orderBy])) > 0 ? orderDir : -orderDir))
  }, [tasks, filters])

  const renderHeader = (title: any, sortable: boolean = false, key: string, sortOrder: number = 1, index: number) => {
    if (!sortable)
      return (
        <th scope="col" className="py-3 px-2" key={`header-${index}`}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
    }

    return (
      <th scope="col" className={`py-3 px-2 ${index == 0 ? '' : ''}`} key={`header-${index}`}>
        <button className="flex uppercase bg-transparent font-bold" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-2" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
      </th>
    )
  }

  const sortableHeaders = [
    { title: 'No', key: 'no' },
    { title: 'Borrower', key: 'borrowerName', sortable: true },
    {
      title: (
        <span className="text-left">
          <div className="border-b w-fit mb-1 border-gray-300 whitespace-nowrap">Description</div>Note
        </span>
      ),
      key: 'description',
      sortable: true,
    },
    {
      title: (
        <span className="text-left">
          <div className="border-b w-fit mb-1 border-gray-300 whitespace-nowrap">Assigned to</div>Due
        </span>
      ),
      key: 'dueDate',
      sortable: true,
    },
    { title: 'Status', key: 'taskStatus' },
    { title: 'Created (by)', key: 'createdAt' },
    { title: 'Completed (by)', key: 'completedDate' },
  ]

  return (
    <div className="flex-auto shadow1 px-2 overflow-auto rounded mb-4">
      <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 border-b">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <div className="">
            <div className="self-baseline text-xl font-semibold whitespace-nowrap dark:text-white">
              Tasks - {loanNumber}
              {byteproFileName > 0 && `- ${byteproFileName}`}
              {isLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin ml-2" />}
            </div>
            {/* {loanOverview && (
              <div className="flex flex-wrap gap-4 text-left">
                <span>
                  - Borrower Name: <b>{borrowerName}</b>
                </span>
                <span>
                  - Property Address: <b>{loanOverview.loan.subjectPropertyAddress}</b>
                </span>
              </div>
            )} */}
          </div>
          <div className="flex flex-wrap gap-4 items-center">
            <div className="hidden w-full md:block md:w-auto" id="navbar-default">
              <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                <li>
                  <button
                    className="flex py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white items-center"
                    aria-current="page"
                    onClick={onNavigateLoanOverview}
                  >
                    <span className="text-shade-blue hover-shadow1 shadow flex items-center px-2 py-1 rounded">
                      <ArrowRightIcon className="w-4 h-4 mr-1" />
                      Loan Overview
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="ml-3">
              <a
                className="block my-2 mr-5 px-2 py-1 rounded hover-shadow1 items-center"
                href={downloadLink}
                target="_blank"
              >
                <ArrowDownTrayIcon className="h-5 w-5 text-shade-blue" />
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div className="md:px-2 py-2.5">
        <p className="text-xl px-2 font-bold mb-2">Tasks</p>
        <div className="flex flex-wrap gap-2 items-center justify-between">
          <div className="h-fit mb-3">
            <ButtonGroup
              title={taskTypeOptions}
              value={filters.taskType}
              onChange={(value) => onChangeFilter('taskType', value)}
            />
          </div>

          <div className="flex flex-wrap gap-3 mb-3 items-center">
            <div className="w-64 mb-3">
              <Input2
                type="search"
                title="Search Tasks"
                hasIcon
                icon={svgSearch}
                value={filters.query}
                onChange={(value) => onChangeFilter('query', value)}
              />
            </div>
            <Button onClick={onAddTask} className="!py-2">
              Ad-Hoc Task
            </Button>
            <Button onClick={onAddTaskFromTemplates} className="!py-2">
              Add Tasks from Templates
            </Button>
          </div>
        </div>

        <div className="shadow-md sm:rounded-lg mb-4">
          <HorzScrollContainer size={filteredTasks.length}>
            <>
              <LayoutLoading show={isLoading} />

              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 overflow-auto">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {sortableHeaders.map(({ title, key }, index) =>
                      renderHeader(
                        title,
                        ['dueDate', 'createdAt'].includes(key),
                        key,
                        filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                        index,
                      ),
                    )}
                    <th scope="col" className="px-2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {filteredTasks.map((task: Record<string, any>, index: number) => {
                    const status = statusText(task.status, task.dueDate)
                    const taskCompleted = task.status == 'complete'
                    return (
                      <tr
                        className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
                          index % 2 && 'bg-slate-50'
                        }`}
                        key={task.no}
                      >
                        <td className="px-2">
                          <span>{task.no}</span>
                        </td>
                        <td className="px-2">{task.borrowerName}</td>
                        <td className="px-2 py-3">
                          <span>
                            <div className="border-b w-full mb-2 font-variation-settings-600">
                              {task.description}
                              {rush && [1011, 1012, 1013, 1014].includes(task.taskTemplateNo) && (
                                <span className="px-2 rounded bg-red-100 border-red-400 text-red-700 ml-2">Rush</span>
                              )}
                            </div>
                            <span className="dangerouslySetInnerHTML flex">
                              <div dangerouslySetInnerHTML={{ __html: task.note }}></div>
                            </span>
                            {task.files.map((file: BaseFile, index: number) => (
                              <p
                                className="text-[12px] hover:underline text-shade-blue italic font-semibold cursor-pointer"
                                onClick={() => openS3Document(file.fileKey)}
                                key={`${task.no}-${index}`}
                              >
                                {file.name}
                              </p>
                            ))}
                          </span>
                        </td>
                        <td className="w-32 px-2">
                          <span>
                            <div className="border-b w-full mb-2">{assignedTo(task.assignedTo)}</div>
                            {formatDate(task.dueDate)}
                          </span>
                        </td>
                        <td className="px-2 py-2 statusColor">
                          <span>
                            <div className={`capitalize text-center p-1 mb-1 w-[80px] text-[13px] rounded ${status}`}>
                              {status}
                            </div>
                          </span>
                        </td>
                        <td className="px-2 py-2">
                          <p>{formatTime(task.createdAt)}</p>
                          <p className="text-[13px] mt-1">{task.creator.email}</p>
                        </td>
                        <td className="px-2">
                          <div className="flex">
                            <div className="w-6">
                              <Checkbox
                                id={`check-${task.id}`}
                                checked={taskCompleted}
                                onClick={() => onOpenTaskMarkModal(task)}
                                title={taskCompleted ? '' : 'Complete'}
                              />
                            </div>
                            {taskCompleted && (
                              <>
                                <div>
                                  <p className="block">{status == 'complete' ? task.completedDate : ''}</p>
                                  {task.completor && <p>{task.completor.email}</p>}
                                </div>
                                <div
                                  className="flex ml-1 p-1 hover-shadow1 rounded cursor-pointer h-fit"
                                  onClick={() => showHistory(task)}
                                >
                                  <ClockIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                        <td className="px-2">
                          <span className="flex flex-wrap justify-center">
                            <span
                              className="text-shade-blue p-1 hover-shadow1 rounded cursor-pointer"
                              onClick={() => onEdit(task)}
                            >
                              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                            </span>
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </>
          </HorzScrollContainer>
        </div>

        {isShowMarkTask && selectedTask && <MarkTaskModal onClose={onCloseModal} task={selectedTask} />}

        {isShowAddTask && (
          <AddTaskModal
            onClose={onCloseModal}
            projectId={0}
            loanNumber={loanNumber}
            byteproFileName={byteproFileName}
            assignedToList={assignedToList}
            task={selectedTask as Task}
          />
        )}

        {isShowAddFromTemplate && (
          <AddTaskFromTemplate projectId={0} loanNumber={loanNumber} templates={taskTemplates} onClose={onCloseModal} />
        )}

        {isShowStatusTask && openedTask && (
          <StatusTaskModal task={openedTask} onClose={onCloseModal} needOnlyHistory={true} />
        )}
      </div>
    </div>
  )
}
