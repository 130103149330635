import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { LoanProcessRushPaymentRequestTypeTitles } from 'pages/LoanSubmission/constants'
import { RushSubmitModal } from 'pages/LoanSubmission/RushSubmitModal'
import { useEffect, useState } from 'react'
import { getInvoices, IStripeIntent, PaymentOrderType, PaymentOrderTypeTitles } from 'services'
import { Button } from 'stories/components'
import { formatTime, renderHeader, thousandSeperator } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { StripeStatusLabels } from '../OrderAppraisals'

export function Invoices() {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<IStripeIntent[]>([])

  useEffect(() => {
    setLoanNumber()
    refetch()
  }, [])

  const refetch = () => {
    setLoading(true)
    getInvoices()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  const onAdd = () => {
    setIsOpen(true)
  }

  const onCloseModal = () => {
    setIsOpen(false)
    refetch()
  }

  const sortableHeaders = [
    { title: 'Intent ID', key: 'intentId' },
    { title: 'Customer Email', key: 'email' },
    { title: 'Type', key: 'type' },
    { title: 'Amount', key: 'amount' },
    { title: 'Status', key: 'status' },
    { title: 'Detail', key: 'detail' },
    { title: 'Created At', key: 'createdAt' },
  ]

  const renderOrderType = (item: IStripeIntent) => {
    if (item.orderType == PaymentOrderType.LoanSubmissionRush)
      return LoanProcessRushPaymentRequestTypeTitles[item.data.requestType] || item.data.feeTitle
    return PaymentOrderTypeTitles[item.orderType]
  }

  const renderDetails = (item: IStripeIntent) => {
    const orderType = renderOrderType(item) // PaymentOrderTypeTitles[item.orderType]

    const refIdTitles: Record<PaymentOrderType, string> = {
      [PaymentOrderType.FeasibilitySign]: 'Appraisal Order Id',
      [PaymentOrderType.LoanExtension]: 'Loan Extension Id',
      [PaymentOrderType.LoanSubmissionRush]: '',
      [PaymentOrderType.PullCreditReport]: 'User',
    }
    const refTitle = refIdTitles[item.orderType]

    return (
      <div>
        <p>Order Type: {orderType}</p>
        {!!refTitle && (
          <p>
            {refTitle}: {item.refId}
          </p>
        )}
        {item.data.requestedBy ? <p>By: {item.data.requestedBy}</p> : null}
      </div>
    )
  }

  return (
    <div className={`invoices-container px-2 py-6`}>
      <Overview title="Invoices" />
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white shadow1 rounded mb-4 p-4`}>
          <LayoutLoading show={loading} />
          <div className="flex gap-4 my-2 justify-between items-center">
            <div className="ml-2 text-[18px] font-variation-settings-600">Invoices</div>
            <div className="">
              <Button onClick={onAdd} className="px-[36px]">
                Add
              </Button>
            </div>
          </div>
          <div className="parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  {sortableHeaders.map(({ title, key }, index) =>
                    renderHeader({
                      title,
                      sortable: false,
                      key,
                      sortOrder: 0,
                      index,
                      onSort: () => {},
                    }),
                  )}
                </tr>
              </thead>
              <tbody className="text-[14.5px] text-gray-900">
                {data.map((item, index) => {
                  const status = StripeStatusLabels[item.status] || []
                  return (
                    <tr key={index} className={`border-b ${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-6 py-3">{index + 1}</td>
                      <td className="px-2">
                        <a
                          className="font-bold text-shade-blue hover:underline cursor-pointer"
                          href={`https://dashboard.stripe.com/payments/${item.intentId}`}
                          target="_blank"
                        >
                          <span>{item.intentId}</span>
                        </a>
                      </td>
                      <td className="px-2">{item.email}</td>
                      <td className="px-2">{renderOrderType(item)}</td>
                      <td className="px-2">${thousandSeperator(item.amount)}</td>
                      <td className={`px-2 text-${status[0]}-500`}>{status[1] || item.status}</td>
                      <td className="px-2">{renderDetails(item)}</td>
                      <td className="px-2">{formatTime(item.createdAt)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isOpen && <RushSubmitModal showDescription={false} onClose={onCloseModal} />}
    </div>
  )
}
