import { ClockIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import type { Color } from 'stories/types'

interface SelectProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean

  readOnly?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Id of Input
   */
  id: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Size of Input
   */
  size?: number
  /**
   * Options of select
   */
  options?: Array<string> | Record<string, string | null> // (Title => key)
  /**
   * Custom class name
   */
  className?: string
  borderClassName?: string
  /**
   * Required
   */
  required?: boolean
  /**
   * Has default option - 'SELECT'
   */
  hasDefaultOption?: boolean
  /**
   * Default option text
   */
  defaultOptionText?: string
  /**
   * Optional history handler
   */
  history?: boolean
  /**
   * Sort
   */
  sort?: boolean
  /**
   * Optional click handler
   */
  onChange?: (value: any) => void
  onBlur?: () => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const Select = ({
  color = 'sky',
  disabled = false,
  readOnly = false,
  id = '',
  title = '',
  name = '',
  value = '',
  error = '',
  options = [],
  className = '',
  borderClassName = '',
  size = 4,
  required = false,
  hasDefaultOption = false,
  defaultOptionText = '- Select -',
  history = false,
  sort = false,
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
  ...props
}: SelectProps) => {
  let classNames = `block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer`
  if (size === 3) {
    classNames = `block focus:ring-0 text-[13px] text-black bg-white py-0 pl-1 pr-4 bg-[center_right_0rem] w-full border-gray-300 ${borderClassName}`
  }

  const renderOptions = useMemo(() => {
    let rlt: any = []
    if (Array.isArray(options)) {
      let _options = options
      if (sort) {
        _options = options.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      }
      _options.map((option) => {
        rlt.push(
          <option key={`${title}-${option}`} value={option}>
            {option}
          </option>,
        )
      })
    }

    if (!Array.isArray(options)) {
      let _options: any = []
      Object.keys(options).map((key) => {
        if (options[key] === null) return
        _options.push({
          key: key,
          value: options[key],
        })
      })
      if (sort) {
        _options = _options.sort(function (a: any, b: any) {
          return a.value.toLowerCase().localeCompare(b.value.toLowerCase())
        })
      }
      _options.map((item: any) => {
        rlt.push(
          <option key={`${title}-${item.key}`} value={item.key}>
            {item.value}
          </option>,
        )
      })
    }
    return rlt
  }, [options, sort])

  return (
    <div className="input-container mb-4">
      <div
        className={`relative z-0 w-full group ${className} ${disabled ? 'bg-gray-100' : ''} ${
          readOnly ? 'cursor-not-allowed' : ''
        }`}
      >
        {title.length > 0 && (
          <label
            htmlFor={id}
            className={`absolute text-xs text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 top-3 origin-[0] peer-focus:left-0 peer-focus:text-${color}-700 peer-focus:dark:text-${color}-700 peer-placeholder-shown:text-sm peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:text-xs peer-focus:-translate-y-6 peer-placeholder-shown:leading-3 ml-2 px-1 rounded bg-white z-10 peer-placeholder-shown:-z-10 peer-focus:z-10 left-0`}
          >
            {title}
            {required && '*'}
          </label>
        )}
        <div className="flex justify-between items-center gap-2">
          <select
            id={id}
            className={classNames}
            name={name}
            value={value}
            disabled={disabled || readOnly}
            onChange={(event) => onChange(event.target.value)}
            onBlur={onBlur}
            required={required}
            {...props}
          >
            {hasDefaultOption && <option value="">{defaultOptionText}</option>}
            {/* {Array.isArray(options) &&
              options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}

            {!Array.isArray(options) &&
              Object.keys(options).map((key) => (
                <option key={key} value={key}>
                  {options[key]}
                </option>
              ))} */}
            {renderOptions}
          </select>
          {!disabled && !readOnly && history && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
        </div>
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1">{error}</p>}
    </div>
  )
}
