import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { CreateUser } from 'components/Modals'
import { accountTypes, assignedToAccountTypes } from 'components/Modals/CreateUser/config'
import { AccountType, companyName } from 'config'
import { UserTasks } from 'pages/Tasks'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getAdminEmails, getUserById, requestUserRoles } from 'services'
import { ButtonGroup } from 'stories/components'
import { useTitle } from 'utils'

import { UserInfo } from '../user.type'
import { UserNotesSection } from '../UserNotesSection'
import { BrokerVerificationFiles } from './BrokerVerificationFiles'
import { RecentActivities } from './RecentActivities'

const tabs: Record<string, string> = {
  tasks: 'Active Tasks',
  // calendly: 'Calendly',
  files: 'Files',
  notes: 'Notes',
}

export const AccountDetails = () => {
  useTitle(`Account Details - ${companyName}`)

  const navigate = useHistory()
  const urlParams: any = useParams()
  const { userId = 0 } = urlParams

  const auth = useSelector((state: any) => state.auth)
  const [isLoading, setLoading] = useState(true)
  const [user, setUser] = useState<UserInfo>()
  const [assignedToList, setAssignedToList] = useState<Record<string, string>>({})
  const [executives, setExecutives] = useState({})
  const [lastUpdatedAt, setLastUpdatedAt] = useState(0)
  const [currentTab, setCurrentTab] = useState('tasks')

  useEffect(() => {
    if (![AccountType.ADMIN].includes(auth.profile.accountType)) {
      updateExecutives()
      return
    }

    getAdminEmails()
      .then((emails) => {
        const newOptions: Record<string, string> = {}
        assignedToAccountTypes.forEach((key) => (newOptions[key] = accountTypes[key]))
        emails.forEach((email) => (newOptions[email] = email))
        setAssignedToList(newOptions)
      })
      .finally(() => updateExecutives())
  }, [])

  const updateExecutives = () => {
    requestUserRoles(AccountType.ACCOUNT_EXECUTIVE, 0)
      .then((roles) => {
        setExecutives(roles)
      })
      .then(() => {
        getUserById(userId)
          .then((user) => {
            setUser(user)
            setLastUpdatedAt(Date.now())
          })
          .finally(() => {
            setLoading(false)
          })
      })
  }

  const renderTabContent = () => {
    if (!user) return null

    switch (currentTab) {
      case 'tasks':
        return <UserTasks userId={userId} />

      // case 'calendly':
      //   return <></>

      case 'files':
        return <BrokerVerificationFiles userId={userId} />

      case 'notes':
        return <UserNotesSection user={user!} visibleClose={false} onClose={() => {}} />
    }
    return null
  }

  return (
    <div className="registration-container py-6 px-2">
      <div className="max-w-screen-2xl m-auto bg-white sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="grid lg:grid-cols-4 gap-8">
          <div className="col-span-3 shadow1 rounded p-3 md:p-7">
            <div className="text-shade-blue flex items-center mb-2 font-bold">
              <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
              <span className="ml-1 cursor-pointer hover:underline" onClick={() => navigate.push('/registrations')}>
                Back to Manage Accounts
              </span>
            </div>

            <p className="text-lg mb-4 font-semibold">Account Details</p>

            <CreateUser
              isOpen={!!lastUpdatedAt}
              defaultData={user}
              executives={executives}
              assignedToList={assignedToList}
              lastUpdatedAt={lastUpdatedAt}
              isModal={false}
              onAfterSubmit={() => {}}
              onClose={() => {}}
            />
          </div>

          <div className="shadow1 rounded p-3 md:p-7 max-h-[100vh] overflow-y-auto">
            <p className="text-lg mb-4 font-semibold">Recent Activities</p>
            <RecentActivities userId={userId} />
          </div>

          <div className="col-span-full shadow1 rounded p-3 md:p-7">
            <ButtonGroup title={tabs} value={currentTab} onChange={(v) => setCurrentTab(v)} />

            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  )
}
