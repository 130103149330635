import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getLoanDataVerify, loanSubmitLexisOrder, openS3Document } from 'services'
import { Button } from 'stories/components'
import { formatDate, formatTime } from 'utils'

export const Lexis = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [borrowers, setBorrowers] = useState([])
  const [data, setData] = useState<Array<any>>([])

  useEffect(() => {
    initData()
  }, [])

  const isSubmittable = (type: string) => {
    return data.filter((v) => v.status == 'Pending' && v.data?.type == type).length > 0
  }

  const initData = async () => {
    setIsLoading(true)
    const res = await getLoanDataVerify('lexis')
    if (res.success) {
      setBorrowers(res.borrowers)
      setData(res.data)
    }
    setIsLoading(false)
  }

  const onSubmit = async (item: any) => {
    setIsLoading(true)
    const res: any = await loanSubmitLexisOrder(item)
    if (res.success) {
      let temp = cloneDeep(data)
      temp = [res.data, ...temp]
      setData(temp)
    }
    setIsLoading(false)
  }

  return (
    <div className="SSA-container relative">
      <LayoutLoading show={isLoading} />
      <div className="">
        <div className="overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  SSN
                </th>
                <th scope="col" className="px-6 py-3">
                  Date of Birth
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {borrowers.map((item: any, index: number) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                    <td className="px-6 py-3 capitalize">{item.type}</td>
                    <td className="px-6 py-3">{[item.firstName, item.lastName].join(' ')}</td>
                    <td className="px-6 py-3">{item.ssn}</td>
                    <td className="px-6 py-3">{formatDate(item.dob)}</td>
                    <td className="px-6 py-3">
                      <Button
                        color="gray"
                        className="mb-[0px]"
                        disabled={isSubmittable(item.type)}
                        onClick={() => onSubmit(item)}
                      >
                        Submit
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-6 mb-2 text-[17px] border-b font-variation-settings-600">Order Processing</div>
        <div className="overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 w-[265px]">
                  Submit Data
                </th>
                <th scope="col" className="px-6 py-3">
                  Order Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Requested Date/By
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                    <td className="px-6 py-3 capitalize">{item.data.type}</td>
                    <td className="px-6 py-3">
                      <table>
                        <tbody className="text-[14px]">
                          <tr>
                            <td className="border px-2">Name:</td>
                            <td className="border px-2 italic">
                              {[item.data.firstName, item.data.lastName].join(' ')}
                            </td>
                          </tr>
                          <tr>
                            <td className="border px-2">SSN:</td>
                            <td className="border px-2 italic">{item.data.ssn}</td>
                          </tr>
                          <tr>
                            <td className="border px-2">Date Of Birth:</td>
                            <td className="border px-2 italic">{formatDate(item.data.dob)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="px-6 py-3">
                      <table className="w-full">
                        <tbody className="text-[14px]">
                          <tr className="statusColor">
                            <td className="border px-2 w-28">Status:</td>
                            <td className={`border px-2 italic ${item.status?.split('/')[0]}`}>
                              <span className="flex items-center gap-1">
                                <span>{item.status}</span>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="border px-2 w-28">Order ID:</td>
                            <td className="border px-2 italic">{item.orderID}</td>
                          </tr>
                          <tr>
                            <td className="border px-2 w-28">Messages:</td>
                            <td className="border px-2 italic">
                              {item.message}
                              <table>
                                <tbody>
                                  {item.messages.map((it: any, index: number) => {
                                    return (
                                      <tr className="" key={index}>
                                        <td className="border px-2">{it.time}</td>
                                        <td className="border px-2">{it.message}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          {!!item.successFileKey && (
                            <tr>
                              <td className="border px-2 py-1" colSpan={2}>
                                <span
                                  className="flex gap-1 items-center text-[13px] text-shade-blue cursor-pointer hover:underline font-bold italic"
                                  onClick={() => openS3Document(item.successFileKey)}
                                >
                                  <span>View Final PDF</span>
                                  <span>
                                    <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                    <td className="px-6 py-3">
                      {item.by}
                      <br />
                      {formatTime(item.time)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
