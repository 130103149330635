import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'
import { setLoanData } from 'actions'
import cloneDeep from 'clone-deep'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLoanFields } from 'services'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { canEditLoanApplication, getItemsFromFullAddress, InputConvert, InputValidate, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { AdditionalPropertyInformation } from '../AdditionalPropertyInformation'
import { getFailedAdditionalPropertyInfo } from '../AdditionalPropertyInformation/logics'
import { defaultInputs } from './constants'
import { initFields, propertyInfoValidate } from './logic'

interface IProps {
  setLoading: (value: boolean) => void
}

export function PropertyInformation(props: IProps) {
  const [inputs, setInputs] = useState(initFields(defaultInputs()))
  const [fieldValueChanged, setFieldValueChanged] = useState(false)

  const dispatch = useDispatch()
  const { loan, loanDetail } = useSelector((state: any) => {
    return {
      loan: state.loan,
      loanDetail: state.loanDetail,
    }
  })

  const { hasPermission } = usePermissions()

  const init = async () => {
    const nInputs = propertyInfoValidate()
    const propertyAddressObj: any = await getItemsFromFullAddress(loan.subjectPropertyAddress)
    if (propertyAddressObj.state === 'NY') {
      nInputs['nyBlock'].visible = true
      nInputs['nyLot'].visible = true
    }
    if (hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE')) {
      nInputs.cdaValue.visible = true
      nInputs.marketRentFromAppraisal.visible = true
    }
    setInputs(nInputs)
  }

  useEffect(() => {
    init()
  }, [])

  const failedAdditionalPropertyInfo = getFailedAdditionalPropertyInfo()

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    setInputs(newInputs)
    setFieldValueChanged(true)

    dispatch(setLoanData({ key: key, data: value }))
  }

  const onBlur = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    if (inputs[key].error.length > 0) return
    props.setLoading(true)
    const reqBody = {
      [key]: loan[key],
    }
    await updateLoanFields(reqBody)
    props.setLoading(false)
    propertyInfoValidate(true, true)
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'Loan',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  const canEdit = canEditLoanApplication()

  const monthlyPayment = useMemo(() => {
    const pitis = combinedMonthlyExpense(loan, loanDetail.rateData)
    return pitis['sum']
  }, [
    loan.proposedMonthlyInsurance,
    loan.proposedMonthlyTaxes,
    loan.proposedMonthlyHoaDues,
    loan.floodInsurance,
    loan.schoolTax,
    loan.otherTax,
  ])

  const renderValidateMessage = () => {
    if (!failedAdditionalPropertyInfo.length) return null

    return (
      <div className="text-left bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]">
        <div className="mb-2">
          This loan has Additional Subject Property Addresses and below addresses are required to validate detailed
          information.
          <br />
          <p className="flex gap-1">
            Please{' '}
            <span
              className="flex gap-1 font-bold cursor-pointer"
              onClick={() => window.scrollTo({ top: window.innerHeight })}
            >
              Scroll down <ArrowDownCircleIcon className="w-4 h-4" />
            </span>{' '}
            and complete the information.
          </p>
        </div>
        {failedAdditionalPropertyInfo.map((item, index) => (
          <p key={index} className="font-semibold">
            - {item.propertyAddress}
          </p>
        ))}
      </div>
    )
  }

  return (
    <div>
      {renderValidateMessage()}
      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-5">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          if (input.visible === false) return null
          input.history = true
          input.value = loan[key]
          if (input.disabled) {
          } else {
            input.disabled = !canEdit
          }
          let rlt: any = [
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} onBlur={onBlur} />
            </div>,
          ]
          if (key === 'otherTax') {
            rlt.push(
              <div className={`input md:col-span-${input.span || 1}`} key={`monthlyPayment-${index}`}>
                <RenderInput
                  input={{
                    title: 'Proposed Monthly Property Payment',
                    inputType: 'text',
                    type: 'thousandSep',
                    prefix: '$',
                    value: monthlyPayment,
                    readOnly: true,
                  }}
                  Key={key}
                  onChange={() => {}}
                />
              </div>,
            )
          }
          return rlt
        })}
      </div>
      <AdditionalPropertyInformation setLoading={props.setLoading} />
    </div>
  )
}
