import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Modal, Toggle } from 'stories/components'

export const ConfirmSignDialog = (props: any) => {
  const [isReviewed, setReviewed] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onDownloadPdf = () => {
    props.onDownloadPdf()
  }

  const onConfirmSign = async () => {
    setLoading(true)
    try {
      await props.onConfirm()
    } catch (e) {
      console.log(e)
      setLoading(false)
      return
    }

    toast('Signed successfully. You can download the signed document', { type: 'success' })

    setLoading(false)
    props.onClose()
  }

  return (
    <Modal
      isOpen
      title="Confirm and Sign"
      titleOkay="Confirm and Sign"
      disabled={!isReviewed}
      loading={isLoading}
      onClose={props.onClose}
      onOk={onConfirmSign}
    >
      <div className="text-gray-600 mv-2 text-md overflow-hidden w-[750px]">
        <p className="font-bold">Once this document is signed you will not the functionality to edit this document.</p>

        <div className="text-sm bg-gray-100 px-2 pb-4 rounded">
          <Button link onClick={onDownloadPdf}>
            <div className="flex items-center underline text-gray-900 hover:text-shade-blue">
              - View Unsigned Application PDF <ArrowDownTrayIcon className="w-4 h-4" />
            </div>
          </Button>
          <Toggle
            id="reviewed"
            title="I have reviewed my application"
            onChange={(value) => setReviewed(value)}
            value={isReviewed}
          />
        </div>
      </div>
    </Modal>
  )
}
