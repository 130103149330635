import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { type InputType, loanOptionsConstants, MaritalStatusTypes } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { checkTemporaryUserExists, getMyBorrowerProfile } from 'services'
import { InputConvert } from 'utils'

import { renderActions } from '../components'
import type { IBorrowerLoanRequestStepProps } from './interface'
import { renderInputs, validateForm } from './util'

const defaultInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      inputType: 'text',
      type: 'text',
      title: 'Legal First',
      required: true,
    },
    lastName: {
      inputType: 'text',
      type: 'text',
      title: 'Legal Last',
      required: true,
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      required: true,
      disabled: true,
      tooltip: 'In order to update your email address please update your profile',
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone Number',
      required: true,
    },
    maritalStatus: {
      inputType: 'Select',
      title: 'Marital status',
      options: MaritalStatusTypes,
      hasDefaultOption: true,
      required: true,
    },
    presentAddress: {
      inputType: 'map',
      title: 'Current Address',
      required: true,
    },
    residency: {
      inputType: 'Select',
      title: 'Citizenship Status',
      options: loanOptionsConstants.citizenship,
      hasDefaultOption: true,
      required: true,
    },
  }
}

export const PersonalInformation = ({
  values = {},
  allValues = {},
  onSubmit: _onSubmit,
  onBack,
}: IBorrowerLoanRequestStepProps) => {
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const { profile, isAuthenticated } = useSelector((state: any) => state.auth)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (isAuthenticated && (!values || Object.keys(values).length == 0)) {
      setLoading(true)
      getMyBorrowerProfile()
        .then((data: Record<string, any>) => setValues(data))
        .finally(() => setLoading(false))
      return
    }

    setValues(values)
  }, [values])

  const setValues = (data: Record<string, any>) => {
    const newInputs = cloneDeep(defaultInputs())
    Object.keys(newInputs).map((key) => (newInputs[key].value = (data as any)[key] || ''))
    if (!isAuthenticated) newInputs.email.disabled = false
    setInputs(newInputs)
  }

  const onChange = (key: string, value: string) => {
    if (inputs[key].value == value) return

    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const { hasError, data } = validateForm({
      inputs,
      setInputs,
      allValues,
    })
    if (hasError) return

    if (!isAuthenticated) {
      const { success } = await checkTemporaryUserExists(data.email)
      if (success) {
        let newInputs = cloneDeep(inputs)
        newInputs.email.error = 'This email is already exists. Please login and try again.'
        setInputs(newInputs)
        return
      }
    }
    _onSubmit(data)
  }

  return (
    <div>
      <LayoutLoading show={isLoading} />
      <p className="mb-6">
        Awesome {profile.fullName}, we’re going to need some information about you first before we help you meet your
        real estate investing goals.
      </p>

      {renderInputs({ inputs, allValues, onChange })}

      {renderActions(onBack, onSubmit)}
    </div>
  )
}
