import { useEffect, useState } from 'react'
import { getReviewFormCategories } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, ButtonGroup } from 'stories/components'

import { ReviewFormCategories } from './ReviewFormCategories'
import { ReviewFormDetails } from './ReviewFormDetails'
import { ReviewFormTemplate, ReviewFormTemplates } from './ReviewFormTemplates'

export interface ReviewFormCategory {
  id: string
  value: string | null
}

export const ReviewFormItems = () => {
  const [isLoading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentTab, setCurrentTab] = useState('Templates')
  const [categories, setCategories] = useState<ReviewFormCategory[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<ReviewFormTemplate | null>(null)

  useEffect(() => {
    setLoading(true)
    getReviewFormCategories().then((values: ReviewFormCategory[]) => {
      setCategories(values)
      setLoading(false)
    })
  }, [])

  const onAdd = () => {
    setIsEditing(true)
    setSelectedTemplate(null)
  }

  const onEdit = (template: ReviewFormTemplate) => {
    setIsEditing(true)
    setSelectedTemplate(template)
  }

  if (isEditing)
    return <ReviewFormDetails categories={categories} defaults={selectedTemplate} onBack={() => setIsEditing(false)} />

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Appraisal Review Conditions
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="flex justify-between mb-6">
        <ButtonGroup title={['Templates', 'Categories']} value={currentTab} onChange={(v) => setCurrentTab(v)} />
        <div className="w-32">
          {currentTab == 'Templates' && (
            <Button full onClick={onAdd}>
              Add
            </Button>
          )}
        </div>
      </div>

      {currentTab === 'Categories' && (
        <ReviewFormCategories
          setLoading={setLoading}
          isLoading={isLoading}
          categories={categories}
          setCategories={setCategories}
        />
      )}
      {currentTab === 'Templates' && (
        <ReviewFormTemplates setLoading={setLoading} isLoading={isLoading} categories={categories} onEdit={onEdit} />
      )}
    </div>
  )
}
