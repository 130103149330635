import type { FormTableHeader } from 'stories/components'
import { formatDate, openAuditLog, renderTableHistoryValue, thousandSeperator } from 'utils'

import type { IAccountingCategory } from '../AccountingCategories'
import type { IAccountingAccount } from '../Accounts/types'
import type { IAccounting } from './types'

const historyHeaders: Array<FormTableHeader> = [
  {
    key: 'date',
    title: 'Date',
  },
  {
    key: 'description',
    title: 'Description',
  },
  {
    key: 'amount',
    title: 'Amount',
  },
  {
    key: 'category',
    title: 'Category',
  },
  {
    key: 'accountId',
    title: 'Account',
  },
  {
    key: 'loanNumber',
    title: 'Loan Number',
  },
  {
    key: 'files',
    title: 'Files',
  },
  {
    key: 'deleted',
    title: 'Deleted',
  },
  {
    key: 'actived',
    title: 'Approved',
  },
]

export const onAccountingHistory = (
  item: IAccounting,
  category: IAccountingCategory[],
  accounts: IAccountingAccount[],
) => {
  const categoryOptions: Record<number, string> = {}
  category.forEach((v) => {
    categoryOptions[v.id] = v.value
  })
  const accountOptions: Record<number, string> = {}
  accounts.forEach((v) => {
    accountOptions[v.id] = `${v.name} - ${v.number}`
  })

  const options = {
    table: 'AccountingRecords',
    field: 'History',
    keys: {
      field: 'History',
      id: item.id,
    },
    renderValue: (data: IAccounting) =>
      renderTableHistoryValue(
        [
          {
            ...data,
            amount: thousandSeperator(data.amount),
            category: categoryOptions[data.category],
            accountId: accountOptions[data.accountId],
            files: (
              <div>
                {(data.files || []).map((v) => (
                  <p>- {v.name}</p>
                ))}
              </div>
            ),
            date: formatDate(data.date),
            actived: data.actived ? 'Approved' : '',
          },
        ],
        historyHeaders,
      ),
  }
  openAuditLog(options)
}

export const getChildrenCategories = (category: IAccountingCategory[], baseId: string | number) => {
  const properCategories: Record<number, string> = {}
  const categories = category.filter((v) => v.baseId == Number(baseId) && v.parentId == 0)
  categories.forEach((v) => {
    properCategories[v.id] = v.value
  })
  return properCategories
}

export const getChildrenSubCategories = (category: IAccountingCategory[], parentId: string | number) => {
  const properCategories: Record<number, string> = {}
  const categories = category.filter((v) => v.parentId == Number(parentId))
  categories.forEach((v) => {
    properCategories[v.id] = v.value
  })
  return properCategories
}
