import type { InputType } from 'config'

export const InvestorServicerType: Record<number, string> = {
  1: 'Investor',
  2: 'Servicer',
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      title: 'Type',
      inputType: 'select',
      options: InvestorServicerType,
      required: true,
      hasDefaultOption: true,
    },
    isDisabled: {
      inputType: 'CheckBox',
      title: 'Disabled',
      className: 'mt-3',
      value: false,
    },

    generalInfoGroup: {
      inputType: 'Group',
      title: 'General Info',
      span: 4,
    },
    generalInfoSection: {
      inputType: 'Section',
      title: 'General Info',
      span: 4,
    },
    investorCode: {
      inputType: 'text',
      title: 'Investor Code',
      required: true,
    },
    fullName: {
      inputType: 'text',
      title: 'Full Name',
      required: true,
    },
    url: {
      inputType: 'text',
      title: 'URL',
    },
    lockDesk: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Lock Desk',
    },
    ppeInvestorCode: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'PPE CODE (OB)',
    },
    docPrepCode: {
      inputType: 'text',
      title: 'Doc Prep Code',
    },
    mersOrgId: {
      inputType: 'text',
      title: 'MERS Org ID',
    },
    ServicerOrgId: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Servicer Org ID',
    },
    ServicingOptions: {
      inputType: 'select',
      options: {
        1: 'Servicing Released',
        2: 'Servicing Retained',
      },
      title: 'Servicing Options',
      hasDefaultOption: true,
    },
    sellerNo: {
      inputType: 'text',
      title: 'Seller No',
    },
    hmdaType: {
      inputType: 'select',
      title: 'HMDA Type of Purchaser',
      options: {
        1: 'Fannie Mae',
        2: 'Ginnie Mae',
        3: 'Freddie Mac',
        4: 'Farmer Mac',
        5: 'Private Securitization',
        6: 'Commercial Bank Or Saving Bank Or Savings Association',
        7: 'Life Insurance Company, Credit Union, Mortgage Bank, or Finance Company (Pre-2018 Only)',
        8: 'Affiliate Institution',
        9: 'Other Type Of Purchaser',
        71: 'Creidt Union',
        72: 'Life Insurance Company',
      },
      hasDefaultOption: true,
    },
    nmlsInvestorType: {
      inputType: 'select',
      title: 'NMLS Investor Type',
      options: {
        1: 'Production Sold to Secondary Market Agencies (Fannie, Freddie, Ginnie)',
        2: 'Production Sold to Others(Non-Affiliate)',
        3: 'Production Sold to Others(Affiliate)',
        4: 'Production Kept in Portfolio/Held for Investment',
        5: 'Production Sold through Non-Agency Securitizations with Sale Treatment',
        6: 'Production Sold through Non-Agency Securitizations without Sale Treatment',
      },
      hasDefaultOption: true,
    },

    shippingSection: {
      inputType: 'Section',
      title: 'Shipping Address Info',
      span: 4,
    },
    shippingName: {
      inputType: 'text',
      title: 'Name',
    },
    shippingContact: {
      inputType: 'text',
      title: 'Contact',
    },
    shippingPhone: {
      inputType: 'text',
      title: 'Phone',
      type: 'phone',
    },
    shippingAddress: {
      inputType: 'map',
      title: 'Address',
      span: 3,
    },

    lossPayeeSection: {
      inputType: 'Section',
      title: 'Loss Payee Info',
      span: 4,
    },
    lossPayeeName: {
      inputType: 'text',
      title: 'Name',
    },
    lossPayeeContact: {
      inputType: 'text',
      title: 'Contact',
    },
    lossPayeePhone: {
      inputType: 'text',
      title: 'Phone',
      type: 'phone',
    },
    lossPayeeAddress: {
      inputType: 'map',
      title: 'Address',
      span: 3,
    },

    accountRepsGroup: {
      inputType: 'Group',
      title: 'Account Reps',
      span: 4,
    },
    accountRepSection1: {
      inputType: 'Section',
      title: 'Account Rep 1',
      span: 4,
    },
    accRep1FirstName: {
      inputType: 'text',
      title: 'First Name',
    },
    accRep1MiddleName: {
      inputType: 'text',
      title: 'Middle Name',
    },
    accRep1LastName: {
      inputType: 'text',
      title: 'Last Name',
    },
    accRep1Title: {
      inputType: 'text',
      title: 'Title',
    },
    accRep1Email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
    },
    accRep1Phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
    },

    accountRepSection2: {
      inputType: 'Section',
      title: 'Account Rep 2',
      span: 4,
    },
    accRep2FirstName: {
      inputType: 'text',
      title: 'First Name',
    },
    accRep2MiddleName: {
      inputType: 'text',
      title: 'Middle Name',
    },
    accRep2LastName: {
      inputType: 'text',
      title: 'Last Name',
    },
    accRep2Title: {
      inputType: 'text',
      title: 'Title',
    },
    accRep2Email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
    },
    accRep2Phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
    },

    goodbyeLetterGroup: {
      inputType: 'Group',
      title: 'Goodbye Letter',
      span: 4,
    },
    servicerNameSection: {
      inputType: 'Section',
      title: 'Servicer Name',
      span: 4,
    },
    servicerName: {
      inputType: 'text',
      title: 'Name',
    },
    servicerPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
    },
    servicerPhoneHours: {
      inputType: 'text',
      title: 'Phone Hours',
    },
    servicerPhoneContact: {
      inputType: 'text',
      title: 'Phone Contact / Department',
    },

    businessSection: {
      inputType: 'Section',
      title: 'Business / Correspondence Address',
      span: 4,
    },
    businessContact: {
      inputType: 'text',
      title: 'Contact',
    },
    businessAddress: {
      inputType: 'map',
      title: 'Address',
      span: 4,
    },

    paymentSection: {
      inputType: 'Section',
      title: 'Payment Address',
      span: 4,
    },
    paymentContact: {
      inputType: 'text',
      title: 'Contact',
    },
    paymentAddress: {
      inputType: 'map',
      title: 'Address',
      span: 4,
    },

    qualifiedSection: {
      inputType: 'Section',
      title: 'Qualified Written Request Address',
      span: 4,
    },
    qualifiedContact: {
      inputType: 'text',
      title: 'Contact',
    },
    qualifiedAddress: {
      inputType: 'map',
      title: 'Address',
      span: 4,
    },

    notesGroup: {
      inputType: 'Group',
      title: 'Notes',
      span: 4,
    },
    notes: {
      inputType: 'textarea',
      title: 'Notes',
      span: 4,
    },
  }
}
