import { AccountType } from 'config'

type TaskAssignee =
  | AccountType
  | 'self_underwriter'
  | 'self_account_executive'
  | 'self_broker'
  | 'self_loan_officer'
  | 'self_loan_processor'
  | 'self_creator'

export const LoanSubmissionActions: Record<string, string> = {
  Submit: 'Submit Loan',
  SubmitLoanSetup: 'Submit to Loan Setup',
  SubmitUnderwriter: 'Submit to Underwriter',
  SubmitConditions: 'Submit Conditions',
  AppraisalReview: 'Appraisal Review',
  ResubmitAppraisalReview: 'Resubmit Appraisal Review',
  BudgetReview: 'Budget Review',
  CreditReportPullImport: 'Credit report Pull / Import',
  ValuationOrderPlaced: 'Valuation Order Placed',
}

export interface LoanSubmissionConfig {
  taskAssignee: TaskAssignee[]
  statusTo: string | null
  email: boolean
  task: boolean
  taskOnTask: boolean
}
