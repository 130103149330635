import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { AccountingAccountTypeOptions, IAccountingAccount } from './types'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'select',
      title: 'Account Type',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: AccountingAccountTypeOptions,
      sort: true,
      required: true,
    },
    name: {
      inputType: 'text',
      title: 'Account Name',
      required: true,
    },
    number: {
      inputType: 'text',
      type: 'number',
      title: 'Account Number',
      required: true,
    },
  }
}
export const EditAccountDialog = ({ item, onClose }: { item: IAccountingAccount | null; onClose: Function }) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    const newInputs = cloneDeep(inputs)

    if (item) {
      newInputs.type.value = item.type
      newInputs.name.value = item.name
      newInputs.number.value = item.number
    }

    setInputs(newInputs)
  }, [item])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (newInputs[key].value.trim) newInputs[key].value = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    if (hasError) {
      setInputs(newInputs)
      return
    }

    setLoading(true)

    const submitData = {
      id: item ? item.id : -Date.now(),
      ...data,
    }

    await onClose(submitData)
    setLoading(false)
  }

  return (
    <Modal
      isOpen
      title={item ? 'Edit Account' : 'Create Account'}
      loading={isLoading}
      onOk={onSubmit}
      onClose={() => onClose(null)}
      titleOkay="Submit"
      titleCancel=""
    >
      <div className="w-96 relative">
        {Object.keys(inputs).map((key, index) => (
          <div className={`input mb-4`} key={index}>
            <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
          </div>
        ))}
      </div>
    </Modal>
  )
}
