import {
  API_REVIEW_FORM,
  API_REVIEW_FORM_BY_ID,
  API_REVIEW_FORM_TEMPLATE_BY_ID,
  API_REVIEW_FORM_TEMPLATE_ORDER_BY_ID,
  API_REVIEW_FORM_TEMPLATES,
} from 'config'
import type { ReviewFormCategory } from 'pages/Admin'
import Api from 'services/api'

import { getSetting, updateSetting } from './admin'

export const getReviewFormCategories = async (): Promise<ReviewFormCategory[]> => {
  const categories = await getSetting('REVIEW_FORM_CATEGORIES')
  try {
    const values = categories || {}
    return JSON.parse(values.value) || []
  } catch {
    return []
  }
}

export const setReviewFormCategories = async (values: ReviewFormCategory[]) => {
  return updateSetting('REVIEW_FORM_CATEGORIES', JSON.stringify(values))
}

export const getReviewFormTemplates = () => {
  return Api.get(API_REVIEW_FORM_TEMPLATES)
}

export const submitReviewFormTemplate = (id: number, data: Record<string, any>) => {
  if (id == 0) return Api.post(API_REVIEW_FORM_TEMPLATES, data)
  return Api.put(API_REVIEW_FORM_TEMPLATE_BY_ID, data, { id })
}

export const deleteReviewFormTemplate = (id: number) => {
  return Api.delete(API_REVIEW_FORM_TEMPLATE_BY_ID, {}, { id })
}

export const updateReviewFormTemplateOrder = (id: number, order: number) => {
  return Api.put(API_REVIEW_FORM_TEMPLATE_ORDER_BY_ID, { order }, { id })
}

export const getReviewForm = (index: number = 0) => {
  return Api.get(API_REVIEW_FORM, { index })
}

export const updateReviewForm = (id: number, key: string, value: any) => {
  return Api.put(API_REVIEW_FORM_BY_ID, { key, value }, { id })
}

export const updateReviewFormData = (id: number, data: Record<string, any>) => {
  return Api.put(API_REVIEW_FORM_BY_ID, { key: 'data', value: data }, { id })
}

export const deleteReviewForm = (id: number) => {
  return Api.delete(API_REVIEW_FORM_BY_ID, {}, { id })
}
