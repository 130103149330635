import { CheckIcon, EyeIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { useEffect, useMemo, useState } from 'react'
import {
  getTrinityCommentsLiens,
  getTrinityProgressStatus,
  postTrinityComments,
  postTrinityDocuments,
  sendTrinityReportToDocStorage,
} from 'services'
import {
  Button,
  ButtonGroup,
  CategoryDocument,
  CategoryDocuments,
  Checkbox,
  CopyableText,
  Modal,
  PlainTable,
  TextArea,
} from 'stories/components'
import { formatDate, formatTime, getPrice2decimal } from 'utils'

import { handleFailedDocsNotification } from './handleFailedDocs'
import { AppraisalDocumentCategories } from './types'

export const Trinity = ({ data, onClose }: { data: Record<string, any>; onClose: Function }) => {
  const [menu, setMenu] = useState('Notification Logs')
  const [logs, setLogs] = useState<Array<any>>([])
  const [progress, setProgress] = useState<any>(null)
  const [reports, setReports] = useState<Array<any>>([])
  const [lienItems, setLienItems] = useState<Array<any>>([])
  const [selectedItems, setSelectedItems] = useState<Array<number>>([])
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('')
  const [documents, setDocuments] = useState<Array<CategoryDocument>>([])
  const [uploadedDocs, setUploadedDocs] = useState<Array<string>>(data.thirdPartyAPI.uploadedDocs)

  const { orderID } = data.thirdPartyAPI

  const getProcessStatus = async () => {
    const res = await getTrinityProgressStatus(orderID, data.id)
    if (res.success) {
      setProgress(res.status)
      setReports(res.reports)
    }
  }

  useEffect(() => {
    getProcessStatus()
    getLogs()
    setDocuments(data.info?.documents || [])
  }, [])

  const getLogs = async () => {
    setLoading(true)
    const res = await getTrinityCommentsLiens(orderID, data.id)
    setLoading(false)
    if (res.success) {
      setLienItems(res.lineItems)
      let rlt: any = []
      res.comments.map((item: any, index: number) => {
        rlt.push([
          index + 1,
          <div>
            {formatTime(item.time)}
            <br />
            <span className="italic">{item.by}</span>
          </div>,
          item.content,
        ])
      })
      setLogs(rlt)
    }
  }

  const onSendMessage = async () => {
    setLoading(true)
    setAction('sendMessage')
    await postTrinityComments({
      orderID,
      comment,
    })
    setComment('')
    setLoading(false)
    setAction('')
    getLogs()
  }

  const extraDocs = useMemo(() => {
    let docs: any = []
    documents.map((item: CategoryDocument) => {
      if (uploadedDocs?.includes(item.fileKey)) {
      } else docs.push(item)
    })
    return docs
  }, [documents, uploadedDocs])

  const sendDocuments = async () => {
    setLoading(true)
    setAction('sendDocuments')
    const res = await postTrinityDocuments({
      id: data.id,
      orderID,
      documents: extraDocs,
    })

    handleFailedDocsNotification(res)

    let tempDocs = cloneDeep(uploadedDocs)
    res.uploadedDocs.map((fileKey: string) => {
      tempDocs.push(fileKey)
    })
    setUploadedDocs(tempDocs)
    setLoading(false)
    setAction('')
  }

  const onDocumentChange = (docs: Array<CategoryDocument>) => {
    setDocuments(docs)
  }

  const sendToDocStorage = async (item: any, index: number) => {
    if (item.sent) return
    setLoading(true)
    const res = await sendTrinityReportToDocStorage(data.id, item)
    if (res.success) {
      let temp = cloneDeep(reports)
      temp[index] = {
        ...item,
        sent: true,
      }
      setReports(temp)
    }
    setLoading(false)
  }

  const onChangeLienItem = async (index: number, value: boolean) => {
    console.log(value)
    let temp = cloneDeep(selectedItems)
    const pos = temp.indexOf(index)
    if (pos === -1) {
      temp.push(index)
    } else {
      temp.splice(pos, 1)
    }
    setSelectedItems(
      temp.sort(function (a, b) {
        return a - b
      }),
    )
  }

  const renderContent = () => {
    if (menu === 'Notification Logs') {
      return (
        <div className="">
          <div className="">
            <PlainTable header={['No', 'Date / By', 'Comment']} tdClass={'px-4 py-2'} data={logs} />
          </div>
          <TextArea title="Comment" value={comment} onChange={(value) => setComment(value)} />
          <div className="text-center mt-3">
            <Button
              color="gray"
              onClick={onSendMessage}
              loading={action === 'sendMessage'}
              disabled={comment.length === 0}
            >
              Send Message to Trinity
            </Button>
          </div>
        </div>
      )
    }
    if (menu === 'Documents') {
      return (
        <div className="">
          <CategoryDocuments
            documents={documents}
            onChange={onDocumentChange}
            setLoading={setLoading}
            type={'OrderAppraisal'}
            categories={AppraisalDocumentCategories}
          />
          <div className="text-center mt-4">
            <Button
              color="gray"
              onClick={sendDocuments}
              loading={action === 'sendDocuments'}
              disabled={extraDocs.length === 0}
            >
              Upload Documents to Trinity
            </Button>
          </div>
        </div>
      )
    }
    if (menu.includes('Trinity Reports')) {
      return (
        <div className="overflow-auto">
          <table className="w-full border text-[14px] rounded">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 border">No</th>
                <th className="p-2 border">Name</th>
                <th className="p-2 border">Type</th>
                <th className="p-2 border">Date</th>
                <th className="p-2 border">Action</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td className="p-2 border">{index + 1}</td>
                    <td className="p-2 border">{item.fileName}</td>
                    <td className="p-2 border">{item.group.name}</td>
                    <td className="p-2 border">{formatDate(item.createdAt)}</td>
                    <td className="p-2 border">
                      <span className="flex flex-wrap gap-2 items-center">
                        <span className="text-shade-blue p-1 hover-shadow1 rounded cursor-pointer">
                          <a href={item.url} target="_blank">
                            <EyeIcon className="w-4 h-4"></EyeIcon>
                          </a>
                        </span>
                        <span
                          className="text-[13px] p-1 hover-shadow1 rounded cursor-pointer flex gap-1 items-center"
                          onClick={() => sendToDocStorage(item, index)}
                        >
                          Send to DocStorage
                          {item.sent ? <CheckIcon className="w-4 h-4"></CheckIcon> : ''}
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }
    if (menu.includes('Lien Items')) {
      return (
        <div className="overflow-auto">
          <table className="w-full border text-[14px] rounded">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 border">No</th>
                <th className="p-2 border">Description</th>
                <th className="p-2 border">Cost</th>
                <th className="p-2 border">Amount Completed</th>
                <th className="p-2 border">Remark</th>
                <th className="p-2 border">Total % Complete this Item</th>
                <th className="p-2 border">% Complete this inspection</th>
              </tr>
            </thead>
            <tbody>
              {lienItems.map((item, index) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-slate-50' : ''}`}>
                    <td className="p-1 border">{index + 1}</td>
                    <td className="p-1 border">
                      <span>
                        <Checkbox
                          size={3}
                          id={`Description-${index}`}
                          title={item.description}
                          key={`Description-${index}`}
                          value={selectedItems.includes(index)}
                          onChange={(value) => onChangeLienItem(index, value)}
                        />
                      </span>
                    </td>
                    <td className="p-1 border text-right">{getPrice2decimal(item.itemCost, false, true)}</td>
                    <td className="p-1 border text-right">
                      {getPrice2decimal((item.itemCost * item.percentCompleted) / 100, false, true)}
                    </td>
                    <td className="p-1 border">{item.remarks}</td>
                    <td className="p-1 border text-right">{item.previousPercentCompleted}</td>
                    <td className="p-1 border text-right">{item.percentCompleted}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }
  }

  const renderProgressStatus = () => {
    if (progress === null) return null
    return (
      <div className="px-2">
        <div className="mb-4">
          <div className="flex flex-wrap gap-4 justify-between">
            <div>
              Status: <span className="font-bold text-shade-blue italic">{progress}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const allSelectedItemText = useMemo(() => {
    let rlt = ``
    {
      selectedItems.map((pos) => {
        rlt += `${pos + 1}. ${lienItems[pos].description}\n`
      })
    }
    return rlt
  }, [selectedItems])

  return (
    <Modal isOpen title={`Trinity - ${orderID}`} titleOkay="" loading={loading} onClose={() => onClose(false)}>
      <div className="text-gray-600 text-md overflow-hidden min-w-[750px] max-w-2xl relative">
        <div className="">
          <div className="border-b mb-2 flex justify-between flex-wrap gap-4">
            <div className="flex flex-wrap items-center gap-4">
              <span className="italic font-semibold">Progress Status</span>
            </div>
          </div>
          {renderProgressStatus()}
        </div>
        <div className="rounded p-2 rounded border-[4px] border-shade-blue/50">
          <ButtonGroup
            title={[
              'Notification Logs',
              'Documents',
              `Trinity Reports (${reports.length})`,
              `Lien Items (${lienItems.length})`,
            ]}
            onChange={(key) => {
              setMenu(key)
            }}
            value={menu}
          />
        </div>
        {selectedItems.length ? (
          <div className="mt-4 flex text-[14px] border-b">
            <div className="mr-2 min-w-[72px]">Selected Liens ({selectedItems.length}):</div>
            <CopyableText copyText={allSelectedItemText}>
              <div className="">
                {selectedItems.map((pos, index) => {
                  return (
                    <span className="mr-2" key={pos}>
                      {index !== 0 && '|'} {pos + 1}. <span className="italic">{lienItems[pos].description}</span>
                    </span>
                  )
                })}
              </div>
            </CopyableText>
          </div>
        ) : null}
        <div className="mt-4 max-h-[80vh] overflow-auto">{renderContent()}</div>
      </div>
    </Modal>
  )
}
