import Api from 'services/api'

const API_LOAN_PROGRAMS = '/loan/loanPrograms'
const API_LOAN_PROGRAMS_BY_ID = `${API_LOAN_PROGRAMS}/:id`

export const getLoanPrograms = () => {
  return Api.get(API_LOAN_PROGRAMS)
}

export const submitLoanProgram = (id: number, data: Record<string, string>) => {
  if (!id) return Api.post(API_LOAN_PROGRAMS, data)

  return Api.put(API_LOAN_PROGRAMS_BY_ID, data, { id })
}

export const deleteLoanProgram = (id: number) => {
  return Api.delete(API_LOAN_PROGRAMS_BY_ID, {}, { id })
}
