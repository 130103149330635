import cloneDeep from 'clone-deep'
import { EscrowTypeLabels, finresiMapConstants, loanOptionsConstants } from 'config'
import type { IDscrLeverageMaxLimits, IRestriction } from 'pages/Resources'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import { foreclosuresOptions } from '../../Commercial/LeverageAdjustments'
import { CrossCollateralBlanketLimit } from '../../Commercial/LeverageLimits/CrossCollateralBlanketLimit'
import { MaxCashToBorrowerLeverageLimit } from '../../Commercial/LeverageLimits/MaxCashToBorrowerLeverageLimit'
import { SecondLienMaxLimits } from '../../Commercial/LeverageLimits/SecondLienMaxLimits'
import { DscrFicoLeverageLimit } from './DscrFicoLeverageLimit'
import { DscrFirstTimeInvestorLeverageMaxLimits } from './DscrFirstTimeInvestorLeverageMaxLimits'
import { DscrInterestOnlyLeverageLimits } from './DscrInterestOnlyLeverageLimits'
import { DscrLeverageLimit } from './DscrLeverageLimit'
import { DscrLoanAmountLeverageLimit } from './DscrLoanAmountLeverageLimit'
import { DscrPropertyTypeLtvLimits } from './DscrPropertyTypeLtvLimits'
import { DscrRuralPropertyLeverageMaxLimits } from './DscrRuralPropertyLeverageMaxLimits'
import { DscrValueLeverageLimits } from './DscrValueLeverageLimits'

const initialMenus = {
  occupancy: 'Occupancy',
  propertyType: 'Property Type',
  escrowType: 'Escrow Type',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  citizenship: 'Citizenship',
  isShortTermRental: 'Is the property a short term rental?',
  decliningMarketProperty: 'Property in a declining market?',
  newConstructionProperty: 'Is the property a new construction?',
  ruralProperty: 'Rural Property?',
  firstTimeInvestor: 'First Time Investor?',
  interestOnly: 'Interest Only (3)',
  fico: 'FICO',
  dscr: 'DSCR',
  loanAmount: 'Loan Amount',
  maxCashToBorrower: 'Cash in Hand',
  crossCollateral: 'Cross Collateral / Blanket (7)',
  secondLien: 'Second Lien (2)',
}

export const DscrLeverageLimits = ({ data }: { data: IRestriction }) => {
  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')

  useEffect(() => {
    if (!data) return

    const {
      PropertyTypeLtvMaxLimit,
      EscrowTypeLeverageLimit,
      CitizenshipLtvMaxLimit,
      MortgageLatesLtvMaxLimit,
      FcSsDilLeverageMaxLimit,
      BankruptcyLeverageMaxLimit,
      FirstTimeInvestorLeverageMaxLimit,
      RuralPropertyLeverageMaxLimit,
      MaxCashToBorrowerLeverageMaxLimit,
      ForbearanceLeverageLimit,
      DscrLeverageLimit,
      FICOLeverageLimit,
      IsShortTermRentalLeverageLimit,
      LoanAmountLeverageLimit,
      OccupancyLeverageLimit,
      DecliningMarketPropertyLeverageLimit,
      NewConstructionPropertyLeverageLimit,
    } = data.LeverageMaxLimits as IDscrLeverageMaxLimits

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'newConstructionProperty':
          if (!!NewConstructionPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${NewConstructionPropertyLeverageLimit.length})`
          }
          break
        case 'occupancy':
          if (!!OccupancyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${OccupancyLeverageLimit.length})`
          }
          break
        case 'decliningMarketProperty':
          if (!!DecliningMarketPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${DecliningMarketPropertyLeverageLimit.length})`
          }
          break
        case 'loanAmount':
          if (!!LoanAmountLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${LoanAmountLeverageLimit.length})`
          }
          break
        case 'propertyType':
          if (!!PropertyTypeLtvMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${PropertyTypeLtvMaxLimit.length})`
          }
          break
        case 'isShortTermRental':
          if (!!IsShortTermRentalLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${IsShortTermRentalLeverageLimit.length})`
          }
          break
        case 'escrowType':
          if (!!EscrowTypeLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${EscrowTypeLeverageLimit.length})`
          }
          break
        case 'dscr':
          if (!!DscrLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${DscrLeverageLimit.length})`
          }
          break
        case 'citizenship':
          if (!!CitizenshipLtvMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${CitizenshipLtvMaxLimit.length})`
          }
          break
        case 'mortgageLates':
          if (!!MortgageLatesLtvMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${MortgageLatesLtvMaxLimit.length})`
          }
          break
        case 'fcSsDil':
          if (!!FcSsDilLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${FcSsDilLeverageMaxLimit.length})`
          }
          break
        case 'bankruptcy':
          if (!!BankruptcyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${BankruptcyLeverageMaxLimit.length})`
          }
          break
        case 'forbearance':
          if (!!ForbearanceLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${ForbearanceLeverageLimit.length})`
          }
          break
        case 'firstTimeInvestor':
          if (!!FirstTimeInvestorLeverageMaxLimit.length && !!FirstTimeInvestorLeverageMaxLimit[0].limits.length) {
            newMenus[key] = initialMenus[key] + ` (${FirstTimeInvestorLeverageMaxLimit[0].limits.length})`
          }
          break
        case 'ruralProperty':
          if (!!RuralPropertyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${RuralPropertyLeverageMaxLimit.length})`
          }
          break
        case 'fico':
          if (!!FICOLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${FICOLeverageLimit.length})`
          }
          break
        case 'maxCashToBorrower':
          if (!!MaxCashToBorrowerLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${MaxCashToBorrowerLeverageMaxLimit.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [data])

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.loanAmount:
        return <DscrLoanAmountLeverageLimit data={data?.LeverageMaxLimits?.LoanAmountLeverageLimit || []} />
      case menus.propertyType:
        return <DscrPropertyTypeLtvLimits data={data?.LeverageMaxLimits?.PropertyTypeLtvMaxLimit || []} />
      case menus.occupancy:
        return (
          <DscrValueLeverageLimits title="Occupancy" data={data?.LeverageMaxLimits?.OccupancyLeverageLimit || []} />
        )
      case menus.decliningMarketProperty:
        return (
          <DscrValueLeverageLimits
            title="Property in a declining market?"
            data={data?.LeverageMaxLimits?.DecliningMarketPropertyLeverageLimit || []}
          />
        )
      case menus.newConstructionProperty:
        return (
          <DscrValueLeverageLimits
            title="Is the property a new construction?"
            data={data?.LeverageMaxLimits?.NewConstructionPropertyLeverageLimit || []}
          />
        )
      case menus.escrowType:
        return (
          <DscrValueLeverageLimits
            title="Escrow Type"
            options={EscrowTypeLabels}
            data={data?.LeverageMaxLimits?.EscrowTypeLeverageLimit || []}
          />
        )
      case menus.dscr:
        return <DscrLeverageLimit data={data?.LeverageMaxLimits?.DscrLeverageLimit || []} />
      case menus.fico:
        return <DscrFicoLeverageLimit data={data?.LeverageMaxLimits?.FICOLeverageLimit || []} />
      case menus.citizenship:
        return (
          <DscrValueLeverageLimits
            title="Citizenship"
            options={finresiMapConstants.citizen}
            data={data?.LeverageMaxLimits?.CitizenshipLtvMaxLimit || []}
          />
        )
      case menus.mortgageLates:
        return (
          <DscrValueLeverageLimits
            title="Mortgage Lates"
            options={finresiMapConstants.mortgageLates}
            data={data?.LeverageMaxLimits?.MortgageLatesLtvMaxLimit || []}
          />
        )
      case menus.fcSsDil:
        return (
          <DscrValueLeverageLimits
            title="FC/SS/DIL"
            options={foreclosuresOptions}
            data={data?.LeverageMaxLimits?.FcSsDilLeverageMaxLimit || []}
          />
        )
      case menus.bankruptcy:
        return (
          <DscrValueLeverageLimits
            title="Bankruptcy"
            options={finresiMapConstants.bankruptcy}
            data={data?.LeverageMaxLimits?.BankruptcyLeverageMaxLimit || []}
          />
        )
      case menus.forbearance:
        return (
          <DscrValueLeverageLimits
            title="Forbearance / Loan Modification"
            options={loanOptionsConstants.forbearance}
            data={data?.LeverageMaxLimits?.ForbearanceLeverageLimit || []}
          />
        )
      case menus.firstTimeInvestor:
        return (
          <DscrFirstTimeInvestorLeverageMaxLimits
            data={data?.LeverageMaxLimits?.FirstTimeInvestorLeverageMaxLimit || []}
          />
        )
      case menus.ruralProperty:
        return (
          <DscrRuralPropertyLeverageMaxLimits data={data?.LeverageMaxLimits?.RuralPropertyLeverageMaxLimit || []} />
        )
      case menus.interestOnly:
        return <DscrInterestOnlyLeverageLimits data={data?.LeverageMaxLimits?.InterestOnlyLeverageLimit} />
      case menus.maxCashToBorrower:
        return (
          <MaxCashToBorrowerLeverageLimit data={data?.LeverageMaxLimits?.MaxCashToBorrowerLeverageMaxLimit || []} />
        )
      case menus.crossCollateral:
        return <CrossCollateralBlanketLimit data={data?.LeverageMaxLimits?.CrossCollateralBlanket} />
      case menus.isShortTermRental:
        return (
          <DscrValueLeverageLimits
            title="Is the property a short term rental?"
            data={data?.LeverageMaxLimits?.IsShortTermRentalLeverageLimit || []}
          />
        )
      case menus.secondLien:
        return <SecondLienMaxLimits data={data?.LeverageMaxLimits?.SecondLienMaxLimit} />
      default:
        return <></>
    }
  }, [selectedMenu, data])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div className="h-full">
      {renderMenus}

      <div className="p-2 h-full">{renderContent}</div>
    </div>
  )
}
