import cloneDeep from 'clone-deep'
import { type InputType, TimeZone } from 'config'
import moment from 'moment-timezone'
import { IBorrower } from 'pages/Borrower/ManageBorrowers/types'
import { useEffect, useState } from 'react'
import { createUserNote } from 'services'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { UserInfo } from './user.type'

enum UserNotesTypeOptions {
  Left = 'Left Message',
  NoAnswer = 'No Answer',
  Busy = 'Busy Signal',
  Wrong = 'Wrong Number',
  DoNotContract = 'Do Not Contact',
  Move = 'Move Along/Eject',
  Intro = 'Intro VM and EM',
  Other = 'Other',
}

const RequiredNotifyAtTypes = [UserNotesTypeOptions.Left, UserNotesTypeOptions.NoAnswer, UserNotesTypeOptions.Busy]

const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'select',
      options: Object.values(UserNotesTypeOptions),
      title: 'Type',
      value: '',
      hasDefaultOption: true,
      required: true,
      span: 'full',
    },
    notifyDate: {
      inputType: 'text',
      type: 'date',
      title: 'Notify At (Date)',
      value: '',
      visible: false,
      required: true,
    },
    notifyTime: {
      inputType: 'text',
      type: 'time',
      title: 'Notify At (Time)',
      value: '',
      visible: false,
      required: true,
    },
    message: {
      inputType: 'textarea',
      title: 'Message',
      value: '',
      required: true,
      span: 'full',
    },
  }
}

export const UserNotesDialog = ({
  user,
  onClose,
  isBorrower = false,
}: {
  user: UserInfo | IBorrower | any
  onClose: (v: boolean) => void
  isBorrower?: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {}, [])

  const onChange = async (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key == 'type') {
      newInputs.notifyDate.visible = RequiredNotifyAtTypes.includes(value as any)
      newInputs.notifyTime.visible = RequiredNotifyAtTypes.includes(value as any)
    }
    setInputs(newInputs)
  }

  const _onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    const requireNotifyAt = newInputs.notifyDate.visible !== false

    const notifyAt = requireNotifyAt ? moment(`${data.notifyDate} ${data.notifyTime}`).tz(TimeZone).toDate() : null
    setLoading(true)
    await createUserNote(user.id, {
      type: data.type,
      message: data.message,
      notifyAt,
      isBorrower,
    })
    setLoading(false)
    onClose(true)
  }

  return (
    <Modal
      title={`Add Notes - ${isBorrower ? user.fullName : user.name}`}
      titleOkay="Submit"
      loading={loading}
      onClose={() => onClose(false)}
      onOk={_onSubmit}
      isOpen
    >
      <div className="w-196 grid gap-4 grid-cols-2">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          if (input.visible === false) return null
          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
