import { ArrowRightIcon, Bars3Icon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { logout, signInRequest } from 'actions'
import cloneDeep from 'clone-deep'
import { LogoBlue } from 'components/Logo'
import { LogInAlertModal, TfaModal } from 'components/Modals'
import { COMPANY_DNS, COMPANY_NAME, IS_MAIN_COMPANY } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getNavBarData } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Input } from 'stories/components'
import { delay, validateEmail } from 'utils'

interface menuType {
  label: String
  visible: Boolean | undefined
  href: any
}

type tpNavigation = {
  [key: string]: any
}

let draftNavigations: tpNavigation = {
  about: { name: 'About Us', href: '/about_us', visible: true },
  investors: { name: 'Investors', href: '/investors', visible: true },
  how_it_works: { name: 'How it works', href: '/how_it_works', visible: true },
  where_we_lend: { name: 'Where we lend', href: '/where_we_lend', visible: true },
  // pricing: { name: 'Pricing', href: '/pricing', visible: true },
  // faqs: { name: 'FAQs', href: '/faqs', visible: true },
  contacts: { name: 'Contact Us', href: '/contacts', visible: true },
  // login: { name: 'Login', href: '/login', visible: true, className: 'bg-shade-blue rounded text-white hover:bg-white' },
  pipeline: { name: 'Pipeline', href: '/pipeline', visible: false },
  reports: { name: 'Reports', href: '/reports', visible: false },
  resources: { name: 'Resources', href: '/resources', visible: false },
  ratesheet: { name: 'Ratesheet', href: '/ratesheet', visible: false },
  appraisals: { name: 'Appraisals', href: '/appraisals', visible: false },
  task: { name: 'Tasks', href: '/tasks', visible: false },
  bridgequal: {
    name: `${IS_MAIN_COMPANY ? 'BridgeQual&#8482;' : 'Qualify'}`,
    href: '/loan_process/structure/new',
    visible: false,
    className: 'bg-shade-blue rounded text-white hover:bg-white',
  },
  register: {
    name: 'Become a Broker',
    href: '/register',
    visible: true,
    className: 'bg-shade-blue rounded text-white hover:bg-white',
  },
}

function _Header(props: any) {
  const [showMenu, setShowMenu] = useState(false)
  const [menus, setMenus] = useState<menuType[]>([])
  const [navigations, setNavigations] = useState({ ...draftNavigations })
  const auth = useSelector((state: any) => state.auth)
  const isTemparary = auth.profile.temparary

  const [logInAlerts, setLogInAlerts] = useState<Array<any>>([])
  const [is2fa, set2fa] = useState(false)
  const [action, setAction] = useState('')
  const [companyBroadcast, setCompanyBroadcast] = useState(null)
  const [activeTasks, setActiveTasks] = useState(0)
  const [activeAppraisals, setActiveAppraisals] = useState(0)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const location = useLocation()
  const navigate = useHistory()

  const { header } = useSelector((state: any) => {
    return {
      header: state.header,
    }
  })

  const activeMenu = (to: String) => {
    if (location.pathname === to) {
      return 'shadow'
    }
    return ''
  }
  const [fields, setFields] = useState({
    email: '',
    password: '',
  })

  const dispatch = useDispatch()

  const handleLogout = () => {
    const { temparary } = auth.profile
    dispatch(logout())
    if (temparary && IS_MAIN_COMPANY) window.open(`https://www.${COMPANY_DNS}`, '_self')
    else if (temparary) navigate.push('/borrowerPipelines/signin')
    else navigate.push('/home')
  }

  const getNotificationCN = (size: number) => {
    return size >= 100 ? 'w-10 h-5 top-[-10px] right-[-28px]' : 'w-6 h-4 top-[-10px] right-[-18px]'
  }

  useEffect(() => {
    setShowMenu(false)
    const draft = cloneDeep(navigations)
    if (auth.isAuthenticated) {
      draft.about.visible = false
      draft.investors.visible = false
      draft.how_it_works.visible = false
      draft.where_we_lend.visible = false
      // draft.pricing.visible = false
      draft.contacts.visible = false
      draft.register.visible = false
      // draft.login.visible = false
      draft.reports.visible = hasPermission('MANAGE_REPORTS')
      draft.pipeline.visible = true
      draft.resources.visible = true
      draft.ratesheet.visible = true
      draft.bridgequal.visible = true
      draft.task.visible = hasPermission('MANAGE_TASKS')
      draft.appraisals.visible = hasPermission('MANAGE_VENDORS')
    } else {
      draft.about.visible = IS_MAIN_COMPANY
      draft.investors.visible = IS_MAIN_COMPANY
      draft.how_it_works.visible = IS_MAIN_COMPANY
      draft.where_we_lend.visible = IS_MAIN_COMPANY
      // draft.pricing.visible = true
      draft.contacts.visible = IS_MAIN_COMPANY
      draft.register.visible = true
      // draft.login.visible = true
      draft.reports.visible = false
      draft.pipeline.visible = false
      draft.resources.visible = false
      draft.ratesheet.visible = false
      draft.bridgequal.visible = true
      draft.task.visible = false
      draft.appraisals.visible = false
    }

    draft.bridgequal.href =
      auth.isAuthenticated && isTemparary ? '/borrowerPipeline/new' : '/loan_process/structure/new'

    if (draft.task.visible) {
      draft.task.name = 'Tasks'
      if (activeTasks) {
        draft.task.name = `
          <div class="relative">
            Tasks<div class="absolute ${getNotificationCN(
              activeTasks,
            )} text-sm flex items-center justify-center text-white rounded-full bg-red-700">${activeTasks}</div>
          </div>
        `
      }
    }
    if (draft.appraisals.visible) {
      draft.appraisals.name = 'Appraisals'
      if (activeAppraisals) {
        draft.appraisals.name = `
          <div class="relative">
            Appraisals<div class="absolute ${getNotificationCN(
              activeAppraisals,
            )} text-sm flex items-center justify-center text-white rounded-full bg-red-700">${activeAppraisals}</div>
          </div>
        `
      }
    }
    setNavigations(draft)
  }, [auth, activeTasks, activeAppraisals])

  useEffect(() => {
    if (!auth.isAuthenticated) {
      setCompanyBroadcast(null)
    } else {
      updateActiveNotification()
    }
  }, [auth.isAuthenticated, header.activeChangeFlag, location])

  const updateActiveNotification = () => {
    if (auth.profile.temparary !== true && auth.isAuthenticated) {
      getNavBarData().then((res) => {
        setCompanyBroadcast(res.broadcast)
        setActiveTasks(Number(res.activeTasks))
        setActiveAppraisals(Number(res.activeAppraisals))
      })
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateActiveNotification()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const { data: permissionData, hasPermission } = usePermissions()

  useEffect(() => {
    if (!permissionData) return
    let draftMenus: menuType[] = [
      {
        label: 'Manage Accounts',
        href: '/registrations',
        visible: hasPermission('MANAGE_ACCOUNTS'),
      },
      {
        label: 'Manage Borrowers',
        href: '/borrowers',
        visible: hasPermission('MANAGE_BORROWERS'),
      },
      {
        label: 'Edit Profile & Info',
        href: '/edit_profile',
        visible: true,
      },
      {
        label: 'divider',
        href: '#',
        visible: true,
      },
      {
        label: 'Manage Accountings',
        href: '/accountings/accountingCategories',
        visible: hasPermission(['MANAGE_ACCOUNTING']),
      },
      {
        label: 'Vendors',
        href: '/vendors/creditReports',
        visible: hasPermission('MANAGE_VENDORS'),
      },
      {
        label: 'Conditions & Templates',
        href: '/condition_template/conditions',
        visible: hasPermission('MANAGE_CONDITIONS_TEMPLATES'),
      },
      {
        label: 'Admin Tools',
        href: '/admin_tool/permissions',
        visible: hasPermission(['MANAGE_ADMIN_TOOLS', 'MANAGE_APPRAISER_SETTLEMENT_CONTRACTOR', 'MANAGE_REVIEWS']),
      },
      {
        label: 'Secondary Marketing',
        href: '/secondary_marketing/permissions',
        visible: hasPermission('MANAGE_ADMIN_TOOLS'),
      },
      {
        label: 'User Activity',
        href: '/user_activity',
        visible: hasPermission('MANAGE_USER_ACTIVITY'),
      },
      {
        label: 'divider',
        href: '#',
        visible: true,
      },
    ]
    setMenus(draftMenus)
  }, [permissionData])

  const onChangeLoginForm = (key: string, value: string) => {
    let temp: any = cloneDeep(fields)
    temp[key] = value
    setFields(temp)
  }

  const queryClient = useQueryClient()

  const submitLogin = async () => {
    let errors = 0
    fields.email = fields.email.trim().toLowerCase()
    if (!validateEmail(fields.email)) errors += 1
    if (fields.password.length === 0) errors += 1
    if (errors) {
      return toast('Invalid Email Address or Password!', { type: 'error' })
    }
    setAction('Signing')
    await queryClient.clear()
    props.signInRequest(
      fields,
      async (isPass2fa: boolean) => {
        if (isPass2fa) {
          setFields({
            email: '',
            password: '',
          })

          if (navigate.location.pathname !== '/loan_process/structure/new') {
            await delay(0.5)
            navigate.push('/pipeline')
          }
        } else {
          set2fa(true)
        }
        setAction('')
      },
      () => {
        setAction('')
      },
      (logInAlerts: Array<any>) => {
        setLogInAlerts(logInAlerts)
      },
    )
  }

  const keyPress = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      submitLogin()
      e.preventDefault()
    }
  }

  const renderLoginForm = () => {
    if (!IS_MAIN_COMPANY) return null
    if (auth.isAuthenticated) return null
    return (
      <div className="login-form-container">
        <div className="flex justify-end flex-wrap gap-2 md:pr-4 mt-2">
          <Input
            type="text"
            title="Email"
            value={fields.email}
            onChange={(value) => onChangeLoginForm('email', value)}
            className="-mb-4 w-[240px]"
          />
          <Input
            type="password"
            title="Password"
            value={fields.password}
            onChange={(value) => onChangeLoginForm('password', value)}
            className="-mb-4 w-[240px]"
            onKeyDown={(e) => keyPress(e)}
          />
          <div className="flex items-center" onClick={submitLogin}>
            <div className="hover:underline text-shade-blue cursor-pointer flex flex-wrap text-[14px] items-center gap-1">
              <UserIcon className="w-3 h-3"></UserIcon>
              <span className="font-bold">{`LogIn`}</span>
              <ArrowRightIcon className="w-3 h-4"></ArrowRightIcon>
              {action === 'Signing' && <img src={svgLoading} className="inline w-4 h-4 ml-2 text-white animate-spin" />}
            </div>
          </div>
        </div>
        <div className="flex justify-end md:pr-4">
          <Link to="/forgetPassword">
            <p className="block mt-1 text-[13px] hover:text-red-900 hover:underline text-red-800">
              Forgot/Reset your password?
            </p>
          </Link>
        </div>
        {is2fa && <TfaModal email={fields.email} password={fields.password} onClose={() => set2fa(false)} />}
      </div>
    )
  }

  return (
    <>
      {companyBroadcast && (
        <div className="bg-red-100 text-red-900 font-bold w-full p-3 text-center">{companyBroadcast}</div>
      )}

      <div>
        <div className="max-w-screen-2xl m-auto relative py-4 px-2 bg-white">
          <nav className="relative flex items-center justify-between" aria-label="Global">
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/home">
                  <span className="sr-only">{COMPANY_NAME}</span>
                  <LogoBlue />
                </Link>
                <div className="mr-2 flex items-center md:hidden">
                  <div
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 hover:outline-none hover:ring-2 hover:ring-inset hover:ring-blue-500"
                    onClick={() => setShowMobileMenu(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden flex-wrap items-center md:flex md:ml-10 md:pr-4 md:space-x-8">
              {Object.keys(navigations).map((key, index) => {
                const item: any = navigations[key]
                if (item.visible) {
                  return (
                    <Link
                      key={`nav-${index}`}
                      to={item.href}
                      className={`hover:shadow font-variation-settings-600 px-3 py-2 my-2 text-gray-600 hover:text-gray-900 ${activeMenu(
                        item.href,
                      )} ${item.className} `}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.name }} />
                    </Link>
                  )
                }
              })}
              {auth.isAuthenticated && [
                <div className="flex header-profile-name text-gray-600" key={0}>
                  <UserIcon className="w-5 h-5"></UserIcon>
                  <span className="font-normal ml-1 text-[15px]">
                    {auth.role?.isBrokerChild ? auth.role.brokerChildEmail : auth.profile.name}
                  </span>
                </div>,
                <div
                  key={1}
                  className="relative p-2 space-y-1.5 hover:bg-gray-600 hover:cursor-pointer rounded shadow"
                  onMouseEnter={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                >
                  <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                  <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                  <span className={`block rounded w-8 h-1 bg-gray-${showMenu ? 100 : 600}`}></span>
                  {showMenu && (
                    <div className="absolute p-5 bg-gray-600 w-72 rounded right-0 space-y-4 z-50">
                      {menus.map((item, index) => {
                        if (item.visible) {
                          if (item.label === 'divider') {
                            return <div key={`menu-${index}`} className="block rounded h-px bg-gray-400"></div>
                          }
                          return (
                            <div
                              className="text-white font-medium pl-1 hover:underline"
                              key={`menu-${index}`}
                              onClick={() => navigate.push(item.href)}
                            >
                              {item.label}
                            </div>
                          )
                        }
                      })}
                      <div className="text-white font-medium hover:underline pl-1" onClick={handleLogout}>
                        Sign Out
                      </div>
                    </div>
                  )}
                </div>,
              ]}
            </div>
          </nav>
          {renderLoginForm()}
          {logInAlerts.length > 0 && <LogInAlertModal data={logInAlerts} onClose={() => setLogInAlerts([])} />}
        </div>
        {showMobileMenu && (
          <div>
            <div className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <LogoBlue />
                  </div>
                  <div className="-mr-2">
                    <div
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 hover:outline-none hover:ring-2 hover:ring-inset hover:ring-blue-500"
                      onClick={() => setShowMobileMenu(false)}
                    >
                      <span className="sr-only">Close main menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {Object.keys(navigations).map((key, index) => {
                    const item: any = navigations[key]
                    if (item.visible) {
                      return (
                        <Link
                          key={index}
                          to={item.href}
                          className={`block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                            item.href,
                          )}`}
                        >
                          <span dangerouslySetInnerHTML={{ __html: item.name }} />
                        </Link>
                      )
                    }
                  })}
                  {auth.isAuthenticated && (
                    <div>
                      <div className="block rounded h-px bg-gray-100"></div>
                      {menus.map(
                        (item, index) =>
                          item.visible &&
                          (item.label === 'divider' ? (
                            <div className="block rounded h-px bg-gray-100" key={index}></div>
                          ) : (
                            <Link
                              key={`trans-menu-${index}`}
                              to={item.href}
                              className={`block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                                item.href,
                              )}`}
                            >
                              {item.label}
                            </Link>
                          )),
                      )}
                      <div
                        className="block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50"
                        onClick={handleLogout}
                      >
                        Sign Out
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

function mapStateToProps() {
  return {}
}

export const Header = connect(mapStateToProps, { signInRequest })(_Header)
