import type { InputType } from 'config'

export const ContractorNoSettingKey = 'CONTRACTOR_NO'

export const defaultInputs = (): Record<string, InputType> => ({
  firstName: {
    inputType: 'text',
    title: 'First Name',
    required: true,
  },
  lastName: {
    inputType: 'text',
    title: 'Last Name',
    required: true,
  },
  entityTitle: {
    inputType: 'text',
    title: 'Entity Title',
    required: true,
  },
  entityEmail: {
    inputType: 'text',
    type: 'email',
    title: 'Email',
    required: true,
  },
})
