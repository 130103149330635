import { ArrowDownTrayIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { addNewLoanInvoice, deleteLoanInvoice, getLoanInvoices, openS3Document, updateLoanInvoice } from 'services'
import { Button } from 'stories/components'
import { confirm, formatDate, formatTime, renderHeader, thousandSeperator } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AddInvoice } from './AddInvoice'
import type { IInvoice } from './types'

export function Invoice() {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<IInvoice[]>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [editingData, setEditingData] = useState<IInvoice | null>(null)
  const [filters, setFilters] = useState({
    orderBy: 'createdAt',
    orderDir: '-1',
  })

  useEffect(() => {
    setLoanNumber()
    refetch(filters)
  }, [])

  const totalAmount = useMemo(() => {
    return data.reduce((prev, cur) => prev + Number(cur.amount), 0)
  }, [data])

  const refetch = (newFilters: Record<string, any> | null = null) => {
    setLoading(true)
    getLoanInvoices(newFilters || filters)
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  const onAdd = () => {
    setEditingData(null)
    setIsOpen(true)
    setSelectedIndex(-1)
  }

  const onCloseModal = (isNeedRefetch: boolean) => {
    setIsOpen(false)
    setEditingData(null)
    if (isNeedRefetch) refetch()
  }

  const onEdit = (id: number, index: number) => {
    setEditingData(data[index])
    setIsOpen(true)
    setSelectedIndex(index)
  }

  const onSubmit = async (newData: Record<string, any>) => {
    if (selectedIndex == -1) {
      await addNewLoanInvoice(newData)
    } else {
      const item = data[selectedIndex]
      await updateLoanInvoice(item.id, newData)
    }
    setIsOpen(false)
    setSelectedIndex(-1)
    refetch()
  }

  const onDelete = async (id: number, index: number) => {
    const content = (
      <div className="mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        No. {index + 1}
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    await deleteLoanInvoice(id)
    refetch()
  }

  const onSort = (key: string, dir: number) => {
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = `${dir}`
    setFilters(newFilters)
    refetch(newFilters)
  }

  const sortableHeaders = [
    { title: 'Date', key: 'date' },
    { title: 'Payee', key: 'payee' },
    { title: 'No', key: 'no' },
    { title: 'Amount', key: 'amount' },
    { title: 'Is Paid', key: 'isPaid' },
    { title: 'Created At', key: 'createdAt' },
  ]

  return (
    <div className={`invoices-container`}>
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white`}>
          <LayoutLoading show={loading} />
          <div className="flex gap-4 my-2 justify-between items-center">
            <p>
              Total Amount: <span className="bold">${thousandSeperator(totalAmount)}</span>
            </p>
            <Button onClick={onAdd} className="px-[36px]">
              Add
            </Button>
          </div>
          <div className="parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  {sortableHeaders.map(({ title, key }, index) =>
                    renderHeader({
                      title,
                      sortable: true,
                      key,
                      sortOrder: filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                      index,
                      onSort: (key: string, dir: number) => onSort(key, dir),
                    }),
                  )}
                  <th scope="col" className="px-6 py-3">
                    Files
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-[14.5px] text-gray-900">
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={`border-b ${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-6 py-3">{index + 1}</td>
                      <td className="px-2 py-3">{formatDate(item.date)}</td>
                      <td className="px-2 py-3">{item.payee}</td>
                      <td className="px-2 py-3">{item.no}</td>
                      <td className="px-2 py-3">{thousandSeperator(item.amount)}</td>
                      <td className="px-2 py-3">{item.isPaid ? 'Paid' : 'Not Paid'}</td>
                      <td className="px-2 py-3">{formatTime(item.createdAt)}</td>
                      <td className="px-2 py-3">
                        {item.files.map((file) => (
                          <p
                            className="flex hover:underline items-center rounded text-shade-blue cursor-pointer"
                            onClick={() => openS3Document(file.fileKey)}
                          >
                            <span className="p-1">
                              <ArrowDownTrayIcon className="w-4 h-4" />
                            </span>
                            {file.name}
                          </p>
                        ))}
                      </td>
                      <td className="px-2 py-3">
                        <div className="flex gap-1">
                          <span
                            className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onEdit(item.id, index)}
                          >
                            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                          </span>
                          <span
                            className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onDelete(item.id, index)}
                          >
                            <TrashIcon className="w-4 h-4"></TrashIcon>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isOpen && <AddInvoice onUpdate={onSubmit} onClose={onCloseModal} item={editingData} index={selectedIndex} />}
    </div>
  )
}
