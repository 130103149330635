import { ChevronUpIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export const ScrollTop = () => {
  const [showBtn, setShowBtn] = useState(false)

  window.onscroll = function () {
    scrollFunction()
  }

  const scrollFunction = () => {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  const topFunction = () => {
    document.body.scrollTop = 300
    document.documentElement.scrollTop = 300
  }

  if (!showBtn) return null

  return (
    <div className="fixed right-[10px] bottom-[10px] z-20">
      <button
        className="p-3 ring-[1px] hover-shadow1 ring-shade-blue bg-shade-blue text-white border hover:bg-white border-shade-blue hover:text-shade-blue rounded flex items-center gap-2"
        onClick={topFunction}
      >
        <ChevronUpIcon className="w-5 h-5"></ChevronUpIcon>
      </button>
    </div>
  )
}
