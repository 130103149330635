import cloneDeep from 'clone-deep'
import { productTypeOptions, transactionTypeOptions } from 'config'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { syncLoanTermSheetTemp } from 'services'
import { Checkbox, Modal } from 'stories/components'

import { termSheetTitles } from '.'
import type { ILoanTermSheetTemp } from './constants'

interface IProps {
  item: { key: 'headerText' | 'exhibitA' | 'fields'; index: number }
  data: ILoanTermSheetTemp
  onClose: () => void
}

export const SyncTermSheetField = (props: IProps) => {
  const { item, data, onClose } = props

  const [isAll, setIsAll] = useState(false)
  const [selected, setSelected] = useState<any[]>([])
  const [isLoading, setLoading] = useState(false)

  let no = 0

  useEffect(() => {
    if (selected.length === 7) setIsAll(true)
    else setIsAll(false)
  }, [selected])

  const onSelectAll = () => {
    const newSelected: any[] = []

    if (!isAll)
      productTypeOptions.forEach((prod) => {
        transactionTypeOptions.forEach((trans) => {
          if (prod === data.productType && trans === data.transactionType) return

          newSelected.push({
            productType: prod,
            transactionType: trans,
          })
        })
      })

    setSelected(newSelected)
  }

  const onSelect = (productType: string, transactionType: string) => {
    const newSelected = cloneDeep(selected)
    const index = selected.findIndex(
      (item) => item.productType === productType && item.transactionType === transactionType,
    )

    if (index !== -1) newSelected.splice(index, 1)
    else
      newSelected.push({
        productType,
        transactionType,
      })

    setSelected(newSelected)
  }

  const onSubmit = async () => {
    const reqBody = {
      ...item,
      targets: selected,
    }

    setLoading(true)
    try {
      await syncLoanTermSheetTemp(data.id, reqBody)
      onClose()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const rederTable = useMemo(() => {
    return (
      <table className="w-full">
        <thead>
          <tr>
            <th className="p-2 border">No</th>
            <th className="p-2 border">Product Type</th>
            <th className="p-2 border">Transaction Type</th>
            <th className="p-2 border">
              <Checkbox title="All" id="all" checked={isAll} onChange={onSelectAll} />
            </th>
          </tr>
        </thead>
        <tbody>
          {productTypeOptions.map((prod, index) => {
            return (
              <Fragment key={`${prod}-${index}`}>
                {transactionTypeOptions.map((trans, idx) => {
                  if (data.productType === prod && data.transactionType === trans) return <></>

                  no++
                  const checked = selected.find((item) => item.productType === prod && item.transactionType === trans)

                  return (
                    <tr key={`${prod}-${index}-${idx}`}>
                      <td className="p-2 border text-center">{no}</td>
                      <td className="p-2 border">{prod}</td>
                      <td className="p-2 border">{trans}</td>
                      <td className="p-2 border flex justify-center">
                        <Checkbox
                          title=""
                          id={`${index}-${idx}`}
                          checked={!!checked}
                          onChange={() => onSelect(prod, trans)}
                          className="w-5"
                        />
                      </td>
                    </tr>
                  )
                })}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [selected, isAll, data])

  return (
    <Modal
      title={`Sychronize ${
        ['headerText', 'exhibitA'].includes(item.key) ? termSheetTitles[item.key] : data.fields[item.index].title
      }`}
      titleOkay="Sync"
      loading={isLoading}
      isOpen={true}
      onClose={onClose}
      onOk={onSubmit}
    >
      <div className="w-[580px]">
        <div className="flex gap-4 mb-4">
          <span className="font-bold text-base">{data.productType}</span> <pre>and</pre>
          <span className="font-bold text-base">{data.transactionType}</span>
          <span>{`=>`}</span>
        </div>

        {rederTable}
      </div>
    </Modal>
  )
}
