import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'
import { Modal, RadioGroup } from 'stories/components'
import { InputValidate } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    option: {
      inputType: 'radio',
      title: 'Generate options',
      options: {
        generate: 'Generate and Save to Document Storage',
        download: 'Just download document',
        email: 'Email to Borrower',
      },
      value: 'download',
    },
  }
}

interface ConfirmGenerateDialogProps {
  /**
   * Title
   */
  content?: string | JSX.Element
  /**
   * Okay button handler
   */
  onOk?: (arg: string) => void
  /**
   * Cancel button handler
   */
  onCancel?: Function
  /**
   * Is Open
   */
  isOpen?: boolean
  lastUpdatedAt: number
}

const ConfirmGenerateDialog = ({
  content = '',
  onOk = () => {},
  onCancel = () => {},
  isOpen: parentIsOpen = false,
  lastUpdatedAt,
}: ConfirmGenerateDialogProps) => {
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onConfirm = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    onOk(inputData.option)
    onCancel()
  }

  return (
    <Modal
      isOpen={parentIsOpen}
      lastUpdatedAt={lastUpdatedAt}
      title={content}
      titleOkay="Continue"
      disabled={!inputs.option.value}
      onClose={() => onCancel()}
      onOk={onConfirm}
    >
      <div className={`text-gray-600 text-md overflow-hidden min-w-[500px]`}>
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div key={index} className="input">
              <RadioGroup
                id="option"
                title={input.title}
                options={{
                  generate: 'Generate and Save to Document Storage',
                  download: 'Just download document',
                  email: 'Email to Borrower',
                }}
                error={input.error}
                value={input.value as string}
                required
                onChange={(value) => onChange('option', value)}
              />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

const ConfirmDialog = ({ show, proceed, promptation }: ReactConfirmProps | any) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  return (
    <ConfirmGenerateDialog
      isOpen={show}
      onOk={(result: string) => proceed(result as any)}
      onCancel={() => proceed(false as any)}
      lastUpdatedAt={lastUpdatedAt}
      content={promptation}
    />
  )
}

const confirmDlg = confirmable(ConfirmDialog)

const createConfirm = createConfirmation(confirmDlg)

export const confirmGenerate = (content: string | JSX.Element) => createConfirm({ promptation: content })
