import { ArrowRightIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PageTableSearch } from 'components/PageTableSearch'
import { appApiUrl, InputType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { sendContractorApplication } from 'services'
import { deleteBankerData, getBankerData, updateBankerData } from 'services/apis/admin'
import { ButtonGroup } from 'stories/components'

export const BankerSearch = (props: any) => {
  const { type } = props
  const location = useLocation()
  const urlQuery = new URLSearchParams(location.search)
  const [action, setAction] = useState('')
  const _tab = urlQuery.get('tab') as string
  const [tab, setTab] = useState(['appraiser', 'settlement', 'constractor'].includes(_tab) ? _tab : 'appraiser')
  const [tableData, setTableData] = useState<any>([])
  const [headers, setHeaders] = useState<any>([])
  const [inputs, setInputs] = useState<any>({})
  const [totalCount, setTotalCount] = useState(0)
  const [filterValue, setFilterValue] = useState<any>({
    appraiser: {
      query: '',
      pageNum: 0,
      orderBy: 'createdAt',
      orderDir: '-1',
    },
    settlement: {
      query: '',
      pageNum: 0,
      orderBy: 'createdAt',
      orderDir: '-1',
    },
    constractor: {
      query: '',
      pageNum: 0,
      orderBy: 'createdAt',
      orderDir: '-1',
      approved: '1',
    },
  })
  const isResModal = type == 'resources'

  const tabs: Record<string, string> = {
    appraiser: 'Appraiser',
    settlement: 'Settlement Agent',
    constractor: 'Contractor',
  }

  const filterInputs: Record<string, Record<string, InputType>> = {
    appraiser: {},
    settlement: {},
    constractor: {
      approved: {
        inputType: 'ButtonGroup',
        title: '',
        options: { '1': 'Completed', '0': 'In Progress' },
        visible: true,
      },
    },
  }

  const auth = useSelector((state: any) => state.auth)

  const navigate = useHistory()
  const { hasPermission } = usePermissions()
  const isAdmin = hasPermission(['MANAGE_ADMIN_TOOLS', 'MANAGE_APPRAISER_SETTLEMENT_CONTRACTOR'])

  const downloadLink = useMemo(() => {
    const filter: Record<string, string> = {
      table: tab,
      orderBy: filterValue[tab].orderBy,
      orderDir: filterValue[tab].orderDir,
    }
    if (tab == 'constractor') filter.approved = filterValue.constractor.approved

    const filterStr = Object.keys(filter)
      .map((key) => `${key}=${filter[key]}`)
      .join('&')
    return `${appApiUrl}/admin/downloadBankerData?token=${auth.token}&${filterStr}`
  }, [auth.token, filterValue, tab])

  const onChangeFilter = (key: string, value: any) => {
    let temp = cloneDeep(filterValue)
    temp[tab][key] = value
    setFilterValue(temp)
    getData(temp)
  }

  const onSort = (key: string, dir: number) => {
    let temp = cloneDeep(filterValue)
    temp[tab]['orderBy'] = key
    temp[tab]['orderDir'] = `${dir}`
    setFilterValue(temp)
    getData(temp)
  }

  const getData = async (filterData: any = null) => {
    setAction('loading')
    const filters = filterData ? filterData[tab] : filterValue[tab]
    const res = await getBankerData({
      table: tab,
      ...filters,
      count: 10,
    })
    if (res.success) {
      if (tab == 'constractor') {
        const headers = res.headers
        if (isAdmin) {
          headers[0].title = (
            <span>
              <div className="border-b w-fit mb-1 border-gray-300">Company</div>
              Loan Number
            </span>
          )
          headers[0].value = (row: Record<string, any>) => {
            return (
              <div>
                <p className="border-b w-full mb-1">{row.entityTitle}</p>
                {row.loanNumber ? (
                  <Link
                    to={`/contractor_application/${row.loanNumber}`}
                    className="font-bold text-shade-blue hover:underline cursor-pointer"
                  >
                    <div>{row.byteproFileName || row.loanNumber}</div>
                  </Link>
                ) : (
                  'N/A'
                )}
              </div>
            )
          }
        } else {
          headers[0].title = <span>Company</span>
          headers[0].value = (row: Record<string, any>) => {
            return <div>{row.entityTitle}</div>
          }
        }
        if (filters.approved == '0')
          headers[4].value = (row: Record<string, any>) => {
            return (
              <div>
                <p className="border-b w-full mb-1">{row.status}</p>
                {row.entityEmail ? (
                  <Link
                    to="#"
                    className="font-bold text-shade-blue hover:underline cursor-pointer"
                    onClick={async () => {
                      setAction('loading')
                      await sendContractorApplication(row.no, { copyURL: false })
                      setAction('')
                    }}
                  >
                    Send Application
                  </Link>
                ) : null}
              </div>
            )
          }
        setHeaders(headers)
        if (isResModal) {
          res.inputs.expireDate.visible = false
          res.inputs.status.visible = false
        }
      } else setHeaders(res.headers)
      setInputs(res.inputs)
      setTableData(res.data)
      setTotalCount(res.total)
    }
    setAction('')
  }

  useEffect(() => {
    getData()
  }, [tab])

  const onSubmitForm = async (data: any, id: number = 0) => {
    setAction('loading')
    const res = await updateBankerData({
      table: tab,
      data,
      id,
    })
    setAction('')
    if (res.success) {
      getData()
    }
    return true
  }

  const onRemove = async (id: number) => {
    setAction('loading')
    const res = await deleteBankerData({
      table: tab,
      id: id,
    })
    setAction('')
    if (res.success) {
      getData()
    }
    return true
  }

  const renderAdditionalAction = () => {
    if (tab != 'constractor') return null
    if (!isAdmin && filterValue[tab].approved == '1') return null
    return renderAdditionalActions
  }

  const renderAdditionalActions = (row: Record<string, any>) => {
    if (!isAdmin) {
      if (row.createdBy != auth.profile.email && row.loanNumber != 0) return null
      if (row.approveContractor) return null
    }

    return (
      <div
        className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded w-fit"
        onClick={() => navigate.push(`/contractor_application/${row.no}/${row.loanNumber}`)}
      >
        <ArrowRightIcon className="w-4 h-4" />
      </div>
    )
  }

  const isEditable = () => {
    if (!isResModal) return true
    if (tab != 'constractor') return false
    if (isAdmin) return true
    if (filterValue[tab].approved == '1') return false

    return (row: Record<string, any>) => row.createdBy == auth.profile.email || row.loanNumber == 0
  }

  const isDeletable = () => {
    if (!isResModal) return true
    if (tab != 'constractor') return false
    if (isAdmin) return true
    if (filterValue[tab].approved == '1') return false

    return (row: Record<string, any>) => row.createdBy == auth.profile.email
  }

  return (
    <div>
      {type !== 'resources' && (
        <h1 className="text-2xl font-bold flex items-center pb-5">Appraiser / Settlement Agent / Contractor</h1>
      )}
      <div className="mb-6">
        <ButtonGroup title={tabs} value={tab} onChange={(v) => setTab(v)} />
      </div>
      <div className="px-2">
        <PageTableSearch
          loading={action === 'loading'}
          key={tab}
          title={tabs[tab]}
          filterInputs={filterInputs[tab]}
          filterValue={filterValue[tab]}
          onChangeFilter={onChangeFilter}
          onSort={onSort}
          onRemove={onRemove}
          onSubmitForm={onSubmitForm}
          headers={headers}
          inputs={inputs}
          tableData={tableData}
          totalCount={totalCount}
          permissions={{
            add: !isResModal || tab == 'constractor',
            edit: isEditable(),
            delete: isDeletable(),
          }}
          downloadLink={downloadLink}
          cols={tab != 'constractor' ? 3 : 2}
          renderAdditionalActions={renderAdditionalAction()}
        />
      </div>
    </div>
  )
}
