import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes, assignedToAccountTypes } from 'components/Modals/CreateUser/config'
import { useEffect, useMemo, useState } from 'react'
import { getAdminEmails, getProjects, getTaskByUserId } from 'services'

import { TaskTable } from './taskTable'

export const UserTasks = ({ userId }: { userId: number }) => {
  const [isLoading, setLoading] = useState(false)
  const [projects, setProjects] = useState<Record<string, any>[]>([])
  const [data, setData] = useState<Record<string, any>[]>([])
  const [selectedProject, setSelectedProject] = useState(0)
  const [assignedToList, setAssignedToList] = useState<Record<string, string>>({})

  useEffect(() => {
    getAdminEmails().then((emails) => {
      const newOptions: Record<string, string> = {}
      assignedToAccountTypes.forEach((key) => (newOptions[key] = accountTypes[key]))
      emails.forEach((email) => (newOptions[email] = email))
      setAssignedToList(newOptions)
    })
  }, [])

  useEffect(() => {
    getProjects().then((data) => {
      setProjects(data)
      setSelectedProject(data[0]?.id)
    })

    filterTasks()
  }, [])

  const taskCounts = useMemo(() => {
    const counts: Record<number, number> = {}
    data.forEach((task) => {
      const projectId = task.project.id
      if (!counts[projectId]) counts[projectId] = 1
      else counts[projectId] += 1
    })
    return counts
  }, [data])

  const filterTasks = async () => {
    setLoading(true)
    const { data } = await getTaskByUserId(userId, {
      taskType: 'active',
      query: '',
      assignedTo: '',
      orderBy: 'createdAt',
      orderDir: '-1',
      count: 50,
    })
    setLoading(false)
    setData(data)
  }

  return (
    <div>
      <LayoutLoading show={isLoading} />
      <div className="flex divide-x text-sm mb-2">
        {projects.map((v, index) => (
          <span
            key={v.name}
            className={`cursor-pointer px-2 py-1 rounded-md ${v.id == selectedProject && 'bg-gray-200'}`}
            onClick={() => setSelectedProject(v.id)}
          >
            {index + 1}. {v.name} ({taskCounts[v.id] || 0})
          </span>
        ))}
      </div>
      <TaskTable
        isLoading={isLoading}
        queryTimes={0}
        assignedToList={assignedToList}
        project={projects.find((v) => v.id == selectedProject) || {}}
        hasActions={false}
        onSelectTaskStatus={() => {}}
        onCheck={() => {}}
        onEdit={() => {}}
        onTrash={() => {}}
        onRecoverDelete={() => {}}
        filterTasks={filterTasks}
        tasks={data.filter((task) => task.project.id == selectedProject)}
      />
    </div>
  )
}
