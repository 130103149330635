import { CheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'

type Function = () => void

interface PromptModalProps {
  /**
   * Title
   */
  title?: string | JSX.Element
  /**
   * Okay button handler
   */
  onOk?: (arg: string) => void
  /**
   * Cancel button handler
   */
  onCancel?: Function
  /**
   * Is Open
   */
  isOpen?: boolean
  lastUpdatedAt: number
  options?: Record<string, any>
  selected?: number
}

/**
 * Primary UI component for user interaction
 */
export const ConfirmOptionsModal = ({
  title = '',
  onOk = () => {},
  onCancel = () => {},
  isOpen: parentIsOpen = false,
  lastUpdatedAt,
  options = {},
  selected,
}: PromptModalProps) => {
  const [showModal, setShowModal] = useState(false)
  const [value, setValue] = useState<any>()
  const [selectedIndex, setSelectedIndex] = useState<Number>(-1)

  useEffect(() => {
    setShowModal(parentIsOpen)
    if (selected != null) {
      setSelectedIndex(selected)
      setValue(options[selected]?.value)
    }
  }, [lastUpdatedAt])

  if (!showModal) return null

  return (
    <>
      <Modal
        title={title}
        titleOkay="Okay"
        loading={false}
        isOpen={parentIsOpen}
        lastUpdatedAt={lastUpdatedAt}
        onClose={onCancel}
        onOk={() => onOk(value as any)}
        childLevel={2}
        disabled={selectedIndex == -1}
      >
        <div className="min-w-[32rem]">
          {options.map((item: any, index: number) => (
            <div
              className={`border rounded-md overflow-hidden p-4 ${
                index != options.length - 1 && 'mb-4'
              } cursor-pointer hover:border-shade-blue ${selectedIndex == index ? 'border-shade-blue' : ''}`}
              onClick={() => {
                setValue(item.value), setSelectedIndex(index)
              }}
              key={index}
            >
              <div className="flex justify-between items-center">
                <p className="font-semibold">
                  {index + 1}. {item?.name}
                </p>
                {selectedIndex == index ? <CheckIcon className="w-5 h-5 text-shade-blue" /> : null}
              </div>
              <p className="text-xs mb-1 underline">{item?.desc}</p>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}
