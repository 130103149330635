import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { COMPANY_NAME_FC } from 'config'
import moment from 'moment-timezone'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { downloadFile, getCreditAuthorizationPdf, uploadMemberSignApplicationPdf } from 'services'
import { Button, Modal, Toggle } from 'stories/components'

import type { BorrowerAdditionalMember } from './types'

export const ConfirmSignDialog = ({ onClose, data }: { onClose: Function; data: BorrowerAdditionalMember }) => {
  const [isReviewed, setReviewed] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onDownloadPdf = async () => {
    const rlt: Blob = await getCreditAuthorizationPdf(data.id)
    downloadFile(
      `${COMPANY_NAME_FC} Credit Authorization Form (${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`,
      rlt,
    )
  }

  const onConfirmSign = async () => {
    setLoading(true)
    const signedData = await uploadMemberSignApplicationPdf(data.id)
    toast('Signed application successfully. You can download the signed document', { type: 'success' })
    setLoading(false)
    onClose({
      ...data,
      ...signedData,
    })
  }

  return (
    <Modal
      isOpen
      title="Confirm and Sign Credit Authorization Form"
      titleOkay="Confirm and Sign"
      disabled={!isReviewed}
      loading={isLoading}
      onClose={() => onClose()}
      onOk={onConfirmSign}
    >
      <div className={`text-gray-600 text-md overflow-hidden w-[750px`}>
        <div className="text-sm bg-gray-100 px-2 pb-4 rounded">
          <Button link onClick={onDownloadPdf}>
            <div className="flex items-center underline text-gray-900 hover:text-shade-blue">
              - View Unsigned Credit Form PDF{' '}
              <span className="ml-2">
                <ArrowDownTrayIcon className="w-4 h-4" />
              </span>
            </div>
          </Button>
          <Toggle
            id="reviewed"
            title="I have reviewed my credit form"
            onChange={(value) => setReviewed(value)}
            value={isReviewed}
          />
        </div>
      </div>
    </Modal>
  )
}
