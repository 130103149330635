import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { logout } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { INVALID_REQUEST } from 'config'
import jwt from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { openS3Document, validateMemberSignApplicationSignature } from 'services'
import { Button } from 'stories/components'
import { formatTime } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { ConfirmSignDialog } from './ConfirmSignDialog'
import type { BorrowerAdditionalMember } from './types'

export const CustomMemberSignApplication = () => {
  const urlParams: any = useParams()
  const { loanNumber, urlSignature, token } = urlParams
  const registeredToken = useSelector((state: any) => state.auth.token)
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)
  const [member, setMember] = useState<null | BorrowerAdditionalMember>(null)
  const [isShowConfirmSign, setShowConfirmSign] = useState(false)

  const dispatch = useDispatch()
  const navigate = useHistory()

  useEffect(() => {
    const payload: any = jwt(token)
    setLoading(true)
    setValidated(false)
    setLoanNumber()
    validateMemberSignApplicationSignature({
      loanNumber,
      urlSignature,
      token,
      id: payload.id,
    })
      .then(async ({ success, data }) => {
        if (!success) {
          toast(INVALID_REQUEST, { type: 'error' })
          navigate.push('/')
          dispatch(logout())
          return
        }
        setValidated(true)
        setMember(data)
      })
      .finally(() => setLoading(false))
  }, [token, loanNumber, urlSignature])

  useEffect(() => {
    if (!registeredToken || !validated) return
  }, [registeredToken, validated])

  const onSignApplication = async () => {
    setShowConfirmSign(true)
  }

  const onDownloadSignedPdf = () => {
    if (!member || !member.signedPdfKey) return
    openS3Document(member.signedPdfKey)
  }

  const onClose = (newData?: BorrowerAdditionalMember) => {
    setShowConfirmSign(false)
    if (newData) setMember(newData)
  }

  if (loading || !member) return <LayoutLoading show={loading} />

  const keys: any = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    dob: 'DOB(MM/DD/YYYY)',
    ssn: 'Social Security Number',
    presentAddress: 'Present Address',
  }

  return (
    <TemporaryLayout noOverview={true}>
      <div className="member-sign-container">
        <div className="px-2 pt-6">
          <Overview title="Member Sign Application" hasBackButton={false} />

          <div className="Overview-container">
            <div className="shadow1 mb-6 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w-full">
              <div className="flex flex-wrap justify-between">
                <h1 className="text-xl font-bold flex items-center mb-2">
                  <span>Member Details</span>
                </h1>
              </div>
              <div className="mt-1 flex flex-wrap mb-5">
                <div className="text-[14px] text-left">
                  {Object.keys(keys).map((key: string, index: number) => {
                    return (
                      <div className="flex mb-2" key={index}>
                        <div className="w-48">{keys[key]}:</div>
                        <div className="font-bold ml-2">{member[key as keyof BorrowerAdditionalMember]}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
              {member && member.signedPdfKey ? (
                <>
                  <Button link onClick={onDownloadSignedPdf}>
                    <div className="flex items-center underline text-gray-900 hover:text-shade-blue">
                      View Signed Credit Form PDF{' '}
                      <span className="ml-2">
                        <ArrowDownTrayIcon className="w-4 h-4" />
                      </span>
                    </div>
                  </Button>

                  <div
                    className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-[14px]"
                    role="alert"
                  >
                    <div className="font-variation-settings-600">
                      Sign request has been sent by <span className="underline">{member.signRequestedBy}</span> and
                      signed by <span className="underline">{member.signedBy}</span> at {formatTime(member.signedDate)}.
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Button onClick={onSignApplication}>Click Here to Sign Application</Button>
                  <div
                    className="bg-yellow-100 border mt-2 border-yellow-400 text-yellow-700 px-4 py-3 rounded relative text-[14px] max-w-2xl"
                    role="alert"
                  >
                    <div className="font-variation-settings-600">
                      Sign request has been sent by <span className="underline">{member.signRequestedBy}</span> on{' '}
                      {formatTime(member.signRequestedAt)} and <span className="font-bold">Pending</span> now.
                    </div>
                  </div>
                </>
              )}
            </div>
            {isShowConfirmSign ? <ConfirmSignDialog onClose={onClose} data={member} /> : null}
          </div>
        </div>
      </div>
    </TemporaryLayout>
  )
}
