import { useState } from 'react'
import { Modal, Toggle } from 'stories/components'

export function SwapBorrowerDialog(props: { onClose: Function; onSubmit: Function }) {
  const [isReset, setReset] = useState(false)
  const [loading, setLoading] = useState(false)

  const onClose = () => {
    props.onClose()
  }

  const onSwap = async () => {
    setLoading(true)
    props.onSubmit(isReset)
    setLoading(false)
    props.onClose()
  }

  return (
    <Modal
      title="Confirm"
      titleOkay="Swap"
      titleCancel="Close"
      loading={loading}
      isOpen
      onClose={onClose}
      onOk={onSwap}
    >
      <div className="">
        <div className="min-w-[400px]">
          <div className="mb-4">Do you want to swap Borrower and Co-Borrower?</div>
          <Toggle id="reset" value={isReset} onChange={setReset} title="Reset Asset Information" />
          <div
            className="p-3 mt-4 text-[16px] bg-yellow-100 border border-yellow-400 text-yellow-700 rounded-lg"
            role="alert"
          >
            <div>
              Asset Information will be <b>{isReset ? 'reset' : 'kept'}</b> after swap Borrowers.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
