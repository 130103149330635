import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { gethud1PageData, posthud1Page1Data } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, Checkbox, RadioGroup } from 'stories/components'
import { OverrideCaclModal } from 'stories/components/OverrideCalcModal/OverrideCalcModal'
import { calculateDueDate, formatDate, getPrice1or2decimal, openAuditLog } from 'utils'
import { confirmOptions } from 'utils/modals/confirmOptions'

import { HUD1InputType } from '../HUD1Page1/constant'

export function HUD1Page3() {
  const [action, setAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [inputs, setInputs] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [IDs, setIDs] = useState<Array<string>>([])
  const [ovData, setOVData] = useState<any>({})

  const initData = async () => {
    setAction('')
    setIsLoading(true)
    const res = await gethud1PageData('page3')
    if (res.success) {
      setInputs(res.inputs)
      setData(res.data)
      setOrgData(res.data)
      setIDs(res.IDs)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onChange = (key: string, value: string, type?: string) => {
    let temp = cloneDeep(data)
    if (type == 'date') temp[key] = value
    else temp[key] = getPrice1or2decimal(value).replaceAll(',', '')
    setData(temp)
    if (!changed) setChanged(true)
  }

  const calculateValue = (key: string | undefined) => {
    let CALCULATED_VALUE: any = undefined
    switch (key) {
      case 'Loan.Term':
        CALCULATED_VALUE = data['Loan.Term'] / 12
        break
      case 'initialPI&MI':
        CALCULATED_VALUE = data['Loan._PI'] + data['PrepaidItems.1.Payment']
        break
      default:
    }
    return CALCULATED_VALUE
  }

  const onCheckClick = async (key: string, type: string) => {
    let options
    if (type == 'check')
      options = [
        { name: 'Use the calculated value', value: 0 },
        { name: 'Force the value to be un-checked', value: 2 },
        { name: 'Force the value to be checked', value: 1 },
      ]
    else if (type == 'radio')
      options = [
        { name: 'Use the calculated value', value: 0 },
        { name: `Force the value to 'No'`, value: 2 },
        { name: `Force the value to be 'Yes'`, value: 1 },
      ]
    const selectedValue: any = await confirmOptions('Override Calculated Value', options)
    if (selectedValue === false) return
    onChange(key, selectedValue)
  }

  const onSaveChanges = async () => {
    setChanged(false)
    let json: any = {}
    Object.keys(data).map((key) => {
      if (JSON.stringify(data[key]) != JSON.stringify(orgData[key])) json[key] = data[key]
    })
    if (Object.keys(json).length > 0) {
      json.IDs = IDs
      setAction('saveChanges')
      await posthud1Page1Data('page1', json)
      setAction('')
      setOrgData(data)
    }
  }

  const renderHUD1Input = (input: any) => {
    const { type, title, titleBold, valueKey, overrideKey, canOverride, disabled, calculatedValue, inputType } = input
    let VALUE = getPrice1or2decimal(data[valueKey])
    const OVVALUE = input.inputType == 'date' ? data[overrideKey] : getPrice1or2decimal(data[overrideKey])
    let CALCULATED_VALUE: any = getPrice1or2decimal(calculateValue(valueKey))
    VALUE = CALCULATED_VALUE || VALUE
    if (type === HUD1InputType.onlyTitle) {
      // only title
      return <div className={`flex gap-2 mt-4 ${titleBold ? 'font-semibold' : ''}`}>{title}</div>
    }
    if (type === HUD1InputType.titleInputValue) {
      // title + input value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex-1">
            <div className="flex gap-2">
              <div className="cursor-pointer hover:underline" onClick={() => showHistory([valueKey])}>
                {title}
              </div>
            </div>
          </div>
          <div className="flex-end">
            <input
              className="px-2 text-right border w-[120px]"
              value={VALUE}
              onChange={(e) => onChange(valueKey, e.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.titleCalculatedValue) {
      // title + calculated value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex-1 flex justify-between items-center">
            <div className={`flex gap-2 ${titleBold ? 'font-semibold' : ''}`}>
              <div className={`cursor-pointer hover:underline`} onClick={() => showHistory([valueKey, overrideKey])}>
                {title}
              </div>
            </div>
            {canOverride && (
              <span
                className="cursor-pointer hover:text-shade-blue"
                onClick={() =>
                  setOVData({
                    title,
                    overrideKey,
                    calcValue: CALCULATED_VALUE || calculatedValue || data[valueKey],
                    ovValue: data[overrideKey] || '',
                  })
                }
              >
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
            )}
          </div>
          <div className="">
            <input
              disabled={true}
              className={`px-2 text-right border cursor-not-allowed w-[120px] ${OVVALUE ? 'bg-red-100' : ''}`}
              value={OVVALUE || VALUE}
            />
          </div>
        </div>
      )
    }

    if (type === HUD1InputType.checkBox) {
      return (
        <Checkbox
          id={`check-${title}`}
          checked={data[overrideKey] < 2 || !data[overrideKey]}
          title={title}
          onChange={() => onCheckClick(overrideKey, 'check')}
          className={`w-[unset] ${data[overrideKey] > 0 && 'bg-red-200 px-2'}`}
        ></Checkbox>
      )
    }
    if (type === HUD1InputType.calculatedInput) {
      return (
        <>
          {canOverride && (
            <span
              className="cursor-pointer hover:text-shade-blue"
              onClick={() =>
                setOVData({
                  title,
                  overrideKey,
                  calcValue: CALCULATED_VALUE || data[valueKey],
                  ovValue: data[overrideKey] || '',
                  type: inputType,
                })
              }
            >
              <span
                className={`px-2 text-right border cursor-not-allowed inline-block w-[${
                  inputType == 'date' ? '120px' : '80px'
                }] mt-2 ${OVVALUE ? 'bg-red-100' : ''}`}
              >
                {OVVALUE || VALUE}
              </span>
            </span>
          )}
        </>
      )
    }
    if (type === HUD1InputType.radioGroup) {
      return (
        <RadioGroup
          id={`option-${title}`}
          name={`option-${title}`}
          title={title}
          options={{
            no: 'No',
            yes: 'Yes',
          }}
          value={data[overrideKey] == 1 ? 'yes' : 'no'}
          onClick={() => {
            if (!disabled) onCheckClick(overrideKey, 'radio')
          }}
          className={`${data[overrideKey] > 0 && 'bg-red-200 px-2'}}`}
          disabled={disabled}
        />
      )
    }
    if (type == HUD1InputType.selectBox) {
      return (
        <select
          id="select"
          className="mt-2 border-gray-200 rounded-md"
          onChange={(event) => onChange(valueKey, event.target.value)}
        >
          <option value="1" selected={data[valueKey] == 1}>
            Yes(May Have)
          </option>
          <option value="2" selected={data[valueKey] == 2}>
            Yes
          </option>
          <option value="3" selected={data[valueKey] == 3}>
            No
          </option>
        </select>
      )
    }
  }

  const renderSectionHeader = (title: string) => {
    return <p className="bg-blue-100 p-2">{title}</p>
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onChange(ovData.overrideKey, data.ovValue, data.type)
    setOVData({})
  }

  const showHistory = async (keys: Array<string>) => {
    const options = {
      table: 'HUD1',
      field: keys.join(', '),
      keys: {
        field: keys,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    keys = keys.filter((key) => key)
    openAuditLog(options)
  }

  return (
    <div className="HUD1Page3-container relative">
      <LayoutLoading show={action !== '' || isLoading} />
      <Prompt
        when={changed}
        message={`You've made some changes on HUD1 Page3!\nAre you sure want to leave without Saving?`}
      />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        HUD1 Page3
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="grid gap-4 md:grid-cols-4 grid-cols-1 space-y-2">
        <div className="md:col-span-4">{renderSectionHeader('Loan Terms')}</div>
        {inputs['baseSection']?.map((item: any) => {
          return (
            <div className="md:col-span-2" key={item.title}>
              {renderHUD1Input(item)}
            </div>
          )
        })}
        <div className="md:col-span-4 grid gap-4 md:grid-cols-4 grid-cols-1 border-[1px] border-solid border-gray-200 rounded-md p-2">
          <div>Includes</div>
          {inputs['checkBoxes']?.map((item: any) => {
            return (
              <div className="md:flex justify-end" key={item.title}>
                {renderHUD1Input(item)}
              </div>
            )
          })}
        </div>
        {inputs['interestRateRise']?.map((item: any) => {
          return <div className="md:col-span-2">{renderHUD1Input(item)}</div>
        })}
        <div className="md:col-span-2">
          {inputs['interestRateRiseDesc'] && (
            <p className="leading-7">
              It can rise to a max of {renderHUD1Input(inputs['interestRateRiseDesc'][0])}%. The 1st change will be on{' '}
              {renderHUD1Input(inputs['interestRateRiseDesc'][1])} and can change again every{' '}
              {renderHUD1Input(inputs['interestRateRiseDesc'][2])} after {data['Loan.GFEIntRateFirstChangeDateDescOV']}.
              Every change date your interest rate can increase or decrease by{' '}
              {renderHUD1Input(inputs['interestRateRiseDesc'][3])}%. Your interest rate will never be lower than{' '}
              {renderHUD1Input(inputs['interestRateRiseDesc'][4])}% or higher than {data['Loan.GFEIntRateMaxOV']}%
            </p>
          )}
        </div>
        {inputs['balanceRise']?.map((item: any) => {
          return <div className="md:col-span-2">{renderHUD1Input(item)}</div>
        })}
        <div className="md:col-span-2">
          {inputs['balanceRiseDesc'] && (
            <p className="leading-7">It can rise to a max of ${renderHUD1Input(inputs['balanceRiseDesc'][0])}</p>
          )}
        </div>
        {inputs['pmRise']?.map((item: any) => {
          return <div className="md:col-span-2">{renderHUD1Input(item)}</div>
        })}
        <div className="md:col-span-2">
          {inputs['pmDesc'] && (
            <p className="leading-7">
              The 1st increase can be on {renderHUD1Input(inputs['pmDesc'][0])} and the monthly amount owed can rise to
              $ {renderHUD1Input(inputs['pmDesc'][1])}. The maximum it can ever rise to is ${' '}
              {renderHUD1Input(inputs['pmDesc'][2])}
            </p>
          )}
        </div>
        {inputs['prepaymentPenalty']?.map((item: any) => {
          return <div className="md:col-span-2">{renderHUD1Input(item)}</div>
        })}
        <div className="md:col-span-2">
          {inputs['prepaymentPenaltyDesc'] && (
            <>
              {renderHUD1Input(inputs['prepaymentPenaltyDesc'][0])}
              <p className="leading-7">
                From the TIL: If you pay off your loan early, you {renderHUD1Input(inputs['prepaymentPenaltyDesc'][1])}{' '}
                have to pay a penalty
              </p>
            </>
          )}
        </div>
        {inputs['ballonPayment']?.map((item: any) => {
          return <div className="md:col-span-2">{renderHUD1Input(item)}</div>
        })}
        <div className="md:col-span-2">
          {inputs['ballonPaymentDesc'] && (
            <p className="leading-7">
              ballon of {renderHUD1Input(inputs['ballonPaymentDesc'][0])} due in{' '}
              <span className="inline-block w-[60px] text-right">{data['balloonTerm']}</span> months on{' '}
              {formatDate(calculateDueDate(data['closingDate'], data['balloonTerm']))}
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Button disabled={!changed} onClick={onSaveChanges} loading={action === 'saveChanges'}>
          Save Changes
        </Button>
      </div>
      {Object.keys(ovData).length > 0 && (
        <OverrideCaclModal
          key={ovData.overrideKey}
          title={ovData.title}
          calcValue={ovData.calcValue}
          ovValue={ovData.ovValue}
          onClose={closeOVModal}
          type={ovData.type}
        />
      )}
      <SaveChanges show={changed} label="Save Changes" onSave={onSaveChanges} />
    </div>
  )
}
