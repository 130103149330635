import { ArrowDownTrayIcon, ArrowTopRightOnSquareIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { SendEmailDialog } from 'components/SendEmailDialog'
import { COMPANY_NAME_FC, ServicingEmail } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { downloadServicingDocs2, getServicingDocumentData, sendServicingDocumentOnEmail } from 'services'
import Api from 'services/api'
import { PlainTable } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

interface ServicingDoc {
  name: string
  placeholders: string[]
  email: { subject: string; comment: string }
}

export function ServicingDocument() {
  const { borrower } = useSelector((state: any) => ({
    borrower: state.borrower.borrower,
  }))
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({})
  const [documents, setDocuments] = useState<ServicingDoc[]>([])
  const [histories, setHistories] = useState<any[]>([])
  const [placeholders, setPlaceholders] = useState<any>({})
  const [docRlt, setDocRlt] = useState<any>({})
  const [currentDocument, setCurrentDocument] = useState<ServicingDoc | null>(null)
  const [missingPlaceholders, setMissingPlaceholders] = useState<any>([])

  const loanNumber = Api.getLoanNumber()

  const init = async () => {
    setLoading(true)

    const res = await getServicingDocumentData()
    if (res.success) {
      setData(res.data)
      setDocuments(res.documents)
      setPlaceholders(res.placeholders)
      setDocRlt(res.docRlt)
      setHistories(res.histories)
    }
    setLoading(false)
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        init()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    init()
  }, [])

  const contentFragment = useMemo(() => {
    const keys: any = {
      Lender: 'Party -> Lender',
      Servicer: 'Party -> Servicer',
      ServicingNote: 'Servicing Note',
    }
    return (
      <div className="">
        {Object.keys(keys).map((key, index) => {
          let url = ''
          if (['Lender', 'Servicer'].includes(key)) {
            url = `/parties/${loanNumber}?edit=${key}`
          }
          if ('ServicingNote' === key) {
            url = `/servicing/${loanNumber}?menu=servicingNotes`
          }
          return (
            <div className="mb-4" key={index}>
              <div className="border-b pl-2 pb-1">
                <Link
                  className="flex items-center gap-1 hover:text-shade-blue cursor-pointer w-fit"
                  to={url}
                  target="_blank"
                >
                  <div>
                    {index + 1}. {keys[key]}
                  </div>
                  <div>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                  </div>
                </Link>
              </div>
              <div className="flex flex-wrap px-2 gap-1">
                {data[key]?.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <RenderInput
                        input={{
                          title: item[0],
                          inputType: 'text',
                          value: item[1] || '',
                          readOnly: true,
                        }}
                        Key={`${index}`}
                        onChange={() => {}}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }, [data])

  const checkPlaceholders = (doc: ServicingDoc) => {
    let errors: any = []
    doc.placeholders.map((key) => {
      if (docRlt[key]?.length > 0) {
      } else {
        errors.push(placeholders[key] || key)
      }
    })
    errors = errors.sort()
    setMissingPlaceholders(errors)
    return true
  }

  const onDownload = (doc: ServicingDoc) => {
    // if (!checkPlaceholders(doc)) return
    setLoading(true)
    downloadServicingDocs2(doc.name).finally(() => setLoading(false))
  }

  const onSendToBorrower = (doc: ServicingDoc) => {
    if (!checkPlaceholders(doc)) return
    setCurrentDocument(doc)
  }

  const onSendEmail = (emailData: Record<string, string>, data: Record<string, any>) => {
    if (!currentDocument) return
    return sendServicingDocumentOnEmail(currentDocument.name, emailData, data).then(() =>
      toast('Document is sent on email successfully.', { type: 'info' }),
    )
  }

  return (
    <div className="servicing-document-container">
      <div className="relative max-w-screen-2xl">
        <LayoutLoading show={loading} />
        <div className="overflow-auto mb-6">
          <table className="text-[15px] w-full">
            <thead>
              <tr>
                <th className="border p-2">No</th>
                <th className="border p-2">Document Name</th>
                <th className="border p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((item, index) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                    <td className="border p-2">{index + 1}</td>
                    <td className="border p-2">{item.name}</td>
                    <td className="border px-2 py-3">
                      <div className="flex flex-wrap gap-4">
                        <div
                          className="flex items-center gap-1 cursor-pointer hover:underline hover:text-shade-blue"
                          onClick={() => onDownload(item)}
                        >
                          Download
                          <ArrowDownTrayIcon className="w-4 h-3"></ArrowDownTrayIcon>
                        </div>
                        <div
                          className="flex items-center gap-1 cursor-pointer hover:underline hover:text-shade-blue"
                          onClick={() => onSendToBorrower(item)}
                        >
                          Send to Borrower
                          <PaperAirplaneIcon className="w-4 h-3"></PaperAirplaneIcon>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="">{contentFragment}</div>
        <div className="mb-4">
          <div className="border-b pl-2 pb-1">
            <div>4. History</div>
          </div>
          <PlainTable header={['No', 'Document', 'Emails', 'Date', 'By']} tdClass="px-4 py-2" data={histories} />
        </div>
        {currentDocument && (
          <SendEmailDialog
            title={`Send "${currentDocument.name}" Document`}
            data={[
              { party: 'Borrower', email: borrower.email },
              { party: `${COMPANY_NAME_FC} Servicing`, email: ServicingEmail },
            ]}
            emailData={currentDocument.email}
            defaultValues={{
              [borrower.email]: 'to',
              [ServicingEmail]: 'cc',
            }}
            onSubmit={onSendEmail}
            onClose={() => setCurrentDocument(null)}
            errors={missingPlaceholders}
          />
        )}
      </div>
    </div>
  )
}
