import { useEffect, useState } from 'react'
import { getLoanAssetInformationVerificationEmailLogs } from 'services'
import { Modal, PlainTable } from 'stories/components'
import { formatTime } from 'utils'

interface ISendVerificationEmailHistory {
  createdBy: string
  createdAt: number
}

export const SendVerificationEmailModal = (props: { onClose: Function; onSubmit: Function }) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<ISendVerificationEmailHistory[]>([])

  useEffect(() => {
    getLoanAssetInformationVerificationEmailLogs()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }, [])

  return (
    <Modal
      title="Confirm to Send Verification Email"
      titleOkay="Send Verification Email"
      titleCancel="Close"
      loading={isLoading}
      isOpen
      onClose={() => props.onClose()}
      onOk={() => {
        setLoading(true)
        props.onSubmit()
      }}
    >
      <div className="w-196">
        <p className="font-bold mb-2">Verification Email Sent Histories</p>
        <PlainTable
          classNames={Array.from(Array(8).keys()).map(() => '!px-4')}
          tdClass={`text-sm px-4 py-2`}
          header={['No', 'Send By', 'Created At'].filter((v) => !!v)}
          data={data.map((item, index) => {
            return [index + 1, item.createdBy, formatTime(item.createdAt)].filter((v) => v !== null) as any
          })}
        />
      </div>
    </Modal>
  )
}
