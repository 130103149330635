import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

import type { IDscrFirstTimeInvestorValueLeverageLimit } from '../../interface'
import { convertNullToBlankValueToDecimal } from '../../logic'

interface IProps {
  title: string
  options: any
  data: IDscrFirstTimeInvestorValueLeverageLimit[]
}

export const DscrFirstTimeInvestorValueLeverageLimits = (props: IProps) => {
  const { title, options, data } = props

  return (
    <div className="h-full">
      {!!data.length ? (
        <table className="text-sm w-full rounded-lg shadow text-center">
          <thead className="bg-gray-100">
            <tr>
              <th rowSpan={2} className="p-1 border">
                {title}
              </th>
              <th rowSpan={2} className="p-1 border">
                Loan Purpose
              </th>
              <th colSpan={2} className="p-1 border">
                Loan Amount
              </th>
              <th rowSpan={2} className="p-1 border w-[90px]">
                Min DSCR
              </th>
              <th rowSpan={2} className="p-1 border w-[100px]">
                Min Months Reserve
              </th>
              <th colSpan={2} className="p-1 border">
                LTV
              </th>
              <th rowSpan={2} className="p-1 border w-[90px]">
                Max CLTV
              </th>
            </tr>
            <tr>
              <th className="p-1 border w-[120px]">From</th>
              <th className="p-1 border w-[120px]">To</th>
              <th className="p-1 border w-[65px]">Min</th>
              <th className="p-1 border w-[65px]">Max</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td rowSpan={3} className="px-3 py-1 border">
                      {options[item.value] || item.value}
                    </td>
                    <td className="border px-3 py-1">Purchase</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minDSCR.purchase}</td>
                    <td className="border px-3 py-1">{item.minMonthsReserve.purchase}</td>
                    <td className="border px-3 py-1">{item.limit.purchase.ltv.min}</td>
                    <td className="border px-3 py-1">{item.limit.purchase.ltv.max}</td>
                    <td className="border px-3 py-1">{item.limit.purchase.cltv}</td>
                  </tr>

                  <tr>
                    <td className="border px-3 py-1">No-Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minDSCR.nocashout}</td>
                    <td className="border px-3 py-1">{item.minMonthsReserve.nocashout}</td>
                    <td className="border px-3 py-1">{item.limit.nocashout.ltv.min}</td>
                    <td className="border px-3 py-1">{item.limit.nocashout.ltv.max}</td>
                    <td className="border px-3 py-1">{item.limit.nocashout.cltv}</td>
                  </tr>

                  <tr>
                    <td className="border px-3 py-1">Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minDSCR.cashout}</td>
                    <td className="border px-3 py-1">{item.minMonthsReserve.cashout}</td>
                    <td className="border px-3 py-1">{item.limit.cashout.ltv.min}</td>
                    <td className="border px-3 py-1">{item.limit.cashout.ltv.max}</td>
                    <td className="border px-3 py-1">{item.limit.cashout.cltv}</td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )}
    </div>
  )
}
