import { ArrowLeftCircleIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import { fieldsByTransactionTypeAndProductTypeConstant } from 'config'
import {
  calculateLoanLimits,
  calculateLTV,
  estimatedClosingAmountBorrower,
  isNonDSCRProduct,
} from 'pages/LoanStructure/Logic'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { loanTypeMap } from 'reducers/loanDetail.reducer'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { getLoanTerm, getPrice3decimal } from 'utils'
import { getInterestOrNot } from 'utils/getInterestOrNot'

export const LoanDetailsSlide = () => {
  const [isShowSlide, setShowSlide] = useState(false)

  const { borrower, loan, loanDetail } = useSelector((state: any) => ({
    borrower: state.borrower,
    loan: state.loan,
    loanDetail: state.loanDetail,
  }))

  const data = useMemo(() => {
    const pitis = combinedMonthlyExpense(loan, loanDetail.rateData, true)
    const ltvs = calculateLoanLimits(loan)
    const { value: cashToCloseBorrower } = estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings)

    let rlt: any = {
      'Borrower Info': {
        'First Name': borrower.borrower.firstName,
        'Last Name': borrower.borrower.lastName,
        'Entity Name': borrower.borrower.hasEntityTitle ? borrower.borrower.entityTitle : 'N/A',
        'Social Security Number': borrower.borrower.ssn,
        'Date of Birth': borrower.borrower.dob,
        'Non-Person Entity': borrower.borrower.hasEntityTitle ? 'Yes' : 'No',
        Street: borrower.borrower.presentAddress,
        Citizenship: loan.residency,
        'First Time Home Buyer': loan.firstTimeHomeBuyer ? 'Yes' : 'No',
        'Marital Status': borrower.borrower.maritalStatus,
        'Work Phone': borrower.borrower.phone,
        Email: borrower.borrower.email,
      },
      'Property Info': {
        'Property Address': loan.subjectPropertyAddress,
        'Property Type': loan.propertyType,
        'Number of Units': loan.numberOfUnits,
      },
      'Loan Property Info': {
        'Occupancy Type': loan.proposedOccupancy,
        'Loan Purpose': loan.transactionType,
        'Loan Program Name': loanDetail.rateData?.program,
        'Mortgage Type': loan.mortgageLates,
        'Amortization Type': loan.amortizationType,
        'Loan Term': `${getLoanTerm(loanDetail.rateData?.type, loan.productType, loan.loanTerm)} months`,
        'Interest Rate': loanDetail.rateData?.rate,
        'Interest Only': getInterestOrNot(loanDetail.rateData?.type) ? 'Yes' : 'No',
      },
      'Loan Details Info': {
        'Product Type': `${loan.productType}`,
        'Purchase Price': `$ ${getPrice3decimal(loan.propertyPurchasePrice)}`,
        'Base Loan': `$ ${getPrice3decimal(loan.proposedLoanAmount)}`,
        'Loan With': `$ ${getPrice3decimal(loan.proposedLoanAmount)}`,
        [pitis['title']]: `$ ${getPrice3decimal(pitis['mortgagePayment'])}`,
        LTV: `${calculateLTV()} %`,
        CLTV: `${calculateLTV(null, true)} %`,
        'ARV LTV': `${ltvs.arv_ltv} %`,
        LTC: `${ltvs.ltc} %`,
        LTP: `${ltvs.ltp} %`,
        DSCR: pitis['dscr'],
        'Cash to Close': `$ ${getPrice3decimal(cashToCloseBorrower)}`,
      },
      'Other Loan Info': {
        'Origination Channel': loanTypeMap[loanDetail.loanType],
        'Escrow Waiver': isNonDSCRProduct(loan.productType) ? 'Waive Both' : loan.escrowType,
        '1st Time Home Buyer': loan.firstTimeHomeBuyer ? 'Yes' : 'No',
        'Lock Expiration': loanDetail.rateData?.expireDate,
      },
    }

    if (
      fieldsByTransactionTypeAndProductTypeConstant[loan.productType]?.[loan.transactionType]?.indexOf(
        'propertyPurchasePrice',
      ) === -1
    ) {
      delete rlt['Loan Details Info']['Purchase Price']
    }

    if (ltvs.arv_ltv === undefined) delete rlt['Loan Details Info']['ARV LTV']
    if (ltvs.ltc === undefined) delete rlt['Loan Details Info']['LTC']
    if (ltvs.ltp === undefined) delete rlt['Loan Details Info']['LTP']

    return rlt
  }, [borrower, loan, loanDetail])

  const onShowSlide = () => {
    setShowSlide(!isShowSlide)
  }

  return (
    <>
      <div className="fixed left-0 top-[20%] z-20">
        <button
          className="p-3 rounded-l-none ring-[1px] ring-shade-blue bg-shade-blue text-white border hover:bg-white border-shade-blue hover:text-shade-blue rounded flex items-center gap-2 border-l-0"
          onClick={onShowSlide}
        >
          <DocumentTextIcon className="w-5 h-5" /> Loan Details
        </button>
      </div>
      {isShowSlide && (
        <div
          className={`z-50 shadow1 fixed bg-white border rounded-l left-0 top-[5vh] bottom-0 transition-[left] w-[24rem] h-[90vh] overflow-auto ease-in-out ${
            isShowSlide ? 'left-0' : '-left-[24rem]'
          }`}
        >
          <div className="w-[24rem] fixed z-20 rounded-t flex justify-between p-3 font-bold text-sm place-items-center items-center text-shade-blue border-b bg-white">
            <div className="flex items-center">
              <p className="text-lg text-gray-800">Loan Details: </p>
              <span className="text-[16px] ml-2 italic font-semibold">
                {loanDetail.byteproFileName > 0 ? loanDetail.byteproFileName : loanDetail.loanNumber}
              </span>
            </div>
            <button className="hover:underline hover-shadow1 p-1 rounded" onClick={onShowSlide}>
              <ArrowLeftCircleIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="pt-14 h-fit overflow-auto relative text-sm">
            {Object.keys(data).map((groupName: string, index: number) => {
              const group = (data as any)[groupName]
              return (
                <div key={index}>
                  <p className="text-shade-blue bg-blue-200 p-1 px-3 border-b font-bold">- {groupName}</p>
                  <table className="w-full">
                    <tbody>
                      {Object.keys(group).map((key, index: number) => (
                        <tr className={`${index % 2 && 'bg-gray-50'}`} key={index}>
                          <td className="p-1 px-3 border-b w-[132px]">{key}:</td>
                          <td className="font-variation-settings-600 border-b text-left pr-6 py-1">{group[key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
