import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { borrowerUploadedDocuments, getLoanSubmission, updateLoanSubmission } from 'services'
import { DocumentFile, InputFileTable, TextArea } from 'stories/components'

import type { LoanSubmissionCondition, LoanSubmissionDocument } from './types'

let isUpdateBorrowerNote = false

export const BorrowerDocumentsUpload = () => {
  const [isLoading, setLoading] = useState(true)
  const [conditions, setConditions] = useState<LoanSubmissionCondition[]>([])
  const [documents, setDocuments] = useState<LoanSubmissionDocument[]>([])
  const [currentConditionNo, setCurrentConditionNo] = useState(0)
  const [templateNumber, setTemplateNumber] = useState(0)
  const [taskCreate, setTaskCreate] = useState(false)

  const outstandingConditions = useMemo(() => {
    return conditions.filter((cond) => cond.requested && !cond.cleared)
  }, [conditions])

  // const clearedConditions = useMemo(() => {
  //   return conditions.filter((cond) => cond.requested && cond.cleared)
  // }, [conditions])

  useEffect(() => {
    if (!!templateNumber && taskCreate) {
      borrowerUploadedDocuments()
    }
  }, [taskCreate])

  useEffect(() => {
    getLoanSubmission()
      .then(({ templateNumber, conditions, documents }) => {
        setTemplateNumber(templateNumber)
        setConditions(conditions)
        setDocuments(documents)
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const handleVisibilityChange = () => {
      setTaskCreate(false)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const onUpdateDocs = async (files: DocumentFile[]) => {
    const newDocs: LoanSubmissionDocument[] = []
    const deletedDocs: LoanSubmissionDocument[] = []
    const documentKeys = documents.map((doc) => doc.key)
    const fileKeys = files.map((file) => file.fileKey)
    const newDocuments = cloneDeep(documents)

    files.forEach((file) => {
      const isInclude = documentKeys.includes(file.fileKey)
      if (isInclude) return

      const newDoc: LoanSubmissionDocument = {
        DocumentID: file.id,
        key: file.fileKey,
        name: file.name,
        status: file.status,
        category: file.category,
        createdAt: file.createdAt,
        conditionNo: currentConditionNo,
        generated: false,
      }
      newDocs.push(newDoc)
      newDocuments.push(newDoc)
    })

    documents.forEach((doc) => {
      if (doc.conditionNo != currentConditionNo) return
      const isInclude = fileKeys.includes(doc.key)
      if (isInclude) return

      deletedDocs.push(doc)
      const index = newDocuments.findIndex((v) => v.key == doc.key)
      if (index != -1) newDocuments.splice(index, 1)
    })

    const updateDocs: Record<string, LoanSubmissionDocument[]> = {}
    if (newDocs.length) updateDocs.update = newDocs
    else if (deletedDocs.length) updateDocs.delete = deletedDocs
    else return

    setLoading(true)
    await updateLoanSubmission({}, updateDocs, {})
    setTaskCreate(true)
    setDocuments(newDocuments)

    setLoading(false)
  }

  const onBlur = async () => {
    if (!isUpdateBorrowerNote) return
    isUpdateBorrowerNote = false

    const currentCond = conditions.find((cond) => cond.no == currentConditionNo)
    if (!currentCond) return

    setLoading(true)
    updateLoanSubmission({ update: [currentCond] }).finally(() => setLoading(false))
  }

  const onChangeBorrowerNote = (value: string) => {
    const newConditions = cloneDeep(conditions)
    const index = newConditions.findIndex((cond) => cond.no == currentConditionNo)
    if (index == -1) return
    newConditions[index].borrowerNote = value
    setConditions(newConditions)
    isUpdateBorrowerNote = true
  }

  const renderCondition = (item: LoanSubmissionCondition, index: number, bgColor: string = '') => {
    const selected = item.no === currentConditionNo

    const docCount = documents.filter((doc) => doc.conditionNo == item.no && !!doc.DocumentID).length
    return (
      <div className="my-4 md:px-4 px-2" key={item.no}>
        <button
          type="button"
          className={`${
            selected ? 'bg-shade-blue text-white ring-4 ring-gray-200' : 'hover:text-gray-900 hover:bg-gray-100'
          } flex gap-2 justify-between items-center p-5 w-full font-medium text-left text-gray-900 rounded-t-xl border-[2px] border-gray-200 focus:ring-4 focus:ring-gray-200 hover:ring-4 hover:ring-gray-200 ${bgColor}`}
          onClick={() => setCurrentConditionNo(selected ? 0 : item.no)}
        >
          <span className="flex-1 text-[15px]">
            {index + 1}. {item.name}{' '}
            {!!docCount && (
              <span className={`italic font-bold text-[14px] ${selected && 'tex-white'}`}>({docCount} Documents)</span>
            )}
          </span>
          {!!templateNumber && item.requested && (
            <div className="flex text-sm gap-1">
              Requested
              <p className="italic font-semibold text-[13px]">{`By ${item.requestedBy}: ${item.requestedDate}`}</p>
            </div>
          )}
          <span className="">
            {selected ? (
              <span className="">
                <MinusIcon className="w-5 h-5 shrink-0" />
              </span>
            ) : (
              <PlusIcon className="w-5 h-5 shrink-0 hover:text-gray-900" />
            )}
          </span>
        </button>
        {selected && <div className="shadow">{renderConditionContent(item)}</div>}
      </div>
    )
  }

  const renderConditionContent = (condition: LoanSubmissionCondition) => {
    const properDocuments: DocumentFile[] = documents
      .filter((doc) => doc.conditionNo == currentConditionNo && !!doc.DocumentID)
      .map((v) => ({
        id: v.DocumentID!,
        name: v.name,
        fileKey: v.key,
        category: v.category,
        status: v.status,
        createdAt: v.createdAt,
      }))

    return (
      <div className={`border border-gray-200 p-4 text-[14px]`}>
        <pre className="mb-3 whitespace-pre-wrap">
          <div dangerouslySetInnerHTML={{ __html: condition.description }}></div>
        </pre>
        {condition.note && (
          <div className="border-t-2 border-dashed mb-3">
            <div className="flex pt-2">
              <div className="font-semibold">Note:</div>
              <pre className="whitespace-pre-wrap px-2 italic">
                <div dangerouslySetInnerHTML={{ __html: condition.note }}></div>
              </pre>
            </div>
          </div>
        )}
        {!!templateNumber && (
          <div className="border-t-2 border-dashed mb-5">
            <div className="flex pt-2">
              <div className="font-semibold">Message to {condition.requestedBy}:</div>
              <div className="mx-2 w-full">
                <TextArea
                  title={`Message`}
                  key={`${condition.no}-borrowerNote`}
                  rows={2}
                  value={condition.borrowerNote}
                  onBlur={onBlur}
                  onChange={onChangeBorrowerNote}
                />
              </div>
            </div>
          </div>
        )}
        <InputFileTable
          title="Documents"
          history={false}
          value={properDocuments}
          filePath=""
          onChange={onUpdateDocs}
          showCategory={false}
          uploadable={!condition.cleared}
        />
      </div>
    )
  }

  return (
    <div className="px-2">
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-6 md:p-4 p-2 min-h-[200px]">
          <LayoutLoading show={isLoading} />
          <div className="overflow-auto">
            {templateNumber == 0 && conditions.map((item, index) => renderCondition(item, index))}
            {!!templateNumber && (
              <>
                {!!outstandingConditions.length && (
                  <>
                    <p className="md:mx-4 px-2 font-bold mt-4 border-b text-[18px] text-gray-700">
                      <span>- Outstanding Conditions</span>
                      <span className="ml-1">({outstandingConditions?.length})</span>
                    </p>
                    {outstandingConditions.map((item, index) => renderCondition(item, index, 'bg-red-100'))}
                  </>
                )}

                {/* {!!clearedConditions.length && (
                  <>
                    <p className="md:mx-4 px-2 font-bold mt-6 border-b text-[18px] text-gray-700">
                      <span>- Cleared Conditions</span>
                      <span className="ml-1">({clearedConditions?.length})</span>
                    </p>
                    {clearedConditions.map((item, index) => renderCondition(item, index, 'bg-green-100'))}
                  </>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
