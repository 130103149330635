import cloneDeep from 'clone-deep'
import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'
import React, { useEffect, useMemo, useState } from 'react'
import { addNewTaskFromTemplates } from 'services'
import svgSearch from 'stories/assets/search.svg'
import { Input, Modal, MultiSelect } from 'stories/components'
import { InputConvert, renderHeader, sortByKey } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const assignToRoles = accountTypes

export const AddTaskFromTemplate = ({
  templates,
  ...props
}: {
  projectId: number
  loanNumber: number
  templates: Record<string, string>[]
  onClose: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<number, InputType>>({})
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'description',
    orderDir: 1,
  })
  const [assignTo, setAssignTo] = useState<Record<number, string[]>>({})
  const [noteInputs, setNoteInputs] = useState<Record<number, InputType>>({})

  useEffect(() => {
    const tempInputs: Record<number, InputType> = {}
    templates.forEach((template) => {
      tempInputs[Number(template.no)] = {
        inputType: 'CheckBox',
        title: template.no,
        value: false,
      }
    })
    setInputs(tempInputs)
  }, [templates])

  const isSubmittable = useMemo(() => {
    return !!Object.keys(inputs).filter((no) => inputs[Number(no)].value).length
  }, [inputs])

  const onChangeFilter = (key: 'query', value: string | number) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const onSort = (key: string, sortOrder: number) => {
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
  }

  const onChange = (no: number, value: boolean) => {
    const newInputs = cloneDeep(inputs)
    newInputs[no].value = InputConvert(newInputs[no], value)
    setInputs(newInputs)

    const template = templates.find((temp) => Number(temp.no) == no)
    if (!template) return
    const newNoteInputs = cloneDeep(noteInputs)
    if (value) {
      onUpdateAssignTo(Number(no), {
        [template.assignedTo]: true,
      })

      newNoteInputs[no] = {
        inputType: 'textarea',
        title: 'Notes',
        value: template.notes,
        rows: 2,
      }
    } else delete newNoteInputs[no]
    setNoteInputs(newNoteInputs)
  }

  const onChangeNote = (index: number, value: boolean) => {
    const newInputs = cloneDeep(noteInputs)
    newInputs[index].value = InputConvert(newInputs[index], value)
    setNoteInputs(newInputs)
  }

  const onUpdateAssignTo = (templateNo: number, value: Record<string, boolean>) => {
    const newAssignTo = cloneDeep(assignTo)
    newAssignTo[templateNo] = Object.keys(value).filter((key) => value[key])
    setAssignTo(newAssignTo)
  }

  const onSubmit = async () => {
    const values: Record<string, any>[] = []
    Object.keys(inputs).filter((no) => {
      const taskNo = Number(no)
      const input = inputs[taskNo]
      if (!input.value) return

      const template = templates.find((temp) => temp.no == input.title)
      if (!template) return
      values.push({
        no: taskNo,
        value: assignTo[taskNo],
        notes: noteInputs[taskNo] ? noteInputs[taskNo].value : template.notes,
      })
    })
    setLoading(true)
    addNewTaskFromTemplates(props.projectId, props.loanNumber, values).then(() => {
      setLoading(false)
      props.onClose(true)
    })
  }

  const onClose = () => {
    props.onClose()
  }

  const renderAssignedTo = (template: Record<string, any>) => {
    const checkInput = inputs[template.no]
    const { assignedTo } = template
    if (!checkInput.value) return accountTypes[assignedTo]

    const value = assignTo[Number(template.no)]
    const valueObj: Record<string, boolean> = {}
    value.forEach((key) => (valueObj[key] = true))

    return (
      <div className="w-96">
        <MultiSelect
          id={`assignedTo-${template.no}`}
          options={accountTypes}
          value={valueObj}
          defaultSelected={false}
          title=""
          onChange={(values: Record<string, boolean>) => onUpdateAssignTo(Number(template.no), values)}
        />
      </div>
    )
  }

  return (
    <Modal
      title="Add Tasks from Templates"
      titleOkay="Add"
      onClose={onClose}
      onOk={onSubmit}
      isOpen
      loading={isLoading}
      disabled={!isSubmittable}
    >
      <div className="w-196">
        <div className="">
          <Input
            type="search"
            title="Search"
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 overflow-auto">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {renderHeader({
                title: 'No',
                index: 0,
                key: 'no',
                sortable: true,
                sortOrder: filters.orderBy == 'no' ? filters.orderDir : 0,
                onSort,
              })}
              {renderHeader({
                title: 'Description',
                index: 1,
                key: 'description',
                sortable: true,
                sortOrder: filters.orderBy == 'description' ? filters.orderDir : 0,
                onSort,
              })}
              <th scope="col" className="py-3 px-2 w-96">
                Assign to
              </th>
            </tr>
          </thead>
          <tbody>
            {sortByKey(
              templates.filter(
                (temp) =>
                  temp.description.toLowerCase().includes(filters.query.toLowerCase()) ||
                  temp.no.toString().includes(filters.query.toLowerCase()),
              ),
              filters.orderBy,
              filters.orderDir,
            ).map((template) => {
              const input = inputs[template.no]
              if (!input) return null
              const hasNotes = input.value && noteInputs[template.no]
              return (
                <React.Fragment key={template.no}>
                  <tr className={`border-l border-r ${!hasNotes && 'border-b'}`}>
                    <td className="px-2 py-1">
                      <RenderInput input={input} Key={template.no} onChange={onChange} />
                    </td>
                    <td className="px-2 py-1">{template.description}</td>
                    <td className="px-2 py-1">{renderAssignedTo(template)}</td>
                  </tr>
                  {hasNotes && (
                    <tr className="border-l border-r border-b">
                      <td />
                      <td className="px-2 py-1" colSpan={2}>
                        <RenderInput input={noteInputs[template.no]} Key={template.no} onChange={onChangeNote} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
