import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUpTrayIcon,
  CloudArrowUpIcon,
  PaperAirplaneIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { TargetBox } from 'components/DragDrop'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { PlainInput } from 'components/PlainInput'
import type { BrokerIdentifyCategory } from 'pages/Admin'
import React, { useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { downloadBrokerAgreementPdf, getBrokerVerificationByUser, openS3Document, uploadFiles } from 'services'
import {
  deleteBrokerVerificationDocument,
  sendBrokerVerificationMessage,
  sendBrokerVerificationPdf,
  sendBrokerVerificationRequest,
  sendSignBrokerApprovalRequest,
  updateBrokerVerificationDocument,
} from 'services/apis/admin'
import { Button, Modal, Select, TextArea, Toggle } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { confirm, formatTime, prompt } from 'utils'

import {
  BrokerVerificationDocument,
  BrokerVerificationHistoryType,
  BrokerVerificationHistoryTypeText,
  BrokreVerificationHistory,
  VerificationCategoryStatusOptions,
  // VerificationDocsStatusOptions,
  VerificationStatus,
} from './BrokerVerificationForUser'
import type { UserInfo } from './user.type'

export const BrokerVerificationForAdmin = ({
  fullPermission,
  canUpdateUser,
  user,
  sameVerificationCategories,
  categories,
  onClose,
  onChangeStatus,
}: {
  fullPermission: Boolean
  canUpdateUser: Boolean
  user: UserInfo
  sameVerificationCategories: Array<any>
  categories: BrokerIdentifyCategory[]
  onClose: Function
  onChangeStatus: Function
}) => {
  const profile = useSelector((state: any) => state.auth.profile)
  const [isLoading, setLoading] = useState(true)
  const [documents, setDocuments] = useState<BrokerVerificationDocument[]>([])
  const [histories, setHistories] = useState<BrokreVerificationHistory[]>([])
  const [isOpenMessage, setIsOpenMessage] = useState(false)
  const [brokerVerificationProjectId, setBrokerVerificationProjectId] = useState(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (!user) return
    refetch()
  }, [user])

  const userType = useMemo(() => {
    if (!user) return ''
    return accountTypes[user.accountType]
  }, [user])

  const customCategories: BrokerIdentifyCategory[] = useMemo(() => {
    return documents
      .filter(({ category, fileName }) => category != 'sign' && category != Number(category).toString() && !fileName)
      .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      .map((doc) => ({
        id: doc.category,
        value: doc.category,
        visible: true,
      }))
  }, [documents])

  const isApprovedDocuments = useMemo(() => {
    const requiredCategoryIds = [...categories, ...customCategories]
      // .filter((category) => category.visible)
      .map((category) => category.id.toString())

    const approvedCategoryFilters = documents.filter((doc) => {
      return (
        requiredCategoryIds.includes(doc.category.toString()) &&
        !doc.fileKey &&
        !doc.fileName &&
        [VerificationStatus.Approved, VerificationStatus.Waived].indexOf(doc.status) !== -1
      )
    })
    return approvedCategoryFilters.length == requiredCategoryIds.length
  }, [documents, categories, customCategories])

  const historyData = useMemo(() => {
    return histories
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .map((history, index: number) => {
        let content = history.content
        if (
          [BrokerVerificationHistoryType.VerificationRequest, BrokerVerificationHistoryType.SignRequest].includes(
            history.type,
          )
        )
          content = ''
        return [
          String(index + 1),
          BrokerVerificationHistoryTypeText[history.type],
          history.email,
          formatTime(history.createdAt),
          content,
        ]
      })
  }, [histories])

  const refetch = async () => {
    setLoading(true)
    const { verifications, histories, brokerVerificationProjectId } = await getBrokerVerificationByUser(user.id)
    setDocuments(verifications)
    setHistories(histories)
    setBrokerVerificationProjectId(brokerVerificationProjectId)
    setLoading(false)
  }

  const getCategoryStatus = (categoryId: string) => {
    const categoryDoc = documents.find((doc) => doc.category == categoryId && !doc.fileName)
    if (!categoryDoc) return ''
    return categoryDoc.status
  }

  const isSignApproved = useMemo(() => {
    return ([VerificationStatus.Approved, VerificationStatus.Waived] as string[]).includes(getCategoryStatus('sign'))
  }, [documents])

  const onOpenTask = () => {
    window.open(`/tasks/${brokerVerificationProjectId}?query=${user.id}&taskType=allActive`, '_blank')
  }

  const onSendVerificationLink = async () => {
    if (user.accountExecutive <= 0) {
      return toast('Account Executive is Required!', { type: 'error' })
    }
    setLoading(true)
    await sendBrokerVerificationRequest(user.id)
    const newHistories = cloneDeep(histories)
    newHistories.push({
      id: Date.now(),
      email: profile.email,
      content: '',
      type: BrokerVerificationHistoryType.VerificationRequest,
      createdAt: 'now',
    })
    setHistories(newHistories)
    setLoading(false)
  }

  const onDownloadBrokerAgreementPdf = () => {
    downloadBrokerAgreementPdf(user.id)
  }

  const onChangeCategoryStatus = async (categoryId: string, status: VerificationStatus) => {
    const index = documents.findIndex((doc) => doc.category == categoryId && !doc.fileKey)
    let newDocs = cloneDeep(documents)
    let newDoc: BrokerVerificationDocument
    if (index === -1) {
      newDoc = {
        id: 0,
        category: categoryId,
        status,
        fileKey: '',
        fileName: '',
        createdAt: 'now',
        email: '',
      }
      newDocs.push(newDoc)
    } else {
      newDocs[index].status = status
      newDoc = newDocs[index]
    }

    let categoryName = ''
    if (categoryId != Number(categoryId).toString()) categoryName = categoryId
    else {
      const category = categories.find((category) => category.id == categoryId)
      if (!category || !category.value) return
      categoryName = category.value
    }
    newDoc.categoryName = categoryName

    setLoading(true)
    newDocs = await updateBrokerVerificationDocument(user.id, [newDoc])
    setDocuments(newDocs)

    const newHistories = cloneDeep(histories)
    newHistories.push({
      id: Date.now(),
      email: profile.email,
      content: `${categoryName} - ${status}`,
      type: BrokerVerificationHistoryType.CategoryStatusChange,
      createdAt: 'now',
    })
    setHistories(newHistories)

    setLoading(false)
  }

  const onAddComment = () => {
    setIsOpenMessage(true)
    setMessage('')
  }

  const sendMessage = async () => {
    if (message.length === 0) {
      return toast('Message is required!', { type: 'error' })
    }
    setLoading(true)
    const res = await sendBrokerVerificationMessage(user.id, {
      message,
      type: BrokerVerificationHistoryType.MessageToBroker,
    })
    if (res.success) {
      const newHistories = cloneDeep(histories)
      newHistories.push({
        id: Date.now(),
        email: profile.email,
        content: message,
        type: BrokerVerificationHistoryType.MessageToBroker,
        createdAt: 'now',
      })
      setHistories(newHistories)
    }
    setLoading(false)
    setIsOpenMessage(false)
  }

  const onChangeApprovalStatus = async (value: boolean) => {
    let canChange = true
    if (value) {
      if (isApprovedDocuments && isSignApproved) {
        canChange = true
      } else {
        canChange = false
      }
    }
    if (!canChange) return toast('All categories must be approved to active this user!', { type: 'error' })
    onChangeStatus(value)
  }

  const onRemoveDocument = async (document: BrokerVerificationDocument) => {
    const index = documents.findIndex((doc) => doc.id === document.id)
    if (index === -1) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this document?
        <br />
        <span className="text-gray-600">Document: {document.fileName}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteBrokerVerificationDocument(user.id, [document.id])
    const newDocs = cloneDeep(documents)
    newDocs.splice(index, 1)
    setDocuments(newDocs)
    setLoading(false)
  }

  const onUploadFilesFromInput = (event: React.ChangeEvent<HTMLInputElement>, category: string) => {
    const { files } = event.target
    if (!files || files.length == 0) return
    const arrFile: Array<File> = []
    for (let i = 0; i < files.length; i++) arrFile.push(files[i])

    onUploadFiles(arrFile, category)
  }

  const onDrop = async (doc: any, category: string) => {
    if (!canUpdateUser) return
    onUploadFiles(doc.files, category)
  }

  const onUploadFiles = async (files: any, category: string) => {
    if (!files || !files.length) return
    const fileArr: File[] = []
    const fileLen = files.length
    for (let i = 0; i < fileLen; i++) fileArr.push(files[i])
    const data = {
      path: `brokerVerify/${user.id}`,
    }
    setLoading(true)
    uploadFiles(data, fileArr)
      .then(async (keys: string[]) => {
        const fileList: BrokerVerificationDocument[] = []
        for (let i = 0; i < fileLen; i++) {
          fileList.push({
            id: 0,
            email: profile.email,
            status: VerificationStatus.Pending,
            category,
            fileName: fileArr[i].name,
            fileKey: keys[i],
            createdAt: formatTime(),
          })
        }
        const newDocs = await updateBrokerVerificationDocument(user.id, fileList)
        setDocuments(newDocs)
      })
      .finally(() => setLoading(false))
  }

  const onSignBrokerApproval = async () => {
    setLoading(true)
    try {
      const newDocs = await sendBrokerVerificationPdf(user.id)
      setDocuments(newDocs)
    } catch (e) {}

    setLoading(false)
  }

  const onSendSignApprovalRequest = async () => {
    setLoading(true)
    const { histories } = await sendSignBrokerApprovalRequest(user.id)
    setHistories(histories)
    setLoading(false)
  }

  const onAddCategory = async () => {
    const category = await prompt('Add new category', {
      placeholder: 'New Category Name',
    })
    if (!category) return

    const isFound = customCategories.find((c) => c.id == category)
    if (isFound || category == 'sign') {
      toast('The category is already exists.', { type: 'error' })
      return
    }

    const newCategory: BrokerVerificationDocument = {
      id: 0,
      email: profile.email,
      status: VerificationStatus.Pending,
      category,
      fileName: '',
      fileKey: '',
      createdAt: formatTime(),
    }
    const newDocs = await updateBrokerVerificationDocument(user.id, [newCategory])
    setDocuments(newDocs)

    const newHistories = cloneDeep(histories)
    newHistories.push({
      id: Date.now(),
      email: profile.email,
      content: category,
      type: BrokerVerificationHistoryType.CreateCategory,
      createdAt: 'now',
    })
    setHistories(newHistories)
  }

  const onEditCategory = async (categoryId: string) => {
    const newCategory = await prompt('Update category name', {
      placeholder: 'Category Name',
      value: categoryId,
    })
    if (categoryId == newCategory) return
    const isFound = customCategories.find((c) => c.id == newCategory)
    if (isFound) {
      toast('The category is already exists.', { type: 'error' })
      return
    }
    const updatedDocuments = documents
      .filter((doc) => doc.category == categoryId)
      .map((doc) => ({
        ...doc,
        category: newCategory,
      }))
    const newDocs = await updateBrokerVerificationDocument(user.id, updatedDocuments)
    setDocuments(newDocs)
  }

  const onDeleteCategory = async (categoryId: string) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this category?
        <br />
        <span className="text-gray-600">Category: {categoryId}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const docIds = documents.filter((doc) => doc.category == categoryId).map((doc) => doc.id)
    setLoading(true)
    await deleteBrokerVerificationDocument(user.id, docIds)
    const newDocs = cloneDeep(documents).filter((doc) => doc.category != categoryId)
    setDocuments(newDocs)
    setLoading(false)
  }

  const onUpdateDocumentProp = async (key: string, newValue: string, id: number) => {
    const index = documents.findIndex((doc) => doc.id == id)
    if (index == -1) return

    if (key == 'fileName') newValue = newValue + documents[index].fileName.substr(-4)

    const newDoc = {
      ...documents[index],
      [key]: newValue,
    }
    setLoading(true)

    const newDocuments = cloneDeep(documents)
    ;(newDocuments[index] as any)[key] = newValue
    setDocuments(newDocuments)

    const newDocs = await updateBrokerVerificationDocument(user.id, [newDoc])
    setDocuments(newDocs)
    setLoading(false)
  }

  const renderSubDocuments = (filteredCategory: string) => {
    const isSignCategory = filteredCategory === 'sign'

    let group = [filteredCategory]
    sameVerificationCategories.map((item) => {
      if (item.includes(filteredCategory)) group = item
    })

    const properDocs = documents
      .filter(({ category, fileName }) => group.includes(category) && fileName)
      .sort((a, b) => (!isSignCategory && a.id > b.id ? -1 : 1))

    return properDocs.map((document, index: number) => (
      <tr className="hover:bg-slate-100" key={index}>
        <td className="px-6 min-w-[300px]">
          <span className="flex gap-2">
            <div className="w-[18px]">{index + 1}.</div>
            {!canUpdateUser ? (
              <div>{document.fileName}</div>
            ) : (
              <PlainInput
                value={document.fileName.substring(0, document.fileName.length - 4)}
                content={document.fileName}
                onChange={(newName: string) => onUpdateDocumentProp('fileName', newName, document.id)}
              />
            )}
          </span>
        </td>
        <td className="px-2 w-[160px]">{document.email}</td>
        <td className="px-2 w-[138px]">{formatTime(document.createdAt)}</td>
        <td className="px-2 py-1 w-[90px]">
          <button
            className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
            onClick={() => openS3Document(document.fileKey)}
          >
            <ArrowDownTrayIcon className="w-4 h-4" />
          </button>
          {canUpdateUser && (
            <button
              className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded"
              onClick={() => onRemoveDocument(document)}
            >
              <TrashIcon className="w-4 h-4" />
            </button>
          )}
        </td>
        {/* <td className="px-2 w-20"></td> */}
      </tr>
    ))
  }

  const renderCategory = (category: BrokerIdentifyCategory, index: number, isCustomCategory = false) => {
    let bgCn = index % 2 ? 'bg-gray-50' : ''
    const { visible } = category
    return (
      <React.Fragment key={category.id}>
        <tr className={bgCn}>
          <td colSpan={4} className="border-b overflow-auto">
            <TargetBox types={[NativeTypes.FILE]} onDrop={(docs: any) => onDrop(docs, category.id)}>
              <table className="w-full">
                <tbody>
                  <tr className="">
                    <td className="px-2 py-1" colSpan={3}>
                      <div className="flex items-center font-medium gap-2">
                        <span className="w-[20px]">{index + 1}.</span>{' '}
                        <span className="text-[15px]">
                          {category.value} {!visible && '(Invisible)'}
                        </span>
                        {isCustomCategory && (
                          <>
                            <span
                              className="text-shade-blue p-1 hover-shadow1 cursor-pointer inline-block rounded"
                              onClick={() => onEditCategory(category.id)}
                            >
                              <PencilIcon className="w-4 h-4"></PencilIcon>
                            </span>
                            <span
                              className="text-red-500 p-1 hover-shadow1 cursor-pointer inline-block rounded"
                              onClick={() => onDeleteCategory(category.id)}
                            >
                              <TrashIcon className="w-4 h-4"></TrashIcon>
                            </span>
                          </>
                        )}
                        {canUpdateUser && (
                          <label
                            className="text-gray-900 p-1 hover-shadow1 cursor-pointer inline-block rounded"
                            htmlFor={`file-${category.id}`}
                          >
                            <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                          </label>
                        )}
                        <input
                          className="hidden"
                          id={`file-${category.id}`}
                          type="file"
                          accept=".pdf,.jpg"
                          multiple
                          required
                          onChange={(event) => onUploadFilesFromInput(event, category.id)}
                        />
                      </div>
                    </td>
                    <td className="px-2 py-1 w-20"></td>
                  </tr>
                  {renderSubDocuments(category.id)}
                </tbody>
              </table>
            </TargetBox>
          </td>

          <td className="px-2 py-1 border-b align-baseline pt-2">
            <Select
              id={`category-status-${category.id}`}
              size={3}
              disabled={!fullPermission}
              className="-mb-4 bg-white w-[120px]"
              options={VerificationCategoryStatusOptions}
              value={getCategoryStatus(category.id)}
              onChange={(value) => onChangeCategoryStatus(category.id, value)}
            />
          </td>
        </tr>
      </React.Fragment>
    )
  }

  const renderSignCategory = () => {
    const index = categories.length + customCategories.length
    if (!isApprovedDocuments && !user.isActive) return null

    const hadAdminSignedPdf = !!documents.filter(
      ({ category, fileName, email }) => category == 'sign' && fileName && email == profile.email,
    ).length

    const hadUserSignedPdf = !!documents.filter(
      ({ category, fileName, email }) => category == 'sign' && fileName && email == user.email,
    ).length

    return (
      <>
        <tr>{/* <td colSpan={5} className="border-t border-gray-600"></td> */}</tr>
        <tr className="border-t border-gray-300 border-t-[3px]">
          <td colSpan={4} className="border-b overflow-auto">
            <table className="w-full">
              <tbody>
                <tr className="">
                  <td className="px-2 py-3" colSpan={3}>
                    {canUpdateUser && (
                      <div className="flex items-center font-medium">
                        <span className="w-[20px]">{index + 1}.</span>{' '}
                        <span className="text-[15px]">{userType} Agreement</span>
                        {!hadAdminSignedPdf && (
                          <span
                            className="flex flex-wrap items-center ring ring-[3px] ring-red-300 gap-1 shadow hover:underline text-shade-blue py-1 px-2 hover-shadow1 cursor-pointer inline-block ml-3 rounded"
                            onClick={onSignBrokerApproval}
                          >
                            <svg className="animate-bounce w-5 h-5">
                              <ArrowDownCircleIcon className="w-4 h-4"></ArrowDownCircleIcon>
                            </svg>
                            <span>Sign Agreement PDF</span>
                            <PencilIcon className="w-4 h-4"></PencilIcon>
                          </span>
                        )}
                        {hadAdminSignedPdf && !hadUserSignedPdf && (
                          <span
                            className="flex flex-wrap items-center ring ring-[3px] ring-red-300 gap-1 shadow hover:underline text-shade-blue py-1 px-2 hover-shadow1 cursor-pointer inline-block ml-3 rounded"
                            onClick={onSendSignApprovalRequest}
                          >
                            <span>Send Sign Request to {userType}</span>
                            <PaperAirplaneIcon className="w-4 h-4"></PaperAirplaneIcon>
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="px-2 py-1 w-20"></td>
                </tr>
                {renderSubDocuments('sign')}
              </tbody>
            </table>
          </td>

          <td className="px-2 w-30 border-b align-baseline pt-2">
            <Select
              id={`category-status-sign`}
              size={3}
              disabled={!canUpdateUser}
              className="bg-white w-[120px]"
              options={VerificationCategoryStatusOptions}
              value={getCategoryStatus('sign')}
              onChange={(value) => onChangeCategoryStatus('sign', value)}
            />
          </td>
        </tr>
      </>
    )
  }

  return (
    <div className="relative my-2 border py-2 px-3 rounded">
      <LayoutLoading show={isLoading} />
      <div className="flex mb-2 items-center gap-2">
        <div className="flex flex-wrap gap-6 items-center flex-1">
          <h2 className="text-2xl font-bold flex items-center">
            <span className="text-[16px] ml-2">
              {userType} Approval of {user.name}
            </span>
          </h2>
          {canUpdateUser && (
            <div className="scale-[90%]">
              <Toggle
                disabled={!fullPermission}
                title={'Status'}
                id={`brokerApproval-status-${user.id}`}
                key={`brokerApproval-status-${user.id}`}
                value={user.isActive}
                onChange={(checked) => onChangeApprovalStatus(checked)}
              />
            </div>
          )}
        </div>
        <span
          className="font-bold flex items-center gap-1 text-shade-blue hover:underline cursor-pointer pl-3 py-3"
          onClick={onOpenTask}
        >
          Go to Task
          <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
        </span>
        <span
          className="text-[14px] flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
          onClick={() => onClose()}
        >
          Close
          <XMarkIcon className="w-4 h-4"></XMarkIcon>
        </span>
      </div>
      <div>
        <div className="relative bg-white border rounded mb-6 p-4">
          <div className="w-full flex items-center">
            <div className="flex gap-2 flex-1 items-center">
              <h2 className="text-2xl font-bold">
                <span className="text-[15px] ml-2">Progress</span>
              </h2>
              <span
                className="font-bold flex items-center text-shade-blue hover:underline cursor-pointer pl-3 py-3"
                onClick={onAddCategory}
              >
                <PlusIcon className="w-4 h-4"></PlusIcon>
                Add Category
              </span>
            </div>
            {canUpdateUser && (
              <div
                onClick={onDownloadBrokerAgreementPdf}
                className="px-[15px] py-[5px] text-shade-blue cursor-pointer hover:underline"
              >
                <span className="flex gap-1 items-center">
                  {userType} Agreement PDF
                  <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
                </span>
              </div>
            )}
            <Button onClick={onSendVerificationLink} color="gray" className="px-[15px] py-[5px]" size={'[14px]'}>
              <span className="flex gap-1 items-center">
                Send Invitation Request
                <PaperAirplaneIcon className="w-4 h-4"></PaperAirplaneIcon>
              </span>
            </Button>
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className="relative overflow-auto mb-3 shadow-md sm:rounded-lg">
              <div className="absolute top-1 right-[calc(50%-135px)] w-full h-fit">
                <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
                  <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
                  <span>Drag and drop files.</span>
                </div>
              </div>
              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="px-3 py-3 min-w-[300px]">Category Name</th>
                    <th className="px-2 py-3 w-[160px]">Uploaded By</th>
                    <th className="px-2 py-3 w-[138px]">Uploaded At</th>
                    <th className="px-2 py-3 w-[90px]">Actions</th>
                    <th className="px-2 py-3 w-[150px]">Category Status</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category: BrokerIdentifyCategory, index: number) =>
                    renderCategory(category, index, false),
                  )}
                  {customCategories.map((category: BrokerIdentifyCategory, index: number) =>
                    renderCategory(category, index + categories.length, true),
                  )}
                  {renderSignCategory()}
                </tbody>
              </table>
            </div>
          </DndProvider>
        </div>

        <div className="relative bg-white border rounded mb-6 p-4">
          <div className="w-full flex justify-between">
            <h2 className="text-2xl font-bold flex items-center">
              <span className="text-[15px] ml-2">Histories</span>
            </h2>
            {canUpdateUser && (
              <Button onClick={onAddComment} color="gray" className="px-[15px] py-[5px]" size={'[14px]'}>
                <span className="flex gap-2 items-center">
                  Send Message to {userType}
                  <PencilIcon className="w-4 h-4"></PencilIcon>
                </span>
              </Button>
            )}
          </div>
          <div className="relative overflow-auto sm:rounded-lg -mb-4">
            <PlainTable
              header={['No', 'Action', 'Email', 'Created At', 'Content']}
              data={historyData}
              tdClass={'px-4 py-2 text-[14px]'}
            />
          </div>
        </div>
      </div>
      <Modal
        title={`Send Message to ${userType}`}
        titleOkay="Send"
        titleCancel="Close"
        isOpen={isOpenMessage}
        lastUpdatedAt={Date.now()}
        onClose={() => setIsOpenMessage(false)}
        onOk={sendMessage}
        loading={isLoading}
      >
        <div className="w-[360px]">
          <TextArea title={'Message'} key={'message'} value={message} onChange={(value) => setMessage(value)} />
        </div>
      </Modal>
    </div>
  )
}
