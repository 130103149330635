import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { useEffect, useState } from 'react'
import { getByteproLogDetail, getByteproLogs } from 'services'
import { Modal } from 'stories/components'
import { formatTime } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export function ByteproLogs() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [lastUpdatedAt, setLastUpdatedAt] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [init, setInit] = useState(false)
  const [editing, setEditing] = useState({
    index: -1,
    url: '',
    data: {},
  })

  useEffect(() => {
    setLoading(true)
    setLoanNumber()
    getByteproLogs()
      .then((data = []) => setData(data))
      .finally(() => setLoading(false))
  }, [])

  const openDetail = async (id: number, index: number) => {
    setInit(true)
    setIsOpen(true)
    setLastUpdatedAt(Date.now())
    const res = await getByteproLogDetail(id)
    setEditing({
      index,
      url: res.request.url,
      data: res.request.data,
    })
    setInit(false)
  }

  return (
    <div className="ByteproLogs-container px-2 py-6">
      <Overview title="Bytepro API Logs" />
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-4 p-4">
          <LayoutLoading show={loading} />
          <div className="overflow-auto shadow-md sm:rounded-lg w-full">
            <table className="text-sm text-left text-gray-900 dark:text-gray-400 pl-6 w-full">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th className="px-6 py-3">No</th>
                  <th className="px-6 py-3">Action</th>
                  <th className="px-6 py-3">Email</th>
                  <th className="px-6 py-3">Date</th>
                  <th className="px-6 py-3">Bytepro API Response</th>
                  <th className="px-6 py-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item: any, index) => {
                  return (
                    <tr key={index} className={`${index % 2 && 'bg-slate-50'}`}>
                      <td className="px-6 py-2">{index + 1}</td>
                      <td className="px-6 py-2">{item.action}</td>
                      <td className="px-6 py-2">{item.email}</td>
                      <td className="px-6 py-2">{formatTime(item.createdAt)}</td>
                      <td className="px-6 py-2">
                        <code>{JSON.stringify(item.response, null, ' ')}</code>
                      </td>
                      <td className="px-6 py-2">
                        <span
                          className="hover:underline text-shade-blue cursor-pointer text-[13px]"
                          onClick={() => openDetail(item.id, index)}
                        >
                          View Detail
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        button={<span></span>}
        title={'Bytepro Log Details'}
        titleOkay={''}
        titleCancel="Close"
        isOpen={isOpen}
        lastUpdatedAt={lastUpdatedAt}
        init={init}
      >
        <>
          {!init && (
            <div className="text-gray-900 text-[14px]">
              <table>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 border">No</td>
                    <td className="px-4 py-2 border">{editing.index + 1}</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">Url</td>
                    <td className="px-4 py-2 border">{editing.url}</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border">Data</td>
                    <td className="px-4 py-2 border">
                      <code>{JSON.stringify(editing.data, null, ' ')}</code>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      </Modal>
    </div>
  )
}
