import Api from 'services/api'

export function setLoanNumber(defaultLoanNumber: number = 0) {
  if (defaultLoanNumber) {
    Api.setLoanNumber(`${defaultLoanNumber}`)
    return defaultLoanNumber
  }

  let params: any = window.location.href.split('?')[0]
  params = params.split('/')
  const loanNumber = params[params.length - 1]
  if (isNaN(Number(loanNumber))) return 0

  Api.setLoanNumber(loanNumber)
  return loanNumber
}
