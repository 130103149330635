import type { AccountType } from 'config'

export interface ChildBroker {
  id: number
  brokerId: number
  email: string
  createdAt: string
  updatedAt: string
}

export interface UserInfo {
  id: number
  name: string
  title: string
  email: string
  isActive: boolean
  street: string
  city: string
  state: string
  zip: string
  phone: string
  phoneExt: string
  accountType: AccountType
  childBrokers: ChildBroker[]
  accountExecutive: number
  broker: number
  branch: number
  companyEIN: string
  companyType: string
  companyName: string
  companyNmls: string
  branchNmls: string
  companyLicense: string
  loanOfficer: string
  minCompensation: string
  maxCompensation: string
  brokerCompensation: string
  brokerSSN: string
  brokerNmls: string
  loanOfficerStateLicense: string
  role: any[]
  deleted: boolean
  tempUser: boolean
  master: boolean
  createdAt: string
  updatedAt: string
  taskAssignViewTypes: object
  executive: string
  additionalExecutive?: string
  createdLoans?: number
  withdrawnLoans?: number
  fundedLoans?: number
  pullCreditScore?: number
  pullCreditScoreLevel?: number
  score?: number
  quarterCreatedLoans?: number
  quarterWithdrawnLoans?: number
  quarterFundedLoans?: number
  quarterScore?: number
  company: string
  address: string
}

export interface UserNote {
  id: number
  userId: number
  type: string
  notifyAt: Date
  message: string
  createdAt: Date
}

export const PullCreditScoreLevels: Record<string, string> = {
  '1': 'Eligible',
  '0': 'Payment',
  '-1': 'InEligible',
}
