import { TemporaryLayout } from 'layouts'
import { CustomSignApplicationContent } from 'pages/LoanApplication/CustomSignApplication/CustomSignApplicationContent'
import { setLoanNumber } from 'utils/setLoanNumber'

export const BorrowerLoanApplication = () => {
  setLoanNumber()

  return (
    <TemporaryLayout title="Loan Application" requireSign noShadow>
      <CustomSignApplicationContent />
    </TemporaryLayout>
  )
}
