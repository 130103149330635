import { Modal, PlainTable } from 'stories/components'
import { getPrice2decimal } from 'utils'

import { type IAssetData, AssetAccountTypeForPlaid, AssetAccountTypeOptions, renderAccountName } from './constants'

export const RefreshBalanceResultModal = (props: {
  data: { old: IAssetData[]; new: IAssetData[]; institutions: string[]; updatedStatus: Record<string, -1 | 0 | 1> }
  onClose: Function
}) => {
  const { old, new: newData, institutions, updatedStatus } = props.data

  const renderStatus = (status: -1 | 0 | 1 | undefined) => {
    if (status === undefined) return ''

    const items = {
      '-1': <div className="text-red-800 bg-red-100 rounded py-1 px-2 w-fit">Auth Requires</div>,
      0: <div className="text-yellow-800 bg-yellow-100 rounded py-1 px-2 w-fit">Not Updated</div>,
      1: <div className="text-green-800 bg-green-100 rounded py-1 px-2 w-fit">Success</div>,
    }
    return items[status]
  }

  return (
    <Modal title={'Updated Asset Information'} titleCancel="Close" titleOkay="" isOpen onClose={() => props.onClose()}>
      <div className="min-w-[400px]">
        <p className="text-sm font-bold mb-2">Updated Assets</p>
        <PlainTable
          classNames={Array.from(Array(8).keys()).map(() => '!px-4')}
          tdClass={`text-sm px-4 py-2`}
          header={['No', 'Account Type', 'Institution', 'Account Name/Number', 'Balance', 'Status'].filter((v) => !!v)}
          data={old
            .filter(
              (item) =>
                AssetAccountTypeForPlaid.includes(item.accountType || 0) &&
                item.verified &&
                updatedStatus[item.id] != 0,
            )
            .map((item, index) => {
              const newAsset = newData.find((v) => v.id == item.id)
              const status = updatedStatus[item.id]
              const oldBalance = !!item.balance ? `$${getPrice2decimal(item.balance, false, true)}` : ''
              const newBalance = newAsset ? `$${getPrice2decimal(newAsset.balance, false, true)}` : ''

              return [
                index + 1,
                item.accountType ? AssetAccountTypeOptions[item.accountType] : '',
                item.institution,
                renderAccountName(item),
                status == 1 ? (
                  <p className="flex gap-2">
                    <span className="text-gray-400 line-through">{oldBalance}</span>
                    <span>{newBalance}</span>
                  </p>
                ) : (
                  oldBalance
                ),
                renderStatus(status),
              ]
            })}
        />

        {!!institutions.length && (
          <>
            <p className="text-sm font-bold mt-4 mb-2">Updated Institution Reports</p>
            {institutions.map((institution) => (
              <p className="text-sm">- {institution}</p>
            ))}
            <p className="text-sm mt-4">New reports of the above institutions have been created.</p>
          </>
        )}
      </div>
    </Modal>
  )
}
