import type { InputType } from 'config'

const valuationTypeOptions = ['Appraisal', 'BPO', 'Richer Values Report']

export const defaultInputs = (): Record<string, InputType> => {
  return {
    title: {
      title: 'Title',
      inputType: 'text',
      required: true,
    },
    lender: {
      title: 'Lender',
      inputType: 'text',
      required: true,
    },
    borrower: {
      title: 'Borrower/Client',
      inputType: 'text',
      required: true,
    },
    propertyAddress: {
      title: 'Property Address',
      inputType: 'map',
      required: true,
    },
    appraisalCompany: {
      title: 'Appraisal Company Name',
      inputType: 'text',
      required: true,
    },
    appraiserName: {
      title: 'Appraiser Name',
      inputType: 'text',
      required: true,
    },
    appraiserPhone: {
      title: 'Appraiser Phone',
      inputType: 'text',
      type: 'phone',
      required: true,
    },
    appraiserEmail: {
      title: 'Appraiser Email',
      inputType: 'text',
      type: 'email',
      required: true,
    },
    appraiserAddress: {
      title: 'Appraiser Address',
      inputType: 'map',
      required: true,
    },
    appraiserLicense: {
      title: 'Appraiser Licence Number',
      inputType: 'text',
      required: true,
    },
    appraiserDate: {
      title: 'Appraiser Date',
      inputType: 'text',
      type: 'date',
      required: true,
    },
    appraiserAsIsProperty: {
      title: 'As Is Value',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      required: true,
    },
    appraiserAfterRepairProperty: {
      title: 'After Repair Value',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      required: true,
    },
    appraiserManagementCompany: {
      title: 'Appraiser Management Company',
      inputType: 'text',
      required: true,
    },
    appraiserAsIsValuationType: {
      title: 'As Is Valuation Type',
      inputType: 'select',
      options: valuationTypeOptions,
      hasDefaultOption: true,
      required: true,
    },
    appraiserARVValuationType: {
      title: 'After Repair Value Valuation Type',
      inputType: 'select',
      options: valuationTypeOptions,
      hasDefaultOption: true,
      required: true,
    },
    data: {
      inputType: 'custom',
      title: '',
      span: 3,
    },
    label1: {
      inputType: 'section',
      title: `* I have reviewed the appraisal. I do not know of any changes since the appraisal's date which would significantly affect the subject property's value.`,
      span: 3,
    },
    isAcceptable: {
      title:
        'The appraisal is acceptable. The martket value estimate has been properly   supported, and the property description is complete and accurate. While it is not neccessary the appraisal meet every specific guideline, the appraisal is supported by sound reasoning and any variations from FNMA/FHLMC standards have been explained',
      inputType: 'checkbox',
      value: false,
      span: 3,
    },
    additionalInfo: {
      title: 'Additional Information',
      inputType: 'textarea',
      span: 3,
    },
    isRequireAcceptableInfo: {
      title:
        'The appraisal requires additional information to be acceptable. The items requiring additional information are:',
      inputType: 'checkbox',
      value: false,
      span: 3,
    },
    acceptableInfo: {
      title: 'Additional Information',
      inputType: 'textarea',
      span: 3,
    },
    isUnacceptable: {
      title:
        'The appraisal is unacceptable. Market value estimates are not sufficiently supported and the subject property description is not accurate and/or complete.',
      inputType: 'checkbox',
      value: false,
      span: 3,
    },
    unacceptableInfo: {
      title: 'Unacceptable Reasons',
      inputType: 'textarea',
      span: 3,
    },
    reviewerName: {
      title: 'Reviewer Name',
      inputType: 'text',
      type: 'text',
      required: true,
    },
    reviewedDate: {
      title: 'Reviewed Date',
      inputType: 'text',
      type: 'date',
      required: true,
    },
    isInspectProperty: {
      title: 'Did Inspect Property',
      inputType: 'checkbox',
      value: false,
    },
  }
}
