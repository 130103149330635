import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { deleteFaq, exchangeFaqOrder, getFaq } from 'services/apis'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { confirm } from 'utils'

import { NewFaqModal } from './NewModal'
import type { IDbFaq } from './type'

export const LandingLearnMore = () => {
  const [status, setStatus] = useState<Record<number, boolean>>({})
  const [editingItemIndex, setEditingItemIndex] = useState<number | null>(null)
  const [data, setData] = useState<IDbFaq[]>([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    reload()
  }, [])

  const reload = () => {
    setLoading(true)
    getFaq()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  const onToggle = (id: number) => {
    const newStatus = cloneDeep(status)
    newStatus[id] = !newStatus[id]
    setStatus(newStatus)
  }

  const onAdd = () => {
    setEditingItemIndex(-1)
  }

  const onClose = (needRefresh: boolean) => {
    setEditingItemIndex(null)
    needRefresh && reload()
  }

  const onExchangeOrder = (index1: number, index2: number) => {
    const newData = cloneDeep(data)
    const temp = newData[index1]
    newData[index1] = newData[index2]
    newData[index2] = temp

    setLoading(true)
    exchangeFaqOrder(newData[index1].id, newData[index2].id)
      .then(() => setData(newData))
      .finally(() => setLoading(false))
  }

  const onRemove = async (index: number) => {
    const result = await confirm('Are you sure to remove this item?')
    if (!result) return

    const newData = cloneDeep(data)
    newData.splice(index, 1)
    deleteFaq(data[index].id)
      .then(() => setData(newData))
      .finally(() => setLoading(false))
  }

  const renderItem = (item: IDbFaq, index: number) => {
    const isActive = status[item.id]
    return (
      <div className="border mb-6 rounded-lg bg-white" key={`${index}-${item.title}`}>
        <div
          className="flex justify-between border-b items-center cursor-pointer p-4 hover:bg-gray-100"
          onClick={(e) => {
            !e.defaultPrevented && onToggle(item.id)
          }}
        >
          <p className="text-lg font-semibold">
            {index + 1}. {item.title}
          </p>
          <div className="flex gap-2 items-center">
            <span
              className="hover-shadow1 p-1"
              onClick={(e) => {
                e.preventDefault()
                index != 0 && onExchangeOrder(index - 1, index)
                return false
              }}
            >
              <ArrowUpIcon className="w-4 h-4 text-indigo-600" />
            </span>

            <span
              className="hover-shadow1 p-1"
              onClick={(e) => {
                e.preventDefault()
                index != data.length - 1 && onExchangeOrder(index, index + 1)
                return false
              }}
            >
              <ArrowDownIcon className="w-4 h-4 text-indigo-600" />
            </span>

            <span
              className="hover-shadow1 p-1"
              onClick={(e) => {
                e.preventDefault()
                setEditingItemIndex(index)
                return false
              }}
            >
              <PencilSquareIcon className="w-4 h-4 text-indigo-600" />
            </span>

            <span
              className="hover-shadow1 p-1"
              onClick={(e) => {
                e.preventDefault()
                onRemove(index)
                return false
              }}
            >
              <TrashIcon className="w-4 h-4 text-red-600" />
            </span>

            <ChevronRightIcon
              className={`w-4 h-4 text-gray-600 transition-all duration-500 transform ${isActive && 'rotate-90'}`}
            />
          </div>
        </div>
        {isActive ? (
          <div className="text-base overflow-hidden duration-500 transition-all text-gray-600">
            <p className="p-4">{item.content}</p>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div className="AccountExecutives-container relative">
      <LayoutLoading show={isLoading} />
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center mb-3">
          Landing Learn More
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h2>
        <div>
          <Button onClick={onAdd} className="">
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto sm:rounded-lg">
        {data.map(renderItem)}

        {editingItemIndex !== null && (
          <NewFaqModal item={editingItemIndex == -1 ? null : data[editingItemIndex]} onClose={onClose} />
        )}
      </div>
    </div>
  )
}
