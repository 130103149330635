import {
  ArrowDownTrayIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  IdentificationIcon,
  LockClosedIcon,
  LockOpenIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { LoanStatus } from 'components/LoanStatus'
import { ServicingStatus } from 'components/ServicingStatus'
import {
  AccountType,
  appApiUrl,
  channelTypeOptions,
  companyName,
  getActiveStatus,
  itemCountPerPage,
  loanStatusConstants,
  lockTypeOptions,
  productTypeOptions,
} from 'config'
import { states } from 'config/states.constants'
import { usePermissions } from 'hooks/usePermissions'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { loanTypeMap } from 'reducers/loanDetail.reducer'
import { deleteLoan, filterPipeline, getLoanStatistics, getPipelineBusDays, setPipelineBusDays } from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { Checkbox, Input2, InputDateRange, MultiSelect, Pagination, Select2, Toggle } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { confirm, filterObject } from 'utils'
import { formatDate, getDaysDiff, getPrice2decimal, serialize, thousandSeperator } from 'utils/convertor'
import { useTitle } from 'utils/pageTitle'

import { BusinessDaysDialog, Cell } from './BusinessDaysDialog'
import { StatusDetail } from './StatusDetail'
import { ViewNoteDialog } from './ViewNoteDialog'

export const calculateSingleStatistics = (
  values: Array<{ count: string; loanStatus?: string; servicingStatus?: string; sum: string }>,
  status: string,
  totalCount: number,
) => {
  const filteredValue = values.find(
    ({ loanStatus, servicingStatus }) => loanStatus == status || servicingStatus == status,
  )
  if (!filteredValue) return { count: 0, amount: 0, percent: 0 }

  const count = Number(filteredValue.count)
  return {
    count,
    amount: Number(filteredValue.sum),
    percent: totalCount && count ? (count / totalCount) * 100 : 0,
  }
}

export function Pipeline() {
  useTitle(`Pipeline - ${companyName}`)
  const { profile } = useSelector((state: any) => state.auth)
  const [statusLoading, setStatusLoading] = useState(false)
  const [pipelineLoading, setPipelineLoading] = useState(false)
  const [isBusinessDaysDialog, setBusinessDaysDialog] = useState(false)
  const [businessDays, setBusinessDays] = useState<Cell[]>([])
  const [total, setTotal] = useState(0)
  const [sumLoanAmount, setSumLoanAmount] = useState(0)
  const [action, setAction] = useState('')
  const [pageNum, setPageNum] = useState(0)
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [loans, setLoans] = useState([])
  const [filters, setFilters] = useState<Record<string, any>>({
    dateRange: { from: null, to: null },
    query: '',
    rush: 'all',
    status: getActiveStatus('object'),
    lock: '0',
    state: '',
    maturity: '0',
    type: '0',
    productType: '0',
    servicing: false,
    myLoans: true,
    orderBy: 'asOf',
    orderDir: '-1',
  })
  const [filterQuery, setFilterQuery] = useState('')
  const [brokerSocre, setBrokerScore] = useState<any>(undefined)
  const [quarterScore, setQuarterScore] = useState<any>(undefined)

  const [statisticsByServicing, setStatisticsByServicing] = useState<Record<string, any> | null>(null)
  const [statisticsByStatus, setStatisticsByStatus] = useState<Record<string, any> | null>(null)
  const [statisticsByLocked, setStatisticsByLocked] = useState<Record<string, any> | null>(null)
  const [totalLoans, setTotalLoans] = useState(0)
  const [viewNote, setViewNote] = useState<Record<string, number> | null>(null)

  const { hasPermission } = usePermissions()

  const isAeAdmin = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

  const hasMyLoans = useMemo(() => {
    return [
      AccountType.UW_MANAGER,
      AccountType.UNDERWRITER,
      AccountType.ACCOUNT_MANAGER,
      AccountType.NATIONAL_SALE,
    ].includes(profile.accountType)
  }, [profile])

  const manageServicing = hasPermission('SERVICING_PIPELINE')

  useEffect(() => {
    const josn = {
      servicing: filters.servicing,
      myLoans: filters.myLoans,
      dateRange: filters.dateRange,
    }
    if (filters.servicing) {
      setStatusLoading(true)
      getLoanStatistics(josn).then(({ byStatus }) => {
        let totalCount = 0
        byStatus.map((item) => {
          totalCount += Number(item.count)
        })
        const dataByServicing = {
          servicing: calculateSingleStatistics(byStatus, 'servicing', totalCount),
          purchased: calculateSingleStatistics(byStatus, 'purchased', totalCount),
          paidInFull: calculateSingleStatistics(byStatus, 'paidInFull', totalCount),
          reo: calculateSingleStatistics(byStatus, 'reo', totalCount),
        }
        setStatisticsByServicing(dataByServicing)
        setStatusLoading(false)
      })
    } else {
      setStatusLoading(true)
      getLoanStatistics(josn).then(({ byStatus, byLocked, brokerUserScore, quarterScore }) => {
        const lockedCount = Number(byLocked?.find(({ rateLocked }) => rateLocked)?.count)
        const unlockedCount = Number(byLocked?.find(({ rateLocked }) => !rateLocked)?.count)
        const totalCount = lockedCount + unlockedCount
        setTotalLoans(totalCount)
        setStatisticsByLocked({
          locked: lockedCount,
          unlocked: unlockedCount,
        })

        const dataByStatus = {
          funded: calculateSingleStatistics(byStatus, 'funded', totalCount),
          submitted: calculateSingleStatistics(byStatus, 'submitted', totalCount),
          clearToClose: calculateSingleStatistics(byStatus, 'clearToClose', totalCount),
          approved: calculateSingleStatistics(byStatus, 'approved', totalCount),
          underwriterReview: calculateSingleStatistics(byStatus, 'underwriterReview', totalCount),
          docsOut: calculateSingleStatistics(byStatus, 'docsOut', totalCount),
          readyForDocs: calculateSingleStatistics(byStatus, 'readyForDocs', totalCount),
          closed: calculateSingleStatistics(byStatus, 'closed', totalCount),
        }
        setStatisticsByStatus(dataByStatus)
        setBrokerScore(brokerUserScore)
        setQuarterScore(quarterScore)
        setStatusLoading(false)
      })

      getPipelineBusDays().then((values) => setBusinessDays(values))
    }
  }, [filters.servicing, filters.myLoans, filters.dateRange])

  useEffect(() => {
    filterLoanData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => action == '' && filterLoanData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const auth = useSelector((state: any) => state.auth)

  const downloadLink = useMemo(() => {
    const params = serialize({
      ...filters,
      status: filterObject(filters.status),
    })
    return `${appApiUrl}/loan/download?${params}&token=${auth.token}&take=0&count=0`
  }, [auth.token, filters])

  const downloadLinkFunded = useMemo(() => {
    const fundedFilters: Record<string, any> = {
      query: '',
      status: ['funded'],
      lock: '0',
      orderBy: 'no',
      orderDir: '1',
    }
    const params = new URLSearchParams(fundedFilters).toString()
    return `${appApiUrl}/loan/download?${params}&token=${auth.token}&take=0&count=0`
  }, [auth.token])

  const onEditBusinessDays = () => {
    if (!hasPermission('TURN_TIME_CONTROL')) return
    setBusinessDaysDialog(true)
  }

  const onUpdateBusinessDays = async (values: Cell[]) => {
    setStatusLoading(true)
    await setPipelineBusDays(values)
    setBusinessDays(values)
    setStatusLoading(false)
    setBusinessDaysDialog(false)
  }

  const onChangeFilter = (key: string, value: any, needRefresh = true) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    if (key === 'servicing') {
      newFilters.status = value ? { servicing: true, reo: true } : getActiveStatus('object')
      newFilters.orderBy = 'asOf'
      newFilters.lock = '0'
      newFilters.maturity = '0'
    }
    setFilters(newFilters)
    if (!needRefresh) return

    setPageNum(0)
    if (key === 'query') setFilterQuery(value)
    else filterLoanData(newFilters, 0)
  }

  const onBlurLoanStatus = () => {
    setPageNum(0)
    filterLoanData(filters, 0)
  }

  const filterLoanData = async (filters: any, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = pageNum
    setAction('getLoans')
    setPipelineLoading(true)
    const filterStatus = filters.status || {}
    const { data, total, sum } = await filterPipeline({
      ...filters,
      status: filterObject(filterStatus),
      lock: filters.lock || '0',
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
    setLoans(data)
    setTotal(total)
    setSumLoanAmount(sum || 0)
    setPipelineLoading(false)
    setAction('')
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
    filterLoanData(filters, num).then(() => {})
  }

  const onViewNote = (no: number, loanFileName: number) => {
    setViewNote({ no, loanFileName })
  }

  const onTrash = async (no: number, index: number) => {
    const content = (
      <div className="text-gray-600 mb-4 text-[18px]">
        Do you want to remove this Loan?
        <br />
        <span className="text-gray-900">Loan Number: {no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setPipelineLoading(true)
    deleteLoan(no)
      .then(() => {
        const newLoans = cloneDeep(loans)
        newLoans.splice(index, 1)
        setLoans(newLoans)
      })
      .finally(() => setPipelineLoading(false))
  }

  const onLoanStatusChange = (no: number, status: string) => {
    let temp: any = cloneDeep(loans)
    temp.map((item: any, index: number) => {
      if (item.no === no) {
        const key = filters.servicing ? 'servicingStatus' : 'loanStatus'
        temp[index][key] = status
      }
    })
    setLoans(temp)
  }

  const onMoveToServicing = (no: number) => {
    let temp: any = cloneDeep(loans)
    temp.map((item: any, index: number) => {
      if (item.no === no) {
        temp[index].servicingPipeline = true
      }
    })
    setLoans(temp)
  }

  const renderLockExp = (loanStatus: string, lockExp: string) => {
    if (!lockExp) return null
    let isExpired = moment().diff(formatDate(lockExp), 'days') > 0
    if (['funded', 'withdrawn'].includes(loanStatus)) isExpired = false

    return (
      <span className={`capitalize p-1 mb-1 w-[80px] text-[13px] rounded ${isExpired ? 'overdue' : ''}`}>
        {formatDate(lockExp)}
      </span>
    )
  }

  const renderMaturity = (maturityDate: string) => {
    if (!maturityDate) return 'n/a'
    let isExpired = new Date(formatDate(maturityDate)) < new Date()
    return (
      <span className={`capitalize p-1 mb-1 w-[80px] text-[13px] rounded ${isExpired ? 'overdue' : ''}`}>
        {formatDate(maturityDate)}
      </span>
    )
  }

  const renderHeader = (title: string | JSX.Element, sortable: boolean = false, key: string) => {
    let sortOrder = filters.orderBy == key ? parseInt(filters.orderDir) : 0
    if (!sortable)
      return (
        <th scope="col" className="py-3 px-2" key={key}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
      filterLoanData(newFilters)
    }

    return (
      <th scope="col" className="py-2 px-2" key={key}>
        <button className="flex uppercase bg-transparent font-bold items-center" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-1 text-gray-900" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-1 text-gray-900" />
            )
          ) : (
            <div className="w-3 h-3 ml-1" />
          )}
        </button>
      </th>
    )
  }

  const viewStatus = (status: Array<string>) => {
    const newFilters = cloneDeep(filters)
    let value: any = {}
    status.map((key) => {
      value[key] = true
    })
    newFilters['status'] = value
    setFilters(newFilters)

    setPageNum(0)
    filterLoanData(newFilters, 0)
  }

  const rushFilterElement = useMemo(() => {
    return (
      <div className="flex flex-wrap items-center gap-3">
        <select
          name="plan"
          id="status-filter-group"
          className="py-0 pl-1 pr-6 text-[12.5px] leading-[16px] border-gray-300"
          value={filters.rush}
          onChange={(e) => {
            onChangeFilter('rush', e.target.value)
          }}
        >
          <option value="-" disabled>
            {' '}
          </option>
          <option value="all">All</option>
          <option value="rush">Rush</option>
        </select>
      </div>
    )
  }, [filters.rush])

  const loanStatusViewAll = useMemo(() => {
    let activeCount = 0,
      archivedCount = 0

    let totalCount = Object.keys(loanStatusConstants['status']).length
    let activeStatus = getActiveStatus()
    let archivedStatus = loanStatusConstants.archivedStatus

    if (filters.servicing) {
      totalCount = Object.keys(loanStatusConstants['servicingStatus']).length
      activeStatus = ['servicing', 'reo']
      archivedStatus = ['purchased', 'paidInFull']
    }

    Object.keys(filters.status).map((key) => {
      if (filters.status[key]) {
        if (activeStatus.includes(key)) activeCount += 1
        else archivedCount += 1
      }
    })

    let value = '-'
    if (activeCount + archivedCount === 0 || activeCount + archivedCount == totalCount) value = 'all'
    else if (activeCount === activeStatus.length && archivedCount === 0) value = 'active'
    else if (archivedCount === archivedStatus.length && activeCount === 0) value = 'archived'

    return (
      <div className="flex flex-wrap items-center gap-3">
        <select
          name="plan"
          id="status-filter-group"
          className="py-0 pl-1 pr-6 text-[12.5px] leading-[16px] border-gray-300"
          value={value}
          onChange={(e) => {
            if (e.target.value === 'all') viewStatus([])
            if (e.target.value === 'active') viewStatus(activeStatus)
            if (e.target.value === 'archived') viewStatus(archivedStatus)
          }}
        >
          <option value="-" disabled>
            {' '}
          </option>
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="archived">Archived</option>
        </select>
      </div>
    )
  }, [filters.status, filters.servicing])

  return (
    <div className="Pipeline-container py-6 px-2">
      <div
        className={`relative ${
          filters.servicing && 'border-[2px] border-sky-300/75'
        } shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full`}
      >
        <LayoutLoading show={statusLoading} />
        <div className="flex flex-wrap justify-between mb-5 items-center">
          <div className="flex gap-4 items-center mb-3">
            <span className="text-2xl font-bold">Pipeline Status</span>{' '}
          </div>
          <div>
            {brokerSocre && (
              <div className="score">
                <table className="text-[13.5px]">
                  <thead>
                    <tr>
                      <th className="bg-gray-100 border font-normal px-2 py-1 text-center" rowSpan={2}>
                        Pull Through Ratio
                      </th>
                      <th className="bg-gray-50 border font-normal px-2 py-1">Lifetime</th>
                      <th className="bg-gray-50 border font-normal px-2 py-1">Recent Quarter</th>
                    </tr>
                    <tr>
                      <td className="border px-2 py-1 text-center font-semibold">
                        {getPrice2decimal(brokerSocre, false, true)}%
                      </td>
                      <td className="border px-2 py-1 text-center font-semibold">
                        {getPrice2decimal(quarterScore, false, true)}%
                      </td>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div className="flex mb-4">
          <InputDateRange
            title="Date Range"
            value={filters.dateRange}
            onChange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>
        {filters.servicing ? (
          <div className="">
            {statisticsByServicing && (
              <div className="flex flex-wrap gap-4">
                <StatusDetail
                  status="Servicing"
                  amount={statisticsByServicing.servicing.amount}
                  loans={statisticsByServicing.servicing.count}
                  percent={statisticsByServicing.servicing.percent}
                  color="sky"
                  viewStatus={() => viewStatus(['servicing'])}
                />
                <StatusDetail
                  status="REO"
                  amount={statisticsByServicing.reo.amount}
                  loans={statisticsByServicing.reo.count}
                  percent={statisticsByServicing.reo.percent}
                  color="yellow"
                  viewStatus={() => viewStatus(['reo'])}
                />
                <StatusDetail
                  status="Purchased"
                  amount={statisticsByServicing.purchased.amount}
                  loans={statisticsByServicing.purchased.count}
                  percent={statisticsByServicing.purchased.percent}
                  color="green"
                  viewStatus={() => viewStatus(['purchased'])}
                />
                <StatusDetail
                  status="Paid in Full"
                  amount={statisticsByServicing.paidInFull.amount}
                  loans={statisticsByServicing.paidInFull.count}
                  percent={statisticsByServicing.paidInFull.percent}
                  color="green"
                  colorDeep={200}
                  viewStatus={() => viewStatus(['paidInFull'])}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-row flex-wrap gap-5 mp-3">
            <div>
              {statisticsByStatus && (
                <>
                  <div className="flex flex-wrap gap-4">
                    <StatusDetail
                      status="Submitted"
                      amount={statisticsByStatus.submitted.amount}
                      loans={statisticsByStatus.submitted.count}
                      percent={statisticsByStatus.submitted.percent}
                      color="gray"
                      viewStatus={() => viewStatus(['submitted'])}
                    />
                    <StatusDetail
                      status="Approved"
                      amount={statisticsByStatus.approved.amount}
                      loans={statisticsByStatus.approved.count}
                      percent={statisticsByStatus.approved.percent}
                      color="yellow"
                      viewStatus={() => viewStatus(['approved'])}
                    />
                    <StatusDetail
                      status="Underwriter Review"
                      amount={statisticsByStatus.underwriterReview.amount}
                      loans={statisticsByStatus.underwriterReview.count}
                      percent={statisticsByStatus.underwriterReview.percent}
                      color="red"
                      viewStatus={() => viewStatus(['underwriterReview'])}
                    />
                  </div>
                  <div className="flex flex-wrap gap-4">
                    <StatusDetail
                      status="Clear to Close"
                      amount={statisticsByStatus.clearToClose.amount}
                      loans={statisticsByStatus.clearToClose.count}
                      percent={statisticsByStatus.clearToClose.percent}
                      color="sky"
                      viewStatus={() => viewStatus(['clearToClose'])}
                    />
                    <StatusDetail
                      status="Ready for Docs | Docs Out | Closed"
                      amount={
                        statisticsByStatus.docsOut.amount +
                        statisticsByStatus.readyForDocs.amount +
                        statisticsByStatus.closed.amount
                      }
                      loans={
                        statisticsByStatus.docsOut.count +
                        statisticsByStatus.readyForDocs.count +
                        statisticsByStatus.closed.count
                      }
                      percent={
                        statisticsByStatus.docsOut.percent +
                        statisticsByStatus.readyForDocs.percent +
                        statisticsByStatus.closed.percent
                      }
                      color="green"
                      viewStatus={() => viewStatus(['readyForDocs', 'docsOut', 'closed'])}
                    />
                    <StatusDetail
                      status="Funded"
                      amount={statisticsByStatus.funded.amount}
                      loans={statisticsByStatus.funded.count}
                      color="green"
                      colorDeep={200}
                      percent={statisticsByStatus.funded.percent}
                      download={downloadLinkFunded}
                      viewStatus={() => viewStatus(['funded'])}
                    />
                  </div>
                </>
              )}
              {statisticsByLocked && (
                <div className="mt-4 flex flex-wrap md:gap-6">
                  <div className="shadow px-4 py-2 rounded my-1">
                    Total: <span className="font-bold text-[16px]">{thousandSeperator(totalLoans || 0)}</span>{' '}
                    <span className="text-[14px]">Loans</span>
                  </div>
                  <div className="shadow px-4 py-2 rounded my-1 flex items-center gap-2">
                    <LockClosedIcon className="w-4 h-4"></LockClosedIcon>
                    Locked:{' '}
                    <span>
                      <span className="font-bold text-[16px]">{thousandSeperator(statisticsByLocked.locked || 0)}</span>{' '}
                      <span className="text-[14px]">Loans</span>
                    </span>
                  </div>
                  <div className="shadow px-4 py-2 rounded my-1 flex items-center gap-2">
                    <LockOpenIcon className="w-4 h-4"></LockOpenIcon>
                    Float:{' '}
                    <span>
                      <span className="font-bold text-[16px]">
                        {thousandSeperator(statisticsByLocked.unlocked || 0)}
                      </span>{' '}
                      <span className="text-[14px]">Loans</span>
                    </span>
                  </div>
                </div>
              )}
            </div>
            {!statusLoading && (
              <div className="">
                <div className="overflow-auto shadow-md sm:rounded-lg">
                  <table className="w-fit text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Dept
                        </th>
                        <th scope="col" className="px-6 py-3 cursor-pointer" onClick={onEditBusinessDays}>
                          Turn Times
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-[15px] text-gray-900">
                      {businessDays.map((row, index) => (
                        <tr key={index} className="hover:bg-gray-200 cursor-pointer border-b">
                          <td className="px-6 py-2.5 text-[14px]">{row.key}</td>
                          <td className="px-6 py-2.5 cursor-pointer" onClick={onEditBusinessDays}>
                            {row.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-8">
        <div
          className={`${
            filters.servicing && 'border-[2px] border-sky-300/75'
          } relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full`}
        >
          <LayoutLoading show={pipelineLoading} />
          <div className="flex flex-wrap gap-4 justify-between items-center mb-5">
            <h1 className="text-2xl font-bold flex items-center mb-3">
              <span>Loan Pipeline</span>{' '}
              <span className="text-base ml-3">
                {pipelineLoading ? (
                  <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
                ) : (
                  `(${total})`
                )}
              </span>
            </h1>
            <div className="flex flex-wrap gap-4">
              {hasMyLoans && (
                <div className="input-container">
                  <Checkbox
                    className=""
                    id={'myLoan'}
                    title={'My Loans'}
                    value={filters.myLoans}
                    onChange={(value) => onChangeFilter('myLoans', value)}
                  />
                </div>
              )}
              {manageServicing && (
                <div className="input-container mb-4">
                  <Toggle
                    className=""
                    name="servicing"
                    id={'servicing'}
                    title={'Servicing'}
                    value={filters.servicing}
                    onChange={(value) => onChangeFilter('servicing', value)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4 mt-4">
            <Input2
              type="search"
              title="Search Loans"
              hasIcon
              icon={svgSearch}
              value={filters.query}
              onChange={(value) => onChangeFilter('query', value)}
              additionalElements={rushFilterElement}
            />
            <MultiSelect
              id="loan-status"
              title="Loan Status"
              options={filters.servicing ? loanStatusConstants.servicingStatus : loanStatusConstants.status}
              value={filters.status}
              hasDefaultOption
              defaultOptionText="All"
              defaultSelected={false}
              onChange={(value) => onChangeFilter('status', value, false)}
              onBlur={onBlurLoanStatus}
              additionalElements={loanStatusViewAll}
            />
            {isAeAdmin && (
              <Select2
                id="loan-type"
                title="Channel"
                options={channelTypeOptions}
                value={filters.type}
                hasDefaultOption
                defaultOptionText="All"
                onChange={(value) => onChangeFilter('type', value)}
              />
            )}
            {filters.servicing ? (
              <Select2
                id="maturity-status"
                title="Maturity Status"
                options={['Active', 'Matured']}
                value={filters.maturity}
                hasDefaultOption
                defaultOptionText="All"
                onChange={(value) => onChangeFilter('maturity', value)}
              />
            ) : (
              <Select2
                id="lock-status"
                title="Lock"
                options={lockTypeOptions}
                value={filters.lock}
                hasDefaultOption
                defaultOptionText="All"
                onChange={(value) => onChangeFilter('lock', value)}
              />
            )}
            <Select2
              id="product-type"
              title="Product Type"
              options={productTypeOptions}
              value={filters.productType}
              hasDefaultOption
              defaultOptionText="All"
              onChange={(value) => onChangeFilter('productType', value)}
            />
            <Select2
              id="state"
              title="State"
              options={states}
              value={filters.state}
              hasDefaultOption
              defaultOptionText="All"
              onChange={(value) => onChangeFilter('state', value)}
            />
          </div>
          <div className="mt-2">
            <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {renderHeader('Loan #', true, 'no')}
                    <th scope="col" className="py-2 px-2">
                      <span>
                        <div className="border-b w-fit mb-1 border-gray-300">Borrower</div>
                        Property Address
                      </span>
                    </th>
                    <th scope="col" className="py-2 px-2">
                      <span>
                        <div className="border-b w-fit mb-1 border-gray-300">Purpose</div>
                        Product
                      </span>
                    </th>
                    {renderHeader('Status', true, 'loanStatus')}
                    {renderHeader('As Of', true, 'asOf')}
                    {isAeAdmin && renderHeader('Notes Date', true, 'noteDate')}
                    {renderHeader('Loan Amount', true, 'proposedLoanAmount')}
                    {filters.servicing && renderHeader('Investor', true, 'investorCode')}
                    {filters.servicing && renderHeader('Commitment Date', true, 'investorCommitmentDate')}
                    {filters.servicing && renderHeader('Last Draw Issue Date', true, 'lastDrawIssueDate')}
                    {filters.servicing && renderHeader('Maturity Date', true, 'maturityDate')}
                    {!filters.servicing && renderHeader('Lock Exp', true, 'lockExp')}
                    {!filters.servicing && (
                      <th scope="col" className="py-2 px-2">
                        <span>
                          <div className="border-b w-fit mb-1 border-gray-300">Account Executive</div>
                          Broker / Creator
                        </span>
                      </th>
                    )}
                    {!filters.servicing && renderHeader('U.W', true, 'underwriterName')}
                    {renderHeader('Channel', true, 'type')}
                    <th className="px-2">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-[14px] text-gray-900">
                  {loans.map((item: any, index: number) => {
                    const isLoanSetupOrOnHold = ['loanSetupOnHold', 'application'].includes(item.loanStatus)
                    return (
                      <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                        <td className="pl-3">
                          <Link
                            to={`/loan_process/overview/${item.no}`}
                            className="font-bold text-shade-blue hover:underline cursor-pointer"
                          >
                            <span className="flex-col flex min-h-[60px] justify-center items-center">
                              <div>{item.byteproFileName || item.no}</div>
                              {filters.servicing && (
                                <div className="font-normal text-gray-700 text-[13px] mt-2">
                                  {item.servicerLoanNumber}
                                </div>
                              )}
                              {!filters.servicing && item.rush && <div className="text-red-700 italic">Rush</div>}
                            </span>
                          </Link>
                        </td>
                        <td className="px-2 py-2 min-w-[170px]">
                          <span>
                            <div className="border-b w-full mb-2 flex gap-3 items-center">
                              <span>{`${item.borrowerFirstName} ${item.borrowerLastName}`}</span>
                              {item.entityTitle?.length > 0 && (
                                <span className="cursor-pointer">
                                  <Tooltip message={item.entityTitle}>
                                    <IdentificationIcon className="w-[15px] h-[15px]"></IdentificationIcon>
                                  </Tooltip>
                                </span>
                              )}
                            </div>
                            {item.subjectPropertyAddress}
                          </span>
                        </td>
                        <td className="px-2 py-2 min-w-[170px]">
                          <span>
                            <div className="border-b w-full mb-2">{item.transactionType}</div>
                            {item.productType}
                          </span>
                        </td>
                        <td className="px-2 py-2">
                          {filters.servicing ? (
                            <ServicingStatus
                              key={item.no}
                              status={item.servicingStatus}
                              onChange={(value: string) => onLoanStatusChange(item.no, value)}
                              loanNumber={item.no}
                              size={4}
                            />
                          ) : (
                            <LoanStatus
                              key={item.no}
                              status={item.loanStatus}
                              servicingPipeline={item.servicingPipeline}
                              onChange={(value: string) => onLoanStatusChange(item.no, value)}
                              onMoveServicing={() => onMoveToServicing(item.no)}
                              loanNumber={item.no}
                              size={4}
                            />
                          )}
                          {item.condsByCategory && (
                            <div className="flex flex-wrap gap-x-2">
                              {Object.keys(item.condsByCategory).map((key, index) => {
                                let view = true
                                if (key === 'Loan Intake') view = isLoanSetupOrOnHold
                                if (isLoanSetupOrOnHold) view = key === 'Loan Intake'
                                if (!view) return null
                                return (
                                  <span className="text-xs mt-1" key={index}>
                                    {key}: {item.condsByCategory[key].cleared} / {item.condsByCategory[key].all}
                                    {index == 0 ? ',' : ''}
                                  </span>
                                )
                              })}
                            </div>
                          )}
                        </td>
                        <td className="px-2 py-2 w-24">
                          {formatDate(filters.servicing ? item.servicingAsOf : item.asOf)}
                          {!filters.servicing &&
                            item.condsByCategory &&
                            item.condsByCategory.PTD &&
                            item.firstSubmittedAt && (
                              <>
                                <br />
                                <span className="text-[12px]">Age:</span>{' '}
                                {Math.abs(getDaysDiff(item.firstSubmittedAt, Date.now()))}{' '}
                                <span className="text-[11px]">Days</span>
                              </>
                            )}
                        </td>
                        {isAeAdmin && (
                          <td
                            className="px-2 py-2 hover:underline cursor-pointer"
                            onClick={() =>
                              onViewNote(item.no, item.byteproFileName !== 0 ? item.byteproFileName : item.no)
                            }
                          >
                            {formatDate(item.noteDate)}
                          </td>
                        )}
                        <td className="px-2 py-2">{thousandSeperator(item.proposedLoanAmount)}</td>
                        {filters.servicing && <td className="px-2 py-2">{item.investorCode}</td>}
                        {filters.servicing && (
                          <td className="px-2 py-2">{formatDate(item.investorCommitmentDate) || 'n/a'}</td>
                        )}
                        {filters.servicing && (
                          <td className="px-2 py-2">{formatDate(item.lastDrawIssueDate) || 'n/a'}</td>
                        )}
                        {filters.servicing && (
                          <td className="px-2 py-2 statusColor">{renderMaturity(item.maturityDate)}</td>
                        )}
                        {!filters.servicing && (
                          <td className="px-2 py-2 statusColor">{renderLockExp(item.loanStatus, item.lockExp)}</td>
                        )}
                        {!filters.servicing && (
                          <td className="px-2 py-2 min-w-[170px]">
                            <span>
                              <div className="border-b w-full mb-1">{item.accountExecutiveName || 'n/a'}</div>
                              <div className="border-b w-full mb-2">{item.brokerCompany}</div>
                              <div className="border-b w-full mb-1 text-[13px]">{item.creatorName}</div>
                              <div className="text-[13px]">{item.creatorPhone}</div>
                            </span>
                          </td>
                        )}
                        {!filters.servicing && <td className="px-2 py-2">{item.underwriterName}</td>}
                        <td className="px-2 py-2">{loanTypeMap[item.type]}</td>
                        <td className="px-2 py-2">
                          <span className="flex">
                            <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer">
                              <Link to={`/loan_process/overview/${item.no}`} target="_blank">
                                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                              </Link>
                            </span>
                            {profile.accountType == AccountType.ADMIN && (
                              <span
                                className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onTrash(item.no, index)}
                              >
                                <TrashIcon className="w-4 h-4"></TrashIcon>
                              </span>
                            )}
                          </span>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot>
                  <tr className="bg-slate-50">
                    <td colSpan={6} className="p-2 text-center font-bold">
                      Total Loan Amount:
                    </td>
                    <td colSpan={10} className="p-2 font-bold">
                      ${thousandSeperator(sumLoanAmount)}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div className="flex justify-end items-center mt-3 mb-3">
                <div className="flex-1" />
                <Pagination
                  totalCount={total}
                  itemCountPerPage={itemCountPerPage}
                  onNavigate={onPageNavigate}
                  pageNum={pageNum}
                />
                <a className="my-2 mr-5 px-2 py-1 rounded hover-shadow1" href={downloadLink} target="_blank">
                  <ArrowDownTrayIcon className="h-5 w-5 text-blue-500" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isBusinessDaysDialog && (
        <BusinessDaysDialog
          properties={businessDays}
          onOk={onUpdateBusinessDays}
          onCancel={() => setBusinessDaysDialog(false)}
        />
      )}
      {viewNote && (
        <ViewNoteDialog
          loanNumber={viewNote.no}
          loanFileName={viewNote.loanFileName}
          onClose={() => setViewNote(null)}
        />
      )}
    </div>
  )
}
