import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { COMPANY_DNS } from 'config'
import type { InputType } from 'config/input.type.constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Checkbox, Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export interface EmailParty {
  party: string
  email: string
}

export type EmailValueDir = '' | 'to' | 'cc' | 'bcc'

export type EmailValue = Record<string, EmailValueDir>

export const hasToEmails = (emails: EmailValue) => !!Object.keys(emails).filter((email) => emails[email] == 'to').length

export function EmailTo(props: {
  Data: EmailParty[]
  Emails: EmailValue
  Type?: string
  CanEdit?: boolean
  hasAddEmail?: boolean
  hasUniqueToEmail?: boolean
  onChange: (data: EmailValue) => void
  onUpdate?: (data: EmailValue) => void
}) {
  const {
    Data,
    Emails,
    Type = 'default',
    CanEdit = false,
    onUpdate = () => {},
    hasAddEmail = true,
    hasUniqueToEmail = false,
  } = props

  const { email, name } = useSelector((state: any) => state.auth.profile)

  const [data, setData] = useState<{ party: string; email: string }[]>([])
  const [emails, setEmails] = useState<any>({})
  const [editing, setEditing] = useState(false)
  const [action, setAction] = useState('')
  const [selectedEmail, setSelectedEmail] = useState('')
  const [emailFromInput, setEmailFromInput] = useState<InputType>({
    inputType: 'Select',
    title: 'Email From',
    options: {
      default: 'No Reply',
      self: name,
    },
    value: Emails.from || 'self',
    required: true,
  })
  const [newEmailInput, setNewEmailInput] = useState<InputType>({
    inputType: 'text',
    type: 'email',
    title: 'New Email',
    required: true,
  })

  const isCompanyEmail = useMemo(() => (email || '').endsWith(`@${COMPANY_DNS}`), [email])

  useEffect(() => {
    let temp: any = cloneDeep(Data)
    let existEmails = temp.map((item: any) => item.email)
    Object.keys(Emails).map((email) => {
      if (!existEmails.includes(email))
        temp.push({
          type: '',
          email,
        })
    })
    setData(temp)
  }, [Data])

  const onSetEmailFrom = (value: any) => {
    const updatedInput = cloneDeep(emailFromInput)
    updatedInput.value = value
    setEmailFromInput(updatedInput)

    if (isCompanyEmail) setSelectedEmail(value)

    props.onChange({
      ...Emails,
      from: value,
    })
  }

  const onChangeCheck = (email: string, key: string, value: boolean) => {
    const newData = cloneDeep(Emails)
    if (hasUniqueToEmail && key == 'to' && value) {
      Object.keys(newData).map((item) => {
        if (newData[item] == 'to') delete newData[item]
      })
    }
    if (value) newData[email] = key as any
    else delete newData[email]
    props.onChange(newData)
  }

  const onNewEmail = (_: string, value: string) => {
    const newInput = cloneDeep(newEmailInput)
    newInput.value = value
    newInput.error = ''
    setNewEmailInput(newInput)
  }

  const onAdd = async () => {
    const newInput = cloneDeep(newEmailInput)
    const newEmail = newInput.value
    let error = InputValidate(newEmailInput)
    if (!error) {
      const isAlreadyContain = data.map((v) => v.email).includes(newEmail)
      if (isAlreadyContain) error = 'This email is already exists.'
    }
    if (error) {
      newInput.error = error
      setNewEmailInput(newInput)
      return
    }
    const newData = cloneDeep(data)
    newData.push({ party: 'New Email', email: newEmail })
    setData(newData)
    newInput.value = ''
    newInput.error = ''
    setNewEmailInput(newInput)
  }

  const renderTable = () => {
    return (
      <div>
        {isCompanyEmail && (
          <div className="flex-1">
            <RenderInput
              Key="emailFrom"
              input={emailFromInput}
              onChange={(_: any, value: any) => {
                onSetEmailFrom(value)
              }}
            />
          </div>
        )}
        <table className="text-left text-sm w-full mb-4">
          <thead>
            <tr className="border-b cursor-pointer">
              <th className="py-2 px-2">Type</th>
              <th className="">Email</th>
              <th className="">To</th>
              <th className="">Cc</th>
              <th className="">Bcc</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ email, party }, index: number) => {
              if (email === 'from') return
              let className = index % 2 ? 'bg-gray-50' : ''
              return (
                <tr key={`tr-${email}-${index}`} className={`border-b ${className}`}>
                  <td className="py-2 px-2">{party}</td>
                  <td className="py-2 px-2">{email}</td>
                  {['to', 'cc', 'bcc'].map((key) => (
                    <td key={`check-${email}-${index}-${key}`}>
                      <Checkbox
                        title=""
                        id={`check-${email}-${index}-${key}`}
                        checked={Emails[email] == key}
                        onChange={(value) => onChangeCheck(email, key, value)}
                      />
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
        {hasAddEmail && (
          <div className="flex gap-2 w-full">
            <div className="flex-1">
              <RenderInput Key="email" input={newEmailInput} onChange={onNewEmail} />
            </div>
            <Button link onClick={onAdd} disabled={!newEmailInput.value} color="shade-blue">
              + Add
            </Button>
          </div>
        )}
      </div>
    )
  }

  const showModal = () => {
    setEmails(Emails)
    setEditing(true)
  }

  const onConfirmUpdate = async () => {
    setAction('updating')
    setEmails(Emails)
    await onUpdate(Emails)
    setAction('')
    setEditing(false)
  }

  const onClose = () => {
    setEditing(false)
    props.onChange(emails)
  }

  const renderContent = () => {
    let rlt: any
    if (Type === 'default') rlt = renderTable()
    if (Type === 'show') {
      rlt = (
        <div className="mb-4">
          {CanEdit && (
            <div
              className="flex flex-wrap gap-1 items-center justify-end text-shade-blue hover:underline cursor-pointer text-[14px]"
              onClick={showModal}
            >
              <span className="">Edit</span>
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            </div>
          )}
          <table className="w-full">
            <tbody>
              {isCompanyEmail && (
                <tr key="from">
                  <td className="px-2 py-1 border w-[30px]">
                    <span className="capitalize text-[15px]">from</span>
                  </td>
                  <td className="px-2 py-1 border">
                    <span className="flex flex-wrap gap-x-2 gap-y-1">
                      <span key={`email-from`} className="text-[14px] italic">
                        {selectedEmail === 'default' ? 'Default' : email}
                      </span>
                    </span>
                  </td>
                </tr>
              )}
              {['to', 'cc', 'bcc'].map((key, index) => {
                let values: any = []
                Object.keys(Emails).map((email: string) => {
                  if (Emails[email] === key) values.push(email)
                })

                return (
                  <tr key={index}>
                    <td className="px-2 py-1 border w-[30px]">
                      <span className="capitalize text-[15px]">{key}</span>
                    </td>
                    <td className="px-2 py-1 border">
                      <span className="flex flex-wrap gap-x-2 gap-y-1">
                        {values.map((email: string, index: number) => (
                          <span key={`email-${index}`} className="text-[14px] italic">
                            {email},
                          </span>
                        ))}
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Modal
            button={<span></span>}
            title={'Edit Emails'}
            titleOkay="Confirm"
            isOpen={editing}
            loading={action === 'updating'}
            lastUpdatedAt={Date.now()}
            onOk={onConfirmUpdate}
            onClose={onClose}
          >
            <>{renderTable()}</>
          </Modal>
        </div>
      )
    }
    return rlt
  }

  return <div className="EmailTo-container group">{renderContent()}</div>
}
