import { type InputType, loanOptionsConstants } from 'config'
import type { FormTableHeader } from 'stories/components'

export const adminHeader: FormTableHeader[] = [
  {
    key: 'propertyTypeAddress',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Property Type</div>
        <div className="border-b w-fit mb-1 border-gray-300">Property Address</div>
        <div className="border-b w-fit mb-1 border-gray-300">Property APN</div># of Units
      </span>
    ),
  },
  {
    key: 'asIsCdaValue',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Purchase Price</div>
        <div className="border-b w-fit mb-1 border-gray-300">As Is Value</div>
        <div className="border-b w-fit mb-1 border-gray-300">CDA Value</div>
        Acquisition Date
      </span>
    ),
  },
  {
    key: 'occupancyRentContract',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Occupancy</div>
        <div className="border-b w-fit mb-1 border-gray-300">Monthly Rent</div>
        Market Rent From Appraisal
      </span>
    ),
  },
  {
    key: 'annualTaxHOAHazardFlood',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Annual Tax</div>
        <div className="border-b w-fit mb-1 border-gray-300">Annual HOA Fees</div>
        <div className="border-b w-fit mb-1 border-gray-300">Annual Insurance</div>
        Annual Flood Insurance
      </span>
    ),
  },
  {
    key: 'dscrValues',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">DSCR</div>
        DSCR Based on Appraisal
      </span>
    ),
  },
  {
    key: 'status',
    title: <span>Status</span>,
  },
]

export const nonAdminHeader: FormTableHeader[] = [
  {
    key: 'propertyTypeAddress',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Property Type</div>
        <div className="border-b w-fit mb-1 border-gray-300">Property Address</div># of Units
      </span>
    ),
  },
  {
    key: 'asIsValueMonthlyRent',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Purchase Price</div>
        <div className="border-b w-fit mb-1 border-gray-300">As Is Value</div>
        Monthly Rent
      </span>
    ),
  },
  {
    key: 'annualTaxHOA',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Annual Tax</div>
        Annual HOA Fees
      </span>
    ),
  },
  {
    key: 'hazardFlood',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Annual Insurance</div>
        Annual Flood Insurance
      </span>
    ),
  },
  {
    key: 'dscrValues',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Occupancy</div>
        DSCR
      </span>
    ),
  },
  {
    key: 'status',
    title: <span>Status</span>,
  },
]

export const defaultInputs = (): Record<string, InputType> => {
  return {
    propertyAddress: {
      inputType: 'map',
      title: 'Additional Property Address',
      required: true,
      error: '',
      disabled: true,
    },
    occupancy: {
      title: 'Occupancy',
      inputType: 'select',
      hasDefaultOption: true,
      options: ['Owner Occupied', 'Non Owner Occupied', 'Partially Owner Occupied', 'Vacant', 'Partially Vacant'],
      error: '',
      required: true,
    },
    propertyType: {
      inputType: 'select',
      title: 'Property Type',
      options: loanOptionsConstants.propertyTypeBigList,
      hasDefaultOption: true,
      required: true,
      error: '',
    },
    numberOfUnits: {
      title: '# of Units',
      inputType: 'text',
      type: 'number',
      error: '',
      required: true,
    },
    propertyAPN: {
      title: 'Property APN',
      inputType: 'text',
      error: '',
      required: true,
    },
    acquisitionDate: {
      inputType: 'text',
      type: 'date',
      title: 'Acquisition Date',
      required: true,
      error: '',
    },
    purchasePrice: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Purchase Price',
      required: true,
      prefix: '$',
      error: '',
    },
    asIsValue: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'As Is Value',
      required: true,
      prefix: '$',
      error: '',
    },
    cdaValue: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'CDA Value',
      required: true,
      error: '',
      prefix: '$',
    },
    monthlyRent: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Monthly Rent',
      required: true,
      error: '',
      prefix: '$',
    },
    monthlyRentFromAppraisal: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Market Rent From Appraisal',
      required: true,
      error: '',
      prefix: '$',
    },
    annualTaxAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Annual Tax Amount',
      required: true,
      error: '',
      prefix: '$',
    },
    annualHOAFees: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Annual HOA Fees',
      required: true,
      error: '',
      prefix: '$',
    },
    insuranceAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Annual Insurance Amount',
      required: true,
      error: '',
      prefix: '$',
    },
    floodInsurance: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Annual Flood Insurance Amount',
      required: true,
      error: '',
      prefix: '$',
    },
    dscr: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'DSCR',
      readOnly: true,
      prefix: '%',
    },
    dscrBasedOnAppraisal: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'DSCR Based on Appraisal',
      readOnly: true,
      prefix: '%',
    },
  }
}

export const dscrCalcRelatedFiels = [
  'monthlyRentFromAppraisal',
  'monthlyRent',
  'annualTaxAmount',
  'annualHOAFees',
  'insuranceAmount',
  'floodInsurance',
]

export const additionalFieldsForAdmin = [
  'propertyAPN',
  'cdaValue',
  'acquisitionDate',
  'monthlyRentFromAppraisal',
  'dscrBasedOnAppraisal',
]
