import type { InputType } from 'config'

export const inputGroups: Record<string, string[]> = {
  'General Info': ['ShipperUserName', 'ServicingCode', 'ScannedPackageRec', 'ScannedPackageReview'],
  Deficiencies: [
    'DeficianceisIdentified',
    'DeficienciesCleard',
    'DeficienciesComments',
    'RepurchaseBuyback',
    'ShippingNotes',
  ],
  Investor: [
    'InvestorCode',
    'ShippedDate',
    'InvestorClearedDate',
    'InvestorSuspendedDate',
    'LoanPurchasedDate',
    'InvestorDueDate',
    'InvestorCommitmentNo',
    'InvestorCommitmentExpirationDate',
    'MasterCommitmentNo',
    'ResubmittedInvestor1',
    'ResubmittedInvestor2',
    'ResubmittedInvestor3',
    'SH03',
  ],
  'Trailing Docs': [
    'SH07',
    'SH10',
    'SH13',
    'SH08',
    'SH11',
    'SH14',
    'SH09',
    'SH12',
    'SH15',
    'CopyRecordedSecurity',
    'CopyFinalTitle',
    'SH05',
  ],
  'Warehouse Collateral': [
    'WarehouseLenderCode',
    'SH02',
    'SH01',
    'NoteBackToWarehouseDate',
    'CollateralPackSentDate',
    'DocsBackDate',
  ],
  'Tracking Info': [
    'WarehouseLenderCode',
    'WarehouseTrackNumber',
    'SH18',
    'TrackingInvestorName',
    'SH17',
    'SH19',
    'SH06',
  ],
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    ShipperUserName: {
      inputType: 'text',
      type: 'text',
      title: 'Shipper Name',
      value: '',
    },
    ServicingCode: {
      inputType: 'select',
      options: [],
      title: 'Servicing',
      hasDefaultOption: true,
      sort: true,
      value: '',
    },
    ScannedPackageRec: {
      inputType: 'text',
      type: 'date',
      title: 'Scanned Pkg. Received',
      value: '',
    },
    ScannedPackageReview: {
      inputType: 'text',
      type: 'date',
      title: 'Scanned Pkg. Completed',
      value: '',
    },
    DeficianceisIdentified: {
      inputType: 'text',
      type: 'date',
      title: 'Deficiencies Identified',
      value: '',
    },
    DeficienciesCleard: {
      inputType: 'text',
      type: 'date',
      title: 'Deficiencies Cleared',
      value: '',
    },
    DeficienciesComments: {
      inputType: 'text',
      type: 'date',
      title: 'Deficiencies Comments',
      value: '',
    },
    RepurchaseBuyback: {
      inputType: 'text',
      type: 'date',
      title: 'Repurchase Buyback',
      value: '',
    },
    ShippingNotes: {
      inputType: 'textarea',
      title: 'Deficiencies Comments',
      value: '',
      span: 2,
      rows: 2,
    },
    InvestorCode: {
      inputType: 'select',
      options: [],
      title: 'Investor',
      sort: true,
      hasDefaultOption: true,
      value: '',
    },
    ShippedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Shipped to Investor',
      value: '',
    },
    InvestorClearedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Investor Cleared Date',
      value: '',
    },
    InvestorSuspendedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Investor Suspended Date',
      value: '',
    },
    LoanPurchasedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Sold to Investor',
      value: '',
    },
    InvestorDueDate: {
      inputType: 'text',
      type: 'date',
      title: 'Investor Due Date',
      value: '',
    },
    InvestorCommitmentNo: {
      inputType: 'text',
      title: 'Investor Commitment No',
      value: '',
    },
    InvestorCommitmentExpirationDate: {
      inputType: 'text',
      type: 'date',
      title: 'Investor Commitment Exp.',
      value: '',
    },
    MasterCommitmentNo: {
      inputType: 'text',
      title: 'Master Commitment No',
      value: '',
    },
    ResubmittedInvestor1: {
      inputType: 'text',
      type: 'date',
      title: 'Resubmitted to Investor 1',
      value: '',
    },
    ResubmittedInvestor2: {
      inputType: 'text',
      type: 'date',
      title: 'Resubmitted to Investor 2',
      value: '',
    },
    ResubmittedInvestor3: {
      inputType: 'text',
      type: 'date',
      title: 'Resubmitted to Investor 3',
      value: '',
    },
    SH03: {
      inputType: 'textarea',
      title: 'Investor Notes',
      value: '',
      rows: 2,
      span: 2,
    },
    WarehouseLenderCode: {
      inputType: 'text',
      title: 'Warehouse Lender Code',
      value: '',
    },
    SH02: {
      inputType: 'text',
      title: 'Collateral Tracking No',
      value: '',
    },
    SH01: {
      inputType: 'text',
      type: 'date',
      title: 'Collateral Returned',
      value: '',
    },
    NoteBackToWarehouseDate: {
      inputType: 'text',
      type: 'date',
      title: 'Note Returned to Warehouse Bank',
      value: '',
    },
    CollateralPackSentDate: {
      inputType: 'text',
      type: 'date',
      title: 'Collateral Sent',
      value: '',
    },
    DocsBackDate: {
      inputType: 'text',
      type: 'date',
      title: 'Collateral Received',
      value: '',
    },
    SH07: {
      inputType: 'text',
      type: 'date',
      title: `Recorded Mtg/Deed Rec'd`,
      value: '',
    },
    SH10: {
      inputType: 'text',
      type: 'date',
      title: 'Passed Audit 1',
      value: '',
    },
    SH13: {
      inputType: 'text',
      type: 'date',
      title: 'Shipped to Custodian 1',
      value: '',
    },
    SH08: {
      inputType: 'text',
      type: 'date',
      title: `Final Title Policy Rec'd`,
      value: '',
    },
    SH11: {
      inputType: 'text',
      type: 'date',
      title: 'Passed Audit 2',
      value: '',
    },
    SH14: {
      inputType: 'text',
      type: 'date',
      title: 'Shipped to Custodian 2',
      value: '',
    },
    SH09: {
      inputType: 'text',
      type: 'date',
      title: 'Original Note Date',
      value: '',
    },
    SH12: {
      inputType: 'text',
      type: 'date',
      title: 'Passed Audit 3',
      value: '',
    },
    SH15: {
      inputType: 'text',
      type: 'date',
      title: 'Shipped to Custodian 3',
      value: '',
    },
    CopyRecordedSecurity: {
      inputType: 'text',
      type: 'date',
      title: 'Copy - Recorded Security Instrument',
      value: '',
    },
    CopyFinalTitle: {
      inputType: 'text',
      type: 'date',
      title: 'Copy - Final Title Policy',
      value: '',
    },
    SH05: {
      inputType: 'textarea',
      title: `Trailing Doc's Notes`,
      value: '',
      rows: 2,
      span: 2,
    },
    WarehouseTrackNumber: {
      inputType: 'text',
      title: 'Warehouse Tracking Number',
      value: '',
    },
    SH18: {
      inputType: 'text',
      type: 'date',
      title: 'Warehouse Tracking Date',
      value: '',
    },
    TrackingInvestorName: {
      inputType: 'text',
      title: 'Investor Name',
      value: '',
    },
    SH17: {
      inputType: 'text',
      type: 'number',
      title: 'Investor Tracking Number',
      value: '',
    },
    SH19: {
      inputType: 'text',
      type: 'date',
      title: 'Investor Tracking Date',
      value: '',
    },
    SH06: {
      inputType: 'textarea',
      title: `Tracking Notes`,
      value: '',
      rows: 2,
      span: 2,
    },
  }
}
