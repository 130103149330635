import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import React, { useState } from 'react'
import { confirm, thousandSeperator } from 'utils'

import { EditAccountingRecordContent } from './EditAccountingRecordContent'
import type { IAccountingRecord } from './types'

export const SubRecordTable = ({
  type,
  data,
  onChange,
}: {
  type: string
  data: IAccountingRecord[]
  onChange: Function
}) => {
  const [curEditItem, setCurEditItem] = useState<IAccountingRecord | null>()

  const onUpdateEditItem = (item: IAccountingRecord | null) => {
    setCurEditItem(undefined)
    if (!item) return

    const newData = cloneDeep(data)
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) newData.push(item)
    else newData[index] = item

    onChange(newData)
  }

  const onDelete = async (item: IAccountingRecord) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this item?
        <br />
        <span className="text-gray-600">Description: {item.description}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(data)
    const index = data.findIndex((v) => v.id == item.id)
    newData.splice(index, 1)

    onChange(newData)
    if (curEditItem && item.id == curEditItem.id) setCurEditItem(undefined)
  }

  return (
    <div className="p-2 text-sm w-full">
      <div className="flex items-center justify-between">
        <p className="font-semibold mb-2">Records</p>
        <span className="text-shade-blue hover:underline cursor-pointer" onClick={() => setCurEditItem(null)}>
          + Add
        </span>
      </div>

      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {['No', 'Loan Number', 'Amount', 'Description', 'Borrower Name', 'Address', ''].map((v) => (
              <th scope="col" className="px-3 py-1" key={`header-${v}`}>
                {v}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={`SubRow-${item.id}`}>
              <tr
                className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}
                key={`balance-${item.id}-${index}`}
              >
                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {index + 1}
                </td>

                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {item.loanNumber}
                </td>

                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {thousandSeperator(item.amount)}
                </td>

                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {item.description}
                </td>

                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {item.borrowerName}
                </td>

                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {item.subjectPropertyAddress}
                </td>

                <td className="px-3 py-1">
                  <div className="flex gap-2">
                    <span
                      className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                      onClick={() => setCurEditItem(item)}
                    >
                      <PencilSquareIcon className="w-4 h-4" />
                    </span>

                    <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                      <TrashIcon className="w-4 h-4" />
                    </span>
                  </div>
                </td>
              </tr>

              {curEditItem && curEditItem.id == item.id ? (
                <tr className="bg-gray-100">
                  <td></td>
                  <td colSpan={6} className="pr-10">
                    <EditAccountingRecordContent type={type} item={curEditItem} onClose={onUpdateEditItem} />
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {curEditItem === null && (
        <div className="text-gray-900">
          <EditAccountingRecordContent type={type} item={null} onClose={onUpdateEditItem} />
        </div>
      )}
    </div>
  )
}
