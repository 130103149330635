import { validateLoanStructureData } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { LoanType } from 'reducers/loanDetail.reducer'
import { hasAuthPermission } from 'utils/hasAuthPermission'

type menuType = {
  [key: string]: string
}

export const getAppBorrowerMenu = (borrowerApplication: boolean = false) => {
  const { loan } = store.getState()
  let isCorrespondentLoan = loan.type == LoanType.CORRESPONDENT

  let rlt: menuType = {
    borrower: 'Borrower Information',
    property: 'Property Information',
    asset: 'Asset & Liability',
    track: 'Track Record',
    hmda: 'Declarations & HMDA',
    sign: 'Acknowledgement & Sign',
    credit: 'Credit Score',
    additionalMember: 'Additional Entity Members',
  }
  if (borrowerApplication) delete rlt.credit
  if (borrowerApplication) delete rlt.additionalMember
  if (isCorrespondentLoan) {
    // delete rlt.sign
    delete rlt.credit
  }
  return rlt
}

export const getAppCoBorrowerMenu = (borrowerApplication: boolean = false) => {
  const { loan } = store.getState()
  let isCorrespondentLoan = loan.type == LoanType.CORRESPONDENT

  let rlt: menuType = {
    borrower2: 'Borrower Information',
    hmda2: 'Declarations & HMDA',
    sign2: 'Acknowledgement',
    credit2: 'Credit Score',
  }
  if (borrowerApplication) delete rlt.credit2
  if (isCorrespondentLoan) {
    delete rlt.credit2
  }
  return rlt
}

const getMapKeys = () => {
  const borrowerMenus = getAppBorrowerMenu()
  const coBorrowerMenus = getAppCoBorrowerMenu()
  return {
    ...borrowerMenus,
    ...coBorrowerMenus,
  }
}

const scoreValidateMenus: any = {
  borrower: ['property', 'borrower', 'asset', 'track', 'hmda', 'sign'],
  coBorrower: ['borrower2', 'hmda2', 'sign2'],
}

const signValidateMenus: any = {
  borrower: ['property', 'borrower', 'asset', 'track', 'hmda'],
  coBorrower: ['borrower2', 'hmda2'],
}

export const pullScoreValidation = (borrowerSeperator: string): string[] => {
  const { step, auth } = store.getState()
  const noRequireSignValidation = hasAuthPermission(auth, 'ADMIN_TO_AE_PROFILE_PERMISSION')
  const { application } = step
  let rlt: any = []
  const mapKeys = getMapKeys()
  scoreValidateMenus[borrowerSeperator].map((key: string) => {
    if (key == 'asset') return
    if (application[key] === -1) {
      if (['sign', 'sign2'].indexOf(key) !== -1 && noRequireSignValidation) {
      } else {
        let text = ''
        if (borrowerSeperator === 'coBorrower') text = `Co-Borrower's `
        rlt.push(`- ${text} ${mapKeys[key]} Need to be Completed!`)
      }
    }
  })
  return rlt
}

export const signApplictionValidation = () => {
  const { step, borrower, loanDetail, loan } = store.getState()
  const { application } = step
  const { includeCoborrower } = loanDetail
  let borrowerKeys = ['borrower']
  if (includeCoborrower) borrowerKeys.push('coBorrower')
  let rlt: any = []
  const mapKeys = getMapKeys()
  const loanStructureErrors = validateLoanStructureData(loan, loanDetail.rateData)
  rlt.push(...loanStructureErrors)
  borrowerKeys.map((borrowerSeperator) => {
    signValidateMenus[borrowerSeperator].map((key: string) => {
      if (key == 'asset') return
      if (application[key] === -1) {
        let text = ''
        if (borrowerSeperator === 'coBorrower') text = `Co-Borrower's `
        rlt.push(`${text} ${mapKeys[key]} Need to be Completed!`)
      }
    })
  })
  if (![LoanType.CORRESPONDENT, LoanType.TABLEFUNDER].includes(loanDetail.loanType)) {
    if (borrower.borrower.agreeToSign !== true) rlt.push(`Borrower Need to Agree on Acknowledgement!`)
    if (includeCoborrower && borrower.coBorrower.agreeToSign !== true)
      rlt.push(`Co-Borrower Need to Agree on Acknowledgement!`)
  }
  return rlt
}
