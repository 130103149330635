import { API_SECONDARY_MARKETING_SCREEN_DATA } from 'config'
import Api from 'services/api'

export const getSecondaryMarketingScreenData = () => {
  return Api.get(API_SECONDARY_MARKETING_SCREEN_DATA)
}

export const postSecondaryMarketingScreenData = (data: Record<string, any>) => {
  return Api.post(API_SECONDARY_MARKETING_SCREEN_DATA, data)
}
