import type { InputType } from 'config'

import { FormTable, FormTableHeader } from './FormTable'

interface InputFormTableProps {
  inputs: Record<string, InputType>

  data: Record<string, any>[]

  onChange: (data: Record<string, any>[]) => void

  cols?: number

  error?: string

  permission?: number
}

export const InputFormTable = ({
  inputs = {},
  data = [],
  onChange = () => {},
  cols = 2,
  error,
  permission = 1,
}: InputFormTableProps) => {
  const headers: FormTableHeader[] = []
  Object.keys(inputs).forEach((key) => {
    headers.push({
      key,
      title: inputs[key].title,
    })
  })

  return (
    <div className={`${permission === 3 ? 'bg-gray-100 p-3 border' : ''} rounded-t ${error && 'border-rose-700'}`}>
      <FormTable
        header={headers}
        defaultData={data}
        inputs={inputs}
        cols={cols}
        onChange={onChange}
        permission={permission}
      />
      {error && (
        <div
          className="mx-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[14px]"
          role="alert"
        >
          {error}
        </div>
      )}
    </div>
  )
}
