import { calculateLoanLimits, calculateLTV, visibleLoansLogic } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { removeComma } from 'utils'

export enum LoanSubmissionPdfType {
  APPROVAL = 0,
  APPROVAL_ALL = 1,
  COMMITMENT = 2,
  Loan_SETUP_SUSPENSE = 3,
}

export const loanSubmissionPdfData = async () => {
  const { loanDetail, borrower, loan } = await store.getState()
  let data: any = {
    loanNumber: loanDetail.byteproFileName === 0 ? loanDetail.loanNumber : loanDetail.byteproFileName,
    ltv: calculateLTV(),
    loanStatus: loanDetail.loanStatus,
    rate: loanDetail.rateData.rate,
    expireDate: loanDetail.rateData.expireDate,
    pricingType: `${loanDetail.rateData.type}`,
    program: `${loanDetail.rateData.program}`,
    borrowerName: `${borrower.borrower.firstName} ${borrower.borrower.lastName}`,
    coBorrowerName: loanDetail.includeCoborrower
      ? `${borrower.coBorrower.firstName} ${borrower.coBorrower.lastName}`
      : '',
    entityTitle: borrower.borrower.hasEntityTitle ? borrower.borrower.entityTitle : '',
    creatorName: loanDetail.parties?.creator?.name,
    processorName: loanDetail.parties?.loanProcessor?.name,
    officerName: loanDetail.parties?.loanOfficer?.name,
    underwriterName: loanDetail.parties?.underwriter?.name,
    ...loan,
  }

  const visibles = visibleLoansLogic()
  if (visibles.indexOf('afterRepairValue') !== -1) data.afterRepairValue = loan.afterRepairValue
  data.initialLoanAmount = removeComma(loan.proposedLoanAmount)
  if (visibles.indexOf('constructionReserve') !== -1) data.initialLoanAmount -= removeComma(loan.constructionReserve)
  const limits = calculateLoanLimits(loan)
  data.ltc = removeComma(limits.ltc) === 0 ? '-' : limits.ltc
  data.ltp = removeComma(limits.ltp) === 0 ? '-' : limits.ltp
  data.arv_ltv = removeComma(limits.arv_ltv) === 0 ? '-' : limits.arv_ltv

  Object.keys(data).map((key) => {
    if (!data[key]) data[key] = ''
    else data[key] = data[key].toString()
  })
  return data
}
