import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => ({
  // type: {
  //   inputType: 'select',
  //   title: 'Draw / Permits',
  //   options: [],
  //   sort: true,
  //   defaultOptionText: '',
  //   hasDefaultOption: true,
  //   required: false,
  // },
  amount: {
    inputType: 'text',
    type: 'thousandSep',
    title: 'Amount Requested',
    prefix: '$',
    required: true,
  },
  text: {
    inputType: 'custom',
    title: '',
    render: () => <div />,
  },
  description: {
    inputType: 'TextArea',
    title: 'Work Description',
    required: true,
  },
  note: {
    inputType: 'TextArea',
    title: 'Note',
    required: false,
  },
})
