import type { InputType } from 'config'
import type { ParagraphComponent } from 'utils/RenderParagraph'

export const paragraph = (inputs: Record<string, InputType>): ParagraphComponent[][] => {
  return [
    [
      {
        input: { inputType: 'span', title: 'Loan Number:' },
      },
      {
        input: inputs.loanNumber,
        Key: 'loanNumber',
        className: 'col-span-2 flex items-end w-96',
      },
      {
        input: { inputType: 'span', title: 'Date:' },
      },
      {
        input: inputs.date,
        Key: 'date',
        className: 'col-span-2 flex items-end w-96',
      },
      {
        input: { inputType: 'span', title: 'Lender:' },
      },
      {
        input: inputs.lender,
        Key: 'lender',
        className: 'col-span-2 flex items-end w-96',
      },
      {
        input: { inputType: 'span', title: 'Borrower(s):' },
      },
      {
        input: inputs.borrower,
        Key: 'borrower',
        className: 'col-span-2 flex items-end w-96',
      },
      {
        input: { inputType: 'span', title: 'Property Address:' },
      },
      {
        input: inputs.propertyAddress,
        Key: 'propertyAddress',
        className: 'col-span-2 flex items-end w-96',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'The servicing of your mortgage loan is being transferred, effective' },
        needSplit: true,
      },
      {
        input: inputs.transferEffectiveDate,
        Key: 'transferEffectiveDate',
        className: 'ml-2 mt-2',
      },
      {
        input: {
          inputType: 'span',
          title:
            'This means that after this date, a new servicer will be collecting your mortgage loan payments from you. Nothing else about your mortgage loan will change.',
        },
      },
      {
        input: inputs.lender,
        Key: 'lender',
        className: 'mr-2 mt-2 w-80',
      },
      {
        input: {
          inputType: 'span',
          title: 'is now collecting your payments.',
        },
        needSplit: true,
      },
    ],
    [
      {
        input: inputs.lender,
        Key: 'lender',
        className: 'mr-1 mt-2 w-80',
      },
      {
        input: {
          inputType: 'span',
          title: 'will stop accepting payments received from you after',
        },
        needSplit: true,
      },
      {
        input: inputs.stopPaymentReceivedate,
        Key: 'stopPaymentReceivedate',
        className: 'ml-1 mt-2',
      },
    ],
    [
      {
        input: inputs.servicingCompany,
        Key: 'servicingCompany',
        className: 'mr-1 mt-2 w-80',
      },
      {
        input: {
          inputType: 'span',
          title:
            'will collect your payments going forward. Your new servicer will start accepting payments received from you on',
        },
        needSplit: true,
      },
      {
        input: inputs.transferEffectiveDate,
        Key: 'transferEffectiveDate',
        className: 'ml-1 mt-2',
      },
    ],
    [
      {
        input: {
          inputType: 'span',
          title: 'SEND ALL PAYMENTS DUE ON OR AFTER',
        },
        needSplit: true,
      },
      {
        input: inputs.transferEffectiveDate,
        Key: 'transferEffectiveDate',
        className: 'ml-1 mr-2 mt-2',
      },
      {
        input: {
          inputType: 'span',
          title: 'TO',
        },
        needSplit: true,
      },
      {
        input: inputs.servicingCompany,
        Key: 'servicingCompany',
        className: 'ml-1 mt-2 w-80',
      },
    ],
    [
      {
        input: {
          inputType: 'span',
          title: 'AT THIS ADDRESS:',
        },
        needSplit: true,
      },
      {
        input: inputs.servicingCompanyAddress,
        Key: 'servicingCompanyAddress',
        className: 'ml-1 mt-2 w-96',
      },
    ],
    [
      {
        input: {
          inputType: 'span',
          title: 'If you have any questions for either your present servicer,',
        },
        needSplit: true,
      },
      {
        input: inputs.lender,
        Key: 'lender',
        className: 'ml-1 mr-2 mt-2 w-80',
      },
      {
        input: {
          inputType: 'span',
          title: 'or your new servicer',
        },
        needSplit: true,
      },
      {
        input: inputs.servicingCompany,
        Key: 'servicingCompany',
        className: 'ml-1 mr-2 mt-2 w-80',
      },
      {
        input: {
          inputType: 'span',
          title: 'about your mortgage loan or this transfer, please contact them using the information below:',
        },
        needSplit: true,
      },
    ],
    [
      {
        input: {
          inputType: 'span',
          title: 'Current Servicer:',
        },
      },
      {
        input: {
          inputType: 'span',
          title: 'New Servicer:',
        },
      },
      {
        input: inputs.lender,
        Key: 'lender',
        className: 'w-80',
      },
      {
        input: inputs.servicingCompany,
        Key: 'servicingCompany',
        className: 'w-80',
      },
      {
        input: inputs.currentServicerPhone,
        Key: 'currentServicerPhone',
        className: 'w-80',
      },
      {
        input: inputs.newServicerPhoneHours,
        Key: 'newServicerPhoneHours',
        className: 'w-80',
      },
      {
        input: inputs.currentServicerAddress,
        Key: 'currentServicerAddress',
        className: 'w-80',
      },
      {
        input: inputs.newServicerPhone,
        Key: 'newServicerPhone',
        className: 'w-80',
      },
      {
        input: {
          inputType: 'span',
          title: '',
        },
      },
      {
        input: inputs.newServicerAddress,
        Key: 'newServicerAddress',
        className: 'w-80',
      },
      {
        input: {
          inputType: 'span',
          title: '',
        },
      },
      {
        input: inputs.servicingAttn,
        Key: 'servicingAttn',
        className: 'w-80',
      },
    ],
  ]
}
