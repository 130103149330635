import type { InputType } from 'config'
import { Button } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'
import { renderParagraph } from 'utils/RenderParagraph'

import { PostClosingAssignmentPdfType } from './constants'
import { securityDeedParagraphs } from './securityDeedConstants'

export function SecurityDeed({
  inputs,
  inputGroups,
  onChange,
  onGenerate,
  download,
}: {
  inputs: Record<string, InputType>
  inputGroups: Record<string, string[]>
  onChange: Function
  onGenerate: Function
  download: number
}) {
  const paragraphObj = securityDeedParagraphs(inputs)
  const inputGroup = inputGroups['Legal Info']

  return (
    <div>
      <p className="text-center text-lg font-bold mt-5">Assignment of Security Deed</p>

      {renderParagraph(paragraphObj, 0, onChange)}

      <div className="grid grid-cols-3 gap-4 mt-20">{renderParagraph(paragraphObj, 1, onChange)}</div>

      <div className="flex flex-wrap items-end mt-12">{renderParagraph(paragraphObj, 2, onChange)}</div>

      <div className="flex flex-wrap items-end mt-2">{renderParagraph(paragraphObj, 3, onChange)}</div>

      <div className="flex flex-wrap items-end mt-14">{renderParagraph(paragraphObj, 4, onChange)}</div>

      <p className="text-center text-lg font-bold mt-16">Exhibit A</p>

      <div className="mb-5">
        <p className="border-b font-bold text-sm w-full mb-2">Legal Info</p>
        <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
          {inputGroup.map((key: string) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex justify-center mt-8">
        <Button
          loading={download === PostClosingAssignmentPdfType.SecurityDeed}
          onClick={() => onGenerate(PostClosingAssignmentPdfType.SecurityDeed)}
        >
          Assignment of Security Deed
        </Button>
      </div>
    </div>
  )
}
