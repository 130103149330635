import { useEffect, useState } from 'react'

export const PlainInput2 = (props: {
  value: string
  content: string
  onChange: Function
  type?: 'text' | 'number'
  className?: string
  disabled?: boolean
  editable?: boolean
  isEditing?: boolean
  origin?: string
  prefix?: string
  suffix?: string
}) => {
  const { value, content, onChange, type = 'text', className = '', disabled = false, editable = true } = props

  const [isEditing, setEditing] = useState(false)
  const [editingValue, setEditingValue] = useState('')

  useEffect(() => {
    setEditingValue(String(value))
  }, [value])

  useEffect(() => setEditing(!!props?.isEditing), [props.isEditing])

  const onUpdate = () => {
    setEditing(false)
    setEditingValue(editingValue.trim())
    onChange(editingValue.trim())
  }

  return isEditing ? (
    <input
      tabIndex={0}
      autoFocus
      type={type}
      value={editingValue}
      disabled={disabled}
      onChange={(e) => setEditingValue(e.target.value)}
      className="w-full leading-4 p-1 block rounded-t border-gray-300 focus:border-sky-600 text-[14px] focus:outline-none focus:ring-0"
      onBlur={onUpdate}
    />
  ) : (
    <span
      tabIndex={0}
      className={`${
        editable ? 'cursor-pointer' : 'cursor-not-allowed'
      } p-1 text-black font-variation-settings-600 w-full ${className} ${disabled ? 'cursor-text' : ''}`}
      onFocus={() => {
        if (!editable) return
        if (!disabled) setEditing(true)
        if (props.origin !== undefined) setEditingValue(props.origin)
      }}
      onClick={() => {
        if (!editable) return
        if (!disabled) setEditing(true)
        if (props.origin !== undefined) setEditingValue(props.origin)
      }}
    >
      <div className="inline-block">
        {props.prefix && props.prefix} {content} {props.suffix && props.suffix}
      </div>
    </span>
  )
}
