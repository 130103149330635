import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Appraisal2StatusType } from 'pages/Loan'
import { thousandSeperator } from 'utils/convertor'

interface InputProps {
  status: string
  count: number
  color: string
  colorDeep?: number
  percent: number
  download?: string
  viewStatus: Function
}

export function AppraisalStatisticsItem({
  status,
  count,
  color,
  colorDeep = 100,
  percent,
  download = '',
  viewStatus,
}: InputProps) {
  return (
    <div className="StatusDetail-container w-80 my-1">
      <div
        className={`bg-${color}-${colorDeep} rounded-l-lg border-l-[6px] border-${color}-${
          colorDeep + 200
        } text-${color}-${colorDeep + 600} px-2 md:px-4 py-2 md:py-3`}
        role="alert"
      >
        <p className="flex items-center justify-between mb-2">
          <span className="cursor-pointer hover:underline" onClick={() => viewStatus()}>
            {Appraisal2StatusType[status]}
          </span>
          {download && (
            <a className="ml-4 hover-shadow1 px-2 rounded hover:cursor-pointer" href={download}>
              <ArrowDownTrayIcon className="h-5 w-5" />
            </a>
          )}
        </p>

        <p className="flex justify-between">
          <span className="cursor-pointer hover:underline" onClick={() => viewStatus()}>
            {thousandSeperator(count)} <span className="text-[14px]">Appraisals</span>
          </span>
          <span className="font-semibold">{percent.toFixed(2)}%</span>
        </p>
      </div>
    </div>
  )
}
