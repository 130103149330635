import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { finresiMapConstants } from 'config'
import { ToggleButton } from 'stories/components'

import { foreclosuresOptions } from '../../Commercial/LeverageAdjustments'
import type { IDscrFirstTimeInvestorLeverageData } from '../../interface'
import { convertNullToBlankValueToDecimal } from '../../logic'
import { DscrFirstTimeInvestorValueLeverageLimits } from './DscrFirstTimeInvestorValueLeverageLimits'

export const DscrFirstTimeInvestorLeverageMaxLimits = ({ data }: { data: IDscrFirstTimeInvestorLeverageData[] }) => {
  return (
    <div className="h-full">
      {!!data.length && !!data[0].limits.length ? (
        <div>
          <ToggleButton
            id="firstTimeInvestor"
            title="First Time Home Investor?"
            disabled={true}
            value={true}
            className="mb-2"
            label={['Yes', 'No']}
          />

          {data[0].limits.map((item, index) => (
            <table key={index} className={`text-sm w-full rounded-lg shadow text-center my-4`}>
              <thead className="bg-gray-100">
                <tr>
                  <th rowSpan={2} className="p-1 border">
                    FICO
                  </th>
                  <th rowSpan={2} className="p-1 border">
                    Loan Purpose
                  </th>
                  <th colSpan={2} className="p-1 border">
                    Loan Amount
                  </th>
                  <th rowSpan={2} className="p-1 border w-[90px]">
                    Min DSCR
                  </th>
                  <th rowSpan={2} className="p-1 border w-[100px]">
                    Min Months Reserve
                  </th>
                  <th colSpan={2} className="p-1 border">
                    LTV
                  </th>
                  <th rowSpan={2} className="p-1 border w-[90px]">
                    Max CLTV
                  </th>
                </tr>
                <tr>
                  <th className="p-1 border w-[120px]">From</th>
                  <th className="p-1 border w-[120px]">To</th>
                  <th className="p-1 border w-[65px]">Min</th>
                  <th className="p-1 border w-[65px]">Max</th>
                </tr>
              </thead>

              <tbody>
                <>
                  <tr>
                    <td rowSpan={3} className="px-2 py-1 border">
                      {item.ficoRange.from} ― {item.ficoRange.to}
                    </td>
                    <td className="border px-2 py-1">Purchase</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-2 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                      </td>
                    ))}
                    <td className="border px-2 py-1">{item.minDSCR.purchase}</td>
                    <td className="border px-2 py-1">{item.minMonthsReserve.purchase}</td>
                    <td className="border px-2 py-1">{item.limit.purchase.ltv.min}</td>
                    <td className="border px-2 py-1">{item.limit.purchase.ltv.max}</td>
                    <td className="border px-2 py-1">{item.limit.purchase.cltv}</td>
                  </tr>

                  <tr>
                    <td className="border px-2 py-1">No-Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-2 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-2 py-1">{item.minDSCR.nocashout}</td>
                    <td className="border px-2 py-1">{item.minMonthsReserve.nocashout}</td>
                    <td className="border px-2 py-1">{item.limit.nocashout.ltv.min}</td>
                    <td className="border px-2 py-1">{item.limit.nocashout.ltv.max}</td>
                    <td className="border px-2 py-1">{item.limit.nocashout.cltv}</td>
                  </tr>

                  <tr>
                    <td className="border px-2 py-1">Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-2 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-2 py-1">{item.minDSCR.cashout}</td>
                    <td className="border px-2 py-1">{item.minMonthsReserve.cashout}</td>
                    <td className="border px-2 py-1">{item.limit.cashout.ltv.min}</td>
                    <td className="border px-2 py-1">{item.limit.cashout.ltv.max}</td>
                    <td className="border px-2 py-1">{item.limit.cashout.cltv}</td>
                  </tr>

                  <tr>
                    <td colSpan={9} className="border p-3">
                      <DscrFirstTimeInvestorValueLeverageLimits
                        title="Bankrupcty"
                        data={item.bankruptcy}
                        options={finresiMapConstants.bankruptcy}
                      />

                      <DscrFirstTimeInvestorValueLeverageLimits
                        title="FC/SS/DIL"
                        data={item.fcSsDil}
                        options={foreclosuresOptions}
                      />

                      <DscrFirstTimeInvestorValueLeverageLimits
                        title="Mortgage Lates"
                        data={item.mortgageLates}
                        options={finresiMapConstants.mortgageLates}
                      />
                    </td>
                  </tr>
                </>
              </tbody>
            </table>
          ))}
        </div>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )}
    </div>
  )
}
