import { IS_MAIN_COMPANY } from 'config'
import { LoanOverview } from 'pages'
import { ForgetPassword, Login, Register, ResetPassword } from 'pages/Auth'
import { AboutUs, Careers, ContactUs, FAQs, Home, HowItWorks, Investors, Pricing, WhereWeLend } from 'pages/Base'
import { BorrowerCreateLoan } from 'pages/Borrower'
import { Resources } from 'pages/Resources'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ProtectedRoutes } from './protected.route'

export function BaseRoute() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={IS_MAIN_COMPANY ? '/home' : '/login'} />
      </Route>
      <Route exact path="/home">
        {IS_MAIN_COMPANY ? <Home /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/about_us">
        <AboutUs />
      </Route>
      <Route exact path="/investors">
        <Investors />
      </Route>
      <Route exact path="/how_it_works">
        <HowItWorks />
      </Route>
      <Route exact path="/where_we_lend">
        <WhereWeLend />
      </Route>
      <Route exact path="/pricing">
        <Pricing />
      </Route>
      <Route exact path="/faqs">
        <FAQs />
      </Route>
      <Route exact path="/contacts">
        <ContactUs />
      </Route>
      <Route exact path="/careers">
        <Careers />
      </Route>
      <Route exact path="/resources">
        <Resources />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/forgetPassword">
        <ForgetPassword />
      </Route>
      <Route exact path="/resetPassword/:selector/:token">
        <ResetPassword />
      </Route>
      <Route exact path="/loan_process/:action/new">
        <LoanOverview />
      </Route>
      <Route exact path="/borrowerPipeline/new" key={11}>
        <BorrowerCreateLoan />
      </Route>
      <ProtectedRoutes />
    </Switch>
  )
}
