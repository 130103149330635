import cloneDeep from 'clone-deep'
import { INVALID_ALL_INPUTS } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { downloadFile, getPostClosingFields, submitPostClosingPDF, uploadFiles } from 'services'
import Api from 'services/api'
import { ButtonGroup } from 'stories/components'
import { InputConvert, InputValidate, REQUIRED_FIELD_ERROR_MESSAGE } from 'utils'

import { confirmGenerate } from '../ConfirmGenerateDialog'
import { defaultInputs, inputGroups } from './constants'
import { DeedOfTrust } from './DeedOfTrust'
import { Mortage } from './Mortage'
import { SecurityDeed } from './SecurityDeed'

const assignmentTypeOptions: Record<string, string> = {
  mortage: 'Assignment of Mortgage',
  trust: 'Assignment of Deed Of Trust',
  security: 'Assignment of Security Deed',
}

const assignmentComponents: Record<string, Function> = {
  mortage: Mortage,
  trust: DeedOfTrust,
  security: SecurityDeed,
}

export function Assignment(props: any) {
  const [inputs, setInputs] = useState(defaultInputs())
  const [download, setDownload] = useState(-1)
  const [assignmentType, setAssignmentType] = useState('mortage')

  const changeAssignmentNames = (inputs: any, options: any) => {
    for (let i = 1; i <= 7; i += 1) {
      inputs[`assignorEntityName${i}`].options = options
    }
    return inputs
  }

  useEffect(() => {
    let nInputs = cloneDeep(inputs)
    props.setLoading(true)
    getPostClosingFields('assignment').then((data) => {
      nInputs = changeAssignmentNames(nInputs, props.assignmentNames)
      Object.keys(nInputs).forEach((key) => {
        nInputs[key].value = data[key] || ''
        nInputs[key].error = InputValidate(nInputs[key])
      })
      setInputs(nInputs)
      props.setLoading(false)
    })
  }, [])

  useEffect(() => {
    let nInputs = cloneDeep(inputs)
    nInputs = changeAssignmentNames(nInputs, props.assignmentNames)
    setInputs(nInputs)
  }, [props.assignmentNames])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
  }

  const onGenerate = async (type: number) => {
    const newInputs = cloneDeep(inputs)
    const values: Record<string, any> = {}
    let hasError = false
    Object.keys(newInputs).forEach((key) => {
      const { value } = newInputs[key]
      // if (error) hasError = true
      values[key] = value
    })
    if (values.legalType == 'attach' && !values.legalFile) {
      newInputs.legalFile.error = REQUIRED_FIELD_ERROR_MESSAGE
      hasError = true
      setInputs(newInputs)
    }
    if (hasError) {
      toast(INVALID_ALL_INPUTS, { type: 'error' })
      return
    }

    const result = await confirmGenerate('Assignment')
    if (!result) return

    props.setLoading(true)
    setDownload(type)

    if (values.legalFile instanceof File) {
      const loanNumber = Api.getLoanNumber()
      const data = {
        path: `loan/${loanNumber}/postClosing`,
      }
      const [legalFileKey] = await uploadFiles(data, [values.legalFile])
      values.legalFile = legalFileKey
    }

    values.result = result
    values.type = type
    const res = await submitPostClosingPDF('assignment', values)
    if (result !== 'email') downloadFile(`Assignment - ${values.loanNumber}.pdf`, res)
    toast('Successfully completed', { type: 'success' })
    props.setLoading(false)
    setDownload(-1)
  }

  const AssignmentComponent = assignmentComponents[assignmentType]

  return (
    <div>
      <ButtonGroup
        title={{ ...assignmentTypeOptions }}
        value={assignmentType}
        onChange={(value) => setAssignmentType(value)}
      />

      <div>
        {AssignmentComponent && (
          <AssignmentComponent
            inputs={inputs}
            inputGroups={inputGroups}
            onChange={onChange}
            onGenerate={onGenerate}
            download={download}
          />
        )}
      </div>
    </div>
  )
}
