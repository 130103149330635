import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { openS3Document } from 'services'

export const DownloadLink = (props: { link: string; children: any }) => {
  return (
    <p
      className="flex items-center gap-2 text-shade-blue hover:underline text-sm cursor-pointer"
      onClick={() => openS3Document(props.link)}
    >
      <ArrowDownTrayIcon className="w-4 h-4" />
      {props.children}
    </p>
  )
}
