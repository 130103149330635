import type { BaseFile } from 'config'

export interface IAccountingRecord {
  id: number
  loanNumber: string
  amount: number
  description: string
  borrowerName: string
  subjectPropertyAddress: string
  files: BaseFile[]
  createdAt: number
  createdBy: string
}

export interface IAccountingCategory {
  baseId: number
  category: number
  subCategory: number
}

export interface IDebitOrCredit {
  debit: IAccountingCategory
  credit: IAccountingCategory
}

export interface IAccounting {
  id: number
  fileId: number
  category: number
  subCategory: number
  date: string
  description: string
  amount: number
  loanNumber: string
  servicerLoanNumber?: string
  accountId: number
  note: string
  files: BaseFile[]
  createdBy?: string
  createdAt?: string
  deleted: boolean
  actived: boolean

  payoff?: boolean
  payoffDate?: Date
  debitOrCredit?: IDebitOrCredit

  baseId?: number
  valid?: boolean
  checked?: boolean
  duplicated?: boolean
  override?: boolean

  updated?: boolean

  relatedId?: number
  relatedItems?: IAccounting[]
  records: IAccountingRecord[]
}

export interface IAccountingCountByCategory {
  category: number
  subCategory: number
  count: number
  amount: number
}

export const AccountingRecordTypeOptions: Record<string, string> = {
  Record: 'Profit & Loss',
  Balance: 'Balance Sheet',
}

/**
 * Profit & Loss
 */
export const RevenueBaseId = 1
export const ExpenseBaseId = 2
export const BaseIdForProfitLoss = [RevenueBaseId, ExpenseBaseId]

/**
 * Balance Sheet
 */
export const AssetsBaseId = 8
export const LiabilitiesBaseId = 9
export const BaseIdForBalanceTable = [AssetsBaseId, LiabilitiesBaseId]

export const LoansHeldForSaleCategoryName = 'LOANS HELD FOR SALE'
