import { CheckBadgeIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType, INVALID_ALL_INPUTS } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getServicingParticipation, saveServicingParticipation } from 'services'
import { Button } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { participationInputs } from './constant'

export function ServicingParticipation() {
  const [action, setAction] = useState('')
  const [inputs, setInputs] = useState<any>({})
  const [rate, setRate] = useState('')
  const [byteproFileName, setByteproFileName] = useState(0)

  const init = async () => {
    setAction('loading')
    const res = await getServicingParticipation()
    if (res.success) {
      let temp: Record<string, InputType> = cloneDeep(participationInputs())
      Object.keys(temp).map((key) => {
        temp[key].value = res.data[key]
      })
      setInputs(temp)
      setRate(res.interestRate.toFixed(2))
      setByteproFileName(res.byteproFileName)
    } else setInputs(participationInputs())
    setAction('')
  }

  useEffect(() => {
    init()
  }, [])

  const onChange = async (key: string, e: any) => {
    const value = InputConvert(inputs[key], e)
    const error = InputValidate({ ...inputs[key], value })
    let temp = cloneDeep(inputs)
    temp[key].value = value
    temp[key].error = error
    setInputs(temp)
  }

  const onSubmit = async () => {
    let data: any = {}
    let errors = 0
    let temp = cloneDeep(inputs)
    Object.keys(inputs).map((key) => {
      data[key] = `${inputs[key].value}`
      const error = InputValidate({ ...inputs[key] })
      temp[key].error = error
      if (error.length) {
        errors += 1
      }
    })
    setInputs(temp)
    if (errors) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setAction('save')
    const loanNumber = setLoanNumber()
    await saveServicingParticipation(loanNumber, byteproFileName, data)
    setAction('')
  }

  const fillDefaultValues = () => {
    let temp = cloneDeep(inputs)
    temp.principalRate.value = Number(rate)
    temp.principalBuyoutRate.value = Number(rate)
    temp.participantExcessReturnPercent.value = 0
    temp.participantExtensionPercent.value = 0
    temp.participantOriginationFeePercent.value = 0
    Object.keys(temp).map((key) => {
      temp[key].error = InputValidate({ ...temp[key] })
    })
    setInputs(temp)
  }

  const defaultFragment = useMemo(() => {
    return `
      <div>
        <div>Principal Rate: ${rate}</div>
        <div>Principal Buyout Rate: ${rate}</div>
        <div>Participant Excess Return Percent: 0</div>
        <div>Participant Extension Percent: 0</div>
        <div>Participant Origination Fee Percent: 0</div>
      </div>
    `
  }, [rate])

  return (
    <div className="participation-Container relative">
      <LayoutLoading show={action.length > 0} />
      <div className="mt-6">
        <div className="flex flex-wrap gap-4 mb-4 items-center">
          <div className="">
            <RenderInput
              input={{
                inputType: 'text',
                title: 'Loan Interest Rate',
                value: rate,
                prefix: '%',
                readOnly: true,
              }}
              Key={'InterestRate'}
              onChange={onChange}
            />
          </div>

          <div
            className="flex gap-1 h-fit hover:underline cursor-pointer text-shade-blue items-center text-[14px]"
            onClick={fillDefaultValues}
          >
            <Tooltip message={defaultFragment}>
              <span>Fill Default Values</span>
            </Tooltip>
            <CheckBadgeIcon className="w-4 h-4"></CheckBadgeIcon>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div className={`md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
        <div className="mt-4 justify-center">
          <Button className="" loading={action == 'save'} onClick={onSubmit}>
            Save & Generate Participation Agreement
          </Button>
        </div>
      </div>
    </div>
  )
}
