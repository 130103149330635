import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useMemo } from 'react'
import { HorzScrollContainer } from 'stories/components'

import { NonLoanTaskProjectIds } from './constants'
import { TaskRow } from './taskRow'

export const TaskTable = ({
  isLoading,
  queryTimes,
  filters = {},
  setFilters = () => {},
  assignedToList,
  project,
  hasActions = true,
  onSelectTaskStatus,
  onCheck,
  onEdit,
  onTrash,
  onRecoverDelete,
  filterTasks,
  tasks,
}: {
  isLoading: boolean
  queryTimes: number
  filters?: Record<string, any>
  setFilters?: Function
  assignedToList: any
  project: Record<string, any>
  hasActions?: boolean
  onSelectTaskStatus: Function
  onCheck: Function
  onEdit: Function
  onTrash: Function
  onRecoverDelete: Function
  filterTasks: Function
  tasks: Record<string, any>[]
}) => {
  const isLoanTasks = useMemo(() => {
    return !NonLoanTaskProjectIds.includes(project.id)
  }, [project.id])

  const sortableHeaders = isLoanTasks
    ? [
        { title: 'Loan #', key: 'loanNumber', sortable: true },
        { title: 'Task No', key: 'no', sortable: true },
        { title: 'Borrower', key: 'borrowerName', sortable: true },
        {
          title: (
            <span className="text-left">
              <div className="border-b w-fit mb-1 border-gray-300 whitespace-nowrap">Description</div>Note
            </span>
          ),
          key: 'description',
          sortable: true,
        },
        {
          title: (
            <span className="text-left">
              <div className="border-b w-fit mb-1 border-gray-300 whitespace-nowrap min-w-[120px]">Assigned to</div>Due
            </span>
          ),
          key: 'dueDate',
          sortable: true,
        },
        { title: 'Task Status', key: 'taskStatus' },
        { title: 'Created', key: 'createdAt', sortable: true },
        { title: 'Closing Date', key: 'closingDate', sortable: true },
        {
          title: (
            <span className="text-left whitespace-nowrap">
              <div className="border-b w-fit mb-1 border-gray-300">Purpose</div>Loan Status
            </span>
          ),
          key: 'loanStatus',
          sortable: true,
        },
      ]
    : [
        { title: 'Task No', key: 'no', sortable: true },
        {
          title: (
            <span className="text-left">
              <div className="border-b w-fit mb-1 border-gray-300 whitespace-nowrap">Description</div>Note
            </span>
          ),
          key: 'description',
          sortable: true,
        },
        {
          title: (
            <span className="text-left">
              <div className="border-b w-fit mb-1 border-gray-300 whitespace-nowrap min-w-[120px]">Assigned to</div>Due
            </span>
          ),
          key: 'assignedTo',
          sortable: true,
        },
        { title: 'Task Status', key: 'taskStatus' },
        { title: 'Created', key: 'createdAt', sortable: true },
      ]

  const renderHeader = (title: any, sortable: boolean = false, key: string, sortOrder: number = 1, index: number) => {
    if (!sortable)
      return (
        <th scope="col" className="py-3 px-2" key={`${project.id}-${key}`}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
      filterTasks(newFilters)
    }

    return (
      <th scope="col" className={`py-3 px-2 ${index == 0 ? '' : ''}`} key={`${project.id}-${key}`}>
        <button className="flex uppercase bg-transparent font-bold" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-2" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
      </th>
    )
  }

  return (
    <HorzScrollContainer size={queryTimes}>
      <>
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6 overflow-auto">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {sortableHeaders.map(({ title, key, sortable }, index) =>
                renderHeader(title, !!sortable, key, filters.orderBy == key ? parseInt(filters.orderDir) : 0, index),
              )}
              {hasActions && (
                <th scope="col" className="px-2">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody className="">
            {tasks &&
              tasks.map((task: Record<string, any>, index: number) => (
                <TaskRow
                  key={task.id}
                  task={task}
                  index={index}
                  filters={filters}
                  assignedToList={assignedToList}
                  isLoanTasks={isLoanTasks}
                  hasActions={hasActions}
                  project={project}
                  onSelectTaskStatus={onSelectTaskStatus}
                  onCheck={onCheck}
                  onEdit={onEdit}
                  onTrash={onTrash}
                  onRecoverDelete={onRecoverDelete}
                />
              ))}
          </tbody>
        </table>
      </>
    </HorzScrollContainer>
  )
}
