import cloneDeep from 'clone-deep'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { loanCloneFile } from 'services'
import { Modal } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

const initData = () => {
  const rlt: any = {
    loanStructure: true,
    loanApplication: true,
    loanSubmission: false,
  }
  return rlt
}

const mapTitle: any = {
  loanStructure: 'Copy Loan Structure Data',
  loanApplication: 'Copy Loan Application Data',
  loanSubmission: 'Copy Loan Submission Data',
}

export function CloneFileDialog(props: any) {
  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const { isOpen } = props
  const [data, setData] = useState(initData())
  const [loading, setLoading] = useState(false)

  const onClose = () => {
    setData(initData())
    props.onClose()
  }

  const onOpen = () => {}

  const onChange = async (key: string, e: any) => {
    let temp = cloneDeep(data)
    temp[key] = e
    setData(temp)
  }

  const onClone = async () => {
    setLoading(true)
    const res = await loanCloneFile(data)
    if (res.success) {
      toast(`Successfuly Created a New Loan: ${res.loanNumber}.`, { type: 'success' })
    }
    setLoading(false)
    props.onClose()
    return true
  }

  return (
    <>
      <Modal
        button={<span></span>}
        title={'Create Additional File for this Borrower'}
        titleOkay="Clone"
        titleCancel="Close"
        loading={loading}
        isOpen={isOpen}
        onOpen={onOpen}
        lastUpdatedAt={Date.now()}
        onClose={onClose}
        onOk={onClone}
      >
        {isOpen ? (
          <div className="">
            <div className="min-w-[400px]">
              {Object.keys(data).map((key: string, index: number) => {
                return (
                  <div className="mb-3" key={index}>
                    {key === 'loanSubmission' ? (
                      auth.profile.accountType === 'admin' && (
                        <RenderInput
                          input={{
                            title: mapTitle[key],
                            inputType: 'Toggle',
                            value: data[key],
                          }}
                          Key={key}
                          onChange={onChange}
                        />
                      )
                    ) : (
                      <span>- {mapTitle[key]}</span>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </Modal>
    </>
  )
}
