import type { InputType } from 'config'

export interface DVResponse {
  loanNumber: number
  attributes: DVAttribute
  scores: DVComponentScore[]
  conditions: DVCondition[]
  // histories: Record<string, DVConditionHistory[]>
  documents: DVConditionDocument[]
  submissions: DVSubmission[]
}

export interface DVAttribute {
  LoanNumber: string
  DRIVEScore: string
  DRIVEStatus: string
  ResultsURL: string
  PrintURL: string
  NumberTimesScored: string
  DataInputScore: string
  ScoringProfile: string
  ScoreDateTime: string
  TimeZone: string
  DVPin: string
  DRIVEAdjustedScore: string
}

export interface DVComponentScore {
  ScoreType: string
  Score: string
  Status: string
  AdjustedScore: string
}

export interface DVConditionResolution {
  DateTime?: string
  UserName?: string
  Status: string
  Comment: string
}

export interface DVCondition {
  Sequence: string
  Code: string
  Category: string
  Subcategory: string
  Subject: string
  Description: string
  ActionStep: string
  ScoringAnalysis: string
  Severity: string
  ConditionResolutions: DVConditionResolution[]
}

export interface DVConditionHistory {
  Comment: string
  Status: string
  createdAt: number
  createdBy: string
}

export interface DVConditionDocument {
  fileKey: string
  createdAt: number
  createdBy: string
}

export enum DVSubmissionType {
  CREATE = 'create',
  UPDATE = 'update',
  CONDITION = 'condition',
}

export interface DVSubmission {
  type: DVSubmissionType
  attributes: DVAttribute
  scores: DVComponentScore[]
  fileKey?: string
  createdAt: number
  createdBy: string
}

export const StatusOptions = ['Open', 'Outstanding', 'Cleared']

export const SeverityColors = {
  H: 'red',
  M: 'yellow',
  L: 'green',
}

export const credentialFormInputs = (): Record<string, InputType> => ({
  username: {
    title: 'UserName',
    inputType: 'text',
    error: '',
    required: true,
  },
  password: {
    title: 'Password',
    inputType: 'text',
    type: 'password',
    error: '',
    required: true,
  },
})
