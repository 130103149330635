import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getServicingDefaultTracking, postServicingDefaultTracking } from 'services'
import { InputConvert, InputValidate, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export function DefaultTracking() {
  const [loading, setLoading] = useState(false)
  const [valueChanged, setValueChanged] = useState(false)
  const [inputs, setInputs] = useState<any>({})

  const init = async () => {
    setLoading(true)
    const res = await getServicingDefaultTracking()
    if (res.success) {
      setInputs(res.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    init()
  }, [])

  const onChange = async (key: string, e: any) => {
    let value = InputConvert(inputs[key], e)
    let temp = cloneDeep(inputs)
    if (key == 'files') {
      setLoading(true)
      await postServicingDefaultTracking({ [key]: value })
      setLoading(false)
    }
    temp[key].value = value
    temp[key].error = InputValidate(temp[key])
    setInputs(temp)
    setValueChanged(true)
  }

  const onBlur = async (key: string) => {
    if (!valueChanged) return
    if (inputs[key].error?.length > 0) return
    setValueChanged(false)
    setLoading(true)
    await postServicingDefaultTracking({ [key]: inputs[key].value })
    setLoading(false)
  }

  const showHistory = async (key: string) => {
    const options = {
      table: 'ServicingDefaultTracking',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  return (
    <div className="DefaultTracking-Container relative mb-5">
      <LayoutLoading show={loading} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          input.disabled = loading
          return (
            <div className={`md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} onBlur={onBlur} showHistory={showHistory} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
