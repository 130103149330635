import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      required: true,
      visible: false,
    },
    days: {
      inputType: 'text',
      type: 'number',
      title: 'Days - Prior to Note Date',
      value: '',
      required: true,
      visible: false,
    },
  }
}

export const ExpirationDatesUpdate = ({
  type,
  value,
  title,
  subTitle,
  onClose,
  onUpdate,
}: {
  type: string
  value: string | number
  title: string
  subTitle: string
  onClose: Function
  onUpdate: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    newInputs[type].visible = true
    if (type === 'date') {
      value = moment(value).format('YYYY-MM-DD')
    }
    newInputs[type].value = value
    setInputs(newInputs)
  }, [])

  const onChange = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (key != type) continue
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    await onUpdate(type === 'days' ? Number(data[type]) : data[type])
  }

  return (
    <Modal
      title={`${title} ${subTitle.toLocaleUpperCase()}`}
      titleOkay="Confirm"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900">
        <div className="w-144 gap-4 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]
            if (!input.visible) return null

            return (
              <div className="input" key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
