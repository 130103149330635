import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    title: {
      inputType: 'text',
      title: 'Title',
      required: true,
    },
    cost: {
      inputType: 'text',
      title: 'Cost',
      type: 'thousandSep',
      required: true,
    },
  }
}
