import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { IProduct } from 'pages/LoanStructure/interfaces'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { productFields } from '../constants'

interface IProps {
  data: IProduct | undefined
  isOpen: boolean
  addProduct: (data: IProduct, isUpdate: boolean) => void
  onClose: () => void
}

export const AddProduct = (props: IProps) => {
  const { data, isOpen, addProduct, onClose } = props
  const [inputs, setInputs] = useState<Record<string, InputType>>(productFields())

  useEffect(() => {
    if (!!data) {
      const newInputs = cloneDeep(inputs)
      Object.keys(newInputs).forEach((key) => {
        newInputs[key].value = (data as any)[key]
      })
      setInputs(newInputs)
    }
  }, [data])

  const onEditProduct = (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onAddProduct = () => {
    const newInputs = cloneDeep(inputs)
    let hasError = false
    const newProduct: IProduct = {
      Id: data?.Id ?? 0,
      Description: '',
    }

    Object.keys(newInputs).forEach((key) => {
      ;(newProduct as any)[key] = newInputs[key].value
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
    })
    setInputs(newInputs)
    if (hasError) return false

    addProduct(newProduct, !!data)
    return true
  }

  return (
    <Modal
      title={`${!data ? 'Add Product' : 'Update Product'}`}
      titleOkay={`${!!data ? 'Update' : 'Add'}`}
      isOpen={isOpen}
      onOk={onAddProduct}
      onClose={onClose}
    >
      <div className="mb-2 md:min-w-[480px] text-left font-normal">
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-2"> */}
        {Object.keys(inputs).map((key, index) => {
          return (
            <div className="w-full mb-4" key={index}>
              <RenderInput input={inputs[key]} Key={key} onChange={onEditProduct} />
            </div>
          )
        })}
        {/* </div> */}
      </div>
    </Modal>
  )
}
