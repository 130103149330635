import { Elements } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { getClientSessionKey, PaymentOrderType, StripeMethod } from 'services'
import { getPrice2decimal } from 'utils'

import CheckoutForm from './CheckoutForm'

export const feePercent: Record<string, number> = {
  ACH: 0.01,
  'Credit Card': 0.0325,
  us_bank_account: 0.01,
  card: 0.0325,
}

export const renderStripeAmountDetails = (amount: number, type: string | undefined) => {
  const fee = type ? feePercent[type] || 0 : 0

  return (
    <div className="grid grid-cols-2 mb-8 text-[14.5px] items-end">
      <p>Payment Amount:</p>
      <p className="text-lg text-black">$ {getPrice2decimal(amount, false, true)}</p>
      <p className="border-b border-gray-300 pb-2 mb-2">Fee ({fee === 0 ? '-' : Number(fee * 100)}%):</p>
      <p className="border-b border-gray-300 pb-2 mb-2 text-lg text-black">
        $ {fee === 0 ? '-' : getPrice2decimal(Number(amount * fee).toFixed(2), false, true)}
      </p>

      <p>Total:</p>
      <p className="text-lg text-black font-bold">
        $ {getPrice2decimal(Number(amount * (1 + fee)).toFixed(2), false, true)}
      </p>
    </div>
  )
}

/**
 * @param method: us_bank_account | card
 */
export const Stripe = ({
  email,
  orderType,
  refId,
  amount,
  method,
  data,
  setLoading = () => {},
  onCompleted = () => {},
}: {
  email: string
  orderType: PaymentOrderType
  refId: string
  amount: number // unit: 1 usd
  method: StripeMethod
  data: Record<string, any>
  setLoading?: Function
  onCompleted?: Function
}) => {
  const { loadStripe } = require('@stripe/stripe-js')

  const [intentId, setIntentId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [publicKey, setPublicKey] = useState('')

  useEffect(() => {
    if (!method) return

    setLoading(true)
    getClientSessionKey({ orderType, refId, method, amount, data }).then(({ intentId, publicKey, clientKey }) => {
      setIntentId(intentId)
      setClientSecret(clientKey)
      setPublicKey(publicKey)
    })
  }, [method, amount])

  if (!clientSecret || !publicKey) return null

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <Elements options={options as any} stripe={loadStripe(publicKey)}>
      <CheckoutForm email={email} onReady={() => setLoading(false)} onCompleted={() => onCompleted(intentId)} />
    </Elements>
  )
}
