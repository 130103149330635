import { getPrice3decimal, isEmpty } from 'utils'

import type { ICommercialDscr, ICommercialOnlyProgrammed } from './interface'

export const NoUnitPropertyTypes = ['SFR', 'Condo', 'PUD']

export const convertNullValue = (value: any): string => {
  const rlt = isEmpty(value) ? 'N/A' : String(value)

  return rlt
}

export const convertNullToBlank = (value: any): string => {
  const rlt = isEmpty(value) ? '' : String(value)

  return rlt
}

export const convertNullToNoMax = (value: any) => {
  return isEmpty(value) || isNaN(value) ? 'No Max' : getPrice3decimal(value)
}

export const convertNullToBlankValueToDecimal = (value: any) => {
  return isEmpty(value) || isNaN(value) ? '' : getPrice3decimal(value)
}

export const getNonDscrTableData = (data: ICommercialOnlyProgrammed) => {
  let rlt: any[] = []

  const {
    baseRates,
    basePrices,
    rateSpreads,
    exitFees,
    ltvLimits,
    ficos,
    monthsReserve,
    monthsReserveAdjustment,
    experience,
    propertyTypes,
    citizenships,
    bankruptcy,
    foreclosure,
    deedInLieu,
    shortsale,
    mortgageLates,
    forbearance,
    rehabBudgetTypes,
    rehabBudgetTypesAdjustment,
    maxRatePriceAdjustment,
    experienceAdjustment,
    loanTerm,
    loanTermAdjustment,
    aivLtvs,
    aivLtvAdjustment,
    ltcs,
    ltcAdjustment,
    ficoAdjustment,
    propertyTypeAdjustment,
    citizenshipAdjustment,
    bankruptcyAdjustment,
    foreclosureAdjustment,
    deedInLieuAdjustment,
    shortsaleAdjustment,
    mortgageLatesAdjustment,
    forbearanceAdjustment,
    loanAmount,
  } = data

  const stackCols = [5, 6, 7, 8]
  const titleRows = [1, 6]
  const titleCols = [0, 1, 2, 3, 4, 5, 6]
  const titleColsSpan2 = [0, 1, 2, 3]
  const colSpan2Cols = [1, 2, 3]
  const colSpan2Rows = [0]

  const rightAlignRows = [2, 3, 4]

  const titles: string[] = ['0-0', '7-0']
  const rightAlignCells: string[] = []
  const colSpan2: string[] = []

  rlt.push([
    'Min/Max Loan Amount',
    isEmpty(loanAmount.from) ? 'N/A' : '$ ' + getPrice3decimal(loanAmount.from),
    isEmpty(loanAmount.to) ? 'N/A' : '$ ' + getPrice3decimal(loanAmount.to),
    '',
  ])

  rlt.push(['Max LTV Limits', 'AIV-LTV', 'ARV-LTV', 'LTC', 'LTP', '', ''])

  rlt.push([
    'Purchase',
    convertNullValue(ltvLimits.purchase.aivLtv),
    convertNullValue(ltvLimits.purchase.arvLtv),
    convertNullValue(ltvLimits.purchase.ltc),
    convertNullValue(ltvLimits.purchase.ltp),
    '',
    '',
  ])

  rlt.push([
    'No-Cashout',
    convertNullValue(ltvLimits.nocashout.aivLtv),
    convertNullValue(ltvLimits.nocashout.arvLtv),
    convertNullValue(ltvLimits.nocashout.ltc),
    convertNullValue(ltvLimits.nocashout.ltp),
    '',
    '',
  ])
  rlt.push([
    'Cashout',
    convertNullValue(ltvLimits.cashout.aivLtv),
    convertNullValue(ltvLimits.cashout.arvLtv),
    convertNullValue(ltvLimits.cashout.ltc),
    convertNullValue(ltvLimits.cashout.ltp),
    '',
    '',
  ])

  rlt.push(['', '', '', '', '', '', ''])
  let posY = 6

  // Rate & Price Adjustment Label Rows
  titleRows.push(posY)
  colSpan2Rows.push(posY)
  titleRows.push(posY + 1)

  const rowPriceAdjFirstRow = posY

  rlt.push(['Rate & Price Adjustments', 'Purchase', 'No-Cashout', 'Cashout'])
  rlt.push([
    '',
    'Rate Adjustment',
    'Price Adjustment',
    'Rate Adjustment',
    'Price Adjustment',
    'Rate Adjustment',
    'Price Adjustment',
  ])
  posY += 2

  // Max Rate & Price Adjustment Row
  titles.push(`${posY}-0`)
  rlt.push([
    'Max Rate & Price Adjustment',
    maxRatePriceAdjustment.purchase.rate,
    maxRatePriceAdjustment.purchase.price,
    maxRatePriceAdjustment.nocashout.rate,
    maxRatePriceAdjustment.nocashout.price,
    maxRatePriceAdjustment.cashout.rate,
    maxRatePriceAdjustment.cashout.price,
  ])
  posY += 2
  rlt.push(['', '', '', '', '', '', ''])

  const ratePriceAdjArr = [
    {
      title: 'Experience',
      data: experience,
      adjustment: experienceAdjustment,
      isRange: false,
    },
    {
      title: 'Loan Term',
      data: loanTerm,
      adjustment: loanTermAdjustment,
      isRange: false,
    },
    {
      title: 'AIV-LTV',
      data: aivLtvs,
      adjustment: aivLtvAdjustment,
      isRange: false,
    },
    {
      title: 'LTC',
      data: ltcs,
      adjustment: ltcAdjustment,
      isRange: false,
    },
    {
      title: 'Fico',
      data: ficos,
      adjustment: ficoAdjustment,
      isRange: true,
    },
    {
      title: 'Months Reserve',
      data: monthsReserve,
      adjustment: monthsReserveAdjustment,
      isRange: true,
    },
    {
      title: 'Property Type',
      data: propertyTypes,
      adjustment: propertyTypeAdjustment,
      isRange: false,
    },
    {
      title: 'Citizenship',
      data: citizenships,
      adjustment: citizenshipAdjustment,
      isRange: false,
    },
    {
      title: 'Bankruptcy',
      data: bankruptcy,
      adjustment: bankruptcyAdjustment,
      isRange: false,
    },
    {
      title: 'Foreclosure',
      data: foreclosure,
      adjustment: foreclosureAdjustment,
      isRange: false,
    },
    {
      title: 'Short Sale',
      data: shortsale,
      adjustment: shortsaleAdjustment,
      isRange: false,
    },
    {
      title: 'Deed In Lieu',
      data: deedInLieu,
      adjustment: deedInLieuAdjustment,
      isRange: false,
    },
    {
      title: 'Mortgage Lates',
      data: mortgageLates,
      adjustment: mortgageLatesAdjustment,
      isRange: false,
    },
    {
      title: 'Forbearance / Loan Modification',
      data: forbearance,
      adjustment: forbearanceAdjustment,
      isRange: false,
    },
    {
      title: 'Rehab Budget Type',
      data: rehabBudgetTypes,
      adjustment: rehabBudgetTypesAdjustment,
      isRange: false,
    },
  ]

  ratePriceAdjArr.forEach((v) => {
    if (v.data) {
      // Title Row
      titleRows.push(posY)
      rlt.push([v.title, '', '', '', '', '', ''])
      posY++

      // Adjustment Rows
      v.data.forEach((item: any, index) => {
        rlt.push([
          v.isRange ? `${item.from} ${isEmpty(item.to) ? '+' : '― ' + item.to}` : item?.label ? item.label : item,
          convertNullValue(v.adjustment.purchase[index].rate),
          convertNullValue(v.adjustment.purchase[index].price),
          convertNullValue(v.adjustment.nocashout[index].rate),
          convertNullValue(v.adjustment.nocashout[index].price),
          convertNullValue(v.adjustment.cashout[index].rate),
          convertNullValue(v.adjustment.cashout[index].price),
        ])

        rightAlignRows.push(posY)
        posY++
      })
      posY++
      rlt.push(['', '', '', '', '', '', ''])
    }
  })
  rlt.pop()

  // Draw Rate & Price
  rlt[0] = [...rlt[0], '', 'Base Rate', 'Base Price', 'Rate Spread', 'Exit Fee']

  baseRates.forEach((_, index: number) => {
    rlt[index + 1] = [
      ...rlt[index + 1],
      '',
      convertNullValue(baseRates[index]),
      convertNullValue(basePrices[index]),
      convertNullValue(rateSpreads[index]),
      convertNullValue(exitFees[index]),
    ]
  })

  let totalRows =
    ficos.length +
    monthsReserve.length +
    experience.length +
    loanTerm.length +
    aivLtvs.length +
    ltcs.length +
    propertyTypes.length +
    citizenships.length +
    bankruptcy.length +
    foreclosure.length +
    deedInLieu.length +
    shortsale.length +
    mortgageLates.length +
    forbearance.length +
    37

  totalRows += rehabBudgetTypes ? rehabBudgetTypes.length + 2 : 0

  for (let i = baseRates.length + 1; i < totalRows; i++) {
    rlt[i] = [...rlt[i], '', '', '', '', '']
  }

  colSpan2Rows.forEach((y) => {
    colSpan2Cols.forEach((x) => {
      colSpan2.push(`${y}-${x}`)
    })
  })

  titleRows.forEach((y) => {
    if (colSpan2Rows.includes(y)) {
      titleColsSpan2.forEach((x) => {
        titles.push(`${y}-${x}`)
      })
    } else
      titleCols.forEach((x) => {
        titles.push(`${y}-${x}`)
      })
  })

  stackCols.forEach((x) => {
    titles.push(`0-${x}`)
  })

  rightAlignRows.forEach((y) => {
    rightAlignCells.push(`${y}-0`)
  })

  return {
    table: rlt,
    style: {
      titles: titles,
      rightAlignCells: rightAlignCells,
      colSpan2: colSpan2,
      rowPriceAdjFirstRow: rowPriceAdjFirstRow,
    },
  }
}

export const getCommercialDscrTableData = (data: ICommercialDscr) => {
  let rlt: any[] = []

  Object.keys(data)
    .sort()
    .map((key) => {
      const {
        loanAmount,
        minDscr,
        monthsReserve,
        ficos,
        purchaseLTVs,
        refinanceLTVs,
        purchaseRates,
        refinanceRates,
        purchasePrice,
        refinancePrice,
      } = data[key]

      let row = [key, 'No Income', `Price at ${purchasePrice}`, '', `Price at ${refinancePrice}`]
      rlt.push(row)

      row = [
        'Loan Amount',
        'Reserves',
        'Min DSCR',
        'FICO',
        'Purchase LTV',
        'Purchase Rates',
        'Refinance LTV',
        'Refinance Rates',
      ]
      rlt.push(row)

      ficos.map((fico: number, index: number) => {
        row = [
          '',
          '',
          '',
          '',
          convertNullValue(fico),
          convertNullValue(purchaseLTVs[index]),
          convertNullValue(purchaseRates[index]),
          convertNullValue(refinanceLTVs[index]),
          convertNullValue(refinanceRates[index]),
        ]

        if (index === 0) {
          row[0] = '$' + getPrice3decimal(loanAmount.from)
          row[1] = '$' + getPrice3decimal(loanAmount.to)
          row[2] =
            convertNullValue(monthsReserve) === 'N/A'
              ? convertNullValue(monthsReserve)
              : convertNullValue(monthsReserve) + 'mo'
          row[3] = convertNullValue(minDscr)
        }

        rlt.push(row)
      })

      rlt.push(['', '', '', '', '', '', '', '', ''])
    })

  rlt.splice(rlt.length - 1, 1)

  return rlt
}
