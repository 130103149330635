import cloneDeep from 'clone-deep'
import { COMPANY_DNS } from 'config'
import type { InputType } from 'config/input.type.constants'
import { feasibilityAllKeys, feasibilityKeys } from 'pages/Loan'
import { defaultInputs } from 'pages/Loan/OrderAppraisals/constants'

export const extensionInputLogic = (_data: any) => {
  let data = cloneDeep(_data)

  feasibilityKeys.forEach((key) => {
    if (!!data[key]) data[key].visible = true
  })
  return data
}

export const loanExtensionInputs = (customer: boolean = false) => {
  const inputs = defaultInputs()
  const filteredInputs: Record<string, InputType> = {}

  filteredInputs['documents'] = {
    inputType: 'FileTable',
    title: 'Please upload loan extension documents.',
    value: [],
    error: '',
    span: 4,
    filePath: '',
    required: false,
    showCategory: false,
    showStatus: false,
  }

  filteredInputs['carrier'] = {
    inputType: 'text',
    title: 'Carrier',
    value: '',
    span: 2,
    visible: true,
    required: false,
    disabled: true,
  }
  filteredInputs['trackingNumber'] = {
    inputType: 'text',
    title: 'Shipping/Tracking Number',
    value: '',
    span: 2,
    visible: true,
    required: false,
    disabled: true,
  }

  filteredInputs.paymentAmount = {
    ...inputs['paymentAmount'],
    title: 'Extension Fee',
    visible: true,
    required: true,
    prefix: '$',
    span: 4,
  }

  feasibilityAllKeys.forEach((key) => {
    filteredInputs[key] = inputs[key]
    if (customer) {
    } else {
      if (!['paymentAmount', 'feasibilityEmailToLink'].includes(key)) filteredInputs[key].required = false
    }
  })

  delete filteredInputs['feasibilityTitle']
  delete filteredInputs['feasibilityACHPaymentAmount']
  delete filteredInputs['feasibilityCCStudyFee']

  filteredInputs['feasibilityEmailToLink'] = {
    inputType: 'email',
    title: 'Email to Send Link',
    required: true,
    visible: false,
    span: 2,
    defaultEmails: [
      {
        party: 'Servicing',
        email: `kfry@${COMPANY_DNS}`,
      },
    ],
  }

  if (!customer) {
    filteredInputs['notes'] = {
      inputType: 'textarea',
      title: 'Notes',
      rows: 0,
      value: '',
      span: 4,
      visible: true,
      required: false,
    }
  }

  if (customer) {
    filteredInputs.paymentAmount.disabled = true
    filteredInputs.feasibilityEmailToLink.disabled = true
    filteredInputs.carrier.disabled = false
    filteredInputs.trackingNumber.disabled = false
    // filteredInputs.carrier.required = true
    // filteredInputs.trackingNumber.required = true
  }

  return filteredInputs
}
