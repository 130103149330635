import { setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { visibleLoansLogic } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { InputValidate } from 'utils'

import { additionalPropertyInfoValidate } from '../AdditionalPropertyInformation/logics'
import { defaultInputs } from './constants'

export const initFields = (fields: any) => {
  const visibleLoans = visibleLoansLogic()
  Object.keys(fields).map((key) => {
    if (visibleLoans.indexOf(key) !== -1) {
      fields[key].disabled = true
      fields[key].tooltip = 'Loan Structure Input Field!'
    }
  })
  if (visibleLoans.indexOf('secondLienPayment') === -1) fields['secondLienPayment'].visible = false
  return fields
}

export const propertyInfoValidate = (validateOnly = false, updateStore = false) => {
  let fields = initFields(defaultInputs())
  const { loan, step } = store.getState()
  let success = true
  Object.keys(fields).map((key) => {
    const error = InputValidate({ ...fields[key], value: loan[key] })
    if (error.length > 0) {
      success = false
      fields[key].error = error
    }
  })
  success = success && additionalPropertyInfoValidate()
  if (updateStore) {
    let application = cloneDeep(step.application)
    application.property = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }
  if (validateOnly) return success
  return fields
}
