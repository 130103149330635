import type { IDrawRequest } from 'pages/Borrower'
import { Modal, PlainTable } from 'stories/components'
import { formatTime, getPrice2decimal, replaceNextLineToBr } from 'utils'

export const DrawRequestsDialog = ({
  value,
  title,
  requests,
  onClose,
}: {
  value: string
  title: string
  requests: IDrawRequest[]
  onClose: Function
}) => {
  return (
    <Modal isOpen titleOkay="" title="Draw Requests" onClose={() => onClose()}>
      <div className="w-196">
        <p className="mb-4">
          Description:{' '}
          <span className="font-semibold">
            {value} / {title}
          </span>
        </p>
        <PlainTable
          header={['No', 'Name', 'Amount', 'Description', 'Requested At']}
          data={requests.map((v, index) => [
            index + 1,
            `${v.firstName} ${v.lastName}`,
            `$${getPrice2decimal(v.amount)}`,
            <p dangerouslySetInnerHTML={{ __html: replaceNextLineToBr(v.description) }} />,
            formatTime(v.createdAt),
          ])}
        />
      </div>
    </Modal>
  )
}
