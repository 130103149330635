import { accountTypes } from 'components/Modals/CreateUser/config'
import { InputType } from 'config'

export const getRulesConfigurationFields = () => {
  const rlt: Record<string, InputType> = {
    section1: {
      inputType: 'section',
      title: 'Email Rules',
      span: 4,
    },
    emailWithdrawnLoans: {
      inputType: 'multiselect',
      title: 'Email Receivers of Withdrawn/Declined Loans',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    emailSurveyNotification: {
      inputType: 'multiselect',
      title: 'Email Receivers of Survey Notification',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
  }

  return rlt
}
