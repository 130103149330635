import type { InputType } from 'config'

export const RequirementList = [
  `The information provided in this application is true and correct as of the date set forth opposite my
signature and that any intentional or negligent misrepresentation of this information contained in this
application may result in civil liability, including monetary damages, to any person who may suffer any loss
due to reliance upon any misrepresentation that I have made on this application, and/or in criminal
penalties including, but not limited to, fine or imprisonment or both under the provisions of Title 18,
United States Code, Sec. 1001, et seq.`,
  `The loan requested pursuant to this application (the "Loan") will be secured by a mortgage or deed of trust
on the property described in this application.`,
  `The property will not be used for any illegal or prohibited purpose or use.`,
  `All statements made in this application are made for the purpose of obtaining a mortgage loan.`,
  `The property will be occupied as indicated in this application`,
  `The Lender, its servicers, successors or assigns may retain the original and/or an electronic record of this
application, whether or not the Loan is approved.`,
  `The Lender and its agents, brokers, insurers, servicers, successors, and assigns may continuously rely on
the information contained in the application, and I am obligated to amend and/or supplement the information
provided in this application if any of the material facts that I have represented herein should change prior
to closing of the Loan.`,
  `In the event that my payments on the Loan become delinquent, the Lender, its servicers, successors or
assigns may, in addition to any other rights and remedies that it may have relating to such delinquency,
report my name and account information to one or more consumer reporting agencies.`,
  `Ownership of the Loan and/or administration of the Loan account may be transferred with such notice as may
be required by law.`,
  `Neither Lender nor its agents, brokers, insurers, servicers, successors or assigns has made any
representation or warranty, express or implied, to me regarding the property or the condition or value of
the property.`,
  `My transmission of this application as an "electronic record" containing my "electronic signature," as those
terms are defined in applicable federal and/or state laws (excluding audio and video recordings), or my
facsimile transmission of this application containing a facsimile of my signature, shall be as effective,
enforceable and valid as if a paper version of this application were delivered containing my original
written.`,
]

export const defaultInputs = (): Record<string, InputType> => {
  return {
    agreeToSign: {
      inputType: 'toggle',
      title: 'I agree to the terms and conditions.',
      value: false,
      required: true,
    },
    informationToSign: {
      inputType: 'textarea',
      title: 'Other information you wish to provide that may be relevant to this loan application',
      required: false,
    },
  }
}

export const commercialInputs = (): Record<string, InputType> => {
  return {
    institution: {
      title: 'Financial Institution',
      inputType: 'text',
      value: '',
      error: '',
      required: true,
    },
    accountNumber: {
      title: 'Account Number',
      type: 'number',
      inputType: 'text',
      minLength: 8,
      maxLength: 17,
      value: '',
      error: '',
      required: true,
    },
    accountType: {
      title: 'Account Type',
      inputType: 'Select',
      value: '',
      options: ['Checking', 'Saving'],
      hasDefaultOption: true,
      required: true,
      error: '',
    },
    ABANumber: {
      title: 'ABA Routing Number',
      inputType: 'text',
      value: '',
      error: '',
      type: 'number',
      required: true,
    },
  }
}
