import {
  API_LOAN_GET_PARTIES,
  API_LOAN_PARTY_ADDITIONAL_INFO,
  API_LOAN_PARTY_OPTIONS,
  API_LOAN_UPDATE_PARTIES,
  API_LOAN_UPDATE_PARTY,
} from 'config'
import Api from 'services/api'

export const updateParty = (data: any) => {
  return Api.post(API_LOAN_UPDATE_PARTY, data)
}

export const getPartyOptions = (key: string) => {
  return Api.get(API_LOAN_PARTY_OPTIONS, {}, { key })
}

export const getParties = (loanNumber: number) => {
  return Api.get(API_LOAN_GET_PARTIES, {}, { loanNumber })
}

export const getPartyAdditionalInfo = (type: string) => {
  return Api.get(API_LOAN_PARTY_ADDITIONAL_INFO, {}, { type })
}

export const updateParties = (loanNumber: number, PartyID: number, data: Record<string, any>) => {
  return Api.post(API_LOAN_UPDATE_PARTIES, data, { loanNumber, PartyID }, { timeout: 150000 })
}
