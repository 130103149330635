import cloneDeep from 'clone-deep'
import { getPrice2decimal, isEmpty, removeComma } from 'utils'

import type { IBudgetData } from './types'

export const calcBudgetValues = (budgetData: IBudgetData) => {
  const newBudget = cloneDeep(budgetData)

  const newBorrowerFinancedProRate = 100 - removeComma(budgetData.borrowerFinancedProRate)

  newBudget.items.forEach((item) => {
    Object.keys(item.data).forEach((key) => {
      const data = item.data[key]
      item.data[key].borrowerResponsibility = Number(
        (
          (removeComma(data.rehabCostLenderApproved || data.rehabCostBorrowerEstimated) *
            removeComma(newBorrowerFinancedProRate)) /
          100
        ).toFixed(2),
      )
    })
  })

  return newBudget
}

export const convertValue = (value: any) => {
  return isNaN(value) || isEmpty(value) ? '' : String(value)
}

export const convertContent = (value: any) => {
  return isNaN(value) || isEmpty(value) ? '' : '$' + getPrice2decimal(value, false, true)
}

export const convertPercentContent = (value: any) => {
  return isNaN(value) || isEmpty(value) ? '' : `${getPrice2decimal(value, false, true)}%`
}

export const convertPercent = (estimated: any, approved: any, addSuffix = false) => {
  estimated = removeComma(estimated) || 0
  approved = removeComma(approved) || 0
  const value = approved / estimated
  if (isNaN(value) || isEmpty(value)) return ''
  const percent = Number(Number(value * 100).toFixed(2))
  return `${percent}${addSuffix ? '%' : ''}`
}

export const convertValue2 = (value: any) => {
  return isNaN(value) || isEmpty(value) ? 'N/A' : String(value)
}

export const convertContent2 = (value: any) => {
  if (typeof value == 'string') value = Number(value)
  return isNaN(value) || isEmpty(value) ? 'N/A' : '$' + getPrice2decimal(value.toFixed(2), false, true)
}

export const roundDown = (value: number, digit: number) => {
  const str = String(value).split('.')

  let newValue = String(value)

  if (digit === 0) {
    newValue = str[0]
  } else if (digit < 0) {
    let temp = str[0].slice(0, str[0].length + digit)

    for (let i = 0; i < Math.abs(digit); i++) temp += '0'

    newValue = temp
  } else {
    if (!str[1]) {
      let temp = ''

      for (let i = 0; i < digit; i++) temp += '0'

      newValue = str[0] + '.' + temp
    } else if (str[1].length < digit) {
      for (let i = 0; i < digit - str[1].length; i++) str[1] += '0'

      newValue = str[0] + '.' + str[1]
    } else {
      let temp = str[1].slice(0, digit)

      newValue = str[0] + '.' + temp
    }
  }

  return newValue
}
