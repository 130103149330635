import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useMemo } from 'react'
import { getPrice3decimal } from 'utils'

import type { IRehabBudgetTypeLtvMaxLimits } from '../../interface'

export const RehabBudgetTypes: Record<string, string> = {
  light: 'Light Rehab',
  standard: 'Standard Rehab',
  heavy: 'Heavy Rehab',
}

export const RehabBudgetTypeLeverageLimit = ({ data }: { data: IRehabBudgetTypeLtvMaxLimits[] }) => {
  const renderLtvMaxLimits = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="w-full text-center text-sm">
        <thead className="font-bold bg-gray-100">
          <tr>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              Rehab Budget Type
            </th>
            <th rowSpan={2} className="border p-1">
              Experience
            </th>
            <th rowSpan={2} className="border p-1">
              FICO
            </th>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min Months Reserve
            </th>
            <th colSpan={4} className="border p-1">
              Max LTVs
            </th>
          </tr>
          <tr>
            <th className="border p-1 w-[120px]">From</th>
            <th className="border p-1 w-[120px]">To</th>
            <th className="border p-1 w-[80px]">AIV-LTV</th>
            <th className="border p-1 w-[80px]">ARV-LTV</th>
            <th className="border p-1 w-[80px]">LTC</th>
            <th className="border p-1 w-[80px]">LTP</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                {item.limits.map((data, id) => {
                  let titleRowSpan = 0
                  item.limits.forEach((v) => (titleRowSpan += v.limits.length * 3))
                  return (
                    <Fragment key={`${index}-${id}`}>
                      {data.limits.map((limit, idx) => (
                        <Fragment key={`${index}-${id}-${idx}`}>
                          <tr>
                            {id === 0 && idx === 0 && (
                              <td rowSpan={titleRowSpan} className="border p-1">
                                {RehabBudgetTypes[item.value]}
                              </td>
                            )}

                            {idx === 0 && (
                              <td rowSpan={3 * data.limits.length} className="border p-1">
                                {data.expRange.from} - {data.expRange.to}
                              </td>
                            )}

                            <td rowSpan={3} className="border px-3 py-1">
                              {limit.FICO.from} - {limit.FICO.to}
                            </td>

                            <td className="border p-1">Purchase</td>
                            {['min', 'max'].map((key, index1) => (
                              <td key={`${index}-${id}-${idx}-${index1}`} className="border px-3 py-1">
                                {getPrice3decimal((limit.loanAmount.purchase as any)[key])}
                              </td>
                            ))}

                            <td className="border px-3 py-1">{limit.minMonthsReserve.purchase}</td>
                            {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                              <td key={`${index}-${id}-${idx}-${index1}`} className="border px-3 py-1">
                                {(limit.LTV.purchase as any)[key]}
                              </td>
                            ))}
                          </tr>

                          <tr>
                            <td className="border p-1">No-Cashout</td>
                            {['min', 'max'].map((key, index1) => (
                              <td key={`${index}-${id}-${idx}-${index1}`} className="border px-3 py-1">
                                {getPrice3decimal((limit.loanAmount.nocashout as any)[key])}
                              </td>
                            ))}

                            <td className="border px-3 py-1">{limit.minMonthsReserve.nocashout}</td>
                            {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                              <td key={`${index}-${id}-${idx}-${index1}`} className="border px-3 py-1">
                                {(limit.LTV.nocashout as any)[key]}
                              </td>
                            ))}
                          </tr>

                          <tr>
                            <td className="border p-1">Cashout</td>
                            {['min', 'max'].map((key, index1) => (
                              <td key={`${index}-${id}-${idx}-${index1}`} className="border px-3 py-1">
                                {getPrice3decimal((limit.loanAmount.cashout as any)[key])}
                              </td>
                            ))}

                            <td className="border px-3 py-1">{limit.minMonthsReserve.cashout}</td>
                            {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                              <td key={`${index}-${id}-${idx}-${index1}`} className="border px-3 py-1">
                                {(limit.LTV.cashout as any)[key]}
                              </td>
                            ))}
                          </tr>
                        </Fragment>
                      ))}
                    </Fragment>
                  )
                })}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return <div className="h-full">{renderLtvMaxLimits}</div>
}
