export const InputRating = ({
  title,
  max = 5,
  value = 0,
  required = false,
  error = '',
  disabled = false,
  readOnly = false,
  onChange,
}: {
  title: string
  max?: number
  value?: number
  required?: boolean
  error?: string
  disabled?: boolean
  readOnly?: boolean
  onChange: Function
}) => {
  return (
    <div className="input-container">
      <p className="text-sm">
        {title}
        {required && '*'}
      </p>
      <div className="flex gap-2">
        {Array(max)
          .fill(0)
          .map((_, index) => (
            <svg
              height="800px"
              width="800px"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 47.94 47.94"
              className={`${
                value < index + 1 ? `fill-gray-300` : `fill-blue-600`
              } w-6 h-6 hover:fill-blue-400 cursor-pointer`}
              onClick={() => !disabled && !readOnly && onChange(index + 1)}
            >
              <path
                d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
	c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
	c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
	c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
	c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
	C22.602,0.567,25.338,0.567,26.285,2.486z"
              />
            </svg>
          ))}
      </div>
      {error && (
        <p className={`${readOnly && 'ml-2'} peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1`}>{error}</p>
      )}
    </div>
  )
}
