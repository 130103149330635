import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { Button, ToggleButton } from 'stories/components'
import { confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { ISurveyTemplates, SurveyType } from './types'

export * from './types'

export default function SurveyTemplates() {
  const [templates, setTemplates] = useState<ISurveyTemplates | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    setLoading(true)
    getAdminConfig('surveyTemplate')
      .then((data) => {
        setTemplates(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const onAdd = (type: SurveyType) => {
    if (templates) {
      const newTemplates = cloneDeep(templates)
      newTemplates.push({
        type: type,
        title: '',
        disabled: false,
      })
      setTemplates(newTemplates)
    }
    setEdit(true)
  }

  const onChange = (index: number, value: any) => {
    if (!templates) return

    const newTemplates = cloneDeep(templates)
    newTemplates[index].title = value
    setTemplates(newTemplates)

    setEdit(true)
  }

  const handleToggleChange = (index: number, checked: boolean) => {
    if (!templates) return

    const newTemplates = cloneDeep(templates)
    newTemplates[index].disabled = checked
    setTemplates(newTemplates)

    setEdit(true)
  }

  const handleDelete = async (index: number) => {
    const content = <div className="text-gray-400 mb-4 text-[18px]">Are you sure to remove this item?</div>
    const result = await confirm(content)
    if (!result) return
    if (templates) {
      const newTemplates = templates.filter((_, i) => i !== index)
      setTemplates(newTemplates)
      setEdit(true)
    }
  }

  const onSubmit = async () => {
    if (!templates) return

    try {
      setLoading(true)
      await setAdminConfig('surveyTemplate', templates)
      setEdit(false)
    } catch (error) {
      console.error('Failed to save templates:', error)
    }
    setLoading(false)
  }

  const renderInputs = () => {
    if (!templates) return null

    const basicTexts: any = []
    const ratingInputs: any = []
    const questionInputs: any = []

    let ratingCount = 0
    let questionCount = 0

    templates.forEach((item, index) => {
      const isBasicText = index == 0
      const key = `${item.type}-${index}`
      const title = isBasicText
        ? 'Basic'
        : item.type === SurveyType.Rating
        ? `Rating ${++ratingCount}`
        : `Answer ${++questionCount}`

      const inputElement = (
        <div key={key} className="flex flex-col mb-4">
          <div className="flex justify-between items-center">
            <div className="w-full mr-4">
              <RenderInput
                input={{
                  value: item.title,
                  error: '',
                  required: true,
                  inputType: isBasicText ? 'textarea' : 'Text',
                  title,
                  disabled: item.disabled,
                  rows: item.disabled !== undefined ? 2 : 4,
                }}
                Key={key}
                onChange={(_: any, value: any) => onChange(index, value)}
                onBlur={() => {}}
              />
            </div>
            <div className="flex items-center">
              {item.disabled !== undefined && (
                <div className="flex items-center">
                  <ToggleButton
                    id={`toggle-${key}`}
                    value={item.disabled || false}
                    label={['No', 'Yes']}
                    onChange={(checked) => handleToggleChange(index, checked)}
                  />
                  <span className="text-red-500 cursor-pointer p-1 hover-shadow1" onClick={() => handleDelete(index)}>
                    <TrashIcon className="w-5 h-5"></TrashIcon>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )

      if (item.disabled === undefined) {
        basicTexts.push(inputElement)
      } else if (item.type === SurveyType.Rating) {
        ratingInputs.push(inputElement)
      } else if (item.type === SurveyType.Text) {
        questionInputs.push(inputElement)
      }
    })

    return (
      <>
        <div className="mb-4">
          <h2 className="font-bold mb-1">Basic Text</h2>
          {basicTexts}
        </div>
        <div className="mb-4">
          <h2 className="font-bold mb-2">Questions for Rating</h2>
          {ratingInputs}
          <Button onClick={() => onAdd(SurveyType.Rating)} className="mt-2">
            Add Rating Question
          </Button>
        </div>
        <div className="mb-4">
          <h2 className="font-bold mb-2">Questions for Answer</h2>
          {questionInputs}
          <Button onClick={() => onAdd(SurveyType.Text)} className="mt-2">
            Add Question
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className="relative p-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold flex items-center pb-5">Review Templates</h1>
      </div>
      <LayoutLoading show={isLoading} />

      <div className="grid gap-2 mb-10">{renderInputs()}</div>

      {edit && (
        <div className="flex justify-center">
          <Button className="w-[200px]" onClick={onSubmit}>
            Save
          </Button>
        </div>
      )}
    </div>
  )
}
