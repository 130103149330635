import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { setReviewFormCategories } from 'services'
import { confirm } from 'utils'

import type { ReviewFormCategory } from './ReviewFormItems'

export const ReviewFormCategories = ({
  isLoading,
  setLoading,
  categories,
  setCategories,
}: {
  isLoading: boolean
  setLoading: Function
  categories: ReviewFormCategory[]
  setCategories: Function
}) => {
  const onAdd = () => {
    const newValues = cloneDeep(categories)
    newValues.push({ id: `${Date.now()}`, value: null })
    setCategories(newValues)
  }

  const onUpdate = (newValue: string, index: number) => {
    const newValues = cloneDeep(categories)
    newValues[index].value = newValue
    setCategories(newValues)
    submitValues(newValues)
  }

  const onChangeOrder = (val: string, index: number) => {
    const nth = Number(val)
    if (nth === index) return

    const newValues = cloneDeep(categories)
    const removedOne = newValues.splice(index, 1)
    newValues.splice(nth, 0, removedOne[0])
    setCategories(newValues)
    submitValues(newValues)
  }

  const onDelete = async (index: number) => {
    const result = await confirm('Do you want to remove this category?')
    if (!result) return

    const newValues = cloneDeep(categories)
    newValues.splice(index, 1)
    setCategories(newValues)
    submitValues(newValues)
  }

  const submitValues = async (values: ReviewFormCategory[]) => {
    setLoading(true)
    const savingValues = values.filter((value) => value.value !== null)
    await setReviewFormCategories(savingValues)
    setLoading(false)
  }

  return (
    <div className="relative overflow-auto shadow-md sm:rounded-lg">
      <LayoutLoading show={isLoading} />
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-3 py-3 w-[20px]">
              No
            </th>
            <th scope="col" className="px-3 py-3">
              Name
            </th>
            <th scope="col" className="px-3 py-3 w-[50px]">
              Order
            </th>
            <th scope="col" className="px-3 py-3 w-[80px]">
              <button
                className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                onClick={onAdd}
              >
                + Add
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {categories.map((value, index) => {
            const orderOptions = Array.apply(null, Array(index + 1)).map((_, index) => index.toString())
            return (
              <tr
                className={`${index % 2 ? 'bg-gray-50' : 'bg-white'} border-b dark:bg-gray-800 dark:border-gray-700`}
                key={index}
              >
                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  {index + 1}
                </td>

                <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  <PlainInput
                    value={value.value || ''}
                    content={value.value || ''}
                    className="h-[35px]"
                    onChange={(newValue: string) => onUpdate(newValue, index)}
                  />
                </td>

                <td>
                  <select
                    onChange={(e) => onChangeOrder(e.target.value, index)}
                    value={index}
                    className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  >
                    {orderOptions.map((val) => (
                      <option key={val}>{val}</option>
                    ))}
                  </select>
                </td>

                <td className="px-6 py-1">
                  <span className="flex">
                    <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(index)}>
                      <TrashIcon className="w-4 h-4"></TrashIcon>
                    </span>
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
