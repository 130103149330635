import cloneDeep from 'clone-deep'
import { finresiMapConstants, loanOptionsConstants } from 'config'
import type { ILeverageMaxLimits, IProgramTableData } from 'pages/Resources'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import { foreclosuresOptions } from '../LeverageAdjustments'
import { CitizenshipLeverageLimit } from './CitizenshipLeverageLimit'
import { CrossCollateralBlanketLimit } from './CrossCollateralBlanketLimit'
import { ExperienceLeverageLimit } from './ExperienceLeverageLimit'
import { FicoLeverageLimit } from './FicoLeverageLimit'
import { FirstTimeInvestorLeverageLimit } from './FirstTimeInvestorLeverageLimit'
import { LoanAmountLeverageLimit } from './LoanAmountLeverageLimit'
import { MaxCashToBorrowerLeverageLimit } from './MaxCashToBorrowerLeverageLimit'
import { PropertyTypeLeverageLimit } from './PropertyTypeLeverageLimit'
import { RehabBudgetTypeLeverageLimit } from './RehabBudgetTypeLeverageLimit'
import { RuralPropertyLeverageLimit } from './RuralPropertyLeverageLimit'
import { SecondLienMaxLimits } from './SecondLienMaxLimits'
import { ValueLeverageLimit } from './ValueLeverageLimit'

const initialMenus = {
  occupancy: 'Occupancy',
  loanAmount: 'Loan Amount',
  experience: 'Experience',
  propertyType: 'Property Type',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  citizenship: 'Citizenship',
  fico: 'FICO',
  rehabBudgetType: 'Rehab Budget Type',
  decliningMarketProperty: 'Property in a declining market?',
  newConstructionProperty: 'Is the property a new construction?',
  ruralProperty: 'Rural Property?',
  firstTimeInvestor: 'First Time Investor?',
  maxCashToBorrower: 'Cash in Hand',
  crossCollateral: 'Cross Collateral / Blanket (6)',
  secondLien: 'Second Lien (2)',
}

export const LeverageLimits = ({ program }: { program: IProgramTableData }) => {
  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')

  useEffect(() => {
    if (!program) return

    const {
      experienceLtvLimits,
      propertyTypeLtvLimits,
      mortgageLatesLtvLimits,
      fcSsDilLeverageMaxLimit,
      bankruptcyLeverageMaxLimit,
      citizenshipLtvLimits,
      ficoLeverageLimit,
      rehabBudgetTypeLtvLimits,
      firstTimeInvestorLeverageMaxLimit,
      ruralPropertyLeverageMaxLimit,
      maxCashToBorrowerLeverageMaxLimit,
      forbearanceLeverageLimit,
      loanAmountLeverageLimit,
      occupancyLeverageLimit,
      decliningMarketPropertyLeverageLimit,
      newConstructionPropertyLeverageLimit,
    } = program.leverageMaxLimits as ILeverageMaxLimits

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'newConstructionProperty':
          if (!!newConstructionPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${newConstructionPropertyLeverageLimit.length})`
          }
          break
        case 'occupancy':
          if (!!occupancyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${occupancyLeverageLimit.length})`
          }
          break
        case 'decliningMarketProperty':
          if (!!decliningMarketPropertyLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${decliningMarketPropertyLeverageLimit.length})`
          }
          break
        case 'loanAmount':
          if (!!loanAmountLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${loanAmountLeverageLimit.length})`
          }
          break
        case 'experience':
          if (!!experienceLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${experienceLtvLimits.length})`
          }
          break
        case 'propertyType':
          if (!!propertyTypeLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${propertyTypeLtvLimits.length})`
          }
          break
        case 'mortgageLates':
          if (!!mortgageLatesLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${mortgageLatesLtvLimits.length})`
          }
          break
        case 'fcSsDil':
          if (!!fcSsDilLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${fcSsDilLeverageMaxLimit.length})`
          }
          break
        case 'bankruptcy':
          if (!!bankruptcyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${bankruptcyLeverageMaxLimit.length})`
          }
          break
        case 'forbearance':
          if (!!forbearanceLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${forbearanceLeverageLimit.length})`
          }
          break
        case 'citizenship':
          if (!!citizenshipLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${citizenshipLtvLimits.length})`
          }
          break
        case 'fico':
          if (!!ficoLeverageLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${ficoLeverageLimit.length})`
          }
          break
        case 'rehabBudgetType':
          if (!!rehabBudgetTypeLtvLimits.length) {
            newMenus[key] = initialMenus[key] + ` (${rehabBudgetTypeLtvLimits.length})`
          }
          break
        case 'firstTimeInvestor':
          if (!!firstTimeInvestorLeverageMaxLimit.length && !!firstTimeInvestorLeverageMaxLimit[0].limits.length) {
            newMenus[key] = initialMenus[key] + ` (${firstTimeInvestorLeverageMaxLimit[0].limits.length})`
          }
          break
        case 'ruralProperty':
          if (!!ruralPropertyLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${ruralPropertyLeverageMaxLimit.length})`
          }
          break
        case 'maxCashToBorrower':
          if (!!maxCashToBorrowerLeverageMaxLimit.length) {
            newMenus[key] = initialMenus[key] + ` (${maxCashToBorrowerLeverageMaxLimit.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [program])

  const renderContent = useMemo(() => {
    const { leverageMaxLimits } = program

    switch (selectedMenu) {
      case menus.loanAmount:
        return <LoanAmountLeverageLimit data={leverageMaxLimits?.loanAmountLeverageLimit || []} />
      case menus.experience:
        return <ExperienceLeverageLimit data={leverageMaxLimits?.experienceLtvLimits || []} />
      case menus.propertyType:
        return <PropertyTypeLeverageLimit data={leverageMaxLimits?.propertyTypeLtvLimits || []} />
      case menus.citizenship:
        return <CitizenshipLeverageLimit data={leverageMaxLimits?.citizenshipLtvLimits || []} />
      case menus.mortgageLates:
        return (
          <ValueLeverageLimit
            title="Mortgage Lates"
            options={finresiMapConstants.mortgageLates}
            data={leverageMaxLimits?.mortgageLatesLtvLimits || []}
          />
        )
      case menus.rehabBudgetType:
        return <RehabBudgetTypeLeverageLimit data={leverageMaxLimits?.rehabBudgetTypeLtvLimits || []} />
      case menus.occupancy:
        return <ValueLeverageLimit title="Occupancy" data={leverageMaxLimits?.occupancyLeverageLimit || []} />
      case menus.decliningMarketProperty:
        return (
          <ValueLeverageLimit
            title="Property in a declining market?"
            data={leverageMaxLimits?.decliningMarketPropertyLeverageLimit || []}
          />
        )
      case menus.newConstructionProperty:
        return (
          <ValueLeverageLimit
            title="Is the property a new construction?"
            data={leverageMaxLimits?.newConstructionPropertyLeverageLimit || []}
            showDesc={true}
          />
        )
      case menus.fcSsDil:
        return (
          <ValueLeverageLimit
            title="FC/SS/DIL"
            options={foreclosuresOptions}
            data={leverageMaxLimits?.fcSsDilLeverageMaxLimit || []}
          />
        )
      case menus.bankruptcy:
        return (
          <ValueLeverageLimit
            title="Bankruptcy"
            options={finresiMapConstants.bankruptcy}
            data={leverageMaxLimits?.bankruptcyLeverageMaxLimit || []}
          />
        )
      case menus.forbearance:
        return (
          <ValueLeverageLimit
            title="Forbearance / Loan Modification"
            options={loanOptionsConstants.forbearance}
            data={leverageMaxLimits?.forbearanceLeverageLimit || []}
          />
        )
      case menus.fico:
        return <FicoLeverageLimit data={leverageMaxLimits?.ficoLeverageLimit || []} />
      case menus.firstTimeInvestor:
        return <FirstTimeInvestorLeverageLimit data={leverageMaxLimits?.firstTimeInvestorLeverageMaxLimit || []} />
      case menus.ruralProperty:
        return <RuralPropertyLeverageLimit data={leverageMaxLimits?.ruralPropertyLeverageMaxLimit || []} />
      case menus.maxCashToBorrower:
        return (
          <MaxCashToBorrowerLeverageLimit
            data={leverageMaxLimits?.maxCashToBorrowerLeverageMaxLimit || []}
            isDSCR={false}
          />
        )
      case menus.crossCollateral:
        return <CrossCollateralBlanketLimit data={leverageMaxLimits?.crossCollateralBlanket} />
      case menus.secondLien:
        return <SecondLienMaxLimits data={leverageMaxLimits?.secondLienMaxLimit} />
      default:
        return <></>
    }
  }, [selectedMenu, program])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div className="h-full">
      {renderMenus}

      <div className="p-2 h-full">{renderContent}</div>
    </div>
  )
}
