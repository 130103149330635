import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'

export const SaveChanges = (props: any) => {
  const { show = false, label = 'Save Changes', onSave } = props

  if (!show) return null
  return (
    <div className="fixed right-0 top-[50%] z-20">
      <button
        className={`p-3 rounded-r-none ring-[5px] ring-red-400 bg-red-600 text-white hover:bg-white hover:text-shade-blue rounded flex items-center gap-2 border-r-0`}
        onClick={onSave}
      >
        <svg className="animate-bounce w-6 h-6">
          <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
        </svg>
        <span>{label}</span>
      </button>
    </div>
  )
}
