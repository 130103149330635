import { SET_LOAN_TIER_LTV_MAX_LIMIT } from 'actions/types'
import cloneDeep from 'clone-deep'

import { createReducer } from '../utils'

const getInitialState = () => {
  return {}
}

export const tierLtvMaxLimit = createReducer(getInitialState(), {
  [SET_LOAN_TIER_LTV_MAX_LIMIT]: (state: any, payload: any) => {
    let newState = cloneDeep(state)
    newState = {
      ...payload.data,
    }
    return newState
  },
})
