import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan Number',
      value: '',
      error: '',
    },
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
    },
    lender: {
      inputType: 'text',
      type: 'text',
      title: 'Lender',
      value: '',
      error: '',
    },
    borrower: {
      inputType: 'text',
      type: 'text',
      title: 'Borrower Name',
      value: '',
      error: '',
    },
    propertyAddress: {
      inputType: 'text',
      title: 'Property Address',
      value: '',
      error: '',
    },
    transferEffectiveDate: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
    },
    stopPaymentReceivedate: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
      disabled: true,
    },
    servicingCompany: {
      inputType: 'text',
      type: 'text',
      title: 'Servicing Company',
      value: '',
      error: '',
    },
    servicingCompanyAddress: {
      inputType: 'text',
      title: 'Servicing Company Address',
      value: '',
      error: '',
    },
    currentServicerPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
      value: '',
      error: '',
    },
    currentServicerAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Address',
      value: '',
      error: '',
    },
    newServicerPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
      value: '',
      error: '',
    },
    newServicerPhoneHours: {
      inputType: 'text',
      type: 'text',
      title: 'Phone Hours',
      value: '',
      error: '',
    },
    newServicerAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Address',
      value: '',
      error: '',
    },
    servicingAttn: {
      inputType: 'text',
      type: 'text',
      title: 'Servicing Attn',
      value: '',
      error: '',
    },
  }
}
