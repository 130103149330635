import React, { useEffect, useMemo, useState } from 'react'

import {
  type IPrepaymentPenalty,
  type IProgramData,
  type IRestriction,
  type ITierData,
  type LtvLimit,
  IPrepaymentPenaltyOverlay,
  ProgramTypes,
} from '../interface'
import { StaticComponent } from '../StaticComponent'
import { DscrLeverageAdjustments } from './DscrLeverageAdjustments'
import { DscrLeverageLimits } from './DscrLeverageLimits'
import { DscrLtvLimit } from './DscrLtvLimit'
import { DscrOverlay } from './DscrOverlay'
import { DscrTiersLtvAdjustment } from './DscrTiersLtvAdjustment'
import { PrepaymentPenalty } from './PrepaymentPenalty'

interface IProps {
  data: IProgramData[]
}

interface SelectedMenu {
  program: string
  label: string
  programID: number
}

type SelectedDataType =
  | 'LtvLimit'
  | 'PrepaymentPenalty'
  | 'TiersLTVAdjustment'
  | 'LeverageLimit'
  | 'LeverageAdjustments'
  | 'DscrOverlay'

export const leverageLimitLabel = 'Leverage Limits'
export const leverageAdjLabel = 'Leverage Adjustments'
export const dscrOverlayLabel = 'DSCR Overlay'

export const DscrProgram = (props: IProps) => {
  const { data } = props

  const [selectedMenu, setSelectedMenu] = useState<SelectedMenu>({ program: '', label: '', programID: NaN })
  const [selectedData, setSelectedData] = useState<
    LtvLimit | IPrepaymentPenalty | ITierData | IRestriction | undefined | null
  >(undefined)
  const [selectedDataType, setSelectedDataType] = useState<SelectedDataType | null>(null)
  const [pppOverlay, setPPPOverlay] = useState<IPrepaymentPenaltyOverlay[]>()
  const [isBPI, setIsBPI] = useState(false)

  useEffect(() => {
    if ([ProgramTypes.BEYOND_PRIME_INVESTOR, ProgramTypes.MULTIFLOW].includes(data[0].Type)) {
      data.map((program, index: number) => {
        if (index === 0) {
          const { LtvLimits } = program.Restriction as IRestriction

          if (LtvLimits) {
            Object.keys(LtvLimits).map((key, keyIndex) => {
              if (!LtvLimits) return null
              const ltvLimit = LtvLimits[key]
              if (keyIndex === 0) {
                setSelectedMenu({ program: program.Name, label: ltvLimit.label, programID: program.ID })
                setSelectedData(ltvLimit)
                setSelectedDataType('LtvLimit')
                setIsBPI(program.Type === ProgramTypes.BEYOND_PRIME_INVESTOR)
              }
            })
          }
        }
      })
    }
  }, [data])

  const renderRestrictionMenu = (program: IProgramData) => {
    if (!program.Restriction) return <></>

    const { LtvLimits, PrepaymentPenalty, PPPOverlay } = program.Restriction as IRestriction

    let index = 1

    return (
      <ul className="sidebar-items flex flex-col p-4 pb-10">
        {LtvLimits &&
          Object.keys(LtvLimits).map((key) => {
            if (!LtvLimits) return null
            const ltvLimit = LtvLimits[key]

            return (
              <li
                key={key}
                onClick={() => {
                  setSelectedMenu({ program: program.Name, label: ltvLimit.label, programID: program.ID })
                  setSelectedData(ltvLimit)
                  setSelectedDataType('LtvLimit')
                  setIsBPI(program.Type === ProgramTypes.BEYOND_PRIME_INVESTOR)
                }}
                className="border-b py-2"
              >
                <p
                  className={`hover:underline cursor-pointer ${
                    selectedMenu.program == program.Name && selectedMenu.label == ltvLimit.label
                      ? 'border px-4 py-1 bg-zinc-100 shadow'
                      : 'py-1'
                  }`}
                >
                  {index++}. {ltvLimit.label}
                </p>
              </li>
            )
          })}
        {PrepaymentPenalty && (
          <li
            onClick={() => {
              setSelectedMenu({ program: program.Name, label: PrepaymentPenalty.label, programID: program.ID })
              setSelectedData(PrepaymentPenalty)
              setPPPOverlay(PPPOverlay)
              setSelectedDataType('PrepaymentPenalty')
            }}
            className="border-b border-t py-2"
          >
            <p
              className={`hover:underline cursor-pointer ${
                selectedMenu.program == program.Name && selectedMenu.label == PrepaymentPenalty.label
                  ? 'border px-4 py-1 bg-zinc-100'
                  : 'py-1'
              }`}
            >
              {index++}. {PrepaymentPenalty.label}
            </p>
          </li>
        )}

        {program.TierData && (
          <li
            onClick={() => {
              setSelectedMenu({ program: program.Name, label: 'Tiers LTV Adjustment', programID: program.ID })
              setSelectedData(program.TierData)
              setSelectedDataType('TiersLTVAdjustment')
            }}
            className="border-b border-t py-2"
          >
            <p
              className={`hover:underline cursor-pointer ${
                selectedMenu.program == program.Name && selectedMenu.label == 'Tiers LTV Adjustment'
                  ? 'border px-4 py-1 bg-zinc-100'
                  : 'py-1'
              }`}
            >
              {index++}. {'Tiers LTV Adjustment'}
            </p>
          </li>
        )}

        <li
          onClick={() => {
            setSelectedMenu({ program: program.Name, label: leverageLimitLabel, programID: program.ID })
            setSelectedData(program.Restriction as IRestriction)
            setSelectedDataType('LeverageLimit')
          }}
          className="border-b border-t py-2"
        >
          <p
            className={`hover:underline cursor-pointer ${
              selectedMenu.program == program.Name && selectedMenu.label == leverageLimitLabel
                ? 'border px-4 py-1 bg-zinc-100'
                : 'py-1'
            }`}
          >
            {index++}. {leverageLimitLabel}
          </p>
        </li>

        <li
          onClick={() => {
            setSelectedMenu({ program: program.Name, label: leverageAdjLabel, programID: program.ID })
            setSelectedData(program.Restriction as IRestriction)
            setSelectedDataType('LeverageAdjustments')
          }}
          className="border-b border-t py-2"
        >
          <p
            className={`hover:underline cursor-pointer ${
              selectedMenu.program == program.Name && selectedMenu.label == leverageAdjLabel
                ? 'border px-4 py-1 bg-zinc-100'
                : 'py-1'
            }`}
          >
            {index++}. {leverageAdjLabel}
          </p>
        </li>

        <li
          onClick={() => {
            setSelectedMenu({ program: program.Name, label: dscrOverlayLabel, programID: program.ID })
            setSelectedData(program.Restriction as IRestriction)
            setSelectedDataType('DscrOverlay')
          }}
          className="border-b border-t py-2"
        >
          <p
            className={`hover:underline cursor-pointer ${
              selectedMenu.program == program.Name && selectedMenu.label == dscrOverlayLabel
                ? 'border px-4 py-1 bg-zinc-100'
                : 'py-1'
            }`}
          >
            {index++}. {dscrOverlayLabel}
          </p>
        </li>
      </ul>
    )
  }

  const renderFragment = useMemo(() => {
    switch (selectedDataType) {
      case 'LtvLimit':
        return (
          <>
            <DscrLtvLimit data={selectedData as LtvLimit} isBPI={isBPI} />
            <StaticComponent programID={selectedMenu.programID} />
          </>
        )
      case 'PrepaymentPenalty':
        return <PrepaymentPenalty data={selectedData as IPrepaymentPenalty} pppOverlay={pppOverlay} />
      case 'TiersLTVAdjustment':
        return <DscrTiersLtvAdjustment data={selectedData as ITierData} />
      case 'LeverageLimit':
        return <DscrLeverageLimits data={selectedData as IRestriction} />
      case 'LeverageAdjustments':
        return <DscrLeverageAdjustments data={selectedData as IRestriction} />
      case 'DscrOverlay':
        return <DscrOverlay data={selectedData as IRestriction} />
      default:
        return <></>
    }
  }, [selectedMenu, selectedData, selectedDataType])

  return (
    <div className="grid grid-cols-12 gap-6 px-2">
      <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded pb-3">
        {data.map((program) => (
          <React.Fragment key={program.Name}>
            <div className="border-b px-4 py-[9px] font-bold flex items-center bg-gray-100">
              <span>{program.Name}</span>
            </div>

            {renderRestrictionMenu(program)}
          </React.Fragment>
        ))}
      </div>
      <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">
        <h2 className="text-2xl font-bold flex items-center mb-3">{selectedMenu.label || ''}</h2>
        {renderFragment}
      </div>
    </div>
  )
}
