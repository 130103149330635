import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { companyName, loanStatusConstants } from 'config'
import { TemporaryLayout } from 'layouts'
import { loanOverviewAndStepLogic } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { LoanOverviewItem } from 'pages/LoanOverview/overviewItem'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { partiesMap } from 'reducers/loanDetail.reducer'
import { getLoanSubmissionConditionsForTemporaryUser } from 'services'
import { useTitle } from 'utils'
import { isConstructionLoan } from 'utils/isConstructionLoan'
import { setLoanNumber } from 'utils/setLoanNumber'

import { BorrowerLoanDetail } from './BorrowerLoanDetail'

export const BorrowerLoanOverview = () => {
  useTitle(`Loan Overview - ${companyName}`)
  const navigate = useHistory()
  const [isLoading, setLoading] = useState(true)
  const [requestedConditions, setRequestedConditions] = useState<string[]>([])

  const loanNumber = setLoanNumber()

  const { loan, loanDetail, step, borrower } = useSelector((state: any) => {
    return {
      loan: state.loan,
      loanDetail: state.loanDetail,
      step: state.step,
      borrower: state.borrower,
    }
  })

  useEffect(() => {
    setLoading(true)

    loanOverviewAndStepLogic(loanNumber, 'overview').finally(() => setLoading(false))
    getLoanSubmissionConditionsForTemporaryUser().then((conditions) => setRequestedConditions(conditions))
  }, [])

  const isShowConstructionLinks = useMemo(() => {
    return ['Fix and Flip', 'Ground Up Construction'].includes(loan.productType)
  }, [loan.productType])

  const overviewSteps = ['application', 'submit']

  const onEdit = (key: string) => {
    navigate.push(location.pathname.replace('overview', key))
  }

  const overview = useMemo(() => {
    let rlt: any[] = []
    overviewSteps.map((key, index) => {
      let newStep = step
      if (key == 'submit') {
        newStep = cloneDeep(step)
        newStep.overview[key].label = 'Document Storage'
      }
      rlt.push(
        <LoanOverviewItem
          step={newStep}
          loanDetail={loanDetail}
          borrower={borrower}
          itemKey={key}
          key={key}
          index={index}
          onEdit={onEdit}
          setLoading={setLoading}
        />,
      )
    })

    return <div className="p-2 md:p-4 shadow1 rounded mb-4 flex flex-col gap-4">{rlt}</div>
  }, [step, loanDetail, borrower])

  const isDrawRequestAvailable = useMemo(() => {
    const { loanStatus, servicingStatus } = loanDetail
    return loanStatus == 'funded' && ['servicing', 'purchased'].includes(servicingStatus)
  }, [loanDetail.loanStatus, loanDetail.servicingPipeline])

  const loanStructure = () => {
    return (
      <div className="p-2 md:p-4 shadow1 rounded mb-4">
        <BorrowerLoanDetail loan={loan} loanNumber={loanNumber} loanDetail={loanDetail} />
      </div>
    )
  }

  const renderExternalLink = (url: string, title: string, disabled = false) => {
    const linkElement = (
      <span className="flex gap-2 items-center">
        <span>{title}</span>
        <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
      </span>
    )
    return (
      <li
        className={`my-2.5 hover:underline w-fit ${disabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'}`}
        key={url}
      >
        {!disabled ? <Link to={`/borrowerPipeline/${url}/${loanDetail.loanNumber}`}>{linkElement}</Link> : linkElement}
      </li>
    )
  }

  const quickLinks1 = () => {
    return (
      <div className="md:col-span-2 p-4 md:p-6 shadow1 rounded mb-4">
        <div className="text-[18px] font-bold flex items-center border-b pb-1 border-gray-300">
          <span>Quick Links</span>
        </div>
        <ol className="ml-2 list-decimal pl-4">
          {overviewSteps.map((key, index) => {
            const item = step.overview[key]
            const disabled = item.status === -1
            let cn = 'cursor-pointer hover:underline'
            if (disabled) {
              cn = 'cursor-not-allowed text-gray-400'
            }

            let { label } = item
            if (key === 'submit') label = 'Document Storage'
            return (
              <li className={`my-2.5 ${cn}`} key={index} onClick={() => !disabled && onEdit(key)}>
                {label}
              </li>
            )
          })}
          {[
            // renderExternalLink('tasks/loan', 'Tasks'),
            isConstructionLoan(loan.productType) && renderExternalLink('budget', 'Budget & Scope of Work'),
            renderExternalLink('amortizationSchedule', 'Amortization Schedule'),
            renderExternalLink('assetInformationVerification', 'Asset Verification'),
          ]}
        </ol>
      </div>
    )
  }

  const quickLinks2 = () => {
    return (
      <div className="md:col-span-2 p-4 md:p-6 shadow1 rounded mb-4">
        <div className="text-[18px] font-bold flex items-center border-b pb-1 border-gray-300">
          <span>Construction</span>
        </div>
        <ol className="ml-2 list-decimal pl-4">
          {[
            renderExternalLink('drawRequest', 'Draw Request', !isDrawRequestAvailable),
            renderExternalLink('constructionLedger', 'Construction Ledger'),
          ]}
        </ol>
      </div>
    )
  }

  const renderParties = () => {
    const { parties } = loanDetail

    return (
      <div className={`p-4 md:px-6 md:py-4 shadow1 rounded-md text-sm mb-6 lg:w-1/2 md:w-full`} key={'parties'}>
        <div className="flex border-b font-bold mt-10 mb-4">
          <span className="flex-auto text-lg">Parties</span>
        </div>
        <div className="overflow-auto">
          <table className="w-full">
            <tbody className="text-[14px] text-gray-900">
              {['loanOfficer', 'loanProcessor'].map((key, index) => {
                const bgCn = index % 2 ? 'bg-gray-50' : ''
                if (!parties[key]) return null
                return (
                  <tr key={index} className={`${bgCn} border-b`}>
                    <td className="p-1 border">{partiesMap[key]}</td>
                    <td className="p-1 border">
                      <span className="font-variation-settings-600">{parties[key].name}</span>
                    </td>
                    <td className="p-1 border">
                      <span className="">{parties[key].email}</span>
                    </td>
                    <td className="p-1 border">
                      <span className="">{parties[key].phone}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const attentions = useMemo(() => {
    const appDisabled = step.overview['application'].status === -1
    const submitDisabled = step.overview['submit'].status === -1

    return (
      <div className={`flex flex-col md:col-span-6 col-span-12 p-4 md:p-6 shadow1 rounded-md text-base mb-4`}>
        <div className="flex mb-2 text-sm items-end">
          <div className="w-36">Loan Status:</div>
          <div className="font-bold ml-2">{loanStatusConstants.status[loanDetail.loanStatus]}</div>
        </div>

        <p className="mb-4">The following items require your attention:</p>
        <ol className="font-semibold ml-2 list-decimal pl-4 text-sm">
          {!borrower.borrower.signedDate && (
            <li
              className={`mb-2 ${appDisabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer hover:underline'}`}
              onClick={() => !appDisabled && onEdit('application')}
            >
              Sign Loan Application
            </li>
          )}
          {step.application.asset == -1 && (
            <li className={`mb-2 cursor-pointer hover:underline`} onClick={() => {}}>
              <Link to={`/assetInformationVerification/_/${loanNumber}`}>Asset Verification</Link>
            </li>
          )}
          {requestedConditions.map((v) => (
            <li
              className={`mb-2 ${
                submitDisabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer hover:underline'
              }`}
              onClick={() => !submitDisabled && onEdit('submit')}
            >
              {v}
            </li>
          ))}
        </ol>
      </div>
    )
  }, [borrower, step, requestedConditions])

  return (
    <TemporaryLayout noOverview={true} requireSign>
      <div className="max-w-screen-2xl m-auto relative py-6 px-2 bg-white">
        <LayoutLoading show={isLoading} />
        <div className="overview-container">
          <div className="flex gap-6 flex-wrap">
            <div className="flex-1 w-1/2">
              <Overview title="Loan Overview" backLink="/borrowerPipelines" />
            </div>
            {renderParties()}
          </div>

          <div className="grid md:grid-cols-4 grid-cols-1 md:gap-6 m-auto">
            <div className="">
              {attentions}
              {overview}
              {quickLinks1()}
              {isShowConstructionLinks && quickLinks2()}
            </div>
            <div className="md:col-span-3">{loanStructure()}</div>
          </div>
        </div>
      </div>
    </TemporaryLayout>
  )
}
