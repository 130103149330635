import {
  ArrowLeftCircleIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { AccountType, BaseFile, COMPANY_DNS, loanStatusConstants } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { openS3Document } from 'services'
import { capitalizeFirstLetter, formatDate, formatTime, isBeforeOrEqualToday } from 'utils'

import { taskProjectID, taskProjectLoanNumber } from './constants'

export const TaskRow = ({
  task,
  index,
  filters,
  assignedToList,
  isLoanTasks,
  project,
  hasActions = true,
  onSelectTaskStatus,
  onCheck,
  onEdit,
  onTrash,
  onRecoverDelete,
}: {
  task: Record<string, any>
  index: number
  filters: Record<string, any>
  assignedToList: any
  isLoanTasks: boolean
  project: Record<string, any>
  hasActions?: boolean
  onSelectTaskStatus: Function
  onCheck: Function
  onEdit: Function
  onTrash: Function
  onRecoverDelete: Function
}) => {
  const navigate = useHistory()
  const auth = useSelector((state: any) => state.auth)
  const { data: permissionData, hasPermission } = usePermissions()

  const hasFullPermission = useMemo(() => {
    return hasPermission('MANAGE_TASKS_FULL')
  }, [permissionData])

  const assignedTo = (values: Array<{ value: string }>) => {
    if (filters.orderBy == 'assignedTo')
      values = values.sort((a, b) => (a.value > b.value ? filters.orderDir : -filters.orderDir))
    return values.map(({ value }) => assignedToList[value] || value).join(', ')
  }

  const statusText = (status: string, dueDate: string) => {
    if (status == 'complete') return status
    if (!isBeforeOrEqualToday(dueDate)) return 'overdue'
    return status
  }

  const onOpenLoan = (loanNumber: number, popup: boolean) => {
    const link = `/loan_process/overview/${loanNumber}`
    if (popup) window.open(link, '_blank', 'resizable=yes')
    else navigate.push(link)
  }

  const renderTaskLoan = (task: Record<string, any>) => {
    if (task.description.startsWith('Contractor / Builder')) {
      const regex = /Contractor No: (\d+)/
      const match = task.note.match(regex)
      if (!match) return null
      const contractorNo = match[1]
      if (!contractorNo) return null
      const link = `/contractor_application/${contractorNo}/${task.loanNumber}`
      return (
        <div className="flex items-center gap-1">
          <button
            className="text-shade-blue hover:underline cursor-pointer font-bold dark:text-white whitespace-nowrap flex flex-col"
            onClick={() => navigate.push(link)}
          >
            C#{contractorNo}
          </button>

          <button
            className="text-shade-blue hover:underline cursor-pointer font-bold dark:text-white whitespace-nowrap flex flex-col"
            onClick={() => window.open(link, '_blank', 'resizable=yes')}
          >
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </button>
        </div>
      )
    }
    return null
  }

  const onOpenTask = (loanNumber: number, taskNo: number) => {
    if (project.id === taskProjectID.BrokerVerification) {
      if (loanNumber === taskProjectLoanNumber.BrokerVerification) {
        const matches = task.note.match(/Broker Name: ([A-Za-z\s]+)\s\d{2}\/\d{2}/)
        let url = ''
        if (matches) url = `email=${matches[1]}`
        else url = `email=${task.creator.email}`
        window.open(`/registrations?${url}&showBrokerVerification=true&status=all`, '_blank')
        return
      } else {
        window.open(`/contractor_application/${loanNumber}`, '_blank')
        return
      }
    }
    if (project.id === taskProjectID.FundDrawRequest) return
    if (project.id === taskProjectID.Reminders) return
    navigate.push(`/tasks/loan/${loanNumber}/${taskNo}`)
  }

  const status = statusText(task.status, task.dueDate)

  return (
    <tr
      className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${index % 2 && 'bg-slate-50'}`}
      key={task.no}
    >
      {isLoanTasks && (
        <td scope="row" className="px-2">
          <div className="flex flex-col gap-2">
            {task.loanNumber != 0 && (
              <div className="flex items-center gap-1">
                <button
                  className="text-shade-blue hover:underline cursor-pointer font-bold dark:text-white whitespace-nowrap flex flex-col"
                  onClick={() => onOpenLoan(task.loanNumber, false)}
                >
                  <span>{task.byteproFileName ? task.byteproFileName : task.loanNumber}</span>
                </button>

                <button
                  className="text-shade-blue hover:underline cursor-pointer font-bold dark:text-white whitespace-nowrap flex flex-col"
                  onClick={() => onOpenLoan(task.loanNumber, true)}
                >
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                </button>
              </div>
            )}
            {renderTaskLoan(task)}
          </div>
        </td>
      )}
      <td className="px-2">
        <button
          className="text-shade-blue py-3 hover:underline cursor-pointer font-variation-settings-600 dark:text-white whitespace-nowrap"
          onClick={() => task.loanNumber && onOpenTask(task.loanNumber, task.no)}
        >
          {task.no}
        </button>
      </td>
      {isLoanTasks && <td className="px-2">{task.borrowerName}</td>}
      <td className={`px-2 py-3 ${task.rush && 'bg-red-50'}`}>
        <span>
          <div className="border-b w-full mb-2 font-variation-settings-600">
            {task.description}
            {task.rush && <span className="px-2 rounded bg-red-100 border-red-400 text-red-700 ml-2">Rush</span>}
          </div>
          <span className="dangerouslySetInnerHTML flex">
            <div dangerouslySetInnerHTML={{ __html: task.note }}></div>
          </span>
          {task.files.map((file: BaseFile, index: number) => (
            <p
              className="text-[12px] hover:underline text-shade-blue italic font-semibold cursor-pointer"
              onClick={() => openS3Document(file.fileKey)}
              key={`${task.no}-${index}`}
            >
              {file.name}
            </p>
          ))}
        </span>
      </td>
      <td className="w-32 px-2">
        <span>
          <div className="border-b w-full mb-2">{assignedTo(task.assignedTo)}</div>
          {formatDate(task.dueDate)}
        </span>
      </td>
      <td className="px-2 py-2 statusColor">
        <button
          className={`capitalize p-1 mb-1 w-[80px] text-[13px] rounded cursor-pointer ${status}`}
          onClick={() => onSelectTaskStatus(index)}
        >
          {status}
        </button>
        <p className="block">{status == 'complete' ? task.completedDate : ''}</p>
      </td>
      <td className="px-2 py-2">
        <p>{formatTime(task.createdAt)}</p>
        <p className="text-[13px] mt-1">
          {project.id === taskProjectID.FundDrawRequest ? capitalizeFirstLetter(COMPANY_DNS) : task.creator.email}
        </p>
      </td>
      {isLoanTasks && (
        <>
          <td className="px-2">{formatDate(task.closingDate) || 'n/a'}</td>
          <td className="px-2">
            <span>
              <div className="border-b w-full mb-2">{task.loanPurpose}</div>
              {(loanStatusConstants.status as any)[task.loanStatus]}
              {task.loanStatus === 'funded' && (
                <>
                  {' | '}
                  {(loanStatusConstants.servicingStatus as any)[task.servicingStatus]}
                </>
              )}
            </span>
          </td>
        </>
      )}
      {hasActions && (
        <td className="px-2">
          <span
            className="text-sky-700 p-2 hover-shadow1 rounded cursor-pointer flex justify-center"
            onClick={() => onCheck(index)}
          >
            <CheckCircleIcon className="w-4 h-4"></CheckCircleIcon>
          </span>
          <span className="flex flex-wrap justify-center">
            <span className="text-shade-blue p-1 hover-shadow1 rounded cursor-pointer" onClick={() => onEdit(index)}>
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            </span>
            {auth.profile.accountType === AccountType.ADMIN && (
              <span className="text-red-800 p-1 hover-shadow1 rounded cursor-pointer" onClick={() => onTrash(index)}>
                <TrashIcon className="w-4 h-4"></TrashIcon>
              </span>
            )}

            {hasFullPermission && task.deleted && (
              <span
                className="text-shade-blue p-1 hover-shadow1 rounded cursor-pointer"
                onClick={() => onRecoverDelete(index)}
              >
                <ArrowLeftCircleIcon className="w-4 h-4"></ArrowLeftCircleIcon>
              </span>
            )}
          </span>
        </td>
      )}
    </tr>
  )
}
