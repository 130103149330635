const cloneDeep = require('clone-deep')
import { authUpdateProfile } from 'actions'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyBorrowerProfile, updateMyBorrowerProfile } from 'services'
import { Button } from 'stories/components'
import { InputConvert } from 'utils/convertor'
import { RenderInput } from 'utils/RenderInput'
import { validateForm } from 'utils/validator'

const defaultInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      inputType: 'text',
      type: 'text',
      title: 'Legal First',
      required: true,
    },
    lastName: {
      inputType: 'text',
      type: 'text',
      title: 'Legal Last',
      required: true,
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      required: true,
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone Number',
      required: true,
    },
    presentAddress: {
      inputType: 'map',
      title: 'Current Address',
      required: true,
    },
  }
}

export function ProfileInfo() {
  const dispatch = useDispatch()
  const profile = useSelector((state: any) => state.auth.profile)

  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getMyBorrowerProfile()
      .then((data: Record<string, any>) => {
        const newInputs = cloneDeep(defaultInputs())
        Object.keys(newInputs).map((key) => (newInputs[key].value = data[key]))
        setInputs(newInputs)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: string) => {
    if (inputs[key].value == value) return

    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const { hasError, data } = validateForm({
      inputs,
      setInputs,
    })
    if (hasError) return

    setLoading(true)
    updateMyBorrowerProfile(data)
      .then(() => {
        dispatch(
          authUpdateProfile({
            ...profile,
            ...data,
            fullName: `${data.firstName} ${data.lastName}`,
          }),
        )
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="ProfileInfo-container">
      <div className="bg-white p-5 xl:mr-8 rounded shadow1">
        <h1 className="text-2xl font-bold flex items-center mb-5">
          <span>My Profile</span>
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          {Object.keys(inputs).map((key) => {
            const input = inputs[key]
            if (
              input.visible === false ||
              (input.visible && typeof input.visible == 'function' && !input.visible(inputs))
            )
              return null

            return (
              <div className="mb-4">
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
        <div className="mt-3 flex justify-center">
          <Button loading={isLoading} onClick={onSubmit} className="w-fit">
            Update
          </Button>
        </div>
      </div>
    </div>
  )
}
