import type { InputType } from 'config'
import type { FormTableHeader } from 'stories/components'

export const header: FormTableHeader[] = [
  { key: 'firstName', title: 'First Name' },
  { key: 'lastName', title: 'Last Name' },
  { key: 'email', title: 'Email' },
  { key: 'phone', title: 'Phone' },
  { key: 'dob', title: 'DOB(MM/DD/YYYY)' },
  { key: 'ssn', title: 'Social Security Number' },
  { key: 'presentAddress', title: 'Present Address' },
]

export const hasRecordInputs = (): Record<string, InputType> => {
  return {
    hasAdditionalMember: {
      inputType: 'check',
      title: 'Has Additional Entity Members?',
      required: true,
      history: true,
    },
  }
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      inputType: 'text',
      title: 'First Name',
      required: true,
    },
    lastName: {
      inputType: 'text',
      title: 'Last Name',
      required: true,
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      required: true,
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
      required: true,
    },
    dob: {
      inputType: 'text',
      type: 'date',
      title: 'DOB(MM/DD/YYYY)	',
      required: true,
    },
    ssn: {
      inputType: 'text',
      type: 'ssn',
      title: 'Social Security Number',
      required: true,
    },
    presentAddress: {
      inputType: 'map',
      title: 'Present Address',
      required: true,
    },
    membershipPercentage: {
      inputType: 'text',
      prefix: '%',
      value: 0,
      title: 'Membership Percentage',
      required: true,
    },
  }
}
