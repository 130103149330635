import { SurveyType } from 'pages/Admin/ConditionsAndTemplates'
import { Modal } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

import { ISurvey } from './type'

export const SurveyModal = ({ data, onClose }: { data: ISurvey; onClose: Function }) => {
  return (
    <Modal
      isOpen
      onClose={() => onClose()}
      title={`Review of Loan ${data.byteproFileName || data.loanNumber}`}
      titleOkay=""
      titleCancel="Close"
    >
      <div className="w-196 flex flex-col gap-4">
        <RenderInput
          input={{
            inputType: 'text',
            title: 'Name',
            required: true,
            readOnly: true,
            value: data.name,
          }}
          Key="name"
          onChange={() => {}}
        />

        <RenderInput
          input={{
            inputType: 'email',
            title: 'Email',
            required: true,
            readOnly: true,
            value: data.email,
          }}
          Key="email"
          onChange={() => {}}
        />
        {data.data.map((item, index) => (
          <RenderInput
            key={`survey-item-${index}`}
            input={{
              inputType: item.type === SurveyType.Rating ? 'rating' : 'textarea',
              title: item.title,
              required: true,
              readOnly: true,
              value: item.value as any,
              rows: item.type === 1 ? 2 : undefined,
            }}
            Key={`input-${index}`}
            onChange={() => {}}
          />
        ))}
      </div>
    </Modal>
  )
}
