import cloneDeep from 'clone-deep'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { loanLockExtension } from 'services'
import { Input2, Modal, Select2 } from 'stories/components'
import { formatDate } from 'utils'

import type { ILoanProcess } from '../interfaces'

interface IProps {
  loanProcessData: ILoanProcess
  isOpen: boolean
  onClose: () => void
  setLoanProcessData: (data: ILoanProcess) => void
}

export function LockExtensionDialog(props: IProps) {
  const { isOpen, onClose, setLoanProcessData, loanProcessData } = props
  const { extensions, expireDate, price } = loanProcessData

  const [day, setDay] = useState('0')
  const [loading, setLoading] = useState(false)

  const onCloseModal = () => {
    setDay('0')
    setLoading(false)
    onClose()
  }

  const onSubmit = async () => {
    if (day === '0') {
      return toast('Extension Day is Required!', { type: 'error' })
    }
    setLoading(true)

    try {
      const res = await loanLockExtension(Number(day))
      if (res.success) {
        const temp = cloneDeep(loanProcessData)
        temp.price = Number((temp.price - 0.02 * Number(day)).toFixed(2))

        if (!temp?.extensions) temp.extensions = []
        temp.extensions.push(Number(day))

        temp.expireDate = moment(expireDate).tz('America/New_York').add(day, 'days').format('MM/DD/YYYY HH:mm')
        setLoanProcessData(temp)
        setLoading(false)
        onCloseModal()
        return true
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const renderInputs = useMemo(() => {
    let rlt: any = []
    let remainDays = 15

    if (!extensions) return null

    extensions.map((item, index) => {
      rlt.push(
        <div key={index} className="mb-3">
          <Input2
            title={`Extension${index + 1}`}
            key={index}
            value={item.toString()}
            disabled={true}
            onChange={() => {}}
          />
        </div>,
      )
      remainDays -= item
    })

    const currentIndex = extensions.length + 1
    let options = []

    for (let i = 1; i <= remainDays; i += 1) options.push(i.toString())

    rlt.push(
      <div key={currentIndex}>
        <Select2
          title={`Extension${currentIndex}`}
          id="extension"
          hasDefaultOption={true}
          options={options}
          key={currentIndex}
          value={day}
          onChange={(value) => setDay(value)}
        />
      </div>,
    )

    return (
      <div className="">
        <div className="relative overflow-x-auto shadow rounded mb-5">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 border"></th>
                <th scope="col" className="px-6 py-3 border">
                  Expire Date
                </th>
                <th scope="col" className="px-6 py-3 border">
                  Price
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-900">
              <tr>
                <td scope="col" className="px-6 py-3 border">
                  Current
                </td>
                <td scope="col" className="px-6 py-3 border">
                  {formatDate(expireDate)}
                </td>
                <td scope="col" className="px-6 py-3 border">
                  {price}
                </td>
              </tr>
              <tr>
                <td scope="col" className="px-6 py-3 border">
                  After Extension
                </td>
                <td scope="col" className="px-6 py-3 border">
                  {day !== '0' && moment(expireDate).tz('America/New_York').add(day, 'days').format('MM/DD/YYYY')}
                </td>
                <td scope="col" className="px-6 py-3 border">
                  {day !== '0' && (price - Number(day) * 0.02).toFixed(4)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {rlt}
      </div>
    )
  }, [extensions, day])

  return (
    <>
      <Modal
        button={<span></span>}
        title="Lock Extensions"
        titleOkay="Confirm"
        titleCancel="Close"
        init={false}
        loading={loading}
        isOpen={isOpen}
        lastUpdatedAt={Date.now()}
        onClose={onCloseModal}
        childLevel={1}
        onOk={onSubmit}
      >
        <div className="">
          <div
            className="p-3 mb-4 text-[16px] bg-blue-100 border border-blue-400 text-blue-700 rounded-lg"
            role="alert"
          >
            <div>- You can extend your lock at most 15 days after the last Lock or Relock!</div>
            <div>- The cost of the lock extension is 2 basis point per day.</div>
          </div>
          <div>{renderInputs}</div>
        </div>
      </Modal>
    </>
  )
}
