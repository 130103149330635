import { ArrowDownTrayIcon, ArrowTrendingUpIcon, Square2StackIcon } from '@heroicons/react/24/outline'
import { setBorrowerGroupData, setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LoanType } from 'reducers/loanDetail.reducer'
import { borrowerInfoUpdate, copyApplicationToUrl, downloadFile, getSSAPdf, sendApplicationTo } from 'services'
import { Button } from 'stories/components'
import {
  canEditLoanApplication,
  capitalizeFirstLetter,
  InputConvert,
  InputValidate,
  isBorrowerUrl,
  openAuditLog,
} from 'utils'
import { copyClipboard } from 'utils/copyClipboard'
import { RenderInput } from 'utils/RenderInput'

import { AlternativeNamesModal } from './AlternativeNamesModal'
import { defaultInputs } from './constants'
import { borrowerInfoValidate, visibleBorrowerFieldsLogic } from './logic'

export function BorrowerInformation(props: any) {
  const { borrowerSeperator } = props
  const [action, setAction] = useState('')
  const [inputs, setInputs] = useState(defaultInputs())
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [visibelFields, setVisibleFields] = useState(visibleBorrowerFieldsLogic(borrowerSeperator))
  const [isAlternativeNames, showAlternativeNames] = useState(false)
  const location = useLocation()

  const { pathname } = location
  const isSignApplicationLink = useMemo(() => isBorrowerUrl(pathname), [location])

  const dispatch = useDispatch()
  const { borrower, loanDetail } = useSelector((state: any) => {
    return {
      borrower: state.borrower,
      loanDetail: state.loanDetail,
    }
  })

  useEffect(() => {
    let entityTitle = borrower.borrower.entityTitle
    if (borrower.borrower.hasEntityTitle !== true) entityTitle = ''
    dispatch(
      setLoanDetail({
        entityTitle,
      }),
    )
  }, [borrower.borrower.hasEntityTitle, borrower.borrower.entityTitle])

  useEffect(() => {
    let _visibelFields = visibleBorrowerFieldsLogic(borrowerSeperator)
    setVisibleFields(_visibelFields)
    setInputs(borrowerInfoValidate(borrowerSeperator, _visibelFields, inputs))
  }, [borrowerSeperator])

  const onChange = async (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    setFieldValueChanged(true)
    value = InputConvert(newInputs[key], value)
    await dispatch(setBorrowerGroupData(borrowerSeperator, { [key]: value }))
    let _visibelFields = visibelFields
    if (['hasEntityTitle', 'ownedRentedYears', 'borrowerType'].indexOf(key) !== -1) {
      _visibelFields = visibleBorrowerFieldsLogic(borrowerSeperator)
      setVisibleFields(_visibelFields)
    }
    setInputs(borrowerInfoValidate(borrowerSeperator, _visibelFields, inputs))
  }

  const onBlur = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = inputs[key]
    if (input.error && input.error.length > 0) return
    props.setLoading(true)
    const reqBody = {
      [key]: borrower[borrowerSeperator][key],
      borrowerSeperator,
    }
    await borrowerInfoUpdate(reqBody)
    props.setLoading(false)
    borrowerInfoValidate(borrowerSeperator, [], {}, true, true)
  }

  const showHistory = async (key: string) => {
    const options = {
      table: 'Borrower',
      field: `${borrowerSeperator}-${key}`,
      keys: {
        field: key,
        borrowerSeperator,
      },
    }
    openAuditLog(options)
  }

  const canEdit = canEditLoanApplication()

  const validateEmail = () => {
    const anotherKey = borrowerSeperator == 'borrower' ? 'coBorrower' : 'borrower'
    const borrowerTitle = capitalizeFirstLetter(borrowerSeperator)
    const otherBorrowerTitle = capitalizeFirstLetter(anotherKey)
    const needCheckOtherBorrower = borrowerSeperator == 'coBorrower' || loanDetail.includeCoborrower

    const { value: email, error } = inputs.email
    if (!email || error) {
      toast(`Please confirm ${borrowerTitle} Email again.`, { type: 'warning' })
      return false
    }
    if (needCheckOtherBorrower && !borrower[anotherKey].email) {
      toast(`Please confirm ${otherBorrowerTitle} Email again.`, { type: 'warning' })
      return false
    }

    if (needCheckOtherBorrower && borrower[anotherKey].email == email) {
      toast(`Please confirm ${otherBorrowerTitle} Email again. You can't have same email as ${borrowerTitle}`, {
        type: 'warning',
      })
      return false
    }

    const { value: presentAddress, error: presentAddressError } = inputs.presentAddress
    if (!presentAddress || presentAddressError) {
      toast(`Please confirm ${borrowerTitle} Present Address again.`, { type: 'warning' })
      return false
    }
    const { value: ssn, error: ssnError } = inputs.ssn
    if (!ssn || ssnError) {
      toast(`Please confirm ${borrowerTitle} Social Security Number again.`, { type: 'warning' })
      return false
    }
    if (needCheckOtherBorrower && !borrower[anotherKey].ssn) {
      toast(`Please confirm ${otherBorrowerTitle} Social Security Number again.`, { type: 'warning' })
      return false
    }

    return email
  }

  const sendApplication = () => {
    const email = validateEmail()
    if (!email) return

    setAction('sendApplication')
    sendApplicationTo(email, borrowerSeperator)
      .then(() => {
        toast('Application is sent to Borrower', { type: 'success' })
      })
      .finally(() => setAction(''))
  }

  const copyAppURL = () => {
    const email = validateEmail()
    if (!email) return

    setAction('copyApplicationUrl')
    copyApplicationToUrl(email, borrowerSeperator)
      .then(({ link }) => {
        copyClipboard(link)
      })
      .finally(() => setAction(''))
  }

  const onDownloadSSAPdf = async () => {
    const validationFields = ['firstName', 'lastName', 'dob', 'ssn']
    let hasError = false
    const newInputs = cloneDeep(inputs)
    for (const key of validationFields) {
      const input: any = newInputs[key]
      input.error = InputValidate(input)
      if (input.error && input.error.length > 0) hasError = true
    }
    if (hasError) {
      setInputs(newInputs)
      toast(`First Name, Last Name, DOB, SSN is required!`, { type: 'error' })
      return
    }

    setAction('generateSSAPdf')
    const data: Blob = await getSSAPdf('borrower')
    downloadFile(
      `SSA Form - ${loanDetail.byteproFileName ? loanDetail.byteproFileName : loanDetail.loanNumber}(${
        borrower.borrower.firstName
      } ${borrower.borrower.lastName}).pdf`,
      data,
    )
    setAction('')
  }

  return (
    <div>
      {!isSignApplicationLink && loanDetail.loanType !== LoanType.CORRESPONDENT && (
        <div className="flex justify-center flex-wrap gap-4 my-2">
          <Button className="" color="gray" onClick={sendApplication} loading={action === 'sendApplication'}>
            <div className="flex gap-2 items-center">
              {borrowerSeperator === 'borrower' ? 'Send Application to Borrower' : 'Send Application to CoBorrower'}
              <ArrowTrendingUpIcon className="w-4 h-4"></ArrowTrendingUpIcon>
            </div>
          </Button>
          <Button link className="" color="gray" onClick={copyAppURL} loading={action === 'copyApplicationUrl'}>
            <div className="flex gap-1 items-center px-3">
              Copy Application URL
              <Square2StackIcon className="w-4 h-4"></Square2StackIcon>
            </div>
          </Button>
        </div>
      )}

      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          if (visibelFields.indexOf(key) === -1) return null
          let input = inputs[key]
          if (borrower[borrowerSeperator][key] !== undefined) {
            input.value = borrower[borrowerSeperator][key]
          } else {
            input.value = ''
          }
          input.history = true
          if (input.disabled) {
          } else {
            input.disabled = !canEdit
          }
          let cn = `input md:col-span-1`
          if (['hasEntityTitle'].indexOf(key) !== -1) {
            cn = `input md:col-span-2`
          }
          if (key === 'firstName')
            input.additionalElements = (
              <span
                className="ml-1 flex items-center gap-1 text-shade-blue hover:underline cursor-pointer"
                onClick={() => showAlternativeNames(true)}
              >
                Alternative Names
              </span>
            )

          if (key === 'ssn')
            input.additionalElements = (
              <span
                className="ml-1 flex items-center gap-1 text-shade-blue hover:underline cursor-pointer"
                onClick={onDownloadSSAPdf}
              >
                SSA89 PDF
                <ArrowDownTrayIcon className="h-[14px] w-[14px]" aria-hidden="true" />
              </span>
            )
          return (
            <div className={cn} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} onBlur={onBlur} />
            </div>
          )
        })}
      </div>
      <LayoutLoading show={action === 'generateSSAPdf'} />
      {isAlternativeNames && (
        <AlternativeNamesModal borrowerSeperator={borrowerSeperator} onClose={() => showAlternativeNames(false)} />
      )}
    </div>
  )
}
