import { ArrowUpTrayIcon, CloudArrowUpIcon, EyeIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { SourceBox, TargetBox } from 'components/DragDrop'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { acceptedFileTypes } from 'config/file.type'
import { TemporaryLayout } from 'layouts'
import { docCategory } from 'pages/LoanSubmission/constants'
import type { LoanSubmissionCondition, LoanSubmissionDocument } from 'pages/LoanSubmission/types'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getLoanSubmission, openS3Document, updateLoanSubmission, uploadFiles } from 'services'
import { Alert, PlainTable, Select } from 'stories/components'
import { formatTime, replaceNextLineToBr } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export const BorrowerLoanSubmission = () => {
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState<LoanSubmissionDocument[]>([])
  const [borrowerDocuments, setBorrowerDocuments] = useState<LoanSubmissionDocument[]>([])
  const [conditions, setConditions] = useState<LoanSubmissionCondition[]>([])
  const [originItemExpands, setOriginItemExpands] = useState<Record<string, boolean>>({})

  const loanNumber = setLoanNumber()
  const profile = useSelector((state: any) => state.auth.profile)

  useEffect(() => {
    const params = location.pathname.split('/')
    const loanNumber = params[3]
    if (!loanNumber || isNaN(parseInt(loanNumber))) return

    setLoading(true)
    getLoanSubmission()
      .then(({ documents, conditions, borrowerDocuments }) => {
        setDocuments(documents)
        setConditions(conditions)
        setBorrowerDocuments(borrowerDocuments)
      })
      .finally(() => setLoading(false))
  }, [])

  const uploadDocumentFiles = (files: File[], conditionNo: number): Promise<any> => {
    return new Promise((resolve) => {
      const data = {
        path: `loan/${loanNumber}/document`,
      }

      setLoading(true)

      let acceptedFiles: any = []
      files.map((file) => {
        const { name } = file
        const params = name.toLowerCase().split('.')
        const length = params.length
        const ext = params[length - 1]
        if (acceptedFileTypes.includes(ext)) {
          acceptedFiles.push(file)
        } else {
          toast(`${name} is not ${acceptedFileTypes.join(', ')}`, { type: 'error' })
        }
      })

      uploadFiles(data, acceptedFiles).then(async (keys: Array<string>) => {
        const newData = cloneDeep(documents)
        const newDocs: LoanSubmissionDocument[] = []
        keys.forEach((key, index) => {
          newDocs.push({
            conditionNo: conditionNo,
            name: acceptedFiles[index].name,
            key,
            status: 'Not Reviewed',
            category: '',
            createdAt: Date.now(),
            generated: false,
            createdBy: profile.email,
          })
        })
        newData.push(...newDocs)
        setDocuments(newData)
        setLoading(true)
        await updateLoanSubmission({}, { update: newDocs })
        setLoading(false)

        resolve({
          documents: newData,
          newDocuments: newDocs,
        })
      })
    })
  }

  const onDropDocument = async (document: any, conditionNo: number) => {
    let newData = cloneDeep(documents)
    let newDocuments: any[] = []

    setLoading(true)
    if (document.files) {
      const { newDocuments: newDocs, documents } = await uploadDocumentFiles(document.files, conditionNo)
      newData = cloneDeep(documents)
      newDocuments = cloneDeep(newDocs)
    } else newDocuments = [document]

    const updatedData = newDocuments
      .map((document) => {
        const index = newData.findIndex((doc) => doc.key == document.key)
        if (index == -1) return null
        if (conditionNo == newData[index].conditionNo) return null

        newData[index].conditionNo = conditionNo
        return newData[index]
      })
      .filter((data) => !!data)
    if (updatedData.length == 0) {
      setLoading(false)
      return
    }

    setDocuments(newData)
    await updateLoanSubmission({}, { update: updatedData })
    setLoading(false)
  }

  const onExpandOriginItem = (key: string) => {
    const newData = cloneDeep(originItemExpands)
    if (newData[key]) delete newData[key]
    else newData[key] = true
    setOriginItemExpands(newData)
  }

  const onUpdateDocumentProp = async (key: string, value: string, conditionNo: number, documentKey: string) => {
    const newData = cloneDeep(documents)
    const item = newData.find((doc) => (!conditionNo || doc.conditionNo == conditionNo) && doc.key == documentKey)
    if (!item) return
    if (key == 'name') value = value + item.name.substr(-4)
    ;(item as any)[key] = value
    setDocuments(newData)
    setLoading(true)
    await updateLoanSubmission({}, { update: [item] })
    setLoading(false)
  }

  /**
   *
   * @param event
   * @param conditionNo
   * @returns
   */
  const onUploadNewfile = (event: React.ChangeEvent<HTMLInputElement>, conditionNo: number) => {
    const { files } = event.target
    if (!files || files.length == 0) return
    const arrFile: Array<File> = []
    for (let i = 0; i < files.length; i++) arrFile.push(files[i])

    uploadDocumentFiles(arrFile, conditionNo)
  }

  const renderOriginDocument = (key: number) => {
    const properDocs = documents.filter(({ conditionNo }) => conditionNo == key)

    return properDocs.map((document, index: number) => (
      <SourceBox item={document} type="document" key={document.key}>
        <tr className="hover:bg-slate-100" key={index}>
          <td className="pl-10">
            <PlainInput
              value={document.name.substr(0, document.name.length - 4)}
              content={document.name}
              onChange={(newName: string) => onUpdateDocumentProp('name', newName, 0, document.key)}
            />
          </td>
          <td className="px-2 w-52 py-1">
            <Select
              id={`document-category-${document.conditionNo}`}
              size={3}
              className="-mb-4"
              options={docCategory}
              value={document.category}
              onChange={(value) => onUpdateDocumentProp('category', value, 0, document.key)}
            />
          </td>
          <td className="px-2 w-40">{formatTime(document.createdAt)}</td>
          <td className="px-2 w-20">
            <button
              className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
              onClick={() => openS3Document(document.key, true)}
            >
              <EyeIcon className="w-4 h-4" />
            </button>
          </td>
        </tr>
      </SourceBox>
    ))
  }

  const renderOriginalTemplate = () => {
    let index = 0
    const content = (
      <React.Fragment>
        {conditions
          .filter((cond) => !!cond.requested)
          .map((condition) => {
            let bgCn = index % 2 ? 'bg-gray-50' : ''
            const key = condition.no
            index += 1
            return (
              <React.Fragment key={key}>
                <tr className={bgCn}>
                  <td colSpan={4} className="border-b px-1">
                    <TargetBox
                      types={['document', NativeTypes.FILE]}
                      onDrop={(document: any) => onDropDocument(document, key)}
                    >
                      <table className="w-full">
                        <tbody>
                          <tr className="">
                            <td className="px-6 py-1" colSpan={3}>
                              <div className="flex items-center">
                                <span className="mr-3">{index}.</span>{' '}
                                <span className="text-[15px]">{condition.name}</span>
                                <button
                                  className="text-gray-700 p-1 hover-shadow1 cursor-pointer ml-2"
                                  onClick={() => onExpandOriginItem(`${key}`)}
                                >
                                  <QuestionMarkCircleIcon className="w-4 h-4"></QuestionMarkCircleIcon>
                                </button>
                              </div>
                            </td>
                            <td className="px-6 py-1 w-20">
                              <label
                                className="text-gray-900 p-1 hover-shadow1 cursor-pointer inline-block"
                                htmlFor={`document-file-${key}`}
                              >
                                <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                              </label>

                              <input
                                className="hidden"
                                id={`document-file-${key}`}
                                type="file"
                                accept=".pdf,.xml"
                                multiple
                                required
                                onChange={(event) => onUploadNewfile(event, key)}
                              />
                            </td>
                          </tr>
                          {originItemExpands[key] ? (
                            <tr>
                              <td className="pl-12 py-1 text-gray-600" colSpan={3}>
                                <span
                                  dangerouslySetInnerHTML={{ __html: replaceNextLineToBr(condition.description) }}
                                />
                              </td>
                              <td></td>
                            </tr>
                          ) : null}
                          {renderOriginDocument(key)}
                        </tbody>
                      </table>
                    </TargetBox>
                  </td>
                </tr>
              </React.Fragment>
            )
          })}
      </React.Fragment>
    )

    return (
      <div className="relative parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
        <div className="absolute top-1 right-[calc(50%-135px)] w-full h-fit">
          <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
            <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
            <span>Drag and drop files.</span>
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3">Document/File Name</th>
              <th className="px-6 py-3 w-52">Category</th>
              <th className="px-6 py-3 w-40">Date Uploaded</th>
              <th className="px-6 py-3 w-20">Actions</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>
      </div>
    )
  }

  const renderBorrowerDocuments = () => {
    if (!borrowerDocuments.length) return null

    return (
      <div>
        <p className="font-semibold mb-4 text-xl">Shared Documents</p>
        <PlainTable
          header={['No', 'Name', 'Status', 'Category', 'Action']}
          data={borrowerDocuments.map((doc, index) => [
            `${index + 1}`,
            doc.name,
            doc.status,
            doc.category,
            <div className="">
              <button
                className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center text-shade-blue"
                onClick={() => openS3Document(doc.key)}
              >
                <EyeIcon className="w-4 h-4" />
              </button>
            </div>,
          ])}
        />
      </div>
    )
  }

  return (
    <TemporaryLayout title="Document Storage" requireSign>
      <LayoutLoading show={loading} />

      {!!conditions.length && (
        <div className="mb-6">
          <Alert color="blue" className="inline-block mb-4">
            The allowable format for documents being uploaded is .pdf, .xls, .xlsx, and .msg.
          </Alert>

          <DndProvider backend={HTML5Backend}>{renderOriginalTemplate()}</DndProvider>
        </div>
      )}

      {renderBorrowerDocuments()}
    </TemporaryLayout>
  )
}
