import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useMemo } from 'react'
import { getPrice3decimal } from 'utils'

import type { ILoanAmountLeverageLimit } from '../../interface'

export const LoanAmountLeverageLimit = ({ data }: { data: ILoanAmountLeverageLimit[] }) => {
  const renderLtvMaxLimits = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="w-full text-center text-sm">
        <thead className="font-bold bg-gray-100">
          <tr>
            <th colSpan={2} className="border p-1">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1">
              Loan Purpose
            </th>
            <th colSpan={4} className="border p-1">
              Max LTVs
            </th>
          </tr>
          <tr>
            <th className="border p-1 w-[200px]">From</th>
            <th className="border p-1 w-[200px]">To</th>
            <th className="border p-1 w-[120px]">AIV-LTV</th>
            <th className="border p-1 w-[120px]">ARV-LTV</th>
            <th className="border p-1 w-[120px]">LTC</th>
            <th className="border p-1 w-[120px]">LTP</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3} className="border px-3 py-1">
                    {getPrice3decimal(item.loanAmount.from)}
                  </td>
                  <td rowSpan={3} className="border px-3 py-1">
                    {getPrice3decimal(item.loanAmount.to)}
                  </td>

                  <td className="border px-3 py-1">Purchase</td>

                  {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {(item.LTV.purchase as any)[key]}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="border px-3 py-1">No-Cashout</td>

                  {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {(item.LTV.nocashout as any)[key]}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="border px-3 py-1">Cashout</td>

                  {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {(item.LTV.cashout as any)[key]}
                    </td>
                  ))}
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return <div className="h-full">{renderLtvMaxLimits}</div>
}
