import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import React, { useState } from 'react'
import { confirm, formatDate, thousandSeperator } from 'utils'

import type { IAccountingCategory } from '../AccountingCategories'
import type { IAccountingAccount } from '../Accounts/types'
import { EditAccountingContent } from './EditAccountingDialog'
import { type IAccounting, AssetsBaseId } from './types'

export const BalanceTable = ({
  type,
  baseId = 0,
  baseCategory,
  category,
  accounts,
  data,
  onChange,
}: {
  type: string
  baseId: number
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  accounts: IAccountingAccount[]
  data: IAccounting[]
  onChange: Function
}) => {
  const [curEditItem, setCurEditItem] = useState<IAccounting | null>()

  const onUpdateEditItem = (item: IAccounting | null) => {
    setCurEditItem(undefined)
    if (!item) return

    item.baseId = baseId
    const newData = cloneDeep(data)
    if (item.id < 0) newData.push(item)
    else {
      const index = data.findIndex((v) => v.id == item.id)
      newData[index] = item
      newData[index].updated = true
    }
    onChange({ baseId, data: newData })
  }

  const onDelete = async (item: IAccounting) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this item? This record will be deleted permanently.
        <br />
        <span className="text-gray-600">
          Description: {item.description} #{item.id > 0 ? item.id : 'NEW'}
        </span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(data)
    const index = data.findIndex((v) => v.id == item.id)
    if (item.id < 0) {
      newData.splice(index, 1)
    } else {
      newData[index] = item
      newData[index].deleted = true
      newData[index].updated = true
    }

    onChange({ baseId, data: newData })
    if (curEditItem && item.id == curEditItem.id) setCurEditItem(undefined)
  }

  return (
    <div className="p-2 text-sm w-full">
      <div className="flex items-center justify-between">
        <p className="font-semibold mb-2">{baseId == AssetsBaseId ? 'Assets' : 'Liabilities'}</p>
        <span className="text-shade-blue hover:underline cursor-pointer" onClick={() => setCurEditItem(null)}>
          + Add
        </span>
      </div>

      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {['No', 'Date', 'Description', 'Amount', 'Loan Number', ''].map((v) => (
              <th scope="col" className="px-3 py-1" key={`header-${v}`}>
                {v}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .filter((v) => !v.deleted)
            .map((item, index) => (
              <React.Fragment key={`SubRow-${item.id}`}>
                <tr
                  className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
                    item.duplicated && item.override !== true ? 'opacity-50' : ''
                  }`}
                  key={`balance-${item.id}-${index}`}
                >
                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>

                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {formatDate(item.date)}
                  </td>

                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {item.description} #{item.id > 0 ? item.id : 'NEW'}
                  </td>

                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {thousandSeperator(item.amount)}
                  </td>

                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {item.loanNumber}
                  </td>

                  <td className="px-3 py-1">
                    <div className="flex gap-2">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                        onClick={() => setCurEditItem(item)}
                      >
                        <PencilSquareIcon className="w-4 h-4" />
                      </span>

                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                        <TrashIcon className="w-4 h-4" />
                      </span>
                    </div>
                  </td>
                </tr>
                {curEditItem && curEditItem.id == item.id ? (
                  <tr className="bg-gray-100">
                    <td></td>
                    <td colSpan={6} className="pr-10">
                      <EditAccountingContent
                        type={type}
                        baseCategory={baseCategory}
                        category={category}
                        accounts={accounts}
                        item={curEditItem}
                        hasBalanceTable={false}
                        baseId={baseId}
                        onClose={onUpdateEditItem}
                      />
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
        </tbody>
      </table>

      {curEditItem === null && (
        <div className="text-gray-900">
          <EditAccountingContent
            type={type}
            baseCategory={baseCategory}
            category={category}
            accounts={accounts}
            item={null}
            hasBalanceTable={false}
            baseId={baseId}
            onClose={onUpdateEditItem}
          />
        </div>
      )}
    </div>
  )
}
