import { ArrowRightCircleIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { thousandSeperator } from 'utils'
import { isConstructionLoan } from 'utils/isConstructionLoan'

export const UnderwritingSlide = () => {
  const { loanDetail, loan } = useSelector((state: any) => {
    return {
      loanDetail: state.loanDetail,
      loan: state.loan,
    }
  })
  const [isShowSlide, setShowSlide] = useState(false)
  const [data, setData] = useState<any>({})
  const [total, setTotal] = useState('')

  const { hasPermission } = usePermissions()

  useEffect(() => {
    const payments = combinedMonthlyExpense(loan, loanDetail.rateData)
    setData({
      [payments['title']]: `$ ${payments['mortgagePayment']}`,
      "Homeowner's Insurance": `$ ${payments['proposedMonthlyInsurance']}`,
      'Property Taxes': `$ ${payments['proposedMonthlyTaxes']}`,
      HOA: `$ ${payments['proposedMonthlyHoaDues']}`,
      'Second Lien Payment': `$ ${payments['secondLienPayment']}`,
      'Flood Insurance': `$ ${payments.floodInsurance}`,
      'School Tax': `$ ${payments.schoolTax}`,
      'Other Tax': `$ ${payments.otherTax}`,
    })
    setTotal(payments['sum'])
  }, [])

  const onOpenLink = (url: string) => {
    window.open(url, '_blank', 'resizable=yes')
  }

  const renderExternalLink = (url: string, title: string, permission: string = '') => {
    if (permission !== '') {
      if (!hasPermission(permission)) return null
    }
    let disabled = false
    const linkElement = (
      <span className="flex gap-2 items-center">
        <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
        <span>{title}</span>
      </span>
    )
    return (
      <li
        className={`my-[7px] hover:underline w-fit ${disabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'}`}
        key={url}
      >
        {disabled ? (
          linkElement
        ) : (
          <span className="hover:underline" onClick={() => onOpenLink(`/${url}/${loanDetail.loanNumber}`)}>
            {linkElement}
          </span>
        )}
      </li>
    )
  }

  const onShowSlide = () => {
    setShowSlide(!isShowSlide)
  }

  return (
    <>
      <div className="fixed right-0 top-[calc(25%-60px)] z-20">
        <button
          className="p-3 rounded-r-none ring-[1px] ring-shade-blue bg-shade-blue text-white border hover:bg-white border-shade-blue hover:text-shade-blue rounded flex items-center gap-2 border-r-0"
          onClick={onShowSlide}
        >
          <ArrowTopRightOnSquareIcon className="w-5 h-5" /> Underwriting
        </button>
      </div>
      {isShowSlide && (
        <div
          className={`shadow1 fixed bg-white border rounded-l z-30 right-0 top-[5vh] bottom-0 transition-[right] w-[24rem] h-[90vh] overflow-auto ease-in-out ${
            isShowSlide ? 'right-0' : '-right-[24rem]'
          }`}
        >
          <div className="w-[24rem] fixed z-20 rounded-t flex justify-between p-3 font-bold text-sm place-items-center items-center text-shade-blue border-b bg-white">
            <div className="flex items-center">
              <p className="text-lg text-gray-800">Underwriting: </p>
              <span className="text-[16px] ml-2 italic font-semibold">
                {loanDetail.byteproFileName > 0 ? loanDetail.byteproFileName : loanDetail.loanNumber}
              </span>
            </div>

            <button className="hover:underline hover-shadow1 p-1 rounded" onClick={onShowSlide}>
              <ArrowRightCircleIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="pt-14 h-fit overflow-auto relative text-sm text-gray-900">
            <ol className="ml-2 pl-4">
              {[
                renderExternalLink('notes', 'Notes', 'ADMIN_TO_AE_PROFILE_PERMISSION'),
                renderExternalLink('loan_process/structure', 'Loan Structure'),
                renderExternalLink('loan_process/application', 'Loan Application'),
                renderExternalLink('review_form', 'Appraisal Review', 'ADMIN_TO_AE_PROFILE_PERMISSION'),
                renderExternalLink('order_valuation', 'Order Valuation'),
                renderExternalLink('appraisal_information', 'Appraisal Information', 'MANAGE_APPRAISAL_INFORMATION'),
                renderExternalLink('expiration_dates', 'Expiration Dates'),
                renderExternalLink('underwriting_summary', 'Summary', 'ADMIN_TO_AE_PROFILE_PERMISSION'),
                renderExternalLink('tasks/loan', 'Tasks', 'MANAGE_TASKS'),
                renderExternalLink('dataverify', 'DataVerify Drive'),
                renderExternalLink('parties', 'Parties', 'MANAGE_PARTIES'),
                isConstructionLoan(loan.productType) &&
                  renderExternalLink('budget', 'Budget & Scope of Work', 'BUDGET_SCOPE_OF_WORK'),
              ]}
            </ol>
            <p className="bg-blue-100 text-shade-blue font-bold p-3 mt-4">Proposed Monthly Property Payment</p>
            <table className="w-full">
              <tbody>
                {Object.keys(data).map((key, index: number) => (
                  <tr className="border-b" key={index}>
                    <td className="px-3 py-2">{key}:</td>
                    <td className="px-3 py-2">{data[key]}</td>
                  </tr>
                ))}
                <tr className="border-b">
                  <td className="px-3 py-2 text-center font-bold">Total:</td>
                  <td className="px-3 py-2 text-shade-blue font-bold">$ {thousandSeperator(total)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}
