import cloneDeep from 'clone-deep'
import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { updateRatesheet } from 'services'
import { Modal } from 'stories/components'
import { formatDate, formatDateYMD, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      required: true,
      span: 2,
    },
  }
}

export const assignToRoles = accountTypes

export const EditRatesheetDialog = (props: { onClose: any; origin: Record<string, any> }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    const { origin } = props
    if (!origin) {
      return
    }

    let newInputs = cloneDeep(inputs)
    newInputs.date.value = formatDateYMD(origin.date)
    setInputs(newInputs)
  }, [props.origin])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)

    if (props.origin) {
      updateRatesheet(props.origin.no, formatDate(data.date))
        .then(() => {
          toast('Ratesheet is updated', { type: 'info' })
          props.onClose(true)
        })
        .catch(() => setLoading(false))
    } else return
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      title={`Update Resource - ${props.origin.date}`}
      titleOkay={'Update'}
      loading={loading}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
