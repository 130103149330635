import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUpTrayIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { setBorrowerGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import {
  borrowerInfoUpdate,
  createLoanTrackRecord,
  deleteLoanTrackRecord,
  downloadLoanTrackRecord,
  parseBudgetTemplateExcel,
  updateLoanTrackRecord,
} from 'services'
import { FormTable, FormTableHeader } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  canEditLoanApplication,
  confirm,
  formatDate,
  formatDateYMD,
  getPrice3decimal,
  isBorrowerUrl,
  openAuditLog,
  removeComma,
  renderTableHistoryValue,
  REQUIRED_FIELD_ERROR_MESSAGE,
} from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, hasRecordInputs, header } from './constants'
import { howManyTrackRecord, trackRecordValidate, trackStatusCheck } from './logic'

export interface IAssetTrackData {
  id?: string
  asRental: boolean
  propertyType: string
  purchaseDate: string
  purchasePrice: number
  additionalNotes: string
  propertyAddress: string
  renovationBudget: number
  salesDate?: string
  salesPrice?: number
}

export function TrackRecord({ setLoading }: any) {
  const [init, setInit] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [length, setLength] = useState(-1)
  const inputs = hasRecordInputs()
  const [tableInputs, setTableInputs] = useState<any>(defaultInputs())

  useEffect(() => {
    setTableInputs(inputLogic(tableInputs))
  }, [])

  const { hasPermission } = usePermissions()

  const location = useLocation()

  const dispatch = useDispatch()
  const { borrower, loan, loanNumber } = useSelector((state: any) => {
    return {
      borrower: state.borrower.borrower,
      loan: state.loan,
      loanNumber: state.loanDetail.loanNumber,
    }
  })

  const borrowerSeperator = 'borrower'

  const convertData = (data: any) => {
    let rlt: any = []
    data.map((item: any) => {
      const status: any = trackStatusCheck(item, true)

      let json: any = {
        ...item,
        propertyTypeAddress: `<span><div class="border-b w-full mb-2">${item.propertyType}</div>${item.propertyAddress}</span>`,
        purchasePriceDate: `<span><div class="border-b w-full mb-2">$${getPrice3decimal(
          item.purchasePrice,
        )}</div>${formatDate(item.purchaseDate)}</span>`,
        salesPriceDate: item.asRental
          ? '-'
          : `<span><div class="border-b w-full mb-2">$${getPrice3decimal(item.salesPrice)}</div>${formatDate(
              item.salesDate,
            )}</span>`,
        status: status.success ? (
          <div className="py-1 px-2 bg-green-200 text-green-900 rounded w-fit">
            <div>Pass</div>
            {item.override && <div className="border-t border-green-300 text-red-900">Overridden</div>}
          </div>
        ) : (
          <div className="flex cursor-pointer">
            <Tooltip
              message={`<div class="w-[225px] whitespace-normal leading-[14px]">${status.errors.join('<br/>')}</div>`}
            >
              <div className="py-1 px-2 bg-red-200 text-red-900 rounded">
                <div className="flex items-center gap-1 rounded">
                  Fail
                  <QuestionMarkCircleIcon className="w-3 h-3"></QuestionMarkCircleIcon>
                </div>
              </div>
            </Tooltip>
          </div>
        ),
      }
      rlt.push(json)
    })
    return rlt
  }

  const getData = useMemo(() => {
    return convertData(data)
  }, [data])

  const passedTracks = useMemo(() => {
    let rlt = 0
    data.map((item: any) => {
      rlt += trackStatusCheck(item) ? 1 : 0
    })
    if (borrower.hasTrackRecord != true) rlt = 0
    return rlt
  }, [data, borrower])

  const requiredTracks = useMemo(() => {
    return howManyTrackRecord()
  }, [loan])

  useEffect(() => {
    setData(borrower.tracks)
    setLength(borrower.tracks.length)
    setTimeout(() => {
      setInit(true)
    }, 500)
  }, [])

  const onSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      let temp = cloneDeep(borrower.tracks)
      setLoading(true)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        createLoanTrackRecord(values)
          .then(() => {
            resolve(values)
            setLength(length + 1)
            temp.push(values)
            dispatch(setBorrowerGroupData(borrowerSeperator, { tracks: temp }))
            setData(temp)
          })
          .finally(() => setLoading(false))
      } else {
        updateLoanTrackRecord(currentId, values)
          .then(() => {
            resolve(values)
            temp.map((item: any, index: number) => {
              if (item.id === currentId) temp[index] = { ...item, ...values }
            })
            dispatch(setBorrowerGroupData(borrowerSeperator, { tracks: temp }))
            setData(temp)
          })
          .finally(() => setLoading(false))
      }
    })
  }

  const onRemove = async (id: any) => {
    return new Promise((resolve) => {
      setLoading(true)
      deleteLoanTrackRecord(id)
        .then(() => {
          resolve(true)
          setLength(length - 1)
          let temp: any = []
          borrower.tracks.map((item: any) => {
            if (item.id !== id) temp.push(item)
          })
          dispatch(setBorrowerGroupData(borrowerSeperator, { tracks: temp }))
          setData(temp)
        })
        .finally(() => setLoading(false))
    })
  }

  const inputLogic = (data: any) => {
    const asRental = data.asRental.value === true
    data.salesPrice.visible = !asRental
    data.salesDate.visible = !asRental
    data.override.visible = hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE')

    return data
  }

  const canEdit = canEditLoanApplication()

  const validateMessage = (requiredTracks: number, confirmModal: boolean) => {
    return (
      <div className="text-left bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]">
        <div>
          This loan is structured with experience of {requiredTracks} past projects. <br />A minimum of {requiredTracks}{' '}
          acceptable projects completed in the track record is required.
        </div>
        {confirmModal ? (
          <div>Are you still wanting to change, has no track record?</div>
        ) : isBorrowerUrl(location.pathname) ? (
          <div>
            Otherwise, please contact your <span className="font-semibold">Loan Officer</span> to restructure the
            transaction.
          </div>
        ) : (
          <div className="flex items-center">
            Otherwise, please{' '}
            <Link to={`/loan_process/structure/${loanNumber}`}>
              <span className="mx-2 font-semibold cursor-pointer hover:underline flex items-center gap-1">
                restructure
                <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
              </span>
            </Link>{' '}
            the transaction.
          </div>
        )}
      </div>
    )
  }

  const onChange = async (key: string, value: boolean) => {
    if (key === 'hasTrackRecord' && value === false) {
      if (requiredTracks > 0) {
        const content = validateMessage(requiredTracks, true)
        const rlt = await confirm(content)
        if (!rlt) return
      }
    }

    await dispatch(setBorrowerGroupData(borrowerSeperator, { [key]: value }))

    setLoading(true)
    const reqBody = {
      [key]: value,
      borrowerSeperator,
    }
    await borrowerInfoUpdate(reqBody)

    setLoading(false)
  }

  useEffect(() => {
    trackRecordValidate(borrower.hasTrackRecord, data, true)
  }, [borrower.hasTrackRecord, data])

  const showHistory = async (key: string) => {
    const options = {
      table: 'Borrower',
      field: `${borrowerSeperator}-${key}`,
      keys: {
        field: key,
        borrowerSeperator,
      },
    }
    openAuditLog(options)
  }

  const showHistoryFormTable = async (key: string, headers: Array<FormTableHeader> = []) => {
    const options = {
      table: 'AssetTrack',
      field: `${key}`,
      keys: {
        field: key,
      },
      renderValue: headers.length ? (data: any) => renderTableHistoryValue(convertData(data), headers) : null,
    }
    openAuditLog(options)
  }

  const onDownloadCSV = () => {
    setLoading(true)

    downloadLoanTrackRecord(loanNumber).finally(() => setLoading(false))
  }

  const importExcel = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)

    const file = event.target.files![0]
    const fileName = file.name.toLowerCase()

    const json = { file }

    const jsonData: string[][] = await parseBudgetTemplateExcel(json)

    let transformedData: any

    if (fileName.endsWith('.csv')) {
      transformedData = transformCSV(jsonData)
    } else if (fileName.endsWith('.xlsx') || fileName.endsWith('.xls')) {
      transformedData = transformExcel(jsonData)
    } else {
      console.error('Unsupported File Format')
      setLoading(false)
      return
    }

    const rowsToProcess = transformedData.slice(3)
    let temp = cloneDeep(borrower.tracks)
    if (rowsToProcess.length > 0) {
      for (const values of rowsToProcess) {
        if (values.purchaseDate === null) {
          continue
        }

        if (!values.id) {
          const newValues = {
            id: Date.now(),
            ...values,
          }
          try {
            await createLoanTrackRecord(newValues)
            setLength((prevLength) => prevLength + 1)
            temp.push(newValues)
          } catch (error) {
            console.error('Error creating loan track record:', error)
          }
        } else {
          temp.push(values)
        }
      }

      dispatch(setBorrowerGroupData(borrowerSeperator, { tracks: temp }))
      setData(temp)
    } else {
      console.log('No data to process from row 4 onwards')
    }

    setLoading(false)
    event.target.value = ''
  }

  const excelSerialToDate = (serial: number): string => {
    const excelEpoch = new Date(1900, 0, 1)

    return formatDateYMD(new Date(excelEpoch.setDate(excelEpoch.getDate() + serial - 2)))
  }

  const transformCSV = (data: string[][]): IAssetTrackData[] => {
    return data.map((value): IAssetTrackData => {
      return {
        propertyAddress: `${value[0]}, ${value[1]}, ${value[2]}, ${value[3]}`,
        propertyType: value[4],
        purchaseDate: value[5],
        purchasePrice: Number(value[6]),
        renovationBudget: Number(value[7]),
        asRental: value[8] === 'Y',
        salesDate: value[9],
        salesPrice: Number(value[10]),
        additionalNotes: value[11],
      }
    })
  }

  const transformExcel = (data: string[][]): IAssetTrackData[] => {
    return data.map((value): IAssetTrackData => {
      const purchaseDateSerial = Number(value[5])
      const salesDateSerial = Number(value[9])
      return {
        propertyAddress: `${value[0]}, ${value[1]}, ${value[2]}, ${value[3]}`,
        propertyType: value[4],
        purchaseDate: !isNaN(purchaseDateSerial) ? excelSerialToDate(purchaseDateSerial) : '',
        purchasePrice: removeComma(value[6]),
        renovationBudget: removeComma(value[7]),
        asRental: value[8] === 'Y',
        salesDate: !isNaN(salesDateSerial) ? excelSerialToDate(salesDateSerial) : '',
        salesPrice: removeComma(value[10]),
        additionalNotes: value[11],
      }
    })
  }

  return (
    <div>
      <div className="my-4">
        {Object.keys(inputs).map((key: string, index: number) => {
          let input = inputs[key]
          input.value = borrower.hasTrackRecord
          let error = ''
          if (borrower.hasTrackRecord === undefined || borrower.hasTrackRecord === null)
            error = REQUIRED_FIELD_ERROR_MESSAGE
          input.error = error
          input.disabled = !canEdit

          return (
            <div className="flex justify-between">
              <div className="w-fit" key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} />
              </div>
              <div className="flex">
                <label className="mt-1 p-2 text-sm cursor-pointer text-shade-blue">
                  <Tooltip message="Import CSV/Excel">
                    <ArrowUpTrayIcon className="w-4 h-4" />
                  </Tooltip>
                  <input
                    className="hidden"
                    id="importExcel"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"
                    multiple={false}
                    onChange={importExcel}
                  />
                </label>
                <span className="mt-1 p-2 text-sm cursor-pointer text-shade-blue" onClick={onDownloadCSV}>
                  <Tooltip message="Download CSV">
                    <ArrowDownTrayIcon className="w-4 h-4" />
                  </Tooltip>
                </span>
              </div>
            </div>
          )
        })}
      </div>

      {init && passedTracks < requiredTracks && validateMessage(requiredTracks, false)}
      {borrower.hasTrackRecord !== false && (
        <div>
          <FormTable
            header={header}
            permission={canEdit ? 1 : 3}
            inputs={tableInputs}
            inputLogic={inputLogic}
            data={getData}
            onSubmit={onSubmit}
            onRemove={onRemove}
            seperateRowKey={'additionalNotes'}
            showHistory={(headers) => showHistoryFormTable('tracks', headers)}
          />
        </div>
      )}
    </div>
  )
}
