import { LayoutLoading } from 'components/LayoutLoading'
import { feePercent, renderStripeAmountDetails, Stripe } from 'components/Stripe'
import { useEffect, useState } from 'react'
import { updatePaymentStatus } from 'services'

export const StripePay = ({ data, onPay }: { data: Record<string, any>; onPay: Function }) => {
  const [isLoading, setLoading] = useState(false)
  const [isCompleted, setCompleted] = useState(false)

  useEffect(() => setLoading(false), [])

  const paymentMethods: Record<string, string> = {
    ACH: 'us_bank_account',
    'Credit Card': 'card',
  }

  const onCompleted = (intentId: string) => {
    setLoading(true)
    updatePaymentStatus(intentId)
      .then(({ status, message }) => {
        setCompleted(true)
        onPay(true, intentId, status, message)
      })
      .finally(() => setLoading(false))
  }

  const method = paymentMethods[data.type]

  if (isCompleted) return renderStripeAmountDetails(data.amount, data.type)

  return (
    <div>
      <LayoutLoading show={isLoading} />
      <div className="text-gray-600 mb-4 text-md max-w-3xl">
        <div className="grid grid-cols-2 text-[14.5px] mb-4"></div>
        <div className="text-sm bg-gray-50 p-4 rounded shadow1">
          {renderStripeAmountDetails(data.amount, data.type)}
          <Stripe
            key={method}
            orderType={data.payOrderType}
            email={data.email}
            refId={data.refId}
            amount={Number(Number(data.amount * (1 + feePercent[data.type])).toFixed(2))}
            method={method as any}
            data={data}
            setLoading={setLoading}
            onCompleted={onCompleted}
          />
        </div>
      </div>
    </div>
  )
}
