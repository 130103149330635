import type { InputType } from 'config/input.type.constants'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    brokerFee: {
      inputType: 'text',
      title: 'Broker Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    underwritingFee: {
      inputType: 'text',
      title: 'Underwriting Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    discountFee: {
      inputType: 'text',
      title: 'Discount Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    processingFee: {
      inputType: 'text',
      title: 'Processing Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    mortgageRecordingTaxes: {
      inputType: 'text',
      title: 'Mortgage Recording Taxes',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    floodCertFee: {
      inputType: 'text',
      title: 'Flood Cert Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    creditReportFee: {
      inputType: 'text',
      title: 'Credit Report Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    hazardInsurance: {
      inputType: 'text',
      title: 'Hazard Insurance',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    realEstateTaxe: {
      inputType: 'text',
      title: 'Real Estate Taxes',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    commitmentFee: {
      inputType: 'text',
      title: 'Commitment Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    constructionConsultantFee: {
      inputType: 'text',
      title: 'Construction Consultant Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    reserveForFloodInsurance: {
      inputType: 'text',
      title: 'Reserve for Flood Insurance',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    reserveForInsurance: {
      inputType: 'text',
      title: 'Reserve for Insurance',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    reserveForPropertyTaxes: {
      inputType: 'text',
      title: 'Reserve for Property Taxes',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    reserveForOtherTaxes: {
      inputType: 'text',
      title: 'Reserve for Other Taxes',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    examinationOfTitleAndRecordingFee: {
      inputType: 'text',
      title: 'Examination of Title and Recording Fee',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    escrows: {
      inputType: 'text',
      title: 'Escrows',
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    lenderLegalCounselFee: {
      inputType: 'text',
      title: `Lender's legal Counsel Fee`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    interestOnBuildingLoanMortgage: {
      inputType: 'text',
      title: `Interest on Building Loan Mortgage Fee`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
      required: true,
    },
    floridaIntangibleTax: {
      inputType: 'text',
      title: `Florida Intangibles Tax`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    documentaryTaxStamp: {
      inputType: 'text',
      title: `Documentary Tax Stamp`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    originationFee: {
      inputType: 'text',
      title: `Origination Fee`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    perDiemInterest: {
      inputType: 'text',
      title: `Per Diem Interest`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    lockExtensionFee: {
      inputType: 'text',
      title: `Lock Extension Fee`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    mersRegistration: {
      inputType: 'text',
      title: `Mers Registration`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    attorneyReviewFee: {
      inputType: 'text',
      title: `Attorney Review Fee`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    assignmentRecording: {
      inputType: 'text',
      title: `Assignment Recording`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    constructionReserve: {
      inputType: 'text',
      title: `Construction Reserve`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    interestReserve: {
      inputType: 'text',
      title: `Interest Reserve`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    siteXReport: {
      inputType: 'text',
      title: `SiteX Report`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    lexisNexisReport: {
      inputType: 'text',
      title: `LexisNexis Report`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    feasibilityReview: {
      inputType: 'text',
      title: `Feasibility Review`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    aggregateAdjustment: {
      inputType: 'text',
      title: `Aggregate Adjustment`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    closingRushFee: {
      inputType: 'text',
      title: `Closing Rush Fee`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    docRedrawFee1: {
      inputType: 'text',
      title: `Doc Redraw Fee 1`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    docRedrawFee2: {
      inputType: 'text',
      title: `Doc Redraw Fee 2`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    docRedrawFee3: {
      inputType: 'text',
      title: `Doc Redraw Fee 3`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
    appraisalTransferFee: {
      inputType: 'text',
      title: `Appraisal Transfer Fee`,
      type: 'thousandSep',
      prefix: '$',
      error: '',
    },
  }
}
