import { AccountType, InputType, loanOptionsConstants } from 'config'
import { states } from 'config/states.constants'

export const accountTypes: Record<string, string> = {
  admin: 'Admin',
  uw_manager: 'UW Manager',
  underwriter: 'UnderWriter',
  appraiser: 'Appraiser',
  loan_setup: 'Loan Setup',
  disclosure_spec: 'Disclosure Specialist',
  lock_desk: 'Lock Desk',
  national_sale: 'National Sales',
  operation_supervisor: 'Operation Supervisor',
  vendor_approval: 'Vendor Approval',
  closer: 'Closer',
  post_closer: 'Post Closer',
  servicing: 'Servicing',
  draw_specialist: 'Draw Specialist',
  feasibility_analyst: 'Feasibility Analyst',
  accounting: 'Accounting',
  account_manager: 'Account Manager',
  account_executive: 'Account Executive',
  broker: 'Broker',
  retail: 'Retail',
  correspondent: 'Correspondent',
  tablefunder: 'Table Funder',
  branch: 'Branch',
  loan_officer: 'Loan Officer',
  loan_processor: 'Loan Processor',
}

export const assignedToAccountTypes = [
  'account_executive',
  'account_manager',
  'accounting',
  'admin',
  'appraiser',
  'closer',
  'disclosure_spec',
  'draw_specialist',
  'feasibility_analyst',
  'loan_setup',
  'lock_desk',
  'national_sale',
  'operation_supervisor',
  'post_closer',
  'servicing',
  'underwriter',
  'uw_manager',
  'vendor_approval',
]

export const profileCompanyInfoKeys = [
  'companyStateOfFormation',
  'companyEIN',
  'companyName',
  'companyNmls',
  'companyType',
  'street',
  'city',
  'state',
  'zip',
]

export const profileCompanyInfoVisibleKeys = ['companyEIN', 'companyName', 'companyNmls']

export const accountTypeOptions = (accountType: string): Record<string, string> => {
  let rlt = accountTypes
  switch (accountType) {
    case AccountType.ACCOUNT_EXECUTIVE:
    case AccountType.NATIONAL_SALE:
      rlt = {
        broker: 'Broker',
        retail: 'Retail',
        correspondent: 'Correspondent',
        tablefunder: 'Table Funder',
        branch: 'Branch',
        loan_officer: 'Loan Officer',
        loan_processor: 'Loan Processor',
      }
      break
    case AccountType.BROKER:
    case AccountType.RETAIL:
    case AccountType.CORRESPONDENT:
    case AccountType.TABLEFUNDER:
      rlt = {
        branch: 'Branch',
        loan_officer: 'Loan Officer',
        loan_processor: 'Loan Processor',
      }
      break
    case AccountType.BRANCH:
      rlt = {
        loan_officer: 'Loan Officer',
        loan_processor: 'Loan Processor',
      }
      break
  }
  return rlt
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    accountType: {
      title: 'Account Type',
      inputType: 'select',
      options: accountTypes,
      visible: true,
      hasDefaultOption: false,
      required: true,
    },
    accountExecutive: {
      title: 'Account Executive Associated With',
      inputType: 'select',
      options: [],
      hasDefaultOption: true,
      value: '0',
      sort: true,
      required: true,
    },
    broker: {
      title: 'Broker Associated With',
      inputType: 'select',
      options: [],
      hasDefaultOption: true,
      value: '0',
      sort: true,
      required: true,
    },
    branch: {
      title: 'Branch Associated With',
      inputType: 'select',
      options: [],
      required: false,
      hasDefaultOption: true,
      defaultOptionText: 'Main Office',
      allowDefaultOption: true,
      sort: true,
      value: '0',
    },
    branchAccountExecutive: {
      title: 'Branch Account Executive',
      inputType: 'select',
      options: [],
      hasDefaultOption: true,
      defaultOptionText: 'None',
      value: '0',
      sort: true,
      required: false,
    },
    companySeperator: {
      inputType: 'section',
      title: 'Company Information',
      span: 2,
    },
    companyStateOfFormation: {
      inputType: 'select',
      title: 'State of Formation',
      options: states,
      hasDefaultOption: true,
    },
    companyType: {
      inputType: 'select',
      options: loanOptionsConstants.entityType,
      hasDefaultOption: true,
      title: 'Company Type',
      required: true,
    },
    companyEIN: {
      inputType: 'text',
      type: 'text',
      title: 'Company EIN',
    },
    companyName: {
      inputType: 'text',
      title: 'Company Name',
      required: true,
    },
    companyNmls: {
      inputType: 'text',
      type: 'text',
      title: 'Company NMLS ID',
      required: false,
    },
    personalSeperator1: {
      inputType: 'section',
      title: 'Personal Information',
      span: 2,
    },
    street: {
      inputType: 'text',
      title: 'Street Address',
      required: true,
    },
    city: {
      inputType: 'text',
      title: 'City',
      required: true,
    },
    state: {
      inputType: 'select',
      title: 'State',
      options: states,
      hasDefaultOption: true,
      required: true,
    },
    zip: {
      inputType: 'text',
      key: 'zipCode',
      type: 'number',
      title: 'Zip',
      required: true,
    },
    personalSeperator2: {
      inputType: 'section',
      title: 'Personal Information',
      span: 2,
    },
    title: {
      inputType: 'text',
      title: 'Principal Title',
    },
    name: {
      inputType: 'text',
      title: 'Principal Full Name',
      required: true,
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Principal Email',
      required: true,
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Principal Phone Number',
      required: true,
    },
    phoneExt: {
      inputType: 'text',
      type: 'phone',
      title: 'Principal Phone Ext',
      required: false,
    },
    brokerSSN: {
      inputType: 'text',
      type: 'ssn',
      title: 'Broker Social Security Number',
      required: true,
    },
    brokerNmls: {
      inputType: 'text',
      title: 'Broker NMLS ID / Real Estate License ID',
      required: false,
    },
    branchNmls: {
      inputType: 'text',
      title: 'Branch NMLS ID',
      required: false,
    },
    companyLicense: {
      inputType: 'text',
      title: 'Company State license ID',
      required: false,
    },
    loanOfficer: {
      inputType: 'text',
      title: 'Loan Officer NMLS ID',
    },
    minCompensation: {
      inputType: 'text',
      type: 'number',
      title: 'Min Compensation',
      required: false,
      prefix: '%',
    },
    maxCompensation: {
      inputType: 'text',
      type: 'number',
      title: 'Max Compensation',
      required: false,
      prefix: '%',
    },
    brokerCompensation: {
      inputType: 'text',
      type: 'number',
      title: 'Broker/Branch Compensation',
      required: false,
      prefix: '%',
    },
    loanOfficerStateLicense: {
      inputType: 'text',
      title: 'Loan Officer State license ID',
      required: false,
    },
    taskSeperator: {
      inputType: 'section',
      title: 'Task Information',
      span: 2,
    },
    taskAssignViewTypes: {
      inputType: 'multiselect',
      title: 'Task Assign View',
      options: assignedToAccountTypes,
      defaultSelected: false,
      value: {},
      span: 2,
    },

    parentMarginSeperator1: {
      inputType: 'section',
      title: 'Parent Margin Adjustment',
      span: 2,
      visible: false,
    },
    parentMarginRate: {
      inputType: 'text',
      type: 'number',
      title: 'Rate',
      required: false,
      prefix: '%',
      visible: false,
    },
    parentMarginPrice: {
      inputType: 'text',
      type: 'number',
      title: 'Price',
      required: false,
      prefix: '%',
      visible: false,
    },
    parentMarginRateFloor: {
      inputType: 'text',
      type: 'number',
      title: 'Rate Floor',
      required: false,
      prefix: '%',
      visible: false,
    },
    parentMarginRateCeiling: {
      inputType: 'text',
      type: 'number',
      title: 'Rate Ceiling',
      required: false,
      prefix: '%',
      visible: false,
    },
    parentMarginPriceFloor: {
      inputType: 'text',
      type: 'number',
      title: 'Price Floor',
      required: false,
      prefix: '%',
      visible: false,
    },
    parentMarginPriceCeiling: {
      inputType: 'text',
      type: 'number',
      title: 'Price Ceiling',
      required: false,
      prefix: '%',
      visible: false,
    },

    marginSeperator1: {
      inputType: 'section',
      title: 'Margin Adjustment',
      span: 2,
      visible: false,
    },
    marginRate: {
      inputType: 'text',
      type: 'number',
      title: 'Rate',
      required: false,
      prefix: '%',
      visible: false,
    },
    marginPrice: {
      inputType: 'text',
      type: 'number',
      title: 'Price',
      required: false,
      prefix: '%',
      visible: false,
    },
    marginRateFloor: {
      inputType: 'text',
      type: 'number',
      title: 'Rate Floor',
      required: false,
      prefix: '%',
      visible: false,
    },
    marginRateCeiling: {
      inputType: 'text',
      type: 'number',
      title: 'Rate Ceiling',
      required: false,
      prefix: '%',
      visible: false,
    },
    marginPriceFloor: {
      inputType: 'text',
      type: 'number',
      title: 'Price Floor',
      required: false,
      prefix: '%',
      visible: false,
    },
    marginPriceCeiling: {
      inputType: 'text',
      type: 'number',
      title: 'Price Ceiling',
      required: false,
      prefix: '%',
      visible: false,
    },
    otherSeperator: {
      inputType: 'section',
      title: 'Others',
      span: 2,
      visible: false,
    },
    pullCreditScoreLevel: {
      inputType: 'radio',
      title: 'Pull Credit',
      options: {
        ' 1': 'Eligible to pull Credit Report',
        ' 0': 'Eligible to Pull Credit Report With Required Payment',
        ' -1': 'Not Eligible to pull Credit Report',
      },
      sort: false,
      required: false,
      visible: false,
      span: 2,
    },
  }
}

export const userMarginInputs = [
  'marginSeperator1',
  'marginRate',
  'marginPrice',
  'marginRateFloor',
  'marginRateCeiling',
  'marginPriceFloor',
  'marginPriceCeiling',
]

export const userParentMarginInputs = [
  'parentMarginSeperator1',
  'parentMarginRate',
  'parentMarginPrice',
  'parentMarginRateFloor',
  'parentMarginRateCeiling',
  'parentMarginPriceFloor',
  'parentMarginPriceCeiling',
]
