import {
  API_LOAN_GET_DOCUMENT_GENIE,
  API_LOAN_GET_OVERVIEW_FOR_DOCUMENT_GENIE,
  API_LOAN_SEND_DOCUMENT_GENIE_SIGN_REQUEST,
  API_LOAN_UPLOAD_SIGNED_DOCUMENT_GENIE_PDF,
} from 'config'
import Api from 'services/api'

export const getDocumentGenie = () => {
  return Api.get(API_LOAN_GET_DOCUMENT_GENIE)
}

export const sendDocumentSignRequest = (key: string, values: Record<string, any>) => {
  return Api.post(API_LOAN_SEND_DOCUMENT_GENIE_SIGN_REQUEST, { key, values })
}

export const uploadSignedDocumentGeniePdf = (data: Record<string, any>) => {
  return Api.post(API_LOAN_UPLOAD_SIGNED_DOCUMENT_GENIE_PDF, data, {}, { timeout: 150000 })
}

export const fetchLoanOverviewForDocumentGenie = () => {
  return Api.get(API_LOAN_GET_OVERVIEW_FOR_DOCUMENT_GENIE)
}
