import { setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { store } from 'reducers'
import { InputValidate } from 'utils'

import { defaultInputs } from './constants'

const disableKeys = ['firstName', 'middleName', 'lastName', 'borrowerType', 'residency']

export const visibleBorrowerFieldsLogic = (borrowerSeperator: string) => {
  const { borrower } = store.getState()

  let visibleFields: any = [
    'residency',
    'firstName',
    'middleName',
    'lastName',
    'email',
    'ssn',
    'phone',
    'dob',
    'presentAddress',
    'mailingAddress',
    'ownership',
    'ownedRentedYears',
    'maritalStatus',
  ]
  if (borrowerSeperator === 'borrower') {
    visibleFields.push('hasEntityTitle')
    visibleFields.push('titleVesting')
    if (borrower['borrower'].hasEntityTitle) {
      if (borrower[borrowerSeperator].borrowerType !== 'Individual') {
        visibleFields.push('borrowerPosition')
      }
      visibleFields.push('borrowerType')
      visibleFields.push('ownershipPercentage')
      visibleFields.push('entityTitle')
      visibleFields.push('entityAddress')
      visibleFields.push('entityTaxID')
      visibleFields.push('entityState')
    }
  }

  if (borrowerSeperator === 'coBorrower') {
    if (borrower['borrower'].hasEntityTitle) {
      if (borrower[borrowerSeperator].borrowerType !== 'Individual') {
        visibleFields.push('borrowerPosition')
      }
      visibleFields.push('ownershipPercentage')
    }
  }

  if (borrower[borrowerSeperator].ownedRentedYears === '1') {
    visibleFields.push('formerAddress')
    visibleFields.push('formerOwnership')
    visibleFields.push('formerOwnedRentedYears')
  }

  return visibleFields
}

export const borrowerInfoValidate = (
  borrowerSeperator: string,
  visibles: any,
  fields: any,
  validateOnly = false,
  updateStore = false,
) => {
  if (validateOnly) {
    visibles = visibleBorrowerFieldsLogic(borrowerSeperator)
    fields = defaultInputs()
  }
  const { borrower, step } = store.getState()
  let success = true
  fields = cloneDeep(fields)
  Object.keys(fields).map((key) => {
    if (visibles.indexOf(key) !== -1) {
      const error = InputValidate({ ...fields[key], value: borrower[borrowerSeperator][key] })
      fields[key].error = error
      if (error.length > 0) {
        success = false
      }
    }
  })

  if (borrowerSeperator === 'borrower') {
    if (borrower[borrowerSeperator].hasEntityTitle === true) {
      if (borrower[borrowerSeperator].borrowerType === 'Individual') {
        fields['borrowerType'].error = `Can not be 'Individual' when Has Entity Name.`
        success = false
      }
    }
    disableKeys.map((key) => {
      fields[key].disabled = true
      fields[key].tooltip = 'Loan Structure Input Field!'
    })
  }

  if (updateStore) {
    let application = cloneDeep(step.application)
    const key = borrowerSeperator === 'borrower' ? 'borrower' : 'borrower2'
    application[key] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }
  if (validateOnly) return success
  return fields
}
