import cloneDeep from 'clone-deep'
import { Appraisal2StatusType } from 'pages/Loan/OrderAppraisals/types'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'

interface IProps {
  data: Record<string, boolean>
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (data: Record<string, boolean>) => void
}

export const AppraisalStatusOrder = (props: IProps) => {
  const { data, isOpen, loading, onClose, onSubmit } = props

  const [status, setStatus] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setStatus(data)
  }, [data])

  const onChangeOrder = (val: string, index: number) => {
    const nth = Number(val) - 1
    if (nth === index) return

    const newStatus = cloneDeep(status)
    const keys = Object.keys(newStatus)
    const removedOne = keys.splice(index, 1)
    keys.splice(nth, 0, removedOne[0])

    const reorderedObject: Record<string, boolean> = {}
    keys.forEach((key) => {
      reorderedObject[key] = newStatus[key]
    })

    setStatus(reorderedObject)
  }

  return (
    <Modal
      title="Appraisal Status Order"
      titleOkay="Change"
      isOpen={isOpen}
      loading={loading === 'setting'}
      onClose={onClose}
      onOk={() => onSubmit(status)}
    >
      <div className="text-gray-700 w-[500px]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="px-3 py-3">
                Status
              </th>
              <th scope="col" className="px-3 py-3">
                Order
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(status).map((key, index) => {
              const orderOptions = Object.keys(status).map((_, idx) => (idx + 1).toString())

              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap">
                    {Appraisal2StatusType[key]}
                  </td>
                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap">
                    <select
                      onChange={(e) => onChangeOrder(e.target.value, index)}
                      value={index + 1}
                      className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    >
                      {orderOptions.map((val) => (
                        <option key={val}>{val}</option>
                      ))}
                    </select>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
