import { renderStripeAmountDetails } from 'components/Stripe'
import { useEffect, useMemo, useState } from 'react'
import { Modal } from 'stories/components'
import { formatTime } from 'utils'

import { defaultInputs } from './constants'
import { StripeStatusLabels } from './FeasibilitySign'
import { feasibilityKeys } from './types'

export const StripeIntentDialog = ({ data, onClose }: { data: Record<string, any>; onClose: Function }) => {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => setLoading(false), [])

  const displayData = useMemo(() => {
    const inputs = defaultInputs()
    const displayData: Record<string, any> = {}

    displayData[inputs.feasibilityType.title] = data.data.type
    feasibilityKeys.forEach((key) => {
      if (data.data[key]) displayData[inputs[key].title] = data.data[key]
    })
    return displayData
  }, [data])

  const renderPaymentMethod = (paymentMethod: Record<string, any>) => {
    const displayData: Record<string, any> = {
      us_bank_account: {
        account_holder_type: 'Account Holder Type',
        bank_name: 'Bank Name',
        last4: 'Account Number (****)',
        routing_number: 'Routing Number',
        account_type: 'Account Type',
      },
      card: {
        brand: 'Brand',
        funding: 'Funding',
        exp_month: 'Exp Month',
        exp_year: 'Exp Year',
        last4: 'Card Number (****)',
      },
    }
    const labels = displayData[paymentMethod.type]
    const details = paymentMethod[paymentMethod.type]

    return (
      <div className="grid grid-cols-2 text-[14.5px] mb-4">
        {Object.keys(labels).map((key) => (
          <div className="flex gap-2">
            <p>{labels[key]}:</p>
            <p className="italic font-bold">{details[key]}</p>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Modal isOpen title={`Stripe Pay #${data.index}`} titleOkay="" loading={isLoading} onClose={() => onClose(false)}>
      <div className="text-gray-600 mb-4 text-md w-[750px]">
        <div className="grid grid-cols-2 text-[14.5px] mb-4">
          {Object.keys(displayData).map((title) => (
            <div className="flex gap-2" key={title}>
              <p>{title}:</p>
              <p className="italic font-bold">{displayData[title]}</p>
            </div>
          ))}
        </div>
        {renderStripeAmountDetails(data.data['amount'], data.data['type'])}
        <div className="text-sm bg-gray-100 p-4 rounded shadow1">
          <div className="flex gap-2 mb-1">
            <p className="w-24">Intent ID:</p>
            <p className="italic font-bold">{data.response.id}</p>
          </div>
          <div className="flex gap-2 mb-1">
            <p className="w-24">Status:</p>
            <p className={`italic font-bold text-${StripeStatusLabels[data.status][0]}-500`}>
              {StripeStatusLabels[data.status][1]}
            </p>
          </div>
          {data.status == 'succeeded' && (
            <div className="flex gap-2">
              <p className="w-24">Succeed On:</p>
              <p className={`italic font-bold`}>{formatTime(data.updatedAt)}</p>
            </div>
          )}
          {data.response.last_payment_error && (
            <div className="mt-4">
              {data.response.last_payment_error.payment_method &&
                renderPaymentMethod(data.response.last_payment_error.payment_method)}
              <div className={`input mb-4`}>
                <div
                  className={`bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] mt-4`}
                  role="alert"
                >
                  <span dangerouslySetInnerHTML={{ __html: data.response.last_payment_error.message }} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
