import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'
import { isValidElement } from 'react'
import type { FormTableHeader } from 'stories/components'

export const renderHeader = ({
  title,
  sortable = false,
  key,
  sortOrder = 1,
  index,
  onSort,
  border = false,
  className = '',
  btnClassName = '',
  alignClassName = '',
  additionalChild = null,
  ...props
}: {
  title: string | JSX.Element
  sortable: boolean
  key: string
  sortOrder: number
  index: number
  onSort: Function
  border?: boolean
  className?: string
  btnClassName?: string
  [key: string]: any
}) => {
  if (!sortable)
    return (
      <th scope="col" className="py-3 px-2" key={`${key}-${index}`} {...props}>
        {title}
      </th>
    )

  const _onSort = () => {
    if (sortOrder == 0) sortOrder = -1
    onSort(key, 0 - sortOrder)
  }

  return (
    <th scope="col" className={`py-3 px-2 ${border ? 'border' : ''} ${className}`} key={`${key}-${index}`} {...props}>
      <div className={`flex gap-2 items-center ${alignClassName}`}>
        <button
          className={`flex uppercase bg-transparent font-bold text-left hover:underline ${btnClassName}`}
          onClick={(e) => !e.defaultPrevented && _onSort()}
        >
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-2" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
        {additionalChild && additionalChild}
      </div>
    </th>
  )
}

export const renderTableHistoryValue = (data: any, headers: Array<FormTableHeader> = []) => {
  return (
    <table className="table-auto">
      <tbody>
        <tr className="bg-gray-100">
          <td className="px-1 border">No</td>
          {headers.map((header: any, headerIndex: number) => (
            <td key={headerIndex} className="px-1 border">
              {header.title}
            </td>
          ))}
        </tr>
        {data?.map((item: any, index: number) => {
          return (
            <tr key={index}>
              <td className="px-1 border">{index + 1}</td>
              {headers.map((header: any, headerIndex: number) => (
                <td key={headerIndex} className="px-1 border">
                  {header.value ? (
                    header.value(item)
                  ) : typeof item[header.key] == 'boolean' ? (
                    (item[header.key] = item[header.key] ? 'Yes' : 'No')
                  ) : isValidElement(item[header.key]) ? (
                    item[header.key]
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: item[header.key] }} />
                  )}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
