export const fundingDisbursementInputs = () => {
  return {
    fundingFacility: {
      inputType: 'text',
      type: 'text',
      title: 'Funding Facility',
      value: '',
      required: true,
    },
    facilityAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Facility Amount',
      prefix: '$',
      value: '',
      required: true,
    },
    facilityNumber: {
      inputType: 'text',
      type: 'text',
      title: 'Funding Facility Loan Number',
      value: '',
    },
    cashAccount: {
      inputType: 'text',
      type: 'text',
      title: 'Cash Account',
      value: '',
      required: true,
    },
    cashAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Cash Amount',
      prefix: '$',
      value: '',
      required: true,
    },
  }
}
