import {
  API_BUSINESS_HOUR,
  API_LOAN_CREATE_ADDRESS_DOCUMENT,
  API_LOAN_GET_LATEST_RATESHEET,
  API_LOAN_GET_PROCESS,
  API_LOAN_LOCK_EXTENSION,
  API_LOAN_LOCK_FLOAT_RATE,
  API_LOAN_NOTIFY_PDF_TO_CREATOR,
  API_LOAN_OVERRIDE_PRICING,
  API_LOAN_PRICING,
  API_LOAN_RE_PRICING_INIT,
  API_LOAN_REPRICE_AFTER_LOCKED,
  API_LOAN_SAVE_PROCESS,
  API_LOAN_SAVE_RATESHEET,
  API_LOAN_SAVE_TO_PIPELINE,
  API_LOAN_STRUCTURE_HISTORY,
  API_LOAN_STRUCTURE_RELOCK_TIMES,
  API_LOAN_SUBMIT_EXCEPTION_REQUEST,
  API_LOAN_SUBMIT_EXCEPTION_RESPONSE,
} from 'config'
import Api from 'services/api'

export const checkBusinessHour = () => {
  return Api.get(API_BUSINESS_HOUR)
}

export const submitExceptionRequest = (data: any) => {
  return Api.post(API_LOAN_SUBMIT_EXCEPTION_REQUEST, data)
}

export const submitExceptionResponse = (data: any) => {
  return Api.post(API_LOAN_SUBMIT_EXCEPTION_RESPONSE, data)
}

export const saveLoanProcess = (data: object) => {
  return Api.post(API_LOAN_SAVE_PROCESS, data)
}

export const getLoanProcessRate = () => {
  return Api.get(API_LOAN_GET_PROCESS)
}

export const saveLoanRatesheet = (data: any) => {
  return Api.post(API_LOAN_SAVE_RATESHEET, data)
}

export const getLatestLoanRatesheet = () => {
  return Api.get(API_LOAN_GET_LATEST_RATESHEET)
}

export const priceLoan = (loanFields: any) => {
  return Api.post(API_LOAN_PRICING, loanFields)
}

export const saveToPipeLine = (loanFields: any) => {
  return Api.post(API_LOAN_SAVE_TO_PIPELINE, loanFields)
}

export const getRePricingInitData = () => {
  return Api.get(API_LOAN_RE_PRICING_INIT)
}

export const loanRePriceAfterLocked = (data: any) => {
  return Api.post(API_LOAN_REPRICE_AFTER_LOCKED, data)
}

export const getLoanStructureHistory = (index: string = 'all') => {
  return Api.get(API_LOAN_STRUCTURE_HISTORY, {}, { index })
}

export const getRelockTimes = () => {
  return Api.get(API_LOAN_STRUCTURE_RELOCK_TIMES, {})
}

export const loanLockorFloatRate = (data: any) => {
  return Api.post(API_LOAN_LOCK_FLOAT_RATE, data)
}

export const notifyPdfToLoanCreator = (pdfData: any) => {
  return Api.post(API_LOAN_NOTIFY_PDF_TO_CREATOR, pdfData, {}, { timeout: 150000 })
}

export const overridePricing = (data: object) => {
  return Api.post(API_LOAN_OVERRIDE_PRICING, data)
}

export const loanLockExtension = (day: number) => {
  return Api.post(API_LOAN_LOCK_EXTENSION, { day })
}

export const createAddressDocument = (address: string) => {
  return Api.post(API_LOAN_CREATE_ADDRESS_DOCUMENT, { address })
}
