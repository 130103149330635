import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import { AccountType } from 'config'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'
import { store } from 'reducers'
import {
  getAdditionalPropertyInfoLogs,
  getAppraisal2Logs,
  getAssetTrackLogs,
  getBorrowerLogs,
  getFundingDisbursementLogs,
  getHUD1Logs,
  getLoanLogs,
  getLoanProcessLogs,
  getLoanSubmissionLogs,
  getNYTransactionsLogs,
  getPurchaseAdviceLogs,
  getPurchaseAdviceSellerLogs,
  getServicingDefaultTrackingLog,
  getShippingInfoLogs,
  getUnderwritingSummaryLogs,
  getUserLogs,
  updateLoanProcessLog,
} from 'services'
import {
  downloadAuditLogCSV,
  downloadAuditLogPDF,
  filterMasterEmails,
  getAccountingHistory,
  getContractorLogs,
  getGenerateDocumentLog,
  getTrustLedgerAdditionalHistory,
} from 'services/apis'
import { Modal } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { formatTime, removeDuplicates } from 'utils/convertor'

interface UserAuditLogProps extends ReactConfirmProps {
  options: {
    table: string
    field: string
    keys: any
    map?: any
    renderValue?: Function
  }
}

const AuditLogDialog = ({ show, proceed, options }: UserAuditLogProps) => {
  const { auth } = store.getState()
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [auditLog, setAuditLog] = useState<Array<any>>([])
  const [fetchingData, setFetchingData] = useState(true)
  const isAdmin = auth.profile.accountType == AccountType.ADMIN
  const [isEditable, setEditable] = useState(false)
  const [visibleEmails, setVisibleEmails] = useState<string[]>([])

  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  useEffect(() => {
    const { table, keys } = options
    if (table === 'LoanSubmission') {
      getLoanSubmissionLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'FundingDisbursement') {
      getFundingDisbursementLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'User') {
      getUserLogs(keys.userId, keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'Loan') {
      getLoanLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'LoanProcess') {
      getLoanProcessLogs(keys.field, keys.loanNumber).then((data) => {
        setAuditLog(data)

        if (isAdmin) setEditable(true)
      })
    }
    if (table === 'Borrower') {
      getBorrowerLogs(keys.borrowerSeperator, keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'Appraisal2') {
      getAppraisal2Logs(keys.field, keys.id).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'Contractor') {
      getContractorLogs(keys.no, keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'AssetTrack') {
      getAssetTrackLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'PurchaseBuyer') {
      getPurchaseAdviceLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'PurchaseSeller') {
      getPurchaseAdviceSellerLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'Underwriting') {
      getUnderwritingSummaryLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'NYTransactions') {
      getNYTransactionsLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'ServicingDefaultTracking') {
      getServicingDefaultTrackingLog(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'ShippingInfo') {
      getShippingInfoLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'AdditionalPropertyInformation') {
      getAdditionalPropertyInfoLogs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'HUD1') {
      getHUD1Logs(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'TrustLedger') {
      getTrustLedgerAdditionalHistory(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'GenerateDocument') {
      getGenerateDocumentLog(keys.field).then((data) => {
        setAuditLog(data)
      })
    }
    if (table === 'AccountingRecords') {
      getAccountingHistory(keys.id).then((data) => {
        setAuditLog(data)
      })
    }
  }, [options])

  useEffect(() => {
    const emails = removeDuplicates(auditLog.map((v) => v.email))
    if (!emails.length) {
      setFetchingData(false)
      return
    }

    filterByMaster(emails)
  }, [auditLog])

  const filterByMaster = async (emails: string[]) => {
    const filteredEmails = await filterMasterEmails(emails)
    setVisibleEmails(filteredEmails)
    setFetchingData(false)
  }

  const onUpdate = async (type: string, newValue: string, index: number) => {
    let value = newValue
    if (type == 'time') value = moment(newValue).toDate().toISOString()
    const { keys, table } = options
    if (table === 'LoanProcess') {
      const newData = cloneDeep(auditLog)
      newData[index][type] = value
      setAuditLog(newData)

      setFetchingData(true)
      await updateLoanProcessLog(keys.field, keys.loanNumber, {
        index,
        type,
        value,
      })
      setFetchingData(false)
    }
  }

  const onDownloadCSV = () => {
    const { table, keys } = options
    downloadAuditLogCSV(auditLog, table, keys.field).finally(() => {})
  }
  const onDownloadPDF = () => {
    const { table, keys } = options
    downloadAuditLogPDF(auditLog, table, keys.field).finally(() => {})
  }

  return (
    <Modal
      button={<span></span>}
      title={'Audit Log Data Details'}
      titleOkay=""
      init={fetchingData}
      isOpen={show}
      lastUpdatedAt={lastUpdatedAt}
      onClose={() => proceed(false as any)}
      childLevel={1}
    >
      <>
        <div className="flex justify-between">
          <div className="info-container text-sm ml-3 max-w-screen-xl">
            <div className="mb-3">
              <span>Table:</span>
              <span className="ml-3 font-bold">{options.table}</span>
            </div>
            <div className="mb-4">
              <span>Field:</span>
              <span className="ml-3 font-bold">{options.field}</span>
            </div>
          </div>
          <div className="flex items-center">
            <span
              className="p-1 mr-2 text-sm cursor-pointer text-shade-blue hover-shadow1 rounded"
              onClick={onDownloadCSV}
            >
              <Tooltip message="Download CSV" placement="right">
                <ArrowDownTrayIcon className="w-4 h-4" />
              </Tooltip>
            </span>
            <span className="p-1 text-sm cursor-pointer text-shade-blue hover-shadow1 rounded" onClick={onDownloadPDF}>
              <Tooltip message="Download PDF" placement="right">
                <ArrowDownTrayIcon className="w-4 h-4" />
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg max-w-screen-xl">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  No
                </th>
                <th scope="col" className="px-6 py-3 w-48">
                  Date & Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  New Value
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-900">
              {auditLog
                .filter((v) => visibleEmails.includes(v.email))
                .map((log, index) => (
                  <tr key={index} className={`${index % 2 && 'bg-gray-50'}`}>
                    <td scope="row" className="px-6 py-2">
                      {index + 1}
                    </td>
                    <td className="px-6 py-2">
                      {!isEditable ? (
                        formatTime(log.time)
                      ) : (
                        <PlainInput
                          value={formatTime(log.time)}
                          content={formatTime(log.time)}
                          onChange={(newValue: string) => onUpdate('time', newValue, index)}
                        />
                      )}
                    </td>
                    <td className="px-6 py-2">{log.email}</td>
                    <td className="px-6 py-2">
                      {options.map
                        ? options.map[log.value]
                        : options.renderValue
                        ? options.renderValue(log.value)
                        : JSON.stringify(log.value)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </>
    </Modal>
  )
}

const auditLogDlg = confirmable(AuditLogDialog)

const createAuditLogDlg = createConfirmation(auditLogDlg)

export const openAuditLog = (options: Record<string, any> = {}) => createAuditLogDlg({ options })
