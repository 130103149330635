import {
  ArrowDownCircleIcon,
  CheckIcon,
  PencilSquareIcon,
  PlusIcon,
  StopIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { setLoanLtvMaxLimit } from 'actions'
import calculatorImg from 'assets/calculator.png'
import { PlainInput2 } from 'components/PlainInput/PlainInput2'
import { INVALID_ALL_INPUTS } from 'config'
import moment from 'moment-timezone'
import { loanStructureStepUpgrade } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import type { IRange } from 'pages/Resources'
import { convertNullToBlank, convertNullValue } from 'pages/Resources/GuidelineOverlay/logic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { saveLoanProcess, saveLoanRatesheet } from 'services'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { svgLoading } from 'stories/assets'
import { Select, Toggle } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import {
  confirm,
  formatDate,
  formatTime,
  getPrice2decimal,
  isEmpty,
  removeComma,
  skeletonDlg,
  solveDecimalJavascriptSum,
} from 'utils'

import type {
  IInElibileProduct,
  ILLPA,
  ILoan,
  ILoanDetail,
  ILoanDetailChangedJson,
  ILoanProcess,
  ILoanRateSheet,
  ILockDay,
  ILockTermPrice,
  IPendingLoanChangeRequestData,
  IPrice,
  IPrice_Program_Price,
  IPriceAdjustment,
  IPriceLimit,
  IProduct,
  IProgram,
  ISaveToPipelineParams,
} from '../interfaces'
import {
  calcInitialLoanAmount,
  getMaximumTotalLoanAmountTip,
  getRateSpreadExitFeeTooltip,
  isNonDSCRProduct,
  showLimitSection,
} from '../Logic'
import { CalculatedDialog } from './CalculatedDialog'
import { InEligibleDialog } from './InEligibleDialog'
import { LoanProcessTable } from './LoanProcessTable'
import { MaxLoanAmount } from './MaxLoanAmount'
import { AddLockDay } from './Modals/AddLockDay'
import { AddProduct } from './Modals/AddProduct'
import { AddProgram } from './Modals/AddProgram'
import { EditPrice } from './Modals/EditPrice'

const enum ModalOpen {
  AddProduct = 'addProduct',
  AddProgram = 'addProgram',
  AddLockDay = 'addLockDay',
  UpdatePrice = 'updatePrice',
}

interface Prop {
  auth: any
  loanRateSheet: ILoanRateSheet
  loan: ILoan
  loanDetail: ILoanDetail
  programs: IProgram[]
  products: IProduct[]
  prices: IPrice[]
  priceLoanTime: string
  lockDays: ILockDay[]
  selectedProgramId: number | undefined
  selectedProductId: number | undefined
  selectedProgram: IPrice_Program_Price[]
  inEligibleProducts: IInElibileProduct
  priceIndex: { index1: number; index: number }
  loanNumber: string
  ratesheetID: number | undefined
  loanProcessData: ILoanProcess
  exceptionApproved?: boolean
  limit?: IPriceLimit
  forPdf?: boolean
  relockTimes?: number
  rePricing?: boolean
  pricingEngineIsOff: boolean
  viewExceptionPricing?: boolean
  highLightRate?: any
  brokerCanNotEditDetail?: boolean | undefined
  canPriceAdjust?: boolean
  canEditLoanDetail?: boolean | undefined
  isExceptionReviewRequest?: boolean | undefined
  changedLoanDetailJson?: Record<string, ILoanDetailChangedJson>
  priceAdjustments?: IPriceAdjustment[]
  pendingChangeRequest?: IPendingLoanChangeRequestData | undefined
  isRelockRate?: boolean
  lockExpired?: boolean
  isUpdated?: boolean
  showSub?: boolean
  canSeeSubPrograms?: boolean
  canDoManualPricing: boolean
  onChangeIsRelockRateToggle?: () => void
  setPriceIndex: (arg1: { index1: number; index: number }) => void
  onExceptionPricing?: () => void
  onClearException?: () => void
  onClearPrice?: () => void
  setLimit: (value: IPriceLimit) => void
  setIsUpdated?: (value: boolean) => void
  setShowSub?: (value: boolean) => void
  setPriceSelectOnRePricing?: (data: any) => void
  setRatesheetData?: (data: ILoanRateSheet) => void
  setLoanProcessData: (data: ILoanProcess) => void
  setRatesheetSync: (value: boolean) => void
  handleSelectProgram: (programID: number, productID: number) => void
  saveToPipeline?: (data: ISaveToPipelineParams) => void
  addProduct: (data: IProduct, isUpdate: boolean) => void
  removeProduct: (data: IProduct) => void
  addProgram: (product: IProduct, program: IProgram, isUpdate: boolean) => void
  addLockDay: (lockDayIndex: number, value: number, isUpdate: boolean) => void
  removeLockDay: (index: number) => void
  onAddRate: (index: number, value: number) => void
  onRemoveRate: (index: number) => void
  onUpdatePrice: (rateIndex: number, lockDayIndex: number, price: number) => void
  onUpdateRateSpreadExitFee: (index: number, key: 'RateSpread' | 'ExitFee', value: number) => void
  removeProgram: (productID: number, programID: number) => void
  checkValidation?: (type: string, customVisibleFields?: string[]) => boolean
}

const checkIcon = <CheckIcon className="w-4 h-4 text-white" />
const uncheckIcon = <StopIcon className="w-4 h-4 text-gray-300" />

export function LoanResult(props: Prop) {
  const dispatch = useDispatch()

  const {
    auth,
    loanRateSheet,
    loan,
    loanDetail,
    programs,
    products,
    prices,
    priceLoanTime,
    lockDays,
    selectedProductId,
    selectedProgramId,
    selectedProgram,
    priceIndex,
    inEligibleProducts,
    limit,
    loanNumber,
    ratesheetID,
    loanProcessData,
    lockExpired = false,
    exceptionApproved = false,
    viewExceptionPricing = false,
    forPdf = false,
    relockTimes = 0,
    rePricing = false,
    highLightRate = null,
    brokerCanNotEditDetail,
    canEditLoanDetail,
    canPriceAdjust,
    pricingEngineIsOff,
    changedLoanDetailJson = {},
    priceAdjustments = [],
    isExceptionReviewRequest,
    pendingChangeRequest,
    isRelockRate = false,
    isUpdated = false,
    canSeeSubPrograms = false,
    showSub = false,
    canDoManualPricing,
    setLoanProcessData,
    setRatesheetSync,
    handleSelectProgram,
    setPriceIndex,
    setLimit = () => {},
    setIsUpdated = () => {},
    setShowSub = () => {},
    onExceptionPricing = () => {},
    onClearException = () => {},
    onClearPrice = () => {},
    setPriceSelectOnRePricing = () => {},
    setRatesheetData = () => {},
    saveToPipeline = () => {},
    addProduct,
    addProgram,
    removeProduct,
    addLockDay,
    removeLockDay,
    onAddRate,
    onRemoveRate,
    onUpdatePrice,
    onUpdateRateSpreadExitFee,
    removeProgram,
    checkValidation = () => {},
  } = props

  const [loaing, setLoading] = useState('')
  const [modal, setModal] = useState<ModalOpen>()
  const [product, setProduct] = useState<IProduct>()
  const [program, setProgram] = useState<IProgram>()
  const [lockDay, setLockDay] = useState<number | undefined>()
  const [price, setPrice] = useState<number | undefined>()
  const [lockDayIndex, setLockDayIndex] = useState(-1)
  const [rateIndex, setRateIndex] = useState(-1)
  const [priceDisplay, setPriceDisplay] = useState('Price')

  const isNewLoan = useMemo(() => {
    return loanNumber === 'New'
  }, [loanNumber])

  const isPoints = useMemo(() => {
    return priceDisplay === 'Points'
  }, [priceDisplay])

  const isDscrProduct = useMemo(() => {
    return !isNonDSCRProduct(loan.productType)
  }, [loan])

  const enableManualyPricing = useMemo(() => {
    return canDoManualPricing && pricingEngineIsOff && !brokerCanNotEditDetail && !forPdf
  }, [pricingEngineIsOff, brokerCanNotEditDetail, canDoManualPricing, forPdf])

  const relockTimesValue = useMemo(() => {
    return isRelockRate ? relockTimes : relockTimes === 0 ? relockTimes : relockTimes - 1
  }, [relockTimes, isRelockRate])

  const selectedProduct = useMemo(() => {
    return products.find((item) => item.Id === selectedProductId)
  }, [products, selectedProductId])

  const adjustmentObject = useMemo(() => {
    let rlt = {
      rate: 0,
      price: 0,
    }
    try {
      priceAdjustments.map((item) => {
        rlt.rate += removeComma(item.rate)
        rlt.price += removeComma(item.price)
      })
    } catch {}
    return rlt
  }, [priceAdjustments])

  const maximumTotalLoanAmountTip = useMemo(() => {
    return getMaximumTotalLoanAmountTip(loan, limit)
  }, [loan, limit])

  useEffect(() => {
    const { priceDisplay } = loanDetail.loanGlobalSettings

    if (priceDisplay) setPriceDisplay(priceDisplay)
  }, [loanDetail])

  useEffect(() => {
    if (pricingEngineIsOff && isUpdated) onSaveLoanRateSheet()
  }, [
    isUpdated,
    products,
    programs,
    prices,
    lockDays,
    selectedProgram,
    selectedProductId,
    selectedProgramId,
    priceIndex,
  ])

  const onSaveLoanRateSheet = async () => {
    if (isNewLoan) return

    const ratesheetJson: ILoanRateSheet = {
      ratesheetID: pricingEngineIsOff ? 0 : ratesheetID,
      data: {
        relockTimes,
        prices,
        priceLoanTime,
        programs,
        products,
        priceAdjustments,
        selectedProgram,
        lockDays,
        limit,
        selectedProductId,
        selectedProgramId,
        priceIndex,
      },
    }

    setLoading('SavingLoan')
    setRatesheetSync(true)
    await saveLoanRatesheet(ratesheetJson)
    setLoading('')
    setRatesheetSync(false)
    setIsUpdated(false)
  }

  const showCalculated = (lockTermPrice: ILockTermPrice, rate: number, baseRate: number, exitFee: number = 0) => {
    const productId = selectedProductId || loanRateSheet.data.selectedProductId
    const programId = selectedProgramId || loanRateSheet.data.selectedProgramId
    let program = prices
      .find((item) => item.ProductID === productId)
      ?.Programs.find((item) => item.ProgramID === programId)
    if (!program)
      program = loanRateSheet.data.prices
        .find((item) => item.ProductID === productId)
        ?.Programs.find((item) => item.ProgramID === programId)

    let targetRate = program?.Prices.find((item) => item.Rate === rate)
    if (isNonDSCRProduct(loan.productType))
      targetRate = program?.Prices.find((item) => Number(item.ExitFee) === Number(exitFee))
    if (program) {
      let LLPA = targetRate?.LLPA || program?.LLPA
      let LTV = program.LTV
      let calcHistory = targetRate?.CalcHistory || program?.CalcHistory
      let margin = program.Margin

      if (lockTermPrice.type === 'lock') {
        LLPA = (program?.LockInfo?.LLPA as Record<string, ILLPA>) || LLPA
        LTV = (program?.LockInfo?.LTV as IRange) || LTV
        calcHistory = (program?.LockInfo?.CalcHistory as string[]) || calcHistory
        margin = (program?.LockInfo?.Margin as number) || margin
      }

      if (Object.keys(LLPA).length || calcHistory.length) {
        skeletonDlg(CalculatedDialog, {
          LLPA: LLPA,
          CalcHistory: calcHistory,
          Price: lockTermPrice,
          LTV: LTV,
          Rate: rate,
          BaseRate: baseRate,
          Loan: loan,
          Margin: margin,
          RelockTimes: relockTimes,
          priceAdjustments: priceAdjustments,
          type: lockTermPrice.type,
          isRelockRate: isRelockRate,
        })
      }
    }
  }

  const selectPrice = async (
    lockTermPrice: ILockTermPrice,
    programPrices: IPrice_Program_Price,
    index1: number,
    index: number,
  ) => {
    if (!auth?.isAuthenticated) return toast('Please sign in to create a new loan.', { type: 'error' })
    if (forPdf) return
    if (pricingEngineIsOff && !checkValidation('PriceLoan')) {
      toast(INVALID_ALL_INPUTS, { type: 'error' })
      return
    }
    const newLtvLimit = (programPrices?.Limit ? programPrices.Limit : limit) as IPriceLimit
    if (!isEmpty(newLtvLimit) && isNonDSCRProduct(loan.productType)) {
      setLimit(newLtvLimit)
      dispatch(setLoanLtvMaxLimit(newLtvLimit))
    }
    const ratesheetJson: ILoanRateSheet = {
      ratesheetID: pricingEngineIsOff ? 0 : ratesheetID,
      data: {
        relockTimes,
        prices,
        priceLoanTime,
        programs,
        products,
        priceAdjustments,
        selectedProgram,
        lockDays,
        limit: newLtvLimit,
        selectedProductId,
        selectedProgramId,
        priceIndex: { index1, index },
      },
    }

    if (['IL', 'NJ'].includes(loan.propertyState)) {
      if (Number(getRate(programPrices.Rate)) > 8) {
        if (!['LLC', 'LLP', 'Corp', 'Partnership'].includes(loan.borrowerType)) {
          return toast(
            `Property State = ${loan.propertyState} : Borrower Type must be one of ['LLC', 'LLP', 'Corp', 'Partnership'] if interest rate is over 8%.`,
            {
              type: 'error',
            },
          )
        }
      }
    }

    const pendingIsException = canPriceAdjust && pendingChangeRequest?.pricingChanges?.sheetDate?.includes('(E)')
    const _selectedProgram = programs.find((item) => item.ID === selectedProgramId)
    let loanProcessDataJson: ILoanProcess = {
      type: selectedProduct?.Description as string,
      program: _selectedProgram?.Name as string,
      rate: getRate(programPrices.Rate),
      price: getPrice(lockTermPrice),
      rateSpread: NaN,
      exitFee: NaN,
      sheetDate: isExceptionReviewRequest || pendingIsException ? `${formatDate('now')}(E)` : formatDate('now'),
      lockDays: Number(lockTermPrice.LockDay.replace(/\D/g, '').trim()),
      subProgramID: programPrices?.SubProgramID,
    }

    if (!isDscrProduct) {
      loanProcessDataJson.rateSpread = programPrices?.RateSpread as number
      loanProcessDataJson.exitFee = programPrices?.ExitFee as number
    }

    if (rePricing) {
      setPriceSelectOnRePricing({
        priceIndex: { index1, index },
        loanProcessData: loanProcessDataJson,
        ratesheetJson: ratesheetJson,
        limit: newLtvLimit,
      })
      return
    }
    if (relockTimes > 0) {
      setPriceIndex({ index1, index })
      setLoanProcessData(loanProcessDataJson)
      setRatesheetData(ratesheetJson)
      return
    }

    const secondsDiff = moment(formatTime('now', 'MM/DD/YYYY HH:mm:ss')).diff(moment(priceLoanTime), 'seconds')
    if (!pricingEngineIsOff && secondsDiff > 60) {
      return toast(`Pricing has expired, please Price Loan again!`, { type: 'error' })
    }

    if (isNewLoan) {
      setLoading('SavingLoan')
      setRatesheetSync(true)
      setPriceIndex({ index1, index })
      saveToPipeline({ ratesheetJson, loanProcessDataJson })
      return
    }

    if (loanProcessData.rateLocked) {
      return toast('Rate Locked!', { type: 'error' })
    }

    if (exceptionApproved) {
      return toast('Exception Approved!', { type: 'error' })
    }

    if (brokerCanNotEditDetail && canEditLoanDetail) {
      if (Object.keys(changedLoanDetailJson).length > 0) {
        return toast(`Because of Loan Detail Changes, You need to Revalidate the Loan!`, { type: 'error' })
      }
    }

    setPriceIndex({ index1, index })
    setLoading('SavingLoan')
    setRatesheetSync(true)
    try {
      await saveLoanRatesheet(ratesheetJson)
      await saveLoanProcess({
        ...loanProcessDataJson,
        historyAction: 'Select Pricing from Ratesheet',
      })
      setLoading('')
      setRatesheetSync(false)

      setLoanProcessData(loanProcessDataJson)

      await loanStructureStepUpgrade('rateAndPrice', 1)
    } catch (error) {
      console.log(error, 'error from selectPrice')
    }
  }

  const getPrice = (item: ILockTermPrice) => {
    return Number((Number(item.Price) + Number(adjustmentObject.price) - Number(relockTimesValue) * 0.125).toFixed(4))
  }

  const getRate = (rate: number) => {
    return Number((rate + Number(adjustmentObject.rate)).toFixed(3))
  }

  const renderModal = useMemo(() => {
    switch (modal) {
      case ModalOpen.AddProduct:
        return (
          <AddProduct
            data={product}
            addProduct={addProduct}
            isOpen={modal === ModalOpen.AddProduct}
            onClose={() => setModal(undefined)}
          />
        )
      case ModalOpen.AddProgram:
        return (
          <AddProgram
            data={program}
            addProgram={(data, isUpdate) => addProgram(product as IProduct, data, isUpdate)}
            isOpen={modal === ModalOpen.AddProgram}
            onClose={() => setModal(undefined)}
          />
        )
      case ModalOpen.AddLockDay:
        return (
          <AddLockDay
            data={lockDay}
            isPoints={isPoints}
            updateLockDay={(value, isUpdate) => addLockDay(lockDayIndex, value, isUpdate)}
            isOpen={modal === ModalOpen.AddLockDay}
            onClose={() => setModal(undefined)}
          />
        )
      case ModalOpen.UpdatePrice:
        return (
          <EditPrice
            data={price}
            updatePrice={(value) => onUpdatePrice(rateIndex, lockDayIndex, value)}
            isOpen={modal === ModalOpen.UpdatePrice}
            isPoints={isPoints}
            onClose={() => setModal(undefined)}
          />
        )
      default:
        return <></>
    }
  }, [modal])

  const returnPricingItems = () => {
    if (products.length === 0 || programs.length === 0) return <></>
    let elements = new Array(programs.length)
    for (let i = 0; i < elements.length; i++) {
      elements[i] = new Array(products.length).fill({
        exist: false,
        programID: -1,
        productID: -1,
        programName: '',
      })
    }

    let programsMapping: any = {}
    let productsMapping: any = {}
    for (let i = 0; i < programs.length; i++) {
      programsMapping[programs[i].ID] = i
    }

    for (let i = 0; i < products.length; i++) {
      productsMapping[products[i].Id] = i
    }

    for (let i = 0; i < prices.length; i++) {
      for (let j = 0; j < prices[i].Programs.length; j++) {
        try {
          if (!!prices[i].Programs[j].Prices.length)
            elements[programsMapping[prices[i].Programs[j].ProgramID]][productsMapping[prices[i].ProductID]] = {
              exist: true,
              programID: prices[i].Programs[j].ProgramID,
              productID: prices[i].ProductID,
              programName: prices[i].Programs[j].ProgramName,
            }
        } catch (error) {
          console.log('track element error', error)
        }
      }
    }
    let emptyFlag = true
    const programJSXElements = []
    for (let i = 0; i < programs.length; i++) {
      if (elements[i].find((item: any) => item.exist === true)) {
        emptyFlag = false

        programJSXElements.push(
          <tr key={i} className="bg-white border text-gray-900">
            {elements[i].map((item1: any, index: number) => {
              let cn = 'text-center border hover:bg-shade-blue/75 hover:text-white p-2'
              const isSelected = selectedProductId == item1.productID && selectedProgramId == item1.programID
              if (isSelected) {
                cn = 'bg-shade-blue text-white text-center border p-2'
              }
              if (highLightRate) {
                if (item1.productID === highLightRate.prodId && item1.programID === highLightRate.progId) {
                  cn += ' border-[2px] border-sky-500'
                }
              }
              const icon = isSelected ? checkIcon : uncheckIcon

              const isLastItem = programs.length - i === 1
              const placement = isLastItem ? 'right' : undefined

              return (
                <td
                  key={`${i}-${index}`}
                  onClick={() => {
                    if (item1.exist) {
                      handleSelectProgram(item1.programID, item1.productID)
                    }
                  }}
                  className={cn}
                >
                  <div
                    className={`relative flex gap-2 items-center ${
                      enableManualyPricing ? 'justify-between' : 'justify-center'
                    }`}
                  >
                    <div className="flex justify-center items-center gap-1">
                      {item1.programName && icon}
                      {item1.programName}
                    </div>
                    {enableManualyPricing && item1.programName && (
                      <div className="flex gap-1 items-center">
                        <Tooltip message="Edit Program" placement={placement}>
                          <PencilSquareIcon
                            className={`w-4 h-4 ${isSelected ? 'text-white' : 'text-shade-blue'}`}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              const targetProgram = programs.find((prog) => prog.ID === item1.programID)
                              const targetProduct = products.find((prod) => prod.Id === item1.productID)
                              setProgram(targetProgram)
                              setProduct(targetProduct)
                              setModal(ModalOpen.AddProgram)
                            }}
                          />
                        </Tooltip>
                        <Tooltip message="Remove Program" placement={placement}>
                          <TrashIcon
                            className={`w-4 h-4 ${isSelected ? 'text-white' : 'text-red-700'}`}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              removeProgram(item1.productID, item1.programID)
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </td>
              )
            })}
          </tr>,
        )
      }
    }
    if (!emptyFlag) {
      return programJSXElements
    } else {
      return (
        <tr className="border text-gray-900">
          <td className="border p-2 text-center" colSpan={products.length}>
            The entered criteria does not meet product guidelines...
          </td>
        </tr>
      )
    }
  }

  return (
    <div>
      {limit && showLimitSection() && (
        <div className="mt-4 mb-2">
          <MaxLoanAmount
            initialLoanAmount={`${calcInitialLoanAmount(loan)}`}
            maximumTotalLoanAmountTip={maximumTotalLoanAmountTip}
            limit={limit}
            pricingEngineIsOff={pricingEngineIsOff}
            loanAmount={loan.proposedLoanAmount}
          />
        </div>
      )}

      {lockExpired && canPriceAdjust && (
        <div className="px-1 mt-4">
          <Toggle
            id="relock_rate_toggle"
            title="Relock Rate"
            value={isRelockRate}
            onChange={props.onChangeIsRelockRateToggle}
          />
        </div>
      )}

      {(prices.length != 0 || loanProcessData?.sheetDate?.includes('(E)') || pricingEngineIsOff) && (
        <div className="overflow-visible">
          <LoanProcessTable
            loan={loan}
            priceDisplay={priceDisplay}
            loanProcessData={loanProcessData}
            canPriceAdjust={canPriceAdjust}
            isDscrProduct={isDscrProduct}
            selectedProgram={!!selectedProgram.length ? selectedProgram : loanRateSheet?.data?.selectedProgram || []}
            priceIndex={
              priceIndex.index !== -1 ? priceIndex : loanRateSheet?.data?.priceIndex || { index: -1, index1: -1 }
            }
            onClearException={onClearException}
            onClearPrice={onClearPrice}
            showCalculated={showCalculated}
            rePricing={rePricing}
            pricingEngineIsOff={pricingEngineIsOff}
          />

          <table className="w-full table-auto cursor-pointer mt-4 mb-1">
            <thead className="text-md text-gray-700 bg-gray-50">
              <tr className="border">
                <th colSpan={products.length} scope="colgroup" className="relative border py-2">
                  <span>
                    Products
                    {!pricingEngineIsOff && <span className="font-normal ml-1 text-[15px]">({priceLoanTime})</span>}
                  </span>
                  {enableManualyPricing && (
                    <div className="absolute right-0 top-0 h-full flex items-center pr-6">
                      <button
                        className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline whitespace-nowrap"
                        onClick={() => {
                          setProduct(undefined)
                          setModal(ModalOpen.AddProduct)
                        }}
                      >
                        + Add Product
                      </button>
                    </div>
                  )}
                </th>
              </tr>
              <tr>
                {products.map((item, index) => {
                  const isLastItem = products.length - index === 1
                  const placement = isLastItem ? 'right' : undefined
                  return (
                    <th scope="col" key={index} className={`border text-[14px] py-1 px-3`}>
                      <div
                        className={`flex gap-2 items-center ${
                          enableManualyPricing ? 'justify-between' : 'justify-center'
                        }`}
                      >
                        <span>{item.Description}</span>

                        {enableManualyPricing && (
                          <div className="flex gap-1 items-center">
                            <Tooltip message="Edit Product">
                              <PencilSquareIcon
                                className="w-4 h-4 text-shade-blue"
                                onClick={() => {
                                  setProduct(item)
                                  setModal(ModalOpen.AddProduct)
                                }}
                              />
                            </Tooltip>
                            <Tooltip message="Remove Product" placement={placement}>
                              <TrashIcon className="w-4 h-4 text-red-700" onClick={() => removeProduct(item)} />
                            </Tooltip>
                            <Tooltip message="Add Program" placement={placement}>
                              <PlusIcon
                                className="w-4 h-4 text-shade-blue"
                                onClick={() => {
                                  setProgram(undefined)
                                  setProduct(item)
                                  setModal(ModalOpen.AddProgram)
                                }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="text-sm">{returnPricingItems()}</tbody>
          </table>
        </div>
      )}

      {selectedProgram.length > 0 && (
        <div className="mt-4">
          {isNewLoan && (
            <div className="mb-4 p-3 rounded ring-[2px] ring-sky-300 flex items-center gap-2">
              <svg className="animate-bounce w-6 h-6">
                <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
              </svg>
              Please Select Price to Proceed!
            </div>
          )}

          <div className="overflow-x-auto">
            <div className="flex justify-between items-center gap-2 mb-2 mt-4">
              {enableManualyPricing && (
                <div className="flex gap-2 items-center mb-2">
                  <button
                    className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline whitespace-nowrap"
                    onClick={() => onAddRate(-1, 0)}
                  >
                    + Add Rate
                  </button>
                  <button
                    className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline whitespace-nowrap"
                    onClick={() => {
                      setLockDay(undefined)
                      setLockDayIndex(-1)
                      setModal(ModalOpen.AddLockDay)
                    }}
                  >
                    + Add {isPoints ? 'Points' : 'Price'}
                  </button>
                </div>
              )}

              <Select
                id="priceDisplay"
                title="Price Display"
                className="w-24 -mb-4"
                options={['Price', 'Points']}
                value={priceDisplay}
                onChange={(value: any) => setPriceDisplay(value)}
              />
              {canSeeSubPrograms && !pricingEngineIsOff && isDscrProduct && (
                <Toggle
                  id="showSubPrograms"
                  title="Show Sub Programs"
                  value={showSub}
                  onChange={(checked) => {
                    setShowSub(checked)
                  }}
                />
              )}
            </div>

            <table className="text-sm min-w-max w-full table-auto cursor-pointer">
              <thead className="text-gray-700 bg-gray-50">
                <tr className="border">
                  <th className="cursor-pointer border py-2 w-[100px]">
                    <div className="flex gap-2 items-center justify-center">
                      <span>Rate</span>
                    </div>
                  </th>
                  {lockDays.map((item, index) => {
                    return (
                      <th className="cursor-pointer border px-2" key={index}>
                        <div className="flex justify-center gap-2 items-center">
                          <span>{item.description} Days Lock</span>

                          {enableManualyPricing && (
                            <div className="flex gap-1 items-center">
                              <Tooltip message={`Update ${isPoints ? 'Points' : 'Price'}`}>
                                <PencilSquareIcon
                                  className="w-4 h-4 text-shade-blue"
                                  onClick={() => {
                                    setLockDay(Number(item.description))
                                    setLockDayIndex(index)
                                    setModal(ModalOpen.AddLockDay)
                                  }}
                                />
                              </Tooltip>
                              <Tooltip message={`Remove ${isPoints ? 'Points' : 'Price'}`}>
                                <TrashIcon
                                  className="w-4 h-4 text-red-700"
                                  onClick={async () => {
                                    const content = (
                                      <div className="text-gray-400 mb-4 text-[18px]">
                                        Do you want to remove this {isPoints ? 'Points' : 'Price'}?
                                        <br />
                                        <span className="text-gray-600 text-base">{item.description} Days Lock</span>
                                      </div>
                                    )

                                    const result = await confirm(content)

                                    if (!result) return

                                    removeLockDay(index)
                                  }}
                                />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </th>
                    )
                  })}
                  {isDscrProduct ? (
                    <th className="cursor-pointer border py-2 w-[100px]">DSCR</th>
                  ) : (
                    <>
                      <th className="cursor-pointer border py-2 max-w-[100px] w-[120px]">Rate Spread</th>
                      <th className="cursor-pointer border py-2 max-w-[100px] w-[120px]">Exit Fee</th>
                    </>
                  )}
                  {enableManualyPricing && <th className="border p-2 w-[60px]">Action</th>}
                </tr>
              </thead>
              <tbody>
                {selectedProgram.map((item, index) => {
                  const rate = getRate(item.Rate)
                  const pitis = combinedMonthlyExpense(loan, { rate, type: selectedProduct?.Description })
                  const rateSpreadExitFeeTooltips = getRateSpreadExitFeeTooltip(loan, item)
                  let rateCn = ''
                  if (highLightRate) {
                    if (
                      selectedProductId === highLightRate.prodId &&
                      selectedProgramId === highLightRate.progId &&
                      Number(rate) === Number(highLightRate.rate)
                    ) {
                      rateCn = 'border-[2px] border-sky-500'
                    }
                  }

                  const emptyPriceLen = lockDays.length - item.LockTermPrices.length
                  const emptyPriceArr: number[] = []
                  for (let i = 0; i < emptyPriceLen; i++) {
                    emptyPriceArr.push(0)
                  }
                  return (
                    <tr key={index} className="bg-white border cursor-pointer text-gray-900">
                      <td className={`border whitespace-nowrap ${rateCn}`}>
                        <Tooltip message={`<div class="text-sm">$${pitis['mortgagePayment']}</div>`}>
                          <div className="flex justify-center">
                            <PlainInput2
                              value={item.Rate.toString()}
                              content={rate.toString()}
                              type="number"
                              editable={enableManualyPricing}
                              onChange={(value: string) => onAddRate(index, Number(value))}
                            />
                          </div>
                        </Tooltip>
                      </td>
                      {item.LockTermPrices.map((item1, index1) => {
                        const price = getPrice(item1)
                        const icon =
                          priceIndex.index === index && priceIndex.index1 === index1 ? checkIcon : uncheckIcon
                        const tooltip = `<div class="text-sm">$${getPrice2decimal(
                          (removeComma(loan.proposedLoanAmount) * (removeComma(price) * 100 - 10000)) / 10000,
                          false,
                          true,
                        )}</div>`
                        return (
                          <td key={selectedProgram.length + index1} className="text-center border cursor-pointer">
                            <span className="flex flex-wrap items-center w-full">
                              <span
                                className={
                                  priceIndex.index === index && priceIndex.index1 === index1
                                    ? 'flex-1 py-1 bg-shade-blue text-white'
                                    : 'flex-1 py-1 hover:bg-shade-blue/75 hover:text-white'
                                }
                                onClick={() => selectPrice(item1, item, index1, index)}
                              >
                                {loaing === 'SavingLoan' &&
                                  priceIndex.index === index &&
                                  priceIndex.index1 === index1 && (
                                    <img src={svgLoading} className="mr-2 inline w-3 h-3 text-white animate-spin" />
                                  )}
                                <Tooltip message={tooltip}>
                                  <div className="flex justify-center items-center gap-1">
                                    {icon}
                                    <div className="w-7 text-left">
                                      {isPoints ? solveDecimalJavascriptSum([100, -1 * price]) : price}
                                    </div>
                                  </div>
                                </Tooltip>
                              </span>
                              {enableManualyPricing && (
                                <Tooltip message={`Edit ${isPoints ? 'Points' : 'Price'}`}>
                                  <PencilSquareIcon
                                    className="w-4 h-4 text-shade-blue"
                                    onClick={() => {
                                      setLockDayIndex(index1)
                                      setRateIndex(index)
                                      setPrice(Number(item1.Price))
                                      setModal(ModalOpen.UpdatePrice)
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {!pricingEngineIsOff && (
                                <span
                                  className="text-slate-400 hover:text-gray-900 hover-shadow1 mx-1"
                                  onClick={() => showCalculated(item1, item.Rate, item.BaseRate, item?.ExitFee)}
                                >
                                  <img src={calculatorImg} alt="calculator-img" className="w-5 h-5" />
                                </span>
                              )}
                            </span>
                          </td>
                        )
                      })}

                      {emptyPriceArr.map((v, index2) => (
                        <td key={`${index}-${index2}`} className={`text-center border`}></td>
                      ))}

                      {isDscrProduct ? (
                        <td className={`text-center border`}>{item.Dscr}</td>
                      ) : (
                        <>
                          <td className={`text-center border`}>
                            <Tooltip message={`<div class="text-sm">${rateSpreadExitFeeTooltips.rateSpread}</div>`}>
                              <div className="flex justify-center">
                                <PlainInput2
                                  value={convertNullToBlank(item.RateSpread)}
                                  content={convertNullValue(item.RateSpread)}
                                  type="number"
                                  editable={enableManualyPricing}
                                  onChange={(value: any) => {
                                    if (value == '') value = null
                                    else value = isNaN(Number(value)) ? null : Number(value)
                                    onUpdateRateSpreadExitFee(index, 'RateSpread', value)
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </td>
                          <td className={`text-center border`}>
                            <Tooltip message={`<div class="text-sm">${rateSpreadExitFeeTooltips.exitFee}</div>`}>
                              <div className="flex justify-center">
                                <PlainInput2
                                  value={convertNullToBlank(item.ExitFee)}
                                  content={convertNullValue(item.ExitFee)}
                                  type="number"
                                  editable={enableManualyPricing}
                                  onChange={(value: any) => {
                                    if (value == '') value = null
                                    else value = isNaN(Number(value)) ? null : Number(value)
                                    onUpdateRateSpreadExitFee(index, 'ExitFee', value)
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </td>
                        </>
                      )}
                      {enableManualyPricing && (
                        <td className="border">
                          <div className="flex gap-1 items-center justify-center">
                            <Tooltip message="Remove Rate" placement="right">
                              <TrashIcon
                                className="w-4 h-4 text-red-700"
                                onClick={() => {
                                  onRemoveRate(index)
                                }}
                              />
                            </Tooltip>
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {Object.keys(inEligibleProducts).length > 0 && (
        <div className="flex justify-center gap-4 mt-4">
          {!pricingEngineIsOff && (
            <div
              className="text-red-600 font-variation-settings-500 hover:underline cursor-pointer text-[14px]"
              onClick={() => {
                skeletonDlg(InEligibleDialog, { inEligibleProducts, programs, limit })
              }}
            >
              View Ineligible Products
            </div>
          )}
          {viewExceptionPricing && (
            <div className="text-shade-blue cursor-pointer hover:underline text-[14px]" onClick={onExceptionPricing}>
              Exception
            </div>
          )}
        </div>
      )}

      {renderModal}
    </div>
  )
}
