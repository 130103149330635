import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitConditionBrokerTemplate } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, ButtonGroup, Checkbox, Input2 } from 'stories/components'

import type { ICondition } from '../Conditions/types'
import { categories } from '../constant'
import type { IConditionBrokerTemplate, IConditionBrokerTemplateItem } from './types'

export function BrokerTemplateDetails(props: {
  lastUpdatedAt: number
  conditions: ICondition[]
  template: IConditionBrokerTemplate
  onBack: Function
  onComplete: Function
}) {
  const [isLoading, setLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [conditions, setConditions] = useState<Array<ICondition>>([])
  const [selectedConditions, setSelectedConditions] = useState<Array<IConditionBrokerTemplateItem>>([])
  const { conditions: _conditions = [], template, lastUpdatedAt } = props

  useEffect(() => {
    setSelectedCategory('All')
    setLoading(false)

    if (!_conditions) return
    setConditions(
      _conditions.sort((a: any, b: any) => {
        const valueA = categories.indexOf(a.category)
        const valueB = categories.indexOf(b.category)
        if (valueA < valueB) return -1
        if (valueA > valueB) return 1

        return 0
      }),
    )

    setSelectedConditions(template.conditions)
  }, [template, lastUpdatedAt, _conditions])

  const onBack = () => {
    props.onBack()
  }

  const onConditionCategoryChange = (value: string) => {
    setSelectedCategory(value)
  }

  const onChangeConditionOrder = (condition: ICondition, value: number) => {
    const orgPos = selectedConditions.findIndex((c) => c.no == condition.no)
    const { required } = selectedConditions[orgPos]
    selectedConditions.splice(orgPos, 1)
    const newConditions: Array<IConditionBrokerTemplateItem> = []
    let added = false
    console.log(condition.no, required, value)
    let index = 0
    selectedConditions.forEach(({ no, required: subRequired }) => {
      if (!added && subRequired == required && index == value) {
        newConditions.push({
          no: condition.no,
          required,
        })
        added = true
      }
      newConditions.push({ no, required: subRequired })
      if (subRequired == required) index += 1
    })
    if (!added) newConditions.push({ no: condition.no, required })

    console.log(newConditions)
    setSelectedConditions(newConditions)
  }

  const onUpdateSelectedConnection = (condition: ICondition, value: boolean) => {
    const { no: conditionNo } = condition
    const selectedIndex = selectedConditions.findIndex((c) => c.no == conditionNo)
    const isSelected = selectedIndex !== -1
    if (value == isSelected) return
    if (value) selectedConditions.push({ no: conditionNo, required: false })
    else selectedConditions.splice(selectedIndex, 1)
    setSelectedConditions(cloneDeep(selectedConditions))
  }

  const onUpdateRequired = (condition: ICondition, value: boolean) => {
    const nSelectedConditions = cloneDeep(selectedConditions)
    const selectedIndex = selectedConditions.findIndex((c) => c.no == condition.no)
    if (selectedIndex == -1) return
    if (nSelectedConditions[selectedIndex].required == value) return

    nSelectedConditions[selectedIndex].required = value
    setSelectedConditions(nSelectedConditions)
  }

  const onSubmit = () => {
    setLoading(true)
    submitConditionBrokerTemplate(template.id, selectedConditions)
      .then(() => {
        toast(`Broker Template has been updated.`, { type: 'success' })
        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  const renderSelectedConditions = (isRequired: boolean) => {
    const visibleConds = conditions
      .filter(
        (condition) => selectedConditions.findIndex((c) => c.no == condition.no && c.required == isRequired) !== -1,
      )
      .sort(
        (a, b) => selectedConditions.findIndex((c) => c.no == a.no) - selectedConditions.findIndex((c) => c.no == b.no),
      )

    const sortKeys = Object.keys(Array(visibleConds.length).fill(1))
    return visibleConds.map((condition, index) => {
      return (
        <tr
          className={`${index % 2 ? 'bg-slate-50' : 'bg-white'} text-center border-b`}
          key={`selected-condition-${isRequired}-${condition.no}`}
        >
          <td className="py-1 px-2">{index + 1}</td>
          <td className="px-2">{condition.no}</td>
          <td className="px-2 py-1">{condition.name}</td>
          <td className="px-2 text-right">
            <select
              value={index}
              className="text-sm py-1 border-gray-200"
              onChange={(e) => onChangeConditionOrder(condition, parseInt(e.target.value))}
            >
              {[...sortKeys].map((value) => (
                <option value={value} key={value}>
                  {parseInt(value) + 1}
                </option>
              ))}
            </select>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="Conditions-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Update Broker Template
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex items-center text-shade-blue">
          <ArrowLeftIcon className="w-4 h-4 mr-2" /> <p>Return to Templates</p>
        </div>
      </Button>

      <div className="w-full mb-3">
        <div className="input">
          <Input2
            title="Name"
            onChange={() => {}}
            value={`${template.productType} - ${template.transactionType}`}
            readOnly
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-between mb-5">
        <div className="lg:w-[65%] w-full">
          <h2 className="text-md font-bold flex items-center mb-3 border-b">
            Choose Conditions - {conditions && conditions.length}
          </h2>
          <ButtonGroup title={['All', ...categories]} onChange={onConditionCategoryChange} value={selectedCategory} />

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3 mb-5 max-h-[80vh]">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="pl-3"></th>
                  <th scope="col" className="py-3 px-3">
                    No
                  </th>
                  <th scope="col" className="py-3 px-3">
                    Name
                  </th>
                  <th scope="col" className="px-3">
                    Category
                  </th>
                  <th scope="col" className="px-3">
                    Required
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {conditions &&
                  selectedConditions &&
                  conditions.map((condition, index: number) => {
                    if (selectedCategory !== 'All' && condition.category != selectedCategory) return null
                    const selectedIndex = selectedConditions.findIndex((c) => c.no == condition.no)
                    return (
                      <tr
                        className={`${
                          index % 2 ? 'bg-slate-50' : 'bg-white'
                        } bg-white hover:bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700`}
                        key={`${index}`}
                      >
                        <td scope="row" className="pl-3">
                          <Checkbox
                            id={`condition-no-${condition.no}`}
                            value={selectedIndex !== -1}
                            onChange={(value) => onUpdateSelectedConnection(condition, value)}
                          />
                        </td>
                        <td className="py-3 px-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                          {condition.no}
                        </td>
                        <td className="px-3">{condition.name}</td>
                        <td className="px-3">{condition.category}</td>
                        <td className="px-3">
                          {selectedIndex != -1 && (
                            <Checkbox
                              id={`condition-ext-${condition.no}`}
                              key={`condition-ext-${condition.no}`}
                              value={selectedIndex == -1 ? false : selectedConditions[selectedIndex].required}
                              onChange={(value) => onUpdateRequired(condition, value)}
                            />
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="lg:w-[34%] w-full mb-5">
          <h2 className="text-md font-bold flex items-center mb-3 border-b">
            Selected Conditions - {selectedConditions && selectedConditions.length}
          </h2>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[80vh]">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-2 text-center">
                    Order
                  </th>
                  <th scope="col" className="py-3 px-2 text-center">
                    No
                  </th>
                  <th scope="col" className="py-3 px-2 text-center">
                    Name
                  </th>
                  <th scope="col" className="px-2 text-center">
                    Change Order
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {['Required', 'Optional'].map((category, index) => (
                  <React.Fragment key={`selected-conditions-${category}`}>
                    <tr className="border-b">
                      <td className="bg-gray-100 p-2 font-bold" colSpan={4}>
                        {category} ({selectedConditions.filter((c) => c.required == !index).length})
                      </td>
                    </tr>
                    {renderSelectedConditions(index == 0)}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          Update Template
        </Button>
      </div>
    </div>
  )
}
