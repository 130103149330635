import { EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { PlainInput } from 'components/PlainInput'
import { useEffect, useState } from 'react'
import { getBrokerVerificationCategories, setBrokerVerificationCategories } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { confirm } from 'utils'

import { ContractorApprovalContent } from './ContractorApprovalContent'

export interface BrokerIdentifyCategory {
  id: string
  value: string | null
  visible: boolean
}

export enum AccountVerificationType {
  Broker = 'broker',
  Correspondent = 'correspondent',
  Tablefunder = 'tablefunder',
  ContractorApproval = 'contractorApproval',
}

export const BrokerVerificationCategory = ({ type }: { type: AccountVerificationType }) => {
  const [isLoading, setLoading] = useState(false)
  const [values, setValues] = useState<BrokerIdentifyCategory[]>([])

  useEffect(() => {
    setLoading(true)
    getBrokerVerificationCategories(type).then((values: BrokerIdentifyCategory[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const onAdd = () => {
    const newValues = cloneDeep(values)
    newValues.push({ id: `${Date.now()}`, value: null, visible: false })
    setValues(newValues)
  }

  const onUpdate = (newValue: string, index: number) => {
    const newValues = cloneDeep(values)
    newValues[index].value = newValue
    setValues(newValues)
    submitValues(newValues)
  }

  const onChangeOrder = (val: string, index: number) => {
    const nth = Number(val)
    if (nth === index) return

    const newValues = cloneDeep(values)
    const removedOne = newValues.splice(index, 1)
    newValues.splice(nth, 0, removedOne[0])
    setValues(newValues)
    submitValues(newValues)
  }

  const onVisible = (index: number) => {
    const newValues = cloneDeep(values)
    newValues[index].visible = !newValues[index].visible
    setValues(newValues)
    submitValues(newValues)
  }

  const onDelete = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this category?
        <br />
        <span className="text-gray-600">Category: {values[index].value}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
    submitValues(newValues)
  }

  const submitValues = async (values: BrokerIdentifyCategory[]) => {
    setLoading(true)
    const savingValues = values.filter((value) => value.value !== null)
    await setBrokerVerificationCategories(savingValues, type)
    setLoading(false)
  }

  const renderAdditionalContent = () => {
    if (type != AccountVerificationType.ContractorApproval) return null

    return <ContractorApprovalContent />
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        {accountTypes[type] || 'Contractor'} Approval Categories
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="relative overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3 w-[20px]">
                No
              </th>
              <th scope="col" className="px-3 py-3">
                Name
              </th>
              <th scope="col" className="px-3 py-3 w-[50px]">
                Order
              </th>
              <th scope="col" className="px-3 py-3">
                <button
                  className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                  onClick={onAdd}
                >
                  + Add
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {values.map((value, index) => {
              const orderOptions = Array.apply(null, Array(index + 1)).map((_, index) => index.toString())
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>

                  <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    <PlainInput
                      value={value.value || ''}
                      content={value.value || ''}
                      className="h-[35px]"
                      onChange={(newValue: string) => onUpdate(newValue, index)}
                    />
                  </td>

                  <td>
                    <select
                      onChange={(e) => onChangeOrder(e.target.value, index)}
                      value={index}
                      className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    >
                      {orderOptions.map((val) => (
                        <option key={val}>{val}</option>
                      ))}
                    </select>
                  </td>

                  <td className="px-6 py-1">
                    <span className="flex gap-2">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onVisible(index)}
                      >
                        {value.visible ? (
                          <EyeIcon className="w-4 h-4"></EyeIcon>
                        ) : (
                          <EyeSlashIcon className="text-gray-300 w-4 h-4"></EyeSlashIcon>
                        )}
                      </span>

                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(index)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {renderAdditionalContent()}
    </div>
  )
}
