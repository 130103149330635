import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    photo: {
      inputType: 'file',
      title: 'Photo',
      required: true,
      multiple: false,
      acceptFileTypes: 'image/*',
    },
    photoPreview: {
      inputType: 'custom',
      title: 'Photo Preview',
    },
    firstName: {
      inputType: 'text',
      title: 'First Name',
      required: true,
    },
    lastName: {
      inputType: 'text',
      title: 'Last Name',
      required: true,
    },
    email: {
      inputType: 'text',
      type: 'email',
      title: 'Email',
      required: true,
    },
    phone: {
      inputType: 'text',
      type: 'phone',
      title: 'Phone',
      required: true,
    },
    workPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Work Phone',
      required: false,
    },
    title: {
      inputType: 'text',
      title: 'Title',
      required: true,
    },
    linkedInUrl: {
      inputType: 'text',
      title: 'LinkedIn URL',
      required: false,
    },
    facebookUrl: {
      inputType: 'text',
      title: 'Facebook URL',
      required: false,
    },
    description: {
      inputType: 'textarea',
      title: 'Description',
      required: true,
      span: 'full',
    },
  }
}
