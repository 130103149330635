import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useMemo } from 'react'

import type { IValueLeverageData } from '../../interface'
import { convertNullValue } from '../../logic'

interface IProps {
  data: IValueLeverageData[]
  title: string
  options?: any
  showDesc?: boolean
}

export const ValueAdjustment = (props: IProps) => {
  const { title, options = {}, data, showDesc = false } = props

  const renderAdjustment = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )

    return (
      <table className="w-full text-center text-sm">
        <thead className="font-bold bg-gray-100">
          <tr>
            <th rowSpan={2} className="border px-3 py-2">
              {title}
            </th>
            <th rowSpan={2} className="border px-3 py-2">
              Loan Purpose
            </th>
            <th colSpan={4} className="border px-3 py-2">
              LTV Adjustments
            </th>
          </tr>
          <tr>
            <th className="border px-3 py-2 w-[150px]">AIV-LTV</th>
            <th className="border px-3 py-2 w-[150px]">ARV-LTV</th>
            <th className="border px-3 py-2 w-[150px]">LTC</th>
            <th className="border px-3 py-2 w-[150px]">LTP</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3} className="border px-3 py-1">
                    {options[item.value] || item.value}
                  </td>
                  <td className="border px-3 py-1">Purchase</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.purchase.aivLtv)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.purchase.arvLtv)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.purchase.ltc)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.purchase.ltp)}</td>
                </tr>
                <tr>
                  <td className="border px-3 py-1">No-Cashout</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.nocashout.aivLtv)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.nocashout.arvLtv)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.nocashout.ltc)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.nocashout.ltp)}</td>
                </tr>
                <tr>
                  <td className="border px-3 py-1">Cashout</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.cashout.aivLtv)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.cashout.arvLtv)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.cashout.ltc)}</td>
                  <td className="border px-3 py-1">{convertNullValue(item.limit.cashout.ltp)}</td>
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data, title])

  return (
    <div className="h-full">
      {showDesc && (
        <p className="mb-2">
          <span>Only when the</span> "
          <i>
            <b>Product Type</b>
          </i>
          " <span>is a</span> "
          <i>
            <b>Bridge</b>
          </i>
          "
        </p>
      )}
      {renderAdjustment}
    </div>
  )
}
