import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

export const renderActions = (onBack: Function | null, onNext: Function | null, titleNext = 'Next') => {
  return (
    <div className="flex gap-6 justify-center">
      <div
        className={`text-shade-blue flex items-center mb-2 font-bold ${!onBack ? 'opacity-0' : 'cursor-pointer'}`}
        onClick={() => onBack && onBack()}
      >
        <ChevronLeftIcon className="w-4 h-4" />
        <p className="hover:underline">Back</p>
      </div>

      <div
        className={`text-shade-blue flex items-center mb-2 font-bold ${!onNext ? 'opacity-0' : 'cursor-pointer'}`}
        onClick={() => onNext && onNext()}
      >
        <p className="hover:underline">{titleNext}</p>
        <ChevronRightIcon className="w-4 h-4" />
      </div>
    </div>
  )
}
