import { ProductTypes } from 'config'

export function getLoanTerm(pricingType: string, productType: string, loanTerm: number) {
  let term = 360
  if (pricingType?.indexOf('40') !== -1) term = 480
  if (pricingType?.indexOf('15') !== -1) term = 180
  if (pricingType?.indexOf('12') !== -1) term = 12
  try {
    if (ProductTypes.DSCR !== productType) {
      term = loanTerm
    }
  } catch {}
  return term
}
