import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan Number',
      value: '',
      error: '',
      required: true,
    },
    loanDate: {
      inputType: 'text',
      type: 'date',
      title: 'Loan Date',
      value: '',
      error: '',
      required: true,
    },
    borrowers: {
      inputType: 'text',
      type: 'text',
      title: 'Borrowers',
      value: '',
      error: '',
      required: true,
    },
    propertyAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Property Address',
      value: '',
      error: '',
      required: true,
    },
    loanAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Loan Amount',
      prefix: '$',
      value: '',
      error: '',
      required: true,
    },
    entity: {
      inputType: 'text',
      type: 'text',
      title: 'Entity',
      value: '',
      error: '',
    },
    payToTheOrderOf: {
      inputType: 'text',
      type: 'text',
      title: 'Pay to the Order of',
      value: '',
      error: '',
    },
    by: {
      inputType: 'text',
      type: 'text',
      title: 'By',
      value: '',
      error: '',
      required: true,
    },
    title: {
      inputType: 'text',
      type: 'text',
      title: 'Title',
      value: '',
      error: '',
      required: true,
    },
  }
}
