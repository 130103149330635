import { ClockIcon } from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions/loanDetail.action'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config/input.type.constants'
import { usePermissions } from 'hooks/usePermissions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveLoanProcess } from 'services/apis'
import { FormTable, FormTableHeader } from 'stories/components'
import { openAuditLog, renderTableHistoryValue } from 'utils'

import { overrideValidationKeyOptions } from './constant'
import type { ILoanDetail, IPriceAdjustment } from './interfaces'

export const PriceAdjustHeader: FormTableHeader[] = [
  {
    key: 'type',
    title: 'Type',
    value: (row: any) => {
      return overrideValidationKeyOptions[row.type]
    },
  },
  { key: 'rate', title: 'Rate' },
  { key: 'price', title: 'Price' },
  { key: 'note', title: 'Note' },
]

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'select',
      title: 'Type',
      options: overrideValidationKeyOptions,
      hasDefaultOption: true,
      required: true,
    },
    rate: {
      inputType: 'text',
      type: 'number',
      title: 'Rate',
      prefix: '%',
      required: true,
    },
    price: {
      inputType: 'text',
      type: 'number',
      title: 'Price',
      prefix: '%',
      required: true,
    },
    note: {
      inputType: 'text',
      type: 'text',
      title: 'Note',
    },
  }
}

interface Prop {
  canPriceAdjust: boolean
  onChange: (key: string, value: any) => void
}

export function PriceAdjust(props: Prop) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const { loanDetail } = useSelector((state: any) => {
    return {
      loanDetail: state.loanDetail as ILoanDetail,
    }
  })

  const { hasPermission } = usePermissions()

  const onRemove = async (id: any) => {
    return new Promise(async (resolve) => {
      let temp: any = []
      setLoading(true)
      let removeType = ''
      loanDetail.priceAdjustments.map((item: any) => {
        if (item.id !== id) temp.push(item)
        else removeType = item.type
      })

      await saveLoanProcess({
        priceAdjustments: temp,
        historyAction: 'Log',
      })
      setLoading(false)
      dispatch(
        setLoanDetail({
          priceAdjustments: temp,
        }),
      )
      if (removeType) {
        props.onChange(removeType, '')
      }

      resolve(true)
    })
  }

  const onSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise(async (resolve) => {
      let temp = cloneDeep(loanDetail.priceAdjustments)
      setLoading(true)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        props.onChange(values.type, '')

        temp.push(values as IPriceAdjustment)
      } else {
        temp.map((item, index) => {
          if (item.id === currentId)
            (temp as any)[index] = {
              id: currentId,
              ...values,
            }
        })
      }

      await saveLoanProcess({
        priceAdjustments: temp,
        historyAction: 'Log',
      })
      dispatch(
        setLoanDetail({
          priceAdjustments: temp,
        }),
      )
      setLoading(false)
      resolve(values)
    })
  }

  const showHistory = () => {
    const headers = [
      { key: 'type', title: 'Type' },
      { key: 'rate', title: 'Rate' },
      { key: 'price', title: 'Price' },
      { key: 'note', title: 'Note' },
    ]
    const options = {
      table: 'LoanProcess',
      field: 'priceAdjustments',
      keys: {
        field: 'priceAdjustments',
        loanNumber: loanDetail.loanNumber,
      },
      renderValue: (data: any) => renderTableHistoryValue(data, headers),
    }
    openAuditLog(options)
  }

  return (
    <div className="PriceAdjust-container relative">
      <LayoutLoading show={loading} />
      <div className="group flex items-center text-[16px] font-variation-settings-600 mb-4">
        <span>Price Adjustments</span>
        {hasPermission('CAN_VIEW_AUDIT_LOG') && (
          <span className="ml-4 hidden group-hover:inline" onClick={() => showHistory()}>
            <ClockIcon className="h-[14px] w-[14px] text-gray-600 cursor-pointer" aria-hidden="true" />
          </span>
        )}
      </div>
      <div className="">
        <FormTable
          header={PriceAdjustHeader}
          cols={2}
          inputs={defaultInputs}
          addText="Add Adjustment"
          defaultData={loanDetail.priceAdjustments}
          onSubmit={onSubmit}
          onRemove={onRemove}
          permission={props.canPriceAdjust ? 1 : 3}
        />
      </div>
    </div>
  )
}
