import { InboxArrowDownIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { appApiUrl, companyName } from 'config'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { store } from 'reducers'
import { getDocument, getResourceProgramRestrictions } from 'services'
import { useTitle } from 'utils/pageTitle'

import { CommercialProgram } from './Commercial/CommercialProgram'
import { DscrProgram } from './DscrProgram'
import {
  type IDocument,
  type IProgramData,
  type IProgramTableData,
  ICommercialDscr,
  ICommercialOnlyProgrammed,
  ILeverageAdjustments,
  ILeverageMaxLimits,
  ProgramTypes,
} from './interface'
import { getCommercialDscrTableData, getNonDscrTableData } from './logic'

export * from './interface'
export * from './StaticComponent'

export function GuidelineOverlay() {
  useTitle(`Guideline Overlay - ${companyName}`)

  const stateData = store.getState()

  const { documentId } = useParams() as any

  const [document, setDocument] = useState<IDocument>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IProgramData[]>([])
  const [programTableData, setProgramTableData] = useState<IProgramTableData[]>([])

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      const targetDocument = (await getDocument(documentId)) as IDocument

      const programData = (await getResourceProgramRestrictions(targetDocument.programIDs)) as IProgramData[]

      const newCommercialTableData: IProgramTableData[] = []

      if (programData[0].Type === ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED) {
        programData.forEach((program) => {
          const { table, style } = getNonDscrTableData(program.ProgramData as ICommercialOnlyProgrammed)

          const { leverageMaxLimits, leverageAdjustments } = program.ProgramData as ICommercialOnlyProgrammed

          newCommercialTableData.push({
            ID: program.ID,
            Name: program.Name,
            Type: program.Type,
            tableData: table,
            tableStyle: style,
            leverageAdjustments: leverageAdjustments as ILeverageAdjustments,
            leverageMaxLimits: leverageMaxLimits as ILeverageMaxLimits,
          })

          setProgramTableData(newCommercialTableData)
        })
      } else if (programData[0].Type === ProgramTypes.COMMERCIAL_DSCR) {
        programData.forEach((program) => {
          const tableData = getCommercialDscrTableData(program.ProgramData as ICommercialDscr)

          newCommercialTableData.push({
            ID: program.ID,
            Name: program.Name,
            Type: program.Type,
            tableData: tableData,
            tableStyle: {},
          })

          setProgramTableData(newCommercialTableData)
        })
      }

      setData(programData)
      setDocument(targetDocument)
      setLoading(false)
    })()
  }, [documentId])

  const renderContent = () => {
    if (data.length == 0) return null

    switch (data[0].Type) {
      case ProgramTypes.COMMERCIAL_DSCR:
      case ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED:
        return <CommercialProgram data={programTableData} />
      case ProgramTypes.BEYOND_PRIME:
      case ProgramTypes.BEYOND_PRIME_INVESTOR:
      case ProgramTypes.MULTIFLOW:
        return <DscrProgram data={data} />
    }
  }

  return (
    <div className="guideline-container py-6">
      <div className="guideline-wrapper max-w-screen-2xl m-auto">
        {document && (
          <div className="flex items-center gap-2 mb-3">
            <h2 className="text-2xl font-bold flex items-center px-2">Matrix - {document.name}</h2>

            <a
              href={`${appApiUrl}/resources/download-matrix/${document.id}?token=${stateData.auth.token}`}
              download
              className="p-1 hover-shadow1 cursor-pointer rounded ml-3 mr-2"
            >
              <InboxArrowDownIcon className="w-5 h-5 text-shade-blue" />
            </a>
          </div>
        )}
        <LayoutLoading show={loading}></LayoutLoading>

        {renderContent()}
      </div>
    </div>
  )
}
