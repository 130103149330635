import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { RenderInput } from 'utils/RenderInput'

import { getLinkConfigurationFields } from './constants'

export const Links = () => {
  const [inputs, setInputs] = useState<Record<string, any>>(getLinkConfigurationFields())
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getAdminConfig('linkConfig')
      .then((value) => {
        let newInputs = cloneDeep(inputs)
        Object.keys(inputs).map((key) => {
          newInputs[key].value = value[key] || ''
        })
        setInputs(newInputs)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: any) => {
    const nInputs = cloneDeep(inputs)
    nInputs[key].value = value
    setInputs(nInputs)
  }

  const onSubmit = async () => {
    const data: Record<string, any> = {}
    Object.keys(inputs).map((key) => {
      if (inputs[key].inputType === 'section') return
      data[key] = inputs[key].value
    })

    setLoading(true)
    await setAdminConfig('linkConfig', data)
    setLoading(false)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        {Object.keys(inputs).map((key, index) => {
          const item = inputs[key]
          return (
            <div className={`input md:col-span-${item.span || 1} `} key={index}>
              <RenderInput key={index} input={item} Key={key} onChange={onChange} onBlur={onSubmit} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
