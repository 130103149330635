import { ArrowDownTrayIcon, LockClosedIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import { COMPANY_DNS, daysOfWeek, InputType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import type { BusinessHour } from 'pages/Admin'
import { loanStructureStepUpgrade } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { convertNullValue } from 'pages/Resources/GuidelineOverlay/logic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { LoanType } from 'reducers/loanDetail.reducer'
import {
  checkBusinessHour,
  downloadFile,
  getBorrowerInfo,
  getLoanStructureHistory,
  getLoanStructurePdf,
  loanLockorFloatRate,
  notifyPdfToLoanCreator,
  overridePricing,
} from 'services'
import Api from 'services/api'
import { getBusinessHours } from 'services/apis/admin'
import { getLoanPriceDetailsData } from 'services/pdfs/loanStructure'
import { svgLoading } from 'stories/assets'
import { Button, Input2, Modal, Select2, TextArea, Toggle } from 'stories/components'
import { confirm, convertTo12HoursMode, formatDate, formatDateYMD, formatTime, InputValidate, isHTML } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { convertHistoryLabel, overrideFields } from '../constant'
import type { IConcessionData, ILoan, ILoanDetail, ILoanProcess, ILoanRateSheet } from '../interfaces'
import { getLockExpired } from '../Logic'
import { LockExtensionDialog } from './LockExtensionDialog'
import { LockHistoryDialog } from './LockHistoryDialog'
interface Prop {
  loanRateSheet: ILoanRateSheet
  loanProcessData: ILoanProcess
  loanDetail: ILoanDetail
  applicationStep: any
  setLoanProcessData: (data: ILoanProcess) => void
  syncOriginLoanDetailWithCurrent: () => void
  openRevalidate: () => void
  showingFields: string[][]
  pricingEngineIsOff: boolean
}

export function ReviewLock(props: Prop) {
  const {
    loanProcessData,
    loanRateSheet,
    setLoanProcessData,
    loanDetail,
    applicationStep,
    showingFields,
    pricingEngineIsOff,
  } = props

  const [histories, setHistories] = useState<Array<any>>([])
  const [reviewLockSync, setReviewLockSync] = useState(false)
  const [historySync, setHistorySync] = useState(false)
  const [historyIndex, setHistoryIndex] = useState('all')
  const [action, setAction] = useState('')
  const [fields, setFields] = useState<Record<string, InputType>>({})
  const [isOpen, setIsOpen] = useState('')
  const [lastUpdatedAt, setLastUpdatedAt] = useState(0)
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState<Record<string, any>>({})
  const [overrideKey, setOverrideKey] = useState('')
  const [ratesheetVlidationError, setRatesheetVlidationError] = useState<Array<any>>([])
  const [concessionNote, setConcessionNote] = useState('')
  const [businessHours, setBusinessHours] = useState<BusinessHour | null>(null)
  const [notifiers, setNotifiers] = useState<EmailValue>({})
  const [showPrice, setShowPrice] = useState(true)

  const dispatch = useDispatch()
  const loanNumber = setLoanNumber()

  const { hasPermission } = usePermissions()

  const anytimeCanLockRate = !!hasPermission('ANYTIME_CAN_LOCK_RATE')

  const { loan } = useSelector((state: any) => {
    return {
      loan: state.loan as ILoan,
    }
  })

  const { email } = useSelector((state: any) => state.auth.profile)
  const isCompanyEmail = useMemo(() => (email || '').endsWith(`@${COMPANY_DNS}`), [email])

  const lockExpired = useMemo(() => {
    return getLockExpired(loanProcessData)
  }, [loanProcessData.rateLocked, loanProcessData.expireDate])

  useEffect(() => {
    initialize()
    let temp = cloneDeep(overrideFields())
    delete temp.override
    delete temp.note
    let _temp: any = {}
    Object.keys(temp).map((key) => {
      _temp[key] = temp[key]
      if (key === 'price') {
        _temp['concession'] = {
          title: 'Exception Concession',
          type: 'number',
          inputType: 'text',
          required: true,
        }
      }
    })
    temp = {
      ..._temp,
      sheetDate: {
        title: 'Ratesheet Date',
        type: 'date',
        inputType: 'text',
        required: true,
      },
      lockedDate: {
        title: 'Lock Start Date',
        type: 'date',
        inputType: 'text',
        required: true,
      },
      expireDate: {
        title: 'Expire Date',
        type: 'date',
        inputType: 'text',
        required: true,
      },
    }
    setFields(temp)

    getBusinessHours().then((businessHour) => setBusinessHours(businessHour))
  }, [])

  useEffect(() => {
    setDefaultNotifiers()
  }, [])

  const setDefaultNotifiers = async () => {
    setHistorySync(true)
    const { borrowerEmail, coBorrowerEmail } = await getBorrowerInfo(loanNumber)
    setHistorySync(false)

    const defaultNotifiers: any = {}

    if (loanDetail?.parties.creator?.email) defaultNotifiers[loanDetail?.parties.creator?.email] = 'to'
    if (loanDetail?.parties.broker?.email) defaultNotifiers[loanDetail?.parties.broker?.email] = 'to'
    if (borrowerEmail) defaultNotifiers[borrowerEmail] = 'to'
    if (coBorrowerEmail) defaultNotifiers[coBorrowerEmail] = 'to'

    if (isCompanyEmail) defaultNotifiers.from = 'self'
    else defaultNotifiers.from = 'default'

    setNotifiers(defaultNotifiers)
  }

  const initialize = async () => {
    if (Api.getLoanNumber() === 'new') return
    setHistorySync(true)
    try {
      const res = await getLoanStructureHistory()
      setHistories(res)
    } catch (error) {
      console.log('Get Loan Structure History Error: ', error)
    }
    setHistorySync(false)
  }

  const getConcessions = (data: any) => {
    let rlt: IConcessionData[] = []
    let stop = false
    data.map((item: any) => {
      const { action, detail, createdAt } = item
      if (detail?.rate) stop = true
      if (!stop) {
        if (action === 'Override Pricing Values') {
          if (detail?.['Exception Concession']) {
            rlt.push({
              note: detail['Note'],
              value: detail['Exception Concession'],
              date: formatTime(createdAt),
            })
          }
        }
      }
    })
    rlt = rlt.reverse()
    return rlt
  }

  const checkInBusinessHours = async () => {
    // if (isAppDev) return true
    if (!businessHours) return false

    const res = await checkBusinessHour()

    setBusinessHours(res.businessHours)

    return res.success
  }

  const openOverrideModal = (key: string) => {
    setIsOpen('overrideModal')
    setConcessionNote('')
    setLastUpdatedAt(Date.now())
    let temp = cloneDeep(fields[key])
    temp.value = loanProcessData[key]
    setEdit(temp)
    setOverrideKey(key)
  }

  const onConfirmOverride = async () => {
    setLoading(true)
    let error = InputValidate(edit)

    if (error.length > 0) {
      setLoading(false)
      return toast(`Invalid Input Value: ${edit.title}`, { type: 'error' })
    }

    let { value, type } = edit

    if (type === 'date') value = formatDate(value)

    let json: any = {
      key: overrideKey,
      value: value,
    }

    if (overrideKey === 'concession') {
      json.note = concessionNote
      json.price = Number(loanProcessData.price) + Number(value)
    }

    const res = await overridePricing(json)

    if (res.success) {
      let temp = cloneDeep(loanProcessData)

      if (overrideKey === 'concession') {
        temp.price = json.price
      } else {
        temp = {
          ...temp,
          [overrideKey]: value,
        }
      }

      setLoanProcessData(temp)
      setLoading(false)
      initialize()
      return true
    }
  }

  const onChangeOverride = (key: string, value: any) => {
    let temp = cloneDeep(edit)
    temp.value = value
    setEdit(temp)
  }

  const lockFloatStructureRate = async () => {
    setAction('lockFloatStructureRate')

    const isBusinessHour = await checkInBusinessHours()

    if (!isBusinessHour) {
      if (anytimeCanLockRate) {
        const res = await confirm('Would you like to proceed with locking the rate outside of business hours?')
        if (!res) {
          setAction('')
          return
        }
      } else {
        setAction('')
        return
      }
    }

    if (reviewLockSync) return

    setReviewLockSync(true)

    const consessions = getConcessions(histories)
    let _concession = ''
    consessions.map((item) => {
      _concession += `${item.value}, `
    })

    let json: any = {
      // continue: continueLockRate,
      rateLocked: loanProcessData.rateLocked,
      loanPriceDetails: await getLoanPriceDetailsData(showingFields, loanProcessData),
      concession: _concession,
      pricingEngineIsOff: pricingEngineIsOff,
    }
    if (!json.rateLocked) {
      json.showingFields = showingFields
    }

    const res = await loanLockorFloatRate(json)

    setAction('')
    setReviewLockSync(false)
    if (res.success === 0) {
      return setRatesheetVlidationError([
        `Pricing Rate Sheet has been updated!<br/>Pricing has expired, please reprice the loan!`,
        `${res.data.currentID} -> ${res.data.finresiID}`,
      ])
    }

    let tempLoanProcess = cloneDeep(loanProcessData)
    tempLoanProcess = {
      ...tempLoanProcess,
      ...res.data,
    }
    toast(res.data.rateLocked ? 'Successfully Lock Rate!' : 'Successfully Float Rate!', { type: 'success' })
    setLoanProcessData(tempLoanProcess)
    if (res.data.rateLocked) {
      props.syncOriginLoanDetailWithCurrent()
    }

    initialize()

    // update loanDetail Reducer
    const tempRateData = cloneDeep(loanDetail.rateData)
    tempRateData.locked = res.data.rateLocked
    dispatch(
      setLoanDetail({
        rateData: tempRateData,
      }),
    )

    await loanStructureStepUpgrade('lockRate', res.data.rateLocked ? 1 : 0)
  }

  const onGeneratePdf = async (isNotify: boolean = false) => {
    setAction(isNotify ? 'notifyPdf' : 'generatePdf')

    let json: any = {
      data: {
        loanNumber: loanDetail.byteproFileName || loanDetail.loanNumber,
        borrowerFirstName: loan.borrowerFirstName,
        borrowerLastName: loan.borrowerLastName,
        subjectPropertyAddress: loan.subjectPropertyAddress,
      },
      loanProcessData,
      loanDetailData: await getLoanPriceDetailsData(showingFields, loanProcessData),
      concessions: getConcessions(histories),
      showPrice,
    }

    if (!isNotify) {
      const data: Blob = await getLoanStructurePdf(json)
      downloadFile(`Pricing Confirmation - ${json.data.loanNumber} (${formatDateYMD()}).pdf`, data)

      setAction('')
      return
    }

    json.emailTo = notifiers

    await notifyPdfToLoanCreator(json)

    setAction('')
  }

  const loanLockExtension = async () => {
    const isBusinessHour = await checkInBusinessHours()

    if (!isBusinessHour) return

    setIsOpen('LockExtensionDialog')
  }

  const loanRelockRate = async () => {
    const isBusinessHour = await checkInBusinessHours()

    if (!isBusinessHour) return

    props.openRevalidate()
  }

  const viewHistoryDetail = (Index: number) => {
    setHistoryIndex(Index.toString())
    setIsOpen('LockHistoryDialog')
  }

  const renderExpireDateStatus = () => {
    if (!lockExpired) return
    return (
      <span className="statusColor">
        <span className={`text-gray-900 font-medium capitalize p-1 mb-1 w-[80px] text-[13px] rounded overdue`}>
          Lock Expired
        </span>
      </span>
    )
  }

  const renderOverrideEach = (key: string) => {
    if (hasPermission('CAN_OVERRIDE_LOAN_PRICING')) {
      return (
        <PencilSquareIcon
          className="w-4 h-4 cursor-pointer"
          key={key}
          onClick={() => openOverrideModal(key)}
        ></PencilSquareIcon>
      )
    }
  }

  const renderHistoryDetail = (item: any, index: number) => {
    if (Object.keys(item.detail).length === 0) return null

    let rlt = (
      <tr className={`${index % 2 === 1 ? 'bg-gray-50' : ''} border-b border-gray-350`} key={`detail-${index}`}>
        <td></td>
        <td colSpan={2} className="p-2 text-[13px] overflow-auto">
          {isHTML(item.detail) ? (
            <span className="dangerouslySetInnerHTML">
              <div dangerouslySetInnerHTML={{ __html: item.detail }}></div>
            </span>
          ) : (
            <div className="overflow-auto max-w-[420px] md:max-w-[620px]">
              <table className="">
                <tbody>
                  <tr className="">
                    {Object.keys(item.detail).map((key, index) => {
                      if (key == 'brokerAdjustment') return null
                      return (
                        <td className="border px-2" key={index}>
                          {convertHistoryLabel(key)}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    {Object.keys(item.detail).map((key, index) => {
                      let value = item.detail[key]
                      if (typeof value == 'boolean') value = value ? 'Yes' : 'No'
                      if (key == 'brokerAdjustment') return null
                      return (
                        <td className="px-2 py-1 border" key={index}>
                          {isHTML(value) ? (
                            <span className="dangerouslySetInnerHTML">
                              <div dangerouslySetInnerHTML={{ __html: value }}></div>
                            </span>
                          ) : (
                            <span className="italic">{convertNullValue(value)}</span>
                          )}
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </td>
      </tr>
    )
    return rlt
  }

  const concessionFragment = useMemo(() => {
    let rlt = getConcessions(histories)
    if (rlt.length === 0) return '-'

    return (
      <table className="font-variation-settings-500 text-[12.5px]">
        <thead>
          <tr>
            <th className="border px-2">Value</th>
            <th className="border px-2">Date</th>
          </tr>
        </thead>
        <tbody>
          {rlt.map((item, index) => {
            let cn = index % 2 ? 'bg-gray-50' : ''
            return [
              <tr key={`1-${index}`}>
                <td className={`${cn} border px-2 text-center text-[14px] font-variation-settings-600`}>
                  {item.value}
                </td>
                <td className={`${cn} border px-2`}>{item.date}</td>
              </tr>,
              <tr key={`2-${index}`}>
                <td colSpan={2} className={`${cn} border px-2 italic`}>
                  {item.note}
                </td>
              </tr>,
            ]
          })}
        </tbody>
      </table>
    )
  }, [histories])

  const renderLockRateRestrictions = useMemo(() => {
    let errors = 0
    let rlt = []
    if (Number(loanProcessData.rate) <= 0) {
      errors += 1
      rlt.push(<div key={3}>- Interest Rate is Required!</div>)
    }
    if (!anytimeCanLockRate) {
      if (loanDetail.loanType != LoanType.CORRESPONDENT && applicationStep.signApplication !== 1) {
        errors += 1
        rlt.push(<div key={2}>- Loan Application Sign is Required.</div>)
      }
      if (loanDetail.submitToUnderwriter === 0) {
        errors += 1
        rlt.push(<div key={0}>- Submit to Underwriter is Required!</div>)
      }
    }
    if (errors > 0) {
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] my-4"
          role="alert"
        >
          <div className="font-variation-settings-600 mb-3">Following steps are required before Lock Rate.</div>
          {rlt}
        </div>
      )
    }
    return null
  }, [loanDetail, loanProcessData])

  const lockRateDisabled = useMemo(() => {
    if (anytimeCanLockRate) {
      return Number(loanProcessData.rate) <= 0 ? true : false
    } else return !!renderLockRateRestrictions
  }, [loanProcessData, renderLockRateRestrictions, anytimeCanLockRate])

  return (
    <div className="ReviewLock-Container">
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-x-6">
        <div className="relative shadow1 md:col-span-6 col-span-12 bg-white rounded p-4 md:p-6 mb-4">
          <div className="text-2xl font-bold mb-4 flex items-center">
            <span>Review & Lock</span>
            {reviewLockSync && (
              <span className="ml-4 flex items-center">
                <img src={svgLoading} className="inline w-5 h-5 text-white animate-spin" />
              </span>
            )}
          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500">
              <tbody className="text-gray-900">
                {Object.keys(fields).map((key, index) => {
                  let value = loanProcessData[key]
                  if (key === 'lockedDate' || key === 'expireDate') {
                    value = formatDate(value)
                    if (!loanProcessData.rateLocked) {
                      value = '-'
                    }
                  }

                  if (key === 'concession') value = concessionFragment

                  return (
                    <tr key={index}>
                      <td className="px-6 py-2 border w-52">{fields[key].title}</td>
                      <td className="px-6 py-2 border text-shade-blue font-bold">
                        <span className="flex gap-4 justify-between">
                          <span className="flex gap-2">
                            {value}
                            {key === 'expireDate' && renderExpireDateStatus()}
                          </span>

                          {renderOverrideEach(key)}
                        </span>
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td className="px-6 py-2 border">Lock Status</td>
                  <td className="px-6 py-2 border text-shade-blue font-bold">
                    {loanProcessData.rateLocked ? 'Yes' : 'No'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {businessHours && (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Business Hours
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-900">
                  <tr>
                    <td className="px-6 py-2 text-[16px]">
                      {convertTo12HoursMode(businessHours.startTime)} - {convertTo12HoursMode(businessHours.endTime)}{' '}
                      (EST){' '}
                      <span className="ml-4">
                        {daysOfWeek[businessHours.startDay]} - {daysOfWeek[businessHours.endDay]}
                        {businessHours.isHoliday ? (
                          <span className="text-shade-blue font-variation-settings-600 ml-4">(Today is Holiday)</span>
                        ) : (
                          ''
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {!loanProcessData.rateLocked && (
            <>
              {renderLockRateRestrictions}
              {ratesheetVlidationError.length > 0 && (
                <div>
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] my-4"
                    role="alert"
                  >
                    <span dangerouslySetInnerHTML={{ __html: ratesheetVlidationError[0] }} />
                  </div>
                </div>
              )}

              {hasPermission('CAN_LOCK_RATE') && (
                <div className="p-4 rounded shadow my-6 max-w-[475px]">
                  <div className="border-b mb-4 text-gray-800 font-bold text-[16px] flex justify-between items-center">
                    <span>Lock Actions</span>
                    <span className="text-shade-blue">
                      <LockClosedIcon className="w-5 h-5"></LockClosedIcon>
                    </span>
                  </div>
                  <Button
                    className="w-full"
                    onClick={lockFloatStructureRate}
                    loading={action === 'lockFloatStructureRate'}
                    disabled={lockRateDisabled}
                  >
                    Lock Rate
                  </Button>
                </div>
              )}
            </>
          )}

          {loanProcessData.rateLocked && (
            <div className="">
              {lockExpired && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] my-6"
                  role="alert"
                >
                  <div className="font-variation-settings-600 mb-3">
                    Rate Lock has Expired! You can do the following:
                  </div>
                  <div>- You can extend your lock by Clicking 'Lock Extension'</div>
                  <div>
                    - You can relock again by clicking 'ReLock Rate'. To do this you need to select new pricing!
                  </div>
                </div>
              )}
              <div className="p-4 rounded shadow my-6 max-w-[475px]">
                <div className="border-b mb-4 text-gray-800 font-bold text-[16px] flex justify-between items-center">
                  <span>Lock Actions</span>
                  <span className="text-shade-blue">
                    <LockClosedIcon className="w-5 h-5"></LockClosedIcon>
                  </span>
                </div>

                {hasPermission('CAN_FLOAT_RATE') && (
                  <div className="my-4">
                    <Button
                      className="w-full"
                      onClick={lockFloatStructureRate}
                      loading={action === 'lockFloatStructureRate'}
                    >
                      Float Rate
                    </Button>
                  </div>
                )}

                {hasPermission('CAN_LOCK_EXTEND') && (
                  <div className="my-4">
                    <Button className="w-full" onClick={loanLockExtension}>
                      Lock Extension
                    </Button>
                  </div>
                )}

                {lockExpired && hasPermission('CAN_RELOCK_RATE') && (
                  <div className="my-4">
                    <Button className="w-full" onClick={loanRelockRate}>
                      ReLock Rate
                    </Button>
                  </div>
                )}
              </div>

              {hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION') && (
                <div className="p-4 shadow max-w-[475px]">
                  <div className="border-b mb-4 text-gray-800 font-bold text-[16px] flex justify-between items-center">
                    <div className="flex gap-2 items-center">
                      <span>Documents</span>
                      <span className="text-shade-blue">
                        <ArrowDownTrayIcon className="w-5 h-5"></ArrowDownTrayIcon>
                      </span>
                    </div>
                    <Toggle id="withPrice" title="Show Price" value={showPrice} onChange={setShowPrice} />
                  </div>

                  <div className="mb-2">
                    <Button
                      className="w-full"
                      color="gray"
                      onClick={() => onGeneratePdf(false)}
                      disabled={historySync}
                      loading={action === 'generatePdf'}
                    >
                      Lock Confirmation
                    </Button>

                    <div className="mb-3"></div>

                    <Button
                      className="w-full"
                      color="gray"
                      onClick={() => {
                        setIsOpen('notifyPdf')
                        setDefaultNotifiers()
                      }}
                      disabled={historySync}
                      loading={action === 'notifyPdf'}
                    >
                      Lock Confirmation Notify
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="relative shadow1 md:col-span-6 col-span-12 bg-white rounded p-4 md:p-6 mb-4">
          <div className="text-2xl font-bold mb-4 flex items-center">
            <span>Structure Histories</span>
            {historySync && (
              <span className="ml-4 flex items-center">
                <img src={svgLoading} className="inline w-5 h-5 text-white animate-spin" />
              </span>
            )}
          </div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-2 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date / By
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-900 overflow-auto">
                {histories.map((item, index) => {
                  const detail = renderHistoryDetail(item, index)

                  let action = item.action
                  if (['Lock Rate', 'ReLock Rate', 'Revalidate Loan'].indexOf(action) !== -1) {
                    action = (
                      <span
                        className="hover:underline cursor-pointer text-shade-blue"
                        onClick={() => viewHistoryDetail(index)}
                      >
                        {action}
                      </span>
                    )
                  }
                  return [
                    <tr key={index} className={`${index % 2 === 1 ? 'bg-gray-50' : ''}`}>
                      <td className={`px-2 py-2 pl-3 ${detail === null && 'border-b'}`}>{index + 1}</td>
                      <td className="px-2 py-2 border-b font-variation-settings-600">{action}</td>
                      <td className="px-2 py-2 border-b">
                        <span>
                          <div className="border-b w-fit mb-1 border-gray-300">{formatTime(item.createdAt)}</div>
                          {item.email}
                        </span>
                      </td>
                    </tr>,
                    detail,
                  ]
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        button={<span></span>}
        title={`Override ${edit.title}`}
        titleOkay="Confirm"
        titleCancel="Close"
        isOpen={isOpen === 'overrideModal'}
        lastUpdatedAt={lastUpdatedAt}
        loading={loading}
        onOk={onConfirmOverride}
      >
        <div className="w-96">
          {edit.inputType === 'text' && (
            <Input2
              title={edit.title}
              key={overrideKey}
              type={edit.type}
              value={edit.value}
              onChange={(value) => onChangeOverride(overrideKey, value)}
            />
          )}
          {edit.inputType === 'select' && (
            <Select2
              title={edit.title}
              id="override"
              options={edit.options}
              key={overrideKey}
              value={edit.value}
              onChange={(value) => onChangeOverride(overrideKey, value)}
            />
          )}
          {overrideKey === 'concession' && (
            <TextArea
              className="mt-4"
              title={'Note'}
              rows={2}
              key={'concessionNote'}
              value={concessionNote}
              onChange={(value) => setConcessionNote(value)}
            />
          )}
        </div>
      </Modal>

      {isOpen === 'notifyPdf' && (
        <Modal
          isOpen={isOpen === 'notifyPdf'}
          title="Send Lock Confirm Notification To"
          titleOkay="Notify"
          onClose={() => setIsOpen('')}
          onOk={() => {
            setIsOpen('')
            onGeneratePdf(true)
          }}
        >
          <div>
            <LoanPartiesEmailTo
              loanNumber={loanNumber}
              value={notifiers}
              data={[]}
              onChange={setNotifiers}
              loadParties
            />
          </div>
        </Modal>
      )}

      <LockExtensionDialog
        isOpen={isOpen === 'LockExtensionDialog'}
        onClose={() => setIsOpen('')}
        setLoanProcessData={setLoanProcessData}
        loanProcessData={loanProcessData}
      />
      <LockHistoryDialog
        loanRateSheet={loanRateSheet}
        historyIndex={historyIndex}
        isOpen={isOpen === 'LockHistoryDialog'}
        onClose={() => setIsOpen('')}
        loanDetail={loanDetail}
      />
    </div>
  )
}
