import cloneDeep from 'clone-deep'
import { EmailValue, hasToEmails, LoanPartiesEmailTo } from 'components/EmailTo'
import type { BaseFile } from 'config'
import { useMemo, useState } from 'react'
import { sendTrustLedgerNotification } from 'services'
import { Checkbox, Input2, Modal, Select2 } from 'stories/components'
import { formatTime, removeComma, solveDecimalJavascriptSum } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export const ChooseDocumentsForTrustLedger = ({
  documents = [],
  onClose = () => {},
  data = {},
}: {
  documents: BaseFile[]
  onClose: Function
  data: any
}) => {
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState<EmailValue>({})
  const [emailType, setEmailType] = useState('')
  const [selectedDocs, setSelectedDocs] = useState<Record<number, boolean>>({})

  const onChangeCheck = (key: number, value: boolean) => {
    const newData = cloneDeep(selectedDocs)
    if (value) newData[key] = value
    else delete newData[key]
    setSelectedDocs(newData)
  }

  const onSubmit = async () => {
    const docKeys = Object.keys(selectedDocs)
    const selectedFiles = documents
      .filter((file) => docKeys.includes(`${file.id}`))
      .map((file) => ({ name: file.name, fileKey: file.fileKey }))
    setLoading(true)
    await sendTrustLedgerNotification(data.ID, emails, emailType, selectedFiles, renderDetails)
    setLoading(false)
    onClose(emails)
  }

  const renderDetails = useMemo(() => {
    let row: any

    let balance = data.constructionReserve === -1 ? 0 : data.constructionReserve
    let continueBalance = true
    data.data.map((item: any) => {
      const fee = solveDecimalJavascriptSum([
        removeComma(item.inspectionFee),
        removeComma(item.wireFee),
        removeComma(item.titleUpdateFee),
      ])
      if (continueBalance)
        balance = solveDecimalJavascriptSum([
          removeComma(balance),
          -1 * removeComma(item.payment),
          removeComma(item.deposit),
          -1 * removeComma(fee),
          -1 * removeComma(item.additionHoldback),
        ])
      if (item.id === data.ID) {
        continueBalance = false
        row = item
      }
    })

    const inputs = [
      {
        title: 'Rehab Budget',
        value: data.rehabBudget === -1 ? '-' : data.rehabBudget,
      },
      {
        title: 'Construction Reserve',
        value: data.constructionReserve === -1 ? '-' : data.constructionReserve,
      },
      {
        title: 'Gross Amount Eligible to receive (Eligible Amount)',
        value: removeComma(row.eligibleAmount),
      },
      {
        title: 'Pro Rata Eligible Amount (Net Eligible Amount)',
        value: removeComma(row.netEligibleAmount),
      },
      {
        title: 'Previously Disbursed',
        value: removeComma(row.previouslyDisbursed),
      },
      {
        title: 'Inspection Fee',
        value: removeComma(row.inspectionFee),
      },
      {
        title: 'Wire Fee',
        value: removeComma(row.wireFee),
      },
      {
        title: 'Title Update Fee',
        value: removeComma(row.titleUpdateFee),
      },
      {
        title: 'Additional Holdback',
        value: removeComma(row.additionHoldback),
      },
      {
        title: 'Net Disbursement',
        value: solveDecimalJavascriptSum([removeComma(row.payment)]),
      },
      {
        title: 'Project Balance',
        value:
          data.rehabBudget === -1
            ? 0
            : solveDecimalJavascriptSum([removeComma(data.rehabBudget), -1 * removeComma(row.eligibleAmount)]),
      },
      {
        title: 'Construction Reserve Balance',
        value: balance,
      },
      {
        title: 'Notes',
        value: row.notes,
      },
    ]
    return inputs
  }, [data])

  return (
    <Modal
      title="Choose Documents to send Email"
      titleOkay="Send Email"
      isOpen
      loading={loading}
      disabled={!hasToEmails(emails) || !emailType.length}
      lastUpdatedAt={Date.now()}
      onClose={() => onClose()}
      onOk={() => onSubmit()}
    >
      <div className="w-[650px] overflow-auto">
        <Select2
          className="mb-5"
          id={'emailType'}
          title={'Email Type'}
          options={{
            review: 'Draw Review Request',
            approved: 'Draw Approved',
          }}
          key={'emailType'}
          value={emailType}
          hasDefaultOption={true}
          required={true}
          onChange={(value: string) => setEmailType(value)}
        />

        <div className="mb-5">
          <p className="text-sm font-semibold">Email To</p>
          <LoanPartiesEmailTo loanNumber={setLoanNumber()} loadParties data={[]} value={emails} onChange={setEmails} />
        </div>

        <table className="text-left text-sm w-full">
          <thead>
            <tr className="border-b cursor-pointer">
              <th className=""></th>
              <th className="px-2">Name</th>
              <th className="px-2">Created At</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index: number) => {
              let className = index % 2 ? 'bg-gray-50' : ''

              return (
                <tr key={`tr-${document.id}`} className={`border-b ${className}`}>
                  <td className="py-2">
                    <Checkbox
                      className="ml-2"
                      title=""
                      id={`check-${document.id}`}
                      checked={Object.keys(selectedDocs).includes(`${document.id}`)}
                      onChange={(value) => onChangeCheck(document.id, value)}
                    />
                  </td>
                  <td className="px-2 py-2">{document.name}</td>
                  <td className="px-2">{formatTime(document.createdAt)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {
          <div className="mt-4">
            <div className="grid md:grid-cols-2 gap-3">
              {renderDetails.map((item: any, index: number) => {
                let prefix = '$',
                  type = 'thousandSep',
                  span = 1
                if (item.title === 'Notes') {
                  prefix = ''
                  type = 'text'
                  span = 2
                }
                return (
                  <div className={`md:col-span-${span}`} key={index}>
                    <Input2
                      title={item.title}
                      prefix={prefix}
                      key={index}
                      type={type}
                      value={item.value as string}
                      readOnly={true}
                      onChange={(value) => {
                        console.log(value)
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}
