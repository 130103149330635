import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitLoanProgram } from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { ARMDetailFields, defaultInputs } from './constants'
import type { ILoanProgram } from './types'

export default function LoanProgramForm(props: {
  lastUpdatedAt: number
  defaults: ILoanProgram | null
  onBack: Function
  onComplete: Function
}) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const { defaults = null, lastUpdatedAt } = props

  const inputLogic = (data: Record<string, InputType>) => {
    data = cloneDeep(data)
    ARMDetailFields.map((key) => {
      data[key].visible = data['amortizationType'].value === 'ARM'
    })
    if (data.ARMNoAdjCap.value) {
      data.ARMAdjustCapFirst.required = false
      data.ARMAdjustCapFirst.value = ''
      data.ARMAdjustCapFirst.disabled = true
      data.ARMAdjustCapSubsequent.required = false
      data.ARMAdjustCapSubsequent.value = ''
      data.ARMAdjustCapSubsequent.disabled = true
    } else {
      data.ARMAdjustCapFirst.required = true
      data.ARMAdjustCapFirst.disabled = false
      data.ARMAdjustCapSubsequent.required = true
      data.ARMAdjustCapSubsequent.disabled = false
    }
    data.ioMonths.visible = data.interestOnly.value === true
    return data
  }

  useEffect(() => {
    setLoading(true)
    if (!defaults) {
      setInputs(defaultInputs())
      setLoading(false)
      return
    }
    const newStats = cloneDeep(defaultInputs())
    for (const key in newStats) {
      if (!!(defaults as any)[key]) {
        newStats[key].value = (defaults as any)[key]
      }
    }
    setInputs(inputLogic(newStats))
    setLoading(false)
  }, [defaults, lastUpdatedAt])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate(newInputs[key])
    if (['amortizationType', 'ARMNoAdjCap', 'interestOnly'].indexOf(key) !== -1) {
      newInputs = inputLogic(newInputs)
    }
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    let data: Record<string, any> = {}
    Object.keys(newInputs).forEach((key) => {
      if (newInputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    })
    setInputs(newInputs)

    if (hasError) return

    setLoading(true)

    const id = defaults ? defaults.id : 0

    submitLoanProgram(id, data)
      .then(() => {
        if (id == 0) toast('New Loan Program is added.', { type: 'success' })
        else toast(`Loan Program No ${defaults?.no} is updated.`, { type: 'success' })

        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="ARMDetails-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Loan Program - {!defaults ? 'Add' : `Update No ${defaults.no}`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-2" /> <p>Return to Loan Programs Lists</p>
        </div>
      </Button>

      <div className="mb-4">
        <div className="grid gap-4 md:grid-cols-6 grid-cols-1 mb-3">
          {Object.keys(inputs).map((key: string) => {
            let input = inputs[key]
            if (input.visible === false) return null
            return (
              <div className={`input md:col-span-${input.span || 2}`} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>

      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'} Loan Program</>
        </Button>
      </div>
    </div>
  )
}
