import React from 'react'
import { thousandSeperator } from 'utils'

import type { LtvLimit } from '../interface'

export const DscrLtvLimit = ({ data, isBPI }: { data: LtvLimit; isBPI: boolean }) => {
  const { occupancy, ltvs, bigDscrLtvs } = data
  const bpiOccupancy = 'Non Owner Occupied'

  return (
    <div>
      <div className="w-full overflow-auto mb-6 shadow-md rounded">
        {bigDscrLtvs && (
          <div className="w-full overflow-auto mb-6 shadow-md rounded mt-4">
            <table className="w-full text-sm text-gray-900 dark:text-gray-400 pl-6 text-center">
              <thead className="text-[14px] text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    colSpan={2 + bigDscrLtvs.length * 4}
                    className="p-3 text-base border max-w-[33%]"
                  >{`DSCR >= 1`}</th>
                </tr>
                <tr>
                  <th className="p-3 border max-w-[33%]" colSpan={2}>
                    {bpiOccupancy}
                  </th>
                  {bigDscrLtvs.map((ltv, index) => (
                    <th className="p-3 border max-w-[33%]" colSpan={4} key={index}>
                      {ltv.title}
                    </th>
                  ))}
                </tr>
                <tr>
                  <th className="p-3 border">Loan Amount</th>
                  <th className="p-3 border">FICO</th>
                  <th className="p-3 border">Reserves</th>
                  {bigDscrLtvs.map((ltv, index) => (
                    <React.Fragment key={index}>
                      {index != 0 && <th className="p-3 border">Reserves</th>}
                      <th className="p-3 border">Purchase</th>
                      <th className="p-3 border">No-Cashout</th>
                      <th className="p-3 border">Cashout</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody className="text-[14.5px] text-gray-900">
                {bigDscrLtvs[0].data.map((ltvData, loanAmountIndex) =>
                  ltvData.ficos.map((fico, ficoIndex) => (
                    <tr
                      key={`${loanAmountIndex}-${ficoIndex}`}
                      className={`${loanAmountIndex % 2 ? 'bg-gray-50' : ''}`}
                    >
                      {ficoIndex == 0 && (
                        <td className="p-2 border" rowSpan={ltvData.ficos.length}>
                          $ {thousandSeperator(ltvData.loanAmount.from)} - {thousandSeperator(ltvData.loanAmount.to)}
                        </td>
                      )}
                      <td className="p-1 border">{fico || 'NaN'}</td>

                      {bigDscrLtvs.map(({ data: ltv }, ltvIndex) => (
                        <React.Fragment key={`${loanAmountIndex}-${ficoIndex}-${ltvIndex}`}>
                          {ficoIndex == 0 && (
                            <td className="p-2 border" rowSpan={ltvData.ficos.length}>
                              {ltv[loanAmountIndex].monthsReserve}Mo
                            </td>
                          )}
                          <td className="p-1 border">{ltv[loanAmountIndex].purchaseRT[ficoIndex] || 'NaN'}</td>
                          <td className="p-1 border">{ltv[loanAmountIndex].nocashout[ficoIndex] || 'NaN'}</td>
                          <td className="p-1 border">{ltv[loanAmountIndex].cashout[ficoIndex] || 'NaN'}</td>
                        </React.Fragment>
                      ))}
                    </tr>
                  )),
                )}
              </tbody>
            </table>
          </div>
        )}

        <table className="w-full text-sm text-gray-900 dark:text-gray-400 pl-6 text-center">
          <thead className="text-[14px] text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            {bigDscrLtvs && (
              <tr>
                <th colSpan={2 + data.ltvs.length * 4} className="p-3 text-base border max-w-[33%]">{`DSCR < 1`}</th>
              </tr>
            )}
            <tr>
              <th className="p-3 border max-w-[33%]" colSpan={2}>
                {isBPI ? bpiOccupancy : occupancy}
              </th>
              {ltvs.map((ltv, index) => (
                <th className="p-3 border max-w-[33%]" colSpan={4} key={index}>
                  {ltv.title}
                </th>
              ))}
            </tr>
            <tr>
              <th className="p-3 border">Loan Amount</th>
              <th className="p-3 border">FICO</th>
              <th className="p-3 border">Reserves</th>
              {ltvs.map((ltv, index) => (
                <React.Fragment key={index}>
                  {index != 0 && <th className="p-3 border">Reserves</th>}
                  <th className="p-3 border">Purchase</th>
                  <th className="p-3 border">No-Cashout</th>
                  <th className="p-3 border">Cashout</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody className="text-[14.5px] text-gray-900">
            {ltvs[0].data.map((ltvData, loanAmountIndex) =>
              ltvData.ficos.map((fico, ficoIndex) => (
                <tr key={`${loanAmountIndex}-${ficoIndex}`} className={`${loanAmountIndex % 2 ? 'bg-gray-50' : ''}`}>
                  {ficoIndex == 0 && (
                    <td className="p-2 border" rowSpan={ltvData.ficos.length}>
                      $ {thousandSeperator(ltvData.loanAmount.from)} - {thousandSeperator(ltvData.loanAmount.to)}
                    </td>
                  )}
                  <td className="p-1 border">{fico || 'NaN'}</td>

                  {ltvs.map(({ data: ltv }, ltvIndex) => (
                    <React.Fragment key={`${loanAmountIndex}-${ficoIndex}-${ltvIndex}`}>
                      {ficoIndex == 0 && (
                        <td className="p-2 border" rowSpan={ltvData.ficos.length}>
                          {ltv[loanAmountIndex].monthsReserve}Mo
                        </td>
                      )}
                      <td className="p-1 border">{ltv[loanAmountIndex].purchaseRT[ficoIndex] || 'NaN'}</td>
                      <td className="p-1 border">{ltv[loanAmountIndex].nocashout[ficoIndex] || 'NaN'}</td>
                      <td className="p-1 border">{ltv[loanAmountIndex].cashout[ficoIndex] || 'NaN'}</td>
                    </React.Fragment>
                  ))}
                </tr>
              )),
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
