import { InputSelect, InputType, loanOptionsConstants } from 'config'

export const HMDAOptions: any = {
  propertyOwn: {
    1: 'PR Principal Residence',
    2: 'SH Second Home',
    3: 'IP Investment Property',
    4: 'SR FHA Secondary Residence',
  },
  holdTitle: {
    1: 'S Solely',
    2: 'SP Jointly w/Spouse',
    3: 'O Jointly w/Another Person',
  },
  bankruptcyType: {
    1: 'Chapter 7',
    2: 'Chapter 11',
    4: 'Chapter 12',
    8: 'Chapter 13',
  },
}

export const defaultInputs = (): {
  inputs: Record<string, InputType>
  group1: string[]
  group2: string[]
} => {
  const inputs1: Record<string, InputType> = {
    group1: {
      inputType: 'Section',
      title: 'About this Property and Your Money for this Loan',
    },
    isPrimaryResidence: {
      inputType: 'ToggleButton',
      title: 'A. Will you occupy as your primary residence?',
    },
    isHadOwnershipInterest: {
      inputType: 'ToggleButton',
      title: 'If YES, have you had an ownership interest in another property in the last three years?',
      className: 'pl-8',
    },
    propertyOwn: {
      inputType: 'Select',
      title: '(1) What type of property did you own?',
      hasDefaultOption: true,
      options: HMDAOptions.propertyOwn,
      className: 'pl-14 pr-5',
    },
    holdTitle: {
      inputType: 'Select',
      title: '(2) How did you hold title to the property?',
      hasDefaultOption: true,
      options: HMDAOptions.holdTitle,
      className: 'pl-14 pr-5',
    },
    isPurchaseTransaction: {
      inputType: 'ToggleButton',
      title:
        'B. If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?',
    },
    isBorrowingMoney: {
      inputType: 'ToggleButton',
      title:
        'C. Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?',
    },
    borrowingMoneyAmount: {
      inputType: 'Text',
      type: 'thousandSep',
      title: 'If YES, what is the amount of this money?',
      className: 'pl-8 pr-5',
      prefix: '$',
    },
    isMortageLoan: {
      inputType: 'ToggleButton',
      title:
        'D. 1. Have you or will you be applying for a mortage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?',
    },
    isNewCredit: {
      inputType: 'ToggleButton',
      title:
        '2. Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?',
      className: 'pl-8',
      required: true,
    },
    isSubjectLien: {
      inputType: 'ToggleButton',
      title:
        'E. Will this property be subject to a lien that could take priority over the first mortage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?',
    },
    group2: {
      inputType: 'Section',
      title: 'About Your Finances',
    },
    isCoSigner: {
      inputType: 'ToggleButton',
      title: 'F. Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?',
    },
    isJudgements: {
      inputType: 'ToggleButton',
      title: 'G. Are there any outstanding judgments against you?',
    },
    isDelinquent: {
      inputType: 'ToggleButton',
      title: 'H. Are you currently delinquent or in default on any Federal debt?',
    },
    isLawsuit: {
      inputType: 'ToggleButton',
      title: 'I. Are you a party to a lawsuit in which you potentially have any personal financial liability?',
    },
    isForeclosure: {
      inputType: 'ToggleButton',
      title: 'J. Have you conveyed title or any property in lieu of foreclosure in the past 7 years?',
    },
    isPreForeclosure: {
      inputType: 'ToggleButton',
      title: 'K. Within the past 7 years, have you completed a pre-foreclosure sale or short sale?',
    },
    isForeclosed: {
      inputType: 'ToggleButton',
      title: 'L. Have you had property foreclosed upon in the last 7 years?',
    },
    isBankrupt: {
      inputType: 'ToggleButton',
      title: 'M. Have you declared bankruptcy within the past 7 years?',
    },
    bankruptcyType: {
      inputType: 'Select',
      title: 'If YES, identify the type(s) of bankruptcy:',
      hasDefaultOption: true,
      options: HMDAOptions.bankruptcyType,
      className: 'pl-8 pr-5',
    },
  }
  Object.keys(inputs1).forEach((key) => {
    const input = inputs1[key]
    if (input.inputType === 'ToggleButton') input.textLeft = true
    if (!input.className) input.required = true
    // inputs1[key].title = `${index + 1}. ${inputs1[key].title}`
  })

  const inputs2: Record<string, InputSelect> = {
    isBorrower: {
      inputType: 'select',
      title: 'Borrower',
      options: {
        Yes: 'I wish to furnish this information',
        No: 'I do not wish to furnish this information',
      },
      span: 1,
    },
    informationProvidedVia: {
      title: 'Information Provided Via',
      inputType: 'select',
      value: '',
      options: {
        1: 'Face To Face',
        2: 'Telephone',
        3: 'Fax Or Mail',
        4: 'Email Or Internet',
      },
      length: 1,
      span: 1,
    },
    sex: {
      inputType: 'select',
      title: 'Sex',
      options: ['Male', 'Female'],
    },
    sexCompletedBy: {
      inputType: 'select',
      title: 'Sex Completed by',
      options: loanOptionsConstants.hmda,
    },
    ethnicity: {
      inputType: 'select',
      title: 'Ethnicity',
      options: ['Hispanic or Latino', 'Not Hispanic or Latino'],
    },
    ethnicityCompletedBy: {
      title: 'Ethnicity Completed by',
      inputType: 'select',
      options: loanOptionsConstants.hmda,
    },
    race: {
      inputType: 'select',
      title: 'Race',
      options: [
        'American Indian or Alaska Native',
        'Native Hawaiian or other Pacific Islander',
        'Asian',
        'White',
        'Black or African American',
      ],
    },
    raceCompletedBy: {
      title: 'Race Completed by',
      inputType: 'select',
      options: loanOptionsConstants.hmda,
    },
  }
  Object.keys(inputs2).forEach((key) => {
    inputs2[key].hasDefaultOption = true
    inputs2[key].required = true
  })

  return {
    inputs: {
      ...inputs1,
      ...inputs2,
    },
    group1: Object.keys(inputs1),
    group2: Object.keys(inputs2),
  }
}
