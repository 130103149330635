import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { svgLoading } from 'stories/assets'
import { Button, Input2, Pagination } from 'stories/components'
import {
  confirm,
  formatDate,
  getPrice3decimal,
  InputConvert,
  InputValidate,
  multiSelectRender,
  renderHeader,
} from 'utils'
import { RenderInput } from 'utils/RenderInput'

interface InputProps {
  loading: boolean
  title: string
  filterInputs: any
  filterValue: any
  onChangeFilter: Function
  onSort: Function
  onSubmitForm: Function
  onRemove: Function
  headers: Array<any>
  tableData: Array<any>
  inputs: any
  totalCount: number
  permissions: {
    add: boolean
    edit: boolean | Function
    delete: boolean | Function
  }
  downloadLink?: string
  cols?: number
  renderAdditionalActions?: Function | null
}

export function PageTableSearch({
  loading,
  title,
  filterInputs,
  filterValue,
  onChangeFilter,
  onSort,
  onSubmitForm,
  onRemove,
  headers,
  tableData,
  inputs,
  totalCount = 0,
  permissions,
  downloadLink,
  cols = 3,
  renderAdditionalActions = null,
}: InputProps) {
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const [mode, setMode] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState<string | undefined>(undefined)

  const onAdd = () => {
    setData({})
    setMode('Add')
  }

  const onEdit = (index: number) => {
    setData(tableData[index])
    setMode('Update')
  }

  const onTrash = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure to remove this {title}?
        <br />
        <span className="text-gray-600">No: {index + 1}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    onRemove(tableData[index].id)
  }

  const onBack = () => {
    setMode('')
    setIsLoading(false)
  }

  const onChange = (key: string, value: string) => {
    let temp = cloneDeep(data)
    let eTemp = cloneDeep(errors)
    temp[key] = InputConvert(inputs[key], value)
    eTemp[key] = ''
    setData(temp)
    setErrors(eTemp)
  }

  const onSubmit = async () => {
    let eTemp = cloneDeep(errors)
    let noError = true
    let json: any = {}
    Object.keys(inputs).map((key) => {
      json[key] = data[key]
      let error = InputValidate({ ...inputs[key], value: data[key] })
      eTemp[key] = error
      if (error.length) noError = false
    })
    setErrors(eTemp)
    if (!noError) return
    setIsLoading(true)
    await onSubmitForm(json, data.id)
    setIsLoading(false)
    setMode('')
  }

  useEffect(() => {
    if (query === undefined) return
    const timeOutId = setTimeout(() => !loading && onChangeFilter('query', query), 700)
    return () => clearTimeout(timeOutId)
  }, [query])

  const hasAction = permissions.edit || permissions.delete || !!renderAdditionalActions

  return (
    <div className="PageTableSearch-container relative">
      <LayoutLoading show={loading} />
      <div className="font-semibold text-[18px] flex items-center">
        <div>
          {title} {mode ? <span>- {mode}</span> : `(${totalCount})`}
          {mode === 'Update' && <span className="ml-2">{data.id}</span>}
        </div>
        {loading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </div>
      {mode !== '' ? (
        <div className="mt-4">
          <Button link onClick={onBack}>
            <div className="flex text-shade-blue items-center">
              <ArrowLeftIcon className="w-4 h-4 mr-1" /> <p>Return to {title}</p>
            </div>
          </Button>
          <div className={`grid gap-4 md:grid-cols-${cols} grid-cols-1 mt-3`}>
            {Object.keys(inputs).map((key) => {
              let input = {
                ...inputs[key],
                value: data[key] === undefined ? inputs[key].value : data[key],
                error: errors[key],
              }
              if (input.visible === false) return null
              return (
                <div className={`input md:col-span-${input.span || 1}`} key={key}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>
          <div className="flex justify-center gap-4 mt-4">
            <Button onClick={onSubmit} className="min-w-[120px] py-1" loading={isLoading}>
              {mode}
            </Button>
            <Button onClick={onBack} className="py-1" color="red">
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-4 flex items-center flex-wrap justify-between">
            <div className="filters flex flex-wrap items-center gap-3">
              <div className="min-w-[275px]">
                <Input2
                  type="search"
                  title="Search"
                  hasIcon
                  icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
                  value={query}
                  onChange={(value) => setQuery(value)}
                />
              </div>
              {Object.keys(filterInputs).map((key, index) => {
                const input = {
                  ...filterInputs[key],
                  value: filterValue[key],
                }
                return (
                  <div className="min-w-[275px]" key={index}>
                    <RenderInput input={input} Key={key} onChange={onChangeFilter} />
                  </div>
                )
              })}
            </div>
            {!!permissions.add && (
              <div className="w-32 md:ml-5">
                <Button full onClick={onAdd}>
                  Add
                </Button>
              </div>
            )}
          </div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-3 py-3">
                    No
                  </th>
                  {headers.map(({ title, key }, index) =>
                    renderHeader({
                      title,
                      sortable: true,
                      key,
                      sortOrder: filterValue.orderBy == key ? parseInt(filterValue.orderDir) : 0,
                      index,
                      onSort: (key: string, dir: number) => onSort(key, dir),
                    }),
                  )}
                  {hasAction && (
                    <th scope="col" className="px-3">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row: any, index: number) => {
                  return (
                    <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                      <td scope="row" className="px-2 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {index + 1}
                      </td>
                      {headers.map(({ key, value }, index) => {
                        let rlt = value ? value(row) : row[key]
                        if (inputs[key]?.type === 'thousandSep' && rlt !== undefined) rlt = getPrice3decimal(rlt)
                        if (inputs[key]?.type === 'date' && rlt !== undefined) rlt = formatDate(rlt)
                        if (inputs[key]?.inputType === 'multiselect' && rlt !== undefined) rlt = multiSelectRender(rlt)
                        if (typeof rlt == 'boolean') rlt = rlt ? 'Yes' : 'No'

                        return (
                          <td className="px-2 py-2" key={index}>
                            {typeof rlt === 'object' ? rlt : <span dangerouslySetInnerHTML={{ __html: rlt }} />}
                          </td>
                        )
                      })}
                      {hasAction && (
                        <td className="px-2">
                          <div className="flex">
                            {(typeof permissions.edit == 'boolean' ? permissions.edit : permissions.edit(row)) && (
                              <span
                                className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onEdit(index)}
                              >
                                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                              </span>
                            )}
                            {(typeof permissions.delete == 'boolean'
                              ? permissions.delete
                              : permissions.delete(row)) && (
                              <span
                                className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onTrash(index)}
                              >
                                <TrashIcon className="w-4 h-4"></TrashIcon>
                              </span>
                            )}
                            {renderAdditionalActions ? renderAdditionalActions(row, index) : null}
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="flex justify-end items-center mt-3 mb-3">
              <Pagination
                totalCount={totalCount}
                itemCountPerPage={10}
                onNavigate={(num) => onChangeFilter('pageNum', num)}
                pageNum={filterValue.pageNum}
              />
              {downloadLink && (
                <a className="my-2 mr-5 px-2 py-1 rounded hover-shadow1" href={downloadLink} target="_blank">
                  <ArrowDownTrayIcon className="h-5 w-5 text-blue-500" />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
