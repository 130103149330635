import { ArrowDownTrayIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { COMPANY_NAME_FC, pullCreditReportLimitDays } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  downloadFile,
  downloadS3Documents,
  getCreditAuthorizationPdf,
  openS3Document,
  pullCreditReportForAdditionalMember,
  sendMemberSignatureRequest,
  uploadFiles,
} from 'services'
import Api from 'services/api'
import { Button } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { dayToMSec, formatTime, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { ConfirmCreditReportDialog } from '../CreditScore/ConfirmCreditReportDialog'
import { CreditRequestType, defaultInputs } from '../CreditScore/constants'
import type { BorrowerAdditionalMember } from './types'

export const MemberDetail = ({
  data,
  onChange: onChangeData,
}: {
  data: BorrowerAdditionalMember
  onChange: Function
}) => {
  const [inputs, setInputs] = useState(defaultInputs())
  const { data: permissionData, hasPermission } = usePermissions()
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('')
  const [isConfirmReportDialog, setConfirmReportDialog] = useState(false)
  const [fieldValueChanged, setFieldValueChanged] = useState(false)

  const loanNumber = setLoanNumber()
  const auth = useSelector((state: any) => state.auth)

  const creditScoreHistoryData = useMemo(() => {
    if (!data.creditScoreHistory) data.creditScoreHistory = []

    return data.creditScoreHistory.map((item, index) => {
      const { fullName, ssn, presentAddress, emailBy, comment, createdAt } = item
      return [
        `${index + 1}`,
        fullName,
        ssn,
        presentAddress,
        comment,
        <div>
          <p>{emailBy}</p>
          <p>{formatTime(createdAt)}</p>
        </div>,
      ]
    })
  }, [data])

  const creditRequestData = useMemo(
    () => [
      data.equifax,
      data.experian,
      data.transunion,
      data.creditFileKey ? (
        <button
          className="text-gray-700 cursor-pointer flex items-center hover:underline"
          onClick={() => onOpenDocument()}
        >
          <span className="flex items-center flex-wrap gap-1 text-shade-blue">
            <span className="cursor-pointer hover:underline text-[14px]">Score PDF</span>
            <ArrowDownTrayIcon className="w-5 h-5" />
          </span>
        </button>
      ) : (
        ''
      ),
    ],
    [data],
  )

  const confirmReportDlgData = useMemo(() => {
    return ['', `${data.firstName} ${data.lastName}`, data.ssn, data.presentAddress]
  }, [data])

  const validations = useMemo(() => {
    const validations: string[] = []

    if (hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE') || !data.creditScoreHistory?.length)
      return validations

    let lastPullScoreDate = null
    data.creditScoreHistory.map((item: any) => {
      if (item.comment === 'Pull Credit Report') lastPullScoreDate = item.createdAt
    })

    if (lastPullScoreDate) {
      const timeDiff = new Date().getTime() - new Date(lastPullScoreDate).getTime()
      const limit = dayToMSec(pullCreditReportLimitDays)
      if (timeDiff < limit)
        validations.push(
          `- You are eligible to request a new credit report ${pullCreditReportLimitDays} days after your last request.`,
        )
    }

    return validations
  }, [data, permissionData])

  const onChangeFile = (key: string, file: File) => {
    if (!file) return

    const loanNumber = setLoanNumber()

    const uploadData = {
      path: `loan/${loanNumber}/Entity_CreditScore_${data.id}`,
      type: 'EntityCreditScorePDF',
      fullName: `${data.firstName} ${data.lastName}`,
      loanNumber: Api.getLoanNumber(),
      email: auth.profile.email,
    }

    setLoading(true)
    uploadFiles(uploadData, [file]).then(async (keys: Array<string>) => {
      let newInputs = cloneDeep(inputs)
      const creditFileKey = keys[0]

      updateData({
        creditFileKey,
      })

      const error = InputValidate({ ...inputs[key], creditFileKey })
      newInputs[key].error = error
      setInputs(newInputs)
      setLoading(false)
    })
  }

  const onChange = async (key: string, value: any) => {
    if (key === 'creditRequestType') {
      updateData({
        experian: '',
        transunion: '',
        equifax: '',
        creditFileKey: '',
        creditRequestType: value,
      })
      return
    }
    let newInputs = cloneDeep(inputs)
    setFieldValueChanged(true)
    value = InputConvert(newInputs[key], value)
    ;(data as any)[key] = value
    const error = InputValidate({ ...inputs[key], value })
    newInputs[key].error = error
    setInputs(newInputs)
  }

  const onBlur = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = inputs[key]
    if (input.error && input.error.length > 0) return

    updateData({
      [key]: inputs[key].value,
    })
  }

  const updateData = async (subData: Record<string, any>, updateToServer = true) => {
    if (Object.keys(subData).length == 0) return
    setLoading(true)
    const newData = cloneDeep(data)
    await onChangeData(
      {
        ...newData,
        ...subData,
      },
      updateToServer,
    )
    setLoading(false)
  }

  const onPullReport = () => {
    setConfirmReportDialog(true)
  }

  const onConfirmPullReport = (method: string, callback: Function) => {
    setAction('onPullReport')
    pullCreditReportForAdditionalMember(method, data.id)
      .then(async ({ success, pullCreditScoreLevel, data: creditData }) => {
        if (!success && pullCreditScoreLevel !== undefined) return callback(pullCreditScoreLevel)
        if (!creditData) return callback(false)
        let newInputs = cloneDeep(inputs)
        Object.keys(creditData).forEach((key) => {
          if (!inputs[key]) return
          const error = InputValidate({ ...inputs[key], value: creditData[key] })
          newInputs[key].error = error
        })
        setInputs(newInputs)

        await updateData(creditData, false)
        callback(true)
      })
      .catch(() => callback(false))
      .finally(async () => {
        setLoading(false)
        setAction('')
      })
  }

  const renderRequestType = () => {
    const input = inputs.creditRequestType
    if (data.creditRequestType !== undefined) {
      input.value = data.creditRequestType
    } else {
      input.value = ''
    }
    input.disabled = !canEdit
    return (
      <div className="input md:col-span-1 mb-4">
        <RenderInput input={input} Key={'creditRequestType'} onChange={onChange} onBlur={onBlur} />
      </div>
    )
  }

  const onOpenDocument = async () => {
    const { creditFileKey } = data
    if (!creditFileKey) return
    downloadS3Documents(creditFileKey).then((res) => {
      var windowReference: any = window.open()
      windowReference.location = res.url
    })
  }

  const viewApplicationPDF = async () => {
    const rlt: Blob = await getCreditAuthorizationPdf(data.id)
    downloadFile(
      `${COMPANY_NAME_FC} Credit Authorization Form (${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`,
      rlt,
    )
  }

  const onSendSignatureRequest = () => {
    setLoading(true)
    setAction('sendingSignRequest')
    sendMemberSignatureRequest(data.id)
      .then(async ({ data }) => {
        await updateData(data, false)
      })
      .finally(() => {
        setLoading(false)
        setAction('')
      })
  }

  const renderCreditInformation = () => {
    return (
      <div className="mb-4 p-4 rounded shadow border">
        <p className="text-md font-bold border-b mb-2">Credit Information</p>

        <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
          {(() => {
            const input = inputs.creditFileKey
            if (data.creditFileKey !== undefined) {
              input.value = data.creditFileKey
            } else {
              input.value = ''
            }
            input.history = true
            input.disabled = !canEdit
            return (
              <div className="input md:col-span-2">
                <RenderInput input={input} Key={'creditFileKey'} onChange={onChangeFile} onBlur={onBlur} />
              </div>
            )
          })()}

          {data.creditFileKey &&
            ['equifax', 'experian', 'transunion'].map((key: string) => {
              const input = inputs[key]
              if ((data as any)[key] !== undefined) {
                input.value = (data as any)[key]
              } else {
                input.value = ''
              }
              input.history = true
              input.disabled = !canEdit
              return (
                <div className={`input md:col-span-${input.span || 1}`} key={key}>
                  <RenderInput input={input} Key={key} onChange={onChange} onBlur={onBlur} />
                </div>
              )
            })}
        </div>
      </div>
    )
  }

  const canEdit = true // canEditLoanApplication()

  return (
    <div className="p-4 border">
      <LayoutLoading show={loading} />

      <div className="p-4 pb-0 rounded shadow border mb-4">
        <p className="text-md font-bold border-b mb-4">Sign Application</p>
        {!data.signedPdfKey ? (
          <>
            <Button color={'gray'} onClick={viewApplicationPDF}>
              <div className="flex gap-2 items-center">
                View Unsigned Credit Form PDF{' '}
                <span className="">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </span>
              </div>
            </Button>
            <Button onClick={onSendSignatureRequest} loading={action === 'sendingSignRequest'}>
              <div className="flex gap-2 items-center">
                Send Signature Request
                <PaperAirplaneIcon className="w-4 h-4"></PaperAirplaneIcon>
              </div>
            </Button>

            {data.signUrlSignature && (
              <div
                className="bg-yellow-100 border mt-2 border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4 text-[14px]"
                role="alert"
              >
                <div className="font-variation-settings-600">
                  Sign request has been sent by <span className="underline">{data.signRequestedBy}</span> on{' '}
                  {formatTime(data.signRequestedAt)} and <span className="font-bold">Pending</span> now.
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <Button link onClick={() => openS3Document(data.signedPdfKey!)}>
              <div className="flex items-center underline text-gray-900 hover:text-shade-blue">
                View Signed Credit Form PDF{' '}
                <span className="ml-2">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </span>
              </div>
            </Button>

            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-[14px]"
              role="alert"
            >
              <div className="font-variation-settings-600">
                Sign request has been sent by <span className="underline">{data.signRequestedBy}</span> and signed by{' '}
                <span className="underline">{data.signedBy}</span> at {formatTime(data.signedDate)}.
              </div>
            </div>
          </>
        )}
      </div>

      {data.signedPdfKey && (
        <div className="p-4 pb-0 rounded shadow border">
          {data.creditFileKey && (
            <>
              <p className="text-md font-bold border-b mb-2">Credit Score</p>

              <PlainTable
                header={['Equifax Score', 'Experian Score', 'Trans Union Score', 'Pdf']}
                data={[creditRequestData]}
              />
            </>
          )}

          <p className="text-md font-bold border-b mb-2">Credit Request Type</p>
          {renderRequestType()}

          {data.creditRequestType == CreditRequestType.Universal && (
            <Button
              className="-mb-4"
              disabled={validations.length !== 0 || canEdit !== true}
              onClick={onPullReport}
              loading={action === 'onPullReport'}
            >
              Pull My Credit Report
            </Button>
          )}

          {data.creditRequestType == CreditRequestType.OnHand && renderCreditInformation()}

          {!!data.creditScoreHistory?.length && (
            <>
              <p className="text-md font-bold border-b mb-2">Credit Report Request History</p>
              <PlainTable
                header={['No', 'Name', 'SSN', 'Present Address', 'Comment', 'By / Created At']}
                data={creditScoreHistoryData}
                thClass="!px-2 py-3"
                tdClass={'px-2 py-4'}
              />
            </>
          )}
        </div>
      )}

      {isConfirmReportDialog && (
        <ConfirmCreditReportDialog
          refId={`${loanNumber}-additional-${data.id}`}
          onClose={() => setConfirmReportDialog(false)}
          onConfirm={onConfirmPullReport}
          data={confirmReportDlgData}
          paymentData={{
            userType: 'additional',
            memberId: data.id,
            name: `${data.firstName} ${data.lastName}`,
            ssn: data.ssn,
            address: data.presentAddress,
          }}
          showAlert={!hasPermission('ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE')}
        />
      )}
    </div>
  )
}
