import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { appApiUrl } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  deleteAccounting,
  getAccounting,
  getAccountingAccount,
  getAccountingCategories,
  getAccountingRelations,
  getBaseAccountingCategories,
  submitAccounting,
} from 'services'
import { svgLoading, svgSearch } from 'stories/assets'
import { Button, ButtonGroup, Input2, Select2, Toggle, ToggleButton } from 'stories/components'
import { confirm, getYearOptions, removeDuplicates } from 'utils'

import type { IAccountingCategory } from '../AccountingCategories'
import { AccountingFilesContent } from '../AccountingFiles'
import type { IAccountingFile } from '../AccountingFiles/types'
import type { IAccountingAccount } from '../Accounts/types'
import { getAccountOptions } from '../Accounts/utils'
import { AccountingTable } from './AccountingTable'
import { DownloadTypeDialog } from './DownloadTypeDialog'
import { EditAccountingContent } from './EditAccountingDialog'
import { ImportAccountingFileDialog } from './ImportAccountingFileDialog'
import {
  type IAccounting,
  AccountingRecordTypeOptions,
  BaseIdForBalanceTable,
  IAccountingCountByCategory,
} from './types'
import { onAccountingHistory } from './util'

export const AccountingRecords = ({ type, onOpenDocs }: { type: string; onOpenDocs: Function }) => {
  const auth = useSelector((state: any) => state.auth)
  const [isLoading, setLoading] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const [filters, setFilters] = useState<Record<string, any>>({
    type,
    year: 'All',
    query: '',
    account: '',
    from: '',
    to: '',
    showDeleted: false,
    actived: true,
    payoff: false,
  })
  const [baseCategory, setBaseCategory] = useState<Record<number, string>>({})
  const [category, setCategory] = useState<IAccountingCategory[]>([])
  const [accounts, setAccounts] = useState<IAccountingAccount[]>([])
  const [data, setData] = useState<IAccounting[]>([])
  const [categoryProps, setCategoryProps] = useState<Record<number, Record<number, { count: number; amount: number }>>>(
    {},
  )
  const [importingCategory, setImportingCategory] = useState<IAccountingCategory | null>()
  const [orders, setOrders] = useState<Record<string, { by: string; dir: number }>>({})
  const [curEditItem, setCurEditItem] = useState<IAccounting | null>()
  const [loadingStatus, setLoadingStatus] = useState<Record<string, boolean>>({})
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [isDownloadTypeDialog, showDownloadTypeDialog] = useState(false)

  const isBalance = type == 'Balance'

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onUpdateFilter('account', filters.account), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  useEffect(() => {
    fetch()
  }, [])

  const filterAccountOptions = useMemo(() => {
    return getAccountOptions(accounts)
  }, [accounts])

  const downloadLink = useMemo(() => {
    const filterStr = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&')
    return `${appApiUrl}/admin/accounting/data/download?token=${auth.token}&${filterStr}`
  }, [auth.token, filters])

  const fetch = async (showReload = true) => {
    showReload && setLoading(true)
    const [baseCategory, category, { data, counts }, accounts] = await Promise.all([
      getBaseAccountingCategories(type),
      getAccountingCategories(type),
      getAccounting(filters),
      getAccountingAccount(),
    ])
    setBaseCategory(baseCategory)
    setCategory(category)
    setData(data)
    setAccounts(accounts)

    updateCounts(counts, {})

    showReload && setLoading(false)

    resetOrders(baseCategory)
    setIsGetUsersOnce(true)
  }

  // const loadIndividualCategory = async (categoryId: number) => {}

  const resetOrders = (baseCategory: Record<number, string>) => {
    const newOrders = cloneDeep(orders)
    Object.keys(baseCategory).forEach((baseId) => {
      newOrders[baseId] = { by: 'date', dir: -1 }
    })
    setOrders(newOrders)
  }

  const updateCounts = (counts: IAccountingCountByCategory[], originalCategoryProps: any = null) => {
    const newCounts: Record<number, Record<number, any>> = cloneDeep(originalCategoryProps || categoryProps)
    counts.forEach((v) => {
      if (!newCounts[v.category]) newCounts[v.category] = {}
      newCounts[v.category][v.subCategory] = {
        count: Number(v.count),
        amount: Number(v.amount),
      }
    })
    Object.keys(newCounts).forEach((category) => {
      const newCountForCategory = newCounts[Number(category)]
      const totalCount = Object.keys(newCountForCategory).reduce(
        (prev, key) => prev + (key == '-1' ? 0 : newCountForCategory[Number(key)].count),
        0,
      )
      const totalAmount = Object.keys(newCountForCategory).reduce(
        (prev, key) => prev + (key == '-1' ? 0 : newCountForCategory[Number(key)].amount),
        0,
      )
      newCounts[Number(category)][-1] = {
        count: totalCount,
        amount: totalAmount,
      }
    })
    setCategoryProps(newCounts)
  }

  const loadByCategoryIds = async (categoryIds: number[], _newData: IAccounting[]) => {
    categoryIds = removeDuplicates(categoryIds as any).map((v) => Number(v))
    const dataByCategory: { data: IAccounting[]; counts: IAccountingCountByCategory[] }[] = await Promise.all(
      categoryIds.map((categoryId) => {
        const baseId = category.find((v) => v.id == categoryId)?.baseId
        return getAccounting({
          ...filters,
          categoryIds: categoryId,
          orderBy: orders[baseId!].by,
          orderDir: orders[baseId!].dir,
          count: Math.max(
            _newData.filter((v) => (v.category == categoryId && v.subCategory == 0) || v.subCategory == categoryId)
              .length,
            10,
          ),
          skip: 0,
        })
      }),
    )

    let newData = cloneDeep(_newData)
    const newCounts: IAccountingCountByCategory[] = []
    newData = newData.filter((v) => !categoryIds.includes(v.category) && !categoryIds.includes(v.subCategory))
    dataByCategory.forEach(({ data, counts }) => {
      data.forEach((item) => !newData.find((v) => v.id == item.id) && newData.push(item))
      newCounts.push(...counts)
    })
    setData(newData)
    updateCounts(newCounts)
  }

  const onSort = async (baseId: string, key: string, order: number) => {
    const newOrders = cloneDeep(orders)
    newOrders[baseId] = { by: key, dir: order }
    setOrders(newOrders)

    const categoryIds = category.filter((v) => v.baseId == Number(baseId)).map((v) => v.id)
    const strCategoryIds = categoryIds.join(',')

    setLoading(true)
    const { data: newData, counts } = await getAccounting({
      ...filters,
      categoryIds: strCategoryIds,
      orderBy: key,
      orderDir: order,
    })
    updateCounts(counts)

    const orgData = data.filter((v) => !categoryIds.includes(v.category))
    setData([...orgData, ...newData])
    setLoading(false)
  }

  const onUpdateFilter = async (key: string, value: string) => {
    const newFilter = cloneDeep(filters)
    newFilter[key] = value
    if (key == 'year') {
      if (value == 'All') {
        newFilter.from = ''
        newFilter.to = ''
      } else {
        if (!isBalance) newFilter.from = `${value}-01-01`
        newFilter.to = `${value}-12-31`
      }
    }
    setFilters(newFilter)
    if (key === 'query') {
      setFilterQuery(value)
      return
    }

    setLoading(true)
    const { data, counts } = await getAccounting(newFilter)
    updateCounts(counts, {})
    setData(data)
    setLoading(false)
    resetOrders(baseCategory)
  }

  const onSubmitCreating = async (importedFile: IAccountingFile | null, addedData: IAccounting[]) => {
    if (!addedData.length || !importedFile) {
      setImportingCategory(undefined)
      return
    }

    await submitAccounting(importedFile, addedData)
    setImportingCategory(undefined)
    await loadByCategoryIds(
      addedData.map((v) => v.subCategory || v.category),
      data,
    )
  }

  const onEdit = (item: IAccounting) => {
    if (curEditItem) {
      toast(
        `Kindly ensure that the current editing record #${curEditItem.id} is submitted or closed before proceeding further.`,
        {
          type: 'warning',
        },
      )
      return
    }
    setCurEditItem(item)
  }

  const onLoadMore = async (categoryId: number, subCategoryId: number, skip: number) => {
    updateLoadingStatus(categoryId, subCategoryId, true)
    const foundCategory = category.find((v) => v.id == categoryId)
    if (!foundCategory) return

    const order = orders[foundCategory.baseId]
    const { data: newData } = await getAccounting({
      ...filters,
      categoryIds: [categoryId].join(','),
      subCategoryId,
      skip,
      orderBy: order.by,
      orderDir: order.dir,
    })
    setData([...data, ...newData])
    updateLoadingStatus(categoryId, subCategoryId, false)
  }

  const updateLoadingStatus = (categoryId: number, subCategoryId: number, status: boolean) => {
    const newStatus = cloneDeep(loadingStatus)
    newStatus[`${categoryId}-${subCategoryId}`] = status
    setLoadingStatus(newStatus)
  }

  const onUpdateEditItem = async (item: IAccounting | null) => {
    setCurEditItem(undefined)

    if (!item) return

    const newData = cloneDeep(data)

    const index = data.findIndex((v) => v.id == item.id)
    const requireBalanceUpdate = isBalance && item.baseId && BaseIdForBalanceTable.includes(item.baseId)
    if (index == -1) {
      // newData.push(item)
    } else {
      // newData[index] = item
      newData.splice(index, 1)
    }

    setLoading(true)
    const oldCategoryIds = data.filter((v) => v.id == item.id).map((v) => v.subCategory || v.category)
    const relatedItems: IAccounting[] = requireBalanceUpdate ? await getAccountingRelations(item.id) : []
    const relatedItemIds = relatedItems.map((v) => v.id)
    const newCategoryIds = relatedItems.map((v) => v.subCategory || v.category)
    const oldRelatedCategoryIds = data
      .filter((v) => relatedItemIds.includes(v.id))
      .map((v) => v.subCategory || v.category)

    await loadByCategoryIds(
      [item.subCategory || item.category, ...newCategoryIds, ...oldCategoryIds, ...oldRelatedCategoryIds],
      newData,
    )
    setLoading(false)
  }

  const onDelete = async (item: IAccounting) => {
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) return

    if (!item.deleted) {
      const content = (
        <div className="text-gray-400 mb-4 text-[18px]">
          Are you sure want to delete this item?
          <br />
          <span className="text-gray-600">
            Description: {item.description} #{item.id}
          </span>
        </div>
      )
      const result = await confirm(content)
      if (!result) return
    }

    setLoading(true)
    try {
      await deleteAccounting(item.id)

      const newValues = cloneDeep(data)
      newValues.splice(index, 1)
      setData(newValues)
      if (curEditItem && item.id == curEditItem.id) setCurEditItem(undefined)

      updateCategoryProps([item], -1)
    } catch (e) {}
    setLoading(false)
  }

  const updateCategoryProps = (data: IAccounting[], dir: 1 | -1) => {
    const newProps = cloneDeep(categoryProps)
    for (const item of data) {
      // if (!newProps[item.category]) newProps[item.category] = { amount: 0, count: 0 }
      newProps[item.category][item.subCategory].amount += item.amount * dir
      newProps[item.category][item.subCategory].count += dir
      newProps[item.category][-1].amount += item.amount * dir
      newProps[item.category][-1].count += dir
    }
    setCategoryProps(newProps)
  }

  const onDownloadByType = (type: number) => {
    showDownloadTypeDialog(false)
    window.open(`${downloadLink}&downloadType=${type == 1 && 'summary'}`, '_blank')
  }

  const ApprovedRecords = useMemo(() => {
    return (
      <>
        <div className="flex flex-wrap gap-3 items-center">
          <div className="w-60 mb-3">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={svgSearch}
              value={filters.query}
              onChange={(v) => onUpdateFilter('query', v)}
            />
          </div>
          <div className="w-60 mb-3">
            <Select2
              id="selectAccount"
              title="Select Account"
              defaultOptionText=""
              hasDefaultOption
              sort
              options={filterAccountOptions}
              value={filters.account}
              onChange={(v) => onUpdateFilter('account', v)}
            />
          </div>
          {!isBalance && (
            <div className="w-60 mb-3">
              <Input2 type="date" title="From" value={filters.from} onChange={(v) => onUpdateFilter('from', v)} />
            </div>
          )}
          <div className="w-60 mb-3">
            <Input2
              type="date"
              title={isBalance ? 'Date' : 'To'}
              value={filters.to}
              onChange={(v) => onUpdateFilter('to', v)}
            />
          </div>
          <div className="flex-1" />
        </div>

        <div className="flex gap-2 items-center mb-2">
          <ButtonGroup
            title={getYearOptions(4)}
            value={filters.year}
            onChange={(value) => onUpdateFilter('year', value)}
          />
          <div className="flex-1" />

          <span
            className="my-2 mr-5 px-2 py-2 rounded cursor-pointer hover-shadow1"
            onClick={() => showDownloadTypeDialog(true)}
          >
            <ArrowDownTrayIcon className="h-5 w-5 text-blue-500" />
          </span>
          <Button onClick={() => setCurEditItem(null)} className="">
            Add
          </Button>
          <Button onClick={() => setImportingCategory(null)} className="">
            Import
          </Button>
          <Toggle
            id="show-deleted"
            title="Trash"
            value={filters.showDeleted}
            onChange={(value) => onUpdateFilter('showDeleted', value)}
          />
          <Toggle
            id="show-paidoff"
            title="Paid Off"
            value={filters.payoff}
            onChange={(value) => onUpdateFilter('payoff', value)}
          />
        </div>

        <div className="relative overflow-auto">
          <LayoutLoading show={isLoading} />
          <AccountingTable
            type={type}
            baseCategory={baseCategory}
            category={category}
            accounts={accounts}
            data={data}
            categoryProps={categoryProps}
            loadingStatus={loadingStatus}
            filters={filters}
            orders={orders}
            curEditItem={curEditItem}
            onSort={onSort}
            onImport={(v: IAccountingCategory) => setImportingCategory(v)}
            onDelete={onDelete}
            onEdit={onEdit}
            onHistory={(v: IAccounting) => onAccountingHistory(v, category, accounts)}
            onLoadMore={onLoadMore}
            onUpdateEditItem={onUpdateEditItem}
          />

          {importingCategory !== undefined && (
            <ImportAccountingFileDialog
              type={type}
              baseCategory={baseCategory}
              category={category}
              defaultCategory={importingCategory}
              accounts={accounts}
              onClose={onSubmitCreating}
              reload={fetch}
            />
          )}

          {curEditItem === null && (
            <EditAccountingContent
              type={type}
              baseCategory={baseCategory}
              category={category}
              item={curEditItem}
              accounts={accounts}
              onClose={onUpdateEditItem}
            />
          )}

          {isDownloadTypeDialog && (
            <DownloadTypeDialog
              isBalance={isBalance}
              onSubmit={(type: number) => onDownloadByType(type)}
              onClose={() => showDownloadTypeDialog(false)}
            />
          )}
        </div>
      </>
    )
  }, [
    filters,
    orders,
    isLoading,
    data,
    categoryProps,
    loadingStatus,
    type,
    baseCategory,
    category,
    curEditItem,
    accounts,
    importingCategory,
    isDownloadTypeDialog,
  ])

  const FileRecords = useMemo(() => {
    return <AccountingFilesContent type={type} isLoading={isLoading} setLoading={setLoading} onOpenDocs={onOpenDocs} />
  }, [type, isLoading])

  return (
    <div>
      <div className="flex items-center gap-4 mb-5">
        <h1 className="text-2xl font-bold flex items-center">{AccountingRecordTypeOptions[type]}</h1>
        <ToggleButton
          id="activedFilter"
          label={['Approved', 'Pending']}
          btnClassName="!w-24"
          value={filters.actived}
          onChange={(value) => onUpdateFilter('actived', value)}
        />
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </div>

      {filters.actived ? ApprovedRecords : FileRecords}
    </div>
  )
}
