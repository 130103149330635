export enum BorrowerStatus {
  Active = 1,
  Pending = 0,
  Inactive = -1,
}

export interface IBorrower {
  id: number
  no: number
  fullName: string
  presentAddress: string
  orgPassword: string
  email: string
  phone: string
  requireReset: boolean
  status: BorrowerStatus
  createdAt: Date
  documents: Array<Record<string, any>>
  entities: Array<string>
}
