import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { getZillowRouteByZipCode } from 'services'
import { getItemsFromFullAddress } from 'utils'

import { LayoutLoading } from './LayoutLoading'

export const MedianPriceLink = ({ address }: { address: string }) => {
  const [isLoading, setLoading] = useState(false)

  const openLink = (url: string = '') => window.open(url || 'https://www.zillow.com/home-values', '_blank')

  const onClick = () => {
    if (!address) {
      openLink()
      return
    }
    getItemsFromFullAddress(address)
      .then(async (addressObj: any) => {
        const { postal_code } = addressObj
        setLoading(true)
        const url = await getZillowRouteByZipCode(postal_code)
        setLoading(false)
        openLink(url)
      })
      .catch((e) => {
        console.log('ERROR', e)
        setLoading(false)
        openLink()
      })
  }

  return (
    <div className="flex items-center justify-end gap-1 text-shade-blue hover:underline cursor-pointer">
      <span className="font-semibold" onClick={onClick}>
        Median Price
      </span>
      <ArrowTopRightOnSquareIcon className="w-3 h-3"></ArrowTopRightOnSquareIcon>

      {isLoading && (
        <div className={`fixed inset-0 z-[999] bg-white/50`}>
          <LayoutLoading show />
        </div>
      )}
    </div>
  )
}
