import { CheckIcon, ClockIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import React, { useMemo } from 'react'
import { formatDate, renderHeader, thousandSeperator, thousandSeperatorWithPrefix } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingCategory } from '../AccountingCategories'
import type { IAccountingAccount } from '../Accounts/types'
import { getAccountOptions } from '../Accounts/utils'
import { EditAccountingContent } from './EditAccountingDialog'
import { type IAccounting, BaseIdForProfitLoss, LoansHeldForSaleCategoryName } from './types'

export const AccountingTable = ({
  type = '',
  data,
  categoryProps = {},
  loadingStatus = {},
  baseCategory,
  category,
  accounts,
  orders,
  onSort,
  onImport,
  onEdit,
  onDelete,
  onHistory,
  onLoadMore,

  curEditItem,
  onUpdateEditItem,

  onOverride,
}: {
  type?: string
  data: IAccounting[]
  categoryProps?: Record<
    number,
    Record<
      number,
      {
        count: number
        amount: number
      }
    >
  >
  loadingStatus?: Record<string, boolean>
  filters?: Record<string, any>
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  accounts: IAccountingAccount[]
  orders: Record<string, { by: string; dir: number }>

  onSort?: (baseId: string, key: string, order: number) => void
  onImport?: (category: IAccountingCategory) => void
  onEdit?: (item: IAccounting) => void
  onDelete?: (item: IAccounting) => void
  onHistory?: (item: IAccounting) => void
  onLoadMore?: (categoryId: number, subCategoryId: number, length: number) => void

  curEditItem?: IAccounting | null | undefined
  onUpdateEditItem?: (item: IAccounting | null) => void

  onOverride?: (itemId: number, override: boolean) => void
}) => {
  const accountOptions = useMemo(() => getAccountOptions(accounts), [accounts])
  const dataCategories = data.map((v) => v.category)

  let baseIndex = 0
  const forModal = !onEdit

  const renderCategoryImport = (item: IAccountingCategory, subCategory: IAccountingCategory | null) => {
    return type == 'Balance' && !forModal && onImport && item.value.toUpperCase() == LoansHeldForSaleCategoryName ? (
      <p
        className="ml-2 text-sm text-shade-blue hover:underline cursor-pointer"
        onClick={() =>
          onImport({
            ...item,
            subCategory: subCategory ? subCategory.id : 0,
          })
        }
      >
        + Import
      </p>
    ) : null
  }

  const isUniqueNoneBlock = (item: IAccountingCategory) => category.filter((v) => v.parentId == item.id).length == 0

  const renderCategoryBlock = (item: IAccountingCategory, subCategory: IAccountingCategory | null = null) => {
    const properData = data.filter(
      (v) => v.category == item.id && (!subCategory ? v.subCategory == 0 : v.subCategory == subCategory.id),
    )
    if (properData.length == 0) return null

    let seqIndex = 0
    const subCategoryId = subCategory ? subCategory.id : 0
    const categoryProp = (categoryProps[item.id] || {})[subCategoryId] || {}

    const total =
      forModal || !categoryProp
        ? properData
            .filter((v) => !forModal || !v.duplicated || v.override)
            .map((v) => Number(v.amount))
            .reduce((v, org) => v + org, 0)
        : categoryProp.amount

    return (
      <React.Fragment>
        {!isUniqueNoneBlock(item) && (
          <tr
            className="border-b dark:bg-gray-800 dark:border-gray-700"
            key={`${item.id}-${subCategory ? subCategory.id : ''}`}
          >
            <td scope="row" colSpan={6} className="px-3 py-1 font-medium dark:text-white whitespace-nowrap">
              <p className="flex items-center">
                {subCategory ? subCategory.value : 'None'} ({properData.length}
                {forModal || !categoryProp.count ? '' : `/${categoryProp.count}`})
                {renderCategoryImport(item, subCategory)}
              </p>
            </td>
            <td scope="row" className="px-3 py-0 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
              {thousandSeperatorWithPrefix(total, '$')}
            </td>
          </tr>
        )}
        {properData.map((item, index) => renderAccountingRow(item, index, ++seqIndex))}
        {!forModal && categoryProp.count && categoryProp.count > properData.length ? (
          <tr>
            <td colSpan={7} className="text-center">
              {!loadingStatus[`${item.id}-${subCategoryId}`] ? (
                <span
                  className="text-shade-blue hover:underline text-sm cursor-pointer"
                  onClick={() => onLoadMore && onLoadMore(item.id, subCategoryId, properData.length)}
                >
                  Load More ({properData.length} / {categoryProp.count})
                </span>
              ) : (
                <>
                  <div className="relative h-5">
                    <LayoutLoading show />
                  </div>
                </>
              )}
            </td>
          </tr>
        ) : null}
      </React.Fragment>
    )
  }

  const renderAccountingRow = (item: IAccounting, index: number, seqIndex: number) => {
    return (
      <React.Fragment key={`row-${item.id}`}>
        <tr
          className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
            item.duplicated && item.override !== true ? 'opacity-50' : ''
          }`}
          key={`${item.id}-${index}`}
        >
          <td scope="row" className="pl-6 pr-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
            {seqIndex}
          </td>

          <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
            {formatDate(item.date)}
          </td>

          <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white">
            {item.description}
            {!forModal ? ` #${item.id}` : ''}
          </td>

          <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
            {thousandSeperator(item.amount)}
          </td>

          <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
            {item.loanNumber}
            {type == 'Balance' && !!item.servicerLoanNumber && (
              <>
                <br />
                <div className="border-t w-fit pt-1 border-gray-300 italic">{item.servicerLoanNumber}</div>
              </>
            )}
          </td>

          <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white capitalize">
            {accountOptions[item.accountId]}
          </td>

          {forModal ? (
            <td className="px-3 py-1">
              {item.duplicated ? (
                <RenderInput
                  Key={`ignoreLoanCheck-${item.id}`}
                  input={{
                    inputType: 'checkbox',
                    title: 'Override Duplicate',
                    key: `ignoreLoanCheck-${item.id}`,
                    visible: false,
                    value: item.override,
                  }}
                  onChange={(_: string, v: boolean) => onOverride && onOverride(item.id, v)}
                />
              ) : (
                <span className="text-green-500 flex items-center gap-2">
                  <CheckIcon className="w-4 h-4" />
                  New
                </span>
              )}
            </td>
          ) : (
            <>
              {/* <td scope="row" className="px-3 py-1 font-medium text-gray-900">
              <span>
                <div className="border-b w-fit mb-1 border-gray-300">
                  {formatTime(item.createdAt)}
                </div>
                {item.createdBy}
              </span>
            </td> */}
              <td className="px-3 py-1">
                <div className="flex gap-2">
                  {onEdit && !item.deleted && (
                    <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                      <PencilSquareIcon className="w-4 h-4" />
                    </span>
                  )}

                  {onDelete &&
                    (item.deleted ? (
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                        <CheckIcon className="w-4 h-4" />
                      </span>
                    ) : (
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                        <TrashIcon className="w-4 h-4" />
                      </span>
                    ))}

                  {onHistory && (
                    <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onHistory(item)}>
                      <ClockIcon className="w-4 h-4" />
                    </span>
                  )}
                </div>
              </td>
            </>
          )}
        </tr>
        {curEditItem && curEditItem.id == item.id ? (
          <tr>
            <td></td>
            <td colSpan={6}>
              <EditAccountingContent
                type={type}
                baseCategory={baseCategory}
                category={category}
                item={curEditItem}
                accounts={accounts}
                onClose={onUpdateEditItem || (() => {})}
              />
            </td>
          </tr>
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <>
      {Object.keys(baseCategory).map((_baseId) => {
        const baseId = Number(_baseId)
        if (type == 'Record' && !BaseIdForProfitLoss.includes(baseId)) return null
        if (type == 'Balance' && BaseIdForProfitLoss.includes(baseId)) return null

        const childrenCategories = category.filter((v) => v.baseId == baseId).map((v) => v.id)

        let isValid = false
        dataCategories.forEach((v) => (isValid = isValid || childrenCategories.includes(Number(v))))
        if (forModal && !isValid) return null

        let totalAmount = 0
        const contents = category
          .filter((v) => v.baseId == Number(baseId) && v.parentId == 0)
          .map((item, index) => {
            const properData = data.filter((v) => v.category == item.id)
            if (properData.length == 0) return null

            const categoryProp = (categoryProps[item.id] || {})[-1] || {}
            const total =
              forModal || !categoryProp
                ? properData
                    .filter((v) => !forModal || !v.duplicated || v.override)
                    .map((v) => Number(v.amount))
                    .reduce((v, org) => v + org, 0)
                : categoryProp.amount
            totalAmount += total

            return (
              <React.Fragment key={`section-${_baseId}-${item.id}-${index}`}>
                <tr className="bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                  <td
                    scope="row"
                    colSpan={6}
                    className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    <p className="flex items-center">
                      - {item.value} ({properData.length}
                      {forModal || !categoryProp.count ? '' : `/${categoryProp.count}`})
                      {isUniqueNoneBlock(item) && renderCategoryImport(item, null)}
                    </p>
                  </td>
                  <td scope="row" className="px-3 py-0 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {thousandSeperatorWithPrefix(total, '$')}
                  </td>
                </tr>

                {renderCategoryBlock(item)}
                {category
                  .filter((v) => v.baseId == Number(baseId) && v.parentId == item.id)
                  .map((subCategory) => renderCategoryBlock(item, subCategory))}
              </React.Fragment>
            )
          })

        return (
          <div className="sm:rounded-lg mb-9" key={`Base-${baseId}`}>
            <div className="flex justify-between items-center text-lg mb-2 border-b bg-gray-100 py-2 px-3 border-l-4 border-gray-300">
              {++baseIndex}. {baseCategory[baseId]}
              <p className="text-sm font-semibold text-gray-900 w-32 pl-6">
                {thousandSeperatorWithPrefix(totalAmount, '$')}
              </p>
            </div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-3 py-3 w-[20px]">
                    No
                  </th>

                  {renderHeader({
                    title: 'Date',
                    index: 1,
                    key: 'date',
                    className: 'px-3 py-3',
                    sortable: !!onSort,
                    sortOrder: orders[baseId] && orders[baseId].by == 'date' ? orders[baseId].dir : 0,
                    onSort: (key: string, order: number) => onSort && onSort(_baseId, key, order),
                  })}

                  {renderHeader({
                    title: 'Description',
                    index: 1,
                    key: 'description',
                    className: 'px-3 py-3',
                    sortable: !!onSort,
                    sortOrder: orders[baseId] && orders[baseId].by == 'description' ? orders[baseId].dir : 0,
                    onSort: (key: string, order: number) => onSort && onSort(_baseId, key, order),
                  })}

                  <th scope="col" className="px-3 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Loan Number
                    {type == 'Balance' && (
                      <>
                        <br />
                        <div className="border-t w-fit pt-1 border-gray-300">Servicing Number</div>
                      </>
                    )}
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Account
                  </th>
                  {/* <th scope="col" className="px-3 py-3">
                        <span>
                          <div className="border-b w-fit mb-1 border-gray-300">Created At</div>
                          Created By
                        </span>
                      </th> */}
                  <th scope="col" className="px-3 py-3 w-32">
                    {forModal ? 'Approve' : ''}
                  </th>
                </tr>
              </thead>
              <tbody>{contents}</tbody>
            </table>
          </div>
        )
      })}
    </>
  )
}
