import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { sendClosingCalendarSignEmail } from 'services'
import { Modal } from 'stories/components'

interface IProps {
  loanNumber: string
  onClose: () => void
}

export const PartiesEmailToDialog = (props: IProps) => {
  const { loanNumber } = props
  const [notifiers, setNotifiers] = useState<EmailValue>({})
  const [loading, setLoading] = useState<boolean>(false)

  const onClose = () => {
    setNotifiers({})
    props.onClose()
  }

  const onSubmit = async () => {
    setLoading(true)
    const data = { emailTo: notifiers }
    await sendClosingCalendarSignEmail(data)
    setLoading(false)

    toast('Email with sign request is sent successfully', { type: 'info' })
    onClose()
  }

  return (
    <Modal
      title="Send E-sign verification"
      isOpen
      loading={loading}
      disabled={!Object.values(notifiers).includes('to')}
      lastUpdatedAt={Date.now()}
      onOk={onSubmit}
      onClose={onClose}
    >
      <LoanPartiesEmailTo
        loanNumber={Number(loanNumber)}
        value={notifiers}
        data={[]}
        onChange={setNotifiers}
        loadParties
        hasAddEmail={false}
        hasUniqueToEmail={true}
      />
    </Modal>
  )
}
