import { useState } from 'react'
import { svgLoading } from 'stories/assets'

import { AccountingFilesContent } from './AccountingFilesContent'

export const AccountingFiles = ({ onOpenDocs }: { onOpenDocs: Function }) => {
  const [isLoading, setLoading] = useState(false)

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Files
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <AccountingFilesContent isLoading={isLoading} setLoading={setLoading} onOpenDocs={onOpenDocs} />
    </div>
  )
}
