import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Checkbox } from 'stories/components'
import { getPrice3decimal } from 'utils'

import { propertyTypeLabels } from '../../Commercial/LeverageLimits/RuralPropertyLeverageLimit'
import type { IDscrRuralPropertyLeverageLimit } from '../../interface'
import { convertNullToBlankValueToDecimal } from '../../logic'

export const DscrRuralPropertyLeverageMaxLimits = ({ data }: { data: IDscrRuralPropertyLeverageLimit[] }) => {
  return (
    <div className="h-full overflow-x-auto">
      {!!data.length ? (
        <table className="text-sm min-w-max w-full rounded-lg shadow text-center">
          <thead className="bg-gray-100">
            <tr>
              <th rowSpan={2} className="p-2 border whitespace-nowrap">
                Rural Property
              </th>
              <th colSpan={3} className="border p-1">
                Max Limits
              </th>
              <th rowSpan={2} className="border p-1 w-[85px]">
                Min FICO
              </th>
              <th rowSpan={2} className="p-1 border w-[100px]">
                FICO
              </th>
              <th rowSpan={2} className="p-1 border w-[140px]">
                Loan Purpose
              </th>
              <th colSpan={2} className="p-1 border">
                Loan Amount
              </th>
              <th rowSpan={2} className="p-1 border w-[90px]">
                Min DSCR
              </th>
              <th rowSpan={2} className="p-1 border w-[110px]">
                Min Months Reserve
              </th>
              <th colSpan={2} className="p-1 border">
                LTV
              </th>
              <th rowSpan={2} className="p-1 border w-[90px]">
                Max CLTV
              </th>
            </tr>
            <tr>
              <th className="border p-1 w-[60px]">LTV</th>
              <th className="border p-1 w-[60px]">CLTV</th>
              <th className="border p-1 w-[100px] whitespace-nowrap">Loan Amount</th>
              <th className="p-1 border w-[100px]">From</th>
              <th className="p-1 border w-[100px]">To</th>
              <th className="p-1 border w-[70px]">Min</th>
              <th className="p-1 border w-[70px]">Max</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td rowSpan={4 * item.limits.length + 1} className="px-3 py-2 border">
                      {item.value}
                    </td>
                    {['LTV', 'CLTV', 'loanAmount'].map((key, idx) => (
                      <td key={`${index}-${idx}`} rowSpan={4 * item.limits.length + 1} className="border px-3 py-1">
                        {getPrice3decimal((item as any)[key])}
                      </td>
                    ))}
                    <td rowSpan={4 * item.limits.length + 1} className="border px-3 py-1">
                      {item.minFICO}
                    </td>
                  </tr>
                  {item.limits.map((limit, idx) => (
                    <Fragment key={`${index}-${idx}`}>
                      <tr>
                        <td rowSpan={3} className={`border whitespace-nowrap p-1`}>
                          {limit.FICO.from} - {limit.FICO.to}
                        </td>
                        <td className="border px-3 py-1">Purchase</td>
                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${index1}`} className="border px-3 py-1">
                            {convertNullToBlankValueToDecimal((limit.loanAmount.purchase as any)[key])}
                          </td>
                        ))}
                        <td className="border px-3 py-1">{limit.minDSCR.purchase}</td>
                        <td className="border px-3 py-1">{limit.minMonthsReserve.purchase}</td>
                        <td className="border px-3 py-1">{limit.LTV.purchase.ltv.min}</td>
                        <td className="border px-3 py-1">{limit.LTV.purchase.ltv.max}</td>
                        <td className="border px-3 py-1">{limit.LTV.purchase.cltv}</td>
                      </tr>
                      <tr>
                        <td className="border px-3 py-1">No-Cashout</td>
                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${index1}`} className="border px-3 py-1">
                            {convertNullToBlankValueToDecimal((limit.loanAmount.nocashout as any)[key])}
                          </td>
                        ))}
                        <td className="border px-3 py-1">{limit.minDSCR.nocashout}</td>
                        <td className="border px-3 py-1">{limit.minMonthsReserve.nocashout}</td>
                        <td className="border px-3 py-1">{limit.LTV.nocashout.ltv.min}</td>
                        <td className="border px-3 py-1">{limit.LTV.nocashout.ltv.max}</td>
                        <td className="border px-3 py-1">{limit.LTV.nocashout.cltv}</td>
                      </tr>
                      <tr>
                        <td className="border px-3 py-1">Cashout</td>
                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${index1}`} className="border px-3 py-1">
                            {convertNullToBlankValueToDecimal((limit.loanAmount.cashout as any)[key])}
                          </td>
                        ))}
                        <td className="border px-3 py-1">{limit.minDSCR.cashout}</td>
                        <td className="border px-3 py-1">{limit.minMonthsReserve.cashout}</td>
                        <td className="border px-3 py-1">{limit.LTV.cashout.ltv.min}</td>
                        <td className="border px-3 py-1">{limit.LTV.cashout.ltv.max}</td>
                        <td className="border px-3 py-1">{limit.LTV.cashout.cltv}</td>
                      </tr>

                      <tr>
                        <td colSpan={9} className="p-3 text-left border">
                          <div className="border-b font-bold mb-1 pb-1">Property Type</div>
                          <div className="flex justify-start flex-wrap gap-x-4 gap-y-2">
                            {propertyTypeLabels.map((p, idx) => (
                              <Checkbox
                                key={`${index}-${idx}`}
                                id={`${index}-${idx}`}
                                title={p}
                                size={3}
                                value={limit.propertyTypes.includes(p)}
                                color="gray"
                                className="w-[175px]"
                              />
                            ))}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )}
    </div>
  )
}
