export enum LoanStatusType {
  PREQUAL = 'prequal',
  APPSIGNED = 'appSigned',
  RESTRUCTURERELEASE = 'restructureRelease',
  LOAN_INTAKE_REVIEW = 'inTakeReview',
  LOAN_SETUP_ON_HOLD = 'loanSetupOnHold',
  APPLICATION = 'application',
  SUBMITTED = 'submitted',
  SUSPENDED = 'suspended',
  APPROVED = 'approved',
  RE_SUBMITTED = 'reSubmitted',
  UNDERWRITER_REVIEW = 'underwriterReview',
  DECLINED = 'declined',
  CLEAR_TO_CLOSE = 'clearToClose',
  READY_FOR_DOCS = 'readyForDocs',
  DOCS_OUT = 'docsOut',
  CLOSED = 'closed',
  CLOSEDLOANREVIEW = 'closedLoanReview',
  READYFORPURCHASE = 'readyForPurchase',
  FUNDED = 'funded',
  WITHDRAWN = 'withdrawn',
}

export const loanStatusConstants: any = {
  loanSetupUnHoldStatus: ['prequal', 'appSigned', 'restructureRelease', 'loanSetupOnHold'],
  closeStatus: ['clearToClose', 'readyForDocs', 'docsOut', 'closed', 'funded', 'withdrawn'],
  archivedStatus: ['funded', 'withdrawn', 'declined'],
  suspendedStatus: [
    'suspended',
    'approved',
    'reSubmitted',
    'underwriterReview',
    'declined',
    'clearToClose',
    'readyForDocs',
    'docsOut',
    'closed',
    'closedLoanReview',
    'readyForPurchase',
    'funded',
    'withdrawn',
  ],
  brokerCanResubmitConditions: [
    'approved',
    'suspended',
    'declined',
    'clearToClose',
    'readyForDocs',
    'docsOut',
    'closed',
    'closedLoanReview',
    'readyForPurchase',
    'funded',
    'withdrawn',
  ],
  status: {
    prequal: 'Prequal',
    appSigned: 'Application Signed',
    restructureRelease: 'Restructure Release',
    inTakeReview: 'Loan Intake Review',
    loanSetupOnHold: 'Loan Setup on Hold',
    application: 'Loan Setup',
    submitted: 'Submitted',
    suspended: 'Suspended',
    approved: 'Approved',
    reSubmitted: 'Resubmitted',
    underwriterReview: 'Underwriter Review',
    clearToClose: 'Clear to Close',
    readyForDocs: 'Ready for Docs',
    docsOut: 'Docs Out',
    closed: 'Closed',
    closedLoanReview: 'Closed Loan Review',
    readyForPurchase: 'Ready for Purchase',
    funded: 'Funded',
    declined: 'Declined',
    withdrawn: 'Withdrawn',
  },
  servicingStatus: {
    servicing: 'Servicing',
    reo: 'REO',
    purchased: 'Purchased',
    paidInFull: 'Paid in Full',
  },
}

export const fullLoanStatusOptions = { ...loanStatusConstants.status, ...loanStatusConstants.servicingStatus }

export const getActiveStatus = (type: string = 'array') => {
  let rlt: any = []
  Object.keys(loanStatusConstants.status).map((key) => {
    if (!loanStatusConstants.archivedStatus.includes(key)) rlt.push(key)
  })
  if (type === 'object') {
    let obj: any = {}
    rlt.map((key: string) => {
      obj[key] = true
    })
    rlt = obj
  }
  return rlt
}
