import type { InputType } from 'config'
import type { ParagraphComponent } from 'utils/RenderParagraph'

export const allongeParagraph = (inputs: Record<string, InputType>): ParagraphComponent[][] => {
  return [
    [
      {
        input: { inputType: 'span', title: 'Loan Number:' },
      },
      {
        input: inputs.loanNumber,
        Key: 'loanNumber',
        className: 'col-span-2 flex items-end w-48',
      },
      {
        input: { inputType: 'span', title: 'Loan Date:' },
      },
      {
        input: inputs.loanDate,
        Key: 'loanDate',
        className: 'col-span-2 flex items-end w-48',
      },
      {
        input: { inputType: 'span', title: 'Borrowers:' },
      },
      {
        input: inputs.borrowers,
        Key: 'borrowers',
        className: 'col-span-2 flex items-end w-48',
      },
      {
        input: { inputType: 'span', title: 'Property Address:' },
      },
      {
        input: inputs.propertyAddress,
        Key: 'propertyAddress',
        className: 'col-span-2 flex items-end w-128',
      },
      {
        input: { inputType: 'span', title: 'Loan Amount:' },
      },
      {
        input: inputs.loanAmount,
        Key: 'loanAmount',
        className: 'col-span-2 flex items-end w-48',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'PAY TO THE ORDER OF:' },
      },
      {
        input: inputs.payToTheOrderOf,
        Key: 'payToTheOrderOf',
        className: 'mt-1 w-full',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'Entity:' },
        className: 'col-span-1',
      },
      {
        input: inputs.entity,
        Key: 'entity',
        className: 'col-span-2 flex items-end',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'By:' },
        className: 'col-span-1',
      },
      {
        input: inputs.by,
        Key: 'by',
        className: 'col-span-2 flex items-end',
      },
      {
        input: { inputType: 'span', title: 'Title:' },
        className: 'col-span-1',
      },
      {
        input: inputs.title,
        Key: 'title',
        className: 'col-span-2 flex items-end',
      },
    ],
  ]
}
