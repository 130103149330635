import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getLoanDataVerify, openS3Document, updateDataverifyTaxStatus } from 'services'
import { Button } from 'stories/components'
import { formatDate, formatTime } from 'utils'

import { TaxRequestDialog } from './TaxRequestDialog'

export function Tax() {
  const [isLoading, setIsLoading] = useState(false)
  const [borrowers, setBorrowers] = useState([])
  const [data, setData] = useState<Array<any>>([])

  const [currentBorrower, setCurrentBorrower] = useState<Record<string, any> | null>(null)
  const [action, setAction] = useState('')

  const initData = async () => {
    setIsLoading(true)
    const res = await getLoanDataVerify('tax')
    if (res.success) {
      setBorrowers(res.borrowers)
      setData(res.data)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const downLoadBlankForm = () => {
    openS3Document('v3/resources/DV4506C.pdf')
  }

  const submitRequest = (item: any) => {
    setCurrentBorrower(item)
  }

  const onCompletedSubmitRequest = (newItem: Record<string, any>) => {
    let newData = cloneDeep(data)
    newData = [newItem, ...newData]
    setData(newData)
  }

  const updateStatus = async (orderID: string, index: number) => {
    setAction(`updateStatus-${orderID}`)
    const res = await updateDataverifyTaxStatus(orderID)
    if (res.success) {
      let temp = cloneDeep(data)
      temp[index] = res.data
      setData(temp)
    }
    setAction('')
  }

  return (
    <div className="Tax-container relative">
      <LayoutLoading show={isLoading} />
      <div className="">
        <div className="my-4">
          <span
            className="flex gap-1 items-center hover:underline cursor-pointer text-[15px] font-medium text-shade-blue"
            onClick={downLoadBlankForm}
          >
            <span className="font-variation-settings-600">Download a blank 4506C form</span>
            <span>
              <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
            </span>
          </span>
        </div>
        <div className="overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  SSN
                </th>
                <th scope="col" className="px-6 py-3">
                  Date of Birth
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {borrowers.map((item: any, index: number) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                    <td className="px-6 py-3 capitalize">{item.type}</td>
                    <td className="px-6 py-3">{[item.firstName, item.lastName].join(' ')}</td>
                    <td className="px-6 py-3">{item.ssn}</td>
                    <td className="px-6 py-3">{formatDate(item.dob)}</td>
                    <td className="px-6 py-3">
                      <Button color="gray" className="mb-[0px]" onClick={() => submitRequest(item)}>
                        Submit
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="mt-6 mb-2 text-[17px] border-b font-variation-settings-600">Order Processing</div>
        <div className="overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 w-[265px]">
                  Submit Data
                </th>
                <th scope="col" className="px-6 py-3">
                  Order Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Requested Date/By
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                    <td className="px-6 py-3 capitalize">{item.data.type}</td>
                    <td className="px-6 py-3">
                      <table>
                        <tbody className="text-[14px]">
                          <tr>
                            <td className="border px-2">Name:</td>
                            <td className="border px-2 italic">
                              {[item.data.firstName, item.data.lastName].join(' ')}
                            </td>
                          </tr>
                          <tr>
                            <td className="border px-2">SSN:</td>
                            <td className="border px-2 italic">{item.data.ssn}</td>
                          </tr>
                          <tr>
                            <td className="border px-2">Date Of Birth:</td>
                            <td className="border px-2 italic">{formatDate(item.data.dob)}</td>
                          </tr>
                          <tr>
                            <td className="border px-2">Form Type:</td>
                            <td className="border px-2 italic">{item.data.formType}</td>
                          </tr>
                          <tr>
                            <td className="border px-2">Years:</td>
                            <td className="border px-2 italic">{item.data.years.join(', ')}</td>
                          </tr>
                          {!!Object.keys(item.data.buzInfo).length && (
                            <>
                              <tr>
                                <td className="border px-2">Business Name:</td>
                                <td className="border px-2 italic">{item.data.buzInfo.name}</td>
                              </tr>
                              <tr>
                                <td className="border px-2">Tax ID:</td>
                                <td className="border px-2 italic">{item.data.buzInfo.tax}</td>
                              </tr>
                            </>
                          )}
                          <tr>
                            <td className="border px-2 py-1" colSpan={2}>
                              <span
                                className="flex gap-1 items-center text-[13px] text-shade-blue cursor-pointer hover:underline font-bold italic"
                                onClick={() => openS3Document(item.data.fileKey)}
                              >
                                <span>View Uploaded PDF</span>
                                <span>
                                  <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
                                </span>
                              </span>
                            </td>
                          </tr>

                          {item.data.signFileKey && (
                            <tr>
                              <td className="border px-2 py-1" colSpan={2}>
                                <span
                                  className="flex gap-1 items-center text-[13px] text-shade-blue cursor-pointer hover:underline font-bold italic"
                                  onClick={() => openS3Document(item.data.signFileKey)}
                                >
                                  <span>View Uploaded Certificate PDF</span>
                                  <span>
                                    <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                    <td className="px-6 py-3">
                      <table className="w-full">
                        <tbody className="text-[14px]">
                          <tr className="statusColor">
                            <td className="border px-2 w-28">Status:</td>
                            <td className={`border px-2 italic ${item.status?.split('/')[0]}`}>
                              <span className="flex items-center gap-1">
                                <span>{item.status}</span>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="border px-2 w-28">Order ID:</td>
                            <td className="border px-2 italic">{item.orderID}</td>
                          </tr>
                          <tr>
                            <td className="border px-2 w-28">Messages:</td>
                            <td className="border px-2 italic">
                              {item.message}
                              <tbody>
                                {item.messages.map((it: any, index: number) => {
                                  return (
                                    <tr className="" key={index}>
                                      <td className="border px-2">{it.time}</td>
                                      <td className="border px-2">{it.message}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </td>
                          </tr>
                          {item.status === 'Completed/Success' && (
                            <tr>
                              <td className="border px-2 py-1" colSpan={2}>
                                <span
                                  className="flex gap-1 items-center text-[13px] text-shade-blue cursor-pointer hover:underline font-bold italic"
                                  onClick={() => openS3Document(item.successFileKey)}
                                >
                                  <span>View Final PDF</span>
                                  <span>
                                    <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          )}
                          {['Request sent to vendor', 'Pending'].indexOf(item.status) !== -1 && (
                            <tr>
                              <td className="border px-2 py-1" colSpan={2}>
                                <span>
                                  <Button
                                    color="gray"
                                    className="py-[5px] mb-[0px] w-full"
                                    loading={action === `updateStatus-${item.orderID}`}
                                    onClick={() => updateStatus(item.orderID, index)}
                                  >
                                    Update Status
                                  </Button>
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                    <td className="px-6 py-3">
                      {item.by}
                      <br />
                      {formatTime(item.time)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        {currentBorrower && (
          <TaxRequestDialog
            borrower={currentBorrower}
            onSubmit={(data: Record<string, any>) => onCompletedSubmitRequest(data)}
            onClose={() => setCurrentBorrower(null)}
          />
        )}
      </div>
    </div>
  )
}
