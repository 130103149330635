import cloneDeep from 'clone-deep'
import { type InputType, loanOptionsConstants, SettingKey } from 'config'
import type { ILoanGlobalSettings } from 'pages/Admin/AdminTools/Configuration/GlobalSettings'
import { propertyTypeNumberUnitInputLogic } from 'pages/LoanStructure/Logic'
import { useEffect, useState } from 'react'
import { getSetting } from 'services'
import { getPrice2decimal, InputConvert, loanAmountRestriction } from 'utils'

import { renderActions } from '../components'
import type { IBorrowerLoanRequestStepProps } from './interface'
import { renderInputs, validateForm } from './util'

const propertiesForUnits = ['SFR-Detached', 'SFR-Attached', 'Industrial']

const defaultInputs = (): Record<string, InputType> => {
  return {
    propertyType: {
      inputType: 'select',
      title: 'How would you describe the property you’re using {lender} for',
      options: {
        'SFR-Detached': 'Single Family Residence-Detached', // ASK
        'SFR-Attached': 'Single Family Residence-Attached', // ASK
        Condo: 'Condominium', // ASK
        'Mixed-Use': 'Mixed-Use',
        Modular: 'Modular',
        '2-4 Units': 'Multifamily 2-4 Unit',
        'MF (5-8 units)': 'Multifamily 5-8 units',
        'MF (9-30 units)': 'Multifamily 9-30 units',
        'MF (31-100 units)': 'Multifamily 30+ Units',
        Industrial: 'Industrial',
        Manufactured: 'Manufactured',
        'Self Storage': 'Self-Storage',
        Office: 'Office',
        Retail: 'Retail',
        Warehouse: 'Warehouse',
        Automotive: 'Automotive',
        PUD: 'Land',
      },
      hasDefaultOption: true,
      required: true,
    },
    numberOfUnits: {
      inputType: 'select',
      title: 'How Many Units?',
      options: {},
      visible: (inputs: Record<string, InputType>) => !propertiesForUnits.includes(inputs.propertyType.value),
      required: true,
    },

    proposedOccupancy: {
      inputType: 'select',
      title: 'Occupancy Type',
      options: loanOptionsConstants.occupancy,
      hasDefaultOption: true,
      required: true,
    },
    subjectPropertyAddress: {
      inputType: 'map',
      title: 'Subject Property Address',
      required: true,
    },

    isShortTermRental: {
      inputType: 'check',
      title: 'Is the property being used as a vacation rental (Airbnb/VRBO) ?',
      required: true,
      textLeft: true,
      visible: (inputs: Record<string, InputType>, allValues: Record<string, any>) =>
        ['DSCR (Long Term Rental)'].includes(allValues.productType),
    },
    ruralProperty: {
      inputType: 'check',
      title: 'Is the property located in a rural area?',
      required: true,
      textLeft: true,
    },
    propertyUnderConstruction: {
      inputType: 'check',
      title: 'Is property currently under construction?',
      required: true,
      textLeft: true,
    },
    isPropertyInLeasableState: {
      inputType: 'check',
      title: 'Can the property be rented as is?',
      required: true,
      textLeft: true,
    },

    hasProposedMonthlyRent: {
      inputType: 'check',
      title: 'Do you expect to earn rent from this property?',
      required: true,
      textLeft: true,
    },
    proposedMonthlyRent: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'How much is the proposed monthly rent?',
      error: '',
      visible: (inputs: Record<string, InputType>) => inputs.hasProposedMonthlyRent.value,
      required: true,
    },

    hasClosingDate: {
      inputType: 'check',
      title: 'Do you have a closing date? If not sure yet, no worries!',
      required: true,
      textLeft: true,
    },
    closingDate: {
      inputType: 'text',
      type: 'date',
      title: 'Closing Date',
      visible: (inputs: Record<string, InputType>) => inputs.hasClosingDate.value,
      required: true,
    },

    // Purchase Transaction only
    propertyPurchasePrice: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'What is the purchase price of “Property Address”?',
      visible: (inputs: Record<string, InputType>, allValues: Record<string, any>) =>
        allValues.transactionType == 'Purchase',
      required: true,
    },

    constructionReserve: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'How much is the estimated budget for the construction of the project?',
      required: true,
      visible: (inputs: Record<string, InputType>, allValues: Record<string, any>) =>
        ['Fix and Flip', 'Ground Up Construction'].includes(allValues.productType),
    },

    asIsValue: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'What do you believe current value of “Property Address” is?',
      required: true,
    },

    afterRepairValue: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'What is the After Repair Value of your project?',
      required: true,
    },

    proposedLoanAmount: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'How much are you looking to borrow?',
      required: true,
    },

    // Purchase only and this is for firt time homebuyer box , for refi default to no.
    firstTimeHomeBuyer: {
      title: 'Is this the first property you have ever purchased?',
      inputType: 'check',
      visible: (inputs: Record<string, InputType>, allValues: Record<string, any>) =>
        allValues.transactionType == 'Purchase',
      required: true,
      textLeft: true,
    },

    // This is for first time investor box, Purchase only,   for refi, default to no
    firstTimeHomeInvestor: {
      title: 'Have you ever owned any investment properties?',
      inputType: 'check',
      visible: (inputs: Record<string, InputType>, allValues: Record<string, any>) =>
        allValues.transactionType == 'Purchase',
      error: '',
      required: true,
      textLeft: true,
    },

    escrowType: {
      inputType: 'Radio',
      title:
        'Would you like to included taxes and insurance in your monthly payment or do you prefer to pay those on your own?',
      options: {
        'Not Waived': 'Included',
        'Waive Both': 'Pay on my own',
      },
      titleType: 'block',
      size: 3,
      visible: (inputs: Record<string, InputType>, allValues: Record<string, any>) =>
        ['DSCR (Long Term Rental)'].includes(allValues.productType),
      required: true,
    },
  }
}

export const InvestmentInformation = ({
  lender,
  values = {},
  allValues = {},
  onSubmit: _onSubmit,
  onBack,
}: IBorrowerLoanRequestStepProps) => {
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [globalSettings, setGlobalSettings] = useState<ILoanGlobalSettings>()

  useEffect(() => {
    setValues(values || {})
  }, [values])

  useEffect(() => {
    getSetting(SettingKey.GLOBAL_SETTINGS).then(({ value }) => {
      const content = JSON.parse(value || '{}')
      setGlobalSettings(content)
    })
  }, [])

  const setValues = (data: Record<string, any>) => {
    const newInputs = cloneDeep(defaultInputs())
    Object.keys(newInputs).map((key) => {
      const input = newInputs[key]
      if (data[key] !== undefined) input.value = data[key]
      if (input.title.includes('{lender}')) input.title = input.title.replace('{lender}', lender)
    })
    updateNumberOfUnits(newInputs)
    updatePropertyAddress(newInputs)
    setInputs(newInputs)
  }

  const updateNumberOfUnits = (inputs: Record<string, InputType>) => {
    const propertyType = inputs.propertyType.value
    propertyTypeNumberUnitInputLogic(propertyType, inputs.numberOfUnits)
  }

  const updatePropertyAddress = (inputs: Record<string, InputType>) => {
    const subjectPropertyAddress = inputs.subjectPropertyAddress.value || 'Property Address'
    inputs['propertyPurchasePrice'].title = `What is the purchase price of “${subjectPropertyAddress}”?`
    inputs['asIsValue'].title = `What do you believe current value of “${subjectPropertyAddress}” is?`
  }

  const onChange = (key: string, value: string) => {
    if (inputs[key].value === value) return

    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)

    if (key == 'propertyType') updateNumberOfUnits(newInputs)
    if (key == 'subjectPropertyAddress') updatePropertyAddress(newInputs)
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let { hasError, data, newInputs } = validateForm({
      inputs,
      setInputs,
      allValues,
    })

    if (globalSettings) {
      const proposedLoanAmount = newInputs.proposedLoanAmount.value
      if (!newInputs.proposedLoanAmount.error) {
        const error = loanAmountRestriction(proposedLoanAmount, 'New', globalSettings)
        if (error) {
          const { minProposedLoanAmount, maxProposedLoanAmount } = globalSettings
          newInputs.proposedLoanAmount.error = `We only lend between $${getPrice2decimal(
            minProposedLoanAmount,
          )} and $${getPrice2decimal(maxProposedLoanAmount)}`
          setInputs(newInputs)
          hasError = true
        }
      }
    }
    if (hasError) return

    if (propertiesForUnits.includes(data.propertyType)) data.numberOfUnits = 1

    _onSubmit({
      firstTimeHomeBuyer: false,
      firstTimeHomeInvestor: false,
      ...data,
    })
  }

  return (
    <div>
      <p className="mb-6">Now lets collect some info about your investment.</p>

      {renderInputs({ inputs, allValues, onChange })}

      {renderActions(onBack, onSubmit)}
    </div>
  )
}
