import { InputType, loanOptionsConstants } from 'config'

const originationChannelOptions = {
  0: '',
  1: 'Retail',
  2: 'Brokered Out',
  3: 'Wholesale',
  4: 'Correspondent',
  5: 'Mini-Corr',
  6: 'Consumer Direct',
}

export const sourceOfBorrowersInterestOptions: Record<string, string> = {
  warrentyDeed: 'Warranty Deed',
  quitClaimDeed: 'Quit Claim Deed',
  other: 'Other',
}

const documentTypeOptions = [
  'Full Doc',
  'Full Doc: 12 Mo. (Limited)',
  '1099: 24 Mo',
  '1099: 12 Mo',
  'Bank Stmts: 1 Mo. Personal',
  'Bank Stmts: 3 Mo. Personal',
  'Bank Stmts: 3 Mo. Business',
  'Bank Stmts: 12 Mo. Personal',
  'Bank Stmts: 12 Mo. Business',
  'Bank Stmts: 24 Mo. Personal',
  'Bank Stmts: 24 Mo. Business',
  'Asset Depletion/Utilization',
  'VOE',
  'PnL: 12 Mo. CPA Prepared',
  'PnL: 24 Mo. CPA Prepared',
  'DSCR / No Ratio DSCR',
  'Stated Income',
  'Asset Qualifier',
  'ATR-in-Full',
  'No Income Verification',
]

const documentNameOptions = [
  'Asset Depletion',
  'Asset Qualifier',
  'Bank Statement',
  'Bank Statement Plus',
  'DSCR',
  'DSCR > 1.00',
  'DSCR .99 to .75',
  'Full Doc',
  'Full Doc W2',
  'Full Doc 1040',
  'Lease of HUD FMR',
  'P & L Only',
  'W2 + Paystub',
]

const accrualTypeOptions = ['30/360', '30/365', 'Actual/360']

export const RecordingInformationOptions: Record<string, string> = {
  bookAndPageOnly: 'Book and Page Only',
  instrumentOnly: 'Instrument # Only',
  bookAndPageWithInstrument: 'Book and Page with Instrument',
}

export const recordingInfoFields: Record<string, string[]> = {
  bookAndPageOnly: ['book', 'page'],
  instrumentOnly: ['instrument'],
  bookAndPageWithInstrument: ['book', 'page', 'instrument'],
}

export const inputGroups: Record<string, Record<string, string[]>> = {
  Purchase: {
    'Underwriting Information': [
      'InvestorCode',
      'FileCreditScore',
      'OriginationChannel',
      'DSCR',
      'LTV',
      'ARVLTV',
      'DocumentType',
      'DocumentName',
      'PROJ250_MonthsReservesatFunding',
      'MosReserves',
      'Borrower_Reserves',
      'ExceptionGranted',
      'CompensatingFactor',
      'PropertyClass',
      'ProjectName',
      'SellerCredit',
      'EarnestMoneyAmount',
      'PROJ250_AccrualType',
      'ExceptionTitle',
      'PROJ250DealNarrative',
      'constructionCompanyAddressMap',
    ],
    'Property/Construction': [
      'PROJ250_Construction',
      'PROJ250_IsNewConstruction',
      'PROJ250_NewAcquisition',
      'PROJ250_PropertyYouAlreadyOwn',
      'PROJ250_PropertyAcquisitionDate',
      'PROJ250_ConstructionCompletionDate',
      'Company',
      'FullName',
      'FullAddress',
      'ContractDate',
      'CurrentSQFT',
      'AfterRehadSQFT',
      'BedroomCount',
      'BathroomCount',
      'LotSize',
      'YearBuilt',
      'purchaseDate',
      'purchasePrice',
      'numberOfUnits',
      'asIsPerUnit',
      'arvPerUnit',
      'msa',
      'lienPosition',
      'numberOfProperties',
      'experience',
      'Strategy',
    ],
    'Borrower Credit/Info': [
      'NonQMBankruptcy',
      'NonQMDeedInLieu',
      'NonQMShortSalePreForeclosure',
      'NonQMForeclosure',
      'NonQMMortgageLatePayment',
      'NonQMHouseholdSize',
      'NonQMCitizenType',
      'BorrowerLifetimeProjects',
      'BorrowerExperiencewithOriginator',
    ],
  },
  Refinance: {
    'Underwriting Information': [
      'InvestorCode',
      'FileCreditScore',
      'OriginationChannel',
      'DSCR',
      'LTV',
      'ARVLTV',
      'DocumentType',
      'DocumentName',
      'PROJ250_MonthsReservesatFunding',
      'MosReserves',
      'Borrower_Reserves',
      'ExceptionGranted',
      'CompensatingFactor',
      'PropertyClass',
      'ProjectName',
      'SellerCredit',
      'EarnestMoneyAmount',
      'PROJ250_AccrualType',
      'ExceptionTitle',
      'PROJ250DealNarrative',
    ],
    'Property/Construction': [
      'PROJ250_Construction',
      'PROJ250_IsNewConstruction',
      'PROJ250_NewAcquisition',
      'PROJ250_PropertyYouAlreadyOwn',
      'PROJ250_PropertyAcquisitionDate',
      'PROJ250_ConstructionCompletionDate',
      'Company',
      'FullName',
      'FullAddress',
      'ContractDate',
      'CurrentSQFT',
      'AfterRehadSQFT',
      'BedroomCount',
      'BathroomCount',
      'LotSize',
      'YearBuilt',
      'purchaseDate',
      'purchasePrice',
      'numberOfUnits',
      'asIsPerUnit',
      'arvPerUnit',
      'msa',
      'lienPosition',
      'numberOfProperties',
      'experience',
      'Strategy',
    ],
    'Borrower Credit/Info': [
      'NonQMBankruptcy',
      'NonQMDeedInLieu',
      'NonQMShortSalePreForeclosure',
      'NonQMForeclosure',
      'NonQMMortgageLatePayment',
      'NonQMHouseholdSize',
      'NonQMCitizenType',
      'BorrowerLifetimeProjects',
      'BorrowerExperiencewithOriginator',
    ],
    'Source of Borrowers Interest In Subject Property': [
      'BorrowersInterestSourceTypeInSubjectProperty',
      'BorrowersInterestSourceTitleInSubjectProperty',
      'RecordingInfo',
      'BorrowersInterestSourceDateInSubjectProperty',
    ],
  },
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    // Underwriting Information
    InvestorCode: {
      inputType: 'text',
      type: 'text',
      title: 'Investor Code',
      value: '',
      error: '',
      disabled: true,
    },
    FileCreditScore: {
      inputType: 'text',
      type: 'number',
      title: 'File Credit Score',
      value: '',
      error: '',
      disabled: true,
    },
    OriginationChannel: {
      inputType: 'select',
      options: originationChannelOptions,
      title: 'Origination Channel',
      hasDefaultOption: true,
      value: '',
      error: '',
      disabled: true,
    },
    DSCR: {
      inputType: 'text',
      type: 'number',
      title: 'DSCR',
      value: '',
      error: '',
      disabled: true,
    },
    LTV: {
      inputType: 'text',
      type: 'text',
      title: 'As Is LTV',
      value: '',
      error: '',
      disabled: true,
    },
    ARVLTV: {
      inputType: 'text',
      type: 'text',
      title: 'After Repair LTV',
      value: '',
      error: '',
      disabled: true,
    },
    DocumentType: {
      inputType: 'select',
      options: documentTypeOptions,
      title: 'Document Type',
      hasDefaultOption: true,
      value: '',
      error: '',
    },
    DocumentName: {
      inputType: 'select',
      options: documentNameOptions,
      title: 'Document Name',
      hasDefaultOption: true,
      value: '',
      error: '',
    },
    PROJ250_MonthsReservesatFunding: {
      inputType: 'text',
      type: 'number',
      title: 'Months Reserves',
      value: '',
      error: '',
    },
    MosReserves: {
      inputType: 'text',
      type: 'text',
      title: 'Mos Reserves',
      value: '',
      error: '',
    },
    Borrower_Reserves: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Borrower Reserves',
      value: '',
      error: '',
      disabled: true,
    },
    ExceptionGranted: {
      inputType: 'text',
      type: 'text',
      title: 'Exception Granted',
      value: '',
      error: '',
    },
    CompensatingFactor: {
      inputType: 'text',
      type: 'text',
      title: 'Compensating Factor',
      value: '',
      error: '',
    },
    PropertyClass: {
      title: 'Project Classification',
      inputType: 'select',
      hasDefaultOption: true,
      options: {
        1: 'A III Condo (obsolete)',
        2: 'B II Condo (obsolete)',
        3: 'C I Condo (obsolete)',
        4: 'E Established PUD Project',
        5: 'F New PUD Project',
        6: 'III PUD (obsolete)',
        7: '1 FUll Review - Co-op Project',
        8: '2 Fannie Mae Review through PERS - Co-op Porject',
        9: 'FHA_VA Condo Or Spot Loan',
        10: 'P Limited Review - New Condo Project',
        11: 'Q Limited Review - Established Condo Project',
        12: 'R Full Review - New Condo Project',
        13: 'S Full Review - Established Condo Project',
        14: 'T Fannie Mae Review through PERS - Condo Project',
        15: 'U FHA-approved Condo Project',
        16: 'Streamlined Review',
        17: 'Established Project',
        18: 'New Project',
        19: 'Detached Project',
        20: '2-to-4-unit Project',
        21: 'Reciprocal Review',
        22: 'V Condo Project Review Waived',
        23: 'T PUD (obsolete)',
        24: 'T COOP (obsolete)',
        25: 'Exempt from Review',
        999: 'Not in a Project or Development',
      },
    },
    ProjectName: {
      title: 'Project Name',
      inputType: 'text',
      value: '',
    },
    SellerCredit: {
      title: 'Seller Credits',
      inputType: 'text',
      type: 'thousandSep',
      value: '',
    },
    ExceptionTitle: {
      inputType: 'AutoComplete',
      title: 'Exception to be Removed',
      value: [],
      error: '',
      span: 6,
    },
    PROJ250DealNarrative: {
      inputType: 'textarea',
      title: 'Deal Narrative',
      value: '',
      error: '',
      span: 6,
    },
    constructionCompanyAddressMap: {
      title: 'AddressSubject Property Address',
      inputType: 'GoogleMap',
      span: 6,
    },
    // Property/Construction
    PROJ250_Construction: {
      inputType: 'checkbox',
      title: 'This is a construction loan',
      value: false,
      span: 3,
    },
    PROJ250_IsNewConstruction: {
      inputType: 'checkbox',
      title: 'New Construction',
      value: false,
      span: 3,
    },
    PROJ250_NewAcquisition: {
      inputType: 'checkbox',
      title: 'The borrower is aquiring this property',
      value: false,
      span: 3,
    },
    PROJ250_PropertyYouAlreadyOwn: {
      inputType: 'checkbox',
      title: 'The borrower already owns this property',
      value: false,
      span: 3,
    },
    PROJ250_PropertyAcquisitionDate: {
      inputType: 'text',
      type: 'date',
      title: 'Property Acquisition Date',
      value: '',
      error: '',
      span: 3,
    },
    PROJ250_ConstructionCompletionDate: {
      inputType: 'text',
      type: 'date',
      title: 'Construction Completion Date',
      value: '',
      error: '',
      span: 3,
    },
    Company: {
      inputType: 'text',
      type: 'text',
      title: 'Construction Company',
      value: '',
      error: '',
      span: 3,
    },
    PROJ250_AccrualType: {
      inputType: 'select',
      options: accrualTypeOptions,
      title: 'Accrual Type',
      hasDefaultOption: true,
      value: '',
      error: '',
    },
    FullName: {
      inputType: 'text',
      type: 'text',
      title: 'General Contractor Name',
      value: '',
      error: '',
      span: 3,
    },
    FullAddress: {
      inputType: 'map',
      title: 'Construction Company Address',
      value: '',
      error: '',
      span: 3,
    },
    ContractDate: {
      inputType: 'text',
      type: 'date',
      title: 'Contract Date',
      value: '',
      error: '',
      span: 3,
    },
    CurrentSQFT: {
      inputType: 'text',
      type: 'number',
      title: 'Current SQFT',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    AfterRehadSQFT: {
      inputType: 'text',
      type: 'number',
      title: 'After Rehab SQFT',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    BedroomCount: {
      inputType: 'text',
      type: 'number',
      title: 'Bedroom Count',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    BathroomCount: {
      inputType: 'text',
      type: 'number',
      title: 'Bathroom Count',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    LotSize: {
      inputType: 'text',
      type: 'number',
      title: 'Lot Size',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    YearBuilt: {
      inputType: 'text',
      type: 'number',
      title: 'Year Built',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    purchaseDate: {
      inputType: 'text',
      type: 'date',
      title: 'Purchase Date',
      span: 3,
      disabled: true,
    },
    purchasePrice: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Purchase Price',
      prefix: '$',
      span: 3,
      disabled: true,
    },
    Square: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Square (Feet)',
      value: '',
      error: '',
      span: 3,
    },
    numberOfUnits: {
      inputType: 'text',
      type: 'number',
      title: '# of Units',
      span: 3,
      disabled: true,
    },
    asIsPerUnit: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'As Is Per Unit',
      span: 3,
      disabled: true,
    },
    arvPerUnit: {
      inputType: 'text',
      type: 'number',
      title: 'ARV Per Unit',
      span: 3,
      disabled: true,
    },
    msa: {
      inputType: 'text',
      title: 'MSA',
      span: 3,
      disabled: true,
    },
    lienPosition: {
      inputType: 'text',
      title: 'Lien Position',
      span: 3,
      disabled: true,
    },
    numberOfProperties: {
      inputType: 'text',
      type: 'number',
      title: '# of Properties',
      span: 3,
      disabled: true,
    },
    experience: {
      inputType: 'text',
      title: 'Experience',
      span: 3,
      disabled: true,
    },
    Strategy: {
      inputType: 'select',
      title: 'Loan Strategy',
      hasDefaultOption: true,
      options: [
        'Sell for profit',
        'Renovate and Sell for profit',
        'Retain as rental',
        'Refinance and retain as rental',
        'Refinance and obtain construction financing for renovation',
        'Refinance and retain as rental once renovated',
      ],
      span: 6,
      required: true,
    },
    NonQMBankruptcy: {
      title: 'Bankruptcy',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.loannex1,
      error: '',
      disabled: true,
    },
    NonQMDeedInLieu: {
      title: 'Deed-In-Lieu',
      inputType: 'select',
      hasDefaultOption: true,
      options: { ...loanOptionsConstants.loannex2 },
      error: '',
      disabled: true,
    },
    NonQMShortSalePreForeclosure: {
      title: 'Short Sale',
      inputType: 'select',
      hasDefaultOption: true,
      options: { ...loanOptionsConstants.loannex2 },
      error: '',
      disabled: true,
    },
    NonQMForeclosure: {
      title: 'Foreclosure',
      inputType: 'select',
      hasDefaultOption: true,
      options: { ...loanOptionsConstants.loannex2 },
      error: '',
      disabled: true,
    },
    NonQMMortgageLatePayment: {
      title: 'Mortgage Lates',
      inputType: 'select',
      hasDefaultOption: true,
      options: ['0X30X12', '1X30X12', '0x60x12', '>= 1X60X12'],
      error: '',
      disabled: true,
    },
    NonQMHouseholdSize: {
      title: 'Household Size',
      inputType: 'text',
      error: '',
    },
    EarnestMoneyAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Earnest Money Deposit',
      prefix: '$',
    },
    NonQMCitizenType: {
      title: 'Citizenship',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.citizenship,
      error: '',
      disabled: true,
    },
    BorrowersInterestSourceTypeInSubjectProperty: {
      inputType: 'select',
      options: sourceOfBorrowersInterestOptions,
      title: 'Type',
      hasDefaultOption: true,
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    BorrowersInterestSourceTitleInSubjectProperty: {
      inputType: 'text',
      type: 'text',
      title: 'Other Type',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    RecordingInfo: {
      inputType: 'select',
      title: 'Recording Information',
      value: '',
      options: RecordingInformationOptions,
      hasDefaultOption: true,
      error: '',
      span: 3,
      required: true,
    },
    BorrowersInterestSourceDateInSubjectProperty: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
      span: 3,
      required: true,
    },
    BorrowerLifetimeProjects: {
      inputType: 'text',
      type: 'number',
      title: 'Borrower Lifetime Projects',
      value: '',
      error: '',
      required: true,
      span: 4,
    },
    BorrowerExperiencewithOriginator: {
      inputType: 'text',
      title: 'Borrower Experience with Originator',
      value: '',
      error: '',
      required: true,
      span: 4,
    },
  }
}
