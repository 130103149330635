import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { itemCountPerPage, UserStatusFilters } from 'config'
import { useEffect, useState } from 'react'
import { getExportHistory } from 'services'
import { Pagination, PlainTable } from 'stories/components'
import { formatTime } from 'utils'

import { PullCreditScoreLevels } from './user.type'

interface ExportHistoryItem {
  id: number
  filters: {
    query: string
    status: string
    accountType: string | null
    deleted: boolean
    pullCreditScoreLevel: string | null
  }
  count: number
  createdAt: Date
  user: {
    name: string
    email: string
  }
}

export default function ExportHistory() {
  const [filters, setFilters] = useState({
    query: '',
    status: '',
    accountType: '',
    pullCreditScoreLevel: '',
    pageNum: 0,
    orderBy: 'createdAt',
    orderDir: '-1',
  })

  const [data, setData] = useState<ExportHistoryItem[]>([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    getExportHistory(filters.pageNum * itemCountPerPage, itemCountPerPage).then(({ exportHistory, total }) => {
      setTotal(total)
      setData(exportHistory)
    })
  }, [filters.pageNum])

  const onPageNavigate = (num: number) => {
    setFilters({ ...filters, pageNum: num })
  }

  const tableHeader = [
    'NO',
    'Name / Email',
    'Filter Query',
    'Status',
    'User Type',
    'Credit Pull',
    'Deleted',
    'User Counts',
    'Created At',
  ]

  const tableData = data.map((item, index) => [
    filters.pageNum * itemCountPerPage + index + 1,
    <>
      <div className="font-bold text-shade-blue">{item.user.name}</div>
      <div>{item.user.email}</div>
    </>,
    item.filters.query || '',
    (UserStatusFilters as any)[item.filters.status] || 'All',
    accountTypes[item.filters.accountType || ''] || 'All',
    PullCreditScoreLevels[item.filters.pullCreditScoreLevel as keyof typeof PullCreditScoreLevels] || 'All',
    item.filters.deleted ? 'Yes' : 'No',
    item.count,
    formatTime(item.createdAt),
  ])

  return (
    <div className="registration-container py-6 px-2">
      <div className="shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full">
        <button
          onClick={() => window.history.back()}
          className="flex items-center text-shade-blue hover:text-blue-800 font-semibold mb-4"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2 items-center" /> Back
        </button>

        <div className="flex flex-wrap justify-between mb-5">
          <h1 className="text-2xl font-bold flex items-center mb-3">
            <span>Exported History</span>
          </h1>
        </div>

        <PlainTable
          header={tableHeader}
          data={tableData}
          widthClass="min-w-full"
          tdClass="py-4 px-6 text-left"
          thClass="py-4 px-6 text-left text-sm font-semibold text-gray-600 bg-gray-100 border-b border-gray-200"
        />

        <div className="flex justify-end items-center mt-3 mb-3">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={filters.pageNum}
          />
        </div>
      </div>
    </div>
  )
}
