import { formatDate, thousandSeperatorWithPrefix } from 'utils'

import { IClosingCalendarData } from './types'

interface ClosingCalanderInfoProps {
  loanInfo: Record<string, string>
  data: IClosingCalendarData[]
  description: string
}

export const ClosingCalendarInfo = ({ data = [], description = '', loanInfo }: ClosingCalanderInfoProps) => {
  const renderFees = (item: IClosingCalendarData) => {
    if (item.brokerFee) return thousandSeperatorWithPrefix(item.brokerFee)
    if (!item.fees) return ''
    if (typeof item.fees == 'string') return item.fees
    return (
      <div className="divide-y">
        {item.fees.map((v: any) => (
          <p>
            {v.title}: {thousandSeperatorWithPrefix(v.amount)}
          </p>
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="p-2">
        <div className="flex mb-4">
          <div className="w-60">Broker Name</div>
          <div className="font-bold ml-2">{loanInfo.brokerName}</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Borrower Name</div>
          <div className="font-bold ml-2">{loanInfo.borrowerName}</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Subject Property Address</div>
          <div className="font-bold ml-2">{loanInfo.subjectProperty}</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Loan Term</div>
          <div className="font-bold ml-2">{loanInfo.loanTerm} months</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Loan Purpose</div>
          <div className="font-bold ml-2">{loanInfo.loanProgram}</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Loan Amount</div>
          <div className="font-bold ml-2">{thousandSeperatorWithPrefix(Number(loanInfo.loanAmount))}</div>
        </div>
        <div className="flex mb-4">
          <div className="w-60">Rate</div>
          <div className="font-bold ml-2">{loanInfo.rate}%</div>
        </div>
      </div>
      <p className="p-2 mb-2" dangerouslySetInnerHTML={{ __html: description }}></p>
      <p className="text-md w-full font-variation-settings-600 text-shade-blue p-2">Closing Requests History</p>
      <div className="parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                Closing
              </th>
              <th scope="col" className="px-6 py-3">
                Fee
              </th>
              <th scope="col" className="px-6 py-3">
                Company
              </th>
              <th scope="col" className="px-6 py-3">
                Contact
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
            </tr>
          </thead>
          <tbody className="text-[15px] text-gray-900">
            {data.map((item, index) => {
              return (
                <tr key={index} className={`border-b ${index % 2 && 'bg-gray-50'}`}>
                  <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4">{formatDate(item.closingDate)}</td>
                  <td className="px-6 py-4">{renderFees(item)}</td>
                  <td className="px-6 py-4">{item.titleCompany}</td>
                  <td className="px-6 py-4">{item.titleCompanyPointOfContact}</td>
                  <td className="px-6 py-4">{item.emailAddressForTitleCompany}</td>
                  <td className="px-6 py-4">{item.titleAgentPhoneNumber}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
