import { EyeIcon } from '@heroicons/react/24/outline'
import { logout } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import type { InputType } from 'config'
import { TemporaryLayout } from 'layouts'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { changeTrustLedgerAgreementStatus, openS3Document, uploadFiles, validateTrustLedgerSignature } from 'services'
import { Button } from 'stories/components'
import {
  confirm,
  formatTime,
  getFileName,
  getPrice2decimal,
  // useQuery
} from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

const defaultInputs = (): Record<string, InputType> => {
  return {
    declineReason: {
      inputType: 'textarea',
      title: 'Decline Reason',
      value: '',
      error: '',
      required: true,
    },
    files: {
      inputType: 'file',
      title: 'Please upload any supporting documentation to your decline reason',
      value: '',
      acceptFileTypes: 'application/pdf',
      error: '',
      multiple: true,
    },
  }
}

export const TrustLedgerAgreement = () => {
  const urlParams: any = useParams()
  const { loanNumber, orderID, urlSignature, token } = urlParams
  const registeredToken = useSelector((state: any) => state.auth.token)
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)
  const [action, setAction] = useState('')
  const [showDecline, setShowDecline] = useState(false)
  const [inputs, setInputs] = useState<any>(defaultInputs())
  const [data, setData] = useState<any>({
    requestID: 0,
    info: [],
    files: [],
    result: {},
  })
  const dispatch = useDispatch()
  const navigate = useHistory()
  // const defaultMenu = useQuery().get('agree')

  useEffect(() => {
    setLoading(true)
    setValidated(false)
    setLoanNumber()
    validateTrustLedgerSignature({
      loanNumber,
      orderID,
      urlSignature,
      token,
    }).then(async (res) => {
      if (!res.success) {
        toast('This link is expired as a more recent link has been sent.', { type: 'error' })
        navigate.push('/')
        dispatch(logout())
        return
      } else {
        setData(res.data)
      }
      setValidated(true)
    })
  }, [token, loanNumber, urlSignature])

  useEffect(() => {
    if (!registeredToken || !validated) return
    setLoading(false)
  }, [registeredToken, validated])

  const onApprove = async () => {
    const content = <div className="mb-4 text-[17px]">Are you sure want to approve this draw request?</div>
    const result = await confirm(content)
    if (!result) return
    setAction('approve')
    setLoading(true)
    const json = {
      status: 'Approve',
      orderID,
      requestID: data.requestID,
    }
    const res = await changeTrustLedgerAgreementStatus(json)
    if (res.success) {
      setData(res.data)
    }
    setAction('')
    setLoading(false)
  }

  const confirmDecline = async () => {
    setAction('decline')
    setLoading(true)
    let json: any = {
      status: 'Decline',
      orderID,
      requestID: data.requestID,
      reason: inputs.declineReason.value,
      files: [],
    }
    if (inputs.files.value) {
      const uploadData = {
        path: `loan/${loanNumber}/trustLedgerAgreement/${orderID}`,
      }
      const files = await uploadFiles(uploadData, inputs.files.value)
      json.files = files
    }
    const res = await changeTrustLedgerAgreementStatus(json)
    if (res.success) {
      setData(res.data)
    }
    setAction('')
    setLoading(false)
  }

  const onDecline = () => {
    setShowDecline(true)
    setInputs(defaultInputs())
  }

  const onChangeInput = async (key: string, e: any) => {
    const temp = cloneDeep(inputs)
    temp[key].value = e
    setInputs(temp)
  }

  const statusFragment = useMemo(() => {
    if (data.result.status === 'Pending') {
      return (
        <div className="pending-continer">
          <div
            className="bg-yellow-100 border mt-2 border-yellow-400 text-yellow-700 px-4 py-3 rounded relative text-[14px] max-w-2xl"
            role="alert"
          >
            <div className="font-variation-settings-600">
              Draw request has been sent by <span className="underline">{data.result.by}</span> on{' '}
              {formatTime(data.result.time)} and <span className="font-bold">Pending</span> now.
            </div>
          </div>
          {showDecline ? (
            <div className="mt-4 w-full">
              {Object.keys(inputs).map((key, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <RenderInput input={inputs[key]} Key={key} onChange={onChangeInput} />
                  </div>
                )
              })}
              <div className="flex flex-wrap justify-center gap-4">
                <Button
                  color="red"
                  disabled={inputs.declineReason.value.length === 0}
                  className="py-[5px]"
                  onClick={confirmDecline}
                  loading={action === 'decline'}
                >
                  Confirm Decline
                </Button>
                <Button link onClick={() => setShowDecline(false)} className="py-[5px]">
                  <span className="text-red-600">Cancel Decline</span>
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex gap-4 flex-wrap mt-4">
              <Button color="sky" className="py-[7px]" onClick={onApprove} loading={action === 'approve'}>
                Approve
              </Button>
              <Button color="red" onClick={onDecline} className="py-[7px]">
                Decline
              </Button>
            </div>
          )}
        </div>
      )
    }
    if (data.result.status === 'Approve') {
      return (
        <div className="pending-continer">
          <div
            className="bg-green-100 border mt-2 border-green-400 text-green-700 px-4 py-3 rounded relative text-[14px] max-w-2xl"
            role="alert"
          >
            <div className="font-variation-settings-600">
              Draw request is Approved by <span className="underline">{data.result.by}</span> on{' '}
              {formatTime(data.result.time)} and <span className="font-bold">Approved</span> now.
            </div>
          </div>
        </div>
      )
    }
    if (data.result.status === 'Decline') {
      return (
        <div className="pending-continer">
          <div
            className="bg-red-100 border mt-2 border-red-400 text-red-700 px-4 py-3 rounded relative text-[14px] max-w-2xl"
            role="alert"
          >
            <div className="font-variation-settings-600">
              Draw request is Declined by <span className="underline">{data.result.by}</span> on{' '}
              {formatTime(data.result.time)} and <span className="font-bold">Declined</span> now.
            </div>
          </div>
          <div className="mt-4 text-[15px]">
            Decline Reason: <span className="ml-1 font-semibold italic">{data.result.declineReason}</span>
          </div>
          <div className="mt-4 flex text-[15px] gap-4">
            <div>Decline Documents:</div>
            <div>
              {data.result.files?.map((file: string, index: number) => {
                return (
                  <div
                    className="text-[13.5px] mb-2 font-semibold italic text-shade-blue cursor-pointer hover:underline"
                    onClick={() => {
                      openS3Document(file)
                    }}
                    key={index}
                  >
                    {index + 1}. {getFileName(file)}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
    }
  }, [data.result, action, showDecline, inputs])

  const renderContent = () => {
    return (
      <div className="Overview-container">
        <div className="shadow1 mb-6 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w-full">
          <div className="mt-1 flex flex-wrap gap-x-6 gap-y-4">
            <div className="detail-container">
              <div className="border-b font-semibold text-[15px] mb-4">
                Details ({orderID}-{data.requestID})
              </div>
              <div className="overflow-auto shadow-md rounded">
                <table className="w-full text-[14px]">
                  <tbody>
                    {data.info.map((item: any, index: number) => {
                      let { title } = item
                      let additionalTR = null
                      let bgCN = ''
                      if (title === 'Net Disbursement') {
                        title += ' to you'
                        bgCN = 'bg-yellow-100 text-[16px]'
                        additionalTR = (
                          <tr key={'addition'}>
                            <td className="border-b-[5px]" colSpan={2}></td>
                          </tr>
                        )
                      }
                      const isNumValue = !isNaN(Number(item.value))
                      return [
                        <tr key={index} className={`${index % 2 === 0 && 'bg-gray-50'}`}>
                          <td className="px-6 py-3">{title}</td>
                          <td className={`px-6 py-3 font-semibold ${bgCN}`}>
                            {isNumValue ? `$${getPrice2decimal(item.value, false, true)}` : item.value}
                          </td>
                        </tr>,
                        additionalTR,
                      ]
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="attach-container">
              <div className="border-b font-semibold text-[15px] mb-4">Attachments</div>
              <div className="overflow-auto shadow-md rounded mb-4">
                <table className="w-full text-[14px]">
                  <thead className="font-medium text-gray-600 bg-gray-100">
                    <tr>
                      <th className="px-6 py-4">No</th>
                      <th className="px-6 py-4">Document Name</th>
                      <th className="px-6 py-4">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.files.map((item: any, index: number) => {
                      return (
                        <tr key={index} className={`${index % 2 && 'bg-gray-50'}`}>
                          <td className="px-6 py-3">{index + 1}</td>
                          <td className="px-6 py-3">{item.name}</td>
                          <td className="px-6 py-3">
                            <button
                              className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                              onClick={() => openS3Document(item.fileKey)}
                            >
                              <EyeIcon className="w-4 h-4" />
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="border-b font-semibold text-[15px] mb-4">Status</div>
              {statusFragment}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!validated) return <LayoutLoading show />

  return (
    <TemporaryLayout noOverview={true}>
      <div className="mt-6 relative">
        <Overview hasBackButton={false} title="Budget Ledger Review" />
        {renderContent()}
        <LayoutLoading show={loading} />
      </div>
    </TemporaryLayout>
  )
}
