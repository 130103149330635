import type { InputType } from 'config'
import { Appraisal2StatusType } from 'pages/Loan/OrderAppraisals/types'

export interface ILoanGlobalSettings {
  minProposedLoanAmount: number
  maxProposedLoanAmount: number
  minTotalConstructionFinanced: number
  maxTotalConstructionFinanced: number
  minConstructionFinancedHoldback: number
  maxConstructionFinancedHoldback: number
  maxPercentOfEstimatedClosingAmountToBorrower: number
  minCreditScore: number
  minBrokerFeePercent: number
  maxBrokerFeePercent: number
  minBrokerFeeAmount: number
  minCommitmentFeeRequiredTypes: {
    productType: string
    transactionType: string
    minCommitmentFee: number
  }[]
  defaultUnderwritingFee: number
  priceDisplay: string
  appraisalStatisticsVisible: Record<string, boolean>
  showingTermSheetPlaceholders: string[]
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    loanSection: {
      inputType: 'section',
      title: 'Loan',
      span: 'full',
    },
    minProposedLoanAmount: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Min Proposed Loan Amount',
      value: '',
      required: true,
    },
    maxProposedLoanAmount: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Max Proposed Loan Amount',
      value: '',
      required: true,
    },
    minTotalConstructionFinanced: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Min Total Construction Financed',
      value: '',
      required: true,
    },
    maxTotalConstructionFinanced: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Max Total Construction Financed',
      value: '',
      required: true,
    },
    minConstructionFinancedHoldback: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Min Construction Financed Holdback',
      value: '',
      required: true,
    },
    maxConstructionFinancedHoldback: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Max Construction Financed Holdback',
      value: '',
      required: true,
    },
    maxPercentOfEstimatedClosingAmountToBorrower: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Max Percent of Estimated Closing Amount to Borrower',
      value: '',
      required: true,
    },
    minCreditScore: {
      inputType: 'text',
      type: 'number',
      title: 'Min Credit Score',
      value: '',
      required: true,
    },
    minBrokerFeeAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Min Broker Fee Amount',
      value: '',
      prefix: '$',
      required: true,
    },
    minCommitmentFeeAmount: {
      inputType: 'text',
      type: 'text',
      title: 'Min Commitment Fee Amount',
      value: '',
      readOnly: true,
    },
    minBrokerFeePercent: {
      inputType: 'text',
      type: 'number',
      prefix: '%',
      title: 'Min Broker Fee Percent',
      value: '',
      required: true,
    },
    maxBrokerFeePercent: {
      inputType: 'text',
      type: 'number',
      title: 'Max Broker Fee Percent',
      value: '',
      prefix: '%',
      required: true,
    },
    defaultUnderwritingFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Default Underwriting Fee',
      value: '',
      prefix: '$',
      required: true,
    },
    priceDisplay: {
      inputType: 'select',
      title: 'Price Display',
      options: ['Price', 'Points'],
      hasDefaultOption: true,
      required: true,
    },
    appraisalSection: {
      inputType: 'section',
      title: 'Appraisal',
      span: 'full',
    },
    appraisalStatisticsVisible: {
      inputType: 'multiselect',
      title: 'Visible Appraisal Status',
      options: Appraisal2StatusType,
      defaultSelected: false,
      value: {},
      required: true,
    },
  }
}
