import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { pendingChangeRequestTypes } from 'pages/LoanStructure/constant'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getLoanChangeRequest, postLoanChangeRequest } from 'services'
import { Button, Modal, TextArea } from 'stories/components'
import { formatTime } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export function LoanChangeRequest() {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [changeDetail, setChangeDetail] = useState('')
  const [action, setAction] = useState('')
  const [data, setData] = useState<Array<Record<string, any>>>([])

  useEffect(() => {
    setLoading(true)
    setLoanNumber()
    getLoanChangeRequest().then(({ data = [] }) => {
      const _data = data.reverse()
      setData(_data)
      setLoading(false)
    })
  }, [])

  const addRequest = () => {
    setChangeDetail('')
    setIsOpen(true)
  }
  const onConfirmRequest = async () => {
    if (changeDetail.length === 0) {
      return toast('Loan Change Detail is Required!', { type: 'error' })
    }
    setAction('onConfirmRequest')
    const res = await postLoanChangeRequest({ detail: changeDetail })
    let _data = cloneDeep(data)
    _data = [res.data, ..._data]
    setData(_data)
    setAction('')
    setIsOpen(false)
    return true
  }

  const renderByWithStatus = (item: any) => {
    try {
      if (item.status === 2) {
        return (
          <span className="statusColor">
            <div className="">
              <div className="my-3">{item.by}</div>
              <div className="p-1 rounded complete">
                Completed
                <br />
                {item.completedBy}
                <br />
                {formatTime(item.completedDate)}
              </div>
            </div>
          </span>
        )
      }
      if (item.status === 3) {
        return (
          <span className="statusColor">
            <div className="">
              <div className="my-3">{item.by}</div>
              <div className="p-1 rounded Cancelled">
                Cancelled
                <br />
                {item.completedBy}
                <br />
                {formatTime(item.completedDate)}
              </div>
            </div>
          </span>
        )
      }
      if (item.status === 4) {
        return (
          <span className="statusColor">
            <div className="">
              <div className="my-3">{item.by}</div>
              <div className="p-1 rounded Cancelled">
                Declined
                <br />
                {item.completedBy}
                <br />
                {formatTime(item.completedDate)}
              </div>
            </div>
          </span>
        )
      }
    } catch {}
    return item.by
  }

  return (
    <div className="LoanChangeRequest-container px-2 py-6">
      <Overview title="Loan Change Request" />
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-4 p-4">
          <LayoutLoading show={loading} />
          <div className="flex gap-4 my-4 items-end justify-between">
            <div className="ml-2 text-[18px] font-variation-settings-600">Requests</div>
            <div className="">
              <Button onClick={addRequest}>Add Request</Button>
            </div>
          </div>
          <div className="parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Task No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Assigned To
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <div className="w-fit border-b border-b-gray-300 mb-2">Description</div>
                    Note
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Requested by
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Requested Date
                  </th>
                </tr>
              </thead>
              <tbody className="text-[14px] text-gray-900">
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-6 py-3">{item.taskNo}</td>
                      <td className="px-6 py-3">
                        {item.type === 1 ? 'All Lock Desk' : 'Admin, Lock Desk, UW Manager'}
                      </td>
                      <td className="px-6 py-3">
                        <div className="border-b border-b-gray-300 mb-2 font-semibold w-fit">
                          {pendingChangeRequestTypes[item.type]}
                        </div>
                        <span dangerouslySetInnerHTML={{ __html: item.detail }} />
                      </td>
                      <td className="px-6 py-3">{renderByWithStatus(item)}</td>
                      <td className="px-6 py-3">{formatTime(item.time)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        button={<span></span>}
        title={`Loan Change Request`}
        titleOkay="Confirm"
        titleCancel="Close"
        isOpen={isOpen}
        lastUpdatedAt={Date.now()}
        loading={action === 'onConfirmRequest'}
        onOk={onConfirmRequest}
        onClose={() => setIsOpen(false)}
      >
        <div className="md:w-[560px]">
          <TextArea
            title={'Change Request Detail'}
            key={'detail'}
            rows={6}
            value={changeDetail}
            required={true}
            onChange={(value) => setChangeDetail(value)}
          />
        </div>
      </Modal>
    </div>
  )
}
