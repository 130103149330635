import {
  BorrowerAmortizationSchedule,
  BorrowerBudgetScopeOfWork,
  BorrowerConstructionLedger,
  // BorrowerCreateLoan,
  BorrowerDrawRequest,
  BorrowerEditProfile,
  BorrowerLoanApplication,
  BorrowerLoanOverview,
  BorrowerLoanSubmission,
  BorrowerPipeline,
} from 'pages/Borrower'
import {
  BorrowerEditBudget,
  CustomSignDocumentGenie,
  LoanServicingExtensionSign,
  OrderValuationFeasibilitySign,
  PurchaseAdviceSellerSign,
  SignClosingCalendar,
} from 'pages/Loan'
import { CustomMemberSignApplication } from 'pages/LoanApplication/AdditionalMember/CustomMemberSignApplication'
import { AssetInformationVerificationPage } from 'pages/LoanApplication/AssetInformation/AssetInformationVerificationPage'
import { CustomSignApplication } from 'pages/LoanApplication/CustomSignApplication'
import { SignLoanTermSheet } from 'pages/LoanStructure/SignLoanTermSheet'
import { BrokerVerificationForUser } from 'pages/ManageAccounts'
import { MakePaymentPage } from 'pages/Payment'
import { ContractorSignApplication, SurveyPage, TrustLedgerAgreement } from 'pages/Temporary'
import { Route } from 'react-router-dom'

export const TemporaryRoute = [
  <Route exact path="/borrowerPipelines" key={0}>
    <BorrowerPipeline />
  </Route>,
  <Route exact path="/borrowerPipelines/:token" key={0}>
    <BorrowerPipeline />
  </Route>,
  <Route exact path="/borrowerPipeline/overview/:loanNumber" key={0}>
    <BorrowerLoanOverview />
  </Route>,
  <Route exact path="/borrowerPipeline/application/:loanNumber" key={0}>
    <BorrowerLoanApplication />
  </Route>,
  <Route exact path="/borrowerPipeline/submit/:loanNumber" key={0}>
    <BorrowerLoanSubmission />
  </Route>,
  <Route exact path="/borrowerPipeline/budget/:loanNumber" key={0}>
    <BorrowerBudgetScopeOfWork />
  </Route>,
  <Route exact path="/borrowerPipeline/assetInformationVerification/:loanNumber" key={11}>
    <AssetInformationVerificationPage />
  </Route>,
  <Route exact path="/borrowerPipeline/amortizationSchedule/:loanNumber" key={11}>
    <BorrowerAmortizationSchedule />
  </Route>,
  <Route exact path="/borrowerPipeline/drawRequest/:loanNumber" key={11}>
    <BorrowerDrawRequest />
  </Route>,
  <Route exact path="/borrowerPipeline/constructionLedger/:loanNumber" key={11}>
    <BorrowerConstructionLedger />
  </Route>,
  // <Route exact path="/borrowerPipeline/new" key={11}>
  //   <BorrowerCreateLoan />
  // </Route>,
  <Route exact path="/borrowerEditProfile" key={11}>
    <BorrowerEditProfile />
  </Route>,

  <Route exact path="/customSignApplication/:token/:loanNumber" key={0}>
    <CustomSignApplication />
  </Route>,
  <Route exact path="/customSignDocumentGenie/:token/:loanNumber" key={1}>
    <CustomSignDocumentGenie />
  </Route>,
  <Route exact path="/brokerVerification/:urlSignature/:token" key={2}>
    <BrokerVerificationForUser />
  </Route>,
  <Route exact path="/customMemberSignApplication/:urlSignature/:token/:loanNumber" key={3}>
    <CustomMemberSignApplication />
  </Route>,
  <Route exact path="/orderValuationFeasibilitySign/:id/:token/:loanNumber" key={4}>
    <OrderValuationFeasibilitySign />
  </Route>,
  <Route exact path="/purchaseAdviceSellerSign/:id/:token/:loanNumber" key={5}>
    <PurchaseAdviceSellerSign />
  </Route>,
  <Route exact path="/budget/:urlSignature/:token/:loanNumber" key={6}>
    <BorrowerEditBudget />
  </Route>,
  <Route exact path="/trustLedgerAgreement/:urlSignature/:token/:orderID/:loanNumber" key={7}>
    <TrustLedgerAgreement />
  </Route>,
  <Route exact path="/loanServicingExtension/:id/:token/:loanNumber" key={8}>
    <LoanServicingExtensionSign />
  </Route>,
  <Route exact path="/contractorSignApplication/:urlSignature/:token/:no/:loanNumber" key={9}>
    <ContractorSignApplication />
  </Route>,
  <Route exact path="/makePayment/:token/:loanNumber" key={10}>
    <MakePaymentPage />
  </Route>,
  <Route exact path="/assetInformationVerification/:token/:loanNumber" key={11}>
    <AssetInformationVerificationPage />
  </Route>,
  <Route exact path="/survey/:token/:loanNumber" key={12}>
    <SurveyPage />
  </Route>,
  <Route exact path="/signLoanTermSheet/:token/:loanNumber" key={10}>
    <SignLoanTermSheet />
  </Route>,
  <Route exact path="/signClosingCalendar/:token/:loanNumber" key={10}>
    <SignClosingCalendar />
  </Route>,
]
