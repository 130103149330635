import { CheckBadgeIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { companyName } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { checkPassport, resetPassword } from 'services'
import { Button, Input2 } from 'stories/components'
import { useTitle } from 'utils/pageTitle'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  password: '',
  confirm: '',
}

export function ResetPassword() {
  useTitle(`Reset Password - ${companyName}`)
  const [isLoading, setLoading] = useState(false)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [submitted, setSubmitted] = useState(false)
  const urlParams: any = useParams()
  const { selector, token } = urlParams
  const [errors, setErrors] = useState({
    password: '',
    confirm: '',
  })

  const auth = useSelector((state: any) => state.auth)
  const navigate = useHistory()

  useEffect(() => {
    if (auth.isAuthenticated || !selector || !token) {
      navigate.push('/login')
      return
    }
    setLoading(true)
    checkPassport(selector, token)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        toast('Invalid token', { type: 'warning' })
        navigate.push('/login')
      })
  }, [])

  const onSubmit = async () => {
    if (!selector || !token) return

    const errors = {
      password: '',
      confirm: '',
    }
    if (!inputStates.password) errors.password = 'Required'
    else if (inputStates.password.length < 6) errors.password = 'Must be at least 6 characters'
    else if (!inputStates.confirm) errors.confirm = 'Required'
    else if (inputStates.password && inputStates.password != inputStates.confirm)
      errors.confirm = 'Not same as New Password'
    if (errors.password || errors.confirm) {
      setErrors(errors)
      return
    }

    setLoading(true)
    resetPassword(selector, token, inputStates.password)
      .then(() => {
        setSubmitted(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    let newState: any = cloneDeep(inputStates)
    let newErrors: any = cloneDeep(errors)
    newErrors[key] = ''
    newState[key] = value
    setInputStates(newState)
    setErrors(newErrors)
  }

  return (
    <div className="reset-pass-container w-full bg-stone-100 flex justify-center">
      <div className="max-w-screen-2xl py-10 px-5">
        <div className="bg-white rounded pl-6 pr-6 pt-8 pb-6 relative">
          <LayoutLoading show={isLoading} />
          <p className="text-stone-800 text-xl font-bold mb-4 md:min-w-[360px]">Reset Password</p>
          {submitted ? (
            <div
              className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4 text-[15.5px]"
              role="alert"
            >
              <div className="flex justify-center mb-3">
                <CheckBadgeIcon className="w-10 h-10"></CheckBadgeIcon>
              </div>
              <div className="max-w-xl">Your password has been reset successfully!</div>
            </div>
          ) : (
            <div>
              <Input2
                type="password"
                title="New Password"
                value={inputStates.password}
                required
                error={errors.password}
                onChange={(value) => onChange('password', value)}
              />
              <div className="my-4"></div>
              <Input2
                type="password"
                title="Confirm Password"
                value={inputStates.confirm}
                required
                error={errors.confirm}
                onChange={(value) => onChange('confirm', value)}
              />
              <div className="my-7"></div>

              <Button className="mt-1" color="sky" full bold onClick={onSubmit} loading={isLoading}>
                Reset
              </Button>
            </div>
          )}
          <Link to="/login">
            <p className="mt-3 hover:text-gray-900 hover:underline text-gray-500 flex items-center gap-1">
              <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
              Back to Login
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}
