import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services/apis/admin'
import { TextArea } from 'stories/components'

export const SettingKeyContractorDescription = 'CONTRACTOR_DESCRIPTION'

export const ContractorApprovalContent = () => {
  const [isLoading, setLoading] = useState(true)
  const [value, setValue] = useState('')

  useEffect(() => {
    getSetting(SettingKeyContractorDescription)
      .then(({ value }) => setValue(value))
      .finally(() => setLoading(false))
  }, [])

  const onSave = () => {
    setLoading(true)
    updateSetting(SettingKeyContractorDescription, value).finally(() => setLoading(false))
  }

  return (
    <div className="relative mt-4">
      <LayoutLoading show={isLoading} />
      <TextArea
        title="Contractor Document Description"
        value={value}
        onChange={(v) => setValue(v)}
        onBlur={onSave}
        rows={10}
      />
    </div>
  )
}
