import {
  ArrowPathRoundedSquareIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { setBorrowerGroupData, setLoanDetail } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { usePermissions } from 'hooks/usePermissions'
import { loanOverviewAndStepLogic } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getBorrowerSignStatus, swapBorrowers, updateLoanFields } from 'services'
import { svgLoading } from 'stories/assets'
import { Toggle } from 'stories/components'
import { canEditLoanApplication, confirm, openAuditLog } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AdditionalMember } from './AdditionalMember'
import { AssetInformation } from './AssetInformation'
import { BorrowerInformation } from './BorrowerInformation'
import { CreditScore } from './CreditScore'
import { DeclarationsHmda } from './DeclarationsHmda'
import { Employment } from './Employment'
import { getAppBorrowerMenu, getAppCoBorrowerMenu } from './logic'
import { PropertyInformation } from './PropertyInformation'
import { SignApplication } from './SignApplication'
import { signAppValidate } from './SignApplication/logic'
import { SwapBorrowerDialog } from './SwapBorrowerDialog'
import { TrackRecord } from './TrackRecord'

export function LoanApplication(props: {
  step?: string | null
  borrowerSeperator?: string | null
  borrowerApplication?: boolean
  changeBorrowerMenu?: Function
}) {
  const { step, borrowerSeperator, borrowerApplication, changeBorrowerMenu } = props
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('')
  const [selectedMenu, setSelectedMenu] = useState('')
  const [menuIndex, setMenuIndex] = useState(0)
  const [appBorrowerMenu, setAppBorrowerMenu] = useState<Record<string, string>>({})
  const [appCoborrowerMenu, setAppCoborrowerMenu] = useState<Record<string, string>>({})

  const [isShowSwap, setShowSwap] = useState(false)

  const { hasEntityTitle, loanDetail, stepApplication } = useSelector((state: any) => {
    return {
      loanDetail: state.loanDetail,
      stepApplication: state.step.application,
      hasEntityTitle: state.borrower.borrower.hasEntityTitle,
    }
  })
  const dispatch = useDispatch()

  const { hasPermission } = usePermissions()

  const setMenu = (index: number, menuItem: string) => {
    setMenuIndex(index)
    setSelectedMenu(menuItem)
  }

  const firstBorrowerMenuKey = useMemo(() => {
    return Object.keys(appBorrowerMenu)[0]
  }, [appBorrowerMenu])

  const lastBorrowerMenuKey = useMemo(() => {
    const length = Object.keys(appBorrowerMenu).length
    return Object.keys(appBorrowerMenu)[length - 1]
  }, [appBorrowerMenu])

  const lastCoBorrowerMenuKey = useMemo(() => {
    const length = Object.keys(appCoborrowerMenu).length
    return Object.keys(appCoborrowerMenu)[length - 1]
  }, [appCoborrowerMenu])

  const renderPreNext = useMemo(() => {
    let orderMenuKeys: any = {}
    let currentOrder = -1
    const mapKeys = { ...appBorrowerMenu, ...appCoborrowerMenu }

    Object.keys(mapKeys).map((key: string, index: number) => {
      orderMenuKeys[index] = key
      if (key === selectedMenu) currentOrder = index
    })

    let prevKey = '',
      nextKey = '',
      prevMenu = 0,
      nextMenu = 0
    try {
      prevKey = orderMenuKeys[currentOrder - 1]
      prevMenu = currentOrder > Object.keys(appBorrowerMenu).length ? 1 : 0
    } catch {}
    try {
      nextKey = orderMenuKeys[currentOrder + 1]
      nextMenu = currentOrder > Object.keys(appBorrowerMenu).length - 2 ? 1 : 0
    } catch {}

    let showLeft = true
    let showRight = true
    if (menuIndex === 0 && selectedMenu === firstBorrowerMenuKey) showLeft = false
    if (menuIndex === 0 && selectedMenu === lastBorrowerMenuKey && loanDetail.includeCoborrower === false)
      showRight = false
    if (menuIndex === 1 && selectedMenu === lastCoBorrowerMenuKey) showRight = false
    const cn = `px-9 py-1 border border-shade-blue rounded cursor-pointer text-shade-blue hover:bg-shade-blue hover:text-white`
    return (
      <div className="flex flex-wrap gap-4 justify-center my-5">
        {showLeft && (
          <div className={cn} onClick={() => setMenu(prevMenu, prevKey)}>
            <span className="">
              <ChevronLeftIcon className="w-6 h-6"></ChevronLeftIcon>
            </span>
          </div>
        )}
        {showRight && (
          <div className={cn} onClick={() => setMenu(nextMenu, nextKey)}>
            <span className="">
              <ChevronRightIcon className="w-6 h-6"></ChevronRightIcon>
            </span>
          </div>
        )}
      </div>
    )
  }, [menuIndex, selectedMenu, loanDetail.includeCoborrower])

  const renderFragment = useMemo(() => {
    if (menuIndex == 0) {
      switch (selectedMenu) {
        case 'property':
          return <PropertyInformation setLoading={setLoading} />

        case 'borrower':
          return <BorrowerInformation key={selectedMenu} borrowerSeperator="borrower" setLoading={setLoading} />

        case 'asset':
          return <AssetInformation setLoading={setLoading} />

        case 'employment':
          return <Employment />

        case 'track':
          return <TrackRecord setLoading={setLoading} />

        case 'hmda':
          return <DeclarationsHmda key={selectedMenu} borrowerSeperator="borrower" setLoading={setLoading} />

        case 'credit':
          return <CreditScore key={selectedMenu} borrowerSeperator="borrower" setLoading={setLoading} />

        case 'sign':
          return (
            <SignApplication
              key={selectedMenu}
              borrowerSeperator="borrower"
              setLoading={setLoading}
              changeBorrowerMenu={changeBorrowerMenu}
            />
          )

        case 'additionalMember':
          return <AdditionalMember borrowerSeperator="borrower" setLoading={setLoading} />

        default: {
          return <div>Comming Soon...</div>
        }
      }
    } else if (menuIndex == 1) {
      switch (selectedMenu) {
        case 'borrower2':
          return <BorrowerInformation key={selectedMenu} borrowerSeperator="coBorrower" setLoading={setLoading} />

        case 'hmda2':
          return <DeclarationsHmda key={selectedMenu} borrowerSeperator="coBorrower" setLoading={setLoading} />

        case 'credit2':
          return <CreditScore key={selectedMenu} borrowerSeperator="coBorrower" setLoading={setLoading} />

        case 'sign2':
          return <SignApplication key={selectedMenu} borrowerSeperator="coBorrower" setLoading={setLoading} />

        default: {
          return <div>Comming Soon...</div>
        }
      }
    }
  }, [menuIndex, selectedMenu])

  useEffect(() => {
    let activeMenuIndex = 0
    // if (location.pathname.indexOf('customSignApplication') !== -1) activeMenuIndex = 5

    const tempBorrowerMenu = getAppBorrowerMenu(borrowerApplication)
    const tempCoborrowerMenu = getAppCoBorrowerMenu(borrowerApplication)

    setAppBorrowerMenu(tempBorrowerMenu)
    setAppCoborrowerMenu(tempCoborrowerMenu)

    if (step && borrowerSeperator) {
      activeMenuIndex = Number(step) - 1
      const index = borrowerSeperator == 'borrower' ? 0 : 1
      setMenu(index, index == 0 ? 'borrower' : 'borrower2')
      return
    }

    setMenu(0, Object.keys(tempBorrowerMenu)[activeMenuIndex])
  }, [])

  const renderMenu = (mIndex: number, menus: Record<string, string>) => {
    if (mIndex === 1 && loanDetail.includeCoborrower === false) return null
    return (
      <ul className="sidebar-items flex flex-col p-4 pb-10">
        {Object.keys(menus).map((item: string, index) => {
          const isActive = mIndex == menuIndex && selectedMenu === item
          if (!hasEntityTitle && item == 'additionalMember') return null
          return (
            <li
              key={index}
              onClick={() => {
                setMenu(mIndex, item)
              }}
              className="border-b py-2 flex justify-between items-center "
            >
              <p
                className={`flex-1 hover:underline cursor-pointer ${
                  isActive ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                }`}
              >
                <span>
                  {index + 1}. {menus[item as keyof typeof menus]}
                </span>
              </p>

              <span className="ml-2">
                {stepApplication[item] === 1 && (
                  <span className="text-shade-blue">
                    <CheckCircleIcon className="w-4 h-4"></CheckCircleIcon>
                  </span>
                )}
                {stepApplication[item] === -1 && (
                  <span className="text-red-800">
                    <XCircleIcon className="w-4 h-4"></XCircleIcon>
                  </span>
                )}
              </span>
            </li>
          )
        })}
      </ul>
    )
  }

  const changeIncludeCoborrower = async (value: boolean) => {
    setAction('changeIncludeCoborrower')

    const res = await getBorrowerSignStatus()

    if (res.success) {
      await dispatch(setBorrowerGroupData('borrower', res.data))
      signAppValidate('borrower', true)

      let continueChange = true
      if (res.confirmMessage?.length > 0) {
        const rlt = await confirm(res.confirmMessage)
        if (!rlt) continueChange = false
      }

      if (continueChange) {
        await updateLoanFields({
          includeCoborrower: value,
        })
        await dispatch(setLoanDetail({ includeCoborrower: value }))
        await dispatch(
          setBorrowerGroupData('borrower', {
            signedDate: '',
            signedPdfKey: '',
          }),
        )
        signAppValidate('borrower', true)
      }
    }
    setAction('')
  }

  const showHistory = () => {
    const options = {
      table: 'Loan',
      field: 'includeCoborrower',
      keys: {
        field: 'includeCoborrower',
      },
    }
    openAuditLog(options)
  }

  const onSwapBorrower = async () => {
    setShowSwap(true)
  }

  const onSubmitSwap = async (isReset: boolean) => {
    setLoading(true)
    await swapBorrowers(isReset)

    const loanNumber = setLoanNumber()
    await loanOverviewAndStepLogic(loanNumber, 'application')

    toast('Borrower and Co-Borrower are swapped successfully.', { type: 'success' })
    setLoading(false)
  }

  const canEdit = canEditLoanApplication()

  return (
    <div className="LoanApplication-container px-2">
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded mb-4 pb-6 min-h-[200px]">
          <div className="relative">
            <LayoutLoading show={action === 'changeIncludeCoborrower'} />
            {renderMenu(0, appBorrowerMenu)}
            <div className="border-b px-4 py-1 font-bold flex items-center relative">
              <span>Co-Borrower</span>
              <Toggle
                className="ml-6"
                id={'includeCoborrower'}
                title={''}
                key={'includeCoborrower'}
                history={true}
                disabled={!canEdit}
                showHistory={showHistory}
                value={loanDetail.includeCoborrower}
                onChange={changeIncludeCoborrower}
              />
            </div>
            {renderMenu(1, appCoborrowerMenu)}

            {hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION') && loanDetail.includeCoborrower && (
              <a onClick={onSwapBorrower} className="border-b py-2 flex gap-2 justify-between items-center mx-4">
                <ArrowPathRoundedSquareIcon className="w-5 h-5" />
                <p className={`flex-1 hover:underline cursor-pointer`}>
                  <span>Swap Borrower</span>
                </p>
              </a>
            )}
            {isShowSwap && <SwapBorrowerDialog onClose={() => setShowSwap(false)} onSubmit={onSubmitSwap} />}
          </div>
        </div>
        <div className="col-span-12 md:col-span-9">
          <div className="relative h-fit content-right bg-white p-4 rounded shadow1 mb-4">
            <LayoutLoading show={loading}></LayoutLoading>
            <h2 className="text-2xl font-bold flex items-center mb-3">
              {menuIndex == 0 ? (
                appBorrowerMenu[selectedMenu]
              ) : (
                <span>
                  {appCoborrowerMenu[selectedMenu]}
                  <span className="text-[16px] ml-2">(CoBorrower)</span>
                </span>
              )}
              <span className="text-base ml-3">
                {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
              </span>
            </h2>
            {renderFragment}
          </div>
          <div className="">{renderPreNext}</div>
        </div>
      </div>
    </div>
  )
}
