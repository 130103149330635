import { TemporaryLayout } from 'layouts'
import { AmortizationScheduleContent } from 'pages/Loan/AmortizationSchedule'
import { setLoanNumber } from 'utils/setLoanNumber'

export const BorrowerAmortizationSchedule = () => {
  setLoanNumber()

  return (
    <TemporaryLayout title="Amortization Schedule" requireSign>
      <AmortizationScheduleContent />
    </TemporaryLayout>
  )
}
