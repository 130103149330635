import { ClockIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { usePermissions } from 'hooks/usePermissions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { partiesChangePermissions, partiesMap } from 'reducers/loanDetail.reducer'
import { getPartyOptions, updateClosingDate, updateEmailContacts, updateParty } from 'services'
import { AutoComplete, CopyableText, Input2, Modal, Select2 } from 'stories/components'
import { openAuditLog, PermissionGate } from 'utils'
import { formatDate } from 'utils/convertor'

interface InputProps {
  id?: string
  type: 'party' | 'closingDate' | 'emailContacts'
}

export function LoanProcessSelection({ id = '', type }: InputProps) {
  const { loanDetail } = useSelector((state: any) => {
    return {
      loanDetail: state.loanDetail,
    }
  })
  const { loanNumber } = loanDetail

  const dispatch = useDispatch()

  let data: any
  if (type === 'party') data = loanDetail.parties[id]
  if (type === 'closingDate') data = loanDetail.closingDate
  if (type === 'emailContacts') data = cloneDeep(loanDetail.emailContacts)
  const [init, setInit] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState({})
  const [editingValue, setEditingValue] = useState('')
  const [titleOkay, setTitleOkay] = useState('')
  const showHistory = () => {
    let options
    if (type === 'party') {
      options = {
        table: 'LoanProcess',
        field: id,
        keys: {
          field: id,
          loanNumber: loanNumber,
        },
      }
    }
    if (type === 'closingDate') {
      options = {
        table: 'LoanProcess',
        field: 'closingDate',
        keys: {
          field: 'closingDate',
          loanNumber: loanNumber,
        },
      }
    }
    if (type === 'emailContacts') {
      options = {
        table: 'LoanProcess',
        field: 'emailContacts',
        keys: {
          field: 'emailContacts',
          loanNumber: loanNumber,
        },
      }
    }
    openAuditLog(options)
  }

  const editItem = async () => {
    setInit(true)
    setIsOpen(true)
    setTitleOkay('')
    setLastUpdatedAt(Date.now())
    let value = ''
    if (type === 'party') {
      value = data.id
      if (id === 'creator') value = data.email
    }
    if (type === 'closingDate') value = data
    if (type === 'emailContacts') value = data
    setEditingValue(value)
    if (type === 'party') {
      if (id !== 'creator') {
        const res = await getPartyOptions(id)
        setOptions(res.data)
      }
    }
    setInit(false)
  }

  const onChange = (value: string) => {
    setEditingValue(value)
    setTitleOkay('Update')
  }

  const onConfirmUpdate = async () => {
    setLoading(true)

    if (type === 'party') {
      let json: any = {
        key: id,
        value: Number(editingValue),
      }
      if (id === 'creator') json.value = editingValue
      const res = await updateParty(json)
      setLoading(false)
      if (res.success) {
        let temp = cloneDeep(loanDetail.parties)
        temp[id] = res.data
        dispatch(
          setLoanDetail({
            parties: temp,
          }),
        )
        return true
      }
    }
    if (type === 'closingDate') {
      const res = await updateClosingDate({
        key: 'closingDate',
        value: editingValue,
      })
      setLoading(false)
      if (res.success) {
        dispatch(
          setLoanDetail({
            closingDate: editingValue,
          }),
        )
        return true
      }
    }
    if (type === 'emailContacts') {
      const res = await updateEmailContacts({
        key: 'emailContacts',
        value: editingValue,
      })
      setLoading(false)
      if (res.success) {
        dispatch(
          setLoanDetail({
            emailContacts: editingValue,
          }),
        )
        return true
      }
    }
  }

  const getTitle = () => {
    if (type === 'party') return `Update Party - ${partiesMap[id]}`
    if (type === 'closingDate') return 'Update Closing Date'
    if (type === 'emailContacts') return 'Update Email Notification Contacts'
  }

  const renderContent = () => {
    if (type === 'party') {
      return (
        <div className="text-[14px]">
          <CopyableText>{data.email}</CopyableText>
        </div>
      )
    }
    if (type === 'closingDate') {
      return <span className="text-[14px]">{formatDate(data)}</span>
    }
    if (type === 'emailContacts') {
      return (
        <span className="text-[14px]">
          {data.map((item: string, index: number) => {
            return (
              <span key={index} className="mr-1">
                {item},
              </span>
            )
          })}
        </span>
      )
    }
    return null
  }

  const renderModalContent = () => {
    if (type === 'emailContacts') {
      return (
        <div className="w-[560px]">
          <AutoComplete
            type="email"
            title="Email Notification Contacts"
            placeholder="Your additional contact email here"
            value={editingValue}
            onChange={onChange}
          />
        </div>
      )
    }
    if (type === 'closingDate') {
      return (
        <div className="w-[480px]">
          <Input2 title={`Closing Date`} type="date" key={id} value={editingValue} onChange={onChange} />
        </div>
      )
    }
    if (type === 'party') {
      if (id === 'creator') {
        return (
          <div className="w-[480px]">
            <Input2
              title={'Input New Creator Email'}
              key={'creator'}
              type={'email'}
              value={editingValue}
              onChange={onChange}
              userSearch={true}
            />
          </div>
        )
      }
      return (
        <div className="w-[480px]">
          <Select2
            id={id}
            title={`Select ${partiesMap[id]}`}
            options={options}
            key={id}
            value={editingValue}
            hasDefaultOption={true}
            onChange={onChange}
            sort={true}
          />
        </div>
      )
    }
  }

  const { hasPermission } = usePermissions()

  const renderActions = () => {
    let rlt = (
      <span className="edit-container flex gap-2">
        <span className="hover:shadow cursor-pointer text-shade-blue">
          <PencilSquareIcon className="w-4 h-4" onClick={editItem}></PencilSquareIcon>
        </span>
        {hasPermission('CAN_VIEW_AUDIT_LOG') && (
          <span className="cursor-pointer invisible group-hover:visible hover:shadow text-gray-500">
            <ClockIcon className="w-4 h-4" onClick={showHistory} />
          </span>
        )}
      </span>
    )
    if (type === 'party') {
      if (partiesChangePermissions[id]) {
        return (
          <PermissionGate permission={partiesChangePermissions[id]} hidden={true}>
            {rlt}
          </PermissionGate>
        )
      }
      return rlt
    }
    if (type === 'closingDate') {
      return (
        <PermissionGate permission={'CAN_CHANGE_LOAN_CLOSING_DATE'} hidden={true}>
          {rlt}
        </PermissionGate>
      )
    }
    if (type === 'emailContacts') {
      return rlt
    }
  }

  return (
    <div className="LoanProcessSelection-container group">
      <div className="flex flex-wrap items-center gap-3 justify-between text-gray-900">
        <span className="font-variation-settings-600">{renderContent()}</span>
        {renderActions()}
      </div>
      <Modal
        button={<span></span>}
        title={getTitle()}
        titleOkay={titleOkay}
        titleCancel="Close"
        isOpen={isOpen}
        lastUpdatedAt={lastUpdatedAt}
        init={init}
        loading={loading}
        onOk={onConfirmUpdate}
      >
        <>{renderModalContent()}</>
      </Modal>
    </div>
  )
}
