import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { deleteInvestorServicer, getInvestorServicers } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, Input2 } from 'stories/components'
import { confirm } from 'utils'

import { InvestorServicerType } from './constants'
import { InvestorsServicerDetails } from './details'

export interface InvestorServicer {
  id: number
  investorCode: string
  fullName: string
  type: number
  data?: Record<string, any>
}

export function InvestorsServicers() {
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState<InvestorServicer[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [selectedItem, setSelectedItem] = useState<InvestorServicer | null>(null)

  useEffect(() => {
    refresh()
  }, [])

  const filteredList = useMemo(() => {
    if (!query) return list
    return list.filter(({ fullName, investorCode, type }) => {
      return [fullName, investorCode, InvestorServicerType[type]]
        .join(' ')
        .toLowerCase()
        .includes(query.trim().toLowerCase())
    })
  }, [query, list])

  const refresh = () => {
    setIsLoading(true)
    getInvestorServicers()
      .then((data) => {
        setTotal(data.length)
        setList(data)
      })
      .finally(() => setIsLoading(false))
  }

  if (isEditing)
    return <InvestorsServicerDetails defaults={selectedItem} onBack={() => setIsEditing(false)} onComplete={refresh} />

  const onAdd = () => {
    setSelectedItem(null)
    setIsEditing(true)
  }

  const onEdit = (item: InvestorServicer) => {
    setSelectedItem(item)
    setIsEditing(true)
  }

  const onTrash = async (item: InvestorServicer) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        <span className="text-gray-600">Name: {item.fullName}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deleteInvestorServicer(item.id)
      .then(refresh)
      .catch(() => setIsLoading(false))
  }

  return (
    <div className="List-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Investors & Servicers
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              onChange={(value) => setQuery(value)}
            />
          </div>
          <p className="ml-5">- {total} Items</p>
        </div>
        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="py-3 px-3">
                Code
              </th>
              <th scope="col" className="px-3">
                Name
              </th>
              <th scope="col" className="px-3">
                Type
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {filteredList.map((item, index) => {
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-3">{item.investorCode}</td>
                  <td className="px-3">{item.fullName}</td>
                  <td className="px-3">{InvestorServicerType[item.type]}</td>
                  <td className="px-3">
                    <span className="flex">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(item)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
