import { AUTH_TOKEN_SET } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { type InputType, COMPANY_NAME_FC } from 'config'
import jwtDecode from 'jwt-decode'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { borrowerLoanRequest, registerTemporaryUser } from 'services'
import { InputConvert } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { renderActions } from './components'
import { defaultInputs, ProductTypeOptions } from './constants'
import { InvestmentInformation, LastQuestions, LoanOptions, PersonalInformation } from './Steps'
import type { IBorrowerLoanRequestStepProps } from './Steps/interface'

const defaultKey = 'transactionType'
const lender = COMPANY_NAME_FC

export const BorrowerCreateLoan = () => {
  const [isLoading, setLoading] = useState(false)

  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  const [curKey, setCurKey] = useState(defaultKey)
  const navigate = useHistory()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => state.auth)
  const {
    isAuthenticated,
    profile: { temparary: isTemparary },
  } = auth

  useEffect(() => {
    if (isAuthenticated && !isTemparary) navigate.push('/pipeline')
  }, [])

  const onChange = (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)

    if (key == 'transactionType') {
      ;(newInputs.productType as any).options = ProductTypeOptions[value]
    }

    setInputs(newInputs)
    onMove(1, value)
  }

  const onMove = async (dir: 1 | -1, value: Record<string, any> = {}) => {
    const keys = Object.keys(inputs)
    const index = keys.indexOf(curKey)
    if (dir == -1 && index == 0) return
    if (curKey == 'lastQuestions' && dir == 1) {
      setLoading(true)
      if (!isAuthenticated) {
        const { token } = await registerTemporaryUser(allValueData.personalInformation)
        if (token) {
          const payload: any = jwtDecode(token)

          if (!payload) {
            navigate.push('/')
            return
          }

          dispatch({
            type: AUTH_TOKEN_SET,
            token,
            user: payload,
          })
        }
      }

      const { loanNumber } = await borrowerLoanRequest({
        ...allValueData,
        lastQuestions: value,
      })
      setLoading(false)
      navigate.push(`/borrowerPipeline/application/${loanNumber}`)
      return
    }

    setCurKey(keys[index + dir])
  }

  const allValues = useMemo(() => {
    return {
      transactionType: inputs.transactionType.value,
      productType: inputs.productType.value,
      ...inputs.personalInformation.value,
      ...inputs.investmentInformation.value,
      ...inputs.loanOptions.value,
      ...inputs.lastQuestions.value,
    }
  }, [inputs])

  const allValueData = useMemo(() => {
    return {
      transactionType: inputs.transactionType.value,
      productType: inputs.productType.value,
      personalInformation: inputs.personalInformation.value,
      investmentInformation: inputs.investmentInformation.value,
      loanOptions: inputs.loanOptions.value,
      lastQuestions: inputs.lastQuestions.value,
    }
  }, [inputs])

  const currentInput = useMemo(() => {
    const input = inputs[curKey]

    const componentMap: Record<string, any> = {
      personalInformation: PersonalInformation,
      investmentInformation: InvestmentInformation,
      loanOptions: LoanOptions,
      lastQuestions: LastQuestions,
    }
    const Component: (data: IBorrowerLoanRequestStepProps) => JSX.Element = componentMap[curKey]
    if (Component)
      (input as any).render = (value: any, onChange: Function) => {
        return (
          <Component
            lender={lender}
            values={value}
            allValues={allValues}
            onSubmit={onChange}
            onBack={() => onMove(-1)}
            key={curKey}
          />
        )
      }

    return input
  }, [curKey])

  return (
    <div>
      <LayoutLoading show={isLoading} />

      <div className="max-w-screen-2xl m-auto py-6">
        <p className="text-2xl mb-10 text-center">
          Welcome to your {lender} loan application, <span className="font-semibold">Lets get started!</span>
        </p>

        <div className="max-w-[40rem] mx-auto min-h-[32rem]">
          <RenderInput input={currentInput} Key={curKey} onChange={onChange} />

          {currentInput.inputType != 'custom' && renderActions(curKey == defaultKey ? null : () => onMove(-1), null)}
        </div>
      </div>
    </div>
  )
}
