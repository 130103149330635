import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

type Function = () => void

interface VideoModalProps {
  /**
   * Title
   */
  content?: string
  /**
   * Okay button handler
   */
  onOk?: Function
  /**
   * Cancel button handler
   */
  onCancel?: Function
  /**
   * Is Open
   */
  isOpen?: boolean
  lastUpdatedAt: number
  options?: Record<string, any>
}

/**
 * Primary UI component for user interaction
 */
export const VideoModal = ({
  content = '',
  onCancel = () => {},
  isOpen: parentIsOpen = false,
  lastUpdatedAt,
  options = {},
}: VideoModalProps) => {
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    setShowModal(parentIsOpen)
  }, [lastUpdatedAt])

  if (!showModal) return null

  const renderVideo = () => {
    if (content.startsWith('https://www.youtube.com/'))
      return <iframe src={content} width="100%" className="aspect-video" />

    return <video src={content} className="w-full aspect-video" controls />
  }

  return (
    <>
      <div
        tabIndex={-1}
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-40 md:inset-0 h-modal md:h-full justify-center items-center flex"
      >
        <div className="relative p-4 max-w-4xl w-10/12 h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/*header*/}
            {options.title ? (
              <div className="flex items-start justify-between p-5 pb-3 border-b border-solid border-slate-200 rounded-t">
                <h3 className="flex items-center text-xl font-semibold">
                  {options.title}
                  {/* {init && <img src={svgLoading} className="inline w-6 h-6 ml-3 text-white animate-spin" />} */}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 border bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={onCancel}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="absolute top-3 border border-gray-500 right-2.5 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={onCancel}
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
            )}
            <div className="p-6 text-center">{renderVideo()}</div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
    </>
  )
}
