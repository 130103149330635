import type { Dispatch } from 'react'

import { SET_APPLICATION_STEP, SET_OVERVIEW_STEP } from './types'

export function setOverviewStep(data: any) {
  return async (dispatch: Dispatch<any>) => {
    return dispatch({ type: SET_OVERVIEW_STEP, data })
  }
}

export function setApplicationStep(data: any) {
  return { type: SET_APPLICATION_STEP, data }
}
