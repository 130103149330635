import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { itemCountPerPage } from 'config'
import { fullStates } from 'config/states.constants'
import { useEffect, useState } from 'react'
import { deleteRolodex, getRolodexCategories, getRolodexes } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, Input2, Pagination, Select2 } from 'stories/components'
import { confirm, renderHeader } from 'utils'

import { RolodexDetails } from './RolodexDetails'
import type { IRolodex } from './types'

export function Rolodex() {
  const [total, setTotal] = useState(0)
  const [pageNum, setPageNum] = useState(0)
  const [filterQuery, setFilterQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState<Record<number, string>>({})
  const [items, setItems] = useState<Array<IRolodex>>([])
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IRolodex | null>(null)

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    category: 0,
    orderBy: 'createdAt',
    orderDir: '1',
  })

  useEffect(() => {
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [pageNum])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && filterData(filters), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = async (filters: Record<string, any>) => {
    setIsLoading(true)
    getRolodexCategories().then((data) => setCategories(data))
    getRolodexes({
      ...filters,
      skip: pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setTotal(total)
        setItems(data)
      })
      .finally(() => setIsLoading(false))
  }

  if (isEditing)
    return (
      <RolodexDetails
        defaults={selectedItem}
        categories={categories}
        onBack={() => setEditing(false)}
        onComplete={() => filterData(filters)}
      />
    )

  const onAdd = () => {
    setSelectedItem(null)
    setEditing(true)
  }

  const onEdit = (index: number) => {
    setSelectedItem(items[index])
    setEditing(true)
  }

  const onTrash = async (index: number) => {
    const { id, no } = items[index]
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this rolodex?
        <br />
        <span className="text-gray-600">Rolodex No: {no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deleteRolodex(id)
      .then(() => filterData(filters))
      .catch(() => setIsLoading(false))
  }

  const onSort = (key: string, dir: number) => {
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = `${dir}`
    setFilters(newFilters)
    filterData(newFilters)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onChangeFilter = (key: 'query' | 'category' | 'orderBy' | 'orderDir', value: string) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'category') setPageNum(0)
    if (key === 'query') setFilterQuery(value)
    else filterData(newFilters)
  }

  const sortableHeaders = [
    { title: 'First Name', key: 'FirstName' },
    { title: 'Last Name', key: 'LastName' },
    { title: 'Company', key: 'Company' },
    { title: 'State', key: 'State' },
    { title: 'Category', key: 'CategoryID' },
  ]

  return (
    <div className="Rolodexes-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Rolodex
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap flex-auto">
          <div className="w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={filterQuery}
              onChange={(value) => onChangeFilter('query', value)}
            />
          </div>
          <p className="ml-5 flex-auto">- {total} rolodexies</p>
          <div className="w-72">
            <Select2
              title="Filter by Category"
              options={categories}
              value={filters.category}
              hasDefaultOption
              defaultOptionText="All"
              id="filterByCategory"
              onChange={(value) => onChangeFilter('category', value)}
            />
          </div>
        </div>
        <div className="w-32 md:ml-5">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              {sortableHeaders.map(({ title, key }, index) =>
                renderHeader({
                  title,
                  sortable: true,
                  key,
                  sortOrder: filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                  index,
                  onSort: (key: string, dir: number) => onSort(key, dir),
                }),
              )}
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {items.map((item, index) => {
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-2 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {item.no}
                  </td>
                  <td className="px-2">{item.FirstName}</td>
                  <td className="px-2">{item.LastName}</td>
                  <td className="px-2">{item.Company}</td>
                  <td className="px-2">{(fullStates as any)[item.State]}</td>
                  <td className="px-2">{categories[item.CategoryID]}</td>
                  <td className="px-2">
                    <span className="flex">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(index)}>
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(index)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <div className="flex justify-end items-center mt-3 mb-3">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
