import { type InputType, COMPANY_NAME_FC } from 'config'

export const purchaseAdviceAccounting: Record<string, InputType> = {
  BasePrice: {
    title: 'Base Price',
    inputType: 'text',
    prefix: '%',
    readOnly: true,
    span: 2,
  },
  LoanTerm: {
    title: 'Loan Term (months)',
    inputType: 'text',
    readOnly: true,
    span: 2,
  },
  ProductType: {
    title: 'Product Type',
    inputType: 'text',
    readOnly: true,
    span: 2,
  },
  OriginalBalance: {
    title: 'Original Balance',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    readOnly: true,
    span: 2,
  },
  NoteRate: {
    title: 'Note Rate',
    inputType: 'text',
    prefix: '%',
    readOnly: true,
    span: 2,
  },
  BuyRate: {
    title: 'Buy Rate',
    inputType: 'text',
    type: 'number',
    prefix: '%',
    span: 2,
  },
  PAUnpaidPrincipalBalance: {
    title: 'Current Balance',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 6,
  },
  InterestCalculation: {
    title: 'Interest Calculation',
    inputType: 'select',
    hasDefaultOption: true,
    options: {
      Dutch: 'Dutch',
      NonDutch: 'Non Dutch',
    },
    visible: false,
    span: 2,
  },
  PAPremiumOrDiscountCalc: {
    title: 'Premium(Discount) Calculated',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 2,
    readOnly: true,
  },
  PAPremiumOrDiscountOV: {
    title: 'Premium(Discount) Override',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 2,
  },
  PANetPrice: {
    title: 'Net Price',
    inputType: 'text',
    type: 'number',
    prefix: '%',
    span: 2,
  },
  PA_SRPAmountCalc: {
    title: 'Net SRP Calculated',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 2,
    readOnly: true,
  },
  PA_SRPAmountOV: {
    title: 'Net SRP Override',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 2,
  },
  PA_SRPPerc: {
    title: 'Net SRP',
    inputType: 'text',
    type: 'number',
    prefix: '%',
    span: 2,
  },
  PARollFee: {
    title: 'Roll Fee (Lender Fee)',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  PAOtherFees: {
    title: 'Other Fee (Flood Cert Fee)',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  PAEscrowBalance: {
    title: 'Escrow Balance to Investor',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  APiece: {
    title: 'A Piece',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  BPiece: {
    title: 'B Piece',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  PariPassu: {
    title: 'Pari Passu',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  ConstructionReserve: {
    title: 'Construction Reserve',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  InterestReserve: {
    title: 'Interest Reserve',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
  PAInterestAmount: {
    title: 'Interest Paid Amount',
    inputType: 'text',
    tooltip: `(('Current/Original Balance' * 'Buy Rate') / 100 / 360) * 'Interest Paid Days'`,
    type: 'thousandSep',
    prefix: '$',
    span: 3,
    disabled: true,
  },
  PAInterestDays: {
    title: 'Interest Paid Days',
    inputType: 'text',
    tooltip: `Days between 'Paid to Date' and 'Settlement Date'`,
    type: 'number',
    span: 3,
    disabled: true,
  },
  PABuydownFunds: {
    title: 'Buydown',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
  },
  PAWireAmount: {
    title: 'Investor Wire Amount',
    inputType: 'text',
    tooltip: `'Current Balance' + <br/>'Premium(Discount) Calculated' + <br/>'Net SRP Calculated' + <br/>'Roll Fee (Lender Fee)' + <br/>'Other Fee (Flood Cert Fee)' + <br/>'Escrow Balance to Investor' + <br/>'A Piece' + <br/>'B Piece' + <br/>'Pari Passu' + <br/>'Construction Reserve' + <br/>'Interest Reserve' + <br/>'Interest Paid Amount' + <br/>'Buydown'`,
    type: 'thousandSep',
    prefix: '$',
    readOnly: true,
    span: 3,
  },
  WireAmountReceived: {
    title: 'Investor Wire Amount Received',
    inputType: 'text',
    type: 'thousandSep',
    prefix: '$',
    span: 3,
  },
}

export const purchaseAdviceGeneralInfos: Record<string, InputType> = {
  InvestorCode: {
    title: 'Investor',
    inputType: 'select',
    options: [],
    sort: true,
    hasDefaultOption: true,
  },
  FirstPaymentDate: {
    title: 'First Payment Date on Loan',
    inputType: 'text',
    type: 'date',
  },
  PurchaseAdviceBuyerPaidToDate: {
    title: 'Paid to Date',
    inputType: 'text',
    type: 'date',
  },
  AnnalyDueDate: {
    title: 'Next Payment Due Date',
    inputType: 'text',
    type: 'date',
  },
  InvestorFirstPaymentDate: {
    title: 'First Payment Due to Investor',
    inputType: 'text',
    type: 'date',
  },
  PurchaseAdviceBuyerSettlementDate: {
    title: 'Settlement Date',
    inputType: 'text',
    type: 'date',
  },
  LoanPurchasedDate: {
    title: 'Purchased Date',
    inputType: 'text',
    type: 'date',
  },
}

export const purchaseAdvicePostClosing: Record<string, InputType> = {
  [`First_Payment_Due_${COMPANY_NAME_FC}`]: {
    title: `First Payment Due ${COMPANY_NAME_FC}`,
    inputType: 'checkbox',
  },
  PCDiligenceNeeded: {
    title: 'Deligence Needed',
    inputType: 'checkbox',
  },
}
