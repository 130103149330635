import { TemporaryLayout } from 'layouts'
import { BudgetScopeOfWork } from 'pages/Loan'
import { setLoanNumber } from 'utils/setLoanNumber'

export const BorrowerBudgetScopeOfWork = () => {
  setLoanNumber()

  return (
    <TemporaryLayout title="Budget & Scope of Work" requireSign noShadow>
      <BudgetScopeOfWork isBorrower={true} noOverview />
    </TemporaryLayout>
  )
}
