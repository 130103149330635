import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getLoanSubmissionDocumentHistory } from 'services'
import { Modal } from 'stories/components'
import { formatTime } from 'utils'

export const DocumentHistoryModal = ({ document, onClose }: { document: any; onClose: Function }) => {
  const [isLoading, setLoading] = useState(true)
  const [history, setHistory] = useState<Record<string, any>[]>([])

  useEffect(() => {
    getLoanSubmissionDocumentHistory(document.key)
      .then((histories) => setHistory(histories))
      .finally(() => setLoading(false))
  }, [])

  return (
    <Modal title={`History - ${document.name}`} titleOkay="" onClose={() => onClose()} isOpen={true}>
      <div className="overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Value
              </th>
              <th scope="col" className="px-6 py-3">
                By
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {history.map((item, index: number) => {
              return (
                <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                  <td className="px-6 py-3">{index + 1}</td>
                  <td className="px-6 py-3">{item.type}</td>
                  <td className="px-6 py-3">{item.value}</td>
                  <td className="px-6 py-3">{item.createdBy}</td>
                  <td className="px-6 py-3">{formatTime(item.createdAt)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
