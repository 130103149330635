import type { InputType } from 'config'
import type { ParagraphComponent } from 'utils/RenderParagraph'

export const paragraph = (inputs: Record<string, InputType>): ParagraphComponent[][] => {
  return [
    [
      {
        input: { inputType: 'span', title: 'Date:' },
        needSplit: true,
      },
      {
        input: inputs.date,
        Key: 'date',
        className: 'flex items-end w-48 ml-1',
      },
    ],
    [
      {
        input: inputs.insuranceCompanyName,
        Key: 'insuranceCompanyName',
        className: 'flex items-end w-96',
      },
      {
        input: inputs.insuranceAddress,
        Key: 'insuranceAddress',
        className: 'flex items-end w-96 mt-3',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'Policy Number:' },
        className: 'w-96',
      },
      {
        input: inputs.policyNumber,
        Key: 'policyNumber',
        className: 'col-span-3 flex items-end w-96',
      },
      {
        input: { inputType: 'span', title: 'Name of Insured:' },
      },
      {
        input: inputs.borrowerName,
        Key: 'borrowerName',
        className: 'col-span-3 flex items-end w-96',
      },
      {
        input: { inputType: 'span', title: '' },
      },
      {
        input: inputs.propertyAddress,
        Key: 'propertyAddress',
        className: 'col-span-3 flex items-end w-96',
      },
    ],
    [
      {
        input: inputs.servicerName,
        Key: 'servicerName',
        className: 'w-96',
      },
      {
        input: inputs.lenderAddress,
        Key: 'lenderAddress',
        className: 'w-96 mt-4',
      },
    ],
  ]
}
