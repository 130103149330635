import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { getPrice1or2decimal } from 'utils'

export function HUD1_1200({
  inputs,
  data,
  ignoreEror,
  isNewFeeLoading,
  renderSectionHeader,
  renderHUD1Input,
  calculateValue,
  setIsNewFeeLoading,
  setIsAddCCModalOpen,
  pageType,
}: {
  inputs: Record<string, any>
  data: Record<string, any>
  ignoreEror: boolean
  isNewFeeLoading: boolean
  renderSectionHeader: Function
  renderHUD1Input: Function
  calculateValue: Function
  setIsNewFeeLoading: Function
  setIsAddCCModalOpen: Function
  pageType: string
}) {
  return (
    <>
      {renderSectionHeader('1200. Goverment Recording and Transfer Charges')}
      {inputs['govermentCharges']?.map((item: any) => {
        const { key, title, valueKey } = item
        return (
          <tr>
            <td className="p-2">
              {key}. {title}
            </td>
            <td className="p-2">{getPrice1or2decimal(calculateValue(valueKey))}</td>
          </tr>
        )
      })}
      {inputs['govermentChargesChilds']?.map((item: any) => {
        const { key, error } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
          </tr>
        )
      })}
      <tr>
        <td className="flex justify-between gap-2 p-2">
          <span>1202.</span>
          <div className="flex justify-between items-center gap-2">
            <span>Deed</span>
            <span>{calculateValue('Recording Fee Deed')}</span>
          </div>
          <div className="flex justify-between items-center gap-2">
            <span>Mortgage</span>
            <span>{calculateValue('Recording Fee Mortgage')}</span>
          </div>
          <div className="flex justify-between items-center gap-2">
            <span>Releases</span>
            <span>{calculateValue('Recording Fee Release')}</span>
          </div>
        </td>
      </tr>
      {inputs['transferTax']?.map((item: any) => {
        const { key, title, valueKey } = item
        return (
          <tr>
            <td className="p-2">
              {key}. {title}
            </td>
            <td className="p-2">{getPrice1or2decimal(calculateValue(valueKey))}</td>
          </tr>
        )
      })}
      {inputs['transferTaxChilds']?.map((item: any) => {
        const { key, error } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
          </tr>
        )
      })}
      <tr>
        <td className="flex justify-between gap-2 p-2">
          <span>1204. City/Country tax/stamps</span>
          <div className="flex justify-between items-center gap-2">
            <span>Deed</span>
            <span>{calculateValue('City/Country Deed')}</span>
          </div>
          <div className="flex justify-between items-center gap-2">
            <span>Mortgage</span>
            <span>{calculateValue('City/Country Mortgage')}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td className="flex justify-between gap-2 p-2">
          <span>1205. State tax/stamps</span>
          <div className="flex justify-between items-center gap-2">
            <span>Deed</span>
            <span>{calculateValue('State Deed')}</span>
          </div>
          <div className="flex justify-between items-center gap-2">
            <span>Mortgage</span>
            <span>{calculateValue('State Mortgage')}</span>
          </div>
        </td>
      </tr>
      {inputs['1206-1300']?.map((item: any) => {
        const { key, error } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2 flex flex-wrap justify-between gap-1" key={key}>
              {renderHUD1Input(item, key)}
            </td>
            <td className="p-2">
              {!data[item['pocKey']] && [3, 5, 99, '3', '5', '99'].includes(data[item['GFEBlockKey']])
                ? getPrice1or2decimal(
                    +data[item['feeBKey']] + (data[item['GFEBlockKey']] != 99 ? +data[item['feeSKey']] : 0),
                  )
                : ''}
            </td>
            {pageType == 'hud1Page2' && (
              <td className="p-2">
                {!data[item['pocKey']] && data[item['GFEBlockKey']] == 99
                  ? getPrice1or2decimal(data[item['feeSKey']])
                  : ''}
              </td>
            )}
          </tr>
        )
      })}
      <tr>
        <td className="flex items-center">
          <Button
            link
            color="blue"
            onClick={() => {
              setIsNewFeeLoading(true), setIsAddCCModalOpen(true)
            }}
            disabled={isNewFeeLoading}
          >
            Add new fee
          </Button>
          {isNewFeeLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-4 h-4 text-white animate-spin" />
            </span>
          )}
        </td>
      </tr>
    </>
  )
}
