import type { IRestriction } from 'pages/Resources'
import { useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import { LoanAmountDscrOverlay } from './LoanAmountDscrOverlay'
import { LoanPurposeDscrOverlay } from './LoanPurposeDscrOverlay'

const menus = {
  loanAmount: 'Loan Amount',
  loanPurpose: 'Loan Purpose',
}

export const DscrOverlay = ({ data }: { data: IRestriction }) => {
  const [selectedMenu, setMenu] = useState(menus.loanAmount)

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.loanAmount:
        return <LoanAmountDscrOverlay data={data} />
      case menus.loanPurpose:
        return <LoanPurposeDscrOverlay data={data} />
      default:
        return <></>
    }
  }, [selectedMenu, data])

  return (
    <div className="h-full">
      <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={(value) => setMenu(value)} />

      <div className="p-2 h-full">{renderContent}</div>
    </div>
  )
}
