import type { InputType } from 'config'

export const borrowerDocCategory = ['Identification', 'Track Record', 'Entity documents']

export const defaultInputs = (): Record<string, InputType> => ({
  no: {
    inputType: 'text',
    type: 'number',
    readOnly: true,
    title: 'No',
    span: 'full',
  },
  fullName: {
    inputType: 'text',
    title: 'Name',
    required: true,
  },
  email: {
    inputType: 'text',
    type: 'email',
    title: 'Email',
    required: true,
  },
  phone: {
    inputType: 'text',
    type: 'phone',
    title: 'Phone Number',
    required: true,
  },
  ssn: {
    inputType: 'text',
    type: 'ssn',
    title: 'Social Security Number',
    required: true,
  },
  presentAddress: {
    inputType: 'map',
    title: 'Present Address',
    required: true,
  },
  dateOfBirth: {
    inputType: 'text',
    type: 'date',
    title: 'Date of Birth',
    required: true,
  },
  orgPassword: {
    inputType: 'text',
    title: 'Default Password',
    readOnly: true,
    required: true,
  },
})

export const collapseInputs = (): Record<string, InputType> => ({
  general: {
    inputType: 'FileTable',
    title: 'General Files',
    required: false,
    filePath: `borrower/new`,
    span: 'full',
    showCategory: true,
    showStatus: false,
    multiple: true,
    categories: borrowerDocCategory,
    additionalActions: [],
    customFields: [
      {
        key: 'expirationDate',
        title: 'Expiration Date',
        content: (value: any, setValue: Function) => {
          return (
            <input
              className="px-0 border border-gray-200 text-[14px] w-[120px] py-0 placeholder-gray-500"
              value={value.expirationDate}
              type="date"
              onChange={(e) => setValue(e.target.value)}
            />
          )
        },
      },
    ],
  },
})
