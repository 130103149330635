import { useSelector } from 'react-redux'
import { CopyableText } from 'stories/components'

export const BorrowerDetails = () => {
  const { profile } = useSelector((state: any) => ({
    profile: state.auth.profile,
  }))

  const borrowerNo = (profile.no || '').toString().padStart(7, '0')

  return (
    <div className="text-[14px] text-left">
      <div className="flex mb-1">
        <div className="w-36 pl-2">Borrower ID:</div>
        <div className="font-bold ml-2">
          <CopyableText>{borrowerNo}</CopyableText>
        </div>
      </div>
      <div className="flex mb-1">
        <div className="font-bold ml-2">
          <CopyableText>{profile.fullName}</CopyableText>
        </div>
      </div>
      <div className="flex mb-1">
        <div className="font-bold ml-2">
          <CopyableText>{profile.presentAddress}</CopyableText>
        </div>
      </div>
    </div>
  )
}
