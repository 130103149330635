import { ArrowDownTrayIcon, CheckIcon } from '@heroicons/react/24/outline'
import { openS3Document } from 'services'

export enum AccountingFileType {
  Legacy,
  LOANS_HELD_FOR_SALE,
}

export const accountingFileTypes = [
  {
    type: AccountingFileType.Legacy,
    headers: ['Date', 'Description', 'Debit', 'Credit'],
    columns: 5,
    description: 'Bank Account Import',
    fileKey: '/v3/accountings/sample/format1.xlsx',
  },
  {
    type: AccountingFileType.LOANS_HELD_FOR_SALE,
    headers: [
      'Loan Number',
      'Prev. Servicer Loan #',
      'Name',
      'City',
      'State',
      'Assignment Date',
      'Maturity Date',
      'Primary Loan Purpose',
      '...',
    ],
    columns: 26,
    description: 'FCI Import',
    fileKey: '/v3/accountings/sample/LoanPortfolio.xlsx',
  },
]

export const ImportAccountingFileTypes = ({ activeIndex, onChange }: { activeIndex: number; onChange: Function }) => {
  return (
    <div className="grid w-128 grid-cols-2 gap-2 mb-8">
      {accountingFileTypes.map((type, index) => (
        <div
          className={`border rounded-md overflow-hidden p-4 aspect-video cursor-pointer hover:border-shade-blue ${
            activeIndex == index ? 'border-shade-blue' : ''
          }`}
          onClick={() => onChange(index)}
          key={type.description}
        >
          <div className="flex justify-between items-center">
            <p className="font-semibold">Format #{index + 1}</p>
            {activeIndex == index ? <CheckIcon className="w-5 h-5 text-shade-blue" /> : null}
          </div>
          <p className="text-xs mb-1 underline">{type.description}</p>

          <div>
            <p
              className="flex items-center gap-1 text-sm my-2 text-shade-blue hover:underline"
              onClick={() => openS3Document(type.fileKey, false)}
            >
              Download <ArrowDownTrayIcon className="h-4 w-4" />
            </p>
          </div>
          {/* <div className="flex items-center divide-x text-xs flex-wrap">
            {type.headers.map((v) => (
              <p className="p-1">{v}</p>
            ))}
          </div> */}
        </div>
      ))}
    </div>
  )
}
