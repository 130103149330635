import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

import type { IDscrFicoLeverageLimit } from '../../interface'
import { convertNullToBlankValueToDecimal } from '../../logic'

export const DscrFicoLeverageLimit = ({ data }: { data: IDscrFicoLeverageLimit[] }) => {
  return (
    <div className="h-full">
      {!!data.length ? (
        <table className="w-full text-center text-sm">
          <thead className="font-bold bg-gray-100">
            <tr>
              <th rowSpan={2} className="border p-1">
                FICO
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap">
                Loan Purpose
              </th>
              <th colSpan={2} className="border p-1">
                Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 w-[90px]">
                Min DSCR
              </th>
              <th rowSpan={2} className="border p-1 w-[100px]">
                Min Months Reserve
              </th>
              <th colSpan={2} className="border p-1">
                LTV
              </th>
              <th rowSpan={2} className="border p-1 w-[100px]">
                Max CLTV
              </th>
            </tr>
            <tr>
              <th className="border p-1 w-[140px]">From</th>
              <th className="border p-1 w-[140px]">To</th>
              <th className="border p-1 w-[90px]">Min</th>
              <th className="border p-1 w-[90px]">Max</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td rowSpan={3} className="border px-3 py-1">
                      {item.ficoRange.from} - {item.ficoRange.to}
                    </td>
                    <td className="border px-3 py-1">Purchase</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minDSCR.purchase}</td>
                    <td className="border px-3 py-1">{item.minMonthsReserve.purchase}</td>
                    <td className="border px-3 py-1">{item.LTV.purchase.ltv.min}</td>
                    <td className="border px-3 py-1">{item.LTV.purchase.ltv.max}</td>
                    <td className="border px-3 py-1">{item.LTV.purchase.cltv}</td>
                  </tr>
                  <tr>
                    <td className="border px-3 py-1">No-Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minDSCR.nocashout}</td>
                    <td className="border px-3 py-1">{item.minMonthsReserve.nocashout}</td>
                    <td className="border px-3 py-1">{item.LTV.nocashout.ltv.min}</td>
                    <td className="border px-3 py-1">{item.LTV.nocashout.ltv.max}</td>
                    <td className="border px-3 py-1">{item.LTV.nocashout.cltv}</td>
                  </tr>
                  <tr>
                    <td className="border px-3 py-1">Cashout</td>
                    {['min', 'max'].map((key, index1) => (
                      <td key={`${index}-${index1}`} className="border px-3 py-1">
                        {convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                      </td>
                    ))}
                    <td className="border px-3 py-1">{item.minDSCR.cashout}</td>
                    <td className="border px-3 py-1">{item.minMonthsReserve.cashout}</td>
                    <td className="border px-3 py-1">{item.LTV.cashout.ltv.min}</td>
                    <td className="border px-3 py-1">{item.LTV.cashout.ltv.max}</td>
                    <td className="border px-3 py-1">{item.LTV.cashout.cltv}</td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )}
    </div>
  )
}
