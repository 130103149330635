import { ChevronLeftIcon, ChevronRightIcon, XCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config'
import { StripeWithMethod } from 'pages/Payment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { PaymentOrderType, sendPaymentLink, StripeMethod } from 'services'
import { getPaymentData, StripeIntentPaidStatus, StripeIntentStatus } from 'services/apis/stripe'
import { Button, Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

export const StripePayModal = ({
  title,
  orderType,
  amount,
  refId,
  data = {},
  onClose,
}: {
  title: string
  orderType: PaymentOrderType
  amount: number
  refId: string
  data?: Record<string, any>
  onClose: Function
}) => {
  const { borrower, profile } = useSelector((state: any) => {
    return {
      borrower: state.borrower.borrower,
      profile: state.auth.profile,
    }
  })

  const [loading, setLoading] = useState(false)
  const [showPayForm, setShowPayForm] = useState(false)
  const [status, setStatus] = useState<StripeIntentStatus>()
  const [borrowerEmailInput, setBorrowerEmailInput] = useState<InputType>({
    inputType: 'text',
    type: 'email',
    title: 'Borrower Email',
    value: borrower.email,
    required: true,
    visible: true,
  })
  const [isCompleted, setCompleted] = useState(false)
  const [message, setMessage] = useState('')
  const loanNumber = setLoanNumber()

  useEffect(() => {
    setLoading(true)
    getPaymentData({ orderType, refId })
      .then(({ status, message }) => {
        const isCompleted = StripeIntentPaidStatus.includes(status)
        setCompleted(isCompleted)
        setStatus(status)
        setMessage(message)
        if (isCompleted) setShowPayForm(true)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChangeBorrowerEmail = (key: string, value: string) => {
    const newInput = cloneDeep(borrowerEmailInput)
    newInput.value = value
    newInput.error = InputValidate(newInput)
    setBorrowerEmailInput(newInput)
  }

  const onSendLinkToBorrower = async () => {
    setLoading(true)
    await sendPaymentLink({
      orderType,
      refId,
      loanNumber,
      amount,
      method: 'link',
      borrowerEmail: borrowerEmailInput.value,
      ...data,
    })
      .then(() => {
        toast('Email with payment link is sent successfully.', { type: 'info' })
      })
      .finally(() => setLoading(false))
    setLoading(false)
  }

  const onPayNow = () => {
    setShowPayForm(true)
  }

  const onPayCompleted = async (intentId: string, status: any) => {
    // setStatus(status)
    setCompleted(StripeIntentPaidStatus.includes(status))
    // setLoading(true)
    // sendLoanProcRushPaymentData({
    //   requestType,
    //   customFee: removeComma(customFeeInput.value),
    //   feeTitle: feeTitleInput.value,
    //   method: 'pay',
    //   intentId,
    // }).finally(() => setLoading(false))
  }

  return (
    <Modal isOpen title={title} titleOkay="" onClose={() => onClose(status == StripeIntentStatus.succeeded)} hiddenX>
      <div className="w-196">
        <LayoutLoading show={loading} />
        {!showPayForm ? (
          <>
            {status &&
              [StripeIntentStatus.payment_failed, StripeIntentStatus.requires_payment_method].includes(status) &&
              !!message && (
                <div
                  className="my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] flex items-center"
                  role="alert"
                >
                  <XCircleIcon className="w-6 h-6"></XCircleIcon>
                  <span className="ml-1">{message}</span>
                </div>
              )}
            <RenderInput
              input={{
                inputType: 'section',
                title: 'Borrower will pay',
              }}
              Key="section1"
              onChange={() => {}}
            />
            <div className="grid grid-cols-2 my-4 gap-4 items-center">
              <RenderInput Key="borrowerEmail" input={borrowerEmailInput} onChange={onChangeBorrowerEmail} />
              <Button
                disabled={!borrowerEmailInput.value || !!borrowerEmailInput.error}
                link
                color="blue"
                onClick={onSendLinkToBorrower}
              >
                <span className="flex gap-2 items-center justify-center">
                  Send Payment Link
                  <ChevronRightIcon className="w-4 h-4" />
                </span>
              </Button>
            </div>

            <RenderInput
              input={{
                inputType: 'section',
                title: 'Pay Now',
              }}
              Key="section2"
              onChange={() => {}}
            />
            <div className="grid grid-cols-2 my-4 gap-4 items-center">
              <Button onClick={onPayNow}>Pay Now</Button>
            </div>
          </>
        ) : (
          <>
            {!isCompleted ? (
              <div className="text-shade-blue flex items-center mb-2 font-bold">
                <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
                <span className="hover:underline cursor-pointer" onClick={() => setShowPayForm(false)}>
                  Back
                </span>
              </div>
            ) : (
              <></>
            )}

            <StripeWithMethod
              availableMethods={[StripeMethod.card]}
              paymentDetails={{ status }}
              onCompleted={onPayCompleted}
              paymentData={{
                email: profile.email,
                amount,
                refId,
                orderType,
                loanNumber,
                method: 'pay',
                ...data,
              }}
            />
          </>
        )}
      </div>
    </Modal>
  )
}
