import cloneDeep from 'clone-deep'
import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { addNewDocument, getTemplatePrograms, updateDocument } from 'services'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'select',
      options: {
        guideline: 'Guidelines and Matrices',
        training: 'Materials',
        form: 'Forms',
        marketing: 'Marketing',
      },
      title: 'Type',
      value: '',
      visible: true,
      hasDefaultOption: true,
      required: true,
      span: 2,
    },
    name: {
      inputType: 'text',
      type: 'text',
      title: 'Name',
      value: '',
      visible: true,
      required: true,
      span: 2,
    },
    programs: {
      inputType: 'multiselect',
      title: 'Programs',
      value: {},
      options: {},
      visible: false,
      required: true,
      span: 2,
    },
    permission: {
      inputType: 'multiselect',
      title: 'Visible To',
      value: {},
      options: accountTypes,
      visible: true,
      required: true,
      span: 2,
    },
    file: {
      inputType: 'file',
      title: 'File',
      span: 2,
      visible: true,
      required: true,
      acceptFileTypes:
        'application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx',
    },
  }
}

interface ITemplatePrograms {
  ID: number
  Name: string
  Type: string
}

export const assignToRoles = accountTypes

export const AddResourceDialog = (props: { onClose: any; origin: Record<string, any> | null }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [programs, setPrograms] = useState<ITemplatePrograms[]>([])

  const defaultPermission = useMemo(() => {
    const res: Record<string, boolean> = {}
    Object.keys(accountTypes).forEach((key) => (res[key] = true))
    return res
  }, [accountTypes])

  useEffect(() => {
    ;(async () => {
      const newInputs = cloneDeep(inputs)

      const { origin } = props
      if (!origin) {
        newInputs.permission.value = defaultPermission
        setInputs(newInputs)
        return
      }

      newInputs.type.value = origin.type
      newInputs.name.value = origin.name
      newInputs.permission.value = origin.permission
      newInputs.file.required = false

      if (origin.type === 'guideline') {
        setLoading(true)

        const res = await getTemplatePrograms()

        setPrograms(res)

        const value = initialProgramsValue(res)

        origin.programIDs.forEach((key: string) => {
          if (res.find((item: ITemplatePrograms) => item.ID === Number(key))) value[key] = true
        })

        newInputs['programs'].value = value
        newInputs['programs'].visible = true
        ;(newInputs['programs'] as any).options = getProgramsOptions(res)
      } else newInputs['programs'].visible = false

      setLoading(false)
      setInputs(newInputs)
    })()
  }, [props.origin, defaultPermission])

  const initialProgramsValue = (data: ITemplatePrograms[]) => {
    const value: Record<string, boolean> = {}

    data.forEach((item) => {
      value[String(item.ID)] = false
    })

    return value
  }

  const getProgramsOptions = (data: ITemplatePrograms[]) => {
    const options: Record<string, string> = {}

    data.forEach((item) => {
      options[String(item.ID)] = item.Name
    })

    return options
  }

  const onChange = async (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key === 'type')
      if (value === 'guideline') {
        setLoading(true)
        const res = await getTemplatePrograms()
        setPrograms(res)

        newInputs['programs'].value = initialProgramsValue(res)
        newInputs['programs'].visible = true
        ;(newInputs['programs'] as any).options = getProgramsOptions(res)
      } else newInputs['programs'].visible = false

    setLoading(false)
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}

    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true

      if (key === 'programs') {
        const programIds: string[] = []

        let type = ''
        let tempIndex = 0

        if (!newInputs[key].visible) {
          data['programIDs'] = '[]'
          continue
        }

        if (Object.values(newInputs[key].value).find((item) => item === true)) {
          Object.keys(newInputs[key].value).forEach((k) => {
            if (newInputs[key].value[k]) {
              programIds.push(k)

              const targetProgram = programs.find((item) => item.ID === Number(k))

              if (!targetProgram) {
                newInputs[key].error = `The program you have selected does not exist.`
                hasError = true
              } else {
                if (tempIndex === 0) type = targetProgram.Type
                else if (type !== targetProgram.Type) {
                  newInputs[key].error = `The selected programs have differing types.`
                  hasError = true
                } else type = targetProgram.Type
              }

              tempIndex++
            }
          })

          data['programIDs'] = JSON.stringify(programIds)
        } else {
          newInputs[key].error = `Select Programs`
          hasError = true
        }
      } else data[key] = newInputs[key].value
    }

    setInputs(newInputs)

    if (hasError) return

    setLoading(true)
    data.permission = JSON.stringify(data.permission)
    if (props.origin) {
      updateDocument(props.origin.id, data)
        .then(() => {
          toast('Document is updated', { type: 'info' })
          props.onClose(true)
        })
        .catch(() => setLoading(false))
    } else
      addNewDocument(data)
        .then(() => {
          toast('A new document is added', { type: 'info' })
          props.onClose(true)
        })
        .catch(() => setLoading(false))
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      title={!props.origin ? 'Add Resource' : `Update Resource - ${props.origin.name}`}
      titleOkay={props.origin ? 'Update' : 'Save'}
      loading={loading}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]

          if (!input.visible) return null

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
