import type { InputType } from 'config'
import { Button } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'
import { renderParagraph } from 'utils/RenderParagraph'

import { PostClosingAssignmentPdfType } from './constants'
import { deedOfTrustParagraphs } from './deedOfTrustConstants'

export function DeedOfTrust({
  inputs,
  inputGroups,
  onChange,
  onGenerate,
  download,
}: {
  inputs: Record<string, InputType>
  inputGroups: Record<string, string[]>
  onChange: Function
  onGenerate: Function
  download: number
}) {
  const paragraphObj = deedOfTrustParagraphs(inputs)
  const inputGroup = inputGroups['Legal Info']

  return (
    <div>
      <p className="text-center text-lg font-bold mt-5">Assignment of Deed Of Trust</p>

      {renderParagraph(paragraphObj, 0, onChange)}

      <div className="grid grid-cols-3 gap-4 mt-20">{renderParagraph(paragraphObj, 1, onChange)}</div>

      <div className="flex flex-wrap items-end mt-16">{renderParagraph(paragraphObj, 2, onChange)}</div>

      <div className="flex flex-wrap items-end mt-10">{renderParagraph(paragraphObj, 3, onChange)}</div>

      <p className="mt-10">
        TO HAVE AND TO HOLD, the same unto Assignee, its successors and assigns, forever, subject only to the terms and
        conditions of the above-described Mortgage.
      </p>

      <p className="mt-8">IN WITNESS WHEREOF, this Assignment is made to be effective as of the date written below.</p>

      <div className="grid grid-cols-3 gap-4 mt-10">{renderParagraph(paragraphObj, 4, onChange)}</div>

      <p className="text-center text-lg font-bold mt-16">Exhibit A</p>

      <div className="mb-5">
        <p className="border-b font-bold text-sm w-full mb-2">Legal Info</p>
        <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
          {inputGroup.map((key: string) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>

      <div className="flex justify-center mt-8">
        <Button
          loading={download === PostClosingAssignmentPdfType.DeedOfTrust}
          onClick={() => onGenerate(PostClosingAssignmentPdfType.DeedOfTrust)}
        >
          Assignment of Deed Of Trust
        </Button>
      </div>
    </div>
  )
}
