import { XMarkIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { IBorrower } from 'pages/Borrower/ManageBorrowers/types'
import { useEffect, useState } from 'react'
import { getUserNotes } from 'services'
import { formatTime, renderHeader } from 'utils'

import { UserInfo, UserNote } from './user.type'
import { UserNotesDialog } from './UserNotesDialog'

export const UserNotesSection = ({
  user,
  visibleClose = true,
  onClose,
  isBorrower = false,
}: {
  user: UserInfo | IBorrower | any
  visibleClose?: boolean
  onClose: Function
  isBorrower?: boolean
}) => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<UserNote[]>([])
  const [selectedNote, setSelectedNote] = useState<UserNote | null>()

  useEffect(() => {
    loadNotes()
  }, [])

  const loadNotes = async () => {
    setLoading(true)
    const data = await getUserNotes(user.id, isBorrower)
    setData(data)
    setLoading(false)
  }

  const onAdd = () => {
    setSelectedNote(null)
  }

  const sortableHeaders = [
    { title: 'Type', key: 'type' },
    { title: 'Notify At', key: 'notifyAt' },
    { title: 'Message', key: 'message' },
    { title: 'Created At', key: 'createdAt' },
  ]

  return (
    <div className={`relative my-2 ${visibleClose && 'border py-2 px-3'} rounded`}>
      <LayoutLoading show={isLoading} />
      <div className="flex mb-2 items-center gap-2">
        <div className="flex flex-wrap gap-6 items-center flex-1">
          <h2 className="text-2xl font-bold flex items-center">
            <span className="text-[16px] ml-2">Notes of {isBorrower ? user.fullName : user.name}</span>
          </h2>
        </div>
        <span
          className="font-bold flex items-center gap-1 text-shade-blue hover:underline cursor-pointer pl-3 py-3"
          onClick={onAdd}
        >
          Add Note
        </span>
        {visibleClose && (
          <span
            className="text-[14px] flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
            onClick={() => onClose()}
          >
            Close
            <XMarkIcon className="w-4 h-4"></XMarkIcon>
          </span>
        )}
      </div>
      <div className="notes-container overflow-auto mb-6 shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              {sortableHeaders.map(({ title, key }, index) =>
                renderHeader({
                  title,
                  sortable: false,
                  key,
                  sortOrder: 0,
                  index,
                  onSort: () => {},
                }),
              )}
            </tr>
          </thead>
          <tbody className="text-[14.5px] text-gray-900">
            {data.map((item, index) => {
              const { type, notifyAt, message, createdAt } = item
              return (
                <tr key={index} className={`border-b ${index % 2 ? 'bg-gray-50' : ''}`}>
                  <td className="px-6 py-3">{index + 1}</td>
                  <td className="px-2 py-3">
                    <span>{type}</span>
                  </td>
                  <td className="px-2 py-3">{formatTime(notifyAt)}</td>
                  <td className="px-2 py-3">{message}</td>
                  <td className="px-2 py-3">{formatTime(createdAt)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {selectedNote !== undefined && (
        <UserNotesDialog
          user={user}
          onClose={(needUpdate: boolean) => {
            setSelectedNote(undefined)
            needUpdate && loadNotes()
          }}
          isBorrower={isBorrower}
        />
      )}
    </div>
  )
}
