import { XCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputSelect, InputType } from 'config'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { submitAccounting, updateAccountingById } from 'services'
import { Modal } from 'stories/components'
import { formatDate, InputConvert, InputValidate, removeComma, thousandSeperator } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingCategory } from '../AccountingCategories'
import { EditableAccountingTable, ExpenseBaseId, IAccounting } from '../AccountingRecords'
import type { IAccountingAccount } from '../Accounts/types'
import { getAccountOptions } from '../Accounts/utils'
import { getChildrenCategories, getChildrenSubCategories } from './util'

const defaultInputs = (): Record<string, InputType> => {
  return {
    baseId: {
      inputType: 'select',
      title: 'Base Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: false,
    },
    category: {
      inputType: 'select',
      title: 'Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
    subCategory: {
      inputType: 'select',
      title: 'Sub Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
    accountId: {
      inputType: 'select',
      title: 'Account',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
  }
}

export const SplitAccountingDialog = ({
  baseCategory,
  category,
  accounts,
  original,
  onClose,
}: {
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  accounts: IAccountingAccount[]
  original: IAccounting
  onClose: (v: IAccounting[] | null) => void
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [data, setData] = useState<IAccounting[]>([original])
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    ;(newInputs.baseId as InputSelect).options = baseCategory
    ;(newInputs.accountId as InputSelect).options = getAccountOptions(accounts)
    setInputs(newInputs)
  }, [])

  const calculatedAmount = useMemo(() => {
    return Number(
      data.reduce((org, item) => org + Number(item.amount) * (item.baseId == ExpenseBaseId ? -1 : 1), 0).toFixed(2),
    )
  }, [data])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key == 'baseId') {
      ;(newInputs.category as InputSelect).options = getChildrenCategories(category, value)
      newInputs.category.value = ''
    }
    if (key == 'category') {
      ;(newInputs.subCategory as InputSelect).options = getChildrenSubCategories(category, value)
      newInputs.subCategory.value = ''
    }
    setInputs(newInputs)

    const newData = cloneDeep(data)
    newData.forEach((v) => {
      ;(v as any)[key] = value
      if (key == 'baseId') {
        v.category = 0
        v.subCategory = 0
      }
      if (key == 'category') v.subCategory = 0
    })
    setData(newData)
  }

  const onSplit = () => {
    const newData = cloneDeep(data)
    newData.push({
      ...data[0],
      id: -Date.now(),
      amount: original.amount - calculatedAmount,
      files: [],
      note: `Split from #${original.id}\nAmount: ${thousandSeperator(original.amount)}\nDescription: ${
        original.description
      }\nDate: ${formatDate(original.date)}`,
      records: [],
      actived: true,
    })
    setData(newData)
  }

  const onDelete = (item: IAccounting) => {
    const newData = cloneDeep(data)
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) return
    if (item.id == original.id) {
      toast("You can't remove the original record.", { type: 'warning' })
      return
    }
    newData.splice(index, 1)
    setData(newData)
  }

  const onSubmit = async () => {
    if (removeComma(original.amount) != calculatedAmount) {
      setErrorMsg('The calculated amount is not the same as original amount. Please review again and fix.')
      return false
    }

    let allPassed = true

    const newValues = cloneDeep(data)

    newValues.forEach((v) => {
      v.valid = !!v.date && !!v.description && !!Number(v.amount) && !!v.baseId && !!v.category && !!v.accountId
      allPassed = allPassed && v.valid
    })

    if (!allPassed) {
      setData(newValues)
      setErrorMsg(
        `Please complete following items: ${newValues
          .map((v, index) => (!v.valid ? index + 1 : 0))
          .filter((v) => !!v)
          .map((v) => `${v}`)
          .join(', ')}.`,
      )
      return false
    }

    setLoading(true)

    const newData = cloneDeep(data)
    newData.forEach((v) => {
      v.actived = true
      v.deleted = false
      delete v.checked
      delete v.valid
    })
    const firstItem = newData.splice(0, 1)[0]
    firstItem.actived = true

    const splitItems: IAccounting[] = await submitAccounting(null, newData)
    firstItem.note += `${firstItem.note ? `${firstItem.note}\n` : ''}Split to ${splitItems
      .map((v) => `#${v.id}`)
      .join(', ')}`

    const newOriginal = await updateAccountingById(original.id, firstItem)
    setLoading(false)
    onClose([newOriginal, ...splitItems])
    return true
  }

  const renderErrorMessage = () => {
    return errorMsg ? (
      <div
        className="my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] flex items-center"
        role="alert"
      >
        <XCircleIcon className="w-6 h-6"></XCircleIcon>
        <span className="ml-1">{errorMsg}</span>
      </div>
    ) : null
  }

  return (
    <Modal
      title={`Split Record #${original.id}`}
      loading={isLoading}
      titleCancel=""
      titleOkay="Submit"
      isOpen
      onClose={() => onClose(null)}
      onOk={onSubmit}
      disabled={data.length <= 1}
    >
      <div className="w-[86rem]">
        <div
          className={`max-w-lg p-2 border border-blue-400 bg-blue-50 text-blue-800 rounded-lg mb-4 col-span-full grid grid-cols-3`}
        >
          {[
            ['Original Amount', original.amount],
            ['Calculated Amount', calculatedAmount],
          ].map((v) => (
            <React.Fragment key={v[0]}>
              <p>- {v[0]}:</p>
              <p className="col-span-2">${thousandSeperator(v[1])}</p>
            </React.Fragment>
          ))}
        </div>

        <div className={`flex gap-x-4 relative`}>
          {Object.keys(inputs).map((key) => {
            return (
              <div className={`input w-72 mb-4`} key={key}>
                <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>

        <EditableAccountingTable
          showCheck={false}
          sortable={false}
          accounts={accounts}
          baseCategory={baseCategory}
          category={category}
          data={data}
          setData={setData}
          onDelete={onDelete}
        />

        <div className="flex mt-4">
          <span
            className="flex items-center gap-1 text-sm text-shade-blue hover:underline cursor-pointer"
            onClick={() => onSplit()}
          >
            + Add
          </span>
        </div>

        {renderErrorMessage()}
      </div>
    </Modal>
  )
}
