import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import calculatorImg from 'assets/calculator.png'
import { convertNullValue } from 'pages/Resources/GuidelineOverlay/logic'
import { useMemo } from 'react'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { Button } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { formatDate, getPrice2decimal, removeComma, solveDecimalJavascriptSum } from 'utils'

import type { ILoan, ILoanProcess, ILockTermPrice, IPrice_Program_Price } from '../interfaces'

interface IProps {
  loan: ILoan
  loanProcessData: ILoanProcess
  canPriceAdjust: boolean | undefined
  rePricing: boolean
  isDscrProduct: boolean
  priceDisplay: string
  selectedProgram: IPrice_Program_Price[]
  priceIndex: { index1: number; index: number }
  isBorrower?: boolean
  pricingEngineIsOff: boolean
  onClearException: () => void
  onClearPrice: () => void
  showCalculated: (lockTermPrice: ILockTermPrice, rate: number, baseRate: number, exitFee?: number) => void
}

export const LoanProcessTable = (props: IProps) => {
  const {
    loan,
    priceDisplay,
    selectedProgram,
    priceIndex,
    loanProcessData,
    canPriceAdjust,
    rePricing,
    isDscrProduct,
    isBorrower = false,
    pricingEngineIsOff,
    onClearException,
    onClearPrice,
    showCalculated,
  } = props

  const monthlyInterest = useMemo(() => {
    const pitis = combinedMonthlyExpense(loan, loanProcessData)
    return pitis['mortgagePayment']
  }, [loan, loanProcessData])

  const priceValue = useMemo(() => {
    return priceDisplay === 'Price'
      ? loanProcessData.price
      : solveDecimalJavascriptSum([100, -1 * Number(loanProcessData.price)])
  }, [priceDisplay, loanProcessData])

  const showCalcHistory = () => {
    if (loanProcessData?.sheetDate?.includes('(E)') && loanProcessData?.rate > 0) return

    const targetPrice = selectedProgram[priceIndex.index]
    if (targetPrice) {
      const targetLockTermPrice = targetPrice.LockTermPrices[priceIndex.index1]

      if (targetLockTermPrice)
        showCalculated(targetLockTermPrice, targetPrice.Rate, targetPrice.BaseRate, targetPrice?.ExitFee)
    }
  }

  return (
    <div>
      {loanProcessData.rate == -1 ? (
        <div className="mt-4 border-b pb-1">
          <span className="flex text-md text-shade-blue font-variation-settings-600 gap-2">
            <ExclamationCircleIcon className="w-6 h-6" />
            <span>This Loan Details has been changed. You need to re-select Interest Rate from Rate Sheet table.</span>
          </span>
        </div>
      ) : (
        loanProcessData.type && (
          <div className="mt-4 border-b pb-1">
            {!isBorrower && (
              <div className="flex justify-between items-center">
                <span className="flex text-md text-shade-blue font-variation-settings-600">
                  <CheckIcon className="w-6 h-6" />
                  The interest rate you have chosen is
                </span>
                {canPriceAdjust && loanProcessData?.sheetDate?.includes('(E)') && !rePricing && (
                  <Button className="text-sm pt-1.5 pb-1.5" color="red" onClick={onClearException}>
                    Clear Exception
                  </Button>
                )}

                {pricingEngineIsOff && canPriceAdjust && !loanProcessData?.sheetDate?.includes('(E)') && !rePricing && (
                  <Button className="text-sm pt-1.5 pb-1.5" color="red" onClick={onClearPrice}>
                    Clear Price
                  </Button>
                )}
              </div>
            )}
            <table className="w-full table-auto cursor-pointer mb-4 shadow">
              <thead className="text-md text-gray-700 bg-gray-50">
                <tr className="text-[14px]">
                  <th className="border p-1">Type</th>
                  <th className="border p-1">Program</th>
                  <th className="border p-1">Rate</th>
                  {!isBorrower && (
                    <th className="border pl-1 pr-0.5">
                      <div className="flex justify-center gap-1">
                        <span>Price</span>
                        {!pricingEngineIsOff && (
                          <span
                            className="text-slate-400 hover:text-gray-900 hover-shadow1 rounded-sm mx-1"
                            onClick={showCalcHistory}
                          >
                            <img src={calculatorImg} alt="calculator-img" className="w-5 h-5" />
                          </span>
                        )}
                      </div>
                    </th>
                  )}
                  <th className="border p-1">Rate Date</th>
                  <th className="border p-1">{isBorrower ? 'Expiration Date' : 'Lock Days'}</th>
                  {!isDscrProduct && (
                    <>
                      {!isBorrower && <th className="border p-1">Rate Spread</th>}
                      <th className="border p-1">Exit Fee</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="text-[15px] text-gray-900 font-variation-settings-600">
                <tr className="bg-white border">
                  <td className="px-1 py-2 text-center border hover:bg-shade-blue/75 hover:text-white">
                    {loanProcessData.type}
                  </td>
                  <td className="px-1 py-2 text-center border hover:bg-shade-blue/75 hover:text-white">
                    {loanProcessData.program}
                  </td>
                  <td className="text-center border hover:bg-shade-blue/75 hover:text-white">
                    <Tooltip message={`<div class="text-[14px]">$${monthlyInterest}</div>`}>
                      <span className="p-2">{loanProcessData.rate}</span>
                    </Tooltip>
                  </td>

                  {!isBorrower && (
                    <td className="w-100 text-center hover:bg-shade-blue/75 hover:text-white">
                      <Tooltip
                        message={`<div class="text-[14px]">$${getPrice2decimal(
                          (removeComma(loan.proposedLoanAmount) * (removeComma(loanProcessData.price) * 100 - 10000)) /
                            10000,
                          false,
                          true,
                        )}</div>`}
                      >
                        <span className="px-1 py-2">{priceValue}</span>
                      </Tooltip>
                    </td>
                  )}

                  <td className="px-1 py-2 text-center border hover:bg-shade-blue/75 hover:text-white">
                    {loanProcessData.sheetDate}
                  </td>
                  <td className="px-1 py-2 text-center border hover:bg-shade-blue/75 hover:text-white">
                    {isBorrower
                      ? loanProcessData.rateLocked
                        ? formatDate(loanProcessData.expireDate)
                        : 'Not Locked'
                      : loanProcessData.lockDays + ' Days'}
                  </td>
                  {!isDscrProduct && (
                    <>
                      {!isBorrower && (
                        <td className="px-1 py-2 text-center border hover:bg-shade-blue/75 hover:text-white">
                          <Tooltip
                            message={`$${getPrice2decimal(
                              (removeComma(loan.proposedLoanAmount) * removeComma(loanProcessData.rateSpread)) /
                                100 /
                                12,
                              false,
                              true,
                            )}/month`}
                          >
                            <span>{convertNullValue(loanProcessData.rateSpread)}</span>
                          </Tooltip>
                        </td>
                      )}
                      <td className="px-1 py-2 text-center border hover:bg-shade-blue/75 hover:text-white">
                        <Tooltip
                          message={`$${getPrice2decimal(
                            (removeComma(loan.proposedLoanAmount) * removeComma(loanProcessData.exitFee)) / 100,
                            false,
                            true,
                          )}`}
                        >
                          <span>{convertNullValue(loanProcessData.exitFee)}</span>
                        </Tooltip>
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  )
}
