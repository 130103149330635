import { useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import { EmailSetup } from './EmailSetup'
import { GlobalSettings } from './GlobalSettings'
import { Links } from './Links'
import { LoanStatusRestrictions } from './LoanStatusRestrictions'
import { StructureDesc } from './LoanStructure'
import { LoanSubmission } from './LoanSubmission'
import { RulesConfiguration } from './RulesConfiguration'
import { TextConfiguration } from './TextsConfiguration'
import { Workflows } from './Workflows/Workflows'

export const menus = {
  globalSettings: 'Global Settings',
  loanStructure: 'Loan Structure Tooltips',
  loanAction: 'Loan Actions',
  loanStatusRestrictions: 'Loan Status Restrictions',
  texts: 'Texts',
  emailSetup: 'Email Setup',
  rules: 'Rules',
  links: 'Links',
}

export const Configuration = () => {
  const [selectedMenu, setSelectedMenu] = useState(menus.globalSettings)

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.globalSettings:
        return <GlobalSettings />
      case menus.loanStructure:
        return <StructureDesc />
      case menus.loanAction:
        return <LoanSubmission />
      case menus.loanStatusRestrictions:
        return (
          <>
            <LoanStatusRestrictions />
            <Workflows />
          </>
        )
      case menus.texts:
        return <TextConfiguration />
      case menus.emailSetup:
        return <EmailSetup />
      case menus.rules:
        return <RulesConfiguration />
      case menus.links:
        return <Links />
      default:
        return <></>
    }
  }, [selectedMenu])

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">Configuration</h1>

      <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={(value) => setSelectedMenu(value)} />

      <div className="p-2">{renderContent}</div>
    </div>
  )
}
