import { CheckIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { loanStatusConstants } from 'config'
import { useEffect, useState } from 'react'
import { getAdminConfig, getAdminEmails, setAdminConfig } from 'services'

import { TaskAssigneeOptions } from './constants'
import { LoanSubmissionDetails } from './details'
import { LoanSubmissionActions, LoanSubmissionConfig } from './types'

export const LoanSubmission = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Record<string, LoanSubmissionConfig>>({})
  const [currentItem, setCurrentItem] = useState<string | null>(null)
  const [adminEmails, setAdminEmails] = useState<string[]>([])

  useEffect(() => {
    load()
    getAdminEmails().then((emails: string[]) => setAdminEmails(emails))
  }, [])

  const load = () => {
    setLoading(true)
    getAdminConfig('loanSubmission')
      .then((values) => setData(values))
      .finally(() => setLoading(false))
  }

  const onBack = (values: LoanSubmissionConfig | null) => {
    if (values && currentItem) {
      setLoading(true)
      const newData = cloneDeep(data)
      newData[currentItem] = values
      setData(newData)
      setAdminConfig('loanSubmission', newData).finally(() => setLoading(false))
    }
    setCurrentItem(null)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      <div className="w-full">
        {!currentItem ? (
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  No
                </th>
                <th scope="col" className="px-3">
                  Action
                </th>
                <th scope="col" className="px-3">
                  Task Assignee
                </th>
                <th scope="col" className="px-3">
                  Loan Status To
                </th>
                <th scope="col" className="px-3">
                  Email
                </th>
                <th scope="col" className="px-3">
                  Task
                </th>
                <th scope="col" className="px-3">
                  Task on Task
                </th>
                <th scope="col" className="px-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="">
              {Object.keys(data).map((key, index) => {
                const item = data[key]
                const checkIcon = <CheckIcon className="w-4 h-4 text-green-500" />
                const uncheckIcon = <XMarkIcon className="w-4 h-4 text-red-500" />
                return (
                  <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                    <td className="px-3 py-3">{index + 1}</td>
                    <td className="px-3">{LoanSubmissionActions[key]}</td>
                    <td className="px-3">{item.taskAssignee.map((v) => TaskAssigneeOptions[v] || v).join(', ')}</td>
                    <td className="px-3">
                      {item.statusTo ? loanStatusConstants.status[item.statusTo] : '- No Change -'}
                    </td>
                    <td className="px-3">{item.email ? checkIcon : uncheckIcon}</td>
                    <td className="px-3">{item.task ? checkIcon : uncheckIcon}</td>
                    <td className="px-3">{item.taskOnTask ? checkIcon : uncheckIcon}</td>
                    <td className="">
                      <span className="flex px-3">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => setCurrentItem(key)}
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <LoanSubmissionDetails
            action={currentItem}
            data={data[currentItem]}
            adminEmails={adminEmails}
            onBack={onBack}
          />
        )}
      </div>
    </div>
  )
}
