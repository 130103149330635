import { useMemo, useState } from 'react'
import { Button } from 'stories/components'

import { type IProgramTableData, ProgramTypes } from '../interface'
import { StaticComponent } from '../StaticComponent'
import { LeverageAdjustments } from './LeverageAdjustments'
import { LeverageLimits } from './LeverageLimits'

interface IProps {
  data: IProgramTableData[]
}

export const menus = {
  limit: 'Leverage Limits',
  adjustment: 'Leverage Adjustments',
}

export const CommercialProgram = (props: IProps) => {
  const { data } = props

  const [selectedProgram, setSelectedProgram] = useState<IProgramTableData>(data[0])
  const [selectedMenu, setSelectedMenu] = useState<string>(menus.limit)

  const renderLtvMaxLimits = useMemo(() => {
    switch (selectedMenu) {
      case menus.limit:
        return <LeverageLimits program={selectedProgram} />
      case menus.adjustment:
        return <LeverageAdjustments program={selectedProgram} />
      default:
        return <></>
    }
  }, [selectedMenu, selectedProgram])

  const renderMenu = useMemo(() => {
    return (
      <ul className="flex flex-col">
        {Object.values(menus).map((item, index) => {
          return (
            <li key={index} onClick={() => setSelectedMenu(item)} className="border-b py-2">
              <p
                className={`hover:underline cursor-pointer ${
                  selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                }`}
              >
                {index + 1 + '. ' + item}
              </p>
            </li>
          )
        })}
      </ul>
    )
  }, [selectedMenu])

  const renderCommercialProgram = () => {
    return (
      <div>
        <div className="grid grid-cols-12 gap-6 mb-6 w-full">
          <div className=" col-span-12 md:col-span-3 shrink-0 p-4 bg-white shadow1 rounded">{renderMenu}</div>

          <div className="col-span-12 md:col-span-9 shrink-0 p-4 bg-white shadow1 rounded w-full">
            <h2 className="text-xl font-semibold mb-3">{selectedMenu || ''}</h2>
            {renderLtvMaxLimits}
          </div>
        </div>

        <table className="w-full text-center text-sm mb-6">
          <tbody>
            {selectedProgram.tableData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {rowData.map((cellData: string, colIndex: number) => {
                  let bgColor = ''

                  if (selectedProgram.tableStyle.titles.includes(`${rowIndex}-${colIndex}`)) {
                    if ([1, 6, selectedProgram.tableStyle.rowPriceAdjFirstRow].includes(rowIndex))
                      bgColor = 'font-bold bg-blue-200'
                    else bgColor = 'font-bold bg-gray-100'
                  } else if (selectedProgram.tableStyle.rightAlignCells.includes(`${rowIndex}-${colIndex}`)) {
                    bgColor = 'text-right'
                  }

                  return (
                    <td
                      key={`${rowIndex}-${colIndex}`}
                      className={`border px-2 h-5 ${bgColor}`}
                      colSpan={selectedProgram.tableStyle.colSpan2.includes(`${rowIndex}-${colIndex}`) ? 2 : 0}
                    >
                      {cellData}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="bg-white p-4 rounded shadow1">
          <StaticComponent programID={selectedProgram.ID} />
        </div>
      </div>
    )
  }

  const renderCommercialDscrProgram = () => {
    return (
      <div>
        <table className="w-full text-center text-sm mb-6">
          <tbody>
            {selectedProgram.tableData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {rowData.map((cellData: string, colIndex: number) => (
                  <td
                    key={`${rowIndex}-${colIndex}`}
                    className={`border px-2 h-5 ${
                      [1, 10].includes(rowIndex)
                        ? 'bg-gray-100 font-bold'
                        : [0, 9].includes(rowIndex) && ![2, 4].includes(colIndex)
                        ? 'bg-gray-100 font-bold'
                        : ''
                    }`}
                    colSpan={
                      [1, 10].includes(rowIndex) && colIndex === 0
                        ? 2
                        : [0, 9].includes(rowIndex) && colIndex === 0
                        ? 5
                        : 0
                    }
                  >
                    {cellData}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="bg-white p-4 px-4 rounded shadow1">
          <StaticComponent programID={selectedProgram.ID} />
        </div>
      </div>
    )
  }

  const renderProgamTable = useMemo(() => {
    if (!selectedProgram) return <></>

    switch (selectedProgram.Type) {
      case ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED:
        return renderCommercialProgram()
      case ProgramTypes.COMMERCIAL_DSCR:
        return renderCommercialDscrProgram()
    }
  }, [selectedProgram, selectedMenu])

  return (
    <div>
      {data && data.length > 1 ? (
        <div className="flex items-center gap-2 flex-wrap mb-4">
          {data.map((item, index) => (
            <Button
              key={index}
              color={selectedProgram?.Name === item.Name ? 'green' : 'gray'}
              className=" whitespace-nowrap"
              onClick={() => setSelectedProgram(item)}
            >
              {item.Name}
            </Button>
          ))}
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="text-center w-fit text-xl border-2 border-gray-500 rounded-lg py-2 px-6 font-bold mb-4">
            {data[0].Name}
          </div>
        </div>
      )}

      {renderProgamTable}
    </div>
  )
}
