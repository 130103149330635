import cloneDeep from 'clone-deep'
import type { InputSelect, InputType } from 'config'
import { useEffect, useState } from 'react'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingCategory } from '../AccountingCategories'
import { accountingDashboardData } from '../Dashboard'
import type { IDebitOrCredit } from './types'
import { getChildrenCategories, getChildrenSubCategories } from './util'

const dcTypes = ['debit', 'credit']

const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'select',
      title: 'Type',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: false,
      required: true,
    },
    baseId: {
      inputType: 'select',
      title: 'Base Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: false,
      required: true,
    },
    category: {
      inputType: 'select',
      title: 'Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
      required: true,
    },
    subCategory: {
      inputType: 'select',
      title: 'Sub Category',
      hasDefaultOption: true,
      defaultOptionText: 'None',
      options: {},
      sort: true,
      required: false,
    },
  }
}
export const DebitOrCreditSection = ({
  baseCategory,
  category,
  value,
  onChange: _onChange,
}: {
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  value: IDebitOrCredit
  onChange: Function
}) => {
  const [inputs, setInputs] = useState<Record<string, Record<string, InputType>>>({
    debit: defaultInputs(),
    credit: defaultInputs(),
  })

  useEffect(() => {
    let newInputs = cloneDeep(inputs)
    dcTypes.map((dcType) => {
      ;(newInputs[dcType].baseId as InputSelect).options = baseCategory

      const typeOptions = Object.keys(accountingDashboardData)
      ;(newInputs[dcType].type as InputSelect).options = typeOptions
      if (!value || !(value as any)[dcType]) return
      const values = (value as any)[dcType]

      for (const key in inputs[dcType]) {
        newInputs[dcType][key].value = values[key]
      }
      if (values.baseId)
        (newInputs[dcType].category as InputSelect).options = getChildrenCategories(category, values.baseId)

      if (values.category)
        (newInputs[dcType].subCategory as InputSelect).options = getChildrenSubCategories(category, values.category)
    })
    setInputs(newInputs)
  }, [value])

  const onChange = (dcType: string, key: string, value: string) => {
    let _newInputs = cloneDeep(inputs)
    const newInputs = _newInputs[dcType]

    const input = _newInputs[dcType][key]
    value = InputConvert(input, value)
    input.error = InputValidate({ ...input, value })
    input.value = value

    if (key == 'baseId') {
      ;(newInputs.category as InputSelect).options = getChildrenCategories(category, value)
      newInputs.category.value = ''
    }
    if (key == 'category') {
      ;(newInputs.subCategory as InputSelect).options = getChildrenSubCategories(category, value)
      newInputs.subCategory.value = ''
    }
    setInputs(_newInputs)

    const data: Record<string, any> = {}
    dcTypes.map((dcType) => {
      data[dcType] = {}
      for (const key in inputs[dcType]) {
        data[dcType][key] = _newInputs[dcType][key].value || ''
      }
    })
    _onChange(data)
  }

  return (
    <div>
      <RenderInput input={{ inputType: 'Section', title: 'Debit or Credit' }} Key="" onChange={() => {}} />

      <div className="grid md:grid-cols-2 gap-4 mt-4">
        {dcTypes.map((dcType) => (
          <div>
            <p className="capitalize font-bold mb-2">{dcType}</p>

            {Object.keys(inputs[dcType]).map((key) => {
              return (
                <div className={`input mb-4`} key={`${dcType}-${key}`}>
                  <RenderInput
                    input={inputs[dcType][key]}
                    Key={key}
                    onChange={(v1: any, v2: any) => onChange(dcType, v1, v2)}
                  />
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
