import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    reason: {
      inputType: 'textarea',
      type: 'text',
      title: 'Override Reason',
      value: '',
      span: 2,
      required: false,
    },
  }
}

export const ExpirationDatesStatus = ({
  item,
  onClose,
  onUpdate,
}: {
  item: Record<string, any>
  onClose: Function
  onUpdate: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    await onUpdate(data.reason, true)
  }

  return (
    <Modal
      title={`Override Expiration - ${item.title}`}
      titleOkay="Confirm"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900">
        <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
