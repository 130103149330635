import { InputType } from 'config'

export const participationInputs = (): Record<string, InputType> => {
  return {
    participantName: {
      inputType: 'text',
      type: 'text',
      title: 'Participant Name',
      value: '',
      required: true,
    },
    participantRepName: {
      inputType: 'text',
      type: 'text',
      title: 'Participant Rep Name',
      value: '',
      required: true,
    },
    participantRepTitle: {
      inputType: 'text',
      type: 'text',
      title: 'Participant Rep Title',
      value: '',
      required: true,
    },
    participantAddress: {
      inputType: 'Map',
      title: 'Participant Address',
      value: '',
      required: true,
    },
    participantAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Participation Amount',
      prefix: '$',
      value: '',
      required: true,
    },
    participantServicingFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Participation Servicing Fee',
      prefix: '$',
      value: '',
      required: true,
    },
    principalRate: {
      inputType: 'text',
      type: 'number',
      title: 'Principal Rate',
      prefix: '%',
      value: '', // default interest rate
      required: true,
    },
    principalBuyoutRate: {
      inputType: 'text',
      type: 'number',
      title: 'Principal Buyout Rate',
      prefix: '%',
      value: '', // default interest rate
      required: true,
    },
    participantInterestRate: {
      inputType: 'text',
      type: 'number',
      title: 'Participation Interest Rate',
      prefix: '%',
      value: '',
      required: true,
    },
    participantExcessReturnPercent: {
      inputType: 'text',
      type: 'number',
      title: 'Participant Excess Return Percent',
      prefix: '%',
      value: '', // default 0
      required: true,
    },
    participantExtensionPercent: {
      inputType: 'text',
      type: 'number',
      title: 'Participant Extension Percent',
      prefix: '%',
      value: '', // default 0
      required: true,
    },
    participantOriginationFeePercent: {
      inputType: 'text',
      type: 'number',
      title: 'Participant Origination Fee Percent',
      prefix: '%',
      value: '', // default 0
      required: true,
    },
  }
}
