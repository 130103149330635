import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { INVALID_ALL_INPUTS } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  checkAppraisalValuation,
  checkEliteValuation,
  checkNationWide,
  getAppraisalCredential,
  getEliteValuationCredential,
  getNationWideCredential,
} from 'services'
import { Button } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export function EliteValuation() {
  const [eliteFields, setEliteFields] = useState<Record<string, any>>({
    title: {
      inputType: 'section',
      title: 'Elite Valuation Information',
    },
    username: {
      title: 'UserName',
      inputType: 'text',
      error: '',
      required: true,
    },
    password: {
      title: 'Password',
      inputType: 'text',
      type: 'password',
      error: '',
      required: true,
    },
  })
  const [appraisalFields, setAppraisalFields] = useState<Record<string, any>>({
    title: {
      inputType: 'section',
      title: 'Appraisal Nation Information',
    },
    username: {
      title: 'UserName',
      inputType: 'text',
      error: '',
      required: true,
    },
    password: {
      title: 'Password',
      inputType: 'text',
      type: 'password',
      error: '',
      required: true,
    },
  })
  const [nationWideFields, setNationWideFields] = useState<Record<string, any>>({
    title: {
      inputType: 'section',
      title: 'Nationwide Appraisal Network (NAN)',
    },
    username: {
      title: 'UserName',
      inputType: 'text',
      error: '',
      required: true,
    },
    password: {
      title: 'Password',
      inputType: 'text',
      type: 'password',
      error: '',
      required: true,
    },
  })
  const [eliteLoading, setEliteLoading] = useState(false)
  const [appraisalLoading, setAppraisalLoading] = useState(false)
  const [nationLoading, setNationLoading] = useState(false)
  const [eliteData, setEliteData] = useState<Record<string, any>>({})
  const [appraisalData, setAppraisalData] = useState<Record<string, any>>({})
  const [natinowideData, setNationWideData] = useState<Record<string, any>>({})
  const [eliteStatus, setEliteStatus] = useState(-1)
  const [appraisalStatus, setAppraisalStatus] = useState(-1)
  const [nationWideStatus, setNationWideStatus] = useState(-1)

  useEffect(() => {
    setEliteLoading(true)
    setAppraisalLoading(true)
    setNationLoading(true)
    getEliteValuationCredential()
      .then((res: any) => {
        if (res.success) {
          setEliteStatus(res.status)
          setEliteData(res.data)
        }
      })
      .finally(() => {
        setEliteLoading(false)
      })

    getAppraisalCredential()
      .then((res: any) => {
        if (res.success) {
          setAppraisalStatus(res.status)
          setAppraisalData(res.data)
        }
      })
      .finally(() => {
        setAppraisalLoading(false)
      })
    getNationWideCredential()
      .then((res: any) => {
        if (res.success) {
          setNationWideStatus(res.status)
          setNationWideData(res.data)
        }
      })
      .finally(() => {
        setNationLoading(false)
      })
  }, [])

  const onEliteChange = (key: string, value: any) => {
    let temp = cloneDeep(eliteData)
    temp[key] = value
    setEliteData(temp)

    const error = InputValidate({ ...eliteFields[key], value })

    if (eliteFields[key].error !== error) {
      let _eliteFields = cloneDeep(eliteFields)
      _eliteFields[key].error = error
      setEliteFields(_eliteFields)
    }
  }

  const onAppraisalChange = (key: string, value: any) => {
    let temp = cloneDeep(appraisalData)
    temp[key] = value
    setAppraisalData(temp)

    const error = InputValidate({ ...appraisalFields[key], value })

    if (appraisalFields[key].error !== error) {
      let _appraisalFields = cloneDeep(appraisalFields)
      _appraisalFields[key].error = error
      setAppraisalFields(_appraisalFields)
    }
  }

  const onNationWideChange = (key: string, value: any) => {
    let temp = cloneDeep(natinowideData)
    temp[key] = value
    setNationWideData(temp)

    const error = InputValidate({ ...nationWideFields[key], value })

    if (nationWideFields[key].error !== error) {
      let _nationWideFields = cloneDeep(nationWideFields)
      _nationWideFields[key].error = error
      setNationWideFields(_nationWideFields)
    }
  }

  const onEliteSubmit = async () => {
    let reqBody: any = {}
    let hasError = 0
    let temp = cloneDeep(eliteFields)
    Object.keys(eliteFields).map((key: string) => {
      if (key == 'title') return
      reqBody[key] = eliteData[key]
      const error = InputValidate({ ...eliteFields[key], value: eliteData[key] })
      if (error.length) {
        hasError += 1
        temp[key].error = error
      }
    })
    if (hasError) {
      setEliteFields(temp)
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setEliteLoading(true)
    const res = await checkEliteValuation(reqBody)
    setEliteLoading(false)
    if (res.success) {
      setEliteStatus(res.status)
    }
  }

  const onAppraisalSubmit = async () => {
    let reqBody: any = {}
    let hasError = 0
    let temp = cloneDeep(appraisalFields)
    Object.keys(appraisalFields).map((key: string) => {
      if (key == 'title') return
      reqBody[key] = appraisalData[key]
      const error = InputValidate({ ...appraisalFields[key], value: appraisalData[key] })
      if (error.length) {
        hasError += 1
        temp[key].error = error
      }
    })
    if (hasError) {
      setAppraisalFields(temp)
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setAppraisalLoading(true)
    const res = await checkAppraisalValuation(reqBody)
    console.log(res)
    setAppraisalLoading(false)
    if (res.success) {
      setAppraisalStatus(res.status)
    }
  }

  const onNationWideSubmit = async () => {
    let reqBody: any = {}
    let hasError = 0
    let temp = cloneDeep(nationWideFields)
    Object.keys(nationWideFields).map((key: string) => {
      if (key == 'title') return
      reqBody[key] = natinowideData[key]
      const error = InputValidate({ ...nationWideFields[key], value: natinowideData[key] })
      if (error.length) {
        hasError += 1
        temp[key].error = error
      }
    })
    if (hasError) {
      setNationWideFields(temp)
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setNationLoading(true)
    const res = await checkNationWide(reqBody)
    console.log(res)
    setNationLoading(false)
    if (res.success) {
      setNationWideStatus(res.status)
    }
  }

  const renderStatus = (type: string) => {
    let status
    if (type == 'elite') status = eliteStatus
    else if (type == 'appraisal') status = appraisalStatus
    else status = nationWideStatus
    if (status === 0) {
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]"
          role="alert"
        >
          Invalid UserName or Password!
        </div>
      )
    }
    if (status === 1) {
      return (
        <div className="p-3 mb-4 text-[16px] bg-blue-100 border border-blue-400 text-blue-700 rounded-lg" role="alert">
          Success!
        </div>
      )
    }
  }

  return (
    <div className="flex-auto bg-white p-5 rounded h-fit shadow1">
      <h1 className="text-2xl font-bold flex items-center pb-5">
        <span>Integrations</span>
      </h1>
      <div className="relative max-w-[450px]">
        <LayoutLoading show={eliteLoading || appraisalLoading} />
        {Object.keys(eliteFields).map((key, index) => {
          const input = eliteFields[key]
          return (
            <div className="mb-4" key={index}>
              <RenderInput
                input={{
                  ...input,
                  value: eliteData[key],
                }}
                Key={key}
                onChange={onEliteChange}
              />
            </div>
          )
        })}
        {renderStatus('elite')}
        <Button className="mb-12" full loading={eliteLoading} onClick={onEliteSubmit}>
          Get Status
        </Button>
        {Object.keys(appraisalFields).map((key, index) => {
          const input = appraisalFields[key]
          return (
            <div className="mb-4" key={index}>
              <RenderInput
                input={{
                  ...input,
                  value: appraisalData[key],
                }}
                Key={key}
                onChange={onAppraisalChange}
              />
            </div>
          )
        })}
        {renderStatus('appraisal')}
        <Button className="mb-12" full loading={appraisalLoading} onClick={onAppraisalSubmit}>
          Get Status
        </Button>
        {Object.keys(nationWideFields).map((key, index) => {
          const input = nationWideFields[key]
          return (
            <div className="mb-4" key={index}>
              <RenderInput
                input={{
                  ...input,
                  value: natinowideData[key],
                }}
                Key={key}
                onChange={onNationWideChange}
              />
            </div>
          )
        })}
        {renderStatus('nationWide')}
        <Button full loading={nationLoading} onClick={onNationWideSubmit}>
          Get Status
        </Button>
      </div>
    </div>
  )
}
