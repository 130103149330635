import cloneDeep from 'clone-deep'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { DownloadLink, Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { AssetAccountTypeForPlaid, defaultInputs, IAssetData, requiredInputs } from './constants'

export const AssetInformationModal = (props: { asset: IAssetData | null; onClose: Function; onSubmit: Function }) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const { hasPermission } = usePermissions()

  useEffect(() => {
    let newInputs = cloneDeep(inputs)
    if (props.asset) {
      Object.keys(newInputs).forEach((key) => {
        newInputs[key].value = (props.asset as any)[key]
        if (props.asset?.verified && !props.asset?.bankStatementReportFileKey && key != 'accountType')
          newInputs[key].disabled = true
      })
      if (AssetAccountTypeForPlaid.includes(Number(props.asset.accountType))) {
        requiredInputs.forEach((key) => (newInputs[key].required = false))
      } else {
        requiredInputs.forEach((key) => (newInputs[key].required = true))
      }
    }
    newInputs.override.visible = hasPermission('PLAID_VALIDATION_OVERRIDE')
    newInputs.override.disabled = false
    setInputs(newInputs)
  }, [])

  const onChange = async (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    if (key == 'accountType') {
      Object.keys(newInputs).forEach((key) => (newInputs[key].error = ''))
      if (AssetAccountTypeForPlaid.includes(Number(value))) {
        requiredInputs.forEach((key) => (newInputs[key].required = false))
      } else {
        requiredInputs.forEach((key) => (newInputs[key].required = true))
      }
    }
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) return false

    setLoading(true)
    values.accountType = Number(values.accountType)

    if (props.asset)
      values = {
        ...props.asset,
        ...values,
      }

    const assetId = props.asset ? props.asset.id : ''
    await props.onSubmit(assetId, [values])

    setLoading(false)
    props.onClose()
  }

  return (
    <Modal
      title={`${props.asset ? 'Update' : 'Add New'} Asset Information`}
      titleOkay="Save"
      titleCancel="Close"
      loading={isLoading}
      isOpen
      onClose={() => props.onClose()}
      onOk={onSubmit}
    >
      <div className="min-w-[400px]">
        <div className="flex flex-col gap-4">
          {Object.keys(inputs).map(
            (key) => inputs[key].visible !== false && <RenderInput input={inputs[key]} Key={key} onChange={onChange} />,
          )}

          {props.asset && props.asset.bankStatementFileKey ? (
            <DownloadLink link={props!.asset!.bankStatementFileKey}>Uploaded Bank Statement</DownloadLink>
          ) : null}

          {props.asset && props.asset.bankStatementReportFileKey ? (
            <DownloadLink link={props!.asset!.bankStatementReportFileKey}>Bank Statement Summary Report</DownloadLink>
          ) : null}

          {!!props.asset?.bankStatementData?.webhookReportData?.note ? (
            <p className="p-2 rounded-lg border-red-400 border bg-red-50 text-sm text-red-600 max-w-sm">
              {props.asset?.bankStatementData?.webhookReportData?.note}
            </p>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}
