import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputSelect, InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitRolodex } from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, inputGroups } from './constant'

export function RolodexDetails(props: any) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const { defaults = null, lastUpdatedAt } = props

  useEffect(() => {
    const newStats = cloneDeep(defaultInputs())
    ;(newStats.CategoryID as InputSelect).options = props.categories
    if (!defaults) {
      setInputs(newStats)
      return
    }
    for (const key in newStats) {
      newStats[key].value = defaults[key]
    }
    setInputs(newStats)
  }, [defaults, lastUpdatedAt])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (!disabled && value !== undefined) data[key] = value
    }

    if (hasError) {
      setInputs(newStats)
      return
    }
    setLoading(true)
    const id = defaults ? defaults.id : 0
    submitRolodex(id, data)
      .then(() => {
        if (id == 0) toast('New Rolodex is added.', { type: 'success' })
        else toast(`Rolodex No ${defaults.no} is updated.`, { type: 'success' })
        props.onComplete({ ...defaults, ...data })
        onBack()
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="rolodex-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Rolodex - {!defaults ? 'Add' : `Update No ${defaults.no}`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      {props.onBack !== null && (
        <Button link onClick={onBack}>
          <div className="flex text-shade-blue items-center">
            <ArrowLeftIcon className="w-4 h-4 mr-1" /> <p>Return to Rolodexes</p>
          </div>
        </Button>
      )}

      {Object.keys(inputGroups).map((title) => {
        const inputGroup = (inputGroups as any)[title]
        return (
          <div className="mb-5" key={title}>
            {title != '_' && <p className="border-b font-bold text-sm w-full mb-2">{title}</p>}
            <div className="grid gap-4 md:grid-cols-3 grid-cols-1 mb-3">
              {inputGroup.map((key: string) => {
                const input = inputs[key]
                if (!input) return null

                return (
                  <div className={`input md:col-span-${input.span || 1}`} key={key}>
                    <RenderInput input={input} Key={key} onChange={onChange} />
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}

      <div className="flex justify-center gap-4">
        {props.onCancel && (
          <Button onClick={props.onCancel} className="px-10" color="red">
            Cancel
          </Button>
        )}
        <Button onClick={onSubmit} className="" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'} Rolodex</>
        </Button>
      </div>
    </div>
  )
}
