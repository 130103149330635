import type { InputType } from 'config'
import type { ParagraphComponent } from 'utils/RenderParagraph'

export const securityDeedParagraphs = (inputs: Record<string, InputType>): ParagraphComponent[][] => {
  return [
    [
      {
        input: { inputType: 'span', title: 'Recording Requested by:' },
        className: 'mb-4',
      },
      {
        input: inputs.recordingRequestedBy,
        Key: 'recordingRequestedBy',
      },
      {
        input: { inputType: 'span', title: 'When Recorded Return to:' },
        className: 'mt-5 mb-4',
      },
      {
        input: inputs.whenRecordedReturnTo,
        Key: 'whenRecordedReturnTo',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'Loan Number:' },
      },
      {
        input: inputs.loanNumber,
        Key: 'loanNumber',
        className: 'col-span-2 flex items-end w-[500px]',
      },
      {
        input: { inputType: 'span', title: 'County Parcel Number:' },
      },
      {
        input: inputs.companyParcelNumber,
        Key: 'companyParcelNumber',
        className: 'col-span-2 flex items-end w-[500px]',
      },
      {
        input: { inputType: 'span', title: 'Property Address:' },
      },
      {
        input: inputs.propertyAddress,
        Key: 'propertyAddress',
        className: 'col-span-2 flex items-end w-[500px]',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'STATE OF' },
        needSplit: true,
        className: 'w-[75px]',
      },
      {
        input: inputs.state,
        Key: 'state',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'COUNTY OF' },
        needSplit: true,
        className: 'w-[75px]',
      },
      {
        input: inputs.county,
        Key: 'county',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'For Value Received, ' },
        needSplit: true,
      },
      {
        input: inputs.assignorEntityName6,
        Key: 'assignorEntityName6',
      },
      {
        input: {
          inputType: 'span',
          title: ' (herein “Assignor”) has this day transferred, sold, assigned, conveyed and set over to ',
        },
        needSplit: true,
      },
      {
        input: inputs.assigneeName,
        Key: 'assigneeName',
      },
      {
        input: { inputType: 'span', title: ', a ' },
        needSplit: true,
      },
      {
        input: inputs.assigneeEntityType,
        Key: 'assigneeEntityType',
      },
      {
        input: { inputType: 'span', title: ' organized and existing under the laws of the ' },
        needSplit: true,
      },
      {
        input: inputs.assigneeState,
        Key: 'assigneeState',
      },
      {
        input: { inputType: 'span', title: ' (herein “Assignee”), whose address is ' },
        needSplit: true,
      },
      {
        input: inputs.assigneeAddress,
        Key: 'assigneeAddress',
        className: 'w-[350px]',
      },
      {
        input: {
          inputType: 'span',
          title:
            ', as Assignee, its successors, representatives and assigns, all its rights, title and interest in and to a certain Security Deed (or Deed to Secure Debt) executed by ',
        },
        needSplit: true,
      },
      {
        input: inputs.borrowers,
        Key: 'borrowers',
      },
      {
        input: { inputType: 'span', title: ' to (assignor) ' },
        needSplit: true,
      },
      {
        input: inputs.assignorEntityName7,
        Key: 'assignorEntityName7',
      },
      {
        input: { inputType: 'span', title: ' dated ' },
        needSplit: true,
      },
      {
        input: inputs.loanDate,
        Key: 'loanDate',
      },
      {
        input: { inputType: 'span', title: ' in the amount of $' },
        needSplit: true,
      },
      {
        input: inputs.loanAmount,
        Key: 'loanAmount',
      },
      {
        input: { inputType: 'span', title: ' recorded in Deed Book ' },
        needSplit: true,
      },
      {
        input: inputs.deedBookName,
        Key: 'deedBookName',
      },
      {
        input: { inputType: 'span', title: ', Page' },
        needSplit: true,
      },
      {
        input: inputs.deedBookPage,
        Key: 'deedBookPage',
      },
      {
        input: inputs.county,
        Key: 'county',
        className: 'ml-2',
      },
      {
        input: { inputType: 'span', title: ', ' },
        needSplit: true,
      },
      {
        input: inputs.state,
        Key: 'state',
      },
      {
        input: {
          inputType: 'span',
          title:
            ' Records and as a lien on that certain property described in Exhibit A, attached here to and made a part of hereof.',
        },
        needSplit: true,
      },
    ],
  ]
}
