import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { loanOptionsConstants } from 'config'
import type { ICrossCollateralBlanket } from 'pages/LoanStructure/interfaces'
import { useMemo } from 'react'
import { Checkbox, Input } from 'stories/components'

export const CrossCollateralBlanketLimit = ({ data }: { data: ICrossCollateralBlanket | undefined }) => {
  const renderLtvMaxLimits = useMemo(() => {
    if (!data)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <div>
        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 px-2">Min/Max # of Properties</div>
        <div className="flex items-center gap-4 p-2">
          {Object.keys(data.numberOfPropertiesLimit)
            .reverse()
            .map((key, index) => {
              const value = (data.numberOfPropertiesLimit as any)[key]

              return (
                <Input
                  key={index}
                  title={`${key === 'min' ? 'Min' : 'Max'}`}
                  type="number"
                  readOnly={true}
                  value={value}
                  className="mb-[-16px]"
                  onChange={() => {}}
                />
              )
            })}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">
          Property Level Allocated Balance
        </div>
        <div className="flex items-center gap-4 p-2">
          {Object.keys(data.propertyLevelAllocatedBalanceLimit)
            .reverse()
            .map((key, index) => {
              const value = (data.propertyLevelAllocatedBalanceLimit as any)[key]

              return (
                <Input
                  key={index}
                  title={`${key === 'min' ? 'Min' : 'Max'}`}
                  type="thousandSep"
                  readOnly={true}
                  value={value}
                  className="mb-[-16px]"
                  onChange={() => {}}
                />
              )
            })}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">Eligible Occupancy</div>
        <div className="flex justify-start flex-wrap gap-x-10 gap-y-2 px-2">
          {loanOptionsConstants.occupancy.map((item, index) => (
            <Checkbox
              key={index}
              id={item}
              title={item}
              size={3}
              value={data.eligibleOccupancies.includes(item)}
              color="gray"
              className="w-fit"
            />
          ))}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">
          Eligible Property Type
        </div>
        <div className="flex justify-start flex-wrap gap-x-10 gap-y-2 px-2">
          {loanOptionsConstants.propertyTypeBigList.map((item, index) => (
            <Checkbox
              key={index}
              id={item}
              title={item}
              size={3}
              value={data.eligiblePropertyTypes.includes(item)}
              color="gray"
              className="w-[175px]"
            />
          ))}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">As Is Value</div>
        <div className="flex items-center gap-4 p-2">
          <Input
            title="Min As Is Value"
            type="thousandSep"
            readOnly={true}
            value={data.minAsIsValue.toString()}
            className="mb-[-16px]"
            onChange={() => {}}
          />
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">Purchase Price</div>
        <div className="flex items-center gap-4 p-2">
          <Input
            title="Min Purchase Price"
            type="thousandSep"
            readOnly={true}
            value={data.minPurchasePrice.toString()}
            className="mb-[-16px]"
            onChange={() => {}}
          />
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">DSCR</div>
        {data.minDscr.length ? (
          <table className="table w-full text-center text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th rowSpan={2} className="border px-3 py-2 whitespace-nowrap">
                  Property Type
                </th>
                <th colSpan={2} className="border px-3 py-2">
                  Min DSCR
                </th>
              </tr>
              <tr>
                <th className="border px-3 py-2 w-1/4">Amortizing Payment</th>
                <th className="border px-3 py-2 w-1/4">Interest Only</th>
              </tr>
            </thead>

            <tbody>
              {data.minDscr.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={`border whitespace-nowrap p-1`}>{item.propertyType}</td>
                    <td className={`border whitespace-nowrap p-1`}>{item.amortizingPaymentMinDscr}</td>
                    <td className={`border whitespace-nowrap p-1`}>{item.interestOnlyMinDscr}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <div className="w-full flex justify-center items-center mt-10">
            <div className="flex flex-col items-center gap-1">
              <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
              <span className="text-gray-400">No Limits</span>
            </div>
          </div>
        )}
      </div>
    )
  }, [data])

  return <div className="h-full">{renderLtvMaxLimits}</div>
}
