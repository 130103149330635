import { CheckIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { TemporaryLayout } from 'layouts'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { createAssetInformationVerifyTask, getLoanAssetInformation } from 'services'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AssetInformationTable } from './AssetInformationTable'
import { type IAssetData, AssetAccountTypeForPlaid } from './constants'
import { SubmitToLenderModal } from './SubmitToLenderModal'

export const AssetInformationVerificationPage = () => {
  const [isLoading, setLoading] = useState(true)
  const [trackData, setTrackData] = useState<IAssetData[]>([])
  const [status, setStatus] = useState(0)
  const [lastAssetReportAt, setLastAssetReportAt] = useState(0)

  setLoanNumber()

  useEffect(() => {
    getLoanAssetInformation()
      .then(({ lastAssetReportAt, data }) => {
        setTrackData(data)
        setLastAssetReportAt(Number(lastAssetReportAt))
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!trackData.length) return
    updateStatus(trackData, lastAssetReportAt)
  }, [status, trackData, lastAssetReportAt])

  const updateStatus = (assets: IAssetData[], lastAssetReportAt: number) => {
    if (status == 0 || status == 3) return

    const hasUnverifiedItem = !!assets.filter(
      (item) => AssetAccountTypeForPlaid.includes(item.accountType || 0) && !(item.verified || item.override),
    ).length

    const hasNotReportedItems = !!assets
      .filter((asset) => (asset.createdAt || Date.now()) > lastAssetReportAt)
      .filter((asset) => AssetAccountTypeForPlaid.includes(Number(asset.accountType))).length

    if (!hasUnverifiedItem && hasNotReportedItems) status != 2 && setStatus(2)
    else status != 1 && setStatus(1)
  }

  const onSubmitToLender = async () => {
    await createAssetInformationVerifyTask()
    toast('All neccessary assets have been successfully submitted to the Lender.', { type: 'success' })
  }

  return (
    <TemporaryLayout title="Asset Verification" requireSign>
      <LayoutLoading show={isLoading} />

      <AssetInformationTable
        trackData={trackData}
        setTrackData={(data: IAssetData[]) => {
          setStatus(1)
          setTrackData(data)
        }}
        setLoading={setLoading}
      />

      {status == 2 && <SubmitToLenderModal onSubmitToLender={onSubmitToLender} onClose={() => setStatus(3)} />}

      {status == 3 && (
        <p className="text-left bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-[15px] flex gap-2 items-center max-w-3xl">
          <CheckIcon className="w-4 h-4" />
          All necessary assets have been successfully submitted to the Lender.
        </p>
      )}
    </TemporaryLayout>
  )
}
