import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { appApiUrl, COMPANY_NAME_FC, INVALID_ALL_INPUTS } from 'config'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Api from 'services/api'
import { downloadFile } from 'services/apis/documents'
import { Modal } from 'stories/components'
import { getPrice3decimal, InputValidate, removeComma, REQUIRED_FIELD_ERROR_MESSAGE, serialize } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const ConstructionLienWaiver = (props: any) => {
  const { data } = props
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [action, setAction] = useState('')

  const defaultInputs = () => {
    let orderOpts: any = {}
    data.map((item: any) => {
      const val = removeComma(item.eligibleAmount)
      orderOpts[getPrice3decimal(val)] = `${item.id} - ${item.reference} - ${getPrice3decimal(val)}`
    })
    orderOpts['other'] = 'Other'
    return {
      type: {
        inputType: 'select',
        title: 'Waiver Type',
        options: {
          conditional: 'Conditional Lien Waiver',
          unConditional: 'Unconditional Lien Waiver of Final Payment',
        },
        hasDefaultOption: true,
        value: '',
        required: true,
      },
      orderNo: {
        inputType: 'select',
        title: 'Order ID',
        options: orderOpts,
        hasDefaultOption: true,
        sort: false,
        value: '',
        error: '',
        required: true,
      },
      drawnAmount: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Total Drawn Amount',
        prefix: '$',
        error: '',
        value: '',
        required: true,
        disabled: true,
      },
      divider: {
        inputType: 'section',
        title: 'Exceptions',
      },
      exceptionReason: {
        inputType: 'text',
        title: 'This document does not affect any of the following',
        value: '',
        error: '',
      },
      extraAmount: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Disputed claims for extra Amount (Exception)',
        prefix: '$',
        value: '',
        error: '',
      },
    }
  }
  const [inputs, setInputs] = useState<any>(defaultInputs())

  const onOpen = () => {
    setInputs(defaultInputs())
  }

  const onClose = () => {}

  const onChangeInput = async (key: string, value: any) => {
    const error = InputValidate({ ...inputs[key], value })
    let temp = cloneDeep(inputs)
    temp[key].value = value
    temp[key].error = error

    if (key === 'orderNo') {
      if (value === 'other') {
        temp['drawnAmount'].disabled = false
        temp['drawnAmount'].value = ''
        temp['drawnAmount'].error = REQUIRED_FIELD_ERROR_MESSAGE
      } else {
        temp['drawnAmount'].disabled = true
        temp['drawnAmount'].value = value
        temp['drawnAmount'].error = ''
      }
    }

    setInputs(temp)
  }

  const auth = useSelector((state: any) => state.auth)

  const onSubmit = async () => {
    let errors = 0
    let data: any = {}
    let temp = cloneDeep(inputs)
    Object.keys(inputs).map((key) => {
      data[key] = inputs[key].value
      const error = InputValidate(inputs[key])
      if (error.length > 0) {
        temp[key].error = error
        errors += 1
      }
    })
    setInputs(temp)
    if (errors) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    setAction('download')
    const res: Blob = await Api.get(
      `${appApiUrl}/reports/constructionLienWaiver?${serialize(data)}&token=${auth.token}`,
      {},
      {},
      {
        responseType: 'blob',
      },
    )
    const loanNumber = Api.getLoanNumber()
    downloadFile(`${COMPANY_NAME_FC} Construction Lien Waiver - ${loanNumber}.pdf`, res)

    setAction('')
    setIsOpen(false)
    setLastUpdatedAt(Date.now())
  }

  return (
    <Modal
      button={
        <button className="hover:underline text-[14px] text-shade-blue p-2">
          <div className="flex items-center">
            <DocumentArrowDownIcon className="w-4 h-4 mr-1" /> Construction Lien Waiver
          </div>
        </button>
      }
      loading={action === 'download'}
      title={'Construction Lien Waiver'}
      titleOkay="Download"
      isOpen={isOpen}
      lastUpdatedAt={lastUpdatedAt}
      onClose={onClose}
      onOk={onSubmit}
      onOpen={onOpen}
    >
      <div className="w-[480px]">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div key={index} className={`md:col-span-${input.span}`}>
                <RenderInput input={input} Key={key} onChange={onChangeInput} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
