import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { getInitialLoanFields, SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services'
import { RenderInput } from 'utils/RenderInput'

export const StructureDesc = () => {
  const [inputs, setInputs] = useState(getInitialLoanFields())
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getSetting(SettingKey.LOAN_STRUCTURE_TOOLTIPS)
      .then(({ value }) => {
        const content = JSON.parse(value || '{}')

        let newInputs = cloneDeep(inputs)
        Object.keys(inputs).map((key) => {
          newInputs[key].value = content[key] || ''
        })
        setInputs(newInputs)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: any) => {
    const nInputs = cloneDeep(inputs)
    nInputs[key].value = value
    setInputs(nInputs)
  }

  const onSubmit = async () => {
    const data: Record<string, any> = {}
    for (const key in inputs) {
      data[key] = inputs[key].value
    }

    setLoading(true)
    await updateSetting(SettingKey.LOAN_STRUCTURE_TOOLTIPS, JSON.stringify(data))
    setLoading(false)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
        {Object.keys(inputs).map((key, index) => {
          const item = inputs[key]
          return (
            <RenderInput
              key={index}
              input={{
                title: item.title,
                inputType: 'textarea',
                type: 'text',
                rows: 2,
                value: item.value,
              }}
              Key={key}
              onChange={onChange}
              onBlur={onSubmit}
            />
          )
        })}
      </div>
    </div>
  )
}
