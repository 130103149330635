import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Modal } from 'stories/components'

export const DownloadTypeDialog = ({ onSubmit, onClose }: { onSubmit: Function; onClose: Function }) => {
  return (
    <Modal
      isOpen
      title="Download QA"
      onOk={() => onSubmit()}
      onClose={() => onClose(null, [])}
      titleOkay=""
      titleCancel=""
    >
      <div className="w-fit relative">
        <p className="mb-4">Select download type.</p>
        <div className="grid w-128 grid-cols-1 gap-2 mb-8">
          {[
            { label: 'PDF Format', value: 'pdf' },
            { label: 'Docx Format', value: 'docx' },
          ].map((v, index) => (
            <div
              className={`border rounded-md overflow-hidden p-4 cursor-pointer hover:border-shade-blue`}
              onClick={() => (onSubmit(v.value), onClose(null, []))}
            >
              <div className="flex justify-between items-center">
                <p className="font-semibold">
                  {index + 1}. {v.label}
                </p>
                <ArrowDownTrayIcon className="w-4 h-4 text-shade-blue" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}
