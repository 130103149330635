import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useMemo } from 'react'
import { Checkbox } from 'stories/components'
import { getPrice3decimal } from 'utils'

import type { IRuralPropertyLeverageLimit } from '../../interface'
import { convertNullToBlankValueToDecimal } from '../../logic'

export const propertyTypeLabels = [
  'SFR-Detached',
  'SFR-Attached',
  'Condo-Warrantable',
  'Condo-NonWarrantable',
  'Condotel',
  '2-4 Units',
  'PUD',
  'Mixed-Use',
  'Modular',
  'MF (5-8 units)',
  'MF (9-30 units)',
  'MF (31-100 units)',
  'Industrial',
  'Manufactured',
  'Self Storage',
  'Office',
  'Retail',
  'Warehouse',
  'Automotive',
]

export const RuralPropertyLeverageLimit = ({ data }: { data: IRuralPropertyLeverageLimit[] }) => {
  const renderLtvMaxLimits = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <div className="overflow-x-auto">
        <table className="w-full min-w-max text-center text-sm">
          <thead className="font-bold bg-gray-100">
            <tr>
              <th rowSpan={2} className="border p-1 whitespace-nowrap">
                Rural Property
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap w-[140px]">
                Max Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap w-[90px]">
                Min FICO
              </th>
              <th rowSpan={2} className="border p-1 w-[140px]">
                FICO
              </th>
              <th rowSpan={2} className="border p-1 w-[140px]">
                Loan Purpose
              </th>
              <th colSpan={2} className="border p-1">
                Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 w-[100px]">
                Min Months Reserve
              </th>
              <th colSpan={4} className="border p-1">
                Max LTVs
              </th>
            </tr>
            <tr>
              <th className="border p-1 w-[130px]">From</th>
              <th className="border p-1 w-[130px]">To</th>
              <th className="border p-1 w-[90px]">AIV-LTV</th>
              <th className="border p-1 w-[90px]">ARV-LTV</th>
              <th className="border p-1 w-[90px]">LTC</th>
              <th className="border p-1 w-[90px]">LTP</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  {item.limits.map((limit, idx) => (
                    <Fragment key={`${index}-${idx}`}>
                      <tr>
                        {idx === 0 && (
                          <td rowSpan={4 * item.limits.length} className="border px-3 py-1">
                            {item.value}
                          </td>
                        )}
                        {idx === 0 && (
                          <td rowSpan={4 * item.limits.length} className="border px-3 py-1">
                            {convertNullToBlankValueToDecimal(item.loanAmount)}
                          </td>
                        )}
                        {idx === 0 && (
                          <td rowSpan={4 * item.limits.length} className="border px-3 py-1">
                            {item.minFICO}
                          </td>
                        )}

                        <td rowSpan={3} className="border px-3 py-1">
                          {limit.FICO.from} - {limit.FICO.to}
                        </td>

                        <td className="border px-3 py-1">Purchase</td>
                        {['min', 'max'].map((key, idx) => (
                          <td key={`${index}-${idx}`} className="border px-3 py-1">
                            {getPrice3decimal((limit.loanAmount.purchase as any)[key])}
                          </td>
                        ))}

                        <td className="border px-3 py-1">{limit.minMonthsReserve.purchase}</td>
                        {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                          <td key={`${index}-${idx}`} className="border px-3 py-1">
                            {(limit.LTV.purchase as any)[key]}
                          </td>
                        ))}
                      </tr>

                      <tr>
                        <td className="border px-3 py-1">No-Cashout</td>
                        {['min', 'max'].map((key, idx) => (
                          <td key={`${index}-${idx}`} className="border px-3 py-1">
                            {getPrice3decimal((limit.loanAmount.nocashout as any)[key])}
                          </td>
                        ))}

                        <td className="border px-3 py-1">{limit.minMonthsReserve.nocashout}</td>
                        {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                          <td key={`${index}-${idx}`} className="border px-3 py-1">
                            {(limit.LTV.nocashout as any)[key]}
                          </td>
                        ))}
                      </tr>

                      <tr>
                        <td className="border px-3 py-1">Cashout</td>
                        {['min', 'max'].map((key, idx) => (
                          <td key={`${index}-${idx}`} className="border px-3 py-1">
                            {getPrice3decimal((limit.loanAmount.cashout as any)[key])}
                          </td>
                        ))}

                        <td className="border px-3 py-1">{limit.minMonthsReserve.cashout}</td>
                        {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                          <td key={`${index}-${idx}`} className="border px-3 py-1">
                            {(limit.LTV.cashout as any)[key]}
                          </td>
                        ))}
                      </tr>

                      <tr>
                        <td colSpan={9} className="p-3 text-left border">
                          <div className="border-b font-bold mb-1 pb-1">Property Type</div>
                          <div className="flex justify-start flex-wrap gap-x-4 gap-y-2">
                            {propertyTypeLabels.map((p, index1) => (
                              <Checkbox
                                key={`${index}-${idx}-${index1}`}
                                id={`${index}-${idx}-${index1}`}
                                title={p}
                                size={3}
                                value={limit.propertyTypes.includes(p)}
                                color="gray"
                                className="w-[175px]"
                              />
                            ))}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }, [data])

  return <div className="h-full">{renderLtvMaxLimits}</div>
}
