import {
  ArrowDownTrayIcon,
  ArrowLeftCircleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ClockIcon,
  EnvelopeIcon,
  IdentificationIcon,
  KeyIcon,
  PencilIcon,
  PencilSquareIcon,
  StarIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { ChangePassword, CreateUser } from 'components/Modals'
import { accountTypeOptions, accountTypes, assignedToAccountTypes } from 'components/Modals/CreateUser/config'
import { confirmChildUsers } from 'components/Modals/CreateUser/ConfirmUser'
import { AccountType, appApiUrl, companyName, UserStatusFilters, VerificationAccountType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { AccountVerificationType, BrokerIdentifyCategory } from 'pages/Admin'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  deleteUser,
  filterUsers,
  getAdminEmails,
  getBrokerVerificationCategories,
  getChildUsers,
  recoverUser,
  requestUserRoles,
  updateChildBroker,
  updateChildUserStatus,
  updateChildUserTypes,
  updateUserStatus,
} from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { AutoComplete, Input, Pagination, Select, Toggle } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { confirm, formatDate, getPrice2decimal } from 'utils'
import { useQuery, useTitle } from 'utils/pageTitle'
import { PermissionGate } from 'utils/PermissionGate'

import { BrokerVerificationForAdmin } from './BrokerVerificationForAdmin'
import { CompanyBroadcastDialog } from './CompanyBroadcastDialog'
import { Tree } from './Tree'
import { type UserInfo, PullCreditScoreLevels } from './user.type'
import { UserNotesSection } from './UserNotesSection'

const itemCountPerPage = 10

export function ManageAccounts() {
  useTitle(`Accounts - ${companyName}`)

  const locationQuery = useQuery()
  const { hasPermission } = usePermissions()

  const canSendCompanyBroadcastEmail = hasPermission('CAN_SEND_COMPANY_BROADCAST_EMAIL')

  const [filters, setFilters] = useState<Record<string, any>>({
    query: locationQuery.get('email') || '',
    status: locationQuery.get('status') || 'active',
    accountType: '0',
    pullCreditScoreLevel: '',
    orderBy: 'createdAt',
    orderDir: '-1',
    pageNum: 0,
    showOthers: false,
    showDeleted: false,
    loanActivity: false,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)

  const [users, setUsers] = useState<Array<UserInfo>>([])
  const [total, setTotal] = useState(0)
  const [action, setAction] = useState('')

  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedEmail, setSelectedEmail] = useState('')
  const [isOpenFromType, setIsOpenFromType] = useState(false)
  const [isOpenChangePwd, setIsOpenChangePwd] = useState(false)
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [isOpenUserNotes, setIsOpenUserNotes] = useState(false)
  const [editChildBroker, setEditChildBroker] = useState<UserInfo | null>(null)
  const [editBrokerIdentity, setEditBrokerIdentity] = useState<UserInfo | null>(null)
  const [companyBroadcastUser, setCompanyBroadcastUser] = useState<UserInfo | null>(null)
  const [verificationCategories, setVerificationCategories] = useState<
    Record<AccountVerificationType, BrokerIdentifyCategory[]>
  >({
    broker: [],
    correspondent: [],
    tablefunder: [],
    contractorApproval: [],
  })
  const [assignedToList, setAssignedToList] = useState<Record<string, string>>({})
  const [executives, setExecutives] = useState({})

  const auth = useSelector((state: any) => state.auth)

  const fullPermission = useMemo(() => {
    return [AccountType.ADMIN, AccountType.VENDOR_APPROVAL].includes(auth.profile.accountType)
  }, [auth.profile])

  const canCreateUser = useMemo(() => {
    return [
      AccountType.ADMIN,
      AccountType.VENDOR_APPROVAL,
      AccountType.OPERATION_SUPERVISOR,
      AccountType.NATIONAL_SALE,
      AccountType.BROKER,
      AccountType.CORRESPONDENT,
      AccountType.TABLEFUNDER,
      AccountType.ACCOUNT_EXECUTIVE,
    ].includes(auth.profile.accountType)
  }, [auth.profile])

  const canUpdateUser = useMemo(() => {
    return [
      AccountType.ADMIN,
      AccountType.VENDOR_APPROVAL,
      AccountType.OPERATION_SUPERVISOR,
      AccountType.BROKER,
      AccountType.CORRESPONDENT,
      AccountType.TABLEFUNDER,
      AccountType.NATIONAL_SALE,
      AccountType.ACCOUNT_EXECUTIVE,
    ].includes(auth.profile.accountType)
  }, [auth.profile])

  const downloadLink = useMemo(() => {
    const filter: Record<string, string> = {
      query: filters.query.trim(),
      status: filters.status,
      accountType: filters.accountType == '0' ? undefined : filters.accountType,
      pullCreditScoreLevel: filters.pullCreditScoreLevel ? filters.pullCreditScoreLevel : undefined,
      showDeleted: filters.showDeleted,
      showOthers: filters.showOthers,
      loanActivity: filters.loanActivity,
      orderBy: filters.orderBy,
      orderDir: filters.orderDir,
    }
    if (!filter.accountType) delete filter.accountType

    const filterStr = Object.keys(filter)
      .map((key) => `${key}=${filter[key]}`)
      .join('&')
    return `${appApiUrl}/user/download?token=${auth.token}&${filterStr}`
  }, [auth.token, filters])

  const filterUsersData = async (filters: Record<string, any>) => {
    setAction('getUsers')
    const { data, total } = await filterUsers({
      query: filters.query.trim(),
      status: filters.status,
      accountType: filters.accountType == '0' ? undefined : filters.accountType,
      pullCreditScoreLevel: filters.pullCreditScoreLevel ? filters.pullCreditScoreLevel : undefined,
      count: itemCountPerPage,
      skip: filters.pageNum * itemCountPerPage,
      orderBy: filters.orderBy,
      orderDir: filters.orderDir,
      showDeleted: filters.showDeleted,
      showOthers: filters.showOthers,
    })
    setUsers(data)
    setTotal(total)
    setAction('')
  }

  useEffect(() => {
    if (editBrokerIdentity) {
      users.map((item, index) => {
        if (item.id === editBrokerIdentity.id) {
          setEditBrokerIdentity(users[index])
        }
      })
    }
  }, [users])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => action == '' && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  useEffect(() => {
    filterUsersData(filters).then(() => {
      setIsGetUsersOnce(true)
    })

    loadVerificationCategories()
    updateExecutives()
  }, [])

  const updateExecutives = () => {
    requestUserRoles(AccountType.ACCOUNT_EXECUTIVE, 0).then((roles) => {
      setExecutives(roles)
    })
  }

  useEffect(() => {
    if (isGetUsersOnce && users.length == 1 && locationQuery.get('showBrokerVerification') == 'true') {
      const user = users.find((user) => user.email == locationQuery.get('email'))
      if (!user) return
      showBrokerVerification(user)
    }
  }, [isGetUsersOnce])

  useEffect(() => {
    if (![AccountType.ADMIN].includes(auth.profile.accountType)) return

    getAdminEmails().then((emails) => {
      const newOptions: Record<string, string> = {}
      assignedToAccountTypes.forEach((key) => (newOptions[key] = accountTypes[key]))
      emails.forEach((email) => (newOptions[email] = email))
      setAssignedToList(newOptions)
    })
  }, [])

  const loadVerificationCategories = async () => {
    const values = await Promise.all(
      [AccountVerificationType.Broker, AccountVerificationType.Correspondent, AccountVerificationType.Tablefunder].map(
        (type) => getBrokerVerificationCategories(type),
      ),
    )
    setVerificationCategories({
      broker: values[0],
      correspondent: values[1],
      tablefunder: values[2],
      contractorApproval: [],
    })
  }

  const sameVerificationCategories = useMemo(() => {
    let rlt: any = []
    let part: any = []
    const data: any = cloneDeep(verificationCategories)
    Object.keys(data).map((key) => {
      const items: any = data[key]
      items.map((item: any) => {
        if (!part.includes(item.id)) {
          let group = [item.id]
          part.push(item.id)
          Object.keys(data).map((key1) => {
            if (key1 !== key) {
              const items1 = data[key1]
              items1.map((item1: any) => {
                if (!part.includes(item1.id)) {
                  if (item.value.toLowerCase().trim() === item1.value.toLowerCase().trim()) {
                    group.push(item1.id)
                    part.push(item1.id)
                  }
                }
              })
            }
          })
          rlt.push(group)
        }
      })
    })
    return rlt
  }, [verificationCategories])

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    if (key === 'loanActivity') {
      newFilters['orderBy'] = value ? 'createdLoans' : 'createdAt'
      newFilters['orderDir'] = -1
    }
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterUsersData(newFilters)
  }

  const onEdit = (index: number) => {
    setIsOpen(true)
    setSelectedIndex(index)
    setLastUpdatedAt(Date.now())
  }

  const onChangePassword = (index: number) => {
    setIsOpenChangePwd(true)
    setSelectedIndex(index)
    setSelectedEmail(users[index].email)
    setLastUpdatedAt(Date.now())
  }

  const onUserSubmit = () => {
    filterUsersData(filters)
    updateExecutives()
  }

  const onCloseUserModal = () => {
    setSelectedIndex(-1)
    setIsOpen(false)
    setIsOpenChangePwd(false)
    setSelectedEmail('')
    setLastUpdatedAt(Date.now())
    setIsOpenFromType(false)
  }

  const askForChildren = async (user: UserInfo) => {
    const { id: userId, accountType } = user
    const askingTypes = [
      AccountType.NATIONAL_SALE,
      AccountType.ACCOUNT_EXECUTIVE,
      AccountType.BROKER,
      AccountType.RETAIL,
      AccountType.CORRESPONDENT,
      AccountType.TABLEFUNDER,
      AccountType.BRANCH,
    ]
    if (askingTypes.indexOf(accountType) != -1) {
      const childUsers = await getChildUsers(userId)
      if (childUsers.length) {
        const confirmResult = (await confirmChildUsers({
          isChangedAccountType: true,
          defaultData: user,
          users: childUsers,
        })) as any
        if (confirmResult === false) return false
        if (confirmResult != true) {
          await updateChildUserTypes(userId, confirmResult)
        }
      }
    }
    return true
  }

  const onTrash = async (index: number) => {
    const user = users[index]
    const isRemove = await confirm(
      `Are you sure you want to ${filters.showDeleted ? 'permanently' : ''} delete "${user.name}"?`,
    )
    if (!isRemove) return

    const isApprovedAsk = await askForChildren(user)
    if (!isApprovedAsk) return

    const newUsers = Object.assign([], users)
    newUsers.splice(index, 1)
    setUsers(newUsers)

    setAction('getUsers')
    await deleteUser(user.id)
    filterUsersData(filters)
  }

  const onAddNotes = async (index: number) => {
    setSelectedIndex(index)
    setIsOpenUserNotes(true)
  }

  const onRecoverUser = async (index: number) => {
    const user = users[index]
    const content = (
      <div className="mb-4">
        <p>Are you really sure want to recover?</p>
        <p>Name: {user.name}</p>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newUsers = Object.assign([], users)
    newUsers.splice(index, 1)
    setUsers(newUsers)

    setAction('getUsers')
    await recoverUser(user.id)
    filterUsersData(filters)
  }

  const onChangeStatus = async (id: number, status: boolean) => {
    const userIndex = users.findIndex((user) => user.id == id)
    if (userIndex == -1) return
    const user = users[userIndex]

    if (VerificationAccountType.indexOf(user.accountType) !== -1 && status) {
      if (user.accountExecutive <= 0) {
        return toast('Account Executive is Required!', { type: 'error' })
      }
      const content = (
        <div className="text-gray-600 mb-4">
          <div className="font-semibold mb-4">Are you sure want to approve this user?</div>
          <div>Name: {user.name}</div>
        </div>
      )
      const result = await confirm(content)
      if (!result) return
    } else if (!status) {
      let content = (
        <div className="text-gray-600 mb-4">
          <div className="font-semibold">Are you sure want to deactive this user?</div>
          <div>Name: {user.name}</div>
        </div>
      )
      const result = await confirm(content)
      if (!result) return

      const childUsers = await getChildUsers(user.id)
      if (childUsers.length) {
        content = (
          <div className="text-gray-600 mb-4">
            <div className="font-semibold">
              There are {childUsers.length} child user(s) under this account. <br />
              Are you sure want to deactive this user?
            </div>
          </div>
        )
        const isApprovedAsk = await confirm(content)
        if (!isApprovedAsk) return
        await updateChildUserStatus(user.id, status)
      }
    }

    setAction('updateStatus')

    updateUserStatus(id, status)
      .then(() => {
        let newUsers = cloneDeep(users)
        newUsers[userIndex].isActive = status
        setUsers(newUsers)
      })
      .finally(() => setAction(''))
  }

  let selectedData: Record<string, any> | null = selectedIndex == -1 ? null : users[selectedIndex]

  if (isOpenFromType && selectedData) {
    const { id, accountType, accountExecutive, broker } = selectedData
    switch (accountType) {
      case AccountType.ACCOUNT_EXECUTIVE:
        selectedData = {
          accountType: AccountType.BROKER,
          accountExecutive: id,
        }
        break
      case AccountType.BROKER:
      case AccountType.RETAIL:
      case AccountType.CORRESPONDENT:
      case AccountType.TABLEFUNDER:
        selectedData = {
          accountType: AccountType.BRANCH,
          accountExecutive,
          broker: id,
        }
        break
      case AccountType.BRANCH:
        selectedData = {
          accountType: AccountType.LOAN_PROCESSOR,
          accountExecutive,
          broker,
          branch: id,
        }
        break
    }
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const renderHeader = (title: any, sortable: boolean = false, key: string, sortOrder: number = 1) => {
    if (!sortable)
      return (
        <th scope="col" className="py-3" key={key}>
          {title}
        </th>
      )

    const onSort = () => {
      if (sortOrder == 0) sortOrder = -1
      const newFilters = Object.assign({}, filters)
      newFilters['orderBy'] = key
      newFilters['orderDir'] = `${0 - sortOrder}`
      setFilters(newFilters)
      filterUsersData(newFilters)
    }

    return (
      <th scope="col" className="py-3" key={key}>
        <button className="flex uppercase bg-transparent font-bold" onClick={() => onSort()}>
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-2" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
      </th>
    )
  }

  const showBrokerChild = (user: UserInfo) => {
    setEditChildBroker(user)
    setEditBrokerIdentity(null)
  }

  const showBrokerVerification = (user: UserInfo) => {
    setEditChildBroker(null)
    setEditBrokerIdentity(user)
  }

  const onChangeChildBroker = async (emails: string[]) => {
    if (!editChildBroker) return

    setAction('updateBrokerEmail')
    try {
      const childBrokers = await updateChildBroker(editChildBroker.id, emails)
      const newEditChildBroker = cloneDeep(editChildBroker)
      newEditChildBroker.childBrokers = childBrokers
      setEditChildBroker(newEditChildBroker)

      const index = users.findIndex((user) => user.id === editChildBroker.id)
      const newUsers = Object.assign([], users)
      newUsers.splice(index, 1, newEditChildBroker)
      setUsers(newUsers)
      setAction('')
    } catch (e) {
      setAction('')
      throw new Error('')
    }
  }

  const onUpdateBrokerPwd = (email: string) => {
    if (!editChildBroker) return

    const index = users.findIndex((user) => user.id === editChildBroker.id)
    setIsOpenChangePwd(true)
    setSelectedIndex(index)
    setSelectedEmail(email)
    setLastUpdatedAt(Date.now())
  }

  const sortableHeaders = [
    { title: 'Company Name', key: 'companyName' },
    {
      title: (
        <span>
          <div className="border-b w-fit mb-1 border-gray-300">Name</div>
          Email
        </span>
      ),
      key: 'name',
    },
    {
      title: (
        <span>
          <div className="border-b w-fit mb-1 border-gray-300">Phone Number</div>
          Credit Eligible
        </span>
      ),
      key: 'phone',
    },
    { title: 'NMLS #', key: 'companyNmls' },
    { title: 'User Type', key: 'accountType' },
  ]

  return (
    <div className="registration-container py-6 px-2">
      <div className="shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full">
        <div className="flex flex-wrap justify-between mb-5">
          <h1 className="text-2xl font-bold flex items-center mb-3">
            <span>Manage Accounts</span>{' '}
            <span className="text-base ml-3">
              {action !== '' ? (
                <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
              ) : (
                `(${total})`
              )}
            </span>
          </h1>
          {canCreateUser && (
            <CreateUser
              isOpen={isOpen}
              defaultData={selectedData}
              executives={executives}
              assignedToList={assignedToList}
              lastUpdatedAt={lastUpdatedAt}
              onAfterSubmit={onUserSubmit}
              onClose={onCloseUserModal}
            />
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-2 items-center">
          <Input
            type="search"
            title="Search Accounts"
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
          <Select
            id="search-status"
            title="Status"
            options={UserStatusFilters}
            value={filters.status}
            onChange={(value) => onChangeFilter('status', value)}
          />
          <Select
            id="search-user-type"
            title="User Type"
            options={accountTypeOptions(auth.profile.accountType)}
            value={filters.accountType}
            hasDefaultOption
            defaultOptionText="All"
            onChange={(value) => onChangeFilter('accountType', value)}
          />
          <Select
            id="search-user-pull-credit-score"
            title="Credit Pull"
            options={PullCreditScoreLevels}
            value={filters.pullCreditScoreLevel}
            hasDefaultOption
            defaultOptionText="All"
            onChange={(value) => onChangeFilter('pullCreditScoreLevel', value)}
          />
          {[AccountType.ACCOUNT_EXECUTIVE, AccountType.NATIONAL_SALE].includes(auth.profile.accountType) && (
            <div className="flex justify-start items-center flex-wrap gap-2 mb-4">
              <Toggle
                id="show-others"
                title="Assigned to Me"
                value={!filters.showOthers}
                onChange={(value) => onChangeFilter('showOthers', !value)}
              />
            </div>
          )}
          {fullPermission && (
            <div className="flex justify-end items-center flex-wrap gap-2">
              <Toggle
                id="show-deleted"
                title="Trash"
                value={filters.showDeleted}
                onChange={(value) => onChangeFilter('showDeleted', value)}
              />
            </div>
          )}
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <LayoutLoading show={['getUsers', 'updateBrokerEmail', 'updateStatus'].includes(action)} />

          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="pl-6 py-3 pr-3">
                  No
                </th>
                {sortableHeaders.map(({ title, key }) => {
                  if (filters.loanActivity && ['companyName', 'phone', 'companyNmls'].includes(key)) return null
                  return renderHeader(title, true, key, filters.orderBy == key ? parseInt(filters.orderDir) : 0)
                })}
                {!filters.loanActivity && (
                  <th scope="col" className="py-3">
                    Executive
                  </th>
                )}
                {filters.loanActivity &&
                  renderHeader(
                    <span>
                      Submitted
                      <br />
                      Loans
                    </span>,
                    true,
                    'createdLoans',
                    filters.orderBy == 'createdLoans' ? parseInt(filters.orderDir) : 0,
                  )}
                {filters.loanActivity &&
                  renderHeader(
                    <span>
                      Funded
                      <br />
                      Loans
                    </span>,
                    true,
                    'fundedLoans',
                    filters.orderBy == 'fundedLoans' ? parseInt(filters.orderDir) : 0,
                  )}
                {filters.loanActivity &&
                  renderHeader(
                    <span>
                      Withdrawn
                      <br />
                      Loans
                    </span>,
                    true,
                    'withdrawnLoans',
                    filters.orderBy == 'withdrawnLoans' ? parseInt(filters.orderDir) : 0,
                  )}

                {filters.loanActivity &&
                  renderHeader('Score', true, 'score', filters.orderBy == 'score' ? parseInt(filters.orderDir) : 0)}
                {filters.loanActivity &&
                  renderHeader(
                    <span>
                      Credit
                      <br />
                      Requests
                    </span>,
                    true,
                    'pullCreditScore',
                    filters.orderBy == 'pullCreditScore' ? parseInt(filters.orderDir) : 0,
                  )}
                {filters.loanActivity &&
                  renderHeader(
                    <span>
                      Credit
                      <br />
                      Eligible
                    </span>,
                    false,
                    'pullCreditScoreLevel',
                    filters.orderBy == 'pullCreditScoreLevel' ? parseInt(filters.orderDir) : 0,
                  )}
                {renderHeader(
                  'Created At',
                  true,
                  'createdAt',
                  filters.orderBy == 'createdAt' ? parseInt(filters.orderDir) : 0,
                )}
                {canUpdateUser && (
                  <th scope="col" className="">
                    Actions
                  </th>
                )}
                {canUpdateUser && (
                  <th scope="col" className="">
                    Status
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="">
              {users.map((user, index) => {
                const { id } = user
                let rlt = []
                rlt.push(
                  <tr
                    className={`${
                      index % 2 ? 'bg-gray-50' : ''
                    } bg-white hover:bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700`}
                    key={`${index}`}
                  >
                    <td
                      scope="row"
                      className="pl-6 py-3 pr-3 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                    >
                      {filters.pageNum * itemCountPerPage + index + 1}
                    </td>
                    {!filters.loanActivity && <td className="max-w-[200px] pr-3">{user.companyName}</td>}
                    <td className="py-3 pr-3">
                      <div>
                        <Link
                          to={`/registrations/${user.id}`}
                          target="_blank"
                          className="font-bold text-shade-blue cursor-pointer border-b w-fit mb-2 text-left"
                          // onClick={() => {
                          //   const newFilters = Object.assign({}, filters)
                          //   newFilters.query = user.name
                          //   newFilters.loanActivity = true
                          //   newFilters['orderBy'] = 'createdLoans'
                          //   newFilters['orderDir'] = -1

                          //   setFilters(newFilters)
                          //   setFilterQuery(user.name)
                          //   filterUsersData(newFilters)
                          // }}
                        >
                          {user.name}
                        </Link>
                        <div>{user.email}</div>
                      </div>
                    </td>
                    {!filters.loanActivity && (
                      <td>
                        <span>
                          <div className="border-b w-full mb-2">{user.phone}</div>
                          {PullCreditScoreLevels[`${user.pullCreditScoreLevel}`]}
                        </span>
                      </td>
                    )}
                    {!filters.loanActivity && <td>{user.companyNmls}</td>}
                    <td>
                      <div className="flex items-center">
                        {user.master && <StarIcon className="w-4 h-4 text-shade-blue" />}
                        <span className="mr-2">{(accountTypes as any)[user.accountType]}</span>
                        {[AccountType.BROKER, AccountType.TABLEFUNDER].includes(user.accountType) && (
                          <span
                            className="text-shade-blue cursor-pointer hover:underline hover-shadow1 p-1 rounded flex items-center"
                            onClick={() => showBrokerChild(user)}
                          >
                            <UserPlusIcon className="w-5 h-4"></UserPlusIcon>
                            {user.childBrokers?.length > 0 && <span className="">{user.childBrokers.length}</span>}
                          </span>
                        )}

                        {VerificationAccountType.includes(user.accountType) && (
                          <span
                            className="text-shade-blue cursor-pointer hover:underline hover-shadow1 p-1 rounded flex items-center"
                            onClick={() => showBrokerVerification(user)}
                          >
                            <IdentificationIcon className="w-4 h-4"></IdentificationIcon>
                          </span>
                        )}

                        {canSendCompanyBroadcastEmail && (
                          <span
                            className="text-shade-blue cursor-pointer hover:underline hover-shadow1 p-1 rounded flex items-center"
                            onClick={() => setCompanyBroadcastUser(user)}
                          >
                            <EnvelopeIcon className="w-4 h-4" />
                          </span>
                        )}
                      </div>
                    </td>
                    {filters.loanActivity && (
                      <td className="pr-2">
                        <span>
                          <div className="border-b w-full mb-2">{user.createdLoans}</div>
                          {user.quarterCreatedLoans}
                        </span>
                      </td>
                    )}
                    {filters.loanActivity && (
                      <td className="pr-2">
                        <span>
                          <div className="border-b w-full mb-2">{user.fundedLoans}</div>
                          {user.quarterFundedLoans}
                        </span>
                      </td>
                    )}
                    {filters.loanActivity && (
                      <td className="pr-2">
                        <span>
                          <div className="border-b w-full mb-2">{user.withdrawnLoans}</div>
                          {user.quarterWithdrawnLoans}
                        </span>
                      </td>
                    )}
                    {filters.loanActivity && (
                      <td className="pr-2">
                        <span>
                          <div className="border-b w-full mb-2">{getPrice2decimal(user.score || 0, false, true)}</div>
                          {getPrice2decimal(user.quarterScore || 0, false, true)}
                        </span>
                      </td>
                    )}
                    {filters.loanActivity && (
                      <td>
                        <a
                          href={`${appApiUrl}/user/downloadCreditRequests/${user.id}?token=${auth.token}`}
                          target="_blank"
                          className="font-bold text-shade-blue hover:underline cursor-pointer"
                        >
                          {user.pullCreditScore}
                        </a>
                      </td>
                    )}
                    {filters.loanActivity && <td>{PullCreditScoreLevels[`${user.pullCreditScoreLevel}`]}</td>}
                    {!filters.loanActivity && (
                      <td>
                        {user.executive}
                        {user.additionalExecutive && (
                          <div className="border-t w-full pt-2">{user.additionalExecutive}</div>
                        )}
                      </td>
                    )}
                    <td>{formatDate(user.createdAt)}</td>
                    {canUpdateUser && (
                      <td className="">
                        <span className="flex">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onEdit(index)}
                          >
                            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                          </span>
                          <span
                            className="text-blue-600 p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onChangePassword(index)}
                          >
                            <KeyIcon className="w-4 h-4"></KeyIcon>
                          </span>
                          {fullPermission && (
                            <span
                              className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                              onClick={() => onTrash(index)}
                            >
                              <TrashIcon className="w-4 h-4"></TrashIcon>
                            </span>
                          )}
                          <Tooltip message="Write Notes">
                            <span
                              className="text-blue-600 p-1 hover-shadow1 cursor-pointer"
                              onClick={() => onAddNotes(index)}
                            >
                              <PencilIcon className="w-4 h-4"></PencilIcon>
                            </span>
                          </Tooltip>
                          {fullPermission && user.deleted && (
                            <span
                              className="text-shade-blue p-1 hover-shadow1 rounded cursor-pointer"
                              onClick={() => onRecoverUser(index)}
                            >
                              <ArrowLeftCircleIcon className="w-4 h-4"></ArrowLeftCircleIcon>
                            </span>
                          )}
                        </span>
                      </td>
                    )}
                    {canUpdateUser && (
                      <td>
                        <span>
                          <div className="scale-90">
                            <Toggle
                              id={`status-${id}`}
                              key={`status-${id}`}
                              value={user.isActive}
                              disabled={VerificationAccountType.indexOf(user.accountType) !== -1}
                              onChange={(checked) => onChangeStatus(id, checked)}
                            />
                          </div>
                        </span>
                      </td>
                    )}
                  </tr>,
                )
                if (editChildBroker && editChildBroker.id === id) {
                  const brokerEmails = editChildBroker.childBrokers.map((child) => child.email)
                  rlt.push(
                    <tr className={`border-b`} key={`childBroker-${index}`}>
                      <td></td>
                      <td colSpan={8}>
                        <div className="my-2 border p-2 rounded shadow">
                          <div className="flex justify-between mb-2">
                            <h2 className="text-2xl font-bold flex items-center">
                              <span className="text-[16px] ml-2">
                                Child {accountTypes[editChildBroker.accountType]}s
                              </span>
                            </h2>
                            <span
                              className="text-[14px] text-red-800 hover:underline cursor-pointer"
                              onClick={() => setEditChildBroker(null)}
                            >
                              Close
                            </span>
                          </div>
                          <div>
                            <AutoComplete
                              className=""
                              title={''}
                              key={id}
                              type={'email'}
                              value={brokerEmails}
                              toLowerCase
                              disabled={!canUpdateUser}
                              additionalComponent={(value: string) => (
                                <KeyIcon
                                  className="w-4 h-4 cursor-pointer text-gray-500 hover:text-red-600 mr-1"
                                  onClick={() => {
                                    canUpdateUser && onUpdateBrokerPwd(value)
                                  }}
                                />
                              )}
                              onChange={(value: any) => onChangeChildBroker(value)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>,
                  )
                } else if (editBrokerIdentity && editBrokerIdentity.id === user.id) {
                  const type = user.accountType as string as AccountVerificationType
                  rlt.push(
                    <tr className={`border-b`} key={`childBroker-${index}`}>
                      <td></td>
                      <td colSpan={8} className="px-2">
                        <BrokerVerificationForAdmin
                          fullPermission={fullPermission}
                          canUpdateUser={canUpdateUser}
                          user={editBrokerIdentity}
                          categories={verificationCategories[type]}
                          sameVerificationCategories={sameVerificationCategories}
                          onClose={() => setEditBrokerIdentity(null)}
                          onChangeStatus={(checked: boolean) => onChangeStatus(editBrokerIdentity.id, checked)}
                        />
                      </td>
                    </tr>,
                  )
                }
                if (isOpenUserNotes && users[selectedIndex] && users[selectedIndex].id === user.id) {
                  rlt.push(
                    <tr className={`border-b`} key={`childBroker-${index}`}>
                      <td></td>
                      <td colSpan={8} className="px-2">
                        <UserNotesSection
                          user={users[selectedIndex]}
                          onClose={() => {
                            setIsOpenUserNotes(false)
                            setSelectedIndex(-1)
                          }}
                        />
                      </td>
                    </tr>,
                  )
                }
                return rlt
              })}
            </tbody>
          </table>
          <div className="flex flex-wrap justify-between items-center">
            <div className="ml-6">
              <Toggle
                id="loan-activity"
                title="Loan Activity"
                value={filters.loanActivity}
                onChange={(value) => onChangeFilter('loanActivity', value)}
              />
            </div>
            <div className="flex justify-end items-center mt-3 mb-3">
              <Pagination
                totalCount={total}
                itemCountPerPage={itemCountPerPage}
                onNavigate={onPageNavigate}
                pageNum={filters.pageNum}
              />
              <a className="my-2 mr-2 px-2 py-1 rounded hover-shadow1" href={downloadLink} target="_blank">
                <ArrowDownTrayIcon className="h-5 w-5 text-blue-500" />
              </a>
              <Link className="my-2 mr-5 px-2 py-1 rounded hover-shadow1" to={`/registrations/exportHistory`}>
                <ClockIcon className="h-5 w-5 cursor-pointer text-blue-500" aria-hidden="true" />
              </Link>
            </div>
          </div>

          {isOpenChangePwd && selectedData && (
            <ChangePassword
              userId={users[selectedIndex].id}
              email={selectedEmail}
              isOpen={isOpenChangePwd}
              lastUpdatedAt={lastUpdatedAt}
              onClose={onCloseUserModal}
            />
          )}
          {companyBroadcastUser && (
            <CompanyBroadcastDialog user={companyBroadcastUser} onClose={() => setCompanyBroadcastUser(null)} />
          )}
        </div>
      </div>
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'} hidden={true}>
        <div className="tree">
          <div className="shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 mt-8 sm:text-center lg:text-left w-full">
            <Tree />
          </div>
        </div>
      </PermissionGate>
    </div>
  )
}
