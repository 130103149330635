import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
  ArrowsUpDownIcon,
  ArrowUpTrayIcon,
  CloudArrowUpIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET, logout } from 'actions'
import cloneDeep from 'clone-deep'
import { TargetBox } from 'components/DragDrop'
import { LayoutLoading } from 'components/LayoutLoading'
import { LogoBlue } from 'components/Logo'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { COMPANY_NAME_FC, INVALID_REQUEST } from 'config'
import jwt from 'jwt-decode'
import type { AccountVerificationType, BrokerIdentifyCategory } from 'pages/Admin'
import React, { useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getBrokerVerification,
  openS3Document,
  signBrokerVerificationPdfForUser,
  submitBrokerVerification,
  uploadFiles,
  validateBrokerVerificationSignature,
} from 'services'
import { getBrokerVerificationCategories, sendBrokerVerificationMessage } from 'services/apis/admin'
import { Button, Modal, TextArea, Toggle } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { Footer } from 'stories/layouts'
import { formatTime } from 'utils'

import type { UserInfo } from './user.type'

export enum VerificationStatus {
  Initial = 'Initial',
  Pending = 'Pending',
  Approved = 'Approved',
  NotApproved = 'Not Approved',
  Unacceptable = 'Unacceptable',
  Waived = 'Waived',
}

export const VerificationCategoryStatusOptions = [
  VerificationStatus.Pending,
  VerificationStatus.Approved,
  VerificationStatus.Unacceptable,
  VerificationStatus.Waived,
]

export const VerificationDocsStatusOptions = [
  VerificationStatus.Approved,
  VerificationStatus.Pending,
  VerificationStatus.Unacceptable,
  VerificationStatus.Waived,
]

export interface BrokerVerificationDocument {
  id: number
  email: string
  fileName: string
  fileKey: string
  category: string
  categoryName?: string
  createdAt: string
  status: VerificationStatus
}

export enum BrokerVerificationHistoryType {
  VerificationRequest = 'verificationRequest',
  ReviewRequest = 'reviewRequest',
  MessageToBroker = 'messageToBroker',
  MessageToVendor = 'messageToVendor',
  SignRequest = 'signRequest',
  StatusChange = 'statusChange',
  CategoryStatusChange = 'categoryStatusChange',
  CreateCategory = 'createCategory',
}

export const BrokerVerificationHistoryTypeText = {
  verificationRequest: 'Send Invitation Request',
  reviewRequest: 'Submit Review Request',
  messageToBroker: 'Message to Broker',
  messageToVendor: 'Message to Vendor',
  signRequest: 'Send Sign Request',
  statusChange: 'Status Change',
  categoryStatusChange: 'Category Status Change',
  createCategory: 'New Category Created',
}

export interface BrokreVerificationHistory {
  id: number
  email: string
  type: BrokerVerificationHistoryType
  content: string
  createdAt: string
}

export const BrokerVerificationForUser = () => {
  const urlParams: any = useParams()
  const { urlSignature, token } = urlParams
  const navigate = useHistory()

  const [documents, setDocuments] = useState<BrokerVerificationDocument[]>([])
  const [histories, setHistories] = useState<BrokreVerificationHistory[]>([])
  const [verificationCategories, setVerificationCategories] = useState<BrokerIdentifyCategory[]>([])
  const [isLoading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)
  const dispatch = useDispatch()
  const profile = useSelector((state: any) => state.auth.profile)
  const [isOpenMessage, setIsOpenMessage] = useState(false)
  const [isOpenBrokerAgreement, setIsOpenBrokerAgreement] = useState(false)
  const [isConfirmBrokerAgreement, setIsConfirmBrokerAgreement] = useState(false)
  const [message, setMessage] = useState('')
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo)

  useEffect(() => {
    if (!token || !urlSignature) {
      navigate.push('/')
      return
    }
    const payload: any = jwt(token)
    setLoading(true)
    setValidated(false)
    validateBrokerVerificationSignature({
      urlSignature,
      token,
    }).then(async ({ success }) => {
      if (!success) {
        toast(INVALID_REQUEST, { type: 'error' })
        navigate.push('/')
        dispatch(logout())
        return
      }
      dispatch({
        type: AUTH_TOKEN_SET,
        token,
        user: payload,
      })
      setValidated(true)
    })
  }, [token, urlSignature])

  useEffect(() => {
    if (!validated) return
    refetch().then((user) => {
      getBrokerVerificationCategories(user.accountType as string as AccountVerificationType)
        .then((values: BrokerIdentifyCategory[]) => {
          setVerificationCategories(values.filter((value) => value.visible))
        })
        .finally(() => setLoading(false))
    })
  }, [validated])

  const userType = useMemo(() => {
    if (!userInfo || !userInfo.accountType) return ''
    return accountTypes[userInfo.accountType]
  }, [userInfo])

  const refetch = async (): Promise<UserInfo> => {
    setLoading(true)
    const { verifications, histories, user } = await getBrokerVerification()
    setDocuments(verifications)
    setHistories(histories)
    setUserInfo(user)
    setLoading(false)
    return user
  }

  const isSubmitable = useMemo(() => {
    return !!documents.filter((doc) => doc.status === VerificationStatus.Initial).length
  }, [documents])

  const historyData = useMemo(() => {
    return histories
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .map((history, index: number) => {
        let content = history.content
        if (
          [BrokerVerificationHistoryType.VerificationRequest, BrokerVerificationHistoryType.SignRequest].includes(
            history.type,
          )
        )
          content = ''
        return [
          String(index + 1),
          BrokerVerificationHistoryTypeText[history.type],
          history.email,
          formatTime(history.createdAt),
          content,
        ]
      })
  }, [histories])

  const customCategories: BrokerIdentifyCategory[] = useMemo(() => {
    return documents
      .filter(({ category, fileName }) => category != 'sign' && category != Number(category).toString() && !fileName)
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
      .map((doc) => ({
        id: doc.category,
        value: doc.category,
        visible: true,
      }))
  }, [documents])

  const getCategoryStatus = (categoryId: string) => {
    const categoryDoc = documents.find((doc) => doc.category == categoryId && !doc.fileName)
    if (!categoryDoc) return VerificationStatus.Pending
    return categoryDoc.status
  }

  const onRemoveDocument = (document: BrokerVerificationDocument) => {
    const index = documents.findIndex((doc) => doc.id === document.id)
    if (index === -1) return
    const newDocs = cloneDeep(documents)
    newDocs.splice(index, 1)
    setDocuments(newDocs)
  }

  const onUploadFilesFromInput = (event: React.ChangeEvent<HTMLInputElement>, category: string) => {
    const { files } = event.target
    if (!files || files.length == 0) return
    const arrFile: Array<File> = []
    for (let i = 0; i < files.length; i++) arrFile.push(files[i])

    onUploadFiles(arrFile, category)
  }

  const onDrop = async (doc: any, category: string) => {
    onUploadFiles(doc.files, category)
  }

  const sendMessage = async () => {
    if (message.length === 0) {
      return toast('Message is required!', { type: 'error' })
    }
    setLoading(true)
    const res = await sendBrokerVerificationMessage(profile.id, {
      message,
      type: BrokerVerificationHistoryType.MessageToVendor,
    })
    if (res.success) {
      const newHistories = cloneDeep(histories)
      newHistories.push({
        id: Date.now(),
        email: profile.email,
        content: message,
        type: BrokerVerificationHistoryType.MessageToVendor,
        createdAt: 'now',
      })
      setHistories(newHistories)
    }
    setLoading(false)
    setIsOpenMessage(false)
  }

  const onUploadFiles = async (files: any, category: string) => {
    if (!files || !files.length) return
    const fileArr: File[] = []
    const fileLen = files.length
    for (let i = 0; i < fileLen; i++) fileArr.push(files[i])
    const data = {
      path: `brokerVerify/${profile.id}`,
    }
    setLoading(true)
    uploadFiles(data, fileArr)
      .then((keys: string[]) => {
        const fileList: BrokerVerificationDocument[] = []
        for (let i = 0; i < fileLen; i++) {
          fileList.push({
            id: Date.now(),
            email: profile.email,
            status: VerificationStatus.Initial,
            category,
            fileName: fileArr[i].name,
            fileKey: keys[i],
            createdAt: 'now',
          })
        }
        const newDocs = cloneDeep(documents)
        newDocs.push(...fileList)
        setDocuments(newDocs)
      })
      .finally(() => setLoading(false))
  }

  const onSubmit = async () => {
    const uploadingDocs = documents.filter((doc) => doc.status === VerificationStatus.Initial)
    setLoading(true)
    await submitBrokerVerification(uploadingDocs)
    refetch()
  }

  const onAddComment = () => {
    setIsOpenMessage(true)
    setMessage('')
  }

  const onConfirmBrokerApproval = () => {
    setLoading(true)
    signBrokerVerificationPdfForUser()
      .then(async ({ fileKey }) => {
        const newDoc: BrokerVerificationDocument = {
          id: Date.now(),
          email: profile.email,
          status: VerificationStatus.Initial,
          category: 'sign',
          fileName: `Sign ${userType} verification approval - User.pdf`,
          fileKey: fileKey,
          createdAt: 'now',
        }
        await submitBrokerVerification([newDoc])
        await refetch()
      })
      .finally(() => {
        setLoading(false)
        setIsOpenBrokerAgreement(false)
      })
  }

  const onSignBrokerApproval = () => {
    setIsOpenBrokerAgreement(true)
    setIsConfirmBrokerAgreement(false)
    return
  }

  const onDownloadBrokerAgreementPDF = () => {
    const properDocs = documents.filter(({ category, fileName }) => category == 'sign' && fileName)
    if (properDocs.length > 0) {
      openS3Document(properDocs[0].fileKey)
    }
  }

  const renderSubDocuments = (filteredCategory: string) => {
    const isSignCategory = filteredCategory === 'sign'
    const properDocs = documents
      .filter(({ category, fileName }) => category == filteredCategory && fileName)
      .sort((a, b) => (!isSignCategory && a.id > b.id ? -1 : 1))

    return properDocs.map((document, index: number) => (
      <tr className="hover:bg-slate-100" key={index}>
        <td className="px-6 min-w-[300px]">
          <span className="flex items-center gap-2">
            <div className="w-[15px]">{index + 1}.</div>
            <div>{document.fileName}</div>
          </span>
        </td>
        <td className="px-2 w-[160px]">{document.email}</td>
        <td className="px-2 w-[138px]">{formatTime(document.createdAt)}</td>
        <td className="px-2 py-1 w-[90px]">
          {isSignCategory ? <div className="py-4"></div> : <div className="py-4"></div>}
        </td>
        <td className="px-2 w-[60px]">
          {isSignCategory && (
            <button
              className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
              onClick={() => openS3Document(document.fileKey)}
            >
              <ArrowDownTrayIcon className="w-4 h-4" />
            </button>
          )}

          {document.status === VerificationStatus.Initial && (
            <button
              className="text-red-800 p-1 hover-shadow1 cursor-pointer"
              onClick={() => onRemoveDocument(document)}
            >
              <TrashIcon className="w-4 h-4" />
            </button>
          )}
        </td>
      </tr>
    ))
  }

  const renderCategory = (category: BrokerIdentifyCategory, index: number) => {
    let bgCn = index % 2 ? 'bg-gray-50' : ''
    return (
      <React.Fragment key={category.id}>
        <tr className={bgCn}>
          <td colSpan={5} className="border-b px-2 py-2">
            <TargetBox types={[NativeTypes.FILE]} onDrop={(docs: any) => onDrop(docs, category.id)}>
              <table className="w-full">
                <tbody>
                  <tr className="">
                    <td className="px-2 py-1" colSpan={3}>
                      <span className="flex items-center font-bold">
                        <div className="mr-3 w-[15px]">{index + 1}.</div>{' '}
                        <span className="text-[15px]">{category.value}</span>
                      </span>
                    </td>
                    <td className="px-2 py-1 w-[90px]">
                      <div className="flex font-bold">{getCategoryStatus(category.id)}</div>
                    </td>
                    <td className="px-2 py-1 w-[60px]">
                      <label
                        className="text-gray-900 p-1 hover-shadow1 cursor-pointer inline-block"
                        htmlFor={`file-${category.id}`}
                      >
                        <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                      </label>

                      <input
                        className="hidden"
                        id={`file-${category.id}`}
                        type="file"
                        accept=".pdf,.jpg"
                        multiple
                        required
                        onChange={(event) => onUploadFilesFromInput(event, category.id)}
                      />
                    </td>
                  </tr>
                  {renderSubDocuments(category.id)}
                </tbody>
              </table>
            </TargetBox>
          </td>
        </tr>
      </React.Fragment>
    )
  }

  const renderSignCategory = () => {
    const index = verificationCategories.length + customCategories.length

    const requiredCategoryIds = [...verificationCategories, ...customCategories]
      .filter((category) => category.visible)
      .map((category) => category.id.toString())

    const approvedCategoryFilters = documents.filter((doc) => {
      return (
        requiredCategoryIds.includes(doc.category.toString()) &&
        !doc.fileKey &&
        !doc.fileName &&
        [VerificationStatus.Approved, VerificationStatus.Waived].indexOf(doc.status) !== -1
      )
    })
    if (approvedCategoryFilters.length !== requiredCategoryIds.length) return null

    const hadAdminSignedPdf = !!documents.filter(
      ({ category, fileName, email }) => category == 'sign' && fileName && email != profile.email,
    ).length

    const hadSelfSignedPdf = !!documents.filter(
      ({ category, fileName, email }) => category == 'sign' && fileName && email == profile.email,
    ).length

    return (
      <tr className="border-t border-gray-300 border-t-[3px]">
        <td colSpan={4} className="border-b px-2 py-2">
          <table className="w-full">
            <tbody>
              <tr className="">
                <td className="px-2 py-1" colSpan={3}>
                  <span className="flex items-center font-bold">
                    <div className="mr-3 w-[15px]">{index + 1}.</div>{' '}
                    <span className="text-[15px]">{userType} Agreement</span>
                    {hadAdminSignedPdf && !hadSelfSignedPdf && (
                      <span
                        className="flex flex-wrap items-center shadow ring ring-[3px] ring-red-300 gap-2 hover:underline text-shade-blue py-1 px-2 hover-shadow1 cursor-pointer inline-block ml-3 rounded"
                        onClick={onSignBrokerApproval}
                      >
                        <svg className="animate-bounce w-5 h-5">
                          <ArrowDownCircleIcon className="w-4 h-4"></ArrowDownCircleIcon>
                        </svg>
                        <span>Click Here to Sign Agreement</span>
                        <PencilIcon className="w-4 h-4"></PencilIcon>
                      </span>
                    )}
                  </span>
                </td>
                <td className="px-2 py-1 w-20"></td>
                <td className="px-2 py-1 w-20">
                  <div className="flex font-bold">{getCategoryStatus('sign')}</div>
                </td>
              </tr>
              {renderSubDocuments('sign')}
            </tbody>
          </table>
        </td>

        <td className="px-2 py-1 w-30 border-b"></td>
      </tr>
    )
  }

  if (isLoading && !validated) return <LayoutLoading show={isLoading} />

  return (
    <div className="BrokerVerificationForUser-container px-2 my-6">
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-6 p-4">
          <LayoutLoading show={isLoading} />

          <div className="flex flex-wrap gap-4 items-center mb-3">
            <div>
              <LogoBlue />
            </div>
            <h2 className="text-2xl font-bold flex items-center">
              <span className="text-[16px] md:text-[18px] ml-2">
                {COMPANY_NAME_FC} {userType} Approval Progress
              </span>
            </h2>
          </div>

          <div className="my-4 text-[14.5px] px-3 py-2 shadow rounded">
            <div className="flex gap-4 my-1">
              <div className="w-[65px]">Company:</div>
              <div className="">{userInfo.company}</div>
            </div>
            <div className="flex gap-4 my-1">
              <div className="w-[65px]">Name:</div>
              <div className="">{userInfo.name}</div>
            </div>
            <div className="flex gap-4 my-1">
              <div className="w-[65px]">Email:</div>
              <div className="">{userInfo.email}</div>
            </div>
            <div className="flex gap-4 my-1">
              <div className="w-[65px]">Address:</div>
              <div className="">{userInfo.address}</div>
            </div>
            <div className="flex gap-4 my-1">
              <div className="w-[65px]">Phone:</div>
              <div className="">{userInfo.phone}</div>
            </div>
          </div>

          <div
            className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4 text-[15px]"
            role="alert"
          >
            Please upload the applicable document into each category bucket.
          </div>

          <DndProvider backend={HTML5Backend}>
            <div className="relative overflow-auto mb-5 shadow-md sm:rounded-lg">
              <div className="absolute top-1 right-[calc(50%-135px)] w-full h-fit">
                <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
                  <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
                  <span>Drag and drop files.</span>
                </div>
              </div>
              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="px-3 py-3 min-w-[300px]">Category Name</th>
                    <th className="px-2 py-3 w-[160px]">Uploaded By</th>
                    <th className="px-2 py-3 w-[138px]">Uploaded At</th>
                    <th className="px-2 py-3 w-[90px]">Status</th>
                    <th className="px-2 py-3 w-[60px]">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {verificationCategories.map((category: BrokerIdentifyCategory, index: number) =>
                    renderCategory(category, index),
                  )}
                  {customCategories.map((category: BrokerIdentifyCategory, index: number) =>
                    renderCategory(category, index + verificationCategories.length),
                  )}
                  {renderSignCategory()}
                </tbody>
              </table>
            </div>
          </DndProvider>
          <div className="w-full flex flex-col items-center">
            <Button disabled={!isSubmitable} onClick={onSubmit}>
              <span className="flex gap-2 items-center">
                Submit Review Request
                <ArrowsUpDownIcon className="w-4 h-4"></ArrowsUpDownIcon>
              </span>
            </Button>
          </div>
        </div>

        <div className="relative bg-white shadow1 rounded mb-6 p-4">
          <div className="w-full flex justify-between">
            <h2 className="text-2xl font-bold flex items-center mb-3">
              <span className="text-[16px] ml-2">Approval Histories</span>
            </h2>
            <Button onClick={onAddComment} color="gray" className="px-[15px] py-[5px]" size={'[14px]'}>
              <span className="flex gap-2 items-center">
                Send Message to Vendor Approval
                <PencilIcon className="w-4 h-4"></PencilIcon>
              </span>
            </Button>
          </div>
          <div className="relative overflow-auto -mb-4 sm:rounded-lg">
            <PlainTable
              header={['No', 'Action', 'Email', 'Created At', 'Content']}
              data={historyData}
              tdClass={'px-4 py-2'}
            />
          </div>
        </div>
      </div>
      <Modal
        title={`Confirm and Sign ${userType} Agreement`}
        titleOkay="Confirm and Sign"
        titleCancel="Close"
        isOpen={isOpenBrokerAgreement}
        lastUpdatedAt={Date.now()}
        onClose={() => setIsOpenBrokerAgreement(false)}
        onOk={onConfirmBrokerApproval}
        loading={isLoading}
        disabled={!isConfirmBrokerAgreement}
      >
        <div className="w-[450px]">
          <Button link onClick={onDownloadBrokerAgreementPDF} className="-mt-3 mb-3">
            <div className="flex items-center text-gray-900 hover:text-shade-blue">
              <span className="underline">View Unsigned {userType} Agreement PDF </span>
              <span className="ml-2">
                <ArrowDownTrayIcon className="w-4 h-4" />
              </span>
            </div>
          </Button>
          <Toggle
            id="reviewed"
            title={`I have reviewed the ${userType} agreement`}
            onChange={(value) => setIsConfirmBrokerAgreement(value)}
            value={isConfirmBrokerAgreement}
          />
        </div>
      </Modal>
      <Modal
        title={`Send Message to Vendor`}
        titleOkay="Send"
        titleCancel="Close"
        isOpen={isOpenMessage}
        lastUpdatedAt={Date.now()}
        onClose={() => setIsOpenMessage(false)}
        onOk={sendMessage}
        loading={isLoading}
      >
        <div className="w-[360px]">
          <TextArea title={'Message'} key={'message'} value={message} onChange={(value) => setMessage(value)} />
        </div>
      </Modal>
      <Footer />
    </div>
  )
}
