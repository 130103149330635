import { setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { store } from 'reducers'
import { InputValidate } from 'utils'

import { defaultInputs } from './constants'

const logicFields1 = [
  'informationProvidedVia',
  'sex',
  'sexCompletedBy',
  'ethnicity',
  'ethnicityCompletedBy',
  'race',
  'raceCompletedBy',
]

const logicFields2 = [
  'isHadOwnershipInterest',
  'isPurchaseTransaction',
  'isBorrowingMoney',
  'isMortageLoan',
  'isNewCredit',
  'isSubjectLien',
  'isCoSigner',
  'isPreForeclosure',
  'bankruptcyType',
]

export const declarationsHmdaValidate = (
  borrowerSeperator: string,
  validateOnly = false,
  updateStore = false,
  loanNumber: number = -1,
) => {
  const { inputs: fields } = defaultInputs()
  const { borrower, step, loanDetail } = store.getState()
  const data = borrower[borrowerSeperator]
  if (loanNumber === -1) loanNumber = loanDetail.loanNumber

  const getValue = (key: string) => {
    if (borrower[borrowerSeperator][key] !== undefined) return borrower[borrowerSeperator][key]
    else return ''
  }

  const isPrimaryResidence = getValue('isPrimaryResidence')
  const isHadOwnershipInterest = getValue('isHadOwnershipInterest')
  fields.isHadOwnershipInterest.required = isPrimaryResidence
  fields.isHadOwnershipInterest.disabled = !isPrimaryResidence
  ;['propertyOwn', 'holdTitle'].forEach((key) => {
    fields[key].required = isPrimaryResidence || isHadOwnershipInterest
    fields[key].disabled = !isPrimaryResidence || !isHadOwnershipInterest
  })

  const isBorrowingMoney = getValue('isBorrowingMoney')
  fields.borrowingMoneyAmount.required = isBorrowingMoney
  fields.borrowingMoneyAmount.disabled = !isBorrowingMoney

  const isBankrupt = getValue('isBankrupt')
  fields.bankruptcyType.required = isBankrupt
  fields.bankruptcyType.disabled = !isBankrupt

  const required = data.isBorrower === 'Yes'
  logicFields1.forEach((key: string) => (fields[key].required = required))

  const disabled = data.isBorrower === 'No'
  logicFields1.forEach((key: string) => (fields[key].disabled = disabled))

  let success = true

  let isPrevLoan = true
  if (loanNumber >= 1431 && loanNumber < 3000000) isPrevLoan = false
  if (isPrevLoan) {
    logicFields2.map((key) => {
      fields[key].required = false
    })
  }

  Object.keys(fields).map((key) => {
    const error = InputValidate({ ...fields[key], value: borrower[borrowerSeperator][key] })
    fields[key].error = error
    if (error.length) success = false
  })

  if (updateStore) {
    let application = cloneDeep(step.application)
    const key = borrowerSeperator === 'borrower' ? 'hmda' : 'hmda2'
    application[key] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }

  if (validateOnly) return success

  return fields
}
