export interface ISurveyQuestion {
  title: string
  type: SurveyType
  disabled: boolean
}

export interface ISurveyAnswer {
  title: string
  type: SurveyType
  value: string | number
}

export type ISurveyTemplates = ISurveyQuestion[]

export enum SurveyType {
  Rating = 0,
  Text = 1,
}
