import { daysOfWeek, InputType } from 'config'

const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]
const minutes = ['00', '15', '30', '45']

export const defaultInputs = (): Record<string, InputType> => {
  return {
    startHour: {
      inputType: 'select',
      title: 'Start Hour',
      span: 1,
      options: hours,
      required: true,
    },
    startMinute: {
      inputType: 'select',
      title: 'Start Minute',
      span: 1,
      options: minutes,
      required: true,
    },
    endHour: {
      inputType: 'select',
      title: 'End Hour',
      span: 1,
      options: hours,
      required: true,
    },
    endMinute: {
      inputType: 'select',
      title: 'End Minute',
      span: 1,
      options: minutes,
      required: true,
    },
    startDay: {
      inputType: 'select',
      title: 'Start Day',
      span: 2,
      options: daysOfWeek,
      required: true,
    },
    endDay: {
      inputType: 'select',
      title: 'End Day',
      span: 2,
      options: daysOfWeek,
      required: true,
    },
    isHoliday: {
      inputType: 'toggle',
      title: 'Holiday',
      span: 2,
      value: false,
      required: true,
    },
  }
}
