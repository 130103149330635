export const HUD1InputType: Record<string, number> = {
  onlyTitle: 0,
  titleInputValue: 1,
  titleCalculatedValue: 2,
  inputTitleInputValue: 3,
  inputFromToValue: 4,
  title3InputsCheckbox: 5,
  inputParty: 6,
  titlePartyInputs: 7,
  titleCopyInput: 8,
  normalInput: 9,
  calculatedInput: 10,
  titleInputCalculatedValueInput: 11,
  checkBox: 12,
  radioGroup: 13,
  selectBox: 14,
}
