import {
  API_ADMIN_ACCOUNTING,
  API_ADMIN_ACCOUNTING_ACCOUNT,
  API_ADMIN_ACCOUNTING_ACCOUNT_BY_ID,
  API_ADMIN_ACCOUNTING_BY_FILE_ID,
  API_ADMIN_ACCOUNTING_BY_ID,
  API_ADMIN_ACCOUNTING_CATEGORY,
  API_ADMIN_ACCOUNTING_CATEGORY_BY_ID,
  API_ADMIN_ACCOUNTING_FILE,
  API_ADMIN_ACCOUNTING_FILE_BY_ID,
  API_ADMIN_ACCOUNTING_HISTORY_BY_ID,
  API_ADMIN_ACCOUNTING_RELATIONS_BY_ID,
} from 'config'
import Api from 'services/api'

/**
 * Accounting Categories
 */
export const getBaseAccountingCategories = (type: string = '') => {
  return Api.get(API_ADMIN_ACCOUNTING_CATEGORY_BY_ID, { type }, { id: 'base' })
}

export const getAccountingCategories = (type: string = '') => {
  return Api.get(API_ADMIN_ACCOUNTING_CATEGORY, { type })
}

export const createAccountingCategory = (data: { baseId: number; parentId: number; value: string }) => {
  return Api.post(API_ADMIN_ACCOUNTING_CATEGORY, data)
}

export const updateAccountingCategory = (id: number, value: string) => {
  return Api.put(API_ADMIN_ACCOUNTING_CATEGORY_BY_ID, { value }, { id })
}

export const deleteAccountingCategory = (id: number) => {
  return Api.delete(API_ADMIN_ACCOUNTING_CATEGORY_BY_ID, {}, { id })
}

/**
 * Accounting Record
 */
export const getAccounting = (filters: Record<string, any>) => {
  return Api.get(API_ADMIN_ACCOUNTING, filters)
}

export const getAccountingById = (id: number) => {
  return Api.get(API_ADMIN_ACCOUNTING_BY_ID, {}, { id })
}

export const getAccountingHistory = (id: number) => {
  return Api.get(API_ADMIN_ACCOUNTING_HISTORY_BY_ID, {}, { id })
}

export const getAccountingRelations = (id: number) => {
  return Api.get(API_ADMIN_ACCOUNTING_RELATIONS_BY_ID, {}, { id })
}

export const parseAccountingFile = (type: string, file: File): Promise<{ data: string[][]; file: any }> => {
  return Api.uploadFiles(API_ADMIN_ACCOUNTING_BY_ID, {}, { id: `file/${type}` }, [file])
}

export const analyzeAccounting = (data: Record<string, any>[]): Promise<number[]> => {
  return Api.post(API_ADMIN_ACCOUNTING_BY_ID, data, { id: 'analyze' }, { timeout: 600000 })
}

export const submitAccounting = (file: Record<string, any> | null, data: Record<string, any>[]) => {
  return Api.post(API_ADMIN_ACCOUNTING, { file, data })
}

export const updateAccounting = (data: Record<string, any>[]) => {
  return Api.put(API_ADMIN_ACCOUNTING, data)
}

export const updateAccountingById = (id: number, data: Record<string, any>) => {
  return Api.put(API_ADMIN_ACCOUNTING_BY_ID, data, { id })
}

export const deleteAccounting = (id: number) => {
  return Api.delete(API_ADMIN_ACCOUNTING_BY_ID, {}, { id })
}

/**
 * Accounting Accounts
 */
export const getAccountingAccount = () => {
  return Api.get(API_ADMIN_ACCOUNTING_ACCOUNT)
}

export const createAccountingAccount = (data: Record<string, any>) => {
  return Api.post(API_ADMIN_ACCOUNTING_ACCOUNT, data)
}

export const updateAccountingAccount = (id: number, data: Record<string, any>) => {
  return Api.put(API_ADMIN_ACCOUNTING_ACCOUNT_BY_ID, data, { id })
}

export const deleteAccountingAccount = (id: number) => {
  return Api.delete(API_ADMIN_ACCOUNTING_ACCOUNT_BY_ID, {}, { id })
}

/**
 * Accounting Files
 */
export const getAccountingFiles = (type: string) => {
  return Api.get(API_ADMIN_ACCOUNTING_FILE, { type })
}

export const getAccountingFilePropById = (id: number) => {
  return Api.get(API_ADMIN_ACCOUNTING_FILE_BY_ID, {}, { id })
}

export const deleteAccountingFile = (id: number) => {
  return Api.delete(API_ADMIN_ACCOUNTING_FILE_BY_ID, {}, { id })
}

export const getAccountingByFileId = (fileId: number) => {
  return Api.get(API_ADMIN_ACCOUNTING_BY_FILE_ID, {}, { fileId })
}
