import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import type { IDscrInterestOnlyLeverageLimit, IInterestOnlyLimit } from 'pages/Resources'
import { getPrice3decimal, isEmpty } from 'utils'

export const LoanPurposeMapping: Record<string, string> = {
  purchase: 'Purchase',
  nocashout: 'No-Cashout',
  cashout: 'Cashout',
}

export const DscrInterestOnlyLeverageLimits = ({ data }: { data: IDscrInterestOnlyLeverageLimit | undefined }) => {
  return (
    <div className="h-full">
      {data ? (
        <div>
          {Object.keys(data)
            .sort((a, b) => {
              if (a < b) return 1
              if (a > b) return -1
              else return 0
            })
            .map((key, index) => {
              const limit: IInterestOnlyLimit[] = (data as any)[key]

              return (
                <div key={index} className={`${index !== 0 ? 'mt-3' : ''}`}>
                  <table className="text-sm w-full rounded-lg shadow text-center">
                    <thead className="bg-gray-100">
                      <tr>
                        <th colSpan={8} className="border p-2">
                          {LoanPurposeMapping[key]}
                        </th>
                      </tr>
                      <tr>
                        <th className="border p-2 whitespace-nowrap w-1/6">FICO</th>
                        <th className="border p-2 whitespace-nowrap w-1/6">Loan Amount</th>
                        <th className="border p-2 whitespace-nowrap w-1/6">Min DSCR</th>
                        <th className="border p-2 whitespace-nowrap w-1/6">Min Months Reserve</th>
                        <th className="border p-2 whitespace-nowrap w-1/6">Min LTV</th>
                        <th className="border p-2 whitespace-nowrap w-1/6">Max LTV</th>
                        <th className="border p-2 whitespace-nowrap w-1/6">Max CLTV</th>
                      </tr>
                    </thead>

                    <tbody>
                      {limit.map((item, idx) => {
                        return (
                          <tr key={`${index}-${idx}`} className={`${idx % 2 == 1 ? 'bg-gray-50' : ''}`}>
                            <td className="px-3 py-2 border">
                              {getPrice3decimal(item.fico.from)}
                              {isEmpty(item.fico.to) ? ' +' : ' - ' + getPrice3decimal(item.fico.to)}
                            </td>
                            <td className="px-3 py-2 border">
                              {getPrice3decimal(item.minLoanAmount)} - {getPrice3decimal(item.maxLoanAmount)}
                            </td>
                            <td className="px-3 py-2 border">{item.minDscr}</td>
                            <td className="px-3 py-2 border">{item.minMonthsReserve}</td>
                            <td className="px-3 py-2 border">{item.minLTV}</td>
                            <td className="px-3 py-2 border">{item.maxLTV}</td>
                            <td className="px-3 py-2 border">{item.maxCLTV}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )
            })}
        </div>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )}
    </div>
  )
}
