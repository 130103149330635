import { CategoryDocument, Modal } from 'stories/components'

export const ContractorDocumentHistoryDialog = (props: { data: CategoryDocument; onClose: Function }) => {
  const { history } = props.data

  return (
    <Modal isOpen title="Contractor Document History" titleOkay="" onClose={() => props.onClose()}>
      <div className={`text-gray-600 mb-4 text-md overflow-hidden`}>
        <p className="mb-4">Document Name: {props.data.name}</p>

        <table className="text-left text-sm w-full mb-4">
          <thead>
            <tr className="border-b cursor-pointer">
              <th className="py-2 px-2">No</th>
              <th className="py-2 px-2">Status</th>
              <th className="py-2 px-2">Created By</th>
              <th className="py-2 px-2">Created At</th>
            </tr>
          </thead>
          <tbody>
            {!!history &&
              history.map(({ status, createdAt, createdBy }, index: number) => {
                let className = index % 2 ? 'bg-gray-50' : ''
                return (
                  <tr key={`tr-${index}`} className={`border-b ${className}`}>
                    <td className="py-2 px-2">{index + 1}</td>
                    <td className="py-2 px-2">{status}</td>
                    <td className="py-2 px-2">{createdBy}</td>
                    <td className="py-2 px-2">{createdAt}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
