import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAlternativeNames, updateAlternativeNames } from 'services'
import { Modal, PlainTable } from 'stories/components'
import { borrowerFullName, confirm, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { BorrowerSeperator } from '../CreditScore/constants'
import type { IAlternativeName } from './types'

const typeOptions: Record<string, string> = {
  both: 'Credit Alias and AKA',
  credit: 'Credit Alias Only',
  aka: 'AKA Only',
}

const defaultInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      inputType: 'text',
      title: 'First Name',
      value: '',
      required: true,
    },
    middleName: {
      inputType: 'text',
      title: 'Middle Name',
      value: '',
      required: false,
    },
    lastName: {
      inputType: 'text',
      title: 'Last Name',
      value: '',
      required: true,
    },
    suffix: {
      inputType: 'text',
      title: 'Suffix',
      value: '',
      required: true,
    },
    creditorName: {
      inputType: 'text',
      title: 'Creditor Name',
      value: '',
      required: true,
    },
    accountNo: {
      inputType: 'text',
      title: 'Account No',
      required: true,
    },
    type: {
      inputType: 'select',
      title: 'Type',
      options: typeOptions,
      required: true,
      hasDefaultOption: true,
    },
  }
}

const AlternativeNamesForm = (props: { data: IAlternativeName | null; onSubmit: Function }) => {
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    if (!props.data) return

    const newInputs = cloneDeep(inputs)
    Object.keys(inputs).forEach((key) => {
      newInputs[key].value = (props.data as any)[key]
    })
    setInputs(newInputs)
  }, [props.data])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    props.onSubmit({
      ...props.data,
      ...data,
    })
  }

  return (
    <Modal
      title={`${props.data ? 'Update' : 'Add'} Alternative Name`}
      titleOkay={'Submit'}
      onClose={() => props.onSubmit(null)}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-144">
        <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]

            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export const AlternativeNamesModal = (props: { borrowerSeperator: BorrowerSeperator; onClose: Function }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IAlternativeName[]>([])
  const [renderType, setRenderType] = useState(0)
  const [formId, setFormId] = useState<Number>()

  const { hasPermission } = usePermissions()
  const canEdit = hasPermission('MANAGE_ALTERNATIVE_NAMES')

  const { borrower } = useSelector((state: any) => ({
    borrower: state.borrower,
  }))

  useEffect(() => {
    fetch()
  }, [])

  const getCondition = () => {
    const { firstName, middleName, lastName } = borrower[props.borrowerSeperator]
    return { firstName, middleName, lastName }
  }

  const fetch = async () => {
    setLoading(true)

    const data = await getAlternativeNames(getCondition())
    setData(data)
    setLoading(false)
  }

  const onClose = () => {
    props.onClose()
  }

  const onOk = () => {
    if (renderType == 0) {
      setRenderType(1)
      setFormId(0)
      return
    }
  }

  const onSubmitForm = (newItem: IAlternativeName | null) => {
    setRenderType(0)
    if (newItem == null) {
      setFormId(undefined)
      return
    }

    const newData = cloneDeep(data)
    const index = formId ? data.findIndex((v) => v.id == formId) : -1
    if (index == -1)
      newData.push({
        ...newItem,
        id: Date.now(),
      })
    else newData[index] = newItem
    update(newData)
  }

  const onDelete = async (item: IAlternativeName, index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure to remove this {borrowerFullName(item)}?
        <br />
        <span className="text-gray-600">No: {index + 1}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(data)
    newData.splice(index, 1)
    update(newData)
  }

  const update = async (data: IAlternativeName[]) => {
    setData(data)

    setLoading(true)
    await updateAlternativeNames(getCondition(), data)
    setLoading(false)
  }

  const renderTable = () => {
    const name = getCondition()
    return (
      <Modal
        title={`Alternative Names (${borrowerFullName(name)})`}
        titleOkay={canEdit ? 'Add' : ''}
        loading={loading}
        onClose={onClose}
        onOk={onOk}
        isOpen
        titleCancel="Close"
      >
        <div className="w-144">
          <PlainTable
            header={['No', 'Type', 'Alternative Name', canEdit ? 'Actions' : ''].filter((v) => !!v)}
            data={data.map((item, no) =>
              [
                no + 1,
                typeOptions[item.type],
                borrowerFullName(item),
                canEdit ? (
                  <div className="flex gap-1">
                    <span
                      className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                      onClick={() => {
                        setFormId(item.id)
                        setRenderType(1)
                      }}
                    >
                      <PencilSquareIcon className="w-4 h-4" />
                    </span>
                    <span
                      className="text-red-700 cursor-pointer hover-shadow1 p-1 rounded"
                      onClick={() => onDelete(item, no)}
                    >
                      <TrashIcon className="w-4 h-4" />
                    </span>
                  </div>
                ) : (
                  ''
                ),
              ].filter((v) => !!v),
            )}
          />
        </div>
      </Modal>
    )
  }

  const renderForm = () => {
    const index = formId ? data.findIndex((v) => v.id == formId) : -1
    if (index == -1) return <AlternativeNamesForm data={null} onSubmit={onSubmitForm} />

    return <AlternativeNamesForm data={data[index]} onSubmit={onSubmitForm} />
  }

  return renderType == 0 ? renderTable() : renderForm()
}
