import type { InputType } from 'config'
import type { ParagraphComponent } from 'utils/RenderParagraph'

export const deedOfTrustParagraphs = (inputs: Record<string, InputType>): ParagraphComponent[][] => {
  return [
    [
      {
        input: { inputType: 'span', title: 'Recording Requested by:' },
        className: 'mb-4',
      },
      {
        input: inputs.recordingRequestedBy,
        Key: 'recordingRequestedBy',
      },
      {
        input: { inputType: 'span', title: 'When Recorded Return to:' },
        className: 'mt-5 mb-4',
      },
      {
        input: inputs.whenRecordedReturnTo,
        Key: 'whenRecordedReturnTo',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'Loan Number:' },
      },
      {
        input: inputs.loanNumber,
        Key: 'loanNumber',
        className: 'col-span-2 flex items-end w-[500px]',
      },
      {
        input: { inputType: 'span', title: 'County Parcel Number:' },
      },
      {
        input: inputs.companyParcelNumber,
        Key: 'companyParcelNumber',
        className: 'col-span-2 flex items-end w-[500px]',
      },
      {
        input: { inputType: 'span', title: 'Property Address:' },
      },
      {
        input: inputs.propertyAddress,
        Key: 'propertyAddress',
        className: 'col-span-2 flex items-end w-[500px]',
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'This ASSIGNMENT OF DEED OF TRUST (“Assignment”) is made by' },
        needSplit: true,
      },
      {
        input: inputs.assignorEntityName4,
        Key: 'assignorEntityName4',
      },
      {
        input: { inputType: 'span', title: ', a ' },
        needSplit: true,
      },
      {
        input: inputs.assignorState,
        Key: 'assignorState',
      },
      {
        input: inputs.assignorEntityType,
        Key: 'assignorEntityType',
      },
      {
        input: { inputType: 'span', title: ' who has an address of ' },

        needSplit: true,
      },
      {
        input: inputs.assignorAddress,
        Key: 'assignorAddress',
        className: 'w-[350px]',
      },
      {
        input: { inputType: 'span', title: ' (“Assignor”), to ' },

        needSplit: true,
      },
      {
        input: inputs.assigneeName,
        Key: 'assigneeName',
      },
      {
        input: { inputType: 'span', title: ' , a ' },

        needSplit: true,
      },
      {
        input: inputs.assigneeState,
        Key: 'assigneeState',
      },
      {
        input: inputs.assigneeEntityType,
        Key: 'assigneeEntityType',
      },
      {
        input: { inputType: 'span', title: ' who has an address of ' },
        needSplit: true,
      },
      {
        input: inputs.assigneeAddress,
        Key: 'assigneeAddress',
        className: 'w-[350px]',
      },
      {
        input: { inputType: 'span', title: ' (“Assignee”).' },
        needSplit: true,
      },
    ],
    [
      {
        input: {
          inputType: 'span',
          title:
            'FOR VALUE RECEIVED, Assignor hereby sells, grants, assigns, transfer and convey unto the above named Assignee any and all of its right, title and interest in and under that certain',
        },
        needSplit: true,
      },
      {
        input: inputs.deedOfTrust,
        Key: 'deedOfTrust',
      },
      {
        input: { inputType: 'span', title: ', dated ' },
        needSplit: true,
      },
      {
        input: inputs.loanDate,
        Key: 'loanDate',
      },
      {
        input: { inputType: 'span', title: ' in the amount of $' },
        needSplit: true,
      },
      {
        input: inputs.loanAmount,
        Key: 'loanAmount',
      },
      {
        input: { inputType: 'span', title: ' executed by ' },
        needSplit: true,
      },
      {
        input: inputs.borrowers,
        Key: 'borrowers',
      },
      {
        input: inputs.trustorName,
        Key: 'trustorName',
      },
      {
        input: { inputType: 'span', title: '(trustor) to ' },
        needSplit: true,
      },
      {
        input: inputs.trusteeCompanyName,
        Key: 'trusteeCompanyName',
      },
      {
        input: inputs.trusteeName,
        Key: 'trusteeName',
      },
      {
        input: { inputType: 'span', title: '(beneficiary) and recorded on ' },
        needSplit: true,
      },
      {
        input: inputs.recordedOn,
        Key: 'recordedOn',
      },
      {
        input: { inputType: 'span', title: ' in the Official Records of ' },
        needSplit: true,
      },
      {
        input: inputs.county,
        Key: 'county',
      },
      {
        input: { inputType: 'span', title: ' County, ' },
        needSplit: true,
      },
      {
        input: inputs.state,
        Key: 'state',
      },
      {
        input: { inputType: 'span', title: ' in Instrument: ' },
        needSplit: true,
      },
      {
        input: inputs.securityInstrument,
        Key: 'securityInstrument',
      },
      {
        input: {
          inputType: 'span',
          title:
            ', and as a lien on that certain real property described on Exhibit A, attached here to and made a part hereof. For purposes of clarification, Assignee hereby owns 100% interest in and to that certain Security Instrument.',
        },
        needSplit: true,
      },
    ],
    [
      {
        input: { inputType: 'span', title: 'Assignor:' },
      },
      {
        input: inputs.assignorEntityName5,
        Key: 'assignorEntityName5',
        className: 'col-span-2 items-end',
      },
      {
        input: { inputType: 'span', title: 'Dated:' },
      },
      {
        input: inputs.dated,
        Key: 'dated',
        className: 'col-span-2 flex items-end w-full',
      },
      {
        input: { inputType: 'span', title: 'By:' },
      },
      {
        input: { inputType: 'span', title: '' },
        Key: 'by',
        className: 'col-span-2 border-b border-black w-full',
      },
      {
        input: { inputType: 'span', title: 'Signors Name:' },
      },
      {
        input: inputs.signorsName,
        Key: 'signorsName',
        className: 'col-span-2 items-end w-full',
      },
      {
        input: { inputType: 'span', title: 'Signors Title:' },
      },
      {
        input: inputs.signorsTitle,
        Key: 'signorsTitle',
        className: 'col-span-2 items-end w-full',
      },
    ],
  ]
}
