import cloneDeep from 'clone-deep'
import { useState } from 'react'
import { Modal } from 'stories/components'

export const ResourceOrderDialog = ({
  documents: _documents,
  onClose = () => {},
  onSubmit = () => {},
}: {
  documents: Record<string, any>[]
  onClose: Function
  onSubmit: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState<Record<string, any>[]>(_documents)

  const onChangeOrder = (val: string, index: number) => {
    const nth = Number(val) - 1
    if (nth === index) return

    const newValues = cloneDeep(documents)
    const removedOne = newValues.splice(index, 1)
    newValues.splice(nth, 0, removedOne[0])
    setDocuments(newValues)
  }

  const _onClose = () => {
    onClose()
  }

  const _onSubmit = async () => {
    setLoading(true)
    const orders = documents.map((doc, index) => ({
      id: doc.id,
      orderId: index,
    }))
    await onSubmit(orders)
    onClose()
  }

  return (
    <Modal title="Reorder documents" titleOkay="Save" loading={loading} onClose={_onClose} onOk={_onSubmit} isOpen>
      <div className="w-144 grid gap-4">
        <div className="relative overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3 w-[20px]">
                  No
                </th>
                <th scope="col" className="px-3 py-3">
                  Name
                </th>
                <th scope="col" className="px-3 py-3 w-[50px]">
                  Order
                </th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc, index) => {
                const orderOptions = documents.map((_, idx) => (idx + 1).toString())
                return (
                  <tr
                    className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
                      index % 2 === 1 && 'bg-slate-50'
                    }`}
                    key={index}
                  >
                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {index + 1}
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {doc.name}
                    </td>

                    <td>
                      <select
                        onChange={(e) => onChangeOrder(e.target.value, index)}
                        value={index + 1}
                        className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                      >
                        {orderOptions.map((val) => (
                          <option key={val}>{val}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
