import cloneDeep from 'clone-deep'
import { Fragment } from 'react'
import { getPrice3decimal } from 'utils'

import { ITier, msaPropertyTypes } from '../../interface'
import { convertNullValue, NoUnitPropertyTypes } from '../../logic'

export const DscrMsaTiers = ({ data, title }: { data: ITier[]; title: string }) => {
  return (
    <div>
      <div className="text-base font-semibold mb-2">{title}</div>

      {data && (
        <table className="table text-center text-sm w-full">
          <thead className="font-bold bg-gray-100">
            <tr>
              <th className="px-3 py-3 border">No</th>
              <th className="px-3 py-3 border">Tier Name</th>
              <th className="px-3 py-3 border">Count</th>
              <th className="px-3 py-3 border">Property Type</th>
              <th className="px-3 py-3 border">Number of Units</th>
              <th className="px-3 py-3 border">As Is Appraised Value</th>
              <th className="px-3 py-3 border">LTV Adjustment</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => {
              if (!!item?.propertyTypeLtvAdj?.length) {
                const firstCondition = item.propertyTypeLtvAdj[0]
                const restCondition = cloneDeep(item.propertyTypeLtvAdj)
                restCondition.shift()

                const rowSpan = item.propertyTypeLtvAdj.length === 0 ? 1 : item.propertyTypeLtvAdj.length

                return (
                  <Fragment key={index}>
                    <tr>
                      <td rowSpan={rowSpan} className="px-3 py-2 border">
                        {index + 1}
                      </td>
                      <td rowSpan={rowSpan} className="px-3 py-2 border">
                        {item.title}
                      </td>
                      <td rowSpan={rowSpan} className="px-3 py-2 border">
                        {item.count}
                      </td>
                      <td className="px-3 py-2 border">
                        {firstCondition ? msaPropertyTypes[firstCondition.propertyType] : ''}
                      </td>
                      <td className="px-3 py-2 border">
                        {!NoUnitPropertyTypes.includes(firstCondition.propertyType) &&
                        firstCondition &&
                        firstCondition?.unitsRange
                          ? firstCondition.unitsRange.from + ' - ' + firstCondition.unitsRange.to
                          : 'N/A'}
                      </td>
                      <td className="px-3 py-2 border">
                        {firstCondition
                          ? '$' +
                            getPrice3decimal(firstCondition.aivValue.from) +
                            ' - $' +
                            getPrice3decimal(firstCondition.aivValue.to)
                          : ''}
                      </td>
                      <td className="px-3 py-2 border">
                        {firstCondition ? convertNullValue(firstCondition.ltvAdjustment.aivLtv) : ''}
                      </td>
                    </tr>

                    {restCondition.length > 0 &&
                      restCondition.map((data, id) => (
                        <tr key={`${index}-${id}`}>
                          <td className="px-3 py-2 border">{msaPropertyTypes[data.propertyType]}</td>
                          <td className="px-3 py-2 border">
                            {!NoUnitPropertyTypes.includes(data.propertyType) && data?.unitsRange
                              ? data.unitsRange.from + ' - ' + data.unitsRange.to
                              : 'N/A'}
                          </td>
                          <td className="px-3 py-2 border">
                            ${getPrice3decimal(data.aivValue.from)} - ${getPrice3decimal(data.aivValue.to)}
                          </td>
                          <td className="px-3 py-2 border">{convertNullValue(data.ltvAdjustment.aivLtv)}</td>
                        </tr>
                      ))}
                  </Fragment>
                )
              }
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}
