import { companyName } from 'config'
import { TemporaryLayout } from 'layouts'
import { useTitle } from 'utils'

import { LoanPipelines } from './LoanPipelines'
import { PipelineStatus } from './PipelineStatus'

export const BorrowerPipeline = () => {
  useTitle(`Pipeline - ${companyName}`)

  return (
    <TemporaryLayout noOverview={true} requireSign>
      <div className="max-w-screen-2xl m-auto relative py-6 px-2 bg-white">
        <div className="Pipeline-container">
          <PipelineStatus />

          <LoanPipelines />
        </div>
      </div>
    </TemporaryLayout>
  )
}
