import { ClockIcon } from '@heroicons/react/24/outline'
import { setHeader } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { AccountType, COMPANY_DNS, COMPANY_TITLE } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import moment from 'moment-timezone'
import { loanOverviewAndStepLogic } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { LoanDetailsSlide } from 'pages/LoanSubmission/loanDetails'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  appraisalV2CreateLog,
  cancelAppraisalInfoV2,
  changeAppraisal2Status,
  createAppraisalInfoV2,
  deleteAppraisalInfoV2,
  // deleteAppraisalInfoV2,
  getAppraisalInformationFeedV2,
  getBorrowerEliteCredential,
  sendOrderAppraisalFeasibilitySignRequest,
  updateAppraisalInfoV2,
} from 'services'
import Api from 'services/api'
import { svgLoading } from 'stories/assets'
import { Button, FormTable, Select, TextArea } from 'stories/components'
import { CategoryDocument, CategoryDocuments } from 'stories/components/CategoryFileTable/CategoryFileTable'
import { confirm, formatDate, formatTime, InputValidate, openAuditLog, removeComma, validateEmail } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AppraisalNation } from './AppraisalNation'
import { defaultInputs } from './constants'
import { DocumentsSlideNameType } from './documents'
import { EliteValuation } from './EliteValuation'
import { handleFailedDocsNotification } from './handleFailedDocs'
import { Trinity } from './Trinity'
import {
  Appraisal2StatusType,
  Appraisal2ThridPartyType,
  AppraisalDocumentCategories,
  brokerCanNotEditFields,
  budgetReviewAmounts,
  budgetReviewOptions,
  cardFields,
  contactPersonFields,
  feasibilityACHKeys,
  feasibilityCCKeys,
  feasibilityKeys,
  feasibilityOrderTypes,
  feasibilityRequiredKeys,
  feasibilityStudyAmounts,
  feasibilityStudyOptions,
  header,
  mapLoanToAppraisal,
  mapOrderTypeFormOptions,
  paymentInformationFormKeys,
} from './types'

export enum IntegrationType {
  EliteValuation = 'eliteValuation',
  AppraisalNation = 'appraisalNation',
  NationWide = 'nationWide',
}

export const AddAppraisalInformationV2 = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<any>>([])
  const [logs, setLogs] = useState<Array<any>>([])
  const [documents, setDocuments] = useState<Record<string, any>[]>([])
  const [appraisalNation, setAppraisalNation] = useState<any>(null)
  const [nationWide, setNationWide] = useState<any>(null)
  const [eliteValuation, setEliteValuation] = useState<any>(null)
  const [trinity, setTrinity] = useState<any>(null)
  const [feed, setFeed] = useState<any>({})
  const [inputs, setInputs] = useState<any>({})
  const [editingLog, setEditingLog] = useState('')
  const [action, setAction] = useState('')
  const [editOrderID, setEditOrderID] = useState('All')
  const [orderIDs, setOrderIDs] = useState<Array<any>>([])
  const [cancelEditFlag, setCancelEditFlag] = useState(0)
  const [updateChangeMade, setUpdateChangeMade] = useState(false)

  const [eliteCredential, setEliteCredential] = useState([])
  const [appraisalCredential, setAppraisalCredential] = useState([])
  const [nationWideCredential, setNationWideCredential] = useState([])
  const documentsRef = useRef()
  const formRef = useRef()
  const dispatch = useDispatch()

  const fullPermissionProfiles = [
    AccountType.ADMIN,
    AccountType.OPERATION_SUPERVISOR,
    AccountType.POST_CLOSER,
    AccountType.SERVICING,
    AccountType.LOCK_DESK,
    AccountType.DRAW_SPECIALIST,
    AccountType.FEASIBILITY_ANALYST,
  ]

  const [upcomingStatus, setUpcomingStatus] = useState<null | Record<string, any>>(null)

  const { hasPermission } = usePermissions()

  const adminGroup = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

  const profile = useSelector((state: any) => state.auth.profile)

  useEffect(() => {
    if (!upcomingStatus) return
    changeStatus(upcomingStatus.id, upcomingStatus.value, upcomingStatus.additional)
    setUpcomingStatus(null)
  }, [upcomingStatus])

  const onSendSignLink = async (id: number, feasibilityData: Record<string, any>) => {
    if (removeComma(feasibilityData.feasibilityCCStudyFee) <= 0) {
      return toast('Payment Amount is Required!', { type: 'error' })
    }

    if (mapOrderTypeFormOptions[feasibilityData.orderType]?.indexOf(feasibilityData.appraisalForm) === -1) {
      return toast(`'Appraisal Form' is Required!`, { type: 'error' })
    }
    if (!validateEmail(feasibilityData.feasibilityEmailToLink)) {
      return toast(`Email to Send Link is Required!`, { type: 'error' })
    }
    if (!id) {
      id = await (formRef.current as any).submit()
      if (!id) return
    }
    setLoading(true)
    await sendOrderAppraisalFeasibilitySignRequest(id, feasibilityData)

    const orderTitle = getOrderTitle(feasibilityData.orderType)
    setUpcomingStatus({ id, value: 'pendingPayment', additional: `${orderTitle} Payment Requested` })
    toast(`${orderTitle} Payment Request is sent.`, { type: 'info' })
    setLoading(false)
  }

  const cancelOrder = async () => {
    if (editOrderID === 'All') {
      return toast('Order ID is required!', { type: 'error' })
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to cancel order?
        <br />
        <span className="text-gray-600 text-[15px] font-bold">Order ID: {editOrderID}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    onConfirmCancelOrder()
  }

  const createLog = async () => {
    if (editingLog.length === 0) {
      return toast('Message is Required!', { type: 'error' })
    }
    if (editOrderID === 'All') {
      return toast('Order ID is required!', { type: 'error' })
    }
    setAction('createLog')
    setLoading(true)
    const res = await appraisalV2CreateLog({ id: editOrderID, message: editingLog })
    if (res.success) {
      let temp = cloneDeep(logs)
      temp.push(res.data)
      setLogs(temp)
    }
    setEditingLog('')
    setAction('')
    setLoading(false)
  }

  const renderDocuments = (value: CategoryDocument[] = [], onChange: Function) => {
    return (
      <CategoryDocuments
        ref={documentsRef}
        documents={value}
        onChange={onChange}
        setLoading={setLoading}
        type={'OrderAppraisal'}
        categories={AppraisalDocumentCategories}
      />
    )
  }

  const getOrderTitle = (orderType: string) => {
    return orderType.split(' ')[0]
  }

  const inputLogic = (_data: any, id: number = 0, key: string = '') => {
    if (id > 0 && key !== '') {
      setUpdateChangeMade(true)
    }
    let data = cloneDeep(_data)
    const orderType = data.orderType.value
    const isFeasibility = feasibilityOrderTypes.includes(orderType)
    if (isFeasibility) {
      data.feasibilityTitle.title = `${getOrderTitle(orderType)} Payment Method`
    }
    paymentInformationFormKeys.forEach((key) => (data[key].visible = !isFeasibility))
    let isSendInvoice = data.isSendInvoice.value
    if (isSendInvoice === undefined) isSendInvoice = true
    if (!isFeasibility) {
      cardFields.map((key) => {
        data[key].visible = !isSendInvoice
      })
      data['paymentLinkEmail'].visible = isSendInvoice
    }
    data['isSendInvoice'].span = isSendInvoice ? 2 : 4

    if (!adminGroup) data['paymentAmount'].visible = false

    if (data['contactSameAsBorrower'].value) {
      Object.keys(contactPersonFields).map((key) => {
        const map = contactPersonFields[key]
        try {
          if (data[key]?.value?.length > 0) {
          } else {
            data[key].value = data[map].value
            data[key].error = InputValidate(data[key])
          }
        } catch {}
      })
    }

    data.purchaseAmount.visible = data.purpose.value === 'Purchase'
    let appraisalFormOptions = []
    try {
      appraisalFormOptions = mapOrderTypeFormOptions[orderType]
    } catch {}
    data.appraisalForm.options = appraisalFormOptions ? appraisalFormOptions : []

    data.underConstruction.visible = ['Commercial Appraisal', 'Residential Appraisal'].indexOf(orderType) !== -1

    if (isFeasibility) {
      ;[...feasibilityKeys, ...feasibilityACHKeys, ...feasibilityCCKeys, 'feasibilitySignKey'].forEach(
        (key) => (data[key].visible = false),
      )
      data.feasibilityTitle.visible = true
      data.feasibilityEmailToLink.visible = true
      data.feasibilitySignKey.visible = true
      data.feasibilityCCStudyFee.visible = true
      feasibilityRequiredKeys.forEach((key) => (data[key].required = true))

      if (key === 'appraisalForm') {
        let amount = 0
        const appraisalForm = data.appraisalForm.value
        if (appraisalForm) {
          let index = feasibilityStudyOptions.indexOf(appraisalForm)
          if (index != -1) {
            amount = feasibilityStudyAmounts[index]
          } else {
            const index = budgetReviewOptions.indexOf(appraisalForm)
            if (index != -1) {
              amount = budgetReviewAmounts[index]
            }
          }
        }
        data.feasibilityCCStudyFee.value = amount
      }

      const feasibilityData: Record<string, any> = {
        feasibilityEmailToLink: data.feasibilityEmailToLink.value,
        orderType: data.orderType.value,
        appraisalForm: data.appraisalForm.value,
        feasibilityCCStudyFee: data.feasibilityCCStudyFee.value,
      }
      data.feasibilitySignKey.render = () => (
        <Button color="gray" onClick={() => onSendSignLink(id, feasibilityData)}>
          Send Payment Link
        </Button>
      )

      if (fullPermissionProfiles.includes(profile.accountType)) {
        data['feasibilityCCStudyFee'].disabled = false
      } else {
        data['feasibilityCCStudyFee'].disabled = true
      }
    } else {
      ;[...feasibilityKeys, ...feasibilityACHKeys, ...feasibilityCCKeys, 'feasibilitySignKey'].forEach(
        (key) => (data[key].visible = false),
      )
    }
    data.feasibilitySignKey.visible = isFeasibility && adminGroup
    data.underConstructionAlert.visible =
      data.underConstruction.visible === true && data.underConstruction.value === true

    data.documents.render = renderDocuments

    if (data.amc.visible) {
      if (data.amc.value === 'Appraisal Nation') {
        data.entityName.visible = true
        if (data.entityName.value === undefined) data.entityName.value = feed.entityTitle
      } else {
        data.entityName.visible = false
      }
      if (data.amc.value === 'Elite Valuations') {
        data.processorEmail.required = true
      } else {
        data.processorEmail.required = false
        data.processorEmail.error = ''
      }
      if (data.amc.value === 'Trinity') {
        data.trinityOrderID.visible = true
      } else {
        data.trinityOrderID.visible = false
      }
    }

    if (
      data.orderType.value === 'Residential Appraisal' &&
      ['Appraisal Nation', 'Elite Valuations', 'Nationwide Appraisal Network (NAN)'].includes(data.amc.value)
    ) {
      const credential =
        data.amc.value == 'Elite Valuations'
          ? eliteCredential
          : data.amc.value == 'Appraisal Nation'
          ? appraisalCredential
          : nationWideCredential

      data.dividerClientLender.visible = true
      data.clientLenderAccount.visible = true
      if (data.amc.value != 'Elite Valuations') data.clientLenderCompany.visible = true
      else data.clientLenderCompany.visible = false

      const accountOptions: any = {}
      credential.map((v: any) => {
        if (v.value.type === 'main') accountOptions['main'] = COMPANY_TITLE
        if (v.value.type === 'broker') accountOptions['broker'] = 'Broker'
        if (v.value.type === 'myAccount') accountOptions['myAccount'] = 'My Account'
      })
      data.clientLenderAccount.options = accountOptions

      const options: any = {}
      credential
        .filter((v: any) => v.value.type == data.clientLenderAccount.value)
        .map((v: any) => {
          if (v.value.clientid) options[v.value.clientid] = v.desc
          else options[v.value.type] = v.desc
        })
      data.clientLenderCompany.options = options
    } else {
      data.dividerClientLender.visible = false
      data.clientLenderAccount.visible = false
      data.clientLenderCompany.visible = false
    }

    if (data.appraisalForm.value === 'Draw Inspection') {
      data.dividerPay.visible = false
      data.isSendInvoice.visible = false
      data.paymentLinkEmail.visible = false
    }

    if (data.notifications.value?.length > 1) {
    } else {
      data.notifications.value =
        data.appraisalForm.value === 'Draw Inspection' ? [`draws@${COMPANY_DNS}`] : [`loansetup@${COMPANY_DNS}`]
    }

    return data
  }

  const hasAdminPermission = fullPermissionProfiles.includes(profile.accountType)

  useEffect(() => {
    if (feed.loanNumber) {
      let temp: any = defaultInputs()
      let _loanNumber = feed.loanNumber
      if (feed.byteproFileName !== 0) _loanNumber = feed.byteproFileName
      temp.loanNumber.value = _loanNumber
      temp.loanOfficerEmail.value = feed.loanOfficer
      temp.processorEmail.value = feed.loanProcessor
      temp.orderDate.value = moment().tz('America/New_York').format('YYYY-MM-DD')
      temp.clientDueDate.value = moment().tz('America/New_York').add(7, 'days').format('YYYY-MM-DD')
      temp.entityName.value = feed.entityTitle
      temp.borrowerFirstName.value = feed.firstName
      temp.borrowerLastName.value = feed.lastName
      temp.borrowerEmail.value = feed.email
      temp.borrowerPhone.value = feed.phone
      temp.propertyAddress.value = feed.subjectPropertyAddress
      temp.propertyCounty.value = feed.propertyCounty
      temp.propertyType.value = feed.propertyType
      temp.purchaseAmount.value = feed.propertyPurchasePrice
      temp.purpose.value = feed.transactionType
      temp.occupancy.value = mapLoanToAppraisal.occupancy[feed.proposedOccupancy]
      brokerCanNotEditFields.map((key: string) => {
        temp[key].disabled = !adminGroup
      })
      temp.amc.visible = hasAdminPermission
      temp.trinityOrderID.visible = hasAdminPermission
      temp.notifications.visible = hasAdminPermission
      temp.entityName.visible = false
      setInputs(inputLogic(temp))
    }
  }, [feed])

  const changeStatus = async (id: number, value: string, additional: string = '') => {
    setLoading(true)
    const res = await changeAppraisal2Status({ id, value, additional })
    if (!additional) toast('Successfully Updated!', { type: 'success' })
    setLoading(false)
    if (res.success) {
      let temp = cloneDeep(data)
      temp.map((item, index) => {
        if (item.id === id) {
          temp[index].status = value
        }
      })
      dispatch(
        setHeader({
          activeChangeFlag: true,
        }),
      )
      setData(temp)
    }
  }

  const showHistory = (id: number) => {
    const options = {
      table: 'Appraisal2',
      field: 'status',
      keys: {
        field: 'status',
        id,
      },
    }
    openAuditLog(options)
  }

  const renderStatus = (item: any) => {
    const load = action === `status-change-${item.id}`
    return (
      <div className="flex items-center">
        <div className={`w-[160px] -mb-4`}>
          <Select
            key={item.id}
            id={`loan-status-${item.id}`}
            size={3}
            title=""
            options={Appraisal2StatusType}
            disabled={load}
            value={item.status}
            onChange={(value) => changeStatus(item.id, value)}
          />
        </div>
        <span className="ml-2 text-gray-500 cursor-pointer">
          <ClockIcon className="w-4 h-4" onClick={() => showHistory(item.id)} />
        </span>
      </div>
    )
  }

  const getHeader = useMemo(() => {
    if (hasAdminPermission) return header()

    const newHeader = header()
    const index = newHeader.findIndex((v) => v.key == 'vendor')
    if (index != -1) newHeader.splice(index, 1)
    return newHeader
  }, [hasAdminPermission])

  const getData = useMemo(() => {
    let rlt: any = []

    const getVendorFragment = (item: any) => {
      let rlt: any = [<div>{item.info.amc}</div>]
      if (item.thirdPartyAPI?.type === Appraisal2ThridPartyType.AppraisalNation) {
        if (item.thirdPartyAPI.appraisalFileNumber) {
          rlt.push(
            <div
              className="text-shade-blue mt-2 hover:underline cursor-pointer italic font-bold"
              onClick={() => {
                setAppraisalNation(item)
                setCancelEditFlag(cancelEditFlag + 1)
              }}
              key={1}
            >
              {item.thirdPartyAPI.appraisalFileNumber}
            </div>,
          )
        }
      }
      if (item.thirdPartyAPI?.type === Appraisal2ThridPartyType.NationWide) {
        if (item.thirdPartyAPI.appraisalFileNumber) {
          rlt.push(
            <div
              className="text-shade-blue mt-2 hover:underline cursor-pointer italic font-bold"
              onClick={() => {
                setNationWide(item)
                setCancelEditFlag(cancelEditFlag + 1)
              }}
              key={1}
            >
              {item.thirdPartyAPI.appraisalFileNumber}
            </div>,
          )
        }
      }
      if (item.thirdPartyAPI?.type === Appraisal2ThridPartyType.Trinity) {
        if (item.thirdPartyAPI.orderID) {
          rlt.push(
            <div
              className="text-shade-blue mt-2 hover:underline cursor-pointer italic font-bold"
              onClick={() => {
                setTrinity(item)
                setCancelEditFlag(cancelEditFlag + 1)
              }}
              key={1}
            >
              TR-{item.thirdPartyAPI.orderID}
            </div>,
          )
        }
      }

      if (item.thirdPartyAPI?.type === Appraisal2ThridPartyType.EliteValuation) {
        if (item.thirdPartyAPI.OrderId) {
          rlt.push(
            <div
              className="text-shade-blue mt-2 hover:underline cursor-pointer italic font-bold"
              onClick={() => {
                setEliteValuation(item)
                setCancelEditFlag(cancelEditFlag + 1)
              }}
              key={1}
            >
              EV-{item.thirdPartyAPI.FileId || item.thirdPartyAPI.OrderId}
            </div>,
          )
        }
      }
      return <div key={2}>{rlt}</div>
    }

    data.map((item) => {
      let json: any = {
        ...item.info,
        vendor: getVendorFragment(item),
        orderDueDate: `<span><div class="border-b w-full mb-2">${formatDate(item.info.orderDate)}</div>${formatDate(
          item.info.clientDueDate,
        )}</span>`,
        orderTypeForm: `<span><div class="border-b w-full mb-2">${item.info.orderType}</div>${item.info.appraisalForm}</span>`,
        status: adminGroup ? renderStatus(item) : Appraisal2StatusType[item.status],
        id: item.id,
        createdBy: `<div>${formatTime(item.createdAt)}</div><div>${item.createdBy}</div>`,
        canUpdateRow: ['newOrder', 'onHold'].includes(item.status),
      }
      rlt.push(json)
    })
    return rlt
  }, [data])

  useEffect(() => {
    let _orderIDs: any = ['All']
    data.map((item: any) => {
      // if (item.status !== 2) {
      _orderIDs.push(item.id)
      // }
    })
    setOrderIDs(_orderIDs)
  }, [data])

  const getCredentials = async (updated = false) => {
    if (updated) return true
    const loanNumber = Number(Api.getLoanNumber())
    const [elite, appraisal, nationWide] = await Promise.all([
      getBorrowerEliteCredential(loanNumber, IntegrationType.EliteValuation),
      getBorrowerEliteCredential(loanNumber, IntegrationType.AppraisalNation),
      getBorrowerEliteCredential(loanNumber, IntegrationType.NationWide),
    ])
    setEliteCredential(elite)
    setAppraisalCredential(appraisal)
    setNationWideCredential(nationWide)
    return true
  }

  const initData = async (needReset = true, updated = false, currentId = 0) => {
    setLoading(true)
    const [res] = await Promise.all([getAppraisalInformationFeedV2(), getCredentials(updated)])
    if (res.success) {
      setData(res.data)
      needReset && setFeed(res.feed)
      setLogs(res.logs)
      setDocuments(res.documents)
      if (updated) {
        const item = currentId ? res.data.find((v: any) => v.id == currentId) : res.data[res.data.length - 1]
        if (item?.thirdPartyAPI.type == Appraisal2ThridPartyType.AppraisalNation) setAppraisalNation(item)
        else if (item?.thirdPartyAPI.type == Appraisal2ThridPartyType.EliteValuation) setEliteValuation(item)
        else if (item?.thirdPartyAPI.type == Appraisal2ThridPartyType.NationWide) setNationWide(item)
      }
    }
    setLoading(false)
  }

  const initOverviewData = () => {
    const loanNumber = Api.getLoanNumber()
    loanOverviewAndStepLogic(loanNumber, '')
  }

  useEffect(() => {
    setLoanNumber()
    initOverviewData()
    initData()
  }, [])

  const getDateWithoutDivider = (values: Record<string, any>) => {
    let rlt = cloneDeep(values)
    Object.keys(rlt).map((key) => {
      if (inputs[key].inputType === 'divider') {
        delete rlt[key]
      }
    })
    return rlt
  }

  const onCancel = () => {
    setUpdateChangeMade(false)
  }

  const onSubmit = (currentId: any, values: Record<string, any>, finishEditing = true) => {
    const data = getDateWithoutDivider(values)
    return new Promise((resolve) => {
      setUpdateChangeMade(false)
      setLoading(true)

      const onCreateAppraisal = async (res: Record<string, any>) => {
        setLoading(false)
        values.id = res.data.id
        values.status = Appraisal2StatusType.newOrder
        values.createdBy = `<div>${formatTime(res.data.createdAt)}</div><div>${res.data.createdBy}</div>`
        await initData(finishEditing, true, currentId)
        resolve(values)
        setEditOrderID(values.id)
        handleFailedDocsNotification(res.thirdPartyResponse)
      }

      const onUpdateAppraisal = async (res: Record<string, any>) => {
        setLoading(false)
        values.createdBy = `<div>${formatTime(res.data.createdAt)}</div><div>${res.data.createdBy}</div>`
        resolve(values)
        setEditOrderID(currentId)
        handleFailedDocsNotification(res.thirdPartyResponse)
        initData(true, true, currentId)
      }

      if (!currentId) createAppraisalInfoV2(data).then(onCreateAppraisal)
      else updateAppraisalInfoV2(currentId, data).then(onUpdateAppraisal)
    })
  }

  const logFragment = useMemo(() => {
    let rlt: any = []
    logs.map((item: any) => {
      let flag = true
      if (editOrderID !== 'All') {
        if (item.id != editOrderID) flag = false
      }
      if (flag) {
        rlt.push(item)
      }
    })
    return (
      <div className="overflow-auto mb-6 shadow-md rounded">
        <table className={`text-sm text-left text-gray-900 dark:text-gray-400 pl-6 w-full`}>
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-2 py-3">Order ID</th>
              <th className="px-2 py-3">
                <span>Message</span>
                <br />
                <span>By/Date</span>
              </th>
            </tr>
          </thead>
          <tbody className="text-[14.5px] text-gray-900">
            {rlt.map((row: any, index: number) => (
              <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                <td className="px-2 py-3">{row.id}</td>
                <td className="px-2 py-3">
                  <span>
                    {row.value}
                    <br />
                    <div className="mt-1 text-right">
                      <span className="italic">{formatTime(row.time)}</span>
                      <br />
                      {row.email}
                    </div>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }, [logs, editOrderID])

  const allDocuments: CategoryDocument[] = useMemo(() => {
    return documents.map((doc) => ({
      category: doc.category,
      name: doc.name,
      fileKey: doc.key,
      createdAt: doc.createdAt,
      status: doc.status,
    }))
  }, [documents])

  const onRemove = async (id: any) => {
    return new Promise((resolve) => {
      setUpdateChangeMade(false)
      setLoading(true)
      deleteAppraisalInfoV2(id).then((res) => {
        if (res.success) {
          setLoading(false)
          resolve(true)
          setEditOrderID('All')
          initData()
        }
      })
    })
  }

  const onConfirmCancelOrder = async () => {
    const id = Number(editOrderID)
    setAction('cancellingOrder')
    const res = await cancelAppraisalInfoV2({ id })
    if (res.success) {
      let temp = cloneDeep(logs)
      temp.push(res.data)
      setLogs(temp)
    }
    setAction('')

    initData()

    return true
  }

  const renderEditOrderInput = () => {
    return (
      <Select
        className="min-w-[160px]"
        id={'orderID'}
        title={'Order ID'}
        options={orderIDs}
        key={'ordeID'}
        value={editOrderID}
        hasDefaultOption={true}
        onChange={(value) => setEditOrderID(value)}
      />
    )
  }

  return (
    <div className="OrderAppraisals2-container px-2">
      <Overview title="Order Valuation" />
      <div className="max-w-screen-2xl m-auto mb-6">
        <div className="relative bg-white shadow1 rounded mb-4 p-4">
          <LayoutLoading show={loading} />
          <div className="relative flex gap-4 my-2 justify-between items-center mb-5">
            <div className="ml-2 text-[18px] font-variation-settings-600 flex items-center gap-2">
              Order Valuation
              <span className="text-[14px] ml-3">
                {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
              </span>
            </div>
          </div>
          {Object.keys(inputs).length > 0 && (
            <div className="grid gap-5 md:grid-cols-3 grid-cols-1 mt-4">
              <div className="md:col-span-1 h-fit">
                <div className="border-b font-variation-settings-[600] ml-2 flex flex-wrap items-end gap-2">
                  <span>Order Process</span>
                  <span>
                    <div className="-mb-3">{renderEditOrderInput()}</div>
                  </span>
                </div>
                {logFragment}
                <div className="px-3">
                  {renderEditOrderInput()}
                  <TextArea
                    className="pt-1"
                    title={'Message'}
                    key={'appraisalLog'}
                    rows={3}
                    value={editingLog}
                    onChange={setEditingLog}
                  />
                  <div className="flex justify-center mt-4 flex-wrap gap-4">
                    <Button className="py-[5px]" onClick={createLog} loading={action === 'createLog'}>
                      Send Message
                    </Button>
                    <Button
                      color="gray"
                      className="py-[5px]"
                      onClick={cancelOrder}
                      loading={action === 'cancellingOrder'}
                    >
                      Cancel Order
                    </Button>
                  </div>
                </div>
              </div>
              <div className="md:col-span-2 h-fit">
                <div className="border-b font-variation-settings-[600] ml-2">Orders</div>
                <div className="border-l">
                  <FormTable
                    ref={formRef}
                    cols={4}
                    header={getHeader}
                    inputs={inputs}
                    inputLogic={inputLogic}
                    data={getData}
                    onSubmit={onSubmit}
                    onRemove={onRemove}
                    onCancel={onCancel}
                    type={'orderAppraisal'}
                    permission={adminGroup ? 1 : 2}
                    addText={'Click Here to add New Order'}
                    cancelEditFlag={cancelEditFlag}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <DocumentsSlideNameType documents={allDocuments} loading={loading} />
        <LoanDetailsSlide />
        {appraisalNation && (
          <AppraisalNation
            onClose={() => {
              setAppraisalNation(null)
              initData()
            }}
            data={appraisalNation}
          />
        )}
        {nationWide && (
          <AppraisalNation
            onClose={() => {
              setNationWide(null)
              initData()
            }}
            data={nationWide}
            type="nationWide"
          />
        )}
        {eliteValuation && (
          <EliteValuation
            onClose={() => {
              setEliteValuation(null)
              initData()
            }}
            data={eliteValuation}
          />
        )}
        {trinity && (
          <Trinity
            onClose={() => {
              setTrinity(null)
              initData()
            }}
            data={trinity}
          />
        )}
      </div>
      <Prompt
        when={updateChangeMade}
        message={`You have added documents and made changes.\nAre you sure you want to leave without clicking the 'Update' button?`}
      />
    </div>
  )
}
