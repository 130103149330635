import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { Overview } from 'components/Overview'
import { usePermissions } from 'hooks/usePermissions'
import { DocumentsSlideNameType, Notes } from 'pages/Loan'
import { loanOverviewAndStepLogic } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { LoanDetailsSlide } from 'pages/LoanSubmission/loanDetails'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getLoanSubmissionDocuments } from 'services'
import Api from 'services/api'
import { setLoanNumber } from 'utils/setLoanNumber'

import { DefaultTracking } from './DefaultTracking'
import { Invoice } from './Invoice'
import { LoanExtension } from './LoanExtension'
import { ServicingParticipation } from './Participation'
import { Payoff } from './Payoff'
import { ServicingDocument } from './ServicingDocument'
import { ServicingNote } from './ServicingNote'
import { TrustLedger } from './TrustLedger'

export function Servicing() {
  const [selectedMenu, setSelectedMenu] = useState('')
  const [menuItems, setMenuItems] = useState<any>({})
  const [documents, setDocuments] = useState<Array<any>>([])

  const location = useLocation()
  const navigate = useHistory()

  useEffect(() => {
    const menu = location.search.replace('?menu=', '')
    if (menu.length) setSelectedMenu(menu)
  }, [location])

  const setMenu = (menuItem: string) => {
    const loanNumber = Api.getLoanNumber()
    navigate.push(`/servicing/${loanNumber}?menu=${menuItem}`)
  }

  useEffect(() => {
    setLoanNumber()
    getLoanSubmissionDocuments().then((res) => {
      if (res.success) setDocuments(res.data)
    })

    const loanNumber = Api.getLoanNumber()
    loanOverviewAndStepLogic(loanNumber, '')
  }, [])

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const { hasPermission } = usePermissions()

  useEffect(() => {
    if (Object.keys(menuItems).length) return
    let temp: any = {}
    if (hasPermission('MANAGE_TRUST_LEDGER')) temp['trustLedger'] = 'Trust Ledger'
    if (hasPermission('MANAGE_SERVICING_DOCS')) {
      temp['servicingDocument'] = 'Servicing Documents'
    }
    if (hasPermission('MANAGE_SERVICING_NOTES')) {
      temp['loanExtensions'] = 'Loan Extensions'
      temp['servicingNotes'] = 'Servicing Notes'
    }
    if (hasPermission('MANAGE_SERVICING_PARTICIPATION')) temp['participation'] = 'Participation'

    if (hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')) temp['defaultTracking'] = 'Default Tracking'

    if (hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')) temp['notes'] = 'Notes'
    if (hasPermission('MANAGE_TASKS')) temp['tasks'] = 'Tasks'
    if (Object.keys(temp).length) temp['invoice'] = 'Invoices'
    temp['orderValuation'] = 'Order Validation'
    temp['payoff'] = 'Payoff'
    setMenuItems(temp)
    if (Object.keys(temp).length && selectedMenu.length === 0) setMenu(Object.keys(temp)[0])
  }, [auth])

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'trustLedger':
        return <TrustLedger />
      case 'servicingDocument':
        return <ServicingDocument />
      case 'notes':
        return <Notes child={true} />
      case 'loanExtensions':
        return <LoanExtension />
      case 'servicingNotes':
        return <ServicingNote />
      case 'participation':
        return <ServicingParticipation />
      case 'defaultTracking':
        return <DefaultTracking />
      case 'invoice':
        return <Invoice />
      case 'payoff':
        return <Payoff />
      default: {
        return <div></div>
      }
    }
  }, [selectedMenu])

  const renderMenu = (menus: Record<string, string>) => {
    return (
      <ul className="sidebar-items flex flex-col p-4 pb-10">
        {Object.keys(menus).map((item: string, index) => {
          const isActive = selectedMenu === item
          if (item === 'tasks') {
            return (
              <li key={index} className="py-2 hover:underline cursor-pointer">
                <Link to={`/tasks/loan/${Api.getLoanNumber()}`} target="_blank">
                  <span className="flex gap-2 items-center">
                    <span>
                      {index + 1}. {menus[item]}
                    </span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                  </span>
                </Link>
              </li>
            )
          }
          if (item === 'orderValuation') {
            return (
              <li key={index} className="py-2 hover:underline cursor-pointer">
                <Link to={`/order_valuation/${Api.getLoanNumber()}`} target="_blank">
                  <span className="flex gap-2 items-center">
                    <span>
                      {index + 1}. {menus[item]}
                    </span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                  </span>
                </Link>
              </li>
            )
          }
          return (
            <li
              key={index}
              onClick={() => {
                setMenu(item)
              }}
              className="py-2"
            >
              <p className={`hover:underline cursor-pointer ${isActive ? 'border px-4 py-1 bg-zinc-100' : 'py-1'}`}>
                {index + 1}. {menus[item]}
              </p>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="py-6 px-2">
      <DocumentsSlideNameType documents={documents} loading={false} />
      <LoanDetailsSlide />

      <Overview title="Servicing" />
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded mb-4">
          {renderMenu(menuItems)}
        </div>
        <div className="relative h-fit content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1 mb-4">
          <h2 className="text-2xl font-bold flex items-center mb-5">{menuItems[selectedMenu]}</h2>
          {renderFragment}
        </div>
      </div>
    </div>
  )
}
