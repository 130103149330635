import { InputType } from 'config'

export const productFields = (): Record<string, InputType> => {
  return {
    Description: {
      inputType: 'text',
      type: 'text',
      title: 'Product Name',
      required: true,
    },
  }
}

export const programFields = (): Record<string, InputType> => {
  return {
    Name: {
      inputType: 'text',
      type: 'text',
      title: 'Program Name',
      required: true,
    },
  }
}

export const priceFields = (): Record<string, InputType> => {
  return {
    Price: {
      inputType: 'text',
      type: 'number',
      title: 'Value',
      required: true,
    },
  }
}

export const lockDayFields = (): Record<string, InputType> => {
  return {
    LockDay: {
      inputType: 'text',
      type: 'date',
      title: 'Price Expiration Date',
      required: true,
    },
  }
}
