import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import type { CustomInput, InputFileTable, InputSelect, InputType } from 'config'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  analyzeAccounting,
  getAccountingRelations,
  submitAccounting,
  updateAccountingById,
  validateLoanNumbers,
} from 'services'
import { Button, Modal } from 'stories/components'
import { confirm, formatDate, formatDateYMD, InputConvert, InputValidate, removeComma, thousandSeperator } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingCategory } from '../AccountingCategories'
import type { IAccountingAccount } from '../Accounts/types'
import { getAccountOptions } from '../Accounts/utils'
import { BalanceTable } from './BalanceTable'
import { DebitOrCreditSection } from './DebitOrCreditSection'
import { SplitAccountingDialog } from './SplitAccountingDialog'
import { SubRecordTable } from './SubRecordTable'
import {
  type IAccounting,
  AccountingRecordTypeOptions,
  AssetsBaseId,
  BaseIdForBalanceTable,
  IAccountingRecord,
  IDebitOrCredit,
  LiabilitiesBaseId,
} from './types'
import { getChildrenCategories, getChildrenSubCategories } from './util'

const defaultInputs = (): Record<string, InputType> => {
  return {
    fileId: {
      inputType: 'text',
      type: 'text',
      title: 'File ID',
      required: false,
      readOnly: true,
    },
    baseId: {
      inputType: 'select',
      title: 'Base Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: false,
      required: true,
    },
    category: {
      inputType: 'select',
      title: 'Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
      required: true,
    },
    subCategory: {
      inputType: 'select',
      title: 'Sub Category',
      hasDefaultOption: true,
      defaultOptionText: 'None',
      options: {},
      sort: true,
      required: false,
    },
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      required: true,
    },
    description: {
      inputType: 'text',
      type: 'text',
      title: 'Description',
      required: true,
    },
    amount: {
      inputType: 'text',
      prefix: '$',
      type: 'thousandSep',
      title: 'Amount',
      required: true,
    },
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan Number',
      required: false,
    },
    ignoreLoanNumberCheck: {
      inputType: 'checkbox',
      title:
        'The loan number entered does not exist, would you like to log this transaction against this loan number anyway?',
      visible: false,
      span: 'full',
    },
    servicerLoanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Servicing Loan Number',
      required: false,
      // visible: false,
    },
    accountId: {
      inputType: 'select',
      title: 'Account',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
      required: true,
    },
    note: {
      inputType: 'textarea',
      title: 'Note',
      required: false,
      rows: 3,
    },
    payoff: {
      inputType: 'toggle',
      title: 'Paid Off',
      required: false,
    },
    payoffDate: {
      inputType: 'text',
      type: 'date',
      title: 'Paid Off Date',
      required: false,
    },
    debitOrCredit: {
      inputType: 'custom',
      title: 'Debit / Credit',
      required: false,
      span: 'full',
    },
    files: {
      inputType: 'filetable',
      filePath: '',
      title: 'Files',
      showStatus: false,
      showCategory: false,
      multiple: true,
      span: 'full',
    },
    records: {
      inputType: 'custom',
      title: 'Records',
      span: 'full',
      visible: false,
    },
    balanceTable: {
      inputType: 'custom',
      title: 'Balance Sheet',
      span: 'full',
      visible: false,
    },
  }
}

interface IEditAccountingContentProps {
  type: string
  baseId?: number
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  accounts: IAccountingAccount[]
  item: IAccounting | null
  hasBalanceTable?: boolean
  onClose: Function
}

export const EditAccountingContent = ({
  type,
  baseId: defaultBaseId = 0,
  baseCategory,
  category,
  accounts,
  item,
  hasBalanceTable = true,
  onClose,
}: IEditAccountingContentProps) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [errorMessage, setErrorMessage] = useState('')
  const [isShowSplit, setShowSplit] = useState(false)
  const isNew = !item

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    ;(newInputs.baseId as InputSelect).options = baseCategory

    if (item && item.fileId) {
      newInputs.fileId.visible = true
      newInputs.fileId.value = `#${item.fileId}`
    } else newInputs.fileId.visible = false

    if (item) {
      const baseId = category.find((v) => v.id == item.category)?.baseId
      item.baseId = baseId
      newInputs.baseId.value = baseId
      ;(newInputs.category as InputSelect).options = getChildrenCategories(category, baseId!)
      ;(newInputs.subCategory as InputSelect).options = getChildrenSubCategories(category, item.category)

      Object.keys(item).forEach((key) => {
        if (newInputs[key]) newInputs[key].value = (item as any)[key]
      })

      newInputs.date.value = formatDateYMD(item.date)
      ;(newInputs.files as InputFileTable).filePath = `accountings/${type}/${item.id}`

      if (hasBalanceTable && baseId && BaseIdForBalanceTable.includes(baseId)) {
        newInputs.records.visible = true
        newInputs.balanceTable.visible = true
        newInputs.balanceTable.title = Number(baseId) == AssetsBaseId ? 'Liabilities' : 'Assets'
      }
      // newInputs.payoff.visible = true
      // newInputs.payoffDate.visible = true
      // newInputs.debitOrCredit.visible = true
    } else (newInputs.files as InputFileTable).filePath = `accountings/${type}/${Date.now()}`
    ;(newInputs.accountId as InputSelect).options = getAccountOptions(accounts)
    ;(newInputs.records as CustomInput).render = renderRecords
    ;(newInputs.balanceTable as CustomInput).render = renderBalanceTable

    if (defaultBaseId) {
      newInputs.baseId.disabled = true
      if (newInputs.baseId.value != defaultBaseId) {
        newInputs.baseId.value = defaultBaseId
        ;(newInputs.category as InputSelect).options = getChildrenCategories(category, defaultBaseId)
      }
    }

    setInputs(newInputs)
  }, [item])

  useEffect(() => {
    if (!item || !inputs.balanceTable.visible || inputs.balanceTable.value) return
    loadRelatedItems()
  }, [inputs])

  const totalRecordAmount = useMemo(() => {
    if (!inputs.records.visible) return 0

    const records: IAccountingRecord[] = inputs.records.value ? inputs.records.value : []
    const calculatedAmount = records.reduce((prev, value) => prev + value.amount, 0)
    return calculatedAmount
  }, [inputs])

  const totalBalanceAmount = useMemo(() => {
    if (!inputs.balanceTable.visible) return 0

    const relatedItems: IAccounting[] = inputs.balanceTable.value ? inputs.balanceTable.value.data : []
    const calculatedAmount = relatedItems.filter((v) => !v.deleted).reduce((prev, value) => prev + value.amount, 0)
    return calculatedAmount
  }, [inputs])

  const loadRelatedItems = async () => {
    setLoading(true)
    const relatedItems = await getAccountingRelations(item!.id)

    const newInputs = cloneDeep(inputs)
    newInputs.balanceTable.value = {
      baseId: AssetsBaseId + LiabilitiesBaseId - Number(inputs.baseId.value),
      data: relatedItems,
    }
    setInputs(newInputs)
    setLoading(false)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key == 'baseId') {
      ;(newInputs.category as InputSelect).options = getChildrenCategories(category, value)
      newInputs.category.value = ''

      const visibleBalanceTable = !!item && hasBalanceTable && !!value && BaseIdForBalanceTable.includes(Number(value))
      newInputs.records.visible = visibleBalanceTable
      newInputs.balanceTable.visible = visibleBalanceTable
      newInputs.balanceTable.title = Number(value) == AssetsBaseId ? 'Liabilities' : 'Assets'
    }
    if (key == 'category') {
      ;(newInputs.subCategory as InputSelect).options = getChildrenSubCategories(category, value)
      newInputs.subCategory.value = ''
    }
    setInputs(newInputs)
  }

  const getCurrentItem = () => {
    const data: Record<string, any> = {}
    for (const key in inputs) {
      data[key] = inputs[key].value
    }
    return {
      ...(item || {}),
      ...data,
      date: formatDate(data.date),
    }
  }

  const onSubmit = async (actived = true) => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (newInputs[key].value && newInputs[key].value.trim) newInputs[key].value = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    if (hasError && actived) {
      setInputs(newInputs)
      return
    }

    const amount = removeComma(data.amount)
    const baseId = Number(inputs.baseId.value)
    if (!BaseIdForBalanceTable.includes(baseId) && inputs.records.visible && inputs.balanceTable.visible) {
      const title = inputs.balanceTable.title

      if (totalRecordAmount + totalBalanceAmount > amount) {
        const message = `The sum of total amount of Records(${thousandSeperator(
          totalRecordAmount,
        )}) and ${title}(${thousandSeperator(totalBalanceAmount)}) is exceed the limitation(${thousandSeperator(
          amount,
        )}). Please review and adjust the amounts of Records and ${title}.`
        setErrorMessage(message)

        setLoading(false)
        return toast(message, { type: 'error' })
      }
    }

    setLoading(true)

    if (data.loanNumber && (!newInputs.ignoreLoanNumberCheck.visible || !newInputs.ignoreLoanNumberCheck.value)) {
      const loanNumberValidList = await validateLoanNumbers([data.loanNumber])
      if (!loanNumberValidList[data.loanNumber]) {
        setLoading(false)
        newInputs.loanNumber.error = 'Loan Number not found'
        newInputs.ignoreLoanNumberCheck.visible = true
        setInputs(newInputs)
        return
      }
    }

    // if (item && item.relatedId) {
    //   const relatedItem: IAccounting = await getAccountingById(item.relatedId)
    //   const relatedItems: IAccounting[] = await getAccountingRelations(item.relatedId)
    //   const calculatedAmount = relatedItems.reduce((prev, value) => prev + value.amount, 0)
    //   const amount = removeComma(data.amount)
    //   const newAmountSum = calculatedAmount - item.amount + amount

    //   if (newAmountSum > relatedItem.amount) {
    //     setErrorMessage(
    //       `The total amount(${thousandSeperator(
    //         newAmountSum,
    //       )}) of related Records is exceed the limitation(${thousandSeperator(
    //         relatedItem.amount,
    //       )}). Please review and adjust the amounts.`,
    //     )
    //     setLoading(false)
    //     return
    //   }
    // }

    const submitData: IAccounting = {
      id: item ? item.id : -Date.now(),
      fileId: item ? item.fileId : 0,
      category: data.category,
      subCategory: Number(data.subCategory || 0),
      date: formatDate(data.date),
      description: data.description,
      amount: removeComma(data.amount),
      loanNumber: data.loanNumber,
      servicerLoanNumber: type == 'Record' ? '' : data.servicerLoanNumber,
      accountId: data.accountId,
      note: data.note,
      files: data.files,
      actived: actived,
      deleted: false,

      relatedId: item ? item.relatedId : 0,
      relatedItems: data.balanceTable ? data.balanceTable.data : [],
      records: data.records ? data.records : [],

      baseId: data.baseId,

      payoff: data.payoff,
      payoffDate: data.payoffDate,
      debitOrCredit: data.debitOrCredit,
    }

    const duplicatedIds = await analyzeAccounting([submitData])
    if (duplicatedIds.includes(submitData.id)) {
      setLoading(false)
      const override = await confirm('The same record is already exists. Are you sure to save this record?')
      if (!override) return
      setLoading(true)
    }

    if (!hasBalanceTable) {
      onClose(submitData)
      setLoading(false)
      return
    }

    let resultData: IAccounting
    if (submitData.id < 0) {
      ;[resultData] = await submitAccounting(null, [submitData])
    } else {
      resultData = await updateAccountingById(submitData.id, submitData)
    }
    setLoading(false)

    resultData.baseId = data.baseId
    onClose(resultData)
  }

  const renderInputs = () => {
    const color = errorMessage ? 'red' : 'blue'
    return (
      <div className={`${isNew ? 'w-112' : 'grid grid-cols-3 gap-x-4'} relative`}>
        {inputs.balanceTable.visible ? (
          <div
            className={`max-w-lg p-2 border border-${color}-400 bg-${color}-50 text-${color}-800 rounded-lg mb-4 col-span-full grid grid-cols-4`}
          >
            {[
              ['Main Amount', inputs.amount.value],
              ['Total Records', totalRecordAmount],
              [`Total ${inputs.baseId.value == AssetsBaseId ? 'Liabilities' : 'Assets'}`, totalBalanceAmount],
            ].map((v) => (
              <React.Fragment key={v[0]}>
                <p>- {v[0]}:</p>
                <p className="col-span-3">${thousandSeperator(v[1])}</p>
              </React.Fragment>
            ))}
            {/* {errorMessage ? <p className="mt-4 col-span-full font-semibold">{errorMessage}</p> : null} */}
          </div>
        ) : null}

        {Object.keys(inputs).map((key, index) => {
          if (key == 'servicerLoanNumber') {
            if (type == 'Record') return null
            if (!inputs.ignoreLoanNumberCheck.visible && !inputs.servicerLoanNumber.value) return null
            if (!inputs.ignoreLoanNumberCheck.visible && inputs.servicerLoanNumber.value)
              inputs.servicerLoanNumber.readOnly = true
            else inputs.servicerLoanNumber.readOnly = false
          }
          if (key == 'amount' && item && !item.actived) {
            inputs[key].additionalElements = (
              <span
                className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
                onClick={() => setShowSplit(true)}
              >
                Split
              </span>
            )
          }
          if (inputs[key].visible === false) return null
          if (key == 'debitOrCredit') {
            ;(inputs[key] as CustomInput).render = renderDebitOrCredit
          }

          return (
            <div
              className={`input mb-4 ${inputs[key].span ? `col-span-${inputs[key].span}` : ''} ${
                key == 'ignoreLoanNumberCheck' ? 'p-2 border border-yellow-400 bg-yellow-50 rounded-lg' : ''
              }`}
              key={index}
            >
              <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    )
  }

  const renderRecords = (value: IAccountingRecord[], onChange: Function) => {
    if (!value) return null

    return <SubRecordTable type={type} data={value ? value : []} onChange={onChange} />
  }

  const renderBalanceTable = (value: { baseId: number; data: IAccounting[] }, onChange: Function) => {
    if (!value) return null

    return (
      <BalanceTable
        type={type}
        baseCategory={baseCategory}
        category={category}
        accounts={accounts}
        data={value ? value.data : []}
        baseId={value.baseId}
        onChange={onChange}
      />
    )
  }

  const renderDebitOrCredit = (value: IDebitOrCredit, onChange: Function) => {
    return <DebitOrCreditSection baseCategory={baseCategory} category={category} value={value} onChange={onChange} />
  }

  const title = useMemo(() => {
    const typeName = AccountingRecordTypeOptions[type]
    if (!item) return `Create ${typeName}`
    if (!item.actived) return `Approve #${item.id}`
    return `Edit #${item.id}`
  }, [item])

  if (item === null)
    return (
      <Modal
        title={title}
        loading={isLoading}
        isOpen
        onOk={() => onSubmit()}
        onClose={() => onClose(null)}
        titleOkay="Submit"
        titleCancel=""
      >
        {renderInputs()}
      </Modal>
    )

  return (
    <div className="relative py-4 text-gray-900">
      <LayoutLoading show={isLoading} />
      <p className="font-semibold text-lg mb-2">{title}</p>
      {renderInputs()}
      <div className="flex justify-center gap-4 border-b-[2px] pb-4 border-dashed">
        {!!item && !item.actived && !item.deleted && (
          <Button outline onClick={() => onSubmit(false)}>
            Update
          </Button>
        )}
        <Button onClick={() => onSubmit()}>{!!item && !item.actived ? 'Approve' : 'Submit'}</Button>
        <Button color="white" onClick={() => onClose(null)}>
          Close
        </Button>
      </div>
      {isShowSplit && (
        <SplitAccountingDialog
          accounts={accounts}
          baseCategory={baseCategory}
          category={category}
          original={getCurrentItem() as IAccounting}
          onClose={(updatedItems) => {
            setShowSplit(false)
            updatedItems && onClose(updatedItems)
          }}
        />
      )}
    </div>
  )
}
