import {
  ArrowDownCircleIcon,
  BuildingOffice2Icon,
  CheckIcon,
  ChevronRightIcon,
  FolderOpenIcon,
} from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { loanOverviewAndStepLogic } from 'pages/LoanOverview/loanOverviewAndStepLogic'
import { BorrowerDocumentsUpload } from 'pages/LoanSubmission/BorrowerDocumentsUpload'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { LoanApplication } from '..'
import { AppSignedModal } from './AppSignedModal'

export const CustomSignApplicationContent = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [menu, setMenu] = useState('application')
  const defaultMenu = useQuery().get('menu')
  const { pathname } = useLocation()
  const [isAppSignedModal, showAppSignedModal] = useState(false)

  const registeredToken = useSelector((state: any) => state.auth.token)

  const { step, loanDetail } = useSelector((state: any) => {
    return {
      step: state.step,
      loanDetail: state.loanDetail,
    }
  })

  const isBorrowerPipeline = pathname.includes('borrowerPipeline')

  const loanNumber = setLoanNumber()

  useEffect(() => {
    if (!registeredToken) return
    loanOverviewAndStepLogic(loanNumber, 'application').then((res: any) => {
      if (res.success) {
        setLoading(false)
      } else {
        setLoading(false)
        setError('Please complete Loan Application Information!')
      }
    })
  }, [registeredToken])

  useEffect(() => {
    if (defaultMenu) setMenu(defaultMenu)
  }, [defaultMenu])

  useEffect(() => {
    setError('')
  }, [menu])

  const applicationComplete = useMemo(() => {
    const { application } = step
    let completed =
      application.property === 1 &&
      application.borrower === 1 &&
      application.asset === 1 &&
      application.track === 1 &&
      application.hmda === 1 &&
      application.sign === 1

    if (loanDetail.includeCoborrower) {
      completed = completed && application.borrower2 === 1 && application.hmda2 === 1 && application.sign2 === 1
    }
    return completed
  }, [step, loanDetail.includeCoborrower])

  const renderContent = () => {
    if (loading) return null
    if (menu === 'application') {
      return (
        <LoanApplication
          borrowerApplication={true}
          changeBorrowerMenu={async () => {
            if (!isBorrowerPipeline) {
              setMenu('documents')
              return
            }
            showAppSignedModal(true)
          }}
        />
      )
    }
    if (menu === 'documents') {
      if (error.length > 0) {
        return (
          <div
            className="max-w-screen-2xl m-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[16px] mt-6"
            role="alert"
          >
            {' '}
            {error}
          </div>
        )
      }
      return <BorrowerDocumentsUpload />
    }
  }

  return (
    <>
      {!isBorrowerPipeline && (
        <>
          <div className="col-span-12 flex flex-wrap items-center gap-4 justify-center mb-2">
            <div
              className={`rounded-t text-gray-900 mb-4 shadow1 px-5 py-2.5 border-b-4 hover:border-sky-500 cursor-pointer flex items-center ${
                menu === 'application' && 'border-shade-blue'
              }`}
              onClick={() => {
                setMenu('application')
              }}
            >
              {applicationComplete && (
                <span className="mr-2 text-shade-blue">
                  <CheckIcon className="w-6 h-6"></CheckIcon>
                </span>
              )}
              <span className="font-semibold">1. Application</span>
              <span className="ml-2">
                <BuildingOffice2Icon className="w-5 h-5"></BuildingOffice2Icon>
              </span>
            </div>
            <span className="mb-4">
              <ChevronRightIcon className="w-6 h-6"></ChevronRightIcon>
            </span>
            <div
              className={`rounded-t shadow1 mb-4 px-5 py-2.5 cursor-pointer flex items-center border-b-4 ${
                !applicationComplete ? 'text-gray-400 cursor-not-allowed' : 'text-gray-900 hover:border-sky-500'
              } ${menu === 'documents' && 'border-shade-blue'}`}
              onClick={() => {
                if (applicationComplete) setMenu('documents')
              }}
            >
              {applicationComplete && (
                <svg className="animate-bounce w-6 h-6 mr-2 text-shade-blue">
                  <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
                </svg>
              )}
              <span className="font-semibold">2. Documents Upload</span>
              <span className="ml-2">
                <FolderOpenIcon className="w-5 h-5"></FolderOpenIcon>
              </span>
            </div>
          </div>
          {applicationComplete && menu === 'application' && (
            <div className="max-w-screen-2xl m-auto mb-6">
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px]"
                role="alert"
              >
                <div className="text-[16px] flex">
                  You've Completed and Signed the Loan Application!{' '}
                  <div
                    className="flex items-center hover:underline font-semibold cursor-pointer ml-2"
                    onClick={() => {
                      setMenu('documents')
                    }}
                  >
                    <span>
                      <svg className="animate-bounce w-6 h-6 mr-1">
                        <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
                      </svg>
                    </span>
                    <span className="mr-1">Click Here</span>
                  </div>{' '}
                  to upload your Documents.
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {renderContent()}
      {isAppSignedModal && <AppSignedModal />}
      <LayoutLoading show={loading} />
    </>
  )
}
