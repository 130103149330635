import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { getPrice3decimal } from 'utils'

import type { IDscrLoanAmountLeverageLimit } from '../../interface'

export const DscrLoanAmountLeverageLimit = ({ data }: { data: IDscrLoanAmountLeverageLimit[] }) => {
  return (
    <div className="h-full">
      {!!data.length ? (
        <table className="w-fit text-center text-sm">
          <thead className="font-bold bg-gray-100">
            <tr>
              <th colSpan={2} className="border p-1">
                Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 px-3 whitespace-nowrap">
                Loan Purpose
              </th>
              <th rowSpan={2} className="border p-1 w-[140px]">
                Max LTV
              </th>
              <th rowSpan={2} className="border p-1 w-[140px]">
                Max CLTV
              </th>
            </tr>
            <tr>
              <th className="border p-1 w-[160px]">From</th>
              <th className="border p-1 w-[160px]">To</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td rowSpan={3} className="border px-3 py-1">
                      {getPrice3decimal(item.loanAmount.from)}
                    </td>
                    <td rowSpan={3} className="border px-3 py-1">
                      {getPrice3decimal(item.loanAmount.to)}
                    </td>
                    <td className="border px-3 py-1">Purchase</td>
                    <td className="border px-3 py-1">{item.LTV.purchase}</td>
                    <td className="border px-3 py-1">{item.CLTV.purchase}</td>
                  </tr>
                  <tr>
                    <td className="border px-3 py-1">No-Cashout</td>
                    <td className="border px-3 py-1">{item.LTV.nocashout}</td>
                    <td className="border px-3 py-1">{item.CLTV.nocashout}</td>
                  </tr>
                  <tr>
                    <td className="border px-3 py-1">Cashout</td>
                    <td className="border px-3 py-1">{item.LTV.cashout}</td>
                    <td className="border px-3 py-1">{item.CLTV.cashout}</td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )}
    </div>
  )
}
