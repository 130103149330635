import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { deleteGuidelineCondition, getGuidelineConditions, postGuidelineCondition } from 'services'
import { Button } from 'stories/components'
import { confirm, prompt } from 'utils'

export interface GuidelineCondition {
  id: number
  type: string
  title: string
  values: string[]
  programID: number
}

export const StaticComponent = ({
  programID,
  count = 0,
  align = 'left',
  additionalItem = null,
}: {
  programID: number
  count?: number
  align?: string
  additionalItem?: JSX.Element | null
}) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<GuidelineCondition[]>([])

  const { hasPermission } = usePermissions()

  const canEdit = hasPermission('RESOURCE_MATRIX')

  useEffect(() => {
    setLoading(true)
    getGuidelineConditions(programID).then((data) => {
      setData(data)
      setLoading(false)
    })
  }, [programID])

  const onAddTable = async () => {
    const title = await prompt('Add New Table', {
      placeholder: 'New Table Title',
    })
    if (!title) return
    onUpdate(0, title, [])
  }

  const onEditTitle = async (item: GuidelineCondition) => {
    const value = await prompt('Update Table Title', {
      placeholder: 'New Table Title',
      value: item.title,
    })
    if (!value) return

    item.title = value
    onUpdate(item.id, item.title, item.values)
  }

  const onDeleteTable = async (item: GuidelineCondition) => {
    const value = await confirm('Do you want to remove this entire table?', {
      title: item.title,
    })

    if (!value) return

    setLoading(true)
    await deleteGuidelineCondition(item.id)
    const newData = cloneDeep(data)
    const index = newData.findIndex((d) => d.id == item.id)
    newData.splice(index, 1)
    setData(newData)
    setLoading(false)
  }

  const onAddRow = async (item: GuidelineCondition) => {
    const value = await prompt('Add New Row', {
      placeholder: 'New Row Value',
    })
    if (!value) return

    item.values.push(value)
    onUpdate(item.id, item.title, item.values)
  }

  const onEdit = async (item: GuidelineCondition, index: number) => {
    const value = await prompt('Edit Row value', {
      placeholder: 'New Row Value',
      value: item.values[index],
    })
    if (!value) return

    item.values[index] = value
    onUpdate(item.id, item.title, item.values)
  }

  const onDelete = async (item: GuidelineCondition, index: number) => {
    const value = await confirm('Do you want to remove this item?', {
      title: item.values[index],
    })
    if (!value) return

    item.values.splice(index, 1)
    onUpdate(item.id, item.title, item.values)
  }

  const onUpdateSort = (item: GuidelineCondition, orgIndex: number, newIndex: number) => {
    if (orgIndex == newIndex) return
    const value = item.values.splice(orgIndex, 1)
    item.values.splice(newIndex, 0, ...value)
    onUpdate(item.id, item.title, item.values)
  }

  const onUpdate = async (id: number, title: string, values: string[]) => {
    setLoading(true)
    const newData = cloneDeep(data)
    const newItem = await postGuidelineCondition(programID, { id, title, values })
    if (id == 0) newData.push(newItem)
    else {
      const index = newData.findIndex((item) => item.id == id)
      if (index != -1) newData[index] = newItem
    }
    setData(newData)
    setLoading(false)
    return newItem
  }

  return (
    <div className="flex flex-col gap-6">
      <LayoutLoading show={isLoading} />
      {data.map(
        (item, index) =>
          (!count || count > index) && (
            <div key={index} className="w-full overflow-auto shadow-md rounded">
              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                <thead className="text-[14px] text-gray-900 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-center">
                  <tr>
                    <th className={`p-3 border`}>
                      <span className={`flex gap-2 items-center ${align == 'center' ? 'inline-flex' : ''}`}>
                        <span className="flex bg-transparent font-bold text-left text-[16px] capitalize font-variation-settings-600">
                          {item.title}
                        </span>

                        {canEdit && (
                          <span className="flex">
                            <span
                              className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                              onClick={() => onEditTitle(item)}
                            >
                              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                            </span>
                            <span
                              className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                              onClick={() => onDeleteTable(item)}
                            >
                              <TrashIcon className="w-4 h-4"></TrashIcon>
                            </span>
                          </span>
                        )}
                      </span>
                    </th>
                    {canEdit && (
                      <th scope="col" className="px-3 py-3 w-24">
                        <button
                          className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                          onClick={() => onAddRow(item)}
                        >
                          + Add
                        </button>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {additionalItem}
                  {item.values.map((v, index) => {
                    const orderOptions = Array.apply(null, Array(index + 1)).map((_, index) => index.toString())
                    return (
                      <tr
                        className={`border-b dark:bg-gray-800 dark:border-gray-700 ${index % 2 && 'bg-slate-50'}`}
                        key={index}
                      >
                        <td className="p-3 flex items-center gap-2">
                          {index + 1}. {v}
                          {canEdit && (
                            <span className="flex gap-2">
                              <span
                                className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onEdit(item, index)}
                              >
                                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                              </span>
                              <span
                                className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                                onClick={() => onDelete(item, index)}
                              >
                                <TrashIcon className="w-4 h-4"></TrashIcon>
                              </span>
                            </span>
                          )}
                        </td>
                        {canEdit && (
                          <td className="w-24">
                            <select
                              onChange={(e) => onUpdateSort(item, index, Number(e.target.value))}
                              value={index}
                              className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                            >
                              {orderOptions.map((val) => (
                                <option key={val}>{val}</option>
                              ))}
                            </select>
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ),
      )}
      {canEdit && !count && (
        <div className="inline">
          <Button size="sm" className="mb-0" onClick={() => onAddTable()}>
            + Add
          </Button>
        </div>
      )}
    </div>
  )
}
