import { useEffect, useState } from 'react'
import { getBrokerVerificationByUser } from 'services'
import { DocumentFile, InputFileTable } from 'stories/components'

export const BrokerVerificationFiles = ({ userId }: { userId: number }) => {
  const [files, setFiles] = useState<DocumentFile[]>([])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const { verifications } = await getBrokerVerificationByUser(userId)
    const files = verifications
      .filter((v: any) => !!v.fileKey)
      .map(
        (v: any): DocumentFile => ({
          id: v.id,
          status: v.status,
          category: '',
          createdAt: v.createdAt,
          fileKey: v.fileKey,
          name: v.fileName,
        }),
      )
    setFiles(files)
  }

  return (
    <InputFileTable
      filePath=""
      uploadable={false}
      showCategory={false}
      onChange={() => {}}
      value={files}
      disableNameChange
      title="Total"
    />
  )
}
