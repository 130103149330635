import { BankerSearch } from 'pages/Admin'
import { Modal } from 'stories/components'

export const FindBankerDialog = (props: any) => {
  return (
    <Modal
      title="Find Appraiser / Settlement Agent / Contractor"
      titleOkay=""
      titleCancel="Close"
      onClose={props.onClose}
      onOk={() => {}}
      isOpen
    >
      <div className="md:w-196">
        <BankerSearch type="resources" />
      </div>
    </Modal>
  )
}
