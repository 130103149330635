import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { useEffect, useMemo, useState } from 'react'
import { svgSearch } from 'stories/assets'
import { Input } from 'stories/components'

import type { IReportHeaders } from './types'

export const ReportPlaceholders = ({
  placeholders,
  selectedPlaces,
  setSelectedPlaces,
  type,
  setType,
}: {
  placeholders: Record<string, IReportHeaders[]>
  selectedPlaces: string[]
  setSelectedPlaces: Function
  type: string
  setType: Function
}) => {
  const [query, setQuery] = useState('')

  const [collapseStatus, setCollapseStatus] = useState<Record<string, boolean>>({})

  useEffect(() => {
    setType('Loan')
  }, [])

  const allPlaceholders = useMemo(() => {
    if (!placeholders[type]) return []
    const list: string[] = []
    placeholders[type].map((v) => v.data).forEach((v) => list.push(...v))
    return list
  }, [type, placeholders])

  const isSelectedAll = useMemo(
    () => selectedPlaces.length == allPlaceholders.length,
    [selectedPlaces, allPlaceholders],
  )

  const filteredPlaceholders = useMemo(() => {
    if (!query) return placeholders[type] || []
    return placeholders[type].map((header) => {
      return {
        title: header.title,
        data: header.data.filter((v) => v.toLowerCase().includes(query.toLowerCase())).sort(),
      }
    })
  }, [type, placeholders, query])

  const onSelectPlaceholder = (value: string) => {
    const newValues = cloneDeep(selectedPlaces)
    const index = newValues.indexOf(value)
    if (index == -1) newValues.push(value)
    else newValues.splice(index, 1)
    setSelectedPlaces(newValues)
  }

  const onToggle = (title: string) => {
    const newData = cloneDeep(collapseStatus)
    newData[title] = !newData[title]
    setCollapseStatus(newData)
  }

  return (
    <div className="border-r border-gray-200 pr-4">
      <div className="flex gap-4 items-center justify-between">
        <p className="text-lg font-bold mb-4">Placeholders</p>
        {/* <ButtonGroup
          title={['Loan', 'Trust Ledger']}
          value={type}
          onChange={(v) => {
            setType(v)
            setSelectedPlaces(['Loan #'])
          }}
        /> */}
      </div>

      <div className="flex items-center justify-between mb-2 gap-x-4">
        <div className="-mb-4 flex-1">
          <Input type="search" title="Search" hasIcon icon={svgSearch} value={query} onChange={setQuery} />
        </div>

        <p
          className="text-sm cursor-pointer text-shade-blue hover:underline"
          onClick={() => setSelectedPlaces(isSelectedAll ? [] : allPlaceholders)}
        >
          {isSelectedAll ? 'Unselect All' : 'Select All'}
        </p>
      </div>
      <div className="grid grid-cols-2 text-sm">
        {filteredPlaceholders.map((headers, index) => {
          const { title, data } = headers
          const isOpen = !collapseStatus[title]

          let isSelectedAll = true
          data.forEach((v) => {
            isSelectedAll = isSelectedAll && selectedPlaces.includes(v)
          })

          return (
            <>
              <p className="flex items-center gap-2 col-span-full px-2 py-1 bg-gray-100 font-semibold cursor-pointer">
                <span className="flex-1" onClick={() => onToggle(title)}>
                  {index + 1}. {title}
                </span>

                <span
                  className="text-sm cursor-pointer text-shade-blue hover:underline font-normal"
                  onClick={() => {
                    const newData = cloneDeep(selectedPlaces)
                    if (isSelectedAll) {
                      data.forEach((v) => {
                        const index = newData.indexOf(v)
                        if (index != -1) newData.splice(index, 1)
                      })
                    } else {
                      data.forEach((v) => {
                        const index = newData.indexOf(v)
                        if (index == -1) newData.push(v)
                      })
                    }
                    setSelectedPlaces(newData)
                  }}
                >
                  {isSelectedAll ? 'Unselect All' : 'Select All'}
                </span>
                {isOpen ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
              </p>
              {isOpen &&
                data.map((v, i) => {
                  const isSelected = selectedPlaces.includes(v)
                  return (
                    <div
                      className={`flex gap-2 cursor-pointer items-center p-2 border-b border-gray-100 hover:bg-gray-100 ${
                        isSelected ? 'bg-gray-200' : ''
                      }`}
                      onClick={() => onSelectPlaceholder(v)}
                    >
                      <CheckIcon className={`${isSelected ? 'text-shade-blue' : 'text-gray-300'} w-4 h-4`} />
                      <p className="text-sm">
                        {i + 1}. {v}
                      </p>
                    </div>
                  )
                })}
            </>
          )
        })}
      </div>
    </div>
  )
}
