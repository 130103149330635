import { useState } from 'react'
import { toast } from 'react-toastify'
import { addNewProject } from 'services'
import { Input2, Modal } from 'stories/components'

export const AddProjectModal = (props: { onClose: any }) => {
  const [loading, setLoading] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [inputError, setInputError] = useState('')

  const reset = () => {
    setProjectName('')
    setInputError('')
  }

  const onChange = (value: string) => {
    setProjectName(value)
    setInputError('')
  }

  const onSubmit = async () => {
    setInputError('Required')
    setLoading(true)
    await addNewProject(projectName)
    setLoading(false)
    toast('A new project is added', { type: 'info' })
    reset()
    props.onClose(true)
  }

  const onClose = () => {
    reset()
    props.onClose()
  }

  return (
    <Modal
      title="Add a new project"
      titleOkay="Save"
      loading={loading}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
      disabled={!projectName}
    >
      <div className="grid gap-x-3 grid-cols-1 w-96">
        <Input2
          title="Project Name"
          type="text"
          error={inputError}
          value={projectName}
          required
          onChange={(value) => onChange(value)}
        />
      </div>
    </Modal>
  )
}
