import { CheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'stories/components'

export const SubmitToLenderModal = (props: { onSubmitToLender: Function; onClose: Function }) => {
  const [isLoading, setLoading] = useState(true)
  const [isSubmitted, setSubmitted] = useState(false)

  useEffect(() => {
    onSubmitToLender()
  }, [])

  const onSubmitToLender = async () => {
    setLoading(true)
    await props.onSubmitToLender()
    setSubmitted(true)
  }

  return (
    <Modal
      title={`Submit to Lender`}
      titleOkay=""
      titleCancel={isSubmitted ? 'Close' : ''}
      isOpen
      hiddenX={!isSubmitted}
      onClose={() => props.onClose()}
    >
      {!isSubmitted ? (
        <div className="w-144">
          <p className="text-left bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded relative mb-4 text-[15px]">
            All necessary assets have been successfully verified and are now ready to be submitted to the lender.
          </p>

          <div className="flex justify-center">
            <Button onClick={onSubmitToLender} loading={isLoading}>
              Submitting Now...
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-144">
          <p className="text-left bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-[15px] flex gap-2 items-center max-w-3xl">
            <CheckIcon className="w-4 h-4" />
            All necessary assets have been successfully submitted to the Lender.
          </p>
        </div>
      )}
    </Modal>
  )
}
