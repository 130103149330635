import { CheckBadgeIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { AccountType, COMPANY_NAME_FC, companyName } from 'config'
import { fullStates } from 'config/states.constants'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { registerUser } from 'services'
import { Agreement, Button, Modal, Toggle } from 'stories/components'
import { InputConvert } from 'utils/convertor'
import { useTitle } from 'utils/pageTitle'
import { RenderInput } from 'utils/RenderInput'
import { InputValidate } from 'utils/validator'

const cloneDeep = require('clone-deep')

const registerFormInputs = [
  {
    title: 'Company information',
    inputs: {
      companyEIN: {
        inputType: 'text',
        type: 'text',
        title: 'Company EIN',
      },
      companyName: {
        inputType: 'text',
        title: 'Company Name',
        required: true,
      },
      companyNmls: {
        inputType: 'text',
        type: 'text',
        title: 'Company NMLS ID',
        required: false,
      },
      street: {
        inputType: 'text',
        title: 'Street Address',
        required: true,
      },
      city: {
        inputType: 'text',
        title: 'City',
        required: true,
      },
      state: {
        inputType: 'select',
        title: 'State',
        options: fullStates,
        hasDefaultOption: true,
        required: true,
      },
      zip: {
        inputType: 'text',
        key: 'zipCode',
        type: 'number',
        title: 'Zip',
        required: true,
      },
    },
  },
  {
    title: 'Personal Information',
    inputs: {
      title: {
        inputType: 'text',
        title: 'Principal Title',
      },
      name: {
        inputType: 'text',
        title: 'Full Name',
        required: true,
      },
      email: {
        inputType: 'text',
        type: 'email',
        title: 'Email',
        required: true,
      },
      phone: {
        inputType: 'text',
        type: 'phone',
        title: 'Phone Number',
        required: true,
      },
      phoneExt: {
        inputType: 'text',
        type: 'phone',
        title: 'Phone Ext',
        required: false,
      },
      brokerSSN: {
        inputType: 'text',
        type: 'ssn',
        title: 'Social Security Number',
        required: true,
      },
      brokerNmls: {
        inputType: 'text',
        title: 'NMLS ID',
        required: false,
      },
    },
  },
]

const defaultInputs = () => {
  return cloneDeep(registerFormInputs)
}

export function Register() {
  useTitle(`Register - ${companyName}`)
  const [isLoading, setLoading] = useState(false)
  const [inputStates, setInputStates] = useState(defaultInputs())
  const [isOpenModal, setOpenModal] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(0)
  const [isAgree, setIsAgree] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputStates)
    const data: Record<string, any> = {}

    newStats.forEach((stats: any) => {
      const newStats = stats.inputs
      for (const key in newStats) {
        const { value } = newStats[key]
        newStats[key].visible = true
        let error = InputValidate(newStats[key])
        newStats[key].error = error
        if (error.length > 0) hasError = true

        if (value !== undefined) data[key] = value
      }
    })
    if (hasError) {
      setInputStates(newStats)
      return
    }

    setLastUpdatedAt(Date.now())
    setOpenModal(true)
  }

  const onChange = (index: number, key: string, value: string) => {
    let newState = cloneDeep(inputStates)
    newState[index].inputs[key].value = InputConvert(newState[index].inputs[key], value)
    newState[index].inputs[key].error = ''
    setInputStates(newState)
  }

  const onCloseModal = () => {
    setOpenModal(false)
    setLastUpdatedAt(Date.now())

    const newStats = cloneDeep(inputStates)
    const data: Record<string, any> = {}

    newStats.forEach((stats: any) => {
      const newStats = stats.inputs
      for (const key in newStats) {
        const { value } = newStats[key]
        if (value !== undefined) data[key] = value
      }
    })
    setLoading(true)

    data.accountType = AccountType.BROKER
    data.accountExecutive = 0
    data.email = data.email.toLowerCase()

    registerUser(data)
      .then((res) => {
        if (res.success) setSubmitted(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderGroup = (group: any, index: number) => {
    const { title, inputs } = group

    return (
      <div key={title} className="block mb-5 rounded-md border border-solid border-stone-200 shadow-md p-5 pb-0">
        <p className="text-xl mb-3">{title}</p>
        {Object.keys(inputs).map((key, _index) => {
          const input = inputs[key]
          return (
            <div className="input w-80 mb-4" key={_index}>
              <RenderInput
                input={{
                  ...input,
                  history: false,
                }}
                Key={key}
                onChange={(key: string, value: any) => onChange(index, key, value)}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative bg-stone-100">
      <div className="max-w-screen-2xl m-auto lg:pt-10 md:pt-5 sm:pt-2 pb-10 pt-4 px-2 overflow-auto">
        <div className="bg-white rounded w-fit pl-6 pr-6 pt-8 pb-6 relative mx-auto">
          <LayoutLoading show={isLoading} />
          <p className=" text-stone-800 text-4xl font-bold mb-3">Register</p>
          <div className="flex mb-5">
            <p className="text-base text-stone-800 text-md mr-5">Already have an account?</p>
            <Link to="/login">
              <p className="block text-md hover:text-gray-900 hover:underline text-gray-500">Login</p>
            </Link>
          </div>

          <div className="mb-5 grid grid-cols-3 gap-4">
            <div className="h-1 bg-shade-blue w-full" />
            <div className="h-1 bg-stone-200 w-full" />
            <div className="h-1 bg-stone-200 w-full" />
          </div>

          {submitted ? (
            <div
              className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4 text-[15.5px]"
              role="alert"
            >
              <div className="flex justify-center mb-3">
                <CheckBadgeIcon className="w-10 h-10"></CheckBadgeIcon>
              </div>
              <div className="max-w-xl">
                Congratulations on joining {COMPANY_NAME_FC}! We appreciate your registration. We'll review your details
                thoroughly and update you within 24 hours!
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-wrap gap-4">
                {inputStates.map((group: any, index: number) => renderGroup(group, index))}
              </div>

              <Button className="mt-1" color="sky" full bold onClick={onSubmit} loading={isLoading}>
                Register
              </Button>
            </div>
          )}
          <Modal
            isOpen={isOpenModal}
            lastUpdatedAt={lastUpdatedAt}
            titleOkay="Agree"
            onOk={onCloseModal}
            title={'Broker Agreement'}
            disabled={!isAgree}
          >
            <div className="max-w-screen-lg">
              <Agreement />
              <div className="mt-4">
                <Toggle
                  title="I agree"
                  name="agree"
                  id="agree"
                  value={isAgree}
                  onChange={(value) => setIsAgree(value)}
                />
              </div>
            </div>
          </Modal>

          <Link to="/login">
            <p className="mt-3 hover:text-gray-900 hover:underline text-gray-500 flex items-center gap-1">
              <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
              Back to Login
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}
