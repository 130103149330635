import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { getActiveStatus, loanStatusConstants } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { filterPipeline } from 'services'
import { svgLoading } from 'stories/assets'
import { MultiSelect } from 'stories/components'
import { formatDate, thousandSeperator } from 'utils'

export const LoanPipelines = () => {
  const [isLoading, setLoading] = useState(false)
  const [loans, setLoans] = useState<Record<string, any>[]>([])
  const [conditions, setConditions] = useState<Record<number, string[]>>({})
  const [signedStatus, setSignedStatus] = useState<Record<number, boolean>>({})
  const [assetStatus, setAssetStatus] = useState<Record<number, boolean>>({})

  const [sumLoanAmount, setSumLoanAmount] = useState(0)

  const activeStatus = getActiveStatus('object')
  activeStatus['funded'] = true

  const [filters, setFilters] = useState<Record<string, any>>({
    status: activeStatus,
    orderBy: 'asOf',
    orderDir: '-1',
  })

  useEffect(() => {
    setLoading(true)
    filterLoanData().finally(() => setLoading(false))
  }, [])

  const filterLoanData = async () => {
    const { data, conditions, signedStatus, assetStatus } = await filterPipeline({})
    setLoans(data)
    setConditions(conditions)
    setSignedStatus(signedStatus)
    setAssetStatus(assetStatus)

    const sum = data.reduce((prev: number, cur: any) => prev + Number(cur.proposedLoanAmount), 0)
    setSumLoanAmount(sum)
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const renderHeader = (title: string | JSX.Element, key: string) => {
    return (
      <th scope="col" className="py-3 px-2" key={key}>
        {title}
      </th>
    )
  }

  const renderAttentions = (loanNumber: number) => {
    const attentions = []
    if (!signedStatus[loanNumber])
      attentions.push(['Sign Loan Application', `/borrowerPipeline/application/${loanNumber}`])
    if (!assetStatus[loanNumber])
      attentions.push(['Asset Verification', `/assetInformationVerification/_/${loanNumber}`])

    conditions[loanNumber] &&
      conditions[loanNumber].forEach((v) => attentions.push([v, `/borrowerPipeline/submit/${loanNumber}`]))

    return (
      <ul className="text-sm list-disc">
        {attentions.map((v) => (
          <li className="cursor-pointer hover:underline">
            <Link to={v[1]}>{v[0]}</Link>
          </li>
        ))}
      </ul>
    )
  }

  const viewStatus = (status: Array<string>) => {
    const newFilters = cloneDeep(filters)
    let value: any = {}
    status.map((key) => {
      value[key] = true
    })
    newFilters['status'] = value
    setFilters(newFilters)
  }

  const loanStatusViewAll = useMemo(() => {
    let activeCount = 0,
      archivedCount = 0

    let totalCount = Object.keys(loanStatusConstants['status']).length
    let activeStatus = getActiveStatus()
    let archivedStatus = loanStatusConstants.archivedStatus

    if (!activeStatus.includes('funded')) activeStatus.push('funded')
    if (archivedStatus.includes('funded')) archivedStatus.splice(archivedStatus.indexOf('funded'), 1)

    Object.keys(filters.status).map((key) => {
      if (filters.status[key]) {
        if (activeStatus.includes(key)) activeCount += 1
        else archivedCount += 1
      }
    })

    let value = '-'
    if (activeCount + archivedCount === 0 || activeCount + archivedCount == totalCount) value = 'all'
    else if (activeCount === activeStatus.length && archivedCount === 0) value = 'active'
    else if (archivedCount === archivedStatus.length && activeCount === 0) value = 'archived'

    return (
      <div className="flex flex-wrap items-center gap-3">
        <select
          name="plan"
          id="status-filter-group"
          className="py-0 pl-1 pr-6 text-[12.5px] leading-[16px] border-gray-300"
          value={value}
          onChange={(e) => {
            if (e.target.value === 'all') viewStatus([])
            if (e.target.value === 'active') viewStatus(activeStatus)
            if (e.target.value === 'archived') viewStatus(archivedStatus)
          }}
        >
          <option value="-" disabled>
            {' '}
          </option>
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="archived">Archived</option>
        </select>
      </div>
    )
  }, [filters.status, filters.servicing])

  const filteredLoans = useMemo(() => {
    return loans.filter((loan) => filters.status[loan.loanStatus] || Object.keys(filters.status).length == 0)
  }, [filters, loans])

  return (
    <div className="mt-8">
      <div
        className={`relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full`}
      >
        <LayoutLoading show={isLoading} />
        <div className="flex flex-wrap gap-4 justify-between items-center mb-5">
          <h1 className="text-2xl font-bold flex items-center mb-3">
            <span>Loan Pipeline</span>{' '}
            <span className="text-base ml-3">
              {isLoading ? (
                <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
              ) : (
                `(${loans.length})`
              )}
            </span>
          </h1>
        </div>
        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
          <MultiSelect
            id="loan-status"
            title="Loan Status"
            options={filters.servicing ? loanStatusConstants.servicingStatus : loanStatusConstants.status}
            value={filters.status}
            hasDefaultOption
            defaultOptionText="All"
            defaultSelected={false}
            onChange={(value) => onChangeFilter('status', value)}
            additionalElements={loanStatusViewAll}
          />
        </div>
        <div className="mt-2">
          <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {renderHeader('Loan #', 'no')}
                  <th scope="col" className="py-2 px-2">
                    <span>Property Address</span>
                  </th>
                  <th scope="col" className="py-2 px-2">
                    <span>Purpose</span>
                  </th>
                  <th scope="col" className="py-2 px-2">
                    <span>Product</span>
                  </th>
                  {renderHeader('Status', 'loanStatus')}
                  {renderHeader('As Of', 'asOf')}
                  {renderHeader('Loan Amount', 'proposedLoanAmount')}
                  {renderHeader('Interest Rate', 'interestRate')}
                  {renderHeader('Items that Require Attention', 'attentions')}
                </tr>
              </thead>
              <tbody className="text-[14px] text-gray-900">
                {filteredLoans.map((item: any, index: number) => {
                  return (
                    <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'}`}>
                      <td className="pl-3">
                        <Link
                          to={`/borrowerPipeline/overview/${item.no}`}
                          className="font-bold text-shade-blue hover:underline cursor-pointer"
                        >
                          <span className="flex-col flex min-h-[60px] justify-center items-center">
                            <div>{item.byteproFileName || item.no}</div>
                            {item.rush && <div className="text-red-700 italic">Rush</div>}
                          </span>
                        </Link>
                      </td>
                      <td className="px-2 py-2 min-w-[170px]">
                        <span>{item.subjectPropertyAddress}</span>
                      </td>
                      <td className="px-2 py-2 min-w-[170px]">
                        <span>{item.transactionType}</span>
                      </td>
                      <td className="px-2 py-2 min-w-[170px]">
                        <span>{item.productType}</span>
                      </td>
                      <td className="px-2 py-2">{loanStatusConstants.status[item.loanStatus]}</td>
                      <td className="px-2 py-2 w-24">{formatDate(item.asOf)}</td>
                      <td className="px-2 py-2">{thousandSeperator(item.proposedLoanAmount)}</td>
                      <td className="px-2 py-2">{item.rate}</td>
                      <td className="px-2 py-2">{renderAttentions(item.no)}</td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr className="bg-slate-50">
                  <td colSpan={6} className="p-2 text-center font-bold">
                    Total Loan Amount:
                  </td>
                  <td colSpan={1} className="p-2 font-bold">
                    ${thousandSeperator(sumLoanAmount)}
                  </td>
                  <td colSpan={2}></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
