import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const validateForm = ({
  inputs,
  setInputs,
  allValues,
}: {
  inputs: Record<string, InputType>
  setInputs: Function
  allValues: Record<string, any>
}) => {
  let hasError = false
  let newInputs = cloneDeep(inputs)
  const data: Record<string, any> = {}
  for (const key in inputs) {
    const input = newInputs[key]
    if (
      input.visible === false ||
      (input.visible && typeof input.visible == 'function' && !input.visible(inputs, allValues))
    )
      continue

    if (input.value && input.value.trim) input.value = input.value.trim()
    input.error = InputValidate(input)
    data[key] = input.value
    if (input.error) hasError = true
    if (input.type == 'thousandSep') data[key] = removeComma(data[key])
  }

  if (hasError) setInputs(newInputs)

  return {
    hasError,
    data,
    newInputs,
  }
}

export const renderInputs = ({
  inputs,
  allValues,
  onChange,
}: {
  inputs: Record<string, InputType>
  allValues: Record<string, any>
  onChange: Function
}) => {
  return (
    <div>
      {Object.keys(inputs).map((key) => {
        const input = inputs[key]
        if (
          input.visible === false ||
          (input.visible && typeof input.visible == 'function' && !input.visible(inputs, allValues))
        )
          return null

        return (
          <div className="mb-4">
            <RenderInput input={input} Key={key} onChange={onChange} />
          </div>
        )
      })}
    </div>
  )
}
