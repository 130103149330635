import { type InputType, transactionTypeOptions } from 'config'

export const ProductTypeOptions: Record<string, Record<string, string>> = {
  Purchase: {
    'Fix and Flip': 'Purchase a property to rehab.',
    'Ground Up Construction': 'Purchase a piece of land to build new construction.',
    'DSCR (Long Term Rental)': 'Purchase an existing property for immediate cashflow.',
    Bridge: 'Purchase an existing property that Im going to hold until I am ready for permanent financing.',
  },
  Refinance: {
    'Fix and Flip': 'Refinance a property to rehab.',
    'Ground Up Construction': 'Refinance a piece of land to build new construction.',
    'DSCR (Long Term Rental)': 'Refinance an existing property for cashflow.',
    Bridge: 'Refinance an existing property that I do not plan to hold long term.',
  },
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    transactionType: {
      inputType: 'OptionSelect',
      options: transactionTypeOptions,
      title: 'Transaction Type',
    },

    productType: {
      inputType: 'OptionSelect',
      options: {},
      title: 'I want to',
    },

    processType: {
      inputType: 'OptionSelect',
      options: [
        'Looking for General Info',
        'Actively Looking For Property',
        'Identified a Property',
        'Have Property Under Contract',
        'I Own The Property/Land',
      ],
      title: 'Where are you in the process?',
    },

    personalInformation: {
      inputType: 'custom',
      title: '',
      value: {},
    },

    investmentInformation: {
      inputType: 'custom',
      title: '',
      value: {},
    },

    loanOptions: {
      inputType: 'custom',
      title: '',
      value: {},
    },

    lastQuestions: {
      inputType: 'custom',
      title: '',
      value: {},
    },
  }
}
