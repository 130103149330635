import cloneDeep from 'clone-deep'
import { loanOptionsConstants } from 'config'
import type { IRange } from 'pages/Resources'
import { useEffect, useMemo, useState } from 'react'
import { Modal } from 'stories/components'
import { isEmpty, removeComma } from 'utils'

import { overrideValidationKeyOptions } from '../constant'
import type { ILLPA, ILoan, ILockTermPrice, IPriceAdjustment } from '../interfaces'
import { isNonDSCRProduct } from '../Logic'

interface Prop {
  isOpen: boolean
  LLPA: Record<string, ILLPA>
  CalcHistory: string[]
  Price: ILockTermPrice
  LTV: IRange
  Rate: number
  BaseRate: number
  Loan: ILoan
  Margin: number
  priceAdjustments: IPriceAdjustment[]
  RelockTimes?: number
  type: string | undefined
  isRelockRate?: boolean
  onClose: () => void
}

export function CalculatedDialog(props: Prop) {
  const {
    isOpen,
    LLPA,
    Price,
    LTV,
    CalcHistory,
    Rate,
    BaseRate,
    Loan,
    Margin,
    RelockTimes = 0,
    priceAdjustments,
    type,
    isRelockRate = false,
    onClose,
  } = props

  const [llpaSum, setLLPASum] = useState(0)
  const [history, setHistory] = useState<string[]>([])

  const adjustmentObject = useMemo(() => {
    let rlt = {
      rate: 0,
      price: 0,
    }
    try {
      priceAdjustments.map((item: any) => {
        rlt.rate += removeComma(item.rate)
        rlt.price += removeComma(item.price)
      })
    } catch {}
    return rlt
  }, [priceAdjustments])

  const relockTimesValue = useMemo(() => {
    return isRelockRate ? RelockTimes : RelockTimes === 0 ? RelockTimes : RelockTimes - 1
  }, [RelockTimes, isRelockRate])

  useEffect(() => {
    const newHistory = cloneDeep(CalcHistory)

    if (!isNonDSCRProduct(Loan.productType)) {
      if (!!Price?.IsPPPMaxPrice) {
        newHistory.push(
          `Added Max Price from Prepayment Penalty Price Restriction: Prepayment Penalty = ${
            (loanOptionsConstants.prepaymentLong as any)[Loan.prepaymentPenalty]
          } => Max Price = ${Price.Price}`,
        )
      }
    }

    setHistory(newHistory)
  }, [CalcHistory, Loan, Price])

  useEffect(() => {
    let sum = Number(Price.LockTermLLPA)
    Object.keys(LLPA).forEach((key) => {
      sum += Number(LLPA[key].value)
    })
    setLLPASum(sum)
  }, [LLPA, Price])

  const getPrice = (item: ILockTermPrice) => {
    return Number((Number(item.Price) + Number(adjustmentObject.price) - Number(relockTimesValue) * 0.125).toFixed(4))
  }

  return (
    <>
      <Modal
        button={<span></span>}
        title={'Calculation Details'}
        titleOkay=""
        titleCancel="Close"
        init={false}
        isOpen={isOpen}
        lastUpdatedAt={Date.now()}
        onClose={onClose}
        childLevel={1}
      >
        {isOpen ? (
          <div className="text-gray-900">
            {type === 'lock' && <div className="mb-4 ml-4 text-[14px]">Locked Ratesheet</div>}

            <table className="w-full table-auto text-center text-[14px]">
              <tbody className="">
                <tr className="border text-shade-blue font-variation-settings-600">
                  <td className="border p-1">Final Rate: {(removeComma(Rate) + adjustmentObject.rate).toFixed(3)}</td>
                  <td className="border">Final Price/Pts: {getPrice(Price)}</td>
                </tr>
                <tr className="border">
                  <td className="border p-1">
                    Original Rate:
                    {BaseRate.toFixed(3)}
                  </td>
                  <td className="border">Original: {Price.BasePrice}</td>
                </tr>
                {false && (
                  <tr className="border">
                    <td className="border p-1">Margin</td>
                    <td className="border">{Margin}</td>
                  </tr>
                )}
                {relockTimesValue ? (
                  <tr className="border">
                    <td className="border p-1">ReLocks</td>
                    <td className="border">-{(0.125 * relockTimesValue).toFixed(3)}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>

            {Object.keys(LLPA).length > 0 && (
              <>
                <div className="mt-3 font-variation-settings-600">- LLPA Price Adjustment</div>
                <table className="w-full table-auto text-center text-[14px]">
                  <thead>
                    <tr className="border">
                      <th className="border text-gray-700 bg-gray-50 p-1">Name</th>
                      <th className="border text-gray-700 bg-gray-50">Description</th>
                      <th className="border text-gray-700 bg-gray-50">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(LLPA).map((key) => {
                      if (LLPA[key].isEnabled) {
                        return (
                          <tr key={key} className="border">
                            <td className="border p-1">
                              {LLPA[key].name}
                              {LLPA[key].label ? ': ' + LLPA[key].label : ''}
                              (LLPA)
                            </td>
                            <td className="border p-1">
                              {LLPA[key].from !== undefined
                                ? `${LLPA[key].name}:  ${key === 'LoanAmount' ? '$' : ''}${
                                    !isEmpty(LLPA[key].from) ? LLPA[key].from : '0'
                                  } - ${key === 'LoanAmount' ? '$' : ''}${!isEmpty(LLPA[key].to) ? LLPA[key].to : ''}, `
                                : ''}
                              LTV: {LTV.from} - {LTV.to}
                            </td>
                            <td>{LLPA[key].value}</td>
                          </tr>
                        )
                      }
                    })}
                    {!!Price.LockTermLLPA && (
                      <tr className="border">
                        <td className="border p-1">Lock Term - {Price.LockDay}(LLPA)</td>
                        <td className="border">
                          LTV: {LTV.from} - {LTV.to}
                        </td>
                        <td className="border">{Price.LockTermLLPA}</td>
                      </tr>
                    )}
                    <tr className="border">
                      <th colSpan={2} scope="colgroup" className="border text-gray-700 bg-gray-50 p-1">
                        Total
                      </th>
                      <th className="border text-gray-700 bg-gray-50">{llpaSum.toFixed(3)}</th>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            {priceAdjustments.length > 0 && (
              <div className="mt-4">
                <div className="mt-4 font-variation-settings-600">- Exception Rate & Price Adjustments</div>
                <table className="w-full table-auto text-center text-[14px]">
                  <thead>
                    <tr className="border">
                      <th className="border text-gray-700 bg-gray-50 p-1">Type</th>
                      <th className="border text-gray-700 bg-gray-50">Description</th>
                      <th className="border text-gray-700 bg-gray-50">Rate</th>
                      <th className="border text-gray-700 bg-gray-50">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {priceAdjustments.map((item, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="border">{overrideValidationKeyOptions[item.type]}</td>
                          <td className="border">{item.note}</td>
                          <td className="border">{item.rate}</td>
                          <td className="border">{item.price}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {history.length > 0 && (
              <>
                <div className="mt-4 font-variation-settings-600">- Calculation History</div>
                <table className="w-full table-auto text-center text-[14px]">
                  <tbody>
                    {history.map((item, index) => {
                      return (
                        <tr key={index} className="border">
                          <td className="text-left p-2">{item}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        ) : (
          <span></span>
        )}
      </Modal>
    </>
  )
}
