import { setBorrowerGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { borrowerInfoUpdate } from 'services'
import { canEditLoanApplication, InputConvert, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import { declarationsHmdaValidate } from './logic'

export function DeclarationsHmda(props: any) {
  const { borrowerSeperator } = props
  const [inputs, setInputs] = useState(defaultInputs().inputs)
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const { group1, group2 } = defaultInputs()

  const dispatch = useDispatch()
  const { borrower } = useSelector((state: any) => {
    return {
      borrower: state.borrower,
    }
  })

  useEffect(() => {
    const nInputs = declarationsHmdaValidate(borrowerSeperator, false, false)
    if (nInputs !== true) setInputs(nInputs)
  }, [borrowerSeperator])

  const onChange = async (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    setFieldValueChanged(true)
    value = InputConvert(newInputs[key], value)
    await dispatch(setBorrowerGroupData(borrowerSeperator, { [key]: value }))

    const nInputs = declarationsHmdaValidate(borrowerSeperator, false, true)
    if (nInputs !== true) setInputs(nInputs)
  }

  const onBlur = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = inputs[key]
    if (input.error && input.error.length > 0) return
    props.setLoading(true)
    const reqBody = {
      [key]: borrower[borrowerSeperator][key],
      borrowerSeperator,
    }
    await borrowerInfoUpdate(reqBody)
    props.setLoading(false)
  }

  const showHistory = async (key: string) => {
    const options = {
      table: 'Borrower',
      field: `${borrowerSeperator}-${key}`,
      keys: {
        field: key,
        borrowerSeperator,
      },
    }
    openAuditLog(options)
  }

  const canEdit = canEditLoanApplication()

  let groupIndex = 0

  return (
    <div>
      <p className="text-md font-bold border-b mb-2">Declarations</p>
      <div className="grid grid-cols-1 mb-3">
        {group1.map((key, index) => {
          const input = inputs[key]
          if (borrower[borrowerSeperator][key] !== undefined) {
            input.value = borrower[borrowerSeperator][key]
          } else {
            input.value = ''
          }
          input.history = true
          input.disabled = input.disabled || !canEdit
          if (!input.className && input.inputType != 'Section') groupIndex += 1
          return (
            <div
              className={`input md:col-span-1 rounded pl-3 py-2 pr-2 ${input.className} ${
                input.inputType != 'Section' && groupIndex % 2 == 1 && 'bg-slate-100'
              }`}
              key={index}
            >
              <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} onBlur={onBlur} />
            </div>
          )
        })}
      </div>

      <p className="text-md font-bold border-b mb-2">HMDA</p>
      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 list-decimal mb-3">
        {group2.map((key, index) => {
          const input = inputs[key]
          if (borrower[borrowerSeperator][key] !== undefined) {
            input.value = borrower[borrowerSeperator][key]
          } else {
            input.value = ''
          }
          input.history = true
          input.disabled = input.disabled || !canEdit
          return (
            <div className={`input md:col-span-${input.span}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} onBlur={onBlur} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
