import cloneDeep from 'clone-deep'
import { type InputType, SecPerDay } from 'config'
import { useState } from 'react'
import { Modal } from 'stories/components'
import { formatDateYMD, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const defaultInputs = (): Record<string, InputType> => {
  return {
    all: {
      inputType: 'checkbox',
      title: 'Download All',
      value: true,
      span: 2,
    },
    from: {
      inputType: 'text',
      type: 'date',
      title: 'From',
      value: formatDateYMD(Date.now() - 3 * 30 * SecPerDay),
      required: true,
      disabled: true,
    },
    to: {
      inputType: 'text',
      type: 'date',
      title: 'To',
      value: formatDateYMD(Date.now() + SecPerDay),
      required: true,
      disabled: true,
    },
  }
}

export const DownloadRangeModal = ({
  title,
  onDownload,
  onClose,
}: {
  title: string
  onDownload: Function
  onClose: Function
}) => {
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    if (key == 'all') {
      newInputs.from.disabled = value
      newInputs.to.disabled = value
    }
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    onDownload(data.all ? { from: '', to: '' } : data)
  }

  return (
    <Modal
      title={title}
      titleOkay="Download"
      onClose={() => onClose()}
      onOk={onSubmit}
      isOpen
      disabled={!inputs.from.value || !inputs.to.value}
    >
      <div className="text-gray-900">
        <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
