import type { InputType } from 'config'

export const AmtTotalFields = [
  'NYReimburseBorrowerAmt1',
  'NYReimburseBorrowerAmt2',
  'NYReimburseBorrowerAmt3',
  'NYReimburseBorrowerAmt4',
]

export const NYIndirectTotalFields = [
  'BondPremium',
  'InsurancePremiums',
  'FeesofArchitects',
  'EngineersAndSurveyors',
  'GroundRents',
  'Taxes',
  'Assessments',
  'Water',
  'SewerRent',
]

export const inputGroups: Record<string, string[]> = {
  'Lien Law Affidavit': [
    'Divider1',
    'NYReimburseBorrowerDesc1',
    'NYReimburseBorrowerAmt1',
    'NYReimburseBorrowerDesc2',
    'NYReimburseBorrowerAmt2',
    'NYReimburseBorrowerDesc3',
    'NYReimburseBorrowerAmt3',
    'NYReimburseBorrowerDesc4',
    'NYReimburseBorrowerAmt4',
    'NYReimburseBorrowerAmtTotal',
    'Divider2',
    'BondPremium',
    'InsurancePremiums',
    'FeesofArchitects',
    'EngineersAndSurveyors',
    'GroundRents',
    'Taxes',
    'Assessments',
    'Water',
    'SewerRent',
    'IndirectTotal',
    'Divider4',
    'AdvRepayment',
  ],
  'Mortgagor Statement & Notice of Lending': [
    'MortgagorStatementOneTwoFamily',
    'MortgagorStatementSeperateCooking',
    'MortgagorStatementNone',
    'NoticeofLending',
    'AdvancesMade',
    'AdvancesDate',
    'Divider3',
    'AdvanceMaxBalance',
  ],
  'CEMA Transactions Only': ['CEMATransaction', 'CEMACurrentLender', 'CEMAUnpaidBalance'],
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    MortgagorStatementOneTwoFamily: {
      inputType: 'checkbox',
      title: 'This Mortgage covers real property improved, or to be improved, by a one or two family dwelling only.',
      value: false,
      span: 6,
    },
    MortgagorStatementSeperateCooking: {
      inputType: 'checkbox',
      title:
        'This Mortgage covers real property principally improved, or to be improved, by one or more structures containing, in the aggregate, not more than six residential dwelling units with each dwelling unit having its own separate cooking facilities.',
      value: false,
      span: 6,
    },
    MortgagorStatementNone: {
      inputType: 'checkbox',
      title: 'This Mortgage does not cover real property improved as described above.',
      value: false,
      span: 6,
    },
    NoticeofLending: {
      inputType: 'select',
      options: [
        'Ground Up construction of a new Single family Residence',
        'Ground Up construction of a new 2-4 Unit Building',
        'Ground Up construction of a new 5+ Unit Building',
        'Ground Up construction of a new Commercial Building',
        'Rehab construction for the subject property',
      ],
      title: 'Notice of Lending',
      hasDefaultOption: true,
      span: 6,
      value: '',
    },
    AdvancesMade: {
      inputType: 'select',
      options: ['Owner', 'Contractor', 'Subcontractor'],
      title: 'Advances to be Made to',
      hasDefaultOption: true,
      span: 3,
      value: '',
    },
    AdvancesDate: {
      inputType: 'text',
      type: 'date',
      title: 'Date of Advance',
      span: 3,
      value: '',
    },
    Divider1: {
      inputType: 'section',
      title:
        'Amount to be advanced from Loan to reimburse the Borrower for costs of the Improvements prior to loan consummation',
      span: 6,
    },
    NYReimburseBorrowerDesc1: {
      inputType: 'text',
      type: 'text',
      title: 'Improvement/Cost Description',
      value: '',
      error: '',
      span: 3,
    },
    NYReimburseBorrowerAmt1: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount',
      value: '',
      error: '',
      span: 3,
      prefix: '$',
    },
    NYReimburseBorrowerDesc2: {
      inputType: 'text',
      type: 'text',
      title: 'Improvement/Cost Description',
      value: '',
      error: '',
      span: 3,
    },
    NYReimburseBorrowerAmt2: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount',
      value: '',
      error: '',
      span: 3,
      prefix: '$',
    },
    NYReimburseBorrowerDesc3: {
      inputType: 'text',
      type: 'text',
      title: 'Improvement/Cost Description',
      value: '',
      error: '',
      span: 3,
    },
    NYReimburseBorrowerAmt3: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount',
      value: '',
      error: '',
      span: 3,
      prefix: '$',
    },
    NYReimburseBorrowerDesc4: {
      inputType: 'text',
      type: 'text',
      title: 'Improvement/Cost Description',
      value: '',
      error: '',
      span: 3,
    },
    NYReimburseBorrowerAmt4: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Amount',
      value: '',
      error: '',
      span: 3,
      prefix: '$',
    },
    NYReimburseBorrowerAmtTotal: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Total to Reimburse Borrower',
      value: '',
      span: 3,
      disabled: true,
      prefix: '$',
    },
    Divider2: {
      inputType: 'section',
      title:
        'Amount from the Loan for indirect costs of the Improvements which may become due and payable after the date hereof and during the construction of the Improvements',
      span: 6,
    },
    BondPremium: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Bond Premium',
      value: '',
      span: 3,
      prefix: '$',
    },
    InsurancePremiums: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Insurance Premiums',
      value: '',
      span: 3,
      prefix: '$',
    },
    FeesofArchitects: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Fees of Architects',
      value: '',
      span: 3,
      prefix: '$',
    },
    EngineersAndSurveyors: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Engineers and Surveyors',
      value: '',
      span: 3,
      prefix: '$',
    },
    GroundRents: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Ground Rents',
      value: '',
      span: 3,
      prefix: '$',
    },
    Taxes: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Taxes',
      value: '',
      span: 3,
      prefix: '$',
    },
    Assessments: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Assessments',
      value: '',
      span: 3,
      prefix: '$',
    },
    Water: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Water',
      value: '',
      span: 3,
      prefix: '$',
    },
    SewerRent: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Sewer Rent',
      value: '',
      span: 3,
      prefix: '$',
    },
    IndirectTotal: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Total',
      value: '',
      disabled: true,
      span: 3,
      prefix: '$',
    },
    Divider3: {
      inputType: 'section',
      title:
        'Amount to be advanced from Loan to repay amounts previously advanced pursuant to Notices of Lending for costs of the Improvements',
      span: 6,
    },
    AdvanceMaxBalance: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Max Balance',
      value: '',
      error: '',
      prefix: '$',
      span: 3,
    },
    Divider4: {
      inputType: 'section',
      title:
        'The amount, if any, to be advanced from the Loan to repay amounts previously advanced to the Borrower pursuant to Notices of Lending for costs of the Improvements is',
      span: 6,
    },
    AdvRepayment: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Advance for Repayment',
      value: '',
      error: '',
      prefix: '$',
      span: 3,
    },
    CEMATransaction: {
      title: 'CEMA Transaction?',
      inputType: 'check',
      error: '',
      span: 6,
    },
    CEMACurrentLender: {
      inputType: 'text',
      title: 'Current Lender',
      value: '',
      error: '',
      span: 3,
      disabled: true,
    },
    CEMAUnpaidBalance: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Current Unpaid Principal Balance',
      value: '',
      error: '',
      prefix: '$',
      span: 3,
      disabled: true,
    },
  }
}
