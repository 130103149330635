import { Button, DocumentFile, InputFileTable } from 'stories/components'
import { setLoanNumber } from 'utils/setLoanNumber'

export const NewDocumentsTable = ({
  files,
  setFiles,
  onSubmit,
  onCancel,
}: {
  files: DocumentFile[]
  setFiles: Function
  onSubmit: Function
  onCancel: Function
}) => {
  const loanNumber = setLoanNumber()

  if (!files.length) return null

  return (
    <div className="p-2">
      <InputFileTable
        filePath={`loan/${loanNumber}/document`}
        onChange={(files) => setFiles(files)}
        value={files}
        title="New Files"
        defaultCategory=""
        narrow
      />

      <div className="flex justify-center mt-2">
        <Button loading={false} color="blue" className="py-[4px]" size={'[13px]'} onClick={() => onSubmit(files)}>
          Add Documents
        </Button>
        <Button loading={false} color="gray" className="py-[4px]" size={'[13px]'} onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
