// import React from 'react'

import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { getInitialLoanFields, groupedFields } from 'config'
import { getDefaultLoanProcessData } from 'pages/LoanStructure/constant'
import type { ILoan, ILoanDetail, ILoanProcess, ILoanRateSheet, IPriceLimit } from 'pages/LoanStructure/interfaces'
import { LoanDetailInputs } from 'pages/LoanStructure/LoanDetailInputs'
import { LoanProcessTable } from 'pages/LoanStructure/LoanResult/LoanProcessTable'
import { MaxLoanAmount } from 'pages/LoanStructure/LoanResult/MaxLoanAmount'
import {
  calcInitialLoanAmount,
  calculateLoanLimits,
  getMaximumTotalLoanAmountTip,
  isNonDSCRProduct,
  loanFieldsValidateAll,
  showLimitSection,
  visibleLoansLogic,
} from 'pages/LoanStructure/Logic'
import { useEffect, useMemo, useState } from 'react'
import { getLatestLoanRatesheet, getLoanProcessRate } from 'services'

interface IProps {
  loanNumber: number
  loan: ILoan
  loanDetail: ILoanDetail
}

export const BorrowerLoanDetail = (props: IProps) => {
  const { loan, loanDetail, loanNumber } = props

  const pricingEngineIsOff = !loanDetail.pricingEngine
  const priceDisplay = loanDetail.loanGlobalSettings?.priceDisplay || 'Price'

  const [showingFields, setShowingFields] = useState(groupedFields)
  const [visibleFields, setVisibleFields] = useState(['productType', 'transactionType'])
  const [loanFields, setLoanFields] = useState(getInitialLoanFields())
  const [loanProcessData, setLoanProcessData] = useState<ILoanProcess>(getDefaultLoanProcessData())
  const [isLoading, setLoading] = useState(false)
  const [ltvLimit, setLtvLimit] = useState<IPriceLimit | undefined>()

  const maximumTotalLoanAmountTip = useMemo(() => {
    return getMaximumTotalLoanAmountTip(loan, ltvLimit)
  }, [loan, ltvLimit])

  const isDscrProduct = useMemo(() => {
    return !isNonDSCRProduct(loan.productType)
  }, [loan])

  const exceptionApproved = useMemo(() => {
    return loanProcessData?.sheetDate?.includes('(E)') && loanProcessData?.rate > 0
  }, [loanProcessData])

  const priceAdjustmentObject = useMemo(() => {
    let rlt: any = {}
    loanDetail.priceAdjustments.map((item: any) => {
      rlt[item.type] = item
    })
    return rlt
  }, [loanDetail.priceAdjustments])

  useEffect(() => {
    const tempVisibleFields = cloneDeep(visibleLoansLogic())

    setVisibleFields(tempVisibleFields)
    setLoanFields(loanFieldsValidateAll(loanFields))
    ;(async () => {
      try {
        setLoading(true)

        const [loanProcessData, loanRatesheetData] = await Promise.all([getLoanProcessRate(), getLatestLoanRatesheet()])

        if (loanRatesheetData.success) {
          const ratesheetData = loanRatesheetData.data.data
          setLoanResultFeed(loanRatesheetData.data, true)

          if (!ratesheetData?.limit) {
            onGetPriceLimit()
          }
        } else {
          onGetPriceLimit()
        }

        if (loanProcessData.success) setLoanProcessData(loanProcessData.data)

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    })()
  }, [loan])

  useEffect(() => {
    const flag: { [key: string]: boolean } = {}
    visibleFields.map((key) => (flag[key] = true))

    const nShowedFields: string[][] = []

    let isEnd = false

    groupedFields.forEach((fields) => {
      if (!isEnd) {
        const newFields = fields.filter((key) => flag[key] === true)

        if (newFields.length > 0) {
          if (newFields.includes('ruralProperty')) {
            const targetIndex = newFields.findIndex((item) => item === 'ruralProperty')
            nShowedFields.push(newFields.slice(0, targetIndex + 1))
            isEnd = true
          } else nShowedFields.push(newFields)
        }
      }
    })

    setShowingFields(nShowedFields)
  }, [visibleFields])

  const onGetPriceLimit = async () => {
    return new Promise(
      async (
        resolve,
        // reject
      ) => {
        if (showLimitSection()) {
          setLtvLimit(calculateLoanLimits(loan))
          resolve(true)
        } else {
          setLtvLimit(undefined)
          resolve(true)
        }
      },
    )
  }

  const setLoanResultFeed = (data: ILoanRateSheet, withCurrentLTV = false) => {
    if (!data) return
    const ratesheetData = data.data

    if (withCurrentLTV) {
      const curLTVs = calculateLoanLimits(loan)
      setLtvLimit({
        ...ratesheetData.limit,
        aiv_ltv: curLTVs.aiv_ltv,
        arv_ltv: curLTVs.arv_ltv,
        ltc: curLTVs.ltc,
        ltp: curLTVs.ltp,
      })
    } else {
      setLtvLimit(ratesheetData.limit)
    }
  }

  return (
    <div>
      <LayoutLoading show={isLoading} />

      {ltvLimit && showLimitSection() && (
        <div className="mt-4 mb-2">
          <MaxLoanAmount
            initialLoanAmount={`${calcInitialLoanAmount(loan)}`}
            maximumTotalLoanAmountTip={maximumTotalLoanAmountTip}
            limit={ltvLimit}
            pricingEngineIsOff={pricingEngineIsOff}
            loanAmount={loan.proposedLoanAmount}
          />
        </div>
      )}

      <LoanProcessTable
        loan={loan}
        priceDisplay={priceDisplay}
        loanProcessData={loanProcessData}
        rePricing={false}
        canPriceAdjust={false}
        isBorrower={true}
        isDscrProduct={isDscrProduct}
        priceIndex={{ index: -1, index1: -1 }}
        pricingEngineIsOff={pricingEngineIsOff}
        selectedProgram={[]}
        onClearException={() => {}}
        onClearPrice={() => {}}
        showCalculated={() => {}}
      />

      <LoanDetailInputs
        loan={loan}
        loanDetail={loanDetail}
        loanFields={loanFields}
        loan_number={loanNumber}
        loanProcessData={loanProcessData}
        showingFields={showingFields}
        visibleFields={visibleFields}
        isAdmin={false}
        canEditLoanDetail={false}
        anytimeCanEditLoanDetail={false}
        canPriceAdjust={false}
        isBorrower={true}
        exceptionApproved={exceptionApproved}
        changedLoanDetailJson={{}}
        brokerCanNotEditDetail={true}
        fillClosingAmountMax={() => {}}
        fillLoanAmountMax={() => {}}
        maximumTotalLoanAmountTip={maximumTotalLoanAmountTip}
        onBlur={async () => {}}
        onChangeLoanInputFields={async () => {}}
        originLoanDetail={undefined}
        priceAdjustmentObject={priceAdjustmentObject}
        sandBoxMode={false}
      />
    </div>
  )
}
