import cloneDeep from 'clone-deep'
import { finresiMapConstants, loanOptionsConstants } from 'config'
import type { IDscrLeverageAdjustments, IRestriction } from 'pages/Resources'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import { foreclosuresOptions } from '../../Commercial/LeverageAdjustments'
import { DscrRangeAdjustment } from './DscrRangeAdjustment'
import { DscrValueAdjustment } from './DscrValueAdjustment'

const initialMenus = {
  occupancy: 'Occupancy',
  loanAmount: 'Loan Amount',
  propertyType: 'Property Type',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  isPropertyInLeasableState: 'Can property be rented as is? (2)',
  isShortTermRental: 'Is the property a short term rental? (2)',
  decliningMarketProperty: 'Property in a declining market? (2)',
  newConstructionProperty: 'Is the property a new construction? (2)',
}

export const DscrLeverageAdjustments = ({ data }: { data: IRestriction }) => {
  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')

  useEffect(() => {
    if (!data) return

    const {
      OccupancyLeverageAdjustment,
      PropertyTypeLeverageAdjustment,
      MortgageLatesLeverageAdjustment,
      FcSsDilLeverageAdjustment,
      BankruptcyLeverageAdjustment,
      ForbearanceLeverageAdjustment,
      LoanAmountLeverageAdjustment,
    } = data.LeverageAdjustments as IDscrLeverageAdjustments

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'occupancy':
          if (!!OccupancyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${OccupancyLeverageAdjustment.length})`
          }
          break
        case 'loanAmount':
          if (!!LoanAmountLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${LoanAmountLeverageAdjustment.length})`
          }
          break
        case 'propertyType':
          if (!!PropertyTypeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${PropertyTypeLeverageAdjustment.length})`
          }
          break
        case 'mortgageLates':
          if (!!MortgageLatesLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${MortgageLatesLeverageAdjustment.length})`
          }
          break
        case 'fcSsDil':
          if (!!FcSsDilLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${FcSsDilLeverageAdjustment.length})`
          }
          break
        case 'bankruptcy':
          if (!!BankruptcyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${BankruptcyLeverageAdjustment.length})`
          }
          break
        case 'forbearance':
          if (!!ForbearanceLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${ForbearanceLeverageAdjustment.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [data])

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.occupancy:
        return (
          <DscrValueAdjustment title="Occupancy" data={data?.LeverageAdjustments?.OccupancyLeverageAdjustment || []} />
        )
      case menus.loanAmount:
        return (
          <DscrRangeAdjustment
            title="Loan Amount"
            data={data?.LeverageAdjustments?.LoanAmountLeverageAdjustment || []}
          />
        )
      case menus.propertyType:
        return (
          <DscrValueAdjustment
            title="Property Type"
            data={data?.LeverageAdjustments?.PropertyTypeLeverageAdjustment || []}
          />
        )
      case menus.mortgageLates:
        return (
          <DscrValueAdjustment
            title="Mortgage Lates"
            options={finresiMapConstants.mortgageLates}
            data={data?.LeverageAdjustments?.MortgageLatesLeverageAdjustment || []}
          />
        )
      case menus.fcSsDil:
        return (
          <DscrValueAdjustment
            title="FC/SS/DIL"
            options={foreclosuresOptions}
            data={data?.LeverageAdjustments?.FcSsDilLeverageAdjustment || []}
          />
        )
      case menus.bankruptcy:
        return (
          <DscrValueAdjustment
            title="Bankruptcy"
            options={finresiMapConstants.bankruptcy}
            data={data?.LeverageAdjustments?.BankruptcyLeverageAdjustment || []}
          />
        )
      case menus.forbearance:
        return (
          <DscrValueAdjustment
            title="Forbearance / Loan Modification"
            options={loanOptionsConstants.forbearance}
            data={data?.LeverageAdjustments?.ForbearanceLeverageAdjustment || []}
          />
        )
      case menus.isPropertyInLeasableState:
        return (
          <DscrValueAdjustment
            title="Can property be rented as is?"
            data={data?.LeverageAdjustments?.IsPropertyInLeasableStateLeverageAdjustment || []}
          />
        )
      case menus.isShortTermRental:
        return (
          <DscrValueAdjustment
            title="Is the property a short term rental?"
            data={data?.LeverageAdjustments?.IsShortTermRentalLeverageAdjustment || []}
          />
        )
      case menus.decliningMarketProperty:
        return (
          <DscrValueAdjustment
            title="Property in a declining market?"
            data={data?.LeverageAdjustments?.DecliningMarketPropertyLeverageAdjustment || []}
          />
        )
      case menus.newConstructionProperty:
        return (
          <DscrValueAdjustment
            title="Is the property a new construction?"
            data={data?.LeverageAdjustments?.NewConstructionPropertyLeverageAdjustment || []}
          />
        )
      default:
        return <></>
    }
  }, [selectedMenu, data])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div className="h-full">
      {renderMenus}

      <div className="p-2 h-full">{renderContent}</div>
    </div>
  )
}
