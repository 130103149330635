import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { finresiMapConstants } from 'config'
import { Fragment, useMemo } from 'react'
import { ToggleButton } from 'stories/components'
import { getPrice3decimal } from 'utils'

import type { IFirstTimeInvestorLeverageData } from '../../interface'
import { foreclosuresOptions } from '../LeverageAdjustments'
import { FirstTimeInvestorValueLeverageLimit } from './FirstTimeInvestorValueLeverageLimit'

export const FirstTimeInvestorLeverageLimit = ({ data }: { data: IFirstTimeInvestorLeverageData[] }) => {
  const renderLtvMaxLimits = useMemo(() => {
    if (!data.length || !data[0].limits.length)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <div>
        <ToggleButton
          id="firstTimeInvestor"
          title="First Time Home Investor?"
          disabled={true}
          value={true}
          className="mb-2"
          label={['Yes', 'No']}
        />
        {data[0].limits.map((item, index) => (
          <table key={index} className={`w-full text-center rounded-lg shadow text-sm ${index === 0 ? '' : 'mt-4'}`}>
            <thead className="font-bold bg-gray-100">
              <tr>
                <th rowSpan={2} className="border p-1">
                  FICO
                </th>
                <th rowSpan={2} className="border p-1">
                  Loan Purpose
                </th>
                <th colSpan={2} className="border p-1">
                  Loan Amount
                </th>
                <th rowSpan={2} className="border p-1 w-[100px]">
                  Min Months Reserve
                </th>
                <th colSpan={4} className="border p-1">
                  Max LTVs
                </th>
              </tr>
              <tr>
                <th className="border p-1 w-[150px]">From</th>
                <th className="border p-1 w-[150px]">To</th>
                <th className="border p-1 w-[100px]">AIV-LTV</th>
                <th className="border p-1 w-[100px]">ARV-LTV</th>
                <th className="border p-1 w-[100px]">LTC</th>
                <th className="border p-1 w-[100px]">LTP</th>
              </tr>
            </thead>
            <tbody>
              <Fragment>
                <tr>
                  <td rowSpan={3} className="border px-3 py-1">
                    {item.ficoRange.from} ― {item.ficoRange.to}
                  </td>
                  <td className="border px-3 py-1">Purchase</td>
                  {['min', 'max'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {getPrice3decimal((item.loanAmount.purchase as any)[key])}
                    </td>
                  ))}

                  <td className="border px-3 py-1">{item.minMonthsReserve.purchase}</td>
                  {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {(item.limit.purchase as any)[key]}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="border px-3 py-1">No-Cashout</td>
                  {['min', 'max'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {getPrice3decimal((item.loanAmount.nocashout as any)[key])}
                    </td>
                  ))}

                  <td className="border px-3 py-1">{item.minMonthsReserve.nocashout}</td>
                  {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {(item.limit.nocashout as any)[key]}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="border px-3 py-1">Cashout</td>
                  {['min', 'max'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {getPrice3decimal((item.loanAmount.cashout as any)[key])}
                    </td>
                  ))}

                  <td className="border px-3 py-1">{item.minMonthsReserve.cashout}</td>
                  {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className="border px-3 py-1">
                      {(item.limit.cashout as any)[key]}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td colSpan={9} className="p-3 border">
                    <FirstTimeInvestorValueLeverageLimit
                      title="Bankruptcy"
                      options={finresiMapConstants.bankruptcy}
                      data={item.bankruptcy}
                    />

                    <FirstTimeInvestorValueLeverageLimit
                      title="FC/SS/DIL"
                      options={foreclosuresOptions}
                      data={item.fcSsDil}
                    />

                    <FirstTimeInvestorValueLeverageLimit
                      title="Mortgage Lates"
                      options={finresiMapConstants.mortgageLates}
                      data={item.mortgageLates}
                    />
                  </td>
                </tr>
              </Fragment>
            </tbody>
          </table>
        ))}
      </div>
    )
  }, [data])

  return <div className="h-full">{renderLtvMaxLimits}</div>
}
