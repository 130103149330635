import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { companyName } from 'config'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getLoanReports, removeLoanReport } from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { Button, Input } from 'stories/components'
import { confirm, formatTime, renderHeader, useTitle } from 'utils'

import type { ILoanReport } from './types'

export function Reports() {
  useTitle(`Reports - ${companyName}`)

  const navigate = useHistory()

  const [data, setData] = useState<ILoanReport[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'createdAt',
    orderDir: '-1',
  })

  const filterData = async () => {
    setLoading(true)
    const data = await getLoanReports()
    setData(data)
    setTotal(data.length)
    setLoading(false)
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    setFilters(newFilters)
  }

  const onSort = (key: string, sortOrder: number) => {
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
  }

  const headers: Record<string, string | JSX.Element> = {
    no: 'No',
    // type: <span>Type</span>,
    title: <span>Title</span>,
    createdBy: <span>Created By</span>,
    createdAt: 'Created At',
    action: 'Actions',
  }

  useEffect(() => {
    filterData()
  }, [])

  const onCreate = () => {
    navigate.push('/reports/0')
  }

  const onDelete = async (item: ILoanReport, index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure to remove this report?
        <br />
        <span className="text-gray-600">Title: {item.title}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    removeLoanReport(item.id)
      .then(() => {
        const newData = cloneDeep(data)
        newData.splice(index, 1)
        setData(newData)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="Reports-container py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full">
        <LayoutLoading show={loading} />
        <div className="flex flex-wrap justify-between mb-5">
          <h1 className="text-2xl font-bold flex items-center mb-3">
            <span>Reports</span>{' '}
            <span className="text-base ml-3">
              {loading ? <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" /> : `(${total})`}
            </span>
          </h1>
          <Button onClick={onCreate}>Create Report</Button>
        </div>
        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
          <Input
            type="search"
            title="Search"
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>
        <div className="mt-2">
          <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {Object.keys(headers).map((key, index) =>
                    renderHeader({
                      title: headers[key],
                      sortable: false,
                      index,
                      key,
                      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
                      onSort: (key: string, dir: number) => onSort(key, dir),
                    }),
                  )}
                </tr>
              </thead>
              <tbody className="text-sm text-gray-900">
                {data.map((item, index: number) => {
                  return (
                    <tr key={index} className={`border ${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-2 py-3">{index + 1}</td>
                      {/* <td className="px-2 py-3">
                        <span>{item.type}</span>
                      </td> */}
                      <td className="px-2 py-3 whitespace-nowrap">
                        <span>{item.title}</span>
                      </td>
                      <td className="px-2 py-3">{item.createdBy}</td>
                      <td className="px-2 py-3">{formatTime(item.createdAt)}</td>
                      <td className="px-2 py-3">
                        <div className="flex items-center gap-2">
                          <span
                            className="p-1 text-sm cursor-pointer text-shade-blue hover-shadow1 rounded"
                            onClick={() => navigate.push(`/reports/${item.id}`)}
                          >
                            <PencilSquareIcon className="w-4 h-4" />
                          </span>
                          <span
                            className="p-1 text-sm cursor-pointer text-red-500 hover-shadow1 rounded"
                            onClick={() => onDelete(item, index)}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
