// import React from 'react'

import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import {
  type InputType,
  loanOptionsConstants,
  productTypeOptions,
  SettingKey,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATE,
  transactionTypeOptions,
} from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getSetting, updateSetting } from 'services'
import { Modal } from 'stories/components'
import { confirm, getPrice2decimal, getPrice3decimal, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const UnderwritingFeeSettingKey = 'UNDERWRITING_FEE'

export interface IUnderwritingFee {
  productType: string
  transactionType: string
  propertyType: string
  loanAmountFrom: number
  loanAmountTo: number
  underwritingFee: number
  additionalFee: number
}

interface IProps {
  broadcasts: Record<string, string>
  canEdit: boolean | undefined
}

const defaultInputs: Record<string, InputType> = {
  productType: {
    title: 'Product Type',
    inputType: 'select',
    hasDefaultOption: true,
    options: productTypeOptions,
    error: '',
    required: true,
  },
  transactionType: {
    title: 'Transaction Type',
    inputType: 'select',
    hasDefaultOption: true,
    options: transactionTypeOptions,
    error: '',
    required: true,
  },
  propertyType: {
    title: 'Property Type',
    inputType: 'select',
    hasDefaultOption: true,
    options: loanOptionsConstants.propertyTypeBigList,
    error: '',
    required: true,
  },
  loanAmountFrom: {
    title: 'Loan Amount: From',
    inputType: 'text',
    type: 'thousandSepNoDecimal',
    error: '',
    prefix: '$',
    required: true,
  },
  loanAmountTo: {
    title: 'Loan Amount: To',
    inputType: 'text',
    type: 'thousandSepNoDecimal',
    error: '',
    prefix: '$',
    required: true,
  },
  underwritingFee: {
    title: 'Underwriting Fee',
    inputType: 'text',
    type: 'thousandSep',
    error: '',
    prefix: '$',
    required: true,
  },
  additionalFee: {
    title: 'Additional Fee',
    inputType: 'text',
    type: 'thousandSep',
    error: '',
    prefix: '$',
    required: true,
  },
}

export const UnderwritingFee = (props: IProps) => {
  const { canEdit, broadcasts } = props

  const [fees, setFees] = useState<IUnderwritingFee[]>([])
  const [selected, setSelected] = useState<{ item: IUnderwritingFee; index: number } | undefined>()
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs)
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [globalSettings, setGlobalSettings] = useState<Record<string, string>>({})

  useEffect(() => {
    setLoading(true)
    getSetting(UnderwritingFeeSettingKey)
      .then(({ value }) => setFees(JSON.parse(value || '[]')))
      .finally(() => setLoading(false))
    setLoading(true)
    getSetting(SettingKey.GLOBAL_SETTINGS)
      .then(({ value }) => setGlobalSettings(JSON.parse(value || '{}')))
      .finally(() => setLoading(false))
  }, [])

  const onSubmit = async () => {
    const newInputs = cloneDeep(inputs)
    let hasError = false
    const newFee: any = {}
    Object.keys(newInputs).forEach((key) => {
      newFee[key] = ['loanAmountFrom', 'loanAmountTo', 'underwritingFee'].includes(key)
        ? removeComma(newInputs[key].value)
        : newInputs[key].value
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
    })

    if (removeComma(newFee.loanAmountFrom) > removeComma(newFee.loanAmountTo))
      newInputs.loanAmountFrom.error = `From can not be greater than To`
    setInputs(newInputs)

    if (hasError) return false

    const newData = cloneDeep(fees)
    if (selected) newData.splice(selected.index, 1, newFee)
    else newData.push(newFee)
    setLoading(true)
    await updateSetting(UnderwritingFeeSettingKey, JSON.stringify(newData))
    setLoading(false)
    toast(`${selected ? SUCCESSFULLY_UPDATE : SUCCESSFULLY_CREATED}`, { type: 'success' })
    setFees(newData)

    return true
  }

  const onEdit = (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onOpen = () => {
    const newInputs = cloneDeep(defaultInputs)
    if (selected) {
      Object.keys(selected.item).forEach((key) => (newInputs[key].value = (selected.item as any)[key]))
    }
    setInputs(newInputs)
  }

  const onRemove = async (index: number) => {
    const content = (
      <div className="text-gray-800 mb-4 text-[18px] font-semibold">Are you sure to remove this item?</div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(fees)
    newData.splice(index, 1)
    setLoading(true)
    await updateSetting(UnderwritingFeeSettingKey, JSON.stringify(newData))
    setLoading(false)
    toast(`Successfully removed`, { type: 'info' })
    setFees(newData)
  }

  return (
    <table className="table-auto w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
      <thead className="text-[14px] text-gray-900 bg-gray-100 text-center">
        <tr>
          <th colSpan={6} className="text-center text-[16px] font-bold px-2 py-2.5 border">
            <div className="flex justify-center items-center gap-2 min-h-[28px]">
              <span>Underwriting Fees</span>
            </div>
          </th>
          {canEdit && (
            <th scope="col" className="px-3 py-3 border">
              <Modal
                button={
                  <button
                    className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline whitespace-nowrap"
                    onClick={() => setSelected(undefined)}
                  >
                    + Add
                  </button>
                }
                isOpen={isOpen}
                lastUpdatedAt={lastUpdatedAt}
                title={`${selected ? 'Update Underwriting Fee' : 'Add a new Underwriting Fee'}`}
                titleOkay={`${selected ? 'Update' : 'Add'}`}
                onOk={onSubmit}
                onOpen={onOpen}
                loading={isLoading}
              >
                <div className="mb-2 md:min-w-[480px] text-left font-normal">
                  {Object.keys(inputs).map((key, index) => {
                    return (
                      <div className="w-full mb-4" key={index}>
                        <RenderInput input={inputs[key]} Key={key} onChange={onEdit} />
                      </div>
                    )
                  })}
                </div>
              </Modal>
            </th>
          )}
        </tr>
        {broadcasts?.underwritingFees && (
          <tr className="bg-white text-left">
            <th className="p-2 pl-3 border text-[14.5px] font-bold italic" colSpan={canEdit ? 7 : 6}>
              {broadcasts.underwritingFees}
            </th>
          </tr>
        )}
        <tr>
          <th className="px-1 py-2 border font-variation-settings-600">No</th>
          <th className="px-1 py-2 border font-variation-settings-600">Product Type</th>
          <th className="px-1 py-2 border font-variation-settings-600">Transaction Type</th>
          <th className="px-1 py-2 border font-variation-settings-600">Property Type</th>
          <th className="px-1 py-2 border font-variation-settings-600">Loan Amount</th>
          <th className="px-1 py-2 border font-variation-settings-600">
            <span className="text-left">
              <div className="border-b w-fit mb-1 border-gray-300 whitespace-nowrap">Underwriting Fee</div>
              <div>Additional Fee</div>
            </span>
          </th>
          {canEdit && <th className="py-2 px-1 border font-variation-settings-600">Action</th>}
        </tr>
      </thead>
      <tbody className="text-center">
        {fees.map((item, index) => {
          return (
            <tr key={index} className={`${index % 2 === 1 ? 'bg-gray-50' : ''}`}>
              <td className="py-1.5 px-0.5 border">{index + 1}</td>
              <td className="py-1.5 px-0.5 border">{item.productType}</td>
              <td className="py-1.5 px-0.5 border">{item.transactionType}</td>
              <td className="py-1.5 px-0.5 border">{item.propertyType}</td>
              <td className="py-1.5 px-0.5 border">
                ${getPrice3decimal(item.loanAmountFrom)} - ${getPrice3decimal(item.loanAmountTo)}
              </td>
              <td className="py-1.5 px-0.5 border">
                <span className="text-center">
                  <div className="border-b mb-1 border-gray-300 whitespace-nowrap">
                    ${getPrice2decimal(item.underwritingFee, false, true)}
                  </div>
                  <div>${getPrice2decimal(item.additionalFee, false, true)}</div>
                </span>
              </td>
              {canEdit && (
                <td className="py-1.5 px-0.5 border">
                  <div className="flex justify-center gap-2">
                    <div
                      className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                      onClick={() => {
                        setIsOpen(true)
                        setLastUpdatedAt(Date.now())
                        setSelected({ item, index })
                      }}
                    >
                      <PencilSquareIcon className="w-4 h-4 hover:cursor-pointer text-shade-blue" />
                    </div>
                    <div
                      className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                      onClick={() => onRemove(index)}
                    >
                      <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                    </div>
                  </div>
                </td>
              )}
            </tr>
          )
        })}
        <tr>
          <td colSpan={canEdit ? 7 : 6} className="p-2 border">
            Default Underwriting Fee is ${getPrice2decimal(globalSettings.defaultUnderwritingFee, false, true)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
