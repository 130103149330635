import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'

interface IProps {
  assignmentNames: Array<string>
  onOk: (arg0: Array<string>) => void
}
export const AssignmentPdfSettingsModal = ({ assignmentNames, onOk }: IProps) => {
  const [values, setValues] = useState<Array<string>>([])

  useEffect(() => {
    setValues(assignmentNames)
  }, [assignmentNames])

  const onAdd = () => {
    const newValues = cloneDeep(values)
    newValues.push('')
    setValues(newValues)
  }

  const onUpdate = (newValue: string, index: number) => {
    const newValues = cloneDeep(values)
    newValues[index] = newValue
    setValues(newValues)
  }

  const onRemove = (index: number) => {
    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
  }

  const onConfirm = () => {
    onOk(values.filter((value) => value != ''))
    return true
  }

  return (
    <Modal
      title="Assignment Pdf Settings"
      titleOkay="Update"
      onOk={onConfirm}
      button={
        <span className="ml-4 p-1 rounded flex hover-shadow1 cursor-pointer text-shade-blue">
          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
        </span>
      }
    >
      <div className="text-gray-700 w-144">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Assignor Entity Name Options
              </th>
              <th scope="col" className="px-6 py-3">
                <button
                  className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                  onClick={onAdd}
                >
                  + Add
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {values.map((value, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                <th scope="row" className="px-6 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                  <PlainInput
                    value={value}
                    content={value}
                    isEditing={value == ''}
                    onChange={(newValue: string) => onUpdate(newValue, index)}
                  />
                </th>
                <td className="px-6 py-1">
                  <span className="flex">
                    <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onRemove(index)}>
                      <TrashIcon className="w-4 h-4"></TrashIcon>
                    </span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
