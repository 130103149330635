import { type InputType } from 'config'

export const termSheetFields = (): Record<string, InputType> => {
  return {
    LoanAmount: {
      title: 'Proposed Loan Amount',
      inputType: 'text',
      key: 'proposedLoanAmount',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      readOnly: true,
    },
    LTV: {
      title: 'LTV',
      inputType: 'text',
      type: 'number',
      error: '',
      prefix: '%',
      readOnly: true,
    },
    BorrowerName: {
      title: 'Borrower Name',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    BrokerStreet: {
      title: 'Broker Street',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    BrokerCity: {
      title: 'Broker City',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    BrokerState: {
      title: 'Broker State',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    // BrokerState: {
    //   title: 'Broker State',
    //   inputType: 'select',
    //   hasDefaultOption: true,
    //   options: [...loanOptionsConstants.states],
    //   error: '',
    //   readOnly: true,
    // },
    BrokerZipCode: {
      title: 'Broker ZipCode',
      inputType: 'text',
      type: 'number',
      error: '',
      readOnly: true,
    },
    LenderCompany: {
      title: 'Lender Company',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    SubjectPropertyAddress: {
      title: 'Subject Property Address',
      inputType: 'text',
      error: '',
      span: 2,
      readOnly: true,
    },
    // SubjectPropertyAddress: {
    //   title: 'Subject Property Address',
    //   inputType: 'Map',
    //   error: '',
    //   required: true,
    //   span: 2,
    //   readOnly: true,
    // },
    InterestRate: {
      title: 'Interest Rate',
      inputType: 'text',
      type: 'number',
      error: '',
      readOnly: true,
    },
    LoanTerm: {
      title: 'Loan Term',
      inputType: 'text',
      type: 'number',
      error: '',
      readOnly: true,
    },
    DiscountFeePercent: {
      title: 'Discount Fee Percent',
      inputType: 'text',
      type: 'number',
      error: '',
      prefix: '%',
      readOnly: true,
    },
    DiscountFeeAmount: {
      title: 'Discount Fee Amount',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      readOnly: true,
    },
    CashBorrowerAtClosing: {
      title: 'Cash Borrower At Closing',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      readOnly: true,
    },
    ExitFee: {
      title: 'Exit Fee',
      inputType: 'text',
      type: 'number',
      error: '',
      readOnly: true,
    },
    ConstructionBudget: {
      title: 'Construction Budget',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      readOnly: true,
    },
    ConstructionAmountFinanced: {
      title: 'Construction Amount Financed',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      readOnly: true,
    },
    BrokerFeeAmount: {
      title: 'Broker Fee Amount',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      required: true,
    },
    CommitmentFee: {
      title: 'Commitment Fee Amount',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      required: true,
    },
    BreakUpFee: {
      title: 'Break-Up Fee Amount',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
    },
    BrokerFeePercent: {
      title: 'Broker Fee Percent',
      inputType: 'text',
      type: 'number',
      error: '',
      prefix: '%',
      required: true,
    },
    BrokerCompanyName: {
      title: 'Broker Company Name',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    UnderwritingFee: {
      title: 'Underwriting Fee',
      inputType: 'text',
      type: 'thousandSepNoDecimal',
      error: '',
      prefix: '$',
      readOnly: true,
    },
    BorrowerType: {
      title: 'Borrower Type',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    // BorrowerType: {
    //   title: 'Borrower Type',
    //   inputType: 'select',
    //   hasDefaultOption: true,
    //   options: loanOptionsConstants.borrowerType,
    //   error: '',
    //   required: true,
    //   readOnly: true,
    // },
    FiveLaterDate: {
      title: 'Five Later Date',
      inputType: 'text',
      type: 'date',
      error: '',
      readOnly: true,
    },
    TodayDate: {
      title: 'Today Date',
      inputType: 'text',
      type: 'date',
      error: '',
      readOnly: true,
    },
    LoanOfficerOrBrokerName: {
      title: 'Loan Officer | Broker Name',
      inputType: 'text',
      error: '',
      readOnly: true,
    },
    EstimatedCreditScore: {
      title: 'Credit Score',
      key: 'estimatedCreditScore',
      inputType: 'text',
      type: 'creditScore',
      error: '',
      readOnly: true,
    },
  }
}

export const overrideFields = (): Record<string, InputType> => {
  return {
    override: {
      title: 'Override Interest Rate and Base Price?',
      inputType: 'check',
      error: '',
      required: true,
    },
    type: {
      inputType: 'InputWithButton',
      type: 'text',
      title: 'Product Type',
      value: '',
      required: true,
      modalTitle: 'Choose a Product Type',
      options: {},
      multiple: false,
      enableInput: true,
    },
    program: {
      inputType: 'InputWithButton',
      type: 'text',
      title: 'Program',
      value: '',
      required: true,
      modalTitle: 'Choose a Program',
      options: {},
      multiple: false,
      enableInput: true,
    },
    rate: {
      title: 'Interest Rate',
      type: 'number',
      inputType: 'text',
      required: true,
    },
    price: {
      title: 'Base Price',
      type: 'number',
      inputType: 'text',
      required: true,
    },
    lockDays: {
      title: 'Lock Days',
      inputType: 'select',
      hasDefaultOption: true,
      options: ['15', '21', '30', '45', '60'],
      error: '',
      required: true,
    },
    rateSpread: {
      title: 'Rate Spread',
      type: 'number',
      inputType: 'text',
      required: true,
    },
    exitFee: {
      title: 'Exit Fee',
      type: 'number',
      inputType: 'text',
      required: true,
    },
    note: {
      title: 'Notes',
      inputType: 'textarea',
      rows: 3,
    },
  }
}

export const getDefaultLoanProcessData = () => {
  return {
    type: '',
    program: '',
    rate: 0,
    price: 0,
    sheetDate: '',
    lockDays: 0,
    lockedDate: '',
    rateLocked: false,
    rateSpread: NaN,
    exitFee: NaN,
  }
}

export const historyLabels: any = {
  type: 'Type',
  program: 'Program',
  rate: 'Rate',
  price: 'Price',
  sheetDate: 'Sheet Date',
  lockDays: 'Lock Days',
  lockedDate: 'Locked Date',
  rateLocked: 'Rate Locked?',
  expireDate: 'Expire Date',
  extensions: 'Extensions',
  note: 'Note',
  override: 'Pricing Adjustment',
  rateSpread: 'Rate Spread',
  exitFee: 'Exit Fee',
}

export const convertHistoryLabel = (key: string) => {
  let rlt = historyLabels[key]
  if (!rlt) rlt = key
  return rlt
}

export const overrideValidationKeyOptions: any = {
  afterRepairValue: 'After Repair Value',
  asIsValue: 'As Is Appraised Value',
  constructionReserve: 'Construction Amount Financed',
  estimatedCreditScore: 'Estimated Credit Score',
  propertyType: 'Property Type',
  proposedLoanAmount: 'Proposed Loan Amount',
  other: 'Other',
}

export const pendingChangeRequestTypes: Record<number, string> = {
  1: 'Loan Change Request',
  2: 'Exception Review Request',
}

export const declineFields = (): Record<string, InputType> => {
  return {
    comment: {
      inputType: 'textarea',
      title: 'Comment',
      value: '',
    },
  }
}
