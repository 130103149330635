import type { ITier } from '../../interface'
import { convertNullValue } from '../../logic'

export const DscrCityCountyTiers = ({ data, title }: { data: ITier[]; title: string }) => {
  return (
    <div>
      <div className="text-base font-semibold mb-2">{title}</div>

      {data && (
        <table className="table text-center text-sm w-full">
          <thead className="font-bold bg-gray-100">
            <tr>
              <th className="px-3 py-3 border">No</th>
              <th className="px-3 py-3 border">Tier Name</th>
              <th className="px-3 py-3 border">Count</th>
              <th className="px-3 py-3 border">LTV Adjustment</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => {
              if (item?.ltvAdjustment)
                return (
                  <tr key={index}>
                    <td className="px-3 py-2 border">{index + 1}</td>
                    <td className="px-3 py-2 border">{item.title}</td>
                    <td className="px-3 py-2 border">{item.count}</td>
                    <td className="px-3 py-2 border">{convertNullValue(item.ltvAdjustment.aivLtv)}</td>
                  </tr>
                )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}
