import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { downloadS3Documents, getAllServiceLink } from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { Input2, Pagination } from 'stories/components'

import { floodProductTypes } from './constants'
import type { INationalFloodOrder } from './types'

export function NationalFloodTab() {
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    pageNum: 0,
  })

  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const [data, setData] = useState<{ loanNumber: number; orders: INationalFloodOrder[] }[]>([])
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  useEffect(() => {
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = async (filters: Record<string, any>) => {
    setLoading(true)
    getAllServiceLink({
      ...filters,
      skip: filters.pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setData(data)
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  const onChangeFilter = (key: 'query' | 'pageNum', value: any) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterData(newFilters)
  }

  const onDownloadDocument = async (pdfKey: string) => {
    setLoading(true)
    const res = await downloadS3Documents(pdfKey)
    var windowReference: any = window.open()
    windowReference.location = res.url
    setLoading(false)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  return (
    <div className="">
      <h2 className="text-xl flex items-center gap-1 font-bold mb-2">
        <span>ServiceLink National Flood</span>
        {isLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin ml-2" />}
      </h2>
      <div className="flex flex-wrap gap-2 items-center">
        <div className="w-64">
          <Input2
            type="search"
            title="Search Loan Number"
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>
        <p className="font-variation-settings-600"> {total} items</p>
      </div>
      <div className="my-4">
        <div className="relative overflow-auto shadow-md sm:rounded-lg">
          <LayoutLoading show={isLoading} />
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  Loan Number
                </th>
                <th scope="col" className="px-3 py-3">
                  Order No
                </th>
                <th scope="col" className="px-3 py-3 min-w-[200px]">
                  Certificate Number
                </th>
                <th scope="col" className="px-3 py-3">
                  Product Type
                </th>
                <th scope="col" className="px-3 py-3">
                  Condition
                </th>
                <th scope="col" className="px-3 py-3">
                  Description
                </th>
                <th scope="col" className="px-3 py-3">
                  Order Date
                </th>
                <th scope="col" className="px-3 py-3">
                  Request Type
                </th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {data.map((item, index: number) => {
                const count = item.orders.length
                return item.orders.map((order, orderIndex) => (
                  <tr key={orderIndex} className={`border ${index % 2 ? 'bg-gray-50' : ''}`}>
                    {orderIndex == 0 && (
                      <td
                        className="px-3 py-3 text-center text-shade-blue font-variation-settings-600 hover:underline cursor-pointer border"
                        rowSpan={count}
                      >
                        <Link to={`/national_flood/${item.loanNumber}`} target="_blank" rel="noopener noreferrer">
                          {item.loanNumber}
                        </Link>
                      </td>
                    )}
                    <td className="px-3 py-3">{order.order_no}</td>
                    <td className="px-3 py-3">{order.certificate_number}</td>
                    <td className="px-3 py-3">{floodProductTypes[order.product]}</td>
                    <td className="px-3 py-3 statusColor">
                      <span className="flex items-center">
                        <p className={`${order.condition} p-2 inline-block rounded`}>{order.condition}</p>
                        {order.pdf_flag === 0 ? (
                          ''
                        ) : (
                          <span
                            className="ml-1 text-shade-blue cursor-pointer color1 fz15 p-1 rounded hover-shadow1"
                            onClick={() => onDownloadDocument(order.pdfKey)}
                          >
                            <ArrowDownTrayIcon className="w-4 h-4" />
                          </span>
                        )}
                      </span>
                    </td>
                    <td className="px-3 py-3">{order.description}</td>
                    <td className="px-3 py-3">
                      <p>{order.req_time}</p>
                    </td>
                    <td className="px-3 py-3">{order.action}</td>
                  </tr>
                ))
              })}
            </tbody>
          </table>

          <div className="flex justify-end items-center mt-3 mb-3">
            <Pagination
              totalCount={total}
              itemCountPerPage={itemCountPerPage}
              onNavigate={onPageNavigate}
              pageNum={filters.pageNum}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
