import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { copyClipboard } from 'utils/copyClipboard'

export const CopyableText = ({ children, copyText }: { children: JSX.Element | string; copyText?: string }) => {
  if (!children) return null

  const onCopy = () => {
    copyClipboard(copyText || (children as string))
  }

  return (
    <span className="flex items-center gap-1 hover:underline cursor-pointer group" onClick={onCopy}>
      {children}

      <span className="text-blue-800 p-1 w-6 h-6 hover-shadow1 rounded">
        <DocumentDuplicateIcon className="w-4 h-4 hidden group-hover:block" />
      </span>
    </span>
  )
}
