import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { submitCondition } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, Checkbox, Toggle } from 'stories/components'
import { confirm, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { conditionInputs } from '../constant'
import type { ICondition, IConditionTemplate } from './types'

export function ConditionDetails(props: {
  templates: IConditionTemplate[]
  defaults: ICondition | null
  isDuplicated: boolean
  lastUpdatedAt: number
  onBack: Function
  onComplete: Function
}) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [selectedTemplates, setSelectedTemplates] = useState<Array<number> | null>(null)
  const { templates = [], defaults = null, isDuplicated, lastUpdatedAt } = props
  const [isForActiveLoan, setForActiveLoan] = useState(false)

  useEffect(() => {
    if (!defaults) {
      setInputs(conditionInputs())
      setSelectedTemplates([])
      return
    }
    const newStats = cloneDeep(conditionInputs()) as Record<string, any>
    for (const key in newStats) {
      newStats[key].value = (defaults as any)[key]
    }
    if (isDuplicated) newStats.name.value = ''
    setInputs(newStats)

    const newTemplates: Array<number> = []
    templates.forEach((template) => {
      if (template.conditions.indexOf(defaults.no) != -1) newTemplates.push(template.no)
    })
    setSelectedTemplates(newTemplates)
  }, [defaults, lastUpdatedAt, templates, isDuplicated])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const changeTempConds = (no: number, value: boolean) => {
    if (!selectedTemplates) return
    const newValues = cloneDeep(selectedTemplates)
    const index = newValues.indexOf(no)
    if (value && index == -1) newValues.push(no)
    else if (!value && index != -1) newValues.splice(index, 1)
    setSelectedTemplates(newValues)
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (!disabled && value !== undefined) data[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    if (isForActiveLoan) {
      const isUpdateLoans = await confirm('Do you want to update all active Loans?')
      data.isUpdateLoans = isUpdateLoans
    }

    setLoading(true)

    data.templates = selectedTemplates

    const id = !defaults || isDuplicated ? 0 : defaults.id
    if (isDuplicated && defaults) {
      delete (defaults as any).id
      delete (defaults as any).no
    }
    submitCondition(id, data)
      .then(() => {
        if (id == 0) toast('New condition is added.', { type: 'success' })
        else toast(`Condition No ${defaults?.no} is updated.`, { type: 'success' })

        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  const isSelectedAll = useMemo(() => {
    if (!selectedTemplates) return false
    return templates.filter((item) => selectedTemplates.indexOf(item.no) === -1).length == 0
  }, [selectedTemplates, templates])

  return (
    <div className="Conditions-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Conditions - {!defaults || isDuplicated ? 'Add' : `Update No ${defaults.no}`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-2" /> <p>Return to Conditions</p>
        </div>
      </Button>

      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div className={`input ${input.span ? `md:col-span-${input.span}` : ''}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>

      <div className="flex items-center gap-4 mb-3 border-b">
        <h2 className="text-md font-bold flex items-center">Templates</h2>

        <Toggle
          id={`template-all`}
          title={`Select All`}
          key={`${lastUpdatedAt}-template-check-all`}
          value={isSelectedAll}
          onChange={() => setSelectedTemplates(isSelectedAll ? [] : templates.map((v) => v.no))}
        />
      </div>
      <div className="grid md:grid-cols-2 mb-5 gap-3">
        {selectedTemplates &&
          templates.map((item, index: number) => (
            <div className="col-12 col-md-6" key={`${lastUpdatedAt}-template-${index}`}>
              <Toggle
                id={`${item.no}`}
                title={`${item.no} - ${item.name}`}
                key={`${lastUpdatedAt}-template-check-${index}`}
                value={selectedTemplates.indexOf(item.no) !== -1}
                onChange={(value) => changeTempConds(item.no, value)}
              />
            </div>
          ))}
      </div>
      <Checkbox
        id="initializeForActiveLoan"
        title="We need to initialize for all active loans"
        value={isForActiveLoan}
        onChange={(v) => setForActiveLoan(v)}
      />
      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{!defaults || isDuplicated ? 'Add' : 'Update'} Condition</>
        </Button>
      </div>
    </div>
  )
}
