import { companyName } from 'config'
import { TemporaryLayout } from 'layouts'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTitle } from 'utils/pageTitle'

import { ProfileInfo } from './ProfileInfo'
import { ResetPassword } from './ResetPassword'

const menus: Record<string, string> = {
  profile: 'My Profie',
  password: 'Change Password',
}

export function BorrowerEditProfile() {
  useTitle(`Edit Profile & Info - ${companyName}`)

  const [selectedMenu, setSelectedMenu] = useState('profile')
  const location = useLocation()

  useEffect(() => {
    const menu = location.search.replace('?menu=', '')
    if (menus[menu]) {
      setSelectedMenu(menu)
    }
  }, [])

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'profile':
        return <ProfileInfo />

      case 'password':
        return <ResetPassword />

      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  return (
    <TemporaryLayout requireSign noOverview>
      <div className="profile-info-container py-6">
        <div className="relative vendors-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
          <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded h-fit">
            <ul className="sidebar-items flex flex-col p-4 pb-20">
              {Object.keys(menus).map((key, index) => {
                const item = menus[key]
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setSelectedMenu(key)
                    }}
                    className="border-b py-2"
                  >
                    <p
                      className={`hover:underline cursor-pointer ${
                        selectedMenu === key ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                      }`}
                    >
                      <span className="flex gap-2">
                        <span className="w-[15px]">{index + 1}.</span>
                        <span>{item}</span>
                      </span>
                    </p>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="content-right col-span-12 md:col-span-9 bg-white">{renderFragment}</div>
        </div>
      </div>
    </TemporaryLayout>
  )
}
