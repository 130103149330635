import {
  API_REPORTS,
  API_REPORTS_BY_ID,
  API_REPORTS_CSV_DOWNLOAD,
  API_REPORTS_DOWNLOAD,
  API_REPORTS_PLACEHOLDERS,
  API_REPORTS_PREVIEW,
} from 'config'
import Api from 'services/api'

import { downloadFile } from '../documents'

export const getLoanReportsPlaceholders = (): Promise<Record<string, any[]>> => {
  return Api.get(API_REPORTS_PLACEHOLDERS)
}

export const getLoanReports = () => {
  return Api.get(API_REPORTS)
}

export const getLoanReport = (id: number) => {
  return Api.get(API_REPORTS_BY_ID, {}, { id })
}

export const generateReportsPreview = (data: { type: string; headers: string[]; filters: Record<string, any> }) => {
  return Api.post(API_REPORTS_PREVIEW, data)
}

export const downloadReports = async (data: {
  type: string
  title: string
  headers: string[]
  filters: Record<string, any>
}) => {
  const res: Blob = await Api.post(
    API_REPORTS_DOWNLOAD,
    data,
    {},
    {
      responseType: 'blob',
    },
  )

  const { title } = data
  downloadFile(`Loan Report${title ? ` - ${title}` : ''}.xlsx`, res)
}

export const downloadReportsCSV = async (data: {
  type: string
  title: string
  headers: string[]
  filters: Record<string, any>
}) => {
  const res: Blob = await Api.post(
    API_REPORTS_CSV_DOWNLOAD,
    data,
    {},
    {
      responseType: 'blob',
    },
  )

  const { title } = data

  downloadFile(`Loan Report${title ? ` - ${title}` : ''}.csv`, res)
}

export const saveReports = (id: number, data: Record<string, any>) => {
  return Api.put(API_REPORTS_BY_ID, data, { id })
}

export const removeLoanReport = (id: number) => {
  return Api.delete(API_REPORTS_BY_ID, {}, { id })
}
