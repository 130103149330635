import cloneDeep from 'clone-deep'
import { finresiMapConstants, loanOptionsConstants } from 'config'
import type { ILeverageAdjustments, IProgramTableData } from 'pages/Resources'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import { RehabBudgetTypes } from '../LeverageLimits/RehabBudgetTypeLeverageLimit'
import { FicoAdjustment } from './FicoAdjustment'
import { RangeAdjustment } from './RangeAdjustment'
import { ValueAdjustment } from './ValueAdjustment'

const initialMenus = {
  occupancy: 'Occupancy',
  loanAmount: 'Loan Amount',
  experience: 'Experience',
  propertyType: 'Property Type',
  fico: 'FICO',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  citizenship: 'Citizenship',
  monthsReserve: 'Months Reserve',
  rehabBudgetType: 'Rehab Budget Type',
  underConstruction: 'Property Under Construction? (2)',
  isPropertyInLeasableState: 'Can property be rented as is? (2)',
  decliningMarketProperty: 'Property in a declining market? (2)',
  newConstructionProperty: 'Is the property a new construction? (2)',
  ruralProperty: 'Rural Property? (2)',
  firstTimeHomeBuyer: 'First Time Home Buyer? (2)',
  firstTimeInvestor: 'First Time Home Investor? (2)',
  exitFee: 'Exit Fee',
}

export const foreclosuresOptions: Record<string, string> = {
  None: 'None',
  ZeroPlus: '1-11 Months',
  OnePlus: '12-23 Months',
  TwoPlus: '24-35 Months',
  ThreePlus: '36-47 Months',
  FourPlus: '48+ Months',
}

export const LeverageAdjustments = ({ program }: { program: IProgramTableData }) => {
  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')

  useEffect(() => {
    if (!program) return

    const {
      occupancyLeverageAdjustment,
      loanAmountLeverageAdjustment,
      experienceLeverageAdjustment,
      ficoLeverageAdjustment,
      propertyTypeLeverageAdjustment,
      mortgageLatesLeverageAdjustment,
      fcSsDilLeverageAdjustment,
      bankruptcyLeverageAdjustment,
      forbearanceLeverageAdjustment,
      citizenshipLeverageAdjustment,
      monthsReserveLeverageAdjustment,
      rehabBudgetTypeLeverageAdjustment,
      exitFeeLeverageAdjustment,
    } = program.leverageAdjustments as ILeverageAdjustments

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'occupancy':
          if (!!occupancyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${occupancyLeverageAdjustment.length})`
          }
          break
        case 'rehabBudgetType':
          if (!!rehabBudgetTypeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${rehabBudgetTypeLeverageAdjustment.length})`
          }
          break
        case 'citizenship':
          if (!!citizenshipLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${citizenshipLeverageAdjustment.length})`
          }
          break
        case 'monthsReserve':
          if (!!monthsReserveLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${monthsReserveLeverageAdjustment.length})`
          }
          break
        case 'fico':
          if (!!ficoLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${ficoLeverageAdjustment.length})`
          }
          break
        case 'loanAmount':
          if (!!loanAmountLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${loanAmountLeverageAdjustment.length})`
          }
          break
        case 'experience':
          if (!!experienceLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${experienceLeverageAdjustment.length})`
          }
          break
        case 'propertyType':
          if (!!propertyTypeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${propertyTypeLeverageAdjustment.length})`
          }
          break
        case 'mortgageLates':
          if (!!mortgageLatesLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${mortgageLatesLeverageAdjustment.length})`
          }
          break
        case 'forbearance':
          if (!!forbearanceLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${forbearanceLeverageAdjustment.length})`
          }
          break
        case 'fcSsDil':
          if (!!fcSsDilLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${fcSsDilLeverageAdjustment.length})`
          }
          break
        case 'bankruptcy':
          if (!!bankruptcyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${bankruptcyLeverageAdjustment.length})`
          }
          break
        case 'exitFee':
          if (!!exitFeeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${exitFeeLeverageAdjustment.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [program])

  const renderContent = useMemo(() => {
    const { leverageAdjustments } = program

    switch (selectedMenu) {
      case menus.occupancy:
        return <ValueAdjustment title="Occupancy" data={leverageAdjustments?.occupancyLeverageAdjustment || []} />
      case menus.loanAmount:
        return <RangeAdjustment title="Loan Amount" data={leverageAdjustments?.loanAmountLeverageAdjustment || []} />
      case menus.experience:
        return <RangeAdjustment title="Experience" data={leverageAdjustments?.experienceLeverageAdjustment || []} />
      case menus.fico:
        return <FicoAdjustment title="FICO" data={leverageAdjustments?.ficoLeverageAdjustment || []} />
      case menus.propertyType:
        return (
          <ValueAdjustment title="Property Type" data={leverageAdjustments?.propertyTypeLeverageAdjustment || []} />
        )
      case menus.mortgageLates:
        return (
          <ValueAdjustment
            title="Mortgage Lates"
            options={finresiMapConstants.mortgageLates}
            data={leverageAdjustments?.mortgageLatesLeverageAdjustment || []}
          />
        )
      case menus.fcSsDil:
        return (
          <ValueAdjustment
            title="FC/SS/DIL"
            options={foreclosuresOptions}
            data={leverageAdjustments?.fcSsDilLeverageAdjustment || []}
          />
        )
      case menus.bankruptcy:
        return (
          <ValueAdjustment
            title="Bankruptcy"
            options={finresiMapConstants.bankruptcy}
            data={leverageAdjustments?.bankruptcyLeverageAdjustment || []}
          />
        )
      case menus.forbearance:
        return (
          <ValueAdjustment
            title="Forbearance / Loan Modification"
            options={loanOptionsConstants.forbearance}
            data={leverageAdjustments?.forbearanceLeverageAdjustment || []}
          />
        )
      case menus.citizenship:
        return (
          <ValueAdjustment
            title="Citizenship"
            options={finresiMapConstants.citizen}
            data={leverageAdjustments?.citizenshipLeverageAdjustment || []}
          />
        )
      case menus.monthsReserve:
        return (
          <RangeAdjustment title="Months Reserve" data={leverageAdjustments?.monthsReserveLeverageAdjustment || []} />
        )
      case menus.rehabBudgetType:
        return (
          <ValueAdjustment
            title="Rehab Budget Type"
            options={RehabBudgetTypes}
            data={leverageAdjustments?.rehabBudgetTypeLeverageAdjustment || []}
          />
        )
      case menus.underConstruction:
        return (
          <ValueAdjustment
            title="Property Under Construction?"
            data={leverageAdjustments?.underConstructionLeverageAdjustment || []}
            showDesc={true}
          />
        )
      case menus.isPropertyInLeasableState:
        return (
          <ValueAdjustment
            title="Can property be rented as is?"
            data={leverageAdjustments?.isPropertyInLeasableStateLeverageAdjustment || []}
            showDesc={true}
          />
        )
      case menus.decliningMarketProperty:
        return (
          <ValueAdjustment
            title="Property in a declining market?"
            data={leverageAdjustments?.decliningMarketPropertyLeverageAdjustment || []}
          />
        )
      case menus.newConstructionProperty:
        return (
          <ValueAdjustment
            title="Is the property a new construction?"
            data={leverageAdjustments?.newConstructionPropertyLeverageAdjustment || []}
            showDesc={true}
          />
        )
      case menus.ruralProperty:
        return (
          <ValueAdjustment title="Rural Property?" data={leverageAdjustments?.ruralPropertyLeverageAdjustment || []} />
        )
      case menus.firstTimeHomeBuyer:
        return (
          <ValueAdjustment
            title="First Time Home Buyer?"
            data={leverageAdjustments?.firstTimeHomeBuyerLeverageAdjustment || []}
          />
        )
      case menus.firstTimeInvestor:
        return (
          <ValueAdjustment
            title="First Time Home Investor?"
            data={leverageAdjustments?.firstTimeInvestorLeverageAdjustment || []}
          />
        )
      case menus.exitFee:
        return <RangeAdjustment data={leverageAdjustments?.exitFeeLeverageAdjustment || []} title="Exit Fee" />
      default:
        return <></>
    }
  }, [selectedMenu, program])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div className="h-full">
      {renderMenus}

      <div className="p-2 h-full">{renderContent}</div>
    </div>
  )
}
