import { XCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputSelect, InputType } from 'config'
import { useEffect, useState } from 'react'
import { updateAccounting } from 'services'
import { Button, Modal } from 'stories/components'
import { InputConvert, InputValidate, sortByKey } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingCategory } from '../AccountingCategories'
import { EditableAccountingTable, IAccounting } from '../AccountingRecords'
import { getChildrenCategories } from '../AccountingRecords/util'
import type { IAccountingAccount } from '../Accounts/types'
import { getAccountOptions } from '../Accounts/utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    baseId: {
      inputType: 'select',
      title: 'Base Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: false,
    },
    category: {
      inputType: 'select',
      title: 'Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
    accountId: {
      inputType: 'select',
      title: 'Account',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
  }
}

export const MultiRecordUpdateDialog = ({
  baseCategory,
  category,
  accounts,
  data: _data,
  onClose,
}: {
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  accounts: IAccountingAccount[]
  data: IAccounting[]
  onClose: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [data, setData] = useState<IAccounting[]>(_data)
  const [errorMsg, setErrorMsg] = useState('')
  const [orders, setOrders] = useState<{ by: string; dir: 1 | -1 }>({ by: 'date', dir: -1 })

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    ;(newInputs.baseId as InputSelect).options = baseCategory
    ;(newInputs.accountId as InputSelect).options = getAccountOptions(accounts)
    setInputs(newInputs)
  }, [])

  useEffect(() => {
    setData(sortByKey(data, orders.by, orders.dir))
  }, [orders])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key == 'baseId') {
      ;(newInputs.category as InputSelect).options = getChildrenCategories(category, value)
      newInputs.category.value = ''
    }
    setInputs(newInputs)

    const newData = cloneDeep(data)
    newData.forEach((v) => {
      ;(v as any)[key] = value
    })
    setData(newData)
  }

  const onUpdate = async () => {
    setLoading(true)
    const checkedData = data.filter((v) => v.checked)
    const newData = await updateAccounting(checkedData)
    setLoading(false)
    onClose(newData)
  }

  const onApprove = async () => {
    let allPassed = true

    const checkedData = data.filter((v) => v.checked)
    const newValues = cloneDeep(checkedData)

    newValues.forEach((v) => {
      v.valid = !!v.date && !!v.description && !!Number(v.amount) && !!v.baseId && !!v.category && !!v.accountId
      allPassed = allPassed && (v.valid || !v.checked)
    })

    if (!allPassed) {
      setData(newValues)
      setErrorMsg(
        `Please complete following items: ${newValues
          .map((v, index) => (!v.valid && v.checked ? index + 1 : 0))
          .filter((v) => !!v)
          .map((v) => `${v}`)
          .join(', ')}.`,
      )
      return null
    }

    setLoading(true)

    const newData = cloneDeep(checkedData)
    newData.forEach((v) => {
      v.actived = true
      v.deleted = false
    })
    const updatedData = await updateAccounting(newData)
    setLoading(false)
    onClose(updatedData)
  }

  const renderErrorMessage = () => {
    return errorMsg ? (
      <div
        className="my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] flex items-center"
        role="alert"
      >
        <XCircleIcon className="w-6 h-6"></XCircleIcon>
        <span className="ml-1">{errorMsg}</span>
      </div>
    ) : null
  }

  return (
    <Modal
      title={`${data[0].actived ? 'Update' : 'Approve Pending'} Records`}
      loading={isLoading}
      titleCancel=""
      titleOkay=""
      isOpen
      onClose={() => onClose(null)}
    >
      <div>
        <div className={`flex gap-x-4 relative`}>
          {Object.keys(inputs).map((key) => {
            return (
              <div className={`input w-72 mb-4`} key={key}>
                <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
        <EditableAccountingTable
          accounts={accounts}
          baseCategory={baseCategory}
          category={category}
          data={data}
          setData={setData}
          orders={orders}
          onSort={setOrders}
        />
        {renderErrorMessage()}
        <div className="flex justify-center gap-4 mt-4">
          <Button outline onClick={onUpdate}>
            Update
          </Button>
          <Button onClick={onApprove}>Approve</Button>
          <Button color="white" onClick={() => onClose(null)}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}
