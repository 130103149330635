import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { downloadPostClosingDocs } from 'services'
import { Button } from 'stories/components'
import { setLoanNumber } from 'utils/setLoanNumber'

export const DownloadDocuments = () => {
  const [action, setAction] = useState('')

  const onDownload = (entity: string) => {
    setAction(entity)
    const loanNumber = setLoanNumber()
    downloadPostClosingDocs(loanNumber, entity).finally(() => setAction(''))
  }

  return (
    <div className="mt-6">
      <div className="p-3 shadow w-96 pb-0">
        <div className="border-b mb-4 text-gray-800 font-bold text-[18px] flex justify-between items-center">
          <span>Documents</span>
          <span className="text-shade-blue">
            <ArrowDownTrayIcon className="w-5 h-5"></ArrowDownTrayIcon>
          </span>
        </div>
        {['Entity', 'Non-Entity'].map((type) => (
          <Button
            key={type}
            className="w-full mb-6"
            color="gray"
            loading={action === type}
            onClick={() => onDownload(type)}
          >
            <>Download {type} Documents</>
          </Button>
        ))}
      </div>
    </div>
  )
}
