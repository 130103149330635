import { convertValueToLabel, getInitialLoanFields } from 'config'
import { ILoanProcess } from 'pages/LoanStructure/interfaces'
import { calculateLTV } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { InputConvert } from 'utils'

import { combinedMonthlyExpense } from './utils'

export async function getLoanPriceDetailsData(showingFields: string[][], loanProcessData: ILoanProcess) {
  const { loan } = await store.getState()
  const pitis = combinedMonthlyExpense(loan, loanProcessData, true)
  const fields = getInitialLoanFields()
  const keys: any = []
  showingFields.map((item: any) => {
    keys.push(...item)
  })
  keys.push('ltv')
  keys.push('cltv')
  const resultData: any[] = []

  keys.forEach((key: string) => {
    if (
      [
        'subjectPropertyAddress',
        'additionalPropertyAddress',
        'borrowerFirstName',
        'borrowerMiddleName',
        'borrowerLastName',
      ].indexOf(key) !== -1
    ) {
    } else {
      let value: any, title: any

      if (key === 'ltv') {
        title = 'LTV'
        value = `% ${calculateLTV()}`
      } else if (key === 'cltv') {
        title = 'CLTV'
        value = `% ${calculateLTV(null, true)}`
      } else {
        value = InputConvert(fields[key], loan[key])
        value = convertValueToLabel(key, value)

        if (fields[key].prefix) value = `${fields[key].prefix} ${value}`

        title = fields[key].title
      }

      if (key === 'estimatedDscr') value = pitis['dscr']

      resultData.push([title, value])
    }
  })
  return resultData
}
