import { ArrowDownTrayIcon, CheckIcon, NoSymbolIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { loanStatusConstants } from 'config'
import { mapStepDetailDescription } from 'reducers/step.reducer'
import { downloadS3Documents } from 'services'

export const LoanOverviewItem = (props: {
  step: Record<string, any>
  loanDetail: Record<string, any>
  borrower: Record<string, any>
  itemKey: string
  index: number
  onEdit: Function
  setLoading: Function
}) => {
  const { step, loanDetail, borrower, itemKey: key, index, onEdit, setLoading } = props

  const item: any = step.overview[key]
  const { status, detail, label, errors } = item
  let bg_cn = '',
    font_cn = '',
    cursor_cn = '',
    btn_cn = 'border-shade-blue hover:bg-shade-blue hover:text-white',
    btn_disabled = false
  if (status === -1) {
    font_cn = 'text-gray-400'
    cursor_cn = 'cursor-not-allowed'
    btn_cn = 'border-gray-400 cursor-not-allowed'
    btn_disabled = true
  }
  if (status === 1) {
    bg_cn = 'bg-slate-50'
  }

  const onDownloadSignPdf = async () => {
    if (!borrower || !borrower.borrower || !borrower.borrower.signedPdfKey) return

    const { signedPdfKey } = borrower.borrower
    setLoading(true)
    const res = await downloadS3Documents(signedPdfKey)
    var windowReference: any = window.open()
    windowReference.location = res.url
    setLoading(false)
  }

  const renderStatus = (key: string) => {
    const { status, detail } = step.overview[key]
    let rlt = ''
    if (status === 1) {
      rlt = 'Completed'
    }
    if (key === 'submit') {
      rlt = loanStatusConstants.status[loanDetail.loanStatus]
      if (detail.submitUnderwriter === 0 && detail.conditionSubmit === 0 && detail.submitSetup === 1)
        rlt = 'Pending Loan Setup'
    }
    return rlt
  }

  return (
    <div
      className={`${
        errors?.length > 0 ? '' : 'md:max-h-[250px]'
      } flex flex-col md:col-span-6 col-span-12 p-2 md:p-4 shadow rounded-md ${bg_cn} ${font_cn} ${cursor_cn}`}
      key={index}
    >
      <div className="font-bold text-[20px] flex justify-between">
        <span>
          {index + 1}. {label}
        </span>
        {status === -1 ? (
          <NoSymbolIcon className="w-4 h-4 text-gray-400"></NoSymbolIcon>
        ) : (
          <PencilSquareIcon className="w-4 h-4 cursor-pointer" onClick={() => onEdit(key)}></PencilSquareIcon>
        )}
      </div>
      <div className="mt-4">
        Status: <span className="ml-1 text-shade-blue text-[15px] font-bold italic">{renderStatus(key)}</span>
      </div>
      <div className="text-[14px] mt-2 flex-1">
        {Object.keys(detail).map((key, index) => {
          if (detail[key] === -2) return null
          return (
            <div className="flex items-center" key={index}>
              <span>- {mapStepDetailDescription[key]}</span>
              {detail[key] === 1 && [
                <span className="ml-1 mb-0.5 text-shade-blue" key={0}>
                  <CheckIcon className="w-5 h-5"></CheckIcon>
                </span>,
                key === 'signApplication' && (
                  <span
                    className="ml-2 mb-0.5 text-shade-blue rounded p-0.5 hover-shadow1 cursor-pointer"
                    key={1}
                    onClick={() => onDownloadSignPdf()}
                  >
                    <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
                  </span>
                ),
              ]}
              {detail[key] === 2 &&
                key === 'lockRate' && [
                  <span className="ml-1 mb-0.5 text-shade-blue" key={0}>
                    <CheckIcon className="w-5 h-5"></CheckIcon>
                  </span>,
                  <span className="rounded p-1 bg-red-200 border border-red-300 ml-2" key={1}>
                    Lock Expired
                  </span>,
                ]}
            </div>
          )
        })}
      </div>
      {errors?.length > 0 && (
        <div className="text-[14px] mt-2">
          <div className="bg-red-100 border border-red-400 text-red-700 rounded p-3" role="alert">
            <div className="font-semibold border-b mb-2">Please complete the following steps to continue:</div>
            {errors.map((item: string, index: number) => {
              return <div key={index}>- {item}</div>
            })}
          </div>
        </div>
      )}
      <div className="mt-6">
        <button
          disabled={btn_disabled}
          className={`${btn_cn} border px-5 py-2.5 w-full rounded`}
          onClick={() => onEdit(key)}
        >
          Edit
        </button>
      </div>
    </div>
  )
}
