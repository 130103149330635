import { ChevronDownIcon, ChevronRightIcon, IdentificationIcon } from '@heroicons/react/24/outline'
import { setBorrowerGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  borrowerInfoUpdate,
  createAdditionalMember,
  deleteAdditionalMember,
  getAdditionalMembers,
  updateAdditionalMember,
} from 'services'
import { FormTable } from 'stories/components'
import { canEditLoanApplication, openAuditLog, REQUIRED_FIELD_ERROR_MESSAGE } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, hasRecordInputs, header } from './constants'
import { additionalMemberValidate } from './logic'
import { MemberDetail } from './MemberDetail'
import type { BorrowerAdditionalMember } from './types'

export function AdditionalMember({ setLoading }: any) {
  const [data, setData] = useState<BorrowerAdditionalMember[]>([])
  const [length, setLength] = useState(-1)
  const [inputs, setInputs] = useState<any>(hasRecordInputs())
  const [init, setInit] = useState(true)
  const [currentMemberId, setCurrentMemberId] = useState(0)

  const dispatch = useDispatch()
  const { borrower } = useSelector((state: any) => {
    return {
      borrower: state.borrower.borrower,
    }
  })
  const borrowerSeperator = 'borrower'

  useEffect(() => {
    setLoading(true)
    setInit(true)
    getAdditionalMembers()
      .then(({ additionalMembers: members = [] }) => {
        setData(members)
        setLength(members.length)
        // setDocuments(trackDocuments)
      })
      .finally(() => {
        setLoading(false)
        setInit(false)
      })
  }, [])

  const onSubmit = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      setLoading(true)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        createAdditionalMember(values)
          .then(() => {
            resolve(values)
            setLength(length + 1)

            const newData = cloneDeep(data)
            newData.push(values as BorrowerAdditionalMember)
            setData(newData)
          })
          .finally(() => setLoading(false))
      } else {
        const index = data.findIndex((d) => d.id == currentId)
        values = {
          ...data[index],
          ...values,
        }

        updateAdditionalMember(currentId, values)
          .then(() => {
            const newData = cloneDeep(data)
            const index = newData.findIndex((d) => d.id == currentId)
            if (index != -1) newData[index] = values as BorrowerAdditionalMember
            setData(newData)

            resolve(values)
          })
          .finally(() => setLoading(false))
      }
    })
  }

  const onRemove = async (id: any) => {
    return new Promise((resolve) => {
      setLoading(true)
      deleteAdditionalMember(id)
        .then(() => {
          resolve(true)
          setLength(length - 1)

          const newData = cloneDeep(data)
          const index = newData.findIndex((d) => d.id == id)
          newData.splice(index, 1)
          setData(newData)
        })
        .finally(() => setLoading(false))
    })
  }

  const inputLogic = (data: any) => {
    return data
  }

  const canEdit = canEditLoanApplication()

  const onChange = async (key: string, value: boolean) => {
    await dispatch(setBorrowerGroupData(borrowerSeperator, { [key]: value }))

    setLoading(true)
    const reqBody = {
      [key]: value,
      borrowerSeperator,
    }
    await borrowerInfoUpdate(reqBody)

    setLoading(false)

    console.log(key, setInputs)
  }

  useEffect(() => {
    additionalMemberValidate(borrower.hasAdditionalMember, length, true)
  }, [borrower.hasAdditionalMember, length])

  const showHistory = async (key: string) => {
    const options = {
      table: 'Borrower',
      field: `${borrowerSeperator}-${key}`,
      keys: {
        field: key,
        borrowerSeperator,
      },
    }
    openAuditLog(options)
  }

  const onChangeMemberDetail = async (
    member: BorrowerAdditionalMember,
    index: number,
    updateToServer: boolean = true,
  ) => {
    const newData = cloneDeep(data)
    newData[index] = member
    setData(newData)

    if (updateToServer) await updateAdditionalMember(`${member.id}`, member as Record<string, any>)
  }

  return (
    <div>
      <div
        className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4 text-[15px]"
        role="alert"
      >
        A credit report is required for any non borrowing entity member with an interest in the entity of 20% or
        greater!
      </div>
      <div className="my-4">
        {Object.keys(inputs).map((key: string, index: number) => {
          let input = inputs[key]
          input.value = borrower.hasAdditionalMember
          let error = ''
          if (!init) {
            if (borrower.hasAdditionalMember === undefined || borrower.hasAdditionalMember === null)
              error = REQUIRED_FIELD_ERROR_MESSAGE
          }
          input.error = error
          input.disabled = !canEdit

          return (
            <div className="w-fit" key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} />
            </div>
          )
        })}
      </div>

      {borrower.hasAdditionalMember !== false && (
        <div>
          <div className="mb-4">
            <FormTable
              header={header}
              addText="Add Member"
              permission={canEdit ? 1 : 3}
              inputs={defaultInputs}
              inputLogic={inputLogic}
              defaultData={data}
              onSubmit={onSubmit}
              onRemove={onRemove}
              type={'AdditionalMember'}
            />
          </div>
          {borrower.hasAdditionalMember === true && length === 0 && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[14px]"
              role="alert"
            >
              At least 1 additional member is required!
            </div>
          )}
        </div>
      )}

      {borrower.hasAdditionalMember &&
        data.map((member, index) => {
          const isSelected = member.id === currentMemberId
          return (
            <React.Fragment key={member.id}>
              <div
                className={`flex justify-between items-center border border-b-[2px] hover:ring-[3px] hover:ring-gray-300 rounded-t-lg px-2 py-4 hover:bg-gray-50 cursor-pointer mt-2 px-4 ${
                  isSelected && 'bg-gray-100 ring-[3px] ring-gray-300'
                }`}
                onClick={() => setCurrentMemberId(isSelected ? 0 : member.id)}
              >
                <p className="flex gap-2 items-end">
                  <span className="text-[16px] font-bold">
                    {index + 1}. {member.firstName} {member.lastName}
                  </span>
                  <span className="text-md">({member.email})</span>
                </p>
                <div className="flex items-center">
                  {member.signUrlSignature && (
                    <span className={`font-bold mx-2 text-sm text-${member.signedPdfKey ? 'green' : 'yellow'}-600`}>
                      {member.signedPdfKey ? 'Signed' : 'Pending'}
                    </span>
                  )}
                  {member.creditFileKey && (
                    <span className="mx-2 w-6 h-6 mt-1">
                      <IdentificationIcon className="text-green-600 w-5 h-5" />
                    </span>
                  )}
                  {isSelected ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
                </div>
              </div>
              {isSelected && (
                <MemberDetail
                  data={member as any}
                  onChange={(member: BorrowerAdditionalMember, updateToServer: boolean) =>
                    onChangeMemberDetail(member, index, updateToServer)
                  }
                />
              )}
            </React.Fragment>
          )
        })}
    </div>
  )
}
