import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { downloadFile, getPostClosingFields, submitPostClosingPDF } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { renderParagraph } from 'utils/RenderParagraph'

import { confirmGenerate } from '../ConfirmGenerateDialog'
import { allongeParagraph } from './allongeParagraph'
import { defaultInputs } from './constants'

export function Allonge(props: any) {
  const [download, setDownload] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const paragraphObj = allongeParagraph(inputs)

  useEffect(() => {
    const nInputs = cloneDeep(inputs)
    props.setLoading(true)
    getPostClosingFields('allonge').then((data) => {
      Object.keys(nInputs).forEach((key) => {
        nInputs[key].value = data[key] || ''
        nInputs[key].error = InputValidate(nInputs[key])
      })
      setInputs(nInputs)
      props.setLoading(false)
    })
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
  }

  const onGenerate = async () => {
    const values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      const { value } = inputs[key]
      // if (error) hasError = true
      values[key] = value
    })
    if (hasError) return

    const result = await confirmGenerate('Allonge')
    if (!result) return

    setDownload(true)
    props.setLoading(true)
    values.result = result
    const res = await submitPostClosingPDF('allonge', values)
    if (result !== 'email') downloadFile(`Note Allonge - ${values.loanNumber}.pdf`, res)
    toast('Successfully completed', { type: 'success' })
    setDownload(false)
    props.setLoading(false)
  }

  return (
    <div>
      <p className="text-center text-lg font-bold mt-5">Note Allonge</p>

      <div className="grid grid-cols-3 gap-4 mt-6">{renderParagraph(paragraphObj, 0, onChange)}</div>

      <div className="flex flex-col items-center justify-center mt-10">
        {renderParagraph(paragraphObj, 1, onChange)}
      </div>

      <p className="text-center text-lg mt-10">Without Recourse</p>

      <div className="grid grid-cols-6 gap-2 my-5">{renderParagraph(paragraphObj, 2, onChange)}</div>

      <div className="grid grid-cols-6 gap-2 my-5">{renderParagraph(paragraphObj, 3, onChange)}</div>

      <div className="block text-center">
        <Button onClick={onGenerate} className="px-10" loading={download}>
          Generate Document
        </Button>
      </div>
    </div>
  )
}
