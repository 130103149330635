import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputValidate, removeComma, solveDecimalJavascriptSum } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { priceFields } from '../constants'

interface IProps {
  data: number | undefined
  isOpen: boolean
  isPoints: boolean
  updatePrice: (data: number) => void
  onClose: () => void
}

export const EditPrice = (props: IProps) => {
  const { data, isOpen, isPoints, updatePrice, onClose } = props
  const [inputs, setInputs] = useState<Record<string, InputType>>(priceFields())

  useEffect(() => {
    if (data !== undefined) {
      const newInputs = cloneDeep(inputs)
      newInputs.Price.value = isPoints ? solveDecimalJavascriptSum([100, -1 * data]) : data
      setInputs(newInputs)
    }
  }, [data])

  const onEditPrice = (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onUpdatePrice = () => {
    const newInputs = cloneDeep(inputs)
    let hasError = false

    Object.keys(newInputs).forEach((key) => {
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
    })
    setInputs(newInputs)
    if (hasError) return false

    const newPrice = isPoints
      ? solveDecimalJavascriptSum([100, -1 * removeComma(newInputs.Price.value)])
      : removeComma(newInputs.Price.value)
    updatePrice(newPrice)
    return true
  }

  return (
    <Modal
      title={`Edit ${isPoints ? 'Points' : 'Price'}`}
      titleOkay={`Save`}
      isOpen={isOpen}
      onOk={onUpdatePrice}
      onClose={onClose}
    >
      <div className="mb-2 md:min-w-[480px] text-left font-normal">
        {Object.keys(inputs).map((key, index) => {
          return (
            <div className="w-full mb-4" key={index}>
              <RenderInput input={inputs[key]} Key={key} onChange={onEditPrice} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
