import {
  ArrowsRightLeftIcon,
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions'
import LockIcon from 'assets/locked.svg'
import signImg from 'assets/sign.png'
import cloneDeep from 'clone-deep'
import { ClosingParty } from 'components/ClosingParty'
import { LayoutLoading } from 'components/LayoutLoading'
import { LoanDetail } from 'components/LoanDetail'
import { LoanProcessSelection } from 'components/LoanProcessSelection'
import { LoanStatus } from 'components/LoanStatus'
import { ServicingStatus } from 'components/ServicingStatus'
import { AccountType, companyName, IS_FINRESI_SYNC, loanStatusConstants } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { LoanApplication } from 'pages/LoanApplication'
import { LoanStructure } from 'pages/LoanStructure'
import { LoanSubmission } from 'pages/LoanSubmission'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { closingPartiesMap, LoanType, partiesMap } from 'reducers/loanDetail.reducer'
import {
  getduplicateLoans,
  loanStatusChange,
  makeConnectionToBytepro,
  pushLoanToYieldeasy,
  saveLoanProcess,
} from 'services'
import { svgLoading } from 'stories/assets'
import { Button, CopyableText, Toggle } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { confirm, formatDate, formatTime, getPrice2decimal, openAuditLog, PermissionGate, useTitle } from 'utils'
import { isConstructionLoan } from 'utils/isConstructionLoan'

import { CloneFileDialog } from './CloneFileDialog'
import { History } from './history'
import { loanOverviewAndStepLogic, stepUpgradeWhenRestructureReleaseNoToYes } from './loanOverviewAndStepLogic'
import { PushDocumentsDialog } from './modal'
import { LoanOverviewItem } from './overviewItem'

export function LoanOverview() {
  const { loan, loanDetail, step, auth, borrower } = useSelector((state: any) => {
    return {
      loan: state.loan,
      loanDetail: state.loanDetail,
      step: state.step,
      auth: state.auth,
      borrower: state.borrower,
    }
  })

  const { hasPermission } = usePermissions()

  const manageServicing = hasPermission('SERVICING_PIPELINE')

  const dispatch = useDispatch()

  const [loanLoaded, setLoanLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [menu, setMenu] = useState('overview')
  const [action, setAction] = useState('')
  const [isOpen, setIsOpen] = useState('')
  const [duplicateLoans, setDuplicateLoans] = useState<Array<any>>([])
  const [header, setHeader] = useState({
    goBack: 'Return to Pipeline',
    title: 'Loan Overview',
  })

  const location = useLocation()
  const navigate = useHistory()

  const isAdmin = auth.profile.accountType === AccountType.ADMIN

  useEffect(() => {
    const params = location.pathname.split('/')
    if (params[3] !== 'new') {
      getduplicateLoans(params[3]).then((res: any) => {
        if (res.success) {
          setDuplicateLoans(res.data)
        }
      })
    }
  }, [])

  useEffect(() => {
    const params = location.pathname.split('/')
    setLoading(true)
    const newMenu = params[2]
    setMenu(newMenu)
    menuChanged(newMenu, params[3])
    if (params[3] === 'new') setDuplicateLoans([])
  }, [location.pathname])

  useEffect(() => {
    const params = location.pathname.split('/')
    useTitle(`${params[3]} - ${header.title} - ${companyName}`)
  }, [header])

  const menuChanged = async (newMenu: string, loanNumber: string) => {
    if (!loanLoaded || loanNumber === 'new') {
      setLoading(true)
      const params = location.pathname.split('/')
      const res: any = await loanOverviewAndStepLogic(params[3], newMenu)
      setLoanLoaded(true)
      setLoading(false)
      if (res.success === false) {
        if (res.message === 'Redirect Pipeline') return navigate.push('/pipeline')
        if (res.message === 'Redirect Overview') return navigate.push(location.pathname.replace(newMenu, 'overview'))
      }
    }
    setLoading(false)
    let newHeader = cloneDeep(header)
    switch (newMenu) {
      case 'overview':
        newHeader.goBack = 'Return to Pipeline'
        newHeader.title = 'Loan Overview'
        break
      case 'structure':
        newHeader.goBack = 'Return to Loan Overview'
        newHeader.title = 'Loan Structure'
        setLoanLoaded(false)
        break
      case 'application':
        newHeader.goBack = 'Return to Loan Overview'
        newHeader.title = 'Loan Application'
        setLoanLoaded(false)
        break
      case 'submit':
        newHeader.goBack = 'Return to Loan Overview'
        newHeader.title = 'Loan Submission'
        setLoanLoaded(false)
        break
    }
    setHeader(newHeader)
  }

  const changeUrl = (key: string) => {
    navigate.push(location.pathname.replace(menu, key))
  }

  const onEdit = (key: string) => {
    changeUrl(key)
  }

  const renderExternalLink = (
    url: string,
    title: string,
    permission: string | Array<string> = '',
    notAllowedProfiles: Array<string> = [],
  ) => {
    if (permission !== '') {
      if (!hasPermission(permission)) return null
    }
    if (notAllowedProfiles.indexOf(auth.profile.accountType) !== -1) return null

    let disabled = false
    if (url === 'closing_calendar') {
      if (loanStatusConstants.closeStatus.indexOf(loanDetail.loanStatus) === -1) disabled = true
    }
    if (url === 'secondary_marketing_screen') {
      if (loanDetail.byteproFileName === 0) disabled = true
    }

    const linkElement = (
      <span className="flex gap-2 items-center">
        <span>{title}</span>
        <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
      </span>
    )
    return (
      <li
        className={`my-2.5 hover:underline w-fit ${disabled ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer'}`}
        key={url}
      >
        {disabled ? linkElement : <Link to={`/${url}/${loanDetail.loanNumber}`}>{linkElement}</Link>}
      </li>
    )
  }

  const onLoanStatusChange = (key: string, status: string) => {
    dispatch(
      setLoanDetail({
        [key]: status,
      }),
    )
  }

  const onMoveToServicing = () => {
    dispatch(
      setLoanDetail({
        servicingPipeline: true,
        servicingStatus: 'servicing',
      }),
    )
  }

  const getLoanNumber = () => {
    let rlt = loanDetail.loanNumber
    if (loanDetail.byteproFileName !== 0) {
      rlt = loanDetail.byteproFileName
    }
    return rlt
  }

  const pushToYieldeasy = async () => {
    if (!IS_FINRESI_SYNC) return
    const result = await confirm('Are you sure to push this loan to Finresi?')
    if (!result) return
    setAction('pushToYieldeasy')
  }

  const onPushYieldeasyDocs = (docKeys: string[], isForce = false) => {
    if (!IS_FINRESI_SYNC) return
    setAction('pushingToYieldeasy')
    pushLoanToYieldeasy({ docKeys, isForce })
      .then(async ({ status, pushedToYieldeasyAt }) => {
        if (status) {
          toast('Pushed to Finresi successfully.', { type: 'success' })
          dispatch(setLoanDetail({ pushedToYieldeasyAt }))
        } else toast('This loan has already been pushed to Finresi.', { type: 'warning' })
      })
      .finally(() => setAction(''))
  }

  const renderHeader = () => {
    let goBackPath = '/pipeline'
    let headerTitle = header.title
    if (header.goBack === 'Return to Loan Overview') {
      const params = location.pathname.split('/')
      goBackPath = location.pathname.replace(params[2], 'overview')
    }
    const { broker, loanOfficer } = loanDetail.parties
    return (
      <div className="relative">
        <LayoutLoading show={loading} />
        {duplicateLoans.length > 0 && (
          <div className="mb-4">
            <div className="text-red-700 font-semibold mb-1 ml-2">* Potential Duplicate File</div>
            <div className="overflow-auto">
              <div className="w-fit">
                <table className={`text-sm text-left text-gray-900 dark:text-gray-400 pl-6`}>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      {[
                        'Loan #',
                        'Property Address',
                        'Borrower Name',
                        'Entity Title',
                        'Loan Status',
                        'Servicing Status',
                        'Broker',
                      ].map((item, index) => (
                        <th scope="col" className={`px-4 py-2 border`} key={index}>
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {duplicateLoans.map((item: any, index: number) => {
                      return (
                        <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                          <td className="border px-4 py-1 text-shade-blue hover:underline cursor-pointer">
                            <Link to={`/loan_process/overview/${item.no}`} target="_blank">
                              {item.byteproFileName || item.no}
                            </Link>
                          </td>
                          <td className="border px-4 py-1">{item.subjectPropertyAddress}</td>
                          <td className="border px-4 py-1">
                            {item.borrowerFirstName} {item.borrowerLastName}
                          </td>
                          <td className="border px-4 py-1">{item.entityTitle}</td>
                          <td className="border px-4 py-1">
                            {item.loanstatus}
                            <br />
                            <span className="italic">{formatDate(item.asOf)}</span>
                          </td>
                          <td className="border px-4 py-1">
                            {item.servicingStatus}
                            <br />
                            <span className="italic">{formatDate(item.servicingAsOf)}</span>
                          </td>
                          <td className="border px-4 py-1">{item.brokerCompany}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="text-shade-blue flex items-center mb-2 font-bold">
          <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
          <span className="ml-1 cursor-pointer hover:underline" onClick={() => navigate.push(goBackPath)}>
            {header.goBack}
          </span>
        </div>
        <div className="flex flex-wrap justify-between">
          <h1 className="text-2xl font-bold flex gap-3 items-center mb-2">
            <CopyableText
              copyText={`${getLoanNumber()} | ${loanDetail.entityTitle} | ${loanDetail.borrowerName} | ${
                loanDetail.propertyAddress
              }`}
            >
              {headerTitle}
            </CopyableText>
            <span className="text-base ml-3">
              {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
            </span>
            {!loading && loanDetail.loanType == LoanType.CORRESPONDENT && (
              <Tooltip message={'Corespondent Loan'}>
                <span className="cursor-pointer">
                  <ArrowsRightLeftIcon className="w-5 h-5" />
                </span>
              </Tooltip>
            )}
            {!loading && loanDetail.loanType == LoanType.RETAIL && (
              <Tooltip message={'Retail Loan'}>
                <span className="cursor-pointer">
                  <ShoppingCartIcon className="w-5 h-5" />
                </span>
              </Tooltip>
            )}
            {!loading && loanDetail.loanType == LoanType.BROKERED && (
              <Tooltip message={'Brokered Loan'}>
                <span className="cursor-pointer">
                  <ShoppingCartIcon className="w-5 h-5" />
                </span>
              </Tooltip>
            )}
            {!loading && loanDetail.loanType == LoanType.TABLEFUNDER && (
              <Tooltip message={'Table Funder Loan'}>
                <span className="cursor-pointer">
                  <TableCellsIcon className="w-5 h-5" />
                </span>
              </Tooltip>
            )}
            {!loading && step.overview.application.detail.signApplication > 0 && (
              <Tooltip message={'Application Signed'}>
                <span className="w-6 h-6 mt-1">
                  <img src={signImg} alt="sign-img" />
                </span>
              </Tooltip>
            )}
            {!loading && step.overview.structure.detail.lockRate > 0 && (
              <span className="">
                <img src={LockIcon} className="w-6 h-6 text-white" />
              </span>
            )}
            {loanDetail.rush && (
              <div className="px-2 rounded bg-red-100 border-red-400 text-red-700 text-[16px] h-fit">Rush</div>
            )}
          </h1>
        </div>
        <div className="mt-1 flex flex-wrap gap-4">
          <div className="">
            <LoanDetail
              loan_number={loading ? '' : getLoanNumber()}
              entity_title={loading ? '' : loanDetail.entityTitle}
              borrower_name={loading ? '' : loanDetail.borrowerName}
              property_address={loading ? '' : loanDetail.propertyAddress}
            />
          </div>
          <div className="md:mx-6 flex flex-wrap">
            <div className="mr-2 text-[14px]">Loan Status:</div>
            <div>
              <div className="md:-mt-2">
                <LoanStatus
                  key={`${loanDetail.loanStatus}-${loanDetail.loanNumber}`}
                  status={loanDetail.loanStatus}
                  servicingPipeline={loanDetail.servicingPipeline}
                  onChange={(status: string) => onLoanStatusChange('loanStatus', status)}
                  onMoveServicing={() => onMoveToServicing()}
                  loanNumber={loanDetail.loanNumber}
                />
                {manageServicing && loanDetail.servicingPipeline && loanDetail.loanStatus === 'funded' && (
                  <div className="mt-1">
                    <ServicingStatus
                      key={`${loanDetail.servicingStatus}-${loanDetail.loanNumber}`}
                      status={loanDetail.servicingStatus}
                      onChange={(status: string) => onLoanStatusChange('servicingStatus', status)}
                      loanNumber={loanDetail.loanNumber}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION') && (
            <div className="score max-w-[500px]">
              <table className="text-[13.5px]">
                <thead>
                  <tr>
                    <th className="bg-gray-100 border font-normal px-2 py-1" rowSpan={2}>
                      Party
                    </th>
                    <th className="bg-gray-100 border font-normal px-2 py-1" rowSpan={2}>
                      Name
                    </th>
                    <th className="bg-gray-100 border font-normal px-2 py-1 text-center" colSpan={2}>
                      Pull Through Ratio
                    </th>
                  </tr>
                  <tr>
                    <th className="bg-gray-100 border font-normal px-2 py-1">Lifetime</th>
                    <th className="bg-gray-100 border font-normal px-2 py-1">Recent Quarter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border px-2 py-1">Broker</td>
                    <td className="border px-2 py-1">{broker?.companyName}</td>
                    <td className="border px-2 py-1">
                      {broker?.score && (
                        <Tooltip
                          message={`<div class="leading-[16px]">Submitted: ${broker.score.created}<br/>Funded: ${broker.score.funded}<br/>Withdrawn: ${broker.score.withdrawn}</div>`}
                        >
                          <span className="flex gap-1 items-center cursor-pointer">
                            {getPrice2decimal(broker.score.value, false, true)}{' '}
                            <QuestionMarkCircleIcon className="w-[14px] h-[14px]" />
                          </span>
                        </Tooltip>
                      )}
                    </td>
                    <td className="border px-2 py-1">
                      {broker?.quarterScore && (
                        <Tooltip
                          message={`<div class="leading-[16px]">Submitted: ${broker.quarterScore.created}<br/>Funded: ${broker.quarterScore.funded}<br/>Withdrawn: ${broker.quarterScore.withdrawn}</div>`}
                        >
                          <span className="flex gap-1 items-center cursor-pointer">
                            {getPrice2decimal(broker.quarterScore.value, false, true)}{' '}
                            <QuestionMarkCircleIcon className="w-[14px] h-[14px]" />
                          </span>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="border px-2 py-1">Loan Officer</td>
                    <td className="border px-2 py-1">{loanOfficer?.name}</td>
                    <td className="border px-2 py-1">
                      {loanOfficer?.score && (
                        <Tooltip
                          message={`<div class="leading-[16px]">Submitted: ${loanOfficer.score.created}<br/>Funded: ${loanOfficer.score.funded}<br/>Withdrawn: ${loanOfficer.score.withdrawn}</div>`}
                        >
                          <span className="flex gap-1 items-center cursor-pointer">
                            {getPrice2decimal(loanOfficer.score.value, false, true)}{' '}
                            <QuestionMarkCircleIcon className="w-[14px] h-[14px]" />
                          </span>
                        </Tooltip>
                      )}
                    </td>
                    <td className="border px-2 py-1">
                      {loanOfficer?.quarterScore && (
                        <Tooltip
                          message={`<div class="leading-[16px]">Submitted: ${loanOfficer.quarterScore.created}<br/>Funded: ${loanOfficer.quarterScore.funded}<br/>Withdrawn: ${loanOfficer.quarterScore.withdrawn}</div>`}
                        >
                          <span className="flex gap-1 items-center cursor-pointer">
                            {getPrice2decimal(loanOfficer.quarterScore.value, false, true)}{' '}
                            <QuestionMarkCircleIcon className="w-[14px] h-[14px]" />
                          </span>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderSteps = () => {
    switch (menu) {
      case 'overview':
        return renderOverview()
      case 'structure':
        return <LoanStructure loan_number={loanDetail.loanNumber} />
      case 'application':
        return <LoanApplication />
      case 'submit':
        return <LoanSubmission />
    }
  }

  const renderOverview = () => {
    let rlt: any[] = []
    Object.keys(step.overview).map((key, index) => {
      rlt.push(
        <LoanOverviewItem
          step={step}
          loanDetail={loanDetail}
          borrower={borrower}
          itemKey={key}
          index={index}
          onEdit={onEdit}
          setLoading={setLoading}
        />,
      )
    })
    const { parties } = loanDetail
    if (loanDetail.loanNumber !== 'New') {
      rlt.push(
        <div
          className={`flex flex-col md:col-span-6 col-span-12 p-2 md:p-4 shadow rounded-md text-[15px]`}
          key={'parties'}
        >
          <div className="flex border-b font-bold">
            <span className="flex-auto">Parties</span>
            {(hasPermission('MANAGE_PARTIES') || hasPermission('MANAGE_PARTIES_INSURANCE_TITLE')) && (
              <Link to={`/parties/${loanDetail.loanNumber}`}>
                <span className="flex gap-2 items-center text-shade-blue text-[13px] hover:underline">
                  <span>View All</span>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                </span>
              </Link>
            )}
          </div>
          <div className="overflow-auto">
            <table className="w-full">
              <tbody className="text-[14px] text-gray-900">
                {Object.keys(parties).map((key, index) => {
                  const bgCn = index % 2 ? 'bg-gray-50' : ''
                  return (
                    <tr key={index} className={`${bgCn} border-b`}>
                      <td className="p-1 border">{partiesMap[key]}</td>
                      <td className="p-1 border">
                        {key === 'creator' && !isAdmin ? (
                          <span className="font-variation-settings-600">{parties[key].email}</span>
                        ) : (
                          <LoanProcessSelection id={key} type={'party'} />
                        )}
                      </td>
                    </tr>
                  )
                })}
                {loanDetail.byteproFileName !== 0 &&
                  Object.keys(closingPartiesMap).map((key, index) => {
                    const bgCn = index % 2 ? '' : 'bg-gray-50'
                    return (
                      <tr key={`closing-${index}`} className={`${bgCn} border-b`}>
                        <td className="p-1 border">{closingPartiesMap[key]}</td>
                        <td className="p-1 border">
                          <ClosingParty id={key} />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>,
      )

      rlt.push(
        <div
          className={`relative flex items-center gap-4 flex-wrap col-span-12 p-2 md:p-4 shadow rounded-md text-[15px]`}
          key={'others'}
        >
          <LayoutLoading show={action === 'changeLoanChannel'} />
          <div className="">
            <div className="">
              <Button color="gray" className="py-[5px] mb-0" onClick={() => setIsOpen('CloneFileDialog')}>
                <span className="flex items-center gap-2">Create Additional File for this Borrower</span>
              </Button>
            </div>
          </div>
          <CloneFileDialog isOpen={isOpen === 'CloneFileDialog'} onClose={() => setIsOpen('')} />
        </div>,
      )
    }

    const connectBytepro = () => {
      makeConnectionToBytepro()
    }

    return (
      <div className="grid md:grid-cols-6 md:gap-10 max-w-screen-2xl m-auto">
        <div className="md:col-span-4 p-2 md:p-4 shadow1 rounded mb-4">
          <div className="grid grid-cols-12 gap-4 md:gap-8 flex-wrap">{rlt}</div>
          {loanDetail.loanStatus === 'appSigned' && isAdmin && loanDetail.byteproFileName === 0 && (
            <div className="mt-5 ml-5 text-shade-blue hover:underline cursor-pointer w-fit" onClick={connectBytepro}>
              Submit to Bytepro
            </div>
          )}
        </div>
        <div className="md:col-span-2 p-4 md:p-6 shadow1 rounded mb-4">
          <div className="text-[18px] font-bold flex items-center border-b pb-1 border-gray-300">
            <span>Quick Links</span>
          </div>
          <ol className="ml-2 list-decimal pl-4">
            {Object.keys(step.overview).map((key, index) => {
              const item = step.overview[key]
              const disabled = item.status === -1
              let cn = 'cursor-pointer hover:underline'
              if (disabled) {
                cn = 'cursor-not-allowed text-gray-400'
              }
              let show = true
              if (['structure', 'application'].indexOf(key) !== -1) {
                if (!hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')) show = false
                if (loanDetail.loanNumber !== 'New') show = false
              }
              if (!show) return null
              let { label } = item
              if (key === 'submit') label = 'Conditions'
              return (
                <li className={`my-2.5 ${cn}`} key={index} onClick={() => !disabled && onEdit(key)}>
                  {label}
                </li>
              )
            })}
            {loanDetail.loanNumber !== 'New' && [
              renderExternalLink('loan_change_request', 'Loan Change Request'),

              renderExternalLink('order_valuation', 'Order Valuation'),

              renderExternalLink('tasks/loan', 'Tasks', 'MANAGE_TASKS'),

              isAdmin && renderExternalLink('document_genie', 'Documents Genie'),

              renderExternalLink('notes', 'Notes', 'ADMIN_TO_AE_PROFILE_PERMISSION'),

              renderExternalLink('closing_calendar', 'Closing Calendar'),

              renderExternalLink('loanInTake', 'Loan Intake', ['ADMIN_TO_AE_PROFILE_PERMISSION']),

              renderExternalLink('secondary_marketing_screen', 'Secondary Marketing', 'MANAGE_ADMIN_TOOLS'),

              renderExternalLink('underwriting', 'Underwriting', [
                'ADMIN_TO_AE_PROFILE_PERMISSION',
                'MANAGE_APPRAISAL_INFORMATION',
              ]),

              renderExternalLink('closing_screen', 'Closing Screen', 'ADMIN_TO_AE_PROFILE_PERMISSION'),

              renderExternalLink('post_closing', 'Post Closing', 'MANAGE_POST_CLOSING_PDF'),

              renderExternalLink('servicing', 'Servicing', [
                'MANAGE_TRUST_LEDGER',
                'MANAGE_SERVICING_DOCS',
                'MANAGE_SERVICING_NOTES',
                'MANAGE_SERVICING_PARTICIPATION',
              ]),

              isConstructionLoan(loan.productType) &&
                renderExternalLink('budget', 'Budget & Scope of Work', 'BUDGET_SCOPE_OF_WORK'),
              renderExternalLink('funding', 'Funding', 'MANAGE_FUNDING'),
              renderExternalLink('amortization_schedule', 'Amortization Schedule', 'ADMIN_TO_AE_PROFILE_PERMISSION'),
              isConstructionLoan(loan.productType) &&
                renderExternalLink('contractor_application', 'Contractor / Builder Application'),
              renderExternalLink('invoice', 'Invoice', 'INVOICE'),
            ]}
          </ol>
          <PermissionGate permission={'MANAGE_LOAN_PROGRESS_RESTRICTION'} hidden={true}>
            <div className="text-[18px] font-bold flex items-center mt-4 border-b pb-1 border-gray-300">
              <span>Loan Progress Restrictions</span>
            </div>
            <div className="ml-2 relative">
              <LayoutLoading show={action === 'onPermissionChange'} />
              <div className="mt-4">
                <Toggle
                  className=""
                  id={'restructureApplication'}
                  title={'Restructure Loan Release?'}
                  key={'restructureApplication'}
                  value={loanDetail.restructureApplicationRelease}
                  history={true}
                  disabled={!hasPermission('MANAGE_RESTRUCTURE_APPLICATION_RELEASE')}
                  showHistory={() => showHistory('restructureApplicationRelease')}
                  onChange={(value) => onPermissionChange('restructureApplicationRelease', value)}
                />
              </div>
            </div>
          </PermissionGate>
          {isAdmin && (
            <div className="text-[14px] font-bold flex items-center my-4 border-b pb-1 border-gray-300 mt-6">
              <span className="mr-4 hover:underline cursor-pointer">
                <Link to={`/bytepro_logs/${loanDetail.loanNumber}`} target="_blank">
                  Bytepro API Logs
                </Link>
              </span>
              <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
            </div>
          )}
          {loanDetail.loanStatus === 'funded' && hasPermission('PUSH_TO_FINRESI') && IS_FINRESI_SYNC && (
            <div className={`relative my-2 pb-1 mt-6 border-b`}>
              {loanDetail.pushedToYieldeasyAt == null ? (
                <div className="text-[14px] font-bold flex items-center" onClick={pushToYieldeasy}>
                  <span className="mr-4 hover:underline cursor-pointer">Push to Finresi</span>
                  <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
                </div>
              ) : (
                <p className="text-[14px] text-gray-600">
                  Sent to Finresi on {formatTime(loanDetail.pushedToYieldeasyAt)}
                </p>
              )}
              <LayoutLoading show={action === 'pushingToYieldeasy'} />
            </div>
          )}
        </div>
      </div>
    )
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'LoanProcess',
      field: key,
      keys: {
        field: key,
        loanNumber: loanDetail.loanNumber,
      },
    }
    openAuditLog(options)
  }

  const onPermissionChange = async (key: string, value: boolean) => {
    if (key === 'restructureApplicationRelease') {
      const content =
        value === false ? (
          <div className="mb-4 text-[18px]">
            <div>
              Are you sure want to <span className="font-bold text-shade-blue">Lock</span> this loan?
            </div>
            <div className="text-[16px] mt-3">When you unlock this loan you will need to Re-request signatures.</div>
          </div>
        ) : (
          <div className="mb-4 text-[18px]">
            Please confirm you wish to <span className="font-bold text-shade-blue">Release</span> the loan back to the
            broker.
          </div>
        )
      const result = await confirm(content)
      if (!result) return
    }
    const permissionData = {
      [key]: value,
      historyAction: 'Log',
    }
    setAction('onPermissionChange')
    await saveLoanProcess(permissionData)
    if (key === 'restructureApplicationRelease' && value === true) {
      await stepUpgradeWhenRestructureReleaseNoToYes()
      const res = await loanStatusChange('restructureRelease', loanDetail.loanNumber)
      if (res.success) {
        onLoanStatusChange('loanStatus', 'restructureRelease')
      }
    }
    dispatch(setLoanDetail(permissionData))
    setAction('')
  }

  return (
    <div className="Loanoverview-container py-6 px-2">
      <div className="shadow1 mb-6 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w-full">
        {renderHeader()}
      </div>
      {!loading && renderSteps()}
      {menu !== 'submit' && <History loanNumber={loanDetail.loanNumber} />}
      {action == 'pushToYieldeasy' && (
        <PushDocumentsDialog onClose={() => setAction('')} onSubmit={onPushYieldeasyDocs} />
      )}
    </div>
  )
}
