import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'
import { ButtonOption, MultiConfirmModal } from 'stories/components'

const MultiConfirmDialog = ({ show, proceed, confirmation, options }: ReactConfirmProps | any) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  return (
    <MultiConfirmModal
      isOpen={show}
      onOk={(v?: string) => proceed(v)}
      onCancel={() => proceed(null)}
      lastUpdatedAt={lastUpdatedAt}
      content={confirmation}
      options={options}
    />
  )
}

const multiConfirmDlg = confirmable(MultiConfirmDialog)

const createConfirm = createConfirmation(multiConfirmDlg)

export const multiConfirm = (content: string | JSX.Element, options: Record<string, string | ButtonOption> = {}) =>
  createConfirm({ confirmation: content, options })
