import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { formatDateYMD, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { lockDayFields } from '../constants'

interface IProps {
  data: number | undefined
  isOpen: boolean
  isPoints: boolean
  updateLockDay: (data: number, isUpdate: boolean) => void
  onClose: () => void
}

export const AddLockDay = (props: IProps) => {
  const { data, isOpen, isPoints, updateLockDay, onClose } = props
  const [inputs, setInputs] = useState<Record<string, InputType>>(lockDayFields())

  useEffect(() => {
    if (data !== undefined) {
      const newInputs = cloneDeep(inputs)
      newInputs.LockDay.value = formatDateYMD(moment().add(data, 'days').toDate())
      setInputs(newInputs)
    }
  }, [data])

  const onEditLockDay = (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onUpdateLockDay = () => {
    const newInputs = cloneDeep(inputs)
    let hasError = false
    const reqBody: any = {}

    Object.keys(newInputs).forEach((key) => {
      ;(reqBody as any)[key] = newInputs[key].value
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
    })
    setInputs(newInputs)
    if (hasError) return false

    const lockDays = moment(reqBody.LockDay).diff(moment(), 'days') + 1
    updateLockDay(lockDays, data !== undefined)
    return true
  }

  return (
    <Modal
      title={`${!data ? 'Add' : 'Update'} ${isPoints ? 'Points' : 'Price'}`}
      titleOkay={`${!!data ? 'Update' : 'Add'}`}
      isOpen={isOpen}
      onOk={onUpdateLockDay}
      onClose={onClose}
    >
      <div className="mb-2 md:min-w-[480px] text-left font-normal">
        {Object.keys(inputs).map((key, index) => {
          return (
            <div className="w-full mb-4" key={index}>
              <RenderInput input={inputs[key]} Key={key} onChange={onEditLockDay} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
