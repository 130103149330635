import { LayoutLoading } from 'components/LayoutLoading'
import { TemporaryLayout } from 'layouts'
import type { IBudgetData } from 'pages/Loan/Budget/types'
import { useEffect, useMemo, useState } from 'react'
import { getBudgetTemplate, getBudgetTemplateList } from 'services'
import { Button, PlainTable } from 'stories/components'
import { formatTime, thousandSeperator } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { CreateDrawRequestModal } from './CreateDrawRequestModal'
import type { IDrawRequest } from './type'

export const BorrowerDrawRequest = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<IDrawRequest[]>([])
  const [budgetItemMapping, setBudgetItemMapping] = useState<Record<string, string>>({})
  const [budget, setBudget] = useState<IBudgetData>()
  const [isShowCreate, setShowCreate] = useState(false)
  const [addressMap, setAddressMap] = useState<Record<string, string>>({})

  useEffect(() => {
    setLoanNumber()
    init()
  }, [])

  const init = async () => {
    setLoading(true)

    const { addresses } = await getBudgetTemplateList()
    const initId = Number(Object.keys(addresses)[0])
    setAddressMap(addresses)

    const {
      itemMapping,
      budget: { data },
      drawRequests,
    } = await getBudgetTemplate(initId)

    setBudget(data)
    setBudgetItemMapping(itemMapping)

    setData(drawRequests)
    setLoading(false)
  }

  const onCreate = () => {
    setShowCreate(true)
  }

  const getDrawPermitValue = (v: IDrawRequest) => {
    if (!budget) return <div />

    return (
      <div>
        {v.types
          .map((v) => {
            const { value, key } = v
            const template = budget.items.find((v) => v.value == value)
            if (!template) return ''

            return `${template.label} / ${budgetItemMapping[key]}`
          })
          .map((v) => (
            <p>{v}</p>
          ))}
      </div>
    )
  }

  const cleanedData = useMemo(() => {
    return data.map((v, index) => [
      index + 1,
      v.budgetId ? addressMap[v.budgetId] : '',
      getDrawPermitValue(v),
      `$${thousandSeperator(v.amount)}`,
      v.description,
      v.note,
      formatTime(v.createdAt),
      'New Order',
    ])
  }, [data])

  return (
    <TemporaryLayout title="Draw Request" requireSign>
      <LayoutLoading show={isLoading} />

      <div className="overflow-auto">
        <div className="text-right py-2">
          <Button onClick={onCreate} disabled={!Object.keys(addressMap).length}>
            Add Requests
          </Button>
        </div>
        <PlainTable
          header={[
            'no',
            'Address',
            'Draw / Permits',
            'Amount to be released',
            'Work Description',
            'Note',
            'Requested At',
            'Status',
          ]}
          data={cleanedData}
          thClass="!px-3"
          tdClass="px-3 py-2 whitespace-nowrap"
        />
      </div>
      {isShowCreate && (
        <CreateDrawRequestModal
          onClose={(requireUpdates: boolean) => {
            setShowCreate(false)
            requireUpdates && init()
          }}
        />
      )}
    </TemporaryLayout>
  )
}
