import { useHistory } from 'react-router-dom'
import { Button, Modal } from 'stories/components'
import { setLoanNumber } from 'utils/setLoanNumber'

export const AppSignedModal = () => {
  const navigate = useHistory()

  const onReturn = () => {
    const loanNumber = setLoanNumber()
    navigate.push(`/borrowerPipeline/overview/${loanNumber}`)
  }

  return (
    <Modal isOpen title="Application is now signed." hiddenX titleOkay="" titleCancel="">
      <div className="w-120 text-center">
        <p className="mb-4">Please upload documents from Loan Submission.</p>
        <Button onClick={onReturn}>Return to Loan Overview</Button>
      </div>
    </Modal>
  )
}
