import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import React, { useEffect, useState } from 'react'
import {
  createAccountingCategory,
  deleteAccountingCategory,
  getAccountingCategories,
  getBaseAccountingCategories,
  updateAccountingCategory,
} from 'services'
import { svgLoading } from 'stories/assets'
import { confirm } from 'utils'

import { AccountingRecordTypeOptions } from '../AccountingRecords'
import type { IAccountingCategory } from './types'

export const AccountingCategories = () => {
  const [isLoading, setLoading] = useState(false)
  const [baseCategory, setBaseCategory] = useState<Record<string, Record<number, string>>>({})
  const [data, setData] = useState<IAccountingCategory[]>([])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const [baseCategory, data] = await Promise.all([getBaseAccountingCategories(), getAccountingCategories()])
    setBaseCategory(baseCategory)
    setData(data)
    setLoading(false)
  }

  const onAdd = (type: string, baseId: number, parentId: number) => {
    const newValues = [
      { id: -Date.now(), baseId, base: baseCategory[type][baseId], parentId, value: '' },
      ...cloneDeep(data),
    ]
    setData(newValues)
  }

  const onUpdate = async (item: IAccountingCategory, newValue: string) => {
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) return

    newValue = newValue.trim().toUpperCase()

    const existSameItem = !!data.find((v) => v.baseId == item.baseId && v.value == newValue && v.id != item.id)
    const newValues = cloneDeep(data)

    if (!newValue || existSameItem) {
      newValues[index].invalid = true
      setData(newValues)
      return false
    }

    setLoading(true)
    if (item.id < 0) {
      newValues[index] = await createAccountingCategory({
        baseId: item.baseId,
        parentId: item.parentId,
        value: newValue,
      })
    } else {
      await updateAccountingCategory(item.id, newValue)
      newValues[index].value = newValue
    }
    newValues[index].invalid = false

    setData(newValues)
    setLoading(false)
  }

  const onDelete = async (item: IAccountingCategory) => {
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) return

    if (item.id < 0) {
      const newValues = cloneDeep(data)
      newValues.splice(index, 1)
      setData(newValues)
      return
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this category?
        <br />
        <span className="text-gray-600">Category: {item.value}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    try {
      await deleteAccountingCategory(item.id)

      const newValues = cloneDeep(data)
      newValues.splice(index, 1)
      setData(newValues)
    } catch (e) {}
    setLoading(false)
  }

  const renderRow = (type: string, nBaseId: number, item: IAccountingCategory, index: number, showIndex: boolean) => {
    const hasEmptyVal = !!data.find((v) => v.baseId == nBaseId && v.value == '')
    return (
      <tr
        className={`${item.invalid ? 'bg-red-100' : 'bg-white'} border-b dark:bg-gray-800 dark:border-gray-700`}
        key={index}
      >
        <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
          {showIndex ? `${index + 1}` : ''}
        </td>

        <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
          <PlainInput
            value={item.value || ''}
            content={showIndex ? item.value || '' : `${index + 1}. ${item.value}` || ''}
            className={`h-[30px] ${!showIndex ? '!text-gray-700' : ''}`}
            onChange={(newValue: string) => onUpdate(item, newValue.toUpperCase())}
          />
        </td>

        <td className="px-6 py-1">
          <div className="flex gap-2">
            {item.parentId == 0 && item.id > 0 && (
              <button
                disabled={hasEmptyVal}
                className={`p-1 ${hasEmptyVal ? 'text-blue-300' : 'text-shade-blue hover-shadow1 cursor-pointer'}`}
                onClick={() => onAdd(type, nBaseId, item.id)}
              >
                <PlusIcon className="w-4 h-4"></PlusIcon>
              </button>
            )}
            <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
              <TrashIcon className="w-4 h-4"></TrashIcon>
            </span>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Accounting Categories
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="relative overflow-auto">
        <LayoutLoading show={isLoading} />

        {Object.keys(baseCategory).map((type) => {
          const baseCategoryByType = baseCategory[type]

          return (
            <div key={type}>
              <p className="font-bold mb-2 text-lg">{AccountingRecordTypeOptions[type]}</p>
              {Object.keys(baseCategoryByType).map((baseId, index) => {
                const nBaseId = Number(baseId)
                const categories = data.filter((v) => v.baseId == nBaseId)
                const hasEmptyVal = !!data.find((v) => v.baseId == nBaseId && v.value == '')
                return (
                  <div className="sm:rounded-lg mb-8" key={`category-${baseId}`}>
                    <p className="text-lg mb-2 border-b bg-gray-100 py-2 px-3 border-l-4 border-gray-300">
                      {index + 1}. {baseCategoryByType[nBaseId]}{' '}
                      <span className="ml-1 text-[14px]">({categories.length})</span>
                    </p>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-3 py-3 w-[20px]">
                            No
                          </th>
                          <th scope="col" className="px-3 py-3">
                            Name
                          </th>
                          <th scope="col" className="px-3 py-3">
                            <button
                              className={`font-variation-settings-600 text-sm font-medium ${
                                hasEmptyVal ? 'text-blue-300' : 'text-shade-blue hover:underline'
                              }`}
                              disabled={hasEmptyVal}
                              onClick={() => onAdd(type, nBaseId, 0)}
                            >
                              + Add
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {categories
                          .filter((v) => v.parentId == 0)
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {renderRow(type, nBaseId, item, index, true)}
                              {categories
                                .filter((v) => v.parentId == item.id)
                                .map((child, childIndex) => renderRow(type, nBaseId, child, childIndex, false))}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}
