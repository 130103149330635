import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  ClockIcon,
  CloudArrowUpIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { TargetBox } from 'components/DragDrop'
import { usePermissions } from 'hooks/usePermissions'
import { docStatus } from 'pages/LoanSubmission/constants'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { openS3Document, openS3Documents, uploadFiles } from 'services'
import Api from 'services/api'
import { Checkbox, Select } from 'stories/components'
import { confirm, formatTime, getUniqueFileName } from 'utils'

export interface CategoryDocument {
  category: string
  name: string
  fileKey: string
  createdAt: string | number
  status?: string
  lock?: boolean
  history?: Record<string, any>[]
}

export const CategoryDocuments = forwardRef(
  (
    {
      categories = [],
      documents = [],
      setLoading = () => {},
      onChange,
      onHistory,
      type,
      showStatus = false,
      canChangeStatus = false,
    }: {
      categories: Array<string>
      documents: CategoryDocument[]
      setLoading: Function
      onChange: Function
      onHistory?: Function
      type: String
      showStatus?: Boolean
      canChangeStatus?: Boolean
    },
    ref,
  ) => {
    const { email: currentEmail } = useSelector((state: any) => state.auth.profile)

    const [selected, setSelected] = useState<Record<string, boolean>>({})
    const loanNumber = Number(Api.getLoanNumber())
    const hasHistory = !!onHistory

    useEffect(() => {
      if (!documents.length) setSelected({})
    }, [documents])

    useImperativeHandle(ref, () => ({
      onAddNew(doc: CategoryDocument) {
        const newDocs: CategoryDocument[] = cloneDeep(documents)
        addHistory(doc)
        newDocs.push(doc)
        onChange(newDocs)
      },
    }))

    const isSelectedBtnDisabled = useMemo(
      () => Object.keys(selected).filter((fileKey) => documents.find((item) => item.fileKey === fileKey)).length == 0,
      [selected, documents],
    )

    const allFileNames = useMemo(() => documents.map((doc) => doc.name), [documents])

    const { hasPermission } = usePermissions()
    const isAdminAe = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

    const onRemoveDocument = async (item: CategoryDocument) => {
      const content = (
        <div className="text-gray-600 mb-4 text-[16px]">
          Do you want to remove this Document?
          <br />
          <span className="text-gray-900">Document name: {item.name}</span>
        </div>
      )
      const result = await confirm(content)
      if (!result) return

      const index = documents.findIndex((doc) => doc.fileKey === item.fileKey)
      if (index === -1) return
      const newDocs = cloneDeep(documents)
      newDocs.splice(index, 1)
      onChange(newDocs)
    }

    const addHistory = (doc: CategoryDocument) => {
      if (!hasHistory) return

      const { history = [] } = doc
      delete doc.history
      history.push({
        ...doc,
        createdBy: currentEmail,
      })
      doc.history = history
    }

    const changeStatus = async (fileKey: string, value: string) => {
      const index = documents.findIndex((doc) => doc.fileKey === fileKey)
      if (index === -1) return
      const newDocs = cloneDeep(documents)
      newDocs[index].status = value
      addHistory(newDocs[index])
      onChange(newDocs)
    }

    const onUploadFilesFromInput = (event: React.ChangeEvent<HTMLInputElement>, category: string) => {
      const { files } = event.target
      if (!files || files.length == 0) return
      const arrFile: Array<File> = []
      for (let i = 0; i < files.length; i++) arrFile.push(files[i])

      onUploadFiles(arrFile, category)
    }

    const onDrop = async (doc: any, category: string) => {
      if (doc.files) onUploadFiles(doc.files, category)
      else {
        let newDocs = []
        documents.map((item) => {
          if (item.fileKey !== doc.fileKey) newDocs.push(item)
        })
        const newDoc = {
          ...doc,
          category,
        }
        addHistory(newDoc)
        newDocs.push(newDoc)
        onChange(newDocs)
      }
    }

    const onUploadFiles = async (files: any, category: string) => {
      if (!files || !files.length) return
      const fileArr: File[] = []
      const fileLen = files.length
      for (let i = 0; i < fileLen; i++) fileArr.push(files[i])
      const data = {
        path: `loan/${loanNumber}/${type}`,
      }
      setLoading(true)
      uploadFiles(data, fileArr)
        .then((keys: string[]) => {
          const fileList: CategoryDocument[] = []
          for (let i = 0; i < fileLen; i++) {
            const doc = {
              status: 'Not Reviewed',
              category,
              name: getUniqueFileName(fileArr[i].name, allFileNames),
              fileKey: keys[i],
              createdAt: formatTime(),
            }
            addHistory(doc)
            fileList.push(doc)
          }
          const newDocs = cloneDeep(documents)
          newDocs.push(...fileList)
          onChange(newDocs)
        })
        .finally(() => setLoading(false))
    }

    const onSelected = (key: string, value: boolean) => {
      const newData = cloneDeep(selected)
      if (!value) delete newData[key]
      else newData[key] = value
      setSelected(newData)
    }

    const onDownloadSelected = () => {
      const filename = `${type}-${loanNumber}`
      const fileKeys = Object.keys(selected).filter((fileKey) => documents.find((item) => item.fileKey === fileKey))
      openS3Documents(loanNumber, filename, fileKeys)
    }

    const renderSubDocuments = (filteredCategory: string, categoryIndex: number) => {
      const properDocs = documents.filter(({ category }) => category === filteredCategory)
      return properDocs.map((document, index: number) => {
        let trashShow = true
        if (document.lock) {
          trashShow = false
        }
        if (showStatus) {
          if (document.status !== 'Not Reviewed') trashShow = false
        }

        return (
          <tr className="hover:bg-slate-100" key={index}>
            <td className="px-3 py-1 min-w-[240px]">
              <Checkbox
                id={`document-no-${categoryIndex}-${index}`}
                color="gray"
                checked={!!selected[document.fileKey]}
                onChange={(value) => onSelected(document.fileKey, value)}
                title={document.name}
                fontClass="font-variation-settings-600 text-shade-blue"
              />
            </td>
            {showStatus && (
              <td className="px-2 w-40">
                {canChangeStatus ? (
                  <span>
                    <div className="-mb-4 min-w-[120px]">
                      <Select
                        key={`loan-status-${categoryIndex}-${index}`}
                        id={`loan-status-${categoryIndex}-${index}`}
                        size={3}
                        title=""
                        options={docStatus}
                        value={document.status}
                        onChange={(value) => changeStatus(document.fileKey, value)}
                      />
                    </div>
                  </span>
                ) : (
                  <span className="italic">{document.status}</span>
                )}
              </td>
            )}
            <td className="px-2 w-40 min-w-[140px]">{formatTime(document.createdAt)}</td>
            <td className="px-2 w-24">
              <button
                className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                onClick={() => openS3Document(document.fileKey)}
              >
                <EyeIcon className="w-4 h-4" />
              </button>
              {trashShow && (
                <button
                  className="text-red-800 p-1 hover-shadow1 cursor-pointer"
                  onClick={() => onRemoveDocument(document)}
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              )}
              {hasHistory && (
                <button
                  className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                  onClick={() => onHistory(document)}
                >
                  <ClockIcon className="w-4 h-4" />
                </button>
              )}
            </td>
          </tr>
        )
      })
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <div className={`flex justify-end mb-2`}>
          <button
            onClick={() => onDownloadSelected()}
            disabled={isSelectedBtnDisabled}
            className={`flex gap-1 items-center px-2 py-1 rounded shadow cursor-pointer ${
              isSelectedBtnDisabled
                ? 'cursor-not-allowed bg-gray-50'
                : 'bg-sky-500 text-white hover-shadow1 hover:underline'
            }`}
          >
            <span className="text-[13px] ">Download</span>
            <ArrowDownTrayIcon className="w-4 h-4" />
          </button>
        </div>
        <div className="relative overflow-auto mb-2 shadow-md sm:rounded-lg">
          <div className="absolute top-1 right-[calc(50%-135px)] w-full h-fit">
            <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
              <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
              <span>Drag and drop files.</span>
            </div>
          </div>
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Document/File Name</th>
                {showStatus && <th className="w-40"></th>}
                <th className="px-6 py-3 w-40">Date Uploaded</th>
                <th className="px-6 py-3 w-24">Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category: string, index: number) => {
                let bgCn = index % 2 ? 'bg-gray-50' : ''
                if (!isAdminAe && category == 'Payment Authorization') return null

                return (
                  <React.Fragment key={category}>
                    <tr className={bgCn}>
                      <td colSpan={showStatus ? 4 : 3} className="border-b px-1">
                        <TargetBox
                          types={['document', NativeTypes.FILE]}
                          onDrop={(docs: any) => onDrop(docs, category)}
                        >
                          <table className="w-full">
                            <tbody>
                              <tr className="">
                                <td className="px-6 py-1" colSpan={showStatus ? 3 : 2}>
                                  <div className="flex items-center">
                                    <span className="mr-3">{index + 1}.</span>{' '}
                                    <span className="text-[15px]">{category}</span>
                                  </div>
                                </td>
                                <td className="px-6 py-1 w-20">
                                  <label
                                    className="text-gray-900 p-1 hover-shadow1 cursor-pointer inline-block"
                                    htmlFor={`file-${category}`}
                                  >
                                    <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                                  </label>

                                  <input
                                    className="hidden"
                                    id={`file-${category}`}
                                    type="file"
                                    accept=".pdf,.xml"
                                    multiple
                                    required
                                    onChange={(event) => onUploadFilesFromInput(event, category)}
                                  />
                                </td>
                              </tr>
                              {renderSubDocuments(category, index)}
                            </tbody>
                          </table>
                        </TargetBox>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </DndProvider>
    )
  },
)
