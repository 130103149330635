import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { INVALID_ALL_INPUTS } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { generateEscrowHoldingBackPdf, getEscrowHoldingBack, updateEscrowHoldingBack } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const EscrowHoldbackAgreement = () => {
  const [loading, setLoading] = useState(true)
  const [inputs, setInputs] = useState<Record<string, any>>({})

  useEffect(() => {
    getEscrowHoldingBack()
      .then(({ inputs, data }) => {
        Object.keys(inputs).forEach((key) => {
          inputs[key].value = data[key]
        })
        setInputs(inputs)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onBlur = async (key: string) => {
    let value = InputConvert(inputs[key], inputs[key].value)
    if (inputs[key].type == 'thousandSep') value = removeComma(value)
    setLoading(true)
    await updateEscrowHoldingBack({ [key]: value })
    setLoading(false)
  }

  const onGenerate = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (!disabled && value !== undefined) data[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      toast(INVALID_ALL_INPUTS, { type: 'error' })
      return
    }
    setLoading(true)
    await generateEscrowHoldingBackPdf()
    setLoading(false)
  }

  return (
    <div className={`ReviewForm-container`}>
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white`}>
          <LayoutLoading show={loading} />

          <div className="review-form-container overflow-show">
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-5">
              {Object.keys(inputs).map((key, index) => {
                const input = inputs[key]
                return (
                  <div
                    className={`input col-span-1 ${input.span && 'md:col-span-2 lg:col-span-3'} items-center`}
                    key={`item-${index}-${key}`}
                  >
                    <RenderInput input={input} Key={key} onChange={onChange} onBlur={onBlur} />
                  </div>
                )
              })}
            </div>
            <div className="">
              <div className="w-96 mx-auto">
                <Button full onClick={onGenerate}>
                  Generate Document
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
