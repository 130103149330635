import { InputType } from 'config'

export interface IFromEmails {
  HOME_EMAIL: string
  CORP_EMAIL: string
  LOANSETUP_EMAIL: string
  UNDERWRITER_EMAIL: string
  DISCLOSURE_EMAIL: string
  CLOSE_EMAIL: string
  PARTNER_EMAIL: string
  LOCK_EMAIL: string
  DRAW_EMAIL: string
  ACCOUNTING_EMAIL: string
  INFO_EMAIL: string
  SERVICE_EMAIL: string
  CEO_EMAIL: string
}

export const defaultInputs = (): Record<string, InputType> => {
  const inputs: Record<string, InputType> = {}
  ;[
    'HOME',
    'CORP',
    'LOANSETUP',
    'UNDERWRITER',
    'DISCLOSURE',
    'CLOSE',
    'PARTNER',
    'LOCK',
    'DRAW',
    'ACCOUNTING',
    'INFO',
    'SERVICE',
    'CEO',
  ].forEach((key) => {
    inputs[`${key}_EMAIL`] = {
      inputType: 'text',
      title: `${key}_EMAIL`,
      value: '',
      required: true,
    }
  })
  return inputs
}
