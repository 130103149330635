import { DocumentDuplicateIcon, MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services/apis/admin'
import { deleteCondition, getConditions } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, Input2, MultiSelect } from 'stories/components'
import { confirm } from 'utils'

import { ConditionDetails } from './details'
import type { ICondition, IConditionTemplate } from './types'

export const borrowerConditionSettingKey = 'BORROWER_CONDITIONS'

export * from './types'

export function Conditions() {
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState(0)
  const [conditions, setConditions] = useState<ICondition[]>([])
  const [templates, setTemplates] = useState<IConditionTemplate[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDuplicated, setIsDuplicated] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [conditionNos, setConditionNos] = useState<Record<number, string>>({})
  const [borrowerConditions, setBorrowerConditions] = useState<Record<string, boolean>>({})

  useEffect(() => {
    refresh()
    getSetting(borrowerConditionSettingKey).then(async ({ value }) => {
      if (value !== '') setBorrowerConditions(JSON.parse(value))
    })
  }, [])

  useEffect(() => {
    const data: Record<number, string> = {}
    conditions.map((item) => {
      data[item.no] = `${item.no} - ${item.name}`
    })
    setConditionNos(data)
  }, [conditions])

  const refresh = () => {
    setIsLoading(true)
    getConditions()
      .then(({ data, templates }) => {
        setTotal(data.length)
        setConditions(data)
        setTemplates(templates)
      })
      .finally(() => setIsLoading(false))
  }

  if (isEditing)
    return (
      <ConditionDetails
        lastUpdatedAt={lastUpdatedAt}
        templates={templates}
        isDuplicated={isDuplicated}
        defaults={selectedIndex == -1 ? null : conditions[selectedIndex]}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
      />
    )

  const onAdd = () => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(-1)
    setIsEditing(true)
    setIsDuplicated(false)
  }

  const onEdit = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
    setIsDuplicated(false)
  }

  const onDuplicate = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
    setIsDuplicated(true)
  }

  const onTrash = async (index: number) => {
    const { id, no } = conditions[index]
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this condition?
        <br />
        <span className="text-gray-600">Condition No: {no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deleteCondition(id)
      .then(async () => {
        refresh()
        if (borrowerConditions[no]) {
          const data: Record<string, boolean> = {}
          Object.keys(borrowerConditions).map((key) => {
            if (borrowerConditions[key] && Number(key) !== Number(no)) data[key] = true
          })
          setBorrowerConditions(data)
          await updateSetting(borrowerConditionSettingKey, JSON.stringify(data))
        }
      })
      .catch(() => setIsLoading(false))
  }

  const onChangeBorrowerConditions = (value: Record<string, boolean>) => {
    let temp = cloneDeep(borrowerConditions)
    temp = {
      ...temp,
      ...value,
    }
    setBorrowerConditions(temp)
  }

  const onBlurBorrowerConditions = async () => {
    const data: Record<string, boolean> = {}
    Object.keys(borrowerConditions).map((key) => {
      if (borrowerConditions[key]) data[key] = true
    })
    setIsLoading(true)
    await updateSetting(borrowerConditionSettingKey, JSON.stringify(data))
    setIsLoading(false)
  }

  return (
    <div className="Conditions-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Conditions
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              onChange={(value) => setQuery(value)}
            />
          </div>
          <p className="ml-5">- {total} conditions</p>
        </div>
        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="py-3">
                Name
              </th>
              <th scope="col" className="px-3">
                Category
              </th>
              <th scope="col" className="px-3">
                Class
              </th>
              <th scope="col" className="px-3">
                Type
              </th>
              <th scope="col" className="px-3">
                Responsibility
              </th>
              <th scope="col" className="px-3">
                Int/Ext
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {conditions.map((condition, index) => {
              const { no, name, category, class: _class, type, responsibility, intext, requested } = condition
              const conditionTexts = [no, name, category, _class, type, responsibility].join(' ').toLowerCase()
              if (query && !conditionTexts.includes(query.trim().toLowerCase())) return null

              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td
                    scope="row"
                    className={`px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap ${
                      requested && 'border-l-[3px] border-yellow-600'
                    }`}
                  >
                    {condition.no}
                  </td>
                  <td className="px-3">{name}</td>
                  <td className="px-3">{category}</td>
                  <td className="px-3">{_class}</td>
                  <td className="px-3">{type}</td>
                  <td className="px-3">{responsibility}</td>
                  <td className="px-3">{intext ? 'Yes' : 'No'}</td>
                  <td className="">
                    <span className="flex px-3">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(index)}>
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span
                        className="text-blue-800 p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onDuplicate(index)}
                      >
                        <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
                      </span>
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(index)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-5 mb-4">
        <div className="font-semibold border-b">Borrower Conditions</div>
        <div className="mt-3 flex gap-2 flex-wrap">
          {Object.keys(borrowerConditions).map((key) => {
            if (borrowerConditions[key]) {
              return (
                <span key={key} className="">
                  {key},
                </span>
              )
            }
          })}
        </div>
        <div className="mt-2">
          <MultiSelect
            id={'borrowerConditionNos'}
            title={'Condition No'}
            options={conditionNos}
            key={'borrowerConditionNos'}
            value={borrowerConditions}
            onChange={(value) => onChangeBorrowerConditions(value)}
            onBlur={() => onBlurBorrowerConditions()}
            defaultSelected={false}
            sort={true}
          />
        </div>
      </div>
    </div>
  )
}
