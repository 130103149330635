import { setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { store } from 'reducers'

export const additionalMemberValidate = (hasAdditionalMember: any, dataLength: number, updateStore = false) => {
  let success = true
  if (hasAdditionalMember === undefined || hasAdditionalMember === null) success = false
  else if (hasAdditionalMember === true) {
    if (dataLength === 0) {
      success = false
    }
  }
  if (updateStore) {
    const { step } = store.getState()
    let application = cloneDeep(step.application)
    application['additionalMember'] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }
  return success
}
