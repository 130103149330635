import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export const Tooltip = ({
  message,
  placement = undefined,
  position = undefined,
  children = null,
}: {
  message: string
  placement?: 'right' | 'left'
  position?: 'top' | 'bottom'
  children?: JSX.Element | null
}) => {
  const [show, setShow] = useState(false)

  return (
    <div
      className="relative flex flex-col items-center"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children || (
        <span className="text-gray-500 cursor-pointer">
          <QuestionMarkCircleIcon className="w-[14px] h-[14px]" />
        </span>
      )}
      {show && (
        <div
          className={`absolute flex flex-col font-variation-settings-500 ${
            !placement ? 'items-center' : placement === 'right' ? 'right-0 items-end' : 'left-0 items-start'
          } ${position == 'bottom' ? 'bottom-0 -mb-10' : 'bottom-0 mb-6'}`}
        >
          {position == 'bottom' && (
            <div
              className={`w-3 h-3 -mb-2 rotate-45 bg-gray-600 ${
                !placement ? '' : placement === 'right' ? 'mr-1' : 'ml-1'
              }`}
            ></div>
          )}
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md">
            <span dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br />') }} />
          </span>
          {position != 'bottom' && (
            <div
              className={`w-3 h-3 -mt-2 rotate-45 bg-gray-600 ${
                !placement ? '' : placement === 'right' ? 'mr-1' : 'ml-1'
              }`}
            ></div>
          )}
        </div>
      )}
    </div>
  )
}
