import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getShippingInfo, updateShippingInfo } from 'services'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, inputGroups } from './constants'

export function ShippingInformation() {
  const [changed, setChanged] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    const nInputs: any = cloneDeep(inputs)
    setLoading(true)

    getShippingInfo()
      .then((data) => {
        Object.keys(nInputs).forEach((key) => {
          nInputs[key].value = data[key]
        })
        nInputs['ServicingCode'].options = data['Investors']
        nInputs['InvestorCode'].options = data['Investors']
        setInputs(nInputs)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
    setChanged(true)
  }

  const onSave = () => {
    let data: Record<string, any> = {}

    Object.keys(inputs).forEach((key) => {
      data[key] = inputs[key].value
    })

    setLoading(true)
    setChanged(false)
    updateShippingInfo(data)
      .then(() => {
        toast('Successfully updated', { type: 'info' })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'ShippingInfo',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  return (
    <div className="shipping-information-container">
      <SaveChanges onSave={onSave} show={changed} />
      <div className="max-w-screen-2xl m-auto">
        <div className="bg-white">
          <div className="grid md:grid-cols-2 gap-5">
            <LayoutLoading show={isLoading} />

            {Object.keys(inputGroups).map((key, index) => {
              const inputGroup = inputGroups[key]

              return (
                <div key={index} className="md:col-span-1">
                  <div className="shadow rounded border">
                    <div className="bg-gray-200 round-t py-1 px-4">{key}</div>
                    <div className="p-2">
                      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
                        {inputGroup.map((item: string) => {
                          let input = inputs[item]
                          input.history = true
                          return (
                            <div className={`input md:col-span-${input.span || 1}`} key={item}>
                              <RenderInput input={input} Key={item} onChange={onChange} showHistory={showHistory} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="flex justify-center md:col-span-2">
              <Button className="w-[240px]" loading={isLoading} onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
