import { AccountType } from 'config'
import { getInitialLoanFields } from 'config/loan.constants'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLoanStructureHistory } from 'services'
import { FormTable, Modal } from 'stories/components'
import { formatTime, getPrice3decimal, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { ExceptionResponse } from '../ExceptionResponse'
import type { ILoanDetail, ILoanRateSheet } from '../interfaces'
import { LoanResult } from '../LoanResult'
import { calculateLTV, estimatedClosingAmountBorrower, isLtvAdjustments } from '../Logic'
import { PriceAdjustHeader } from '../PriceAdjust'

interface IProps {
  loanRateSheet: ILoanRateSheet
  loanDetail: ILoanDetail
  historyIndex: string
  isOpen: boolean
  onClose: () => void
}

export function LockHistoryDialog(props: IProps) {
  const { loanDetail, historyIndex, isOpen, loanRateSheet, onClose } = props

  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(false)
  const [data, setData] = useState<any>({})

  const loanFields = getInitialLoanFields()

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const canPriceAdjust = [AccountType.ADMIN, AccountType.LOCK_DESK].includes(auth.profile.accountType)

  useEffect(() => {
    if (historyIndex !== 'all') {
      setInit(true)
      getLoanStructureHistory(historyIndex).then((res: any) => {
        setData(res[0])
        setInit(false)
      })
    }
  }, [historyIndex])

  const onCloseModal = () => {
    setLoading(false)
    onClose()
  }

  const onSubmit = () => {
    return new Promise(async (resolve) => {
      resolve(true)
    })
  }

  const changeInput = (key: string, e: any) => {
    console.log(key, e)
  }

  const renderContent = () => {
    if (init) return null
    let sheet = data?.ratesheet?.ratesheet?.data

    return (
      <div className="">
        <div className="flex gap-4 text-[15px] text-gray-900 mb-4">
          <div className="">
            Date:{' '}
            <span className="font-variation-settings-600 text-shade-blue underline">{formatTime(data.createdAt)}</span>
          </div>
          <div className="">
            By: <span className="font-variation-settings-600 text-shade-blue underline">{data.email}</span>
          </div>
          <div className="">
            RatesheetID:{' '}
            <span className="font-variation-settings-600 text-shade-blue underline">
              {data?.ratesheet?.ratesheet?.ratesheetID}
            </span>
          </div>
        </div>
        {data?.ratesheet?.exceptions?.length > 0 && (
          <div className="mb-4">
            <ExceptionResponse exceptions={data.ratesheet.exceptions} forPdf={true} />
          </div>
        )}

        {data?.ratesheet?.ratesheet?.ratesheetID !== undefined && (
          <div className="m-auto grid grid-cols-12 md:gap-x-6">
            <div className="relative shadow1 md:col-span-6 col-span-12 bg-white rounded p-4 md:p-6 mb-4">
              {data?.ratesheet?.showingFields?.map((fields: string[], index: number) => {
                const isLtvAdjustment = isLtvAdjustments(fields)
                const showLtvAdjustments = isLtvAdjustment && canPriceAdjust
                const hideLtvAdjustments = isLtvAdjustment && !canPriceAdjust

                if (hideLtvAdjustments) return <></>

                return (
                  <div
                    key={index}
                    className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 mb-5 rounded-t border border-solid border-gray-200 shadow p-2 justify-around"
                  >
                    {showLtvAdjustments && (
                      <div className=" col-span-2 text-[16px] font-variation-settings-600">Max LTV Adjustments</div>
                    )}
                    {fields.map((key, index1) => {
                      let rlt = []
                      const cn = loanFields[key]?.length === 1 ? 'md:col-span-2' : 'md:col-span-1'

                      rlt.push(
                        <div key={`${index}-${index1}`} className={cn}>
                          <RenderInput
                            input={{
                              ...loanFields[key],
                              value: data.ratesheet.loanDetail[key],
                              history: false,
                              disabled: true,
                            }}
                            Key={key}
                            onChange={changeInput}
                          />
                        </div>,
                      )
                      if (key === 'constructionReserve') {
                        rlt.push(
                          <div key={`rehabFinancedPercent-${index}-${index1}`}>
                            <RenderInput
                              input={{
                                title: 'Construction Financed %',
                                inputType: 'text',
                                prefix: '%',
                                value: (
                                  (removeComma(data.ratesheet.loanDetail.constructionReserve) * 100) /
                                  removeComma(data.ratesheet.loanDetail.rehabBudget)
                                ).toFixed(2),
                                readOnly: true,
                              }}
                              Key={'rehabFinancedPercent'}
                              onChange={changeInput}
                            />
                          </div>,
                        )
                      }
                      if (key === 'proposedLoanAmount') {
                        rlt.push(
                          <div key={`ltv-${index}-${index1}`} className={cn}>
                            <RenderInput
                              input={{
                                title: 'LTV',
                                inputType: 'text',
                                prefix: '%',
                                value: calculateLTV(data.ratesheet.loanDetail),
                                readOnly: true,
                              }}
                              Key={key}
                              onChange={changeInput}
                            />
                          </div>,
                        )
                        rlt.push(
                          <div key={`cltv-${index}-${index1}`} className={cn}>
                            <RenderInput
                              input={{
                                title: 'CLTV',
                                inputType: 'text',
                                prefix: '%',
                                value: calculateLTV(data.ratesheet.loanDetail, true),
                                readOnly: true,
                              }}
                              Key={key}
                              onChange={changeInput}
                            />
                          </div>,
                        )
                      }
                      if (key === 'closingCostEstimate') {
                        const loan = data.ratesheet.loanDetail
                        const { value } = estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings)
                        rlt.push(
                          <div key={`atClosingBorrowerAmount-${index}-${index1}`} className={cn}>
                            <RenderInput
                              input={{
                                title: `Estimated Amount at Closing`,
                                inputType: 'text',
                                prefix: '$',
                                value: getPrice3decimal(value),
                                readOnly: true,
                              }}
                              Key={key}
                              onChange={changeInput}
                            />
                          </div>,
                        )
                      }
                      return rlt
                    })}
                  </div>
                )
              })}

              {canPriceAdjust && (
                <div className="relative shadow1 col-span-12 bg-white rounded p-4">
                  <div className="PriceAdjust-container relative">
                    <div className="group flex items-center text-[16px] font-variation-settings-600 mb-4">
                      <span>Price Adjustments</span>
                    </div>
                  </div>
                  <div className="">
                    <FormTable
                      header={PriceAdjustHeader}
                      defaultData={sheet.priceAdjustments}
                      inputs={{}}
                      cols={2}
                      onChange={() => {}}
                      permission={3}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="relative shadow1 md:col-span-6 col-span-12 bg-white rounded p-4 md:p-6 mb-4">
              <div className="-mt-4">
                <LoanResult
                  auth={auth}
                  loanRateSheet={loanRateSheet}
                  loan={data.ratesheet.loanDetail}
                  loanDetail={loanDetail}
                  programs={sheet.programs}
                  products={sheet.products}
                  prices={sheet.prices}
                  priceAdjustments={sheet.priceAdjustments}
                  priceLoanTime={sheet.priceLoanTime}
                  selectedProgramId={sheet.selectedProgramId}
                  selectedProductId={sheet.selectedProductId}
                  selectedProgram={sheet.selectedProgram}
                  lockDays={sheet.lockDays}
                  handleSelectProgram={(arg1, arg2) => console.log(arg1, arg2)}
                  loanNumber={props.loanDetail.loanNumber}
                  limit={sheet.limit}
                  priceIndex={sheet.priceIndex}
                  setPriceIndex={(arg1) => console.log(arg1)}
                  ratesheetID={data.ratesheet.ratesheet.ratesheetID}
                  loanProcessData={{
                    type: data.ratesheet.type,
                    program: data.ratesheet.program,
                    rate: data.ratesheet.rate,
                    price: data.ratesheet.price,
                    sheetDate: data.ratesheet.sheetDate,
                    lockDays: data.ratesheet.lockDays,
                    rateSpread: data.ratesheet.rateSpread,
                    exitFee: data.ratesheet.exitFee,
                  }}
                  relockTimes={sheet.relockTimes}
                  inEligibleProducts={{}}
                  setLoanProcessData={(arg1) => console.log(arg1)}
                  setRatesheetSync={() => {}}
                  setLimit={() => {}}
                  addProduct={() => {}}
                  addProgram={() => {}}
                  removeProduct={() => {}}
                  addLockDay={() => {}}
                  removeLockDay={() => {}}
                  onAddRate={() => {}}
                  onRemoveRate={() => {}}
                  onUpdatePrice={() => {}}
                  onUpdateRateSpreadExitFee={() => {}}
                  removeProgram={() => {}}
                  forPdf={true}
                  isRelockRate={data.ratesheet?.noPriceReduction}
                  pricingEngineIsOff={!!data.ratesheet?.pricingEngineIsOff}
                  canDoManualPricing={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Modal
        button={<span></span>}
        title={`Lock History`}
        titleOkay=""
        titleCancel="Close"
        init={init}
        loading={loading}
        isOpen={isOpen}
        lastUpdatedAt={Date.now()}
        onClose={onCloseModal}
        childLevel={1}
        onOk={onSubmit}
      >
        <div className="min-w-[500px]">{renderContent()}</div>
      </Modal>
    </>
  )
}
