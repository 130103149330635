import { FlexSelect } from 'components/FlexSelect'
import type { InputType } from 'config'
import { Input } from 'stories/components'
import { GoogleAutoComplete } from 'stories/components/GoogleAutoComplete/GoogleAutoComplete'

export interface ParagraphComponent {
  input: InputType
  text?: string
  Key?: string
  needSplit?: boolean
  className?: string
  onChange?: Function
  onBlur?: Function
  showHistory?: Function
}

export const RenderParagraph = (props: ParagraphComponent) => {
  const {
    input,
    Key = '',
    text = '',
    needSplit = false,
    onChange = () => {},
    onBlur = () => {},
    showHistory = () => {},
    className = '',
  } = props

  switch (input.inputType) {
    case 'Span':
    case 'span':
      if (needSplit) return <span className={`mt-6 ${className}`}>{text}</span>
      else return <div className={`${className}`}>{text}</div>
    case 'Text':
    case 'text':
      return (
        <div className={`min-w-[200px] ${className}`}>
          <Input
            value={input.value as string}
            title={input.title}
            disabled={input.disabled}
            onChange={(value: string) => onChange(Key, value)}
            className={`-mb-4 ${className}`}
            type={input.type}
          />
        </div>
      )
    case 'Select':
    case 'select':
      return (
        <div className={`min-w-[200px] ${className}`}>
          <FlexSelect
            title={input.title}
            id={Key}
            options={input.options}
            value={input.value as string}
            hasDefaultOption={true}
            onChange={(value) => onChange(Key, value)}
          />
        </div>
      )
    case 'Map':
    case 'map':
      return (
        <GoogleAutoComplete
          title={input.title}
          key={Key}
          inputKey={Key}
          error={input.error}
          value={input.value as string}
          disabled={input.disabled}
          required={input.required}
          readOnly={input.readOnly}
          tooltip={input.tooltip}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          history={input.history}
          showHistory={() => showHistory(Key)}
        />
      )
  }
  return null
}

export const renderParagraph = (paragraphObj: ParagraphComponent[][], index: number, onChange: Function) => {
  return paragraphObj[index].map((item, index) => {
    if (item.input.inputType.toLowerCase() === 'span' && item.needSplit) {
      let textArr: Array<string> = item.input.title.split(' ')

      return textArr.map((word, id) => (
        <RenderParagraph
          key={id}
          text={word}
          input={item.input}
          Key={item.Key}
          onChange={item.onChange || onChange}
          needSplit={item.needSplit}
          className={`${item.className || 'pl-1'} ${id === textArr.length - 1 ? 'pr-1' : ''}`}
        />
      ))
    } else
      return (
        <RenderParagraph
          key={index}
          text={item.input.title}
          input={item.input}
          Key={item.Key}
          onChange={item.onChange || onChange}
          needSplit={item.needSplit}
          className={item.className}
        />
      )
  })
}
