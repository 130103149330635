import { PrinterIcon } from '@heroicons/react/24/outline'
import { Overview } from 'components/Overview'
import { usePermissions } from 'hooks/usePermissions'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { downloadFile, downloadHUD1PDF } from 'services'
import Api from 'services/api'

import { AggregateEscrow } from './AggregateEscrow'
import { LoanGenerateDocuments } from './GenerateDocuments'
import { HUD1Page1 } from './HUD1Page1'
import { HUD1Page2 } from './HUD1Page2'
import { HUD1Page3 } from './HUD1Page3'
import { Settlement } from './Settlement'

type menuType = {
  [key: string]: string //fix this
}

const leftMenuItems: menuType = {
  settlement: 'Settlement Sheet',
  docGen: 'Document Generate',
  escrow: 'Aggregate Escrow',
  hud1Page1: 'HUD1 Page1',
  hud1Page2: 'HUD1 Page2',
  hud1Page3: 'HUD1 Page3',
  hud1A: 'HUD1A',
}

export function ClosingScreen() {
  const { step } = useSelector((state: any) => {
    return {
      step: state.step,
    }
  })
  const { hasPermission } = usePermissions()
  const location = useLocation()
  const history = useHistory()
  const loanNumber = Api.getLoanNumber()

  const [selectedMenu, setSelectedMenu] = useState('')

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
    history.replace(`/closing_screen/${loanNumber}?menu=${menuItem}`)
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'settlement':
        return <Settlement />
      case 'docGen':
        return <LoanGenerateDocuments />
      case 'hud1Page1':
        return <HUD1Page1 />
      case 'hud1Page2':
        return <HUD1Page2 />
      case 'hud1Page3':
        return <HUD1Page3 />
      case 'escrow':
        return <AggregateEscrow />
      case 'hud1A':
        return <HUD1Page2 pageType="hud1A" />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const menuItem = decodeURI(params.get('menu') || '')
    setMenu(menuItem || Object.keys(leftMenuItems)[0])
  }, [loanNumber])

  const hasLoanGenerateDoc = useMemo(
    () => step.overview.submit.detail.submitSetup > 0 && hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION'),
    [step],
  )

  const onDownload = async () => {
    const pdfData = await downloadHUD1PDF()
    downloadFile(`HUD1 ${loanNumber}(${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`, pdfData)
  }

  return (
    <div className="closingScreen-container px-2 py-6">
      <Overview title="Closing Screen" />
      <div className="closingScreen-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
          <ul className="sidebar-items flex flex-col p-4 pb-20">
            {Object.keys(leftMenuItems)
              .filter((v) => v != 'docGen' || hasLoanGenerateDoc)
              .map((item: string, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setMenu(item)
                    }}
                    className="border-b py-2"
                  >
                    <p
                      className={`hover:underline cursor-pointer ${
                        selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                      }`}
                    >
                      {index + 1}. {leftMenuItems[item as keyof typeof leftMenuItems]}
                    </p>
                  </li>
                )
              })}
            <li className="py-2">
              <p className={`hover:underline cursor-pointer flex justify-end`}>
                <span className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDownload}>
                  <PrinterIcon className="w-5 h-5 text-shade-blue" />
                </span>
              </p>
            </li>
          </ul>
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
