import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import type { IDscrRangeLeverageAdjustment } from 'pages/Resources'
import { getPrice3decimal } from 'utils'

import { convertNullValue } from '../../logic'

interface IProps {
  title: string
  data: IDscrRangeLeverageAdjustment[]
}

export const DscrRangeAdjustment = (props: IProps) => {
  const { title, data } = props

  return (
    <div className="h-full">
      {!!data.length ? (
        <table className="text-sm w-full max-w-4xl rounded-lg shadow text-center">
          <thead className="bg-gray-100">
            <tr>
              <th colSpan={2} className="px-3 py-2 border">
                {title}
              </th>
              <th colSpan={3} className="px-3 py-2 border">
                LTV Adjustments
              </th>
            </tr>
            <tr>
              <th className="px-3 py-2 border w-1/6">From</th>
              <th className="px-3 py-2 border w-1/6">To</th>
              <th className="px-3 py-2 border w-1/6">Purchase</th>
              <th className="px-3 py-2 border w-1/6">No-Cashout</th>
              <th className="px-3 py-2 border w-1/6">Cashout</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index} className={`${index % 2 == 1 ? 'bg-gray-50' : ''}`}>
                  <td className="px-3 py-2 border">{getPrice3decimal(item.range.from)}</td>
                  <td className="px-3 py-2 border">{getPrice3decimal(item.range.to)}</td>
                  <td className="px-3 py-2 border">{convertNullValue(item.limit.purchase)}</td>
                  <td className="px-3 py-2 border">{convertNullValue(item.limit.nocashout)}</td>
                  <td className="px-3 py-2 border">{convertNullValue(item.limit.cashout)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )}
    </div>
  )
}
