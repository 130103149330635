import { useSelector } from 'react-redux'
import { BorrowerHeader, Footer, Header } from 'stories/layouts'

export const BaseLayout = (props: any) => {
  const { children } = props
  const auth = useSelector((state: any) => state.auth)
  const { temparary } = auth.profile

  return (
    <div className="relative bg-white overflow-hidden">
      <div className={`relative lg:w-full lg:px-2 sm:px-1 h-full-screen bg-white`}>
        {!temparary ? <Header /> : <BorrowerHeader />}
        <main className="relative mx-auto main">{children}</main>
        <Footer />
      </div>
    </div>
  )
}
