import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  deleteAccountingFile,
  getAccountingAccount,
  getAccountingCategories,
  getAccountingFilePropById,
  getAccountingFiles,
  getBaseAccountingCategories,
  openS3Document,
} from 'services'
import { ButtonGroup } from 'stories/components'
import { confirm, formatTime } from 'utils'

import type { IAccountingCategory } from '../AccountingCategories'
import { AccountingRecordTypeOptions } from '../AccountingRecords'
import type { IAccountingAccount } from '../Accounts/types'
import { FileDetails } from './FileDetails'
import type { IAccountingFile } from './types'

export const AccountingFilesContent = ({
  type = '',
  isLoading,
  setLoading,
  onOpenDocs,
}: {
  type?: string
  isLoading: boolean
  setLoading: Function
  onOpenDocs: Function
}) => {
  const [baseCategory, setBaseCategory] = useState<Record<string, Record<number, string>>>({})
  const [category, setCategory] = useState<IAccountingCategory[]>([])
  const [accounts, setAccounts] = useState<IAccountingAccount[]>([])
  const [data, setData] = useState<IAccountingFile[]>([])
  const [activeFile, setActiveFile] = useState(0)
  const [filterStatus, setFilterStatus] = useState('pending')

  const navigate = useHistory()

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)

    const [baseCategory, category, data, accounts] = await Promise.all([
      getBaseAccountingCategories(),
      getAccountingCategories(),
      getAccountingFiles(type),
      getAccountingAccount(),
    ])
    setBaseCategory(baseCategory)
    setCategory(category)
    setData(data)
    setAccounts(accounts)

    setLoading(false)
  }

  const onDelete = async (item: IAccountingFile) => {
    setLoading(true)
    const { total, deleted, pending, approved } = await getAccountingFilePropById(item.id)
    setLoading(false)

    const content = (
      <div className="text-left text-gray-900 mb-4 text-sm">
        <p className="mb-2">
          Are you sure want to delete this file?
          <br />
          If you delete this file, following records will be <b>permanently</b> removed from database.
        </p>
        <div className="ml-4">
          {[
            ['Total', total],
            ['Approved', approved],
            ['Pending', pending],
            ['Deleted', deleted],
          ].map((v) => (
            <div className="mt-1">
              <div className="border-b border-gray-200 mb-1 flex items-center gap-2">
                <p className="w-24">- {v[0]}:</p>
                <p>{v[1]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteAccountingFile(item.id)

    const newData = cloneDeep(data)
    const index = data.findIndex((v) => v.id == item.id)
    newData.splice(index, 1)
    setData(newData)

    setLoading(false)
  }

  const renderName = (item: IAccountingFile) => {
    const { name, loanNumber } = item
    let byteLoanNumber = 0
    let prefix = ''
    let suffix = ''

    const newFundingRegex = /^New Loan Funding (\d+) \$/
    const newLoanFundingMatch = name.match(newFundingRegex)
    if (newLoanFundingMatch) {
      byteLoanNumber = Number(newLoanFundingMatch[1])
      prefix = 'New Loan Funding'
      suffix = name.replace(newFundingRegex, '$')
    }

    const drawIssuedRegex = /^Draw Issued (\d+) #/
    const drawIssuedMatch = name.match(drawIssuedRegex)
    if (drawIssuedMatch) {
      byteLoanNumber = Number(drawIssuedMatch[1])
      prefix = 'Draw Issued'
      suffix = name.replace(drawIssuedRegex, '#')
    }

    if (!byteLoanNumber) return name

    const link = `/loan_process/overview/${loanNumber}`
    return (
      <div className="flex items-center gap-2">
        {prefix}
        <div className="flex items-center gap-1">
          <button
            className="text-shade-blue hover:underline cursor-pointer font-bold dark:text-white whitespace-nowrap flex flex-col"
            onClick={() => navigate.push(link)}
          >
            <span>{byteLoanNumber}</span>
          </button>

          <button
            className="text-shade-blue hover:underline cursor-pointer font-bold dark:text-white whitespace-nowrap flex flex-col"
            onClick={() => window.open(link, '_blank', 'resizable=yes')}
          >
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </button>
        </div>
        {suffix}
        <span
          className="text-shade-blue font-semibold hover-shadow1 cursor-pointer p-1"
          onClick={() => onOpenDocs(loanNumber)}
        >
          <DocumentDuplicateIcon className="w-4 h-4" />
        </span>
      </div>
    )
  }

  return (
    <div className="relative overflow-auto">
      <ButtonGroup
        title={{
          pending: 'Pending',
          completed: 'Completed',
          all: 'All',
        }}
        value={filterStatus}
        onChange={setFilterStatus}
      />

      <LayoutLoading show={isLoading} />

      <div className="sm:rounded-lg mb-8">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3 w-[20px]">
                ID
              </th>
              {!type && (
                <th scope="col" className="px-3 py-3">
                  Type
                </th>
              )}
              <th scope="col" className="px-3 py-3">
                File Name
              </th>
              <th scope="col" className="px-3 py-3">
                Pending/Total
              </th>
              <th scope="col" className="px-3 py-3">
                Created By
              </th>
              <th scope="col" className="px-3 py-3">
                Created At
              </th>
              <th scope="col" className="px-3 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter((v) => {
                if (filterStatus == 'all') return true
                if (filterStatus == 'pending') return v.approved != v.total
                if (filterStatus == 'completed') return v.approved == v.total
                return false
              })
              .map((item, index) => {
                const baseCategories: Record<number, string> = {
                  ...(baseCategory['Record'] || {}),
                  ...(baseCategory['Balance'] || {}),
                }
                return (
                  <React.Fragment key={`file-${item.id}`}>
                    <tr className={`border-b dark:bg-gray-800 dark:border-gray-700`} key={index}>
                      <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        #{item.id}
                      </td>

                      {!type && (
                        <td
                          scope="row"
                          className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                          {AccountingRecordTypeOptions[item.type]}
                        </td>
                      )}

                      <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {renderName(item)}
                      </td>

                      <td scope="row" className="px-3 py-1 font-medium text-shade-blue whitespace-nowrap">
                        {item.total - item.approved} / {item.total}
                      </td>

                      <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {item.createdBy}
                      </td>

                      <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {formatTime(item.createdAt)}
                      </td>

                      <td className="px-3 py-1">
                        <div className="flex">
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => setActiveFile(activeFile == item.id ? 0 : item.id)}
                          >
                            <ArrowRightIcon className="w-4 h-4" />
                          </span>
                          {!!item.fileKey && (
                            <span
                              className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                              onClick={() => openS3Document(item.fileKey)}
                            >
                              <EyeIcon className="w-4 h-4" />
                            </span>
                          )}
                          <span
                            className="text-red-500 p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onDelete(item)}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </span>
                        </div>
                      </td>
                    </tr>
                    {activeFile == item.id && (
                      <tr>
                        <td></td>
                        <td colSpan={6}>
                          <FileDetails
                            baseCategory={baseCategories}
                            category={category}
                            accounts={accounts}
                            data={item}
                            setLoading={setLoading}
                            update={(fileId: number, newProps: Record<string, any>) => {
                              const newData = cloneDeep(data)
                              const index = data.findIndex((v) => v.id == fileId)
                              newData[index] = {
                                ...newData[index],
                                ...newProps,
                              }
                              setData(newData)
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
