import cloneDeep from 'clone-deep'
import type { ReviewFormCategory } from 'pages/Admin'
import { defaultInputs } from 'pages/Admin/ConditionsAndTemplates/ReviewFormItems/constants'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { ReviewFormData } from './ReviewForm'

export const AddReviewFormModal = (props: {
  categories: ReviewFormCategory[]
  data: ReviewFormData | null
  isDuplicate: Function
  onSubmit: Function
  onClose: Function
}) => {
  const { categories } = props
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    const newInputs = defaultInputs()
    const categoryMap: Record<string, string> = {}
    categories.forEach((category) => {
      if (!category.value) return
      categoryMap[category.id] = category.value
    })
    ;(newInputs.categoryId as any).options = categoryMap
    if (!props.data) {
      setInputs(newInputs)
      return
    }
    for (const key in props.data) {
      if (newInputs[key]) newInputs[key].value = (props.data as any)[key]
    }
    setInputs(newInputs)
  }, [props.data])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (!disabled && value !== undefined) data[key] = value
    }
    if (props.isDuplicate(props.data?.id, data.title)) {
      hasError = true
      newStats.title.error = 'This title is already exists.'
    }
    if (hasError) {
      setInputs(newStats)
      return
    }
    setLoading(true)
    await props.onSubmit({
      ...(props.data || {}),
      ...data,
    })
    onClose()
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      title={props.data ? 'Update' : `Add new item`}
      titleOkay="Confirm"
      isOpen
      lastUpdatedAt={Date.now()}
      loading={isLoading}
      onClose={onClose}
      onOk={onSubmit}
    >
      <div className="grid gap-4 grid-cols-1 mb-3 w-144">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div className={`input`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
