import { ArrowPathIcon, ChevronLeftIcon, XCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputSelect, InputType } from 'config'
import * as moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { analyzeAccounting, parseAccountingFile, validateLoanNumbers } from 'services'
import { Button, InputFile, Modal } from 'stories/components'
import { formatDate, InputConvert, InputValidate, removeComma, sortByKey } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingCategory } from '../AccountingCategories'
import type { IAccountingFile } from '../AccountingFiles/types'
import type { IAccountingAccount } from '../Accounts/types'
import { getAccountOptions } from '../Accounts/utils'
import { AccountingTable } from './AccountingTable'
import { EditableAccountingTable } from './EditableAccountingTable'
import { AccountingFileType, accountingFileTypes, ImportAccountingFileTypes } from './ImportAccountingFileTypes'
import { type IAccounting, AssetsBaseId, LoansHeldForSaleCategoryName } from './types'
import { getChildrenCategories, getChildrenSubCategories } from './util'

enum ImportAccountingSteps {
  ImportFile,
  Editing,
  Finalize,
}

const defaultInputs = (): Record<string, InputType> => {
  return {
    baseId: {
      inputType: 'select',
      title: 'Base Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: false,
    },
    category: {
      inputType: 'select',
      title: 'Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
    subCategory: {
      inputType: 'select',
      title: 'Sub Category',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
    accountId: {
      inputType: 'select',
      title: 'Account',
      hasDefaultOption: true,
      defaultOptionText: '',
      options: {},
      sort: true,
    },
  }
}

export const ImportAccountingFileDialog = ({
  type,
  baseCategory,
  category,
  defaultCategory,
  accounts,
  reload,
  onClose,
}: {
  type: string
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  defaultCategory: IAccountingCategory | null
  accounts: IAccountingAccount[]
  reload: Function
  onClose: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [step, setStep] = useState<ImportAccountingSteps>(ImportAccountingSteps.ImportFile)
  const [activeFileType, setActiveFileType] = useState(AccountingFileType.Legacy)
  const [inputs, setInputs] = useState(defaultInputs())
  const [data, setData] = useState<IAccounting[]>([])
  const [orders, setOrders] = useState<{ by: string; dir: 1 | -1 }>({ by: 'description', dir: 1 })
  const [errorMsg, setErrorMsg] = useState('')
  const [ignoreLoanCheck, setIgnoreLoanCheck] = useState<boolean>()
  const [isSubmitConfirmed, setSubmitConfirmed] = useState<boolean>()
  const [importedFile, setImportedFile] = useState<IAccountingFile>()

  useEffect(() => {
    if (!defaultCategory) return
    if (defaultCategory.value == LoansHeldForSaleCategoryName) setActiveFileType(AccountingFileType.LOANS_HELD_FOR_SALE)
  }, [defaultCategory])

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    ;(newInputs.baseId as InputSelect).options = baseCategory
    ;(newInputs.accountId as InputSelect).options = getAccountOptions(accounts)

    if (defaultCategory) {
      newInputs.baseId.value = defaultCategory.baseId
      newInputs.category.value = defaultCategory.id
      newInputs.subCategory.value = defaultCategory.subCategory
    }

    setInputs(newInputs)
  }, [accounts, baseCategory, defaultCategory])

  useEffect(() => {
    setData(sortByKey(data, orders.by, orders.dir))
  }, [orders])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key == 'baseId') {
      ;(newInputs.category as InputSelect).options = getChildrenCategories(category, value)
      newInputs.category.value = ''
    }
    if (key == 'category') {
      ;(newInputs.subCategory as InputSelect).options = getChildrenSubCategories(category, value)
      newInputs.subCategory.value = ''
    }
    setInputs(newInputs)

    const newData = cloneDeep(data)
    newData.forEach((v) => {
      ;(v as any)[key] = Number(value) || 0
      if (key == 'baseId') {
        v.category = 0
        v.subCategory = 0
      }
      if (key == 'category') v.subCategory = 0
    })
    setData(newData)
  }

  const onSort = (key: string, order: number) => {
    setOrders({ by: key, dir: order as any })
  }

  const onLoadFile = async (_file: File | null) => {
    if (!_file) return
    setLoading(true)

    const { data: parsedData, file } = await parseAccountingFile(type, _file)
    setImportedFile(file)

    const { type: fileType, columns } = accountingFileTypes[activeFileType]
    let nthInvalidRecord = -1
    let baseId = 0
    let categoryId = 0

    if (fileType == AccountingFileType.LOANS_HELD_FOR_SALE) {
      baseId = AssetsBaseId
      const loansHeldCategory = category.find(
        (v) => v.baseId == AssetsBaseId && v.value == LoansHeldForSaleCategoryName,
      )
      if (loansHeldCategory) categoryId = loansHeldCategory.id
    }

    const newData: IAccounting[] = sortByKey(
      parsedData
        .map((row) => row.map((v) => (typeof v == 'string' ? v.trim() : v)))
        .map((row, index): IAccounting | null => {
          if (nthInvalidRecord != -1) return null
          if (row.length > columns) {
            nthInvalidRecord = index
            return null
          }

          let date = ''
          let amount = 0
          let description = ''
          let loanNumber = ''
          let servicerLoanNumber = ''

          switch (fileType) {
            case AccountingFileType.Legacy: {
              date = row[0]
              amount = removeComma(row[2] || row[3])
              if (isNaN(amount)) amount = 0
              if (index != 0 && row[2] && row[3]) {
                nthInvalidRecord = index
                return null
              }
              description = row[1]
              loanNumber = row[4] || ''
              break
            }
            case AccountingFileType.LOANS_HELD_FOR_SALE: {
              date = row[20]
              amount = removeComma(row[9])
              description = `${row[0]} - ${row[2]}`
              servicerLoanNumber = row[0]
              loanNumber = row[1]
              break
            }
          }

          if (typeof date == 'string') date = moment.default(date).isValid() ? formatDate(date) : ''
          else if (typeof date == 'number')
            date = formatDate(
              moment
                .default('1900-01-01')
                .add(date - 2, 'days')
                .toDate(),
            )
          if (!date || !description || (!amount && amount !== 0)) {
            if (index == 0) return null
            nthInvalidRecord = index
            return null
          }

          return {
            id: -index - 1,
            baseId,
            category: categoryId,
            subCategory: defaultCategory && defaultCategory.subCategory ? defaultCategory.subCategory : 0,
            date,
            description,
            amount,
            loanNumber,
            checked: true,
            servicerLoanNumber,
          } as IAccounting
        })
        .filter((v) => !!v),
      'description',
    )
    setLoading(false)

    if (nthInvalidRecord != -1) {
      setErrorMsg(`This file has incorrect format at ${nthInvalidRecord + 1}th Row. Please fix and upload again.`)
      return
    }

    setErrorMsg('')
    if (newData.length == 0) return

    setData(newData)
    setStep(ImportAccountingSteps.Editing)
  }

  const onSubmit = async () => {
    setLoading(true)
    await onClose(
      importedFile,
      data
        .map((v) => ({ ...v, id: 0, actived: true }))
        .filter((v) => v.valid && v.checked && (!v.duplicated || v.override)),
    )
    setLoading(false)
  }

  const onReload = async () => {
    setLoading(true)
    await reload(false)
    setLoading(false)
  }

  const checkedItemsCount = useMemo(() => {
    return data.filter((v) => v.checked).length
  }, [data])

  const onAnalyze = async () => {
    let allPassed = true

    const newValues = cloneDeep(data)

    newValues.forEach((v) => {
      v.valid = !!v.date && !!v.description && !!Number(v.amount) && !!v.baseId && !!v.category && !!v.accountId
      allPassed = allPassed && (v.valid || !v.checked)
    })

    setLoading(true)

    let allLoanFound = true
    if (!ignoreLoanCheck) {
      const loanNumbers = newValues
        .filter((v) => v.valid && v.checked && !!Number(v.loanNumber))
        .map((v) => Number(v.loanNumber))
      if (loanNumbers.length) {
        const loanNumberValidList = await validateLoanNumbers(loanNumbers)

        newValues.forEach((v) => {
          if (!v.loanNumber || !v.checked) return
          v.valid = !!v.valid && !!loanNumberValidList[Number(v.loanNumber)]
          allPassed = allPassed && v.valid
          allLoanFound = allLoanFound && !!loanNumberValidList[Number(v.loanNumber)]
        })
      }
    }

    if (!allPassed) {
      setData(newValues)
      setErrorMsg(
        `Please complete following items: ${newValues
          .map((v, index) => (!v.valid && v.checked ? index + 1 : 0))
          .filter((v) => !!v)
          .map((v) => `${v}`)
          .join(', ')}.`,
      )
      setLoading(false)
      if (!allLoanFound && ignoreLoanCheck === undefined) setIgnoreLoanCheck(false)
      return null
    }

    const duplicatedIds = await analyzeAccounting(newValues)
    duplicatedIds.forEach((id) => {
      const index = newValues.findIndex((v) => v.id == id)
      if (index == -1) return
      newValues[index].duplicated = true
    })
    newValues.forEach((item) => {
      item.category = Number(item.category)
    })

    setData(newValues)
    setStep(ImportAccountingSteps.Finalize)
    setLoading(false)
    setErrorMsg('')
    return newValues
  }

  const onAddToPending = async () => {
    setLoading(true)
    await onClose(
      importedFile,
      data
        .filter((v) => !!v.date)
        .map((v) => ({
          ...v,
          id: 0,
          actived: false,
        })),
    )
    setLoading(false)
  }

  const renderTable = () => {
    return (
      <EditableAccountingTable
        accounts={accounts}
        baseCategory={baseCategory}
        category={category}
        data={data}
        setData={setData}
        orders={orders}
        onSort={onSort}
      />
    )
  }

  const renderBackButton = () => {
    if (step == ImportAccountingSteps.ImportFile) return null

    return (
      <div className="text-shade-blue flex items-center mb-2 font-bold">
        <ChevronLeftIcon className="w-4 h-4" />
        <span
          className="hover:underline cursor-pointer"
          onClick={() => {
            setErrorMsg('')
            setStep(step - 1)
            setSubmitConfirmed(false)
          }}
        >
          Back
        </span>
      </div>
    )
  }

  const renderAnalyzedTable = () => {
    const totalOrders: Record<string, { by: string; dir: number }> = {}
    Object.keys(baseCategory).forEach((baseId) => {
      totalOrders[baseId] = orders
    })
    const uncheckedRecords = data.filter((v) => !v.checked).length
    const newRecords = data.filter((v) => v.checked && !v.duplicated).length
    const duplicatedRecords = data.filter((v) => v.checked && v.duplicated).length
    const overrideRecords = data.filter((v) => v.checked && v.duplicated && v.override).length

    return (
      <div>
        <AccountingTable
          baseCategory={baseCategory}
          category={category}
          accounts={accounts}
          data={data.filter((v) => v.checked)}
          orders={totalOrders}
          onOverride={(id: number, v: boolean) => {
            const newData = cloneDeep(data)
            const index = data.findIndex((v) => v.id == id)
            newData[index].override = v
            setData(newData)
          }}
        />

        <p className="mt-4 text-sm">
          - Total records of this file: <b>{data.length}</b>
          <br />- Unchecked records: <b>{uncheckedRecords}</b>
          <br />- Analyzed records: <b>{data.length - uncheckedRecords}</b> (New: <b>{newRecords}</b>, Duplicated:{' '}
          <b>{duplicatedRecords}</b>, Override Duplicated: <b>{overrideRecords}</b>)
          <br />
          <RenderInput
            Key="isSubmitConfirmed"
            input={{
              inputType: 'checkbox',
              title: `Confirm to add ${newRecords + overrideRecords} records`,
              visible: false,
              value: isSubmitConfirmed,
            }}
            onChange={(_: string, v: boolean) => setSubmitConfirmed(v)}
          />
        </p>
      </div>
    )
  }

  const renderErrorMessage = () => {
    return errorMsg ? (
      <div
        className="my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-[15px] flex items-center"
        role="alert"
      >
        <XCircleIcon className="w-6 h-6"></XCircleIcon>
        <span className="ml-1">{errorMsg}</span>
      </div>
    ) : null
  }

  const renderContent = () => {
    switch (step) {
      case ImportAccountingSteps.ImportFile:
        return (
          <>
            <ImportAccountingFileTypes
              activeIndex={activeFileType}
              onChange={(v: AccountingFileType) => {
                defaultCategory = null
                setActiveFileType(v)
              }}
            />
            <InputFile
              className="pt-1 w-128"
              title="Load File"
              acceptFileTypes=".xls, .xlsx"
              value=""
              onChange={onLoadFile as any}
              acceptDocument={false}
            />
            {renderErrorMessage()}
          </>
        )

      case ImportAccountingSteps.Editing:
        return (
          <>
            <div className="flex justify-between items-center">
              {/* <div className="mb-4 w-72">
                <Select2
                  id="account"
                  title="Select Account"
                  value={account}
                  required
                  hasDefaultOption
                  sort
                  defaultOptionText=""
                  onChange={setAccount}
                  options={accountOptions}
                />
              </div> */}

              {Object.keys(inputs).map((key) => {
                return (
                  <div className={`input w-72 mb-4`} key={key}>
                    <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
                  </div>
                )
              })}
              <button
                className={`flex gap-1 items-center font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline`}
                onClick={onReload}
              >
                <ArrowPathIcon className="w-4 h-4" /> Reload Options
              </button>
            </div>

            {renderTable()}

            {ignoreLoanCheck !== undefined && (
              <div className="mt-4">
                <RenderInput
                  Key="ignoreLoanCheck"
                  input={{
                    inputType: 'checkbox',
                    title:
                      'The loan number entered does not exist, would you like to log this transaction against this loan number anyway?',
                    visible: false,
                    value: ignoreLoanCheck,
                  }}
                  onChange={(_: string, v: boolean) => setIgnoreLoanCheck(v)}
                />
              </div>
            )}

            {renderErrorMessage()}

            <div className="mt-4 flex gap-2 justify-center">
              <Button disabled={checkedItemsCount == 0} onClick={onAnalyze}>
                Analyze Data
              </Button>
              <Button outline onClick={onAddToPending}>
                Add to Pending
              </Button>
            </div>
          </>
        )

      case ImportAccountingSteps.Finalize:
        return renderAnalyzedTable()
    }
  }

  return (
    <Modal
      isOpen
      title="Load Accounting File"
      loading={isLoading}
      onOk={() => onSubmit()}
      onClose={() => onClose(null, [])}
      disabled={step != ImportAccountingSteps.Finalize || !isSubmitConfirmed}
      titleOkay={step != ImportAccountingSteps.Finalize ? '' : 'Submit'}
      titleCancel=""
    >
      <div className={`${step <= ImportAccountingSteps.ImportFile ? 'w-fit' : 'w-[84rem]'} relative`}>
        {renderBackButton()}
        {renderContent()}
      </div>
    </Modal>
  )
}
