import { ClockIcon } from '@heroicons/react/24/outline'
import { loanStatusConstants } from 'config'
import { useState } from 'react'
import { servicingStatusChange } from 'services'
import { svgLoading } from 'stories/assets'
import { Select } from 'stories/components'
import { openAuditLog } from 'utils'

export function ServicingStatus(props: any) {
  const { status, loanNumber, size = 5 } = props
  const [loading, setLoading] = useState(false)

  const changeLoanDetail = async (value: string) => {
    setLoading(true)
    try {
      const res = await servicingStatusChange(value, loanNumber)
      if (res.success) {
        props.onChange(value)
      }
    } catch {}
    setLoading(false)
  }

  const showHistory = () => {
    const options = {
      table: 'LoanProcess',
      field: 'servicingStatus',
      keys: {
        field: 'servicingStatus',
        loanNumber: loanNumber,
      },
    }
    openAuditLog(options)
  }

  let widthClass = 'w-44'
  let sizeClass = 'w-5 h-5'
  let selectSize = 5
  if (size === 4) {
    widthClass = 'w-36'
    sizeClass = 'w-4 h-4'
    selectSize = 3
  }

  return (
    <div className="ServicingStatus-container flex items-center">
      <div className={`${widthClass} -mb-4`}>
        <Select
          id={`servicing-status-${loanNumber}`}
          size={selectSize}
          title=""
          options={loanStatusConstants.servicingStatus}
          disabled={loading}
          value={status}
          hasDefaultOption={true}
          defaultOptionText={''}
          onChange={(value) => changeLoanDetail(value)}
        />
      </div>
      {loading && <img src={svgLoading} className={`inline ${sizeClass} ml-2 mt-2 mb-2 text-white animate-spin`} />}
      {!loading && (
        <span className="ml-2 text-gray-500 cursor-pointer">
          <ClockIcon className={sizeClass} onClick={showHistory} />
        </span>
      )}
    </div>
  )
}
