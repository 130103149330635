import { CheckIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { deleteAccounting, getAccountingByFileId } from 'services'
import { svgSearch } from 'stories/assets'
import { ButtonGroup, Checkbox, Input2 } from 'stories/components'
import { confirm, formatDate, sortByKey, thousandSeperator } from 'utils'

import type { IAccountingCategory } from '../AccountingCategories'
import { type IAccounting, EditAccountingContent } from '../AccountingRecords'
// import { onAccountingHistory } from '../AccountingRecords/util'
import type { IAccountingAccount } from '../Accounts/types'
import { MultiRecordUpdateDialog } from './MultiRecordUpdateDialog'
import type { IAccountingFile } from './types'

export const FileDetails = ({
  baseCategory,
  category,
  accounts,
  data: file,
  setLoading,
  update,
}: {
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  accounts: IAccountingAccount[]
  data: IAccountingFile
  setLoading: Function
  update: Function
}) => {
  const [data, setData] = useState<IAccounting[]>([])
  const [activeItem, setActiveItem] = useState<IAccounting | null>(null)
  const [filters, setFilters] = useState({
    query: '',
    status: 'pending',
  })
  const [showMultiApprove, setShowMultiApprove] = useState(false)

  useEffect(() => {
    setLoading(true)
    getAccountingByFileId(file.id)
      .then((v) => setData(v))
      .finally(() => setLoading(false))
  }, [])

  const onCheckAll = (value: boolean) => {
    const newData = cloneDeep(data)
    filteredData.forEach((v) => {
      const index = newData.findIndex((data) => data.id == v.id)
      if (index < 0) return
      newData[index].checked = value
    })
    setData(newData)
  }

  const onUpdateFilter = (key: string, value: any) => {
    const newFilter = cloneDeep(filters)
    ;(newFilter as any)[key] = value
    setFilters(newFilter)
    if (key == 'status') {
      const newData = cloneDeep(data)
      newData.forEach((item) => (item.checked = false))
      setData(newData)
    }
  }

  const onUpdate = (item: IAccounting, key: string, value: any) => {
    const newData = cloneDeep(data)
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) return
    newData[index] = {
      ...item,
      [key]: value,
    }

    setData(newData)
  }

  const onApprove = (item: IAccounting) => {
    if (activeItem) {
      toast(
        `Kindly ensure that the current editing record #${activeItem.id} is submitted or closed before proceeding further.`,
        {
          type: 'warning',
        },
      )
      return
    }
    setActiveItem(item)
  }

  const onUpdateEditItem = (item: IAccounting[] | IAccounting | null) => {
    setActiveItem(null)
    if (!item) return

    const newData = cloneDeep(data)

    if (Array.isArray(item)) {
      item.forEach((child) => {
        const index = data.findIndex((v) => v.id == child.id)
        if (index == -1) newData.push(child)
        else newData[index] = child
      })
    } else {
      const index = data.findIndex((v) => v.id == item.id)
      if (index == -1) return
      newData[index] = item
    }

    setData(newData)
    updateFileStates(newData)
  }

  const onDelete = async (item: IAccounting) => {
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) return

    if (!item.deleted) {
      const content = (
        <div className="text-gray-400 mb-4 text-[18px]">
          Are you sure want to delete this item?
          <br />
          <span className="text-gray-600">
            Description: {item.description} #{item.id}
          </span>
        </div>
      )
      const result = await confirm(content)
      if (!result) return
    }

    setLoading(true)
    try {
      await deleteAccounting(item.id)

      const newValues = cloneDeep(data)
      newValues[index].deleted = !newValues[index].deleted
      setData(newValues)
      if (activeItem && item.id == activeItem.id) setActiveItem(null)
      updateFileStates(newValues)
    } catch (e) {}
    setLoading(false)
  }

  const onMultiApprove = () => {
    setShowMultiApprove(true)
  }

  const onCloseMultiUpdate = (updatedData: IAccounting[] | null) => {
    setShowMultiApprove(false)
    if (!updatedData) return

    const newData = cloneDeep(data)
    newData.forEach((v, index) => {
      const updatedItem = updatedData.find((data) => data.id == v.id)
      if (!updatedItem) return
      newData[index] = updatedItem
    })
    setData(newData)
  }

  const updateFileStates = (data: IAccounting[]) => {
    const approved = data.filter((v) => v.actived || v.deleted).length
    update(file.id, { approved, total: data.length })
  }

  const renderStatus = (item: IAccounting) => {
    if (item.deleted) return <p className="text-sm text-red-500">Deleted</p>
    if (!item.actived) return <p className="text-sm text-yellow-400">Pending</p>
    return <p className="text-sm text-green-500">Approved</p>
  }

  const filteredData = useMemo<IAccounting[]>(() => {
    const { query, status } = filters
    return sortByKey(data, 'actived').filter((v: IAccounting) => {
      return (
        (!query || `${formatDate(v.date)} ${v.amount} ${v.description}`.toLowerCase().includes(query)) &&
        ((filters.status == 'pending' && !v.actived && !v.deleted) ||
          (status == 'approved' && v.actived && !v.deleted) ||
          (status == 'deleted' && v.deleted) ||
          status == 'all')
      )
    })
  }, [data, filters])

  const hasSelectedItems = useMemo(() => {
    return !!filteredData.filter((v) => v.checked).length
  }, [filteredData])

  const isAllChecked = useMemo(() => {
    return !!filteredData.length && filteredData.filter((v) => v.checked).length == filteredData.length
  }, [filteredData])

  return (
    <div className="sm:rounded-lg my-2 text-gray-900">
      <div className="flex justify-center items-center mb-4">
        <div className="flex-1">
          <div className="w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={svgSearch}
              value={filters.query}
              onChange={(v) => onUpdateFilter('query', v)}
            />
          </div>
        </div>
        <div className="scale-90">
          <ButtonGroup
            title={{
              pending: 'Pending',
              approved: 'Approved',
              deleted: 'Deleted',
              all: 'All',
            }}
            value={filters.status}
            onChange={(v) => onUpdateFilter('status', v)}
          />
        </div>
        <div className="flex-1 flex justify-end">
          {hasSelectedItems && (
            <p
              className="flex items-center gap-2 text-sm cursor-pointer hover:underline text-shade-blue"
              onClick={onMultiApprove}
            >
              {filters.status == 'approved' ? (
                <>
                  <PencilSquareIcon className="w-4 h-4" />
                  Update
                </>
              ) : (
                <>
                  <CheckIcon className="w-4 h-4" />
                  Approve
                </>
              )}
            </p>
          )}
        </div>
      </div>

      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-3 py-3 w-[20px]">
              <Checkbox
                id={`check-no-all`}
                fontClass="!font-semibold !text-gray-700 !text-xs"
                title="No"
                checked={isAllChecked}
                onChange={(v) => onCheckAll(v)}
              />
            </th>
            <th scope="col" className="px-3 py-3">
              Date
            </th>
            <th scope="col" className="px-3 py-3">
              Description
            </th>
            <th scope="col" className="px-3 py-3">
              Amount
            </th>
            <th scope="col" className="px-3 py-3">
              Status
            </th>
            <th scope="col" className="px-3 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length ? (
            filteredData.map((item: IAccounting, index) => {
              return (
                <React.Fragment key={`file-${item.id}`}>
                  <tr className={`border-b dark:bg-gray-800 dark:border-gray-700`} key={index}>
                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      <div>
                        <Checkbox
                          id={`check-${index + 1}`}
                          title={`${index + 1}`}
                          checked={item.checked}
                          onChange={(v) => onUpdate(item, 'checked', v)}
                        />
                      </div>
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {formatDate(item.date)}
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white">
                      {item.description} #{item.id}
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap">
                      {thousandSeperator(item.amount)}
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {renderStatus(item)}
                    </td>

                    <td className="px-6 py-1">
                      <div className="flex gap-2">
                        {(!item.actived || item.deleted) && (
                          <span
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onApprove(item)}
                          >
                            <CheckIcon className="w-4 h-4" />
                          </span>
                        )}

                        {!item.deleted && (
                          <span
                            className="text-red-500 p-1 hover-shadow1 cursor-pointer"
                            onClick={() => onDelete(item)}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </span>
                        )}

                        {/* {(item.actived || item.deleted) && (
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                        onClick={() => onAccountingHistory(item, category, accounts)}
                      >
                        <ClockIcon className="w-4 h-4" />
                      </span>
                    )} */}
                      </div>
                    </td>
                  </tr>
                  {activeItem && activeItem.id == item.id && (
                    <tr>
                      <td></td>
                      <td colSpan={5}>
                        <EditAccountingContent
                          type={file.type}
                          baseCategory={baseCategory}
                          category={category}
                          accounts={accounts}
                          item={activeItem}
                          onClose={onUpdateEditItem}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })
          ) : (
            <tr>
              <td colSpan={6}>
                <p className="text-sm text-center">--- No data ---</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showMultiApprove && (
        <MultiRecordUpdateDialog
          baseCategory={baseCategory}
          category={category}
          accounts={accounts}
          data={filteredData.filter((v) => v.checked)}
          onClose={onCloseMultiUpdate}
        />
      )}
    </div>
  )
}
