import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    accountType: {
      inputType: 'multiselect',
      title: 'Account Type',
      options: accountTypes,
      required: true,
      value: {},
      sort: true,
    },
    title: {
      inputType: 'text',
      type: 'text',
      title: 'Title',
      value: '',
      required: true,
    },
    content: {
      inputType: 'textarea',
      title: 'Content',
      value: '',
      required: true,
    },
    file: {
      inputType: 'file',
      title: 'File',
      required: false,
    },
  }
}
