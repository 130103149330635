import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { downloadS3Documents, getFloodOrder, saveFloodOrderInfo, submitFloodOrder, syncNationalFlood } from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, validateGoogleAddress } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { borrowerPropertyInputGroups, defaultInputs, floodProductTypes, floodResultInfoInputGroups } from './constants'
import { ServiceLinkOrderModal } from './ServiceLinkOrderModal'
import type { INationalFloodOrder } from './types'

export function NationalFlood({ child = false }) {
  const [loading, setLoading] = useState(false)
  const [borrowerLoading, setBorrowerLoading] = useState(false)
  const [floodLoading, setFloodLoading] = useState(false)
  const [orders, setOrders] = useState<INationalFloodOrder[]>([])
  const [inputs, setInputs] = useState(defaultInputs())
  const [isModalOpened, setModalOpened] = useState(false)
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const [action, setAction] = useState('')

  useEffect(() => {
    setLoanNumber()
    setDefaultValues()
  }, [])

  const certificateNumbers = useMemo(() => {
    const nums: string[] = []
    orders.forEach(({ certificate_number }) => {
      if (!nums.includes(certificate_number)) nums.push(certificate_number)
    })
    return nums
  }, [orders])

  const setDefaultValues = async () => {
    setLoading(true)
    const {
      data: { info = {}, orders = [] },
      loanDetail,
    } = await getFloodOrder()

    setLoading(false)

    const {
      firstName,
      middleName,
      lastName,
      includeCoborrower,
      coFirstName,
      coMiddleName,
      coLastName,
      subjectPropertyAddress,
      propertyCounty,
    } = loanDetail

    setOrders(orders)
    const newInputs = cloneDeep(inputs)
    newInputs.firstName.value = firstName
    newInputs.middleName.value = middleName
    newInputs.lastName.value = lastName

    if (includeCoborrower) {
      newInputs.coFirstName.value = coFirstName
      newInputs.coMiddleName.value = coMiddleName
      newInputs.coLastName.value = coLastName
    } else {
      delete newInputs.coFirstName
      delete newInputs.coMiddleName
      delete newInputs.coLastName
    }

    const addresses = await validateGoogleAddress(subjectPropertyAddress)
    newInputs.street.value = addresses.street_address1
    newInputs.city.value = addresses.city
    newInputs.state.value = addresses.state
    newInputs.zip.value = addresses.postal_code
    newInputs.county.value = propertyCounty

    Object.keys(info).forEach((key: string) => {
      if (!newInputs[key]) return

      newInputs[key].value = info[key] || newInputs[key].value
      if (newInputs[key].type === 'date' && info[key].length > 0) {
        newInputs[key].value = moment(info[key]).format('YYYY-MM-DD')
      }
    })

    setInputs(newInputs)
  }

  const onChangeInput = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
    setFieldValueChanged(true)
  }

  const onBlurInput = async (key: string, index: number) => {
    if (!fieldValueChanged) return
    const { value } = inputs[key]

    if (index == 0) setBorrowerLoading(true)
    else setFloodLoading(true)

    await saveFloodOrderInfo({ key, value })

    if (index == 0) setBorrowerLoading(false)
    else setFloodLoading(false)
    setFieldValueChanged(false)
  }

  const onSubmitOrder = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    for (const key in borrowerPropertyInputGroups) {
      const keys = (borrowerPropertyInputGroups as any)[key]
      for (const inputKey of keys) {
        if (!newInputs[inputKey]) continue
        newInputs[inputKey].error = InputValidate(newInputs[inputKey])
        if (newInputs[inputKey].error) hasError = true
      }
    }
    setInputs(newInputs)
    if (hasError) return

    setModalOpened(true)
  }

  const onCloseModal = () => {
    setModalOpened(false)
  }

  const onSubmitModal = async (orderData: Record<string, any>) => {
    const {
      data: { orders, info },
    } = await submitFloodOrder(orderData)

    setOrders(orders)

    const newInputs = cloneDeep(inputs)
    Object.keys(info).forEach((key: string) => {
      if (!newInputs[key]) return
      newInputs[key].value = info[key] || newInputs[key].value
    })

    setInputs(newInputs)
  }

  const onDownloadDocument = async (pdfKey: string) => {
    setLoading(true)
    const res = await downloadS3Documents(pdfKey)
    var windowReference: any = window.open()
    windowReference.location = res.url
    setLoading(false)
  }

  const onSync = async () => {
    setLoading(true)
    setAction('sync')
    syncNationalFlood()
      .then(() => {})
      .finally(() => {
        setLoading(false)
        setAction('')
      })
  }

  return (
    <div className={`National-Flood-container ${!child && 'px-2 py-6'}`}>
      {!child && <Overview title="ServiceLink National Flood" />}
      <div className="max-w-screen-2xl m-auto">
        <LayoutLoading show={loading} />
        <div className={`relative bg-white rounded text-center ${!child && 'shadow1 rounded mb-6 p-4'}`}>
          <div className="overflow-auto mb-6 sm:rounded-lg">
            <p className="text-[19px] font-bold mb-3">Order Processing</p>
            <div className="overflow-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-3 py-3">
                      Order No
                    </th>
                    <th scope="col" className="px-3 py-3 min-w-[200px]">
                      Certificate Number
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Product Type
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Condition
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Description
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Order Date (by)
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Request Type
                    </th>
                  </tr>
                </thead>
                <tbody className="text-[15px] text-gray-900">
                  {orders.map((order, index: number) => (
                    <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-3 py-3">{order.order_no}</td>
                      <td className="px-3 py-3">{order.certificate_number}</td>
                      <td className="px-3 py-3">{floodProductTypes[order.product]}</td>
                      <td className="px-3 py-3 statusColor">
                        <span className="flex items-center">
                          <p className={`${order.condition} p-2 inline-block rounded`}>{order.condition}</p>
                          {order.pdf_flag === 0 ? (
                            ''
                          ) : (
                            <span
                              className="ml-1 text-shade-blue cursor-pointer color1 fz15 p-1 rounded hover-shadow1"
                              onClick={() => onDownloadDocument(order.pdfKey)}
                            >
                              <ArrowDownTrayIcon className="w-4 h-4" />
                            </span>
                          )}
                        </span>
                      </td>
                      <td className="px-3 py-3">{order.description}</td>
                      <td className="px-3 py-3">{order._name}</td>
                      <td className="px-3 py-3">
                        <p>{order.req_time}</p>
                        <p>{order.by}</p>
                      </td>
                      <td className="px-3 py-3">{order.action}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Button onClick={onSubmitOrder} className="mb-[5px]">
            Submit Order
          </Button>
        </div>

        <div className="grid gap-x-3 grid-cols-1 md:grid-cols-2 w-full mt-4">
          <div className="relative bg-white shadow border rounded mb-2 p-4">
            <LayoutLoading show={borrowerLoading} />
            <p className="text-[18px] font-bold mb-3">
              Borrower & Property Info
              {borrowerLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin ml-2" />}
            </p>
            {Object.keys(borrowerPropertyInputGroups).map((title) => {
              if (title == 'Co-Borrower Info' && !inputs.coFirstName) return null

              const inputGroup = (borrowerPropertyInputGroups as any)[title]
              return (
                <div className="mb-5" key={title}>
                  <p className="border-b font-bold text-sm w-full mb-2 bg-gray-100 p-2 rounded">{title}</p>
                  <div className="grid gap-4 sm:grid-cols-2 grid-cols-1 mb-3 px-2">
                    {inputGroup.map((key: string) => {
                      const input = inputs[key]
                      return (
                        <div className={`input md:col-span-${input.span || 1}`} key={key}>
                          <RenderInput
                            input={input}
                            Key={key}
                            onChange={onChangeInput}
                            onBlur={(key: string) => onBlurInput(key, 0)}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>

          <div className="relative bg-white shadow border rounded mb-2 p-4  bg-gray-50">
            <LayoutLoading show={floodLoading} />
            <p className="text-[18px] font-bold mb-3">
              Flood Results & HMDA Info
              {floodLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin ml-2" />}
            </p>
            {Object.keys(floodResultInfoInputGroups).map((title) => {
              const inputGroup = (floodResultInfoInputGroups as any)[title]
              return (
                <div className="mb-5" key={title}>
                  <p className="border-b font-bold text-sm w-full mb-3 bg-blue-100 p-2 rounded">{title}</p>
                  <div className="grid gap-4 sm:grid-cols-2 grid-cols-1 mb-3 px-2">
                    {inputGroup.map((key: string) => {
                      let input = inputs[key] as any
                      return (
                        <div className={`input md:col-span-${input.span || 1}`} key={key}>
                          <RenderInput
                            input={input}
                            Key={key}
                            onChange={onChangeInput}
                            onBlur={(key: string) => onBlurInput(key, 1)}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            <div className="text-center">
              <Button onClick={onSync} className="px-[40px]" loading={action === 'sync'}>
                Sync
              </Button>
            </div>
          </div>
        </div>
      </div>

      {isModalOpened && (
        <ServiceLinkOrderModal
          certificateNumbers={certificateNumbers}
          onClose={onCloseModal}
          onSubmit={onSubmitModal}
        />
      )}
    </div>
  )
}
