import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { deleteReviewFormTemplate, getReviewFormTemplates, updateReviewFormTemplateOrder } from 'services'
import { Select } from 'stories/components'
import { confirm } from 'utils'

import type { ReviewFormCategory } from './ReviewFormItems'

export interface ReviewFormTemplate {
  id: number
  no: number
  categoryId: string
  title: string
  description: string
  order: number
}

export const ReviewFormTemplates = ({
  isLoading,
  setLoading,
  categories,
  onEdit,
}: {
  isLoading: boolean
  setLoading: Function
  categories: ReviewFormCategory[]
  onEdit: (v: ReviewFormTemplate) => void
}) => {
  const [values, setValues] = useState<ReviewFormTemplate[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string>()

  useEffect(() => {
    setLoading(true)
    getReviewFormTemplates().then((values: ReviewFormTemplate[]) => {
      setValues(values)
      setLoading(false)
    })
  }, [])

  const categoryMap = useMemo(() => {
    const categoryMap: Record<string, string> = {}
    categories.forEach((category) => {
      if (!category.value) return
      categoryMap[category.id] = category.value
    })
    return categoryMap
  }, [categories])

  const onChangeOrder = async (val: string, value: ReviewFormTemplate) => {
    setLoading(true)
    const newOrder = Number(val)
    await updateReviewFormTemplateOrder(value.id, newOrder)
    setLoading(false)

    const newValues = cloneDeep(values)
    newValues.forEach((v) => {
      if (v.id == value.id) v.order = newOrder
      else if (value.order > newOrder && value.order <= v.order && v.order >= newOrder) v.order += 1
      else if (value.order < newOrder && value.order <= v.order && v.order <= newOrder) v.order -= 1
    })
    setValues(newValues)
  }

  const onDelete = async (item: ReviewFormTemplate) => {
    const result = await confirm('Do you want to remove this template?')
    if (!result) return

    const index = values.findIndex((v) => v.no == item.no)
    if (index === -1) return
    await deleteReviewFormTemplate(values[index].id)

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
  }

  return (
    <div>
      <div className="flex flex-wrap justify-between">
        <div className="flex items-center flex-wrap flex-auto">
          <div className="w-72">
            <Select
              id="searchBy-category"
              title="Filter by Category"
              hasDefaultOption
              defaultOptionText="All"
              options={categoryMap}
              value={categoryFilter}
              onChange={(v) => setCategoryFilter(v)}
            />
          </div>
        </div>
      </div>
      <div className="relative overflow-auto shadow-md sm:rounded-lg">
        <LayoutLoading show={isLoading} />
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3 w-[40px]">
                No
              </th>
              <th scope="col" className="px-3 py-3 w-[120px]">
                Category
              </th>
              <th scope="col" className="px-3 py-3">
                Title
              </th>
              <th scope="col" className="px-3 py-3 w-[50px]">
                Order
              </th>
              <th scope="col" className="px-3 py-3 w-[100px]">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {categories
              .filter((v) => (categoryFilter ? v.id == categoryFilter : true))
              .map((category) => [
                values
                  .filter((item) => item.categoryId == category.id)
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((value, index) => {
                    const totalCount = values.filter((item) => item.categoryId == category.id).length
                    const orderOptions = Array.apply(null, Array(totalCount)).map((_, index) => index.toString())
                    return (
                      <tr
                        className={`${
                          index % 2 ? 'bg-gray-50' : 'bg-white'
                        } border-b dark:bg-gray-800 dark:border-gray-700`}
                        key={value.no}
                      >
                        <td
                          scope="row"
                          className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                          {value.no}
                        </td>

                        <td
                          scope="row"
                          className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                          {categoryMap[value.categoryId]}
                        </td>

                        <td
                          scope="row"
                          className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                          {value.title}
                        </td>
                        <td>
                          <select
                            onChange={(e) => onChangeOrder(e.target.value, value)}
                            value={index}
                            className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                          >
                            {orderOptions.map((val) => (
                              <option key={val}>{val}</option>
                            ))}
                          </select>
                        </td>

                        <td className="px-3 py-2">
                          <span className="flex gap-2">
                            <span
                              className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                              onClick={() => onEdit(value)}
                            >
                              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                            </span>
                            <span
                              className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded"
                              onClick={() => onDelete(value)}
                            >
                              <TrashIcon className="w-4 h-4"></TrashIcon>
                            </span>
                          </span>
                        </td>
                      </tr>
                    )
                  }),
                <tr className="border-b bg-gray-200">
                  <td className="py-1" colSpan={5}></td>
                </tr>,
              ])}
          </tbody>
        </table>
      </div>
    </div>
  )
}
