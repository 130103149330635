import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import {
  changeOrderServicingNoteTemplate,
  deleteServicingNoteTemplate,
  getServicingTemplateNotes,
} from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button, Input2 } from 'stories/components'
import { confirm } from 'utils'

import { ServicingNoteDetails } from './details'

export interface ServicingNote {
  id: number
  note: string
  type: string
}

export function ServicingNotes() {
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState<ServicingNote[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ServicingNote | null>(null)

  useEffect(() => {
    refresh()
  }, [])

  const filteredList = useMemo(() => {
    if (!query) return list
    return list.filter(({ note }) => {
      return note.toLowerCase().includes(query.trim().toLowerCase())
    })
  }, [query, list])

  const refresh = () => {
    setIsLoading(true)
    getServicingTemplateNotes()
      .then((data) => {
        setTotal(data.length)
        setList(data)
      })
      .finally(() => setIsLoading(false))
  }

  const onAdd = () => {
    setSelectedItem(null)
    setIsEditing(true)
  }

  const onEdit = (item: ServicingNote) => {
    setSelectedItem(item)
    setIsEditing(true)
  }

  const onTrash = async (item: ServicingNote) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        <span className="text-gray-600">{item.note}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deleteServicingNoteTemplate(item.id)
      .then(refresh)
      .catch(() => setIsLoading(false))
  }

  const orderOptions = useMemo(() => {
    const rlt: Number[] = []
    list.map((item, index) => rlt.push(index + 1))
    return rlt
  }, [list.length])

  const changeOrder = async (prev: number, _cur: string) => {
    const cur = Number(_cur) - 1
    let _list = cloneDeep(list)
    const temp: ServicingNote[] = []
    _list.splice(prev, 1)
    let added = false
    _list.map((item, index: number) => {
      if (index === cur) {
        temp.push(list[prev])
        added = true
      }
      temp.push(item)
    })
    if (!added) temp.push(list[prev])

    const newOrder = temp.map((item) => item.id)
    setIsLoading(true)
    await changeOrderServicingNoteTemplate(newOrder)
    setIsLoading(false)
    setList(temp)
  }

  if (isEditing)
    return <ServicingNoteDetails defaults={selectedItem} onBack={() => setIsEditing(false)} onComplete={refresh} />

  return (
    <div className="List-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Servicing Notes
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              onChange={(value) => setQuery(value)}
            />
          </div>
          <p className="ml-5">- {total} Items</p>
        </div>
        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="px-3">
                Content
              </th>
              <th scope="col" className="px-3">
                Type
              </th>
              {query.length === 0 && (
                <th scope="col" className="px-3">
                  Order
                </th>
              )}
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {filteredList.map((item, index) => {
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-3">{item.note}</td>
                  <td className="px-3">{item.type}</td>
                  {query.length === 0 && (
                    <td className="px-3">
                      <select
                        value={index + 1}
                        className="py-1 border-gray-300 text-[15px]"
                        onChange={(e) => changeOrder(index, e.target.value)}
                      >
                        {orderOptions.map((item, index1) => {
                          return (
                            <option key={index1} value={`${item}`} disabled={item === index + 1}>
                              {item}
                            </option>
                          )
                        })}
                      </select>
                    </td>
                  )}
                  <td className="px-3">
                    <span className="flex">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(item)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
