import { useEffect } from 'react'
import { Modal } from 'stories/components'

interface ErrorClosingCostsModalProps {
  errorClosingCosts: Record<string, any>
  onClose?: Function
  onErrorSelect?: Function
  onIngoreError?: Function
}
export const ErrorClosingCostsModal = ({
  errorClosingCosts = {},
  onClose = () => {},
  onErrorSelect = () => {},
  onIngoreError = () => {},
}: ErrorClosingCostsModalProps) => {
  useEffect(() => {
    if (!errorClosingCosts.length) onClose()
  }, [errorClosingCosts])
  return (
    <>
      {errorClosingCosts.length && (
        <Modal
          title="Closing Costs Errors"
          isOpen
          titleOkay="Override and Proceed"
          onClose={() => onClose()}
          onOk={() => {
            onIngoreError(), onClose()
          }}
        >
          <>
            <span>Click the error message below to edit the associated closing cost</span>
            <div className="bg-white mt-2 p-2 border-gray-300 border-solid border-[1px]">
              {errorClosingCosts.map((v: Record<string, any>) => (
                <div
                  key={v.HUDLineNo}
                  className="flex items-center gap-2 cursor-pointer hover:bg-blue-200 hover:text-white px-1"
                  onClick={() => onErrorSelect(v)}
                >
                  <p className="text-gray-600">
                    {v.Name && v.Name + ':'} {v.error}
                  </p>
                </div>
              ))}
            </div>
          </>
        </Modal>
      )}
    </>
  )
}
