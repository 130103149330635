import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getServicingNotes, postServicingNotes } from 'services'
import { TextArea } from 'stories/components'
import { setLoanNumber } from 'utils/setLoanNumber'
import { validDateMMDDYYYY } from 'utils/validator'

export const ServicingNote = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({})
  const [order, setOrder] = useState<Array<string>>([])
  const [comment, setComment] = useState('')

  useEffect(() => {
    setLoading(true)
    setLoanNumber()
    getServicingNotes()
      .then((res: any) => {
        if (res.success) {
          setData(res.info)
          setComment(res.comment)
          setOrder(res.order)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = async (key: string, value: string, type: string) => {
    if (type === 'date' && value.length !== 0) {
      if (!validDateMMDDYYYY(value)) {
        return toast(`Please input valid Date(MM/DD/YYYY).`, { type: 'error' })
      }
    }
    const temp = cloneDeep(data)
    temp[key].value = value
    setData(temp)
    setLoading(true)
    await postServicingNotes({
      key,
      value,
    })
    setLoading(false)
  }

  const changeComment = (value: string) => {
    setComment(value)
  }

  const onBlurComment = async () => {
    setLoading(true)
    await postServicingNotes({
      key: 'comment',
      value: comment,
    })
    setLoading(false)
  }

  return (
    <div className="servicing-note-container">
      <LayoutLoading show={loading} />
      <div className="w-full">
        <div>
          {order.map((key, index) => {
            if (!data[key]) return null
            let { note, value, type } = data[key]
            if (value === undefined || value === null) value = ''
            return (
              <div key={index} className="flex flex-wrap items-center my-2">
                <div className="p-2 text-[14px]">{note}</div>
                <div className="flex-1">
                  <PlainInput
                    value={value}
                    content={value}
                    onChange={(val: string) => onChange(key, val, type)}
                    className="border-b"
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className="mt-4">
          <TextArea
            title="Comment"
            rows={4}
            value={comment}
            onChange={(v) => changeComment(v)}
            onBlur={onBlurComment}
          />
        </div>
      </div>
    </div>
  )
}
