import cloneDeep from 'clone-deep'
import { type InputType, howHearAboutUsOpions, loanOptionsConstants, preferredCommunicationOptions } from 'config'
import { useEffect, useState } from 'react'
import { InputConvert } from 'utils'

import { renderActions } from '../components'
import type { IBorrowerLoanRequestStepProps } from './interface'
import { renderInputs, validateForm } from './util'

const defaultInputs = (): Record<string, InputType> => {
  return {
    borrowerType: {
      inputType: 'select',
      hasDefaultOption: true,
      title: 'How will you hold title to this property?',
      options: {
        LLC: 'LLC',
        Corp: 'Corporation',
        Individual: 'Individual name',
        Partnership: 'Partnership',
        'Revocable Trust': 'Trust',
      },
      required: true,
    },

    // If not DSCR, then show, If DSCR, do not ask this question.
    loanTerm: {
      title: 'What is the term you would like to request for this specific transaction?',
      inputType: 'select',
      options: loanOptionsConstants.loanTerm,
      hasDefaultOption: true,
      visible: (inputs: Record<string, InputType>, values: Record<string, any>) =>
        values.productType != 'DSCR (Long Term Rental)',
      required: true,
    },

    howHear: {
      title: 'How did you hear about us?',
      inputType: 'select',
      options: howHearAboutUsOpions,
      hasDefaultOption: true,
      required: true,
    },

    preferredCommunication: {
      title: 'Preferred Communication',
      inputType: 'multiselect',
      options: preferredCommunicationOptions,
      defaultSelected: false,
      required: true,
    },
  }
}

export const LastQuestions = ({
  lender,
  values = {},
  allValues = {},
  onSubmit: _onSubmit,
  onBack,
}: IBorrowerLoanRequestStepProps) => {
  const [inputs, setInputs] = useState<Record<string, InputType>>({})

  useEffect(() => {
    setValues(values || {})
  }, [values])

  const setValues = (data: Record<string, any>) => {
    const newInputs = cloneDeep(defaultInputs())
    Object.keys(newInputs).map((key) => {
      const input = newInputs[key]
      if (data[key] !== undefined) input.value = data[key]
    })
    setInputs(newInputs)
  }

  const onChange = (key: string, value: string) => {
    if (inputs[key].value === value) return

    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const { hasError, data } = validateForm({
      inputs,
      setInputs,
      allValues,
    })
    if (hasError) return

    _onSubmit(data)
  }

  return (
    <div>
      <p className="mb-6">
        You’re well on your way to becoming a superstar real estate investor with {lender}! We have a few more
        questions.
      </p>

      {renderInputs({ inputs, allValues, onChange })}

      <p className="mb-6">
        All of those details are really helpful, and You’re being directed to our complete your application so that you
        can begin your journey:
      </p>

      {renderActions(onBack, onSubmit, 'Proceed')}
    </div>
  )
}
