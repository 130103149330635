import type { InputType } from 'config'
import { fullStates } from 'config/states.constants'

export const inputGroups: Record<string, string[]> = {
  'Property Info': [
    'recordingRequestedBy',
    'whenRecordedReturnTo',
    'loanAmount',
    'loanDate',
    'loanNumber',
    'propertyAddress',
    'county',
    'state',
    'borrowers',
    'companyParcelNumber',
  ],
  'Assignor Info': ['assignorEntityName', 'assignorAddress', 'assignorState', 'assignorEntityType'],
  'Assignee Info': ['assigneeName', 'assigneeAddress', 'assigneeState', 'assigneeEntityType'],
  'Signors Info': ['signorsName', 'signorsTitle'],
  'Deed Info': ['deedOfTrust', 'trustorName', 'trusteeCompanyName', 'trusteeName', 'deedBookName', 'deedBookPage'],
  'Other Info': ['recordedOn', 'securityInstrument'],
  'Legal Info': ['legalType', 'legalDescription', 'legalFile'],
}

export enum PostClosingAssignmentPdfType {
  Mortgage = 0,
  DeedOfTrust = 1,
  SecurityDeed = 2,
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    // Property Info
    recordingRequestedBy: {
      inputType: 'text',
      type: 'text',
      title: 'Recording Requested by',
      value: '',
      error: '',
      required: true,
    },
    whenRecordedReturnTo: {
      inputType: 'text',
      type: 'text',
      title: 'When Recorded Return to',
      value: '',
      error: '',
      required: true,
    },
    loanAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Loan Amount',
      value: '',
      error: '',
      required: true,
      prefix: '$',
    },
    loanDate: {
      inputType: 'text',
      type: 'date',
      title: 'Loan Date',
      value: '',
      error: '',
      required: true,
    },
    dated: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: '',
      error: '',
      required: true,
    },
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan Number',
      value: '',
      error: '',
      required: true,
    },
    propertyAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Property Address',
      value: '',
      error: '',
      required: true,
    },
    county: {
      inputType: 'text',
      type: 'text',
      title: 'County',
      value: '',
      error: '',
      required: true,
    },
    state: {
      inputType: 'select',
      options: fullStates,
      title: 'State',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    borrowers: {
      inputType: 'text',
      type: 'text',
      title: 'Borrowers',
      value: '',
      error: '',
      required: true,
    },
    companyParcelNumber: {
      inputType: 'text',
      title: 'Company Parcel Number',
      value: '',
      error: '',
      required: true,
    },

    // Assignor Info
    assignorEntityName1: {
      inputType: 'select',
      options: [],
      title: 'Assignor Entity Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    assignorEntityName2: {
      inputType: 'select',
      options: [],
      title: 'Assignor Entity Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    assignorEntityName3: {
      inputType: 'select',
      options: [],
      title: 'Assignor Entity Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    assignorEntityName4: {
      inputType: 'select',
      options: [],
      title: 'Assignor Entity Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    assignorEntityName5: {
      inputType: 'select',
      options: [],
      title: 'Assignor Entity Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    assignorEntityName6: {
      inputType: 'select',
      options: [],
      title: 'Assignor Entity Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    assignorEntityName7: {
      inputType: 'select',
      options: [],
      title: 'Assignor Entity Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    assignorAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Assignor Address',
      value: '',
      error: '',
      required: true,
    },
    assignorState: {
      inputType: 'select',
      options: fullStates,
      title: 'Assignor State',
      hasDefaultOption: true,
      defaultOptionText: 'Blank',
      value: '',
      error: '',
      required: true,
    },
    assignorEntityType: {
      inputType: 'select',
      options: [
        'Corporation',
        'General Partnership',
        'Government Entity',
        'Illionis Land Trust',
        'Joint Venture',
        'Land Trust and Beneficiary is Individual',
        'Land Trust',
        'Limited Liability Company (LLC)',
        'Limited Partnership',
        'Living Trust',
        'Native American Tribe or Tribal Organization',
        'Non Profit Corporation',
        'Partnership',
        'S Corporation',
        'Sole Proprietorship',
      ],
      title: 'Assignor Entity Type',
      hasDefaultOption: true,
      required: true,
      value: '',
      error: '',
    },

    // Assignee Info
    assigneeName: {
      inputType: 'text',
      type: 'text',
      title: 'Assignee Name',
      value: '',
      error: '',
      required: false,
    },
    assigneeAddress: {
      inputType: 'text',
      type: 'text',
      title: 'Assignee Address',
      value: '',
      error: '',
      required: false,
    },
    assigneeState: {
      inputType: 'select',
      options: fullStates,
      title: 'Assignee State',
      hasDefaultOption: true,
      value: '',
      error: '',
    },
    assigneeEntityType: {
      inputType: 'select',
      options: [
        'BLANK',
        'Corporation',
        'General Partnership',
        'Government Entity',
        'Illionis Land Trust',
        'Joint Venture',
        'Land Trust and Beneficiary is Individual',
        'Land Trust',
        'Limited Liability Company (LLC)',
        'Limited Partnership',
        'Living Trust',
        'Native American Tribe or Tribal Organization',
        'Non Profit Corporation',
        'Partnership',
        'S Corporation',
        'Sole Proprietorship',
      ],
      title: 'Assignee Entity Type',
      hasDefaultOption: true,
      required: true,
      value: '',
      error: '',
    },

    // Signors Info
    signorsName: {
      inputType: 'text',
      type: 'text',
      title: 'Signors Name',
      value: '',
      error: '',
    },
    signorsTitle: {
      inputType: 'text',
      type: 'text',
      title: 'Signors Title',
      value: '',
      error: '',
    },

    // Deed Info
    deedOfTrust: {
      inputType: 'text',
      type: 'text',
      title: 'Deed of Trust',
      value: '',
      error: '',
    },
    trustorName: {
      inputType: 'text',
      type: 'text',
      title: 'Trustor Name',
      value: '',
      error: '',
    },
    trusteeCompanyName: {
      inputType: 'text',
      type: 'text',
      title: 'Trustee Company Name',
      value: '',
      error: '',
    },
    trusteeName: {
      inputType: 'text',
      type: 'text',
      title: 'Trustee Name',
      value: '',
      error: '',
    },
    deedBookName: {
      inputType: 'text',
      type: 'text',
      title: 'Deed Book Name',
      value: '',
      error: '',
    },
    deedBookPage: {
      inputType: 'text',
      type: 'text',
      title: 'Deed Book Page',
      value: '',
      error: '',
    },

    // Other Info
    recordedOn: {
      inputType: 'text',
      type: 'date',
      title: 'Recorded on',
      value: '',
      error: '',
    },
    securityInstrument: {
      inputType: 'text',
      type: 'text',
      title: 'Security/Instrument',
      value: '',
      error: '',
    },

    // Legal Info
    legalType: {
      inputType: 'select',
      options: {
        fill: 'Fill Legal',
        attach: 'Attach Legal',
      },
      title: 'Legal type',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    legalDescription: {
      inputType: 'textarea',
      title: 'Legal Description',
      value: '',
      error: '',
      span: 2,
      required: true,
    },
    legalFile: {
      inputType: 'file',
      title: 'Legal File',
      value: '',
      error: '',
      span: 2,
      required: false,
    },
  }
}
