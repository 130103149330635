import type { ILoanGlobalSettings } from 'pages/Admin/AdminTools/Configuration/GlobalSettings'
import type { IRange } from 'pages/Resources'

export interface ILLPA {
  name: string
  isEnabled: boolean
  value: number
  label?: string
  from?: number
  to?: number
}

export interface ILockTermPrice {
  LockDay: string
  BasePrice: number
  LockTermLLPA: number
  Price: string
  type?: string
  IsPPPMaxPrice?: string
}

export interface IPrice_Program_Price {
  Rate: number
  BaseRate: number
  Dscr?: number
  RateSpread?: number
  ExitFee?: number
  LockTermPrices: ILockTermPrice[]
  CalcHistory?: string[]
  LLPA?: Record<string, ILLPA>
  Limit?: IPriceLimit
  SubProgramID?: number
}

export interface IPrice_Program {
  ProgramID: number
  ProgramName: string
  Margin: number
  Prices: IPrice_Program_Price[]
  LLPA: Record<string, ILLPA>
  CalcHistory: string[]
  LTV: IRange
  LockInfo?: {
    Margin: number
    LLPA: Record<string, ILLPA>
    CalcHistory: string[]
    LTV: IRange
  }
}

export interface IPrice {
  ProductID: number
  ProductName: string
  Programs: IPrice_Program[]
}

export interface IInElibileProduct {
  [key: string]: {
    name: string
    value: string
    description: string
    ltvMaxCalcHistory?: string[]
  }[]
}

export interface IProduct {
  Id: number
  Description: string
  IsInterestOnly?: boolean
  Power?: boolean
  Term?: number
  Type?: string
}

export interface IProgram {
  ID: number
  Name: string
  LtvLimit?: IPriceLimit
}

export interface ILoanProcess {
  type: string
  program: string
  subProgramID?: number
  rate: number
  price: number
  sheetDate: string
  rateSpread: number
  exitFee: number
  lockDays: number
  lockedDate?: string
  expireDate?: string
  rateLocked?: boolean
  restructureApplicationRelease?: boolean
  extensions?: number[]
  [key: string]: any
}

export interface IPriceLimit {
  Rate?: number
  aiv_ltv?: number
  arv_ltv?: number
  ltc?: number
  ltp?: number
  max_aiv_ltv?: number
  max_arv_ltv?: number
  max_ltc?: number
  max_ltp?: number
}

export enum LoanType {
  DEFAULT = 0,
  CORRESPONDENT = 1,
  RETAIL = 2,
  TABLEFUNDER = 3,
}

export interface ILoan {
  id: number
  no: number
  byteproFileName: number
  productType: string
  transactionType: string
  proposedOccupancy: string
  loanTerm: number
  borrowerType: string
  proposedLoanAmount: number
  propertyPurchasePrice: number
  asIsValue: number
  afterRepairValue: number
  rehabBudget: number
  interestReserve: number
  constructionReserve: number
  lienPayoff: number
  closingCostEstimate: number
  experience: string
  howManyExperiences: number
  proposedMonthlyTaxes: number
  proposedMonthlyInsurance: number
  proposedMonthlyRent: number
  proposedMonthlyHoaDues: number
  floodInsurance: number
  schoolTax: number
  otherTax: number
  propertyType: string
  condoType: string
  prepaymentPenalty: number
  residency: string
  amortizationType: string
  escrowType: string
  countryOfCreditScore: string
  firstTimeHomeBuyer: boolean
  firstTimeHomeInvestor: boolean
  propertyUnderConstruction: boolean
  propertyHasBeenListed: boolean
  ruralProperty: boolean
  bankruptcy: string
  foreclosure: string
  deedInLieu: string
  shortSale: string
  mortgageLates: string
  secondLien: number
  secondLienPayment: number
  monthsReserve: number
  estimatedDscr: number
  numberOfUnits: number
  borrowerFirstName: string
  borrowerMiddleName: string
  borrowerLastName: string
  entityTitle: string
  estimatedCreditScore: number
  isNoScore: boolean
  propertyState: string
  subjectPropertyAddress: string
  additionalPropertyAddress: any[]
  propertyCounty: string
  apartmentUnit: number
  parcelNo: string
  yearAcquired: number
  amountExistingLiens: number
  nyBlock: string
  nyLot: string
  includeCoborrower: boolean
  type: LoanType
  deleted: boolean
  createdAt: Date
  updatedAt: Date
  ltvAdjustment: number
  cltvAdjustment: number
  aivLtvAdjustment: number
  arvLtvAdjustment: number
  ltcAdjustment: number
  ltpAdjustment: number
}

export interface IMinMaxLimit {
  min: number
  max: number
}

export interface ICrossCollateralBlanket {
  numberOfPropertiesLimit: IMinMaxLimit
  propertyLevelAllocatedBalanceLimit: IMinMaxLimit
  eligibleOccupancies: string[]
  eligiblePropertyTypes: string[]
  minAsIsValue: number
  minPurchasePrice: number
  minDscr: {
    propertyType: string
    amortizingPaymentMinDscr: number
    interestOnlyMinDscr: number
  }[]
}

export interface ILoanDetail {
  loanNumber: string
  byteproFileName: number
  entityTitle: string
  borrowerName: string
  propertyAddress: string
  loanProcessDate: string
  loanStatus: string
  servicingStatus: any
  servicingPipeline: boolean
  includeCoborrower: boolean
  loanType: LoanType
  parties: Record<string, any>
  closeParties: Record<string, any>
  rateData: Record<string, any>
  priceAdjustments: IPriceAdjustment[]
  restructureApplicationRelease: boolean
  closingDate: string
  emailContacts: any[]
  rush: boolean
  submitToLoanSetup: number
  submitConditions: number
  submitToUnderwriter: number
  pushedToYieldeasyAt: any
  crossCollateralBlanketLimit: ICrossCollateralBlanket
  allocatedLoanAmount: number[]
  loanGlobalSettings: ILoanGlobalSettings
  pricingEngine: boolean
}

export interface ILockDay {
  description: string
  Power?: boolean
}

export interface ILoanDetailChangedJson {
  label: string
  origin: any
  changed: any
}

export interface IPendingLoanChangeRequestData {
  loanDetailChanges: Record<string, ILoanDetailChangedJson>
  pricingChanges: ILoanProcess
  taskID: number
  type: number
}

export interface IPriceAdjustment {
  id: number
  note: string
  price: string
  rate: string
  type: string
}

export interface ILoanRateSheet {
  id?: number
  loanNumber?: number
  ratesheetID: number | undefined
  data: {
    selectedProgram: IPrice_Program_Price[]
    relockTimes: number
    prices: IPrice[]
    priceLoanTime: string
    programs: IProgram[]
    products: IProduct[]
    priceAdjustments: IPriceAdjustment[]
    lockDays: ILockDay[]
    limit: IPriceLimit | undefined
    selectedProductId: number | undefined
    selectedProgramId: number | undefined
    priceIndex: { index1: number; index: number }
  }
}

export interface ISaveToPipelineParams {
  ratesheetJson?: ILoanRateSheet
  loanProcessDataJson?: ILoanProcess
}

export interface IConcessionData {
  note: any
  value: any
  date: string
}

export interface ITermSheetPlaceholders {
  LoanAmount: string
  LTV: string
  BorrowerName: string
  BrokerStreet: string
  BrokerCity: string
  BrokerState: string
  BrokerZipCode: string
  LenderCompany: string
  SubjectPropertyAddress: string
  InterestRate: string
  LoanTerm: string
  DiscountFeePercent: string
  DiscountFeeAmount: string
  CashBorrowerAtClosing: string
  ExitFee: string
  ConstructionBudget: string
  ConstructionAmountFinanced: string
  BrokerFeeAmount: string
  BrokerFeePercent: string
  BrokerCompanyName: string
  UnderwritingFee: string
  BorrowerType: string
  FiveLaterDate: string
  TodayDate: string
  LoanOfficerOrBrokerName: string
  EstimatedCreditScore: string
  CommitmentFee: string
  BreakUpFee: string
}
