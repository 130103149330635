import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { addNewAppraisalInformation, updateAppraisalInformation } from 'services'
import { Modal } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'

export const AddAppraisalInformation = (props: any) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    setLoading(false)
    if (props.data) {
      let newInputs = cloneDeep(inputs)
      const { data } = props
      Object.keys(data).forEach((key) => {
        if (newInputs[key]) newInputs[key].value = data[key]
      })
      setInputs(newInputs)
    }
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onClose = () => {
    props.onClose(false)
  }

  const onSubmit = () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)

    if (props.data) {
      updateAppraisalInformation(props.data.id, data)
        .then(() => {
          props.onClose(true)
        })
        .catch(() => setLoading(false))
    } else {
      addNewAppraisalInformation(data)
        .then(() => {
          props.onClose(true)
        })
        .catch(() => setLoading(false))
    }
  }

  const fillInfo = () => {
    let newInputs = cloneDeep(inputs)
    Object.keys(props.appraiserInfo).map((key) => {
      let value = props.appraiserInfo[key]
      value = InputConvert(newInputs[key], value)
      newInputs[key].value = value
      newInputs[key].error = InputValidate({ ...newInputs[key], value })
    })
    setInputs(newInputs)
  }

  return (
    <Modal
      title={!props.data ? 'Add Appraisal Information' : `Appraisal Information No.${props.index + 1}`}
      titleOkay={props.data ? 'Update' : 'Save'}
      loading={isLoading}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          if (key === 'company') {
            let tooltip = ''
            Object.keys(props.appraiserInfo).map((key, index) => {
              tooltip += `<div class="mb-1" key=${index}>${inputs[key].title}: ${props.appraiserInfo[key]}</div>`
            })
            input.additionalElements = (
              <span
                className="mx-2 text-shade-blue hover:underline cursor-pointer font-variation-settings-600"
                onClick={fillInfo}
              >
                <Tooltip message={tooltip}>
                  <span>Fill Appraisal Review Info</span>
                </Tooltip>
              </span>
            )
          }
          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
