import {
  API_LOAN_GET_SHIPPING_INFO,
  API_LOAN_GET_SHIPPING_INFO_LOG,
  API_LOAN_UPDATE_SHIPPING_INFO,
  API_POST_CLOSING_DOCS,
  API_POST_CLOSING_FIELDS,
  API_PURCHASE_ADVICE_DATA,
  API_PURCHASE_ADVICE_LOG,
  API_PURCHASE_ADVICE_SELLER_DATA,
  API_PURCHASE_ADVICE_SELLER_LOG,
  API_PURCHASE_ADVICE_SELLER_SIGN,
  API_PURCHASE_ADVICE_SELLER_SIGN_BY_ID,
} from 'config'
import Api from 'services/api'

import { downloadFile } from '../documents'

export const updatePostClosingFields = (menu: string, loanFields: Record<string, any>) => {
  return Api.put(API_POST_CLOSING_FIELDS, loanFields, { menu })
}

export const getPostClosingFields = (menu: string) => {
  return Api.get(API_POST_CLOSING_FIELDS, {}, { menu })
}

export const downloadPostClosingDocs = async (loanNumber: number, entity: string) => {
  const response: Blob = await Api.get(
    API_POST_CLOSING_DOCS,
    { entity },
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(`PostClosing - ${loanNumber}.zip`, response)
}

export const getPurchaseAdviceData = () => {
  return Api.get(API_PURCHASE_ADVICE_DATA)
}

export const postPurchaseAdviceDate = (data: Record<string, any>) => {
  return Api.post(API_PURCHASE_ADVICE_DATA, data)
}

export const getPurchaseAdviceLogs = (key: string) => {
  return Api.get(API_PURCHASE_ADVICE_LOG, {}, { key })
}

export const sendPurchaseAdviceDataToAccounting = () => {
  return Api.post(`${API_PURCHASE_ADVICE_DATA}/accounting`)
}

export const getPurchaseAdviceSellerData = () => {
  return Api.get(API_PURCHASE_ADVICE_SELLER_DATA)
}

export const postPurchaseAdviceSellerData = (data: Record<string, any>) => {
  return Api.post(API_PURCHASE_ADVICE_SELLER_DATA, data)
}

export const getPurchaseAdviceSellerLogs = (key: string) => {
  return Api.get(API_PURCHASE_ADVICE_SELLER_LOG, {}, { key })
}

export const sendPurchaseAdviceSignRequest = (data: Record<string, any>) => {
  return Api.post(API_PURCHASE_ADVICE_SELLER_SIGN, data)
}

export const getPurchaseAdviceSellerSignData = (id: string) => {
  return Api.get(API_PURCHASE_ADVICE_SELLER_SIGN_BY_ID, {}, { id })
}

export const signPurchaseAdviceSellerData = (id: string) => {
  return Api.post(API_PURCHASE_ADVICE_SELLER_SIGN_BY_ID, {}, { id }, { timeout: 150000 })
}

export const getShippingInfo = () => {
  return Api.get(API_LOAN_GET_SHIPPING_INFO, {})
}

export const updateShippingInfo = (data: Record<string, any>) => {
  return Api.post(API_LOAN_UPDATE_SHIPPING_INFO, data)
}

export const getShippingInfoLogs = (key: string) => {
  return Api.get(API_LOAN_GET_SHIPPING_INFO_LOG, {}, { key })
}
