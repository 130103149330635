import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    categoryId: {
      title: 'Category',
      inputType: 'Select',
      options: {},
      value: '',
      error: '',
      required: true,
      hasDefaultOption: true,
    },
    title: {
      inputType: 'text',
      title: 'Title',
      value: '',
      error: '',
      required: true,
    },
    description: {
      inputType: 'textarea',
      title: 'Description',
      value: '',
      error: '',
      required: true,
    },
  }
}
