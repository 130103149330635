import { useEffect, useState } from 'react'
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm'

const SkeletonDialog = ({ show, proceed, confirmation: Modal, options }: ReactConfirmProps | any) => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  useEffect(() => {
    setLastUpdatedAt(Date.now())
  }, [show])

  return (
    <Modal
      isOpen={show}
      onOk={() => proceed(true as any)}
      onClose={() => proceed(false as any)}
      lastUpdatedAt={lastUpdatedAt}
      {...options}
    />
  )
}

const _skeletonDlg = confirmable(SkeletonDialog)

const createConfirm = createConfirmation(_skeletonDlg)

export const skeletonDlg = (content: any, options: Record<string, any> = {}) =>
  createConfirm({ confirmation: content, options })
