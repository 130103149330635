import { API_BASE_VALIDATE_ADDRESS, API_HOME_CREDIT_BOX, API_HOME_NEWSLETTER, API_HOME_SUBMIT_CONTACTUS } from 'config'
import Api from 'services/api'

export const requestNewsletter = (email: string) => {
  return Api.post(API_HOME_NEWSLETTER, { email })
}

export const submitContactUs = (data: Record<string, string>) => {
  return Api.post(API_HOME_SUBMIT_CONTACTUS, data)
}

export const getHomeCreditBox = () => {
  return Api.get(API_HOME_CREDIT_BOX)
}

export const validateAddress = (value: string) => {
  return Api.post(API_BASE_VALIDATE_ADDRESS, { value })
}
