import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useMemo } from 'react'
import { getPrice3decimal } from 'utils'

import type { IExperienceLtvMaxLimits } from '../../interface'

export const ExperienceLeverageLimit = ({ data }: { data: IExperienceLtvMaxLimits[] }) => {
  const renderLtvMaxLimits = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="w-full text-center text-sm">
        <thead className="font-bold bg-gray-100">
          <tr>
            <th colSpan={2} className="border p-1">
              Experience
            </th>
            <th rowSpan={2} className="border p-1">
              FICO
            </th>
            <th rowSpan={2} className="border p-1">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min Months Reserve
            </th>
            <th colSpan={4} className="border p-1">
              Max LTVs
            </th>
          </tr>
          <tr>
            <th className="border p-1 w-[80px]">From</th>
            <th className="border p-1 w-[80px]">To</th>
            <th className="border p-1 w-[120px]">From</th>
            <th className="border p-1 w-[120px]">To</th>
            <th className="border p-1 w-[90px]">AIV-LTV</th>
            <th className="border p-1 w-[90px]">ARV-LTV</th>
            <th className="border p-1 w-[90px]">LTC</th>
            <th className="border p-1 w-[90px]">LTP</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3 * item.limits.length + 1} className="border px-3 py-1">
                    {item.from}
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className="border px-3 py-1">
                    {item.to}
                  </td>
                </tr>

                {item.limits.map((limit, idx) => (
                  <Fragment key={`${index}-${idx}`}>
                    <tr>
                      <td rowSpan={3} className="border px-3 py-1">
                        {limit.FICO.from} - {limit.FICO.to}
                      </td>

                      <td className="border px-3 py-1">Purchase</td>

                      {['min', 'max'].map((key, idx) => (
                        <td key={`${index}-${idx}`} className="border px-3 py-1">
                          {getPrice3decimal((limit.loanAmount.purchase as any)[key])}
                        </td>
                      ))}

                      <td className="border px-3 py-1">{limit.minMonthsReserve.purchase}</td>
                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                        <td key={`${index}-${idx}`} className="border px-3 py-1">
                          {(limit.LTV.purchase as any)[key]}
                        </td>
                      ))}
                    </tr>

                    <tr>
                      <td className="border px-3 py-1">No-Cashout</td>

                      {['min', 'max'].map((key, idx) => (
                        <td key={`${index}-${idx}`} className="border px-3 py-1">
                          {getPrice3decimal((limit.loanAmount.nocashout as any)[key])}
                        </td>
                      ))}

                      <td className="border px-3 py-1">{limit.minMonthsReserve.nocashout}</td>
                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                        <td key={`${index}-${idx}`} className="border px-3 py-1">
                          {(limit.LTV.nocashout as any)[key]}
                        </td>
                      ))}
                    </tr>

                    <tr>
                      <td className="border px-3 py-1">Cashout</td>

                      {['min', 'max'].map((key, idx) => (
                        <td key={`${index}-${idx}`} className="border px-3 py-1">
                          {getPrice3decimal((limit.loanAmount.cashout as any)[key])}
                        </td>
                      ))}

                      <td className="border px-3 py-1">{limit.minMonthsReserve.cashout}</td>
                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                        <td key={`${index}-${idx}`} className="border px-3 py-1">
                          {(limit.LTV.cashout as any)[key]}
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return <div className="h-full">{renderLtvMaxLimits}</div>
}
