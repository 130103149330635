import {
  API_LOAN_BYTEPRO_LOG_DETAIL,
  API_LOAN_BYTEPRO_LOGS,
  API_LOAN_CONNECT_BYTEPRO,
  API_LOAN_GET_BYTEPRO_FILENAME,
} from 'config'
import Api from 'services/api'

export const getByteproLogs = () => {
  return Api.get(API_LOAN_BYTEPRO_LOGS)
}

export const getByteproLogDetail = (id: number) => {
  return Api.get(API_LOAN_BYTEPRO_LOG_DETAIL, {}, { id })
}

export const getByteproFileName = (loanNumber: number | null) => {
  return Api.get(API_LOAN_GET_BYTEPRO_FILENAME, {}, { loanNumber })
}
export const makeConnectionToBytepro = () => {
  return Api.get(API_LOAN_CONNECT_BYTEPRO)
}
