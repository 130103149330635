import cloneDeep from 'clone-deep'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup, Modal } from 'stories/components'
import { isHTML } from 'utils'

import type { IInElibileProduct, IPriceLimit, IProgram } from '../interfaces'
import { getMaximumTotalLoanAmountTip, showLimitSection } from '../Logic'
import { MaxLoanAmount } from './MaxLoanAmount'

interface Prop {
  isOpen: boolean
  inEligibleProducts: IInElibileProduct
  programs: Array<IProgram>
  limit: IPriceLimit
  onClose: () => void
}

export function InEligibleDialog(props: Prop) {
  const { isOpen, onClose, inEligibleProducts, programs, limit } = props

  const [inEligibleProductKey, setInEligibleProductKey] = useState<string>('')

  const maximumTotalLoanAmountTip = useMemo(() => {
    let _limit = cloneDeep(limit)
    const program = programs.find((item) => item.Name === inEligibleProductKey)
    if (program?.LtvLimit) {
      _limit.max_aiv_ltv = program.LtvLimit.max_aiv_ltv
      _limit.max_arv_ltv = program.LtvLimit.max_arv_ltv
      _limit.max_ltc = program.LtvLimit.max_ltc
      _limit.max_ltp = program.LtvLimit.max_ltp
    }
    return {
      tip: getMaximumTotalLoanAmountTip(null, _limit),
      limit: _limit,
    }
  }, [inEligibleProductKey])

  useEffect(() => {
    const key = Object.keys(inEligibleProducts)[0]
    setInEligibleProductKey(key)
  }, [inEligibleProducts])

  const renderLimits = () => {
    if (showLimitSection()) {
      return (
        <div className="my-4 w-fit">
          <MaxLoanAmount
            maximumTotalLoanAmountTip={maximumTotalLoanAmountTip.tip}
            limit={maximumTotalLoanAmountTip.limit}
            pricingEngineIsOff={false}
          />
        </div>
      )
    }
  }

  return (
    <>
      <Modal
        button={<span></span>}
        title={'InEligible Products'}
        titleOkay=""
        titleCancel="Close"
        init={false}
        isOpen={isOpen}
        lastUpdatedAt={Date.now()}
        onClose={onClose}
        childLevel={1}
      >
        {isOpen ? (
          <div className="text-gray-900">
            <div className="">
              <ButtonGroup
                title={[...Object.keys(inEligibleProducts)]}
                onChange={(e: any) => setInEligibleProductKey(e)}
                value={inEligibleProductKey}
              />
            </div>

            {renderLimits()}

            <table className="w-full table-auto mt-4 text-center text-[14px]">
              <thead className="text-md text-gray-700 bg-gray-50">
                <tr className="border">
                  <th className="border p-1">Field</th>
                  <th className="border">Value</th>
                  <th className="border">Description</th>
                </tr>
              </thead>
              <tbody>
                {inEligibleProducts[inEligibleProductKey] &&
                  inEligibleProducts[inEligibleProductKey].map(
                    (
                      item: {
                        name: string
                        value: string
                        description: string
                        ltvMaxCalcHistory?: string[]
                      },
                      index: number,
                    ) => {
                      return (
                        <tr key={index} className="border">
                          <td className="border p-2">{item.name}</td>
                          <td className="border p-2">
                            {isHTML(item.value) ? (
                              <span className="dangerouslySetInnerHTML">
                                <div dangerouslySetInnerHTML={{ __html: item.value }}></div>
                              </span>
                            ) : (
                              item.value
                            )}
                          </td>
                          <td className="border p-2">
                            {item.description}
                            {item.ltvMaxCalcHistory && (
                              <ul className="text-left list-disc pl-4 font-weight-400">
                                {item.ltvMaxCalcHistory.map((item, index1) => {
                                  return <li key={index1}>{item}</li>
                                })}
                              </ul>
                            )}
                          </td>
                        </tr>
                      )
                    },
                  )}
              </tbody>
            </table>
          </div>
        ) : (
          <span></span>
        )}
      </Modal>
    </>
  )
}
