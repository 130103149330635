import { loanOptionsConstants } from 'config'
import { PlainTable } from 'stories/components/PlainTable'

import type { IPrepaymentPenalty, IPrepaymentPenaltyOverlay } from '../interface'

interface IProps {
  data: IPrepaymentPenalty
  pppOverlay: IPrepaymentPenaltyOverlay[] | undefined
}

export const PrepaymentPenalty = ({ data, pppOverlay }: IProps) => {
  const { limits } = data
  const tableData = Object.keys(limits).map((key) => [
    (loanOptionsConstants.prepaymentLong as any)[key],
    limits[Number(key)].min,
    limits[Number(key)].max,
  ])

  return (
    <div className="max-w-[540px]">
      <PlainTable header={['Type', 'Min Price', 'Max Price']} data={tableData} />

      <p className="text-lg font-variation-settings-600 mt-4 mb-2">Prepayment Penalty Overlay</p>

      {pppOverlay && (
        <table className="table text-center">
          <thead className="bg-gray-100">
            <tr>
              <th className="border py-2 px-6 w-fit whitespace-nowrap">
                Has property been listed in the last 6 months?
              </th>
              <th className="border py-2 px-6 w-fit whitespace-nowrap">Min Prepayment Penalty</th>
            </tr>
          </thead>

          <tbody>
            {pppOverlay.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="border p-1">{item.label}</td>
                  <td className="border p-1">{(loanOptionsConstants.prepaymentLong as any)[item.minPPP]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}
