import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputSelect, InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitReviewFormTemplate } from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import type { ReviewFormCategory } from './ReviewFormItems'
import type { ReviewFormTemplate } from './ReviewFormTemplates'

export const ReviewFormDetails = (props: {
  categories: ReviewFormCategory[]
  defaults: ReviewFormTemplate | null
  onBack: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const { defaults = null } = props

  useEffect(() => {
    const newInputs = defaultInputs()
    const categoryMap: Record<string, string> = {}
    props.categories.forEach((category) => {
      if (!category.value) return
      categoryMap[category.id] = category.value
    })
    ;(newInputs.categoryId as InputSelect).options = categoryMap
    if (!defaults) {
      setInputs(newInputs)
      return
    }
    for (const key in defaults) {
      if (newInputs[key]) newInputs[key].value = (defaults as any)[key]
    }
    setInputs(newInputs)
  }, [defaults])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (!disabled && value !== undefined) data[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }
    setLoading(true)
    const id = defaults ? defaults.id : 0
    submitReviewFormTemplate(id, data)
      .then(() => {
        if (id == 0) toast('New Appraisal Review template is added.', { type: 'success' })
        else toast(`Appraisal Review template ${defaults!.no} is updated.`, { type: 'success' })
        onBack()
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="review-form-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Appraisal Review - {!defaults ? 'Add' : `Update No ${defaults.no}`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-2" /> <p>Return to Appraisal Review</p>
        </div>
      </Button>

      <div className="grid gap-4 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div className={`input`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'}</>
        </Button>
      </div>
    </div>
  )
}
