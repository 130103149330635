import { toast } from 'react-toastify'

export const handleFailedDocsNotification = (data: any) => {
  if (data?.failedDocs?.length > 0) {
    let messages = [<div key={'docs'}>Following documents uploads are failed!</div>]
    data.failedDocs.map((item: any, index: number) => {
      messages.push(
        <div key={index} className="text-[12.5px]">
          {index + 1}. {item.name}
        </div>,
      )
      messages.push(
        <div key={`error-${index}`} className="text-[12px] ml-3">
          {item.code}
        </div>,
      )
    })
    toast(<div>{messages}</div>, { type: 'error' })
  }
}
