import { ArrowPathIcon, ClockIcon, Square2StackIcon } from '@heroicons/react/24/outline'
import { setBorrowerGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import { usePermissions } from 'hooks/usePermissions'
import type { ILoanDetail } from 'pages/LoanStructure/interfaces'
import { estimatedClosingAmountBorrower } from 'pages/LoanStructure/Logic'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  copyAssetVerificationUrl,
  createLoanAssetInformation,
  createLoanLiabilityInformation,
  deleteLoanAssetInformation,
  deleteLoanLiabilityInformation,
  reloadLoanAssetInformation,
  sendLoanAssetInformationVerificationEmail,
  updateEarnestMoney,
  updateLoanAssetInformation,
  updateLoanLiabilityInformation,
} from 'services'
import { Button, FormTable, FormTableHeader } from 'stories/components'
import {
  canEditLoanApplication,
  confirm,
  getPrice3decimal,
  openAuditLog,
  removeComma,
  renderTableHistoryValue,
} from 'utils'
import { copyClipboard } from 'utils/copyClipboard'
import { RenderInput } from 'utils/RenderInput'

import { AssetInformationModal } from './AssetInformationModal'
import { AssetInformationTable } from './AssetInformationTable'
import {
  AssetAccountTypeForPlaid,
  AssetAccountTypeOptions,
  defaultInputs2,
  header,
  header2,
  IAssetData,
} from './constants'
import { assetInfoValidate } from './logics'
import { RefreshBalanceResultModal } from './RefreshBalanceResultModal'
import { SendVerificationEmailModal } from './SendVerificationEmailModal'

export function AssetInformation({ setLoading }: any) {
  const [trackData, setTrackData] = useState<IAssetData[]>([])
  const [liabilityData, setLiabilityData] = useState<Array<Record<string, any>>>([])
  const [data2, setData2] = useState<Array<Record<string, any>>>([])
  const [earnestMoney, setEarnestMoney] = useState<string>()

  const [selectedAsset, setSelectedAsset] = useState<IAssetData | null>()
  const [isLoaded, setLoaded] = useState(false)
  const [refreshBalanceResults, setRefreshBalanceResults] = useState<Record<string, any>>()

  const [showSendVerificationEmail, setShowSendVerificationEmail] = useState(false)

  const dispatch = useDispatch()
  const { hasPermission } = usePermissions()

  const { transactionType, borrower, loan, loanDetail } = useSelector((state: any) => {
    return {
      transactionType: state.loan.transactionType,
      borrower: state.borrower.borrower,
      loan: state.loan,
      loanDetail: state.loanDetail as ILoanDetail,
    }
  })
  const isTemparary = useSelector((state: any) => state.auth.profile.temparary)
  const canEdit = canEditLoanApplication()

  useEffect(() => {
    setTrackData(borrower.assets)

    setData2(borrower.liabilities)
    setLiabilityData(borrower.liabilities)
    setEarnestMoney(borrower.earnestMoney)
    setLoaded(true)
  }, [])

  useEffect(() => {
    assetInfoValidate(true)
  }, [trackData])

  const ableToSendEmail = useMemo(() => {
    const hasUnverifiedItem = !!trackData.filter(
      (item) => AssetAccountTypeForPlaid.includes(item.accountType || 0) && !(item.verified || item.override),
    ).length
    return !isTemparary && hasUnverifiedItem
  }, [isTemparary, trackData])

  const showReload = useMemo(() => {
    if (!hasPermission('PLAID_ASSETS_FULL_PERMISSION')) return false
    return !!trackData.filter(
      (item) => AssetAccountTypeForPlaid.includes(item.accountType || 0) && (item.verified || item.override),
    ).length
  }, [trackData])

  const showRequiredError = useMemo(() => {
    const { value } = estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings)
    return (
      isLoaded &&
      trackData.length == 0 &&
      (transactionType == 'Purchase' || (transactionType == 'Refinance' && value < 0))
    )
  }, [transactionType, trackData, isLoaded])

  const updateStore = (key: string, data: Array<Record<string, any>> | any) => {
    let temp = cloneDeep(borrower)
    temp[key] = data
    dispatch(setBorrowerGroupData('borrower', temp))
  }

  const onSubmit2 = (currentId: any, values: Record<string, any>) => {
    return new Promise((resolve) => {
      setLoading(true)
      if (!currentId) {
        values = {
          id: Date.now(),
          ...values,
        }
        createLoanLiabilityInformation(values)
          .then(() => {
            resolve(values)
            let temp = cloneDeep(liabilityData)
            temp.push(values)
            setLiabilityData(temp)
            updateStore('liabilities', temp)
          })
          .finally(() => setLoading(false))
      } else {
        updateLoanLiabilityInformation(currentId, values)
          .then(() => resolve(values))
          .finally(() => {
            let temp = cloneDeep(liabilityData)
            temp.map((item, index) => {
              if (item.id === currentId) temp[index] = values
            })
            setLiabilityData(temp)
            updateStore('liabilities', temp)
            setLoading(false)
          })
      }
    })
  }

  const onAddAsset = () => {
    setSelectedAsset(null)
  }

  const onSendAssetVerificationEmail = async () => {
    setShowSendVerificationEmail(true)
  }

  const sendVerificationEmail = async () => {
    await sendLoanAssetInformationVerificationEmail()
    toast('Email is sent to borrower successfully.', { type: 'success' })
    setShowSendVerificationEmail(false)
  }

  const onCopyVerificationURL = async () => {
    setLoading(true)

    copyAssetVerificationUrl()
      .then(({ link }) => {
        copyClipboard(link)
      })
      .finally(() => setLoading(false))
  }

  const onSubmit = (currentId: any, values: IAssetData[]) => {
    return new Promise((resolve) => {
      if (!currentId) {
        const now = Date.now()
        values = values.map((item, index) => ({
          ...item,
          id: item.id || `${now}-${index}`,
        }))
        createLoanAssetInformation(values).then(() => {
          resolve(values)
          let temp = cloneDeep(trackData)
          temp.push(...values)
          setTrackData(temp)
          updateStore('assets', temp)
        })
      } else {
        updateLoanAssetInformation(currentId, values[0])
          .then(() => resolve(values))
          .finally(() => {
            let temp = cloneDeep(trackData)
            temp.map((item, index) => {
              if (item.id === currentId) temp[index] = values[0]
            })
            setTrackData(temp)
            updateStore('assets', temp)
          })
      }
    })
  }

  const onRemove2 = async (id: any) => {
    return new Promise((resolve) => {
      setLoading(true)
      deleteLoanLiabilityInformation(id)
        .then(() => {
          resolve(true)
        })
        .finally(() => {
          let temp: any = []
          liabilityData.map((item) => {
            if (item.id !== id) temp.push(item)
          })
          setLiabilityData(temp)
          updateStore('liabilities', temp)
          setLoading(false)
        })
    })
  }

  const onRemove = async (curItem: IAssetData) => {
    const index = trackData.findIndex((item) => item.id == curItem.id)

    const content = (
      <div className="mb-4 text-[18px]">
        Do you want to remove <span className="font-bold">No.{index + 1}</span> item?
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    return new Promise((resolve) => {
      setLoading(true)
      deleteLoanAssetInformation(curItem.id)
        .then(() => resolve(true))
        .finally(() => {
          const temp: IAssetData[] = []
          trackData.map((item) => {
            if (item.id !== curItem.id) temp.push(item)
          })
          setTrackData(temp)
          updateStore('assets', temp)
          setLoading(false)
        })
    })
  }

  const onReloadAssets = () => {
    setLoading(true)
    reloadLoanAssetInformation()
      .then(({ assets, institutions, updatedStatus }) => {
        const isUpdated = !!Object.keys(updatedStatus).filter((id) => updatedStatus[id] != 0).length
        if (!isUpdated) {
          toast('No Asset Information has been updated.', { type: 'info' })
        } else
          setRefreshBalanceResults({
            old: trackData,
            new: assets,
            institutions,
            updatedStatus,
          })
        setTrackData(assets)
      })
      .finally(() => setLoading(false))
  }

  const convertData = (data: any) => {
    let rlt = cloneDeep(data)
    rlt.map((item: any, index: number) => {
      rlt[index].accountType = AssetAccountTypeOptions[item.accountType]
      rlt[index].verified =
        item.override || item.verified ? (
          <div className="text-green-800 bg-green-100 rounded py-1 px-2 w-fit">
            Verified
            {item.override && <div className="border-t border-green-300 text-red-900">Overridden</div>}
          </div>
        ) : null
    })
    return rlt
  }

  const showHistory = async (key: string, headers: Array<FormTableHeader> = []) => {
    const options = {
      table: 'AssetTrack',
      field: `${key}`,
      keys: {
        field: key,
      },
      renderValue: headers.length ? (data: any) => renderTableHistoryValue(convertData(data), headers) : null,
    }
    openAuditLog(options)
  }

  const renderAssetTotalFragment = useMemo(() => {
    let total = 0
    trackData.map((item: any) => {
      total += removeComma(item.balance)
    })
    if (total > 0) {
      return (
        <div className="mb-3">
          <div className="flex gap-2">
            Asset Total: <span className="font-variation-settings-600">${getPrice3decimal(total)}</span>
          </div>
        </div>
      )
    }
  }, [trackData])

  const renderLiabilityTotalFragment = useMemo(() => {
    let total = 0
    liabilityData.map((item: any) => {
      total += removeComma(item.balance)
    })
    if (total > 0) {
      return (
        <div className="mb-3">
          <div className="flex gap-2">
            Liability Total: <span className="font-variation-settings-600">${getPrice3decimal(total)}</span>
          </div>
        </div>
      )
    }
  }, [liabilityData])

  const onChangeEarnestMoney = (key: string, value: any) => {
    setEarnestMoney(value)
  }

  const onSubmitEarnestMoney = async () => {
    if (earnestMoney == undefined) return
    setLoading(true)
    await updateEarnestMoney(removeComma(earnestMoney))
    updateStore('earnestMoney', earnestMoney)
    setEarnestMoney(removeComma(earnestMoney).toString())
    setLoading(false)
  }

  return (
    <div className="">
      <div className="">
        <div className="border-b-[2px] text-[18px] font-variation-settings-[600] mb-3 group flex items-center gap-2">
          <p>1. Asset Information</p>
          {hasPermission('CAN_VIEW_AUDIT_LOG') && (
            <span
              className="hidden group-hover:inline text-shade-blue cursor-pointer hover:underline text-[13px]"
              onClick={() => showHistory('assets', header)}
            >
              <ClockIcon className="w-4 h-4" aria-hidden="true" />
            </span>
          )}
          <div className="flex-1" />
          {showReload && (
            <p
              className="flex text-shade-blue cursor-pointer hover:underline items-center text-sm gap-1"
              onClick={() => onReloadAssets()}
            >
              Refresh Balance
              <span className="inline text-[13px]">
                <ArrowPathIcon className="w-4 h-4" aria-hidden="true" />
              </span>
            </p>
          )}
        </div>
        <div className="pl-2">
          {renderAssetTotalFragment}

          {showRequiredError && (
            <div className="text-left bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]">
              Information of at least 1 asset is required.
            </div>
          )}
          <AssetInformationTable
            trackData={trackData}
            setTrackData={setTrackData}
            setLoading={setLoading}
            updateStore={updateStore}
            onEdit={(item: IAssetData) => setSelectedAsset(item)}
            onRemove={(item: IAssetData) => onRemove(item)}
          />
          <div className="flex justify-center">
            {canEdit && (
              <Button onClick={onAddAsset} className="px-10">
                Add Asset
              </Button>
            )}
            {ableToSendEmail && (
              <Button onClick={onSendAssetVerificationEmail} className="px-10" color="gray">
                Send Verification Email
              </Button>
            )}
            {ableToSendEmail && (
              <Button link className="" color="gray" onClick={onCopyVerificationURL}>
                <div className="flex gap-1 items-center px-3">
                  Copy Verification Link
                  <Square2StackIcon className="w-4 h-4"></Square2StackIcon>
                </div>
              </Button>
            )}
          </div>

          {selectedAsset !== undefined && (
            <AssetInformationModal
              asset={selectedAsset}
              onSubmit={onSubmit}
              onClose={() => setSelectedAsset(undefined)}
            />
          )}

          {refreshBalanceResults && (
            <RefreshBalanceResultModal
              data={refreshBalanceResults as any}
              onClose={() => setRefreshBalanceResults(undefined)}
            />
          )}

          {showSendVerificationEmail && (
            <SendVerificationEmailModal
              onSubmit={() => sendVerificationEmail()}
              onClose={() => setShowSendVerificationEmail(false)}
            />
          )}

          {/* {trackData.length === 0 && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[14px]"
              role="alert"
            >
              At least 1 asset information is required!
            </div>
          )} */}
        </div>
      </div>
      <div className="mt-4">
        <div className="border-b-[2px] text-[18px] font-variation-settings-[600] mb-3">2. Earnest Money</div>
        <div className="max-w-xs">
          <RenderInput
            Key="earnestMoney"
            input={{
              title: 'Earnest Money',
              inputType: 'text',
              type: 'thousandSep',
              prefix: '$',
              value: earnestMoney,
            }}
            onChange={onChangeEarnestMoney}
            onBlur={onSubmitEarnestMoney}
          />
        </div>
      </div>
      <div className="mt-8">
        <div className="border-b-[2px] text-[18px] font-variation-settings-[600] mb-3">3. Liability Information</div>
        <div className="pl-2">
          {renderLiabilityTotalFragment}
          <FormTable
            header={header2}
            permission={canEdit ? 1 : 3}
            inputs={defaultInputs2}
            defaultData={data2}
            onSubmit={onSubmit2}
            onRemove={onRemove2}
            showHistory={(headers) => showHistory('liabilities', headers)}
          />
        </div>
      </div>
    </div>
  )
}
