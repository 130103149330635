import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { downloadS3Documents, getAllDataverifySSA } from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { Input2, Pagination } from 'stories/components'
import { formatTime } from 'utils'

import type { IDataVerifySSA } from './types'

export function DataVerifySSA({ type }: { type: 'ssa' | 'tax' }) {
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    pageNum: 0,
  })

  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const [data, setData] = useState<IDataVerifySSA[]>([])
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)

  useEffect(() => {
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = async (filters: Record<string, any>) => {
    setLoading(true)
    getAllDataverifySSA(type, {
      ...filters,
      skip: filters.pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setData(data)
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  const onChangeFilter = (key: 'query' | 'pageNum', value: any) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterData(newFilters)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const viewS3 = (key: string) => {
    setLoading(true)
    downloadS3Documents(key).then((res) => {
      var windowReference: any = window.open()
      windowReference.location = res.url
      setLoading(false)
    })
  }

  const title = useMemo(() => {
    if (type == 'ssa') return 'SSA'
    else return '4506'
  }, [type])

  return (
    <div className="">
      <h2 className="text-xl font-bold mb-2 flex items-center gap-2">
        <span>Dataverify {title}</span>
        {isLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin ml-2" />}
      </h2>
      <div className="flex flex-wrap gap-2 items-center">
        <div className="w-64">
          <Input2
            type="search"
            title="Search Loan Number"
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>
        <p className="font-variation-settings-600"> {total} items</p>
      </div>
      <div className="my-4">
        <div className="relative overflow-auto shadow-md sm:rounded-lg">
          <LayoutLoading show={isLoading} />
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3">
                  Loan Number
                </th>
                <th scope="col" className="px-3 py-3">
                  Borrower
                </th>
                <th scope="col" className="px-3 py-3">
                  Uploaded PDF
                </th>
                <th scope="col" className="px-3 py-3">
                  Order No
                </th>
                {type == 'tax' && (
                  <th scope="col" className="px-3 py-3">
                    Form Type
                  </th>
                )}
                <th scope="col" className="px-3 py-3">
                  Status
                </th>
                <th scope="col" className="px-3 py-3">
                  Requested by
                </th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {data.map((item, index) => {
                return item[type].map((ssa, orderIndex) => (
                  <tr key={orderIndex} className={`border ${index % 2 ? 'bg-gray-50' : ''}`}>
                    {orderIndex == 0 && (
                      <td
                        className="px-3 py-3 text-center text-shade-blue font-variation-settings-600 hover:underline cursor-pointer border"
                        rowSpan={item[type].length}
                      >
                        <Link to={`/dataverify/${item.loanNumber}`} target="_blank" rel="noopener noreferrer">
                          {item.loanNumber}
                        </Link>
                      </td>
                    )}
                    <td className="px-3 py-3 capitalize">{ssa.data.type}</td>
                    <td className="px-3 py-3">
                      <span
                        className="flex gap-1 items-center text-[13px] text-shade-blue cursor-pointer hover:underline font-bold italic"
                        onClick={() => viewS3(ssa.data.fileKey)}
                      >
                        <span>View PDF</span>
                        <span>
                          <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
                        </span>
                      </span>
                      {ssa.data.signFileKey && (
                        <span
                          className="flex gap-1 items-center text-[13px] text-shade-blue cursor-pointer hover:underline font-bold italic"
                          onClick={() => viewS3(ssa.data.signFileKey)}
                        >
                          <span>View eSign PDF</span>
                          <span>
                            <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
                          </span>
                        </span>
                      )}
                    </td>
                    <td className="px-3 py-3">{ssa.orderID}</td>
                    {type == 'tax' && <td className="px-3 py-3">{ssa.data.formType}</td>}
                    <td className="px-3 py-3">
                      <span className="statusColor flex items-center gap-1 flex-wrap">
                        <span className={`p-2 rounded ${ssa.status?.split('/')[0]}`}>{ssa.status}</span>
                        {ssa.status === 'Completed/Success' && (
                          <span
                            className="p-1 h-fit rounded hover-shadow1 flex gap-1 items-center text-[13px] text-shade-blue cursor-pointer hover:underline font-bold italic"
                            onClick={() => viewS3(ssa.successFileKey)}
                          >
                            <span>
                              <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
                            </span>
                          </span>
                        )}
                      </span>
                    </td>
                    <td className="px-3 py-3">
                      {ssa.by}
                      <br />
                      {formatTime(ssa.time)}
                    </td>
                  </tr>
                ))
              })}
            </tbody>
          </table>

          <div className="flex justify-end items-center mt-3 mb-3">
            <Pagination
              totalCount={total}
              itemCountPerPage={itemCountPerPage}
              onNavigate={onPageNavigate}
              pageNum={filters.pageNum}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
