import cloneDeep from 'clone-deep'
import { fullStates } from 'config/states.constants'
import { usePermissions } from 'hooks/usePermissions'
import { RolodexDetails } from 'pages/Admin'
import { useEffect, useMemo, useState } from 'react'
import { getAllRolodex } from 'services'
import svgSearch from 'stories/assets/search.svg'
import { Input2, Modal, Select2 } from 'stories/components'
import { renderHeader } from 'utils'

export const RolodexModal = (props: any) => {
  const [loading, setLoading] = useState(false)

  const [categories, setCategories] = useState<Record<number, string>>({})
  const [data, setData] = useState<Record<string, any>[]>([])
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    category: '',
    orderBy: 'No',
    orderDir: 1,
  })
  const [selectedRow, setSelectedRow] = useState<number | null>(null)
  const [editRow, setEditRow] = useState<number | null>(null)

  const { hasPermission } = usePermissions()
  const hasAction = hasPermission('MANAGE_CONDITIONS_TEMPLATES')

  useEffect(() => {
    setLoading(true)
    getAllRolodex().then(({ data, categories }) => {
      setLoading(false)
      setCategories(categories)
      setData(data)
    })
  }, [])

  const filteredData = useMemo(() => {
    if (!data) return []
    const { orderBy, orderDir } = filters
    return data
      .filter((item) => !Number(filters.category) || item.CategoryID == filters.category)
      .filter(
        (item) =>
          !filters.query ||
          [
            item.Company,
            item.FirstName,
            item.LastName,
            item.LicenseNo,
            item.Street,
            item.City,
            (fullStates as any)[item.State],
          ]
            .join(' ')
            .toLowerCase()
            .includes(filters.query?.toLowerCase()),
      )
      .sort((a: any, b: any) => {
        if (a[orderBy] > b[orderBy]) return orderDir
        else if (a[orderBy] == b[orderBy]) return 0
        return -orderDir
      })
  }, [data, filters])

  useEffect(() => {
    Object.keys(categories).map((id: string) => {
      if (categories[Number(id)] === props.type) onChangeFilter('category', id)
    })
  }, [props.type, categories])

  const onChangeFilter = (key: 'query' | 'category' | 'orderBy' | 'orderDir', value: string) => {
    if (loading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    setEditRow(null)
    setSelectedRow(null)
  }

  const onSort = (key: string, dir: number) => {
    if (loading) return
    const newFilters = Object.assign({}, filters)
    newFilters.orderBy = key
    newFilters.orderDir = dir
    setFilters(newFilters)
  }

  const onClose = () => {
    props.onClose(null)
  }

  const onConfirm = () => {
    if (selectedRow === null) props.onClose(null)
    else props.onClose(filteredData[selectedRow])
  }

  const onDoubleClick = (index: number) => {
    props.onClose(filteredData[index])
  }

  const headers = [
    { title: 'No', key: 'no' },
    { title: 'Category', key: 'CategoryID' },
    { title: 'Company', key: 'Company' },
    { title: 'First Name', key: 'FirstName' },
    { title: 'Last Name', key: 'LastName' },
    { title: 'Street', key: 'Street' },
    { title: 'City', key: 'City' },
    { title: 'State', key: 'State' },
  ]

  const onCompleteEditData = (editData: any) => {
    let temp = cloneDeep(data)
    if (editRow !== null) {
      temp.map((item, index) => {
        if (item.id === filteredData[editRow].id) temp[index] = editData
      })
    }
    setData(temp)
    setEditRow(null)
  }

  return (
    <Modal
      title={
        <span>
          Select <b className="text-shade-blue">{props.type}</b> from Rolodex...
        </span>
      }
      titleOkay="Confirm"
      loading={loading}
      onClose={onClose}
      onOk={onConfirm}
      disabled={selectedRow === null}
      isOpen
      childLevel={2}
    >
      <div className="text-gray-700 w-[calc(70vw)] text-left">
        <div className="flex flex-wrap gap-2 items-center justify-between">
          <div className="h-fit mb-3 flex-auto">
            <div className="w-96">
              <Select2
                id="category"
                title="Category"
                hasDefaultOption
                defaultOptionText="All"
                options={categories}
                value={filters.category}
                onChange={(value) => onChangeFilter('category', value)}
                sort={true}
              />
            </div>
          </div>
          <div className="h-fit mb-3">
            <div className="w-96">
              <Input2
                type="search"
                title="Search"
                hasIcon
                icon={svgSearch}
                value={filters.query}
                onChange={(value) => onChangeFilter('query', value)}
              />
            </div>
          </div>
        </div>
        <div className="parties-container overflow-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {headers.map(({ title, key }, index) =>
                  renderHeader({
                    title,
                    sortable: true,
                    key,
                    sortOrder: filters.orderBy == key ? parseInt(filters.orderDir) : 0,
                    index,
                    onSort,
                    border: true,
                  }),
                )}
                {hasAction && (
                  <th scope="col" className="py-3 px-2 border">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {filteredData.map((item, index) => {
                let background = index % 2 && 'bg-slate-50'
                if (index === selectedRow) background = 'bg-slate-500 text-white'
                return [
                  <tr
                    className={`border-b dark:bg-gray-800 dark:border-gray-700 ${background} cursor-pointer`}
                    key={item.no}
                    onClick={() => setSelectedRow(index)}
                    onDoubleClick={() => onDoubleClick(index)}
                  >
                    <td className="px-2 py-2 border" rowSpan={editRow === index ? 2 : 1}>
                      {index + 1}
                    </td>

                    <td className="px-2 border">{categories[item.CategoryID]}</td>
                    <td className="px-2 border">{item.Company}</td>
                    <td className="px-2 border">{item.FirstName}</td>
                    <td className="px-2 border">{item.LastName}</td>
                    <td className="px-2 border">{item.Street}</td>
                    <td className="px-2 border">{item.City}</td>
                    <td className="px-2 border">{(fullStates as any)[item.State]}</td>
                    {hasAction && (
                      <td className="px-2 border text-center">
                        <span className="hover:underline text-[13.5px]" onClick={() => setEditRow(index)}>
                          Edit
                        </span>
                      </td>
                    )}
                  </tr>,
                  editRow === index && (
                    <tr key={`${item.no}-edit`}>
                      <td className="px-3 border" colSpan={8}>
                        <RolodexDetails
                          defaults={item}
                          categories={categories}
                          onBack={null}
                          onComplete={(editData: any) => onCompleteEditData(editData)}
                          onCancel={() => setEditRow(null)}
                        />
                      </td>
                    </tr>
                  ),
                ]
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
