import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { deleteVendorOptions, enableVendorOptions, getVendorOptions, updateVendorOptions } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, ButtonGroup } from 'stories/components'
import { confirm, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import { IVendorData } from './types'

export const CredentialConfig = ({ title, type }: { title: string; type: string }) => {
  const [isLoading, setIsLoading] = useState<string>('')
  const [selectedMenu, setSelectedMenu] = useState<string>('')
  const [inputOptions, setInputOptions] = useState<Record<string, InputType>>({})
  const [inputs, setInputs] = useState<Record<string, InputType>>({})

  const [enabled, setEnabled] = useState(false)
  const [values, setValues] = useState<IVendorData>({})

  useEffect(() => {
    setIsLoading('loading')
    getVendorOptions(type)
      .then(
        ({ enabled, value, options }: { enabled: boolean; value: IVendorData; options: Record<string, InputType> }) => {
          setEnabled(enabled)
          setInputOptions(options)
          setValues(value)
          setSelectedMenu(Object.keys(value)[0])
        },
      )
      .finally(() => setIsLoading(''))
  }, [])

  useEffect(() => {
    const newInputs: Record<string, InputType> = cloneDeep({
      title: defaultInputs.title,
      ...inputOptions,
    })

    if (!selectedMenu) return
    const selectedValue = values[selectedMenu]
    Object.keys(selectedValue).forEach((v) => {
      if (newInputs[v]) newInputs[v].value = selectedValue[v]
    })
    setInputs(newInputs)
  }, [inputOptions, values, selectedMenu])

  const menus = useMemo(() => {
    const menus: Record<string, string> = {}
    Object.keys(values).forEach((key) => {
      const item = values[key]
      menus[key] = `${item.title}${item.isActive ? ' (Active)' : ''}`
    })
    return menus
  }, [values])

  const active = useMemo(() => values[selectedMenu]?.isActive, [values, selectedMenu])

  const onActive = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    Object.keys(newStats).forEach((key) => {
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
    })
    if (hasError) {
      setInputs(newStats)
      return
    }

    const newValues = cloneDeep(values)
    Object.keys(newValues).forEach((key) => {
      if (key === selectedMenu) newValues[key].isActive = true
      else newValues[key].isActive = false
    })

    setIsLoading('active')
    updateVendorOptions(type, newValues)
      .then(({ success }) => {
        if (!success) return
        setValues(newValues)
      })
      .then(() => setIsLoading(''))
  }

  const onAddNew = () => {
    const newInputs = cloneDeep(inputs)
    const newKey = `${Date.now()}`
    Object.keys(newInputs).forEach((key) => (newInputs[key].value = ''))
    newInputs.title.value = 'New'
    setInputs(newInputs)
    const newValue = cloneDeep(values)
    newValue[newKey] = { title: 'New', isActive: false }
    setValues(newValue)
    setSelectedMenu(newKey)

    setIsLoading('add')
    updateVendorOptions(type, newValue)
      .then(({ success }) => {
        if (!success) return
        success && toast(`New credential has been added.`, { type: 'info' })
      })
      .then(() => setIsLoading(''))
  }

  const onChange = async (key: string, value: string | boolean) => {
    let newState = cloneDeep(inputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputs(newState)
  }

  const onDelete = async () => {
    const result = await confirm('Are you sure you want to delete!')
    if (!result) return

    setIsLoading('delete')
    deleteVendorOptions(type, selectedMenu)
      .then(({ success, updatedValues }) => {
        if (!success) return
        toast(`Seletected credential has been deleted.`, { type: 'info' })
        setSelectedMenu(Object.keys(updatedValues)[0])
        setValues(updatedValues)
      })
      .then(() => setIsLoading(''))
  }

  const onEnable = (enabled: boolean) => {
    setIsLoading('loading')
    enableVendorOptions(type, enabled)
      .then(({ success }) => {
        if (!success) return
        toast(`${title} has been ${enabled ? 'enabled' : 'disabled'}`, { type: 'info' })
        setEnabled(enabled)
      })
      .then(() => setIsLoading(''))
  }

  const onSubmit = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (!disabled && value !== undefined) data[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    const newValue = cloneDeep(values)
    Object.keys(data).forEach((key) => {
      newValue[selectedMenu][key] = data[key]
    })

    setValues(newValue)

    setIsLoading('submit')
    updateVendorOptions(type, newValue)
      .then(({ success }) => {
        success && toast(`Credential(${data.title}) has been updated.`, { type: 'info' })
      })
      .then(() => setIsLoading(''))
  }

  return (
    <div className="CreditReports-container">
      <h2 className="text-xl font-bold flex items-center">
        {title}
        {!!isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="mt-5 w-full">
        <RenderInput
          input={
            {
              ...defaultInputs.enabled,
              value: enabled,
              disabled: !!isLoading,
            } as any
          }
          Key="enabled"
          onChange={(_: string, v: boolean) => onEnable(v)}
        />
        <div className="py-3">
          <ButtonGroup title={menus} value={selectedMenu} onChange={(value) => setSelectedMenu(value)} />
          <Button loading={isLoading === 'add'} onClick={() => onAddNew()} disabled={!!isLoading}>
            + Add
          </Button>
        </div>

        {Object.keys(inputs).map((key: any, index) => {
          if (!enabled) inputs[key].required = false
          let input = inputs[key]
          return (
            <div className={`input md:max-w-2xl mb-3`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
      <div className="mt-5">
        <Button onClick={onSubmit} loading={isLoading === 'submit'} disabled={!!isLoading}>
          Submit
        </Button>
        <Button
          onClick={onDelete}
          loading={isLoading === 'remove'}
          color="red"
          disabled={active ? active : !!isLoading}
        >
          Delete
        </Button>
        <Button onClick={onActive} loading={isLoading === 'active'} disabled={active ? active : !!isLoading}>
          Make active
        </Button>
      </div>
    </div>
  )
}
