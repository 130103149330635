import { store } from 'reducers'

export const canEditLoanApplication = () => {
  let rlt = true
  const { auth, loanDetail } = store.getState()
  if (!loanDetail.restructureApplicationRelease) rlt = false
  const matched = auth?.role?.role?.permissions?.find(
    (permissionName: string) => permissionName === 'ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE',
  )
  if (matched) rlt = true
  return rlt
}

export const canEditLoanStructureDetail = () => {
  let rlt = true
  const { auth, loanDetail } = store.getState()
  if (loanDetail.rateData.locked) rlt = false
  if (!loanDetail.restructureApplicationRelease) rlt = false
  const matched = auth?.role?.role?.permissions?.find(
    (permissionName: string) => permissionName === 'ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE',
  )
  if (matched) rlt = true
  return rlt
}

export const canEditCreditScore = () => {
  let rlt = true
  const { auth, loanDetail } = store.getState()
  if (loanDetail.submitToLoanSetup != 0) rlt = false
  const matched = auth?.role?.role?.permissions?.find(
    (permissionName: string) => permissionName === 'ANYTIME_CAN_EDIT_LOAN_APPLICATION_STRUCTURE',
  )
  if (matched) rlt = true
  return rlt
}
