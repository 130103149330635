import { ClockIcon } from '@heroicons/react/24/outline'
import { setHeader } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { companyName, itemCountPerPage, SettingKey } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import type { ILoanGlobalSettings } from 'pages/Admin/AdminTools/Configuration/GlobalSettings'
import {
  Appraisal2StatusType,
  Appraisal2StatusTypeColor,
  Appraisal2StatusTypeWithGroup,
  OrderAppraisalOrderTypeOptions,
  OrderAppraisalVendorOptions,
} from 'pages/Loan'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { changeAppraisal2Status, getAllOrderAppraisal2, getAppraisal2Statistics, getSetting } from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { Input, InputDateRange, Pagination, Select, SelectWithGroup } from 'stories/components'
import { formatDate, formatTime, openAuditLog, renderHeader, useTitle } from 'utils'

import { AppraisalStatisticsItem } from './AppraisalStatisticsItem'

interface IAppraisalStatistics {
  count: number
  percent: number
  status: string
}

export function Appraisals() {
  useTitle(`Appraisals - ${companyName}`)

  const [data, setData] = useState<Record<string, any>[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [action, setAction] = useState('')
  const [filters, setFilters] = useState<Record<string, any>>({
    dateRange: {},
    query: '',
    status: 'Active',
    orderType: '',
    vendor: '',
    orderBy: 'createdAt',
    orderDir: '-1',
  })
  const [pageNum, setPageNum] = useState(0)
  const [filterQuery, setFilterQuery] = useState('')
  const [isGetDataOnce, setIsGetDataOnce] = useState(false)
  const [statistics, setStatistics] = useState<IAppraisalStatistics[]>([])
  const [visibleStatus, setVisibleStatus] = useState<string[]>([])

  const { hasPermission } = usePermissions()

  const dispatch = useDispatch()

  const hasAdminPermission = hasPermission('MANAGE_ADMIN_TOOLS')

  const filterStatistics = async (filters: Record<string, any>, needLoading = true) => {
    needLoading && setIsLoading(true)
    const res = await getAppraisal2Statistics(filters.dateRange)
    setStatistics(res)
    needLoading && setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      await filterStatistics(filters, false)
      const { value } = await getSetting(SettingKey.GLOBAL_SETTINGS)
      const settings = JSON.parse(value || '{}') as ILoanGlobalSettings
      const newVisible: string[] = []
      Object.keys(settings.appraisalStatisticsVisible).forEach((key) => {
        if (settings.appraisalStatisticsVisible[key]) newVisible.push(key)
      })
      setVisibleStatus(newVisible)
      setIsLoading(false)
    })()
  }, [])

  const filterData = async (filters: any, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = pageNum
    setAction('getData')
    setLoading(true)
    const { data, total } = await getAllOrderAppraisal2({
      ...filters,
      skip: pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
    setData(data)
    setTotal(total)
    setLoading(false)
    setAction('')
  }

  useEffect(() => {
    if (!isGetDataOnce) return
    const timeOutId = setTimeout(() => action == '' && filterData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    setFilters(newFilters)
    setPageNum(0)
    if (key === 'query') setFilterQuery(value)
    else if (key == 'dateRange') filterStatistics(newFilters)
    else filterData(newFilters, 0)
  }
  const renderLoanNumberLink = (item: any) => {
    return (
      <Link to={`/order_valuation/${item.loanNumber}`} className="text-shade-blue hover:underline" target="_blank">
        <span className="font-variation-settings-700">
          {item.byteproFileName > 0 ? item.byteproFileName : item.loanNumber}
        </span>
      </Link>
    )
  }

  const changeStatus = async (id: number, value: string) => {
    setAction(`status-change-${id}`)
    const res = await changeAppraisal2Status({ id, value })
    setAction('')
    if (res.success) {
      let temp = cloneDeep(data)
      temp.map((item, index) => {
        if (item.id === id) {
          temp[index].status = value
        }
      })
      setData(temp)
      dispatch(
        setHeader({
          activeChangeFlag: true,
        }),
      )
    }
  }

  const showHistory = (id: number) => {
    const options = {
      table: 'Appraisal2',
      field: 'status',
      keys: {
        field: 'status',
        id,
      },
    }
    openAuditLog(options)
  }

  const renderStatus = (item: any) => {
    const load = action === `status-change-${item.id}`
    return (
      <div className="flex items-center">
        <div className={`w-[160px] -mb-4`}>
          <Select
            key={item.id}
            id={`loan-status-${item.id}`}
            size={3}
            title=""
            options={Appraisal2StatusType}
            disabled={load}
            value={item.status}
            onChange={(value) => changeStatus(item.id, value)}
          />
        </div>
        {load && <img src={svgLoading} className={`inline w-4 h-4 ml-2 mt-2 mb-2 text-white animate-spin`} />}
        {!load && (
          <span className="ml-2 text-gray-500 cursor-pointer">
            <ClockIcon className="w-4 h-4" onClick={() => showHistory(item.id)} />
          </span>
        )}
      </div>
    )
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onSort = (key: string, sortOrder: number) => {
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
    filterData(newFilters, 0)
  }

  const headers: Record<string, string | JSX.Element> = {
    loanNumber: 'Loan Number',
    id: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Loan Status</div>
        Order ID
      </span>
    ),
    borrower: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Borrower</div>
        Property Address
      </span>
    ),
    orderType: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Order Type</div>
        Appraisal Form
      </span>
    ),
    // appraisalForm: 'APPRAISAL FORM',
    amc: 'Vendor',
    status: 'Status',
    createdAt: 'Order Date',
    dueDate: 'Due Date',
  }
  if (!hasAdminPermission) delete headers.amc

  useEffect(() => {
    filterData(filters).then(() => {
      setIsGetDataOnce(true)
    })
  }, [pageNum])

  const viewStatus = (status: string) => {
    const newFilters = cloneDeep(filters)
    newFilters['status'] = status
    setFilters(newFilters)

    setPageNum(0)
    filterData(newFilters, 0)
  }

  return (
    <div className="Appraisals-container py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full mb-6">
        <LayoutLoading show={isLoading} />
        <div className="flex flex-wrap justify-between mb-5">
          <h1 className="text-2xl font-bold flex items-center mb-3">
            <span>Statistics</span>
          </h1>
        </div>

        <div className="flex mb-4">
          <InputDateRange
            title="Date Range"
            value={filters.dateRange}
            onChange={(value) => onChangeFilter('dateRange', value)}
          />
        </div>

        <div className="flex flex-wrap justify-between gap-4">
          {visibleStatus.map((status, index) => {
            const targetData = statistics.find((item) => item.status === status)

            return (
              <div key={index}>
                <AppraisalStatisticsItem
                  status={status}
                  count={targetData?.count || 0}
                  percent={targetData?.percent || 0}
                  color={Appraisal2StatusTypeColor[status]}
                  viewStatus={() => viewStatus(status)}
                />
              </div>
            )
          })}
        </div>
      </div>

      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 sm:text-center lg:text-left w-full">
        <LayoutLoading show={loading} />
        <div className="flex flex-wrap justify-between mb-5">
          <h1 className="text-2xl font-bold flex items-center mb-3">
            <span>Appraisals</span>
            <span className="text-base ml-3">
              {loading ? <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" /> : `(${total})`}
            </span>
          </h1>
        </div>
        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
          <Input
            type="search"
            title="Search"
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
          <SelectWithGroup
            id="status"
            title="Status"
            options={Appraisal2StatusTypeWithGroup()}
            value={filters.status}
            onChange={(value) => onChangeFilter('status', value)}
          />
          <Select
            id="orderType"
            title="Order Type"
            hasDefaultOption
            options={OrderAppraisalOrderTypeOptions}
            value={filters.orderType}
            onChange={(value) => onChangeFilter('orderType', value)}
          />
          <Select
            id="vendor"
            title="Vendor"
            hasDefaultOption
            options={OrderAppraisalVendorOptions}
            value={filters.vendor}
            onChange={(value) => onChangeFilter('vendor', value)}
          />
        </div>
        <div className="mt-2">
          <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {Object.keys(headers).map((key, index) =>
                    renderHeader({
                      title: headers[key],
                      sortable: true,
                      index,
                      key,
                      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
                      onSort: (key: string, dir: number) => onSort(key, dir),
                    }),
                  )}
                </tr>
              </thead>
              <tbody className="text-[14px] text-gray-900">
                {data.map((item, index: number) => {
                  return (
                    <tr key={index} className={`border ${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-2 py-3">{renderLoanNumberLink(item)}</td>
                      <td className="px-2 py-3 whitespace-nowrap">
                        <span>
                          <div className="border-b w-full mb-2">{item.loanStatus}</div>
                          {item.id}
                        </span>
                      </td>
                      <td className="px-2 py-3 min-w-[170px]">
                        <span>
                          <div className="border-b w-full mb-2">{`${item.info.borrowerFirstName} ${item.info.borrowerLastName}`}</div>
                          {item.info.propertyAddress}
                        </span>
                      </td>
                      <td className="px-2 py-3 min-w-[180px] max-w-[360px]">
                        <span>
                          <div className="border-b w-full mb-2">{item.info.orderType}</div>
                          {item.info.appraisalForm}
                        </span>
                      </td>
                      {hasAdminPermission && <td className="px-2 py-3">{item.info.amc}</td>}
                      <td className="px-2 py-3">
                        <span className="statusColor">{renderStatus(item)}</span>
                      </td>
                      <td className="px-2 py-3">{formatTime(item.createdAt)}</td>
                      <td className="px-2 py-3">{formatDate(item.info.clientDueDate)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="flex justify-end items-center mt-3 mb-3">
              <Pagination
                totalCount={total}
                itemCountPerPage={itemCountPerPage}
                onNavigate={onPageNavigate}
                pageNum={pageNum}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
