import type { ITier, ITierData } from 'pages/Resources'
import { Fragment } from 'react'

import { DscrCityCountyTiers } from './DscrCityCountyTiers'
import { DscrMsaTiers } from './DscrMsaTiers'

const tierTypes: Record<string, string> = { cities: 'City Tiers', counties: 'County Tiers', msa: 'MSA Tiers' }

export const DscrTiersLtvAdjustment = ({ data }: { data: ITierData }) => {
  return (
    <div>
      {Object.keys(tierTypes).map((key, index) => {
        if (data[key] && (data[key] as ITier[]).length > 0)
          switch (key) {
            case 'cities':
            case 'counties':
              return (
                <div key={index} className="py-2">
                  <DscrCityCountyTiers data={data[key] as ITier[]} title={tierTypes[key]} />
                </div>
              )
            case 'msa':
              return (
                <div key={index} className="py-2">
                  <DscrMsaTiers data={data[key] as ITier[]} title={tierTypes[key]} />
                </div>
              )
            default:
              return <Fragment key={index}></Fragment>
          }
      })}
    </div>
  )
}
