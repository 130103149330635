import cloneDeep from 'clone-deep'
import type { InputFileTable, InputType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal } from 'stories/components'
import { InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { IAccountingRecord } from './types'

const defaultInputs = (): Record<string, InputType> => {
  return {
    loanNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Loan Number',
      required: false,
    },
    description: {
      inputType: 'text',
      type: 'text',
      title: 'Description',
      required: true,
    },
    amount: {
      inputType: 'text',
      prefix: '$',
      type: 'thousandSep',
      title: 'Amount',
      required: true,
    },
    borrowerName: {
      inputType: 'text',
      title: 'Borrower Name',
      required: false,
    },
    subjectPropertyAddress: {
      inputType: 'map',
      title: 'Subject Property Address',
      required: false,
    },
    files: {
      inputType: 'filetable',
      filePath: '',
      title: 'Files',
      showStatus: false,
      showCategory: false,
      multiple: true,
      span: 'full',
    },
  }
}

interface IEditAccountingRecordContentProps {
  type: string
  item: IAccountingRecord | null
  onClose: Function
}

export const EditAccountingRecordContent = ({ type, item, onClose }: IEditAccountingRecordContentProps) => {
  const auth = useSelector((state: any) => state.auth)

  const [inputs, setInputs] = useState(defaultInputs())
  const isNew = !item

  useEffect(() => {
    const newInputs = cloneDeep(inputs)

    if (item) {
      Object.keys(item).forEach((key) => {
        if (newInputs[key]) newInputs[key].value = (item as any)[key]
      })
      ;(newInputs.files as InputFileTable).filePath = `accountings/${type}/${item.id}`
    } else (newInputs.files as InputFileTable).filePath = `accountings/${type}/${Date.now()}`

    setInputs(newInputs)
  }, [item])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (newInputs[key].value && newInputs[key].value.trim) newInputs[key].value = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    if (hasError) {
      setInputs(newInputs)
      return
    }

    const submitData: IAccountingRecord = {
      id: item ? item.id : Date.now(),
      description: data.description,
      amount: removeComma(data.amount),
      loanNumber: data.loanNumber,
      files: data.files,
      borrowerName: data.borrowerName || '',
      subjectPropertyAddress: data.subjectPropertyAddress || '',
      createdAt: item ? item.createdAt : Date.now(),
      createdBy: item ? item.createdBy : auth.profile.email,
    }

    onClose(submitData)
  }

  const renderInputs = () => {
    return (
      <div className={`${isNew ? 'w-112' : 'grid grid-cols-3 gap-x-4'} relative`}>
        {Object.keys(inputs).map((key, index) => {
          if (inputs[key].visible === false) return null

          return (
            <div className={`input mb-4 ${inputs[key].span ? 'col-span-full' : ''}`} key={index}>
              <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    )
  }

  const title = useMemo(() => {
    if (!item) return `Create Record`
    return `Edit Record`
  }, [item])

  if (item === null)
    return (
      <Modal title={title} isOpen onOk={onSubmit} onClose={() => onClose(null)} titleOkay="Submit" titleCancel="">
        {renderInputs()}
      </Modal>
    )

  return (
    <div className="relative py-4">
      <p className="text-gray-900 font-semibold text-lg mb-2">{title}</p>
      {renderInputs()}
      <div className="flex justify-center gap-4">
        <Button onClick={onSubmit}>Submit</Button>
        <Button color="white" onClick={() => onClose(null)}>
          Close
        </Button>
      </div>
    </div>
  )
}
