import { logout } from 'actions'
import { IS_MAIN_COMPANY } from 'config'
import jwt from 'jwt-decode'
import { Appraisals, LoanOverview, Pipeline, Ratesheet, Tasks } from 'pages'
import {
  AdminTools,
  ConditionsAndTemplates,
  ManageAccountings,
  NationalFlood,
  SecondaryMarketing,
  UserActivity,
  Vendors,
} from 'pages/Admin'
import { EditProfile } from 'pages/Auth'
import { ManageBorrowers } from 'pages/Borrower'
import {
  AddAppraisalInformationV2,
  AppraisalInformation,
  BudgetScopeOfWork,
  ByteproLogs,
  ClosingCalendar,
  ClosingScreen,
  ContractorApplication,
  ContractorApplications,
  Dataverify,
  DocumentGenie,
  ExpirationDates,
  Funding,
  Invoices,
  LoanChangeRequest,
  LoanInTake,
  Notes,
  Parties,
  PostClosing,
  PurchaseAdvice,
  PurchaseAdviceSeller,
  ReportDetails,
  Reports,
  ReviewForm,
  SecondaryMarketingScreen,
  Servicing,
  Underwriting,
  UnderwritingSummary,
} from 'pages/Loan'
import { AmortizationSchedule } from 'pages/Loan/AmortizationSchedule'
import { AccountDetails, ManageAccounts } from 'pages/ManageAccounts'
import ExportHistory from 'pages/ManageAccounts/ExportHistory'
import { GuidelineOverlay } from 'pages/Resources'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { PermissionGate } from 'utils/PermissionGate'

const routes = (
  <Switch>
    <Route exact path="/registrations">
      <PermissionGate permission={'MANAGE_ACCOUNTS'}>
        <ManageAccounts />
      </PermissionGate>
    </Route>

    <Route exact path="/registrations/exportHistory">
      <PermissionGate permission={'MANAGE_ACCOUNTS'}>
        <ExportHistory />
      </PermissionGate>
    </Route>

    <Route exact path="/registrations/:userId">
      <PermissionGate permission={'MANAGE_ACCOUNTS'}>
        <AccountDetails />
      </PermissionGate>
    </Route>

    <Route exact path="/user_activity">
      <PermissionGate permission={'MANAGE_USER_ACTIVITY'}>
        <UserActivity />
      </PermissionGate>
    </Route>
    <Route exact path="/admin_tool/:menu">
      <PermissionGate permission={['MANAGE_ADMIN_TOOLS', 'MANAGE_APPRAISER_SETTLEMENT_CONTRACTOR', 'MANAGE_REVIEWS']}>
        <AdminTools />
      </PermissionGate>
    </Route>
    <Route exact path="/accountings/:menu">
      <PermissionGate permission={['MANAGE_ACCOUNTING']}>
        <ManageAccountings />
      </PermissionGate>
    </Route>
    <Route exact path="/secondary_marketing/:menu">
      <PermissionGate permission={'MANAGE_ADMIN_TOOLS'}>
        <SecondaryMarketing />
      </PermissionGate>
    </Route>
    <Route exact path="/vendors/:menu">
      <PermissionGate permission={'MANAGE_VENDORS'}>
        <Vendors />
      </PermissionGate>
    </Route>
    <Route exact path="/condition_template/:menu">
      <PermissionGate permission={'MANAGE_CONDITIONS_TEMPLATES'}>
        <ConditionsAndTemplates />
      </PermissionGate>
    </Route>
    <Route exact path="/servicing/:loan_number">
      <Servicing />
    </Route>
    <Route exact path="/budget/:loanNumber">
      <PermissionGate permission={'BUDGET_SCOPE_OF_WORK'}>
        <BudgetScopeOfWork />
      </PermissionGate>
    </Route>
    <Route exact path="/underwriting/:loan_number">
      <Underwriting />
    </Route>
    <Route exact path="/loanInTake/:loan_number">
      <LoanInTake />
    </Route>
    <Route exact path="/bytepro_logs/:loan_number">
      <ByteproLogs />
    </Route>
    <Route exact path="/edit_profile">
      <EditProfile />
    </Route>
    <Route exact path="/loan_process/:action/:loan_number">
      <LoanOverview />
    </Route>
    <Route exact path="/loan_change_request/:loan_number">
      <LoanChangeRequest />
    </Route>
    <Route exact path="/post_closing/:loan_number">
      <PostClosing />
    </Route>
    <Route exact path="/closing_calendar/:loan_number">
      <ClosingCalendar />
    </Route>
    <Route exact path="/closing_screen/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <ClosingScreen />
      </PermissionGate>
    </Route>
    <Route exact path="/amortization_schedule/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <AmortizationSchedule />
      </PermissionGate>
    </Route>
    <Route exact path="/order_valuation/:loan_number">
      <AddAppraisalInformationV2 />
    </Route>
    <Route exact path="/document_genie/:loan_number">
      <DocumentGenie />
    </Route>
    <Route exact path="/review_form/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <ReviewForm />
      </PermissionGate>
    </Route>
    <Route exact path="/appraisal_information/:loan_number">
      <PermissionGate permission={'MANAGE_APPRAISAL_INFORMATION'}>
        <AppraisalInformation />
      </PermissionGate>
    </Route>
    <Route exact path="/appraisals">
      <PermissionGate permission={'MANAGE_VENDORS'}>
        <Appraisals />
      </PermissionGate>
    </Route>
    <Route exact path="/expiration_dates/:loan_number">
      <ExpirationDates />
    </Route>
    <Route exact path="/notes/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <Notes />
      </PermissionGate>
    </Route>
    <Route exact path="/dataverify/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <Dataverify />
      </PermissionGate>
    </Route>
    <Route exact path="/underwriting_summary/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <UnderwritingSummary />
      </PermissionGate>
    </Route>
    <Route exact path="/purchase_advice/:loan_number">
      <PermissionGate permission={'PURCHASE_ADVICE_BUYER'}>
        <PurchaseAdvice />
      </PermissionGate>
    </Route>
    <Route exact path="/purchase_advice_seller/:loan_number">
      <PermissionGate permission={'PURCHASE_ADVICE_SELLER'}>
        <PurchaseAdviceSeller />
      </PermissionGate>
    </Route>
    <Route exact path="/secondary_marketing_screen/:loan_number">
      <PermissionGate permission={'MANAGE_ADMIN_TOOLS'}>
        <SecondaryMarketingScreen />
      </PermissionGate>
    </Route>
    <Route exact path="/national_flood/:loan_number">
      <PermissionGate permission={'ADMIN_TO_AE_PROFILE_PERMISSION'}>
        <NationalFlood />
      </PermissionGate>
    </Route>
    <Route exact path="/reports">
      <PermissionGate permission={'MANAGE_REPORTS'}>
        <Reports />
      </PermissionGate>
    </Route>
    <Route exact path="/reports/:id">
      <PermissionGate permission={'MANAGE_REPORTS'}>
        <ReportDetails />
      </PermissionGate>
    </Route>
    <Route exact path="/pipeline">
      <Pipeline />
    </Route>
    <Route exact path="/ratesheet">
      <Ratesheet />
    </Route>
    <Route exact path="/tasks">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/tasks/:projectId">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/tasks/loan/:loanNumber">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/tasks/loan/:loanNumber/:taskNo">
      <PermissionGate permission={'MANAGE_TASKS'}>
        <Tasks />
      </PermissionGate>
    </Route>
    <Route exact path="/parties/:loanNumber">
      <PermissionGate permission={['MANAGE_PARTIES', 'MANAGE_PARTIES_INSURANCE_TITLE']}>
        <Parties />
      </PermissionGate>
    </Route>
    <Route exact path="/guidelineOverlay/:documentId">
      <GuidelineOverlay />
    </Route>
    <Route exact path="/funding/:loanNumber">
      <PermissionGate permission={'MANAGE_FUNDING'}>
        <Funding />
      </PermissionGate>
    </Route>
    <Route exact path="/contractor_application/:loanNumber">
      <ContractorApplications />
    </Route>
    <Route exact path="/contractor_application/:no/:loanNumber">
      <ContractorApplication />
    </Route>
    <Route exact path="/invoice/:loanNumber">
      <PermissionGate permission={'INVOICE'}>
        <Invoices />
      </PermissionGate>
    </Route>
    <Route exact path="/borrowers">
      <PermissionGate permission={'MANAGE_BORROWERS'}>
        <ManageBorrowers />
      </PermissionGate>
    </Route>
    <Route path="*">
      <Redirect to={IS_MAIN_COMPANY ? '/home' : '/pipeline'} />
    </Route>
  </Switch>
)

export const ProtectedRoutes = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => state.auth)
  let success = false

  try {
    const payload: any = jwt(auth.token)
    if (payload.exp * 1000 > Date.now()) {
      success = true
    } else {
      dispatch(logout())
    }
  } catch {}

  try {
    if (auth.profile.temparary) {
      dispatch(logout())
      success = false
    }
  } catch {}

  const location = useLocation()

  if (success) return routes

  return <Redirect to={IS_MAIN_COMPANY ? '/home' : '/login'} from={location.pathname} />
}
