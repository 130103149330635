import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { useEffect, useState } from 'react'
import { getClosingSettlement, postClosingSettlement } from 'services'
import { svgLoading } from 'stories/assets'
import { InputConvert, removeComma, thousandSeperator } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from './constant'

export function Settlement() {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any>({})
  const [inputs, setInputs] = useState(defaultInputs())
  const [action, setAction] = useState('')
  const [disableFields, setDisableFields] = useState<Array<string>>([])

  console.log(setInputs)

  const initData = async () => {
    setIsLoading(true)
    const res = await getClosingSettlement()
    if (res.success) {
      setData(res.data)
      setDisableFields(res.disable)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setLoanNumber()
    initData()
  }, [])

  const onChange = async (key: string, e: any) => {
    const temp = cloneDeep(data)
    temp[key] = InputConvert(inputs[key], e)
    setData(temp)

    setAction('saving')
    await postClosingSettlement(temp)
    setAction('')
  }
  const count = Object.keys(inputs).length

  return (
    <div className="Settlement-container relative">
      <LayoutLoading show={action !== '' || isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Settlement Sheet
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="mt-4">
        <div className="border p-2 rounded bg-gray-100 font-variation-settings-600 text-sm">Fee Information</div>

        <div className={`grid md:grid-cols-2 grid-cols-1 mb-3`}>
          {[0, 1].map((outIndex) => (
            <div className="grid grid-cols-1">
              {Object.keys(inputs)
                .sort()
                .map((key, index) => {
                  if (outIndex == 0 && index > count / 2) return null
                  if (outIndex == 1 && index < count / 2) return null

                  const input: any = cloneDeep(inputs[key])
                  if (input.visible === false) return null

                  input.value = data[key] ? removeComma(data[key]) : ''
                  if (disableFields.indexOf(key) !== -1) input.disabled = true
                  let content = thousandSeperator(input.value)
                  if (content) content = `$ ${content}`

                  return (
                    <div key={index} className="flex flex-wrap items-center my-2">
                      <div className="p-2 text-[14px]">{input.title}</div>
                      <div className="flex-1">
                        {input.disabled ? (
                          <p className="text-black font-variation-settings-600">{content}</p>
                        ) : (
                          <PlainInput
                            type="number"
                            value={input.value}
                            content={content}
                            onChange={(val: string) => onChange(key, val)}
                            className="border-b"
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
