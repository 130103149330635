import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'text',
      title: 'Type',
      required: true,
    },
    content: {
      inputType: 'textarea',
      title: 'Content',
      required: true,
      rows: 15,
    },
  }
}
