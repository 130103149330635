import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getBusinessHours, setBusinessHours } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'

export interface BusinessHour {
  startTime: string // HH:MM
  endTime: string // HH:MM
  startDay: number // 0 - 6 0: Sunday 6 - Saturday
  endDay: number // 0 - 6 0: Sunday 6 - Saturday
  isHoliday: boolean
}

export const BusinessHours = () => {
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    getBusinessHours()
      .then((data: Record<string, any> | null) => {
        if (!data) return
        const inputsData = cloneDeep(inputs)

        Object.keys(data).forEach((key) => {
          if (key === 'startTime') {
            inputsData.startHour.value = data[key].substr(0, 2)
            inputsData.startMinute.value = data[key].substr(3, 2)
          } else if (key === 'endTime') {
            inputsData.endHour.value = data[key].substr(0, 2)
            inputsData.endMinute.value = data[key].substr(3, 2)
          } else inputsData[key].value = data[key]
        })

        setInputs(inputsData)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const newInputs = cloneDeep(inputs)
    const values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) return

    const newBusinessHour: BusinessHour = {
      startTime: values.startHour + ':' + values.startMinute,
      endTime: values.endHour + ':' + values.endMinute,
      startDay: values.startDay,
      endDay: values.endDay,
      isHoliday: values.isHoliday,
    }

    setLoading(true)
    await setBusinessHours(newBusinessHour as BusinessHour)
    setLoading(false)
    toast('Business Hour is saved.', { type: 'info' })
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Business Hours
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div key={index} className={`col-span-${input.span}`}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
      <div className="flex justify-center">
        <Button className="mt-4 w-[180px]" loading={isLoading} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  )
}
