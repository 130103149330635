import { ClockIcon } from '@heroicons/react/24/outline'
import type { Color } from 'stories/types'

interface RadioGroupProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Id of Input
   */
  id: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Size of Input
   */
  size?: number
  /**
   * Options of select
   */
  options?: Array<string> | Record<string, string> // (Title => key)
  /**
   * Custom class name
   */
  className?: string
  /**
   * Required
   */
  required?: boolean
  /**
   * Optional history handler
   */
  history?: boolean

  titleType?: string
  isPlain?: boolean
  noBorder?: boolean
  noPadding?: boolean
  /**
   * Optional click handler
   */
  onChange?: (value: any) => void
  onClick?: (value: any) => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const RadioGroup = ({
  color = 'sky',
  disabled = false,
  id = '',
  title = '',
  name = '',
  value = '',
  error = '',
  options = [],
  className = '',
  size = 4,
  required = false,
  history = false,
  titleType = 'absolute',
  isPlain = false,
  noBorder = false,
  noPadding = false,
  onChange = () => {},
  onClick = () => {},
  showHistory = () => {},
  ...props
}: RadioGroupProps) => {
  let classNames = `block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer`
  if (size === 3) {
    classNames = `block focus:ring-0 text-[13px] text-black bg-white py-0 pl-1 pr-4 bg-[center_right_0rem] w-full border-gray-300`
  }
  if (isPlain) classNames += ' !flex gap-4'
  if (noBorder) classNames += ' !border-0'
  if (noPadding) classNames += ' !p-0'
  return (
    <div className="input-container -mt-2 mb-2">
      <div className={`relative z-0 pt-4 w-full group ${className} ${disabled ? 'bg-gray-100' : ''}`}>
        {title.length > 0 &&
          (titleType == 'absolute' ? (
            <label
              htmlFor={id}
              className={`absolute text-xs text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-2 top-3 origin-[0] peer-focus:left-0 peer-focus:text-${color}-700 peer-focus:dark:text-${color}-700 peer-placeholder-shown:text-sm peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:text-xs peer-focus:-translate-y-6 peer-placeholder-shown:leading-3 ml-2 px-1 rounded bg-white z-10 peer-placeholder-shown:-z-10 peer-focus:z-10 left-0`}
            >
              {title}
              {required && '*'}
            </label>
          ) : (
            <label className="text-sm font-medium text-gray-900 dark:text-gray-300 flex-auto">
              {title}
              {required && '*'}
            </label>
          ))}
        <div className={classNames} {...props}>
          {Array.isArray(options) &&
            options.map((option) => (
              <div className="flex items-center my-2" key={`option-${name}-${option}`}>
                <input
                  type="radio"
                  id={`${name}-${option}`}
                  name={name}
                  value={option}
                  key={`${name}-${option}`}
                  disabled={disabled}
                  onChange={(e) => onChange(e.currentTarget.value)}
                  onClick={(e) => onClick(e.currentTarget.value)}
                  required={required}
                  checked={value === option}
                />
                <label htmlFor={`${name}-${option}`} className="ml-1">
                  {option}
                </label>
              </div>
            ))}

          {!Array.isArray(options) &&
            Object.keys(options).map((key) => (
              <div className="flex items-center my-2" key={`option-${key}`}>
                <input
                  type="radio"
                  id={`${name}-${key}`}
                  name={name}
                  value={key}
                  key={`${name}-${key}`}
                  disabled={disabled}
                  onChange={(e) => onChange(e.currentTarget.value)}
                  onClick={(e) => onClick(e.currentTarget.value)}
                  required={required}
                  checked={value === key}
                />
                <label htmlFor={`${name}-${key}`} className="ml-1 cursor-pointer">
                  <span dangerouslySetInnerHTML={{ __html: options[key] }} />
                </label>
              </div>
            ))}
        </div>
        {history && (
          <span className="ml-1 hidden group-hover:inline absolute right-2 top-0" onClick={() => showHistory()}>
            <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
          </span>
        )}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1">{error}</p>}
    </div>
  )
}
