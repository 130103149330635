import { type InputType, COMPANY_TITLE, loanOptionsConstants } from 'config'

import { CCOptions, OrderAppraisalOrderTypeOptions, OrderAppraisalVendorOptions } from './types'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    // File / Company Information
    dividerCompany: {
      inputType: 'divider',
      title: 'File / Company Information',
    },
    companyID: {
      inputType: 'select',
      title: 'Company ID',
      value: COMPANY_TITLE,
      options: [COMPANY_TITLE],
      hasDefaultOption: true,
      error: '',
      required: true,
    },
    loanNumber: {
      inputType: 'text',
      title: 'Loan Number',
      value: '',
      error: '',
      required: true,
    },
    loanOfficerEmail: {
      inputType: 'text',
      type: 'email',
      title: 'Loan Officer Email',
      required: true,
      copyable: true,
    },
    processorEmail: {
      inputType: 'text',
      type: 'email',
      title: 'Processor Email',
    },

    // Associated Dates
    dividerDates: {
      inputType: 'divider',
      title: 'Associated Dates',
    },
    orderDate: {
      inputType: 'text',
      type: 'date',
      title: 'Order Date',
      required: true,
    },
    clientDueDate: {
      inputType: 'text',
      type: 'date',
      title: 'Client Due Date',
      required: true,
    },
    inspectionDate: {
      inputType: 'text',
      type: 'date',
      title: 'Inspection Date',
    },
    acceptedDate: {
      inputType: 'text',
      type: 'date',
      title: 'Accepted Date',
    },
    receivedByBorrowerDate: {
      inputType: 'text',
      type: 'date',
      title: 'Received by Borrower',
    },
    waiverDate: {
      inputType: 'text',
      type: 'date',
      title: 'Timing Waiver Date',
    },
    completeDate: {
      inputType: 'text',
      type: 'date',
      title: 'Complete Date',
    },

    // Borrower & Contacts
    dividerBorrower: {
      inputType: 'divider',
      title: 'Borrower & Contacts',
      copyable: true,
    },
    borrowerFirstName: {
      inputType: 'text',
      title: 'Borrower First Name',
      required: true,
      copyable: true,
    },
    borrowerLastName: {
      inputType: 'text',
      title: 'Borrower Last Name',
      required: true,
      copyable: true,
    },
    borrowerEmail: {
      inputType: 'text',
      type: 'email',
      title: 'Borrower Primary Email',
      required: true,
      copyable: true,
    },
    borrowerPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Borrower Primary Phone',
      required: true,
      copyable: true,
    },
    contactFirstName: {
      inputType: 'text',
      title: 'Contact First Name',
      required: true,
      copyable: true,
    },
    contactLastName: {
      inputType: 'text',
      title: 'Contact Last Name',
      required: true,
      copyable: true,
    },
    contactEmail: {
      inputType: 'text',
      type: 'email',
      title: 'Contact Primary Email',
      required: true,
      copyable: true,
    },
    contactPhone: {
      inputType: 'text',
      type: 'phone',
      title: 'Contact Primary Phone',
      required: true,
      copyable: true,
    },
    contactSameAsBorrower: {
      inputType: 'toggle',
      value: false,
      title: 'Contact Info Same as Borrower Info',
      span: 2,
    },
    entityName: {
      inputType: 'text',
      title: 'Entity Name',
      value: '',
      error: '',
      required: true,
      span: 2,
    },
    // Property Details
    dividerProperty: {
      inputType: 'divider',
      title: 'Property Details',
    },
    propertyAddress: {
      inputType: 'map',
      title: 'Property Address',
      value: '',
      error: '',
      required: true,
      span: 4,
    },
    propertyCounty: {
      inputType: 'map',
      title: 'Property County',
      value: '',
      error: '',
      span: 1,
    },
    propertyType: {
      inputType: 'select',
      title: 'Property Type',
      value: '',
      hasDefaultOption: true,
      options: loanOptionsConstants.propertyTypeBigList,
      required: true,
    },
    pointOfContact: {
      inputType: 'select',
      title: 'Point of Contact',
      value: 'Borrower',
      options: {
        Contact: 'Contact Person',
        Borrower: 'Borrower',
      },
      required: true,
    },

    // Order Details
    dividerOrderDetail: {
      inputType: 'divider',
      title: 'Order Details',
    },
    orderType: {
      inputType: 'select',
      title: 'Order Type',
      value: '',
      options: OrderAppraisalOrderTypeOptions,
      error: '',
      hasDefaultOption: true,
      required: true,
      sort: true,
    },
    appraisalForm: {
      inputType: 'select',
      tooltip: 'If you select Order Type, then drop down options will exist!',
      title: 'Appraisal Form',
      value: '',
      options: [],
      hasDefaultOption: true,
      required: true,
    },
    purpose: {
      inputType: 'select',
      title: 'Loan Purpose',
      value: '',
      options: ['Purchase', 'Refinance'],
      hasDefaultOption: true,
      required: true,
    },
    purchaseAmount: {
      inputType: 'text',
      title: 'Purchase Amount',
      prefix: '$',
      value: '',
      type: 'thousandSep',
      error: '',
      required: true,
    },
    occupancy: {
      inputType: 'select',
      title: 'Occupancy',
      value: '',
      options: ['Investment', 'Primary', 'Secondary'],
      hasDefaultOption: true,
      required: true,
    },
    noteMessages: {
      inputType: 'textarea',
      title: 'Note Messages',
      value: '',
      rows: 2,
      span: 3,
    },
    amc: {
      inputType: 'select',
      title: 'Vendor',
      value: '',
      options: OrderAppraisalVendorOptions,
      sort: true,
      error: '',
      hasDefaultOption: true,
      required: false,
    },
    trinityOrderID: {
      inputType: 'text',
      title: 'Trinity Order ID',
      value: '',
      type: 'number',
      error: '',
    },
    notifications: {
      inputType: 'autocomplete',
      title: 'Notification Emails',
      type: 'email',
      required: true,
      span: 2,
      value: [],
    },
    underConstruction: {
      inputType: 'check',
      title: 'Is property currently under construction?',
      value: '',
      error: '',
      span: 3,
      visible: false,
      required: true,
    },

    // Feasibility Authorization Method
    feasibilityTitle: {
      inputType: 'divider',
      title: 'Feasibility Payment Method',
      visible: false,
    },
    feasibilityType: {
      inputType: 'select',
      title: 'Type',
      value: '',
      options: ['ACH', 'Credit Card'],
      hasDefaultOption: true,
      required: true,
      visible: false,
    },
    feasibilitySignerName: {
      inputType: 'text',
      type: 'text',
      title: 'Signer Name',
      required: true,
      visible: false,
    },
    feasibilityEmailToLink: {
      inputType: 'text',
      type: 'email',
      title: 'Email to Send Link',
      required: true,
      visible: false,
      span: 2,
    },

    feasibilityACHPaymentAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Payment Amount',
      prefix: '$',
      required: true,
      visible: false,
      disabled: true,
    },
    feasibilityACHFinancialInstitution: {
      inputType: 'text',
      type: 'text',
      title: 'Name of Financial Institution',
      required: true,
      visible: false,
    },
    feasibilityACHAccount: {
      inputType: 'text',
      type: 'text',
      title: 'Name on Account',
      required: true,
      visible: false,
    },
    feasibilityACHRoutingNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Routing Number',
      maxLength: 9,
      required: true,
      visible: false,
    },
    feasibilityACHAccountNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Account Number',
      minLength: 8,
      maxLength: 17,
      required: true,
      visible: false,
    },
    feasibilityACHAccountType: {
      inputType: 'select',
      title: 'Account Type',
      options: ['Checking', 'Saving'],
      hasDefaultOption: true,
      required: true,
      visible: false,
    },
    feasibilityCCType: {
      inputType: 'select',
      title: 'CC Type',
      value: '',
      hasDefaultOption: true,
      options: CCOptions,
      required: true,
      visible: false,
    },

    feasibilityCCStudyFee: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Payment Amount',
      prefix: '$',
      required: true,
      disabled: true,
      visible: false,
    },
    feasibilityCCFirstName: {
      inputType: 'text',
      title: 'First Name',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    feasibilityCCLastName: {
      inputType: 'text',
      title: 'Last Name',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    feasibilityCCCardNumber: {
      inputType: 'text',
      title: 'Card Number',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    feasibilityCCCardCode: {
      inputType: 'text',
      title: 'Card Code',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    feasibilityCCExpMonth: {
      inputType: 'select',
      title: 'Exp Month',
      value: '',
      hasDefaultOption: true,
      options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      required: true,
      visible: false,
    },
    feasibilityCCExpYear: {
      inputType: 'select',
      title: 'Exp Year',
      value: '',
      hasDefaultOption: true,
      options: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
      required: true,
      visible: false,
    },
    feasibilityCCBillingAddress: {
      inputType: 'map',
      title: 'Billing Address',
      value: '',
      error: '',
      required: true,
      visible: false,
      span: 3,
    },
    feasibilitySignKey: {
      inputType: 'custom',
      title: '',
      visible: false,
      span: 2,
    },

    dividerClientLender: {
      inputType: 'divider',
      title: 'Select Client Lender for Appraisal Nation',
      visible: false,
    },

    clientLenderAccount: {
      inputType: 'select',
      title: 'Account',
      value: '',
      hasDefaultOption: true,
      options: { main: COMPANY_TITLE, broker: 'Broker', myAccount: 'My Account' },
      required: true,
      span: 2,
      visible: false,
    },

    clientLenderCompany: {
      inputType: 'select',
      title: 'Company List',
      value: '',
      hasDefaultOption: true,
      options: [],
      required: true,
      span: 2,
      visible: false,
    },

    // Payment Information
    dividerPay: {
      inputType: 'divider',
      title: 'Payment Information',
    },
    isSendInvoice: {
      inputType: 'check',
      title: 'Send Payment Link',
      value: true,
      error: '',
      span: 2,
      required: true,
    },
    paymentLinkEmail: {
      inputType: 'text',
      type: 'email',
      title: 'Email to Send Link',
      required: true,
      span: 2,
    },
    ccType: {
      inputType: 'select',
      title: 'CC Type',
      value: '',
      hasDefaultOption: true,
      options: CCOptions,
      required: true,
      visible: false,
    },
    cardFirstName: {
      inputType: 'text',
      title: 'First Name',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    cardLastName: {
      inputType: 'text',
      title: 'Last Name',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    cardEmail: {
      inputType: 'text',
      title: 'Email',
      type: 'email',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    paymentAmount: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Payment Amount',
      value: '',
      error: '',
      visible: false,
    },
    cardNumber: {
      inputType: 'text',
      title: 'Card Number',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    cardCode: {
      inputType: 'text',
      title: 'Card Code',
      value: '',
      error: '',
      required: true,
      visible: false,
    },
    expMonth: {
      inputType: 'select',
      title: 'Exp Month',
      value: '',
      hasDefaultOption: true,
      options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      required: true,
      visible: false,
    },
    expYear: {
      inputType: 'select',
      title: 'Exp Year',
      value: '',
      hasDefaultOption: true,
      options: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
      required: true,
      visible: false,
    },
    billingAddress: {
      inputType: 'map',
      title: 'Billing Address',
      value: '',
      error: '',
      required: true,
      visible: false,
      span: 3,
    },

    dividerDocuments: {
      inputType: 'divider',
      title: 'Documents',
    },
    underConstructionAlert: {
      inputType: 'alert',
      title: '',
      value: 'Please upload Budget and Scope of Work!',
      visible: false,
      span: 4,
    },
    documents: {
      inputType: 'custom',
      title: '',
      span: 4,
    },
  }
}
