import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    note: {
      inputType: 'text',
      title: 'Note',
      required: true,
    },
    type: {
      inputType: 'select',
      title: 'Input Type',
      options: ['text', 'number', 'date'],
      hasDefaultOption: true,
      required: true,
    },
    key: {
      inputType: 'text',
      title: 'Docgen key',
      required: true,
    },
  }
}
