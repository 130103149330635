import cloneDeep from 'clone-deep'
import { EmailParty, EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const defaultInputs = (): Record<string, InputType> => ({
  subject: { inputType: 'text', title: 'Email Subject', value: '', required: true },
  comment: { inputType: 'TextArea', title: 'Email Comment', value: '', required: true },
})

export const SendEmailDialog = ({
  title = '',
  titleOkay = 'Send Email',
  data = [],
  loadParties = false,
  emailData = { subject: '', comment: '' },
  defaultValues = {},
  onSubmit: _onSubmit,
  onClose,
  errors = [],
  hasSubject = true,
  hasComment = true,
}: {
  title?: string
  titleOkay?: string
  data?: EmailParty[]
  loadParties?: boolean
  emailData?: Record<string, string>
  defaultValues?: Record<string, any>
  errors?: Array<string>
  onSubmit: Function
  onClose: Function
  hasSubject?: boolean
  hasComment?: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState<EmailValue>(defaultValues)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    newInputs.subject.value = emailData.subject
    newInputs.comment.value = emailData.comment

    newInputs.subject.visible = hasSubject
    newInputs.comment.visible = hasComment
    setInputs(newInputs)
  }, [])

  const onChangeInput = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const hasToEmail = !!Object.keys(selectedEmails).filter((email) => selectedEmails[email] == 'to').length
    if (!hasToEmail) {
      toast('Please choose one more TO email.', { type: 'error' })
      return
    }

    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    _onSubmit(data, selectedEmails)
      .then(() => onClose())
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={title || `Send Document on Email`}
      titleOkay={titleOkay}
      isOpen
      loading={loading}
      disabled={!Object.keys(selectedEmails).length || errors.length > 0}
      lastUpdatedAt={Date.now()}
      onClose={() => onClose()}
      onOk={() => onSubmit()}
    >
      <div className="w-144">
        {errors.length > 0 && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15.5px]"
            role="alert"
          >
            <div className="font-semibold mb-2">Following information is required before send:</div>
            {errors.map((error, index) => {
              return (
                <div className="ml-2" key={index}>
                  {index + 1}. {error}
                </div>
              )
            })}
          </div>
        )}
        <LoanPartiesEmailTo value={selectedEmails} data={data} onChange={setSelectedEmails} loadParties={loadParties} />
        {hasSubject && (
          <div className="mb-1">
            <RenderInput input={inputs.subject} Key="subject" onChange={onChangeInput} />
          </div>
        )}
        {hasComment && <RenderInput input={inputs.comment} Key="comment" onChange={onChangeInput} />}
      </div>
    </Modal>
  )
}
