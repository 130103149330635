import cloneDeep from 'clone-deep'
import type { InputSelect, InputType } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { Modal } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

import { floodProductTypes } from './constants'

const requestTypes = ['New Order', 'Reissue', 'Change', 'Cancellation', 'Get Status']

const defaultInputs = (): Record<string, InputType> => ({
  requestType: {
    inputType: 'select',
    title: 'Request Type',
    options: requestTypes,
    value: '',
    hasDefaultOption: true,
    required: true,
  },
  certificationIdentifier: {
    inputType: 'select',
    title: 'Flood Certification Identifier',
    options: [],
    value: '',
    hasDefaultOption: true,
    required: true,
    visible: false,
  },
  productType: {
    inputType: 'radio',
    title: 'Product Type',
    options: floodProductTypes,
    value: '',
    required: true,
    visible: false,
  },
})

export function ServiceLinkOrderModal(props: { certificateNumbers: string[]; onClose: Function; onSubmit: Function }) {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  const isAbleToSubmit = useMemo(() => inputs.certificationIdentifier.value || inputs.productType.value, [inputs])

  useEffect(() => {
    ;(inputs.certificationIdentifier as InputSelect).options = props.certificateNumbers
  }, [])

  const onClose = () => {
    props.onClose()
  }

  const onChange = async (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    if (key === 'requestType') {
      newInputs.productType.visible = value === 'New Order'
      newInputs.certificationIdentifier.value = ''
      newInputs.certificationIdentifier.visible = value !== 'New Order'
      newInputs.productType.value = '26'
    }
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const data: Record<string, any> = {}
    Object.keys(inputs).forEach((key: string) => {
      data[key] = inputs[key].value
    })

    data['certificateNumber'] = data['certificationIdentifier']
    delete data['certificationIdentifier']

    setLoading(true)
    await props.onSubmit(data)
    setLoading(false)
    props.onClose()
    return true
  }

  return (
    <Modal
      button={<span></span>}
      title={'ServiceLink National Flood Order'}
      titleOkay="Submit Order"
      titleCancel="Close"
      loading={loading}
      isOpen
      lastUpdatedAt={Date.now()}
      disabled={!isAbleToSubmit}
      onClose={onClose}
      onOk={onSubmit}
    >
      <div className="">
        <div className="w-196">
          <div className="">
            <RenderInput input={inputs.requestType} Key={'requestType'} onChange={onChange} />
          </div>
          {inputs.certificationIdentifier.visible && (
            <div className="mt-3">
              <RenderInput input={inputs.certificationIdentifier} Key={'certificationIdentifier'} onChange={onChange} />
            </div>
          )}
          {inputs.productType.visible && (
            <div className="mt-5">
              <RenderInput input={inputs.productType} Key={'productType'} onChange={onChange} />
              {inputs.productType.value && (
                <p className="bg-blue-100 text-blue-800 text-sm font-bold p-3 rounded">
                  {(floodProductTypes as any)[inputs.productType.value]}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
