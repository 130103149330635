import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    closingDate: {
      inputType: 'text',
      type: 'date',
      title: 'Closing Date',
      value: '',
      error: '',
      required: true,
    },
    fees: {
      inputType: 'custom',
      title: '',
    },
    titleCompany: {
      inputType: 'text',
      type: 'text',
      title: 'Title Company',
      value: '',
      error: '',
      required: true,
    },
    titleCompanyPointOfContact: {
      inputType: 'text',
      type: 'text',
      title: 'Title Company Point of Contact',
      value: '',
      error: '',
      required: true,
    },
    emailAddressForTitleCompany: {
      inputType: 'text',
      type: 'email',
      title: 'Email Address For Title Company',
      value: '',
      error: '',
      required: true,
    },
    titleAgentPhoneNumber: {
      inputType: 'text',
      type: 'phone',
      title: 'Title Agent Phone Number',
      value: '',
      error: '',
      required: true,
    },
  }
}
