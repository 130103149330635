import { useEffect, useState } from 'react'
import { getTaskCompleteHistory } from 'services'
import { Modal, renderFileList } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { formatTime } from 'utils'

export const StatusTaskModal = (props: { onClose: any; task: Record<string, any>; needOnlyHistory?: boolean }) => {
  const { needOnlyHistory = false } = props
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState<Record<string, any>[]>([])

  useEffect(() => {
    const { task } = props
    if (!task) return

    setLoading(true)
    getTaskCompleteHistory(task.id)
      .then((history) => setHistory(history))
      .finally(() => setLoading(false))
  }, [props.task])

  const onClose = () => {
    props.onClose()
  }

  const renderHistory = () => {
    const header = ['No', 'Action', 'By', 'Date', 'Comment', 'Notifiers', 'Files']
    const data = history.map((item: any, index) => [
      index + 1,
      item.action,
      item.by,
      formatTime(item.createdAt),
      item.comment,
      item.notifiers,
      renderFileList(item.files),
    ])
    return (
      <div className="-mb-6">
        <PlainTable header={header} data={data} tdClass="whitespace-pre-wrap px-6 py-3" />
      </div>
    )
  }

  const title = needOnlyHistory
    ? `Complete Change History Task Number: ${props.task.no}`
    : `Task Details: ${props.task.no}`

  return (
    <Modal title={title} titleOkay="" loading={loading} onClose={onClose} isOpen>
      <div className="max-w-screen-xl">
        <div className="w-full">
          {!needOnlyHistory && (
            <>
              <p className="font-semibold mb-3">Complete Change History</p>
            </>
          )}
          {renderHistory()}
        </div>
      </div>
    </Modal>
  )
}
