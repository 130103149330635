import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs, TaskAssigneeOptions } from './constants'
import { LoanSubmissionActions, LoanSubmissionConfig } from './types'

export const LoanSubmissionDetails = ({
  action,
  data,
  adminEmails,
  onBack,
}: {
  action: string
  data: LoanSubmissionConfig
  adminEmails: string[]
  onBack: Function
}) => {
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      const value = (data as any)[key]
      newInputs[key].value = value
    })
    setInputs(newInputs)
  }, [action])

  const assigneeOptions = useMemo(() => {
    const options = { ...TaskAssigneeOptions }
    adminEmails.forEach((email) => (options[email] = email))
    return options
  }, [adminEmails])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      let { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) return false

    onBack(values)
  }

  return (
    <div className="w-full">
      <div className="text-shade-blue flex items-center mb-2 font-bold">
        <ChevronLeftIcon className="w-4 h-4" />
        <span className="hover:underline cursor-pointer" onClick={() => onBack(null)}>
          Back
        </span>
      </div>

      <div className="flex flex-col gap-4 mb-4">
        <p>Action: {LoanSubmissionActions[action]}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((key) => {
            const input = inputs[key]

            if (key == 'taskAssignee') {
              ;(input as any).options = assigneeOptions
            }

            return (
              <div className={input.span ? `col-span-${input.span}` : ''}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>

      <Button onClick={onSubmit}>Save</Button>
    </div>
  )
}
