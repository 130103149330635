import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { INVALID_ALL_INPUTS } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getFundingDisbursement, saveFundingDisbursement } from 'services'
import Api from 'services/api'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, openAuditLog, removeComma, thousandSeperator } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { fundingDisbursementInputs } from './constant'

export function Disbursement() {
  const [action, setAction] = useState('')
  const [inputs, setInputs] = useState<any>(fundingDisbursementInputs())

  const loanNumber = Api.getLoanNumber()

  const init = async () => {
    setAction('loading')
    const res = await getFundingDisbursement()
    if (res.success) {
      let temp = cloneDeep(inputs)
      Object.keys(temp).map((key) => {
        temp[key].value = res.data[key]
      })
      setInputs(temp)
    }
    setAction('')
  }

  useEffect(() => {
    init()
  }, [])

  const totalAmount = useMemo(
    () => thousandSeperator(removeComma(inputs.facilityAmount.value) + removeComma(inputs.cashAmount.value)),
    [inputs],
  )

  const onChange = async (key: string, e: any) => {
    const value = InputConvert(inputs[key], e)
    const error = InputValidate({ ...inputs[key], value })
    let temp = cloneDeep(inputs)
    temp[key].value = value
    temp[key].error = error
    setInputs(temp)
  }

  const onSubmit = async () => {
    let data: any = {}
    let errors = 0
    let temp = cloneDeep(inputs)
    Object.keys(inputs).map((key) => {
      data[key] = inputs[key].value
      const error = InputValidate({ ...inputs[key] })
      temp[key].error = error
      if (error.length) {
        errors += 1
      }
    })
    setInputs(temp)
    if (errors) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }
    setAction('save')
    await saveFundingDisbursement(data)
    setAction('')
  }

  const onShowHistory = async (key: string) => {
    const options = {
      table: 'FundingDisbursement',
      field: key,
      keys: {
        field: key,
        loanNumber: loanNumber,
      },
    }
    openAuditLog(options)
  }

  return (
    <div className="disbursement-Container relative">
      <LayoutLoading show={action.length > 0} />
      <h1 className="text-2xl font-bold flex items-center">Disbursement Information</h1>
      <div className="mt-6 max-w-[960px]">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            input.history = true
            return (
              <div className="md:col-span-1" key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} showHistory={onShowHistory} />
              </div>
            )
          })}
          <div className="md:col-span-1">
            <RenderInput
              input={{
                inputType: 'text',
                type: 'thousandSep',
                title: 'Total Amount',
                readOnly: true,
                prefix: '$',
                value: totalAmount,
              }}
              Key={'totalAmount'}
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="mt-4 justify-center">
          <Button className="w-[300px]" loading={action == 'save'} onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
