import { setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import moment from 'moment-timezone'
import { visibleLoansLogic } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { getPrice3decimal, removeComma } from 'utils'

export const trackRecordValidate = (hasTrackRecord: any, tracks: any, updateStore = false) => {
  let success = true,
    passedTracks = 0
  const requiredTracks = howManyTrackRecord()

  tracks.map((item: any) => {
    passedTracks += trackStatusCheck(item) ? 1 : 0
  })

  if (hasTrackRecord === undefined || hasTrackRecord === null) success = false
  else if (hasTrackRecord === true) {
    if (passedTracks < requiredTracks) {
      success = false
    }
  } else if (hasTrackRecord === false) {
    if (requiredTracks > 0) success = false
  }

  if (updateStore) {
    const { step } = store.getState()
    let application = cloneDeep(step.application)
    application['track'] = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }
  return success
}

export const howManyTrackRecord = () => {
  let rlt = 0
  const visibleFields = visibleLoansLogic()
  const { loan } = store.getState()
  try {
    if (visibleFields.includes('howManyExperiences')) rlt = Number(loan?.howManyExperiences)
    else if (visibleFields.includes('experience')) rlt = Number(loan.experience?.split(' ')[0])
  } catch {}
  return rlt
}

export const trackStatusCheck = (data: any, withError: boolean = false) => {
  let success = true
  let errors = []

  const { loan, loanDetail } = store.getState()
  const { loanProcessDate } = loanDetail
  const { subjectPropertyAddress } = loan
  const visibleFields = visibleLoansLogic()

  errors.push(`Loan Date: ${loanProcessDate}`)

  if (data.override) {
  } else {
    if (moment(new Date(loanProcessDate)).isBefore(moment(new Date(data.purchaseDate)))) {
      success = false
      errors.push(`Purchase Date must be before Loan Date.`)
    }
    if (data.propertyAddress == subjectPropertyAddress) {
      success = false
      errors.push(`Address cannot be the same as Subject Property Address.`)
    }

    if (data.asRental === false) {
      if (moment(new Date(data.salesDate)).isBefore(moment(new Date(data.purchaseDate)))) {
        success = false
        errors.push(`Purchase Date must be before Sold Date.`)
      }
      if (moment(new Date(loanProcessDate)).isBefore(moment(new Date(data.salesDate)))) {
        success = false
        errors.push(`Sold Date must be before Loan Date.`)
      }

      if (moment(new Date(loanProcessDate)).isAfter(moment(new Date(data.salesDate)).add(36, 'months'))) {
        success = false
        errors.push(`Sold Date must be within 36 months of Loan Date.`)
      }
      if (moment(new Date(data.salesDate)).isAfter(moment(new Date(data.purchaseDate)).add(24, 'months'))) {
        success = false
        errors.push(`Purchase Date must be within 24 months of Sold Date.`)
      }

      if (visibleFields.includes('afterRepairValue')) {
        if (removeComma(data.salesPrice) / removeComma(loan.afterRepairValue) < 0.75) {
          success = false
          errors.push(`Sold Price, cannot be less than 75% of After Repair Value.`)
          errors.push(`After Repair Value: $${getPrice3decimal(loan.afterRepairValue)}`)
        }
      }
    }
  }
  if (withError) {
    return {
      success,
      errors,
    }
  }
  return success
}
