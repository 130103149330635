import type { InputType } from 'config/input.type.constants'
import type { FormTableHeader } from 'stories/components'

export const originatorLockFields: Record<string, Record<string, InputType>> = {
  leftGroup: {
    LockDays: {
      title: 'Lock Days',
      inputType: 'text',
      type: 'number',
      span: 2,
    },
    LockFloating: {
      title: 'Floating',
      inputType: 'CheckBox',
      span: 1,
    },
    LockExtension1Days: {
      title: 'Lock Ext1',
      inputType: 'text',
      type: 'number',
      span: 1,
    },
    LockExtension2Days: {
      title: 'Lock Ext2',
      inputType: 'text',
      type: 'number',
      span: 1,
    },
    LockExtension3Days: {
      title: 'Lock Ext3',
      inputType: 'text',
      type: 'number',
      span: 1,
    },
    LockStartDate: {
      title: 'Date Locked',
      inputType: 'text',
      type: 'date',
    },
    LockExpirationDate: {
      title: 'Lock Expiration Date',
      inputType: 'text',
      type: 'date',
    },
    LockCanceledDate: {
      title: 'Date Cancelled',
      inputType: 'text',
      type: 'date',
    },
  },
  rightGroup: {
    UnextendedLockExpDate: {
      title: 'Original Lock Exp Date',
      inputType: 'text',
      type: 'date',
    },
    BuyCommitmentNo: {
      title: 'Originator Commitment No',
      inputType: 'text',
    },
    LockStartDate: {
      title: 'Regulatory Lock Date (Calculated)',
      inputType: 'text',
      type: 'date',
      readOnly: true,
    },
    RegulatoryLockDateOV: {
      title: 'Regulatory Lock Date (Override)',
      inputType: 'text',
      type: 'date',
    },
  },
}

export const investorCommitmentFields: Record<string, Record<string, InputType>> = {
  leftGroup: {
    InvestorCode: {
      title: 'Investor',
      inputType: 'select',
      options: [],
      hasDefaultOption: true,
      sort: true,
    },
    MandatoryInvestor: {
      title: 'Mandatory Investor',
      inputType: 'select',
      options: [],
      hasDefaultOption: true,
      sort: true,
    },
    ExcludedInvestor: {
      title: 'Excluded Investor',
      inputType: 'select',
      options: [],
      hasDefaultOption: true,
      sort: true,
    },
    InvestorCommitmentType: {
      title: 'Investor Commitment Type',
      inputType: 'select',
      options: {
        0: '',
        1: 'Best Efforts',
        2: 'Single Loan Mandatory',
        3: 'Direct Trade',
        4: 'AOT',
        5: 'Co Issue',
        6: 'Securitization',
        7: 'Bulk Trade',
      },
      hasDefaultOption: true,
    },
    InvestorCommitmentNo: {
      title: 'Investor Commitment No',
      inputType: 'text',
    },
    MasterCommitmentNo: {
      title: 'Master Commitment No',
      inputType: 'text',
    },
    InvestorLoanProgramCode: {
      title: 'Investor Program Name',
      inputType: 'text',
    },
    InvestorLoanNo: {
      title: 'Investor Loan No',
      inputType: 'text',
    },
  },
  rightGroup: {
    InvestorRegisteredDate: {
      title: 'Registered Date',
      inputType: 'text',
      type: 'date',
    },
    InvestorLockDays: {
      title: 'Commitment Days',
      inputType: 'text',
      type: 'number',
    },
    InvestorLockExtension1Days: {
      title: 'Lock Ext1',
      inputType: 'text',
      type: 'number',
      span: 1,
    },
    InvestorLockExtension2Days: {
      title: 'Lock Ext2',
      inputType: 'text',
      type: 'number',
      span: 1,
    },
    InvestorLockExtension3Days: {
      title: 'Lock Ext3',
      inputType: 'text',
      type: 'number',
      span: 1,
    },
    InvestorCommitmentDate: {
      title: 'Commitment Date',
      inputType: 'text',
      type: 'date',
    },
    InvestorCommitmentExpirationDate: {
      title: 'Expiration Date',
      inputType: 'text',
      type: 'date',
    },
    ShipByDate: {
      title: 'Ship By Date',
      inputType: 'text',
      type: 'date',
    },
    InvestorDueDate: {
      title: 'Delivery Due Date',
      inputType: 'text',
      type: 'date',
    },
    CorrPurchaseByDate: {
      title: 'Purchase By Date',
      inputType: 'text',
      type: 'date',
    },
    Hedged: {
      title: 'Commited Hedge',
      inputType: 'checkbox',
    },
    HedgeCancellationDate: {
      title: 'Hedge Cancellation Date',
      inputType: 'text',
      type: 'date',
    },
  },
}

export const buyPricingFields: Record<string, InputType> = {
  RateSheetID: {
    title: 'Rate Sheet ID',
    inputType: 'text',
  },
  PPETimePriced: {
    title: 'Time Priced',
    inputType: 'text',
    type: 'date',
  },
  PricingLastUpated: {
    title: 'Pricing Last Updated',
    inputType: 'text',
    type: 'date',
  },
  BuyPricedInvestor: {
    title: 'Priced Investor',
    inputType: 'select',
    options: [],
    hasDefaultOption: true,
    sort: true,
  },
  PPEPricedProductName: {
    title: 'Priced Product',
    inputType: 'text',
  },
}

export const sellPricingFields: Record<string, InputType> = {
  InvestorRateSheetID: {
    title: 'Rate Sheet ID',
    inputType: 'text',
  },
  InvestorPPETimePriced: {
    title: 'Time Priced',
    inputType: 'text',
    type: 'date',
  },
  InvestorPricingLastUpated: {
    title: 'Pricing Last Updated',
    inputType: 'text',
    type: 'date',
  },
  PricedInvestor: {
    title: 'Priced Investor',
    inputType: 'select',
    options: [],
    hasDefaultOption: true,
    sort: true,
  },
  InvestorPPEPricedProductName: {
    title: 'Priced Product',
    inputType: 'text',
  },
}

export const priceHeader: FormTableHeader[] = [
  { key: 'Description', title: 'Description' },
  { key: 'MarginPercent', title: 'Margin Percent' },
  { key: 'IntRatePercent', title: 'Rate Percent' },
  { key: 'PricePercent', title: 'Price Percent' },
  { key: 'FeeAmount', title: 'Fee Amount' },
  { key: 'PriceAdjustmentType', title: 'Type' },
]

export const defaultPricingAdjustmentInputs = (): Record<string, InputType> => {
  return {
    Description: {
      inputType: 'text',
      title: 'Description',
      required: true,
    },
    PriceAdjustmentType: {
      title: 'Type',
      inputType: 'select',
      options: {
        0: 'Standard Price Adjustment',
        1: 'SRP Price Adjustment',
      },
      hasDefaultOption: true,
      required: true,
    },
    PricePercent: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Price Adjustment',
      required: true,
      prefix: '%',
    },
    IntRatePercent: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Rate Adjustment',
      required: true,
      prefix: '%',
    },
    MarginPercent: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Margin Adjustment',
      required: true,
      prefix: '%',
    },
    FeeAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Fee',
      required: true,
      prefix: '$',
    },
    Notes: {
      inputType: 'text',
      title: 'Notes',
      span: 2,
    },
  }
}

export const markupHeader: FormTableHeader[] = [
  { key: 'Description', title: 'Description' },
  { key: 'MarkupPercent', title: 'Markup Percent' },
  { key: 'MarkupAmount', title: 'Markup Amount' },
]

export const defaultMarkupInputs = (): Record<string, InputType> => {
  return {
    Description: {
      inputType: 'text',
      title: 'Description',
      required: true,
      span: 2,
    },
    MarkupPercent: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Markup Percent',
      required: true,
      prefix: '%',
    },
    MarkupAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Markup Amount',
      required: true,
      prefix: '$',
    },
  }
}
