import {
  API_LOAN_AMORTIZATION_SCHEDULE,
  API_LOAN_FUNDING_DISBURSEMENT,
  API_LOAN_FUNDING_DISBURSEMENT_LOG,
} from 'config'
import Api from 'services/api'

export const getAmortizationSchedule = () => {
  return Api.get(API_LOAN_AMORTIZATION_SCHEDULE)
}

export const saveFundingDisbursement = (data: any) => {
  return Api.post(API_LOAN_FUNDING_DISBURSEMENT, data)
}

export const getFundingDisbursement = () => {
  return Api.get(API_LOAN_FUNDING_DISBURSEMENT)
}

export const getFundingDisbursementLogs = (key: string) => {
  return Api.get(API_LOAN_FUNDING_DISBURSEMENT_LOG, {}, { key })
}
