import { InputType, loanOptionsConstants } from 'config'
import type { FormTableHeader } from 'stories/components'

export const header: FormTableHeader[] = [
  {
    key: 'propertyTypeAddress',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Property Type</div>
        Property Address
      </span>
    ),
  },
  {
    key: 'purchasePriceDate',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Purchase Price</div>
        Purchase Date
      </span>
    ),
  },
  {
    key: 'renovationBudget',
    title: (
      <span>
        Renovation
        <br />
        Budget
      </span>
    ),
  },
  {
    key: 'asRental',
    title: (
      <span>
        Retained <br />
        As Rental?
      </span>
    ),
  },
  {
    key: 'salesPriceDate',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Sold Price</div>
        Sold Date
      </span>
    ),
  },
  {
    key: 'status',
    title: 'Status',
  },
]

export const hasRecordInputs = (): Record<string, InputType> => {
  return {
    hasTrackRecord: {
      inputType: 'check',
      title: 'Has Track Record?',
      required: true,
      history: true,
    },
  }
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    propertyAddress: {
      inputType: 'map',
      title: 'Property Address',
      required: true,
    },
    propertyType: {
      inputType: 'select',
      title: 'Property Type',
      options: loanOptionsConstants.propertyTypeBigList,
      hasDefaultOption: true,
      required: true,
    },
    purchasePrice: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Purchase Price',
      required: true,
      prefix: '$',
    },
    purchaseDate: {
      inputType: 'text',
      type: 'date',
      title: 'Purchase Date',
      required: true,
    },
    renovationBudget: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Renovation Budget',
      required: true,
      prefix: '$',
    },
    asRental: {
      inputType: 'Check',
      title: 'Retained As Rental?',
      required: true,
    },
    salesPrice: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Sold Price',
      required: true,
      prefix: '$',
    },
    salesDate: {
      inputType: 'text',
      type: 'date',
      title: 'Sold Date',
      required: true,
    },
    additionalNotes: {
      span: 2,
      inputType: 'textarea',
      title: 'Additional Notes',
      rows: 2,
      required: false,
    },
    override: {
      inputType: 'toggle',
      title: 'Override Validation',
    },
  }
}
