import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { getTrustLedgerAdditionalInfo, updateTrustLedgerAdditional } from 'services'
import { InputConvert, InputValidate, openAuditLog, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from './constants'

export const Payoff = () => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [fieldValueChanged, setFieldValueChanged] = useState(false)
  const loanNumber = setLoanNumber()

  useEffect(() => {
    setLoading(true)
    init()
  }, [])

  const init = async () => {
    const data = await getTrustLedgerAdditionalInfo()
    let newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = data[key] || ''
    })
    setInputs(newInputs)
    setLoading(false)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)

    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])

    setInputs(newInputs)
    setFieldValueChanged(true)
  }

  const onUpdate = async (key: string) => {
    if (!fieldValueChanged) return
    setFieldValueChanged(false)
    const input: any = inputs[key]
    if (input.error && input.error.length > 0) return
    setLoading(true)
    let value = inputs[key].value
    if (key === 'payoffAmount') value = removeComma(value)
    const reqBody = {
      [key]: value,
    }
    await updateTrustLedgerAdditional(reqBody)
    setLoading(false)
  }

  const onAdditionalShowHistory = (key: string) => {
    const options = {
      table: 'TrustLedger',
      field: key,
      keys: {
        field: key,
        loanNumber,
      },
    }
    openAuditLog(options)
  }

  return (
    <div className="TrustLedger-container">
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white">
          <LayoutLoading show={loading} />
          <div className={`grid gap-4 md:grid-cols-4 grid-cols-1 mb-3`}>
            {Object.keys(inputs).map((key, index) => {
              const input: any = cloneDeep(inputs[key])
              if (input.visible === false) return null
              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput
                    input={input}
                    Key={key}
                    onChange={onChange}
                    onBlur={onUpdate}
                    showHistory={() => onAdditionalShowHistory(key)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
