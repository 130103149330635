import { AddNote } from 'pages/Loan'
import { useEffect, useState } from 'react'
import { addNewNote, getNotes } from 'services'
import Api from 'services/api'
import { Button, Modal } from 'stories/components'
import { formatTime } from 'utils'

export const ViewNoteDialog = ({
  loanNumber,
  loanFileName,
  onClose,
}: {
  loanNumber: number
  loanFileName: number
  onClose: Function
}) => {
  const [data, setData] = useState<Record<string, any>[]>([])
  const [loading, setLoading] = useState(true)
  const [isAddNote, setAddNote] = useState(false)

  useEffect(() => {
    refetch()
  }, [])

  const refetch = () => {
    setLoading(true)
    Api.setLoanNumber(`${loanNumber}`)
    getNotes({
      orderBy: 'createdAt',
      orderDir: '-1',
    })
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  const onAddNote = async (content: string) => {
    setLoading(true)
    await addNewNote(content)
    await refetch()
    setAddNote(false)
  }

  return (
    <>
      <Modal title={`Notes - ${loanFileName}`} titleOkay="" onClose={() => onClose()} isOpen={true} loading={loading}>
        <div className="text-gray-700 w-196">
          <div className="flex justify-end -mt-6">
            <Button link onClick={() => setAddNote(true)} className="text-shade-blue my-[0px]">
              + Add Note
            </Button>
          </div>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-2 py-3">
                  No
                </th>
                <th scope="col" className="px-2 py-3">
                  Note By
                </th>
                <th scope="col" className="px-2 py-3">
                  Content
                </th>
                <th scope="col" className="px-2 py-3">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((value, index) => (
                <tr key={index} className="border-b">
                  <td className="px-2 py-1">{index + 1}</td>
                  <td className="px-2 py-1">{value.noteBy}</td>
                  <td className="px-2 py-1">
                    <span dangerouslySetInnerHTML={{ __html: value.content }} />
                  </td>
                  <td className="px-2 py-1">{formatTime(value.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
      {isAddNote && (
        <AddNote
          index={0}
          item={null}
          onClose={() => setAddNote(false)}
          onUpdate={(content: string) => onAddNote(content)}
        />
      )}
    </>
  )
}
