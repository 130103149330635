import cloneDeep from 'clone-deep'
import { type InputType, loanOptionsConstants } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { InputConvert } from 'utils'

import { renderActions } from '../components'
import type { IBorrowerLoanRequestStepProps } from './interface'
import { renderInputs, validateForm } from './util'

const defaultInputs = (): Record<string, InputType> => {
  return {
    experience: {
      inputType: 'select',
      options: {
        '0': '0 Transaction',
        '1': '1 Transaction',
        '2': '2 Transactions',
        '3': '3 Transactions',
        '4': '4 Transactions',
        '5': '5+ Transactions',
      },
      hasDefaultOption: true,
      title: 'How would you describe your experience as an investor?',
      required: true,
    },
    propertyCounts: {
      inputType: 'text',
      type: 'number',
      title: '# of properties sold over the last 36 months',
      required: true,
      visible: (inputs: Record<string, InputType>) => Number(inputs.experience.value) > 0,
    },
    recentIntendStates: {
      inputType: 'multiselect',
      title: 'In which states do you intend to focus on the next 12-24 months?',
      options: loanOptionsConstants.states,
      required: true,
      defaultSelected: false,
    },
    approximateLiquidity: {
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      title: 'Approximate Liquidity (Checking, Savings, Investment Accounts)',
      required: true,
    },
    // estimatedCreditScore: {
    //   title: 'What is your estimated credit score?',
    //   inputType: 'text',
    //   type: 'creditScore',
    //   error: '',
    //   required: true,
    // },
    estimatedCreditScore: {
      title: 'What is your estimated credit score?',
      inputType: 'select',
      options: {
        '800': '800+',
        '760': '760-799',
        '720': '720-759',
        '680': '680-719',
        '640': '640-679',
        '600': '600-639',
        '599': '599 or Below',
        '0': 'Foreign National - No Score',
      },
      error: '',
      hasDefaultOption: true,
      required: true,
    },

    section1: {
      title: 'Please select Yes or no on the following',
      inputType: 'section',
    },
    hasBankruptcy: {
      inputType: 'check',
      title: 'Have you ever been in bankruptcy?',
      required: true,
      textLeft: true,
    },
    bankruptcy: {
      title: 'What is the Status of bankruptcy?',
      inputType: 'select',
      hasDefaultOption: true,
      options: {
        ...loanOptionsConstants.loannex1,
        None: null,
      } as any,
      visible: (inputs: Record<string, InputType>) => inputs.hasBankruptcy.value,
      required: true,
    },

    hasForeclosure: {
      inputType: 'check',
      title: 'Have you ever had a foreclosure proceeding?',
      required: true,
      textLeft: true,
    },
    foreclosure: {
      title: 'How long Ago?',
      inputType: 'select',
      hasDefaultOption: true,
      options: {
        ...loanOptionsConstants.loannex2,
        None: null,
      } as any,
      visible: (inputs: Record<string, InputType>) => inputs.hasForeclosure.value,
      required: true,
    },

    hasDeedInLieu: {
      inputType: 'check',
      title: 'Have you ever had a deed-in-leu?',
      required: true,
      textLeft: true,
    },
    deedInLieu: {
      title: 'How long Ago?',
      inputType: 'select',
      hasDefaultOption: true,
      options: {
        ...loanOptionsConstants.loannex2,
        None: null,
      } as any,
      visible: (inputs: Record<string, InputType>) => inputs.hasDeedInLieu.value,
      required: true,
    },

    hasShortSale: {
      inputType: 'check',
      title: 'Have you ever had a short sale?',
      required: true,
      textLeft: true,
    },
    shortSale: {
      title: 'How long Ago?',
      inputType: 'select',
      hasDefaultOption: true,
      options: {
        ...loanOptionsConstants.loannex2,
        None: null,
      } as any,
      visible: (inputs: Record<string, InputType>) => inputs.hasShortSale.value,
      required: true,
    },

    elseBackground: {
      inputType: 'textarea',
      title: 'Is there anything else in your background that we should be aware of?',
      required: true,
    },

    hasMortgageLates: {
      inputType: 'check',
      title: 'Have you had any mortgage lates in the last 24 months?',
      required: true,
      textLeft: true,
    },
    mortgageLates: {
      title: 'Payment History',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanOptionsConstants.fullMortgageLates,
      visible: (inputs: Record<string, InputType>) => inputs.hasMortgageLates.value,
      required: true,
    },
  }
}

export const LoanOptions = ({
  values = {},
  allValues = {},
  onSubmit: _onSubmit,
  onBack,
}: IBorrowerLoanRequestStepProps) => {
  const { profile } = useSelector((state: any) => ({
    profile: state.auth.profile,
  }))
  const [inputs, setInputs] = useState<Record<string, InputType>>({})

  useEffect(() => {
    setValues(values || {})
  }, [values])

  const setValues = (data: Record<string, any>) => {
    const newInputs = cloneDeep(defaultInputs())
    Object.keys(newInputs).map((key) => {
      const input = newInputs[key]
      if (data[key] !== undefined) input.value = data[key]
    })
    setInputs(newInputs)
  }

  const onChange = (key: string, value: string) => {
    if (inputs[key].value === value) return

    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const { hasError, data } = validateForm({
      inputs,
      setInputs,
      allValues,
    })
    if (hasError) return

    _onSubmit(data)
  }

  const renderName = () => {
    const { firstName, lastName } = allValues
    return profile?.fullName || `${firstName} ${lastName}`
  }

  return (
    <div>
      <p className="mb-6">
        Looking good <b>{renderName()}</b>! Almost there. Lets grab some additional information!
      </p>

      {renderInputs({ inputs, allValues, onChange })}

      {renderActions(onBack, onSubmit)}
    </div>
  )
}
