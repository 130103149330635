import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { accountTypes } from 'components/Modals/CreateUser/config'
import { AccountType, itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { openS3Document } from 'services'
import { createBroadcastEmail, getBroadcastEmails, getSetting, updateSetting } from 'services/apis/admin'
import { svgLoading } from 'stories/assets'
import { Button, Input2, Pagination } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { confirm, formatTime, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'

export const CompanyBroadcastSettingKey = 'COMPANY_BROADCAST'

export interface BroadCastEmailItem {
  name: string
  email: string
  accountType: AccountType
  length: number
}

export interface BroadcastEmail {
  accountType: string[]
  emails: BroadCastEmailItem[]
  title: string
  content: string
  createdAt: string
  fileKey: string
}

export const CompanyBroadcast = () => {
  const [action, setAction] = useState('')
  const [content, setContent] = useState('')
  const [inputs, setInputs] = useState(defaultInputs())
  const [history, setHistory] = useState<BroadcastEmail[]>([])
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setAction('setting')
    reset()
    getSetting(CompanyBroadcastSettingKey)
      .then(async ({ value }) => {
        setContent(value)
      })
      .finally(() => setAction(''))
  }, [])

  useEffect(() => {
    fetchHistory()
  }, [pageNum])

  const fetchHistory = async () => {
    setAction('history')
    const { data, total } = await getBroadcastEmails({
      skip: pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
    setHistory(data)
    setTotal(total)
    setAction('')
  }

  const reset = () => {
    const newInputs = cloneDeep(inputs)
    for (const key in inputs) newInputs[key].value = ''
    newInputs.accountType.value = {}
    Object.keys(accountTypes).forEach((type) => {
      newInputs.accountType.value[type] = false
    })
    setInputs(newInputs)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const historyTable = history.map((item, index) => [
    pageNum * itemCountPerPage + index + 1,
    <p>
      {!item.emails.length
        ? item.accountType.map((type) => accountTypes[type]).join(', ')
        : `${accountTypes[item.emails[0].accountType]} BroadCast -> ${item.emails[0].name} (${
            item.emails[0].length
          } emails)`}
    </p>,
    <div>
      <div className="border-b font-semibold mb-2 flex items-center gap-2 justify-between">
        {item.title}
        {item.fileKey && (
          <span
            className="flex cursor-pointer items-center gap-1 font-normal hover:underline text-shade-blue"
            onClick={() => openS3Document(item.fileKey)}
          >
            File
            <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
          </span>
        )}
      </div>
      {item.content}
    </div>,
    formatTime(item.createdAt),
  ])

  const onChange = (value: string) => {
    setContent(value)
  }

  const onSubmit = async () => {
    setAction('setting')
    await updateSetting(CompanyBroadcastSettingKey, content)
    setAction('')
    toast('Company Broadcast is saved.', { type: 'info' })
  }

  const onChangeForm = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSendEmail = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    data.accountType = Object.keys(data.accountType).filter((key) => data.accountType[key])
    const content = (
      <div>
        <div className="font-semibold mb-4">Are you sure you want to email the following?</div>
        <div className="text-left mb-5">
          <div className="border-b">- Account Types</div>
          <div className="mt-3 flex flex-wrap gap-2 ml-3 italic text-gray-600 text-[14px]">
            {data.accountType.map((type: string, index: number) => {
              return <div key={index}>{accountTypes[type]}, </div>
            })}
          </div>
        </div>
      </div>
    )
    const isConfirmed = await confirm(content)
    if (!isConfirmed) return

    setAction('history')

    createBroadcastEmail(data)
      .then(async () => {
        await fetchHistory()
        reset()
      })
      .finally(() => setAction(''))
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Company Broadcast
        {action && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h1>

      <div className="relative mb-6">
        <div className="mb-4">
          <Input2 title="Content" type="text" value={content} onChange={(value) => onChange(value)} />
        </div>
        <Button className="w-[300px]" loading={action == 'setting'} onClick={onSubmit}>
          Save
        </Button>
      </div>

      <div className="shadow1 p-4 rounded">
        <div className="border-b font-bold mb-4">Send Emails</div>
        <div className="grid gap-4 md:grid-cols-12">
          <div className="md:col-span-7 gap-4 mb-3 flex flex-col">
            {Object.keys(inputs).map((key, index) => {
              if (key === 'file') return null
              let input = inputs[key]

              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChangeForm} />
                </div>
              )
            })}
          </div>
          <div className={`md:col-span-5 input md:p-5`}>
            <RenderInput input={inputs.file} Key="file" onChange={onChangeForm} />
          </div>
        </div>
        <div className="mb-2">
          <Button className="w-[300px]" loading={action == 'history'} onClick={onSendEmail}>
            Send Email
          </Button>
        </div>

        <div className="-mb-4 mt-5">
          <div className="-mb-4">
            <PlainTable
              header={[
                'No',
                'Users',
                `<span class="border-b border-b-gray-300">Title / File </span><br/>Content`,
                'Created At',
              ]}
              data={historyTable}
              tdClass="px-4 py-2 break-spaces"
            />
          </div>
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
