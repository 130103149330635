import { LayoutLoading } from 'components/LayoutLoading'
import { TextHighlighter } from 'components/TextHighlighter'
import { useEffect, useState } from 'react'
import { userSearchAutoComplete } from 'services'
import { Checkbox } from 'stories/components'

const filterUserTypes: any = {
  all: 'All',
  broker: 'Counterparty',
  internal: 'Internal',
}

export const UserSearch = ({ query, onSelect }: { query: string; onSelect: Function }) => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<Array<any>>([])
  const [total, setTotal] = useState(0)
  const [type, setType] = useState('all')
  const [filterQuery, setFilterQuery] = useState('')

  useEffect(() => {
    setFilterQuery(query)
  }, [query])

  useEffect(() => {
    const timeOutId = setTimeout(() => !loading && filterUsersData(query, type), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterUsersData = async (search: string, type: string) => {
    if (search.length < 3) return
    setLoading(true)
    const res = await userSearchAutoComplete({ search, type })
    setLoading(false)
    if (res.success) {
      setUsers(res.users)
      setTotal(res.total)
    }
  }

  const onChangeType = (type: string, value: boolean) => {
    if (loading) return
    if (value) setType(type)
    else setType('all')
    filterUsersData(query, type)
  }

  return (
    <div className="UserSearch-container relative border-l border-b border-r p-1 shadow">
      <LayoutLoading show={loading} />
      {users.map((user: any, index: number) => {
        return (
          <div
            className={`p-[2px] pl-1 cursor-pointer hover:bg-gray-200 flex gap-3`}
            key={index}
            onClick={() => onSelect(user.value)}
          >
            <span>{index + 1}.</span>
            <TextHighlighter searchText={query} text={user.label} />
          </div>
        )
      })}
      <div className="ml-1 border-t mt-1">
        <div className="flex mt-1 gap-4">
          {Object.keys(filterUserTypes).map((key: string) => {
            return (
              <Checkbox
                className={`w-fit`}
                id={key}
                title={filterUserTypes[key]}
                key={key}
                size={3}
                value={key === type}
                onChange={(value) => onChangeType(key, value)}
              />
            )
          })}
        </div>
        <div className="flex gap-4 justify-between ">
          <span className="italic">
            showing {users.length} users of {total}
          </span>
        </div>
      </div>
    </div>
  )
}
