import type { ICrossCollateralBlanket } from 'pages/LoanStructure/interfaces'

export interface ILtvValues {
  aivLtv: number
  arvLtv: number
  ltc: number
  ltp: number
}

export interface IRatePriceAdjustmentValue {
  rate: number
  price: number
}

export interface IRange {
  from: number
  to: number
}

export interface ILabelValue {
  label: string
  value: string
}

export interface IRatePriceAdjustment {
  purchase: IRatePriceAdjustmentValue[]
  nocashout: IRatePriceAdjustmentValue[]
  cashout: IRatePriceAdjustmentValue[]
}

export interface IMaxCashToBorrowerLeverageLimit {
  ltv: IRange
  maxValue: number
  loanAmount: IMinMaxLimit
  minDSCR: number
  minFICO: number
}

export interface INumberOfPropertiesLimit {
  min: number
  max: number
}

export interface IMinMaxLimit {
  min: number
  max: number
}

export interface INonDscrLTVs {
  aivLtv: number
  arvLtv: number
  ltc: number
  ltp: number
  cltv: number
}

export interface ISecondLienLimit {
  maxCLTV: number
  maxSecondLien: number
}

// Non-DSCR leverage limit interfaces
export interface IExperienceLtvMaxLimits {
  from: number
  to: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IPropertyTypeLeverageData {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: INonDscrLTVs
      nocashout: INonDscrLTVs
      cashout: INonDscrLTVs
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface ICitizenshipLtvMaxLimits {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    minExperience: number
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IValueLeverageLimit {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IFirstTimeInvestorValueLeverageLimit {
  value: string
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IFirstTimeInvestorLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
  mortgageLates: IFirstTimeInvestorValueLeverageLimit[]
  fcSsDil: IFirstTimeInvestorValueLeverageLimit[]
  bankruptcy: IFirstTimeInvestorValueLeverageLimit[]
}

export interface IFirstTimeInvestorLeverageData {
  label: string
  limits: IFirstTimeInvestorLimit[]
}

export interface IRuralPropertyLeverageLimit {
  value: string
  loanAmount: number
  minFICO: number
  limits: {
    FICO: IRange
    propertyTypes: string[]
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IRehabBudgetTypeLimit {
  expRange: IRange
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: ILtvValues
      nocashout: ILtvValues
      cashout: ILtvValues
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IRehabBudgetTypeLtvMaxLimits {
  value: string
  limits: IRehabBudgetTypeLimit[]
}

export interface IFicoLeverageLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  LTV: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface ILoanAmountLeverageLimit {
  loanAmount: IRange
  LTV: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
}

export interface ILeverageMaxLimits {
  experienceLtvLimits: IExperienceLtvMaxLimits[]
  propertyTypeLtvLimits: IPropertyTypeLeverageData[]
  occupancyLeverageLimit: IValueLeverageLimit[]
  bankruptcyLeverageMaxLimit: IValueLeverageLimit[]
  fcSsDilLeverageMaxLimit: IValueLeverageLimit[]
  mortgageLatesLtvLimits: IValueLeverageLimit[]
  forbearanceLeverageLimit: IValueLeverageLimit[]
  decliningMarketPropertyLeverageLimit: IValueLeverageLimit[]
  newConstructionPropertyLeverageLimit: IValueLeverageLimit[]
  citizenshipLtvLimits: ICitizenshipLtvMaxLimits[]
  ruralPropertyLeverageMaxLimit: IRuralPropertyLeverageLimit[]
  rehabBudgetTypeLtvLimits: IRehabBudgetTypeLtvMaxLimits[]
  ficoLeverageLimit: IFicoLeverageLimit[]
  firstTimeInvestorLeverageMaxLimit: IFirstTimeInvestorLeverageData[]
  loanAmountLeverageLimit: ILoanAmountLeverageLimit[]
  maxCashToBorrowerLeverageMaxLimit: IMaxCashToBorrowerLeverageLimit[]
  crossCollateralBlanket: ICrossCollateralBlanket
  secondLienMaxLimit: ISecondLienLimit
}

// DSCR leverage limit interfeaces
export interface IDscrLTVs {
  ltv: IMinMaxLimit
  cltv: number
}

export interface IDscrPropertyTypeLeverageData {
  value: string
  loanAmount: number
  LTV: number
  CLTV: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: IDscrLTVs
      nocashout: IDscrLTVs
      cashout: IDscrLTVs
    }
    minDSCR: {
      purchase: number
      nocashout: number
      cashout: number
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IDscrFicoLeverageLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  LTV: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minDSCR: {
    purchase: number
    nocashout: number
    cashout: number
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrValueLeverageLimit {
  value: string
  loanAmount: number
  LTV: number
  CLTV: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: IDscrLTVs
      nocashout: IDscrLTVs
      cashout: IDscrLTVs
    }
    minDSCR: {
      purchase: number
      nocashout: number
      cashout: number
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
  }[]
}

export interface IDscrRuralPropertyLeverageLimit {
  value: string
  loanAmount: number
  LTV: number
  CLTV: number
  minFICO: number
  limits: {
    FICO: IRange
    loanAmount: {
      purchase: IMinMaxLimit
      nocashout: IMinMaxLimit
      cashout: IMinMaxLimit
    }
    LTV: {
      purchase: IDscrLTVs
      nocashout: IDscrLTVs
      cashout: IDscrLTVs
    }
    minDSCR: {
      purchase: number
      nocashout: number
      cashout: number
    }
    minMonthsReserve: {
      purchase: number
      nocashout: number
      cashout: number
    }
    propertyTypes: string[]
  }[]
}

export interface IDscrFirstTimeInvestorValueLeverageLimit {
  value: string
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minDSCR: {
    purchase: number
    nocashout: number
    cashout: number
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrFirstTimeInvestorLimit {
  ficoRange: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minDSCR: {
    purchase: number
    nocashout: number
    cashout: number
  }
  minMonthsReserve: {
    purchase: number
    nocashout: number
    cashout: number
  }
  mortgageLates: IDscrFirstTimeInvestorValueLeverageLimit[]
  fcSsDil: IDscrFirstTimeInvestorValueLeverageLimit[]
  bankruptcy: IDscrFirstTimeInvestorValueLeverageLimit[]
}

export interface IDscrFirstTimeInvestorLeverageData {
  label: string
  limits: IDscrFirstTimeInvestorLimit[]
}

export interface IInterestOnlyLimit {
  fico: IRange
  minDscr: number
  minMonthsReserve: number
  minLoanAmount: number
  maxLoanAmount: number
  minLTV: number
  maxLTV: number
  maxCLTV: number
}

export interface IDscrInterestOnlyLeverageLimit {
  purchase: IInterestOnlyLimit[]
  nocashout: IInterestOnlyLimit[]
  cashout: IInterestOnlyLimit[]
}

export interface IDscrRangeLeverageLimit {
  dscr: IRange
  loanAmount: {
    purchase: IMinMaxLimit
    nocashout: IMinMaxLimit
    cashout: IMinMaxLimit
  }
  limit: {
    purchase: IDscrLTVs
    nocashout: IDscrLTVs
    cashout: IDscrLTVs
  }
  minFICO: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrLoanAmountLeverageLimit {
  loanAmount: IRange
  LTV: {
    purchase: number
    nocashout: number
    cashout: number
  }
  CLTV: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrLeverageMaxLimits {
  OccupancyLeverageLimit: IDscrValueLeverageLimit[]
  PropertyTypeLtvMaxLimit: IDscrPropertyTypeLeverageData[]
  EscrowTypeLeverageLimit: IDscrValueLeverageLimit[]
  CitizenshipLtvMaxLimit: IDscrValueLeverageLimit[]
  MortgageLatesLtvMaxLimit: IDscrValueLeverageLimit[]
  FcSsDilLeverageMaxLimit: IDscrValueLeverageLimit[]
  BankruptcyLeverageMaxLimit: IDscrValueLeverageLimit[]
  ForbearanceLeverageLimit: IDscrValueLeverageLimit[]
  IsShortTermRentalLeverageLimit: IDscrValueLeverageLimit[]
  DecliningMarketPropertyLeverageLimit: IDscrValueLeverageLimit[]
  NewConstructionPropertyLeverageLimit: IDscrValueLeverageLimit[]
  RuralPropertyLeverageMaxLimit: IDscrRuralPropertyLeverageLimit[]
  FirstTimeInvestorLeverageMaxLimit: IDscrFirstTimeInvestorLeverageData[]
  InterestOnlyLeverageLimit: IDscrInterestOnlyLeverageLimit
  FICOLeverageLimit: IDscrFicoLeverageLimit[]
  DscrLeverageLimit: IDscrRangeLeverageLimit[]
  LoanAmountLeverageLimit: IDscrLoanAmountLeverageLimit[]
  MaxCashToBorrowerLeverageMaxLimit: IMaxCashToBorrowerLeverageLimit[]
  CrossCollateralBlanket: ICrossCollateralBlanket
  SecondLienMaxLimit: ISecondLienLimit
}

export interface IValueLeverageData {
  value: string
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
}

export interface IRangeLeverageAdjustment {
  range: IRange
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
}

export interface IFicoLeverageAdjustment {
  range: IRange
  limit: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  propertyTypes: string[]
}

export interface ILeverageAdjustments {
  occupancyLeverageAdjustment: IValueLeverageData[]
  propertyTypeLeverageAdjustment: IValueLeverageData[]
  mortgageLatesLeverageAdjustment: IValueLeverageData[]
  fcSsDilLeverageAdjustment: IValueLeverageData[]
  bankruptcyLeverageAdjustment: IValueLeverageData[]
  forbearanceLeverageAdjustment: IValueLeverageData[]
  underConstructionLeverageAdjustment: IValueLeverageData[]
  isPropertyInLeasableStateLeverageAdjustment: IValueLeverageData[]
  decliningMarketPropertyLeverageAdjustment: IValueLeverageData[]
  newConstructionPropertyLeverageAdjustment: IValueLeverageData[]
  exitFeeLeverageAdjustment: IRangeLeverageAdjustment[]
  ficoLeverageAdjustment: IFicoLeverageAdjustment[]
  loanAmountLeverageAdjustment: IRangeLeverageAdjustment[]
  monthsReserveLeverageAdjustment: IRangeLeverageAdjustment[]
  experienceLeverageAdjustment: IRangeLeverageAdjustment[]
  citizenshipLeverageAdjustment: IValueLeverageData[]
  rehabBudgetTypeLeverageAdjustment: IValueLeverageData[]
  ruralPropertyLeverageAdjustment: IValueLeverageData[]
  firstTimeHomeBuyerLeverageAdjustment: IValueLeverageData[]
  firstTimeInvestorLeverageAdjustment: IValueLeverageData[]
}

export interface IDscrValueLeverageData {
  value: string
  limit: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrRangeLeverageAdjustment {
  range: IRange
  limit: {
    purchase: number
    nocashout: number
    cashout: number
  }
}

export interface IDscrLeverageAdjustments {
  OccupancyLeverageAdjustment: IDscrValueLeverageData[]
  LoanAmountLeverageAdjustment: IDscrRangeLeverageAdjustment[]
  PropertyTypeLeverageAdjustment: IDscrValueLeverageData[]
  MortgageLatesLeverageAdjustment: IDscrValueLeverageData[]
  FcSsDilLeverageAdjustment: IDscrValueLeverageData[]
  BankruptcyLeverageAdjustment: IDscrValueLeverageData[]
  ForbearanceLeverageAdjustment: IDscrValueLeverageData[]
  IsPropertyInLeasableStateLeverageAdjustment: IDscrValueLeverageData[]
  IsShortTermRentalLeverageAdjustment: IDscrValueLeverageData[]
  DecliningMarketPropertyLeverageAdjustment: IDscrValueLeverageData[]
  NewConstructionPropertyLeverageAdjustment: IDscrValueLeverageData[]
}

export interface ICommercialOnlyProgrammed {
  leverageMaxLimits?: ILeverageMaxLimits
  leverageAdjustments?: ILeverageAdjustments
  baseRates: number[]
  basePrices: number[]
  rateSpreads: number[]
  exitFees: number[]
  ltvLimits: {
    purchase: ILtvValues
    nocashout: ILtvValues
    cashout: ILtvValues
  }
  maxRatePriceAdjustment: {
    purchase: IRatePriceAdjustmentValue
    nocashout: IRatePriceAdjustmentValue
    cashout: IRatePriceAdjustmentValue
  }
  experience: string[]
  experienceAdjustment: IRatePriceAdjustment
  loanTerm: number[]
  loanTermAdjustment: IRatePriceAdjustment
  aivLtvs: number[]
  aivLtvAdjustment: IRatePriceAdjustment
  ltcs: number[]
  ltcAdjustment: IRatePriceAdjustment
  ficos: IRange[]
  ficoAdjustment: IRatePriceAdjustment
  monthsReserve: IRange[]
  monthsReserveAdjustment: IRatePriceAdjustment
  propertyTypes: string[]
  propertyTypeAdjustment: IRatePriceAdjustment
  bankruptcy: ILabelValue[]
  bankruptcyAdjustment: IRatePriceAdjustment
  foreclosure: ILabelValue[]
  foreclosureAdjustment: IRatePriceAdjustment
  deedInLieu: ILabelValue[]
  deedInLieuAdjustment: IRatePriceAdjustment
  shortsale: ILabelValue[]
  shortsaleAdjustment: IRatePriceAdjustment
  mortgageLates: ILabelValue[]
  mortgageLatesAdjustment: IRatePriceAdjustment
  forbearance: string[]
  forbearanceAdjustment: IRatePriceAdjustment
  citizenships: ILabelValue[]
  citizenshipAdjustment: IRatePriceAdjustment
  rehabBudgetTypes: ILabelValue[]
  rehabBudgetTypesAdjustment: IRatePriceAdjustment
  loanAmount: IRange
}

export interface IProgramTableData {
  ID: number
  Name: string
  Type: string
  tableData: any[]
  tableStyle: Record<string, any>
  leverageMaxLimits?: ILeverageMaxLimits
  leverageAdjustments?: ILeverageAdjustments
}

export interface ICommercialDscrData {
  properties: string[]
  incomeDoc: string
  loanAmount: IRange
  minDscr: number
  monthsReserve: number
  ficos: number[]
  ltvs: number[]
  purchaseRates: number[]
  refinanceRates: number[]
  purchasePrice: number
  refinancePrice: number
  purchaseLTVs: number[]
  refinanceLTVs: number[]
}

export interface ICommercialDscr {
  [key: string]: ICommercialDscrData
}

export interface ILtvValues {
  aivLtv: number
  arvLtv: number
  ltc: number
  ltp: number
}

export interface IPropertyTypeLtvAdj {
  propertyType: string
  unitsRange: IRange
  aivValue: IRange
  arvValue: IRange
  ltvAdjustment: ILtvValues
}

export interface IExperienceTierLimit {
  expRange: IRange
  minFico: number
  maxLoanAmount: number
}

export interface IExperienceTierData {
  title: string
  count: number
  limit: IExperienceTierLimit[]
}

export interface ITier {
  title: string
  count: number
  ltvAdjustment?: ILtvValues
  propertyTypeLtvAdj?: IPropertyTypeLtvAdj[]
}

export interface IExperienceTier {
  [key: string]: IExperienceTierData[]
}

export interface ITierData {
  [key: string]: ITier[] | IExperienceTier
}

export const msaPropertyTypes: Record<string, string> = {
  SFR: 'Single Family',
  Condo: 'Condo',
  '2-4 units': '2-4 Units',
  PUD: 'PUD',
  'Mixed Use': 'Mixed-Use',
  '5+ units': 'Multi-Family',
}

export interface IProgramData {
  ID: number
  Name: string
  Type: string
  Restriction: IRestriction | CommercialRestriction
  ProgramData: ICommercialOnlyProgrammed | ICommercialDscr
  TierData: ITierData
}

export interface CommercialRestriction {
  MinRate: string
  RateReduction: number
}

export interface ILoanAmountDscrOverlay {
  loanAmount: IRange
  minDscr: number
}

export interface ILoanPurposeDscrOverlay {
  loanPurpose: string
  minDscr: number
}

export interface IDscrOverlay {
  loanAmountDscrOverlay?: ILoanAmountDscrOverlay[]
  loanPurposeDscrOverlay?: ILoanPurposeDscrOverlay[]
}

export interface IRestriction {
  LtvLimits?: Record<string, LtvLimit>
  PrepaymentPenalty?: IPrepaymentPenalty
  StateLTVMaxLimit?: StateLTVMaxLimit
  LeverageMaxLimits?: IDscrLeverageMaxLimits
  LeverageAdjustments?: IDscrLeverageAdjustments
  DscrOverlay?: IDscrOverlay
  PPPOverlay?: IPrepaymentPenaltyOverlay[]
}

export interface LtvData {
  cashout: number[]
  nocashout: number[]
  ficos: number[]
  loanAmount: { from: number; to: number }
  monthsReserve: number
  purchaseRT: number[]
}

export interface Ltv {
  title: string
  data: LtvData[]
}

export interface LtvLimit {
  label: string
  occupancy: string
  ltvs: Ltv[]
  bigDscrLtvs?: Ltv[]
  conditionLabels: Record<string, string>
  [key: string]: any
}

export interface IPrepaymentPenalty {
  label: string
  limits: Record<number, { min: number; max: number }>
}

export interface StateLTVMaxLimit {
  label: string
  limits: Record<string, number>
}

export enum DocumentType {
  GUIDELINE = 'guideline',
  FORM = 'form',
  TRAINING = 'training',
  RESISDENTIAL = 'resisdential',
  MARKETING = 'marketing',
}

export interface IDocument {
  id: number
  name: string
  orderId: number
  fileKey: string
  type: DocumentType
  programIDs: string[]
  createdAt: Date
  updatedAt: Date
}

export const ProgramTypes = {
  BEYOND_PRIME: 'Beyond Prime',
  BEYOND_PRIME_INVESTOR: 'Beyond Prime Investor',
  COMMERCIAL_ONLY_PROGRAMMED: 'Commercial Only Programmed',
  MULTIFLOW: 'MultiFlow',
  FAST_MONEY: 'FastMoney',
  HARD_MONEY: 'HardMoney',
  SOFT_MONEY: 'SoftMoney',
  COMMERCIAL_DSCR: 'CommercialDSCR',
}

export interface IPrepaymentPenaltyOverlay {
  label: string
  minPPP: number
}
