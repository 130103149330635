import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import {
  createAccountingAccount,
  deleteAccountingAccount,
  getAccountingAccount,
  updateAccountingAccount,
} from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { confirm } from 'utils'

import { EditAccountDialog } from './EditAccountDialog'
import { type IAccountingAccount, AccountingAccountTypeOptions } from './types'

export const Accounts = () => {
  const [isLoading, setLoading] = useState(false)
  const [curEditItem, setCurEditItem] = useState<IAccountingAccount | null>()
  const [data, setData] = useState<IAccountingAccount[]>([])

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const data = await getAccountingAccount()
    setData(data)
    setLoading(false)
  }

  const onUpdateEditItem = async (item: IAccountingAccount | null) => {
    setCurEditItem(undefined)
    if (!item) return

    const newData = cloneDeep(data)
    setLoading(true)
    if (item.id < 0) {
      const _addedData = await createAccountingAccount(item)
      newData.push(_addedData)
    } else {
      const index = data.findIndex((v) => v.id == item.id)
      await updateAccountingAccount(item.id, item)
      newData[index] = item
    }
    setLoading(false)
    setData(newData)
  }

  const onDelete = async (item: IAccountingAccount) => {
    const index = data.findIndex((v) => v.id == item.id)
    if (index == -1) return

    if (item.id < 0) {
      const newValues = cloneDeep(data)
      newValues.splice(index, 1)
      setData(newValues)
      return
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete this account?
        <br />
        <span className="text-gray-600">Account Name: {item.name}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    try {
      await deleteAccountingAccount(item.id)

      const newValues = cloneDeep(data)
      newValues.splice(index, 1)
      setData(newValues)
    } catch (e) {}
    setLoading(false)
  }

  return (
    <div>
      <div className="flex flex-wrap gap-3 mb-3 items-center justify-between">
        <h1 className="text-2xl font-bold flex items-center pb-5">
          Accounting Accounts
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h1>
        <div className="flex gap-2">
          <Button onClick={() => setCurEditItem(null)} className="">
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-auto">
        <LayoutLoading show={isLoading} />

        <div className="sm:rounded-lg mb-8">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-3 py-3 w-[20px]">
                  No
                </th>
                <th scope="col" className="px-3 py-3">
                  Type
                </th>
                <th scope="col" className="px-3 py-3">
                  Name
                </th>
                <th scope="col" className="px-3 py-3">
                  Number
                </th>
                <th scope="col" className="px-3 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr
                    className={`${
                      item.invalid ? 'bg-red-100' : 'bg-white'
                    } border-b dark:bg-gray-800 dark:border-gray-700`}
                    key={index}
                  >
                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {index + 1}
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {AccountingAccountTypeOptions[item.type]}
                    </td>

                    <td
                      scope="row"
                      className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap capitalize"
                    >
                      {item.name}
                    </td>

                    <td scope="row" className="px-3 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.number}
                    </td>

                    <td className="px-6 py-1">
                      <div className="flex gap-2">
                        <span
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                          onClick={() => setCurEditItem(item)}
                        >
                          <PencilSquareIcon className="w-4 h-4" />
                        </span>

                        <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        {curEditItem !== undefined && <EditAccountDialog item={curEditItem} onClose={onUpdateEditItem} />}
      </div>
    </div>
  )
}
