import cloneDeep from 'clone-deep'
import { StripePayModal } from 'components/Modals'
import { pullCreditReportLimitDays } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { isVendorEnabled, PaymentOrderType } from 'services'
import { ButtonGroup, Modal } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'

export const ConfirmCreditReportDialog = ({
  data = [],
  refId,
  paymentData = {},
  onConfirm,
  onClose,
  showAlert,
}: any) => {
  const [isLoading, setLoading] = useState(false)
  const [showPayment, setShowPayment] = useState(false)

  const [method, setMethod] = useState('')
  const [options, setOptions] = useState<Record<string, string>>({})

  const initData = async () => {
    setLoading(true)
    const meridianEnabled = await isVendorEnabled('CreditReport')
    const xactusEnabled = await isVendorEnabled('Xactus')

    const temp = cloneDeep(options)
    if (meridianEnabled) temp['meridian'] = 'CIC Credit'
    if (xactusEnabled) temp['xactus'] = 'Xactus360'

    if (Object.keys(temp).length == 1) setMethod(Object.keys(temp)[0])
    setOptions(temp)
    setLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onOk = async () => {
    setLoading(true)
    onConfirm(method, (pullCreditScoreLevel: number | boolean) => {
      setLoading(false)
      if (pullCreditScoreLevel === true) return onClose()
      if (pullCreditScoreLevel === -1) {
        toast('You are not allowed to pull credit report. Please contact with Administrator.', { type: 'error' })
        return
      }
      if (pullCreditScoreLevel === 0) setShowPayment(true)
    })
  }

  const keys = ['Borrower Seperator', 'Borrower Name', 'SSN', 'Present Address']
  const tableData = keys
    .map((key, index) => data[index] && [<span className="font-medium">{key}</span>, data[index]])
    .filter((v) => !!v)

  if (showPayment)
    return (
      <StripePayModal
        title="Pay to Pull my credit report"
        orderType={PaymentOrderType.PullCreditReport}
        amount={50}
        refId={refId}
        data={paymentData}
        onClose={() => setShowPayment(false)}
      />
    )

  return (
    <Modal
      isOpen
      title="Confirm and Pull my credit report"
      titleOkay="Confirm"
      loading={isLoading}
      onClose={onClose}
      disabled={!method}
      onOk={onOk}
    >
      <div className={`text-gray-600 text-md w-[750px]`}>
        <p className="font-bold mb-4">Are you sure to use this information?</p>
        <div className="">
          <PlainTable header={['key', 'value']} data={tableData} />
        </div>
        <div>
          {Object.keys(options).length == 2 && (
            <>
              <p className="font-bold mb-4">Which service would you like to use?</p>
              <ButtonGroup
                title={{
                  meridian: 'CIC Credit',
                  xactus: 'Xactus360',
                }}
                value={method}
                onChange={(key) => {
                  setMethod(key)
                }}
                className="-ml-2"
              />
            </>
          )}
          {!isLoading && Object.keys(options).length == 0 && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15.5px]"
              role="alert"
            >
              <div className="font-semibold flex items-center gap-1">No credit report service is enabled</div>
            </div>
          )}
        </div>
        {showAlert && (
          <p
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 mt-4 text-[15.5px]"
            role="alert"
          >
            After you request the credit score, you can not request again within {pullCreditReportLimitDays} days.
            <br />
            This will take about 3 - 5 minutes!
          </p>
        )}
      </div>
    </Modal>
  )
}
