import {
  ArrowDownTrayIcon,
  ArrowRightCircleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  BookOpenIcon,
  ClockIcon,
  CloudArrowUpIcon,
  EnvelopeIcon,
  EyeIcon,
  // PencilSquareIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { SourceBox, TargetBox } from 'components/DragDrop'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { baseUrl } from 'config'
import { useMemo, useState } from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { downloadS3Document, getTemporaryUserInfo, updateBorrower, uploadFiles } from 'services'
import { Checkbox, DocumentFile, HorzScrollContainer, Input2, Select, Toggle } from 'stories/components'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { capitalizeFirstLetter, confirm, formatTime, hasFileExt } from 'utils'
import { confirmOptions } from 'utils/modals/confirmOptions'
import { setLoanNumber } from 'utils/setLoanNumber'

import { docCategory, docStatus } from './constants'
import { NewDocumentsTable } from './NewDocumentsTable'
import { LoanSendEmailDialog } from './sendEmailDialog'
import { LoanSubmissionDocument } from './types'

export const DocumentsSlide = ({
  submitToLoanSetup,
  brokerProfile,
  canDeleteDocumentPermanently,
  loading,
  setLoading,
  documents,
  onAdd,
  onUpdate,
  onOpen,
  onRemove: _onRemove,
  onSelectedRemove: _onSelectedRemove,
  onOpenDocHistory,
}: any) => {
  const token = useSelector((state: any) => state.auth.token)
  const [isShowDocuments, setShowDocuments] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  // const [name, setName] = useState('')
  const [selected, setSelected] = useState<Record<string, boolean>>({})
  const [sortKey, setSortKey] = useState('')
  const [sortDir, setSortDir] = useState(true)
  const [uploadingFiles, setUploadingFiles] = useState<DocumentFile[]>([])
  const [isEmailDialog, showEmailDialog] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState<LoanSubmissionDocument[]>([])

  const sortedDocuments = useMemo<LoanSubmissionDocument[]>(() => {
    const query = searchQuery.trim().toLowerCase()
    let docs = documents
      .filter((doc: any) => {
        if (!query) return true
        return (
          String(doc.conditionNo).toLowerCase().includes(query) ||
          doc.name.toLowerCase().includes(query) ||
          doc.status?.toLowerCase().includes(query) ||
          doc.category?.toLowerCase().includes(query)
        )
      })
      .sort((a: any, b: any) => {
        const aV = a[sortKey]
        const bV = b[sortKey]
        if (aV > bV) return 1
        if (aV == bV) return 0
        return -1
      })
    if (sortDir) docs = docs.reverse()
    return docs
  }, [documents, sortDir, sortKey, searchQuery])

  const isSelectedBtnDisabled = useMemo(() => Object.keys(selected).length == 0, [selected])

  const onUpdateDocument = (key: string, value: string | boolean, documentKey: string) => {
    const index = documents.findIndex((doc: any) => doc.key == documentKey)
    if (index == -1) return
    const newDocs = cloneDeep(documents)

    if (key == 'name') {
      newDocs[index].isEditing = false
      const orgName = newDocs[index].name
      value = value + (hasFileExt(orgName) ? orgName.substr(-4) : '')
    }

    newDocs[index][key] = value

    onUpdate(newDocs, newDocs[index])
  }

  const onShowDocuments = () => {
    setShowDocuments(!isShowDocuments)
  }

  const onSelected = (key: string, value: boolean) => {
    const newData = cloneDeep(selected)
    if (!value) delete newData[key]
    else newData[key] = value
    setSelected(newData)
  }

  const onSelectAll = () => {
    const count = documents.length
    const selCount = Object.keys(selected).length
    let newData = cloneDeep(selected)
    if (count != selCount) documents.forEach((doc: any) => (newData[doc.key] = true))
    else newData = {}
    setSelected(newData)
  }

  const onSort = (key: string) => {
    setSortKey(key)
    if (sortKey == key) setSortDir(!sortDir)
  }

  const onDownload = (type: string) => {
    const params = location.pathname.split('/')
    const loanNumber = params[3]
    let url = `${baseUrl}/loan/downloadSubmissionDocuments/${loanNumber}/${type}?token=${token}`
    if (type == 'selected') {
      const selectedIndexes = Object.keys(selected).map((key) =>
        documents.findIndex((doc: Record<string, any>) => doc.key == key),
      )
      url += `&keys=${selectedIndexes.join(',')}`
    }

    var windowReference: any = window.open()
    windowReference.document.title = 'Downloading Documents...'
    windowReference.location = url
  }

  const onSelectedRemove = async () => {
    const indexes = Object.keys(selected)
      .map((key) => documents.findIndex((doc: Record<string, any>) => doc.key == key && !doc.generated))
      .filter((v) => v != -1)
      .sort((a, b) => a - b)
    if (indexes.length == 0) {
      setSelected({})
      return
    }

    const content = (
      <div className="text-gray-900 mb-4 text-md">
        Are you sure want to delete this documents permanently?
        <div className="text-gray-800 flex flex-col text-left text-sm mt-4">
          {indexes.map((index) => (
            <span key={documents[index].key}>- {documents[index] && documents[index].name}</span>
          ))}
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    await _onSelectedRemove(Object.keys(selected))
    setSelected({})
  }

  const onRemove = (document: Record<string, any>) => {
    setSelected({})
    _onRemove(document)
  }

  const onUploadFiles = async (files: File[]) => {
    setLoading(true)
    const loanNumber = setLoanNumber()
    const uploadData = {
      path: `loan/${loanNumber}/document`,
    }
    const keys: string[] = await uploadFiles(uploadData, files)
    const now = Date.now()

    setLoading(false)
    const newFiles = cloneDeep(uploadingFiles)
    newFiles.push(
      ...files.map((file, index) => ({
        id: now + index,
        name: file.name,
        fileKey: keys[index],
        status: 'Not Reviewed',
        category: '',
        createdAt: now,
      })),
    )
    setUploadingFiles(newFiles)
  }

  const OnSendEmail = () => {
    const selectedDocuments = sortedDocuments.filter((doc: any) => selected[doc.key])
    setSelectedDocuments(selectedDocuments)
    showEmailDialog(true)
  }

  const onSelectProfile = async () => {
    const loanNumber = setLoanNumber()
    const selectedDocuments = sortedDocuments.filter((doc: any) => selected[doc.key])
    const borrower = await getTemporaryUserInfo(loanNumber)
    const options = [{ name: 'General', value: 'general' }]
    borrower.entities.map((v: string) => {
      options.push({ name: capitalizeFirstLetter(v), value: v })
    })
    const result: any = await confirmOptions('Please select where document(s) should be assigned', options)
    if (result === false) return
    const data: Record<string, any> = {}
    data['email'] = borrower.email
    data['documents'] = [...borrower.documents]
    selectedDocuments.map((newDoc: any) => {
      let isExisting = false
      for (let i = 0; i < borrower.documents.length; i++) {
        if (borrower.documents[i].fileKey === newDoc.key) {
          isExisting = true
          break
        }
      }
      if (!isExisting)
        data['documents'].push({
          name: newDoc.name,
          fileKey: newDoc.key,
          status: newDoc.status,
          category: newDoc.category,
          section: result,
          createdAt: Date.now(),
        })
    })
    setLoading(true)
    await updateBorrower(borrower.id, data)
    setLoading(false)
  }

  const onEmailDialogClose = async (result: boolean) => {
    if (!result) {
      showEmailDialog(false)
      return
    }
    showEmailDialog(false)
  }

  const renderHeader = (key: string, title: any) => {
    return (
      <div className="flex flex-wrap font-normal items-center">
        <p>{title}</p>

        <span className="ml-2">
          {sortKey == key &&
            (sortDir ? <BarsArrowUpIcon className="w-3 h-3" /> : <BarsArrowDownIcon className="w-3 h-3" />)}
        </span>
      </div>
    )
  }

  const renderRow = (document: any, index: number) => {
    const clsName = document.status.replace(' ', '')
    return [
      <SourceBox item={document} type="document" key={document.key}>
        <tr className={`bg-gray-50 ${clsName}`} key={`first-${document.key}`}>
          <td className="px-3">
            <Checkbox
              id={`condition-no-${document.key}`}
              title={document.conditionNo || 'Other'}
              checked={!!selected[document.key]}
              onChange={(value) => onSelected(document.key, value)}
            />
          </td>
          <td colSpan={3}>
            {brokerProfile && submitToLoanSetup === 1 ? (
              <span className="italic text-[13.5px]">{document.name}</span>
            ) : (
              <PlainInput
                value={hasFileExt(document.name) ? document.name.substr(0, document.name.length - 4) : document.name}
                content={document.name}
                isEditing={document.isEditing}
                onChange={(newName: string) => onUpdateDocument('name', newName, document.key)}
              />
            )}
          </td>
          <td rowSpan={2}>
            <div className="grid grid-cols-2">
              <button
                className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center text-shade-blue"
                onClick={() => onOpen(document)}
              >
                <EyeIcon className="w-4 h-4" />
              </button>

              <button
                className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center"
                onClick={() => downloadS3Document(document.key, document.name)}
              >
                <ArrowDownTrayIcon className="w-4 h-4" />
              </button>

              {/* <button
                className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center"
                onClick={() => onUpdateDocument('isEditing', true, document.key)}
              >
                <PencilSquareIcon className="w-4 h-4" />
              </button> */}

              {!brokerProfile && (
                <button
                  className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center text-gray-600"
                  onClick={() => onOpenDocHistory(document)}
                >
                  <ClockIcon className="w-4 h-4" />
                </button>
              )}

              {!document.generated && canDeleteDocumentPermanently && (
                <button
                  className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center text-red-800"
                  onClick={() => onRemove(document)}
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              )}
            </div>
          </td>
        </tr>
        <tr className={`border-b ${clsName}`} key={`second-${document.key}`}>
          <td className="px-2">
            <div className="flex items-center gap-2 justify-center">
              {!brokerProfile && (
                <div className="scale-75">
                  <Toggle
                    id={`intext-${index}`}
                    className="flex-col"
                    value={document.intext}
                    tooltip="Internal"
                    onChange={(value) => onUpdateDocument('intext', value, document.key)}
                  />
                </div>
              )}
              <div className="scale-75">
                <Toggle
                  id={`borrower-${index}`}
                  className="flex-col"
                  value={document.borrower}
                  tooltip="Borrower"
                  onChange={(value) => onUpdateDocument('borrower', value, document.key)}
                />
              </div>
            </div>
          </td>
          <td className="px-1">
            {brokerProfile ? (
              <span className="italic text-[13.5px]">{document.status}</span>
            ) : (
              <Select
                id={`side-document-status-${document.key}`}
                size={3}
                className="-mb-4 bg-white"
                options={docStatus}
                value={document.status}
                onChange={(value) => onUpdateDocument('status', value, document.key)}
              />
            )}
          </td>

          <td className="px-1">
            {brokerProfile && submitToLoanSetup === 1 ? (
              <span className="italic text-[13.5px]">{document.category}</span>
            ) : (
              <Select
                id={`side-document-category-${document.key}`}
                size={3}
                className="-mb-4 bg-white"
                options={docCategory}
                value={document.category}
                onChange={(value) => onUpdateDocument('category', value, document.key)}
              />
            )}
          </td>

          <td className="">{formatTime(document.createdAt)}</td>
        </tr>
      </SourceBox>,
    ]
  }

  return (
    <>
      <div className="fixed right-0 top-[25%] z-20">
        <button
          className="p-3 rounded-r-none ring-[1px] ring-shade-blue bg-shade-blue text-white border hover:bg-white border-shade-blue hover:text-shade-blue rounded flex items-center gap-2 border-r-0"
          onClick={onShowDocuments}
        >
          <BookOpenIcon className="w-6 h-6" /> Documents
        </button>
      </div>
      {isShowDocuments && (
        <div
          className={`shadow1 fixed bg-white border rounded-l z-30 right-0 top-[5vh] bottom-0 transition-[right] w-[calc(40rem+4px)] h-[90vh] ease-in-out ${
            isShowDocuments ? 'right-0' : '-right-[calc(40rem+4px)]'
          }`}
        >
          <HorzScrollContainer width={6} size={documents.length}>
            <>
              {!uploadingFiles.length && (
                <div className="absolute top-12 right-[calc(50%-135px)] w-full h-fit">
                  <div className="flex flex-wrap items-center absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 py-8 text-gray-300 w-[270px] text-center">
                    <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
                    <span>Drag and drop files.</span>
                  </div>
                </div>
              )}

              <div className="relative w-[40rem] z-20 rounded-t font-bold text-sm text-shade-blue">
                <div className="flex justify-between place-items-center items-center border-b p-3">
                  <p className="text-lg text-gray-800">Documents</p>
                  <button className="hover:underline" onClick={() => onDownload('all')}>
                    <div className="flex">
                      <p className="mr-1 font-bold">All</p> <ArrowDownTrayIcon className="w-5 h-5" />
                    </div>
                  </button>

                  <button className="hover:underline" onClick={() => onDownload('approved')}>
                    <div className="flex">
                      <p className="mr-1 font-bold">Approved</p> <ArrowDownTrayIcon className="w-5 h-5" />
                    </div>
                  </button>

                  <div className="flex items-center">
                    <button
                      className={`${isSelectedBtnDisabled ? 'text-blue-400' : 'hover:underline'} mr-4`}
                      onClick={() => onDownload('selected')}
                      disabled={isSelectedBtnDisabled}
                    >
                      <div className="flex">
                        <p className="mr-1 font-bold">Selected</p> <ArrowDownTrayIcon className="w-5 h-5" />
                      </div>
                    </button>

                    <Checkbox
                      title={`${Object.keys(selected).length}/${documents.length}`}
                      id="documentSelected"
                      checked={Object.keys(selected).length == documents.length}
                      onClick={onSelectAll}
                      disabled={documents.length == 0}
                    />
                    {canDeleteDocumentPermanently && (
                      <div className="flex">
                        <button
                          className={`${
                            isSelectedBtnDisabled ? 'text-red-400' : 'hover:underline hover-shadow1 rounded'
                          } text-red-600 ml-4 `}
                          onClick={() => onSelectedRemove()}
                          disabled={isSelectedBtnDisabled}
                        >
                          <div className="flex p-1">
                            <TrashIcon className="w-4 h-4" />
                          </div>
                        </button>
                      </div>
                    )}
                    <button
                      onClick={OnSendEmail}
                      disabled={isSelectedBtnDisabled}
                      className={` ml-4 ${isSelectedBtnDisabled ? 'text-blue-400' : 'rounded hover-shadow1'}`}
                    >
                      <div className="flex p-1">
                        <Tooltip message="Send Email" position="bottom">
                          <EnvelopeIcon className="w-5 h-5" />
                        </Tooltip>
                      </div>
                    </button>
                    <button
                      onClick={onSelectProfile}
                      disabled={isSelectedBtnDisabled}
                      className={` ml-4 ${isSelectedBtnDisabled ? 'text-blue-400' : 'rounded hover-shadow1'}`}
                    >
                      <div className="flex p-1">
                        <Tooltip message="Send documents to Borrower" placement="right" position="bottom">
                          <UserIcon className="w-5 h-5" />
                        </Tooltip>
                      </div>
                    </button>
                  </div>

                  <button className="hover:underline p-1 rounded hover-shadow1" onClick={onShowDocuments}>
                    <ArrowRightCircleIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <LayoutLoading show={loading} />
              <NewDocumentsTable
                files={uploadingFiles}
                setFiles={setUploadingFiles}
                onSubmit={async (files: DocumentFile[]) => {
                  await onAdd(files)
                  setUploadingFiles([])
                }}
                onCancel={() => setUploadingFiles([])}
              />
              <div className="p-1">
                {!uploadingFiles.length && (
                  <TargetBox
                    canDrop={(data: any) => {
                      return !!data.files
                    }}
                    types={['document', NativeTypes.FILE]}
                    onDrop={({ files }: { files: File[] }) => onUploadFiles(files)}
                  >
                    <>
                      <div className="w-full h-fit">
                        <div className="w-full p-2">
                          <Input2
                            type="search"
                            title="Search"
                            hasIcon
                            transparent
                            icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
                            value={searchQuery}
                            onChange={(value) => setSearchQuery(value)}
                          />
                        </div>
                      </div>
                      <div className="relative overflow-y-auto w-[calc(40rem-8px)] h-[calc(90vh-150px)]">
                        <table className="w-full font-normal text-sm text-left text-black relative">
                          <thead className="border-b bg-black/5">
                            <tr>
                              <th
                                className="px-3 border-r border-slate-300 cursor-pointer"
                                onClick={() => onSort('conditionNo')}
                              >
                                {renderHeader('conditionNo', <>Condition #</>)}
                              </th>
                              <th
                                colSpan={4}
                                className="px-2 py-1 border-b border-slate-300 cursor-pointer"
                                onClick={() => onSort('name')}
                              >
                                {renderHeader('name', 'Name')}
                              </th>
                            </tr>
                            <tr>
                              <th className="px-3 border-r border-slate-300 border-t font-variation-settings-500 w-10">
                                Internal / Borrower
                              </th>
                              <th
                                className="px-2 py-1 border-r border-slate-300 cursor-pointer"
                                onClick={() => onSort('status')}
                              >
                                {renderHeader('status', 'Status')}
                              </th>
                              <th
                                className="px-2 border-r border-slate-300 cursor-pointer"
                                onClick={() => onSort('category')}
                              >
                                {renderHeader('category', 'Category')}
                              </th>
                              <th
                                className="px-2 border-r border-slate-300 cursor-pointer"
                                onClick={() => onSort('createdAt')}
                              >
                                {renderHeader('createdAt', 'Uploaded Date')}
                              </th>
                              <th className="px-2 font-normal">Action</th>
                            </tr>
                          </thead>

                          <tbody className="text-gray-900">
                            {sortedDocuments.map((document: any, index: number) => renderRow(document, index))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  </TargetBox>
                )}
              </div>
            </>
          </HorzScrollContainer>
        </div>
      )}
      {isEmailDialog && <LoanSendEmailDialog documents={selectedDocuments} onClose={onEmailDialogClose} />}
    </>
  )
}
