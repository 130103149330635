import { setApplicationStep } from 'actions'
import cloneDeep from 'clone-deep'
import { estimatedClosingAmountBorrower } from 'pages/LoanStructure/Logic'
import { store } from 'reducers'
import { isVendorEnabled } from 'services'

import { type IAssetData, AssetAccountTypeForPlaid } from './constants'

export const assetInfoValidate = async (updateStore = false) => {
  const {
    borrower: {
      borrower: { assets },
    },
    loan,
    step,
    loanDetail,
  } = store.getState()

  const [plaidConfig, snapptConfig] = await Promise.all([isVendorEnabled('Plaid'), isVendorEnabled('Snappt')])

  let success =
    assets.filter((asset: IAssetData) => {
      if (!AssetAccountTypeForPlaid.includes(asset.accountType || 0)) return false
      if (asset.verified || asset.override || (!plaidConfig && !snapptConfig)) return false
      if (!asset.bankStatementFileKey) return true
      return !asset.bankStatementReportFileKey && !asset.bankStatementData.webhookReportData
    }).length == 0
  const { transactionType } = loan

  const verifiedAssets = assets.filter(
    (asset: IAssetData) =>
      !AssetAccountTypeForPlaid.includes(asset.accountType || 0) ||
      asset.verified ||
      asset.override ||
      (!plaidConfig && !snapptConfig),
  )
  if (transactionType == 'Purchase' && verifiedAssets.length == 0) success = false
  else if (transactionType == 'Refinance' && assets.length == 0) {
    const { value } = estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings)
    if (value < 0) success = false
  }

  if (updateStore) {
    let application = cloneDeep(step.application)
    application.asset = success ? 1 : -1
    store.dispatch(setApplicationStep(application))
  }

  return success
}
