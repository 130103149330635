import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { createAddressDocument, validateAddress } from 'services'
import { svgLoading } from 'stories/assets'

import { Tooltip } from '../Tooltip/Tooltip'
interface AutoCompleteItemProps {
  value: string | number
  onRemove: (v: string | number) => void
  additionalComponent?: (v: any) => JSX.Element
  autoCompleteType?: string
}

enum ValidateStatus {
  Failed = -2,
  Warning = -1,
  Nothing = 0,
  Loading = 1,
  Success = 2,
}

const AutoCompleteItem: React.FC<AutoCompleteItemProps> = ({
  value,
  onRemove,
  additionalComponent,
  autoCompleteType,
}) => {
  const [isClicked, setIsClicked] = useState(false)
  const [validateTimer, setValidateTimer] = useState<NodeJS.Timeout>()
  const [validatedAddr, setValidatedAddr] = useState('')
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>(ValidateStatus.Nothing)

  const handleClick = () => {
    setIsClicked(true)

    if (validateTimer) clearTimeout(validateTimer)

    const timer = setTimeout(() => {
      if (!value) return
      setValidateStatus(ValidateStatus.Loading)

      validateAddress(value.toString()).then(({ success, address }) => {
        setValidateTimer(undefined)
        if (success) setValidateStatus(ValidateStatus.Success)
        else if (address) setValidateStatus(ValidateStatus.Warning)
        else setValidateStatus(ValidateStatus.Failed)

        setValidatedAddr(address)
      })
      setValidateTimer(undefined)
    }, 250)

    setValidateTimer(timer)
    generateAddressDocument()
  }

  const generateAddressDocument = () => {
    if (!value) return
    setValidateStatus(ValidateStatus.Loading)

    createAddressDocument(value.toString())
  }

  const renderValidate = () => {
    switch (validateStatus) {
      case ValidateStatus.Nothing:
        return <></>
      case ValidateStatus.Loading:
        return <img src={svgLoading} className="w-[14px] h-[14px] text-white animate-spin" />
      case ValidateStatus.Success:
        return <CheckIcon className="h-[14px] w-[14px] text-green-600 cursor-pointer" aria-hidden="true" />
      case ValidateStatus.Warning:
        return (
          <Tooltip message={validatedAddr}>
            <p className="bg-yellow-300 rounded-full h-[14px] w-[14px] text-white cursor-pointer text-center leading-3">
              !
            </p>
          </Tooltip>
        )
      case ValidateStatus.Failed:
        return (
          <p className="bg-red-500 rounded-full h-[14px] w-[14px] text-white cursor-pointer text-center leading-3">x</p>
        )
    }
  }

  const isMap = autoCompleteType === 'map'

  return (
    <div className="flex relative items-center border border-gray-300 p-2 rounded group/item" key={value}>
      <TrashIcon
        className="w-4 h-4 cursor-pointer text-gray-500 hover:text-red-600 mr-1"
        onClick={() => onRemove(value)}
      />
      {additionalComponent ? additionalComponent(value) : null}
      <label className={isMap ? 'mr-2' : ''}>{value}</label>
      {isMap ? (
        <span onClick={handleClick}>
          {!isClicked && <CheckIcon className="h-[14px] w-[14px] text-gray-400 cursor-pointer" aria-hidden="true" />}
          {renderValidate()}
        </span>
      ) : null}
    </div>
  )
}

export default AutoCompleteItem
