import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { COMPANY_NAME_FC } from 'config'
// import { Overview } from 'components/Overview'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { getPurchaseAdviceData, postPurchaseAdviceDate, sendPurchaseAdviceDataToAccounting } from 'services'
import { Button } from 'stories/components'
import { openAuditLog, removeComma } from 'utils'
import { isConstructionLoan } from 'utils/isConstructionLoan'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { purchaseAdviceAccounting, purchaseAdviceGeneralInfos, purchaseAdvicePostClosing } from './constant'

export function PurchaseAdvice() {
  const [data, setData] = useState<any>({})
  const [IDs, setIDs] = useState<any>({})
  const [action, setAction] = useState('')
  const [purchaseAdviceGeneralInfosFields, setPurchaseAdviceGeneralInfosFields] =
    useState<any>(purchaseAdviceGeneralInfos)
  const [purchaseAdviceAccountingFields, setPurchaseAdviceAccountingFields] = useState<any>(purchaseAdviceAccounting)
  const [isDutch, setIsDutch] = useState(false)
  const [changed, setChanged] = useState(false)

  const initData = async () => {
    setAction('init')
    const res = await getPurchaseAdviceData()
    setAction('')
    if (res.success) {
      setData(res.data.data)
      setIDs(res.data.IDs)
      let temp = cloneDeep(purchaseAdviceGeneralInfosFields)
      temp['InvestorCode'].options = res.data.investors
      setPurchaseAdviceGeneralInfosFields(temp)

      if (isConstructionLoan(res.data.data.ProductType)) {
        let tempPostClosing = cloneDeep(purchaseAdviceAccountingFields)
        tempPostClosing['PAUnpaidPrincipalBalance'].span = 4
        tempPostClosing['InterestCalculation'].visible = true
        setPurchaseAdviceAccountingFields(tempPostClosing)
        if (res.data.data.InterestCalculation === 'Dutch') setIsDutch(true)
      }
    }
  }

  useEffect(() => {
    setLoanNumber()
    initData()
  }, [])

  const onSync = async () => {
    setAction('sync')
    setChanged(false)
    let _data = cloneDeep(data)
    _data['PAInterestDays'] = calcValue('PAInterestDays')
    _data['PAInterestAmount'] = calcValue('PAInterestAmount')
    await postPurchaseAdviceDate({ IDs, data: _data })
    setAction('')
  }

  const onSendAccounting = async () => {
    if (changed) await onSync()
    setAction('accounting')
    await sendPurchaseAdviceDataToAccounting()
    setAction('')
  }

  const changeData = (key: string, value: any) => {
    const temp = cloneDeep(data)
    if (
      [
        'PARollFee',
        'PAOtherFees',
        'PAEscrowBalance',
        'PABuydownFunds',
        'APiece',
        'BPiece',
        'PariPassu',
        'ConstructionReserve',
        'InterestReserve',
      ].indexOf(key) !== -1
    ) {
      if (removeComma(value) > 0) value = -1 * removeComma(value)
    }
    temp[key] = value
    if (key === 'InterestCalculation') setIsDutch(value === 'Dutch')
    setChanged(true)
    setData(temp)
  }

  const calcValue = (key: string) => {
    let rlt = 0
    const unpaidBalance = removeComma(data.PAUnpaidPrincipalBalance)
    if (key === 'PAInterestDays') {
      try {
        var start = moment(data['PurchaseAdviceBuyerPaidToDate'], 'YYYY-MM-DD')
        var end = moment(data['PurchaseAdviceBuyerSettlementDate'], 'YYYY-MM-DD')
        //Difference in number of days
        rlt = Math.trunc(moment.duration(start.diff(end)).asDays())
        if (rlt > 0) rlt -= 1
        else if (rlt < 0) rlt + 1
        rlt *= -1
      } catch {}
      return rlt
    }
    if (key === 'PAInterestAmount') {
      try {
        let currentBalance = removeComma(data['PAUnpaidPrincipalBalance'])
        if (isDutch) currentBalance = removeComma(data['OriginalBalance'])
        const rate = removeComma(data['BuyRate'])
        const interestDays = removeComma(calcValue('PAInterestDays'))
        rlt = ((currentBalance * rate) / 100 / 360) * interestDays
      } catch {}
    }
    if (key === 'PAPremiumOrDiscountCalc') {
      const netPrice = removeComma(data.PANetPrice)
      if (netPrice !== 0) {
        rlt = (-1 * unpaidBalance * (100 - netPrice)) / 100
      }
    }
    if (key === 'PA_SRPAmountCalc') {
      const srpPerc = removeComma(data.PA_SRPPerc)
      if (srpPerc !== 0) {
        rlt = (unpaidBalance * srpPerc) / 100
      }
    }
    if (key === 'PAWireAmount') {
      rlt =
        removeComma(data.PAUnpaidPrincipalBalance) +
        removeComma(data.PARollFee) +
        removeComma(data.PAOtherFees) +
        removeComma(data.PAEscrowBalance) +
        removeComma(data.APiece) +
        removeComma(data.BPiece) +
        removeComma(data.PariPassu) +
        removeComma(data.ConstructionReserve) +
        removeComma(data.InterestReserve) +
        removeComma(calcValue('PAInterestAmount')) +
        removeComma(data.PABuydownFunds)
      if (removeComma(data.PAPremiumOrDiscountOV) !== 0) {
        rlt += removeComma(data.PAPremiumOrDiscountOV)
      } else {
        rlt += removeComma(calcValue('PAPremiumOrDiscountCalc'))
      }
      if (removeComma(data.PA_SRPAmountOV) !== 0) {
        rlt += removeComma(data.PA_SRPAmountOV)
      } else {
        rlt += removeComma(calcValue('PA_SRPAmountCalc'))
      }
    }
    return rlt.toFixed(2)
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'PurchaseBuyer',
      field: `${key}`,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  return (
    <div className="purchase-advice-container px-2">
      <SaveChanges show={changed} onSave={onSync} />
      {/* <Overview title="Purchase Advice Buyer" /> */}
      <div className="max-w-screen-2xl m-auto">
        {/* <div className="relative bg-white shadow1 rounded mb-4 md:p-6 p-2"> */}
        <div className="relative bg-white mt-6">
          <LayoutLoading show={action !== ''} />
          <div className="grid md:grid-cols-12 gap-5">
            <div className="md:col-span-5">
              <div className="shadow rounded border">
                <div className="bg-gray-200 round-t py-1 px-4">Accounting / Post Closing</div>
                <div className="p-2">
                  {Object.keys(purchaseAdvicePostClosing).map((key, index) => {
                    let input = purchaseAdvicePostClosing[key]
                    input.value = data[key]
                    input.history = true
                    if (input.visible === false) return null
                    return (
                      <div className="py-[5px]" key={index}>
                        <RenderInput input={input} Key={key} onChange={changeData} showHistory={showHistory} />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="shadow rounded border mt-4">
                <div className="bg-gray-200 round-t py-1 px-4">General Info & Date</div>
                <div className="p-2">
                  {Object.keys(purchaseAdviceGeneralInfosFields).map((key, index) => {
                    let input = purchaseAdviceGeneralInfosFields[key]
                    input.value = data[key]
                    input.history = true
                    if (input.visible === false) return null
                    return (
                      <div className="py-[5px]" key={index}>
                        <RenderInput input={input} Key={key} onChange={changeData} showHistory={showHistory} />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="shadow rounded border mt-4">
                <div className="bg-gray-200 round-t py-1 px-4">Purchase Advice Notes</div>
                <div className="p-2">
                  <RenderInput
                    input={{
                      title: 'Notes',
                      inputType: 'textarea',
                      history: true,
                      value: data['PANotes'] ? data['PANotes'] : '',
                    }}
                    Key={'PANotes'}
                    onChange={changeData}
                    showHistory={showHistory}
                  />
                </div>
              </div>
            </div>
            <div className="md:col-span-7">
              <div className="shadow rounded border">
                <div className="bg-gray-200 round-t py-1 px-4">Investor Wire Calculation</div>
                <div className="grid md:grid-cols-6 gap-2 p-2">
                  {Object.keys(purchaseAdviceAccountingFields).map((key, index) => {
                    let input = purchaseAdviceAccountingFields[key]
                    input.value = data[key]
                    input.history = true
                    if (input.visible === false) return null
                    if (
                      [
                        'PAInterestAmount',
                        'PAPremiumOrDiscountCalc',
                        'PA_SRPAmountCalc',
                        'PAWireAmount',
                        'PAInterestDays',
                      ].indexOf(key) !== -1
                    )
                      input.value = calcValue(key)
                    return (
                      <div className={`md:col-span-${input.span ? input.span : 6}`} key={index}>
                        <RenderInput input={input} Key={key} onChange={changeData} showHistory={showHistory} />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="p-2 grid md:grid-cols-6 gap-2 my-2">
                <div className="md:col-span-3">
                  <RenderInput
                    input={{
                      title: `Taxes to be paid by ${COMPANY_NAME_FC}`,
                      className: 'flex items-center',
                      inputType: 'checkbox',
                      history: true,
                      value:
                        data['PurchaseAdviceBuyerTaxPaid'] !== undefined ? data['PurchaseAdviceBuyerTaxPaid'] : false,
                    }}
                    Key={'PurchaseAdviceBuyerTaxPaid'}
                    onChange={changeData}
                    showHistory={showHistory}
                  />
                </div>
                <div className="md:col-span-3">
                  <RenderInput
                    input={{
                      title: `Insurance to be paid by ${COMPANY_NAME_FC}`,
                      className: 'flex items-center',
                      inputType: 'checkbox',
                      history: true,
                      value:
                        data['PurchaseAdviceBuyerInsurancePaid'] !== undefined
                          ? data['PurchaseAdviceBuyerInsurancePaid']
                          : false,
                    }}
                    Key={'PurchaseAdviceBuyerInsurancePaid'}
                    onChange={changeData}
                    showHistory={showHistory}
                  />
                </div>
              </div>
              <div className="flex justify-center gap-4">
                <div className="w-48">
                  <Button loading={action === 'sync'} onClick={onSync} className="w-full">
                    Sync
                  </Button>
                </div>
                <div className="w-48 -mt-2">
                  <Button loading={action === 'accounting'} link onClick={onSendAccounting} className="w-full">
                    Send to Accounting
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
