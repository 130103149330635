import type { BaseFile } from 'config'
import type { UserInfo } from 'pages/ManageAccounts'

export enum TaskStatus {
  ACTIVE = 'active',
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  OVERDUE = 'overdue',
}
export const taskProjectLoanNumber = {
  BrokerVerification: 1,
  FundDrawRequest: 2,
  Reminders: 3,
}

export const taskProjectID = {
  BrokerVerification: 2,
  FundDrawRequest: 3,
  Reminders: 4,
}

export const NonLoanTaskProjectIds = [
  taskProjectID.BrokerVerification,
  taskProjectID.FundDrawRequest,
  taskProjectID.Reminders,
]

export interface Project {
  id: number

  name: string

  user: UserInfo

  createdAt: Date

  updatedAt: Date
}

export interface TaskAssignedTo {
  id: number

  task: Task

  value: string

  createdAt: Date
}

export interface Task {
  id: number

  project: Project

  assignedTo: TaskAssignedTo[]

  assignedToManager: TaskAssignedTo[]

  loanNumber: number

  byteproFileName: number

  no: number

  taskTemplateNo: number

  byteproTaskID: number

  borrowerName: string

  description: string

  dueDate: string

  note: string

  files: BaseFile[]

  status: TaskStatus

  creator: UserInfo

  completor: UserInfo

  completedDate: string

  additionalInfo: Record<string, string | number>

  deleted: boolean

  createdAt: Date

  updatedAt: Date
}
