import { ArrowDownTrayIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { appApiUrl, companyName } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { deleteTask, getAllTasks, getTasks, recoverTask } from 'services'
import { svgLoading } from 'stories/assets'
import svgSearch from 'stories/assets/search.svg'
import { ButtonGroup, Input2, InputDateRange, MultiSelect, Pagination, Toggle } from 'stories/components'
import { confirm, filterObject, formatDate, serialize, useQuery, useTitle } from 'utils'

import { Task } from './constants'
import { AddTaskModal } from './Modals/addTask'
import { DownloadRangeModal } from './Modals/downloadRange'
import { MarkTaskModal } from './Modals/markTask'
import { StatusTaskModal } from './Modals/statusTask'
import { TaskTable } from './taskTable'

const itemCountPerPage = 25

const taskTypeOptions = {
  active: 'My Active',
  completed: 'My Completed',
}

const allTaskTypeOptions = {
  allActive: 'All Active',
  allCompleted: 'All Completed',
}

const myAssignedTaskTypeOptions = {
  myAllActive: 'Active Assigned',
  myAllCompleted: 'Completed Assigned',
}

export const TasksForProject = ({ project, assignedToList }: { project: Record<string, any>; assignedToList: any }) => {
  useTitle(`Tasks - ${companyName}`)

  const locationQuery = useQuery()

  const [filters, setFilters] = useState<Record<string, any>>({
    projectId: project.id,
    taskType: locationQuery.get('taskType') || 'active',
    description: {},
    query: locationQuery.get('query') || '',
    assignedTo: {},
    orderBy: 'createdAt',
    orderDir: '-1',
    pageNum: 0,
    showDeleted: false,
    dateRange: { from: '', to: '' },
  })

  const [total, setTotal] = useState(0)
  // const [action, setAction] = useState('')
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [isLoading, setLoading] = useState(false)
  const [tasks, setTasks] = useState<Record<string, any>[]>([])
  const [queryTimes, setQueryTimes] = useState(0)
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const auth = useSelector((state: any) => state.auth)

  const [isShowStatusTask, setShowStatusTask] = useState(false)
  const [isShowAddTask, setShowAddTask] = useState(false)
  const [isShowMarkTask, setShowMarkTask] = useState(false)
  const [selectedTask, setSelectedTask] = useState<Record<string, any> | null>(null)
  const [descriptionList, setDescriptionList] = useState<string[]>([])
  const [isDownloadRangeModal, setDownloadRangeModal] = useState(false)
  const { data: permissionData, hasPermission } = usePermissions()

  useEffect(() => {
    const newFilters = cloneDeep(filters)
    newFilters.projectId = project.id
    setFilters(newFilters)
    filterUsersData(newFilters).then(() => {
      setIsGetUsersOnce(true)
    })
    getTasks().then(({ data }) => {
      const descriptions = data.map(({ description }: any) => description)
      setDescriptionList(descriptions)
    })
  }, [project])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterUsersData = async (filters: any) => {
    if (!filters.projectId) return

    setLoading(true)
    getAllTasks(filters.projectId, {
      ...filters,
      description: filterObject(filters.description),
      assignedTo: filterObject(filters.assignedTo),
      fromDate: filters.dateRange.from,
      toDate: filters.dateRange.to,
      skip: filters.pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setTasks(data)
        setTotal(total)
        setQueryTimes(queryTimes + 1)
      })
      .finally(() => setLoading(false))
  }

  const onAddTask = () => {
    setShowAddTask(true)
    setSelectedTask(null)
  }

  const onCloseModal = (isNeedRefetch: boolean, newStatus: string) => {
    if (isShowMarkTask && isNeedRefetch && selectedTask) {
      const index = tasks.findIndex((item) => item.id === selectedTask.id)
      const newTasks = cloneDeep(tasks)
      newTasks[index].status = newStatus
      setTasks(newTasks)
      isNeedRefetch = false
    }
    setShowStatusTask(false)
    setShowAddTask(false)
    setShowMarkTask(false)
    setSelectedTask(null)
    if (isNeedRefetch) filterUsersData(filters)
  }

  const onChangeFilter = (
    key:
      | 'taskType'
      | 'query'
      | 'description'
      | 'assignedTo'
      | 'orderBy'
      | 'orderDir'
      | 'pageNum'
      | 'showDeleted'
      | 'dateRange',
    value: any,
    refetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    if (key === 'taskType') newFilters.pageNum = 0
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterUsersData(newFilters)
  }

  const onBlur = () => {
    filterUsersData(filters)
  }

  const onSelectTaskStatus = (index: number) => {
    setShowStatusTask(true)
    setSelectedTask(tasks[index])
  }

  const onCheck = (index: number) => {
    setShowMarkTask(true)
    setSelectedTask(tasks[index])
  }

  const onEdit = (index: number) => {
    setShowAddTask(true)
    setSelectedTask(tasks[index])
  }

  const onTrash = async (index: number) => {
    const task = tasks[index]
    const content = (
      <div className="mb-4">
        <p>Are you really sure want to delete?</p>
        <p>Task No: {task.no}</p>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    deleteTask(task.id)
      .then(() => {
        toast('Successfully removed', { type: 'success' })
        filterUsersData(filters)
      })
      .finally(() => setLoading(false))
  }

  const onRecoverDelete = async (index: number) => {
    const task = tasks[index]
    const content = (
      <div className="mb-4">
        <p>Are you really sure want to recover?</p>
        <p>Task No: {task.no}</p>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    recoverTask(task.id)
      .then(() => {
        toast('Successfully recovered', { type: 'success' })
        filterUsersData(filters)
      })
      .finally(() => setLoading(false))
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onDownload = () => {
    setDownloadRangeModal(true)
  }

  const onDownloadStart = ({ from, to }: { from: string; to: string }) => {
    const { projectId } = filters
    const link = `${appApiUrl}/task/download/${projectId}?token=${auth.token}&${serialize({
      description: filterObject(filters.description),
      assignedTo: filterObject(filters.assignedTo),
      query: filters.query,
      from,
      to,
    })}`
    window.open(link, '_blank')
    setDownloadRangeModal(false)
  }

  const hasFullPermission = useMemo(() => {
    return hasPermission('MANAGE_TASKS_FULL')
  }, [permissionData])

  const allTaskOptions = useMemo(() => {
    return hasFullPermission ? allTaskTypeOptions : {}
  }, [permissionData])

  const assignedTaskOptions = useMemo(() => {
    return hasFullPermission ? myAssignedTaskTypeOptions : {}
  }, [permissionData])

  return (
    <div
      className={`flex-auto shadow1 px-2 ${
        tasks.length == 0 ? 'overflow-visible' : 'overflow-auto min-h-[37rem]'
      } rounded mb-4`}
    >
      <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 border-b">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <div className="flex flex-col items-center">
            <span className="self-baseline text-xl font-semibold whitespace-nowrap dark:text-white">
              Project - {project.name}
              {isLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin ml-2" />}
            </span>
            <span>
              Created on {formatDate(project.createdAt)} by {project.user.email}
            </span>
          </div>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <button
                  className="flex py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white items-center"
                  aria-current="page"
                  onClick={onAddTask}
                >
                  <span className="text-shade-blue hover-shadow1 shadow flex items-center px-2 py-1 rounded">
                    <PlusCircleIcon className="w-4 h-4 mr-1" />
                    Add Task
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="md:px-2 py-2.5">
        <div className="flex justify-between items-center">
          <p className="text-xl px-2 font-bold mb-2">Tasks</p>

          <div className="flex items-center gap-4">
            {hasFullPermission && (
              <Toggle
                id="show-deleted"
                title="Trash"
                value={filters.showDeleted}
                onChange={(value) => onChangeFilter('showDeleted', value)}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-2 items-center justify-between">
          <div className="h-fit mb-3">
            <ButtonGroup
              title={{ ...taskTypeOptions, ...allTaskOptions, ...assignedTaskOptions }}
              value={filters.taskType}
              className="!px-2"
              onChange={(value) => onChangeFilter('taskType', value)}
            />
          </div>

          <div className="flex flex-wrap gap-2 mb-3">
            <div className="w-80">
              <InputDateRange
                title="Date Range"
                value={filters.dateRange}
                onChange={(value) => onChangeFilter('dateRange', value)}
              />
            </div>
            <div className="w-60">
              <MultiSelect
                id="search-description"
                title="Pipeline"
                options={descriptionList}
                sort={true}
                defaultOptionText="All"
                hasDefaultOption
                value={filters.description}
                defaultSelected={false}
                onChange={(value) => onChangeFilter('description', value, false)}
                onBlur={onBlur}
              />
            </div>
            {hasFullPermission && (
              <div className="w-60">
                <MultiSelect
                  id="search-status"
                  title="Assigned To"
                  options={assignedToList}
                  defaultOptionText="All"
                  hasDefaultOption
                  value={filters.assignedTo}
                  defaultSelected={false}
                  onChange={(value) => onChangeFilter('assignedTo', value, false)}
                  onBlur={onBlur}
                />
              </div>
            )}
            <div className="w-60">
              <Input2
                type="search"
                title="Search Tasks"
                hasIcon
                icon={svgSearch}
                value={filters.query}
                onChange={(value) => onChangeFilter('query', value)}
              />
            </div>
          </div>
        </div>

        <div className="shadow-md sm:rounded-lg mb-4">
          <TaskTable
            isLoading={isLoading}
            queryTimes={queryTimes}
            filters={filters}
            setFilters={setFilters}
            assignedToList={assignedToList}
            project={project}
            onSelectTaskStatus={onSelectTaskStatus}
            onCheck={onCheck}
            onEdit={onEdit}
            onTrash={onTrash}
            onRecoverDelete={onRecoverDelete}
            filterTasks={filterUsersData}
            tasks={tasks}
          />
        </div>

        <div className="flex justify-end items-center mt-3 mb-3">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={filters.pageNum}
          />
          <span className="my-2 mr-5 px-2 py-1 rounded hover-shadow1 cursor-pointer" onClick={onDownload}>
            <ArrowDownTrayIcon className="h-5 w-5 text-shade-blue" />
          </span>
        </div>

        {isShowAddTask && (
          <AddTaskModal
            onClose={onCloseModal}
            projectId={project.id}
            assignedToList={assignedToList}
            task={selectedTask as Task}
          />
        )}

        {isShowMarkTask && selectedTask && <MarkTaskModal onClose={onCloseModal} task={selectedTask} />}

        {isShowStatusTask && selectedTask && <StatusTaskModal onClose={onCloseModal} task={selectedTask} />}

        {isDownloadRangeModal && (
          <DownloadRangeModal
            title="Select range to download tasks"
            onClose={() => setDownloadRangeModal(false)}
            onDownload={onDownloadStart}
          />
        )}
      </div>
    </div>
  )
}
