import { EyeIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { filterSurvey } from 'services'
import { svgLoading } from 'stories/assets'
import { Input2, Pagination } from 'stories/components'
import { formatTime } from 'utils'

import { SurveyModal } from './SurveyModal'
import type { ISurvey } from './type'

export const Survey = () => {
  const [isLoading, setLoading] = useState(true)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ISurvey[]>([])
  const [currentItem, setCurrentItem] = useState<ISurvey | null>(null)

  useEffect(() => {
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  useEffect(() => {
    filterData(filters)
  }, [])

  const filterData = async (filters: Record<string, any>) => {
    setLoading(true)
    const { data, total } = await filterSurvey({
      query: filters.query.trim(),
      count: itemCountPerPage,
      skip: filters.pageNum * itemCountPerPage,
    })

    setData(data)
    setTotal(total)
    setLoading(false)
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterData(newFilters)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  return (
    <div className="Reviews-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Reviews
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>

      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={filters.query}
              onChange={(value) => onChangeFilter('query', value)}
            />
          </div>
          <p className="ml-5 text-[15px]">- {total} Surveys</p>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3">
                No
              </th>
              <th scope="col" className="px-2 py-3">
                Loan Number
              </th>
              <th scope="col" className="px-2 py-3">
                Name
              </th>
              <th scope="col" className="px-2 py-3">
                Email
              </th>
              <th scope="col" className="px-2 py-3">
                Created At
              </th>
              <th scope="col" className="px-2 py-3"></th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((item, index: number) => {
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-2 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {filters.pageNum * itemCountPerPage + index + 1}
                  </td>
                  <td className="px-2">
                    <Link
                      to={`/loan_process/overview/${item.loanNumber}`}
                      className="font-bold text-shade-blue hover:underline cursor-pointer"
                    >
                      {item.byteproFileName || item.loanNumber}
                    </Link>
                  </td>
                  <td className="px-2">{item.name}</td>
                  <td className="px-2">{item.email}</td>
                  <td className="px-2">{formatTime(item.createdAt)}</td>
                  <td className="px-2">
                    <div className="flex flex-wrap gap-2">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
                        onClick={() => setCurrentItem(item)}
                      >
                        <EyeIcon className="w-4 h-4" />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="footer flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
      </div>

      {!!currentItem && <SurveyModal data={currentItem} onClose={() => setCurrentItem(null)} />}
    </div>
  )
}
