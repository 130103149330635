import { ArrowDownTrayIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import moment from 'moment'
import { ClosingCalendarInfo } from 'pages/Loan/ClosingCalendar/ClosingCalendarInfo'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { downloadFile, getClosingCalendarDetails, signClosingCalendarPDF } from 'services'
import { Button } from 'stories/components'

export const SignClosingCalendar = () => {
  const urlParams: any = useParams()
  const { loanNumber, token } = urlParams
  const [isLoading, setLoading] = useState(true)
  const [isSigned, setSigned] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [description, setDescription] = useState('')
  const [loanInfo, setLoanInfo] = useState<Record<string, any>>({})
  const [signEmail, setSignEmail] = useState('')

  useEffect(() => {
    const payload: Record<string, any> = jwtDecode(token)
    setSignEmail(payload.email)
    getClosingCalendarDetails().then(({ loanInfo, desc, closingRequests }) => {
      setLoanInfo(loanInfo)
      setData(closingRequests)
      setDescription(desc)
      setLoading(false)
    })
  }, [])

  const onSubmit = async (sign: boolean) => {
    setLoading(true)

    const pdfData = await signClosingCalendarPDF({ signEmail, sign })
    downloadFile(`Closing Calendar ${loanNumber}(${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`, pdfData)

    if (sign) {
      toast('Signed Closing Calendar Successfully', { type: 'success' })
      setSigned(true)
    }
    setLoading(false)
  }

  return (
    <TemporaryLayout title="Sign Closing Calendar">
      <LayoutLoading show={isLoading} />
      <div>
        <div className="-mt-4">
          <Button onClick={() => onSubmit(false)} color="blue" link>
            <p className="flex items-center gap-2">
              Download unsigned Closing Calendar
              <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
            </p>
          </Button>
        </div>
        <ClosingCalendarInfo loanInfo={loanInfo} data={data as any} description={description} />
        {!isSigned ? (
          <Button onClick={() => onSubmit(true)} className="shade-blue" loading={isLoading}>
            Sign Closing Calendar
          </Button>
        ) : (
          <div
            className="my-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px] flex items-center"
            role="alert"
          >
            <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
            <span className="ml-1">Successfully Signed.</span>
          </div>
        )}
      </div>
    </TemporaryLayout>
  )
}
