import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { plainTextCostTypeItems } from 'pages/Loan/Budget/constants'
import { convertContent, convertPercent } from 'pages/Loan/Budget/logic'
import type { IBudgetData } from 'pages/Loan/Budget/types'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { createDrawRequest, getBudgetTemplate, getBudgetTemplateList } from 'services'
import { Alert, ButtonGroup, Checkbox, Modal } from 'stories/components'
import { hasDuplicatedData, InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'

export const CreateDrawRequestModal = ({ onClose }: { onClose: Function }) => {
  const [isLoading, setLoading] = useState(false)
  const [budgetItemMapping, setBudgetItemMapping] = useState<Record<string, string>>({})
  const [budget, setBudget] = useState<IBudgetData>()
  const [currentId, setCurrentId] = useState(0)
  const [addressMap, setAddressMap] = useState<Record<string, string>>({})
  const [checked, setChecked] = useState<string[]>([])
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [step, setStep] = useState(0)
  const [rehabFinancedPercent, setRehabFinancedPercent] = useState(1)

  useEffect(() => {
    init()
  }, [currentId])

  const maxAmount = useMemo(() => {
    if (!budget) return 0

    let maxAmount = 0
    budget.items.map((item) => {
      Object.keys(item.data)
        .filter((v) => checked.includes(`${item.value}-${v}`))
        .forEach((key) => {
          const { lenderDisbursedAmount, rehabCostBorrowerEstimated } = item.data[key]
          const amount = Number(rehabCostBorrowerEstimated) * rehabFinancedPercent - Number(lenderDisbursedAmount || 0)

          maxAmount += amount
        })
    })
    return maxAmount
  }, [budget, checked])

  const init = async () => {
    if (!currentId) {
      const { addresses } = await getBudgetTemplateList()
      setAddressMap(addresses)
      const ids = Object.keys(addresses)
      if (ids.length) setCurrentId(Number(ids[0]))
      return
    }

    setLoading(true)
    const res = await getBudgetTemplate(currentId)

    setBudgetItemMapping(res.itemMapping)
    setBudget(res.budget.data)
    setRehabFinancedPercent(res.rehabFinancedPercent)
    setLoading(false)
  }

  const onCheck = (key: string) => {
    const newChecked = cloneDeep(checked)
    if (checked.includes(key)) newChecked.splice(newChecked.indexOf(key), 1)
    else newChecked.push(key)
    setChecked(newChecked)
  }

  const onSubmit = async () => {
    if (step == 0) {
      setStep(1)
      return
    }

    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      if (newInputs[key].value && newInputs[key].value.trim) newInputs[key].value = newInputs[key].value.trim()
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    data.amount = removeComma(data.amount)

    if (!newInputs.amount.error && data.amount > maxAmount) {
      newInputs.amount.error = `The amount being requested may not exceed ${convertContent(maxAmount)}.`
      hasError = true
    }

    if (hasError) {
      setInputs(newInputs)
      return
    }

    setLoading(true)
    await createDrawRequest({
      ...data,
      budgetId: Number(currentId),
      type: checked,
    })
    setLoading(false)
    onClose(true)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].error = ''
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)
  }

  const renderTable = () => {
    if (!budget) return null

    const isFirstStep = step == 0

    let totalIndex = 1
    return (
      <>
        {isFirstStep && <ButtonGroup title={addressMap} value={`${currentId}`} onChange={(v) => setCurrentId(v)} />}
        <table className="table relative w-full text-sm text-left">
          <thead>
            <tr className="bg-gray-100 font-semibold">
              <th className="px-2 py-1 border text-center">No</th>
              <th className="px-2 py-1 border">Draw / Permits</th>
              <th className="px-2 py-1 border">Rehab Cost Borrower Estimated Amount</th>
              <th className="px-2 py-1 border">Lender Financed Amount</th>
              <th className="px-2 py-1 border">Rehab % Lender Approved</th>
              <th className="px-2 py-1 border">Rehab Cost Lender Approved</th>
              <th className="px-2 py-1 border">Lender Disbursed Amount</th>
              <th className="px-2 py-1 border">Amount Available</th>
              <th className="px-2 py-1 border">Work to Be Performed</th>
            </tr>
          </thead>
          <tbody>
            {budget.items.map((item, index) => {
              if (!isFirstStep) {
                const keys = Object.keys(item.data).map((v) => `${item.value}-${v}`)
                if (!hasDuplicatedData(keys, checked)) return null
              }

              return (
                <Fragment key={index}>
                  <tr className="bg-gray-100 font-semibold">
                    <td className="px-2 py-1 border"></td>
                    <td className="px-2 py-1 border text-left" colSpan={8}>
                      {item.label}
                    </td>
                  </tr>
                  {Object.keys(item.data)
                    .filter((v) => isFirstStep || checked.includes(`${item.value}-${v}`))
                    .sort()
                    .map((key: string, id) => {
                      const data = item.data[key]
                      const isChecked = checked.includes(`${item.value}-${key}`)
                      const hasAvailable =
                        Number(data.rehabCostBorrowerEstimated) > Number(data.rehabCostLenderApproved) &&
                        Number(data.rehabCostBorrowerEstimated) * rehabFinancedPercent >
                          Number(data.lenderDisbursedAmount || 0)

                      return (
                        <tr key={`${index}-${id}`} className={isChecked && isFirstStep ? 'bg-yellow-100' : ''}>
                          <td
                            className={`px-2 py-0 border ${data.lockData && 'bg-gray-200/75'} ${
                              data.highlight && 'bg-yellow-100'
                            }`}
                          >
                            {isFirstStep && hasAvailable ? (
                              <Checkbox
                                id={`check-${key}`}
                                onClick={() => onCheck(`${item.value}-${key}`)}
                                value={isChecked}
                                title={`${totalIndex++}`}
                              />
                            ) : (
                              <span className="pl-6">{totalIndex++}</span>
                            )}
                          </td>
                          <td
                            className={`relative group px-2 py-1 border ${data.highlight && 'bg-yellow-100'} ${
                              plainTextCostTypeItems.includes(key) ? 'bg-gray-100' : ''
                            }`}
                          >
                            {data.title || budgetItemMapping[key]}
                          </td>

                          <td className={`px-2 py-0 border`}>{convertContent(data.rehabCostBorrowerEstimated)}</td>

                          <td className={`px-2 py-0 border`}>
                            {convertContent(data.rehabCostBorrowerEstimated * rehabFinancedPercent)}
                          </td>

                          <td className={`px-2 py-0 border`}>
                            {convertPercent(data.rehabCostBorrowerEstimated, data.rehabCostLenderApproved, true)}
                          </td>

                          <td className={`px-2 py-0 border`}>{convertContent(data.rehabCostLenderApproved)}</td>

                          <td className={`px-2 py-0 border`}>{convertContent(data.lenderDisbursedAmount)}</td>

                          <td className={`px-2 py-0 border`}>
                            {!!data.rehabCostBorrowerEstimated &&
                              convertContent(
                                data.rehabCostBorrowerEstimated * rehabFinancedPercent -
                                  (data.lenderDisbursedAmount || 0),
                              )}
                          </td>

                          <td className={`px-2 py-0 border`}>{data.description}</td>
                        </tr>
                      )
                    })}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }

  const renderForm = () => {
    if (!budget) return null

    return (
      <>
        {renderTable()}

        <Alert className="my-4">
          You are requesting an inspection for the following items that have been completed.
        </Alert>

        <div className="grid grid-cols-2 gap-4">
          {Object.keys(inputs).map((key) => (
            <RenderInput Key={key} input={inputs[key]} onChange={onChange} />
          ))}
        </div>
      </>
    )
  }

  const isDisabled = useMemo(() => {
    if (step == 0) return !checked.length
    return false
  }, [checked])

  return (
    <Modal
      isOpen
      title="Create Draw Requests"
      onOk={onSubmit}
      onClose={() => onClose(false)}
      titleOkay={step == 0 ? 'Submit' : 'Process inspection requests'}
      disabled={isDisabled}
      loading={isLoading}
    >
      <>
        <div className="max-w-4xl overflow-y-auto">
          {step == 0 && renderTable()}
          {step == 1 && renderForm()}
        </div>
      </>
    </Modal>
  )
}
