import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { getPostClosingFields, updatePostClosingFields } from 'services'
import { Select2 } from 'stories/components'

export function ServicerChoose(props: any) {
  const [data, setData] = useState<Array<any>>([])
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const options = useMemo(() => {
    let rlt: any = {}
    data.map((item) => {
      rlt[item.id] = `${item.investorCode} - ${item.lossPayeeName}`
    })
    return rlt
  }, [data])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const res = await getPostClosingFields('servicerChoose')
      setData(res.data)
      setValue(res.servicerName)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    data.map((item: any) => {
      if (item.id == value) {
        props.initServicerChoose(item)
      }
    })
  }, [value])

  const onChange = async (val: string) => {
    setValue(val)
    setLoading(true)
    await updatePostClosingFields('servicerName', { value: val })
    setLoading(false)
  }

  return (
    <div className="servicerChoose-container relative">
      <LayoutLoading show={loading} />
      <Select2
        id={'ServicerCompany'}
        title={'Select Servicer'}
        options={options}
        key={'ServicerCompany'}
        sort={true}
        value={value}
        hasDefaultOption={true}
        onChange={(value) => onChange(value)}
      />
    </div>
  )
}
