import { BaseLayout } from 'layouts'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Switch } from 'react-router-dom'
import { BaseRoute, TemporaryRoute } from 'routes'
const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        {TemporaryRoute}
        <BaseLayout>
          <BaseRoute />
        </BaseLayout>
      </Switch>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
