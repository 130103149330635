export const Alert = ({
  children,
  color = 'blue',
  className = '',
}: {
  children: JSX.Element | string
  color?: string
  className?: string
}) => {
  return (
    <p className={`text-${color}-900 bg-${color}-50 border border-${color}-400 rounded-lg p-2 ${className}`}>
      {children}
    </p>
  )
}
