import { API_CLOSING_CALENDAR, API_CLOSING_CALENDAR_DETAILS, API_CLOSING_CALENDAR_SIGN_EMAIL } from 'config'
import Api from 'services/api'

export const getClosingCalendar = () => {
  return Api.get(API_CLOSING_CALENDAR)
}

export const addClosingCalendar = (data: Record<string, any>) => {
  return Api.post(API_CLOSING_CALENDAR, data)
}

export const sendClosingCalendarSignEmail = (data: Record<string, any>) => {
  return Api.post(API_CLOSING_CALENDAR_SIGN_EMAIL, data)
}

export const getClosingCalendarDetails = () => {
  return Api.get(API_CLOSING_CALENDAR_DETAILS)
}
