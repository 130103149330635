import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Modal } from 'stories/components'

export const DownloadTypeDialog = ({
  isBalance,
  onSubmit,
  onClose,
}: {
  isBalance: boolean
  onSubmit: Function
  onClose: Function
}) => {
  const strType = isBalance ? 'Balance Sheet' : 'P&L'

  return (
    <Modal
      isOpen
      title={`Download ${strType}`}
      onOk={() => onSubmit()}
      onClose={() => onClose(null, [])}
      titleOkay=""
      titleCancel=""
    >
      <div className="w-fit relative">
        <p className="mb-4">Select download type.</p>
        <div className="grid w-128 grid-cols-1 gap-2 mb-8">
          {[`Detailed ${strType}`, `Summary ${strType}`].map((v, index) => (
            <div
              className={`border rounded-md overflow-hidden p-4 cursor-pointer hover:border-shade-blue`}
              onClick={() => onSubmit(index)}
            >
              <div className="flex justify-between items-center">
                <p className="font-semibold">
                  {index + 1}. {v}
                </p>
                <ArrowDownTrayIcon className="w-4 h-4 text-shade-blue" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}
