import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { setBorrowerGroupData, setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { COMPANY_NAME_FC } from 'config'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { store } from 'reducers'
import { downloadFile, getLoanSignAppPdf, uploadSignApplicationPdf } from 'services'
import { Button, Modal, Toggle } from 'stories/components'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { commercialInputs } from './constants'
import { signAppValidate } from './logic'

export const ConfirmSignDialog = (props: any) => {
  const { loanDetail } = store.getState()
  const [isReviewed, setReviewed] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [comInputs, setComInputs] = useState(commercialInputs())

  const dispatch = useDispatch()

  useEffect(() => {
    const { restructureApplicationRelease } = loanDetail

    if (!restructureApplicationRelease) {
      dispatch(
        setLoanDetail({
          restructureApplicationRelease: true,
        }),
      )
    }
  }, [])

  const onDownloadPdf = async () => {
    setLoading(true)
    const data: Blob = await getLoanSignAppPdf(getCommercialValues())
    downloadFile(`${COMPANY_NAME_FC} Application (${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`, data)
    setLoading(false)
  }

  const getCommercialValues = () => {
    if (!isCommercialDscr) return null

    const values: Record<string, any> = {}
    Object.keys(comInputs).forEach((key) => {
      values[key] = comInputs[key].value || ''
    })
    return values
  }

  const onChangeCommercialInputs = (key: string, value: string) => {
    const newInputs = cloneDeep(comInputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setComInputs(newInputs)
  }

  const onConfirmSign = async () => {
    let hasError = false
    const newStats = cloneDeep(comInputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (isCommercialDscr && hasError) {
      setComInputs(newStats)
      return
    }

    setLoading(true)
    const { signedPdfKey } = await uploadSignApplicationPdf(inputData)

    const data = { signedDate: `${Date.now()}`, signedPdfKey }

    await dispatch(setBorrowerGroupData('borrower', data))
    signAppValidate('borrower', true)
    await dispatch(
      setLoanDetail({
        restructureApplicationRelease: false,
      }),
    )
    toast('Signed application successfully. You can download the signed document', { type: 'success' })
    window.scrollTo(0, 0)

    setTimeout(() => {
      if (props.changeBorrowerMenu) props.changeBorrowerMenu()
    }, 1000)

    setLoading(false)
    props.onClose()
  }

  const isCommercialDscr = loanDetail.rateData.program === 'Commercial DSCR'

  return (
    <Modal
      isOpen
      title="Confirm and Sign Application"
      titleOkay="Confirm and Sign"
      disabled={!isReviewed}
      loading={isLoading}
      onClose={props.onClose}
      onOk={onConfirmSign}
    >
      <div
        className={`text-gray-600 mb-4 text-md overflow-hidden w-[750px] ${
          isCommercialDscr ? `h-[512px]` : `h-[160px]`
        }`}
      >
        <p className="font-bold">
          Once your application is signed, you will not have the functionality to edit the application and loan
          structure
        </p>
        <p className="text-sm">- Pricing is subject to change if terms of the transaction change.</p>

        {isCommercialDscr && (
          <div className="my-5 mx-1 shadow1 p-4 bg-light rounded">
            <div className="">
              Prior to signing the application, please input payment information for the appraisal associated with your
              transaction. <br />
              The cost will be <span className="font-bold">$2,900</span>.
            </div>
            <div className="grid gap-4 grid-cols-2 my-4">
              {Object.keys(comInputs).map((key, index) => {
                const input = comInputs[key]
                return (
                  <div key={index} className="input col-span-1">
                    <RenderInput input={input} Key={key} onChange={onChangeCommercialInputs} />
                  </div>
                )
              })}
            </div>
            <div className="mt-2 mb-2 font-weight-bold ml-2 fz-14 color1">
              Note: Your account will only be debited these funds when your broker submits your loan for underwriting.
            </div>
          </div>
        )}
        <div className="text-sm bg-gray-100 px-2 pb-4 rounded flex gap-4">
          <div className="ml-1">
            <Button link onClick={onDownloadPdf}>
              <div className="flex items-center underline text-gray-900 hover:text-shade-blue">
                - View Unsigned Application PDF{' '}
                <span className="ml-2">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </span>
              </div>
            </Button>
            <Toggle
              id="reviewed"
              title="I have reviewed my application"
              onChange={(value) => setReviewed(value)}
              value={isReviewed}
            />
          </div>
          {props.signUrl && (
            <div
              className="ml-6 w-[400px] bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mt-4 text-[14px]"
              role="alert"
            >
              Upon successful signing of this document, you will be redirected to the documents upload page.
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
