import { SET_LOAN_LTV_MAX_LIMIT } from 'actions/types'

import { createReducer } from '../utils'

const getInitialState = () => {
  return {}
}

export const ltvMaxLimit = createReducer(getInitialState(), {
  [SET_LOAN_LTV_MAX_LIMIT]: (state: any, payload: any) => {
    return payload.data
  },
})
