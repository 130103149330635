import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { submitEmailTemplate } from 'services/apis/conditions'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { getPartiesMap, getUnknownKeyInPartiesEmailTemplate, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { EmailTemplate } from '.'
import { defaultInputs } from './constants'

interface EmailTemplateDetailsProps {
  defaults: EmailTemplate | null
  onBack: Function
  onComplete: Function
}

export function EmailTemplateDetails(props: EmailTemplateDetailsProps) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const { defaults = null } = props

  useEffect(() => {
    onUpdateDefaults()
  }, [defaults])

  const onUpdateDefaults = async () => {
    if (!defaults) {
      setInputs(defaultInputs())
      return
    }
    const newStats = cloneDeep(defaultInputs())
    for (const key in newStats) {
      newStats[key].value = (defaults as any)[key]
    }
    setInputs(newStats)
  }

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (value !== undefined) data[key] = value
    }

    if (hasError) {
      setInputs(newStats)
      return
    }
    const { type, content } = data
    const unknownKey = getUnknownKeyInPartiesEmailTemplate(content)
    if (unknownKey) {
      toast(`${unknownKey} is unknown variable.`, { type: 'warning' })
      return
    }

    setLoading(true)
    const id = defaults ? defaults.id : 0

    submitEmailTemplate(id, {
      type,
      content,
    })
      .then(() => {
        if (id == 0) toast('New Email Template is added.', { type: 'success' })
        else toast(`Email Template "${defaults?.type}" is updated.`, { type: 'success' })
        props.onComplete()
        onBack()
      })
      .catch(() => setLoading(false))
  }

  const placeholders = getPartiesMap({})

  return (
    <div className="email-template-container">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Email Template - {!defaults ? 'Add' : `Update`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-1" /> <p>Return to Email Template</p>
        </div>
      </Button>

      <div className="my-4">
        <div className="border-b mb-2">- Placeholders</div>
        <pre className="flex gap-1 flex-wrap">
          {Object.keys(placeholders).map((key, index) => {
            return (
              <div key={index} className="mr-2">
                {key},{' '}
              </div>
            )
          })}
        </pre>
      </div>
      <div className="mb-3">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          if (key === 'type' && defaults) input.disabled = true
          return (
            <div
              className={`mb-4 input ${input.span ? 'col-span-1 lg:col-span-4 md:col-span-3 sm:col-span-2' : ''}`}
              key={index}
            >
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>

      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'}</>
        </Button>
      </div>
    </div>
  )
}
