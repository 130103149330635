import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { EmailValue, LoanPartiesEmailTo } from 'components/EmailTo'
import { COMPANY_DNS, COMPANY_TITLE, InputType, ProductTypes, SettingKey, TimeZone } from 'config'
import moment from 'moment-timezone'
import { convertNullValue } from 'pages/Resources/GuidelineOverlay/logic'
import { IUnderwritingFee } from 'pages/Resources/UnderwritingFee'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { downloadFile, getSetting, loanTermSheetPdf } from 'services'
import { Button, Modal } from 'stories/components'
import {
  formatDate,
  formatDateYMD,
  getLoanTerm,
  getPrice3decimal,
  InputConvert,
  InputValidate,
  removeComma,
} from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { termSheetFields } from './constant'
import { type ILoan, type ILoanDetail, type ILoanProcess, type ITermSheetPlaceholders, LoanType } from './interfaces'
import { calculateLoanLimits, estimatedClosingAmountBorrower, getUWFee, isNonDSCRProduct } from './Logic'

interface IProps {
  loanNumber: string
  loan: ILoan
  loanDetail: ILoanDetail
  loanProcessData: ILoanProcess
  isOpen: boolean
  onClose: () => void
}

export function TermSheetDialog(props: IProps) {
  const { isOpen, loan, loanDetail, loanProcessData, loanNumber } = props

  const [inputs, setInputs] = useState<Record<string, InputType>>(termSheetFields())
  const [loading, setLoading] = useState(false)
  const [notifiers, setNotifiers] = useState<EmailValue>({})
  const [errMsg, setErrMsg] = useState('')
  const [underwritingFees, setUnderwritingFees] = useState<IUnderwritingFee[]>([])

  const proposedLoanAmount = removeComma(loan.proposedLoanAmount)

  const isRequiredMinCommitmentFee = !!loanDetail.loanGlobalSettings.minCommitmentFeeRequiredTypes.find(
    (item) => item.productType === loan.productType && item.transactionType === loan.transactionType,
  )
  const { loanGlobalSettings, parties } = loanDetail
  let { showingTermSheetPlaceholders } = loanGlobalSettings

  if (!isRequiredMinCommitmentFee)
    showingTermSheetPlaceholders = showingTermSheetPlaceholders.filter((item) => item !== 'CommitmentFee')

  useEffect(() => {
    getSetting(SettingKey.UNDERWRITING_FEE).then(({ value }) => {
      const content = JSON.parse(value || '[]')
      setUnderwritingFees(content)
    })
  }, [])

  const { email } = useSelector((state: any) => state.auth.profile)
  const isCompanyEmail = useMemo(() => (email || '').endsWith(`@${COMPANY_DNS}`), [email])

  useEffect(() => {
    const newNotifiers: any = cloneDeep(notifiers)
    if (isCompanyEmail) newNotifiers.from = 'self'
    else newNotifiers.from = 'default'
    setNotifiers(newNotifiers)

    const limits = calculateLoanLimits(loan)
    let LTV = limits.arv_ltv
    if ([ProductTypes.Bridge, ProductTypes.DSCR].includes(loan.productType as any)) {
      LTV = limits.aiv_ltv
    }

    let brokerUser: any = {
      name: '',
      company: '',
      street: '',
      city: '',
      state: '',
      zip: '',
    }
    if (parties?.broker?.name) brokerUser = parties.broker
    else if (parties?.creator?.name) brokerUser = parties.creator

    const isTableOrCor = [LoanType.CORRESPONDENT, LoanType.TABLEFUNDER].includes(loan.type)
    const LenderCompany = isTableOrCor ? parties.creator.companyName : COMPANY_TITLE
    const loanOfficerName = parties?.loanOfficer?.name || null

    const discountFeePercent = (100 - Number(loanProcessData.price)).toFixed(4)
    const discountFeeAmount = (removeComma(discountFeePercent) * removeComma(loan.proposedLoanAmount)) / 100

    const placeholders: ITermSheetPlaceholders = {
      LoanAmount: getPrice3decimal(loan.proposedLoanAmount),
      LTV: LTV,
      BorrowerName: `${loan.borrowerFirstName} ${loan.borrowerLastName}`,
      BrokerStreet: brokerUser.street,
      BrokerCity: brokerUser.city,
      BrokerState: brokerUser.state,
      BrokerZipCode: brokerUser.zip,
      LenderCompany: LenderCompany,
      SubjectPropertyAddress: loan.subjectPropertyAddress,
      InterestRate: `${loanProcessData.rate}`,
      LoanTerm: `${getLoanTerm(loanProcessData.type, loan.productType, loan.loanTerm)}`,
      DiscountFeePercent: discountFeePercent,
      DiscountFeeAmount: `${discountFeeAmount}`,
      CashBorrowerAtClosing: `${estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings).value}`,
      ExitFee: `${isNonDSCRProduct(loan.productType) ? convertNullValue(loanProcessData.exitFee) : 'None'}`,
      ConstructionBudget: `${loan.rehabBudget}`,
      ConstructionAmountFinanced: `${loan.constructionReserve}`,
      BrokerFeeAmount: '',
      BrokerFeePercent: '',
      BrokerCompanyName: brokerUser.companyName,
      UnderwritingFee: `${getUWFee(loan, underwritingFees, loanGlobalSettings)}`,
      BorrowerType: loan.borrowerType,
      FiveLaterDate: moment().tz(TimeZone).add(5, 'days').format('MM/DD/YYYY'),
      TodayDate: formatDate(),
      LoanOfficerOrBrokerName: loanOfficerName ? loanOfficerName : brokerUser.name,
      EstimatedCreditScore: `${loan.estimatedCreditScore}`,
      CommitmentFee: ``,
      BreakUpFee: ``,
    }

    const newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = (placeholders as any)[key]
    })
    setInputs(newInputs)
  }, [loanDetail, loan])

  useEffect(() => {
    validate()
  }, [inputs])

  const validate = () => {
    const { minBrokerFeeAmount, minCommitmentFeeRequiredTypes, minBrokerFeePercent, maxBrokerFeePercent } =
      loanDetail.loanGlobalSettings
    const minCommitmentFee = minCommitmentFeeRequiredTypes.find(
      (item) => item.productType === loan.productType && item.transactionType === loan.transactionType,
    )?.minCommitmentFee
    const brokerFeeAmount = removeComma(inputs['BrokerFeeAmount'].value)
    const brokerFeePercent = removeComma(inputs['BrokerFeePercent'].value)
    const commitmentFeeAmount = removeComma(inputs['CommitmentFee'].value)

    if (brokerFeeAmount < minBrokerFeeAmount) {
      setErrMsg(`Broker Fee Amount can not be lower than $${minBrokerFeeAmount}`)
      return true
    }
    if (brokerFeePercent < minBrokerFeePercent) {
      setErrMsg(`Broker Fee Amount can not be lower than ${minBrokerFeePercent}% of Loan Amount`)
      return true
    }
    if (brokerFeePercent > maxBrokerFeePercent) {
      setErrMsg(`Broker Fee Amount can not be greater than ${maxBrokerFeePercent}% of Loan Amount`)
      return true
    }

    if (isRequiredMinCommitmentFee && minCommitmentFee !== undefined && commitmentFeeAmount < minCommitmentFee) {
      setErrMsg(`Commitment Fee Amount can not be lower than $${minCommitmentFee}`)
      return true
    }
    setErrMsg('')
    return false
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value

    if (key === 'BrokerFeeAmount') {
      newInputs['BrokerFeePercent'].value = Number(((removeComma(value) * 100) / proposedLoanAmount).toFixed(3))
      newInputs['BrokerFeePercent'].error = ''
    }
    if (key === 'BrokerFeePercent') {
      newInputs['BrokerFeeAmount'].value = (removeComma(loan.proposedLoanAmount) * removeComma(value)) / 100
      newInputs['BrokerFeeAmount'].error = ''
    }

    setInputs(newInputs)
  }

  const onClose = () => {
    setNotifiers({})
    setErrMsg('')
    props.onClose()
  }

  const onSubmit = async (sendEmail = true) => {
    let newInputs = cloneDeep(inputs)
    let hasError = false
    Object.keys(newInputs).forEach((key) => {
      if (!isRequiredMinCommitmentFee && key === 'CommitmentFee') return
      newInputs[key].error = InputValidate(newInputs[key])
      if (newInputs[key].error) hasError = true
    })

    if (hasError) {
      setInputs(newInputs)
      return
    }

    hasError = await validate()
    if (hasError) return

    const data: Record<string, any> = {}
    showingTermSheetPlaceholders.forEach((key) => {
      data[key] = inputs[key].value
    })

    data.CashBorrowerAtClosing = estimatedClosingAmountBorrower(loan, loanDetail.loanGlobalSettings).value

    setLoading(true)
    const reqBody = {
      data,
      emailTo: sendEmail ? notifiers : {},
    }

    const pdfData = await loanTermSheetPdf(reqBody)
    downloadFile(`Loan Term Sheet ${loanDetail.loanNumber}(${formatDateYMD()}).pdf`, pdfData)

    setLoading(false)
    if (!sendEmail) return

    if (Object.values(notifiers).includes('to')) {
      onClose()
      toast('Email with sign request is sent successfully', { type: 'info' })
      return true
    }
  }

  return (
    <>
      <Modal
        button={<span></span>}
        title={'Generate Loan Term Sheet'}
        titleOkay={`Send Sign Request`}
        titleCancel="Close"
        loading={loading}
        isOpen={isOpen}
        disabled={!Object.values(notifiers).includes('to')}
        lastUpdatedAt={Date.now()}
        onClose={onClose}
        onOk={onSubmit}
      >
        <div>
          <div className="-mt-6 -mb-2">
            <Button onClick={() => onSubmit(false)} color="blue" link>
              <p className="flex items-center gap-2">
                Download unsigned loan term sheet
                <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
              </p>
            </Button>
          </div>

          {errMsg && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]"
              role="alert"
            >
              {errMsg}
            </div>
          )}

          <div
            className={`relative grid grid-cols-1 ${
              showingTermSheetPlaceholders.length <= 6 ? '' : 'sm:grid-cols-2 md:grid-cols-3'
            } gap-4`}
          >
            {showingTermSheetPlaceholders.map((key, index) => {
              let input = inputs[key]

              return (
                <div className={`input col-span-1 md:col-span-${input.span || 1} `} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>

          <div className="mt-4">
            <LoanPartiesEmailTo
              loanNumber={Number(loanNumber)}
              value={notifiers}
              data={[]}
              onChange={setNotifiers}
              loadParties
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
