import { ArrowDownTrayIcon, BarsArrowDownIcon, BarsArrowUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { docCategory } from 'pages/LoanSubmission/constants'
import type { LoanSubmissionDocument } from 'pages/LoanSubmission/types'
import { useEffect, useMemo, useState } from 'react'
import { getLoanSubmissionDocuments, openS3Document } from 'services'
import { Checkbox, Input2, Modal, MultiSelect } from 'stories/components'

export const PushDocumentsDialog = ({ onClose, onSubmit }: { onClose: Function; onSubmit: Function }) => {
  const defaultCategories: Record<string, boolean> = {}

  docCategory.forEach((v) => (defaultCategories[v] = true))

  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [category, setCategory] = useState<Record<string, boolean>>(defaultCategories)
  const [documents, setDocuments] = useState<LoanSubmissionDocument[]>([])
  const [selected, setSelected] = useState<Record<string, boolean>>({})
  const [sortKey, setSortKey] = useState('')
  const [sortDir, setSortDir] = useState(true)

  useEffect(() => {
    getLoanSubmissionDocuments()
      .then((res) => {
        if (res.success) setDocuments(res.data.map((doc: any) => ({ ...doc, key: doc.fileKey })))
      })
      .finally(() => setLoading(false))
  }, [])

  const sortedDocuments = useMemo(() => {
    const query = searchQuery.trim().toLowerCase()
    const selectedCategories = docCategory.filter((v) => category[v] !== false)
    let docs = documents
      .filter((doc) => doc.status == 'Approved')
      .filter((doc) => selectedCategories.includes(doc.category || ''))
      .filter((doc) => {
        if (!query) return true
        return doc.name.toLowerCase().includes(query) || doc.category.toLowerCase().includes(query)
      })
      .sort((a: any, b: any) => {
        const aV = a[sortKey]
        const bV = b[sortKey]
        if (aV > bV) return 1
        if (aV == bV) return 0
        return -1
      })
    if (sortDir) docs = docs.reverse()
    return docs
  }, [documents, sortDir, sortKey, searchQuery, category])

  const onSort = (key: string) => {
    setSortKey(key)
    if (sortKey == key) setSortDir(!sortDir)
  }

  const onSelected = (key: string, value: boolean) => {
    const newData = cloneDeep(selected)
    if (!value) delete newData[key]
    else newData[key] = value
    setSelected(newData)
  }

  const renderHeader = (key: string, title: any) => {
    return (
      <div className="flex flex-wrap font-normal items-center">
        <p>{title}</p>

        <span className="ml-2">
          {sortKey == key &&
            (sortDir ? <BarsArrowUpIcon className="w-3 h-3" /> : <BarsArrowDownIcon className="w-3 h-3" />)}
        </span>
      </div>
    )
  }

  const renderRow = (document: LoanSubmissionDocument, index: number) => {
    return (
      <tr className={`bg-gray-50`} key={`first-${document.key}`}>
        <td className="px-3 py-2">
          <Checkbox
            id={`condition-no-${document.key}`}
            title={`${index + 1}. ${document.name}`}
            checked={!!selected[document.key]}
            onChange={(value) => onSelected(document.key, value)}
          />
        </td>
        <td className="px-2">
          <span className="text-[13.5px]">{document.category}</span>
        </td>
        <td className="px-2">
          <button
            className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center"
            onClick={() => openS3Document(document.key, true)}
          >
            <ArrowDownTrayIcon className="w-4 h-4" />
          </button>
        </td>
      </tr>
    )
  }

  return (
    <Modal
      title={'Select Documents to push to Finresi.'}
      titleOkay="Push"
      titleCancel="Close"
      loading={loading}
      isOpen
      onClose={() => onClose()}
      onOk={() => onSubmit(Object.keys(selected))}
    >
      <div className="w-196">
        <div className="grid grid-cols-2 w-full h-fit mb-2 gap-2">
          <Input2
            type="search"
            title="Search"
            hasIcon
            icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
          />
          <MultiSelect
            title="Category"
            options={docCategory}
            id="category"
            value={category}
            onChange={(v) => setCategory(v)}
          />
        </div>
        <div className="relative overflow-y-auto max-h-[calc(90vh-200px)]">
          <table className="w-full font-normal text-sm text-left text-black">
            <thead className="border-b">
              <tr>
                <th
                  className="bg-gray-100 px-2 py-2 border-b border-slate-300 cursor-pointer"
                  onClick={() => onSort('name')}
                >
                  {renderHeader('name', 'Name')}
                </th>
                <th
                  className="bg-gray-100 px-2 border-r border-slate-300 cursor-pointer"
                  onClick={() => onSort('category')}
                >
                  {renderHeader('category', 'Category')}
                </th>
                <th className="bg-gray-100 px-2 font-normal">Action</th>
              </tr>
            </thead>

            <tbody className="text-gray-900">
              {sortedDocuments.map((document: any, index: number) => renderRow(document, index))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
