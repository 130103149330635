import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  ClockIcon,
  CloudArrowUpIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { TargetBox } from 'components/DragDrop'
import { PlainInput } from 'components/PlainInput'
import type { BaseFile } from 'config'
import { acceptedFileTypes } from 'config/file.type'
import { docCategory, docStatus } from 'pages/LoanSubmission/constants'
import { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import { toast } from 'react-toastify'
import { downloadS3Document, openS3Document, uploadFiles } from 'services'
import { svgLoading } from 'stories/assets'
import { confirm, formatTime, hasFileExt } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { Select } from '../Select/Select'
import { Tooltip } from '../Tooltip/Tooltip'

export interface DocumentFile extends BaseFile {
  status: string
  category: string
  [key: string]: any
}

interface InputFileTableProps {
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string

  filePath: string
  /**
   * Is has icon
   */
  hasIcon?: boolean
  /**
   * Required
   */
  required?: boolean
  /**
   * Icon component
   */
  icon?: string | JSX.Element | null

  multiple?: boolean

  acceptFileTypes?: string

  history?: boolean

  value?: DocumentFile[]

  showCategory?: boolean

  showStatus?: boolean

  uploadable?: boolean

  disableNameChange?: boolean

  defaultCategory?: string

  narrow?: boolean
  categories?: Array<string>
  customFields?: Array<any>
  additionalActions?: JSX.Element[]
  onChange: (e: DocumentFile[]) => void
  showHistory?: () => void
}

export const renderFileList = (files: BaseFile[]) => {
  return (
    <div>
      {files.map((file) => (
        <p
          className="text-sm text-shade-blue hover:underline flex items-start gap-2 cursor-pointer"
          onClick={() => openS3Document(file.fileKey)}
        >
          <ArrowDownTrayIcon className="w-4 h-4" />
          <span className="flex-1">{file.name}</span>
        </p>
      ))}
    </div>
  )
}

export const InputFileTable = ({
  tooltip = '',
  title = '',
  name = '',
  error = '',
  className = '',
  filePath: _filePath = '',
  required,
  multiple,
  acceptFileTypes = 'application/pdf',
  history,
  value = [],
  showStatus = true,
  showCategory = true,
  uploadable = true,
  disabled = false,
  disableNameChange = false,
  defaultCategory = 'TRAILING DOCS',
  narrow = false,
  categories = [],
  customFields = [],
  additionalActions = [],
  onChange = () => {},
  showHistory = () => {},
}: InputFileTableProps) => {
  if (!name) name = `file-input-${Date.now()}`

  const [isLoading, setLoading] = useState(false)
  const [filePath, setFilePath] = useState('')

  if (disabled) uploadable = false

  useEffect(() => {
    setLoading(false)
    if (_filePath) setFilePath(_filePath)
    else {
      const loanNumber = setLoanNumber()
      setFilePath(`loan/${loanNumber}/document`)
    }
  }, [_filePath])

  const onDropDocument = async (document: any) => {
    if (document.files) {
      uploadDocumentFiles(document.files)
    } else {
      let exist = false
      value.map((item) => {
        if (item.fileKey === document.fileKey) exist = true
      })
      if (!exist)
        value.push({
          ...document,
        })
      onChange(value)
    }
  }

  const onUploadNewfile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files.length == 0) return
    const arrFile: Array<File> = []
    for (let i = 0; i < files.length; i++) arrFile.push(files[i])

    uploadDocumentFiles(arrFile)
  }

  const uploadDocumentFiles = (files: File[]): Promise<any> => {
    return new Promise((resolve) => {
      const data = {
        path: filePath,
      }
      let acceptedFiles: any = []
      files.map((file) => {
        const { name } = file
        const params = name.toLowerCase().split('.')
        const length = params.length
        const ext = params[length - 1]
        if (acceptedFileTypes.includes(ext)) {
          acceptedFiles.push(file)
        } else {
          toast(`${name} is not ${acceptedFileTypes.join(', ')}`, { type: 'error' })
        }
      })
      setLoading(true)

      uploadFiles(data, acceptedFiles).then(async (keys: Array<string>) => {
        const newData = cloneDeep(value)
        const newDocs: DocumentFile[] = []
        const id = Date.now()
        keys.forEach((key, index) => {
          newDocs.push({
            id: id + index,
            name: acceptedFiles[index].name,
            fileKey: key,
            status: 'Not Reviewed',
            category: showCategory ? (categories.length ? '' : defaultCategory) : ' ',
            createdAt: Date.now(),
          })
        })
        newData.push(...newDocs)
        setLoading(false)
        onChange(newData)
        resolve(true)
      })
    })
  }

  const onUpdateDocumentProp = async (
    type: 'name' | 'status' | 'category' | string,
    index: number,
    newName: string,
  ) => {
    const newData = cloneDeep(value)
    if (type == 'name') {
      const orgName = newData[index][type]
      newName = newName + (hasFileExt(orgName) ? orgName.substring(orgName.length - 4) : '')
    }
    newData[index][type] = newName
    onChange(newData)
  }

  const onRemoveDocument = async (document: DocumentFile, index: number) => {
    const content = (
      <div className="text-gray-900 mb-4 text-md">
        Are you sure want to delete this document?
        <div className="text-gray-800 flex flex-col text-left text-sm">
          <span>Document Name: {document.name}</span>
          <span>Uploaded Date: {formatTime(new Date(document.createdAt))}</span>
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(value)
    newData.splice(index, 1)
    onChange(newData)
  }

  const renderContent = () => {
    return (
      <div className={`relative group border-b`}>
        <div className="relative w-full rounded p-2 text-sm text-left">
          {uploadable && (
            <div className="absolute top-1 right-[calc(50%-135px)] w-full h-fit">
              <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
                <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
                <span>Drag and drop files.</span>
              </div>
            </div>
          )}
          <div className="flex border-b pb-1">
            <p className="flex-auto">
              <span className="font-semibold">{title}</span>
              {required && '*'}
              <span className="ml-1 font-semibold">({value.length})</span>
              {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
              {history && (
                <span className="ml-1 hidden group-hover:inline-block" onClick={() => showHistory()}>
                  <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
                </span>
              )}{' '}
            </p>
            {isLoading && <img src={svgLoading} className="inline w-5 h-5 text-white animate-spin" />}

            {additionalActions}
            {uploadable && (
              <>
                <label className="text-gray-700 p-1 hover-shadow1 cursor-pointer rounded" htmlFor={name}>
                  <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                </label>

                <input
                  className="hidden"
                  id={name}
                  type="file"
                  accept={acceptFileTypes}
                  multiple={multiple}
                  required
                  onChange={(event) => onUploadNewfile(event)}
                />
              </>
            )}
          </div>
          <div className="overflow-auto">
            <table className="w-full text-[13px]">
              <thead className="font-medium text-gray-600 bg-gray-100">
                {!narrow ? (
                  <tr>
                    <th className="px-2 py-1">No</th>
                    <th className="px-2 py-1">Document Name</th>
                    {showStatus && <th className="px-2">Status</th>}
                    {showCategory && <th className="px-2">Category</th>}
                    {customFields.map((field) => {
                      return (
                        <th key={field.key} className="px-2">
                          {field.title}
                        </th>
                      )
                    })}
                    <th className="px-2">Uploaded Date</th>
                    <th className="px-2">Actions</th>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <th className="px-2 py-1" rowSpan={2}>
                        No
                      </th>
                      <th className="px-2 py-1" colSpan={Number(showStatus) + Number(showCategory) + 1}>
                        Document Name
                      </th>
                      <th className="px-2" rowSpan={2}>
                        Actions
                      </th>
                    </tr>
                    <tr>
                      {showStatus && <th className="px-2">Status</th>}
                      {showCategory && <th className="px-2">Category</th>}
                      {customFields.map((field) => {
                        return (
                          <th key={field.key} className="px-2">
                            {field.title}
                          </th>
                        )
                      })}
                      <th className="px-2">Uploaded Date</th>
                    </tr>
                  </>
                )}
              </thead>
              <tbody className="text-gray-900 text-[14px]">
                {value.map((document, index: number) => {
                  const cellName =
                    disabled || disableNameChange ? (
                      <span className="hover:underline cursor-pointer" onClick={() => openS3Document(document.fileKey)}>
                        {document.name}
                      </span>
                    ) : (
                      <PlainInput
                        value={
                          hasFileExt(document.name)
                            ? document.name.substring(0, document.name.length - 4)
                            : document.name
                        }
                        content={document.name}
                        onChange={(newName: string) => onUpdateDocumentProp('name', index, newName)}
                      />
                    )

                  const cellStatus = showCategory ? (
                    <Select
                      id={`side-document-category-${document.id}`}
                      size={3}
                      className="-mb-4 bg-white"
                      options={docStatus}
                      value={document.status}
                      onChange={(value) => onUpdateDocumentProp('status', index, value)}
                    />
                  ) : (
                    document.status
                  )

                  const cellCategory = (
                    <Select
                      id={`side-document-category-${document.id}`}
                      size={3}
                      className="-mb-4 bg-white"
                      options={categories.length ? categories : docCategory}
                      value={document.category}
                      hasDefaultOption
                      onChange={(value) => onUpdateDocumentProp('category', index, value)}
                    />
                  )

                  const cellCreatedAt = formatTime(new Date(document.createdAt))

                  const cellAction = (
                    <>
                      <button
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                        onClick={() => openS3Document(document.fileKey)}
                      >
                        <EyeIcon className="w-4 h-4" />
                      </button>
                      <button
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                        onClick={() => downloadS3Document(document.fileKey, document.name)}
                      >
                        <ArrowDownTrayIcon className="w-4 h-4" />
                      </button>
                      {(showCategory || document.status == 'Not Reviewed') && uploadable && (
                        <button
                          className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded"
                          onClick={() => onRemoveDocument(document, index)}
                        >
                          <TrashIcon className="w-4 h-4" />
                        </button>
                      )}
                    </>
                  )

                  const customFieldContents = customFields.map((field) => {
                    return field.content(document, (value: any) => onUpdateDocumentProp(field.key, index, value))
                  })

                  return !narrow ? (
                    <tr className={`hover:bg-slate-100 ${index % 2 && 'bg-gray-50'}`} key={index}>
                      <td className="px-2">{index + 1}</td>
                      <td className="px-2">{cellName}</td>
                      {showStatus && <td className="px-2 w-36">{cellStatus}</td>}
                      {showCategory && <td className="px-2 w-40">{cellCategory}</td>}
                      {customFieldContents.map((v) => {
                        return <td className="px-2 w-36">{v}</td>
                      })}
                      <td className="px-2 w-36">{cellCreatedAt}</td>
                      <td className="px-2 w-24 py-1">{cellAction}</td>
                    </tr>
                  ) : (
                    <>
                      <tr className={`${index % 2 && 'bg-gray-50'}`} key={`${index}-1`}>
                        <td className="px-2" rowSpan={2}>
                          {index + 1}
                        </td>
                        <td className="px-2" colSpan={Number(showStatus) + Number(showCategory) + 1}>
                          {cellName}
                        </td>
                        <td className="px-2 w-20 py-1" rowSpan={2}>
                          {cellAction}
                        </td>
                      </tr>
                      <tr className={`${index % 2 && 'bg-gray-50'}`} key={`${index}-2`}>
                        {showStatus && <td className="px-2 w-36">{cellStatus}</td>}
                        {showCategory && <td className="px-2 w-36">{cellCategory}</td>}
                        {customFieldContents.map((v) => {
                          return <td className="px-2 w-36">{v}</td>
                        })}
                        <td className="px-2 w-36">{cellCreatedAt}</td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  if (!uploadable)
    return (
      <div className={`flex flex-col gap-2 ${className}`}>
        {renderContent()}
        {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1 mt-2">{error}</p>}
      </div>
    )

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`flex flex-col gap-2 ${className}`}>
        <TargetBox types={['document', NativeTypes.FILE]} onDrop={(document: any) => onDropDocument(document)}>
          {renderContent()}
        </TargetBox>
        {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1 mt-2">{error}</p>}
      </div>
    </DndProvider>
  )
}
