import { PlusIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getProjects } from 'services'

import { AddProjectModal } from './Modals/addProject'

export const SideBar = (props: { onSelect: Function }) => {
  const navigate = useHistory()
  const [loading, setLoading] = useState(false)
  const [isShowAddProject, setShowAddProject] = useState(false)
  const [projectId, setProjectId] = useState<number | null>(null)
  const [loanNumber, setLoanNumber] = useState<number | null>(null)
  const [projects, setProjects] = useState<Record<string, any>[]>([])
  const urlParams: any = useParams()
  const { projectId: defaultProjectId, loanNumber: defaultLoanNumber } = urlParams

  useEffect(() => {
    refetch(true)
  }, [])

  useEffect(() => {
    if (defaultLoanNumber) {
      setLoanNumber(Number(defaultLoanNumber))
      setProjectId(null)
      props.onSelect(null)
    } else {
      setProjectId(Number(defaultProjectId))
      setLoanNumber(null)
    }
  }, [defaultProjectId, defaultLoanNumber])

  const refetch = (isFirstFetch = false) => {
    setLoading(true)
    getProjects().then((data) => {
      setLoading(false)
      setProjects(data)
      if (data.length > 0) {
        if (isFirstFetch && defaultLoanNumber) {
        } else if (isFirstFetch && defaultProjectId) {
          const project = data.find((item: Record<string, any>) => item.id == defaultProjectId)
          onSelectProject(project)
        } else onSelectProject(data[0])
      }
    })
  }

  const onAddProject = () => {
    setShowAddProject(true)
  }

  const onCloseModal = (isNeedRefetch: boolean) => {
    setShowAddProject(false)
    if (isNeedRefetch) refetch()
  }

  const onSelectProject = (project: Record<string, any>) => {
    if (loanNumber) {
      navigate.push(`/tasks/${project.id}`)
      props.onSelect(project)
    } else {
      setProjectId(project.id)
      props.onSelect(project)
    }
  }

  return (
    <aside className="relative w-64 min-w-[16rem] pr-2 mb-4" aria-label="Sidebar">
      <LayoutLoading show={loading} />
      <div className="relative h-full overflow-y-auto sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded mb-4">
        {loanNumber && (
          <ul className="sidebar-items flex flex-col p-3 pb-3">
            <li className="border-b py-2 flex justify-between items-center ">
              <p className="flex-1 font-bold">
                <span>Tasks</span>
              </p>
            </li>
            <li className="border-b py-2 flex justify-between items-center bg-slate-100">
              <p className="flex-1 px-2 text-gray-900">
                <span className="text-[15px] font-variation-settings-600">Loan Number {loanNumber}</span>
              </p>
            </li>
          </ul>
        )}

        <ul className="sidebar-items flex flex-col p-3 pb-10">
          <li className="border-b py-2 flex justify-between items-center ">
            <p className="flex-1 font-bold">
              <span>All Tasks</span>
            </p>
            <span className="text-shade-blue p-1 rounded shadow hover-shadow1 cursor-pointer" onClick={onAddProject}>
              <PlusIcon className="w-4 h-4"></PlusIcon>
            </span>
          </li>
          {projects.map((project, index) => {
            const isActive = projectId && projectId === project.id
            return (
              <li
                key={index}
                onClick={() => onSelectProject(project)}
                className={`border-b py-2 flex justify-between items-center ${isActive ? 'bg-slate-100' : ''}`}
              >
                <p className="flex-1 hover:underline cursor-pointer px-2 text-gray-900">
                  <span className="text-[15px] font-variation-settings-600">
                    {index + 1}. {project.name}
                  </span>
                </p>
              </li>
            )
          })}
        </ul>

        {isShowAddProject && <AddProjectModal onClose={onCloseModal} />}
      </div>
    </aside>
  )
}
