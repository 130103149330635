import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

import type { ILoanPurposeDscrOverlay, IRestriction } from '../../interface'

export const LoanPurposeOptions: Record<string, string> = {
  purchase: 'Purchase',
  nocashout: 'No-Cashout',
  cashout: 'Cashout',
}

export const LoanPurposeDscrOverlay = ({ data }: { data: IRestriction }) => {
  const [overlay, setOverlay] = useState<ILoanPurposeDscrOverlay[]>([])

  useEffect(() => {
    if (data?.DscrOverlay?.loanPurposeDscrOverlay) setOverlay(data.DscrOverlay.loanPurposeDscrOverlay)
    else setOverlay([])
  }, [data])

  return (
    <div className="p-2">
      {!!overlay.length ? (
        <table className="text-sm w-full max-w-xl rounded-lg shadow text-center">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-3 py-2 border">Loan Purpose</th>
              <th className="px-3 py-2 border">Min DSCR</th>
            </tr>
          </thead>

          <tbody>
            {overlay.map((item, index) => {
              return (
                <tr key={index} className={`${index % 2 == 1 ? 'bg-gray-50' : ''}`}>
                  <td className="px-3 py-2 border">{LoanPurposeOptions[item.loanPurpose]}</td>
                  <td className="px-3 py-2 border">{item.minDscr}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1 mb-[64px]">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )}
    </div>
  )
}
