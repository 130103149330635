import { accountTypes, assignedToAccountTypes } from 'components/Modals/CreateUser/config'
import { companyName } from 'config'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAdminEmails, getByteproFileName } from 'services'
import { useTitle } from 'utils'

import { SideBar } from './sidebar'
import { TasksForLoan } from './tasksForLoan'
import { TasksForProject } from './tasksForProject'

export const Tasks = () => {
  useTitle(`Tasks - ${companyName}`)

  const urlParams: any = useParams()
  const { loanNumber: defaultLoanNumber, taskNo = 0 } = urlParams

  const [byteproFileName, setByteproFileName] = useState(0)
  const [loanNumber, setLoanNumber] = useState<number | null>(null)
  const [selectedProject, setSelectedProject] = useState<Record<string, any> | null>(null)
  const [assignedToList, setAssignedToList] = useState<Record<string, string>>({})

  useEffect(() => {
    getAdminEmails().then((emails) => {
      const newOptions: Record<string, string> = {}
      assignedToAccountTypes.forEach((key) => (newOptions[key] = accountTypes[key]))
      emails.forEach((email) => (newOptions[email] = email))
      setAssignedToList(newOptions)
    })
  }, [])

  useEffect(() => {
    if (defaultLoanNumber) {
      setLoanNumber(Number(defaultLoanNumber))
      getByteproFileName(Number(defaultLoanNumber)).then((res: any) => {
        if (res.success) {
          setByteproFileName(res.data)
        }
      })
    } else setLoanNumber(null)
  }, [defaultLoanNumber])

  const onSelectProject = (project: Record<string, any>) => {
    setSelectedProject(project)
  }

  return (
    <div className="flex xl:flex-nowrap flex-wrap px-2 pt-6 pb-2 max-w-full m-auto">
      <SideBar onSelect={onSelectProject} />
      {selectedProject && <TasksForProject project={selectedProject} assignedToList={assignedToList} />}
      {loanNumber && (
        <TasksForLoan
          byteproFileName={byteproFileName}
          loanNumber={loanNumber}
          taskNo={Number(taskNo)}
          assignedToList={assignedToList}
        />
      )}
    </div>
  )
}
