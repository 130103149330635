import { TermSheetPlaceholders } from './constants'

export const validatePlaceholders = (value: string) => {
  const regex = /\{\{(.*?)\}\}/g
  const placeholders = value.match(regex)
  const opens = value.match(/\{\{/g)
  const ends = value.match(/\}\}/g)

  let hasError = false

  if (!!placeholders) {
    const matchedPlaceholders = placeholders.map((item: string) => item.replace(/\{\{|\}\}/g, ''))

    matchedPlaceholders.forEach((item: string) => {
      if (!TermSheetPlaceholders.includes(item)) hasError = true
    })
  }

  if (!!opens) {
    if (!ends) hasError = true
    else if (opens.length !== ends.length) hasError = true
  } else if (!!ends) hasError = true

  return hasError
}
