import cloneDeep from 'clone-deep'
import { accountTypes } from 'components/Modals/CreateUser/config'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getChildUsers } from 'services'
import { createBroadcastEmail } from 'services/apis/admin'
import { Checkbox, Modal } from 'stories/components'
import { confirm, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import type { UserInfo } from './user.type'

const defaultInputs = (): Record<string, InputType> => {
  return {
    emails: {
      title: 'Additional Emails',
      inputType: 'AutoComplete',
      autoCompleteType: 'email',
      type: 'email',
      error: '',
      value: [],
      required: false,
    },
    title: {
      inputType: 'text',
      type: 'text',
      title: 'Title',
      value: '',
      required: true,
    },
    content: {
      inputType: 'textarea',
      title: 'Content',
      value: '',
      required: true,
    },
    file: {
      inputType: 'file',
      title: 'File',
      required: false,
    },
  }
}

export const CompanyBroadcastDialog = (props: { onClose: any; user: UserInfo }) => {
  const [loading, setLoading] = useState(true)
  const [inputs, setInputs] = useState(defaultInputs())
  const [children, setChildren] = useState<string[]>([])
  const [childStatus, setChildStatus] = useState<Record<string, boolean>>({})

  useEffect(() => {
    getChildUsers(props.user.id)
      .then((children) => {
        setChildren(children.map(({ email }: { email: string }) => email))
      })
      .finally(() => setLoading(false))
  }, [])

  const onToggleChild = (email: string, value: boolean) => {
    const newStatus = cloneDeep(childStatus)
    newStatus[email] = value
    setChildStatus(newStatus)
  }

  const onChange = async (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    const emails: string[] = []
    emails.push(props.user.email)
    children.forEach((email) => childStatus[email] !== false && emails.push(email))
    emails.push(...(data.emails || []))
    data.accountType = props.user.accountType
    data.emails = emails

    const content = (
      <div>
        <div className="font-semibold mb-4">Are you sure you want to email the following?</div>
        <div className="text-left mb-5">
          <div className="border-b">- Emails</div>
          <div className="mt-3 flex flex-wrap gap-2 ml-3 italic text-gray-600 text-[14px]">
            {emails.map((email: string, index: number) => {
              return <div key={index}>{email}, </div>
            })}
          </div>
        </div>
      </div>
    )
    const isConfirmed = await confirm(content)
    if (!isConfirmed) return

    setLoading(true)
    createBroadcastEmail(data)
      .then(async () => {
        toast('Company Broadcast email is sent successfully', { type: 'success' })
        props.onClose()
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={`Send Company Broadcast Email - ${props.user.name} (${accountTypes[props.user.accountType]})`}
      titleOkay="Send"
      loading={loading}
      onClose={props.onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="w-196 grid gap-4 grid-cols-1">
        {!!children.length && (
          <div>
            <p className="font-medium border-b pl-2 mb-2">Descendant Users</p>
            <div className="flex flex-wrap gap-2 pl-4">
              {[props.user.email, ...children.sort()].map((email) => (
                <div>
                  <Checkbox
                    id={`check-${email}`}
                    color="gray"
                    size={3}
                    checked={childStatus[email] !== false}
                    onChange={(v) => onToggleChild(email, v)}
                    title={email}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
