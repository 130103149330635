import { MagnifyingGlassIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { changeOrderPaymentTemplate, deletePaymentTypeTemplate, getAllPaymentType } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, Input2 } from 'stories/components'
import { confirm, thousandSeperator } from 'utils'

import { PaymentTypeDetails } from './details'

export interface PaymentType {
  id: number
  title: string
  cost: number
  order: number
}

export function PaymentTypes() {
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedItem, setSelectedItem] = useState<PaymentType | null>(null)
  const [data, setData] = useState<PaymentType[]>([])

  const filteredList = useMemo(() => {
    if (!query) return data
    return data.filter(({ title }) => {
      return title.toLowerCase().includes(query.trim().toLowerCase())
    })
  }, [query, data])

  useEffect(() => {
    refresh()
  }, [])

  const onAdd = () => {
    setSelectedItem(null)
    setIsEditing(true)
  }

  const refresh = () => {
    setIsLoading(true)
    getAllPaymentType()
      .then((data) => {
        if (data.length === 0) {
          setTotal(0)
          setData([])
        } else {
          const orderedData = data.map((item: any, index: any) => ({ ...item, order: index }))
          setTotal(orderedData.length)
          setData(orderedData)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const onEdit = (item: PaymentType) => {
    setSelectedItem(item)
    setIsEditing(true)
  }

  const onTrash = async (item: PaymentType) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        <span className="text-gray-600">{item.title}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)
    deletePaymentTypeTemplate(item.id)
      .then(refresh)
      .catch(() => setIsLoading(false))
  }

  const handleOrderChange = async (currentIndex: any, newOrder: any) => {
    const updatedData = [...data]
    setData([])

    const [movedItem] = updatedData.splice(currentIndex, 1)
    updatedData.splice(newOrder, 0, movedItem)
    setData(updatedData)

    await changeOrderPaymentTemplate(updatedData)
  }

  if (isEditing)
    return (
      <PaymentTypeDetails
        defaults={selectedItem}
        onBack={() => {
          setIsEditing(false)
          refresh()
        }}
        onComplete={() => {
          setIsEditing(false)
          refresh()
        }}
      />
    )

  return (
    <div className="List-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Payment Types
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="flex flex-wrap justify-between mb-3">
        <div className="flex items-center flex-wrap">
          <div className="md:w-96 w-72">
            <Input2
              type="search"
              title="Search"
              hasIcon
              icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
              value={query}
              onChange={(value) => setQuery(value)}
            />
          </div>
          <p className="ml-5">- {total} Items</p>
        </div>
        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="px-3">
                Title
              </th>
              <th scope="col" className="px-3">
                Cost
              </th>
              {query.length === 0 && (
                <th scope="col" className="px-3">
                  Order
                </th>
              )}
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredList.length === 0 ? (
              <tr>
                <td colSpan={query.length === 0 ? 5 : 4} className="text-center py-3">
                  No data available
                </td>
              </tr>
            ) : (
              filteredList.map((item, index) => {
                const orderOptions = Array.from({ length: data.length }, (_, i) => i)
                return (
                  <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={index}>
                    <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {index + 1}
                    </td>
                    <td className="px-3">{item.title}</td>
                    <td className="px-3">{thousandSeperator(item.cost)}</td>
                    {query.length === 0 && (
                      <td className="px-3">
                        <select
                          onChange={(e) => handleOrderChange(index, parseInt(e.target.value))}
                          value={index}
                          className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                        >
                          {orderOptions.map((val) => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                      </td>
                    )}
                    <td className="px-3">
                      <span className="flex">
                        <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                          <PencilSquareIcon className="w-4 h-4" />
                        </span>
                        <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onTrash(item)}>
                          <TrashIcon className="w-4 h-4" />
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
