import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { uploadAssetInformationBankStatement } from 'services'
import { InputFile, Modal } from 'stories/components'

import { type IAssetData, SnapptVideoLink } from './constants'
import { bankStatementRequires } from './VerifyingMethodModal'

export const SnapptDetailsModal = ({
  asset,
  onClose,
}: {
  asset: IAssetData
  onClose: (data: IAssetData | null) => void
}) => {
  const [isLoading, setLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [submittedData, setSubmittedData] = useState(null)
  const [error, setError] = useState('')

  const assetResult = asset.bankStatementData?.webhookReportData?.result
  const isUndertermined = assetResult == 'UNDETERMINED'
  console.log('assetResult', assetResult)

  const onChooseFile = (file: File | null) => {
    setFile(file)
  }

  const onSubmit = async () => {
    if (!file) return
    setLoading(true)
    setError('')
    uploadAssetInformationBankStatement(asset.id, file)
      .then((data) => {
        if (data.error) {
          setError(data.error)
          return
        }
        setSubmittedData(data)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={isUndertermined ? 'Upload Bank Statement' : 'Bank Statement'}
      titleOkay={isUndertermined && !submittedData ? 'Submit' : ''}
      titleCancel="Close"
      isOpen
      loading={isLoading}
      disabled={!file}
      onOk={onSubmit}
      onClose={() => (submittedData ? onClose(submittedData) : onClose(null))}
    >
      {!isUndertermined ? (
        <div className="w-128">
          <p className="">
            Status:{' '}
            <span className="p-2 rounded-lg border-red-400 border bg-red-50 text-sm text-red-600">{assetResult}</span>
          </p>

          <p className="p-2 rounded-lg border-yellow-400 border bg-yellow-50 text-sm text-yellow-600 mt-4">
            The uploaded bank statement has failed verification. Please watch the guide and create the asset again.
          </p>
          {SnapptVideoLink}
        </div>
      ) : !submittedData ? (
        <div className="w-128">
          {!!asset?.bankStatementData?.webhookReportData?.note ? (
            <>
              <p className="p-2 rounded-lg border-red-400 border bg-red-50 text-sm text-red-600 w-full mb-4">
                {asset?.bankStatementData?.webhookReportData?.note}
              </p>
            </>
          ) : null}

          <div className="bg-blue-50 p-4 rounded-lg">
            {bankStatementRequires.map((v) => (
              <p className="flex items-start gap-2 text-sm mb-2">
                <CheckCircleIcon className="w-4 h-4 text-shade-blue mt-1" />
                <span className="flex-1">{v}</span>
              </p>
            ))}
          </div>

          <InputFile title="Choose Bank Statement" value={file || undefined} onChange={onChooseFile as any} />

          {SnapptVideoLink}
          {!!error ? (
            <p className="p-2 rounded-lg border-red-400 border bg-red-50 text-sm text-red-600 mt-4">{error}</p>
          ) : null}
        </div>
      ) : (
        <div className="w-128">
          <div className="w-20 h-20 bg-blue-50 border border-blue-400 rounded-full mx-auto p-2 mb-4">
            <CheckIcon className="w-16 h-16 text-shade-blue" />
          </div>
          <p className="text-base">
            The bank statement has been successfully submitted to Snappt. It will take 1 business day to receive the
            result. We will notify you once the result has been received.
          </p>
        </div>
      )}
    </Modal>
  )
}
