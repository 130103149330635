import {
  API_BUDGET_TEMPLATE,
  API_BUDGET_TEMPLATE_BORROWER_CONFIRM,
  API_BUDGET_TEMPLATE_BY_ID,
  API_BUDGET_TEMPLATE_EXPORT_QA,
  API_BUDGET_TEMPLATE_ITEM,
  API_BUDGET_TEMPLATE_LOCK_BORROWER,
  API_BUDGET_TEMPLATE_PARSE_EXCEL,
  API_BUDGET_TEMPLATE_SEND_TO_BORROWER,
  API_BUDGET_TEMPLATE_SIGN_SIGNATURE,
} from 'config'
import Api from 'services/api'

import { downloadFile, fileJsonConvert } from '../documents'

export const getBudgetTemplateList = () => {
  return Api.get(API_BUDGET_TEMPLATE)
}

export const createBudgetTemplateList = () => {
  return Api.post(API_BUDGET_TEMPLATE)
}

export const getBudgetTemplate = (id: number) => {
  return Api.get(API_BUDGET_TEMPLATE_BY_ID, {}, { id })
}

export const updateBudgetTemplate = (id: number, data: Record<string, any>) => {
  return Api.put(API_BUDGET_TEMPLATE_BY_ID, data, { id })
}

export const deleteBudgetTemplate = (id: number) => {
  return Api.delete(API_BUDGET_TEMPLATE_BY_ID, {}, { id })
}

export const updateBudgetTemplateItem = (id: number, data: Record<string, any>) => {
  return Api.put(API_BUDGET_TEMPLATE_ITEM, data, { id })
}

export const borrowerConfirmBudget = (id: number) => {
  return Api.get(API_BUDGET_TEMPLATE_BORROWER_CONFIRM, {}, { id }, { timeout: 150000 })
}

export const updateLockBorrower = (id: number, value: boolean) => {
  return Api.put(API_BUDGET_TEMPLATE_LOCK_BORROWER, { value }, { id })
}

export const sendBudgetToBorrower = (id: number, emails: Record<string, string>, note: string) => {
  return Api.post(API_BUDGET_TEMPLATE_SEND_TO_BORROWER, { emails, note }, { id })
}

export const validateBudgetSignSignature = (id: number, data: Record<string, any>) => {
  return Api.post(API_BUDGET_TEMPLATE_SIGN_SIGNATURE, data, { id })
}

export const parseBudgetTemplateExcel = (data: any) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_BUDGET_TEMPLATE_PARSE_EXCEL, json.data, {}, json.files)
}

export const downloadBudgetQA = async (
  items: Record<string, any>[],
  narrativeNote: string,
  type: string,
  fileName: string,
) => {
  const res: Blob = await Api.post(
    API_BUDGET_TEMPLATE_EXPORT_QA,
    { items, narrativeNote, type },
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(fileName, res)
}
