import { ArrowRightIcon, EyeIcon, PencilSquareIcon, PlayCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { PlaidAccount, PlaidInstitution, usePlaidLink } from 'react-plaid-link'
import { useSelector } from 'react-redux'
import {
  type IPlaidBalanceData,
  AccountSubtype,
  createLoanAssetInformation,
  deleteLoanAssetInformation,
  getPlaidAccountBalance,
  getPlaidClientToken,
  isVendorEnabled,
  openS3Document,
} from 'services'
import { getAdminConfig } from 'services/apis/admin'
import { PlainTable } from 'stories/components'
import { canEditLoanApplication, formatDate, getPrice2decimal, showVideo } from 'utils'

import { AssetAccountTypeForPlaid, AssetAccountTypeOptions, IAssetData, renderAccountName } from './constants'
import { SnapptDetailsModal } from './SnapptDetailsModal'
import { VerifyingMethod, VerifyingMethodModal } from './VerifyingMethodModal'

enum AssetStatus {
  Failed = -1,
  Initial,
  UnderReview,
  Verified,
  VerifiedByStatement,
}

export const AssetInformationTable = ({
  trackData,
  setTrackData,
  setLoading,
  updateStore,
  onEdit,
  onRemove,
}: {
  trackData: IAssetData[]
  setTrackData: Function
  setLoading: Function
  updateStore?: Function
  onEdit?: Function
  onRemove?: Function
}) => {
  const isTemparary = useSelector((state: any) => state.auth.profile.temparary)
  const [clientToken, setClientToken] = useState('')
  const [verifyingAsset, setVerifyingAsset] = useState<IAssetData | null>()
  const [verifyingMethod, setVerifyingMethod] = useState<VerifyingMethod>(VerifyingMethod.None)
  const [balanceData, setBalanceData] = useState<IPlaidBalanceData[] | null>(null)
  const [snapptDetails, setSnapptDetails] = useState<IAssetData | null>()
  const [plaidEnabled, setPlaidEnabled] = useState(false)
  const [snapptEnabled, setSnapptEnabled] = useState(false)

  const canEdit = canEditLoanApplication()
  const { hasPermission } = usePermissions()
  const hasAEPermission = hasPermission('ADMIN_TO_AE_PROFILE_PERMISSION')

  useEffect(() => {
    isVendorEnabled('Plaid').then((res) => {
      setPlaidEnabled(res)
    })
    isVendorEnabled('Snappt').then((res) => {
      setSnapptEnabled(res)
    })
  }, [])
  useEffect(() => {
    updateBalanceData()
  }, [balanceData])

  const { open: openPlaid, ready: isPlaidReady } = usePlaidLink({
    token: clientToken,
    onSuccess: (public_token, metadata) => {
      console.log('success', public_token, metadata)
      if (metadata.institution) onLink(metadata.institution, metadata.accounts, public_token)
      else {
        setLoading(false)
      }
    },
    onEvent: (event, metadata) => {
      console.log('event', event, metadata)
    },
    onExit: (error, metadata) => {
      console.log('exit', error, metadata)
      setLoading(false)
      setVerifyingAsset(null)
    },
    onLoad: () => {
      console.log('onLoad')
    },
  })

  useEffect(() => {
    if (!clientToken || !isPlaidReady) return
    openPlaid()
  }, [clientToken, isPlaidReady])

  const onLink = async (institution: PlaidInstitution, accounts: PlaidAccount[], publicToken: string) => {
    const filteredAccounts = filterAssets(institution, accounts)
    if (!filteredAccounts) {
      setLoading(false)
      setVerifyingAsset(null)
      return
    }

    setLoading(true)
    getPlaidAccountBalance({ institution, accounts: filteredAccounts, publicToken })
      .then(async (balanceData) => {
        if (balanceData.length) setBalanceData(balanceData)
        else {
          setLoading(false)
          setVerifyingAsset(null)
        }
      })
      .catch(() => {
        setLoading(false)
        setVerifyingAsset(null)
      })
  }

  const onVerifyAsset = (item: IAssetData) => {
    if (item.verified || item.override) return

    setVerifyingAsset(item)
    setVerifyingMethod(VerifyingMethod.None)
  }

  const onVerifyAssetWithPlaid = () => {
    if (clientToken && isPlaidReady) {
      openPlaid()
      return
    }
    setLoading(true)
    getPlaidClientToken('bank')
      .then(({ link_token }) => setClientToken(link_token))
      .catch(() => setLoading(false))
  }

  const convertAccountType = (accountType: number, subtype: AccountSubtype | null) => {
    if (subtype == AccountSubtype.Checking) return 2
    if (subtype == AccountSubtype.Savings) return 1
    if (subtype == AccountSubtype.MoneyMarket) return 6
    return accountType
  }

  const updateBalanceData = async () => {
    if (!verifyingAsset || !balanceData) return
    await deleteLoanAssetInformation(verifyingAsset.id)
    const newData: IAssetData[] = balanceData.map((balance) => ({
      ...balance,
      accountType: convertAccountType(verifyingAsset.accountType || 0, balance.subtype),
      verified: true,
      accountNumber: renderAccountName(balance as any),
    })) as any
    await createLoanAssetInformation(newData)

    const temp: IAssetData[] = []
    trackData.forEach((item) => {
      if (item.id == verifyingAsset.id) {
        temp.push(...newData)
      } else temp.push(item)
    })

    setTrackData(temp)
    updateStore && updateStore('assets', temp)
    setLoading(false)
    setVerifyingAsset(null)
    setBalanceData(null)
  }

  const filterAssets = (institution: PlaidInstitution, accounts: PlaidAccount[]) => {
    const filteredAccounts = accounts.filter(
      (account) =>
        trackData.findIndex(
          (item) => item.mask == account.mask && item.name == account.name && item.institution == institution.name,
        ) == -1,
    )
    if (filteredAccounts.length == 0) return null
    return filteredAccounts
  }

  const onPlayInstruction = async () => {
    setLoading(true)
    try {
      const linkConfig = await getAdminConfig('linkConfig')
      const videoUrl = linkConfig.instructionAssetVerification

      setLoading(false)

      if (videoUrl) {
        showVideo(videoUrl, { title: 'Instructional Video of Asset Verification' })
      } else {
        console.error('Video URL not found in the LINK_CONFIGURATION setting')
      }
    } catch (error) {
      console.error('Error fetching video link:', error)
      setLoading(false)
    }
  }

  const onViewSnapptDetails = (asset: IAssetData) => {
    setSnapptDetails(asset)
  }

  const updateAssetData = (assetData: IAssetData) => {
    const index = trackData.findIndex((v) => v.id == assetData.id)
    if (index == -1) return

    const temp = cloneDeep(trackData)
    temp[index] = assetData
    setTrackData(temp)
    updateStore && updateStore('assets', temp)
  }

  const renderVerifiedStatus = (asset: IAssetData) => {
    if (asset.override || asset.verified)
      return (
        <div className="text-green-800 bg-green-100 rounded py-1 px-2 w-fit">
          Verified
          {asset.override && <div className="border-t border-green-300 text-red-900">Overridden</div>}
          {!asset.override && asset.bankStatementReportFileKey && (
            <div className="text-xs border-t border-green-300 text-green-800">by Snappt</div>
          )}
          {!asset.override && !asset.bankStatementReportFileKey && (
            <div className="text-xs border-t border-green-300 text-green-800">by Plaid</div>
          )}
        </div>
      )

    if (asset.bankStatementFileKey) {
      if (asset.bankStatementData.webhookReportData == null)
        return (
          <div className="text-yellow-800 bg-yellow-100 rounded py-1 px-2 w-fit whitespace-nowrap">
            Under Review
            <div className="text-xs border-t border-yellow-300 text-yellow-800">by Snappt</div>
          </div>
        )
      let { result } = asset.bankStatementData.webhookReportData
      if (result != 'CLEAN') {
        if (result == 'UNDETERMINED')
          return (
            <div className={`text-orange-800 bg-orange-100 rounded py-1 px-2 w-fit capitalize`}>
              Failed
              <div className={`text-xs border-t border-orange-300 text-orange-800`}>by Snappt</div>
              <span className="btn-link" onClick={() => onViewSnapptDetails(asset)}>
                View Details
              </span>
            </div>
          )
        return (
          <div className={`text-red-800 bg-red-100 rounded py-1 px-2 w-fit capitalize`}>
            Failed
            <div className={`text-xs border-t border-red-300 text-red-800`}>by Snappt</div>
            <span className="btn-link" onClick={() => onViewSnapptDetails(asset)}>
              View Details
            </span>
          </div>
        )
      }
    }

    return (
      <div className="flex whitespace-nowrap">
        <span
          className="text-yellow-800 bg-yellow-100 cursor-pointer hover:underline px-2 py-1.5 rounded flex gap-1 items-center"
          onClick={() => onVerifyAsset(asset)}
        >
          Verify Now
          <ArrowRightIcon className="w-4 h-4" />
        </span>
      </div>
    )
  }

  const getStatus = (asset: IAssetData): AssetStatus => {
    if (asset.bankStatementReportFileKey) return AssetStatus.VerifiedByStatement
    if (asset.verified || asset.override) return AssetStatus.Verified
    if (asset.bankStatementFileKey)
      if (asset.bankStatementData.webhookReportData == null) return AssetStatus.UnderReview
      else return AssetStatus.Failed
    return AssetStatus.Initial
  }

  const ableToEdit = (asset: IAssetData) => {
    if (hasAEPermission) return true

    const status = getStatus(asset)
    if ([AssetStatus.Initial].includes(status)) return true
    if (status == AssetStatus.VerifiedByStatement && !isTemparary) return true

    return false
  }

  const ableToDelete = (asset: IAssetData) => {
    if (hasAEPermission) return true

    const status = getStatus(asset)
    return [AssetStatus.Initial].includes(status)
  }

  return (
    <>
      <PlainTable
        classNames={Array.from(Array(8).keys()).map(() => '!px-4')}
        tdClass={`text-sm px-4 py-2`}
        header={[
          'No',
          'Account Type',
          'Institution',
          'Account Name/Number',
          'Account Balance',
          'Created At',
          plaidEnabled || snapptEnabled ? 'Status' : '',
          canEdit || hasAEPermission ? 'Action' : '',
        ].filter((v) => !!v)}
        data={trackData.map((item, index) => {
          const isPlaidItem = AssetAccountTypeForPlaid.includes(item.accountType || 0)

          return [
            index + 1,
            item.accountType ? AssetAccountTypeOptions[item.accountType] : '',
            item.institution,
            renderAccountName(item),
            !!item.balance ? `$${getPrice2decimal(item.balance, false, true)}` : '',
            formatDate(item.createdAt),
            plaidEnabled || snapptEnabled ? isPlaidItem ? renderVerifiedStatus(item) : <></> : null,
            canEdit || hasAEPermission ? (
              <div className="flex gap-1">
                {hasAEPermission && !!item.assetReportFileKey && (
                  <span
                    className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                    onClick={() => openS3Document(item.assetReportFileKey)}
                  >
                    <EyeIcon className="w-4 h-4"></EyeIcon>
                  </span>
                )}
                {(!!item.bankStatementReportFileKey || !!item.bankStatementFileKey) && (
                  <span
                    className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                    onClick={() => openS3Document(item.bankStatementReportFileKey || item.bankStatementFileKey)}
                  >
                    <EyeIcon className="w-4 h-4"></EyeIcon>
                  </span>
                )}
                {ableToEdit(item) && onEdit && (
                  <span
                    className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                    onClick={() => onEdit(item)}
                  >
                    <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                  </span>
                )}
                {ableToDelete(item) && onRemove && (
                  <span
                    className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
                    onClick={() => onRemove(item)}
                  >
                    <TrashIcon className="w-4 h-4"></TrashIcon>
                  </span>
                )}
              </div>
            ) : null,
          ].filter((v) => v !== null) as any
        })}
      />
      <div className="flex justify-end mb-4">
        <p
          className="flex text-shade-blue cursor-pointer hover:underline items-center text-sm gap-1"
          onClick={onPlayInstruction}
        >
          - Instructional Video of Asset Verification
          <span className="inline text-[13px]">
            <PlayCircleIcon className="w-4 h-4" aria-hidden="true" />
          </span>
        </p>
      </div>

      {verifyingAsset && verifyingMethod == VerifyingMethod.None && (
        <VerifyingMethodModal
          asset={verifyingAsset}
          onClose={(method, assetData) => {
            switch (method) {
              case VerifyingMethod.None:
                setVerifyingAsset(null)
                return

              case VerifyingMethod.Plaid:
                setVerifyingMethod(method)
                onVerifyAssetWithPlaid()
                return

              case VerifyingMethod.Snappt:
                if (!assetData) return
                setVerifyingAsset(null)
                setVerifyingMethod(VerifyingMethod.None)
                updateAssetData(assetData)
                return
            }
          }}
        />
      )}

      {snapptDetails && (
        <SnapptDetailsModal
          asset={snapptDetails}
          onClose={(assetData: IAssetData | null) => {
            assetData && updateAssetData(assetData)
            setSnapptDetails(null)
          }}
        />
      )}
    </>
  )
}
