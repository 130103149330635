import { accountTypes } from 'components/Modals/CreateUser/config'
import { type InputType, loanStatusConstants } from 'config'

export const TaskAssigneeOptions: Record<string, string> = {
  self_underwriter: '[Loan] UnderWriter',
  self_account_executive: '[Loan] Account Executive',
  self_broker: '[Loan] Broker',
  self_loan_officer: '[Loan] Loan Officer',
  self_loan_processor: '[Loan] Loan Processor',
  self_creator: '[Loan] Creator',
  ...accountTypes,
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    taskAssignee: {
      inputType: 'multiselect',
      title: 'Task Assignee',
      options: TaskAssigneeOptions,
      defaultSelected: false,
      required: true,
    },
    section1: {
      inputType: 'section',
      title: 'Loan Status',
      span: 'full',
    },
    statusTo: {
      inputType: 'select',
      title: 'Status Change To',
      options: loanStatusConstants.status,
      hasDefaultOption: true,
      defaultOptionText: '- No Change -',
      required: false,
    },
    statusToRequireVerify: {
      inputType: 'togglebutton',
      title: 'Require Validation',
      required: true,
    },
    statusListBeforeToUpdate: {
      inputType: 'multiselect',
      title: `Update Loan status when below`,
      options: loanStatusConstants.status,
      tooltip: 'Update Loan Status when is in the status list',
      defaultSelected: false,
      required: false,
    },
    section2: {
      inputType: 'section',
      title: 'Email & Task',
      span: 'full',
    },
    email: {
      inputType: 'togglebutton',
      title: 'Email',
      required: true,
    },
    task: {
      inputType: 'togglebutton',
      title: 'Task',
      required: true,
    },
    taskOnTask: {
      inputType: 'togglebutton',
      title: 'Task on Task',
      required: true,
    },
  }
}
