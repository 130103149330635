import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import { getPrice3decimal } from 'utils'

import type { IMaxCashToBorrowerLeverageLimit } from '../../interface'
import { convertNullToNoMax } from '../../logic'

export const MaxCashToBorrowerLeverageLimit = ({
  data,
  isDSCR = true,
}: {
  data: IMaxCashToBorrowerLeverageLimit[]
  isDSCR?: boolean
}) => {
  const renderLtvMaxLimits = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full mt-8 flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="w-full text-center text-sm">
        <thead className="bg-gray-100 font-bold">
          <tr>
            <th colSpan={2} className="p-2 border">
              {isDSCR ? 'LTV' : 'AIV-LTV'}
            </th>
            <th colSpan={2} className="p-2 border">
              Loan Amount
            </th>
            {isDSCR && (
              <th rowSpan={2} className="p-2 border w-[100px]">
                Min DSCR
              </th>
            )}
            <th rowSpan={2} className="p-2 border w-[100px]">
              Min FICO
            </th>
            <th rowSpan={2} className="p-2 border whitespace-nowrap">
              Max Estimated Closing Amount to Borrower
            </th>
          </tr>
          <tr>
            <th className="p-2 border w-[100px]">From</th>
            <th className="p-2 border w-[100px]">To</th>
            <th className="p-2 border w-[150px]">From</th>
            <th className="p-2 border w-[150px]">To</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td className="border p-2">{item.ltv.from}</td>
                <td className="border p-2">{item.ltv.to}</td>
                <td className="border p-2">{getPrice3decimal(item.loanAmount.min)}</td>
                <td className="border p-2">{getPrice3decimal(item.loanAmount.max)}</td>
                {isDSCR && <td className="border p-2">{item.minDSCR}</td>}
                <td className="border p-2">{item.minFICO}</td>
                <td className="border p-2">{convertNullToNoMax(item.maxValue)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-2">
        <p>
          <span>Only when the</span> "
          <i>
            <b>Transaction Type</b>
          </i>
          " <span>is a</span> "
          <i>
            <b>Refinance</b>
          </i>
          "
        </p>
      </div>

      {renderLtvMaxLimits}
    </div>
  )
}
