import { ArrowDownTrayIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { COMPANY_ADDRESS, COMPANY_TITLE, companyName, itemCountPerPage } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useMemo, useRef, useState } from 'react'
import { addRatesheet, deleteRatesheet, listAllRatesheet, openS3Document, uploadFiles } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, Pagination } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { confirm, formatDateYMD } from 'utils'
import { useTitle } from 'utils/pageTitle'

import { EditRatesheetDialog } from './EditRatesheetDialog'

export const Ratesheet = () => {
  useTitle(`Ratesheet - ${companyName}`)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setLoading] = useState(false)
  const [ratesheets, setRatesheet] = useState<Record<string, any>[]>([])
  const [isShowAdd, setShowAdd] = useState(false)
  const [selectedItem, setSelectedItem] = useState<Record<string, any> | null>(null)
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    refetch()
  }, [pageNum])

  const refetch = async (_pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = pageNum

    setLoading(true)
    const { data, total } = await listAllRatesheet({
      skip: pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
    setRatesheet(data)
    setTotal(total)
    setLoading(false)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const permission = usePermissions()
  const hasPermission = useMemo(() => {
    return permission.hasPermission('MANAGE_RESOURCES')
  }, [permission.data])

  const onCloseModal = (isNeedRefetch: boolean) => {
    if (isNeedRefetch) refetch()
    setSelectedItem(null)
    setShowAdd(false)
  }

  const onAdd = () => {
    if (!fileInputRef) return
    ;(fileInputRef as any).current.click()
  }

  const onUploadFile = (file: File | null) => {
    if (!file) return
    const data = {
      path: `ratesheets/${formatDateYMD()}`,
    }
    setLoading(true)
    uploadFiles(data, [file]).then(async (fileKeys) => {
      const fileKey = fileKeys[0]
      await addRatesheet(fileKey)
      await refetch()
      setLoading(false)
    })
  }

  const onEdit = (item: Record<string, any>) => {
    setSelectedItem(item)
    setShowAdd(true)
  }

  const downloadDocument = async (fileKey: string) => {
    setLoading(true)
    await openS3Document(fileKey)
    setLoading(false)
  }

  const onDelete = async (item: Record<string, any>, index: number) => {
    const content = (
      <div className="text-gray-600 mb-4 text-[16px]">
        Do you want to remove this Ratesheet?
        <br />
        <span className="text-gray-900">Ratesheet No: {item.no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteRatesheet(item.no)
    setLoading(false)

    const newDocuments = cloneDeep(ratesheets)
    newDocuments.splice(index, 1)
    setRatesheet(newDocuments)
  }

  const tableData = useMemo(() => {
    return ratesheets.map((item, index) => [
      item.no,
      item.date,
      hasPermission ? (
        <span>
          <div className="flex justify-center">
            <span className="flex gap-2">
              <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
              <span className="p-1 hover-shadow1 cursor-pointer" onClick={() => downloadDocument(item.fileKey)}>
                <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
              </span>
              <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item, index)}>
                <TrashIcon className="w-4 h-4"></TrashIcon>
              </span>
            </span>
          </div>
        </span>
      ) : (
        <span className="flex justify-center">
          <span className="p-1 hover-shadow1 cursor-pointer" onClick={() => downloadDocument(item.fileKey)}>
            <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
          </span>
        </span>
      ),
    ])
  }, [ratesheets])

  return (
    <div className="ratesheet-container">
      <div className="px-2 md:px-5 pb-2 max-w-screen-2xl m-auto mt-4">
        <div className="flex">
          <div className="flex text-[24x] md:text-[26px] font-bold text-shade-blue items-center flex-auto capitalize">
            Ratesheet
            <span className="text-base ml-3">
              {isLoading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
            </span>
          </div>
          {hasPermission && (
            <div>
              <Button onClick={onAdd} className="!py-2 !px-8">
                Add
              </Button>
              <div className="hidden">
                <input
                  type="file"
                  onChange={(e) => onUploadFile(e.target.files?.length ? e.target.files?.item(0) : null)}
                  multiple={false}
                  accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                  ref={fileInputRef}
                />
              </div>
            </div>
          )}
        </div>
        <div className="grid md:grid-cols-2 md:gap-4 px-4">
          <div className="mt-4">
            <div className="text-slate-500">Mortgagee Clause for CPL</div>
            <div className="text-blue-900 font-bold">
              {COMPANY_TITLE} ISAOA/ATIMA {COMPANY_ADDRESS}
            </div>
          </div>
          <div className="mt-4">
            <div className="text-slate-500">Mortgagee Clause for Insurance</div>
            <div className="text-blue-900 font-bold">
              {COMPANY_TITLE} ISAOA/ATIMA {COMPANY_ADDRESS}
            </div>
          </div>
        </div>
        <div className="mt-8 flex justify-center">
          <div className="overflow-auto">
            <div className="table-container mb-6 relative overflow-x-auto sm:rounded max-w-[750px] md:min-w-[600px]">
              <PlainTable
                header={['Rate Sheet ID', 'Date', 'Action']}
                data={tableData}
                classNames={['', '', 'text-center']}
                widthClass="border w-full"
              />
              <Pagination
                totalCount={total}
                itemCountPerPage={itemCountPerPage}
                onNavigate={onPageNavigate}
                pageNum={pageNum}
              />
            </div>
          </div>
          {isShowAdd && selectedItem && <EditRatesheetDialog origin={selectedItem} onClose={onCloseModal} />}
        </div>
      </div>
    </div>
  )
}
