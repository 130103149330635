import cloneDeep from 'clone-deep'
import { EmailTo } from 'components/EmailTo'
import { type InputType, COMPANY_DNS } from 'config'
import type { EmailTemplate } from 'pages/Admin'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { partiesMap } from 'reducers/loanDetail.reducer'
import { getEmailTemplates, sendLoanSubmissionDocumentEmail } from 'services'
import { loanSubmissionPdfData, LoanSubmissionPdfType } from 'services/pdfs/loan'
import { Modal } from 'stories/components'
import { getPartiesMap, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const SendPdfEmailDialog = ({
  type,
  onClose,
}: {
  type: LoanSubmissionPdfType
  conditions: any[]
  onClose: Function
}) => {
  const { loanDetail } = useSelector((state: any) => ({
    loanDetail: state.loanDetail,
    borrower: state.borrower.borrower,
  }))
  const { parties, emailContacts } = loanDetail
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ party: string; email: string }[]>([])
  const [selectedEmails, setSelectedEmails] = useState<Record<string, 'to' | 'cc' | 'bcc' | ''>>({})
  const [newEmailInput, setNewEmailInput] = useState<InputType>({
    inputType: 'text',
    type: 'email',
    title: 'New Email',
    required: true,
  })

  const [selectTypeInput, setSelectTypeInput] = useState<InputType>({
    inputType: 'select',
    options: {},
    title: 'Type',
    required: true,
    hasDefaultOption: true,
  })
  const [contentInput, setContentInput] = useState<InputType>({
    inputType: 'textarea',
    title: '',
    rows: 10,
  })
  const [isSsnIncludes, setSsnIncludes] = useState(false)
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null)
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([])

  const partyMaps = useMemo(() => {
    return getPartiesMap(parties)
  }, [parties])

  const documentName = useMemo(() => {
    let label = 'Approval'
    if (loanDetail.loanStatus === 'suspended') label = 'Suspense'
    switch (type) {
      case LoanSubmissionPdfType.APPROVAL:
        return label
      case LoanSubmissionPdfType.APPROVAL_ALL:
        return `${label}-All`
      case LoanSubmissionPdfType.COMMITMENT:
        return 'Commitment'
      case LoanSubmissionPdfType.Loan_SETUP_SUSPENSE:
        return 'Loan Setup Suspense'
    }
  }, [type])

  useEffect(() => {
    const emails: { party: string; email: string }[] = []
    Object.keys(parties).map((key) => {
      let { email } = parties[key]
      email = key === 'creator' ? email : loanDetail.parties[key].email
      if (!email) return

      emails.push({
        party: partiesMap[key],
        email,
      })
    })
    emails.push(
      ...emailContacts.map((email: string) => ({
        party: 'Email Notification Contacts',
        email,
      })),
    )
    emails.push({ party: 'Loan Setup', email: `loansetup@${COMPANY_DNS}` })
    emails.push({ party: 'Closer', email: `closing@${COMPANY_DNS}` })
    setData(emails)
  }, [parties, emailContacts])

  useEffect(() => {
    getEmailTemplates().then((data: EmailTemplate[]) => {
      setEmailTemplates(data)

      const newInput = cloneDeep(selectTypeInput)
      const options: Record<number, string> = {}
      data.forEach((item) => (options[item.id] = item.type))
      ;(newInput as any).options = options
      setSelectTypeInput(newInput)
    })
  }, [])

  const onNewEmail = (_: string, value: string) => {
    const newInput = cloneDeep(newEmailInput)
    newInput.value = value
    newInput.error = ''
    setNewEmailInput(newInput)
  }

  const onUpdateEmailType = (_: string, value: string) => {
    const newInput = cloneDeep(selectTypeInput)
    newInput.value = value
    newInput.error = ''
    setSelectTypeInput(newInput)

    updateEmailContent(value)
  }

  const updateEmailContent = (templateId: string) => {
    const template = emailTemplates.find((item) => item.id == Number(templateId))
    if (!template) return false

    let { content } = template
    let result = true
    while (true) {
      const matched = content.match(/{{\w+}}/)
      if (!matched) break
      const foundBlock = matched[0]
      const foundKey = foundBlock.replace(/{|}/g, '')
      if (partyMaps[foundKey] !== undefined) content = content.replace(foundBlock, partyMaps[foundKey])
      else {
        toast(`${foundKey} is unknown variable.`, { type: 'warning' })
        result = false
        break
      }
    }

    const newContentInput = cloneDeep(contentInput)
    newContentInput.value = content
    newContentInput.error = ''
    setContentInput(newContentInput)
    return result
  }

  const onUpdateEmailContent = (_: string, value: string) => {
    const newInput = cloneDeep(contentInput)
    newInput.value = value
    newInput.error = ''
    setContentInput(newInput)
  }

  const onSubmit = async () => {
    const hasToEmail = !!Object.keys(selectedEmails).filter((email) => selectedEmails[email] == 'to').length
    if (!hasToEmail) {
      toast('Please choose one more TO email.', { type: 'error' })
      return
    }

    let hasError = false
    if (!selectTypeInput.value) {
      const newInput = cloneDeep(selectTypeInput)
      newInput.error = InputValidate(newInput)
      setSelectTypeInput(newInput)
      hasError = hasError || !!newInput.error
    }
    if (!contentInput.value) {
      const newInput = cloneDeep(contentInput)
      newInput.error = InputValidate(newInput)
      setContentInput(newInput)
      hasError = hasError || !!newInput.error
    }
    if (hasError) return
    const files: File[] = []
    setLoading(true)

    if (attachmentFile) files.push(attachmentFile)

    const template = emailTemplates.find((item) => item.id == Number(selectTypeInput.value))

    const pdfData = await loanSubmissionPdfData()

    await sendLoanSubmissionDocumentEmail(
      documentName,
      template?.type || '',
      selectedEmails,
      contentInput.value,
      isSsnIncludes,
      files,
      pdfData,
      type,
    )
    setLoading(false)
    onClose()
  }

  return (
    <Modal
      title={`Send ${documentName} Document on Email`}
      titleOkay="Send Email"
      isOpen
      loading={loading}
      disabled={!Object.keys(selectedEmails).length}
      lastUpdatedAt={Date.now()}
      onClose={() => onClose()}
      onOk={() => onSubmit()}
    >
      <div className="w-144">
        <EmailTo Emails={selectedEmails} Data={data} onChange={(data: any) => setSelectedEmails(data)} />
        <div className="w-full flex flex-col gap-2">
          <RenderInput Key="section" input={{ inputType: 'section', title: 'Email' }} onChange={onNewEmail} />
          <RenderInput Key="type" input={selectTypeInput} onChange={onUpdateEmailType} />
          <RenderInput Key="content" input={contentInput} onChange={onUpdateEmailContent} />
        </div>
        <div className="mt-2">
          <RenderInput
            Key="ssnPdf"
            input={{
              inputType: 'checkbox',
              title: 'Include SSA89 PDF',
              value: isSsnIncludes,
            }}
            onChange={(k: any, v: boolean) => setSsnIncludes(v)}
          />
          <RenderInput
            Key="attachment"
            input={{
              inputType: 'file',
              title: 'Attachment File',
              value: attachmentFile,
            }}
            onChange={(k: any, v: any) => setAttachmentFile(v)}
          />
        </div>
      </div>
    </Modal>
  )
}
