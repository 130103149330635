import { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    payoffAmount: {
      inputType: 'text',
      type: 'thousandSep',
      title: 'Payoff Amount',
      prefix: '$',
      required: true,
    },
    expirationDate: {
      inputType: 'text',
      type: 'date',
      title: 'Expiration Date',
      required: true,
    },
  }
}
