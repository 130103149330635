import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'

export interface Cell {
  key: string
  value: string
}

interface IProps {
  properties: Cell[]
  onOk: (arg0: Cell[]) => void
  onCancel: () => void
}
export const BusinessDaysDialog = ({ properties, onOk, onCancel }: IProps) => {
  const [values, setValues] = useState<Cell[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValues(properties)
  }, [properties])

  const onAdd = () => {
    const newValues = cloneDeep(values)
    newValues.push({ key: '', value: '' })
    setValues(newValues)
  }

  const onUpdate = (newValue: string, key: 'key' | 'value', index: number) => {
    const newValues = cloneDeep(values)
    newValues[index][key] = newValue
    setValues(newValues)
  }

  const onChangeOrder = (val: string, index: number) => {
    const nth = Number(val) - 1
    if (nth === index) return

    const newValues = cloneDeep(values)
    const removedOne = newValues.splice(index, 1)
    newValues.splice(nth, 0, removedOne[0])
    setValues(newValues)
  }

  const onRemove = (index: number) => {
    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
  }

  const onConfirm = () => {
    setLoading(true)
    onOk(values.filter((value) => value.key != '' || value.value != ''))
  }

  const onOpen = () => {
    setLoading(false)
  }

  return (
    <Modal
      title="Update Business Days"
      titleOkay="Update"
      onOpen={onOpen}
      onOk={onConfirm}
      onClose={onCancel}
      isOpen={true}
      loading={loading}
    >
      <div className="text-gray-700 w-144">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-48">
                Dept
              </th>
              <th scope="col" className="px-6 py-3 w-48">
                Turn Times
              </th>
              <th scope="col" className="px-6 py-3">
                Order
              </th>
              <th scope="col" className="px-6 py-3">
                <button
                  className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline"
                  onClick={onAdd}
                >
                  + Add
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {values.map((value, index) => {
              const orderOptions = values.map((_, idx) => (idx + 1).toString())
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                  <td scope="row" className="px-6 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    <PlainInput
                      value={value.key}
                      content={value.key}
                      isEditing={value.key == ''}
                      className="h-[35px]"
                      onChange={(newValue: string) => onUpdate(newValue, 'key', index)}
                    />
                  </td>

                  <td scope="row" className="px-6 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    <PlainInput
                      value={value.value}
                      content={value.value}
                      className="h-[35px]"
                      onChange={(newValue: string) => onUpdate(newValue, 'value', index)}
                    />
                  </td>

                  <td>
                    <select
                      onChange={(e) => onChangeOrder(e.target.value, index)}
                      value={index + 1}
                      className="block rounded py-1.5 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    >
                      {orderOptions.map((val) => (
                        <option key={val}>{val}</option>
                      ))}
                    </select>
                  </td>

                  <td className="px-6 py-1">
                    <span className="flex">
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onRemove(index)}>
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
