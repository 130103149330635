import type { InputType } from 'config'
import { fullStates } from 'config/states.constants'

export const borrowerPropertyInputGroups = {
  'Borrower Info': ['firstName', 'middleName', 'lastName'],
  'Co-Borrower Info': ['coFirstName', 'coMiddleName', 'coLastName'],
  'Subject Property Info': ['street', 'city', 'state', 'zip', 'county', 'parcelNo', 'legalDesc', 'comments'],
}

export const floodProductTypes: Record<string, any> = {
  25: 'Basic Determination',
  40: 'Basic Determination w/HMDA Information',
  26: 'Life of Loan Determination',
  41: 'Life of Loan Determination w/HMDA Information',
  50: 'Basic Commercial',
  51: 'Basic Commercial w/HMDA Information',
  52: 'Life of Loan – Commercial',
  53: 'Life of Loan w/HMDA – Commercial',
  36: 'Auto Only Basic Determination',
  37: 'Auto Only Basic Determination w/HMDA Information',
  35: 'Manual Only Determination',
  129: 'Life of Loan Upgrade',
  205: 'Refinance',
  206: '2nd Mortgage',
  20: 'Non-Guaranteed Auto-Only',
}

export const floodResultInfoInputGroups = {
  'Order Info': ['certificateNumber', 'certificateDate', 'lifeOfLoan'],
  'A. NFIP Community Jurisdiction': ['nfipCommunityName', 'nfipCounty', 'nfipCommunityNumber'],
  'B. NFIP Date Affectiong Building / Mobile Home': [
    'mapPanelNumber',
    'mapPanelDate',
    'lomaDate',
    'floodZone',
    'mapCoversBuilding',
  ],
  'C. NFIP Availability': ['communityParticipation', 'cbarOpa', 'cbraDesignDate'],
  'D. Determination': ['specialFloodArea'],
  'Additional Info': ['partialSfha', 'structuresInFloodZone', 'dataRevisionType', 'communityPartcipDate'],
  // 'HMDA Info': ['countyCode', 'censusTract', 'msaNo'],
}

export const defaultInputs = (): Record<string, InputType> => {
  return {
    // Borrower Info
    firstName: {
      inputType: 'text',
      type: 'text',
      title: 'First Name',
      value: '',
      required: true,
    },
    middleName: {
      inputType: 'text',
      type: 'text',
      title: 'Middle Name',
      value: '',
      required: false,
    },
    lastName: {
      inputType: 'text',
      type: 'text',
      title: 'Last Name',
      value: '',
      required: true,
    },

    // Co-Borrower Info
    coFirstName: {
      inputType: 'text',
      type: 'text',
      title: 'First Name',
      value: '',
      required: true,
    },
    coMiddleName: {
      inputType: 'text',
      type: 'text',
      title: 'Middle Name',
      value: '',
      required: false,
    },
    coLastName: {
      inputType: 'text',
      type: 'text',
      title: 'Last Name',
      value: '',
      required: true,
    },

    // Subject Property Info
    street: {
      inputType: 'text',
      type: 'text',
      title: 'Street',
      value: '',
      required: true,
    },
    city: {
      inputType: 'text',
      type: 'text',
      title: 'City',
      value: '',
      required: true,
    },
    state: {
      inputType: 'select',
      title: 'State',
      options: fullStates,
      hasDefaultOption: true,
      value: '',
      required: true,
    },
    zip: {
      inputType: 'text',
      type: 'number',
      title: 'Zip',
      value: '',
      required: true,
    },
    county: {
      inputType: 'text',
      type: 'text',
      title: 'County',
      value: '',
      required: false,
    },
    parcelNo: {
      inputType: 'text',
      type: 'number',
      title: 'Parcel No',
      value: '',
      required: false,
    },
    legalDesc: {
      inputType: 'textarea',
      title: 'Legal Desc.',
      value: '',
      required: false,
      span: 2,
    },
    comments: {
      inputType: 'textarea',
      title: 'Comments',
      value: '',
      required: false,
      span: 2,
    },

    // Order Info
    certificateNumber: {
      inputType: 'text',
      type: 'number',
      title: 'Flood Certificate Number',
      value: '',
      required: true,
    },
    certificateDate: {
      inputType: 'text',
      type: 'date',
      title: 'Flood Certificate Date',
      value: '',
      required: true,
    },
    lifeOfLoan: {
      inputType: 'select',
      title: 'Life of Loan',
      options: {
        '1': 'Yes',
        '2': 'No',
      },
      hasDefaultOption: true,
      value: '',
      required: true,
    },

    // A. NFIP Community Jurisdiction
    nfipCommunityName: {
      inputType: 'text',
      type: 'text',
      title: 'NFIP Community Name',
      value: '',
      required: true,
    },
    nfipCounty: {
      inputType: 'text',
      type: 'text',
      title: 'County(ies)',
      value: '',
      required: true,
    },
    nfipCommunityNumber: {
      inputType: 'text',
      type: 'number',
      title: 'NFIP Community Number',
      value: '',
      required: true,
    },

    // B. NFIP Date Affectiong Building / Mobile Home
    mapPanelNumber: {
      inputType: 'text',
      title: 'NFIP Map/Panel Number',
      value: '',
      required: true,
    },
    mapPanelDate: {
      inputType: 'text',
      type: 'date',
      title: 'FloodInsNFIPMapPanelDate',
      value: '',
      required: true,
    },
    lomaDate: {
      inputType: 'text',
      type: 'date',
      title: 'LOMA Date',
      value: '',
      required: true,
    },
    floodZone: {
      inputType: 'text',
      type: 'text',
      title: 'Flood Zone',
      value: '',
      required: true,
    },
    mapCoversBuilding: {
      inputType: 'select',
      options: {
        '1': 'Yes',
        '2': 'No',
      },
      title: 'Map Covers Building',
      hasDefaultOption: true,
      value: '',
      required: true,
    },

    // C. NFIP Availability
    communityParticipation: {
      inputType: 'select',
      title: 'Community Participation',
      options: {
        '0': 'Not Participating in NFIP',
        '1': 'Regular Program',
        '2': 'Emergency Program',
        '3': 'Probation',
        '4': 'Suspended',
        '5': 'Unknown',
        '6': 'Other',
      },
      hasDefaultOption: true,
      value: '',
      required: true,
    },
    cbarOpa: {
      inputType: 'toggle',
      title: 'CBRA / OPA',
      value: false,
      required: true,
    },
    cbraDesignDate: {
      inputType: 'text',
      type: 'date',
      title: 'CBRA/OPA Desig. Date',
      value: '',
      required: true,
    },

    // D. Determination
    specialFloodArea: {
      inputType: 'toggle',
      title: 'Special Flood Hazard Area',
      value: false,
      required: true,
    },

    // Additional Info
    partialSfha: {
      inputType: 'toggle',
      title: 'CBRA/OPA Desig. Date',
      value: false,
      required: true,
    },
    structuresInFloodZone: {
      inputType: 'text',
      title: '# of Structures in Flood Zone',
      value: '',
      required: true,
    },
    dataRevisionType: {
      inputType: 'select',
      title: 'Data Revision Type',
      options: {
        '1': 'LOMA',
        '2': 'LOMR',
      },
      hasDefaultOption: true,
      value: '',
      required: true,
    },
    communityPartcipDate: {
      inputType: 'text',
      type: 'date',
      title: 'Community Partcip. Date',
      value: '',
      required: true,
    },

    // HMDA Info
    countyCode: {
      inputType: 'text',
      type: 'number',
      title: 'County Code',
      value: '',
      readOnly: true,
    },
    censusTract: {
      inputType: 'text',
      type: 'text',
      title: 'Census Tract',
      value: '',
      readOnly: true,
    },
    msaNo: {
      inputType: 'text',
      type: 'number',
      title: 'MSA No.',
      value: '',
      readOnly: true,
    },
  }
}
