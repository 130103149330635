import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { IFeeData } from './types'

export interface FeeDialogProps {
  modalTitle: string
  onClose: () => void
  onSubmit: Function
  data: IFeeData[]
}

const defaultInputs = (item?: IFeeData): Record<string, InputType> => {
  return {
    title: { title: 'Title', inputType: 'text', prefix: '', value: item?.title, error: '', required: true },
    amount: {
      title: 'Amount',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      value: item?.amount,
      error: '',
      required: true,
    },
  }
}

export const FeeDialog = (props: FeeDialogProps) => {
  const { data, modalTitle } = props
  const [inputGroups, setInputGroups] = useState<Record<string, InputType>[]>([])
  const [loading, setLoading] = useState(false)
  const onClose = () => {
    props.onClose()
  }

  useEffect(() => {
    if (data.length > 0) {
      setInputGroups(data.map((item) => defaultInputs(item)))
    } else {
      setInputGroups([defaultInputs()])
    }
  }, [data])

  const handleAddInputs = () => {
    setInputGroups([...inputGroups, defaultInputs()])
  }

  const onChange = (groupIndex: number, key: string, value: string) => {
    const newInputGroups = cloneDeep(inputGroups)
    newInputGroups[groupIndex][key].value = value
    setInputGroups(newInputGroups)
  }
  const handleRemoveGroup = (groupIndex: number) => {
    const newInputGroups = inputGroups.filter((_, i) => i !== groupIndex)
    setInputGroups(newInputGroups)
  }

  const onSubmit = async () => {
    const newInputs = cloneDeep(inputGroups)
    let hasError = false
    newInputs.forEach((group, index) => {
      Object.keys(group).forEach((key) => {
        newInputs[index][key].error = InputValidate(group[key])
        const { error } = newInputs[index][key]
        if (error) hasError = true
      })
    })
    setInputGroups(newInputs)
    if (hasError) return

    setLoading(true)
    const feeData = inputGroups.map((group) => ({
      title: group.title.value,
      amount: removeComma(group.amount.value),
    }))

    props.onSubmit(feeData)
    setLoading(false)
    onClose()
  }

  return (
    <Modal title={modalTitle} isOpen loading={loading} lastUpdatedAt={Date.now()} onOk={onSubmit} onClose={onClose}>
      <div className="w-120">
        {inputGroups?.map((group, groupIndex) => (
          <div key={groupIndex} className="flex gap-4 mb-4 items-center">
            {Object.keys(group).map((key, inputIndex) => (
              <div key={inputIndex}>
                <RenderInput
                  input={group[key]}
                  Key={`${groupIndex}-${key}`}
                  onChange={(_: any, value: string) => onChange(groupIndex, key, value)}
                />
              </div>
            ))}
            <button type="button" onClick={() => handleRemoveGroup(groupIndex)} className="text-red-600">
              <TrashIcon className="w-4 h-4"></TrashIcon>
            </button>
          </div>
        ))}
        <div>
          <button
            className="font-variation-settings-600 text-sm font-medium text-shade-blue hover:underline whitespace-nowrap"
            onClick={handleAddInputs}
          >
            + Add
          </button>
        </div>
      </div>
    </Modal>
  )
}
