import { ArrowDownTrayIcon, ArrowTopRightOnSquareIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { setBorrowerGroupData } from 'actions'
import cloneDeep from 'clone-deep'
import { COMPANY_DNS, COMPANY_NAME_FC } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LoanType } from 'reducers/loanDetail.reducer'
import {
  addHandSignApplicationPdf,
  borrowerInfoUpdate,
  downloadFile,
  downloadS3Documents,
  getLoanSignAppPdf,
  sendSignatureRequest,
  uploadFiles,
} from 'services'
import { Button, InputFile } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { canEditLoanApplication, formatTime, InputConvert, InputValidate, isBorrowerUrl, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { signApplictionValidation } from '../logic'
import { ConfirmSignDialog } from './ConfirmSignDialog'
import { defaultInputs, RequirementList } from './constants'
import { signAppValidate } from './logic'

export function SignApplication(props: any) {
  const { borrowerSeperator, changeBorrowerMenu } = props
  const [inputs, setInputs] = useState(defaultInputs())
  const [loading, setLoading] = useState(false)
  const [isShowConfirmSign, setShowConfirmSign] = useState(false)
  const [signUrl, setSignUrl] = useState(false)
  const [action, setAction] = useState('')

  const location = useLocation()

  // const isTemparary = useSelector((state: any) => state.auth.profile.temparary)

  const dispatch = useDispatch()
  const { borrower, loanDetail } = useSelector((state: any) => {
    return {
      profile: state.auth.profile,
      borrower: state.borrower,
      loanDetail: state.loanDetail,
    }
  })

  const canEdit = canEditLoanApplication()
  const { hasPermission } = usePermissions()

  useEffect(() => {
    if (isBorrowerUrl(location.pathname)) {
      setSignUrl(true)
    }
  }, [])

  useEffect(() => {
    let newInputs = cloneDeep(inputs)
    Object.keys(inputs).map((key) => {
      const error = InputValidate({ ...inputs[key], value: borrower[borrowerSeperator][key] })
      newInputs[key].error = error
    })
    setInputs(newInputs)
  }, [borrowerSeperator])

  useEffect(() => props.setLoading(loading), [loading])

  const fullName = (data: Record<string, string>) => [data.firstName, data.middleName, data.lastName].join(' ')

  const { handSignedDocuments = [] } = borrower[borrowerSeperator]

  const onChange = async (key: string, value: string | boolean) => {
    if (key === 'exempt' && value && handSignedDocuments.length === 0) {
      return toast('You need to upload hand signed Application!', { type: 'error' })
    }
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    await dispatch(setBorrowerGroupData(borrowerSeperator, { [key]: value }))

    const error = InputValidate({ ...inputs[key], value })
    newInputs[key].error = error
    setInputs(newInputs)
  }

  const onBlur = async (key: string) => {
    let data = { [key]: borrower[borrowerSeperator][key] }
    if (key === 'exempt') {
      if (handSignedDocuments.length === 0) return
      data.signedDate = data[key] ? `${Date.now()}` : ``
    }
    setLoading(true)
    const reqBody = {
      ...data,
      borrowerSeperator,
    }
    await borrowerInfoUpdate(reqBody)
    if (key === 'exempt') {
      await dispatch(setBorrowerGroupData(borrowerSeperator, { signedDate: data.signedDate }))
    }
    signAppValidate(borrowerSeperator, true)
    setLoading(false)
  }

  const showHistory = async (key: string) => {
    const options = {
      table: 'Borrower',
      field: `${borrowerSeperator}-${key}`,
      keys: {
        field: key,
        borrowerSeperator,
      },
    }
    openAuditLog(options)
  }

  const signData = useMemo(() => {
    const { email, signedDate } = borrower.borrower
    let wetSignDisable = !hasPermission('ALWAYS_CAN_SIGN_APPLICATION')
    if ([LoanType.TABLEFUNDER, LoanType.CORRESPONDENT].includes(loanDetail.loanType)) wetSignDisable = false
    return [
      [
        'Borrower',
        email,
        <p className="font-['freehand'] text-[24px]">{fullName(borrower.borrower)}</p>,
        signedDate ? <p className="text-shade-blue font-bold">Signed</p> : 'Unsigned',
        signedDate ? formatTime(new Date(Number(signedDate))) : '',
        <RenderInput
          input={{
            inputType: 'checkbox',
            title: '',
            value: borrower['borrower'].exempt,
            disabled: wetSignDisable,
            history: true,
          }}
          Key={'exempt'}
          onChange={onChange}
          showHistory={showHistory}
          onBlur={onBlur}
        />,
      ],
      loanDetail.includeCoborrower
        ? [
            'CoBorrower',
            borrower.coBorrower.email,
            <p className="font-['freehand'] text-[24px]">{fullName(borrower.coBorrower)}</p>,
            signedDate ? <p className="text-shade-blue font-bold">Signed</p> : 'Unsigned',
            signedDate ? formatTime(new Date(Number(signedDate))) : '',
          ]
        : null,
    ]
  }, [borrower, loanDetail.includeCoborrower])

  const validateForm = () => {
    const checkignData = [
      { value: borrower.borrower.email, error: 'Please input Email from Borrower Information', required: true },
    ]
    for (const item of checkignData) {
      if (!item.required) continue

      if (!item.value) {
        toast(item.error, { type: 'error' })
        return false
      }
    }
    return true
  }

  const onSendSignatureRequest = () => {
    if (!validateForm()) return
    setLoading(true)
    setAction('sendingSignRequest')
    sendSignatureRequest()
      .then(() => {})
      .finally(() => {
        setLoading(false)
        setAction('')
      })
  }

  const onSignApplication = async () => {
    setShowConfirmSign(true)
  }

  const onDownloadPdf = async (fileKey: string) => {
    setLoading(true)
    const res = await downloadS3Documents(fileKey)
    var windowReference: any = window.open()
    windowReference.location = res.url
    setLoading(false)
  }

  const onUploadHandSignPdf = (file: any) => {
    const loanNumber = setLoanNumber()

    const data = {
      path: `loan/${loanNumber}/hand_signed_pdf`,
    }

    setLoading(true)
    uploadFiles(data, [file]).then(async (keys: Array<string>) => {
      const signedFileKey = keys[0]
      const { data } = await addHandSignApplicationPdf(signedFileKey)

      const { handSignedDocuments } = borrower[borrowerSeperator]
      const newDocuments = cloneDeep(handSignedDocuments || [])
      newDocuments.push(data)

      await dispatch(setBorrowerGroupData(borrowerSeperator, { handSignedDocuments: newDocuments }))

      setLoading(false)
    })
  }

  const renderInputWithBorrower = (key: string) => {
    const input = inputs[key]
    if (borrower[borrowerSeperator][key] !== undefined) {
      input.value = borrower[borrowerSeperator][key]
    } else {
      input.value = ''
    }
    input.history = true
    input.disabled = !canEdit
    if (key === 'agreeToSign') {
      if (input.value !== true) input.error = 'Required!'
      // Only Borrower & Admin Profile Can Edit
      if (input.disabled === false) {
        if (signUrl || hasPermission('CAN_AGREE_TERMS_AND_CONDITIONS')) {
        } else {
          input.disabled = true
        }
      }
    }
    return (
      <div className="input mb-4">
        <RenderInput input={input} Key={key} onChange={onChange} showHistory={showHistory} onBlur={onBlur} />
      </div>
    )
  }

  const validations = signApplictionValidation()

  const renderValidation = () => {
    if (validations.length) {
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[14px]"
          role="alert"
        >
          <div className="font-variation-settings-600">
            Following steps are required before Sign or Upload Signed Application.
          </div>
          {validations.map((item: string, index: number) => {
            return <div key={index}>- {item}</div>
          })}
        </div>
      )
    }
  }

  const sendSignRequestValidationFragment = useMemo(() => {
    let errors = []
    if (!loanDetail.restructureApplicationRelease) {
      errors.push(`- Restructure Application Release?: No`)
    }
    if (loanDetail.rateData.rate > 0) {
    } else {
      errors.push(`- Interest Rate & Base Price is Required!`)
    }
    if (errors.length) {
      return (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative mb-4 text-[14px]"
          role="alert"
        >
          {errors.map((item: string, index: number) => {
            return (
              <div className="my-1" key={index}>
                {item}
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }, [loanDetail])

  const viewApplicationPDF = async () => {
    const data: Blob = await getLoanSignAppPdf()
    downloadFile(`${COMPANY_NAME_FC} Application (${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`, data)
  }

  const renderContent = () => {
    if (borrower.borrower.signedDate) {
      return (
        <div className="border rounded p-4 shadow">
          <div className="flex">
            <Button color={'gray'} onClick={() => onDownloadPdf(borrower.borrower.signedPdfKey)}>
              <span className="flex gap-2 items-center">
                <span>View Your Signed Application PDF</span> <ArrowDownTrayIcon className="w-4 h-4" />
              </span>
            </Button>
            <Button link onClick={viewApplicationPDF} className="text-shade-blue pl-4 mt-0">
              <div className="flex gap-2 items-center">
                View Unsigned Application PDF{' '}
                <span className="">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </span>
              </div>
            </Button>
          </div>
          {renderHandSignContent()}
        </div>
      )
    }
    const signElement = (
      <div className="border rounded p-4 shadow">
        <Button
          disabled={validations.length !== 0}
          onClick={onSignApplication}
          loading={action === 'checkSignAppRestriction'}
        >
          Click Here to Sign Application
        </Button>
        {renderHandSignContent()}
      </div>
    )
    if (signUrl) {
      return signElement
    }
    return (
      <div className="">
        {loanDetail.loanType !== LoanType.CORRESPONDENT ? (
          <div>
            {sendSignRequestValidationFragment}
            <div className="flex gap-4 flex-wrap">
              <Button color={'gray'} onClick={viewApplicationPDF}>
                <div className="flex gap-2 items-center">
                  View Unsigned Application PDF{' '}
                  <span className="">
                    <ArrowDownTrayIcon className="w-4 h-4" />
                  </span>
                </div>
              </Button>
              <Button
                onClick={onSendSignatureRequest}
                loading={action === 'sendingSignRequest'}
                disabled={sendSignRequestValidationFragment !== null}
              >
                <div className="flex gap-2 items-center">
                  Send Signature Request
                  <PaperAirplaneIcon className="w-4 h-4"></PaperAirplaneIcon>
                </div>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex gap-4 flex-wrap mt-2">
            <Button color={'gray'} onClick={viewApplicationPDF}>
              <div className="flex gap-2 items-center">
                View Unsigned Application PDF{' '}
                <span className="">
                  <ArrowDownTrayIcon className="w-4 h-4" />
                </span>
              </div>
            </Button>
          </div>
        )}
        {loanDetail.loanType !== LoanType.CORRESPONDENT && hasPermission('ALWAYS_CAN_SIGN_APPLICATION') && (
          <div className="mt-4">
            <div className="border rounded p-4 shadow">
              <Button
                disabled={validations.length !== 0}
                onClick={onSignApplication}
                loading={action === 'checkSignAppRestriction'}
              >
                Click Here to Sign Application
              </Button>
            </div>
          </div>
        )}
        {renderHandSignContent()}
      </div>
    )
  }

  const renderHandSignContent = () => {
    const documentsData = handSignedDocuments.map(({ creator, createdAt, fileKey }: any, index: number) => [
      index + 1,
      creator,
      createdAt,
      <span
        className="inline-block ml-2 mb-0.5 text-shade-blue rounded p-0.5 hover-shadow1 cursor-pointer"
        onClick={() => onDownloadPdf(fileKey)}
      >
        <span>
          <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>{' '}
        </span>
      </span>,
    ])

    return (
      <div className="mt-4 border border-gray-200 rounded p-4 shadow">
        <div className="font-variation-settings-500 text-[15px]">
          - If you have Signed Application by Hand, you can upload in here.
        </div>
        <div className="overflow-auto">
          <div className="w-fit -mb-5">
            <PlainTable header={['No', 'Email', 'Uploaded Date', 'Actions']} data={documentsData} />
          </div>
        </div>

        <div className="-mt-4">
          <InputFile
            className="pt-1"
            title=""
            acceptFileTypes="application/pdf"
            multiple={false}
            disabled={validations.length !== 0}
            onChange={onUploadHandSignPdf}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="text-sm mb-4 text-[15px]">
        <p className="mb-2">
          Each of the undersigned specifically represents to Lender and to Lender's actual or potential agents, brokers,
          processors, attorneys, insurers, servicers, successors and assigns and agrees and acknowledges that:
        </p>
        <div className="mb-2 list-decimal p-4">
          {RequirementList.map((value, index) => (
            <div className={`mb-1 ${index % 2 === 1 && 'bg-slate-100'} p-2 rounded`} key={index}>
              <span className="mr-1">{index + 1}.</span>
              {value}
            </div>
          ))}
        </div>
        <p className="mb-4 mt-4">
          Acknowledgement: Each of the undersigned hereby acknowledges that any owner of the Loan, its servicers,
          successors and assigns, may verify or reverify any information contained in this application or obtain any
          information or data relating to the Loan, for any legitimate business purpose through any source, including a
          source named in this application or a consumer reporting agency.
          <br />
          <br />A commitment fee of $500.00 will be required prior to loan submission.
        </p>
      </div>

      <div className="mb-4">
        <span className="flex gap-2 items-center font-bold text-[14px]">
          <a
            href={`https://${COMPANY_DNS}/#/terms-and-conditions`}
            rel="noopener noreferrer"
            target="_blank"
            className="hover:underline text-shade-blue w-fit"
          >
            - Terms & Conditions
          </a>
          <span className="text-shade-blue">
            <ArrowTopRightOnSquareIcon className="w-4 h-4"></ArrowTopRightOnSquareIcon>
          </span>
        </span>
        {![LoanType.CORRESPONDENT].includes(loanDetail.loanType) && (
          <div className="input md:col-span-1 mb-2 mt-3">{renderInputWithBorrower('agreeToSign')}</div>
        )}
      </div>

      <div className="input md:col-span-1 mb-4">{renderInputWithBorrower('informationToSign')}</div>

      {borrowerSeperator == 'borrower' && (
        <>
          <div className="text-xl font-bold mb-2">- Sign Application</div>
          {renderValidation()}
          <PlainTable header={['', 'Email', 'Signature', 'Status', 'Signed Date', 'Wet Signed']} data={signData} />
          <div>{renderContent()}</div>
        </>
      )}

      {isShowConfirmSign ? (
        <ConfirmSignDialog
          signUrl={signUrl}
          changeBorrowerMenu={changeBorrowerMenu}
          onClose={() => setShowConfirmSign(false)}
        />
      ) : null}
    </div>
  )
}
