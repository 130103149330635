import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { usePermissions } from 'hooks/usePermissions'
import { PartyModal } from 'pages/Loan/Parties/Modal/PartyModal'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CopyableText } from 'stories/components'

export function ClosingParty(props: any) {
  const { id } = props

  const [editing, setEditing] = useState(false)

  const { loanDetail } = useSelector((state: any) => {
    return {
      loanDetail: state.loanDetail,
    }
  })

  const dispatch = useDispatch()

  const data: any = loanDetail.closeParties[id]

  const { hasPermission } = usePermissions()

  const canEdit =
    hasPermission('MANAGE_PARTIES') ||
    loanDetail.submitToLoanSetup === 0 ||
    ['loanSetupOnHold', 'restructureRelease', 'appSigned'].includes(loanDetail.loanStatus)

  const editParty = () => {
    setEditing(true)
  }

  const onCloseModal = (result: boolean, item: Record<string, any>) => {
    setEditing(false)
    if (!result) return
    let temp = cloneDeep(loanDetail.closeParties)
    temp[id] = item
    dispatch(
      setLoanDetail({
        closeParties: temp,
      }),
    )
  }

  return (
    <div className="ClosingParty-container group">
      <div className="flex flex-wrap items-center gap-3 justify-between text-gray-900">
        <span className="font-variation-settings-600">
          <div className="text-[14px]">
            <CopyableText>{data.EMail || ''}</CopyableText>
          </div>
        </span>
        {canEdit && (
          <span className="edit-container flex gap-2">
            <span className="hover:shadow cursor-pointer text-shade-blue">
              <PencilSquareIcon className="w-4 h-4" onClick={editParty}></PencilSquareIcon>
            </span>
          </span>
        )}
      </div>
      {editing && <PartyModal item={data} onClose={onCloseModal} rolodexShow={false} />}
    </div>
  )
}
