import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions/loanDetail.action'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { LoanDetail } from 'components/LoanDetail'
import { companyName, loanStatusConstants } from 'config'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { loanOverviewSection } from 'services'
import { svgLoading } from 'stories/assets'
import { CopyableText } from 'stories/components'
import { useTitle } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export function Overview(props: any) {
  const [number, setNumber] = useState('-')
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState({
    loanNumber: '-',
    // byteproFileName: 0,
    entityTitle: 'N/A',
    borrowerName: '-',
    propertyAddress: '-',
    loanStatus: '',
  })

  const {
    noChangeTitle,
    noCompanyTitle,
    title,
    hasBackButton = true,
    backTitle = '',
    backLink = '',
    showLoanStatus = false,
  } = props

  const { borrower } = useSelector((state: any) => {
    return {
      borrower: state.borrower,
    }
  })

  useEffect(() => {
    if (borrower.borrower.hasEntityTitle === false) {
      if (detail.entityTitle !== 'N/A') {
        let temp = cloneDeep(detail)
        temp.entityTitle = 'N/A'
        setDetail(temp)
      }
    }
    if (borrower.borrower.hasEntityTitle === true) {
      if (detail.entityTitle !== borrower.borrower.entityTitle) {
        let temp = cloneDeep(detail)
        temp.entityTitle = borrower.borrower.entityTitle
        setDetail(temp)
      }
    }
  }, [borrower.borrower.hasEntityTitle, borrower.borrower.entityTitle])

  const dispatch = useDispatch()
  const loanNumber = setLoanNumber()

  useEffect(() => {
    setNumber(loanNumber)
    if (loanNumber == 0) return

    setLoading(true)
    loanOverviewSection().then((data) => {
      if (data.byteproFileName !== 0) {
        data.loanNumber = data.byteproFileName
      }
      setDetail(data)
      if (props.feedOverview) {
        props.feedOverview(data)
      }

      dispatch(
        setLoanDetail({
          loanType: data.type,
        }),
      )
      if (noChangeTitle) {
      } else useTitle(`${data.loanNumber} - ${title}${noCompanyTitle ? '' : ` - ${companyName}`}`)
      setLoading(false)
    })
  }, [])

  return (
    <div className="Overview-container">
      <div className="shadow1 mb-6 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w-full">
        <div className="relative">
          <LayoutLoading show={loading} />
          {hasBackButton && (
            <div className="text-shade-blue flex items-center mb-2 font-bold">
              <ChevronLeftIcon className="w-4 h-4"></ChevronLeftIcon>
              <Link to={backLink || `/loan_process/overview/${number}`} className="hover:underline">
                {backTitle || 'Return to Loan Overview'}
              </Link>
            </div>
          )}
          <div className="flex flex-wrap justify-between">
            <h1 className="text-2xl font-bold flex items-center mb-2">
              <CopyableText
                copyText={`${detail.loanNumber} | ${detail.entityTitle} | ${detail.borrowerName} | ${detail.propertyAddress}`}
              >
                {title}
              </CopyableText>
              <span className="text-base ml-3">
                {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
              </span>
            </h1>
          </div>
          {loanNumber != 0 && (
            <div className="mt-1 flex flex-wrap gap-x-10">
              <LoanDetail
                loan_number={loading ? '' : detail.loanNumber}
                entity_title={loading ? '' : detail.entityTitle}
                borrower_name={loading ? '' : detail.borrowerName}
                property_address={loading ? '' : detail.propertyAddress}
              />

              {showLoanStatus && (
                <div className="flex mb-2 text-sm items-end">
                  <div className="w-36">Loan Status:</div>
                  <div className="font-bold ml-2">
                    <CopyableText>{loanStatusConstants.status[detail.loanStatus]}</CopyableText>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
